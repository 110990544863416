import { ErrorMessage } from '@hookform/error-message'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell, getDateValue, MarketValue, MoneyInput, renderSelectController } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { DealershipInsurer } from 'actff-bo-lib/admin/dealership'
import {
  InsurancePolicy,
  OpportunityInsurancePaymentTypes,
  OpportunityInsuranceSellTypes,
  OpportunityInsuranceSource,
  OpportunityInsuranceType,
} from 'actff-bo-lib/crm/insurance'
import { convertToUTC } from 'actff-bo-lib/date'
import { SelectOption } from 'actff-bo-lib/global'
import { mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import { CssElementsPlacement } from 'actff-bo-lib/style'
import addYears from 'date-fns/add_years'
import subDays from 'date-fns/sub_days'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { insurerOptionValueMapper, insurersToSelectOptions } from '../insurer-option-mapper'
import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'

type Props = Testable
  & SectionFormType
  & {
    readonly oldPolicy?: InsurancePolicy,
    readonly readOnly?: boolean,
    readonly insurers: ReadonlyArray<DealershipInsurer> | undefined,
  }

export const SectionOldPolicy: React.FC<Props> = ({ control, errors, expanded, insurers, register, setValue, testId, watch, readOnly }) => {
  const { t } = useTranslation()
  const insuranceTypes = mapToOptions(Object.keys(OpportunityInsuranceType), 'crmInsurance.insuranceType', t)
  const insuranceSellTypes = mapToOptions(Object.keys(OpportunityInsuranceSellTypes), 'crmInsurance.insuranceSellType', t)
  const insuranceSources = mapToOptions(Object.keys(OpportunityInsuranceSource), 'crmInsurance.boughtAt', t)
  const insurancePaymentTypes = mapToOptions(Object.keys(OpportunityInsurancePaymentTypes), 'crmInsurance.insurancePaymentType', t)

  const handlePolicyStartsChange = (onChange: (value: Date) => void) => (value: Date) => {
    setValue('oldPolicy.expires', convertToUTC(subDays(addYears(value, 1), 1)))
    onChange(value)
  }

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.oldPolicy'), !!errors.oldPolicy)}>
      <SectionForm>
        <FormCell>
          <label>{t('crmInsurance.oldPolicy.type')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...insuranceTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-type`,
              transKey: 'crmInsurance.insuranceType',
            })}
            control={control}
            name='oldPolicy.type'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.oldPolicy.number')}:</label>
          <input type='text' name='oldPolicy.number' ref={register} data-testid={`${testId}oldPolicy-number`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.oldPolicy.issuer')}:</label>
          <Controller
            render={renderSelectController({
              clearable: true,
              customValueMapper: insurerOptionValueMapper(t),
              disabled: readOnly,
              options: [...insurersToSelectOptions(insurers)],
              t,
              testId: `${testId}-insurer`,
            })}
            control={control}
            name='oldPolicy.insurer'
          />
        </FormCell>
        <FormCell />

        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.oldPolicy.issued')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                date={getDateValue(value)}
                onChange={onChange}
                timeDisabled={true}
                testId={`${testId}oldPolicy-issued`}
                disabled={readOnly}
              />
            )}
            control={control}
            name='oldPolicy.issued'
          />
        </FormCell>
        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.oldPolicy.starts')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                date={getDateValue(value)}
                onChange={handlePolicyStartsChange(onChange)}
                timeDisabled={true}
                testId={`${testId}oldPolicy-starts`}
                disabled={readOnly}
              />
            )}
            control={control}
            name='oldPolicy.starts'
          />
        </FormCell>
        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.oldPolicy.expires')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                date={getDateValue(value)}
                onChange={onChange}
                timeDisabled={true}
                testId={`${testId}oldPolicy-expires`}
                disabled={readOnly}
              />
            )}
            control={control}
            name='oldPolicy.expires'
          />
          <ErrorMessage errors={errors} name='oldPolicy.expires' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell />

        <MarketValue
          name='oldPolicy'
          testId={testId}
          control={control}
          errors={errors}
          setValue={setValue}
          register={register}
          watch={watch}
          readOnly={readOnly}
        />
        <FormCell>
          <label>{t('crmInsurance.oldPolicy.grossPremium')}:</label>
          <MoneyInput
            control={control}
            data-testid={`${testId}oldPolicy-grossPremium`}
            name='oldPolicy.grossPremium'
            disabled={readOnly}
          />
        </FormCell>

        <FormCell>
          <label>{t('crmInsurance.oldPolicy.sellType')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              menuPlacement: CssElementsPlacement.Top,
              options: [...insuranceSellTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-sellType`,
              transKey: 'crmInsurance.insuranceSellType',
            })}
            control={control}
            name='oldPolicy.sellType'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.oldPolicy.boughtAt')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...insuranceSources] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-boughtAt`,
              transKey: 'crmInsurance.boughtAt',
            })}
            control={control}
            name='oldPolicy.boughtAt'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.oldPolicy.paymentType')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...insurancePaymentTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-paymentType`,
              transKey: 'crmInsurance.insurancePaymentType',
            })}
            control={control}
            name='oldPolicy.paymentType'
          />
        </FormCell>
      </SectionForm>
    </CollapsableSection>
  )
}
