import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FormCell } from 'actff-bo-app/components/Form'
import { OpportunityService } from 'actff-bo-lib/crm/service'
import { defaultDateFormat } from 'actff-bo-lib/date'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = Pick<OpportunityService, 'lastServiceDate' | 'nextServiceDate'> & {
  readonly i18Key?: string,
}

const ServiceFirst = styled(FormCell)`
  grid-area: service-first;
`

const ServiceSecond = styled(FormCell)`
  grid-area: service-second;
`

export const OpportunityViewService: React.FC<Props> = ({ lastServiceDate, nextServiceDate, i18Key = 'service' }) => {
  const { t } = useTranslation()

  return (
    <>
      <ServiceFirst>
        <label>{t(`opportunity.${i18Key}.lastServiceDate`)}:</label>
        <span><FormattedDate date={lastServiceDate} format={defaultDateFormat} /></span>
      </ServiceFirst>
      <ServiceSecond>
        <label>{t(`opportunity.${i18Key}.nextServiceDate`)}:</label>
        <span><FormattedDate date={nextServiceDate} format={defaultDateFormat} /></span>
      </ServiceSecond>
    </>
  )
}
