import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell, getDateValue, handleBoolCheck, RadioBoxes } from 'actff-bo-app/components/Form'
import { FormFormattedPhoneInput } from 'actff-bo-app/components/FormFormattedPhoneInput'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'

type Props = Testable
  & SectionFormType & {
  readonly driverPhone?: string,
}

export const SectionDriver: React.FC<Props> = ({ control, driverPhone, errors, expanded, register, testId, readOnly }) => {
  const { t } = useTranslation()

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.driver'), !!errors.driver)}>
      <SectionForm>
        <FormCell>
          <label>{t('crmInsurance.driver.name')}:</label>
          <input type='text' name='driver.name' ref={register} data-testid={`${testId}driver-name`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.nip')}:</label>
          <input type='text' name='driver.nip' ref={register} data-testid={`${testId}driver-nip`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.regon')}:</label>
          <input type='text' name='driver.regon' ref={register} data-testid={`${testId}driver-regon`} disabled={readOnly} />
        </FormCell>
        <FormCell />
        <FormCell>
          <label>{t('crmInsurance.driver.firstName')}:</label>
          <input type='text' name='driver.firstName' ref={register} data-testid={`${testId}driver-firstName`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.lastName')}:</label>
          <input type='text' name='driver.lastName' ref={register} data-testid={`${testId}driver-lastName`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.pesel')}:</label>
          <input type='text' name='driver.pesel' ref={register} data-testid={`${testId}driver-pesel`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.isDMS')}:</label>
          <Controller
            render={({ value, onChange}) => (
              <RadioBoxes>
                <div>
                  <input
                    data-testid={`${testId}driver-dms-yes`}
                    id='dms-yes'
                    type='radio'
                    name='driver.dms'
                    defaultChecked={!!value}
                    onClick={handleBoolCheck(true, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='dms-yes'>{t('caption.yes')}</label>
                </div>
                <div>
                  <input
                    data-testid={`${testId}driver-dms-no`}
                    id='dms-no'
                    type='radio'
                    name='driver.dms'
                    defaultChecked={!value}
                    onClick={handleBoolCheck(false, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='dms-no'>{t('caption.no')}</label>
                </div>
              </RadioBoxes>
            )}
            control={control}
            name='driver.dms'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.postCode')}:</label>
          <input type='text' name='driver.postCode' ref={register} data-testid={`${testId}driver-postCode`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.city')}:</label>
          <input type='text' name='driver.city' ref={register} data-testid={`${testId}driver-city`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.street')}:</label>
          <input type='text' name='driver.street' ref={register} data-testid={`${testId}driver-street`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.streetNo')}:</label>
          <input type='text' name='driver.streetNo' ref={register} data-testid={`${testId}driver-streetNo`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.phone')}:</label>
          <FormFormattedPhoneInput
            control={control}
            defaultValue={driverPhone}
            name='driver.driverPhone'
            data-testid={`${testId}driver-phone`}
            disabled={readOnly}
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.driver.email')}:</label>
          <input type='text' name='driver.email' ref={register} data-testid={`${testId}driver-email`} disabled={readOnly} />
        </FormCell>
        <FormCell />
        <FormCell />
        <FormCell>
          <label>{t('crmInsurance.driver.drivingLicenceDate')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                date={getDateValue(value)}
                onChange={onChange}
                testId={`${testId}driver-drivingLicenceDate`}
                timeDisabled={true}
                disabled={readOnly}
              />
            )}
            control={control}
            name='driver.drivingLicenceIssueDate'
          />
        </FormCell>
      </SectionForm>
    </CollapsableSection>
  )
}
