import styled from 'styled-components'
import { colors, LightTable } from 'styles'
import { ButtonWithIcon as ButtonWithIconComponent } from '../../components/Button'

export const Container = styled.div`
  padding: 24px 24px 80px 24px;
`

export const Table = styled(LightTable)`
  width: 50%;

  tbody {
    tr {
      &:hover {
        box-shadow: 0 0 4px ${colors.dustyGray};
      }
    }
    td:last-child {
      min-width: 400px;
    }
  }
`

export const Td = styled.td`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  height: 49px;
`

export const TdDelete = styled(Td)`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 70px;
`

export const Th = styled.th`
  font-weight: bold;

  &:first-child {
    width: 50px;
  }
`

export const Header = styled.div`
  line-height: 27px;
  padding: 0 0 24px;
  text-transform: uppercase;

  h2 {
    font-size: 18px;
  }
`

export const Label = styled.label`
  padding: 10px 0;
`

export const AddingDialogContent = styled.div`
  display: grid;
  padding: 24px 0;
`

export const ButtonWithIcon = styled(ButtonWithIconComponent)`
  padding: 24px;
`
