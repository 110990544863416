export const months: ReadonlyArray<string> = [
  'daypicker.months.january',
  'daypicker.months.february',
  'daypicker.months.march',
  'daypicker.months.april',
  'daypicker.months.may',
  'daypicker.months.june',
  'daypicker.months.july',
  'daypicker.months.august',
  'daypicker.months.september',
  'daypicker.months.october',
  'daypicker.months.november',
  'daypicker.months.december',
]
