import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = {
  readonly children: JSX.Element,
}

const BadgeContainer = styled.div`
  background: ${colors.mystic};
  border-radius: 2px;
  display: grid;
  height: 18px;
  place-items: center;
  width: 17px;
`

const Content = styled.div`
  display: grid;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  place-items: center;
`

export const Badge: React.FC<Props> = ({ children }) => (
  <BadgeContainer>
    <Content>{children}</Content>
  </BadgeContainer>
)
