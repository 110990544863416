import { withoutProps } from 'actff-bo-app/components/withoutProps'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { colors, Opacity, withOpacity } from './colors'
import { theme } from './theme'

type ButtonProps = {
  readonly noPadding?: boolean,
  readonly noHeight?: boolean,
  readonly textAlign?: string,
}

export const OrangeLink = styled(withoutProps(['noPadding'])(Link))`
  color: ${colors.kumera} !important;
  font-weight: bold;
  padding: ${({ noPadding }: ButtonProps) => noPadding ? '0' : '0 35px'};
`

export const OrangeButton = styled.button`
  color: ${colors.kumera};
  font-weight: bold;
  padding: ${({ noPadding }: ButtonProps) => noPadding ? '0' : '0 35px'};
`

export const SecondaryButton = styled.button`
  background-color: ${withOpacity(colors.kumera, Opacity['20%'])};
  border-radius: 3px;
  color: ${colors.kumera};
  font-weight: bold;
  height: ${({ noHeight }: ButtonProps) => noHeight ? '0' : '32px'};
  padding: ${({ noPadding }: ButtonProps) => noPadding ? '0' : '0 35px'};
`

export const LinkButton = styled.button`
  color: ${theme.button.link.text};
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  height: ${({ noHeight }: ButtonProps) => noHeight ? '0' : '32px'};
  padding: ${({ noPadding }: ButtonProps) => noPadding ? '0' : '0 35px'};
  text-align: ${({ textAlign }: ButtonProps) => textAlign ? textAlign : 'default'};
  transition: color .2s ease;

  &.active {
    color: ${theme.button.link.active.text};
    cursor: default;
    pointer-events: none;
  }

  &:disabled {
    color: ${theme.button.link.disabled.text};
    cursor: default;
  }

  &:hover {
    color: ${theme.button.link.hovered.color};
  }
`

export const PrimaryButton = styled.button`
  background-color: ${theme.button.primary.background};
  border-radius: 3px;
  color: ${theme.button.primary.text};
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  height: 32px;
  padding: ${({ noPadding }: ButtonProps) => noPadding ? '0' : '0 35px'};
  text-transform: capitalize;
  transition: background-color .2s ease;

  ::first-letter {
    text-transform: uppercase;
  }

  &:disabled {
    background-color: ${theme.button.primary.disabled.background};
    color: ${theme.button.primary.disabled.text};
    cursor: default;
    &:hover {
      background-color: ${theme.button.primary.disabled.background};
    }
  }

  &:hover {
    background-color: ${theme.button.primary.hovered.background};
  }
`
export const MinorButton = styled(PrimaryButton)`
  background-color: ${theme.button.minor.background};
  border: 2px solid ${theme.button.minor.border};
  cursor: pointer;
  padding: ${({ noPadding }: ButtonProps) => noPadding ? '0' : '0 35px'};

  &:disabled {
    background: ${theme.button.minor.disabled.background};
    color: ${theme.button.minor.disabled.text};
    cursor: default;
  }
`
