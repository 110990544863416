import { IconType, Path } from 'actff-bo-lib/menu/dto'
import { LoggedInPermissions } from 'actff-bo-lib/user'

import { ComponentType } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

// tslint:disable object-literal-sort-keys
export const crmInsuranceMenu = {
  access: LoggedInPermissions,
  component: ComponentType.CrmInsuranceOpportunityLists,
  expanded: false,
  icon: IconType.CrmInsurance,
  name: 'menu.crmInsurance',
  path: Paths.CrmInsurance as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmInsuranceOpportunityLists,
      expanded: false,
      name: 'menu.crmInsurance.chance',
      path: Paths.CrmInsuranceOpportunityList as Path,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmInsuranceOpportunity,
      expanded: false,
      name: 'menu.crmInsurance.manual',
      path: Paths.CrmInsuranceOpportunityNew as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.CrmInsuranceOpportunity,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.CrmInsuranceOpportunity as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmInsuranceReport,
      expanded: false,
      name: 'menu.crmInsurance.report',
      path: Paths.CrmInsuranceReportIndex as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.CrmInsuranceReport,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.CrmInsuranceReport as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
  ],
}
