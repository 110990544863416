import { Filters } from 'actff-bo-app/components/Filters'
import { HeaderContainer } from 'actff-bo-app/components/Grid'
import { Search } from 'actff-bo-app/components/Search'
import { TabFilters } from 'actff-bo-app/components/TabFilters'
import { OpportunityAssignFilters } from 'actff-bo-app/Crm/Trade/OpportunityAssign'
import { OpportunityList } from 'actff-bo-app/Crm/Trade/OpportunityList/OpportunityList'
import { PurchaseOpportunityListFilters } from 'actff-bo-app/Crm/Trade/Purchase'
import { FiltersContainer } from 'actff-bo-app/Crm/Trade/Purchase/OpportunityList/Styled'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { OpportunityPurchaseFilterValues, OpportunitySaleFilterValues, TradeOpportunityType } from 'actff-bo-lib/crm/trade'
import {
  CrmTradeOpportunityPurchaseAction,
  initialDetailedFiltersFormValues,
  selectOpportunityPurchaseListsDetailedFilters,
  selectOpportunityPurchaseListsStatusFilters,
  selectOpportunityPurchaseSearchPhrase,
} from 'actff-bo-lib/crm/trade/purchase'
import { DetailedFiltersFormValues } from 'actff-bo-lib/crm/trade/purchase/filters/filters'
import {
  CrmTradeOpportunitySaleAction,
  selectOpportunitySaleListsDetailedFilters,
  selectOpportunitySaleListsStatusFilters,
  selectOpportunitySaleSearchPhrase,
} from 'actff-bo-lib/crm/trade/sale'
import { selectUserPermissions, selectUsersWithoutMe, UserAction, UserPermissions } from 'actff-bo-lib/user'
import { hasPermission } from 'actff-bo-lib/user/has-permission'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { H2, HeaderList } from 'styles'

const testId = 'trade-crm-opportunity-list__'
const filtersMinWidth = 1360

export const TradeOpportunityLists: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isPurchase = location.pathname.includes('purchase')
  const opportunityType = isPurchase ? TradeOpportunityType.Purchase : TradeOpportunityType.Sale

  const getSearchPhraseSelector = () => isPurchase ? selectOpportunityPurchaseSearchPhrase : selectOpportunitySaleSearchPhrase
  const getStatusFiltersSelector = () => isPurchase ? selectOpportunityPurchaseListsStatusFilters : selectOpportunitySaleListsStatusFilters

  const getDetailedFiltersSelector = () => isPurchase ?
    selectOpportunityPurchaseListsDetailedFilters : selectOpportunitySaleListsDetailedFilters

  const getUserList = useMemo(() => () => { dispatch(UserAction.getUserList()) }, [dispatch])
  const clearPurchaseState = useCallback(() => dispatch(CrmTradeOpportunityPurchaseAction.clearPurchaseState()), [dispatch])
  const { data: userList } = useSelector(selectUsersWithoutMe)

  useEffect(() => {
    clearPurchaseState()
    getUserList()
  }, [clearPurchaseState, getUserList])

  const { t } = useTranslation()
  const userPermissions = useSelector(selectUserPermissions)
  const existingSearchPhrase = useSelector(getSearchPhraseSelector())
  // TODO solve the type issue here
  // @ts-ignore
  const opportunityListsStatusFilters = useSelector(getStatusFiltersSelector())
  const filterFormValues = useSelector(getDetailedFiltersSelector())

  const onSearch = (searchPhrase: string) => dispatch(
    isPurchase
      ? CrmTradeOpportunityPurchaseAction.changeSearchPhrase(searchPhrase)
      : CrmTradeOpportunitySaleAction.changeSearchPhrase(searchPhrase),
  )

  const getHeader = () => isPurchase ? t('crmTrade.purchase.list.header') : t('crmTrade.sale.list.header')

  const onListsFilterChange = (param: string, value: OpportunityPurchaseFilterValues | OpportunitySaleFilterValues) =>
    isPurchase ? dispatch(CrmTradeOpportunityPurchaseAction.changeListsStatusFilters(param, value as OpportunityPurchaseFilterValues))
      : dispatch(CrmTradeOpportunitySaleAction.changeListsStatusFilters(param, value as OpportunitySaleFilterValues))

  const onDetailedFiltersClear = () => {
    dispatch(CrmTradeOpportunityPurchaseAction.clearListsDetailedFilters())
  }

  const onDetailedFiltersSubmit = (values: DetailedFiltersFormValues) => {
    isPurchase
      ? dispatch(CrmTradeOpportunityPurchaseAction.changeListsDetailedFilters(values))
      : dispatch(CrmTradeOpportunitySaleAction.changeListsDetailedFilters(values))
  }

  return (
    <>
      <HeaderList>
        <HeaderContainer>
          <div><H2 data-testid={`${testId}header`}>{getHeader()}</H2></div>
          <Search
            testId={testId}
            onSearch={onSearch}
            initialValues={{ searchPhrase: existingSearchPhrase }}
          />
        </HeaderContainer>
        <FiltersContainer isPurchase={isPurchase}>
          <Filters filters={opportunityListsStatusFilters} onFilterChange={onListsFilterChange} testId={testId} />
          {isPurchase && (
            <TabFilters
              defaultFormValues={filterFormValues}
              filters={filterRendererProps => <PurchaseOpportunityListFilters {...filterRendererProps} />}
              leftDirection={true}
              minWidth={filtersMinWidth}
              onClear={onDetailedFiltersClear}
              onSubmit={onDetailedFiltersSubmit}
              resetValues={initialDetailedFiltersFormValues}
            />
          )}
          <OpportunityAssignFilters userList={userList} opportunityType={opportunityType} />
        </FiltersContainer>
      </HeaderList>
      <OpportunityList
        opportunityType={opportunityType}
        title='opportunity.list.past'
        type={OpportunityListType.overdue}
      />
      <OpportunityList
        opportunityType={opportunityType}
        title='opportunity.list.current'
        type={OpportunityListType.new}
      />
      <OpportunityList
        opportunityType={opportunityType}
        changeTimeTo={true}
        title='opportunity.list.future'
        type={OpportunityListType.future}
      />
      {hasPermission([UserPermissions.AdminAllService])(userPermissions) && (
        <OpportunityList
          opportunityType={opportunityType}
          title='opportunity.list.all'
          type={OpportunityListType.all}
        />
      )}
    </>
  )
}
