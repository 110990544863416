import { ClientFullName, ClientPhone, ClientType } from 'actff-bo-app/components/Client'
import { TimeAgo } from 'actff-bo-app/components/DateTime'
import { WithStyledRow } from 'actff-bo-app/components/Table'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { defaultTimeFormat } from 'actff-bo-lib/date'
import { selectDealerLocationByKey } from 'actff-bo-lib/dealership'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { TelephoneRequest, TelephoneRequestStatus } from 'actff-bo-lib/telephone-request'
import { format } from 'date-fns'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { TableInfo } from 'styles'

type Props = Testable & {
  readonly telephoneRequest: TelephoneRequest,
}

export const TelephoneRequestRow: React.FC<Props> = ({ telephoneRequest, testId }) => {
  const { car, client, requestTime, status } = telephoneRequest
  const route = createRoute(Paths.TelephoneRequestsView, { telephoneRequestId: telephoneRequest.uuid })
  const Td = TdDecorator(route)

  const dealerLocation = useSelector(selectDealerLocationByKey(telephoneRequest.locationKey))

  return (
    <WithStyledRow
      isStyled={status === TelephoneRequestStatus.NEW}
      time={requestTime}
    >
      <Td testId={`${testId}--date`}>
        {format(requestTime, defaultTimeFormat)}
        <TableInfo><TimeAgo date={requestTime} /></TableInfo>
      </Td>
      <Td testId={`${testId}--full-name`}><ClientFullName client={client} /></Td>
      <Td testId={`${testId}--type`}><ClientType client={client} /></Td>
      <Td testId={`${testId}--phone`}><ClientPhone client={client} /></Td>
      <Td testId={`${testId}--registration-number`}>{car.registrationNumber}</Td>
      <Td testId={`${testId}--alias`}>{car.alias}</Td>
      <Td testId={`${testId}--dealer-location`}>{dealerLocation?.name}</Td>
    </WithStyledRow>
  )
}
