import styled from 'styled-components/macro'
import { colors, FiltersContainer as FiltersContainerBase, layers, TabsStyled as TabsStyledBase } from 'styles'

type FiltersContainerProps = {
  readonly expanded: boolean,
}

type TabStyledProps = {
  readonly expanded: boolean,
  readonly transparent?: boolean,
  readonly width?: string,
}

export const TabsStyled = styled(TabsStyledBase)<TabStyledProps>`
  ${({ transparent }: TabStyledProps) => transparent && 'background-color: white'};
  padding-left: 0;
  position: relative;
  height: 50px;
  width: ${({ width }: TabStyledProps) => `${width}px` ?? '100%'};
  z-index: ${layers.tabs};

  li {
    ${({ transparent }: TabStyledProps) => transparent && 'background-color: white'};
  }
`

export const FiltersContainer = styled(FiltersContainerBase)<FiltersContainerProps>`
  grid-gap: 30px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    'filters'
    'footer';
  visibility: ${({ expanded }: FiltersContainerProps) => expanded ? 'visible' : 'hidden'};
`

export const Filters = styled.div`
  grid-area: filters;
  width: 100%;
`

export const FooterStyled = styled.footer`
  border-top: 1px solid ${colors.whisperBlue};
  display: inline-grid;
  grid-area: footer;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  margin-top: 10px;
  min-width: 500px;
  padding: 10px;
  width: 100%;
`

export const FiltersActionButtonsStyled = styled.div`
  justify-content: space-between;
`

export const MainActionButtonsStyled = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 130px);
  justify-content: end;
  justify-items: center;
`
