import {
  approvalsFormDataToApprovalsDto,
  Client,
  ContactApprovals,
  ContactApprovalsWithClientIdToAPI,
} from 'actff-bo-lib/client'
import { User } from 'actff-bo-lib/user/dto'
import { withFormik } from 'formik'
import { boolean, object } from 'yup'

export type ApprovalsFormValues = {
  readonly pushMarketingOfferAgree: boolean,
  readonly pushTradeOfferAgree: boolean,
  readonly smsMarketingOfferAgree: boolean,
  readonly smsTradeOfferAgree: boolean,
  readonly emailMarketingOfferAgree: boolean,
  readonly emailTradeOfferAgree: boolean,
  readonly personalDataAgree: boolean,
  readonly profileAgree: boolean,
  readonly termsAndConditionsAgree: boolean,
  readonly tradeInformationAgree: boolean,
  readonly marketingInformationAgree: boolean,
}

export type ApprovalsFormProps = {
  readonly approvals: ContactApprovals,
  readonly client: Client,
  readonly user: User | null,
  readonly updateContactApprovals: (contactApprovals: ContactApprovalsWithClientIdToAPI) => void,
}
// tslint:disable object-literal-sort-keys

const validationSchema = object({
  tradeInformationAgree: boolean(),
  pushTradeOfferAgree: boolean().when('tradeInformationAgree', {
    is: true,
    then: boolean(),
    otherwise: boolean().oneOf([false]),
  }),
  smsTradeOfferAgree: boolean().when('tradeInformationAgree', {
    is: true,
    then: boolean(),
    otherwise: boolean().oneOf([false]),
  }),
  emailTradeOfferAgree: boolean().when('tradeInformationAgree', {
    is: true,
    then: boolean(),
    otherwise: boolean().oneOf([false]),
  }),
  marketingInformationAgree: boolean(),
  pushMarketingOfferAgree: boolean().when('marketingInformationAgree', {
    is: true,
    then: boolean(),
    otherwise: boolean().oneOf([false]),
  }),
  smsMarketingOfferAgree: boolean().when('marketingInformationAgree', {
    is: true,
    then: boolean(),
    otherwise: boolean().oneOf([false]),
  }),
  emailMarketingOfferAgree: boolean().when('marketingInformationAgree', {
    is: true,
    then: boolean(),
    otherwise: boolean().oneOf([false]),
  }),
})

export const formik = withFormik<ApprovalsFormProps, ApprovalsFormValues>({
  handleSubmit: (formValues: ApprovalsFormValues, { props }) => {
    const approvals = props.approvals
    const { client } = props

    props.updateContactApprovals(
      approvalsFormDataToApprovalsDto(approvals, formValues, client),
    )
  },
  isInitialValid: true,
  mapPropsToValues: ({ approvals }) => ({
      // Contact Approvals
      pushTradeOfferAgree: approvals.tradeOffers.pushNotifications[0].approved,
      pushMarketingOfferAgree: approvals.marketingOffers.pushNotifications[0].approved,
      smsMarketingOfferAgree: approvals.marketingOffers.sms[0].approved,
      smsTradeOfferAgree: approvals.tradeOffers.sms[0].approved,
      emailMarketingOfferAgree: approvals.marketingOffers.email[0].approved,
      emailTradeOfferAgree: approvals.tradeOffers.email[0].approved,
      // Registration Approvals
      marketingInformationAgree: approvals.marketingOffers.general[0].approved,
      personalDataAgree: approvals.personalData.general[0].approved,
      profileAgree: approvals.profile ? approvals.profile.general[0].approved : false,
      termsAndConditionsAgree: approvals.termsAndConditions ? approvals.termsAndConditions.general[0].approved : false,
      tradeInformationAgree: approvals.tradeOffers.general[0].approved,
    }),
  validationSchema,
})
