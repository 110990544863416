import { ActionsUnion, createAction } from '@evojam/redux-utils'

import { Toast } from './dto'

export enum ToastActionType {
  DisplayToast = '[TOAST] DISPLAY TOAST',
  HideToast = '[TOAST] HIDE TOAST',
}

export const ToastAction = {
  displayToast: (toast: Toast) => createAction(ToastActionType.DisplayToast, toast),
  hideToast: (id: string) => createAction(ToastActionType.HideToast, id),
}

export type ToastAction = ActionsUnion<typeof ToastAction>
