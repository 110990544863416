import * as DOMPurify from 'dompurify'
import * as React from 'react'

type Props = {
  readonly dirtyText: string,
}

export const SanitizeHtml: React.FC<Props> = ({ dirtyText }) => (
  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(dirtyText) }} />
)
