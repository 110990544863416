import { api, PagedResult } from 'actff-bo-lib/api'
import { OpportunityListType } from 'actff-bo-lib/crm'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { buildUrl, Filters, getFiltersAsObject, getSimpleFiltersAsObject } from 'actff-bo-lib/global'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  createAccomplishmentDetailsDto,
  createOpportunity,
  Lead,
  OpportunityAccomplishmentDetailsDto,
  OpportunityArrangedDto,
  OpportunityFailedDto,
  OpportunityFilterValues,
  OpportunityId,
  OpportunityInternalCommentDto,
  OpportunityManualPayload,
  OpportunityMissedDto,
  OpportunityRetryDto,
  OpportunityService,
} from './dto'

export const getOpportunities = (
  type: OpportunityListType,
  page: number,
  filters: Filters<OpportunityFilterValues>,
  assignmentFilter: UserId | null,
  location?: ReadonlyArray<DealerLocationKey>,
  brand?: ReadonlyArray<string>,
  fromDate?: Date,
  toDate?: Date,
  phrase?: string,
): Observable<PagedResult<OpportunityService>> => {
  const from = fromDate && fromDate.toISOString()
  const to = toDate && toDate.toISOString()

  if (type === OpportunityListType.all) {
    return api.get(buildUrl('/crm/opportunity', {
      ...getFiltersAsObject(filters),
      assignee: assignmentFilter,
      page,
      phrase,
      ...getSimpleFiltersAsObject({ location, brand }),
    }))
  }

  return api.get(buildUrl(
    `/crm/opportunity/${type}`,
    {
      ...getFiltersAsObject(filters),
      assignee: assignmentFilter,
      page,
      // tslint:disable-next-line:object-literal-sort-keys
      from,
      to,
      phrase,
      ...getSimpleFiltersAsObject({ location, brand }),
    },
  ))
}

export const createOpportunityManual = (payload: OpportunityManualPayload): Observable<OpportunityService> =>
  api.post(`/crm/opportunity/${payload.vin}/manual`, payload.opportunity)

export const getOpportunity = (uuid: OpportunityId): Observable<OpportunityService> =>
  api.get(`/crm/opportunity/${uuid}`).pipe(map(createOpportunity))

export const getLead = (vin: string): Observable<Lead> =>
  api.get(`/crm/lead/${vin}`)

export const sendOpportunityInternalComment = (opportunityId: OpportunityId, internalComment: OpportunityInternalCommentDto) =>
  api.post(`/crm/opportunity/${opportunityId}/comment`, internalComment)

export const setOpportunityStatusAccomplished = (opportunity: OpportunityAccomplishmentDetailsDto) => {
  const { invoiceNumber, orderNumber, partsProfit, labourProfit, uuid, ...rest } = opportunity

  return api.post(`/crm/opportunity/${uuid}/accomplished`, {
    ...rest,
    ...createAccomplishmentDetailsDto({ invoiceNumber, orderNumber, partsProfit, labourProfit }),
  })
}

export const setOpportunityStatusArranged = (opportunity: OpportunityArrangedDto) => {
  const { uuid, ...rest } = opportunity

  return api.post(`/crm/opportunity/${uuid}/arranged`, { ...rest })
}

export const setOpportunityStatusFailed = (opportunity: OpportunityFailedDto) => {
  const { uuid, ...rest } = opportunity

  return api.post(`/crm/opportunity/${uuid}/failed`, { ...rest })
}

export const setOpportunityStatusRetry = (opportunity: OpportunityRetryDto) => {
  const { uuid, ...rest } = opportunity

  return api.post(`/crm/opportunity/${uuid}/retry`, { ...rest })
}

export const setOpportunityStatusMissed = (opportunity: OpportunityMissedDto) => {
  const { uuid, ...rest } = opportunity

  return api.post(`/crm/opportunity/${uuid}/missed`, { ...rest })
}

export const updateOpportunity = (opportunity: OpportunityService) => {
  const { uuid, comments, ...dataToPatch } = opportunity

  return api.patch(`/crm/opportunity/${uuid}`, dataToPatch)
}

export const assignOpportunity = (uuid: OpportunityId, user: UserWithoutPermissions) =>
  api.patch<OpportunityService, UserWithoutPermissions>(`/crm/opportunity/${uuid}/assign`, user)

export const unassignOpportunity = (uuid: OpportunityId) =>
  api.delete<OpportunityService>(`/crm/opportunity/${uuid}/assign`)
