import { ViewTabs } from 'actff-bo-app/Admin/Brands/ViewTabs'
import { Container, Header } from 'actff-bo-app/Admin/Styled'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { addAssistance, deleteAssistance, getAssistance } from 'actff-bo-lib/admin/brands/dao'
import { CarAssistance } from 'actff-bo-lib/admin/brands/dto'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { selectCurrentBrand } from 'actff-bo-lib/dealership'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { H2 } from 'styles'

import { AssistanceTable } from './AssistanceTable'

const testId = 'admin-view-content-management__'

const AssistanceComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentBrand = useSelector(selectCurrentBrand)

  const { data: assistance, isLoading, isError, refetch } = useQuery([QueryKeys.GET_ASSISTANCE, currentBrand], async () =>
    await getAssistance([currentBrand]), { retry: false, refetchOnWindowFocus: false },
  )

  const handleDelete = async (carAssistance: CarAssistance) => {
    try {
      await deleteAssistance(currentBrand, carAssistance.assistanceName)
      dispatch(displaySuccessToast('admin.brands.assistance.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      await refetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.brands.assistance.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  const handleAdd = async (assistanceName: string, assistancePhoneNumber: string) => {
    try {
      await addAssistance(currentBrand, assistanceName, assistancePhoneNumber)
      dispatch(displaySuccessToast('admin.brands.assistance.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      await refetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.brands.assistance.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <Container>
          <Header><H2>{t('admin.brands.assistance.subheader')}</H2></Header>
          <LocalLoader />
        </Container>
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('admin.brands.assistance.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.brands.assistance.subheader')}</H2></Header>
        <AssistanceTable assistance={assistance} onAdd={handleAdd} onDelete={handleDelete} />
      </Container>
    </>
  )
}

export const Assistance = withAdminViewHeader(AssistanceComponent)
