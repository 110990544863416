import { Token } from './dto'

export const decodeJwtPayload = <T>(token: Token | null): T | null => {
  if (!token) {
    return null
  }

  const [, base64Url] = token.split('.')
  const base64 = base64Url.replace('-', '+').replace('_', '/')

  try {
    return JSON.parse(atob(base64))
  } catch {
    return null
  }
}
