import { Field, FormCell, FormHeader } from 'actff-bo-app/components/Form'
import { FormikEffect } from 'actff-bo-app/components/Form/FormikEffect'
import { OpportunityAccomplishmentDetails as OpportunityAccomplishmentDetailsType } from 'actff-bo-lib/crm'
import { hasErrors } from 'actff-bo-lib/global'
import { FormikProps, FormikState, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'
import { object, string } from 'yup'

type Props = {
  readonly accomplishmentDetails?: OpportunityAccomplishmentDetailsType,
  readonly editMode?: boolean,
  readonly onChange: (values: OpportunityAccomplishmentDetailsType) => void,
  readonly onValidateChange: (hasErrors: boolean) => void,
}

type OpportunityAccomplishmentDetailsProps = Props & FormikProps<OpportunityAccomplishmentDetailsType> & Props & WithTranslation

const initialValues: OpportunityAccomplishmentDetailsType = {
  invoiceNumber: '',
  labourProfit: '',
  orderNumber: '',
  partsProfit: '',
}

const OpportunityAccomplishmentDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
`

const OpportunityAccomplishmentDetailsFormHeader = styled(FormHeader)`
  padding-left: 32px;
`

class OpportunityAccomplishmentDetailsComponent extends React.Component<OpportunityAccomplishmentDetailsProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: OpportunityAccomplishmentDetailsProps): void {
    if (nextProps.values !== this.props.values) {
      this.props.onChange(nextProps.values)
    }
  }

  public render(): React.ReactNode {
    const { accomplishmentDetails, editMode = true, errors, touched, t } = this.props

    return editMode
      ? (
        <FormikEffect formik={this.props} onChange={this.handleValidateChange}>
          <OpportunityAccomplishmentDetailsContainer>
            <OpportunityAccomplishmentDetailsFormHeader>
              {t('opportunity.accomplishmentDetails.service.data')}
            </OpportunityAccomplishmentDetailsFormHeader>
            <OpportunityAccomplishmentDetailsFormHeader>
              {t('opportunity.accomplishmentDetails.service.income')}
            </OpportunityAccomplishmentDetailsFormHeader>
            <FormCell>
              <label htmlFor='invoiceNumber'>{t('opportunity.form.invoiceNumber.label')}</label>
              <Field
                error={errors.invoiceNumber}
                id='invoiceNumber'
                name='invoiceNumber'
                placeholder={t('opportunity.form.invoiceNumber.placeholder')}
                touched={touched.invoiceNumber}
                type='text'
              />
            </FormCell>
            <FormCell>
              <label htmlFor='partsProfit'>{t('opportunity.form.partsProfit.label')}</label>
              <Field
                error={errors.partsProfit}
                id='partsProfit'
                name='partsProfit'
                placeholder='0,00'
                touched={touched.partsProfit}
                type='text'
              />
            </FormCell>
            <FormCell>
              <label htmlFor='orderNumber'>{t('opportunity.form.orderNumber.label')}</label>
              <Field
                error={errors.orderNumber}
                id='orderNumber'
                name='orderNumber'
                placeholder={t('opportunity.form.orderNumber.placeholder')}
                touched={touched.orderNumber}
                type='text'
              />
            </FormCell>
            <FormCell>
              <label htmlFor='labourProfit'>{t('opportunity.form.labourProfit.label')}</label>
              <Field
                error={errors.labourProfit}
                id='labourProfit'
                name='labourProfit'
                placeholder='0,00'
                touched={touched.labourProfit}
                type='text'
              />
            </FormCell>
          </OpportunityAccomplishmentDetailsContainer>
        </FormikEffect>
      )
      : accomplishmentDetails
        ? (
          <>
            {accomplishmentDetails.invoiceNumber}
            {accomplishmentDetails.partsProfit}
            {accomplishmentDetails.labourProfit}
            {accomplishmentDetails.orderNumber}
          </>
        )
        : null
  }

  private readonly handleValidateChange = (
    props: FormikState<OpportunityAccomplishmentDetailsType>,
    newProps: FormikState<OpportunityAccomplishmentDetailsType>,
  ) => {
    if (hasErrors(props.errors) !== hasErrors(newProps.errors)) {
      this.props.onValidateChange(hasErrors(newProps.errors))
    }
  }
}

const sixDigitPricePattern = new RegExp('^-?(([0]{1})|([1-9]{1}[0-9]{0,5}))([\\.|,][0-9]{2})?$')

const validationSchema = () => object<OpportunityAccomplishmentDetailsType>().shape({
  invoiceNumber: string().required(),
  labourProfit: string().matches(sixDigitPricePattern),
  orderNumber: string().required(),
  partsProfit: string().matches(sixDigitPricePattern),
})

const formik = withFormik<Props, OpportunityAccomplishmentDetailsType>({
  handleSubmit: () => null,
  mapPropsToValues: ({ accomplishmentDetails }) => {
    const { invoiceNumber, partsProfit, labourProfit, orderNumber } = accomplishmentDetails || initialValues

    return ({
      invoiceNumber,
      labourProfit,
      orderNumber,
      partsProfit,
    })
  },
  validateOnMount: true,
  validationSchema,
})

export const OpportunityAccomplishmentDetails = compose(
  formik,
  withTranslation(),
)(OpportunityAccomplishmentDetailsComponent)
