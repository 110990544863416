import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { User } from './dto'

export enum UserActionType {
  GetMe = '[USER] GET ME',
  GetMeFailure = '[USER] GET ME FAILURE',
  GetMeSuccess = '[USER] GET ME SUCCESS',

  GetUserList = '[USER] GET USER LIST',
  GetUserListFailure = '[USER] GET USER LIST FAILURE',
  GetUserListSuccess = '[USER] GET USER LIST SUCCESS',
  SetMeInitialized = '[USER] SET ME INITIALIZED',

  UpdateUsersLocations = '[USER] UPDATE USER LOCATIONS',
  UpdateUsersLocationsFailure = '[USER] UPDATE USER LOCATIONS FAILURE',
  UpdateUsersLocationsSuccess = '[USER] UPDATE USER LOCATIONS SUCCESS',
}

export const UserAction = {
  getMe: () => createAction(UserActionType.GetMe),
  getMeFailure: (error: AjaxError) => createAction(UserActionType.GetMeFailure, error),
  getMeSuccess: (user: User) => createAction(UserActionType.GetMeSuccess, user),

  getUserList: () => createAction(UserActionType.GetUserList),
  getUserListFailure: (error: AjaxError) => createAction(UserActionType.GetUserListFailure, error),
  getUserListSuccess: (userList: ReadonlyArray<User>) => createAction(UserActionType.GetUserListSuccess, userList),

  updateUsersLocations: (users: ReadonlyArray<User>) => createAction(UserActionType.UpdateUsersLocations, users),
  updateUsersLocationsFailure: (error: AjaxError) => createAction(UserActionType.UpdateUsersLocationsFailure, error),
  updateUsersLocationsSuccess: (userList: ReadonlyArray<User>) => createAction(UserActionType.UpdateUsersLocationsSuccess, userList),

  setMeInitialized: () => createAction(UserActionType.SetMeInitialized),
}

export type UserAction = ActionsUnion<typeof UserAction>
