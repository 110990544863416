// tslint:disable-next-line:cyclomatic-complexity
export function isEqualBy <T>(
  a1: ReadonlyArray<T> | null | undefined,
  a2: ReadonlyArray<T> | null | undefined,
  compareField: string,
): boolean {
  if (
    !a1 || !a2
    || a1.length !== a2.length
    || !a1.some(elem => elem[compareField]) || !a2.some(elem => elem[compareField])
  ) { return false }

  return a1
    .every(itemA => a2
      .some(itemB => itemB[compareField] === itemA[compareField]))
}
