import { objectDiff } from 'actff-bo-lib/global'
import { servicesToIds } from 'actff-bo-lib/service'
import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectServiceRequestFeature = (state: State) => state.serviceRequest

export const selectIsLoadingServiceRequestList =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.list.loading)

export const selectAvailableCarLeaveOptions =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.availableCarLeaveOptions)

export const selectCreateServiceRequestPending =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.createServiceRequestPending)

export const selectOverdueListCurrentPage =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.overdueCurrentPage)

export const selectCurrentPage =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.currentPage)

export const selectCurrentServiceRequest =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.currentServiceRequest.data)

export const selectCurrentServiceRequestInternalComments =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.currentServiceRequest.comments)

export const selectDraftServiceRequest =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.draftServiceRequest)

export const selectCurrentServiceRequestAttachments =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.currentServiceRequest.attachments)

export const selectDraftServiceRequestDiffAndId =
  createSelector(selectCurrentServiceRequest, selectDraftServiceRequest, (current, draft) => {
    if (!current || !draft) {
      return {}
    }

    const { uuid, services, ...rest } = objectDiff(current, draft)

    return ({
      ...rest,
      ...(services && { services: servicesToIds(draft.services) }),
      uuid: current.uuid,
    })
  })

export const selectServiceRequestsList =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.list.data)

export const selectOverdueServiceRequestsList =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.overdueList.data)

export const selectNewServiceRequestsCount =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.newServiceRequestsCount)

export const selectServiceRequestListFilters = createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.list.filters)

export const selectServiceRequestSearchPhrase =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.list.searchPhrase)

export const selectIsUpdatingServiceRequest =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.isUpdatingServiceRequest)

export const selectServiceRequestDealerLocationFilters =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.list.locationFilters)

export const selectServiceRequestBrandFilters =
  createSelector(selectServiceRequestFeature, serviceRequest => serviceRequest.list.brandFilters)
