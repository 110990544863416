import { Wrapper } from 'actff-bo-app/components/Wrapper'
import * as React from 'react'

import { TopBar } from './TopBar'

export const withTopBar: Wrapper = () => (Component: React.ComponentType) => // tslint:disable-line
  class WTB extends React.Component {
    public render(): React.ReactNode {
      return (
        <>
          <TopBar />
          <Component />
        </>
      )
    }
  }
