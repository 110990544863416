import { List } from 'immutable'

export const isMenuPathActive = (activePath: string, menuPath?: string, skipLast?: boolean) => {
  if (!menuPath) {
    return false
  }

  const [, ...menuPathArray] = menuPath.split('/')
  const [, ...activePathArray] = activePath.split('/')

  const menuPathArrayValue = skipLast
    ? List(menuPathArray)
      .skipLast(1)
      .toArray()
    : menuPathArray

  return skipLast
    ? menuPathArrayValue.every(element => activePathArray.includes(element))
    : menuPathArrayValue.every((menuPathElement, idx) =>
      activePathArray.indexOf(menuPathElement) === idx || menuPathElement[0] === ':')
}
