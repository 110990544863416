import { FormikErrors } from 'formik'
import { FieldErrors } from 'react-hook-form/dist/types/form'

export type SelectOption<T> = {
  readonly label: string,
  readonly value: T,
}

export type Option = {
  readonly label: string,
  readonly value: string,
}

export const isSelectOption = <T>(option: unknown): option is SelectOption<T> =>
  !!(typeof option === 'object'
    && (option as object).hasOwnProperty('label')
    && typeof (option as object & { readonly label: string }).label === 'string'
    && (option as object).hasOwnProperty('value'))

// tslint:disable-next-line:no-any
export const hasErrors = <T>(errors: T | FormikErrors<any> | FieldErrors): boolean => Object.keys(errors).length > 0

export const mapValuesToSelectOptions = <T>(values: ReadonlyArray<T> | null | undefined, uniqueValues = true) => {
  if (!values) {
    return []
  }

  return uniqueValues
    ? Array.from(new Set(values)).map(value => ({ label: value, value }))
    : values.map(value => ({ label: value, value }))

}
