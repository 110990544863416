/***

 Credits: Code in this file comes from react-hook-form resolver for io-ts.
 Sources: https://github.com/react-hook-form/resolvers/tree/master/io-ts/src

 ***/

import { either, option, readonlyArray } from 'fp-ts'
import * as Either from 'fp-ts/Either'
import { absurd, flow, identity, not, pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

export const FormError = t.type({
  message: t.string,
  path: t.string,
  type: t.string,
})

export type FormError = t.TypeOf<typeof FormError>
export type FormErrors = ReadonlyArray<FormError>

const TypeToErrorMap = {
  NonEmptyString: 'This field is required.',
  UUID: 'Please, provide correct UUID.',
}
export const typeToError = (type: string) =>
  TypeToErrorMap[type] || 'Provided value is incorrect.'

const arrayToPath = (paths: ReadonlyArray<Either.Either<string, number>>): string =>
  paths.reduce(
    (previous, path, index) =>
      pipe(
        path,
        Either.fold(
          key => `${index > 0 ? '.' : ''}${key}`,
          key => `[${key}]`,
        ),
        _path => `${previous}${_path}`,
      ),
    '',
  )

const formatErrorPath = (context: t.Context): string =>
  pipe(
    context,
    // tslint:disable-next-line:cyclomatic-complexity
    readonlyArray.filterMapWithIndex((index, contextEntry) => {
      const previousIndex = index - 1

      const shouldBeFiltered =
        context[previousIndex] === undefined ||
        context[previousIndex].type instanceof t.TaggedUnionType ||
        context[previousIndex].type instanceof t.UnionType ||
        context[previousIndex].type instanceof t.IntersectionType

      return shouldBeFiltered ? option.none : option.some(contextEntry)
    }),
    readonlyArray.map(({ key }) => key),
    readonlyArray.map(key =>
      pipe(
        key,
        k => parseInt(k, 10),
        either.fromPredicate(not<number>(Number.isNaN), () => key),
      ),
    ),
    readonlyArray.toArray,
    arrayToPath,
  )

const formatError = (e: t.ValidationError) => {
  const path = formatErrorPath(e.context)

  const message = pipe(
    e.message,
    either.fromNullable(e.context),
    either.mapLeft(
      flow(
        readonlyArray.last,
        option.map(contextEntry => typeToError(contextEntry.type.name)),
        option.getOrElseW(() =>
          absurd<string>('Error context is missing name' as never),
        ),
      ),
    ),
    either.getOrElseW(identity),
  )

  const type = pipe(
    e.context,
    readonlyArray.last,
    option.map(contextEntry => contextEntry.type.name),
    option.getOrElse(() => 'unknown'),
  )

  return { message, type, path }
}

export const errorsToRecord = (errors: t.Errors): FormErrors =>
  pipe(errors, readonlyArray.map(formatError))

export const toErrorMessage = (errors: FormErrors) => errors
  .map(e => `${e.path}: ${e.message}`)
  .join('\n')
