// tslint:disable object-literal-sort-keys no-magic-numbers
import { CarTransmissionType } from 'actff-bo-lib/car'
import { DetailedFilters } from './filters'

export const detailedFiltersConfig: DetailedFilters = {
  brands: [{
    name: 'brands',
    caption: 'crmTrade.opportunities.filters.brand',
    value: {
      label: '',
      value: '',
    },
    trimLength: 2,
  }],
  opportunityType: [
    {
      name: 'fromFinance',
      caption: 'crmTrade.opportunities.filters.fromFinance',
      value: false,
    },
    {
      name: 'tradePotential',
      caption: 'crmTrade.opportunities.filters.tradePotential',
      value: false,
    },
  ],
  origin: [
    {
      name: 'own',
      caption: 'crmTrade.opportunities.filters.carOrigin.own',
      value: false,
    },
    {
      name: 'country',
      caption: 'crmTrade.opportunities.filters.carOrigin.country',
      value: false,
    },
    {
      name: 'importFilter',
      caption: 'crmTrade.opportunities.filters.carOrigin.import',
      value: false,
    },
  ],
  hasInspection: [
    {
      name: 'hasInspection',
      caption: 'crmTrade.opportunities.filters.showCars.hasInspection',
      value: false,
    },
    {
      name: 'hasNotInspection',
      caption: 'crmTrade.opportunities.filters.showCars.hasNotInspection',
      value: false,
    },
  ],
  newCarOffer: [
    {
      name: 'newCarOffer',
      caption: 'crmTrade.opportunities.filters.newCarOffer.yes',
      value: false,
    },
  ],
  opportunityDate: [
    {
      name: 'from',
      caption: 'caption.from',
      value: null,
    },
    {
      name: 'to',
      caption: 'caption.to',
      value: null,
    },
  ],
  financeEnd: [
    {
      name: 'financeEndMin',
      caption: 'caption.from',
      value: null,
    },
    {
      name: 'financeEndMax',
      caption: 'caption.to',
      value: null,
    },
  ],
  plannedSale: [
    {
      name: 'plannedSaleMin',
      caption: 'caption.from',
      value: '',
    }, {
      name: 'plannedSaleMax',
      caption: 'caption.to',
      value: '',
    },
  ],

  productionYear: [
    {
      name: 'productionYearMin',
      caption: 'caption.from',
      value: '',
    }, {
      name: 'productionYearMax',
      caption: 'caption.to',
      value: '',
    },
  ],
  mileage: [
    {
      name: 'mileageMin',
      caption: 'caption.from',
      value: '',
    },
    {
      name: 'mileageMax',
      caption: 'caption.to',
      value: '',
    },
  ],
  price: [
    {
      name: 'priceMin',
      caption: 'caption.from',
      value: '',
    },
    {
      name: 'priceMax',
      caption: 'caption.to',
      value: '',
    },
  ],
  ratings: [{
    name: 'ratings',
    caption: 'caption.from',
    value: {
      label: '',
      value: '',
    },
  }],
  models: [{
    name: 'models',
    caption: 'crmTrade.opportunities.filters.model',
    value: {
      label: '',
      value: '',
    },
  }],
  engine: [{
    name: 'engine',
    caption: 'crmTrade.opportunities.filters.engine',
    value: {
      label: 'V4000',
      value: 'V4000',
    },
  }],
  transmission: [{
    name: 'transmission',
    caption: 'crmTrade.opportunities.filters.transmission',
    options: [{
      label: 'automatyczna',
      value: CarTransmissionType.Automatic,
    }, {
      label: 'manualna',
      value: CarTransmissionType.Manual,
    }],
    value: {
      label: '',
      value: null,
    },
  }],
  version: [{
    name: 'version',
    caption: 'crmTrade.opportunities.filters.version',
    value: {
      label: '',
      value: null,
    },
    trimLength: 2,
  }],
  clientSource: [
    {
      name: 'clientSourceApplication',
      caption: 'crmTrade.opportunities.filters.clientSource.application',
      value: false,
    },
    {
      name: 'clientSourceDMS',
      caption: 'crmTrade.opportunities.filters.clientSource.DMS',
      value: false,
    },
    {
      name: 'clientSourceOutside',
      caption: 'crmTrade.opportunities.filters.clientSource.outside',
      value: false,
    },
  ],
  saleOpportunitiesCount: [
    {
      name: 'saleOpportunitiesCount0',
      caption: '0',
      value: false,
    },
    {
      name: 'saleOpportunitiesCount2',
      caption: '2',
      value: false,
    },
    {
      name: 'saleOpportunitiesCount1',
      caption: '1',
      value: false,
    },
    {
      name: 'saleOpportunitiesCount3',
      caption: '3',
      value: false,
    },
  ],
}
