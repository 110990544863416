import { parseToDateOrNull } from 'actff-bo-lib/date'
import { Map } from 'immutable'
export enum CarLeaveOptionName {
  Contact = 'CONTACT',
  D2D = 'D2D',
  LeaveCar = 'LEAVE_CAR',
  WaitInPlace = 'WAIT_IN_PLACE',
}

export type LeaveCar = {
  readonly carLeaveOptionName: CarLeaveOptionName.LeaveCar,
  readonly leaveTime: Date,
  readonly pickUpTime: Date,
}

export type WaitInPlace = {
  readonly carLeaveOptionName: CarLeaveOptionName.WaitInPlace,
  readonly visitTime: Date,
  readonly waitPeriod: number,
}

export type Contact = {
  readonly carLeaveOptionName: CarLeaveOptionName.Contact,
}

export type CarLeaveOptionAddress = {
  readonly buildNo: string,
  readonly city: string,
  readonly postCode: string,
  readonly street: string,
}

export type D2D = {
  readonly carLeaveOptionName: CarLeaveOptionName.D2D,
  readonly giveAwayTime: Date,
  readonly giveBackTime: Date,
  readonly takeAddress: CarLeaveOptionAddress
  readonly returnAddress: CarLeaveOptionAddress,
}

export type CarLeaveOption = LeaveCar | WaitInPlace | Contact | D2D

export const CarLeaveOptionNameMap: Map<CarLeaveOptionName, string> = Map([
  [CarLeaveOptionName.Contact, 'serviceRequest.carLeaveOption.contact'],
  [CarLeaveOptionName.D2D, 'serviceRequest.carLeaveOption.d2d'],
  [CarLeaveOptionName.LeaveCar, 'serviceRequest.carLeaveOption.leaveCar'],
  [CarLeaveOptionName.WaitInPlace, 'serviceRequest.carLeaveOption.waitInPlace'],
]) as Map<CarLeaveOptionName, string>

export const createAddress = (data: {} | null) => data instanceof Object
  ? data
  : { buildNo: '', city: '', postCode: '', street: '' }

const initialCarLeaveOption: Contact = {
  carLeaveOptionName: CarLeaveOptionName.Contact,
}

// tslint:disable-next-line cyclomatic-complexity
export const createCarLeaveOption = (data: any) => { // tslint:disable-line no-any
  const carLeaveOption = data instanceof Object ? data : initialCarLeaveOption

  switch (carLeaveOption.carLeaveOptionName) {
    default:
    case CarLeaveOptionName.Contact:
      return carLeaveOption

    case CarLeaveOptionName.WaitInPlace:
      return {
        ...carLeaveOption,
        visitTime: parseToDateOrNull(carLeaveOption.visitTime),
      }

    case CarLeaveOptionName.LeaveCar:
      return {
        ...carLeaveOption,
        leaveTime: parseToDateOrNull(carLeaveOption.leaveTime),
        pickUpTime: parseToDateOrNull(carLeaveOption.pickUpTime),
      }

    case CarLeaveOptionName.D2D:
      return {
        ...carLeaveOption,
        giveAwayTime: parseToDateOrNull(carLeaveOption.giveAwayTime),
        giveBackTime: parseToDateOrNull(carLeaveOption.giveBackTime),
        returnAddress: createAddress(carLeaveOption.returnAddress),
        takeAddress: createAddress(carLeaveOption.takeAddress),
      }
  }
}
