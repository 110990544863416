import { DatePickerInputField, FormHeader, InputField, RadioButtonField, SelectField, YesNoInput } from 'actff-bo-app/components/Form'
import { Loader } from 'actff-bo-app/components/Loader'
import { CarId, CarTires, ChangeType, checkIsCarRejected, selectCurrentCarTires, selectIsLoadingCarTires } from 'actff-bo-lib/car'
import { CarTiresAction } from 'actff-bo-lib/car/actions/tires'
import { CarDictionaryValue, DictionaryAction, selectTireTypes } from 'actff-bo-lib/dictionary'
import { SelectOption } from 'actff-bo-lib/global'
import { State } from 'actff-bo-lib/store'
import { Field, FormikProps, withFormik } from 'formik'
import i18next from 'i18next'
import React, { Component, ReactNode } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { OptionsType } from 'react-select'
import { compose } from 'redux'
import { boolean, mixed, object } from 'yup'

import { CarActionButtons } from '../CarActionButtons'
import { withCarViewHeader, WithCarViewHeaderProps } from '../withCarViewHeader'
import {
  ChangeType as ChangeTypeContainer,
  Changing,
  ChangingHeader,
  Container,
  Damaged,
  LastChange,
  StorageDate,
  StorageNumber,
  Storing,
  StoringHeader,
  TiresHeader,
  TireType,
} from './Styled'

type StateToProps = {
  readonly carTires: CarTires | null,
  readonly loading: boolean,
  readonly tireTypes: ReadonlyArray<SelectOption<string>>,
}

type DispatchToProps = {
  readonly getCarTires: (carId: CarId) => void,
  readonly getTireTypes: () => void,
  readonly updateCarTires: (carId: CarId, carTires: CarTires) => void,
}

type Props = FormikProps<CarTires> & WithCarViewHeaderProps & WithTranslation & StateToProps & DispatchToProps

const testId = 'car-view-tires__'

const getSelectOptions = (dictionaryItems: ReadonlyArray<SelectOption<string>>, t: i18next.TFunction)
: OptionsType<SelectOption<CarDictionaryValue>> =>
  dictionaryItems.map(item => ({ label: t(item.label), value: item.value }))

class CarViewTiresComponent extends Component<Props> {

  public componentDidMount(): void {
    this.props.getTireTypes()
    this.props.getCarTires(this.props.car.uuid)
  }

  public render(): ReactNode {
    const { car, deleteCar, errors, handleSubmit, loading, revertCar, touched, tireTypes, t } = this.props

    if (loading) {
      return <Loader />
    }

    return (
      <>
        <Container>
          <ChangingHeader>
            <FormHeader data-testid={`${testId}header`}>{t('carView.form.tires.changingHeader')}</FormHeader>
          </ChangingHeader>
          <Changing>
            <YesNoInput
              error={errors.change?.changing}
              label={t('carView.form.tires.changing')}
              name='change.changing'
              touched={touched.change?.changing}
              testId={`${testId}changing`}
            />
          </Changing>
          <ChangeTypeContainer>
            <label>{t('carView.form.tires.changeType')}</label>
            <div>
              <Field
                component={RadioButtonField}
                error={errors.change?.changeType}
                htmlFor={ChangeType.WHEELS}
                id={ChangeType.WHEELS}
                inline={true}
                label={t('carView.form.tires.wheels')}
                name='change.changeType'
                testId={`${testId}change-type--wheels`}
                touched={touched.change?.changeType}
                value={ChangeType.WHEELS}
              />
              <Field
                component={RadioButtonField}
                error={errors.change?.changeType}
                htmlFor={ChangeType.TIRES}
                id={ChangeType.TIRES}
                inline={true}
                label={t('carView.form.tires.tires')}
                name='change.changeType'
                testId={`${testId}change-type--tires`}
                touched={touched.change?.changeType}
                value={ChangeType.TIRES}
              />
            </div>
          </ChangeTypeContainer>
          <TiresHeader>
            <FormHeader data-testid={`${testId}tires-header`}>{t('carView.form.tires.tiresHeader')}</FormHeader>
          </TiresHeader>
          <TireType>
            <Field
              component={SelectField}
              error={errors.change?.tireType}
              label={t('carView.form.tires.tireType')}
              name='change.tireType'
              options={getSelectOptions(tireTypes, t)}
              testId={`${testId}tires-type`}
              touched={touched.change?.tireType}
            />
          </TireType>
          <LastChange>
            <Field
              component={DatePickerInputField}
              error={errors.change?.lastChange}
              label={t('carView.form.tires.lastChange')}
              name='change.lastChange'
              testId={`${testId}last-change`}
              touched={touched.change?.lastChange}
            />
          </LastChange>
          <StoringHeader>
            <FormHeader data-testid={`${testId}storing-header`}>{t('carView.form.tires.storingHeader')}</FormHeader>
          </StoringHeader>
          <Storing>
            <label>{t('carView.form.tires.storing')}</label>
            <YesNoInput name='storage.storing' testId={`${testId}storage--storing`} />
          </Storing>
          <StorageDate>
            <Field
              component={DatePickerInputField}
              error={errors.storage?.storageDate}
              label={t('carView.form.tires.storageDate')}
              name='storage.storageDate'
              testId={`${testId}storage--date`}
              touched={touched.storage?.storageDate}
            />
          </StorageDate>
          <StorageNumber>
            <Field
              component={InputField}
              error={errors.storage?.storageNumber}
              label={t('carView.form.tires.storageNumber')}
              name='storage.storageNumber'
              testId={`${testId}storage--number`}
              touched={touched.storage?.storageNumber}
              type='text'
            />
          </StorageNumber>
          <Damaged>
            <YesNoInput
              error={errors.storage?.damaged}
              label={t('carView.form.tires.damaged')}
              name='storage.damaged'
              testId={`${testId}storage--damaged`}
              touched={touched.storage?.damaged}
            />
          </Damaged>
        </Container>
        <CarActionButtons
          carId={car.uuid}
          inRejectedMode={checkIsCarRejected(car)}
          onAccept={handleSubmit}
          onDelete={deleteCar}
          onRevert={revertCar}
          testId={testId}
        />
      </>
    )
  }
}

// tslint:disable:object-literal-sort-keys
const validationSchema = () => object<CarTires>().shape({
  change: object().shape({
    changing: boolean().required(),
    changeType: mixed()
      .when('changing', {
        is: true,
        then: mixed().required(),
      }),
    tireType: mixed()
      .when('changing', {
        is: true,
        then: mixed().required(),
      }),
    lastChange: mixed()
      .when('changing', {
        is: true,
        then: mixed().required(),
      }),
  }),
  storage: object().shape({
    storing: boolean().required(),
    storageDate: mixed()
      .when('storing', {
        is: true,
        then: mixed().required(),
      }),
    storageNumber: mixed()
      .when('storing', {
        is: true,
        then: mixed().required(),
      }),
  }),
})

const initialValues: CarTires = ({
  change: {
    changing: false,
    changeType: null,
    tireType: null,
    lastChange: null,
  },
  storage: {
    storing: false,
    storageDate: null,
    storageNumber: '',
    damaged: false,
  },
})

const formik = withFormik({
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    props.updateCarTires(props.car.uuid, values)
  },
  mapPropsToValues: (({ carTires }: Props) => carTires || initialValues),
  validationSchema,
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  carTires: selectCurrentCarTires(state),
  loading: selectIsLoadingCarTires(state),
  tireTypes: selectTireTypes(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getCarTires: (carId: CarId) => { dispatch(CarTiresAction.getCarTires(carId)) },
  getTireTypes: () => { dispatch(DictionaryAction.getTireTypes()) },
  updateCarTires: (carId: CarId, carTires: CarTires) => { dispatch(CarTiresAction.updateCarTires({ carId, ...carTires})) },
})

export const CarViewTires = compose(
  withCarViewHeader,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  formik,
)(CarViewTiresComponent)
