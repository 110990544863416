import { ExpandCollapse } from 'actff-bo-app/components/ExpandCollapse'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BtnType } from './Button'

type ExpandCollapseButtonProps = Testable & {
  readonly expanded: boolean,
  readonly onClick: () => void,
}

const Button = styled.button`
  justify-self: right;
`

export const ExpandCollapseButton: FC<ExpandCollapseButtonProps> = ({ expanded, onClick, testId }) => {
  const { t } = useTranslation()

  return (
    <Button data-testid={`${testId}expand-collapse-button`} type={BtnType.Button} onClick={onClick}>
      {expanded ? t('caption.collapse') : t('caption.expand')}
      <ExpandCollapse expanded={expanded}/>
    </Button>
  )
}
