import { getServiceName, Service } from 'actff-bo-lib/service'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { H2 } from 'styles'

type Props = {
  readonly service?: Service,
}

export const OpportunityTitle: FC<Props> = ({ service }) => {
  const { t } = useTranslation()

  return (
    <H2>{t('opportunity.title')}: {service && getServiceName(service, t)}</H2>
  )
}
