import { FormValues } from 'actff-bo-lib/form/dto'
import { some } from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/function'

export const hasSelectedFormValues = (formValues: FormValues | undefined): boolean =>
  pipe(
    formValues,
    Object.values,
    some(a => !!a),
  )
