import { ClientEmail, ClientFullName, ClientPhone } from 'actff-bo-app/components/Client'
import { ContactInfoStyled, EmailIcon } from 'actff-bo-app/components/Client/Styled'
import { Icon } from 'actff-bo-app/components/Icon'
import { Loader } from 'actff-bo-app/components/Loader'
import { Overlay } from 'actff-bo-app/components/Overlay'
import {
  CarAction,
  CarId,
  CarInfoWithClient,
  checkIsCarRejected,
  OwnershipAction,
  selectCurrentCarData,
  selectIsLoadingCar,
  selectIsLoadingOwnedCar,
  selectIsNdcCar,
} from 'actff-bo-lib/car'
import { defaultDateTimeFormat } from 'actff-bo-lib/date'
import { getLocale } from 'actff-bo-lib/date/locales'
import { Testable } from 'actff-bo-lib/global/testable'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State } from 'actff-bo-lib/store'
import { selectUserPermissions } from 'actff-bo-lib/user'
import { format } from 'date-fns'
import React, { FC } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import { colors, H2 } from 'styles'

import { CarViewTabs } from './CarViewTabs'
import { CarViewContainer, CarViewCreatedDateStyled, CarViewHeaderStyled, NdcCarViewHeaderStyled } from './Styled'

type DispatchToProps = {
  readonly deleteCar: (carId: CarId) => void,
  readonly getCar: (carId: CarId) => void,
  readonly getNdcCar: (vin: string) => void,
  readonly rejectCar: (carId: CarId) => void,
  readonly resetGetOwnedCarFetchFailed: () => void,
  readonly revertCar: (carId: CarId) => void,
  readonly setIsNdcCar: (value: boolean) => void,
}

type StateToProps = {
  readonly currentLanguage: Language,
  readonly car: CarInfoWithClient | null,
  readonly isNdcCar: boolean,
  readonly isLoadingCar: boolean,
  readonly isLoadingNdcCar: boolean,
}

type CarViewParams = {
  readonly carId: string,
  readonly vin: string,
}

type CarViewHeaderProps = {
  readonly car: CarInfoWithClient,
  readonly locale: {},
  readonly isNdcCar: boolean,
}

type CarViewProps = Testable & StateToProps & DispatchToProps & RouteComponentProps<CarViewParams> & WithTranslation

export type WithCarViewHeaderProps = DispatchToProps & RouteComponentProps<CarViewParams> & {
  readonly car: CarInfoWithClient,
}

const testId = 'car-view__'

const CarViewHeader: FC<CarViewHeaderProps> = ({ car, locale, isNdcCar }) => isNdcCar ?
  <NdcCarViewHeaderStyled /> : (
    <CarViewHeaderStyled>
      <H2 data-testid={`${testId}header`}>
        <ClientFullName client={car.client} />&nbsp;
        <Icon type={IconType.Car} />&nbsp;
        <small>{car.alias}</small>
      </H2>
      <CarViewCreatedDateStyled>{format(car.created, defaultDateTimeFormat, { locale })}</CarViewCreatedDateStyled>
      <ContactInfoStyled>
        <Icon type={IconType.Phone} />
        <ClientPhone client={car.client} data-testid={`${testId}header--client-phone`} />
        <EmailIcon type={IconType.Email} />
        <ClientEmail client={car.client} data-testid={`${testId}header--client-email`} />
      </ContactInfoStyled>
    </CarViewHeaderStyled>
  )

const WithCarViewHeader = (Component: React.ComponentType<CarViewProps>) => (
  class extends React.Component<CarViewProps> {

    private get isLoading(): boolean {
      const { isLoadingCar, isLoadingNdcCar } = this.props

      return isLoadingCar || isLoadingNdcCar
    }

    public componentDidMount(): void {
      this.props.resetGetOwnedCarFetchFailed()
      if (this.isNdcCar()) {
        this.getNdcCar()
        this.props.setIsNdcCar(true)

        return
      }

      this.getCar()
      this.props.setIsNdcCar(false)
    }

    public UNSAFE_componentWillReceiveProps(newProps: CarViewProps): void {
      if (this.isNdcCar() && this.getCarVin(this.props) !== this.getCarVin(newProps)) {
        this.getNdcCar()

        return
      }

      if (this.getCarId(this.props) !== this.getCarId(newProps)) {
        this.getCar()
      }
    }

    public render(): React.ReactNode {
      const { currentLanguage, car } = this.props
      const isNdcCar = this.isNdcCar()
      const locale = getLocale(currentLanguage)

      if (this.isLoading) {
        return <Loader />
      }

      if (!car) {
        return null
      }

      return (
        <CarViewContainer>
          {checkIsCarRejected(car) && <Overlay color={colors.dark} height='calc(100% - 65px)' opacity='0.5' position='absolute' />}
          <CarViewHeader car={car} locale={locale} isNdcCar={isNdcCar} />
          <CarViewTabs car={car} isNdcCar={isNdcCar} testId={`${testId}tabs`} />
          <Component {...this.props} />
        </CarViewContainer>
      )
    }

    private readonly getCarId = (props: CarViewProps) => props.match.params.carId as CarId
    private readonly getCarVin = (props: CarViewProps) => props.match.params.vin
    private readonly getCar = () => { this.props.getCar(this.getCarId(this.props)) }
    private readonly getNdcCar = () => { this.props.getNdcCar(this.getCarVin(this.props)) }
    private readonly isNdcCar = () => this.props.match.path.includes('cars/ndc/')
  }
)

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  // TODO finish when API will be ready
  deleteCar: () => {
    // dispatch(CarAction.deleteCar(carId))
  },
  getCar: (carId: CarId) => { dispatch(CarAction.getCar(carId)) },
  getNdcCar: (vin: string) => { dispatch(CarAction.getNdcCar(vin)) },
  rejectCar: (carId: CarId) => { dispatch(CarAction.rejectCar(carId)) },
  resetGetOwnedCarFetchFailed: () => { dispatch(OwnershipAction.resetGetOwnedCarFetchFailed()) },
  revertCar: (carId: CarId) => { dispatch(CarAction.revertCar(carId)) },
  setIsNdcCar: (value: boolean) => { dispatch(CarAction.setIsNdcCar(value)) },
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  car: selectCurrentCarData(state),
  currentLanguage: selectCurrentLanguage(state),
  isLoadingCar: selectIsLoadingCar(state),
  isLoadingNdcCar: selectIsLoadingOwnedCar(state),
  isNdcCar: selectIsNdcCar(state),
  userPermissions: selectUserPermissions(state),
})

export const withCarViewHeader = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  WithCarViewHeader,
)
