import { Testable } from 'actff-bo-lib/global/testable'
import { Path } from 'actff-bo-lib/menu'
import * as React from 'react'
import styled from 'styled-components'
import { theme } from 'styles'

type NavigationItemContainer = Testable & {
  readonly active: boolean,
  readonly menuItemPath: Path,
  readonly toggleNavigationItem: (path: Path) => () => void,
}

const NavigationItemContainerStyled = styled.li`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4fr 1fr;
  min-height: 48px;
  padding: 0 32px;
  position: relative;

  img {
    opacity: 0.5;
  }
`

const NavigationItemContainerActiveStyled = styled(NavigationItemContainerStyled)`
  background: ${theme.menu.background.active};
  color: ${theme.menu.text.active};

  img {
    opacity: 1;
  }

  &:before {
    background: ${theme.menu.indicator};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }
`

export const NavigationItemContainer: React.FC<NavigationItemContainer> = ({
  active,
  children,
  menuItemPath,
  testId,
  toggleNavigationItem,
}) => {
  const ItemContainer = active ? NavigationItemContainerActiveStyled : NavigationItemContainerStyled

  return (<ItemContainer data-testid={testId} onClick={toggleNavigationItem(menuItemPath)}>{children}</ItemContainer>)
}
