import { api, axiosApi } from 'actff-bo-lib/api'
import { Observable } from 'rxjs'

import { User, UserDto, UserId, UserLocationsDto, UserPasswordDto } from './dto'

export const getUser = (id: UserId): Observable<User> => api.get(`/user/${id}`)

export const addUser = (user: UserDto) => axiosApi.post<UserDto, UserDto>('/user', user)

export const changeUserPassword = (userPasswordDto: UserPasswordDto) =>
  axiosApi.patch<User, UserPasswordDto>('/user', userPasswordDto)

export const deleteUser = (userId: UserId) => axiosApi.delete<ReadonlyArray<User>, UserId>(`/user/${userId}`)

export const getUsers = (): Observable<ReadonlyArray<User>> => api.get('/user')

export const getUsersWithAxios = () => axiosApi.get<ReadonlyArray<User>>('/user')

export const updateUsersWithAxios = (users: ReadonlyArray<UserDto>) =>
  axiosApi.patch<ReadonlyArray<User>, ReadonlyArray<UserDto>>('/user', users)

export const updateUsersLocations = (users: ReadonlyArray<UserLocationsDto>, authorId: UserId) =>
  axiosApi.patch<ReadonlyArray<User>, ReadonlyArray<UserLocationsDto>>(`/user/${authorId}/locations`, users)
