import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import styled from 'styled-components'
import { MinorButton as MinorButtonStyled } from 'styles'

import { Icon } from '../Icon'
import { BtnTheme, BtnType } from './Button'

type Props = Testable & {
  readonly caption: string,
  readonly iconType?: IconType,
  readonly className?: string,
  readonly onClick?: () => void,
  readonly type?: BtnType,
  readonly theme?: BtnTheme,
}

const ButtonIcon = styled(Icon)`
  margin-right: 5px;
`

export const MinorButton: React.FC<Props> = ({ caption, className, onClick, iconType, testId }) => (
  <MinorButtonStyled className={className} onClick={onClick} type={BtnType.Button} data-testid={testId}>
    {iconType && <ButtonIcon type={iconType} />}
    {caption}
  </MinorButtonStyled>
)
