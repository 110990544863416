import { reactSelectStyles } from 'actff-bo-app/components/Form'
import { ReportColumns } from 'actff-bo-lib/crm/insurance/dto/report'
import { SelectOption } from 'actff-bo-lib/global'
import rowGrouper from 'lodash/groupBy'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDataGrid, { Filters, SortDirection } from 'react-data-grid'
// tslint:disable-next-line: no-import-side-effect
import 'react-data-grid/dist/react-data-grid.css'
import { useTranslation } from 'react-i18next'
import Select, { OptionsType, ValueType } from 'react-select'
import styled from 'styled-components'
import { DataGridGroupLabel, DataGridTheme, theme } from 'styles'

type Props<R, S> = {
  readonly cols: ReportColumns<R, S>,
  readonly filters?: Filters,
  readonly onFiltersChange?: (filters: Filters) => void,
  readonly printMode: boolean,
  readonly rows: ReadonlyArray<R>,
  readonly summaryRows?: ReadonlyArray<S>,
}

const GridGroupSelect = styled(Select)`
  color: ${theme.text.primary};
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  outline: none;
`

const generateGroupByIds = <T extends unknown>(
  _rows: ReadonlyArray<T>,
  groupByArr: ReadonlyArray<string> | undefined,
  ids: ReadonlyArray<string>,
): ReadonlyArray<string> => {
  if (groupByArr && groupByArr.length > 0) {
    const generatedIds = _rows.map(
      row => groupByArr?.reduce(
        (prev, curr, idx) => `${prev}${row[curr]}${idx < (groupByArr.length - 1) ? '__' : ''}`,
      ''),
    )
    const slicedGroupByArr = groupByArr.slice(0, groupByArr.length - 1)

    return generateGroupByIds(_rows, slicedGroupByArr, [...ids, ...generatedIds])
  }

  return ids
}

export function DataGrid<R, S>({
  cols,
  filters,
  onFiltersChange,
  printMode,
  rows,
  summaryRows,
}: Props<R, S>): React.ReactElement {
  const rowKeyGetter = (row: R & { readonly id: number }) => row.id
  const [selectedRows, setSelectedRows] = useState(() => new Set<React.Key>())
  const [selectedOptions, setSelectedOptions] = useState<ValueType<SelectOption<string>, true>>([])
  const [expandedByUserGroupIds, setExpandedByUserGroupIds] = useState(() => new Set<unknown>([]))
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set<unknown>([]))
  const [[sortColumn, sortDirection], setSort] = useState<[string, SortDirection]>([cols[0]?.key || '', 'ASC'])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const groupBy = useMemo(() =>
    Array.isArray(selectedOptions) ? selectedOptions.map((o: SelectOption<string>) => o.value) : undefined,
    [selectedOptions],
  )

  const groupsToExpand = new Set(generateGroupByIds(rows, groupBy, []))

  useEffect(() => {
    if (printMode) {
      if (groupsToExpand.size !== expandedGroupIds.size) {
        setExpandedGroupIds(groupsToExpand)
      }
    } else {
      setExpandedGroupIds(expandedByUserGroupIds)
    }
  }, [printMode, rows, expandedGroupIds, expandedByUserGroupIds, groupBy, groupsToExpand])

  const handleSetExpandedGroupIds = (ids: Set<string>) => {
    setExpandedByUserGroupIds(ids)
    setExpandedGroupIds(ids)
  }

  const noOptionsMessage = () => t('caption.noMoreOptions')
  const rowHeight = 35
  const summaryRowHeight = 50
  const headerRowHeight = 35

  const calculateHeight = () =>
    printMode ?
        + headerRowHeight
        + rows.length * rowHeight
        + expandedGroupIds.size * rowHeight
        + summaryRowHeight
      : 'auto'

  const sortedRows = useMemo(() => {
    if (sortDirection === 'NONE') {
      return rows
    }

    const sorted = [...rows].sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]))

    return sortDirection === 'DESC' ? sorted.reverse() : sorted

  }, [rows, sortDirection, sortColumn])

  const handleSort = useCallback((columnKey: string, direction: SortDirection) => {
    setSort([columnKey, direction])
  }, [])

  return (
    <DataGridTheme ref={wrapperRef}>
      {!printMode &&
        <DataGridGroupLabel>
          <GridGroupSelect
            isMulti={true}
            value={selectedOptions}
            onChange={(selected: ReadonlyArray<SelectOption<string>>) => {
              setSelectedOptions(selected)
              setExpandedGroupIds(new Set())
            }}
            options={cols.map(({ key, name }) => ({ value: key, label: name })) as OptionsType<SelectOption<string>>}
            styles={{
              ...reactSelectStyles,
              control: (provided: object) => ({
                ...provided,
                height: 40,
              }),
            }}
            placeholder={t('placeholder.gridGropSelect')}
            noOptionsMessage={noOptionsMessage}
            closeMenuOnSelect={false}
          />
        </DataGridGroupLabel>
      }
      <ReactDataGrid
        className={`rdg-light ${printMode && 'rdg-print-mode'}`}
        columns={cols}
        defaultColumnOptions={{ resizable: true, sortable: true }}
        enableFilterRow={!printMode}
        expandedGroupIds={expandedGroupIds}
        filters={filters}
        groupBy={groupBy}
        headerRowHeight={headerRowHeight}
        onExpandedGroupIdsChange={handleSetExpandedGroupIds}
        onFiltersChange={onFiltersChange}
        onSelectedRowsChange={setSelectedRows}
        onSort={handleSort}
        rowGrouper={rowGrouper}
        rowHeight={rowHeight}
        rowKeyGetter={rowKeyGetter}
        rows={sortedRows}
        selectedRows={selectedRows}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        style={{ height: calculateHeight() }}
        summaryRows={summaryRows}
      />
    </DataGridTheme>
  )
}
