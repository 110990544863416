import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { DictionaryType } from './dto'

/*eslint no-redeclare: 0*/
export enum DictionaryActionType {
  GetFuelTypes = '[DICTIONARY] GET FUEL TYPES',
  GetFuelTypesFailure = '[DICTIONARY] GET FUEL TYPES FAILURE',
  GetFuelTypesSuccess = '[DICTIONARY] GET FUEL TYPES SUCCESS',

  GetTireTypes = '[DICTIONARY] GET TIRE TYPES',
  GetTireTypesFailure = '[DICTIONARY] GET TIRE TYPES FAILURE',
  GetTireTypesSuccess = '[DICTIONARY] GET TIRE TYPES SUCCESS',
}

export const DictionaryAction = {
  getFuelTypes: () => createAction(DictionaryActionType.GetFuelTypes),
  getFuelTypesFailure: (error: AjaxError) => createAction(DictionaryActionType.GetFuelTypesFailure, error),
  getFuelTypesSuccess: (fuelTypes: ReadonlyArray<DictionaryType<string>>) =>
    createAction(DictionaryActionType.GetFuelTypesSuccess, fuelTypes),

  getTireTypes: () => createAction(DictionaryActionType.GetTireTypes),
  getTireTypesFailure: (error: AjaxError) => createAction(DictionaryActionType.GetTireTypesFailure, error),
  getTireTypesSuccess: (tireTypeList: ReadonlyArray<DictionaryType<string>>) =>
    createAction(DictionaryActionType.GetTireTypesSuccess, tireTypeList),
}

export type DictionaryAction = ActionsUnion<typeof DictionaryAction>
