import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { ClientPersonalData, ClientPersonalDataVersion } from 'actff-bo-lib/client'
import { defaultShortDateFormat } from 'actff-bo-lib/date'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

import { PersonalDataChanger } from './PersonalDataChanger'
import { PersonalDataChangeState } from './PersonalDataChangeState'

export type PersonalDataType = keyof Pick<ClientPersonalDataVersion, 'firstName' | 'lastName' | 'email'>

type PersonalDataGroupProps = {
  readonly personalDataHistory: ClientPersonalData,
  readonly personalDataType: PersonalDataType,
}

type PersonalDataChangeDateProps = {
  readonly date: Date,
}

type IndicatorProps = {
  readonly first: boolean,
}

const PersonalDataGroupStyled = styled.div`
  border-bottom: 1px solid ${colors.solitude};
  display: grid;
  padding: 16px 0;
  grid-template-areas: 'indicator content';
  justify-content: left;
  align-items: baseline;
  grid-column-gap: 8px;

  &:last-child {
    border-bottom: none;
  }
`

const PersonalDataGroupSubheader = styled.span`
  color: ${colors.dustyGray};
  font-weight: 700;
  text-transform: uppercase;
  padding: 12px 0 0;
`

const PersonalDataHeaderIndicator = styled.div`
  background: ${colors.white};
  border-radius: 100px;
  border: 2px solid;
  border-color: ${({ first }: IndicatorProps) => first ? colors.kumera : colors.nepalBlue};
  grid-area: indicator;
  height: 8px;
  margin-top: 5px;
  width: 8px;
`

const PersonalDataHeaderContent = styled.div`
  display: grid;
  grid-auto-flow: row;
`

const PersonalDataHeaderContentItem = styled.div`
  align-items: baseline;
  display: grid;
  grid-auto-flow: column;
  grid-template-areas: 'indicator content';
  grid-column-gap: 5px;
  justify-content: left;
`

const FormattedDateStyled = styled.span`
  font-size: 13px;
  font-weight: 600;
`

const ChangedValue = styled.span`
  font-size: 13px;
  font-weight: 600;
`

const ChangeDate: React.FC<PersonalDataChangeDateProps> = ({ date }) => (
  <FormattedDateStyled><FormattedDate date={date} format={defaultShortDateFormat} />:</FormattedDateStyled>
)

const ChangeTime: React.FC<PersonalDataChangeDateProps> = ({ date }) => (
  <span><FormattedDate date={date} format='HH:mm' /></span>
)

export const PersonalDataGroup: React.FC<PersonalDataGroupProps> = ({ personalDataHistory, personalDataType }) => {
  const { t } = useTranslation()

  return (
    <>
      {personalDataType &&
        <PersonalDataGroupSubheader>
          {t(`clientView.personalData.${personalDataType}`)}
        </PersonalDataGroupSubheader>
      }
      {personalDataHistory.map((personalData, index) => {
        const previousValue = personalDataHistory[index + 1] && personalDataHistory[index + 1][personalDataType]
        const currentValue = personalData[personalDataType]

        return previousValue !== currentValue && (
          <PersonalDataGroupStyled key={currentValue + index}>
            <PersonalDataHeaderContent>
              <PersonalDataHeaderContentItem>
                <PersonalDataHeaderIndicator first={!previousValue} />
                <ChangeDate date={personalData.from} />
                <PersonalDataChangeState first={!previousValue} />
                <ChangedValue>{personalData[personalDataType]}</ChangedValue>
              </PersonalDataHeaderContentItem>
              <PersonalDataHeaderContentItem>
                <ChangeTime date={personalData.from} />
                <PersonalDataChanger personalData={personalData} />
              </PersonalDataHeaderContentItem>
            </PersonalDataHeaderContent>
          </PersonalDataGroupStyled>
        )
      })}
    </>
  )
}
