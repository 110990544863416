import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { EditSaveActionButton, YesNoInput } from 'actff-bo-app/components/Form'
import { ContactApproval, ContactApprovalType } from 'actff-bo-lib/client'
import { createRoute, IconType, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { FormikProps } from 'formik'
import i18next from 'i18next'
import * as React from 'react'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { H2, PrimaryButton } from 'styles'

import { ApprovalContentPopup } from './ApprovalContentPopup'
import { ApprovalsHistory } from './ApprovalsHistory'
import { ApprovalType } from './ApprovalType'
import {
  Approval,
  ApprovalsContainer,
  ContactApprovalsContactHeader,
  ContactApprovalsFooter,
  ContactApprovalsForm,
  ContactApprovalsRegistrationHeader,
  DownloadButtonIcon,
  DownloadButtonStyled,
  SeeContactApprovalsHistoryStyled,
  SeeRegistrationApprovalsHistoryStyled,
} from './styled'
import { ApprovalsFormProps, ApprovalsFormValues, formik } from './withFormik'

// tslint:disable max-file-line-count
type State = {
  readonly areCommunicationApprovalsEditable: boolean,
  readonly areRegistrationApprovalsEditable: boolean,
  readonly isApprovalPreviewVisible: boolean,
  readonly currentApproval: ContactApproval | null,
  readonly currentApprovalType: ContactApprovalType,
}

type ApprovalsFormComponentProps = ApprovalsFormProps & FormikProps<ApprovalsFormValues> & WithTranslation

enum ApprovalsGroup {
  CommunicationApprovals,
  RegistrationApprovals,
}

const testId = 'client-view-approvals-form__'

const SeeContactApprovalsHistory: React.FC = () => {
  const { t } = useTranslation()

  return (
    <SeeContactApprovalsHistoryStyled>
      <label>{t('clientView.approvals.history')}</label>
      <ButtonWithIcon
        caption={t('clientView.approvals.seeHistory')}
        iconType={IconType.DropdownGold}
        noPadding={true}
        theme={BtnTheme.Link}
        testId={`${testId}see-history`}
      />
    </SeeContactApprovalsHistoryStyled>
  )
}

const DownloadButton = (t: i18next.TFunction) => () => (
  <DownloadButtonStyled type='button'>
    <DownloadButtonIcon type={IconType.DownloadGold} data-testid={`${testId}download-button`} />
    <span>{t('clientView.approvals.fetchChangesHistory')}</span>
  </DownloadButtonStyled>
)

// TODO refactor to smaller file
export class ApprovalsFormComponent extends React.Component<ApprovalsFormComponentProps> {
  public readonly state: State = {
    areCommunicationApprovalsEditable: false,
    areRegistrationApprovalsEditable: false,
    currentApproval: null,
    currentApprovalType: ContactApprovalType.pushNotifications,
    isApprovalPreviewVisible: false,
  }

  public UNSAFE_componentWillReceiveProps(newProps: ApprovalsFormComponentProps): void {
    if (newProps.values !== this.props.values) {
      this.toggleGeneralApproval(newProps)
    }
  }

  public render(): React.ReactNode {
    const { approvals, errors, submitForm, t } = this.props
    const {
      areCommunicationApprovalsEditable,
      areRegistrationApprovalsEditable,
      isApprovalPreviewVisible,
      currentApproval,
      currentApprovalType,
    } = this.state

    return (
      <ContactApprovalsForm onSubmit={submitForm}>
        <ApprovalContentPopup
          currentApproval={currentApproval}
          open={isApprovalPreviewVisible}
          onClose={this.hideApprovalPreview}
          header={t(`clientView.approvals.${[currentApprovalType || ContactApprovalType.pushNotifications]}`)}
        />
        <ApprovalsContainer>
          <ContactApprovalsContactHeader>
            <H2>{t('clientView.approvals.formsOfContact')}</H2>
            <ApprovalsHistory historyDownloadTrigger={DownloadButton(t)} />
            <EditSaveActionButton
              disabled={Object.keys(errors).length > 0}
              onEdit={this.toggleEditable(ApprovalsGroup.CommunicationApprovals)}
              onSave={this.saveAndToggleEditable(ApprovalsGroup.CommunicationApprovals)}
              isInEditMode={areCommunicationApprovalsEditable}
              testId={`${testId}edit-save`}
            />
          </ContactApprovalsContactHeader>
          {/* PUSH NOTIFICATIONS */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(approvals.marketingOffers.pushNotifications[0], ContactApprovalType.pushNotifications)}
              icon={IconType.Ring}
              header={t('clientView.approvals.pushNotifications')}
              info={t('clientView.approvals.pushNotificationAgreement')}
              testId={`${testId}approval-type--push-notifications`}
            />
            <YesNoInput
              disabled={!areCommunicationApprovalsEditable}
              error={errors.pushMarketingOfferAgree}
              label={t('clientView.approvals.marketingOffers')}
              name='pushMarketingOfferAgree'
              testId={`${testId}push-marketing-offer-agree`}
            />
            <YesNoInput
              disabled={!areCommunicationApprovalsEditable}
              error={errors.pushTradeOfferAgree}
              label={t('clientView.approvals.tradeOffers')}
              name='pushTradeOfferAgree'
              testId={`${testId}push-trade-offer-agree`}
            />
            <SeeContactApprovalsHistory />
          </Approval>
          {/* SMS NOTIFICATIONS */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(approvals.marketingOffers.sms[0], ContactApprovalType.sms)}
              icon={IconType.Sms}
              header={t('clientView.approvals.sms')}
              info={t('clientView.approvals.smsAgreement')}
              testId={`${testId}approval-type--sms`}
            />
            <YesNoInput
              disabled={!areCommunicationApprovalsEditable}
              error={errors.smsMarketingOfferAgree}
              label={t('clientView.approvals.marketingOffers')}
              name='smsMarketingOfferAgree'
              testId={`${testId}sms-marketing-offer-agree`}
            />
            <YesNoInput
              disabled={!areCommunicationApprovalsEditable}
              error={errors.smsTradeOfferAgree}
              label={t('clientView.approvals.tradeOffers')}
              name='smsTradeOfferAgree'
              testId={`${testId}sms-trade-offer-agree`}
            />
            <SeeContactApprovalsHistory />
          </Approval>
          {/* EMAIL NOTIFICATIONS */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(approvals.marketingOffers.email[0], ContactApprovalType.email)}
              icon={IconType.EmailEnvelope}
              header={t('clientView.approvals.email')}
              info={t('clientView.approvals.emailAgreement')}
              testId={`${testId}approval-type--push-notifications`}
            />
            <YesNoInput
              disabled={!areCommunicationApprovalsEditable}
              error={errors.emailMarketingOfferAgree}
              label={t('clientView.approvals.marketingOffers')}
              name='emailMarketingOfferAgree'
              testId={`${testId}email-marketing-offer-agree`}
            />
            <YesNoInput
              disabled={!areCommunicationApprovalsEditable}
              error={errors.emailTradeOfferAgree}
              label={t('clientView.approvals.tradeOffers')}
              name='emailTradeOfferAgree'
              testId={`${testId}email-trade-offer-agree`}
            />
            <SeeContactApprovalsHistory />
          </Approval>
        </ApprovalsContainer>
        <ApprovalsContainer>
          <ContactApprovalsRegistrationHeader>
            <H2>{t('clientView.approvals.registrationApproval')}</H2>
            <EditSaveActionButton
              disabled={Object.keys(errors).length > 0}
              onEdit={this.toggleEditable(ApprovalsGroup.RegistrationApprovals)}
              onSave={this.saveAndToggleEditable(ApprovalsGroup.RegistrationApprovals)}
              isInEditMode={areRegistrationApprovalsEditable}
              testId={`${testId}edit-save`}
            />
          </ContactApprovalsRegistrationHeader>
          {/* PERSONAL DATA */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(approvals.personalData.general[0], ContactApprovalType.personalData)}
              header={t('clientView.approvals.personalData')}
              testId={`${testId}approval-type--personal-data`}
            />
            <YesNoInput
              disabled={!areRegistrationApprovalsEditable}
              name='personalDataAgree'
              testId={`${testId}personal-data-agree`}
            />
            <SeeRegistrationApprovalsHistoryStyled>
              <SeeContactApprovalsHistory />
            </SeeRegistrationApprovalsHistoryStyled>
          </Approval>
          {/* TRADE OFFERS */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(approvals.tradeOffers.general[0], ContactApprovalType.tradeOffers)}
              header={t('clientView.approvals.tradeInformation')}
              testId={`${testId}approval-type--trade-information`}
            />
            <YesNoInput
              disabled={true}
              name='tradeInformationAgree'
              testId={`${testId}trade-information-agree`}
            />
            <SeeRegistrationApprovalsHistoryStyled>
              <SeeContactApprovalsHistory />
            </SeeRegistrationApprovalsHistoryStyled>
          </Approval>
          {/* MARKETING OFFERS */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(approvals.marketingOffers.general[0], ContactApprovalType.marketingOffers)}
              header={t('clientView.approvals.marketingInformation')}
              testId={`${testId}approval-type--marketing-information`}
            />
            <YesNoInput
              disabled={true}
              name='marketingInformationAgree'
              testId={`${testId}marketing-information-agree`}
            />
            <SeeRegistrationApprovalsHistoryStyled>
              <SeeContactApprovalsHistory />
            </SeeRegistrationApprovalsHistoryStyled>
          </Approval>
          {/* PROFILE */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(approvals.profile && approvals.profile.general[0], ContactApprovalType.profile)}
              header={t('clientView.approvals.profile')}
              testId={`${testId}approval-type--profile`}
            />
            <YesNoInput
              disabled={!areRegistrationApprovalsEditable}
              name='profileAgree'
              testId={`${testId}profile-agree`}
            />
            <SeeRegistrationApprovalsHistoryStyled>
              <SeeContactApprovalsHistory />
            </SeeRegistrationApprovalsHistoryStyled>
          </Approval>
          {/* TERMS AND CONDITIONS */}
          <Approval>
            <ApprovalType
              onReadClick={this.showApprovalPreview(
                approvals.termsAndConditions && approvals.termsAndConditions.general[0],
                ContactApprovalType.termsAndConditions)
              }
              header={t('clientView.approvals.termsAndConditions')}
              testId={`${testId}approval-type--terms-and-conditions`}
            />
            <YesNoInput
              disabled={!areRegistrationApprovalsEditable}
              name='termsAndConditionsAgree'
              testId={`${testId}terms-and-conditions-agree`}
            />
            <SeeRegistrationApprovalsHistoryStyled>
              <SeeContactApprovalsHistory />
            </SeeRegistrationApprovalsHistoryStyled>
          </Approval>
        </ApprovalsContainer>
        {/* FOOTER */}
        <FixedFooter>
          <ContactApprovalsFooter>
            <ButtonWithIcon
              type={BtnType.Button}
              onClick={this.handleResetForm}
              caption={t('caption.undoChanges')}
              iconType={IconType.Undo}
              theme={BtnTheme.Link}
              testId={`${testId}reset-form`}
            />
            <PrimaryButton type={BtnType.Button} onClick={this.handleRowClick} data-testid={`${testId}back-to-list`}>
              {t('caption.backToList')}
            </PrimaryButton>
          </ContactApprovalsFooter>
        </FixedFooter>
      </ContactApprovalsForm>
    )
  }

  private readonly showApprovalPreview = (approval: ContactApproval, type: ContactApprovalType) => () => {
    this.setState({
      ...this.state,
      currentApproval: approval,
      currentApprovalType: type,
      isApprovalPreviewVisible: true,
    })
  }

  private readonly hideApprovalPreview = (): void => {
    this.setState({
      ...this.state,
      currentApproval: null,
      isApprovalPreviewVisible: false,
    })
  }

  private readonly handleRowClick = () => {
    history.push(createRoute(Paths.ClientList))
  }

  private readonly handleResetForm = () => {
    this.props.resetForm()
  }

  private readonly toggleGeneralApproval = (props: ApprovalsFormComponentProps): void => {
    const { values } = props

    this.allMarketingOfferChannelsDisabled(values)
      ? props.setFieldValue('marketingInformationAgree', false)
      : props.setFieldValue('marketingInformationAgree', true)

    this.allTradeOfferChannelsDisabled(values)
      ? props.setFieldValue('tradeInformationAgree', false)
      : props.setFieldValue('tradeInformationAgree', true)
  }

  private readonly allMarketingOfferChannelsDisabled = (values: ApprovalsFormValues) =>
  !values.pushMarketingOfferAgree && !values.emailMarketingOfferAgree && !values.smsMarketingOfferAgree

  private readonly allTradeOfferChannelsDisabled = (values: ApprovalsFormValues) =>
    !values.pushTradeOfferAgree && !values.emailTradeOfferAgree && !values.smsTradeOfferAgree

  private readonly toggleEditable = (approvalsGroup: ApprovalsGroup) => (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()

    switch (approvalsGroup) {
      case ApprovalsGroup.CommunicationApprovals:
        this.setState({
          ...this.state,
          areCommunicationApprovalsEditable: !this.state.areCommunicationApprovalsEditable,
        })
        break
      case ApprovalsGroup.RegistrationApprovals:
        this.setState({
          ...this.state,
          areRegistrationApprovalsEditable: !this.state.areRegistrationApprovalsEditable,
        })
        break
      default:
        break
    }
  }

  private readonly saveAndToggleEditable = (approvalsGroup: ApprovalsGroup) => () => {
    this.toggleEditable(approvalsGroup)()
    this.props.submitForm()
  }
}

export const ApprovalsForm = compose(
  formik,
  withTranslation(),
)(ApprovalsFormComponent)
