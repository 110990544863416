import { ResourceSourceInput } from '@fullcalendar/resource-common'
import { BtnType } from 'actff-bo-app/components/Button'
import { mapScheduleFormToRules } from 'actff-bo-lib/admin'
import { createRules } from 'actff-bo-lib/admin/employee-scheduler/dao'
import { ScheduleRuleForm, Shift } from 'actff-bo-lib/admin/employee-scheduler/dto'
import { Testable } from 'actff-bo-lib/global/testable'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { addDays, differenceInDays } from 'date-fns'
import React, { FC, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QueryObserverResult } from 'react-query'
import { useDispatch } from 'react-redux'
import { LinkButton, PrimaryButton } from 'styles'

import { Days } from './Days'
import { RepeatCycle } from './RepeatCycle'
import { Resources } from './Resources'
import { DrawerHeader, DrawerStyled, Footer, FormStyled, RepeatCycleContainer } from './Styled'

type Props = Testable & {
  readonly resources: ResourceSourceInput,
  readonly toggleOpen: () => void,
  readonly onRefetch: () => Promise<QueryObserverResult>,
  readonly onClearSelection: () => void,
  readonly formValues?: ScheduleRuleForm,
  readonly shifts?: ReadonlyArray<Shift>,
}

const defaultValues = {}

export const week = 7

export const Form: FC<Props> = ({ formValues = defaultValues, resources, shifts, toggleOpen, onRefetch, onClearSelection, testId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { control, handleSubmit, setValue, getValues, watch, errors, trigger, register } = useForm<ScheduleRuleForm>({
    defaultValues: formValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  })

  const { append, fields } = useFieldArray({
    control,
    name: 'elements',
  })

  const [showRepeatCycle, setShowRepeatCycle] = useState(false)

  const repeatInterval = watch('every')
  const firstDay = watch('elements[0].from') as Date
  const lastDay = watch(`elements.${fields.length - 1}.to`) as Date

  const isContainedInRepeatCycle = repeatInterval ? (differenceInDays(lastDay, firstDay) <= (repeatInterval * week)) : true

  const availableEndDate = repeatInterval ? addDays(lastDay, repeatInterval * week) : lastDay

  const onSubmit = async (form: ScheduleRuleForm) => {
    await trigger()

    try {
      await createRules(mapScheduleFormToRules(form))
      toggleOpen()
      dispatch(displaySuccessToast('admin.employeeSchedule.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      onClearSelection()
      await onRefetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.employeeSchedule.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  const toggleRepeatCycle = () => {
    setShowRepeatCycle(!showRepeatCycle)
  }

  return (
    <DrawerStyled
      anchor='right'
      open={true}
      ModalProps={{ onBackdropClick: toggleOpen }}
    >
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <DrawerHeader>
          {t('admin.employeeSchedule.form.header')}
        </DrawerHeader>
        <Days
          append={append}
          fields={fields}
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          shifts={shifts}
          isContainedInRepeatCycle={isContainedInRepeatCycle}
        />
        <Resources control={control} resources={resources} errors={errors} watch={watch} />
        <RepeatCycleContainer>
          <input
            type='checkbox'
            id='repeat'
            checked={showRepeatCycle}
            name='isRecurrent'
            onChange={toggleRepeatCycle}
            ref={register}
          />
          <label htmlFor='repeat'>{t('admin.employeeSchedule.form.repeatCycle')}</label>
          {showRepeatCycle &&
            <RepeatCycle control={control} errors={errors} testId={testId} availableEndDate={availableEndDate} />
          }
        </RepeatCycleContainer>
        <Footer>
          <LinkButton onClick={toggleOpen} type={BtnType.Button}>
            {t('caption.cancel')}
          </LinkButton>
          <PrimaryButton type={BtnType.Submit}>
            {t('caption.accept')}
          </PrimaryButton>
        </Footer>
      </FormStyled>
    </DrawerStyled>
  )
}
