import { ErrorMessage } from '@hookform/error-message'
import { Popover } from '@material-ui/core'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Icon } from 'actff-bo-app/components/Icon'
import { IconContainer } from 'actff-bo-app/components/Table'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import { IconType } from 'actff-bo-lib/menu'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { ChangePasswordForm, changeUserPassword, User } from 'actff-bo-lib/user'
import React, { FC, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import { Td } from 'styles'

import {
  ChangePasswordDialogContent,
  DeleteUserLink,
  EditPasswordLink,
  FormSegment,
  Input,
  LabelStyled,
  PopoverContentContainer,
} from './Styled'

type Props = {
  readonly onDelete: () => void,
  readonly user: Partial<User>,
}

export const ActionMenu: FC<Props> = ({
  user,
  onDelete,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)
  const popoverOpen = Boolean(popoverAnchor)

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const toggleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen)

    if (popoverOpen) {
      setPopoverAnchor(null)
    }
  }

  const togglePasswordDialog = () => {
    setPasswordDialogOpen(!passwordDialogOpen)

    if (!passwordDialogOpen) {
      setPopoverAnchor(null)
    }
  }

  const { control, register, handleSubmit, formState, errors } = useForm<ChangePasswordForm>({
    defaultValues: {
      newPassword: '',
      newPasswordRepeat: '',
      oldPassword: '',
    },
  })

  const changeUserPasswordMutation = useMutation(
    QueryKeys.CHANGE_USER_PASSWORD,
    changeUserPassword,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.users.changeUserPassword.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        togglePasswordDialog()
      },
    },
  )

  const newPasswordWatched = useWatch({
    control,
    defaultValue: '',
    name: 'newPassword',
  })

  const handlePopoverClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setPopoverAnchor(null)
  }

  const handleDeleteClick = () => {
    toggleDeleteDialog()
    onDelete()
  }

  const handleIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
    event.stopPropagation()
    event.preventDefault()
  }

  const handleClosePasswordDialog = () => {
    togglePasswordDialog()
  }

  const handleChangePasswordConfirm = (formData: ChangePasswordForm) => {
    changeUserPasswordMutation.mutate({
      login: user.email,
      newPassword: formData.newPassword,
      oldPassword: formData.oldPassword,
    })
  }

  const isSubmitDisabled = () => !formState.isDirty && !formState.isValid

  return (
    <Td>
      <IconContainer onClick={handleIconClick}>
        <Icon type={IconType.More} />
      </IconContainer>
      <Popover
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={handlePopoverClose}
        open={popoverOpen}
        anchorEl={popoverAnchor}
      >
        <PopoverContentContainer>
          <EditPasswordLink onClick={togglePasswordDialog}>
            <Icon type={IconType.Edit} />
            <span>{t('admin.dealership.users.menu.changePassword')}</span>
          </EditPasswordLink>
          <DeleteUserLink onClick={toggleDeleteDialog}>
            <Icon type={IconType.TrashRed} />
            <span>{t('admin.dealership.users.menu.deleteUser')}</span>
          </DeleteUserLink>
        </PopoverContentContainer>
      </Popover>

      <Dialog
        content='admin.dealership.users.changePassword.content'
        confirmDisabled={isSubmitDisabled()}
        isLoading={changeUserPasswordMutation.isLoading}
        open={passwordDialogOpen}
        onConfirm={handleSubmit(handleChangePasswordConfirm)}
        onClose={handleClosePasswordDialog}
        title='admin.dealership.users.changePassword.title'
      >
        <ChangePasswordDialogContent>
          <FormSegment>
            <LabelStyled>{t('admin.dealership.users.changePassword.old')}:</LabelStyled>
            <Input
              type='password'
              name='oldPassword'
              ref={register({
                ...getValidator({ type: ValidationType.MaxLength }),
                ...getValidator({ type: ValidationType.MinLength }),
                ...getValidator({ type: ValidationType.Required }),
              })}
            />
            <ErrorMessage errors={errors} name='oldPassword' as={TranslatedErrorMessage} />
          </FormSegment>
          <FormSegment>
            <LabelStyled>{t('admin.dealership.users.changePassword.new')}:</LabelStyled>
            <Input
              type='password'
              name='newPassword'
              ref={register({
                ...getValidator({ type: ValidationType.MaxLength }),
                ...getValidator({ type: ValidationType.MinLength }),
                ...getValidator({ type: ValidationType.Pattern }),
                ...getValidator({ type: ValidationType.Required }),
              })}
            />
            <ErrorMessage errors={errors} name='newPassword' as={TranslatedErrorMessage} />
          </FormSegment>
          <FormSegment>
            <LabelStyled>{t('admin.dealership.users.changePassword.repeatNew')}:</LabelStyled>
            <Input
              type='password'
              name='newPasswordRepeat'
              ref={register({
                ...getValidator({ type: ValidationType.MaxLength }),
                ...getValidator({ type: ValidationType.MinLength }),
                ...getValidator({ type: ValidationType.Pattern }),
                ...getValidator({ type: ValidationType.Required }),
                validate: value => value !== newPasswordWatched ? 'form.validation.password.notSame' : true,
              })}
            />
            <ErrorMessage errors={errors} name='newPasswordRepeat' as={TranslatedErrorMessage} />
          </FormSegment>

        </ChangePasswordDialogContent>
      </Dialog>

      <Dialog
        autoWidth={true}
        content='admin.dealership.users.menu.deleteUser.content'
        contentProps={{ firstName: user.firstName ?? '', lastName: user.lastName ?? '' }}
        open={deleteDialogOpen}
        onConfirm={handleDeleteClick}
        onClose={toggleDeleteDialog}
        title='admin.dealership.users.menu.deleteUser'
      />

    </Td>
  )
}
