import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormHeader } from 'actff-bo-app/components/Form'
import { Loader } from 'actff-bo-app/components/Loader'
import { NoItems, WithPagination } from 'actff-bo-app/components/Table'
import { CarInspectionAction, selectCurrentInspections, selectIsNdcCar } from 'actff-bo-lib/car'
import { selectCurrentInspectionsPage } from 'actff-bo-lib/car/selectors/inspection'
import { createRoute, IconType, Path, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { withCarViewHeader, WithCarViewHeaderProps } from '../../withCarViewHeader'
import { InspectionRow } from './InspectionRow'

type Props = WithCarViewHeaderProps

const FormHeaderStyled = styled(FormHeader)`
  align-items: center;
  display: inline-grid;
  grid-auto-flow: unset;
  grid-template-columns: 1fr auto;
  padding: 28px 32px;
  width: 100%;
`

const testId = 'car-inspection-list__'

const InspectionListComponent: FC<Props> = ({ car }) => {
  const dispatch = useDispatch()
  const getInspections = useMemo(() => () => dispatch(CarInspectionAction.getInspections()), [dispatch])
  const changeCurrentPage = useMemo(() => (nextPage: number) => dispatch(CarInspectionAction.changePage(nextPage)), [dispatch])
  const isNdcCar = useSelector(selectIsNdcCar)
  const Inspections = useSelector(selectCurrentInspections)
  const currentPage = useSelector(selectCurrentInspectionsPage)
  const { loading, data } = Inspections
  const { t } = useTranslation()

  const handleNewInspectionClick = () => {
    if (isNdcCar) {
      history.push(createRoute(Paths.NdcCarViewNewInspection, { vin: car.vin }))

      return
    }

    history.push(createRoute(Paths.CarViewNewInspection, { carId: car.uuid }))
  }

  useEffect(() => {
    getInspections()

    return
  }, [getInspections])

  if (loading) {
    return <Loader />
  }

  return (
    data && (
      <>
        <FormHeaderStyled>
          {t('carView.inspection.table.name')}
          <ButtonWithIcon
            caption={t('carView.inspection.new')}
            iconType={IconType.Plus}
            onClick={handleNewInspectionClick}
          />
        </FormHeaderStyled>
        <WithPagination
          currentPage={currentPage}
          onPageChange={changeCurrentPage}
          pageCount={data.noOfPages}
          path={Paths.CarViewInspections as Path}
          testId={testId}
          rowsInCurrentPage={data.result.length}
        >
        {data.result.length > 0
          ? (
            <HorizontallyScrolledContainer>
              <Table>
                <thead>
                <Tr>
                  <Th>
                  {t('carView.inspection.table.header.rating')}
                  </Th>
                  <Th>
                    {t('carView.inspection.table.header.inspectionDate')}
                  </Th>
                  <Th>
                    {t('carView.inspection.table.header.lastServiceDate')}
                  </Th>
                  <Th>
                    {t('carView.inspection.table.header.motDate')}
                  </Th>
                  <Th>
                    {t('carView.inspection.table.header.status')}
                  </Th>
                </Tr>
                </thead>
                <tbody>
                  {data.result.map((result, index) => (
                    <InspectionRow
                      carId={car.uuid}
                      element={result}
                      key={result.uuid}
                      isNdcCar={isNdcCar}
                      testId={`${testId}inspection-${index}`}
                      vin={car.vin}
                    />
                  ))}
                </tbody>
              </Table>
              </HorizontallyScrolledContainer>
          )
          : <NoItems icon={IconType.NoCars} text={t('caption.noData')} />
        }
        </WithPagination>
      </>
    )
  )
}

export const InspectionList = withCarViewHeader(InspectionListComponent)
