import { api, axiosApi } from 'actff-bo-lib/api'
import { Observable } from 'rxjs'
import { buildUrl } from '../global'

import { CarModel, CarVersion, DealerLocation, GetOilTypesPayload } from './dto'

export const getDealerLocations = (): Observable<ReadonlyArray<DealerLocation>> =>
  api.get<ReadonlyArray<DealerLocation>>('/dealer-location')

export const getDealerBrands = () => api.get<ReadonlyArray<string>>('/dealer-settings/brands')

export const deleteDealerCarBrand = (name: string) => api.delete(`/car/brands/${name}`)

export const addDealerCarBrand = (name: string) => api.post('/dealer-settings/brands', { name })

export const getCarModels = (brands?: ReadonlyArray<string>) =>
  api.get<ReadonlyArray<CarModel>>(`/dealer-settings/car-model${brands ? `?brand=${brands.toString()}` : ''}`)

export const getCarVersions = (brands?: ReadonlyArray<string>) =>
  api.get<ReadonlyArray<CarVersion>>(`/dealer-settings/car-version${brands ? `?brand=${brands.toString()}` : ''}`)

export const getEngineCodes = () => api.get<ReadonlyArray<string>>('/dealer-settings/engine-code')

export const getBrakeLiquids = (brand: string) =>
  api.get<ReadonlyArray<string>>(buildUrl('/dealer-settings/brake-liquid', { brand }))

export const getOilTypes = ({ brand, engineCode }: GetOilTypesPayload) =>
  api.get<ReadonlyArray<string>>(buildUrl('/dealer-settings/engine-oil', { brand, engineCode }))

export const getOilCodesWithAxios = () =>
  axiosApi.get<ReadonlyArray<string>>('/dealer-settings/engine-oil')
