import { api, AppConfig, getConfig } from 'actff-bo-lib/api'
import { initializeApp, messaging } from 'firebase'
import { noop } from 'rxjs'

import { NotificationToken } from './dto'

export const registerToken = (notificationToken: NotificationToken) => api.post('/user/notification-token', { notificationToken })

export const unregisterToken = (notificationToken: string) => api.delete(`/user/notification-token/${notificationToken}`)

export const initFcm = () => {
  initializeApp({
    apiKey: getConfig(AppConfig.FCM_API_KEY),
    appId: getConfig(AppConfig.FCM_APP_ID),
    authDomain: getConfig(AppConfig.FCM_AUTH_DOMAIN),
    databaseURL: getConfig(AppConfig.FCM_DATABASE_URL),
    messagingSenderId: getConfig(AppConfig.FCM_MESSAGING_SENDER_ID),
    projectId: getConfig(AppConfig.FCM_PROJECT_ID),
    storageBucket: getConfig(AppConfig.FCM_STORAGE_BUCKET),
  })

  messaging().usePublicVapidKey(getConfig(AppConfig.FCM_PUBLIC_VAPID_KEY))
}

export const initFcmSw = async () => navigator.serviceWorker && navigator.serviceWorker
  .register(`${getConfig(AppConfig.PUBLIC_URL)}/sw.js`)
  .then(registration => {
    messaging().useServiceWorker(registration)
    if (registration.active) {
      registration.active.postMessage({ messagingSenderId: getConfig(AppConfig.FCM_MESSAGING_SENDER_ID) })
    }
  })
  .catch(noop)
