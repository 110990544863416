import { DateTime } from 'actff-bo-app/components/DateTime'
import { Field, FormCell, FormHeader, reactSelectStyles, selectTheme } from 'actff-bo-app/components/Form'
import { defaultTimeFormat } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { WaitInPlace as WaitInPlaceType } from 'actff-bo-lib/service-request'
import { addHours, format } from 'date-fns'
import { Form, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import Select from 'react-select'
import { compose } from 'redux'
import styled from 'styled-components'

type Props = {
  readonly carLeaveOption: WaitInPlaceType,
  readonly editMode: boolean,
  readonly locale: {},
  readonly onCarLeaveOptionChange: (carLeaveOption: WaitInPlaceType) => void,
}

type SelectOptionType = {
  readonly label: string,
  readonly value: number,
}

type WaitInPlaceComponentProps = Testable & Props & WithTranslation

const Container = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

class WaitInPlaceComponent extends React.Component<WaitInPlaceComponentProps> {
  public render(): React.ReactNode {
    const { carLeaveOption, editMode, locale, t, testId } = this.props
    const { visitTime, waitPeriod } = carLeaveOption

    return (
      <Container>
        <FormCell slim={true}>
          <FormHeader>{t('serviceRequest.carLeaveOption.visitTime')}</FormHeader>
        </FormCell>
        <FormCell slim={true}>
          <FormHeader>{t('serviceRequest.carLeaveOption.departureTime')}</FormHeader>
        </FormCell>
        <Field
          component={DateTime}
          dateTime={visitTime}
          editMode={editMode}
          locale={locale}
          onDateTimeChange={this.handleCarLeaveOptionChange('visitTime')}
          testId={`${testId}wait-in-place-visit`}
        />
        <FormCell>
          <label>{t('serviceRequest.carLeaveOption.visitHour')}</label>
          <span data-testid={`${testId}wait-in-place-departure-time`}>{format(addHours(visitTime, waitPeriod), defaultTimeFormat)}</span>
        </FormCell>
        <FormCell data-testid={`${testId}wait-in-place-wait-time`}>
          <label>{t('serviceRequest.carLeaveOption.waitTime')}</label>
          {this.renderWaitPeriod(waitPeriod)}
        </FormCell>
      </Container>
    )
  }

  // tslint:disable no-magic-numbers readonly-array
  private readonly getSelectElements = (): SelectOptionType[] =>
    [...Array(24)].map((_, index: number) => {
      const time = index / 2
      const labelForTime = Number.isInteger(time)
        ? 'serviceRequest.carLeaveOption.hours'
        : 'serviceRequest.carLeaveOption.hourAndHalf'

      return ({
        label: this.props.t(labelForTime, { count: time }),
        value: time,
      })
    })

  // tslint:enable

  private readonly getDefaultValue = (period: number): SelectOptionType => ({
    label: this.props.t('serviceRequest.carLeaveOption.hours', { count: period }),
    value: period,
  })

  private readonly handleCarLeaveOptionChange = (field: keyof WaitInPlaceType) => (date: Date) => {
    this.props.onCarLeaveOptionChange({
      ...this.props.carLeaveOption,
      [field]: date,
    })
  }

  private readonly handleWaitPeriodChange = (option: SelectOptionType) => {
    this.props.onCarLeaveOptionChange({
      ...this.props.carLeaveOption,
      waitPeriod: option.value,
    })
  }

  private readonly renderWaitPeriod = (period: number) => {
    const { editMode, t } = this.props

    return (
      editMode
        ? (
          <Select
            defaultValue={this.getDefaultValue(period)}
            onChange={this.handleWaitPeriodChange}
            options={this.getSelectElements()}
            styles={reactSelectStyles}
            theme={selectTheme}
          />
          )
        : <span>{t('serviceRequest.carLeaveOption.hours', { count: period })}</span>
    )
  }
}

const formik = withFormik<Props, {}>({
  handleSubmit: () => null,
})

export const WaitInPlace = compose(
  formik,
  withTranslation(),
)(WaitInPlaceComponent)
