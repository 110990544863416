import { api } from 'actff-bo-lib/api'
import { DictionaryType } from 'actff-bo-lib/dictionary'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { BankAccount, InsurerName } from '../dto'

export const getBankAccountNumbersForInsurer = (insurer: InsurerName): Observable<ReadonlyArray<BankAccount>> =>
  api.get<ReadonlyArray<DictionaryType<BankAccount>>>(`/dictionary/insurer/${insurer}/bankAccounts`).pipe(
    map(bankAccounts => bankAccounts.map(({ value }) => value)),
  )
