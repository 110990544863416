import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import { CarCard } from 'actff-bo-app/components/CarCard'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Loader } from 'actff-bo-app/components/Loader'
import { PagedResult } from 'actff-bo-lib/api'
import { CarId } from 'actff-bo-lib/car'
import { CarInfo } from 'actff-bo-lib/car/dto'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State as GlobalState } from 'actff-bo-lib/store'
import {
  selectCurrentTelephoneRequest,
  selectCurrentTelephoneRequestCars,
  TelephoneRequest,
  TelephoneRequestAction,
  TelephoneRequestId,
  TelephoneRequestStatus,
  UpdateTelephoneRequestDto,
} from 'actff-bo-lib/telephone-request'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import styled from 'styled-components'
import { colors, H2, Header, Small } from 'styles'

import { TelephoneRequestClient } from './TelephoneRequestClient'

type State = {
  readonly activeCar: CarInfo | null,
}

type StateToProps = {
  readonly cars: PagedResult<CarInfo> | null,
  readonly telephoneRequest: TelephoneRequest | null,
}

type DispatchToProps = {
  readonly clearTelephoneRequest: () => void,
  readonly getTelephoneRequest: (uuid: TelephoneRequestId) => void,
  readonly updateTelephoneRequest: (dto: UpdateTelephoneRequestDto) => void,
}

type TelephoneRequestViewParams = {
  readonly telephoneRequestId: string,
}

type TelephoneRequestViewProps = StateToProps & DispatchToProps & RouteComponentProps<TelephoneRequestViewParams> & WithTranslation

const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    "content comments"
    "footer comments";
  border-bottom: 1px solid ${colors.mysticGray};
`

const TelephoneRequestContent = styled.div`
  padding: 16px 32px;
`

const TelephoneRequestCars = styled.div`
  display: grid;
  grid-area: content;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  margin-top: 32px;
`

const TelephoneRequestInternalComments = styled.div`
  border-left: 1px solid ${colors.mysticGray};
  grid-area: comments;
`

const Footer = styled.footer`
  border-top: 1px solid ${colors.mysticGray};
  display: grid;
  grid-area: footer;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  justify-content: end;
  padding: 16px 32px;
`

const testId = 'telephone-request__'

class TelephoneRequestViewComponent extends React.Component<TelephoneRequestViewProps, State> {
  public readonly state: State = {
    activeCar: null,
  }

  public componentDidMount(): void {
    this.props.getTelephoneRequest(this.getServiceRequestId())
  }

  public componentDidUpdate(prevProps: TelephoneRequestViewProps): void {
    if (!prevProps.telephoneRequest && this.props.telephoneRequest) {
      this.setState({
        activeCar: this.props.telephoneRequest.car,
      })
    }
  }

  public componentWillUnmount(): void {
    this.props.clearTelephoneRequest()
  }

  public render(): React.ReactNode {
    const { cars, telephoneRequest, t } = this.props

    return telephoneRequest && cars
      ? (
        <>
          <Header>
            <H2 data-testid={`${testId}header`}>{t('telephoneRequest.header')}</H2>
            <Small data-testid={`${testId}request-time`}><FormattedDate date={telephoneRequest.requestTime} /></Small>
          </Header>
          <Content>
            <TelephoneRequestContent>
              <TelephoneRequestClient client={telephoneRequest.client} testId={`${testId}client-`} />
              <TelephoneRequestCars>
                <CarCard
                  active={this.isCarActive(telephoneRequest.car.uuid)}
                  car={telephoneRequest.car}
                  numeral={1}
                  testId={`${testId}car-0--`}
                />
                {cars.result.map((car, index) =>
                  <CarCard
                    active={this.isCarActive(car.uuid)}
                    key={car.uuid}
                    car={car}
                    numeral={index + 2} // tslint:disable-line no-magic-numbers
                    testId={`${testId}car-${index}--`}
                  />,
                )}
              </TelephoneRequestCars>
            </TelephoneRequestContent>
            <TelephoneRequestInternalComments>
              <></>
            </TelephoneRequestInternalComments>
            <Footer>
              {this.renderStatusButtons()}
            </Footer>
          </Content>
        </>
      )
      : <Loader />
  }

  private readonly renderStatusButtons = () => {
    const { telephoneRequest, t } = this.props
    const { CANCELED, COMPLETED } = TelephoneRequestStatus

    if (!telephoneRequest) {
      return null
    }

    const { status } = telephoneRequest

    return (
      <>
        {status !== COMPLETED &&
          <ButtonWithIcon
            caption={t('telephoneRequest.status.canceled')}
            disabled={status === CANCELED}
            iconType={IconType.Cross}
            onClick={this.handleStatusButtonClick(CANCELED)}
            testId={`${testId}button--canceled`}
          />
        }
        {status !== CANCELED &&
          <ButtonWithIcon
            caption={t('telephoneRequest.status.completed')}
            disabled={status === COMPLETED}
            iconType={IconType.CheckMark}
            onClick={this.handleStatusButtonClick(COMPLETED)}
            testId={`${testId}button--completed`}
          />
        }
      </>
    )
  }

  private readonly handleStatusButtonClick = (status: TelephoneRequestStatus) => () => {
    const { telephoneRequest } = this.props

    if (telephoneRequest) {
      this.props.updateTelephoneRequest({ status, uuid: telephoneRequest.uuid })
    }
  }

  private readonly isCarActive = (carUuid: CarId): boolean =>
    !!this.state.activeCar && this.state.activeCar.uuid === carUuid

  private readonly getServiceRequestId = () => this.props.match.params.telephoneRequestId as TelephoneRequestId
}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  cars: selectCurrentTelephoneRequestCars(state),
  telephoneRequest: selectCurrentTelephoneRequest(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  clearTelephoneRequest: () => { dispatch(TelephoneRequestAction.clearTelephoneRequest()) },
  getTelephoneRequest: uuid => { dispatch(TelephoneRequestAction.getTelephoneRequest(uuid)) },
  updateTelephoneRequest: dto => { dispatch(TelephoneRequestAction.updateTelephoneRequest(dto)) },
})

export const TelephoneRequestView = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(TelephoneRequestViewComponent)
