import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import i18next from 'i18next'
import * as React from 'react'
import { LinkButton, PrimaryButton } from 'styles'

import { FiltersActionButtonsStyled, FooterStyled, MainActionButtonsStyled } from './Styled'

type Props = Testable & {
  readonly handleClearClick: () => void,
  readonly onClose: () => void,
  readonly onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,
  readonly t: i18next.TFunction,
}

type FiltersActionButtonsProps = Testable & {
  readonly onClearClick: () => void,
  readonly t: i18next.TFunction,
}

export const FiltersActionButtons: React.FC<FiltersActionButtonsProps> = ({ onClearClick, testId, t }) => (
  <FiltersActionButtonsStyled>
    <ButtonWithIcon
      caption={t('caption.cleanEverything')}
      iconType={IconType.Trash}
      type={BtnType.Button}
      theme={BtnTheme.Link}
      onClick={onClearClick}
      testId={`${testId}clear`}
    />
  </FiltersActionButtonsStyled>
)

type MainActionButtonsProps = Testable & {
  readonly onClose: () => void,
  readonly onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,
  readonly t: i18next.TFunction,
}

export const MainActionButtons: React.FC<MainActionButtonsProps> = ({ onClose, onSubmit, testId, t }) => (
  <MainActionButtonsStyled>
    <LinkButton type={BtnType.Button} onClick={onClose} data-testid={`${testId}cancel`}>{t('caption.cancel')}</LinkButton>
    <PrimaryButton type={BtnType.Submit} onClick={onSubmit} data-testid={`${testId}submit`}>{t('caption.filter')}</PrimaryButton>
  </MainActionButtonsStyled>
)

export const Footer: React.FC<Props> = ({ handleClearClick, onClose, onSubmit, testId, t }) => (
  <FooterStyled>
    <FiltersActionButtons onClearClick={handleClearClick} testId={testId} t={t} />
    <MainActionButtons onClose={onClose} onSubmit={onSubmit} testId={testId} t={t} />
  </FooterStyled>
)
