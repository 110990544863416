import { api, PagedResult } from 'actff-bo-lib/api'
import { CarId } from 'actff-bo-lib/car'
import {
  buildUrl,
  createInternalComment,
  Filters,
  getFiltersAsObject,
  getSimpleFiltersAsObject,
  InternalComment,
} from 'actff-bo-lib/global'
import { CarLeaveOption } from 'actff-bo-lib/service-request/dto/car-leave-option'
import { of } from 'rxjs'
import { map } from 'rxjs/operators'
import { DealerLocationKey } from '../dealership'
import { toServiceRequest } from './dto'

import { ServiceRequest, ServiceRequestCount, ServiceRequestFilterValues, ServiceRequestId } from './dto/service-request'

export const createServiceRequest = (carUuid: CarId) => api.post(`/service-request/${carUuid}`, {})

// TODO: Fetch carLeaveOptions from API
// tslint:disable
export const getAvailableCarLeaveOptions = () =>
  of<ReadonlyArray<CarLeaveOption>>([
    {'carLeaveOptionName': 'CONTACT'} as CarLeaveOption,
    {'carLeaveOptionName': 'LEAVE_CAR', 'leaveTime': new Date(), 'pickUpTime': new Date()} as CarLeaveOption,
    {'carLeaveOptionName': 'WAIT_IN_PLACE', 'visitTime': new Date(), 'waitPeriod': 4.0} as CarLeaveOption,
    {'carLeaveOptionName': 'D2D', 'giveAwayTime': new Date(), 'giveBackTime': new Date(), 'takeAddress': {}, 'returnAddress': {}} as CarLeaveOption
  ])
// tslint:enable

export const getServiceRequests = (
  filters: Filters<ServiceRequestFilterValues>,
  page: number,
  searchText?: string,
  location?: ReadonlyArray<DealerLocationKey>,
  brand?: ReadonlyArray<string>,
) =>
  api.get<PagedResult<ServiceRequest>>(
    buildUrl('/service-request', {
      page,
      ...getFiltersAsObject(filters),
      searchText,
      ...getSimpleFiltersAsObject({ location, brand }),
    }),
  )

export const getOverdueServiceRequests = (
  page: number,
) =>
  api.get<PagedResult<ServiceRequest>>(
    buildUrl('/service-request/overdue', { page }),
  )

export const getServiceRequest = (serviceRequestId: ServiceRequestId) => api.get<ServiceRequest>(`/service-request/${serviceRequestId}`)
  .pipe(
    map(toServiceRequest),
  )

export const getNewServiceRequestCount = () => api.get<ServiceRequestCount>('/service-request/count/new')

export const getServiceRequestAttachment = (url: string) => api.getObjectUrl(url)

export const getServiceRequestComments = (serviceRequestId: ServiceRequestId) =>
  api.get<ReadonlyArray<InternalComment>>(`/service-request/${serviceRequestId}/comments`)
    .pipe(
      map(response => response.map(createInternalComment)),
    )

export const updateServiceRequest = (serviceRequest: ServiceRequest) => {
  const {uuid, ...dataToPatch} = serviceRequest

  return api.patch(`/service-request/${uuid}`, dataToPatch)
}

export const sendServiceRequestInternalComment = (serviceRequestId: ServiceRequestId, text: string) =>
  api.post(`/service-request/${serviceRequestId}/comment`, { text })
