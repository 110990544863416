import { DatePickerInput } from 'actff-bo-app/components/DateTime/DatePickerInput'
import { DatePickerInputPosition } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { FieldProps } from 'formik'
import * as React from 'react'
import { Modifier } from 'react-day-picker/types/common'
import styled from 'styled-components'
import { theme } from 'styles'

import { Label } from '../Label'

type Props = Testable & {
  readonly date: Date,
  readonly label: string,
  readonly disabled?: boolean,
  readonly disabledDays?: ReadonlyArray<Modifier>,
  readonly error?: boolean,
  readonly placeholder?: string,
  readonly position?: DatePickerInputPosition,
  readonly withPlaceholder?: boolean,
  readonly touched?: boolean,
}
type DatePickerContainerProps = {
  readonly error?: boolean,
  readonly touched?: boolean,
}

const DatePickerContainer = styled.div<DatePickerContainerProps>`
  cursor: pointer;

  & input {
    cursor: pointer;
    width: 100%;
    ${({ error, touched }: Props) => touched && error && `border: 1.5px solid ${theme.form.error}`};
  }

  .DayPickerInput {
    width: 100%;
  }
`

export const DatePickerInputField: React.FC<HTMLElement & Props & FieldProps> = ({
  disabled = false,
  disabledDays,
  className,
  field: { name, value },
  error: formikError,
  touched,
  label,
  form,
  id,
  position,
  placeholder = new Date(),
  withPlaceholder = false,
  testId,
}) => {
  const error = touched && formikError
  const handleOnChange = (selectedDate: Date) => {
    const dateToSet = selectedDate || null
    form.setFieldValue(name, dateToSet)
  }

  return (
    <DatePickerContainer
      error={error}
      touched={touched}
    >
      <Label
        {...(error && { className: 'error' })}
        disabled={disabled}
        error={error}
        htmlFor={id}
      >
        {label}
      </Label>
      <DatePickerInput
        disabled={disabled}
        disabledDays={disabledDays}
        error={error}
        className={className}
        date={value || null}
        onChange={handleOnChange}
        position={position}
        placeholder={placeholder}
        withPlaceholder={withPlaceholder}
        testId={testId}
      />
    </DatePickerContainer>
  )
}
