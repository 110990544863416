import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { OpportunityInsurance, OpportunityInsuranceId, OpportunityViewerMessage } from '../dto'

export enum CrmInsuranceOpportunityActionType {
  ClearOpportunity = '[CRM INSURANCE] CLEAR OPPORTUNITY',

  GetOpportunity = '[CRM INSURANCE] GET OPPORTUNITY',
  GetOpportunityFailure = '[CRM INSURANCE] GET OPPORTUNITY FAILURE',
  GetOpportunityDataFailure = '[CRM INSURANCE] GET OPPORTUNITY DATA FAILURE',
  GetOpportunitySuccess = '[CRM INSURANCE] GET OPPORTUNITY SUCCESS',

  SaveOpportunity = '[CRM INSURANCE] SAVE OPPORTUNITY',
  SaveOpportunityDataFailure = '[CRM INSURANCE] SAVE OPPORTUNITY DATA FAILURE',
  SaveOpportunityFailure = '[CRM INSURANCE] SAVE OPPORTUNITY FAILURE',
  SaveOpportunitySuccess = '[CRM INSURANCE] SAVE OPPORTUNITY SUCCESS',

  ConnectOpportunityViewersSocket = '[CRM INSURANCE] CONNECT OPPORTUNITY VIEWERS SOCKET',
  ConnectOpportunityViewersSocketFailure = '[CRM INSURANCE] CONNECT OPPORTUNITY VIEWERS SOCKET FAILURE',
  DisconnectOpportunityViewersSocket = '[CRM INSURANCE] DISCONNECT OPPORTUNITY VIEWERS SOCKET',
  ReceivedOpportunityViewersUpdate = '[CRM INSURANCE] RECEIVED OPPORTUNITY VIEWERS UPDATE',

  DeleteOpportunity = '[CRM INSURANCE] DELETE OPPORTUNITY',
  DeleteOpportunityFailure = '[CRM INSURANCE] DELETE OPPORTUNITY FAILURE',
  DeleteOpportunitySuccess = '[CRM INSURANCE] DELETE OPPORTUNITY SUCCESS',
}

// tslint:disable object-literal-sort-keys
export const CrmInsuranceOpportunityAction = {
  clearOpportunity: () => createAction(CrmInsuranceOpportunityActionType.ClearOpportunity),

  getOpportunity: (uuid: OpportunityInsuranceId) => createAction(CrmInsuranceOpportunityActionType.GetOpportunity, uuid),
  getOpportunityDataFailure: (field: keyof OpportunityInsurance) =>
    createAction(CrmInsuranceOpportunityActionType.GetOpportunityDataFailure, field),
  getOpportunityFailure: (error: AjaxError) => createAction(CrmInsuranceOpportunityActionType.GetOpportunityFailure, error),
  getOpportunitySuccess: (opportunity: OpportunityInsurance) =>
    createAction(CrmInsuranceOpportunityActionType.GetOpportunitySuccess, opportunity),

  saveOpportunity: (uuid: OpportunityInsuranceId | undefined, opportunity: OpportunityInsurance) =>
    createAction(CrmInsuranceOpportunityActionType.SaveOpportunity, { uuid, opportunity }),
  saveOpportunityDataFailure: (errors: { readonly [key: string]: string }, ajaxError: AjaxError) =>
    createAction(CrmInsuranceOpportunityActionType.SaveOpportunityDataFailure, { errors, ajaxError }),
  saveOpportunityFailure: (error: AjaxError) =>
    createAction(CrmInsuranceOpportunityActionType.SaveOpportunityFailure, error),
  saveOpportunitySuccess: (uuid: OpportunityInsuranceId) =>
    createAction(CrmInsuranceOpportunityActionType.SaveOpportunitySuccess, uuid),

  connectOpportunityViewersSocket: (uuid: OpportunityInsuranceId) =>
    createAction(CrmInsuranceOpportunityActionType.ConnectOpportunityViewersSocket, uuid),
  connectOpportunityViewersSocketFailure: () =>
    createAction(CrmInsuranceOpportunityActionType.ConnectOpportunityViewersSocketFailure),
  disconnectOpportunityViewersSocket: (uuid: OpportunityInsuranceId) =>
    createAction(CrmInsuranceOpportunityActionType.DisconnectOpportunityViewersSocket, uuid),
  receivedOpportunityViewersUpdate: (message: OpportunityViewerMessage) =>
    createAction(CrmInsuranceOpportunityActionType.ReceivedOpportunityViewersUpdate, message),

  deleteOpportunity: (uuid: OpportunityInsuranceId) => createAction(CrmInsuranceOpportunityActionType.DeleteOpportunity, uuid),
  deleteOpportunityFailure: (error: AjaxError) => createAction(CrmInsuranceOpportunityActionType.DeleteOpportunityFailure, error),
  deleteOpportunitySuccess: (uuid: OpportunityInsuranceId) =>
    createAction(CrmInsuranceOpportunityActionType.DeleteOpportunitySuccess, uuid),
}

export type CrmInsuranceOpportunityAction = ActionsUnion<typeof CrmInsuranceOpportunityAction>
