import { ErrorMessage } from '@hookform/error-message'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import {
  FormCell,
  getDateValue,
  MoneyInput,
  reactSelectStyles,
  renderSelectController,
} from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { DealershipInsurer } from 'actff-bo-lib/admin/dealership'
import { OpportunityInsurancePaymentMethods, selectBankAccountNumbersForInsurer } from 'actff-bo-lib/crm/insurance'
import { CrmInsuranceInsurerAction } from 'actff-bo-lib/crm/insurance/actions/insurer'
import { InsurerName } from 'actff-bo-lib/crm/insurance/dto/payments'
import { DatePickerInputPosition } from 'actff-bo-lib/date'
import { SelectOption } from 'actff-bo-lib/global'
import { mapToOption, mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import { CssElementsPlacement } from 'actff-bo-lib/style'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Creatable from 'react-select/creatable'

import { dateValidation } from '../date-validation'
import { insurersToSelectOptions } from '../insurer-option-mapper'
import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'

type Props = Testable
  & SectionFormType
  & {
    readonly installmentCount: number,
    readonly readOnly?: boolean,
    readonly insurers: ReadonlyArray<DealershipInsurer> | undefined,
}

export const SectionPayments: React.FC<Props> = ({
  control,
  installmentCount,
  insurers,
  errors,
  expanded,
  setValue,
  testId,
  watch,
  readOnly,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading: loadingBankAccountNumbers, data: bankAccountNumbers } = useSelector(selectBankAccountNumbersForInsurer)

  const bankAccountOptions = bankAccountNumbers && mapToOptions(bankAccountNumbers)

  const watchNewPolicyPaymentMethod = watch('newPolicy.paymentMethod')

  const isPaymentMethodCash = watchNewPolicyPaymentMethod === OpportunityInsurancePaymentMethods.CASH

  const paymentsRequired = () => isPaymentMethodCash && 'caption.error.required'
  const installmentRequired = (index: number) => index === 0 && paymentsRequired()
  const dateRequired = (index: number) => watch(`payments.installments[${index}].amount`) && 'caption.error.required'

  const shouldValidateInstallmentDate = (index: number) => index === 0 && isPaymentMethodCash

  const handlePaymentForChange = (value: InsurerName) => {
    setValue('payments.bankAccount', null)
    dispatch(CrmInsuranceInsurerAction.getBankAccountNumbersForInsurer(value))
  }

  const handleBankAccountChange = (onChange: (value: string | null) => void) => (option: SelectOption<string>) => {
    option ? onChange(option.value) : onChange(null)
  }

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.payments'), !!errors.payments)}>
      <SectionForm>
        <FormCell id='payments-receiver'>
          <label>{t('crmInsurance.payments.paymentFor')}:</label>
          <Controller
            control={control}
            name='payments.receiver'
            render={renderSelectController({
              clearable: true,
              disabled: readOnly,
              menuPlacement: CssElementsPlacement.Top,
              onChange: handlePaymentForChange,
              options: insurersToSelectOptions(insurers),
              t,
              testId: `${testId}-paymentFor`,
            })}
            rules={{ required: paymentsRequired() }}
          />
          <ErrorMessage errors={errors} name={'payments.receiver'} as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell id='payments-bankAccount'>
          <label>{t('crmInsurance.payments.bankAccountNumber')}:</label>
          <Controller
            render={({ value, onChange }) => <Creatable
              allowCreateWhileLoading={true}
              formatCreateLabel={text => `${t('caption.use')} "${text}"?`}
              isClearable={true}
              isLoading={loadingBankAccountNumbers}
              isMulti={false}
              isDisabled={readOnly}
              loadingMessage={() => `${t('caption.loading')}`}
              noOptionsMessage={() => `${t('caption.noResults')}`}
              options={bankAccountOptions || []}
              onChange={handleBankAccountChange(onChange)}
              placeholder={t('placeholder.choose')}
              styles={{...reactSelectStyles}}
              testId={`${testId}-bankAccountNumber`}
              value={value && mapToOption({ value })}
            />}
            rules={{ required: paymentsRequired() }}
            control={control}
            name='payments.bankAccount'
          />
          <ErrorMessage errors={errors} name={'payments.bankAccount'} as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell />
        <FormCell />

        {Array(installmentCount).fill(null).map((_, index) => (
          <React.Fragment key={index}>
            <FormCell id={`payments-installments[${index}].amount`}>
              <label>{t('crmInsurance.payments.installment', { number: index + 1 })}:</label>
              <MoneyInput
                rules={{ required: installmentRequired(index) }}
                control={control}
                name={`payments.installments[${index}].amount`}
                disabled={readOnly}
              />
              <ErrorMessage errors={errors} name={`payments.installments[${index}].amount`} as={TranslatedErrorMessage} />
            </FormCell>
            <FormCell className='datepicker-fullwidth'>
              <label>{t('crmInsurance.payments.installmentDate', { number: index + 1 })}:</label>
              <Controller
                render={({ onChange, value }) => (
                  <DatePickerInput
                    date={getDateValue(value)}
                    onChange={onChange}
                    position={DatePickerInputPosition.TOP}
                    timeDisabled={true}
                    testId={`${testId}payments-installmentDate${index}`}
                    disabled={readOnly}
                  />
                )}
                rules={{
                  required: installmentRequired(index) || dateRequired(index),
                  validate: dateValidation(shouldValidateInstallmentDate(index)),
                }}
                control={control}
                name={`payments.installments[${index}].date`}
              />
              <ErrorMessage errors={errors} name={`payments.installments[${index}].date`} as={TranslatedErrorMessage} />
            </FormCell>
            <FormCell />
            <FormCell />
          </React.Fragment>
        ))}
      </SectionForm>
    </CollapsableSection>
  )
}
