import { OptionTypeBase } from 'react-select'
import { StylesConfig } from 'react-select/src/styles'

export type IsMulti = false

export const getReactSelectStyles = (
  customStyles?: () => StylesConfig<OptionTypeBase, IsMulti>,
  minWidth?: number,
  maxWidth?: number,
  hasSelectedOptionBackground?: boolean,
): StylesConfig<OptionTypeBase, IsMulti> => ({
  control: restStyles => ({
    ...restStyles,
    ...{minWidth: `${minWidth}px`},
    ...{maxWidth: `${maxWidth}px`}, maxHeight: '32px',
  }),
  input: restStyles => ({
    ...restStyles,
    height: '32px',
    padding: '0 0 6px',
    placeholder: {
      top: '0%',
    },
  }),
  multiValueLabel: base => ({
    ...base,
    maxWidth: '74px',
    textOverflow: 'ellipsis',
  }),
  option: base => ({
    ...base,
    ...(!hasSelectedOptionBackground && { backgroundColor: 'white' }),
  }),
  valueContainer: restStyles => ({
    ...restStyles,
    flexWrap: 'initial',
  }),
  ...(customStyles && customStyles()),
})
