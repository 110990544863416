import { Overlay } from 'actff-bo-app/components/Overlay'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu'
import { setActivePopupElementKey } from 'actff-bo-lib/ui'
import { selectActivePopupElementKey } from 'actff-bo-lib/ui/selectors'
import isPlainObject from 'lodash/isPlainObject'
import React, { useEffect } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ArrowIcon, TabListStyled, TabPanel, TabsLabelStyled, TabStyled } from 'styles'
import { Footer } from './Footer'
import { Filters, FiltersContainer, TabsStyled } from './Styled'

export type FiltersRendererProps = Pick<UseFormMethods, 'control' | 'register' | 'setValue' | 'watch'>
export type FiltersRenderer = (props: FiltersRendererProps) => JSX.Element

type Props<T> = {
  readonly buttonLabel?: string,
  readonly filters: FiltersRenderer,
  readonly defaultFormValues?: T,
  readonly resetValues?: T,
  readonly leftDirection?: boolean,
  readonly onSubmit: (props: object) => void,
  readonly onClear: () => void,
  readonly minWidth?: number,
  readonly tabWidth?: string,
  readonly transparent?: boolean,
} & Testable

function prepareResetFormFieldsValues<T>(formValues: T): object {
  return Object
    .entries(formValues)
    .reduce((prev, [key, value]) => ({
      ...prev,
      [key]: isPlainObject(value) ? prepareResetFormFieldsValues(value) : false,
    }), {})
}

export function TabFilters<T>(
  {
    buttonLabel,
    defaultFormValues,
    resetValues,
    filters,
    leftDirection,
    minWidth,
    onClear,
    onSubmit,
    testId,
    tabWidth,
    transparent,
  }: Props<T>): React.ReactElement {
  const { t } = useTranslation()
  const { key } = useLocation()
  const dispatch = useDispatch()
  const activePopupElementKey = useSelector(selectActivePopupElementKey)
  const shouldExpand = () => activePopupElementKey === elementKey
  const getLabel = () => buttonLabel || t('clientList.filters.defaultFilters.tabLabel')

  const [elementKey] = React.useState(`${key}-${buttonLabel}`)
  const [filtersExpanded, setFiltersExpanded] = React.useState(shouldExpand())

  if (!shouldExpand() && filtersExpanded) {
    setFiltersExpanded(false)
  }

  const toggleFilterTab = () => { setFiltersExpanded(!filtersExpanded) }

  const { control, handleSubmit, getValues, register, reset, setValue, watch } =
    useForm({ defaultValues: { ...defaultFormValues }, mode: 'onSubmit' })

  useEffect(() => {
    if (!filtersExpanded) {
      dispatch(setActivePopupElementKey(null))
    }
  }, [filtersExpanded])

  const submitWrapper = () => {
    void handleSubmit(onSubmit)()
    setFiltersExpanded(false)
  }

  const clearWrapper = () => {
    reset(resetValues ? { ...resetValues } : prepareResetFormFieldsValues(getValues()))
    onClear()
  }

  const handleFilterClick = () => {
    toggleFilterTab()
    dispatch(setActivePopupElementKey(elementKey))
  }

  return (
    <>
      <TabsStyled data-testid={`${testId}filters`} expanded={filtersExpanded} transparent={transparent} width={tabWidth}>
        <TabListStyled>
          <TabStyled expanded={Number(filtersExpanded)} onClick={handleFilterClick}>
            <TabsLabelStyled dark={true}>{getLabel()}</TabsLabelStyled>
            <ArrowIcon type={filtersExpanded ? IconType.ArrowTop : IconType.ArrowBottom} />
          </TabStyled>
        </TabListStyled>
        <TabPanel expanded={Number(filtersExpanded)} leftdirection={leftDirection} minwidth={minWidth}>
          <FiltersContainer expanded={filtersExpanded}>
            <Filters>
              {filters({ control, register, setValue, watch })}
            </Filters>
            <Footer
              handleClearClick={clearWrapper}
              onSubmit={submitWrapper}
              onClose={toggleFilterTab}
              testId={testId}
              t={t}
            />
          </FiltersContainer>
        </TabPanel>
      </TabsStyled>
      <Overlay onClick={handleFilterClick} shouldRender={filtersExpanded} />
    </>
  )
}
