import { CarFinanceAction, CarFinanceActionType } from '../actions'
import { CarFinanceState } from '../state'

const carFinanceInitialState: CarFinanceState = {
  data: null,
  loading: false,
}

// tslint:disable-next-line cyclomatic-complexity
export const carFinanceReducer = (state: CarFinanceState = carFinanceInitialState, action: CarFinanceAction): CarFinanceState => {
  switch (action.type) {
    case CarFinanceActionType.GetCarFinance:
      return {
        data: null,
        loading: true,
      }

    case CarFinanceActionType.GetCarFinanceFailure:
      return {
        ...state,
        loading: false,
      }

    case CarFinanceActionType.GetCarFinanceSuccess:
      return {
        data: action.payload,
        loading: false,
      }

    case CarFinanceActionType.UpdateCarFinance:
      return {
        data: null,
        loading: true,
      }

    case CarFinanceActionType.UpdateCarFinanceFailure:
      return {
        ...state,
        loading: false,
      }

    case CarFinanceActionType.UpdateCarFinanceSuccess:
      return {
        data: action.payload,
        loading: false,
      }

      default:
      return state
  }
}
