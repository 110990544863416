import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

export const selectTestDriveRequestFeature = (state: State) => state.testDriveRequest

export const selectTestDriveRequests = createSelector(selectTestDriveRequestFeature, testDriveRequest => testDriveRequest.list)

export const selectNewTestDriveRequestsCount =
  createSelector(selectTestDriveRequestFeature, testDriveRequest => testDriveRequest.newTestDriveRequestCount)

export const selectTestDriveRequestCurrentPage =
  createSelector(selectTestDriveRequestFeature, testDriveRequest => testDriveRequest.currentPage)

export const selectCurrentTestDriveRequest =
  createSelector(selectTestDriveRequestFeature, testDriveRequest => testDriveRequest.currentTestDriveRequest)

export const selectTestDriveRequestListFilters =
  createSelector(selectTestDriveRequestFeature, testDriveRequest => testDriveRequest.listFilters)
