import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu/dto'
import { Field, FieldAttributes } from 'formik'
import * as React from 'react'
import styled from 'styled-components'
import { theme } from 'styles'

import { ErrorMessage } from './ErrorMessage'

type Props = {
  readonly error?: boolean,
  readonly message?: string,
}

export const ErrorIcon = styled(Icon)`
  margin-right: 8px;
  vertical-align: baseline;
`

const FieldStyled = styled(Field)`
  ${({ error }: FieldAttributes<Props>) => error && `border: 1.5px solid ${theme.form.error}`};
  padding-right: 40px;
  width: 100%;
`

const FieldWrapperStyled = styled.div`
  position: relative;
`

const ErrorIconStyled = styled(ErrorIcon)`
  position: absolute;
  right: 10px;
  top: 13px;
`

const ErrorMessageWrapper = styled.div`
  position: absolute;
  top: 30px;
`

export const FieldWithError: typeof Field = (props: FieldAttributes<Props>) => (
  <FieldWrapperStyled>
    <FieldStyled {...props} />
    {props.error && <ErrorIconStyled type={IconType.ErrorTriangle} />}
    <ErrorMessageWrapper>
      {props.message && <ErrorMessage message={props.message} />}
    </ErrorMessageWrapper>
  </FieldWrapperStyled>
)
