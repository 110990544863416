import { Loader } from 'actff-bo-app/components/Loader'
import { ListTypeTrStyleMap, WithPagination } from 'actff-bo-app/components/Table'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { CrmTradeOpportunitySaleAction, selectOpportunitySaleList, selectOpportunitySaleListCurrentPage } from 'actff-bo-lib/crm/trade/sale'
import { Path, Paths } from 'actff-bo-lib/menu'
import { selectUsersWithoutMe } from 'actff-bo-lib/user'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Th, Tr } from 'styles'

import { OpportunityRow } from './OpporunityRow'

type Props = {
  readonly type: OpportunityListType,
}

const rowInCurrentPage = 10

export const SaleOpportunityTable: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation()
  const { data: userList } = useSelector(selectUsersWithoutMe)
  const dispatch = useDispatch()
  const { data: opportunities, loading } = useSelector(selectOpportunitySaleList(type))
  const currentPage = useSelector(selectOpportunitySaleListCurrentPage(type))

  const onPageChange = useMemo(() => (nextPage: number) => {
    dispatch(CrmTradeOpportunitySaleAction.changeOpportunityListCurrentPage(nextPage, type))
  }, [dispatch, type])

  if (loading) { return <Loader />}
  if (!opportunities) { return null }

  return (
    <WithPagination
      currentPage={currentPage}
      onPageChange={onPageChange}
      pageCount={opportunities?.noOfPages || 0}
      path={Paths.CrmTradePurchaseOpportunityList as Path}
      rowsInCurrentPage={rowInCurrentPage}
    >
      <div>
        <Table>
          <thead>
          <Tr>
            <Th />
            <Th>{t('table.columns.status')}</Th>
            <Th>{t('table.columns.opportunityDate')}</Th>
            <Th>{t('table.columns.fullName')}</Th>
            <Th>{t('table.columns.phone')}</Th>
            <Th>{t('table.columns.email')}</Th>
            <Th>{t('table.columns.purchaseOpportunitiesCount')}</Th>
          </Tr>
          </thead>
          <tbody>
          {opportunities.result.map(opportunity =>
            <OpportunityRow
              key={opportunity.uuid}
              opportunity={opportunity}
              rowComponent={ListTypeTrStyleMap.get(type)}
              opportunityListType={type}
              userList={userList}
            />,
          )}
          </tbody>
        </Table>
      </div>
    </WithPagination>
  )
}
