import { InspectionCommentType, InspectionPaintCoatElementType } from 'actff-bo-lib/car/dto'
import { NominalString } from 'actff-bo-lib/global'

export const thumbnailPostfix = '/thumb'

export enum AttachmentAcceptType {
  Gif = 'image/gif',
  Heic = 'image/heic',
  Heif = 'image/heif',
  JPG = 'image/jpeg',
  PDF = 'application/pdf',
  PNG = 'image/x-png',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export enum AttachmentUploadResultType {
  Failure = 'FAILURE',
}

export enum AttachmentUploadType {
  Inspection,
  PaintCoat,
  OpportunityComment,
  OpportunityPurchaseEvent,
  OpportunitySaleEvent,
}

export type Url = NominalString<{}>
export type BlobUrl = NominalString<{}>

export type UrlIdentificationKeyType = InspectionPaintCoatElementType | InspectionCommentType

export type IdentifiedUrl = {
  readonly key: UrlIdentificationKeyType,
  readonly url: Url,
}

export type Attachment = {
  readonly file: File,
  readonly type: AttachmentType,
  readonly key?: UrlIdentificationKeyType,
}

export enum AttachmentType {
  PDF = 'pdf',
  Image = 'image',
  File = 'file',
}

export const isIdentifiedUrl = (url: Url | IdentifiedUrl): url is IdentifiedUrl => url.hasOwnProperty('key')

export const haveAttachmentsToUpload = (attachments?: ReadonlyArray<Attachment>) => !!(attachments && attachments.length > 0)
