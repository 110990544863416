import { ButtonWithIcon, MinorButton } from 'actff-bo-app/components/Button'
import { ContactApproval } from 'actff-bo-lib/client/dto'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { ApprovalContent, ApprovalPopupFooter, PopupStyled } from './styled'

type ApprovalContentPopupProps = {
  readonly currentApproval: ContactApproval | null,
  readonly open?: boolean,
  readonly onClose?: () => void,
  readonly header?: string,
}

const printApproval = () => {
  window.print()
}

export const ApprovalContentPopup: React.FC<ApprovalContentPopupProps> = ({
  currentApproval,
  open = false,
  onClose,
  header,
}) => {
  const { t } = useTranslation()

  return (
    <PopupStyled open={open} onClose={onClose} header={header}>
      <>
        <ApprovalContent>{currentApproval && currentApproval.content}</ApprovalContent>
        <ApprovalPopupFooter>
          <MinorButton caption={t('caption.print')} onClick={printApproval} />
          <ButtonWithIcon caption={t('caption.download')} iconType={IconType.DownloadGold} />
        </ApprovalPopupFooter>
      </>
    </PopupStyled>
  )
}
