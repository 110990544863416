import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  addDealerBrandToLocation,
  deleteDealerBrandLocation,
  getDealerLocationBrands,
} from 'actff-bo-lib/admin/dealer-locations/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { getDealerBrandsAction, selectCurrentLocation } from 'actff-bo-lib/dealership'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { H2 } from 'styles'
import { Container, Header } from '../../Styled'

import { ViewTabs } from '../ViewTabs'
import { BrandsTable } from './BrandsTable'

const testId = 'admin-view-dealer-locations-brands__'

const BrandsComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentLocation = useSelector(selectCurrentLocation)

  const { data: brands, isLoading, isError, refetch } = useQuery([QueryKeys.GET_LOCATION_BRANDS, currentLocation], async () =>
    await getDealerLocationBrands(currentLocation?.key), { refetchOnWindowFocus: false, retry: false },
  )

  const addBrandMutation = useMutation(
    QueryKeys.ADD_LOCATION_BRAND,
    addDealerBrandToLocation,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.brands.addLocationBrand.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch()
        dispatch(displaySuccessToast('admin.brands.addLocationBrand.success', ToastActionType.CREATE_OR_UPDATE))
        dispatch(getDealerBrandsAction())
      },
    },
  )

  const deleteBrandMutation = useMutation(
    QueryKeys.DELETE_LOCATION_BRAND,
    deleteDealerBrandLocation,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.brands.deleteLocationBrand.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch()
        dispatch(displaySuccessToast('admin.brands.deleteLocationBrand.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const handleAdd = (name: string) => {
    if (currentLocation) {
      addBrandMutation.mutate({
        key: currentLocation.key,
        name,
      })
    }
  }

  const handleDelete = (name: string) => {
    if (currentLocation) {
      deleteBrandMutation.mutate({
        key: currentLocation.key,
        name,
      })
    }
  }

  if (isError) {
    dispatch(displayFailureToast('admin.brands.locationBrands.get.failure', ToastActionType.GET))
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <Container>
          <Header><H2>{t('admin.dealerLocations.brands')}</H2></Header>
          <NoData><LocalLoader /></NoData>
        </Container>
      </>
    )
  }

  if (!brands) {
    return (
      <>
        <ViewTabs testId={testId} />
        <Container>
          <Header><H2>{t('admin.dealerLocations.brands')}</H2></Header>
          <NoData>{t('noData')}</NoData>
        </Container>
      </>
    )
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.dealerLocations.brands')}</H2></Header>
        <span>{t('admin.dealerLocations.brands.info')}</span>
        <BrandsTable
          brands={brands}
          onAdd={handleAdd}
          onDelete={handleDelete}
          testId={testId}
        />
      </Container>
    </>
  )
}

export const Brands = withAdminViewHeader(BrandsComponent)
