import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { defaultShortDateFormat } from 'actff-bo-lib/date'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly lastPushDate?: Date,
}

export const OpportunityLastPushDate: FC<Props> = ({ lastPushDate }) => {
  const { t } = useTranslation()

  return lastPushDate
    ? (
      <>
        {t('opportunity.push.sent')}: <FormattedDate date={lastPushDate} format={defaultShortDateFormat} />
      </>
    )
    : null
}
