import { Dialog } from '@material-ui/core'
import { BtnType } from 'actff-bo-app/components/Button'
import { FormFooter, FormFooterRight } from 'actff-bo-app/components/Form'
import { ErrorMessage } from 'actff-bo-app/components/Form/FieldWithError/ErrorMessage'
import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu/dto'
import React, { FC, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { H2, LinkButton, PrimaryButton } from 'styles'

type Props = {
  readonly children: JSX.Element | null,
  readonly confirmDisabled: boolean,
  readonly header: string,
  readonly open: boolean,
  readonly onClose: () => void,
  readonly onConfirm: () => void,
  readonly onSearch: (searchPhrase: string) => void,
  readonly searchPhrase: string | undefined,
  readonly testId: string,
}

const Container = styled.div`
  padding: 24px 150px;
`

const HeaderStyled = styled.div`
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  height: 50px;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
`

const Content = styled.div`
  display: grid;
`

const InputContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
`

const Input = styled.input`
  margin-bottom: 8px;
  width: 400px;
`

const Label = styled.p`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 19px;
`

const TableLabel = styled(Label)`
  font-weight: 600;
  margin-top: 24px;
`

const Footer = styled(FormFooter)`
  border: none;
  padding: 24px;
`

const H2Styled = styled(H2)`
  font-size: 18px;
`

const IconStyled = styled(Icon)`
  cursor: pointer;
`

const minSearchPhraseLength = 3

export const SearchPersonDialog: FC<Props> = ({
  children,
  confirmDisabled,
  header,
  onClose,
  onConfirm,
  onSearch,
  open,
  searchPhrase,
  testId,
}) => {
  const { t } = useTranslation()
  const [searchTouched, setSearchTouched] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState(searchPhrase)
  const isValid = searchInputValue && searchInputValue.length >= minSearchPhraseLength
  const shouldShowError = () => (searchInputValue && searchInputValue.length < minSearchPhraseLength && searchTouched)

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value)
  }

  const handleSearch = (e: FormEvent) => {
    e.preventDefault()
    setSearchTouched(true)

    if (isValid) {
      setSearchInputValue('')
      setSearchTouched(false)
      onSearch(searchInputValue as string)
    }
  }

  const handleClose = () => {
    setSearchInputValue('')
    onClose()
  }

  return (
    <>
      <Dialog fullScreen={true} open={open} onClose={handleClose} transitionDuration={{ enter: 0, exit: 0 }}>
        <Container>
          <HeaderStyled>
            <div><H2Styled data-testid={`${testId}-header`}>{t(header)}</H2Styled></div>
            <IconStyled type={IconType.Close} onClick={handleClose} />
          </HeaderStyled>

          <Content>
            <Label>{t('ownership.searchPerson.inputLabel')}</Label>
            <InputContainer>
              <form onSubmit={handleSearch}>
                <Input
                  data-testid={`${testId}--search-input`}
                  type='text'
                  onChange={handleSearchChange}
                  value={searchInputValue}
                />
              </form>
            </InputContainer>
            {
              shouldShowError() &&
                <ErrorMessage data-testid={`${testId}-search-input--error`} message='ownership.searchPerson.search.tooShort' />
            }
            <TableLabel data-testid={`${testId}table-label`}>{t('ownership.searchPerson.listLabel')}:</TableLabel>

            {children}

          </Content>

          <Footer>
            <FormFooterRight>
              <LinkButton data-testid={`${testId}-button--cancel`} type={BtnType.Button} onClick={handleClose}>
                {t('caption.cancel')}
              </LinkButton>
              <PrimaryButton data-testid={`${testId}-button--confirm`} disabled={confirmDisabled} type={BtnType.Button} onClick={onConfirm}>
                {t('caption.confirmChoice')}
              </PrimaryButton>
            </FormFooterRight>
          </Footer>

        </Container>
      </Dialog>
    </>
  )
}
