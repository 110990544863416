import { DateTime } from 'actff-bo-app/components/DateTime'
import { Field, FormCell, FormHeader } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import { LeaveCar as LeaveCarType } from 'actff-bo-lib/service-request'
import { FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'

type Props = {
  readonly carLeaveOption: LeaveCarType,
  readonly editMode: boolean,
  readonly locale: {},
  readonly onCarLeaveOptionChange: (carLeaveOption: LeaveCarType) => void,
}
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

type LeaveCarComponentProps = Testable & Props & FormikProps<{}>

const LeaveCarComponent: React.FC<LeaveCarComponentProps> = ({ carLeaveOption, editMode, locale, onCarLeaveOptionChange, testId }) => {
  const handleCarLeaveOptionChange = (field: keyof LeaveCarType) => (date: Date) => {
    onCarLeaveOptionChange({
      ...carLeaveOption,
      [field]: date,
    })
  }

  const { t } = useTranslation()

  return (
    <Container>
      <FormCell slim={true}>
        <FormHeader>{t('serviceRequest.carLeaveOption.leaveCar.leaveTime')}</FormHeader>
      </FormCell>
      <FormCell slim={true}>
        <FormHeader>{t('serviceRequest.carLeaveOption.leaveCar.pickUpTime')}</FormHeader>
      </FormCell>
      <Field
        component={DateTime}
        dateTime={carLeaveOption.leaveTime}
        editMode={editMode}
        locale={locale}
        onDateTimeChange={handleCarLeaveOptionChange('leaveTime')}
        testId={`${testId}leave-car`}
      />
      <Field
        component={DateTime}
        dateTime={carLeaveOption.pickUpTime}
        editMode={editMode}
        locale={locale}
        onDateTimeChange={handleCarLeaveOptionChange('pickUpTime')}
        testId={`${testId}pick-up`}
      />
    </Container>
  )
}

const formik = withFormik<Props, {}>({
  handleSubmit: () => null,
})

export const LeaveCar = compose(
  formik,
)(LeaveCarComponent)
