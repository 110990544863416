import { Icon } from 'actff-bo-app/components/Icon'
import { Testable } from 'actff-bo-lib/global/testable'
import {
  IconType,
  MenuAction,
  selectMenuDisabled,
  selectMenuExpanded,
  selectMenuForCurrentUser,
} from 'actff-bo-lib/menu'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { expandedMenuWidth, layers, tabletBoundary, theme } from 'styles'

import { NavigationItem } from './NavigationItem'

type NavProps = {
  readonly menuExpanded: boolean,
  readonly menuDisabled: boolean,
}

const Nav = styled.nav`
  background: ${theme.menu.background.primary};
  color: ${theme.menu.text.primary};
  display: grid;
  width: ${({ menuExpanded }: NavProps) => menuExpanded ? `${expandedMenuWidth}px` : 'auto'};
  position: relative;
  padding: 110px 0;
  z-index: ${layers.navbar};

  ${({ menuDisabled }: NavProps) => menuDisabled && `
    &::before {
      background: rgba(255, 255, 255, 0.3);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: ' ';
      z-index: 1;
    }
  `};

  @media screen and (max-width: ${tabletBoundary}) {
    padding: 0 0 50px;
    width: auto;
    ${({ menuExpanded }: NavProps) => !menuExpanded &&
      `
        padding: 0;

        & > ul {
          display: none;
        }
        & > button {
          position: relative;
          padding: 20px;
          bottom: 0;
          left: 0;
        }
      `
    };
  }
`

const Ul = styled.ul`
  padding: 0;
  text-decoration: none;
`

const Collapse = styled.button`
  bottom: 25px;
  position: absolute;
  right: 33px;
`

const ExpandIcon = styled(Icon)`
  transform: rotate(180deg);
`

export const Navigation: FC<Testable> = ({ testId }) => {
  const dispatch = useDispatch()
  const toggleNavigation = () => dispatch(MenuAction.toggleMenu())
  const menuExpanded = useSelector(selectMenuExpanded)
  const menuDisabled = useSelector(selectMenuDisabled)
  const menuItems = useSelector(selectMenuForCurrentUser)

  return (
    <Nav menuExpanded={menuExpanded} menuDisabled={menuDisabled}>
      <Ul>
        {menuItems.map(menuItem =>
          <NavigationItem
            key={menuItem.path}
            menuExpanded={menuExpanded}
            menuItem={menuItem}
            testId={testId}
          />,
        )}
      </Ul>
      <Collapse onClick={toggleNavigation}>
        {menuExpanded ? <Icon type={IconType.MenuCollapse} /> : <ExpandIcon type={IconType.MenuCollapse} />}
      </Collapse>
    </Nav>
  )
}
