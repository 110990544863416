import { Loader } from 'actff-bo-app/components/Loader'
import { AuthAction } from 'actff-bo-lib/auth'
import { DebugToolsAction, selectIsLogrocketInitialized } from 'actff-bo-lib/debug-tools'
import { connector } from 'actff-bo-lib/global'
import { I18nAction, selectI18nInitialized } from 'actff-bo-lib/i18n'
import { NotificationAction, selectAreNotificationsInitialized } from 'actff-bo-lib/notification'
import { State } from 'actff-bo-lib/store'
import { selectMeInitialized } from 'actff-bo-lib/user'
import React, { Component } from 'react'
import { Dispatch } from 'redux'

const mapState = (state: State) => ({
  areNotificationsInitialized: selectAreNotificationsInitialized(state),
  isI18nInitialized: selectI18nInitialized(state),
  isLogrocketInitialized: selectIsLogrocketInitialized(state),
  isMeInitialized: selectMeInitialized(state),
})

const mapDispatch = (dispatch: Dispatch) => ({
  initI18n: () => { dispatch(I18nAction.init()) },
  initLogrocket: () => { dispatch(DebugToolsAction.initLogrocket()) },
  initNotifications: () => { dispatch(NotificationAction.init()) },
  loadToken: () => { dispatch(AuthAction.loadToken()) },
})

type StateProps = ReturnType<typeof mapState>
type DispatchProps = ReturnType<typeof mapDispatch>

class RootComponent extends Component<StateProps & DispatchProps> {
  public componentDidMount(): void {
    this.props.initNotifications()
    this.props.initI18n()
    this.props.initLogrocket()
    this.props.loadToken()
  }

  // tslint:disable-next-line cyclomatic-complexity
  public render(): React.ReactNode {
    const {
      areNotificationsInitialized,
      isI18nInitialized,
      isMeInitialized,
      isLogrocketInitialized,
    } = this.props

    return (
      areNotificationsInitialized && isI18nInitialized && isMeInitialized && isLogrocketInitialized
        ? this.props.children
        : <Loader />
    )
  }
}

export const Root = connector(mapState, mapDispatch)(RootComponent)
