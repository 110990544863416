import { Icon } from 'actff-bo-app/components/Icon'
import { Label } from 'actff-bo-app/components/Label'
import { Tab, TabList, TabPanel as TabPanelComponent, Tabs } from 'react-tabs'
import styled from 'styled-components'
import { colors, layers } from 'styles'

type TabsLabelStyledProps = {
  readonly dark: boolean,
}

type TabsStyledProps = {
  readonly expanded: boolean,
  readonly leftdirection?: boolean,
  readonly minwidth?: string,
  readonly zIndex?: string,
}

export const FiltersContainer = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: grid;
  padding: 20px;
  place-items: self-start;
`

export const TabsLabelStyled = styled(Label)<TabsLabelStyledProps>`
  color: ${({ dark }: TabsLabelStyledProps) => dark ? colors.dark : colors.dustyGray};
  cursor: pointer;
  text-transform: lowercase;
  white-space: nowrap;
`

export const TabsStyled = styled(Tabs)`
  background-color: ${colors.athensGray};
  z-index: 1;
`

export const TabStyled = styled(Tab)<TabsStyledProps>`
  background-color: ${({ expanded }: TabsStyledProps) => expanded ? colors.white : colors.athensGray};
  border: none;
  cursor: pointer;
  display: inline-grid;
  grid-template-columns: 1fr 20px;
  padding: 14px 10px 10px;
  place-content: center;
  place-items: center;
  z-index: ${({ zIndex }: TabsStyledProps) => zIndex || layers.tabs};

  &:focus {
    outline: none;
  }
`
export const TabListStyled = styled(TabList)`
  border: none;
  display: inline-grid;
  height: 50px;
  margin: 0;
`

export const TabPanel = styled(TabPanelComponent)<TabsStyledProps>`
  background-color: ${colors.white};
  ${({ expanded }: TabsStyledProps) => !expanded && 'display: none'};
  ${({ leftdirection }: TabsStyledProps) => leftdirection && 'right: -18vw'};
  ${({ minwidth }: TabsStyledProps) => minwidth && `min-width: ${minwidth}px`};
  position: absolute;
  z-index: 1;
`

export const ArrowIcon = styled(Icon)`
  height: 12px;
  top: 1px;
  width: 12px;
`
