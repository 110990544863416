import { MenuItem } from '../dto/menu-item'

export const flatNestedMenuItems = (menu: ReadonlyArray<MenuItem>): ReadonlyArray<MenuItem> =>
  menu.reduce((prev, menuItem) => [
    ...prev,
    {
      ...menuItem,
      children: [],
    },
    ...flatNestedMenuItems(menuItem.children),
  ], [])
