import { api } from 'actff-bo-lib/api'
import { DateRange } from 'actff-bo-lib/date'
import { buildUrl } from 'actff-bo-lib/global'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Report, ReportRaw, ReportType } from '../dto'
import { parseReport } from '../utils'

export const getReport = (type: ReportType, dateRange: DateRange): Observable<Report> => {
  const from = dateRange.from && dateRange.from.toISOString()
  const to = dateRange.to && dateRange.to.toISOString()

  return api.getPlainText(buildUrl(
    '/insurance/report',
    { from, to, type },
  )).pipe(
    map<ReportRaw, Report>(parseReport),
  )
}
