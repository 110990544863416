import { parseToDateOrNull } from 'actff-bo-lib/date'

import { CarId } from './car'

export enum ChangeType {
  TIRES = 'TIRES',
  WHEELS = 'WHEELS',
}

export enum TireType {
  SUMMER = 'SUMMER',
  WINTER = 'WINTER',
  YEARROUND = 'YEARROUND',
  NA = 'NA',
}

export type CarTires = {
  readonly change: {
    readonly changing: boolean,
    readonly changeType: ChangeType | null,
    readonly tireType: TireType | null,
    readonly lastChange: Date | null,
  },
  readonly storage: {
    readonly storing: boolean,
    readonly storageDate: Date | null,
    readonly storageNumber: string | null,
    readonly damaged: boolean,
  },
}

export type CarTiresWithUuid = { readonly carId: CarId } & CarTires

export const toCarTires = (data: any): CarTires => ({ // tslint:disable-line no-any
  change: {
    ...data.change,
    lastChange: parseToDateOrNull(data.change.lastChange),
  },
  storage: {
    ...data.storage,
    storageDate: parseToDateOrNull(data.storage.storageDate),
  },
})
