import { Testable } from 'actff-bo-lib/global/testable'
import { isMenuPathActive, MenuItem, Path } from 'actff-bo-lib/menu'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { ExpandCollapse } from './ExpandCollapse'
import { NavigationItemActiveStyled, NavigationItemStyled, Ul, UlActive } from './NavigationItemChildrenStyled'
import { NavigationLink } from './NavigationLink'

type NavigationItemComponentProps = Testable & {
  readonly isSubmenuActive: boolean,
  readonly menuItem: MenuItem,
  readonly toggleNavigationItem: (path: Path) => () => void,
}

type NavigationItemContainerProps = Testable & {
  readonly active: boolean,
  readonly toggleNavigationItem?: (path: Path) => () => void,
}

const NavigationItemContainer: React.FC<NavigationItemContainerProps> = ({ active, children, testId, toggleNavigationItem }) => {
  const ItemContainer = active ? NavigationItemActiveStyled : NavigationItemStyled

  return <ItemContainer data-testid={testId} onClick={toggleNavigationItem}>{children}</ItemContainer>
}

const NavigationItemChild: React.FC<NavigationItemComponentProps> = ({ isSubmenuActive, menuItem, testId, toggleNavigationItem }) => {
  const { pathname } = useLocation()

  return (
    <>
      <NavigationItemContainer
        active={isMenuPathActive(pathname, menuItem.path, menuItem.childrenHasMultipleTabs)}
        testId={testId}
        toggleNavigationItem={toggleNavigationItem}
      >
        <NavigationLink menuItem={menuItem}/>
        <ExpandCollapse menuItem={menuItem}/>
      </NavigationItemContainer>
      <NavigationItemChildren isSubmenuActive={isSubmenuActive} menuItem={menuItem} toggleNavigationItem={toggleNavigationItem}/>
    </>
  )
}

const UlContainer: React.FC<NavigationItemContainerProps> = ({ active, children }) => {
  const Container = active ? UlActive : Ul

  return <Container>{children}</Container>
}

const childTestId = (path: string) => path.substr(1).replace('/', '--')

export const NavigationItemChildren: React.FC<NavigationItemComponentProps> = ({
  isSubmenuActive,
  menuItem,
  testId,
  toggleNavigationItem,
}) => {
  const { pathname } = useLocation()

  return (
    menuItem.children.length > 0 && menuItem.expanded
      ? (
        <UlContainer active={isSubmenuActive}>
          {menuItem.children.map(child =>
            <NavigationItemChild
              isSubmenuActive={isMenuPathActive(pathname, menuItem.path)}
              key={`${child.path}+${child.paramValue}`}
              menuItem={child}
              testId={`${testId}${childTestId(child.path)}`}
              toggleNavigationItem={toggleNavigationItem}
            />,
          )}
        </UlContainer>
      )
      : null
  )
}
