import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'

import { ServiceAction, ServiceActionType } from './actions'
import { getServices } from './dao'

const getServicesEpic: Epic<ServiceAction> = action$ => action$.pipe(
  ofType(ServiceActionType.GetServices),
  switchMap(() => getServices().pipe(
    map(ServiceAction.getServicesSuccess),
    catchError((error: AjaxError) => of(ServiceAction.getServicesFailure(error))),
  )),
)

export const serviceEpic = combineEpics(
  getServicesEpic,
)
