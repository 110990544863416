import { Filters } from 'actff-bo-app/components/Filters'
import { Loader } from 'actff-bo-app/components/Loader'
import { NoItems, WithPagination } from 'actff-bo-app/components/Table'
import { PagedResult } from 'actff-bo-lib/api'
import { Filters as FiltersType } from 'actff-bo-lib/global'
import { Path, Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State } from 'actff-bo-lib/store'
import {
  selectNewTestDriveRequestsCount,
  selectTestDriveRequestCurrentPage,
  selectTestDriveRequestListFilters,
  selectTestDriveRequests,
  TestDriveRequest,
  TestDriveRequestAction,
  TestDriveRequestFilterValues,
} from 'actff-bo-lib/test-drive-request'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { H2, HeaderFiltersContainer, HeaderList, Small } from 'styles'

import { TestDriveRequestTable } from './TestDriveRequestTable/TestDriveRequestTable'

type StateToProps = {
  readonly currentPage: number,
  readonly newTestDriveRequestCount: number,
  readonly testDriveRequestsFilters: FiltersType<TestDriveRequestFilterValues>,
  readonly testDriveRequests: PagedResult<TestDriveRequest> | null,
}

type DispatchToProps = {
  readonly getNewTestDriveRequestCount: () => void,
  readonly getTestDriveRequests: () => void,
  readonly onListFilterChange: (param: string, value: TestDriveRequestFilterValues) => void,
  readonly onPageChange: (nextPage: number) => void,
}

type TestDriveRequestListProps = StateToProps & DispatchToProps & WithTranslation

const testId = 'test-drive-list__'

class TestDriveRequestListComponent extends React.Component<TestDriveRequestListProps> {
  public componentDidMount(): void {
    this.props.getTestDriveRequests()
    this.props.getNewTestDriveRequestCount()
  }

  public render(): React.ReactNode {
    const {
      currentPage,
      newTestDriveRequestCount,
      onListFilterChange,
      onPageChange,
      testDriveRequests,
      testDriveRequestsFilters,
      t,
    } = this.props

    return testDriveRequests
      ? (
        <>
          <HeaderList>
            <H2>{t('testDriveRequests.header')}</H2>
            <Small>{t('testDriveRequest.newRequests', { count: newTestDriveRequestCount })}</Small>
            <HeaderFiltersContainer>
              <Filters filters={testDriveRequestsFilters} onFilterChange={onListFilterChange} testId={testId} />
            </HeaderFiltersContainer>
          </HeaderList>
          {
            testDriveRequests.result.length
              ? (
                <WithPagination
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  pageCount={testDriveRequests.noOfPages}
                  path={Paths.TestDriveRequests as Path}
                  rowsInCurrentPage={testDriveRequests.result.length}
                  testId={testId}
                >
                  <TestDriveRequestTable testDrives={testDriveRequests} testId={testId} />
                </WithPagination>
              )
              : <NoItems icon={IconType.NoCars} text='brak' />
          }
        </>
      )
      : <Loader />
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = (state: State) => ({
  currentPage: selectTestDriveRequestCurrentPage(state),
  newTestDriveRequestCount: selectNewTestDriveRequestsCount(state),
  testDriveRequests: selectTestDriveRequests(state),
  testDriveRequestsFilters: selectTestDriveRequestListFilters(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = (dispatch: Dispatch) => ({
  getNewTestDriveRequestCount: () => { dispatch(TestDriveRequestAction.getNewTestDriveRequestCount()) },
  getTestDriveRequests: () => { dispatch(TestDriveRequestAction.getTestDriveRequests()) },
  onListFilterChange: (param: string, value: TestDriveRequestFilterValues) => {
    dispatch(TestDriveRequestAction.changeListFilter(param, value))
  },
  onPageChange: (nextPage: number) => { dispatch(TestDriveRequestAction.changePage(nextPage)) },
})

export const TestDriveRequestList = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation(),
  )(TestDriveRequestListComponent)
