import { Icon } from 'actff-bo-app/components/Icon'
import { Attachment, AttachmentType } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu'
import PDFIcon from 'assets/icons/pdf.svg'
import Lightbox from 'lightbox-react'
import * as React from 'react'
import { noop } from 'rxjs'
import styled from 'styled-components'

type Props = {
  readonly files: ReadonlyArray<Attachment>,
  readonly onDeleteFile: (attachment: Attachment) => () => void,
}

type State = {
  readonly previewFile: Attachment | null,
  readonly showPreview: boolean,
}

const FilesContainer = styled.div`
  display: block;
  max-height: 136px;
  overflow: auto;
`

const File = styled.div`
  display: inline-block;
  height: 68px;
  padding: 10px;
  position: relative;
  width: 68px;

  & .close-icon {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: -1px;
    top: -1px;
    visibility: hidden;
    width: 20px;
  }

  :hover > .close-icon {
    visibility: visible;
  }
`

const FileThumbnail = styled.img`
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
  object-fit: cover;
  width: 100%;
`

export class PreviewFiles extends React.Component<Props> {
  public readonly state: State = {
    previewFile: null,
    showPreview: false,
  }

  public render(): React.ReactNode {
    const { previewFile, showPreview } = this.state
    const { files, onDeleteFile } = this.props

    return (
      <>
        {showPreview
          && previewFile
          && <Lightbox mainSrc={URL.createObjectURL(previewFile.file)} onCloseRequest={this.toggleLightbox()} />
        }
        {files && (
          <FilesContainer>
            {files.map((attachment: Attachment, index: number) =>
              <File key={index}>
                <FileThumbnail
                  onClick={attachment.type === AttachmentType.Image ? this.toggleLightbox(attachment) : noop}
                  src={attachment.type === AttachmentType.Image ? (URL.createObjectURL(attachment.file)) : PDFIcon}
                />
                <Icon className='close-icon' onClick={onDeleteFile(attachment)} type={IconType.CloseCircle} />
              </File>,
            )}
          </FilesContainer>
        )}
      </>
    )
  }

  private readonly toggleLightbox = (previewFile?: Attachment) => () => {
    this.setState({
      previewFile,
      showPreview: !this.state.showPreview,
    })
  }
}
