import { createAction } from '@reduxjs/toolkit'
import { AjaxError } from 'rxjs/ajax'
import { GetOilTypesPayload } from './dto'
import { dealershipSlice } from './slice'

export const addDealerBrandAction = createAction<string>('[DEALERSHIP] ADD DEALER BRAND')
export const addDealerBrandActionFailure = createAction('[DEALERSHIP] ADD DEALER BRAND FAILURE')

export const getDealerBrandsAction = createAction('[DEALERSHIP] GET DEALER BRANDS')
export const getDealerBrandsFailure = createAction<AjaxError>('[DEALERSHIP] GET DEALER BRANDS FAILURE')

export const getDealerLocationsAction = createAction('[DEALERSHIP] GET DEALER LOCATIONS')

export const getDealerLocationsFailure =
  createAction<AjaxError, 'DEALERSHIP - GET DEALER LOCATIONS FAILURE'>('DEALERSHIP - GET DEALER LOCATIONS FAILURE')

export const getCarModelsAction = createAction<ReadonlyArray<string> | undefined>('[DEALERSHIP] GET CAR MODELS')
export const getCarModelsFailure = createAction<AjaxError>('[DEALERSHIP] GET CAR MODELS FAILURE')

export const getCarVersionsAction = createAction<ReadonlyArray<string> | undefined>('[DEALERSHIP] GET CAR VERSIONS')
export const getCarVersionsFailure = createAction<AjaxError>('[DEALERSHIP] GET CAR VERSIONS FAILURE')

export const getEngineCodesAction = createAction('[DEALERSHIP] GET ENGINE CODES')
export const getEngineCodesFailure = createAction<AjaxError>('[DEALERSHIP] GET ENGINE CODES FAILURE')

export const getOilTypesAction = createAction<GetOilTypesPayload>('[DEALERSHIP] GET OIL TYPES')
export const getOilTypesFailure = createAction<AjaxError>('[DEALERSHIP] GET OIL TYPES FAILURE')

export const getBrakeLiquidsAction = createAction<string>('[DEALERSHIP] GET BRAKE LIQUIDS')
export const getBrakeLiquidsFailure = createAction<AjaxError>( '[DEALERSHIP] GET BRAKE LIQUIDS FAILURE')

export const {
  clearBrakeLiquids,
  clearCarModels,
  clearCarVersions,
  clearOilTypes,
  getDealerBrandsSuccess,
  getDealerLocationsSuccess,
  getCarModelsSuccess,
  getCarVersionsSuccess,
  getEngineCodesSuccess,
  getOilTypesSuccess,
  getBrakeLiquidsSuccess,
  setCurrentBrandAction,
  setCurrentLocationAction,
} = dealershipSlice.actions
