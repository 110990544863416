import { Divider as MaterialDivider } from '@material-ui/core'
import styled from 'styled-components'
import { colors, LinkButton } from 'styles'

export const Link = styled(LinkButton)`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 300;
  height: 24px;
  letter-spacing: 0.4px;
  line-height: 24px;
`

export const PopoverContentContainer = styled.div`
  display: grid;
  padding: 15px;
`

export const Divider = styled(MaterialDivider)`
  margin: 15px 0 !important;
`

export const Strong = styled.b`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  letter-spacing: 0.6px;
`

export const Filters = styled.div`
  align-self: center;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  justify-self: right;
`

export const ExpandCollapseContainer = styled.div`
  align-self: center;
`

export const CurrentAssignmentFilterCaptionContainer = styled.span`
  color: ${colors.kumera};
  text-transform: uppercase;
`
