import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectApprovalsContentFeature = (state: State) => state.approvalsContent

export const selectApprovalsContent = createSelector(selectApprovalsContentFeature, privacyPolicy => privacyPolicy.data)

export const selectPersonalDataApprovalsContent = createSelector(selectApprovalsContent, approvalsContent => approvalsContent
  ? approvalsContent.personalData.general.document
  : null,
)

export const selectIsLoadingApprovalsContent =
  createSelector(selectApprovalsContentFeature, privacyPolicy => privacyPolicy.loading)
