import { OptionTypeBase } from 'react-select'
import { StylesConfig } from 'react-select/src/styles'
import { css } from 'styled-components/macro'
import { colors } from 'styles'

export const singleOptionStyles = css`
  line-height: 18px;
  padding: 8px;
`

export const multiSelectStyles = (): StylesConfig<OptionTypeBase, false> => ({
  clearIndicator: restStyles => ({
    ...restStyles,
    color: `${colors.black}`,
    height: '16px',
    padding: '0',
    width: '16px',
  }),
  container: restStyles => ({
    ...restStyles,
  }),
  control: restStyles => ({
    ...restStyles,
    border: `1.5px solid ${colors.mysticGray}`,
    borderRadius: '3px',
    color: `${colors.dark}`,
    fontSize: '13px',
    height: '32px',
    marginTop: '7px',
    minHeight: '32px',
    padding: '0 2px',
  }),
  dropdownIndicator: restStyles => ({
    ...restStyles,
    color: `${colors.black}`,
    height: '16px',
    padding: '0',
    width: '16px',
  }),
  indicatorSeparator: () => ({
    width: '0',
  }),
  indicatorsContainer: restStyles => ({
    ...restStyles,
    height: '28px',
    lineHeight: '28px',
    padding: '0',
  }),
  input: restStyles => ({
    ...restStyles,
    height: '24px',
    margin: '0',
    padding: '0',
    placeholder: {
      top: '0%',
    },
  }),
  menu: restStyles => ({
    ...restStyles,
    lineHeight: '28px',
    width: '200px',
  }),
  multiValue: restStyles => ({
    ...restStyles,
    lineHeight: '18px',
    margin: '0 5px 0 -5px',
  }),
  option: restStyles => ({
    ...restStyles,
    padding: '0',
  }),
  placeholder: restStyles => ({
    ...restStyles,
    color: `${colors.black}`,
    fontWeight: 'normal',
    lineHeight: '32px',
    margin: '0 0 0 -5px',
  }),
  valueContainer: restStyles => ({
    ...restStyles,
    lineHeight: '32px',
  }),
})
