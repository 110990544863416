// tslint:disable max-file-line-count
import { BtnTheme, BtnType } from 'actff-bo-app/components/Button'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { BusinessEventsHistory } from 'actff-bo-app/components/EventsHistory'
import { FormFooter, FormFooterLeft, FormFooterRight, getDateValue } from 'actff-bo-app/components/Form'
import { UploadFileProgress } from 'actff-bo-app/components/UploadFile'
import { OpportunityCommentsAndServiceHistory } from 'actff-bo-app/Crm/OpportunityCommentsAndServiceHistory'
import { OpportunityOwner } from 'actff-bo-app/Crm/OpportunityOwner'
import { Content } from 'actff-bo-app/Crm/Styled'
import { AddOpportunityEvent } from 'actff-bo-app/Crm/Trade/AddOpportunityEvent'
import { MatchedPurchaseOpportunities } from 'actff-bo-app/Crm/Trade/MatchedOpportunities/MatchedPurchaseOpportunities'
import { OpportunityStatus as OpportunityStatusComponent } from 'actff-bo-app/Crm/Trade/OpportunityStatus'
import {
  OpportunityAdditionalInformation,
} from 'actff-bo-app/Crm/Trade/Purchase/OpportunityPurchaseView/OpportunityAdditionalInformation'
import {
  OpportunitySale,
  OpportunitySaleId,
  OpportunitySaleStatusType,
  OpportunityTradeEventType,
  OpportunityTradeFailureReasonType,
  OpportunityTradeInternalEventFormWithAttachments,
  TradeOpportunityDto,
} from 'actff-bo-lib/crm/trade'
import { CrmTradeOpportunityPurchaseAction } from 'actff-bo-lib/crm/trade/purchase'
import { CrmTradeOpportunitySaleAction } from 'actff-bo-lib/crm/trade/sale'
import { selectCurrentOpportunitySale } from 'actff-bo-lib/crm/trade/sale/selectors'
import { buildUrl, hasErrors, mapToOptions, SelectOption } from 'actff-bo-lib/global'
import { selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { IconType, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { hasPermission, selectMeWithoutPermissions, selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useParams } from 'react-router-dom'
import { Header, LinkButton, PrimaryButton } from 'styles'

import {
  ActionData,
  ButtonWithIconStyled,
  OpportunityContent,
  QuickNotificationsButton,
  StatusWithActionData,
} from '../../Styled'
import { Preferences } from './Preferences'

type OpportunityViewParams = {
  readonly opportunityId: OpportunitySaleId,
}

type OpportunitySaleViewForm = {
  readonly status: OpportunitySaleStatusType | null,
  readonly actionDate?: Date | null,
  readonly failureReason?: OpportunityTradeFailureReasonType | null,
  readonly failureExplanation?: string,
  readonly invoiceNumber?: string,
}

const testId = 'Opportunity-Sale-View__'

type OpportunityViewComponentProps = RouteComponentProps<OpportunityViewParams>

export const OpportunitySaleView: FC<OpportunityViewComponentProps> = () => {
  const dispatch = useDispatch()
  const { opportunityId } = useParams<OpportunityViewParams>()
  const [eventDialogVisible, setEventDialogVisible] = useState(false)

  const { control, errors, formState: { isValid, isDirty }, handleSubmit, register, setValue, reset } =
    useForm<OpportunitySaleViewForm>({
      defaultValues: {
        failureExplanation: '',
        failureReason: null,
        status: null,
      },
      mode: 'onChange',
    })

  const clearCurrentOpportunity = useMemo(() => () => {
    dispatch(CrmTradeOpportunityPurchaseAction.clearCurrentOpportunity())
  }, [dispatch])

  const getCurrentOpportunity = useMemo(() => (uuid: OpportunitySaleId) => {
    dispatch(CrmTradeOpportunitySaleAction.getOpportunity(uuid))
  }, [dispatch])

  const userPermissions = useSelector(selectUserPermissions)
  const hasAdminAllServicePermissions = hasPermission([UserPermissions.AdminAllService])(userPermissions)
  const isAcceptButtonDisabled = !isValid || !isDirty || hasErrors(errors)

  const sendNewComment = (opportunitySale: OpportunitySale, comment: string) =>
    dispatch(CrmTradeOpportunitySaleAction.sendInternalComment(opportunitySale.uuid, { comment }))

  useEffect(() => {
    getCurrentOpportunity(opportunityId)

    return () => { clearCurrentOpportunity() }
  }, [getCurrentOpportunity, opportunityId])

  const { t } = useTranslation()
  const currentOpportunity = useSelector(selectCurrentOpportunitySale)
  const me = useSelector(selectMeWithoutPermissions)
  const currentLanguage = useSelector(selectCurrentLanguage)
  const shouldDisableQuickNotificationsButton = () => !(currentOpportunity?.client || currentOpportunity?.owner)
  const eventTypes = mapToOptions(Object.keys(OpportunityTradeEventType), 'crmTrade.eventType', t)

  const watchedStatus = useWatch({ control, name: 'status', defaultValue: currentOpportunity?.status })
  const toggleDialogVisible = () => { setEventDialogVisible(!eventDialogVisible) }
  const handleGoBack = () => { history.goBack() }
  const handleCancel = () => { reset() }
  const getOwner = () => currentOpportunity?.client || currentOpportunity?.owner

  const updateOpportunity = (opportunityData: TradeOpportunityDto) => currentOpportunity &&
    dispatch(CrmTradeOpportunitySaleAction.updateOpportunity(opportunityData))

  const handleQuickNotificationsClick = (opportunitySale: OpportunitySale | null) =>
    () => opportunitySale?.client?.phone ? history.push(buildUrl(
      Paths.CrmServiceQuickCampaign,
      { phone: opportunitySale.client.phone }),
    ) : history.push(Paths.CrmServiceQuickCampaign)

  const submitForm = (opportunitySale: OpportunitySale) => handleSubmit(formValues => {
    const { failureExplanation, failureReason, invoiceNumber, status, ...restFormValues } = formValues

    updateOpportunity({
      ...restFormValues,
      ...(status === OpportunitySaleStatusType.FAILURE && { failureReason, failureExplanation }),
      ...(status === OpportunitySaleStatusType.SUCCESS && { invoiceNumber }),
      status: formValues.status || currentOpportunity?.status,
      uuid: opportunitySale.uuid,
    })
  })

  const handleNewCommentSend = (comment: string) => {
    if (me && currentOpportunity) {
      sendNewComment(currentOpportunity, comment)
    }
  }

  const sendEvent = (id: OpportunitySaleId) => (event: OpportunityTradeInternalEventFormWithAttachments) => {
    toggleDialogVisible()
    const { attachments, ...restEvent } = event
    dispatch(CrmTradeOpportunitySaleAction.sendInternalEventWithAttachments(id, {
      ...restEvent,
    }, attachments))
  }

  useEffect(() => {
    if (currentOpportunity) {
      setValue('status', currentOpportunity.status)
      setValue('failureReason', currentOpportunity.failureReason)
      setValue('failureExplanation', currentOpportunity.failureExplanation)
      setValue('invoiceNumber', currentOpportunity.invoiceNumber)
    }
  }, [currentOpportunity, setValue])

  const getQuickNotificationsButton = () => hasAdminAllServicePermissions && (
    <QuickNotificationsButton
      disabled={shouldDisableQuickNotificationsButton()}
      theme={BtnTheme.Link}
      iconType={IconType.Speed}
      caption={t('quickNotifications')}
      noPadding={false}
      onClick={handleQuickNotificationsClick(currentOpportunity)}
    />
  )

  if (!currentOpportunity) { return null }

  return (
    <>
      <div>
        <Header>
          <h2>{t('crm.opportunitySale')}</h2>
        </Header>
        <Content>
          <OpportunityContent>
            <StatusWithActionData>
              <Controller
                render={({ onChange, value }) => (
                  <OpportunityStatusComponent
                    currentStatus={value}
                    onChange={onChange}
                    statuses={Object.values(OpportunitySaleStatusType)}
                  />
                )}
                defaultValue={currentOpportunity.status}
                control={control}
                name='status'
              />
              <ActionData>
                <label>{t('crmTrade.opportunitySale.opportunityDate')}:</label>
                <Controller
                  render={({ onChange, value }) => (
                    <DatePickerInput timeDisabled={true} date={getDateValue(value)} onChange={onChange} />
                  )}
                  defaultValue={currentOpportunity.actionDate}
                  control={control}
                  name='actionDate'
                />
              </ActionData>
            </StatusWithActionData>
            <OpportunityAdditionalInformation
              control={control}
              currentLanguage={currentLanguage}
              currentStatus={watchedStatus as OpportunitySaleStatusType}
              errors={errors}
              opportunity={currentOpportunity}
              register={register}
              testId={testId}
            />
            <OpportunityOwner owner={getOwner()} header='crmTrade.buyer' />
            <Preferences preferences={currentOpportunity?.preferences} />
            <MatchedPurchaseOpportunities />
            <BusinessEventsHistory
              className='internal-comments'
              businessEvents={currentOpportunity.businessEvents}
              currentLanguage={currentLanguage}
            />
          </OpportunityContent>
          <OpportunityCommentsAndServiceHistory comments={currentOpportunity.comments} onSend={handleNewCommentSend} />
        </Content>
        <FormFooter>
          <FormFooterLeft>
            <LinkButton
              onClick={handleGoBack}
              type={BtnType.Button}
              noPadding={true}
            >
              {t('caption.back')}
            </LinkButton>
          </FormFooterLeft>
          <FormFooterRight>
            {getQuickNotificationsButton()}
            <LinkButton onClick={handleCancel} type={BtnType.Button}>{t('caption.cancel')}</LinkButton>
            <ButtonWithIconStyled
              theme={BtnTheme.Minor}
              iconType={IconType.Event}
              caption={t('registerEvent')}
              onClick={() => setEventDialogVisible(true)}
              noPadding={false}
            />
            <PrimaryButton
              disabled={isAcceptButtonDisabled}
              onClick={submitForm(currentOpportunity)}
              type={BtnType.Button}
            >
              {t('caption.accept')}
            </PrimaryButton>
          </FormFooterRight>
        </FormFooter>
      </div>
      <UploadFileProgress />
      <AddOpportunityEvent
        currentUser={me}
        onClose={toggleDialogVisible}
        open={eventDialogVisible}
        options={eventTypes as ReadonlyArray<SelectOption<OpportunityTradeEventType>>}
        sendEvent={sendEvent(currentOpportunity.uuid)}
      />
      <UploadFileProgress />
    </>
  )
}
