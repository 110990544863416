import { CSSProperties } from 'react'
import { colors } from 'styles'

export const PhotosHeaderStyles: CSSProperties = {
  color: colors.dustyGray,
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '23px',
  textTransform: 'uppercase',
}
