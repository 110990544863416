import { OwnershipAction, OwnershipActionType } from 'actff-bo-lib/car/actions/ownership'
import {
  deleteOwnershipAppDriver,
  deleteOwnershipNdcDriver,
  deleteOwnershipNdcOwner,
  getOwnedCar,
  getOwnershipCounterpartyList,
  updateOwnershipAppDriver,
  updateOwnershipNdcDriver,
  updateOwnershipNdcOwner,
} from 'actff-bo-lib/car/dao'
import {
  selectCounterpartyListCurrentPage,
  selectCounterpartyListSearchPhrase,
} from 'actff-bo-lib/car/selectors/ownership'
import { retryWithDelay } from 'actff-bo-lib/global/operators'
import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'

const ownedCarDelay = 2000
const ownedCarRetryCount = 3

const changeNdcPersonListParamsEpic: Epic<OwnershipAction, OwnershipAction, State> = action$ => action$.pipe(
  ofType(OwnershipActionType.ChangeCounterpartyListPage),
  map(OwnershipAction.getCounterpartyList),
)

const changeSearchPhraseEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType(OwnershipActionType.ChangeAppPersonListSearchPhrase),
  map(() => OwnershipAction.changeAppPersonListPage(1)),
)

const getOwnedCarEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OwnershipAction.getOwnedCarByVin>>(OwnershipActionType.GetOwnedCarByVin),
  switchMap(({ payload }) => getOwnedCar(payload).pipe(
    retryWithDelay(ownedCarDelay, ownedCarRetryCount),
    map(OwnershipAction.getOwnedCarByVinSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.getOwnedCarByVinFailure(error)),
  )),
))

const getNdcPersonListEpic: Epic<OwnershipAction> = (action$, store$) => action$.pipe(
  ofType(OwnershipActionType.GetCounterpartyList),
  withLatestFrom(store$),
  switchMap(([, state]) => getOwnershipCounterpartyList(
    selectCounterpartyListCurrentPage(state) - 1,
    encodeStringIfExists(selectCounterpartyListSearchPhrase(state)),
  ).pipe(
    map(OwnershipAction.getCounterpartyListSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.getCounterpartyListFailure(error)),
  )),
))

const updateAppDriverEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OwnershipAction.updateAppDriver>>(OwnershipActionType.UpdateAppDriver),
  switchMap(({ payload }) => updateOwnershipAppDriver(payload.vin, payload.appPerson).pipe(
    map(OwnershipAction.updateAppDriverSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.updateAppDriverFailure(error))),
  )),
)

const updateNdcDriverEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OwnershipAction.updateNdcDriver>>(OwnershipActionType.UpdateNdcDriver),
  switchMap(({ payload }) => updateOwnershipNdcDriver(payload.vin, payload.ndcPerson).pipe(
    map(OwnershipAction.updateNdcDriverSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.updateNdcDriverFailure(error))),
  )),
)

const updateNdcOwnerEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OwnershipAction.updateNdcOwner>>(OwnershipActionType.UpdateNdcOwner),
  switchMap(({ payload }) => updateOwnershipNdcOwner(payload.vin, payload.ndcPerson).pipe(
    map(OwnershipAction.updateNdcOwnerSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.updateNdcOwnerFailure(error))),
  )),
)

const deleteAppDriverEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OwnershipAction.deleteAppDriver>>(OwnershipActionType.DeleteAppDriver),
  switchMap(({ payload }) => deleteOwnershipAppDriver(payload).pipe(
    map(OwnershipAction.deleteAppDriverSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.deleteAppDriverFailure(error)),
  )),
))

const alertOnUpdateOwnershipFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof OwnershipAction.updateNdcOwnerFailure>
    | ReturnType<typeof OwnershipAction.updateAppDriverFailure>
    | ReturnType<typeof OwnershipAction.updateNdcDriverFailure>
    | ReturnType<typeof OwnershipAction.updateNdcOwnerFailure>
    | ReturnType<typeof OwnershipAction.deleteAppDriverFailure>
    | ReturnType<typeof OwnershipAction.deleteNdcDriverFailure>
    | ReturnType<typeof OwnershipAction.deleteNdcOwnerFailure>
  >(
    OwnershipActionType.UpdateNdcOwnerFailure,
    OwnershipActionType.UpdateAppDriverFailure,
    OwnershipActionType.UpdateNdcDriverFailure,
    OwnershipActionType.DeleteNdcOwnerFailure,
    OwnershipActionType.DeleteNdcDriverFailure,
    OwnershipActionType.DeleteAppDriverFailure,
  ),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      body: payload.message,
      id: 'api.actionFailure',
      title: 'api.actionFailure',
      type: ToastType.Error,
    }),
  ]),
)

const alertOnUpdateOwnershipSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof OwnershipAction.updateNdcOwnerSuccess>
    | ReturnType<typeof OwnershipAction.updateAppDriverSuccess>
    | ReturnType<typeof OwnershipAction.updateNdcDriverSuccess>
    | ReturnType<typeof OwnershipAction.deleteAppDriverSuccess>
    | ReturnType<typeof OwnershipAction.deleteNdcDriverSuccess>
    | ReturnType<typeof OwnershipAction.deleteNdcOwnerSuccess>
  >(
    OwnershipActionType.UpdateNdcOwnerSuccess,
    OwnershipActionType.UpdateAppDriverSuccess,
    OwnershipActionType.UpdateNdcDriverSuccess,
    OwnershipActionType.DeleteNdcOwnerSuccess,
    OwnershipActionType.DeleteNdcDriverSuccess,
    OwnershipActionType.DeleteAppDriverSuccess,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: 'toast.updateActionSuccess',
      id: 'api.actionSuccess',
      title: 'toast.success',
      type: ToastType.Success,
    }),
  ]),
)

const alertOnGetOwnedCarFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof OwnershipAction.getOwnedCarByVinFailure>
    | ReturnType<typeof OwnershipAction.getOwnedCarByIdFailure>
    >(
      OwnershipActionType.GetOwnedCarByVinFailure,
      OwnershipActionType.GetOwnedCarByIdFailure,
    ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: 'ownership.failure.getOwnedCarFailure.alert.body',
      id: 'ownership.failure.getOwnedCarFailure.alert.title',
      title: 'ownership.failure.getOwnedCarFailure.alert.title',
      type: ToastType.Error,
    }),
  ]),
)
const alertOnGetOwnershipPersonListFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof OwnershipAction.getAppPersonListFailure>
    | ReturnType<typeof OwnershipAction.getCounterpartyListFailure>
    >(
      OwnershipActionType.GetAppPersonListFailure,
      OwnershipActionType.GetCounterpartyListFailure,
    ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 5000,
      id: 'ownership.failure.getPersonList.alert.msg',
      title: 'ownership.failure.getPersonList.alert.msg',
      type: ToastType.Error,
    }),
  ]),
)

const deleteNdcDriverEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OwnershipAction.deleteNdcDriver>>(OwnershipActionType.DeleteNdcDriver),
  switchMap(({ payload }) => deleteOwnershipNdcDriver(payload).pipe(
    map(OwnershipAction.deleteNdcDriverSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.deleteNdcDriverFailure(error)),
  )),
))

const deleteNdcOwnerEpic: Epic<OwnershipAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OwnershipAction.deleteNdcOwner>>(OwnershipActionType.DeleteNdcOwner),
  switchMap(({ payload }) => deleteOwnershipNdcOwner(payload).pipe(
    map(OwnershipAction.deleteNdcOwnerSuccess),
    catchError((error: AjaxError) => of(OwnershipAction.deleteNdcOwnerFailure(error)),
  )),
))

export const ownershipEpic = combineEpics(
  alertOnGetOwnedCarFailureEpic,
  alertOnGetOwnershipPersonListFailureEpic,
  alertOnUpdateOwnershipFailureEpic,
  alertOnUpdateOwnershipSuccessEpic,
  changeNdcPersonListParamsEpic,
  changeSearchPhraseEpic,
  deleteAppDriverEpic,
  deleteNdcDriverEpic,
  deleteNdcOwnerEpic,
  getNdcPersonListEpic,
  getOwnedCarEpic,
  updateAppDriverEpic,
  updateNdcDriverEpic,
  updateNdcOwnerEpic,
)
