import { PagedResult } from 'actff-bo-lib/api'
import { Client } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { ClientRow } from './ClientRow'

type ClientTableProps = {
  readonly clients: PagedResult<Client>,
}

const testId = 'client-list__'

export const ClientTable: React.FC<ClientTableProps> = ({
  clients: { result },
}) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
          <Tr>
            <Th>{t('clientList.columns.date')}</Th>
            <Th>{t('table.columns.status')}</Th>
            <Th>{t('table.columns.fullName')}</Th>
            <Th>{t('table.columns.type')}</Th>
            <Th>{t('table.columns.phone')}</Th>
            <Th>{t('table.columns.email')}</Th>
            <Th>{t('table.columns.cars')}</Th>
            <Th>{t('table.columns.approvals')}</Th>
          </Tr>
        </thead>
        <tbody>
          {result.length && result.map((client, index) =>
            <ClientRow client={client} key={client.uuid} testId={`${testId}client-${index}`} />,
          )}
        </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )

}
