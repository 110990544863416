import { SpanStyled } from 'actff-bo-app/Cars/CarView/Styled'
import { Loader } from 'actff-bo-app/components/Loader'
import {
  CarId,
  CarInfoWithClient,
  CarInsurance,
  CarInsuranceAction,
  CarInsuranceWithCarUuid,
  selectCurrentCarInsurance,
  selectIsLoadingCarInsurance,
} from 'actff-bo-lib/car'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { State } from 'actff-bo-lib/store'
import { selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import { hasPermission } from 'actff-bo-lib/user/has-permission'
import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'

import { withCarViewHeader } from '../withCarViewHeader'
import { CarViewInsuranceForm } from './CarViewInsuranceForm'
import { CarViewInsuranceReadonly } from './CarViewInsuranceReadonly'

type DispatchToProps = {
  readonly getCarInsurance: (carId: CarId) => void,
  readonly updateCarInsurance: (carInsurance: CarInsuranceWithCarUuid) => void,
}

type StateToProps = {
  readonly carInsurance: CarInsurance | null,
  readonly currentLanguage: Language,
  readonly isLoading: boolean,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

type CarViewInsuranceComponentProps = {
  readonly car: CarInfoWithClient,
  readonly rejectCar: (carId: CarId) => void,
  readonly deleteCar: (carId: CarId) => void,
  readonly revertCar: (carId: CarId) => void,
}

export type CarViewInsuranceProps = StateToProps & DispatchToProps & WithTranslation & CarViewInsuranceComponentProps

class CarViewInsuranceComponent extends React.Component<CarViewInsuranceProps> {
  public componentDidMount(): void {
    this.getCarInsurance()
  }

  public render(): React.ReactNode {
    const {
      car,
      carInsurance,
      currentLanguage,
      deleteCar,
      isLoading,
      rejectCar,
      revertCar,
      updateCarInsurance,
      userPermissions,
      t,
    } = this.props

    if (isLoading) {
      return <Loader />
    }

    if (hasPermission([
      UserPermissions.InsuranceEditInsurance,
      UserPermissions.ServiceEditInsurance,
      UserPermissions.FinanceEditInsurance,
    ])(userPermissions)) {
      return (
        <CarViewInsuranceForm
          car={car}
          carInsurance={carInsurance}
          rejectCar={rejectCar}
          deleteCar={deleteCar}
          revertCar={revertCar}
          updateCarInsurance={updateCarInsurance}
        />
      )
    }

    if (!carInsurance) {
      return (
        <SpanStyled>{t('carView.form.insurance.noData')}</SpanStyled>
      )
    }

    return (
      <CarViewInsuranceReadonly carInsurance={carInsurance} currentLanguage={currentLanguage} />
    )
  }

  private readonly getCarInsurance = () => { this.props.getCarInsurance(this.props.car.uuid) }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getCarInsurance: (carId: CarId) => { dispatch(CarInsuranceAction.getCarInsurance(carId)) },
  updateCarInsurance: (carInsurance: CarInsuranceWithCarUuid) => {
    dispatch(CarInsuranceAction.updateCarInsurance(carInsurance))
  },
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  carInsurance: selectCurrentCarInsurance(state),
  currentLanguage: selectCurrentLanguage(state),
  isLoading: selectIsLoadingCarInsurance(state),
  userPermissions: selectUserPermissions(state),
})

export const CarViewInsurance = compose(
  withCarViewHeader,
  connect(mapStateToProps, mapDispatchToProps),
)(CarViewInsuranceComponent)
