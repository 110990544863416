import { ClientBasicData, ClientStatus, formatPhoneNumber } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly client: ClientBasicData,
}

export const ClientPhone: React.FC<Props> = ({ client }) => {
  const { t } = useTranslation()

  return client && client?.status !== ClientStatus.Deleted
    ? <>{formatPhoneNumber(client.phone)}</>
    : <>{t('caption.noData')}</>
}
