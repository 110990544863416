import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  CommunicationsMethod,
  CommunicationsTopic,
  getRegulationsAndApprovals,
  RegulationsAndApprovalsForm as RegulationsAndApprovalsFormType,
  updateRegulationsAndApprovals,
} from 'actff-bo-lib/admin/dealership'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { ViewTabs } from '../ViewTabs'
import { RegulationsAndApprovalsForm } from './RegulationsAndApprovalsForm'

const testId = 'admin-view-dealership--insurers__'

const RegulationsAndApprovalsComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, isLoading, isError, refetch } = useQuery(QueryKeys.GET_REGULATIONS_AND_APPROVALS, async () =>
      await getRegulationsAndApprovals(),
    { retry: false },
  )

  const updateRegulationsAndApprovalsMutation = useMutation(
    QueryKeys.UPDATE_REGULATIONS_AND_APPROVALS,
    updateRegulationsAndApprovals,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.dealership.regulationsAndApprovals.update.failure', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch(),
        dispatch(displaySuccessToast('admin.dealership.regulationsAndApprovals.update.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const handleUpdate = (formData: RegulationsAndApprovalsFormType) => {
    updateRegulationsAndApprovalsMutation.mutate([
      {
        document: formData.personalData,
        method: CommunicationsMethod.GENERAL,
        topic: CommunicationsTopic.PERSONAL,
      },
      {
        document: formData.termsAndConditions,
        method: CommunicationsMethod.GENERAL,
        topic: CommunicationsTopic.TERMS_AND_CONDITIONS,
      },
    ])
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <LocalLoader />
      </>
    )
  }

  if (!data) {
    return (
      <>
        <ViewTabs testId={testId} />
        <NoData>{t('caption.noData')}</NoData>
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('api.action.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <RegulationsAndApprovalsForm data={data?.contactApprovals} onUpdate={handleUpdate} />
    </>
  )
}

export const RegulationsAndApprovals = withAdminViewHeader(RegulationsAndApprovalsComponent)
