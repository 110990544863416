import { Tabs } from 'actff-bo-app/Navigation'
import { Testable } from 'actff-bo-lib/global/testable'
import { Path, Paths, TabMenuItem } from 'actff-bo-lib/menu'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { H2 } from 'styles'
import { ViewTabsHeader } from '../Styled'

const getTabItems = (testId: string, t: i18next.TFunction): ReadonlyArray<TabMenuItem> => ([
  {
    name: t('admin.dealership.insurers'),
    path: Paths.AdminDealershipInsurers as Path,
    testId: `${testId}tabs--insurers`,
  },
  {
    name: t('admin.dealership.insurersQuestions'),
    path: Paths.AdminDealershipInsurersQuestions as Path,
    testId: `${testId}tabs--insurers-questions`,
  },
  {
    name: t('admin.dealership.regulationsAndApprovals'),
    path: Paths.AdminDealershipRegulationsAndApprovals as Path,
    testId: `${testId}tabs--regulations-and-approvals`,
  },
  {
    name: t('admin.dealership.holidays'),
    path: Paths.AdminDealershipHolidays as Path,
    testId: `${testId}tabs--holidays`,
  },
  {
    name: t('admin.dealership.users'),
    path: Paths.AdminDealershipUsers as Path,
    testId: `${testId}tabs--users`,
  },
])

export const ViewTabs: React.FC<Testable> = ({ testId }) => {
  const { t } = useTranslation()

  return (
    <>
      <ViewTabsHeader>
        <div><H2>{t('admin.dealership')}</H2></div>
      </ViewTabsHeader>
      <Tabs tabItems={getTabItems(testId as string, t)} />
    </>
  )
}
