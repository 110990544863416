import { combineEpics } from 'redux-observable'

import { crmInsuranceInsurerEpic } from './insurer'
import { crmOpportunityListEpic } from './list'
import { crmOpportunityEpic } from './opportunity'
import { crmInsuranceReportEpic } from './report'

export const crmInsuranceEpic = combineEpics(
  crmOpportunityEpic,
  crmOpportunityListEpic,
  crmInsuranceInsurerEpic,
  crmInsuranceReportEpic,
)
