import { Icon } from 'actff-bo-app/components/Icon'
import { BlobUrl, selectAttachmentForUrl, selectAttachmentThumbnailForUrl, thumbnailPostfix, Url } from 'actff-bo-lib/global/attachment'
import { AttachmentAction } from 'actff-bo-lib/global/attachment/actions'
import { IconType } from 'actff-bo-lib/menu'
import { State as GlobalState } from 'actff-bo-lib/store'
import Lightbox from 'lightbox-react'
import * as React from 'react'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import styled from 'styled-components'

type Props = {
  readonly url: Url,
  readonly thumbnailSize?: number,
}

type StateToProps = {
  readonly attachment?: BlobUrl,
  readonly thumbnail?: BlobUrl,
}

type DispatchToProps = {
  readonly getAttachment: (url: Url) => void,
  readonly getAttachmentThumbnail: (url: Url) => void,
}

type State = {
  readonly showDownloadButton: boolean,
  readonly showPreview: boolean,
}

type AttachmentProps = Props & StateToProps & DispatchToProps

const defaultThumbnailSize = 72

const Placeholder = styled.div`
  display: inline-block;
  height: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
  width: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
`

export const AttachmentFileStyled = styled.img`
  border-radius: 5px;
  cursor: pointer;
  height: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
  object-fit: cover;
  width: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
`

const getAttachmentThumbnailUrl = (url: Url) => {
  const [urlBeforeParams, ...params] = url.split('?')

  return urlBeforeParams + thumbnailPostfix + (params.length > 0 ? `?${params.toString()}` : '') as Url
}

class AttachmentComponent extends React.Component<AttachmentProps> {
  public readonly state: State = {
    showDownloadButton: false,
    showPreview: false,
  }

  public componentDidMount(): void {
    const { getAttachmentThumbnail, thumbnail, url } = this.props

    if (!thumbnail) {
      getAttachmentThumbnail(getAttachmentThumbnailUrl(url))
    }
  }

  public componentDidUpdate(oldProps: AttachmentProps, oldState: State): void {
    if (!oldState.showPreview && this.state.showPreview) {
      this.props.getAttachment(this.props.url)
    }
  }

  public render(): React.ReactNode {
    const { thumbnail, thumbnailSize } = this.props

    return (
      <>
        {this.state.showPreview && this.displayLightbox()}
        {thumbnail
          ? (<AttachmentFileStyled onClick={this.toggleLightbox} src={thumbnail} thumbnailSize={thumbnailSize} />)
          : <Placeholder thumbnailSize={thumbnailSize}>Loading</Placeholder> // TODO: Add loader
        }
      </>
    )
  }

  private readonly displayLightbox = () => {
    const { attachment, thumbnail } = this.props
    const { showDownloadButton } = this.state
    const DownloadButton = () => <a href={attachment} download={true}><Icon type={IconType.Download} /></a>

    return (
      attachment && (
        <Lightbox
          mainSrc={attachment}
          mainSrcThumbnail={thumbnail}
          onCloseRequest={this.toggleLightbox}
          onImageLoadError={this.setShowDownloadButton}
          enableZoom={!showDownloadButton}
          toolbarButtons={[
            showDownloadButton && <DownloadButton />,
          ]}
        />
      )
    )
  }

  private readonly setShowDownloadButton = () => {
    this.setState({
      showDownloadButton: true,
    })
  }

  private readonly toggleLightbox = () => {
    this.setState({
      showDownloadButton: false,
      showPreview: !this.state.showPreview,
    })
  }
}

const mapStateToProps: MapStateToProps<StateToProps, Props, GlobalState> = (state, props) => ({
  attachment: selectAttachmentForUrl(props.url)(state),
  thumbnail: selectAttachmentThumbnailForUrl(getAttachmentThumbnailUrl(props.url))(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getAttachment: (url: Url) => { dispatch(AttachmentAction.getAttachment(url)) },
  getAttachmentThumbnail: (url: Url) => { dispatch(AttachmentAction.getAttachmentThumbnail(url)) },
})

export const Attachment = connect(mapStateToProps, mapDispatchToProps)(AttachmentComponent)
