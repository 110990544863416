import { ErrorMessage } from '@hookform/error-message'
import { Input } from 'actff-bo-app/Admin/Dealership/Users/Styled'
import { BtnTheme } from 'actff-bo-app/components/Button'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { ButtonWithIcon, Table, Th } from 'actff-bo-app/components/EditableList/Styled'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Label } from 'actff-bo-app/components/Label'
import { CarAssistance } from 'actff-bo-lib/admin/brands/dto'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { colors, Tr } from 'styles'

import { Container } from '../../Styled'
import { AssistanceRow } from './AssistanceRow'

type Props = {
  readonly onAdd: (name: string, telephoneNumber: string) => void,
  readonly onDelete: (itemValue: CarAssistance | null) => void,
  readonly assistance?: ReadonlyArray<CarAssistance>,
}

type FormValues = {
  readonly name: string,
  readonly phoneNumber: string,
}

const FormSegment = styled.div`
  display: grid;
  grid-auto-flow: row;
`

const FormContent = styled.div`
  display: grid;
  padding: 24px 0;
  grid-row-gap: 12px;
`

export const AssistanceTable: FC<Props> = ({ assistance, onAdd, onDelete }) => {
  const { t } = useTranslation()

  const [addNewItemOpen, setAddNewItemOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<CarAssistance | null>(null)

  const {
    errors,
    handleSubmit,
    register,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      phoneNumber: '',
    },
    mode: 'onSubmit',
  })

  const toggleAddDialogOpen = () => { setAddNewItemOpen(!addNewItemOpen) }

  const onDialogClose = () => {
    toggleAddDialogOpen()
  }

  const onDialogConfirm = (formValues: FormValues) => {
    const { name, phoneNumber } = formValues
    onAdd(name, phoneNumber)
    toggleAddDialogOpen()
  }

  const handleDelete = (item: CarAssistance) => () => {
    setItemToDelete(item)
  }

  const onDeleteDialogConfirm = () => {
    onDelete(itemToDelete)
    setItemToDelete(null)
  }

  const onDeleteDialogClose = () => {
    setItemToDelete(null)
  }

  return (
    <form>
      <Container>
        <Table>
          <thead>
          <Tr>
            <Th>{t('caption.listNumber')}</Th>
            <Th>{t('admin.brands.assistance.name')}</Th>
            <Th>{t('admin.brands.assistance.telephoneNumber')}</Th>
          </Tr>
          </thead>
          <tbody>
          {assistance?.map((item, index) => (
            <AssistanceRow
              key={item.assistanceName}
              index={index}
              assistance={item}
              onDelete={handleDelete(item)}
            />
          ))}
          </tbody>
        </Table>
        <ButtonWithIcon
          caption={t('admin.brands.assistance.add')}
          onClick={toggleAddDialogOpen}
          iconType={IconType.Plus}
          theme={BtnTheme.Link}
        />
      </Container>

      <Dialog
        open={addNewItemOpen}
        onConfirm={handleSubmit(onDialogConfirm)}
        onClose={onDialogClose}
        title='admin.brands.assistance.add'
      >
        <FormContent>
          <FormSegment>
            <Label>{t('admin.brands.assistance.name')}:</Label>
            <Input
              type='text'
              name='name'
              ref={register({
                ...getValidator({ type: ValidationType.Required }),
                ...getValidator({
                  msgTKey: 'form.validation.string.noEmpty',
                  regExp: /^(?!\s*$).+/,
                  type: ValidationType.Pattern,
                }),
              })}
            />
            <ErrorMessage errors={errors} name='name' as={TranslatedErrorMessage} />
          </FormSegment>
          <FormSegment>
            <Label>{t('admin.brands.assistance.telephoneNumber')}:</Label>
            <Input
              type='text'
              name='phoneNumber'
              ref={register({
                ...getValidator({ type: ValidationType.Required }),
                ...getValidator({ type: ValidationType.PhoneNumber }),
              })}
            />
            <Label fontSize='12' uppercase={false} color={colors.black}>{t('form.validation.phoneNumber.format')}</Label>
            <ErrorMessage errors={errors} name='phoneNumber' as={TranslatedErrorMessage} />
          </FormSegment>
        </FormContent>
      </Dialog>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.delete.content'
        contentProps={{ item: itemToDelete?.assistanceName || '' }}
        open={!!itemToDelete}
        onConfirm={onDeleteDialogConfirm}
        onClose={onDeleteDialogClose}
        title='contentManagement.dialog.delete.title'
      />
    </form>
  )
}
