import { Th } from 'actff-bo-app/components/EditableList/Styled'
import { Select } from 'actff-bo-app/components/Form'
import { Label } from 'actff-bo-app/components/Label'
import { Link } from 'actff-bo-app/components/Popover'
import styled from 'styled-components/macro'
import { colors } from 'styles/colors'

export const AddingDialogContent = styled.div`
  display: grid;
  padding: 12px 0;
`

export const Container = styled.div`
  padding: 24px 0 250px 12px;
`

export const LabelStyled = styled(Label)`
  padding-bottom: 0;
`

export const LastTh = styled(Th)`
  // TODO find a way to avoid !important. Due to styles in Table.ts file L27
  border-bottom: 1px solid ${colors.boysenberryShadow} !important;
`

export const NewUserFormContainer = styled.form`
  display: grid;
  grid-row-gap: 14px;
  max-height: 850px;
  overflow-y: auto;
`

export const Password = styled.div`
  color: ${colors.gray};
`

export const ChangePasswordDialogContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 24px;
  padding-top: 24px;
`

export const FormSegment = styled.div`
  display: grid;
  grid-auto-flow: row;
  width: 70%;
`

export const Input = styled.input`
  height: 40px;
`

export const PopoverContentContainer = styled.div`
  display: grid;
  padding: 5px;
`

export const DeleteUserLink = styled(Link)`
  color: ${colors.red};
  grid-template-columns: 20px 1fr;
  padding-left: 3px;
`

export const EditPasswordLink = styled(Link)`
  align-items: center;
  grid-template-columns: 20px 1fr;
`

export const RolesSelect = styled(Select)`
  width: 200px;
`
