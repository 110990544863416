import { IconType } from 'actff-bo-lib/menu/dto'
import Accept from 'assets/icons/accept.svg'
import ArrowBottom2 from 'assets/icons/arrow-bottom-2.svg'
import ArrowBottom from 'assets/icons/arrow-bottom.svg'
import ArrowTop2 from 'assets/icons/arrow-top-2.svg'
import ArrowTop from 'assets/icons/arrow-top.svg'
import Arrow from 'assets/icons/arrow.svg'
import Attachment from 'assets/icons/attachment.svg'
import Back from 'assets/icons/back.svg'
import Bulb from 'assets/icons/bulb.svg'
import CalendarWhite from 'assets/icons/calendar-white.svg'
import Calendar from 'assets/icons/calendar.svg'
import Call from 'assets/icons/call.svg'
import CarWhite from 'assets/icons/car-white.svg'
import Car from 'assets/icons/car.svg'
import ChatOpenExpanded from 'assets/icons/chat-open-expanded.svg'
import ChatOpen from 'assets/icons/chat-open.svg'
import Chat from 'assets/icons/chat.svg'
import CheckMark from 'assets/icons/check-mark.svg'
import Clients from 'assets/icons/clients.svg'
import Clock from 'assets/icons/clock.svg'
import CloseCircle from 'assets/icons/close-circle.svg'
import CloseWhite from 'assets/icons/close-white.svg'
import Close from 'assets/icons/close.svg'
import Cog from 'assets/icons/cog.svg'
import Comment from 'assets/icons/comment.svg'
import CrmInsurance from 'assets/icons/crm-insurance.svg'
import CrmService from 'assets/icons/crm-service.svg'
import CrmTrade from 'assets/icons/crm-trade.svg'
import Crm from 'assets/icons/crm.svg'
import Cross from 'assets/icons/cross.svg'
import Crown from 'assets/icons/crown.svg'
import DangerTriangle from 'assets/icons/danger-triangle.svg'
import Dashboard from 'assets/icons/dashboard.svg'
import StatusDefinitiveFailure from 'assets/icons/definitive-failure.svg'
import DownloadGold from 'assets/icons/download-gold.svg'
import Download from 'assets/icons/download.svg'
import DropdownGold from 'assets/icons/dropdown-gold.svg'
import Dropdown from 'assets/icons/dropdown.svg'
import EditBlack from 'assets/icons/edit-black.svg'
import Edit from 'assets/icons/edit.svg'
import EmailEnvelope from 'assets/icons/email-envelope.svg'
import Email from 'assets/icons/email.svg'
import ErrorCircle from 'assets/icons/error-circle.svg'
import ErrorTriangle from 'assets/icons/error-triangle.svg'
import Event from 'assets/icons/event.svg'
import Exit from 'assets/icons/exit.svg'
import Eye from 'assets/icons/eye.svg'
import StatusFailure from 'assets/icons/failure.svg'
import Flag from 'assets/icons/flag.svg'
import Fullscreen from 'assets/icons/fullscreen.svg'
import IdCard from 'assets/icons/id-card.svg'
import ImageBig from 'assets/icons/image-big.svg'
import ImageBlack from 'assets/icons/image-black.svg'
import ImageWhite from 'assets/icons/image-white.svg'
import Info from 'assets/icons/info.svg'
import LineVertical from 'assets/icons/line-vertical.svg'
import Loader from 'assets/icons/loader.gif'
import MenuCollapse from 'assets/icons/menu-collapse.svg'
import Minus from 'assets/icons/minus.svg'
import MobilePhone from 'assets/icons/mobile-phone.svg'
import More from 'assets/icons/more.svg'
import NewPage from 'assets/icons/new-page.svg'
import NewServiceRequest from 'assets/icons/new-service-request.svg'
import NoCars from 'assets/icons/no-cars.svg'
import PDF from 'assets/icons/pdf.svg'
import Phone from 'assets/icons/phone.svg'
import PlusWhite from 'assets/icons/plus-white.svg'
import Plus from 'assets/icons/plus.svg'
import Refresh from 'assets/icons/refresh.svg'
import Ring from 'assets/icons/ring.svg'
import Rotate from 'assets/icons/rotate.svg'
import Save from 'assets/icons/save.svg'
import Search from 'assets/icons/search.svg'
import Sms from 'assets/icons/sms.svg'
import Speed from 'assets/icons/speed.svg'
import StatusSuccess from 'assets/icons/success.svg'
import TaskList from 'assets/icons/task-list.svg'
import Timer from 'assets/icons/timer.svg'
import TrashRed from 'assets/icons/trash-red.svg'
import Trash from 'assets/icons/trash.svg'
import Trash2 from 'assets/icons/trash2.svg'
import Undo from 'assets/icons/undo.svg'
import Undo2 from 'assets/icons/undo2.svg'
import CheckMarkWhite from 'assets/icons/white-check-mark.svg'

export type IconMap = Map<IconType, string>

export const IconMap: Map<IconType, string> = new Map([
  [IconType.Accept, Accept],
  [IconType.ArrowTop, ArrowTop],
  [IconType.ArrowTop2, ArrowTop2],
  [IconType.ArrowBottom, ArrowBottom],
  [IconType.ArrowBottom2, ArrowBottom2],
  [IconType.Arrow, Arrow],
  [IconType.Attachment, Attachment],
  [IconType.Back, Back],
  [IconType.Bulb, Bulb],
  [IconType.Calendar, Calendar],
  [IconType.CalendarWhite, CalendarWhite],
  [IconType.Call, Call],
  [IconType.Car, Car],
  [IconType.CarWhite, CarWhite],
  [IconType.ChatOpen, ChatOpen],
  [IconType.ChatOpenExpanded, ChatOpenExpanded],
  [IconType.Chat, Chat],
  [IconType.CheckMark, CheckMark],
  [IconType.CheckMarkWhite, CheckMarkWhite],
  [IconType.Clients, Clients],
  [IconType.Clock, Clock],
  [IconType.Close, Close],
  [IconType.CloseCircle, CloseCircle],
  [IconType.CloseWhite, CloseWhite],
  [IconType.Comment, Comment],
  [IconType.Cog, Cog],
  [IconType.Cross, Cross],
  [IconType.Crown, Crown],
  [IconType.Crm, Crm],
  [IconType.CrmInsurance, CrmInsurance],
  [IconType.CrmTrade, CrmTrade],
  [IconType.CrmService, CrmService],
  [IconType.DangerTriangle, DangerTriangle],
  [IconType.Dashboard, Dashboard],
  [IconType.Download, Download],
  [IconType.DownloadGold, DownloadGold],
  [IconType.Dropdown, Dropdown],
  [IconType.DropdownGold, DropdownGold],
  [IconType.Edit, Edit],
  [IconType.EditBlack, EditBlack],
  [IconType.Email, Email],
  [IconType.EmailEnvelope, EmailEnvelope],
  [IconType.ErrorCircle, ErrorCircle],
  [IconType.ErrorTriangle, ErrorTriangle],
  [IconType.Exit, Exit],
  [IconType.Event, Event],
  [IconType.Eye, Eye],
  [IconType.Flag, Flag],
  [IconType.Fullscreen, Fullscreen],
  [IconType.IdCard, IdCard],
  [IconType.ImageBlack, ImageBlack],
  [IconType.ImageWhite, ImageWhite],
  [IconType.ImageBig, ImageBig],
  [IconType.Info, Info],
  [IconType.LineVertical, LineVertical],
  [IconType.Loader, Loader],
  [IconType.MenuCollapse, MenuCollapse],
  [IconType.Minus, Minus],
  [IconType.MobilePhone, MobilePhone],
  [IconType.More, More],
  [IconType.NewServiceRequest, NewServiceRequest],
  [IconType.NewPage, NewPage],
  [IconType.NoCars, NoCars],
  [IconType.PDF, PDF],
  [IconType.Phone, Phone],
  [IconType.Plus, Plus],
  [IconType.PlusWhite, PlusWhite],
  [IconType.Refresh, Refresh],
  [IconType.Ring, Ring],
  [IconType.Rotate, Rotate],
  [IconType.Save, Save],
  [IconType.Search, Search],
  [IconType.Sms, Sms],
  [IconType.Speed, Speed],
  [IconType.StatusDefinitiveFailure, StatusDefinitiveFailure],
  [IconType.StatusFailure, StatusFailure],
  [IconType.StatusSuccess, StatusSuccess],
  [IconType.TaskList, TaskList],
  [IconType.Timer, Timer],
  [IconType.Trash, Trash],
  [IconType.Trash2, Trash2],
  [IconType.TrashRed, TrashRed],
  [IconType.Undo, Undo],
  [IconType.Undo2, Undo2],
])
