import { Label } from 'actff-bo-app/components/Label'
import { Testable } from 'actff-bo-lib/global/testable'
import { Field } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { RadioButtonField } from './RadioButtonField'

type YesNoInputProps = Testable & {
  readonly name: string,
  readonly darkColor?: boolean,
  readonly disabled?: boolean,
  readonly error?: string | boolean,
  readonly label?: string,
  readonly inline?: boolean,
  readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  readonly slim?: boolean,
  readonly touched?: boolean,
}

const Container = styled.div<YesNoInputProps>`
  display: ${({ inline }: YesNoInputProps) => inline ? 'inline-grid' : ''};
  grid-template-columns: 1fr 1fr;
`

export const YesNoInput: React.FC<YesNoInputProps> = ({
  darkColor = false,
  disabled = false,
  error: formikError,
  label,
  name,
  onChange,
  slim = false,
  inline = true,
  testId,
  touched,
}) => {
  const { t } = useTranslation()
  const error = touched && formikError

  return (
    <div>
      <Label
        {...(error && { className: 'error' })}
        disabled={disabled}
        error={error}
      >
        {label}
      </Label>
      <Container>
        <Field
          darkColor={darkColor}
          disabled={disabled}
          error={error}
          id={`${name}Yes`}
          label={t('caption.yes')}
          name={name}
          onChange={onChange}
          value={true}
          htmlFor={`${name}Yes`}
          component={RadioButtonField}
          inline={inline}
          slim={slim}
          testId={`${testId}--yes`}
        />
        <Field
          darkColor={darkColor}
          disabled={disabled}
          error={error}
          id={`${name}No`}
          label={t('caption.no')}
          name={name}
          onChange={onChange}
          value={false}
          htmlFor={`${name}No`}
          component={RadioButtonField}
          inline={inline}
          slim={slim}
          testId={`${testId}--no`}
        />
      </Container>
    </div>
  )
}
