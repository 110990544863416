import { ApprovalsFormValues } from 'actff-bo-app/Clients/ClientView/Approvals/withFormik'
import { Client, ContactApprovals, ContactApprovalsWithClientIdToAPI } from 'actff-bo-lib/client'

export const approvalsFormDataToApprovalsDto = (
  approvals: ContactApprovals,
  formValues: ApprovalsFormValues,
  client: Client,
): ContactApprovalsWithClientIdToAPI => ({
  clientUuid: client.uuid,
  contactApprovals: {
    contactApprovals: {
      ...approvals,
      marketingOffers: {
        email: {
            approved: formValues.emailMarketingOfferAgree,
          },
        general: {
            approved: formValues.marketingInformationAgree,
          },
        pushNotifications: {
            approved: formValues.pushMarketingOfferAgree,
          },
        sms: {
            approved: formValues.smsMarketingOfferAgree,
          },
      },
      personalData: {
        general: {
            approved: formValues.personalDataAgree,
          },
      },
      profile: {
        general: {
            approved: formValues.profileAgree,
          },
      },
      termsAndConditions: {
        general: {
          approved: formValues.termsAndConditionsAgree,
        },
      },
      tradeOffers: {
        email: {
            approved: formValues.emailTradeOfferAgree,
          },
        general: {
            approved: formValues.tradeInformationAgree,
          },
        pushNotifications: {
            approved: formValues.pushTradeOfferAgree,
          },
        sms: {
            approved: formValues.smsTradeOfferAgree,
          },
      },
    },
  },
})
