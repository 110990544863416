import { State } from 'actff-bo-lib/store'
import { selectMe, User, UserAction, UserActionType } from 'actff-bo-lib/user'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { filter, ignoreElements, map, tap, withLatestFrom } from 'rxjs/operators'

import { DebugToolsAction, DebugToolsActionType } from './actions'
import { getLogrocketId, identifyLogrocket, initLogrocket } from './dao'
import { selectIsLogrocketInitialized } from './selectors'

const initLogrocketEpic: Epic<DebugToolsAction> = action$ => action$.pipe(
  ofType(DebugToolsActionType.InitLogrocket),
  map(getLogrocketId),
  filter<string>(Boolean),
  tap(initLogrocket),
  map(() => DebugToolsAction.initLogrocketSuccess()),
)

const setLogrocketInitializedWhenNoIdProvidedEpic: Epic<DebugToolsAction> = action$ => action$.pipe(
  ofType(DebugToolsActionType.InitLogrocket),
  map(getLogrocketId),
  filter<string>(logrocketId => !logrocketId),
  map(() => DebugToolsAction.initLogrocketSuccess()),
)

const identifyLogrocketEpic: Epic<UserAction, UserAction, State> = (action$, store$) => action$.pipe(
  ofType(UserActionType.GetMeSuccess),
  withLatestFrom(store$),
  map(([, state]) => [selectMe(state), selectIsLogrocketInitialized(state)]),
  filter<[User, boolean]>(([user, isInitialized]) => Boolean(user) && Boolean(isInitialized)),
  tap(([me]) => identifyLogrocket(me.uuid)),
  ignoreElements(),
)

export const debugToolsEpic = combineEpics(
  initLogrocketEpic,
  identifyLogrocketEpic,
  setLogrocketInitializedWhenNoIdProvidedEpic,
)
