import { PersonAddress } from 'actff-bo-lib/car'
import React, { FC } from 'react'
import styled from 'styled-components'

type Props = {
  readonly address?: PersonAddress,
}

const AddressContainer = styled.div`
  display: grid;
`

export const Address: FC<Props> = ({ address }) => {
  if (!address) {
    return null
  }

  const { city, postCode, streetPrefix, street } = address

  return (
    <AddressContainer>
      <span>{streetPrefix} {street},</span>
      <span>{postCode} {city}</span>
    </AddressContainer>
  )
}
