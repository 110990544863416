import { RadioValue } from 'actff-bo-app/components/Form'
import { AppPerson, CarInfo, CarTransmissionType, Counterparty, InspectionId } from 'actff-bo-lib/car'
import { Client, ClientPreferences } from 'actff-bo-lib/client'
import { OpportunityInternalComment, TradeMatch } from 'actff-bo-lib/crm/dto'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { Attachment, AttachmentAcceptType, NominalString, SelectOption, Url } from 'actff-bo-lib/global'
import { UserId, UserPermissions, UserWithoutPermissions } from 'actff-bo-lib/user'

export enum TradeOpportunityType {
  Purchase = 'purchase',
  Sale = 'sale',
}

export enum OpportunityPurchaseStatusType {
  WAIT_FOR_FIRST_CONTACT = 'WAIT_FOR_FIRST_CONTACT',
  FIRST_CONTACT = 'FIRST_CONTACT',
  RETRY_CONTACT = 'RETRY_CONTACT',
  POSTPONED_CONTACT = 'POSTPONED_CONTACT',
  BUY_OFFER = 'BUY_OFFER',
  INITIAL_VALUATION = 'INITIAL_VALUATION',
  INSPECTION = 'INSPECTION',
  WAITING_LIST = 'WAITING_LIST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  EXPIRED = 'EXPIRED',
}

// TODO final version to be decided
export enum OpportunitySaleStatusType {
  WAIT_FOR_FIRST_CONTACT = 'WAIT_FOR_FIRST_CONTACT',
  FIRST_CONTACT = 'FIRST_CONTACT',
  RETRY_CONTACT = 'RETRY_CONTACT',
  POSTPONED_CONTACT = 'POSTPONED_CONTACT',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum OpportunityTradeEventType {
  CONTACT = 'CONTACT',
  MEETING = 'MEETING',
  OFFER = 'OFFER',
  VALUATION_ON_SERVICE = 'VALUATION_ON_SERVICE',
  OTHER = 'OTHER',
}

export enum OpportunityTradeFailureReasonType {
  CAR_SOLD = 'CAR_SOLD',
  NOT_INTERESTED = 'NOT_INTERESTED',
  OTHER = 'OTHER',
}

export type OpportunityPurchaseFilterValues = OpportunityPurchaseStatusType
export type OpportunitySaleFilterValues = OpportunitySaleStatusType

export type CrmTradeOwner = Client | Counterparty | AppPerson

type OpportunityTrade = {
  readonly actionDate: Date | null,
  readonly assignee: UserWithoutPermissions,
  readonly businessEvents: ReadonlyArray<OpportunityTradeInternalEvent>,
  readonly car: CarInfo,
  readonly comments: ReadonlyArray<OpportunityInternalComment>,
  readonly created: Date,
  readonly events: ReadonlyArray<OpportunityTradeInternalEventDto>,
  readonly failureExplanation: string,
  readonly failureReason: OpportunityTradeFailureReasonType | null,
  readonly invoiceNumber?: string,
  readonly owner: Counterparty | AppPerson,
}

export type OpportunitySaleId = NominalString<OpportunitySale>

export type OpportunitySale = OpportunityTrade & {
  readonly car?: CarInfo,
  readonly client?: Client,
  readonly matches: ReadonlyArray<TradeMatch>,
  readonly preferences: ClientPreferences,
  readonly status: OpportunitySaleStatusType,
  readonly statusHistory: OpportunityTradeStatusHistory,
  readonly uuid: OpportunitySaleId,
}

export type OpportunityPurchaseId = NominalString<OpportunityPurchase>
export type OpportunityPurchaseMatchedId = NominalString<OpportunityPurchaseMatched>

export type OpportunityPurchaseInspection = {
  readonly attachments: ReadonlyArray<Url>,
  readonly origin: string,
  readonly created: Date,
  readonly rating: number,
  readonly uuid: InspectionId,
}

export type OpportunityPurchaseCarInsurance = {
  readonly marketValue: number | null,
  readonly insuranceValue: number | null,
  readonly startDate: Date | null,
  readonly endDate: Date | null,
  readonly expirationDate: Date | null,
}

export type OpportunityPurchaseEquipment = {
  readonly drive: string,
  readonly engineCapacity: string,
  readonly enginePower: string,
  readonly transmission: CarTransmissionType,
}

export type OpportunityPurchase = OpportunityTrade & {
  readonly assignee: UserWithoutPermissions,
  readonly client?: Client,
  readonly equipment: OpportunityPurchaseEquipment,
  readonly financeEndDate: Date | null,
  readonly innerValuation: string | null,
  readonly inspection: OpportunityPurchaseInspection,
  readonly inspectionRating: number,
  readonly insurance: OpportunityPurchaseCarInsurance,
  readonly isFromFinance: boolean,
  readonly isFromNdc: boolean,
  readonly matches?: ReadonlyArray<TradeMatch>,
  readonly owner?: CrmTradeOwner,
  readonly isNewCar?: boolean,
  readonly newCarOffer?: boolean,
  readonly plannedSaleFrom: Date | null,
  readonly plannedSaleTo: Date | null,
  readonly status: OpportunityPurchaseStatusType,
  readonly statusHistory: OpportunityTradeStatusHistory,
  readonly uuid: OpportunityPurchaseId,
}

export type OpportunityPurchaseMatched = {
  readonly uuid: OpportunityPurchaseMatchedId,
  readonly purchaseUuid: OpportunityPurchaseId,
}

export type ListFilter<T> = {
  readonly name: string,
  readonly value: T,
  readonly caption: string,
  readonly options?: ReadonlyArray<SelectOption<string>>,
  readonly requiredPermissions?: ReadonlyArray<UserPermissions>,
  readonly trimLength?: number,
}

export type OpportunityTradeId = OpportunityPurchaseId | OpportunitySaleId

export const opportunityUnassigned: UserId = 'UNASSIGNED' as UserId
export const maxMatchedOpportunityNumber = 3

export const createOpportunity = (data: any) => ({ // tslint:disable-line no-any
  ...data,
  created: parseToDateOrNull(data.created),
  date: parseToDateOrNull(data.data),
})

export const CrmTradeOpportunityAttachmentAcceptTypes: ReadonlyArray<AttachmentAcceptType> = [
  AttachmentAcceptType.PNG,
  AttachmentAcceptType.JPG,
  AttachmentAcceptType.PDF,
  AttachmentAcceptType.XLS,
  AttachmentAcceptType.XLSX,
]

export type OpportunitySaleStatusDto = {
  readonly author: UserWithoutPermissions,
  readonly uuid: OpportunitySaleId,
}

export type OpportunityTradeInternalEvent = {
  readonly created: Date,
  readonly comment: string,
  readonly author: UserWithoutPermissions,
  readonly attachments?: ReadonlyArray<Url>,
  readonly type: OpportunityTradeEventType,
}

export type OpportunityTradeStatusHistory = {
  readonly actionDate: Date,
  readonly date: Date,
  readonly status: OpportunityPurchaseStatusType | OpportunitySaleStatusType,
  readonly author: UserWithoutPermissions,
}

export type OpportunityTradeInternalEventDto = Pick<OpportunityTradeInternalEvent, 'attachments' | 'comment' | 'type'>

export type OpportunityTradeInternalEventFormWithAttachments = OpportunityTradeInternalEventDto & {
  readonly attachments: ReadonlyArray<Attachment>,
  readonly type: SelectOption<OpportunityTradeEventType>,
}

export type OpportunityPurchaseViewForm = {
  readonly actionDate?: Date | null,
  readonly failureExplanation?: string,
  readonly failureReason?: OpportunityTradeFailureReasonType | null,
  readonly invoiceNumber?: string,
  readonly innerValuation: string,
  // TODO consider merge those two fields into one like `newCarOffer`
  readonly isNewCar: RadioValue,
  readonly newCarOffer: RadioValue,
  readonly insuranceValue: string,
  readonly startDate: Date | null,
  readonly plannedSaleFrom: Date | null,
  readonly plannedSaleTo: Date | null,
  readonly status: OpportunityPurchaseStatusType | null,
}

export type TradeOpportunityDto = {
  readonly actionDate?: Date | null,
  readonly failureExplanation?: string,
  readonly failureReason?: OpportunityTradeFailureReasonType | null,
  readonly invoiceNumber?: string,
  readonly status?: OpportunityPurchaseStatusType | OpportunitySaleStatusType,
  readonly uuid: OpportunityPurchaseId | OpportunitySaleId,
}
