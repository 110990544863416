import React, { FC } from 'react'
import styled from 'styled-components'

type Props = {
  readonly padding?: number | string,
}

const Container = styled.div<Props>`
  padding: ${({ padding }: Props) => typeof padding === 'string' ? padding : `${padding}px`}};
`

const defaultPadding = 20

export const Padder: FC<Props> = ({ children, padding = defaultPadding }) =>
  <Container padding={padding}>{children}</Container>
