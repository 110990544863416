import styled from 'styled-components'
import { colors, Opacity, tabletBoundary, withOpacity } from 'styles'

import { theme } from './theme'

const { table } = theme

type ThProps = {
  readonly hasPadding?: boolean,
}

type LightTableProps = {
  readonly opacity?: string,
  readonly paddingTight?: boolean,
  readonly textAlign?: string,
}

export const LightTable = styled.table<LightTableProps>`
  border-collapse: collapse;
  border-spacing: 0;

  @media screen and (max-width: ${tabletBoundary}) {
    display: block;
    overflow-x: auto;
  }

  th {
    background: ${({ opacity }: LightTableProps) => withOpacity(colors.solitude, Opacity[opacity ?? '15%'])};
    border-bottom: 1px solid ${colors.solitude};
    border-right: 1px solid ${colors.solitude};
    font-weight: 600;
    text-align: ${({ textAlign }: LightTableProps) => textAlign ?? 'left'};
    padding: 8px 24px;

    &:last-child {
      border-right: none;
    }
  }

  tr {
    border-bottom: 1px solid ${colors.solitude};

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    border-right: 1px solid ${colors.solitude};
    padding: ${({ paddingTight }: LightTableProps) => paddingTight ? '8px 16px' : '8px 24px'};

    &:last-child {
      border-right: none;
    }
  }
`

export const Table = styled.table`
  border: 1px solid ${table.border};
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`

export const TableInfo = styled.span`
  clear: left;
  display: block;
  font-size: 10px;
  line-height: 12px;
`

type TrProps = {
  readonly bgColor: string,
  readonly clickable?: boolean,
  readonly shadowed?: boolean,
}

export const Tr = styled.tr`
  cursor: ${({ clickable }: TrProps) => clickable ? 'pointer' : 'default'};
  margin: 0;
  padding: 0;

  td {
    color: ${({ shadowed }: TrProps) => shadowed ? `${colors.nepalBlue}` : 'default'};
    background-color: ${({ bgColor }: TrProps) => bgColor ?? table.item.background.primary};
  }
`
export const TrDanger = styled(Tr)`
  td {
    color: ${({ shadowed }: TrProps) => shadowed ? `${colors.nepalBlue}` : 'default'};
    background-color: ${({ bgColor }: TrProps) => bgColor ?? table.item.background.danger};
    font-weight: 600;
  }
`

export const TrWarning = styled(Tr)`
  td {
    color: ${({ shadowed }: TrProps) => shadowed ? `${colors.nepalBlue}` : 'default'};
    background-color: ${({ bgColor }: TrProps) => bgColor ?? table.item.background.accent};
    font-weight: 600;
  }
`

export const TrSuccess = styled(Tr)`
  td {
    color: ${({ shadowed }: TrProps) => shadowed ? `${colors.nepalBlue}` : 'default'};
    background-color: ${({ bgColor }: TrProps) => bgColor ?? withOpacity(table.item.background.success, Opacity['30%'])};
    font-weight: 600;
  }
`

type TdProps = {
  readonly bgColor?: string,
  readonly noPadding?: boolean,
  readonly styles?: React.CSSProperties,
}

export const Th = styled.th<ThProps>`
  background-color: ${table.header.background};
  font-weight: normal;
  margin: 0;
  padding: 18px 25px;
  text-align: left;

  ${({ hasPadding = true }: ThProps) => hasPadding && `
  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    padding-right: 32px;
  }
  `};
`

export const Td = styled.td<TdProps>`
  border-bottom: 1px solid ${table.item.border};
  margin: 0;
  ${({ noPadding }: TdProps) => !noPadding && 'padding: 8px 16px'};
  ${({ noPadding }: TdProps) => noPadding && 'height: 110px'};

  tr:last-child & {
    border-bottom: 0;
  }

  &:first-child {
    padding-left:5px;
  }

  &:last-child {
    padding-right: 32px;
  }

  ${({ styles }: TdProps) => styles && styles};
`

export const ThLighter = styled(Th)`
  background-color: ${colors.washMe};
`

export const ThLighterBold = styled(ThLighter)`
  font-weight: bold;
`

/*** pivottable */
type PivotTableProps = {
  readonly showPanel: boolean,
}

export const DataGridGroupLabel = styled.label`
  width: 400
`

export const DataGridTheme = styled.div<PivotTableProps>`
  font-weight: normal;

  .rdg {
    min-height: calc(100vh - 360px)
  }

  .rdg-filter {
    width: 100%;
  }

  select.rdg-filter {
    border-radius: 3px;
    border: 1.5px solid ${theme.form.input.border};
    color: ${theme.form.input.text};
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    height: 32px;
    outline: none;
    padding: 0 2px;
  }

  .rdg-group-row {
    background-color: ${colors.athensGray};
    font-weight: bold;
  }

  .rdg-header-row {
    background-color: ${colors.mysticGray};
  }

  .rdg-summary-row {
    background-color: ${table.item.background.accent};
    font-weight: bold;
    height: 35px;

    & > .rdg-cell {
      border-top: 1px solid ${colors.dark};
    }
  }

  .rdg-print-mode {
    width: 1124px;
  }

  @media print {
    .rdg {
      --selection-color: none;
      font-family: Arial;
      font-size: 9px !important;
    }

    .rdg-cell-frozen-last {
      box-shadow: none;
    }

    .rdg-row {
      page-break-inside: avoid;
    }

    @page {
      size: landscape;
      margin: 16px 10px 16px;
    }
  }
`
