import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { AjaxError } from 'rxjs/ajax'

import { NdcCar } from './dto'

export enum NdcCarActionType {
  ChangePage = '[NDC CAR] CHANGE PAGE',
  ChangeSearchPhrase = '[NDC CAR] CHANGE SEARCH PHRASE',
  ChangeDealerLocationFilters = '[NDC CAR] CHANGE DEALER LOCATION FILTERS',

  GetNdcCars = '[NDC CAR] GET NDC CARS',
  GetNdcCarsFailure = '[NDC CAR] GET NDC CARS FAILURE',
  GetNdcCarsSuccess = '[NDC CAR] GET NDC CARS SUCCESS',
}

export const NdcCarAction = {
  changeDealerLocations: (filters: ReadonlyArray<DealerLocationKey>) =>
    createAction(NdcCarActionType.ChangeDealerLocationFilters, filters),
  changePage: (page: number) => createAction(NdcCarActionType.ChangePage, page),
  changeSearchPhrase: (searchPhrase: string) => createAction(NdcCarActionType.ChangeSearchPhrase, searchPhrase),

  getNdcCars: () => createAction(NdcCarActionType.GetNdcCars),
  getNdcCarsFailure: (error: AjaxError) => createAction(NdcCarActionType.GetNdcCarsFailure, error),
  getNdcCarsSuccess: (ndcCars: PagedResult<NdcCar>) => createAction(NdcCarActionType.GetNdcCarsSuccess, ndcCars),
}

export type NdcCarAction = ActionsUnion<typeof NdcCarAction>
