import * as React from 'react'
import styled from 'styled-components/macro'
import { layers } from 'styles'

type Props = {
  readonly color?: string,
  readonly height?: string,
  readonly children?: JSX.Element,
  readonly opacity?: string,
  readonly onClick?: (event: React.MouseEvent) => void,
  readonly position?: string,
  readonly shouldRender?: boolean,
  readonly zIndex?: number,
}

const OverlayComponent = styled.div`
  background-color: ${({ color }: Props) => color || 'none'};
  height: ${({ height }: Props) => height || '100vh'};
  left: 0;
  opacity: ${({ opacity }: Props) => opacity || 1};
  position: ${({ position }: Props) => position || 'fixed'};
  top: 0;
  width: 100vw;
  z-index: ${({ zIndex }: Props) => zIndex || layers.overlay};
`

const OverlayContent = styled.div`
  align-items: center;
  display: grid;
  height: 100vh;
  justify-items: center;
  width: 100vw;
  z-index: 0;
`

export const Overlay: React.FC<Props> = ({ shouldRender = true, ...props }) => {
  if (!shouldRender) { return null }

  return (
    <OverlayComponent {...props}>
      <OverlayContent>
        {props.children}
      </OverlayContent>
    </OverlayComponent>
  )
}
