import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { FieldValue, FormCell, FormCellFullWidth, FormCellWide, FormHeader, FormWrapper } from 'actff-bo-app/components/Form'
import { AmountType, CarFinance } from 'actff-bo-lib/car'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { history } from 'actff-bo-lib/router'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'

const InstalmentContainer = styled.div`
  align-items: self-start;
  display: inline-grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(3, min-content);
`

const FinanceContainer = styled(FormCellFullWidth)`
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(3, 320px);
  padding: 0 32px;
  width: 100%;
`

export type Props = {
  readonly carFinance: CarFinance,
}

const handleGoBackClick = () => {
  history.push(Paths.CarList)
}

const testId = 'car-view-finance-readonly__'

const CarViewFinanceReadonlyComponent: React.FC<Props & WithTranslation> = ({ carFinance, t }) => (
  <>
    {/* FINANCE IN HOUSE */}

    <FormWrapper>
      <FormCellWide>
        <label>{t('carView.form.finance.inHouse')}</label>
        <FieldValue data-testid={`${testId}in-house`}>{t(carFinance.inHouse ? 'caption.yes' : 'caption.no')}</FieldValue>
      </FormCellWide>

      {/* FINANCE DATA*/}

      <FormCellFullWidth slim={true}>
        <FormHeader>{t('carView.form.finance.financeInfo')}</FormHeader>
      </FormCellFullWidth>

      <FinanceContainer>
        <label>{t('carView.form.finance.lessorName')}</label>
        <label>{t('carView.form.finance.financeEndDate')}</label>
        <label>{t('carView.form.finance.type')}</label>
        <FieldValue data-testid={`${testId}lessor-name`}>{carFinance.lessorName}</FieldValue>
        <FieldValue data-testid={`${testId}finance-end-date`}>
          <FormattedDate date={carFinance.financeEndDate} format={defaultDateFormat} />
        </FieldValue>
        <FieldValue data-testid={`${testId}finance-type`}>{carFinance.financeType}</FieldValue>

      </FinanceContainer>

      <FormCell>
        <label>{t('carView.form.finance.instalment')}</label>
        <InstalmentContainer>
          <FieldValue data-testid={`${testId}amount`}>{carFinance.amount}</FieldValue>
          <FieldValue data-testid={`${testId}currency`}>{carFinance.currency}</FieldValue>
          <FieldValue data-testid={`${testId}amount-type`}>
            {t(carFinance.amountType === AmountType.Gross ? 'caption.gross' : 'caption.net')}
          </FieldValue>
        </InstalmentContainer>
      </FormCell>

    </FormWrapper>
    <FixedFooter>
      <ButtonWithIcon
        caption={t('caption.back')}
        iconType={IconType.Back}
        onClick={handleGoBackClick}
        testId={`${testId}go-back`}
        theme={BtnTheme.Link}
      />
    </FixedFooter>
  </>
)

export const CarViewFinanceReadonly = withTranslation()(CarViewFinanceReadonlyComponent)
