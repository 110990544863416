import Logo from 'assets/logo.svg'
import * as React from 'react'
import styled from 'styled-components'
import { OrangeLink } from 'styles'

import { Wrapper } from './Wrapper'

const ImageScreenStyled = styled.div`
  display: grid;
  grid-template-columns: minmax(500px, 1fr) 2fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    'logo image'
    'content image'
    'footer image'
  ;
  grid-row-gap: 63px;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
`

const LogoWrapperStyled = styled.img`
  grid-area: logo;
  height: 25px;
  margin: 56px 0 0 10%;
  width: 100px;
`

const ContentStyled = styled.div`
  margin: 0 72px 32px;
  grid-area: content;
  justify-self: center;
  width: 80%;
`

const Footer = styled.footer`
  grid-area: footer;
  justify-self: center;
  margin-bottom: 32px;
  width: 80%;
`

const CopyrightsStyled = styled.p`
  font-size: 10px;
  margin-bottom: 8px;
`

const ContainerWithBackgroundImage = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: image;
`

type Props = {
  readonly image: string,
}

// TODO: Translation
export const withImageColumn: Wrapper = (props: Props) => (Component: React.FC | React.ComponentClass) =>
  class WithImageColumnComponent extends React.Component<Props> {
    public render(): React.ReactNode {
      return (
        <ImageScreenStyled>
          <LogoWrapperStyled src={Logo} alt='Autogala logo' />
          <ContentStyled>
            <Component />
          </ContentStyled>
          <Footer>
            <CopyrightsStyled>Copyright © 2019 Autogala. Wszystkie prawa zastrzeżone.</CopyrightsStyled>
            <OrangeLink to='privacy-policy'>Polityka prywatności</OrangeLink>
          </Footer>
          <ContainerWithBackgroundImage style={{ backgroundImage: `url('${props.image}')` }} />
        </ImageScreenStyled>
      )
    }
  }
