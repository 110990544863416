import { BtnType } from 'actff-bo-app/components/Button'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu/dto'
import React, { FC } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, H2 } from 'styles'

import { Form, Header } from '../../Styled'

type Props = {
  readonly workdaysToAvailableVisit: number,
  readonly updateVisitAvailability: (workdaysToAvailableVisit: number) => void,
}

const DaysSelectorContainer = styled.div`
  display: grid;
  grid-row-gap: 20px;
  padding-top: 24px;
`

const DaysSelector = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 50px) 2fr;
  place-items: center;
`

const Input = styled.input`
  height: 34px;
  padding: 7px 5px;
  padding-left: 10px;
  width: 70px;
`

const Button = styled.button`
  background-color: ${colors.straw};
  border-radius: 3px;
  display: grid;
  height: 34px;
  place-items: center;
  width: 23px;
`

const InputMaxValue = 200

export const VisitAvailabilityForm: FC<Props> = ({ workdaysToAvailableVisit, updateVisitAvailability }) => {
  const { t } = useTranslation()
  const { control, formState, handleSubmit, register, reset, setValue } = useForm<Props>({ defaultValues: { workdaysToAvailableVisit }})
  const { isSubmitting, touched } = formState

  const workdays = useWatch({
    control,
    defaultValue: workdaysToAvailableVisit,
    name: 'workdaysToAvailableVisit',
  })

  const handleResetForm = () => { reset({ workdaysToAvailableVisit }) }

  const onSubmit = handleSubmit<Props>((formValues: Props) => {
    updateVisitAvailability(formValues.workdaysToAvailableVisit)
  })

  const onMinusClick = () => { setValue('workdaysToAvailableVisit', Math.max(0, Number(workdays) - 1)) }
  const onPlusClick = () => { setValue('workdaysToAvailableVisit', Math.max(0, Number(workdays) + 1)) }

  return (
    <Form>
      <Header><H2>{t('admin.dealerLocations.visitsAvailability')}</H2></Header>
      <span>{t('admin.dealerLocations.visitsAvailability.description')}</span>
      <DaysSelectorContainer>
        <label>{t('admin.dealerLocations.visitsAvailability.nextAvailableDate')}:</label>
        <DaysSelector>
          <Button type={BtnType.Button} onClick={onMinusClick}><Icon type={IconType.Minus} /></Button>
          <Input name='workdaysToAvailableVisit' max={InputMaxValue} type='number' readonly={true} ref={register} />
          <Button type={BtnType.Button} onClick={onPlusClick}><Icon type={IconType.PlusWhite} /></Button>
        </DaysSelector>
      </DaysSelectorContainer>
      <FixedFooter
        onCancel={handleResetForm}
        onSave={onSubmit}
        isSaveDisabled={workdaysToAvailableVisit === workdays || isSubmitting || !touched}
      />
    </Form>
  )
}
