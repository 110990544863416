import { Testable } from 'actff-bo-lib/global/testable'
import { ErrorMessage, ErrorMessageProps as BaseErrorMessageProps } from 'formik'
import * as React from 'react'

import { ValidationErrorMessage } from './ValidationErrorMessage'

type ErrorMessageProps = BaseErrorMessageProps & Testable

export const ValidationError: React.FC<ErrorMessageProps> = ({ testId, ...props }) => (
  <ErrorMessage component={ValidationErrorMessage} data-testid={testId} {...props} />
)
