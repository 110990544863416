import { Loader } from 'actff-bo-app/components/Loader'
import {
  CrmServiceAction,
  Lead,
  OpportunityManualPayload,
  selectCurrentLead,
  selectIsLoadingLead,
} from 'actff-bo-lib/crm/service'
import { selectSelectableServices, Service, ServiceAction } from 'actff-bo-lib/service'
import { State as GlobalState } from 'actff-bo-lib/store'
import { selectMe } from 'actff-bo-lib/user'
import { User } from 'actff-bo-lib/user/dto'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import { H2, Header } from 'styles'

import { OpportunityManualForm } from './OpportunityManualForm'

type OpportunityNewParams = {
  readonly vin: string,
}

type StateToProps = {
  readonly availableServices: ReadonlyArray<Service>,
  readonly currentLead: Lead | null,
  readonly isLoadingLead: boolean,
  readonly me: User | null,
}

type DispatchToProps = {
  readonly createOpportunity: (payload: OpportunityManualPayload) => void,
  readonly getCurrentLead: (vin: string) => void,
  readonly getServices: () => void,
}

type OpportunityNewComponentProps =
  StateToProps
  & DispatchToProps
  & RouteComponentProps<OpportunityNewParams>
  & WithTranslation

const testId = 'crm-opportunities-manual__'

export class OpportunityManualComponent extends React.Component<OpportunityNewComponentProps> {
  public componentDidMount(): void {
    this.props.getServices()
    this.props.getCurrentLead(this.getOpportunityCarVin(this.props))
  }

  public render(): React.ReactNode {
    const { availableServices, createOpportunity, currentLead, me, t } = this.props

    if (!me || !currentLead) {
      return <Loader />
    }

    return (
      <>
        <Header>
          <H2 data-testid={`${testId}header`}>{t('opportunity.manual.title')}</H2>
        </Header>
        <OpportunityManualForm
          availableServices={availableServices}
          createOpportunity={createOpportunity}
          currentLead={currentLead}
          me={me}
          testId={testId}
        />
      </>
    )
  }

  private readonly getOpportunityCarVin = (props: OpportunityNewComponentProps) => props.match.params.vin
}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  availableServices: selectSelectableServices(state),
  currentLead: selectCurrentLead(state),
  isLoadingLead: selectIsLoadingLead(state),
  me: selectMe(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, OpportunityNewComponentProps> = dispatch => ({
  clearOpportunity: () => { dispatch(CrmServiceAction.clearOpportunity()) },
  createOpportunity: (payload: OpportunityManualPayload) => { dispatch(CrmServiceAction.createOpportunityManual(payload)) },
  getCurrentLead: (vin: string) => { dispatch(CrmServiceAction.getLead(vin)) },
  getServices: () => { dispatch(ServiceAction.getServices()) },
})

export const OpportunityManual = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(OpportunityManualComponent)
