import { ErrorMessage } from '@hookform/error-message'
import { HolidayRow } from 'actff-bo-app/Admin/Dealership/Holidays/HolidayRow'
import { Input } from 'actff-bo-app/Admin/Dealership/Users/Styled'
import { Footer } from 'actff-bo-app/Admin/Footer'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { Container, Label, Th } from 'actff-bo-app/components/EditableList/Styled'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Holiday } from 'actff-bo-lib/calendar/dto'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import { isString } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LightTable, Tr } from 'styles'

type Props = Testable & {
  readonly holidays: ReadonlyArray<Holiday>,
  readonly onAdd: (holiday: Holiday) => void,
  readonly onDelete: (holiday: Holiday) => void,
}

type FormValues = {
  readonly date: Date | null,
  readonly description: string,
}

const FormSegment = styled.div`
  display: grid;
  grid-auto-flow: row;
`

const FormContent = styled.div`
  display: grid;
  padding: 24px 0;
  grid-row-gap: 12px;
`

const maxDescriptionLength = 100

export const HolidaysTable: FC<Props> = ({ holidays, onDelete, onAdd, testId }) => {
  const { t } = useTranslation()

  const [addNewHolidayOpen, setAddNewHolidayOpen] = useState(false)
  const [holidayToDelete, setHolidayToDelete] = useState<Holiday | null>(null)

  const {
    errors,
    handleSubmit,
    register,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      date: null,
      description: '',
    },
    mode: 'onSubmit',
  })

  const toggleAddDialogOpen = () => { setAddNewHolidayOpen(!addNewHolidayOpen) }

  const onDialogClose = () => {
    toggleAddDialogOpen()
  }

  const onDialogConfirm = (formValues: FormValues) => {
    onAdd(formValues)
    toggleAddDialogOpen()
  }

  const handleDelete = (item: Holiday) => () => {
    setHolidayToDelete(item)
  }

  const onDeleteDialogConfirm = () => {
    if (!holidayToDelete) { return }

    isString(holidayToDelete) ? onDelete(holidayToDelete) : onDelete(holidayToDelete)
    setHolidayToDelete(null)
  }

  const onDeleteDialogClose = () => {
    setHolidayToDelete(null)
  }

  return (
    <Container>
      <LightTable>
        <thead>
        <Tr>
          <Th>{t('caption.date')}</Th>
          <Th>{t('caption.nameStartsBigLetter')}</Th>
        </Tr>
        </thead>
        <tbody>
        {holidays.map(holiday => (
          <HolidayRow
            key={holiday.id}
            holiday={holiday}
            onDelete={handleDelete(holiday)}
          />
        ))}
        </tbody>
      </LightTable>

      <Dialog
        autoWidth={true}
        content='calendar.holidays.add.dialog.content'
        open={addNewHolidayOpen}
        onConfirm={handleSubmit(onDialogConfirm)}
        onClose={onDialogClose}
        title='calendar.holidays.add.dialog.title'
      >
        <FormContent>
          <FormSegment>
            <Label>{t('caption.date')}:</Label>
            <Controller
              render={({ value, onChange }) =>
                <DatePickerInputStyled onChange={onChange} date={value} timeDisabled={true} />}
              control={control}
              name='date'
              rules={{ required: 'caption.error.required' }}
            />
            <ErrorMessage errors={errors} name='date' as={TranslatedErrorMessage} />
          </FormSegment>
          <FormSegment>
            <Label>{t('caption.description')}:</Label>
            <Input
              type='text'
              name='description'
              ref={register({
                ...getValidator({ type: ValidationType.Required }),
                ...getValidator({
                  msgTKey: 'form.validation.string.noEmpty',
                  regExp: /^(?!\s*$).+/,
                  type: ValidationType.Pattern,
                }),
                ...getValidator({
                  msgTKey: 'form.validation.holidayDescription.tooLong',
                  type: ValidationType.MaxLength,
                  value: maxDescriptionLength,
                }),
              })}
            />
            <ErrorMessage errors={errors} name='description' as={TranslatedErrorMessage} />
          </FormSegment>
        </FormContent>
      </Dialog>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.delete.content'
        contentProps={{ item: holidayToDelete?.description || '' }}
        open={!!holidayToDelete}
        onConfirm={onDeleteDialogConfirm}
        onClose={onDeleteDialogClose}
        title='contentManagement.dialog.delete.title'
      />
      <Footer
        onAddClick={toggleAddDialogOpen}
        testId={testId}
        onAddTLabel='calendar.holidays.add'
      />
    </Container>
)
}

export const DatePickerInputStyled = styled(DatePickerInput)`
  .DayPickerInput {
    width: 100%;
  }

  div {
    width: 100%;
  }
`
