import { ActionsUnion, createAction } from '@evojam/redux-utils'

import { Path } from './dto'

export enum MenuActionType {
  ToggleDisableMenu = '[MENU] TOGGLE DISABLE MENU',
  ToggleMenu = '[MENU] TOGGLE MENU',
  ToggleMenuItem = '[MENU] TOGGLE MENU ITEM',
}

export const MenuAction = {
  toggleDisableMenu: () => createAction(MenuActionType.ToggleDisableMenu),
  toggleMenu: () => createAction(MenuActionType.ToggleMenu),
  toggleMenuItem: (path: Path) => createAction(MenuActionType.ToggleMenuItem, path),
}

export type MenuAction = ActionsUnion<typeof MenuAction>
