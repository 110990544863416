import { axiosApi } from 'actff-bo-lib/api/axios-requests'
import { buildUrl } from 'actff-bo-lib/global'

import {
  CalendarResource,
  CalendarResourceAvailability,
  CalendarResourceId,
  CalendarResourceStatus,
  CalendarResourceType,
  ScheduleRule,
  Shift,
} from './dto'

export const getShifts = () =>
  axiosApi.get<ReadonlyArray<Shift>>(buildUrl('/new-calendar/shift'))

export const getResourcesAvailability = (fromDate: Date, toDate: Date) => {
  const from = fromDate.toISOString()
  const to = toDate.toISOString()

  return axiosApi.get<ReadonlyArray<CalendarResourceAvailability>>(
    buildUrl('/new-calendar/schedule/search', { from, to }),
  )
}

export const createHumanResource = (form: CalendarResource, type: CalendarResourceType) =>
  axiosApi.post<CalendarResource, CalendarResource>(
    buildUrl('/new-calendar/schedule/human-resource'),
    {
      ...form,
      status: CalendarResourceStatus.ACTIVE,
      type,
    },
  )

export const updateHumanResource = (form: CalendarResource, type: CalendarResourceType, uuid?: CalendarResourceId) =>
  axiosApi.put<CalendarResource, CalendarResource>(
    buildUrl(`/new-calendar/schedule/human-resource/${uuid}`),
    {
      ...form,
      status: CalendarResourceStatus.ACTIVE,
      type,
    },
  )

export const createReplacementCar = (form: CalendarResource, type: CalendarResourceType) =>
  axiosApi.post<CalendarResource, CalendarResource>(
    buildUrl('/new-calendar/schedule/replacement-car'),
    {
      ...form,
      status: CalendarResourceStatus.ACTIVE,
      type,
    },
  )

export const updateReplacementCar = (form: CalendarResource, type: CalendarResourceType, uuid?: CalendarResourceId) =>
  axiosApi.put<CalendarResource, CalendarResource>(
    buildUrl(`/new-calendar/schedule/replacement-car/${uuid}`),
    {
      ...form,
      status: CalendarResourceStatus.ACTIVE,
      type,
    },
  )

export const createRules = (data: ScheduleRule) =>
  axiosApi.post<ScheduleRule, ScheduleRule>(buildUrl('/new-calendar/rule/many'), data)

export const deleteRules = (data?: ScheduleRule) =>
  axiosApi.delete<ScheduleRule, ScheduleRule>(buildUrl('/new-calendar/rule'), data)
