import { NominalString } from 'actff-bo-lib/global'

export type Color = NominalString<'Color'>

export const colors: { readonly [key: string]: Color } = {
  athensGray: '#f2f5f7' as Color,
  athsSpecial: '#EDE4D1' as Color,
  beige: '#FFF8EC' as Color,
  black: '#000000' as Color,
  blue: '#4FAAFF' as Color,
  blueViolet: '#3f51b5' as Color,
  boysenberryShadow: '#f3f4f8' as Color,
  brown: '#6F4B00' as Color,
  burgundy: '#980125' as Color,
  chathamsBlue: '#184475' as Color,
  dark: '#050907' as Color,
  darkKumera: '#654e1a' as Color,
  darkerYellowOrange: '#e09104' as Color,
  dustyGray: '#9b9b9b' as Color,
  flamingoRed: '#ef413a' as Color,
  gold: '#B7A57F' as Color,
  gray: '#BCBCBC' as Color,
  green: '#009406' as Color,
  iceCastle: '#d6f0ff' as Color,
  khmerCurry: '#EB5757' as Color,
  kumera: '#916e25' as Color,
  lustrianUndergrowth: '#c4c4c4' as Color,
  midnightBlue: '#003067' as Color,
  mintJelly: '#43D493' as Color,
  mystic: '#d9dde9' as Color,
  mysticGray: '#e7e9f1' as Color,
  nepalBlue: '#91a6bd' as Color,
  provincialPink: '#fdeceb' as Color,
  purple: '#874FFF' as Color,
  red: '#EB0900' as Color,
  shamrock: '#3AC587' as Color,
  silver: '#E3E4E5' as Color,
  solitude: '#e7e9f1' as Color,
  sprintWoodGold: '#f9f7f1' as Color,
  straw: '#d3bc8d' as Color,
  sunglow: '#FFCC32' as Color,
  swirlGold: '#d6d2c4' as Color,
  ulvaLactucaGreen: '#90ee90' as Color,
  washMe: '#FAFBFC' as Color,
  whisperBlue: '#E7E9F1' as Color,
  white: '#ffffff' as Color,
  yellow: '#FFE600' as Color,
  yellowOrange: '#fdbc48' as Color,
}

export enum Opacity {
  '100%' = 'FF',
  '95%' = 'F2',
  '90%' = 'E6',
  '85%' = 'D9',
  '80%' = 'CC',
  '75%' = 'BF',
  '70%' = 'B3',
  '65%' = 'A6',
  '60%' = '99',
  '55%' = '8C',
  '50%' = '80',
  '45%' = '73',
  '40%' = '66',
  '35%' = '59',
  '30%' = '4D',
  '25%' = '40',
  '20%' = '33',
  '15%' = '26',
  '10%' = '1A',
  '5%' = '0D',
  '0%' = '00',
}

export const withOpacity = (color: Color, opacity: Opacity) => `${color.valueOf()}${opacity}`
