import { CarInspectionAction } from 'actff-bo-lib/car'
import { Paths } from 'actff-bo-lib/menu'
import { parseUrl } from 'actff-bo-lib/pagination'

import { CarInspectionActionType } from '../actions'
import { CarInspectionState } from '../state'

const parsedUrl = parseUrl(Paths.CarViewInspections)

const carInspectionInitialState: CarInspectionState = {
  currentInspection: {
    data: null,
    loading: false,
  },
  currentInspections: {
    data: null,
    loading: false,
  },
  currentPage: parsedUrl.page ? +parsedUrl.page : 1,
}

// tslint:disable-next-line cyclomatic-complexity
export const carInspectionReducer = (
  state: CarInspectionState = carInspectionInitialState,
  action: CarInspectionAction,
): CarInspectionState => {
  switch (action.type) {
    case CarInspectionActionType.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case CarInspectionActionType.ClearInspection:
      return {
        ...state,
        currentInspection: {
          data: null,
          loading: false,
        },
      }

    case CarInspectionActionType.GetInspection:
      return {
        ...state,
        currentInspection: {
          data: null,
          loading: true,
        },
      }

    case CarInspectionActionType.GetInspectionSuccess:
      return {
        ...state,
        currentInspection: {
          data: action.payload,
          loading: false,
        },
      }
    case CarInspectionActionType.GetInspectionFailure:
      return {
        ...state,
        currentInspection: {
          data: null,
          loading: false,
        },
      }

    case CarInspectionActionType.GetInspections:
      return {
        ...state,
        currentInspections: {
          ...state.currentInspections,
          loading: true,
        },
      }

    case CarInspectionActionType.GetInspectionsSuccess:
      return {
        ...state,
        currentInspections: {
          data: action.payload,
          loading: false,
        },
      }

    case CarInspectionActionType.CreateInspection:
    case CarInspectionActionType.UpdateInspection:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          loading: true,
        },
      }

    case CarInspectionActionType.CreateInspectionFailure:
    case CarInspectionActionType.CreateInspectionSuccess:
    case CarInspectionActionType.UpdateInspectionFailure:
    case CarInspectionActionType.UpdateInspectionSuccess:
      return {
        ...state,
        currentInspection: {
          ...state.currentInspection,
          loading: false,
        },
      }

    default:
      return state
  }
}
