import { DealerLocation } from 'actff-bo-lib/dealership'
import { NominalString, SelectOption } from 'actff-bo-lib/global'

import { UserPermissions } from './permissions'

export type UserId = NominalString<User>

export type UserWithoutPermissions = Omit<User, 'permissions' | 'disabled'>

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
export const userPasswordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{3,15}$/

export type UserPasswordDto = {
  readonly login?: string,
  readonly oldPassword: string,
  readonly newPassword: string,
}

export enum UserRole {
  ROLE_RECEPTION_SERVICE = 'ROLE_RECEPTION_SERVICE',
  ROLE_RECEPTION_INSURANCE = 'ROLE_RECEPTION_INSURANCE',
  ROLE_RECEPTION_FINANCE = 'ROLE_RECEPTION_FINANCE',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export type User = {
  readonly uuid: UserId,
  readonly firstName: string,
  readonly lastName: string,
  readonly brands: ReadonlyArray<string>,
  readonly locations: ReadonlyArray<DealerLocation>,
  readonly email: string,
  readonly permissions: ReadonlyArray<UserPermissions>,
  readonly roles: ReadonlyArray<UserRole>,
  readonly disabled: boolean,
}

export type UserDto = {
  readonly firstName: string,
  readonly lastName: string,
  readonly brands: ReadonlyArray<string>,
  readonly locations: ReadonlyArray<Partial<DealerLocation>>,
  readonly email: string,
  readonly roles: ReadonlyArray<string>,
  readonly permissions?: ReadonlyArray<string>,
  readonly password?: string,
}

export type FormUser = {
  readonly uuid?: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly brands: ReadonlyArray<SelectOption<string>>,
  readonly locations: ReadonlyArray<SelectOption<string>>,
  readonly email: string,
  readonly roles: SelectOption<string>,
}

export type ChangePasswordForm = {
  readonly oldPassword: string,
  readonly newPassword: string,
  readonly newPasswordRepeat: string,
}

export type UserLocationsDto = {
  readonly locations: ReadonlyArray<Pick<DealerLocation, 'key'>>,
}
