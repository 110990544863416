import { oneSecond } from 'actff-bo-lib/date'
import { differenceInMinutes } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tr, TrDanger, TrWarning } from 'styles'

type Props = {
  readonly isStyled: boolean,
  readonly onClick?: () => void,
  readonly time?: Date,
  readonly clickable?: boolean,
  readonly dangerStyleLimit?: number,
  readonly menu?: React.ReactNode,
  readonly shadowed?: boolean,
}

const MenuContainerTdWrapper = styled.td`
  position: relative;
`

const MenuContainer = styled.div`
  height: calc(100% - 1px);
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
`
// tslint:disable-next-line
const timeToRefresh = 15 * oneSecond
const defaultDangerStyleLimit = 30

export const WithStyledRow: FC<Props> = ({
  children,
  clickable = true,
  dangerStyleLimit = defaultDangerStyleLimit,
  menu,
  isStyled,
  onClick,
  time = 1,
  shadowed,
}) => {
  const [, updateState] = useState<null>()
  const [difference, setDifference] = useState(differenceInMinutes(new Date(), time))
  const [, StyledRow] = isStyled
    ? difference <= dangerStyleLimit
      ? ['warning', TrWarning]
      : ['danger', TrDanger]
    : ['', Tr]

  useEffect(() => {
    if (difference <= dangerStyleLimit && time) {
      const interval = setInterval(() => {
        updateState(null)
        setDifference(differenceInMinutes(new Date(), time))
      }, timeToRefresh)

      return () => clearInterval(interval)
    }

    return
  })

  return (
    <StyledRow
      clickable={clickable}
      shadowed={shadowed}
      onClick={onClick}
    >
      {children}
      {menu && (
        <MenuContainerTdWrapper>
          <MenuContainer>
            {menu}
          </MenuContainer>
        </MenuContainerTdWrapper>
      )}
    </StyledRow>
  )
}
