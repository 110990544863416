import { CarColor } from 'actff-bo-lib/car/dto/car'
import { SelectOption } from 'actff-bo-lib/global'

export enum CarTransmissionType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum CarTransmissionShortListType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
}

export enum CarDriveType {
  FourAxis = 'FOUR_AXIS',
  FrontWheel = 'FRONT_WHEEL',
  RearWheel = 'REAR_WHEEL',
}

export type CarEquipmentItem = {
  readonly name: string,
  readonly uuid: string,
  readonly value: boolean | null,
}

export type CarEquipmentData = {
  readonly color: CarColor | string | null,
  readonly drive: CarDriveType | null,
  readonly engineCapacity: number | null,
  readonly enginePower: number | null,
  readonly transmission: CarTransmissionType | null,
}

export type CarEquipmentDataForm = {
  readonly engineCapacity: number | null,
  readonly enginePower: number | null,
  readonly drive: SelectOption<string> | null,
  readonly modelYear: string,
  readonly productionYear: string,
  readonly transmission: SelectOption<string> | null,
}

export type CarEquipmentDataFormWithoutCar = Omit<CarEquipmentDataForm, 'productionYear'>

export type CarEquipmentItems = ReadonlyArray<CarEquipmentItem>
