import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectDictionaryFeature = (state: State) => state.dictionary

export const selectFuelTypes =
  createSelector(selectDictionaryFeature, dictionary => dictionary.fuelTypes)

export const selectTireTypes =
  createSelector(selectDictionaryFeature, dictionary => dictionary.tireTypes)
