import { api, PagedResult } from 'actff-bo-lib/api'
import { axiosApi } from 'actff-bo-lib/api/axios-requests'
import {
  CarDataExistencePayload,
  CarEquipmentData,
  CarEquipmentItems,
  CarFilterValues,
  CarFinance,
  CarFinanceWithCarUuid,
  CarFormDataDto,
  CarId,
  CarInfoDto,
  CarInfoWithClient,
  CarInsurance,
  CarInsuranceWithCarUuid,
  CarTires,
  CarTiresWithUuid,
  Inspection,
  InspectionDto,
  InspectionId,
  InspectionListElement,
  mapToInspectionDto,
  mapToInspectionListElement,
  NewCarsCount,
  SearchCarResult,
  toCarTires,
} from 'actff-bo-lib/car/dto'
import { ClientId } from 'actff-bo-lib/client'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { buildUrl, Filters, getFiltersAsObject, getSimpleFiltersAsObject } from 'actff-bo-lib/global'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export const checkVinExistence = (uuid: CarId | null, vin: string) =>
  api.get<CarDataExistencePayload>(`/car/vin/${uuid}/${vin}`)

export const checkRegistrationNumberExistence = (uuid: CarId | null, registrationNumber: string) =>
  api.get<CarDataExistencePayload>(`/car/registrationNumber/${uuid}/${registrationNumber}`)

export const createCar = (carDto: CarInfoDto, clientUuid: ClientId) => {
  const { uuid, ...payloadWithoutUuid } = carDto

  return api.post<CarInfoWithClient, Omit<CarInfoDto, 'uuid'>>(`/car/${clientUuid}`, payloadWithoutUuid)
}

export const createInspection = (vin: string, inspection: InspectionDto) =>
  api.post<Inspection, InspectionDto>(`/trade/inspection/${vin}`, inspection)

export const updateInspection = (vin: string, inspectionId: InspectionId, inspection: InspectionDto) =>
  api.patch<Inspection, InspectionDto>(`/trade/inspection/${vin}/${inspectionId}`, inspection)

export const getCars = (
  filters: Filters<CarFilterValues>,
  dealerLocations?: ReadonlyArray<DealerLocationKey>,
  brand?: ReadonlyArray<string>,
  page?: number,
  searchText?: string,
) => api.get<PagedResult<CarInfoWithClient>>(
    buildUrl('/car', {
      page,
      ...getFiltersAsObject(filters),
      searchText,
      ...getSimpleFiltersAsObject({ location: dealerLocations, brand }) }),
  )

export const getCar = (carUuid: CarId) => api.get<CarInfoWithClient>(`/car/${carUuid}`)

export const getNdcCar = (vin: string) => api.get<CarInfoWithClient>(`/cars/ndc/${vin}`)

export const getCarAttachment = (url: string) => api.getObjectUrl(url)

export const getCarFinance = (carUuid: CarId) => api.get<CarFinance>(`/car/${carUuid}/finance`)

export const getCarInsurance = (carUuid: CarId) => api.get<CarInsurance>(`/car/${carUuid}/insurance`)

export const getCarTires = (carUuid: CarId) => api.get<CarTires>(`/car/${carUuid}/tires`).pipe(map(toCarTires))

export const getExistingCarInspection = (vin: string, inspectionId: InspectionId): Observable<Inspection> =>
  api
    .get<Inspection>(`/trade/inspection/${vin}/${inspectionId}`)
    .pipe(
      map(mapToInspectionDto),
    )

export const getNewCarInspection = (): Observable<Inspection> =>
  api.get<Inspection>('/trade/inspection')

export const getCarInspections = (vin: string, page: number): Observable<PagedResult<InspectionListElement>> =>
  api
    .get<PagedResult<InspectionListElement>>(`/trade/inspection/${vin}?page=${page}&size=10`)
    .pipe(
      map(data => ({
        ...data,
        result: data.result.map(mapToInspectionListElement),
      })),
    )

export const getCarEquipmentData = (vin: string) => api.get<CarEquipmentData>(`/trade/equipment/${vin}/data`)

export const getCarEquipmentItems = (vin?: string) => vin
  ? api.get<CarEquipmentItems>(`/trade/equipment/${vin}/items`)
  : api.get<CarEquipmentItems>('/trade/equipment/items')

export const getCarEquipmentItemsEdit = (vin: string) => api.get<CarEquipmentItems>(`/trade/equipment/${vin}/items/edit`)

export const getNewCarsCount = () => api.get<NewCarsCount>('/car/count/new')

export const deleteCar = (carUuid: CarId) => api.delete<CarId>(`/car/${carUuid}`)

export const updateCar = (payload: CarInfoDto) => {
  const { uuid, ...payloadWithoutUuid } = payload

  return api.patch<CarInfoWithClient, Omit<CarInfoDto, 'uuid'>>(`/car/${payload.uuid}`, payloadWithoutUuid)
}

export const updateCarStatus = (payload: Partial<CarInfoWithClient>) =>
  api.patch<CarInfoWithClient, Partial<CarInfoWithClient>>(`/car/${payload.uuid}`, {
    status: payload.status,
  })

export const updateCarInsurance = ({ carUuid, ...rest}: CarInsuranceWithCarUuid) =>
  api.put<CarInsurance, CarFormDataDto>(`/car/${carUuid}/insurance`, rest)

export const updateCarFinance = ({ carUuid, ...rest }: CarFinanceWithCarUuid) =>
  api.put<CarFinance, CarFormDataDto>(`/car/${carUuid}/finance`, rest)

export const updateCarTires = ({ carId, ...rest }: CarTiresWithUuid) =>
  api.put<CarTires, CarTires>(`/car/${carId}/tires`, rest)

export const updateCarEquipmentData = (data: CarEquipmentData, vin: string) =>
  api.put<CarEquipmentData, CarEquipmentData>(`/trade/equipment/${vin}/data`, data)

export const updateCarEquipmentItems = (items: CarEquipmentItems, vin: string) =>
  api.put<CarEquipmentItems, CarEquipmentItems>(`/trade/equipment/${vin}/items`, items)

export const searchCar = (vinOrRegistrationNumber: string) =>
  axiosApi.get<ReadonlyArray<SearchCarResult>>(
    buildUrl('/cars/opportunity/insurance/car/search', { vinOrRegistrationNumber }),
  )
