import { Loadable } from '../global'
import { UserAction, UserActionType } from './actions'
import { User } from './dto'

export type UserState = {
  readonly me: User | null,
  readonly meInitialized: boolean,
  readonly userList: Loadable<ReadonlyArray<User>>,
}

const defaultUserState: UserState = {
  me: null,
  meInitialized: false,
  userList: {
    data: null,
    loading: false,
  },
}

// tslint:disable-next-line:cyclomatic-complexity
export const userReducer = (state: UserState = defaultUserState, action: UserAction): UserState => {
  switch (action.type) {
    case UserActionType.GetMeSuccess:
      return {
        ...state,
        me: action.payload,
      }

    case UserActionType.SetMeInitialized:
      return {
        ...state,
        meInitialized: true,
      }

    case UserActionType.GetUserList:
      return {
        ...state,
        userList: {
          data: null,
          loading: true,
        },
      }

    case UserActionType.GetUserListSuccess:
      return {
        ...state,
        userList: {
          data: action.payload,
          loading: false,
        },
      }

    default:
      return state
  }
}
