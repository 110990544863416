import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { Testable } from 'actff-bo-lib/global/testable'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = Testable & {
  readonly control: Control,
}

const SellPlannedDates = styled.div`
  height: 95px;
`

const Header = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
`

const PlannedSellDateContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 12px;
`

const SellDate = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 24px 1fr;

  .DayPickerInput {
    display: initial;
  }
`
const getValueAsDate = (value: Date | string) => value ? new Date(value) : null

export const PlannedSellDate: React.FC<Props> = ({ control, testId }) => {
  const { t } = useTranslation()

  return (
    <SellPlannedDates>
      <Header>
        <label>{t('crmTrade.sellPlannedDate')}:</label>
      </Header>
      <PlannedSellDateContainer>
        <SellDate>
          <label>{t('caption.from')}:</label>
          <Controller
            render={({ value, onChange }) =>
              <DatePickerInput onChange={onChange} date={getValueAsDate(value)} testId={`${testId}plannedSaleFrom`} />}
            control={control}
            name='plannedSaleFrom'
          />
        </SellDate>
        <SellDate>
          <label>{t('caption.to')}:</label>
          <Controller
            render={({ value, onChange }) =>
              <DatePickerInput onChange={onChange} date={getValueAsDate(value)} testId={`${testId}plannedSaleTo`} />}
            control={control}
            name='plannedSaleTo'
          />
        </SellDate>
      </PlannedSellDateContainer>
    </SellPlannedDates>
  )
}
