import { BtnType } from 'actff-bo-app/components/Button'
import { getTranslation } from 'actff-bo-lib/i18n'
import React, { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LinkButton } from 'styles'
import { Value, Values } from './Styled'

type TrimPreferencesProps = {
  readonly tKey: string,
  readonly preferences: ReadonlyArray<string>,
  readonly shouldTranslate?: boolean,
}

type SeeMoreButtonProps = {
  readonly expanded: boolean,
}

const SeeMoreButton = styled(LinkButton)<SeeMoreButtonProps>`
  align-items: ${({ expanded }: SeeMoreButtonProps) => expanded ? 'end' : 'start'};
  display: grid;
  height: 100%;
  padding: 0;
  padding-top: 2px;
`

const trimLengthThreshold = 5

export const TrimPreferences: FC<TrimPreferencesProps> = ({ preferences, tKey, shouldTranslate = false }): ReactElement => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  const values = !expanded && preferences.length > trimLengthThreshold ? preferences.slice(0, trimLengthThreshold) : preferences
  const restValuesLength = preferences.length - values.length
  const getSetButtonContent = () => expanded ? t('caption.collapse') : restValuesLength > 0 ? `+${restValuesLength}` : ''

  return (
    <div>
      <label>{t(`crmTrade.sale.preferences.${tKey}`)}:</label>
      <Values>
        <Value>{values.map(value => shouldTranslate ? getTranslation(t, `car.${tKey}.`, value) : value).join(', ')}</Value>
        <SeeMoreButton onClick={() => setExpanded(!expanded)} type={BtnType.Button} expanded={expanded}>
          {getSetButtonContent()}
        </SeeMoreButton>
      </Values>
    </div>
  )
}
