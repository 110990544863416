import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly group: string,
  readonly uuid: string,
  readonly name: string;
  readonly values: object,
  readonly register?: () => void,
}

type ContainerProps = {
  readonly columnCount: number,
  readonly columnWidth: number,
}

const columnWidth = 78

const Container = styled.div`
  border-top: 1px solid ${colors.mysticGray};
  display: grid;
  grid-auto-flow: column;
  min-height: 50px;
  grid-template-columns: ${({ columnCount, columnWidth: containerColumnWidth }: ContainerProps) =>
    `repeat(${columnCount}, ${containerColumnWidth}px) 1fr`};
`
const InputContainer = styled.div`
  border-right: 1px solid ${colors.mysticGray};
  text-align: center;
  width: ${columnWidth}px;
`

const TextContainer = styled.div`
  align-self: center;
  display: inline-block;
  padding: 16px 23px;
`

export const MultipleChoiceField: FC<Props> = ({ group, name, register, uuid, testId, values }) => (
  <Container columnCount={Object.values(values).length} columnWidth={columnWidth}>
    {Object.values(values).map(value =>
      <InputContainer key={`${uuid} ${value}`}>
        <input
          data-testid={`${testId}--${value}`}
          id={`${group}[${uuid}][${value}]`}
          name={`${group}[${uuid}]`}
          type='radio'
          ref={register}
          value={value}
        />
        <label data-testid={`${testId}--${value}-label`} htmlFor={`${group}[${uuid}][${value}]`} />
      </InputContainer>,
    )}
    <TextContainer data-testid={`${testId}--name`}>{name}</TextContainer>
  </Container>
)
