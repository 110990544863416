import { CSSProperties } from 'react'
import Select, { Theme as SelectTheme } from 'react-select'
import { StylesConfigFunction } from 'react-select/src/styles'
import styled from 'styled-components'
import { colors, theme } from 'styles'

type FieldValueProps = {
  readonly hidden?: boolean,
}

type FormHeaderProps = {
  readonly styles?: CSSProperties,
}

type SelectProps = {
  readonly uppercase?: boolean,
}

export const ValidationError = styled.div`
  color: ${theme.form.error};
  font-weight: 600;
  font-size: 13px;
  line-height: 1.2;
`

export const FormRow = styled.div`
  display: grid;
  grid-template-rows: 1fr;
`

export const FormFooter = styled.footer`
  border-top: 1px solid ${theme.form.footer.border};
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  padding: 16px 32px;
`

export const FormFooterLeft = styled.div`
  display: grid;
  justify-items: start;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: max-content;
`

export const FormFooterRight = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  justify-content: end;
`
export const FormHeader = styled.h3<FormHeaderProps>`
  color: ${colors.dark};
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  line-height: 27px;
  text-transform: uppercase;
  ${({ styles }: FormHeaderProps) => ({...styles})};
`

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  height: 72%;
  margin: 28px 32px 0 0;
  padding-bottom: 100px;
`
type FormCellProps = {
  readonly inline?: true,
  readonly slim?: true,
  readonly noPaddingLeft?: false,
}

export const FormCell = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-weight: 600;
  padding: ${({ slim, noPaddingLeft }: FormCellProps) => {
    if (slim) {
      return '25px 0 0 32px'
    }

    if (noPaddingLeft) {
      return '12.5px 0 12.5px 0'
    }

    return '12.5px 0 12.5px 32px'
  }};
`

export const FormCellWide = styled(FormCell)`
  align-content: start;
  grid-column: span 2;
`

export const FormCellFullWidth = styled(FormCell)`
  grid-column: span 4;
`

export const FormSeparator = styled(FormCell)`
  border-top: 1px solid ${colors.mystic};
  grid-column: span 4;
  margin: 32px 0 0 32px;
`

export const PrimaryCell = styled(FormCell)`
  background-color: ${theme.form.primary};
  grid-column: span 2;
  grid-template-columns: 2fr 2fr;
  padding: 7.5px 0;
  margin-top: 12.5px;
`

export const SelectStyled = styled(Select)<SelectProps>`
  text-transform: ${({ uppercase = true }: SelectProps) => uppercase ? 'uppercase' : 'lowercase'};
`

export const FormRadio = styled.div`
  display: ${({ inline = true }: FormCellProps) => inline ? 'inline-block' : 'grid'};
  margin: ${({ slim }: FormCellProps) => slim ? '10px 0' : '10px 24px 0 0'};
`

export const FormCheckbox = styled.div`
  display: inline-block;
  margin: ${({ slim }: FormCellProps) => slim ? '0' : '10px 24px 0 0'};
`

export const reactSelectStyles: Partial<StylesConfigFunction> = {
  container: (styles: CSSProperties) => ({
    cursor: 'pointer',
    ...styles,
    border: 'none',
    outline: 'none',
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    '&:hover': {
      borderColor: theme.form.select.border,
      cursor: 'pointer',
    },
    borderColor: theme.form.select.border,
    borderWidth: 1.5,
    boxShadow: 'none',
    fontSize: 13,
    fontWeight: 'normal',
    height: 32,
    minHeight: 32,
    outline: 'none',
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    color: theme.form.select.indicator,
    padding: '0 8px',
    position: 'relative',
    top: '-2px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  input: (styles: CSSProperties) => ({
    ...styles,
    height: 32,
    marginTop: -2,
    minHeight: 32,
    outline: 'none',
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    fontWeight: 'normal',
  }),
  placeholder: (styles: CSSProperties) => ({
    ...styles,
    height: 32,
    marginTop: 5,
    minHeight: 32,
    textWeight: 500,
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    top: '15px',
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    lineHeight: '24px',
    padding: '0 8px',
  }),
}

export const selectTheme = (reactSelectTheme: SelectTheme) => ({
  ...reactSelectTheme,
  colors: {
    ...reactSelectTheme.colors,
    neutral0: theme.form.select.hoverText,
    neutral20: theme.form.select.hoverText,
    neutral50: theme.form.select.color,
    primary: theme.form.select.selected,
    primary25: theme.form.select.hover,
    primary50: theme.form.select.hover,
  },
})

export const Footer = styled(FormFooter)`
  align-items: self-end;
  height: 400px;
  justify-items: left;
`

export const FieldValue = styled.span<FieldValueProps>`
  ${({ hidden }: FieldValueProps) => hidden && 'visibility: hidden'};
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: .05em;
  line-height: 23px;
  min-width: unset;
  text-transform: uppercase;
`

export const RadioBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`
