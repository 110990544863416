import styled from 'styled-components'

import { colors } from 'styles'

export const Calendar = styled.div`
  .fc-toolbar {
    display: block;

    &.fc-header-toolbar {
      margin: 24px 0;
    }
  }

  .fc-toolbar-title {
    display: inline-block;
  }

  .fc-prev-button, .fc-next-button {
    background: transparent;
    border: 0;
    color: ${colors.kumera};
    padding-top: 0;

    &:hover,
    &:active {
      background: transparent;
      color: ${colors.kumera};
    }
  }
`
