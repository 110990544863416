export enum IconType {
  Accept,
  Arrow,
  ArrowTop,
  ArrowTop2,
  ArrowBottom ,
  ArrowBottom2 ,
  Attachment,
  Back,
  Bulb,
  Calendar,
  CalendarWhite,
  Call,
  Car,
  CarWhite,
  ChatOpen,
  ChatOpenExpanded,
  Chat,
  CheckMark,
  CheckMarkWhite,
  Clients,
  Clock,
  Close,
  CloseCircle,
  CloseWhite,
  Comment,
  Cog,
  Cross,
  Crown,
  Crm,
  CrmInsurance,
  CrmTrade,
  CrmService,
  DangerTriangle,
  Dashboard,
  Download,
  DownloadGold,
  Dropdown,
  DropdownGold,
  Edit,
  EditBlack,
  Email,
  EmailEnvelope,
  ErrorCircle,
  ErrorTriangle,
  Exit,
  Eye,
  Event,
  Flag,
  Fullscreen,
  IdCard,
  ImageBlack,
  ImageWhite,
  ImageBig,
  Info,
  Loader,
  LineVertical,
  MenuCollapse,
  Minus,
  MobilePhone,
  More,
  NewServiceRequest,
  NewPage,
  NoCars,
  PDF,
  Phone,
  Plus,
  PlusWhite,
  Refresh,
  Ring,
  Rotate,
  Save,
  Search,
  Sms,
  Speed,
  StatusFailure,
  StatusDefinitiveFailure,
  StatusSuccess,
  TaskList,
  Timer,
  Trash,
  Trash2,
  TrashRed,
  Undo,
  Undo2,
}
