import { ClientFullName, ClientType as ClientTypeComponent } from 'actff-bo-app/components/Client'
import { FormHeader } from 'actff-bo-app/components/Form'
import { Icon } from 'actff-bo-app/components/Icon'
import { isCounterparty } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { CrmTradeOwner } from 'actff-bo-lib/crm/trade'
import { IconType } from 'actff-bo-lib/menu'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, H2 } from 'styles'

type Props = {
  readonly owner?: CrmTradeOwner,
  readonly header?: string,
}

const ClientContainer = styled.div`
  border-top: 1px solid ${colors.athensGray};
  background: ${colors.athensGray};
  grid-area: owner;
  padding: 32px 30px;
  `

const ClientNameContainer = styled.div`
  grid-area: type-name;
`

const ClientName = styled(H2)`
  margin-right: 10px;
`

const ClientType = styled(ClientTypeComponent)`
  display: inline;
`

const ClientItem = styled.span`
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  margin-right: 24px;
`

export const OpportunityOwner: React.FC<Props> = ({ owner, header }) => {
  const { t } = useTranslation()

  if (!owner) {
    return <ClientContainer><FormHeader>{t('crmTrade.opportunity.lackOfOwner')}</FormHeader></ClientContainer>
  }

  const { email, id, phone } = owner

  const getId = () => id && <ClientItem><Icon type={IconType.IdCard} />{id}</ClientItem>
  const getHeader = () => header && <FormHeader>{t(header)}:</FormHeader>
  const getOwnerName = () => isCounterparty(owner) ? owner.name : <ClientFullName client={owner as Client} />
  const renderPhoneNumber = () => phone && <ClientItem><Icon type={IconType.Phone} />{phone}</ClientItem>
  const renderEmail = () => email && <ClientItem><Icon type={IconType.Email} />{email}</ClientItem>

  return (
    <ClientContainer>
      {getHeader()}
      <ClientNameContainer>
        <ClientName>{getOwnerName()}</ClientName>
        <ClientType className='client-type' client={owner} />
      </ClientNameContainer>
      {getId()}
      {renderPhoneNumber()}
      {renderEmail()}
    </ClientContainer>
  )
}
