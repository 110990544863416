import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { FormFooter, FormFooterLeft, FormFooterRight } from 'actff-bo-app/components/Form'
import { Client, ClientAction, ClientId, ClientStatus } from 'actff-bo-lib/client'
import { Language } from 'actff-bo-lib/i18n'
import { IconType, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State as GlobalState } from 'actff-bo-lib/store'
import { selectMe, User, UserPermissions } from 'actff-bo-lib/user'
import { hasPermission } from 'actff-bo-lib/user/has-permission'
import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'
import { MinorButton, PrimaryButton } from 'styles/Button'

import { withClientViewHeader } from '../withClientViewHeader'
import { ArchiveClientPopup } from './ArchiveClientPopup'
import { BasicDataForm } from './BasicDataForm'

type DispatchToProps = {
  readonly archiveClient: (clientUuid: ClientId) => () => void,
  readonly restoreClient: (clientUuid: ClientId) => () => void,
  readonly updateClient: (client: Client) => void,
}

type StateToProps = {
  readonly user: User | null,
}

type State = {
  readonly isInEditMode: boolean,
  readonly isPopupVisible: boolean,
  readonly shouldResetForm: boolean,
}

type BasicDataComponentProps = {
  readonly client: Client,
  readonly currentLanguage: Language,
  readonly user: User,
}

export type BasicDataProps = DispatchToProps & WithTranslation & BasicDataComponentProps

class BasicDataComponent extends React.Component<BasicDataProps> {
  public readonly state: State = {
    isInEditMode: false,
    isPopupVisible: false,
    shouldResetForm: false,
  }

  public render(): React.ReactNode {
    const { archiveClient, client, user, updateClient, t, restoreClient } = this.props
    const { isInEditMode, isPopupVisible } = this.state

    return (
      <>
        <ArchiveClientPopup
          client={client}
          isOpened={isPopupVisible}
          onCancel={this.closePopup}
          onConfirm={archiveClient}
        />
        <BasicDataForm
          client={client}
          isInEditMode={isInEditMode}
          handleToggleEditable={this.toggleEditable}
          handleToggleResetForm={this.toggleResetForm}
          shouldResetForm={this.state.shouldResetForm}
          updateClient={updateClient}
          user={user}
        />
        <FixedFooter>
          <FormFooter>
            <FormFooterLeft>
              {isInEditMode && (
                <ButtonWithIcon
                  caption={t('caption.revertChanges')}
                  onClick={this.toggleResetForm}
                  iconType={IconType.Undo}
                  theme={BtnTheme.Link}
                />
              )}
            </FormFooterLeft>
            <FormFooterRight>
              {this.canArchiveClient() && (
                <MinorButton type={BtnType.Button} onClick={this.openPopup}>{t('clientView.archive')}</MinorButton>
              )}
              {this.canRestoreClient() && (
                <MinorButton type={BtnType.Button} onClick={restoreClient(client.uuid)}>{t('clientView.restore')}</MinorButton>
              )}
              <PrimaryButton type={BtnType.Button} onClick={this.handleGoBackClick}>{t('caption.backToList')}</PrimaryButton>
            </FormFooterRight>
          </FormFooter>
        </FixedFooter>
      </>
    )
  }

  private readonly canRestoreClient = () => {
    const { client, user } = this.props

    return client.status === ClientStatus.Archived && hasPermission([UserPermissions.AdminAllClients])(user.permissions)
  }

  private readonly canArchiveClient = () => {
    const { client, user } = this.props

    return hasPermission([UserPermissions.AdminAllClients])(user.permissions)
      && client.status !== ClientStatus.Archived
      && client.status !== ClientStatus.Deleted
  }

  private readonly handleGoBackClick = () => {
    history.push(Paths.ClientList)
  }

  private readonly openPopup = () => {
    this.setState({
      isPopupVisible: true,
    })
  }

  private readonly closePopup = () => {
    this.setState({
      isPopupVisible: false,
    })
  }

  private readonly toggleEditable = () => {
    this.setState({
      isInEditMode: !this.state.isInEditMode,
    })
  }

  private readonly toggleResetForm = () => {
    this.setState({
      shouldResetForm: !this.state.shouldResetForm,
    })
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  archiveClient: (uuid: ClientId) => () => { dispatch(ClientAction.archiveClient(uuid)) },
  restoreClient: (uuid: ClientId) => () => { dispatch(ClientAction.restoreClient(uuid)) },
  updateClient: (payload: Client) => { dispatch(ClientAction.updateClient(payload)) },
})

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  user: selectMe(state),
})

export const BasicData = compose(
  withClientViewHeader,
  connect(mapStateToProps, mapDispatchToProps),
)(BasicDataComponent)
