
import { Map } from 'immutable'
import { CarEngineCode, CarEngineFuelType } from '../dto'

enum FuelTypeChars {
  B = 'B',
  D = 'D',
}

const FuelMap: Map<string, CarEngineFuelType> = Map([
  [FuelTypeChars.B, CarEngineFuelType.Gasoline],
  [FuelTypeChars.D, CarEngineFuelType.Diesel],
]) as Map<string, CarEngineFuelType>

export const mapEngineCodeToFuelType = (engineCode?: CarEngineCode): CarEngineFuelType => {
  if (!engineCode) {
    return CarEngineFuelType.NA
  }

  const engineCodeFirstLetter = engineCode.split('')[0].toUpperCase()

  return FuelMap.get(engineCodeFirstLetter, CarEngineFuelType.NA)
}
