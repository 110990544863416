import { ErrorMessage } from '@hookform/error-message'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Label } from 'actff-bo-app/components/Label'
import { MultipleSelect } from 'actff-bo-app/components/MultipleSelect'
import { CalendarResourceId } from 'actff-bo-lib/admin'
import { SelectOption } from 'actff-bo-lib/global'
import React, { FC } from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ResourceContainer, ResourceRow } from './Styled'

type Props = Pick<UseFormMethods, 'control' | 'errors' | 'watch'> & {
  // tslint:disable-next-line no-any
  readonly resources?: any, // TODO: Find typing for resources
}

// tslint:disable-next-line no-any
const mapResourcesToSelect = (resources: ReadonlyArray<any>): ReadonlyArray<SelectOption<CalendarResourceId>> =>
  resources.reduce((prev, curr) => {
    const value = 'children' in curr
      ? mapResourcesToSelect(curr.children)
      : [{ label: curr.title, value: curr.id }]

    return ([
      ...prev,
      ...value,
    ])
  }, [])

export const Resources: FC<Props> = ({ control, resources, errors, watch }) => {
  const { t } = useTranslation()

  const resourcesValidation = () => watch('resources').length === 0 ? 'caption.error.required' : undefined

  return (
    <ResourceContainer>
      <Label>{t('admin.employeeSchedule.form.resource')}</Label>
      <ResourceRow>
        <Controller
          render={({ onChange, value }) => (
            <MultipleSelect
              shouldTranslate={false}
              // @ts-ignore mismatch with react-select typings
              isMulti={true}
              options={mapResourcesToSelect(resources)}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          rules={{ validate: resourcesValidation }}
          name='resources'
        />
        <ErrorMessage errors={errors} name='resources' as={TranslatedErrorMessage} />
      </ResourceRow>
    </ResourceContainer>
  )
}
