import { PagedResult } from 'actff-bo-lib/api'
import { Testable } from 'actff-bo-lib/global/testable'
import { ServiceRequest } from 'actff-bo-lib/service-request'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { ServiceRequestRow } from './ServiceRequestRow'

type Props = Testable & {
  readonly serviceRequests: PagedResult<ServiceRequest>,
}

export const ServiceRequestTable: React.FC<Props> = ({ serviceRequests: { result }, testId }) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
          <Tr>
            <Th>{t('table.columns.data')}</Th>
            <Th>{t('table.columns.id')}</Th>
            <Th>{t('table.columns.status')}</Th>
            <Th>{t('table.columns.fullName')}</Th>
            <Th>{t('table.columns.type')}</Th>
            <Th>{t('table.columns.registrationNumber')}</Th>
            <Th>{t('table.columns.phone')}</Th>
            <Th>{t('table.columns.service')}</Th>
            <Th>{t('table.columns.notes')}</Th>
            <Th>{t('table.columns.location')}</Th>
          </Tr>
        </thead>
        <tbody>
          {result.length && result.map((serviceRequest, index) => (
            <ServiceRequestRow key={serviceRequest.uuid} testId={`${testId}request-${index}`} serviceRequest={serviceRequest} />
          ))}
        </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )

}
