import { Icon } from 'actff-bo-app/components/Icon'
import { AttachmentAction, selectAttachmentForUrl, Url } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu'
import Lightbox from 'lightbox-react'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

type Props = {
  readonly url: Url,
  readonly prevUrl: Url,
  readonly nextUrl: Url,
  readonly isOpen: boolean,
  readonly onLightboxClose: (open: boolean) => void,
  readonly onMoveNextRequest?: () => void,
  readonly onMovePrevRequest?: () => void,
}

export const DownloadLink = styled.a`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
`

export const DownloadIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  opacity: 0.7;
  position: absolute;
  top: 5px;
`

export const AttachmentPreview: FC<Props> = ({
  url,
  prevUrl,
  nextUrl,
  isOpen,
  onLightboxClose,
  onMovePrevRequest,
  onMoveNextRequest,
}) => {
  const [showDownloadButton, setShowDownloadButton] = useState(false)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(AttachmentAction.getAttachment(url))
  }, [url, dispatch])

  const attachment = useSelector(selectAttachmentForUrl(url))
  const closeLightbox = () => onLightboxClose(false)

  const DownloadButton = () => <DownloadLink href={attachment} download={true}><DownloadIcon type={IconType.Download} /></DownloadLink>

  return (
    attachment && isOpen ? (
      <Lightbox
        mainSrc={attachment}
        nextSrc={nextUrl}
        prevSrc={prevUrl}
        onMoveNextRequest={onMoveNextRequest}
        onMovePrevRequest={onMovePrevRequest}
        onCloseRequest={closeLightbox}
        onImageLoadError={() => setShowDownloadButton(true)}
        enableZoom={!!attachment}
        toolbarButtons={[
          showDownloadButton && <DownloadButton />,
        ]}
      />
    ) : null
  )
}
