import { Footer } from 'actff-bo-app/Admin/Footer'
import { Container } from 'actff-bo-app/Admin/Styled'
import { Label } from 'actff-bo-app/components/Label'
import {
  RegulationsAndApprovalsForm as RegulationsAndApprovalsFormType,
} from 'actff-bo-lib/admin/dealership'
import { ApprovalsContent } from 'actff-bo-lib/approvals-content/dto'
import { getOrElse } from 'actff-bo-lib/global/data'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
// tslint:disable-next-line:no-import-side-effect
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import { colors } from 'styles'
import { formats, modules } from './EditorConfig'

type Props = {
  readonly data: ApprovalsContent | undefined,
  readonly onUpdate: (formData: RegulationsAndApprovalsFormType) => void,
}

const RegulationsContainer = styled.div`
  display: grid;
  min-height: 320px;
  grid-auto-flow: column;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 1fr;
`

const RequiredApprovalLabel = styled.label`
  color: ${colors.grey};
  font-weight: normal;
  padding-left: 8px;
  text-transform: none;
`

const Segment = styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-template-rows: 20px 1fr;
`

const ReactQuillStyled = styled(ReactQuill)`
  border: 1px solid ${colors.mysticGray};
  height: 600px;

  .ql-container {
    border: 1px solid ${colors.mysticGray};
    height: 85%;
  }

  .ql-toolbar {
    border: 1px solid ${colors.mysticGray};
  }
`

export const RegulationsAndApprovalsForm: FC<Props> = ({ data, onUpdate }) => {
  const { t } = useTranslation()

  const termsAndConditions = getOrElse(data?.termsAndConditions.general.document, '')
  const personalData = getOrElse(data?.personalData.general.document, '')

  const {
    control,
    handleSubmit,
    formState,
  } = useForm({
    defaultValues: {
      personalData,
      termsAndConditions,
    },
    mode: 'onBlur',
  })

  const handleUpdate = (formData: RegulationsAndApprovalsFormType) => {
    onUpdate(formData)
  }

  const isSubmitDisabled = () => !formState.isDirty || !formState.isValid

  return (
    <Container>
      <RegulationsContainer>
        <Segment>
          <div>
            <Label uppercase={false} color={colors.black}>{t('admin.dealership.regulationsAndApprovals.regulationsEdit')}</Label>
            <RequiredApprovalLabel>({t('admin.dealership.regulationsAndApprovals.requiredApproval')})</RequiredApprovalLabel>
          </div>
          <Controller
            render={({ value, onChange }) =>
              <ReactQuillStyled theme='snow' value={value} onChange={onChange} modules={modules} formats={formats} />}
            control={control}
            name='termsAndConditions'
          />
        </Segment>
        <Segment>
          <Label uppercase={false} color={colors.black}>{t('admin.dealership.regulationsAndApprovals.personalDataEdit')}</Label>
          <Controller
            render={({ value, onChange }) =>
              <ReactQuillStyled theme='snow' value={value} onChange={onChange} modules={modules} formats={formats} />}
            control={control}
            name='personalData'
          />
        </Segment>
      </RegulationsContainer>

      <Footer isSaveDisabled={isSubmitDisabled()} onSave={handleSubmit(handleUpdate)} />
    </Container>
  )
}
