import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { Filters } from 'actff-bo-lib/global'
import { UserAction, UserActionType, UserId, UserPermissions } from 'actff-bo-lib/user'

import { CrmServiceAction, CrmServiceActionType } from '../actions'
import { OpportunityCreationMode, OpportunityFilterValues, OpportunityStatus } from '../dto'

export type CrmServiceListFiltersState = {
  readonly filters: Filters<OpportunityFilterValues>,
  readonly brandFilters: ReadonlyArray<string>,
  readonly locationFilters: ReadonlyArray<DealerLocationKey>,
  readonly assignmentFilter: UserId | null,
  readonly searchPhrase?: string,
}

const initialState = {
  assignmentFilter: null,
  brandFilters: [],
  filters: { // TODO: Rebuild filters logic as Robert suggested https://github.com/Actff/autogala_backoffice/pull/446#discussion_r430948468
    creationMode: [
      {
        caption: 'filters.opportunity.manual',
        selected: false,
        value: OpportunityCreationMode.MANUAL,
      },
      {
        caption: 'filters.opportunity.automatic',
        selected: false,
        value: OpportunityCreationMode.AUTOMATIC,
      },
    ],
    status: [
      {
        caption: 'filters.opportunity.expired',
        requiredPermissions: [UserPermissions.AdminAllService],
        selected: false,
        value: OpportunityStatus.EXPIRED,
      },
      {
        caption: 'filters.new',
        selected: false,
        value: [OpportunityStatus.NEW, OpportunityStatus.NEW_LIST],
      },
      {
        caption: 'filters.opportunity.missed',
        requiredPermissions: [UserPermissions.AdminAllService],
        selected: false,
        value: OpportunityStatus.MISSED,
      },
      {
        caption: 'filters.opportunity.pushSent',
        selected: false,
        value: OpportunityStatus.PUSH_SENT,
      },
      {
        caption: 'filters.opportunity.arranged',
        requiredPermissions: [UserPermissions.AdminAllService],
        selected: false,
        value: OpportunityStatus.ARRANGED,
      },
      {
        caption: 'filters.opportunity.afterAppointment',
        selected: false,
        value: OpportunityStatus.AFTER_APPOINTMENT_LIST,
      },
      {
        caption: 'filters.opportunity.cancelled',
        selected: false,
        value: [OpportunityStatus.CANCELLED, OpportunityStatus.CANCELLED_LIST],
      },
      {
        caption: 'filters.opportunity.retry',
        selected: false,
        value: [OpportunityStatus.RETRY, OpportunityStatus.RETRY_LIST],
      },
      {
        caption: 'filters.opportunity.accomplished',
        requiredPermissions: [UserPermissions.AdminAllService],
        selected: false,
        value: OpportunityStatus.ACCOMPLISHED,
      },
      {
        caption: 'filters.opportunity.failed',
        requiredPermissions: [UserPermissions.AdminAllService],
        selected: false,
        value: OpportunityStatus.FAILED,
      },
    ],
  },
  locationFilters: [],
}

// tslint:disable-next-line:cyclomatic-complexity
export const crmServiceListFiltersReducer = (
  state: CrmServiceListFiltersState = initialState,
  action: CrmServiceAction | UserAction,
) => {
  switch (action.type) {
    case CrmServiceActionType.ChangeAssignmentFilter:
      return {
        ...state,
        assignmentFilter: action.payload,
      }

    case CrmServiceActionType.ChangeListsDealerLocationFilters:
      return {
        ...state,
        locationFilters: action.payload,
      }

    case CrmServiceActionType.ChangeListsBrandFilters:
      return {
        ...state,
        brandFilters: action.payload,
      }

    case CrmServiceActionType.ChangeListsFilter:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.param]: [
            ...state.filters[action.payload.param]
              .map(filter => filter.value === action.payload.value
                ? ({
                  ...filter,
                  selected: !filter.selected,
                })
                : ({ ...filter }),
              ),
          ],
        },
      }

    case CrmServiceActionType.ChangeSearchPhrase:
      return {
        ...state,
        searchPhrase: action.payload,
      }

    case UserActionType.GetMeSuccess:
      return {
        ...state,
        locationFilters: action.payload.locations.map(location => location.key),
      }

    default:
      return state
  }
}
