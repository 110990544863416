import { MenuItem } from '../dto'

export const filterHiddenMenuItems = (menu: ReadonlyArray<MenuItem>): ReadonlyArray<MenuItem> =>
  menu
    .filter(element => element.path !== '/')
    .filter(element => !element.hiddenInMenu)
    .map(element => element.children.length > 0
      ? (
        {
          ...element,
          children: filterHiddenMenuItems(element.children),
        }
      )
      : element,
    )
