import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { Attachment, InternalComment } from 'actff-bo-lib/global'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import { AjaxError } from 'rxjs/ajax'

import {
  Lead,
  OpportunityAccomplishmentDetailsDto,
  OpportunityArrangedDto,
  OpportunityFailedDto,
  OpportunityFilterValues,
  OpportunityId,
  OpportunityInternalCommentDto,
  OpportunityManualPayload,
  OpportunityMissedDto,
  OpportunityRetryDto,
  OpportunityService,
  OpportunityStatus,
  UpdateOpportunityFromDraftDto,
} from './dto'

export enum CrmServiceActionType {
  AssignOpportunity = '[OPPORTUNITY] ASSIGN OPPORTUNITY',
  AssignOpportunityFailure = '[OPPORTUNITY] ASSIGN OPPORTUNITY FAILURE',
  AssignOpportunitySuccess = '[OPPORTUNITY] ASSIGN OPPORTUNITY SUCCESS',

  ChangeOpportunityListCurrentPage = '[OPPORTUNITY] CHANGE OPPORTUNITY LIST CURRENT PAGE',
  ChangeOpportunityListTimeFrom = '[OPPORTUNITY] CHANGE OPPORTUNITY LIST TIME FROM',
  ChangeOpportunityListTimeTo = '[OPPORTUNITY] CHANGE OPPORTUNITY LIST TIME TO',

  ChangeSearchPhrase = '[OPPORTUNITY] CHANGE SEARCH PHRASE',
  ChangeAssignmentFilter = '[OPPORTUNITY] CHANGE ASSIGNMENT FILTER',
  ChangeListsFilter = '[OPPORTUNITY] CHANGE LISTS FILTER',
  ChangeListsDealerLocationFilters = '[OPPORTUNITY] CHANGE LISTS DEALER LOCATION FILTERS',
  ChangeListsBrandFilters = '[OPPORTUNITY] CHANGE LISTS BRAND FILTERS',

  ClearOpportunity = '[OPPORTUNITY] CLEAR OPPORTUNITY',

  CreateOpportunityManual = '[OPPORTUNITY] CREATE OPPORTUNITY MANUAL',
  CreateOpportunityManualFailure = '[OPPORTUNITY] CREATE OPPORTUNITY MANUAL FAILURE',
  CreateOpportunityManualSuccess = '[OPPORTUNITY] CREATE OPPORTUNITY MANUAL SUCCESS',

  GetOpportunity = '[OPPORTUNITY] GET OPPORTUNITY',
  GetOpportunityFailure = '[OPPORTUNITY] GET OPPORTUNITY FAILURE',
  GetOpportunitySuccess = '[OPPORTUNITY] GET OPPORTUNITY SUCCESS',

  GetLead = '[OPPORTUNITY] GET LEAD',
  GetLeadFailure = '[OPPORTUNITY] GET LEAD FAILURE',
  GetLeadSuccess = '[OPPORTUNITY] GET LEAD SUCCESS',

  GetInternalComments = '[OPPORTUNITY] GET INTERNAL COMMENTS',
  GetInternalCommentsFailure = '[OPPORTUNITY] GET INTERNAL COMMENTS FAILURE',
  GetInternalCommentsSuccess = '[OPPORTUNITY] GET INTERNAL COMMENTS SUCCESS',

  GetOpportunities = '[OPPORTUNITY] GET OPPORTUNITIES',
  GetOpportunitiesFailure = '[OPPORTUNITY] GET OPPORTUNITIES FAILURE',
  GetOpportunitiesSuccess = '[OPPORTUNITY] GET OPPORTUNITIES SUCCESS',

  ResetPaginationOnLists = '[OPPORTUNITY] RESET PAGINATION ON LISTS',

  SetOpportunityStatusAccomplished = '[OPPORTUNITY] SET OPPORTUNITY STATUS ACCOMPLISHED',
  SetOpportunityStatusAccomplishedFailure = '[OPPORTUNITY] SET OPPORTUNITY STATUS ACCOMPLISHED FAILURE',
  SetOpportunityStatusAccomplishedSuccess = '[OPPORTUNITY] SET OPPORTUNITY STATUS ACCOMPLISHED SUCCESS',

  SetOpportunityStatusArranged = '[OPPORTUNITY] SET OPPORTUNITY STATUS ARRANGED',
  SetOpportunityStatusArrangedFailure = '[OPPORTUNITY] SET OPPORTUNITY STATUS ARRANGED FAILURE',
  SetOpportunityStatusArrangedSuccess = '[OPPORTUNITY] SET OPPORTUNITY STATUS ARRANGED SUCCESS',

  SetOpportunityStatusFailed = '[OPPORTUNITY] SET OPPORTUNITY STATUS FAILED',
  SetOpportunityStatusFailedFailure = '[OPPORTUNITY] SET OPPORTUNITY STATUS FAILED FAILURE',
  SetOpportunityStatusFailedSuccess = '[OPPORTUNITY] SET OPPORTUNITY STATUS FAILED SUCCESS',

  SetOpportunityStatusRetry = '[OPPORTUNITY] SET OPPORTUNITY STATUS RETRY',
  SetOpportunityStatusRetryFailure = '[OPPORTUNITY] SET OPPORTUNITY STATUS RETRY FAILURE',
  SetOpportunityStatusRetrySuccess = '[OPPORTUNITY] SET OPPORTUNITY STATUS RETRY SUCCESS',

  SetOpportunityStatusMissed = '[OPPORTUNITY] SET OPPORTUNITY STATUS MISSED',
  SetOpportunityStatusMissedFailure = '[OPPORTUNITY] SET OPPORTUNITY STATUS MISSED FAILURE',
  SetOpportunityStatusMissedSuccess = '[OPPORTUNITY] SET OPPORTUNITY STATUS MISSED SUCCESS',

  SendInternalComment = '[OPPORTUNITY] SEND INTERNAL COMMENT',
  SendInternalCommentFailure = '[OPPORTUNITY] SEND INTERNAL COMMENT FAILURE',
  SendInternalCommentSuccess = '[OPPORTUNITY] SEND INTERNAL COMMENT SUCCESS',

  UnassignOpportunity = '[OPPORTUNITY] UNASSIGN OPPORTUNITY',
  UnassignOpportunityFailure = '[OPPORTUNITY] UNASSIGN OPPORTUNITY FAILURE',
  UnassignOpportunitySuccess = '[OPPORTUNITY] UNASSIGN OPPORTUNITY SUCCESS',

  UpdateDraftOpportunity = '[OPPORTUNITY] UPDATE CURRENT OPPORTUNITY',
  UpdateStatusAndResetDraftOpportunity = '[OPPORTUNITY] UPDATE STATUS AND RESET DRAFT OPPORTUNITY',

  UpdateDraftOpportunityComment = '[OPPORTUNITY] UPDATE DRAFT OPPORTUNITY COMMENT',

  UpdateOpportunityFromDraft = '[OPPORTUNITY] UPDATE OPPORTUNITY FROM DRAFT',
  UpdateOpportunityFromDraftFailure = '[OPPORTUNITY] UPDATE OPPORTUNITY FROM DRAFT FAILURE',
  UpdateOpportunityFromDraftSuccess = '[OPPORTUNITY] UPDATE OPPORTUNITY FROM DRAFT SUCCESS',
}

export const CrmServiceAction = {
  assignOpportunity: (uuid: OpportunityId, user: UserWithoutPermissions, opportunityListType: OpportunityListType) =>
    createAction(CrmServiceActionType.AssignOpportunity, { uuid, user, opportunityListType }),
  assignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmServiceActionType.AssignOpportunityFailure, error),
  assignOpportunitySuccess: (opportunity: OpportunityService, opportunityListType: OpportunityListType) =>
    createAction(CrmServiceActionType.AssignOpportunitySuccess, { opportunity, opportunityListType }),

  changeOpportunityListCurrentPage: (page: number, type: OpportunityListType) =>
    createAction(CrmServiceActionType.ChangeOpportunityListCurrentPage, { page, type }),
  changeOpportunityListTimeFrom: (date: Date, type: OpportunityListType) =>
    createAction(CrmServiceActionType.ChangeOpportunityListTimeFrom, { date, type } ),
  changeOpportunityListTimeTo: (date: Date, type: OpportunityListType) =>
    createAction(CrmServiceActionType.ChangeOpportunityListTimeTo, { date, type }),

  changeAssignmentFilter: (filter: UserId | null) =>
    createAction(CrmServiceActionType.ChangeAssignmentFilter, filter),

  changeListFilters: (param: string, value: OpportunityFilterValues) =>
    createAction(CrmServiceActionType.ChangeListsFilter, { param, value }),
  changeListsBrandFilters: (brands: ReadonlyArray<string>) =>
    createAction(CrmServiceActionType.ChangeListsBrandFilters, brands),
  changeListsDealerLocationFilters: (locations: ReadonlyArray<DealerLocationKey>) =>
    createAction(CrmServiceActionType.ChangeListsDealerLocationFilters, locations),
  changeSearchPhrase: (searchPhrase: string) => createAction(CrmServiceActionType.ChangeSearchPhrase, searchPhrase),

  clearOpportunity: () => createAction(CrmServiceActionType.ClearOpportunity),

  createOpportunityManual: (payload: OpportunityManualPayload) => createAction(CrmServiceActionType.CreateOpportunityManual, payload),
  createOpportunityManualFailure: (error: AjaxError) => createAction(CrmServiceActionType.CreateOpportunityManualFailure, error),
  createOpportunityManualSuccess: (opportunity: OpportunityService) =>
    createAction(CrmServiceActionType.CreateOpportunityManualSuccess, opportunity),

  getOpportunity: (uuid: OpportunityId) => createAction(CrmServiceActionType.GetOpportunity, uuid),
  getOpportunityFailure: (error: AjaxError) => createAction(CrmServiceActionType.GetOpportunityFailure, error),
  getOpportunitySuccess: (opportunity: OpportunityService) => createAction(CrmServiceActionType.GetOpportunitySuccess, opportunity),

  getLead: (vin: string) => createAction(CrmServiceActionType.GetLead, vin),
  getLeadFailure: (error: AjaxError) => createAction(CrmServiceActionType.GetLeadFailure, error),
  getLeadSuccess: (lead: Lead) => createAction(CrmServiceActionType.GetLeadSuccess, lead),

  resetPaginationOnLists: () => createAction(CrmServiceActionType.ResetPaginationOnLists),

  setOpportunityStatusAccomplished: (opportunity: OpportunityAccomplishmentDetailsDto) =>
    createAction(CrmServiceActionType.SetOpportunityStatusAccomplished, opportunity),
  setOpportunityStatusAccomplishedFailure: (error: AjaxError) =>
    createAction(CrmServiceActionType.SetOpportunityStatusAccomplishedFailure, error),
  setOpportunityStatusAccomplishedSuccess: (opportunity: OpportunityService) =>
    createAction(CrmServiceActionType.SetOpportunityStatusAccomplishedSuccess, opportunity),

  setOpportunityStatusArranged: (opportunity: OpportunityArrangedDto) =>
    createAction(CrmServiceActionType.SetOpportunityStatusArranged, opportunity),
  setOpportunityStatusArrangedFailure: (error: AjaxError) =>
    createAction(CrmServiceActionType.SetOpportunityStatusArrangedFailure, error),
  setOpportunityStatusArrangedSuccess: (opportunity: OpportunityService) =>
    createAction(CrmServiceActionType.SetOpportunityStatusArrangedSuccess, opportunity),

  setOpportunityStatusFailed: (opportunity: OpportunityFailedDto) =>
    createAction(CrmServiceActionType.SetOpportunityStatusFailed, opportunity),
  setOpportunityStatusFailedFailure: (error: AjaxError) =>
    createAction(CrmServiceActionType.SetOpportunityStatusFailedFailure, error),
  setOpportunityStatusFailedSuccess: (opportunity: OpportunityService) =>
    createAction(CrmServiceActionType.SetOpportunityStatusFailedSuccess, opportunity),

  setOpportunityStatusRetry: (opportunity: OpportunityRetryDto) =>
    createAction(CrmServiceActionType.SetOpportunityStatusRetry, opportunity),
  setOpportunityStatusRetryFailure: (error: AjaxError) =>
    createAction(CrmServiceActionType.SetOpportunityStatusRetryFailure, error),
  setOpportunityStatusRetrySuccess: (opportunity: OpportunityService) =>
    createAction(CrmServiceActionType.SetOpportunityStatusRetrySuccess, opportunity),

  setOpportunityStatusMissed: (opportunity: OpportunityMissedDto) =>
    createAction(CrmServiceActionType.SetOpportunityStatusMissed, opportunity),
  setOpportunityStatusMissedFailure: (error: AjaxError) =>
    createAction(CrmServiceActionType.SetOpportunityStatusMissedFailure, error),
  setOpportunityStatusMissedSuccess: (opportunity: OpportunityService) =>
    createAction(CrmServiceActionType.SetOpportunityStatusMissedSuccess, opportunity),

  getOpportunities: (type: OpportunityListType) => createAction(CrmServiceActionType.GetOpportunities, { type }),
  getOpportunitiesFailure: (type: OpportunityListType, error: AjaxError) =>
    createAction(CrmServiceActionType.GetOpportunitiesFailure, { type, error }),
  getOpportunitiesSuccess: (type: OpportunityListType, opportunityList: PagedResult<OpportunityService>) =>
    createAction(CrmServiceActionType.GetOpportunitiesSuccess, { type, opportunityList }),

  sendInternalComment: (opportunityId: OpportunityId, internalComment: OpportunityInternalCommentDto) =>
    createAction(CrmServiceActionType.SendInternalComment, { opportunityId, internalComment }),
  sendInternalCommentFailure: (error: AjaxError) => createAction(CrmServiceActionType.SendInternalCommentFailure, error),
  sendInternalCommentSuccess: (internalComment: InternalComment) =>
    createAction(CrmServiceActionType.SendInternalCommentSuccess, internalComment),

  unassignOpportunity: (uuid: OpportunityId, opportunityListType: OpportunityListType) =>
    createAction(CrmServiceActionType.UnassignOpportunity, { uuid, opportunityListType }),
  unassignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmServiceActionType.UnassignOpportunityFailure, error),
  unassignOpportunitySuccess: (opportunity: OpportunityService, opportunityListType: OpportunityListType) =>
    createAction(CrmServiceActionType.UnassignOpportunitySuccess, { opportunity, opportunityListType }),

  updateDraftOpportunity: (opportunity: Partial<OpportunityService>) =>
    createAction(CrmServiceActionType.UpdateDraftOpportunity, opportunity),
  updateStatusAndResetDraftOpportunity: (opportunityStatus: OpportunityStatus) =>
    createAction(CrmServiceActionType.UpdateStatusAndResetDraftOpportunity, opportunityStatus),

  updateOpportunityFromDraft: (opportunity: OpportunityService, comment: string, attachments: ReadonlyArray<Attachment>) =>
    createAction<CrmServiceActionType.UpdateOpportunityFromDraft, UpdateOpportunityFromDraftDto>
      (CrmServiceActionType.UpdateOpportunityFromDraft, { attachments, comment, opportunity }),
}

export type CrmServiceAction = ActionsUnion<typeof CrmServiceAction>
