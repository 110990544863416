import { ClientEmail, ClientFullName, ClientPhone, ClientType } from 'actff-bo-app/components/Client'
import { Client } from 'actff-bo-lib/client'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly client: Client,
}

const Container = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-template-areas:
    "name type"
    "phone ."
    "email .";
  justify-content: start;
`

const Name = styled.span`
  color: ${colors.kumera};
  display: grid;
  font-size: 24px;
  font-weight: 400;
  grid-area: name;
`

const Types = styled.span`
  align-content: center;
  color: ${colors.dustyGray};
  display: grid;
  font-size: 13px;
  font-weight: 600;
  grid-area: type;
  grid-auto-flow: column;
  justify-content: start;
  text-transform: uppercase;
`

const Phone = styled.span`
  display: grid;
  font-size: 18px;
  font-weight: 300;
  grid-area: phone;
`

const Email = styled.span`
  color: ${colors.kumera};
  display: grid;
  font-size: 18px;
  grid-area: email;
`

export const TestDriveRequestClient: React.FC<Props> = ({ client, testId }) => (
  <Container data-testid={`${testId}container`}>
    <Name data-testid={`${testId}full-name`}><ClientFullName client={client} /></Name>
    <Types data-testid={`${testId}type`}><ClientType client={client} /></Types>
    <Phone data-testid={`${testId}phone`}><ClientPhone client={client} /></Phone>
    <Email data-testid={`${testId}email`}><ClientEmail client={client} /></Email>
  </Container>
)
