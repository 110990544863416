import { BtnType } from 'actff-bo-app/components/Button'
import {
  CheckboxInputField,
  Field,
  FormCell,
  FormFooter,
  FormFooterLeft,
  FormFooterRight,
  FormHeader,
  RadioButtonField,
  Textarea,
  YesNoInput,
} from 'actff-bo-app/components/Form'
import { QuickCampaignChannel } from 'actff-bo-lib/campaign'
import { CampaignAction } from 'actff-bo-lib/campaign/actions'
import { QuickCampaignDto } from 'actff-bo-lib/campaign/dto'
import { selectSmsCost } from 'actff-bo-lib/campaign/selectors'
import {
  DealerLocation,
  DealerLocationKey,
  selectDealerBrandsData,
  selectDealerLocationsData,
} from 'actff-bo-lib/dealership'
import { mapKeysToFormValues } from 'actff-bo-lib/form/map-values-to-keys'
import { State } from 'actff-bo-lib/store'
import { Form, FormikProps } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { H2, H3, Header, PrimaryButton } from 'styles'
import { FormValues } from '../../actff-bo-lib/form'
import { mapFormValuesToDto, QuickCampaignForm } from './Form'
import { LocationFilterRow } from './LocationFilterRow'
import { CostButton, CostInfo, DealershipFiltersContainer, DealershipFiltersHeader, TextareaStyled } from './Styled'

const testId = 'quick-campaign__'

type QuickCampaignParams = {
  readonly location: string,
  readonly phone: string,
}

export type DispatchToProps = {
  readonly getSmsCost: (values: QuickCampaignDto) => void,
  readonly sendQuickCampaign: (values: QuickCampaignDto) => void,
}

export type StateToProps = {
  readonly brands: ReadonlyArray<string> | null,
  readonly dealerLocations: ReadonlyArray<DealerLocation> | null,
  readonly smsCost: string | null,
}

export const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  brands: selectDealerBrandsData(state),
  dealerLocations: selectDealerLocationsData(state),
  smsCost: selectSmsCost(state),
})

export const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getSmsCost: values => dispatch(CampaignAction.getSmsCost(values)),
  sendQuickCampaign: values => dispatch(CampaignAction.sendQuickCampaign(values)),
})

export type Props = DispatchToProps & StateToProps & RouteComponentProps<QuickCampaignParams> & FormikProps<QuickCampaignForm>

export const QuickCampaignComponent: FC<Props> = ({
  brands,
  dealerLocations,
  dirty,
  errors,
  getSmsCost,
  isSubmitting,
  isValid,
  smsCost,
  setValues,
  touched,
  values,
}) => {
  const { t } = useTranslation()
  const isSmsChannel = values.channel === QuickCampaignChannel.SMS
  const isSubmitButtonDisabled = () => isSubmitting || !isValid || !dirty

  const handleGetSmsCost = () => {
    getSmsCost(mapFormValuesToDto(values))
  }

  const handleBrandsGroupSelectorChange = (brandName: string) => () => {
    const valueToSet = !values.brandsGroupSelector[brandName]

    const mapped = dealerLocations?.reduce((prev, curr) => ({
      ...prev,
      brandsGroupSelector: {
        ...values.brandsGroupSelector,
        [brandName]: valueToSet,
      },
      [curr.key]: {
        ...values[curr.key] as object,
        [brandName]: valueToSet,
      },
    }), {})

    if (!mapped) { return }

    setValues({
      ...values,
      ...mapped,
    })
  }

  const handleLocationsGroupSelectorChange = (locationKey: DealerLocationKey) => () => {
    const valueToSet = !values.locationsGroupSelector[locationKey]

    setValues({
      ...values,
      [locationKey]: mapKeysToFormValues(brands, valueToSet),
      locationsGroupSelector: {
        ...values.locationsGroupSelector,
        [locationKey]: valueToSet,
      },
    })
  }

  const toggleAll = () => {
    const toggleSelectorValues = (keys: FormValues) =>
      Object.keys(keys).reduce((prev, curr) => ({
        ...prev,
        [curr]: !values.chosenLocations,
      }), {})

    const mappedBrands = toggleSelectorValues(values.brandsGroupSelector)
    const mapped = dealerLocations?.reduce((prev, curr) => ({
      ...prev,
      [curr.key]: mappedBrands,
    }), {})

    setValues({
      ...values,
      ...mapped,
      brandsGroupSelector: mappedBrands,
      chosenLocations: !values.chosenLocations,
      locationsGroupSelector: toggleSelectorValues(values.locationsGroupSelector),
    })
  }

  return (
    <Form>
      <Header>
        <H2 data-testid={`${testId}header`}>{t('campaign.quickCampaign.header')}</H2>
        <H3>{t('campaign.quickCampaign.description')}</H3>
        <DealershipFiltersContainer>
          <DealershipFiltersHeader>
            <Field
              component={CheckboxInputField}
              disabled={!values}
              id='chosenLocations'
              label={t('menu.crm.quickNotifications.dealershipFilters.chosenLocations')}
              name='chosenLocations'
              onChange={toggleAll}
              testId={`${testId}chosenLocations`}
            />
            {brands?.map(brand => (
              <Field
                component={CheckboxInputField}
                disabled={!values}
                id={`brandsGroupSelector.${brand}`}
                key={`brandsGroupSelector.${brand}`}
                label={brand}
                name={`brandsGroupSelector.${brand}`}
                onChange={handleBrandsGroupSelectorChange(brand)}
                testId={`${testId}${brand}`}
              />
            ))}
          </DealershipFiltersHeader>
          {dealerLocations?.map(location => (
            <LocationFilterRow
              key={location.key}
              location={location}
              onLocationsGroupSelectorChange={handleLocationsGroupSelectorChange(location.key)}
            />
          ))}
        </DealershipFiltersContainer>
      </Header>
      <FormCell>
        <FormHeader>
          {t('campaign.quickCampaign.settings')}
        </FormHeader>
      </FormCell>
      <FormCell>
        <label>{t('campaign.quickCampaign.fields.channel.label')}</label>
        <div>
          <Field
            component={RadioButtonField}
            testId={`${testId}channel--sms`}
            htmlFor={QuickCampaignChannel.SMS}
            id={QuickCampaignChannel.SMS}
            error={errors.channel}
            inline={true}
            label={t('campaign.quickCampaign.fields.channel.sms')}
            touched={touched.channel}
            name='channel'
            value={QuickCampaignChannel.SMS}
          />
          <Field
            component={RadioButtonField}
            testId={`${testId}channel--push`}
            htmlFor={QuickCampaignChannel.PUSH}
            id={QuickCampaignChannel.PUSH}
            error={errors.channel}
            inline={true}
            label={t('campaign.quickCampaign.fields.channel.push')}
            touched={touched.channel}
            name='channel'
            value={QuickCampaignChannel.PUSH}
          />
          <Field
            component={RadioButtonField}
            testId={`${testId}channel--internal`}
            inline={true}
            error={errors.channel}
            htmlFor={QuickCampaignChannel.INTERNAL}
            id={QuickCampaignChannel.INTERNAL}
            label={t('campaign.quickCampaign.fields.channel.internal')}
            touched={touched.channel}
            name='channel'
            value={QuickCampaignChannel.INTERNAL}
          />
        </div>
      </FormCell>
      {isSmsChannel && (
        <>
          <FormCell>
            <YesNoInput label={t('campaign.quickCampaign.fields.target')} name='chosenUsers' testId={`${testId}target--chosenUsers`} />
          </FormCell>
          {!values.chosenUsers && (
            <>
              <FormCell>
                <TextareaStyled
                  component={Textarea}
                  data-testid={`${testId}target-phones`}
                  touched={touched.phones}
                  name='phones'
                  placeholder={t('campaign.quickCampaign.fields.phones.placeholder')}
                  value={values.phones}
                />
              </FormCell>
              <FormCell>
                <label>{t('campaign.quickCampaign.SmsCost')}</label>
                <CostInfo>
                  <CostButton
                    disabled={!isValid}
                    type={BtnType.Button}
                    onClick={handleGetSmsCost}
                  >
                    {t('campaign.quickCampaign.getSmsCost')}
                  </CostButton>
                  <p>{t('caption.price')}: {smsCost || '_'} {t('currency.pln')}</p>
                </CostInfo>
              </FormCell>
            </>
          )}
        </>
      )}
      <FormCell>
        <label>{t('campaign.quickCampaign.fields.message.label')}</label>
        <TextareaStyled
          component={Textarea}
          data-testid={`${testId}textarea`}
          touched={touched.message}
          error={errors.message}
          name='message'
          placeholder={t('campaign.quickCampaign.fields.message.placeholder')}
          value={values.message}
        />
      </FormCell>
      <FormFooter>
        <FormFooterLeft />
        <FormFooterRight>
          <PrimaryButton
            data-testid={`${testId}button-submit`}
            disabled={isSubmitButtonDisabled()}
            type={BtnType.Submit}
          >
            {t('caption.send')}
          </PrimaryButton>
        </FormFooterRight>
      </FormFooter>
    </Form>
  )
}
