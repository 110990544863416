import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { from, of } from 'rxjs'

import { Language, Resource, translationsAndLanguageToResource } from './dto'

export const getTranslations = (language: Language) =>
  of(translationsAndLanguageToResource(language, require(`./${language}.json`)))

export const initI18n = (lng: Language, resources: Resource) =>
  from(
    i18next
    .use(initReactI18next)
    .init({
      debug: true, // TODO: Set it according to env
      keySeparator: '|',
      lng,
      resources,
    }),
  )
