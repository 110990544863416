import { ToastAction, ToastBodyComponentType, ToastType } from 'actff-bo-lib/toast'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'

import { CampaignAction, CampaignActionType } from './actions'
import { getSmsCost, sendQuickCampaign } from './dao'
import { CreateQuickCampaignResponseDto } from './dto'

const sendQuickCampaignEpic: Epic<CampaignAction | ToastAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CampaignAction.sendQuickCampaign>>(CampaignActionType.SendQuickCampaign),
  switchMap(({ payload }) => sendQuickCampaign(payload).pipe(
    map((response: CreateQuickCampaignResponseDto) => ToastAction.displayToast({
        body: ToastBodyComponentType.QuickCampaignSuccess,
        bodyProps: { params: { count: response.clientsNotified, content: payload.message }},
        id: 'campaign.quickCampaign.sent',
        title: 'campaign.quickCampaign.sent',
        type: ToastType.Success,
    })),
    catchError((error: AjaxError) => of(CampaignAction.sendQuickCampaignFailure(error)),
  )),
))

const getSmsCostEpic: Epic<CampaignAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CampaignAction.getSmsCost>>(CampaignActionType.GetSmsCost),
  switchMap(({ payload }) => getSmsCost(payload).pipe(
    map(CampaignAction.getSmsCostSuccess),
    catchError((error: AjaxError) => of(CampaignAction.getSmsCostFailure(error)),
  )),
))

export const campaignEpic = combineEpics(
  getSmsCostEpic,
  sendQuickCampaignEpic,
)
