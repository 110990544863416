import { Badge } from 'actff-bo-app/components/Badge'
import { ClientEmail, ClientFullName, ClientPhone, ClientType } from 'actff-bo-app/components/Client'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { Client, isClientArchived } from 'actff-bo-lib/client'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Td as TdRegular, Tr } from 'styles'

type ClientRowProps = Testable & {
  readonly client: Client,
}

const TdApprovals = styled(TdRegular)`
  max-width: 130px;
`

const Approval = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
`

export const ClientRowComponent: React.FC<ClientRowProps & WithTranslation> = ({ client, t, testId }) => {
  const route = createRoute(Paths.ClientViewBasicData, { clientId: client.uuid })
  const Td = TdDecorator(route)

  return (
    <Tr
      clickable={true}
      shadowed={isClientArchived(client)}
    >
      <Td testId={`${testId}--creation-delete-date`}>
        <FormattedDate date={isClientArchived(client) ? client.deletionDate : client.registrationDate} format={defaultDateFormat}/>
      </Td>
      <Td testId={`${testId}--status`}>{t(`clientList.columns.status.${client.status}`)}</Td>
      <Td testId={`${testId}--full-name`}><ClientFullName client={client} /></Td>
      <Td testId={`${testId}--type`}><ClientType client={client} /></Td>
      <Td testId={`${testId}--phone`}><ClientPhone client={client} /></Td>
      <Td testId={`${testId}--email`}><ClientEmail client={client} /></Td>
      <Td testId={`${testId}--cars-count`}>{client.carsCount}</Td>
      <TdApprovals>
        {Object.keys(client.approvalCounts).map(key => (
          <Approval key={key} dataTestId={`${testId}--approval-${key}`}>
            <>{t(`clientView.${key}`)}</>
            <Badge>{client.approvalCounts[key]}</Badge>
          </Approval>
        ))
      }</TdApprovals>
    </Tr>
  )
}

export const ClientRow = withTranslation()(ClientRowComponent)
