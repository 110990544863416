/* tslint:disable: no-object-mutation disable for redux-toolkit */
import { createSlice } from '@reduxjs/toolkit'
import { Loadable } from 'actff-bo-lib/global'
import { ApplicationLinks } from './dto'

export type DealerLocationsState = {
  readonly applicationLinks: Loadable<ApplicationLinks | null>,
  readonly locationBrands: Loadable<ReadonlyArray<string> | null>,
}

const initialState: DealerLocationsState = {
  applicationLinks: {
    data: null,
    loading: false,
  },
  locationBrands: {
    data: null,
    loading: false,
  },
}

export const dealerLocationsSlice = createSlice({
  initialState,
  name: 'adminDealerLocations',
  reducers: {
    getApplicationLinksAction: state => {
      state.applicationLinks.loading = true
    },
    getApplicationLinksActionSuccess: (state, action) => {
      state.applicationLinks.data = action.payload
      state.applicationLinks.loading = false
    },
    getLocationBrandsActionSuccess: (state, action) => {
      state.locationBrands.data = action.payload
      state.locationBrands.loading = false
    },
    updateApplicationLinksActionSuccess: (state, action) => {
      state.applicationLinks.data = action.payload
    },
  },
})

export default dealerLocationsSlice.reducer
