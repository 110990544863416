import { BtnType } from 'actff-bo-app/components/Button'
import { DropdownContent } from 'actff-bo-app/components/DropdownContent'
import { ExpandCollapse } from 'actff-bo-app/components/ExpandCollapse'
import { OpportunityStatusButton, OpportunityStatusContainer } from 'actff-bo-app/Crm/Styled'
import { changeableStatuses, OpportunityStatus as OpportunityStatusType, OpportunityStatusNameMap } from 'actff-bo-lib/crm/service'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly currentStatus: OpportunityStatusType,
  readonly expanded: boolean,
  readonly onChange: (status: OpportunityStatusType) => () => void,
  readonly onClose: () => void,
}

export const OpportunityStatus: FC<Props> = ({ currentStatus, expanded, onChange, onClose }) => {
  const { t } = useTranslation()
  const statusDropdownTopOffset = 60

  return (
    <OpportunityStatusContainer>
      <label>{t('opportunity.status')}:</label>
      <OpportunityStatusButton onClick={onClose} type={BtnType.Button}>
        {t(OpportunityStatusNameMap.get(currentStatus, ''))}
        <ExpandCollapse expanded={expanded} />
      </OpportunityStatusButton>
      {expanded && <DropdownContent topOffset={statusDropdownTopOffset}>
        <ul>
          {changeableStatuses
            .map(([statusType, statusName]) => (
              <li key={statusType} onClick={onChange(statusType)}>{t(statusName)}</li>
          ))}
        </ul>
      </DropdownContent>}
    </OpportunityStatusContainer>
  )
}
