import { Icon } from 'actff-bo-app/components/Icon'
import { Attachment, AttachmentType } from 'actff-bo-lib/global'
import { ImageDefaultCompressOptions } from 'actff-bo-lib/image'
import { IconType } from 'actff-bo-lib/menu'
import React, { ChangeEvent, ComponentType, FC } from 'react'
import Resizer from 'react-image-file-resizer'
import styled from 'styled-components'

type Props = {
  readonly acceptTypes: string,
  readonly onChange: (url: ReadonlyArray<Attachment>) => void,
  readonly className?: string,
  readonly component?: ComponentType<UploadComponentProps>,
  readonly multiple?: boolean,
}

export type UploadComponentProps = {
  readonly onChange: (event: ChangeEvent<HTMLInputElement>) => void,
} & Pick<Props, 'acceptTypes' | 'className' | 'multiple'>

const UploadFileContainer = styled.div`
  position: relative;
`

const UploadFileIcon = styled(Icon)`
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
`

const UploadFileButton = styled.input`
  bottom: 5px;
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 15px;
`

const getAttachmentTypeFromBlobType = (type: string): AttachmentType => {
  switch (type.split('/')[0]) {
    case 'image':
      return AttachmentType.Image
    case 'pdf':
      return AttachmentType.PDF
    default:
      return AttachmentType.File
  }
}

const DefaultUploadComponent: FC<UploadComponentProps> = ({
  acceptTypes,
  className,
  onChange,
  multiple,
}) => (
  <div className={className}>
    <UploadFileContainer>
      <UploadFileIcon type={IconType.Attachment} />
      <UploadFileButton
        accept={acceptTypes}
        onChange={onChange}
        multiple={multiple}
        type='file'
      />
    </UploadFileContainer>
  </div>
)

const resizeFile = (file: File) =>
  new Promise(resolve => {
    if (file.type.includes('image')) {
      Resizer.imageFileResizer(
        file,
        ImageDefaultCompressOptions.width,
        ImageDefaultCompressOptions.height,
        'JPEG',
        ImageDefaultCompressOptions.quality,
        0,
        (uri: File) => { resolve(uri) },
        'blob',
      )
    } else {
      resolve(file)
    }
  })

export const UploadFile: FC<Props> = ({
  acceptTypes,
  className,
  component: Component = DefaultUploadComponent,
  onChange,
  multiple = false,
}) => {
  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.from(target.files || [])

    Promise.all(fileList.map(resizeFile))
      .then(result => {
        // Ugly hack to reset input in the case I will use the same photo again after uploading
        target.value = ''  // tslint:disable-line no-object-mutation

        onChange(result.map((file: File) => ({
          file,
          type: getAttachmentTypeFromBlobType(file.type),
        })))
      })
  }

  return <Component acceptTypes={acceptTypes} className={className} onChange={handleOnChange} multiple={multiple} />
}
