import { axiosApi } from 'actff-bo-lib/api'
import { ApprovalsContent } from 'actff-bo-lib/approvals-content/dto'
import { AgreementUpdate, DealershipInsurer, InsurerQuestion, InsurerQuestions } from './dto'

export const getDealershipInsurers = () => axiosApi.get<ReadonlyArray<DealershipInsurer>>('/admin/dealership/insurers')

export const addDealershipInsurer = (insurer: DealershipInsurer) => axiosApi.post('/admin/dealership/insurers', insurer)

export const deleteDealershipInsurer = (insurer: string) => axiosApi.delete(`/admin/dealership/insurers/${insurer}`)

export const getDealershipInsurersQuestions = () => axiosApi.get<InsurerQuestions>('/offer-request/renew')

export const updateDealershipInsurerQuestions = (questions: ReadonlyArray<InsurerQuestion>) =>
  axiosApi.put('/offer-request/renew', { questions })

export const updateDealershipInsurers = (insurers: ReadonlyArray<DealershipInsurer>) =>
  axiosApi.put('/admin/dealership/insurers', insurers)

export const getRegulationsAndApprovals = () =>
  axiosApi.get<{ readonly contactApprovals: ApprovalsContent }>('/contact-approvals')

export const updateRegulationsAndApprovals = (payload: ReadonlyArray<AgreementUpdate>) => axiosApi.patch('/contact-approvals', payload)
