import { Attachment } from 'actff-bo-app/components/Attachment'
import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormCell, FormHeader } from 'actff-bo-app/components/Form'
import { PreviewFiles, UploadComponentProps, UploadFile } from 'actff-bo-app/components/UploadFile'
import { InspectionPhotoAcceptTypes } from 'actff-bo-lib/car'
import { Attachment as AttachmentType, Url } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu'
import React, { CSSProperties, FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = {
  readonly control: Control,
  readonly formFieldName: string,
  readonly maxHeight?: number,
  readonly noPadding?: boolean,
  readonly setValue: any, // tslint:disable-line no-any
  readonly headerStyles?: CSSProperties,
  readonly photoUrls?: ReadonlyArray<Url>,
  readonly withBackground?: boolean,
}

type ContainerProps = {
  readonly maxHeight?: number,
  readonly withBackground: boolean,
  readonly noPadding?: boolean,
}

const Container = styled(FormCell)<ContainerProps>`
  background: ${({ withBackground }: ContainerProps) => withBackground ? colors.athensGray : 'none'};
  margin: 24px 0;
  ${({ maxHeight }: ContainerProps) => maxHeight && `max-height: ${maxHeight}px`};
  overflow-y: auto;
  ${({ noPadding }: ContainerProps) => noPadding && 'padding: 0'};
`

const PhotoContainer = styled.div`
  display: inline-block;
  margin-top: 24px;

  > * {
    margin-right: 10px;
  }
`

const PhotosUploadContainer = styled.div`
  height: 40px;
  position: relative;
`

const PhotosUpload = styled.div`
  cursor: pointer;
  pointer-events: none;
  position: absolute;
`

const PhotosUploadNativeButton = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
`

const thumbnailSize = 160

const PhotosUploadButton: FC<UploadComponentProps> = ({ acceptTypes, onChange, multiple }) => {
  const { t } = useTranslation()

  return (
    <PhotosUploadContainer>
      <PhotosUpload>
        <ButtonWithIcon caption={t('caption.addAttachments')} iconType={IconType.Attachment} noPadding={true} theme={BtnTheme.Link}/>
      </PhotosUpload>
      <PhotosUploadNativeButton
        accept={acceptTypes}
        onChange={onChange}
        multiple={multiple}
        type='file'
      />
    </PhotosUploadContainer>
  )
}

export const Photos: React.FC<Props> = ({
  control,
  formFieldName,
  maxHeight,
  noPadding,
  photoUrls = [],
  setValue,
  headerStyles,
  withBackground,
}) => {
  // tslint:disable-next-line no-any
  const watchedAttachments = useWatch<any>({ control, name: formFieldName })
  const { t } = useTranslation()

  const handleDeleteAttachment = (file: AttachmentType) => () => {
    setValue(formFieldName, [
      ...watchedAttachments.filter((attachment: AttachmentType) => attachment.file !== file.file),
    ])
  }

  return (
    <Container withBackground={withBackground} maxHeight={maxHeight} noPadding={noPadding}>
      <FormHeader styles={headerStyles}>
        {t('carView.inspection.form.photos.header')}&nbsp;({photoUrls?.length || 0})
      </FormHeader>
      <PhotoContainer>
        {photoUrls?.length > 0 && (
          photoUrls.map(photoUrl => <Attachment key={photoUrl} thumbnailSize={thumbnailSize} url={photoUrl} />)
        )}
      </PhotoContainer>
      <PreviewFiles files={watchedAttachments} onDeleteFile={handleDeleteAttachment} />
      <Controller
        control={control}
        name={formFieldName}
        render={({ onChange, value: currentAttachments }) => (
          <UploadFile
            acceptTypes={InspectionPhotoAcceptTypes.join(', ')}
            component={PhotosUploadButton}
            onChange={attachments => onChange([...(currentAttachments || []), ...attachments])}
            multiple={true}
          />
        )}
      />
    </Container>
  )
}
