import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { selectDealerBrands, selectDealerLocations } from 'actff-bo-lib/dealership'
import { mapToOptions } from 'actff-bo-lib/global'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { deleteUser, getUsersWithAxios, updateUsersWithAxios, UserRole } from 'actff-bo-lib/user'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { withAdminViewHeader } from '../../withAdminViewHeader'
import { ViewTabs } from '../ViewTabs'
import { UsersTable } from './UsersTable'

const testId = 'admin-view-dealership--users__'

const UsersComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data: dealerBrands } = useSelector(selectDealerBrands)
  const { data: dealerLocations } = useSelector(selectDealerLocations)

  const { data: users, isLoading, isError, refetch } = useQuery([QueryKeys.GET_USERS, QueryKeys.ADD_NEW_USER], async () =>
      await getUsersWithAxios(),
    { retry: false, refetchOnWindowFocus: false },
  )
  const getUsersWithFilteredLocations = () => users?.map(user => ({
    ...user,
    locations: user.locations.filter(location => location.available),
  })) || []

  const handleAddNewUser = () => { refetch() }

  const updateUsersMutation = useMutation(
    QueryKeys.UPDATE_USERS,
    updateUsersWithAxios,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.users.updateUsers.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        dispatch(displaySuccessToast('admin.users.updateUsers.success', ToastActionType.CREATE_OR_UPDATE))
        refetch()

      },
    },
  )

  const deleteUserMutation = useMutation(
    QueryKeys.DELETE_USER,
    deleteUser,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.users.deleteUser.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        dispatch(displaySuccessToast('admin.users.deleteUser.error', ToastActionType.CREATE_OR_UPDATE))
        refetch()
      },
    },
  )

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <LocalLoader />
      </>
    )
  }

  if (!users) {
    return (
      <>
        <ViewTabs testId={testId} />
        <NoData>{t('caption.noData')}</NoData>
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('api.action.get.failure', ToastActionType.GET))
  }

  const rolesSelectOptions = mapToOptions(Object.keys(UserRole), 'user.roles', t)
  const locationSelectOptions = dealerLocations?.map(location => ({ label: location.name, value: location.key })) ?? []
  const brandSelectOptions = mapToOptions(dealerBrands)

  return (
    <>
      <ViewTabs testId={testId} />
      <UsersTable
        onAddNewUser={handleAddNewUser}
        users={getUsersWithFilteredLocations()}
        brandSelectOptions={brandSelectOptions}
        locationSelectOptions={locationSelectOptions}
        updateUsers={updateUsersMutation.mutate}
        deleteUser={deleteUserMutation.mutate}
        rolesSelectOptions={rolesSelectOptions}
      />
    </>
  )
}

export const Users = withAdminViewHeader(UsersComponent)
