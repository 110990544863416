import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly description: string,
}

const Description = styled.div`
  display: grid;
  grid-area: description;
  padding: 24px 32px;
`

export const OppoetunityDescription: FC<Props> = ({ description }) => {
  const { t } = useTranslation()

  return (
    <Description>
      <label htmlFor='desc'>{t('opportunity.description.label')}:</label>
      <p>{description || t('caption.noDescription')}</p>
    </Description>
  )
}
