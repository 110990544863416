import { IconType } from 'actff-bo-lib/menu/dto'
import { CssPosition } from 'actff-bo-lib/style'
import * as React from 'react'
import { colors } from 'styles'

import { Icon } from './Icon'
import { Overlay } from './Overlay'

type Props = {
  readonly height?: string,
  readonly opacity?: string,
  readonly position?: CssPosition,
}

const loaderIndex = 2

export const Loader: React.FC<Props> = ({ opacity = '0.85' }) => (
  <Overlay opacity={opacity} color={colors.white} zIndex={loaderIndex}>
    <Icon type={IconType.Loader} />
  </Overlay>
)

export const LocalLoader: React.FC<Props> = ({ height = '500px', opacity = '0.85', position = CssPosition.Relative }) => (
  <Overlay opacity={opacity} color={colors.white} position={position} height={height} zIndex={loaderIndex}>
    <Icon type={IconType.Loader} />
  </Overlay>
)
