import { Tabs } from 'actff-bo-app/Navigation'
import { Testable } from 'actff-bo-lib/global/testable'
import { Path, Paths, TabMenuItem } from 'actff-bo-lib/menu'
import { ServiceRequest } from 'actff-bo-lib/service-request'
import { UserPermissions } from 'actff-bo-lib/user'
import i18next from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = Testable & {
  readonly serviceRequest: ServiceRequest,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

const getTabItems = (
  serviceRequest: ServiceRequest,
  userPermissions: ReadonlyArray<UserPermissions>,
  t: i18next.TFunction,
  testId?: string,
): ReadonlyArray<TabMenuItem> => ([
  {
    name: t('serviceRequest.tabs.data'),
    parameters: { serviceRequestId: serviceRequest.uuid },
    path: Paths.ServiceRequestsView as Path,
    testId: `${testId}data`,
  },
])

export const ServiceRequestViewTabs: React.FC<Props> = ({ serviceRequest, testId, userPermissions }) => {
  const { t } = useTranslation()

  return <Tabs tabItems={getTabItems(serviceRequest, userPermissions, t, testId)} />
}
