import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { AjaxError } from 'rxjs/ajax'

import { CarDataExistencePayload, CarFilterValues, CarId, CarInfoDto, CarInfoWithClient, NewCarsCount } from '../dto'

export enum CarActionType {
  InitDealerLocations = '[CAR] INIT DEALER LOCATIONS',
  ChangeDealerLocationFilters = '[CAR] CHANGE DEALER LOCATION FILTERS',

  ChangeBrandFilters = '[CAR] CHANGE BRAND FILTERS',

  ChangeListFilter = '[CAR] CHANGE LIST FILTER',
  ChangePage = '[CAR] CHANGE PAGE',
  ChangeSearchPhrase = '[CAR] CHANGE SEARCH PHRASE',

  CheckVinExistence = '[CAR] CHECK VIN EXISTENCE',
  CheckVinExistenceFailure = '[CAR] CHECK VIN EXISTENCE FAILURE',
  CheckVinExistenceSuccess = '[CAR] CHECK VIN EXISTENCE SUCCESS',

  CheckRegistrationNumberExistence = '[CAR] CHECK REGISTRATION NUMBER EXISTENCE',
  CheckRegistrationNumberExistenceFailure = '[CAR] CHECK REGISTRATION NUMBER EXISTENCE FAILURE',
  CheckRegistrationNumberExistenceSuccess = '[CAR] CHECK REGISTRATION NUMBER EXISTENCE SUCCESS',

  ClearCurrentCar = '[CAR] CLEAR CURRENT CAR',

  GetCar = '[CAR] GET CAR',
  GetCarFailure = '[CAR] GET CAR FAILURE',
  GetCarSuccess = '[CAR] GET CAR SUCCESS',

  GetNdcCar = '[CAR] GET NDC CAR',
  GetNdcCarFailure = '[CAR] GET NDC CAR FAILURE',
  GetNdcCarSuccess = '[CAR] GET NDC CAR SUCCESS',

  DeleteCar = '[CAR] DELETE CAR',
  DeleteCarFailure = '[CAR] DELETE CAR FAILURE',
  DeleteCarSuccess = '[CAR] DELETE CAR SUCCESS',

  GetCarAttachmentsSuccess = '[CAR] GET CAR ATTACHMENTS SUCCESS',
  GetCarAttachmentsFailure = '[CAR] GET CAR ATTACHMENTS FAILURE',

  GetCars = '[CAR] GET CARS',
  GetCarsFailure = '[CAR] GET CARS FAILURE',
  GetCarsSuccess = '[CAR] GET CARS SUCCESS',

  GetNewCarsCount = '[CAR] GET NEW CARS COUNT',
  GetNewCarsCountFailure = '[CAR] GET NEW CARS COUNT FAILURE',
  GetNewCarsCountSuccess = '[CAR] GET NEW CARS COUNT SUCCESS',

  RejectCar = '[CAR] REJECT CAR',
  RejectCarFailure = '[CAR] REJECT CAR FAILURE',
  RejectCarSuccess = '[CAR] REJECT CAR SUCCESS',

  RevertCar = '[CAR] REVERT CAR',
  RevertCarFailure = '[CAR] REVERT CAR FAILURE',
  RevertCarSuccess = '[CAR] REVERT CAR SUCCESS',

  SetIsNdcCar = '[CAR] SET IS NDC CAR',

  UpdateCar = '[CAR] UPDATE CAR',
  UpdateCarFailure = '[CAR] UPDATE CAR FAILURE',
  UpdateCarSuccess = '[CAR] UPDATE CAR SUCCESS',
}

// tslint:disable object-literal-sort-keys
export const CarAction = {
  changeDealerLocationFilters: (dealerLocations: ReadonlyArray<DealerLocationKey>) =>
    createAction(CarActionType.ChangeDealerLocationFilters, dealerLocations),
  changeCarBrandFilters: (carBrands: ReadonlyArray<string>) => createAction(CarActionType.ChangeBrandFilters, carBrands),

  changeListFilter: (param: string, value: CarFilterValues) => createAction(CarActionType.ChangeListFilter, { param, value }),
  changePage: (page: number) => createAction(CarActionType.ChangePage, page),
  changeSearchPhrase: (searchPhrase: string) => createAction(CarActionType.ChangeSearchPhrase, searchPhrase),

  checkVinExistence: (carId: CarId | null, vin: string) => createAction(CarActionType.CheckVinExistence, { uuid: carId, vin }),
  checkVinExistenceFailure: (error: AjaxError) => createAction(CarActionType.CheckVinExistenceFailure, error),
  checkVinExistenceSuccess: (payload: CarDataExistencePayload) => createAction(CarActionType.CheckVinExistenceSuccess, payload),

  checkRegistrationNumberExistence: (carId: CarId | null, registrationNumber: string) =>
    createAction(CarActionType.CheckRegistrationNumberExistence, { uuid: carId, registrationNumber }),
  checkRegistrationNumberExistenceFailure: (error: AjaxError) =>
    createAction(CarActionType.CheckRegistrationNumberExistenceFailure, error),
  checkRegistrationNumberExistenceSuccess: (payload: CarDataExistencePayload) =>
    createAction(CarActionType.CheckRegistrationNumberExistenceSuccess, payload),

  clearCurrentCar: () => createAction(CarActionType.ClearCurrentCar),

  deleteCar: (carId: CarId) => createAction(CarActionType.DeleteCar, carId),
  deleteCarFailure: (error: AjaxError) => createAction(CarActionType.DeleteCarFailure, error),
  deleteCarSuccess: (carId: CarId) => createAction(CarActionType.DeleteCarSuccess, carId),

  getCar: (carId: CarId) => createAction(CarActionType.GetCar, carId),
  getCarFailure: (error: AjaxError) => createAction(CarActionType.GetCarFailure, error),
  getCarSuccess: (car: CarInfoWithClient) => createAction(CarActionType.GetCarSuccess, car),

  getNdcCar: (vin: string) => createAction(CarActionType.GetNdcCar, vin),
  getNdcCarFailure: (error: AjaxError) => createAction(CarActionType.GetNdcCarFailure, error),
  getNdcCarSuccess: (car: CarInfoWithClient) => createAction(CarActionType.GetNdcCarSuccess, car),

  getCarAttachmentsFailure: (error: AjaxError) => createAction(CarActionType.GetCarAttachmentsFailure, error),
  getCarAttachmentsSuccess: (carAttachments: ReadonlyArray<string>) => createAction(CarActionType.GetCarAttachmentsSuccess, carAttachments),

  getCars: () => createAction(CarActionType.GetCars),
  getCarsFailure: (error: AjaxError) => createAction(CarActionType.GetCarsFailure, error),
  getCarsSuccess: (carList: PagedResult<CarInfoWithClient>) => createAction(CarActionType.GetCarsSuccess, carList),

  getNewCarsCount: () => createAction(CarActionType.GetNewCarsCount),
  getNewCarsCountFailure: (error: AjaxError) => createAction(CarActionType.GetNewCarsCountFailure, error),
  getNewCarsCountSuccess: (count: NewCarsCount) => createAction(CarActionType.GetNewCarsCountSuccess, count),

  rejectCar: (carId: CarId) => createAction(CarActionType.RejectCar, carId),
  rejectCarFailure: (error: AjaxError) => createAction(CarActionType.RejectCarFailure, error),
  rejectCarSuccess: (payload: CarInfoWithClient) => createAction(CarActionType.RejectCarSuccess, payload),

  setIsNdcCar: (value: boolean) => createAction(CarActionType.SetIsNdcCar, value),

  revertCar: (carId: CarId) => createAction(CarActionType.RevertCar, carId),
  revertCarFailure: (error: AjaxError) => createAction(CarActionType.RevertCarFailure, error),
  revertCarSuccess: (payload: CarInfoWithClient) => createAction(CarActionType.RevertCarSuccess, payload),

  updateCar: (payload: CarInfoDto) => createAction(CarActionType.UpdateCar, payload),
  updateCarFailure: (error: AjaxError) => createAction(CarActionType.UpdateCarFailure, error),
  updateCarSuccess: (payload: CarInfoWithClient) => createAction(CarActionType.UpdateCarSuccess, payload),
}

export type CarAction = ActionsUnion<typeof CarAction>
