import { withImageColumn } from 'actff-bo-app/components/withImageColumn'
import { withNavigation } from 'actff-bo-app/Navigation/main'
import { withTopBar } from 'actff-bo-app/TopBar'
import { WrapperType } from 'actff-bo-lib/menu/dto/wrapper'
import * as React from 'react'

export type Wrapper = (props?: any) => (component: React.ComponentType) => React.ComponentType // tslint:disable-line

export const WrapperMap: Map<WrapperType, Wrapper> = new Map([
  [WrapperType.WithImageColumn, withImageColumn],
  [WrapperType.WithTopBar, withTopBar],
  [WrapperType.WithNavigation, withNavigation],
])

export const NullWrapper: Wrapper = () => () => class extends React.Component {
  public render(): React.ReactNode {
    return null
  }
}

export const wrapperTypeToWrapper = (type: WrapperType): Wrapper => WrapperMap.get(type) || NullWrapper
