import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import {
  FormCell,
  getDateValue,
  handleBoolCheck,
  RadioBoxes,
  renderSelectController,
} from 'actff-bo-app/components/Form'
import { SearchCarField } from 'actff-bo-app/components/SearchCarField/SearchCarField'
import { SearchCarResult } from 'actff-bo-lib/car'
import { InsuranceCarKind, InternalCarType } from 'actff-bo-lib/crm/insurance'
import { getCarModelsAction, selectCarModels, selectDealerBrands } from 'actff-bo-lib/dealership'
import { mapValuesToSelectOptions, SelectOption } from 'actff-bo-lib/global'
import { mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'

type Props = Testable
  & SectionFormType
  & Pick<UseFormMethods, 'control' | 'register' | 'reset' | 'setValue' | 'watch'>

export const SectionCar: React.FC<Props> = ({ control, errors, expanded, register, reset, setValue, testId, watch, readOnly }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: brands } = useSelector(selectDealerBrands)
  const { data: models } = useSelector(selectCarModels)

  const selectedBrand = watch('car.brand')

  React.useEffect(() => {
    if (selectedBrand) {
      dispatch(getCarModelsAction([selectedBrand]))
    }
  }, [selectedBrand])

  const carKinds = mapToOptions(Object.keys(InsuranceCarKind), 'crmInsurance.carKind', t)
  const inHouseTypes = mapToOptions(Object.keys(InternalCarType), 'crmInsurance.inHouseType', t)
  const isInHouseCar = watch('car.inHouse')

  const handleValueChange =
    (field: string, value: SelectOption<string>, action: string, carSearchResults?: ReadonlyArray<SearchCarResult>) => {

    setValue(`car.${field}`, value?.value, { shouldDirty: true })

    if (value) {
      const selectedCarFromSearchResults = carSearchResults?.find(car => car[field] === value.value)

      if (action === 'select-option' && selectedCarFromSearchResults) {
        setValue('car', selectedCarFromSearchResults)
        setValue('car.brand', selectedCarFromSearchResults.brand)
        setValue('car.firstRegistrationDate', getDateValue(selectedCarFromSearchResults.firstRegistrationDate))

        setValue('contactPerson', selectedCarFromSearchResults.client)

        setValue('driver', selectedCarFromSearchResults.driver)
        setValue('driver', selectedCarFromSearchResults.driver?.address)
        setValue('driver', selectedCarFromSearchResults.driver?.contactInfo)

        setValue('owner', selectedCarFromSearchResults.owner)
        setValue('owner', selectedCarFromSearchResults.owner?.address)
        setValue('owner', selectedCarFromSearchResults.owner?.contactInfo)
      }
    }
  }

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.car'), !!errors.car)}>
      <SectionForm>
        <FormCell>
          <label>{t('crmInsurance.car.vin')}:</label>
          <SearchCarField
            control={control}
            name='vin'
            register={register}
            reset={reset}
            setValue={setValue}
            disabled={readOnly}
            onValueChange={handleValueChange}
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.car.registrationNumber')}:</label>
          <SearchCarField
            control={control}
            name='registrationNumber'
            register={register}
            reset={reset}
            setValue={setValue}
            disabled={readOnly}
            onValueChange={handleValueChange}
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.car.carKind')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...carKinds] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-carKind`,
              transKey: 'crmInsurance.carKind',
            })}
            control={control}
            name='car.carKind'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.car.brand')}:</label>
          <Controller
            render={renderSelectController({
              creatable: true,
              disabled: readOnly,
              options: [...mapValuesToSelectOptions(brands)] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-brand`,
            })}
            control={control}
            name='car.brand'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.car.model')}:</label>
          <Controller
            render={renderSelectController({
              creatable: true,
              disabled: readOnly,
              options: [...mapValuesToSelectOptions(models)] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-carModel`,
            })}
            control={control}
            name='car.model'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.car.modelVersion')}:</label>
          <input type='text' name='car.modelVersion' ref={register} disabled={readOnly} />
        </FormCell>
        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.car.productionYear')}:</label>
          <input type='text' name='car.productionYear' ref={register} disabled={readOnly} />
        </FormCell>
        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.car.firstRegistrationDate')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                date={getDateValue(value)}
                onChange={onChange}
                testId={`${testId}car-firstRegistrationDate`}
                timeDisabled={true}
                disabled={readOnly}
              />
            )}
            control={control}
            name='car.firstRegistrationDate'
          />
        </FormCell>

        <FormCell>
          <label>{t('crmInsurance.car.inHouse')}:</label>
          <Controller
            render={({ value, onChange}) => (
              <RadioBoxes>
                <div>
                  <input
                    data-testid={`${testId}car-inHouse-yes`}
                    id='inHouse-yes'
                    type='radio'
                    name='car.inHouse'
                    defaultChecked={!!value}
                    onClick={handleBoolCheck(true, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='inHouse-yes'>{t('caption.yes')}</label>
                </div>
                <div>
                  <input
                    data-testid={`${testId}car-inHouse-no`}
                    id='inHouse-no'
                    type='radio'
                    name='car.inHouse'
                    defaultChecked={!value}
                    onClick={handleBoolCheck(false, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='inHouse-no'>{t('caption.no')}</label>
                </div>
              </RadioBoxes>
            )}
            control={control}
            name='car.inHouse'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.car.inHouseType')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly || !isInHouseCar,
              options: [...inHouseTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-inHouseType`,
              transKey: 'crmInsurance.inHouseType',
            })}
            control={control}
            name='car.inHouseType'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.car.imported')}:</label>
          <Controller
            render={({ value, onChange}) => (
              <RadioBoxes>
                <div>
                  <input
                    data-testid={`${testId}car-imported-yes`}
                    id='imported-yes'
                    type='radio'
                    name='car.imported'
                    defaultChecked={!!value}
                    onClick={handleBoolCheck(true, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='imported-yes'>{t('caption.yes')}</label>
                </div>
                <div>
                  <input
                    data-testid={`${testId}car-imported-no`}
                    id='imported-no'
                    type='radio'
                    name='car.imported'
                    defaultChecked={!value}
                    onClick={handleBoolCheck(false, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='imported-no'>{t('caption.no')}</label>
                </div>
              </RadioBoxes>
            )}
            control={control}
            name='car.imported'
          />
        </FormCell>
      </SectionForm>
    </CollapsableSection>
  )
}
