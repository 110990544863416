import { ReportType, selectReport } from 'actff-bo-lib/crm/insurance'
import { translateReport } from 'actff-bo-lib/crm/insurance/utils'
import * as React from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MinorButton } from 'styles'

import { getTranslatedReportColumns } from './Report/InsuranceReportConfig'

type Props = {
  readonly type: ReportType,
}

export const DownloadReport: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation()
  const report = useSelector(selectReport)
  const reportData = (report.data || []).slice(1)
  const columns = getTranslatedReportColumns(type, t)

  const data = translateReport<ReadonlyArray<object>>(
    reportData,
    columns.map(({ name }) => name),
    'crmInsurance',
    t,
  )

  const extension = '.csv'
  const filename = `${new Date().toLocaleString()}${extension}`

  return (
    <CSVLink
      uFEFF={true}
      data={data}
      filename={filename}
      separator={';'}
    >
      <MinorButton disabled={!report.data}>
        {t('caption.download')}
      </MinorButton>
    </CSVLink>
  )
}
