import { DropdownContent } from 'actff-bo-app/components/DropdownContent'
import { Icon } from 'actff-bo-app/components/Icon'
import { Form } from 'formik'
import styled from 'styled-components'
import { theme } from 'styles'

export const ChatNewThreadContainer = styled.div`
  padding: 20px 24px;
  position: relative;
`

export const FieldContainer = styled.div`
  border: 1.5px solid ${theme.form.input.border};
  position: relative;

  & input {
    border: none;
    width: 95%;
  }
`

export const FieldIcon = styled(Icon)`
  position: absolute;
  right: 5%;
  top: calc(50% - 6px);
`

export const ChatNewThreadDropdownContainer = styled(DropdownContent)`
  left: 24px;
  padding: 0 !important;
  width: 275px;
`

export const ChatNewThreadForm = styled(Form)`
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 10px;
  padding: 8px 16px;

  & label {
    padding: 14px 0 8px;
  }
`

export const ChatNewThreadResultsContainer = styled.div`
  margin-bottom: 10px;
  max-height: 250px;
  overflow: auto;
`
