import { Label } from 'actff-bo-app/components/Label'
import { ApplicationLink as ApplicationLinkType } from 'actff-bo-lib/admin'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Ref } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { colors, Switch } from 'styles'

type Props = Testable & {
  readonly control: Control,
  readonly link: ApplicationLinkType,
  readonly fieldName: string,
  readonly register: (ref: Ref | null) => void,
}

const Container = styled.div`
  display: grid;
  max-width: 50%;
  min-width: 170px;
  padding: 12px 0;
  grid-row-gap: 12px;
`

const NameWithSwitch = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
`

const UrlInput = styled.div`
  display: grid;
  grid-row-gap: 6px;
`

export const ApplicationLink: FC<Props> = ({ control, link, fieldName, register }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <NameWithSwitch>
        <Label color={colors.black} uppercase={false}>
          {t(`admin.dealerLocations.applicationLinks.linkType.${link.type}`)}
        </Label>
        <Controller
          render={({ value, onChange }) => (
            <Switch type='checkbox' onChange={onChange} checked={value ?? false} />
          )}
          control={control}
          name={`${fieldName}.enabled`}
        />
      </NameWithSwitch>
      <UrlInput>
        <Label>{t('caption.url')}:</Label>
        <input name={`${fieldName}.url`} ref={register} type='url' />
      </UrlInput>
    </Container>
  )
}
