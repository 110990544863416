import styled from 'styled-components'

import { colors } from './colors'

type Props = {
  readonly bold: boolean,
}

type HeaderProps = {
  readonly centered: boolean,
  readonly withAlert: boolean,
}

export type CssOffsetCoordinates = {
  readonly left: string,
  readonly bottom: string,
}

export const Header = styled.header`
  background-color: ${({ withAlert }: HeaderProps) => withAlert ? colors.flamingoRed : colors.athensGray };
  color: ${({ withAlert }: HeaderProps) => withAlert && colors.white };
  margin: 0;
  padding: ${({ centered }: HeaderProps) => centered ? '24px 32px 24px 32px' : '24px 32px 42px 32px' };
`

export const HeaderList = styled(Header)`
  padding: ${({ centered }: HeaderProps) => centered ? '24px 32px 10px 32px' : '24px 32px 10px 32px' };
`

export const HeaderFiltersContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
  margin: 12px 0;
`

export const HeaderSearch = styled.div`
  display: grid;
  align-items: center;
`

export const Search = styled.input`
  grid-area: search;
`

export const H1 = styled.h1`
  display: inline;
  font-size: 28px;
  font-weight: ${({ bold }: Props) => bold ? '500' : '300'};
  grid-area: header;
`

export const H2 = styled.h2`
  display: inline;
  font-size: 24px;
  font-weight: ${({ bold }: Props) => bold ? '500' : '300'};
  grid-area: header;
`

export const H3 = styled.h3`
  color: ${colors.dark};
  font-size: 13px;
  font-weight: 600;
  letter-spacing: .05em;
  line-height: 23px;
`

export const Small = styled.small`
  color: ${colors.dark};
  display: inline;
  font-size: 10px;
  font-weight: 600;
  grid-area: count;
  letter-spacing: 0.05em;
  margin-left: 10px;
  text-transform: uppercase;
`

export const Span = styled.span`
  color: ${colors.dark};
  font-size: 10px;
  letter-spacing: 0.05em;
`
