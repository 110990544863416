import { OwnershipAction, OwnershipActionType } from 'actff-bo-lib/car/actions'
import { OwnershipState } from 'actff-bo-lib/car/state'

const defaultOwnershipState: OwnershipState = {
  counterpartyList: {
    currentPage: 1,
    data: null,
    loading: false,
    searchPhrase: '',
  },
  currentOwnedCar: {
    data: null,
    fetchFailed: false,
    loading: false,
  },
}
// tslint:disable-next-line:cyclomatic-complexity
export const ownershipReducer = (state: OwnershipState = defaultOwnershipState, action: OwnershipAction): OwnershipState => {
  switch (action.type) {
    case OwnershipActionType.ClearCurrentCarOwnership:
      return defaultOwnershipState

    case OwnershipActionType.ClearCounterpartyList:
      return {
        ...state,
        counterpartyList: defaultOwnershipState.counterpartyList,
      }

    case OwnershipActionType.ChangeCounterpartyListPage:
      return {
        ...state,
        counterpartyList: {
          ...state.counterpartyList,
          currentPage: action.payload,
          data: null,
        },
      }

    case OwnershipActionType.ChangeCounterpartyListSearchPhrase:
      return {
        ...state,
        counterpartyList: {
          ...state.counterpartyList,
          currentPage: 1,
          searchPhrase: action.payload,
        },
      }

    case OwnershipActionType.GetCounterpartyList:
      return {
        ...state,
        counterpartyList: {
          ...state.counterpartyList,
          data: null,
          loading: true,
        },
      }

    case OwnershipActionType.GetCounterpartyListFailure:
      return {
        ...state,
        counterpartyList: {
          ...state.counterpartyList,
          data: null,
          loading: false,
        },
      }

    case OwnershipActionType.GetCounterpartyListSuccess:
      return {
        ...state,
        counterpartyList: {
          ...state.counterpartyList,
          data: action.payload,
          loading: false,
        },
      }

    case OwnershipActionType.GetOwnedCarById:
      return {
        ...state,
        currentOwnedCar: {
          data: null,
          fetchFailed: false,
          loading: true,
        },
      }

    case OwnershipActionType.GetOwnedCarByIdFailure:
      return {
        ...state,
        currentOwnedCar: {
          data: null,
          fetchFailed: true,
          loading: false,
        },
      }

    case OwnershipActionType.GetOwnedCarByIdSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    case OwnershipActionType.GetOwnedCarByVin:
      return {
        ...state,
        currentOwnedCar: {
          data: null,
          fetchFailed: false,
          loading: true,
        },
      }

    case OwnershipActionType.GetOwnedCarByVinFailure:
      return {
        ...state,
        currentOwnedCar: {
          data: null,
          fetchFailed: true,
          loading: false,
        },
      }

    case OwnershipActionType.GetOwnedCarByVinSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    case OwnershipActionType.ResetGetOwnedCarFetchFailed:
      return {
        ...state,
        currentOwnedCar: {
          ...state.currentOwnedCar,
          fetchFailed: false,
        },
      }

    case OwnershipActionType.UpdateAppDriverSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    case OwnershipActionType.UpdateNdcDriverSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    case OwnershipActionType.UpdateNdcOwnerSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    case OwnershipActionType.DeleteAppDriverSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    case OwnershipActionType.DeleteNdcDriverSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    case OwnershipActionType.DeleteNdcOwnerSuccess:
      return {
        ...state,
        currentOwnedCar: {
          data: action.payload,
          fetchFailed: false,
          loading: false,
        },
      }

    default:
      return state
  }
}
