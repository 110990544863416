import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { ClientId } from 'actff-bo-lib/client'
import { CrmInternalCommentDto, OpportunityListType } from 'actff-bo-lib/crm/dto'

import {
  OpportunityPurchase,
  OpportunityPurchaseId,
  OpportunitySale,
  OpportunitySaleFilterValues,
  OpportunitySaleId,
  OpportunityTradeInternalEvent,
  OpportunityTradeInternalEventDto,
  TradeOpportunityDto,
} from 'actff-bo-lib/crm/trade/dto'
import { DetailedFiltersFormValues } from 'actff-bo-lib/crm/trade/purchase'
import { Attachment } from 'actff-bo-lib/global/attachment'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import { AjaxError } from 'rxjs/ajax'

export enum CrmTradeOpportunitySaleActionType {
  AssignOpportunity = '[CRM TRADE SALE] ASSIGN OPPORTUNITY SALE',
  AssignOpportunityFailure = '[CRM TRADE SALE] ASSIGN OPPORTUNITY SALE FAILURE',
  AssignOpportunitySuccess = '[CRM TRADE SALE] ASSIGN OPPORTUNITY SALE SUCCESS',

  AssignPurchaseOpportunities = '[CRM TRADE SALE] ASSIGN PURCHASE SALE OPPORTUNITIES',
  AssignPurchaseOpportunitiesFailure = '[CRM TRADE SALE] ASSIGN PURCHASE SALE OPPORTUNITIES FAILURE',
  AssignPurchaseOpportunitiesSuccess = '[CRM TRADE SALE] ASSIGN PURCHASE SALE OPPORTUNITIES SUCCESS',

  ChangeOpportunityListCurrentPage = '[CRM TRADE SALE] CHANGE OPPORTUNITY SALE LIST CURRENT PAGE',
  ChangeOpportunityListTimeFrom = '[CRM TRADE SALE] CHANGE OPPORTUNITY SALE LIST TIME FROM',
  ChangeOpportunityListTimeTo = '[CRM TRADE SALE] CHANGE OPPORTUNITY SALE LIST TIME TO',
  ChangeSearchMatchingOpportunitiesCurrentPage = '[CRM TRADE SALE] CHANGE SEARCH MATCHING OPPORTUNITIES CURRENT PAGE',

  ChangeSearchPhrase = '[CRM TRADE SALE] CHANGE SEARCH PHRASE',
  ChangeAssignmentFilter = '[CRM TRADE SALE] CHANGE ASSIGNMENT FILTER',
  ChangeListsStatusFilters = '[CRM TRADE SALE] CHANGE LISTS STATUS FILTER',
  ClearListsStatusFilters = '[CRM TRADE SALE] CLEAR LISTS STATUS FILTERS',
  ChangeSearchMatchingOpportunitiesSearchPhrase = '[CRM TRADE SALE] CHANGE SEARCH MATCHING OPPORTUNITIES SEARCH PHRASE',

  ClearCurrentOpportunity = '[CRM TRADE SALE] CLEAR CURRENT OPPORTUNITY',
  ClearSaleState = '[CRM TRADE SALE] CLEAR SALE STATE',

  ChangeListsDetailedFilters = '[CRM TRADE SALE] CHANGE LISTS DETAILED FILTERS',
  ClearListsDetailedFilters = '[CRM TRADE SALE] CLEAR LISTS DETAILED FILTERS',

  GetOpportunity = '[CRM TRADE SALE] GET OPPORTUNITY SALE',
  GetOpportunityFailure = '[CRM TRADE SALE] GET OPPORTUNITY SALE FAILURE',
  GetOpportunitySuccess = '[CRM TRADE SALE] GET OPPORTUNITY SALE SUCCESS',

  GetInternalComments = '[CRM TRADE SALE] GET INTERNAL COMMENTS',
  GetInternalCommentsFailure = '[CRM TRADE SALE] GET INTERNAL COMMENTS FAILURE',
  GetInternalCommentsSuccess = '[CRM TRADE SALE] GET INTERNAL COMMENTS SUCCESS',

  GetOpportunityByClient = '[CRM TRADE SALE] GET OPPORTUNITY SALE BY CLIENT',
  GetOpportunityByClientFailure = '[CRM TRADE SALE] GET OPPORTUNITY SALE BY CLIENT FAILURE',
  GetOpportunityByClientSuccess = '[CRM TRADE SALE] GET OPPORTUNITY SALE BY CLIENT SUCCESS',

  GetOpportunities = '[CRM TRADE SALE] GET OPPORTUNITIES',
  GetOpportunitiesFailure = '[CRM TRADE SALE] GET OPPORTUNITIES FAILURE',
  GetOpportunitiesSuccess = '[CRM TRADE SALE] GET OPPORTUNITIES SUCCESS',

  GetMatchedPurchaseOpportunities = '[CRM TRADE SALE] GET MATCHED PURCHASE OPPORTUNITIES',
  GetMatchedPurchaseOpportunitiesFailure = '[CRM TRADE SALE] GET MATCHED PURCHASE OPPORTUNITIES FAILURE',
  GetMatchedPurchaseOpportunitiesSuccess = '[CRM TRADE SALE] GET MATCHED PURCHASE OPPORTUNITIES SUCCESS',

  ResetPaginationOnLists = '[CRM TRADE SALE] RESET PAGINATION ON LISTS',

  SendInternalEventWithAttachments = '[CRM TRADE SALE] SEND INTERNAL EVENT WITH ATTACHMENTS',
  SendInternalEventWithAttachmentsFailure = '[CRM TRADE SALE] SEND INTERNAL EVENT WITH ATTACHMENTS FAILURE',
  SendInternalEventWithAttachmentsSuccess = '[CRM TRADE SALE] SEND INTERNAL EVENT WITH ATTACHMENTS SUCCESS',

  SendInternalEvent = '[CRM TRADE SALE] SEND INTERNAL EVENT',
  SendInternalEventFailure = '[CRM TRADE SALE] SEND INTERNAL EVENT FAILURE',
  SendInternalEventSuccess = '[CRM TRADE SALE] SEND INTERNAL EVENT SUCCESS',

  SendInternalComment = '[CRM TRADE SALE] SEND INTERNAL COMMENT',
  SendInternalCommentFailure = '[CRM TRADE SALE] SEND INTERNAL COMMENT FAILURE',
  SendInternalCommentSuccess = '[CRM TRADE SALE] SEND INTERNAL COMMENT SUCCESS',

  UnassignOpportunity = '[CRM TRADE SALE] UNASSIGN OPPORTUNITY SALE',
  UnassignOpportunityFailure = '[CRM TRADE SALE] UNASSIGN OPPORTUNITY SALE FAILURE',
  UnassignOpportunitySuccess = '[CRM TRADE SALE] UNASSIGN OPPORTUNITY SALE SUCCESS',

  MatchPurchaseOpportunities = '[CRM TRADE SALE] MATCH PURCHASE OPPORTUNITIES',
  MatchPurchaseOpportunitiesByClient = '[CRM TRADE SALE] MATCH PURCHASE OPPORTUNITIES BY CLIENT',
  MatchPurchaseOpportunitiesFailure = '[CRM TRADE SALE] MATCH PURCHASE OPPORTUNITIES FAILURE',
  MatchPurchaseOpportunitiesByClientFailure = '[CRM TRADE SALE] MATCH PURCHASE OPPORTUNITIES BY CLIENT  FAILURE',
  MatchPurchaseOpportunitiesByClientSuccess = '[CRM TRADE SALE] MATCH PURCHASE OPPORTUNITIES BY CLIENT SUCCESS',
  MatchPurchaseOpportunitiesSuccess = '[CRM TRADE SALE] MATCH PURCHASE OPPORTUNITIES SUCCESS',

  UnmatchPurchaseOpportunity = '[CRM TRADE SALE] UNMATCH PURCHASE SALE OPPORTUNITY',
  UnmatchPurchaseOpportunitySuccess = '[CRM TRADE SALE] UNMATCH PURCHASE SALE OPPORTUNITY SUCCESS',
  UnmatchPurchaseOpportunityFailure = '[CRM TRADE SALE] UNMATCH PURCHASE SALE OPPORTUNITY FAILURE',

  UpdateOpportunity = '[CRM TRADE SALE] UPDATE OPPORTUNITY SALE',
  UpdateOpportunityFailure = '[CRM TRADE SALE] UPDATE OPPORTUNITY SALE FAILURE',
  UpdateOpportunitySuccess = '[CRM TRADE SALE] UPDATE OPPORTUNITY SALE SUCCESS',
}

export const CrmTradeOpportunitySaleAction = {
  assignOpportunity: (uuid: OpportunityPurchaseId, user: UserWithoutPermissions, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunitySaleActionType.AssignOpportunity, { uuid, user, opportunityListType }),
  assignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.AssignOpportunityFailure, error),
  assignOpportunitySuccess: (opportunity: OpportunitySale, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunitySaleActionType.AssignOpportunitySuccess, { opportunity, opportunityListType }),

  assignPurchaseOpportunities: (uuid: OpportunitySaleId, opportunities: ReadonlyArray<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunitySaleActionType.AssignPurchaseOpportunities, { uuid, opportunities }),
  assignPurchaseOpportunitiesFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.AssignPurchaseOpportunitiesFailure, error),
  assignPurchaseOpportunitiesSuccess: (opportunities: ReadonlyArray<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunitySaleActionType.AssignPurchaseOpportunitiesSuccess, opportunities),

  changeOpportunityListCurrentPage: (page: number, type: OpportunityListType) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeOpportunityListCurrentPage, { page, type }),
  changeOpportunityListTimeFrom: (date: Date, type: OpportunityListType) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeOpportunityListTimeFrom, { date, type } ),
  changeOpportunityListTimeTo: (date: Date, type: OpportunityListType) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeOpportunityListTimeTo, { date, type }),
  changeSearchMatchingOpportunitiesSearchPhrase: (searchPhrase: string) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeSearchMatchingOpportunitiesSearchPhrase, searchPhrase),

  changeAssignmentFilter: (filter: UserId | null) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeAssignmentFilter, filter),

  changeListsStatusFilters: (param: string, value: OpportunitySaleFilterValues) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeListsStatusFilters, { param, value }),
  changeSearchMatchingOpportunitiesCurrentPage: (page: number) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeSearchMatchingOpportunitiesSearchPhrase, { page }),
  changeSearchPhrase: (searchPhrase: string) => createAction(CrmTradeOpportunitySaleActionType.ChangeSearchPhrase, searchPhrase),
  clearListsStatusFilters: () => createAction(CrmTradeOpportunitySaleActionType.ClearListsStatusFilters),

  changeListsDetailedFilters: (values: DetailedFiltersFormValues) =>
    createAction(CrmTradeOpportunitySaleActionType.ChangeListsDetailedFilters, values),
  clearListsDetailedFilters: () => createAction(CrmTradeOpportunitySaleActionType.ClearListsDetailedFilters),

  clearCurrentOpportunity: () => createAction(CrmTradeOpportunitySaleActionType.ClearCurrentOpportunity),
  clearSaleState: () => createAction(CrmTradeOpportunitySaleActionType.ClearSaleState),

  getOpportunity: (uuid: OpportunitySaleId) => createAction(CrmTradeOpportunitySaleActionType.GetOpportunity, uuid),
  getOpportunityFailure: (error: AjaxError) => createAction(CrmTradeOpportunitySaleActionType.GetOpportunityFailure, error),
  getOpportunitySuccess: (opportunity: OpportunitySale) =>
    createAction(CrmTradeOpportunitySaleActionType.GetOpportunitySuccess, opportunity),

  resetPaginationOnLists: () => createAction(CrmTradeOpportunitySaleActionType.ResetPaginationOnLists),

  getOpportunityByClient: (clientId: ClientId) =>
    createAction(CrmTradeOpportunitySaleActionType.GetOpportunityByClient, { clientId }),
  getOpportunityByClientFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.GetOpportunityByClientFailure, error),
  getOpportunityByClientSuccess: (opportunity: OpportunitySale) =>
    createAction(CrmTradeOpportunitySaleActionType.GetOpportunityByClientSuccess, opportunity),

  getOpportunities: (type: OpportunityListType) => createAction(CrmTradeOpportunitySaleActionType.GetOpportunities, { type }),
  getOpportunitiesFailure: (type: OpportunityListType, error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.GetOpportunitiesFailure, { type, error }),
  getOpportunitiesSuccess: (type: OpportunityListType, opportunityList: PagedResult<OpportunitySale>) =>
    createAction(CrmTradeOpportunitySaleActionType.GetOpportunitiesSuccess, { type, opportunityList }),

  getMatchedPurchaseOpportunities: (opportunityId: OpportunityPurchaseId) =>
    createAction(CrmTradeOpportunitySaleActionType.GetMatchedPurchaseOpportunities, opportunityId),
  getMatchedPurchaseOpportunitiesFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.GetMatchedPurchaseOpportunitiesFailure, error),
  getMatchedPurchaseOpportunitiesSuccess: (opportunities: ReadonlyArray<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunitySaleActionType.GetMatchedPurchaseOpportunitiesSuccess, opportunities),

  sendInternalEventWithAttachments: (
    opportunityId: OpportunitySaleId,
    internalEvent: OpportunityTradeInternalEventDto,
    attachments?: ReadonlyArray<Attachment>,
  ) => createAction(CrmTradeOpportunitySaleActionType.SendInternalEventWithAttachments, { opportunityId, internalEvent, attachments }),
  sendInternalEventWithAttachmentsFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalEventWithAttachmentsFailure, error),
  sendInternalEventWithAttachmentsSuccess: (internalEvent: OpportunityTradeInternalEvent) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalEventWithAttachmentsSuccess, internalEvent),

  sendInternalEvent: (opportunityId: OpportunitySaleId, internalEvent: OpportunityTradeInternalEventDto) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalEvent, { opportunityId, internalEvent }),
  sendInternalEventFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalEventFailure, error),
  sendInternalEventSuccess: (internalEvent: OpportunitySale) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalEventSuccess, internalEvent),

  sendInternalComment: (opportunityId: OpportunitySaleId, internalCommentDto: CrmInternalCommentDto) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalComment, { opportunityId, internalCommentDto }),
  sendInternalCommentFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalCommentFailure, error),
  sendInternalCommentSuccess: (internalComment: OpportunitySale) =>
    createAction(CrmTradeOpportunitySaleActionType.SendInternalCommentSuccess, internalComment),

  unassignOpportunity: (uuid: OpportunitySaleId, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunitySaleActionType.UnassignOpportunity, { uuid, opportunityListType }),
  unassignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.UnassignOpportunityFailure, error),
  unassignOpportunitySuccess: (opportunity: OpportunitySale, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunitySaleActionType.UnassignOpportunitySuccess, { opportunity, opportunityListType }),

  matchPurchaseOpportunities: (saleId: OpportunitySaleId, opportunities: ReadonlyArray<OpportunityPurchaseId>) =>
    createAction(CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunities, { saleId, opportunities }),
  matchPurchaseOpportunitiesByClient: (clientId: ClientId, opportunities: ReadonlyArray<OpportunityPurchaseId>) =>
    createAction(CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesByClient, { clientId, opportunities }),
  matchPurchaseOpportunitiesByClientFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesByClientFailure, error),
  matchPurchaseOpportunitiesFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesFailure, error),
  matchPurchaseOpportunitiesSuccess: (opportunities: ReadonlyArray<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesSuccess, opportunities),

  unmatchPurchaseOpportunity: (saleId: OpportunityPurchaseId) =>
    createAction(CrmTradeOpportunitySaleActionType.UnmatchPurchaseOpportunity, saleId),
  unmatchPurchaseOpportunityFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.UnmatchPurchaseOpportunityFailure, error),
  unmatchPurchaseOpportunitySuccess: (opportunities: ReadonlyArray<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunitySaleActionType.UnmatchPurchaseOpportunitySuccess, opportunities),

  updateOpportunity: (opportunityDto: TradeOpportunityDto) =>
    createAction(CrmTradeOpportunitySaleActionType.UpdateOpportunity, opportunityDto),
  updateOpportunityFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunitySaleActionType.UpdateOpportunityFailure, error),
  updateOpportunitySuccess: (opportunity: OpportunitySale) =>
    createAction(CrmTradeOpportunitySaleActionType.UpdateOpportunitySuccess, opportunity),
}

export type CrmTradeOpportunitySaleAction = ActionsUnion<typeof CrmTradeOpportunitySaleAction>
