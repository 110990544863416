import { api, PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { ClientId } from 'actff-bo-lib/client'
import { buildUrl, Filters, getFiltersAsObject } from 'actff-bo-lib/global'
import { Observable } from 'rxjs'

import {
  TestDriveRequest,
  TestDriveRequestCount,
  TestDriveRequestFilterValues,
  TestDriveRequestId,
  UpdateTestDriveRequestDto,
} from './dto'

export const getTestDriveRequest = (uuid: TestDriveRequestId): Observable<TestDriveRequest> =>
  api.get<TestDriveRequest>(`/test-drive/${uuid}`)

export const getNewTestDriveRequestCount = () => api.get<TestDriveRequestCount>('/test-drive/count/new')

export const getTestDriveRequestCars = (clientUuid: ClientId) => api.get<PagedResult<CarInfo>>(`/car?clientUuid=${clientUuid}`)

export const getTestDriveRequests = (
  filters: Filters<TestDriveRequestFilterValues>,
  page: number,
): Observable<PagedResult<TestDriveRequest>> =>
  api.get<PagedResult<TestDriveRequest>>(
    buildUrl('/test-drive', { page, ...getFiltersAsObject(filters) }),
  )

export const updateTestDriveRequest = (dto: UpdateTestDriveRequestDto) =>
  api.patch(`/test-drive/${dto.uuid}`, { status: dto.status })
