import { getItem, removeItem, setItem } from 'actff-bo-lib/storage'
import { Token } from './dto'

const tokenStorageKey = 'actff-token'

export const getToken = () => getItem<Token>(tokenStorageKey)

export const setToken = (token: Token) => setItem<Token>(tokenStorageKey, token)

export const removeToken = () => removeItem(tokenStorageKey)
