import { ErrorMessage } from '@hookform/error-message'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell, FormCellWide, getDateValue, renderSelectController } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { ContactHistoryOutcome } from 'actff-bo-lib/crm/insurance'
import { SelectOption } from 'actff-bo-lib/global'
import { mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm, TextareaStyled } from '../Styled'

type Props = Testable
  & SectionFormType

export const SectionContactHistory: React.FC<Props> = ({ control, errors, expanded, register, testId, watch, readOnly }) => {
  const { t } = useTranslation()
  const attemptsCount = 4

  const contactHistoryResults = mapToOptions(Object.keys(ContactHistoryOutcome), 'crmInsurance.contactResult', t)

  const isDateRequired = (index: number) =>
    (
      !!watch(`contactHistory.attempts[${index}][outcome]`)
      || !!watch(`contactHistory.attempts[${index}][retry]`)
    )
    && 'caption.error.required'

  const isRetryRequired = (index: number) =>
    (
      !!watch(`contactHistory.attempts[${index}][date]`)
      && ![ContactHistoryOutcome.SUCCESS, ContactHistoryOutcome.REFUSAL]
        .includes(watch(`contactHistory.attempts[${index}][outcome]`)?.toString())
    )
    && 'caption.error.required'

  const isOutcomeRequired = (index: number) =>
    (
      !!watch(`contactHistory.attempts[${index}][date]`)
      || !!watch(`contactHistory.attempts[${index}][retry]`)
    )
    && 'caption.error.required'

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.contactHistory'), !!errors.contactHistory)}>
      <SectionForm>
      {Array(attemptsCount).fill(null).map((_, index) => (
          <React.Fragment key={index}>
            <FormCell className='datepicker-fullwidth' id={`contactHistory-attempts[${index}][date]`}>
              <label>{t('crmInsurance.contactHistory.contactDate', { number: index + 1 })}:</label>
              <Controller
                render={({ onChange, value }) => (
                  <DatePickerInput
                    date={getDateValue(value)}
                    onChange={onChange}
                    timeDisabled={true}
                    testId={`${testId}contactHistory-contactDate${index}`}
                    disabled={readOnly}
                  />
                )}
                control={control}
                rules={{ required: isDateRequired(index) }}
                name={`contactHistory.attempts[${index}][date]`}
              />
              <ErrorMessage errors={errors} name={`contactHistory.attempts[${index}][date]`} as={TranslatedErrorMessage} />
            </FormCell>
            <FormCell id={`contactHistory-attempts[${index}][outcome]`}>
              <label>{t('crmInsurance.contactHistory.result', { number: index + 1 })}:</label>
              <Controller
                render={renderSelectController({
                  disabled: readOnly,
                  options: [...contactHistoryResults] as ReadonlyArray<SelectOption<string>>,
                  t,
                  testId: `${testId}-history_result`,
                  transKey: 'crmInsurance.contactResult',
                })}
                control={control}
                rules={{ required: isOutcomeRequired(index) }}
                name={`contactHistory.attempts[${index}][outcome]`}
              />
              <ErrorMessage errors={errors} name={`contactHistory.attempts[${index}][outcome]`} as={TranslatedErrorMessage} />
            </FormCell>
            {index < attemptsCount - 1
              ? (
                <>
                  <FormCell className='datepicker-fullwidth' id={`contactHistory-attempts[${index}][retry]`}>
                    <label>{t('crmInsurance.contactHistory.retryDate', { number: index + 1 })}:</label>
                    <Controller
                      render={({ onChange, value }) => (
                        <DatePickerInput
                          date={getDateValue(value)}
                          onChange={onChange}
                          timeDisabled={true}
                          testId={`${testId}contactHistory-retryDate${index}`}
                          disabled={readOnly}
                        />
                      )}
                      control={control}
                      rules={{ required: isRetryRequired(index)}}
                      name={`contactHistory.attempts[${index}][retry]`}
                    />
                    <ErrorMessage errors={errors} name={`contactHistory.attempts[${index}][retry]`} as={TranslatedErrorMessage} />
                  </FormCell>
                </>
              )
              : <FormCell />
            }
            <FormCell />
          </React.Fragment>
        ))}

        <FormCellWide>
          <label>{t('crmInsurance.contactHistory.contactDescription')}:</label>
          <TextareaStyled name='contactHistory.description' ref={register} disabled={readOnly} />
        </FormCellWide>
      </SectionForm>
    </CollapsableSection>
  )
}
