import { Ref } from '@hookform/error-message/dist/types'
import { TimePicker as TimePickerComponent } from 'actff-bo-app/components/DateTime'
import { FieldValue } from 'actff-bo-app/components/Form'
import { Label } from 'actff-bo-app/components/Label'
import { FormOpeningHours } from 'actff-bo-lib/calendar/dto'
import { defaultTimeFormat, defaultTimeInterval, formatDate } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { endOfToday, startOfToday } from 'date-fns'
import React, { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly control: Control,
  readonly name: string,
  readonly register: (ref: Ref | null) => void,
  readonly setValue: (name: string, value: FormOpeningHours) => void,
}

type TimePickerProps = Testable & {
  readonly control: Control,
  readonly editMode: boolean,
  readonly name: string,
}

type InputContainerProps = {
  readonly hidden: boolean,
}

type WorkdayContainerProps = {
  readonly isWorkingDay: boolean,
}

const WorkdayContainer = styled.div<WorkdayContainerProps>`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 150px) 1fr;
  height: 50px;
  padding: 10px 0;

  * {
    ${({ isWorkingDay }: WorkdayContainerProps) => !isWorkingDay && `color: ${colors.dustyGray} !important`};
  }
`

const InputContainer = styled.div<InputContainerProps>`
  ${({ hidden }: InputContainerProps) => hidden && 'visibility: hidden'};
  min-width: 200px;

  input {
    width: 130px;
  }
`

const InputLabel = styled(Label)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`

const FieldValueStyled = styled(FieldValue)`
  padding-left: 14px;
`

const TimePicker: FC<TimePickerProps> = ({ control, editMode, name, testId }) => (
  <>
    <InputContainer>
      <Controller
        render={({ value, ...rest }) => {
          if (!editMode) {
            return (
              <FieldValueStyled hidden={editMode} data-testid={`${testId}${name}--readonly-value`}>
                <span>{value ? formatDate(defaultTimeFormat)(value) : '-'}</span>
              </FieldValueStyled>
            )
          }

          return (
            <TimePickerComponent
              date={value}
              disabled={!editMode}
              format={defaultTimeFormat}
              interval={defaultTimeInterval}
              maxTime={endOfToday()}
              minTime={startOfToday()}
              testId={testId}
              {...rest}
            />
          )
        }}
        control={control}
        name={name}
      />
    </InputContainer>
  </>
)

export const Weekday: FC<Props> = ({ control, name, register, setValue, testId }) => {
  const { t } = useTranslation()
  const isWorkingDayName = `${name}.isWorkingDay`

  const watchedWorkingDay = useWatch<FormOpeningHours>({
    control,
    name,
  })
  const isWorkingDay = !!watchedWorkingDay?.isWorkingDay

  if (!watchedWorkingDay) {
    return null
  }

  const handleIsWorkingDayChange = () => {
    setValue(name, {
      ...watchedWorkingDay,
      isWorkingDay: !isWorkingDay,
      timeFrom: null,
      timeTo: null,
    })
  }

  return (
    <WorkdayContainer isWorkingDay={isWorkingDay}>
      <input type='text' name={`${name}.dayOfWeek`} ref={register} style={{ display: 'none' }} />
      <input
        data-testid={`${testId}${name}--is-working-day`}
        id={isWorkingDayName}
        name={isWorkingDayName}
        onChange={handleIsWorkingDayChange}
        ref={register}
        type='checkbox'
      />
      <InputLabel data-testid={`${testId}${name}--is-working-day-label`} htmlFor={isWorkingDayName}>
        {t(`daypicker.weekdays.long.${name}`)}
      </InputLabel>
      <TimePicker
        control={control}
        editMode={isWorkingDay}
        name={`${name}.timeFrom`}
        testId={testId}
      />
      <TimePicker
        control={control}
        editMode={isWorkingDay}
        name={`${name}.timeTo`}
        testId={testId}
      />
    </WorkdayContainer>
  )
}
