import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormFooterLeft, FormFooterRight } from 'actff-bo-app/components/Form'
import { PrintButton } from 'actff-bo-app/components/PrintButton'
import { FooterStyled } from 'actff-bo-app/components/TabFilters/Styled'
import { ReportType } from 'actff-bo-lib/crm/insurance'
import { DateRange } from 'actff-bo-lib/date'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { addHours, addYears, startOfMonth, subYears } from 'date-fns'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { H2 } from 'styles'

import { DownloadReport } from './DownloadReport'
import { InsuranceReport } from './Report/InsuranceReport'
import { getReportFilters, getReportSummary, getTranslatedReportColumns, prepareColumns } from './Report/InsuranceReportConfig'
import { DatePickerContainer, FilterButton, FiltersContainer, HeaderFiltersContainer, HeaderStyled, ReportWrapper } from './Styled'

type OpportunityReportParams = {
  readonly type: ReportType,
}

const testId = 'crm-insurance__report-'
const startOfMonthHoursOffset = 2

export const OpportunityReport: React.FC = () => {
  const { t } = useTranslation()

  const [reportDateRange, setReportDateRange] = React.useState<DateRange>({
    from: addHours(startOfMonth(new Date()), startOfMonthHoursOffset),
    to: new Date(),
  })

  const { type = ReportType.Sales } = useParams<OpportunityReportParams>()
  const [printMode, setPrintMode] = React.useState(false)
  const tableRef = React.useRef<Element>()
  const columns = prepareColumns(getTranslatedReportColumns(type, t), printMode)
  const summaryRowsFn = getReportSummary(type)
  const reportFilters = getReportFilters(type)
  const pastYearsOffset = 7
  const maxYearDifference = 2
  const { to: currentTo, from: currentFrom } = reportDateRange

  const handleReportChange = (reportType: ReportType) => () => {
    history.push(createRoute(Paths.CrmInsuranceReport, { type: reportType }))
  }

  const handleDetailedDataDateChange = (keyOfDate: keyof DateRange) => (date: Date | null) => {
    const from = keyOfDate === 'from' ? date : reportDateRange.from
    const to = keyOfDate === 'to' ? date : reportDateRange.to

    setReportDateRange({ from, to })
  }

  return (
    <>
      <HeaderStyled>
        <HeaderFiltersContainer>
          <div><H2 data-testid={`${testId}header`}>{t('crmInsurance.report.header')}</H2></div>

          <FiltersContainer right={true}>
            <DatePickerContainer>
              <label>{t('label.from')}:</label>
              <DatePickerInput
                clickUnselectsDay={false}
                date={reportDateRange.from}
                disabledDays={[{ before: currentTo && subYears(currentTo, maxYearDifference) }]}
                futureYearsOffset={0}
                onChange={handleDetailedDataDateChange('from')}
                pastYearsOffset={pastYearsOffset}
              />
            </DatePickerContainer>
            <DatePickerContainer>
              <label>{t('label.to')}:</label>
              <DatePickerInput
                clickUnselectsDay={false}
                date={reportDateRange.to}
                disabledDays={[{ after: currentFrom && addYears(currentFrom, maxYearDifference) }]}
                futureYearsOffset={0}
                onChange={handleDetailedDataDateChange('to')}
                pastYearsOffset={pastYearsOffset}
              />
            </DatePickerContainer>
          </FiltersContainer>
        </HeaderFiltersContainer>
        <HeaderFiltersContainer>
          <FiltersContainer>
            {Object.keys(ReportType)
              .map(key => (
                <FilterButton
                  onClick={handleReportChange(ReportType[key])}
                  selected={type.toLowerCase() === ReportType[key].toLowerCase()}
                  key={key}
                >
                  {t(`crmInsurance.report.${ReportType[key].toLowerCase()}`)}
                </FilterButton>
            ))}
          </FiltersContainer>
        </HeaderFiltersContainer>
      </HeaderStyled>
      <ReportWrapper ref={tableRef}>
        {columns &&
          <InsuranceReport
            columns={columns}
            filters={reportFilters}
            printMode={printMode}
            reportDateRange={reportDateRange}
            summaryRowsFn={summaryRowsFn}
            type={type}
          />
        }
      </ReportWrapper>
      <FooterStyled>
        <FormFooterLeft />
        <FormFooterRight>
          <DownloadReport type={type} />
          <PrintButton
            contentRef={tableRef}
            onPrintEnd={() => setPrintMode(false)}
            onPrintStart={() => setPrintMode(true)}
          />
        </FormFooterRight>
      </FooterStyled>
    </>
  )
}
