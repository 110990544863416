import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectCarFeature = (state: State) => state.car

export const selectCurrentCarInsurance =
  createSelector(selectCarFeature, car => car.currentCarInsurance.data)

export const selectIsLoadingCarInsurance =
  createSelector(selectCarFeature, car => car.currentCarInsurance.loading)
