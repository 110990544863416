import { envSelector } from 'actff-bo-lib/api'
import { LoggedInPermissions } from 'actff-bo-lib/user'

import { ComponentType, IconType, Path } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

export const calendarMenu = {
  access: LoggedInPermissions,
  component: ComponentType.CalendarServiceAdvisors,
  expanded: false,
  hiddenInMenu: envSelector({
    default: false,
    dev: false,
    prod: true,
    stg: true,
    test: true,
  }),
  icon: IconType.CalendarWhite,
  name: 'menu.calendar',
  path: Paths.Calendar as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      component: ComponentType.CalendarServiceAdvisors,
      expanded: false,
      name: 'menu.calendar.serviceAdvisors',
      path: Paths.CalendarServiceAdvisors as Path,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CalendarMechanics,
      expanded: false,
      name: 'menu.calendar.mechanics',
      path: Paths.CalendarMechanics as Path,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CalendarElectronicsEngineers,
      expanded: false,
      name: 'menu.calendar.electronicsEngineers',
      path: Paths.CalendarElectronicsEngineers as Path,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CalendarReplacementCars,
      expanded: false,
      name: 'menu.calendar.replacementCars',
      path: Paths.CalendarReplacementCars as Path,
      ...LoggedInWrappers,
      children: [],
    },
  ],
}
