import { Icon } from 'actff-bo-app/components/Icon'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'

import { PaginationNavigationStyled, PaginationSummaryStyled, PaginationWrapperStyled } from './Styled'

type Props = Testable & {
  readonly centered?: boolean,
  readonly children?: JSX.Element,
  readonly currentPage: number,
  readonly onPageChange: (nextPage: number) => void,
  readonly pageCount: number,
  readonly pageRangeDisplayed?: number,
  readonly rowsInCurrentPage: number,
  readonly rowPerPage?: number,
  readonly showSummary?: boolean,
  readonly withMarginPages?: boolean,
}

const DefaultRowPerPage = 10
const DefaultPageRangeDisplayed = 2

export const Pagination: React.FC<Props> = ({
  centered = false,
  children,
  currentPage,
  onPageChange,
  pageCount,
  pageRangeDisplayed,
  rowsInCurrentPage,
  rowPerPage = DefaultRowPerPage,
  showSummary = true,
  testId,
  withMarginPages= true,
}) => {
  const handlePageChange = ({ selected }: { readonly selected: number }) => {
    onPageChange(selected + 1)
  }

  const getPageRangeDisplayed = () => pageRangeDisplayed || DefaultPageRangeDisplayed

  const { t } = useTranslation()
  const getMarginPagesDisplayed = () => withMarginPages ? getPageRangeDisplayed() : 0
  const getPageIndex = () => currentPage - 1
  const getStartCount = () => getPageIndex() * rowPerPage
  const end = getStartCount() + (pageCount > 1 ? rowPerPage : rowsInCurrentPage)
  const getTotal = () => pageCount > 1 ? pageCount * rowPerPage : rowsInCurrentPage
  const summary = (
    <PaginationSummaryStyled>
      {t('pagination.results' , {start: getStartCount() + 1, end, total: getTotal()})}
    </PaginationSummaryStyled>
  )

  return (
    pageCount > 1 ? (
      <PaginationWrapperStyled centered={centered}>
        <PaginationNavigationStyled data-testid={`${testId}pagination`}>
          <ReactPaginate
            activeClassName={'active'}
            breakClassName={'break-me'}
            breakLabel={'...'}
            containerClassName={'pagination'}
            disableInitialCallback={true}
            forcePage={getPageIndex()}
            initialPage={getPageIndex()}
            marginPagesDisplayed={getMarginPagesDisplayed()}
            nextLabel={<Icon type={IconType.Arrow} />}
            onPageChange={handlePageChange}
            pageCount={pageCount}
            pageRangeDisplayed={getPageRangeDisplayed()}
            previousLabel={<Icon type={IconType.Arrow} />}
          />
        </PaginationNavigationStyled>
        {children}
        {showSummary && summary}
      </PaginationWrapperStyled>
    )
    : null
  )
}
