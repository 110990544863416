import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { CarInfo } from 'actff-bo-lib/car'
import { yearDateFormat } from 'actff-bo-lib/date'
import { createRoute } from 'actff-bo-lib/menu/helpers'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import React, { FC } from 'react'
import styled from 'styled-components'

type Props = {
  readonly car: CarInfo,
}

export const Tr = styled.tr`
  cursor: pointer;
`

export const CarRow: FC<Props> = ({ car }) => {
  const { model, version, alias, modelYear, firstRegistrationDate, vin, uuid } = car
  const href = createRoute(Paths.CarViewBasicData, { carId: uuid })
  const Td = TdDecorator(href)

  return (
    <>
      <Tr clickable={true} isStyled={true}>
        <Td>{model}</Td>
        <Td>{alias}</Td>
        <Td><FormattedDate date={modelYear} format={yearDateFormat} /></Td>
        <Td><FormattedDate date={firstRegistrationDate} /></Td>
        <Td>{version}</Td>
        <Td>{vin}</Td>
      </Tr>
    </>
  )
}
