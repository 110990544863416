import { LinkButton, MinorButton, PrimaryButton } from 'styles'

export enum BtnTheme {
  Link,
  Minor,
  Primary,
}

export enum BtnType {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

export const ButtonComponent = (theme: BtnTheme) => {
  switch (theme) {
    case BtnTheme.Link:
      return (
        LinkButton
      )
    case BtnTheme.Primary:
      return (
        PrimaryButton
      )
    case BtnTheme.Minor:
    default:
      return (
        MinorButton
      )
  }
}
