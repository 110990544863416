import { Label } from 'actff-bo-app/components/Label'
import { Testable } from 'actff-bo-lib/global/testable'
import { FieldProps } from 'formik'
import * as React from 'react'
import { theme } from 'styles'

import { FormRadio } from './FormStyled'

type RadioButtonFieldProps = Testable & {
  readonly darkColor?: boolean,
  readonly disabled?: boolean,
  readonly error?: string,
  readonly htmlFor: string,
  readonly label: string,
  readonly inline?: boolean,
  readonly slim?: boolean,
  readonly touched?: boolean,
  readonly value: boolean,
}

// tslint:disable-next-line no-any
const isBoolean = (value: any): boolean => typeof value === 'boolean'

export const RadioButtonField: React.FC<HTMLElement & RadioButtonFieldProps & FieldProps> = ({
  field: { name, value: fieldValue, onBlur },
  disabled = false,
  error,
  form,
  label,
  slim = false,
  id,
  inline = true,
  testId,
  touched,
  value,
}) => {
  const handleOnChange = () => {
    form.setFieldValue(name, isBoolean(fieldValue) ? !fieldValue : value)
  }
  const getTestId = (postfix = '') => testId && { 'data-testid': `${testId}${postfix}` }

  return (
    <FormRadio slim={slim} inline={inline}>
      <input
        className='error'
        disabled={disabled}
        name={name}
        id={id}
        type='radio'
        checked={fieldValue === value}
        onChange={handleOnChange}
        onBlur={onBlur}
        {...getTestId()}
      />
      <Label
        {...(touched && error && { className: 'error' })}
        color={theme.form.radio.label}
        disabled={disabled}
        error={touched && error}
        htmlFor={id}
        uppercase={false}
        {...getTestId('-label')}
      >
        {label}
      </Label>
    </FormRadio>
  )
}
