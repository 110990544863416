import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

export const selectOfferRequestFeature = (state: State) => state.offerRequest

export const selectOfferRequests = createSelector(selectOfferRequestFeature, offerRequest => offerRequest.list.data)

export const selectNewOfferRequestCount = createSelector(selectOfferRequestFeature, offerRequest => offerRequest.newOfferRequestCount)

export const selectIsLoadingOfferRequests = createSelector(selectOfferRequestFeature, offerRequest => offerRequest.list.loading)

export const selectOfferRequestsCurrentPage =
  createSelector(selectOfferRequestFeature, offerRequest => offerRequest.currentPage)

export const selectCurrentOfferRequest =
  createSelector(selectOfferRequestFeature, offerRequest => offerRequest.currentOfferRequest)

export const selectOfferRequestSearchPhrase =
  createSelector(selectOfferRequestFeature, offerRequest => offerRequest.list.searchPhrase)

export const selectCurrentOfferRequestCars =
  createSelector(selectOfferRequestFeature, offerFeature => offerFeature.currentOfferRequestCars)

export const selectOfferRequestListFilters =
  createSelector(selectOfferRequestFeature, offerRequest => offerRequest.listFilters)

export const selectOfferRequestDealerLocationFilters =
  createSelector(selectOfferRequestFeature, offerRequest => offerRequest.list.locationFilters)

export const selectOfferRequestBrandFilters =
  createSelector(selectOfferRequestFeature, offerRequest => offerRequest.list.brandFilters)
