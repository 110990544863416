import { Label } from 'actff-bo-app/components/Label'
import { FieldProps } from 'formik'
import * as React from 'react'
import styled from 'styled-components'
import { FormCell } from './FormStyled'

enum InputType {
  Text = 'text',
  Number = 'number',
}

type InputProps = {
  readonly disabled?: boolean,
  readonly error?: string,
  readonly htmlFor: string,
  readonly label: string,
  readonly inline?: boolean,
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  readonly slim?: boolean,
  readonly testId?: boolean,
  readonly touched?: boolean,
}

type StringInputProps = {
  readonly type: InputType.Text,
  readonly value: string,
}

type NumberInputProps = {
  readonly type: InputType.Number,
  readonly maxNumberValue?: number,
  readonly minNumberValue?: number,
  readonly value: number,
}

const FormCellStyled = styled(FormCell)`
  padding: 0;
`
// tslint:disable cyclomatic-complexity
type InputFieldProps = (StringInputProps | NumberInputProps) & InputProps & HTMLElement & FieldProps
export const InputField: React.FC<InputFieldProps> = props => {
  const { error, disabled, id, label, inline, form, onChange, slim, type, field, testId, touched } = props
  const { name, value: fieldValue, onBlur } = field

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)

      return
    }

    form.setFieldValue(name, event.target.value)
  }

  return (
    <FormCellStyled slim={slim} inline={inline}>
      <Label
        {...(touched && error && { className: 'error' })}
        disabled={disabled}
        error={touched && error}
        htmlFor={id}
        {...(testId && { 'data-testid': `${testId}--label` })}
      >
        {label}
      </Label>
      <input
        {...(touched && error && { className: 'error' })}
        {...(testId && { 'data-testid': `${testId}` })}
        disabled={disabled}
        name={name}
        id={id}
        onChange={handleOnChange}
        onBlur={onBlur}
        type={type}
        value={fieldValue || ''}
        {...(props.type === InputType.Number && props.minNumberValue !== undefined && { min: props.minNumberValue })}
        {...(props.type === InputType.Number && props.maxNumberValue !== undefined && { max: props.maxNumberValue })}
      />
    </FormCellStyled>
  )
}
