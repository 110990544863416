import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'

import { CrmInsuranceInsurerAction, CrmInsuranceInsurerActionType } from '../actions/insurer'
import { getBankAccountNumbersForInsurer } from '../dao/insurer'

const getBankAccountNumbersForInsurerEpic: Epic<CrmInsuranceInsurerAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceInsurerAction.getBankAccountNumbersForInsurer>>(
    CrmInsuranceInsurerActionType.GetBankAccountNumbersForInsurer,
  ),
  switchMap(({ payload }) => getBankAccountNumbersForInsurer(payload).pipe(
    map(CrmInsuranceInsurerAction.getBankAccountNumbersForInsurerSuccess),
    catchError((error: AjaxError) => of(CrmInsuranceInsurerAction.getBankAccountNumbersForInsurerFailure(error))),
  )),
)

export const crmInsuranceInsurerEpic = combineEpics(
  getBankAccountNumbersForInsurerEpic,
)
