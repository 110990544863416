import { formatPhoneNumber } from 'actff-bo-lib/client'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { ChangeEvent, FC } from 'react'
import { Control, Controller } from 'react-hook-form'

type Props = Testable & {
  readonly control: Control,
  readonly name: string,
  readonly defaultValue?: string,
  readonly disabled?: boolean,
}

export const FormFormattedPhoneInput: FC<Props> = ({
  control,
  defaultValue,
  name,
  testId,
  disabled,
}): React.ReactElement => (
  <Controller
    render={({ value, onChange }) => (
      <input
        type='text'
        name='contactPerson.mobile'
        data-testid={`${testId}contactPerson-cellPhone`}
        onChange={(changedValue: ChangeEvent<HTMLInputElement>) => onChange(formatPhoneNumber(changedValue.target.value))}
        value={value}
        disabled={disabled}
      />
    )}
    control={control}
    defaultValue={defaultValue || ''}
    name={name}
  />
)
