import { Testable } from 'actff-bo-lib/global/testable'
import { Path } from 'actff-bo-lib/menu'
import * as React from 'react'

import { Pagination } from './Pagination'

type Props = Testable & {
  readonly additionalActionButton?: JSX.Element,
  readonly currentPage: number,
  readonly onPageChange: (nextPage: number) => void,
  readonly pageCount: number,
  readonly path: Path,
  readonly rowsInCurrentPage: number,
  readonly withMarginPages?: boolean,
}

export class WithPagination extends React.Component<Props> {
  public render(): React.ReactNode {
    const { additionalActionButton, currentPage, onPageChange, pageCount, testId, rowsInCurrentPage, withMarginPages } = this.props

    return (
      <>
        {this.props.children}
        <Pagination
          centered={true}
          currentPage={currentPage}
          onPageChange={onPageChange}
          pageCount={pageCount}
          testId={testId}
          rowsInCurrentPage={rowsInCurrentPage}
          withMarginPages={withMarginPages}
        >
          {additionalActionButton}
        </Pagination>
      </>
    )
  }
}
