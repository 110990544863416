import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectDashboardFeature = (state: State) => state.dashboard

export const selectDashboardSummaryData = createSelector(selectDashboardFeature, dashboard => dashboard.summaryData)

export const selectDashbordDetailedDataType = createSelector(selectDashboardFeature, dashboard => dashboard.detailedData.type)
export const selectDashboardDetailedDataDateRange =
  createSelector(selectDashboardFeature, dashboard => ({ from: dashboard.detailedData.from, to: dashboard.detailedData.to }))
export const selectDashboardDetailedDataGroupBy = createSelector(selectDashboardFeature, dashboard => dashboard.detailedData.groupBy)

export const selectDashboardDetailedData = createSelector(selectDashboardFeature, dashboard => dashboard.detailedData.data)
