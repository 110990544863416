import { Tooltip as TooltipMaterial, withStyles } from '@material-ui/core'
import * as React from 'react'
import { colors } from 'styles'

const TooltipContainer = withStyles(() => ({
  arrow: {
    color: colors.white,
  },
  tooltip: {
    backgroundColor: colors.white,
    color: colors.dark,
    fontSize: '12px',
    padding: '10px',
  },
}))(TooltipMaterial)

type Props = {
  readonly children: JSX.Element,
  readonly title: string,
}

export const Tooltip = React.forwardRef<React.ComponentType, Props>(({ children, title }, ref) => (
  <TooltipContainer arrow={true} innerRef={ref} placement='top' title={title}>
    <span aria-label='tooltip'>{children}</span>
  </TooltipContainer>
))
