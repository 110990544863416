import { Loadable } from 'actff-bo-lib/global'
import { CrmInsuranceOpportunityAction, CrmInsuranceOpportunityActionType } from '../actions/opportunity'
import { OpportunityInsurance, OpportunityViewer } from '../dto'

export type CrmInsuranceOpportunityState = {
  readonly currentOpportunity: Loadable<OpportunityInsurance>,
  readonly currentOpportunityErrors: { readonly [key: string]: string } | null,
  readonly currentOpportunityViewers: ReadonlyArray<OpportunityViewer>,
}

const initialInsuranceOpportunityState: CrmInsuranceOpportunityState = {
  currentOpportunity: {
    data: null,
    loading: false,
  },
  currentOpportunityErrors: null,
  currentOpportunityViewers: [],
}

// tslint:disable-next-line:cyclomatic-complexity
export const crmInsuranceOpportunityReducer = (
  state: CrmInsuranceOpportunityState = initialInsuranceOpportunityState,
  action: CrmInsuranceOpportunityAction,
): CrmInsuranceOpportunityState => {
  switch (action.type) {
    case CrmInsuranceOpportunityActionType.ClearOpportunity:
      return ({
        ...state,
        currentOpportunity: {
          data: null,
          loading: false,
        },
        currentOpportunityErrors: null,
      })

    case CrmInsuranceOpportunityActionType.GetOpportunity:
      return ({
        ...state,
        currentOpportunity: {
          data: null,
          loading: true,
        },
        currentOpportunityErrors: null,
      })

    case CrmInsuranceOpportunityActionType.GetOpportunitySuccess:
      return ({
        ...state,
        currentOpportunity: {
          data: action.payload,
          loading: false,
        },
      })

    case CrmInsuranceOpportunityActionType.SaveOpportunity:
      return ({
        ...state,
        currentOpportunityErrors: null,
      })

    case CrmInsuranceOpportunityActionType.SaveOpportunityFailure:
      return ({
        ...state,
        currentOpportunityErrors: {
          ...action.payload.response,
        },
      })

    case CrmInsuranceOpportunityActionType.ReceivedOpportunityViewersUpdate:
      return ({
        ...state,
        currentOpportunityViewers: action.payload.viewers || [],
      })

    case CrmInsuranceOpportunityActionType.DeleteOpportunity:
      return ({
        ...state,
        currentOpportunity: {
          ...state.currentOpportunity,
          loading: true,
        },
      })

    case CrmInsuranceOpportunityActionType.DeleteOpportunitySuccess:
      return ({
        ...state,
        currentOpportunity: {
          data: null,
          loading: false,
        },
      })

    case CrmInsuranceOpportunityActionType.DeleteOpportunityFailure:
      return ({
        ...state,
        currentOpportunity: {
          ...state.currentOpportunity,
          loading: false,
        },
      })

    default:
      return state
  }
}
