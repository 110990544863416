import { adminLocationsEpic } from 'actff-bo-lib/admin/dealer-locations'
import { apiEpics } from 'actff-bo-lib/api'
import { approvalsContentEpic } from 'actff-bo-lib/approvals-content'
import { authEpic } from 'actff-bo-lib/auth'
import { campaignEpic } from 'actff-bo-lib/campaign/epic'
import { carEpic } from 'actff-bo-lib/car'
import { chatEpic } from 'actff-bo-lib/chat'
import { clientEpic } from 'actff-bo-lib/client'
import { crmInsuranceEpic } from 'actff-bo-lib/crm/insurance/epic'
import { crmServiceEpic } from 'actff-bo-lib/crm/service'
import { crmTradeEpic } from 'actff-bo-lib/crm/trade'
import { dashboardEpic } from 'actff-bo-lib/dashboard'
import { dealershipEpic } from 'actff-bo-lib/dealership'
import { debugToolsEpic } from 'actff-bo-lib/debug-tools'
import { dictionaryEpic } from 'actff-bo-lib/dictionary'
import { attachmentEpic } from 'actff-bo-lib/global/attachment'
import { i18nEpic } from 'actff-bo-lib/i18n'
import { ndcCarEpic } from 'actff-bo-lib/ndc-car'
import { notificationEpic } from 'actff-bo-lib/notification'
import { offerRequestEpic } from 'actff-bo-lib/offer-request'
import { serviceEpic } from 'actff-bo-lib/service'
import { serviceRequestEpic } from 'actff-bo-lib/service-request'
import { telephoneRequestEpic } from 'actff-bo-lib/telephone-request'
import { testDriveRequestEpic } from 'actff-bo-lib/test-drive-request'
import { userEpic } from 'actff-bo-lib/user'
import { combineEpics } from 'redux-observable'

export const rootEpic = combineEpics(
  adminLocationsEpic,
  apiEpics,
  approvalsContentEpic,
  attachmentEpic,
  authEpic,
  campaignEpic,
  carEpic,
  chatEpic,
  clientEpic,
  crmInsuranceEpic,
  crmServiceEpic,
  crmTradeEpic,
  dashboardEpic,
  dealershipEpic,
  debugToolsEpic,
  dictionaryEpic,
  i18nEpic,
  ndcCarEpic,
  notificationEpic,
  offerRequestEpic,
  serviceEpic,
  serviceRequestEpic,
  telephoneRequestEpic,
  testDriveRequestEpic,
  userEpic,
)
