import { ClientFullName, ClientPhone, ClientType } from 'actff-bo-app/components/Client'
import { TimeAgo } from 'actff-bo-app/components/DateTime'
import { WithStyledRow } from 'actff-bo-app/components/Table'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { defaultTimeFormat } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { TestDriveRequest, TestDriveRequestStatus } from 'actff-bo-lib/test-drive-request'
import { format } from 'date-fns'
import * as React from 'react'
import { TableInfo } from 'styles'

type Props = Testable & {
  readonly testDrive: TestDriveRequest,
}

export const TestDriveRequestRow: React.FC<Props> = ({ testDrive, testId }) => {
  const { client, requestTime, status } = testDrive
  const route = createRoute(Paths.TestDriveRequestsView, { testDriveRequestId: testDrive.uuid })
  const Td = TdDecorator(route)

  return (
    <WithStyledRow
      isStyled={status === TestDriveRequestStatus.NEW}
      time={requestTime}
    >
      <Td testId={`${testId}--date`}>
        {format(requestTime, defaultTimeFormat)}
        <TableInfo><TimeAgo date={requestTime} /></TableInfo>
      </Td>
      <Td testId={`${testId}--full-name`}><ClientFullName client={client} /></Td>
      <Td testId={`${testId}--type`}><ClientType client={client} /></Td>
      <Td testId={`${testId}--phone`}><ClientPhone client={client} /></Td>
    </WithStyledRow>
  )
}
