import { NewUserForm } from 'actff-bo-app/Admin/Dealership/Users/NewUserForm'
import { Footer } from 'actff-bo-app/Admin/Footer'
import { Th } from 'actff-bo-app/components/EditableList/Styled'
import { MultipleSelectFilter } from 'actff-bo-app/components/Filters'
import { ControlledInput } from 'actff-bo-app/components/Form/ControlledInput'
import { mapOptionsToArray, mapToOptions, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { FormUser, User, UserDto, UserId } from 'actff-bo-lib/user'
import React, { FC, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LightTable, Td, Tr } from 'styles'

import { ActionMenu } from './ActionMenu'
import { mapOptionsToLocationKeys } from './map-options-to-locations'
import { Container, LastTh, Password, RolesSelect } from './Styled'

type Props = Testable & {
  readonly users: ReadonlyArray<User>,
  readonly brandSelectOptions: ReadonlyArray<SelectOption<string | null>>,
  readonly locationSelectOptions: ReadonlyArray<SelectOption<string | null>>,
  readonly deleteUser: (userId: UserId) => void,
  readonly updateUsers: (users: ReadonlyArray<UserDto>) => void,
  readonly rolesSelectOptions: ReadonlyArray<SelectOption<string | null>>,
  readonly onAddNewUser: () => void,
}

type FormValues = {
  readonly users: ReadonlyArray<FormUser>,
}

export const minSelectWidth = 200
export const maxSelectWidth = 200
const rowSpan = 2

const mapUserRoles = (roles: SelectOption<string>) =>
  roles ? (mapOptionsToArray([roles]) || []) : []

const mapFormValuesToUserDto = (formValues: FormValues): ReadonlyArray<UserDto> =>
  formValues.users.map(user => ({
    ...user,
    brands: mapOptionsToArray(user.brands) || [],
    locations: mapOptionsToLocationKeys(user.locations) || [],
    roles: mapUserRoles(user.roles),
  }))

export const UsersTable: FC<Props> = ({
  deleteUser,
  users,
  brandSelectOptions,
  locationSelectOptions,
  onAddNewUser,
  rolesSelectOptions,
  testId,
  updateUsers,
}) => {
  const { t } = useTranslation()

  const mapUsersToFormValues = (usersList: ReadonlyArray<User>): ReadonlyArray<FormUser> =>
    usersList.map(user => ({
      ...user,
      brands: mapToOptions(user.brands) || [],
      locations: mapToOptions(user.locations.map(location => location.key)) || [],
      roles: mapToOptions(user.roles, 'user.roles', t)[0],
    }))

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false)
  const toggleAddUserDialogOpen = () => { setAddUserDialogOpen(!addUserDialogOpen) }

  const {
    control,
    handleSubmit,
    formState,
    reset,
  } = useForm({
    defaultValues: {
      users: mapUsersToFormValues(users),
    },
    mode: 'onBlur',
  })

  useEffect(() => {
    reset({
      users: mapUsersToFormValues(users),
    })
  }, [users])

  const { fields, remove } = useFieldArray({
    control,
    name: 'users',
  })

  const handleDelete = (uuid: UserId, index: number) => () => {
    deleteUser(uuid)
    remove(index)
  }

  const onSubmit = (data: FormValues) => {
    updateUsers(mapFormValuesToUserDto(data))
  }

  const isSaveDisabled = !formState.isDirty || !formState.isValid

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <LightTable textAlign='center' opacity='50%' paddingTight={true}>
          <thead>
          <Tr>
            <Th rowSpan={rowSpan}>{t('caption.firstName')}</Th>
            <Th rowSpan={rowSpan}>{t('caption.lastName')}</Th>
            <Th rowSpan={rowSpan}>{t('user.role')}</Th>
            <Th colSpan={rowSpan}>{t('admin.dealership.users.authData')}</Th>
            <Th colSpan={rowSpan}>{t('admin.dealership.users.accesses')}</Th>
            <LastTh colSpan={rowSpan} />
          </Tr>
          <Tr>
            <Th>{t('user.password')}</Th>
            <Th>{t('caption.mail')}</Th>
            <Th>{t('caption.location')}</Th>
            <Th>{t('caption.brand')}</Th>
            <Th colSpan={rowSpan} />
          </Tr>
          </thead>
          <tbody>
          {fields.map((field, index) => (
            <Tr key={field.id}>
              <Td>
                <ControlledInput control={control} name={`users.${index}.firstName`} styles={{ height: '40px' }} />
                <ControlledInput control={control} name={`users.${index}.uuid`} styles={{ height: '40px' }} type='hidden' />
              </Td>
              <Td>
                <ControlledInput control={control} name={`users.${index}.lastName`} styles={{ height: '40px' }} />
              </Td>
              <Td>
                <Controller
                  as={<RolesSelect />}
                  control={control}
                  name={`users.${index}.roles`}
                  options={rolesSelectOptions}
                />
              </Td>
              <Td>
                <Password>*******</Password>
              </Td>
              <Td>
                <ControlledInput
                  control={control}
                  disabled={true}
                  name={`users.${index}.email`}
                  type='email'
                  styles={{ height: '40px', width: '300px' }}
                />
              </Td>
              <Td>
                <MultipleSelectFilter
                  control={control}
                  key={`users.${index}.locations`}
                  name={`users.${index}.locations`}
                  options={locationSelectOptions}
                  trimLength={1}
                  menuPlacement='bottom'
                  maxWidth={maxSelectWidth}
                  minWidth={minSelectWidth}
                />
              </Td>
              <Td>
                <MultipleSelectFilter
                  control={control}
                  key={`users.${index}.brands`}
                  name={`users.${index}.brands`}
                  options={brandSelectOptions}
                  trimLength={1}
                  maxWidth={maxSelectWidth}
                  minWidth={minSelectWidth}
                />
              </Td>
              <ActionMenu
                onDelete={handleDelete(field.uuid, index)}
                user={{ firstName: field.firstName, lastName: field.lastName, email: field.email }}
              />
            </Tr>
          ))}
          </tbody>
        </LightTable>

        <NewUserForm
          dialogOpen={addUserDialogOpen}
          toggleAddUserDialogOpen={toggleAddUserDialogOpen}
          brandSelectOptions={brandSelectOptions}
          onAddNewUser={onAddNewUser}
          rolesSelectOptions={rolesSelectOptions}
          locationSelectOptions={locationSelectOptions}
        />

        <Footer
          isSaveDisabled={isSaveDisabled}
          onAddClick={toggleAddUserDialogOpen}
          onSave={handleSubmit(onSubmit)}
          testId={testId}
          onAddTLabel='admin.dealership.users.add'
        />
      </Container>

    </form>
  )
}
