import styled, { css } from 'styled-components'

export const tabletBoundary = '1366px'

export const HorizontallyScrolledContainer = styled.div`
  overflow-x: auto;
`

export const containers = {
  main: {
    style: css`
      margin: auto;
      width: 100%;
    `,
  },
}
