import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectOpportunitySaleFeature = (state: State) => state.crmTrade.sale

export const selectOpportunitySaleListByType = (type: OpportunityListType) =>
  createSelector(selectOpportunitySaleFeature, opportunity => opportunity[type])

export const selectOpportunitySaleListCurrentPage = (type: OpportunityListType) =>
  createSelector(selectOpportunitySaleListByType(type), list => list.currentPage)

export const selectOpportunitySaleListTimeFrom =
  (type: OpportunityListType) => createSelector(selectOpportunitySaleListByType(type), list => list.from)

export const selectOpportunitySaleListTimeTo =
  (type: OpportunityListType) => createSelector(selectOpportunitySaleListByType(type), list => list.to)

export const selectOpportunitySaleList = (type: OpportunityListType) =>
  createSelector(selectOpportunitySaleListByType(type), list => list.list)

export const selectMatchedPurchaseOpportunities =
  createSelector(selectOpportunitySaleFeature, sale => sale.currentMatchedPurchaseOpportunities)

export const selectCurrentOpportunitySale =
  createSelector(selectOpportunitySaleFeature, sale => sale.currentOpportunity)

export const selectOpportunitySaleSearchPhrase =
  createSelector(selectOpportunitySaleFeature, sale => sale.searchPhrase)

export const selectOpportunitySaleListsStatusFilters =
  createSelector(selectOpportunitySaleFeature, sale => sale.statusFilters)

export const selectOpportunitySaleListsDetailedFilters =
  createSelector(selectOpportunitySaleFeature, sale => sale.detailedFilters)

export const selectOpportunitySaleListsStringifiedDetailedFilters =
  createSelector(selectOpportunitySaleFeature, sale => sale.stringifiedDetailedFilters)

export const selectOpportunitySaleListAssignmentFilter =
  createSelector(selectOpportunitySaleFeature, sale => sale.assignmentFilter)
