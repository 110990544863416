import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { redirectToList } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { OfferRequestAction, OfferRequestActionType } from './actions'
import { getNewOfferRequestCount, getOffer, getOfferCars, getOffers, updateOffer } from './dao'
import {
  selectOfferRequestBrandFilters,
  selectOfferRequestDealerLocationFilters,
  selectOfferRequestListFilters,
  selectOfferRequestsCurrentPage,
  selectOfferRequestSearchPhrase,
} from './selectors'

const changeListParamsEpic: Epic<OfferRequestAction, OfferRequestAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof OfferRequestAction.changePage>>(OfferRequestActionType.ChangePage),
  withLatestFrom(state$),
  tap(([, store]) => {
    redirectToList(Paths.OfferRequests, selectOfferRequestsCurrentPage(store))
  }),
  map(OfferRequestAction.getOfferRequests),
)

const changeFilterOrSearchPhraseEpic: Epic<OfferRequestAction> = action$ => action$.pipe(
  ofType(
    OfferRequestActionType.ChangeListFilter,
    OfferRequestActionType.ChangeSearchPhrase,
    OfferRequestActionType.ChangeDealerLocations,
    OfferRequestActionType.ChangeBrands,
  ),
  map(() => OfferRequestAction.changePage(1)),
)

const getOfferRequestsEpic: Epic<OfferRequestAction, OfferRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(OfferRequestActionType.GetOfferRequests),
  withLatestFrom(state$),
  switchMap(([, state]) => getOffers(
    selectOfferRequestListFilters(state),
    selectOfferRequestsCurrentPage(state) - 1,
    encodeStringIfExists(selectOfferRequestSearchPhrase(state)),
    selectOfferRequestDealerLocationFilters(state),
    selectOfferRequestBrandFilters(state),
  ).pipe(
      map(OfferRequestAction.getOfferRequestsSuccess),
      catchError((error: AjaxError) => of(OfferRequestAction.getOfferRequestsFailure(error)),
  )),
))

const getOfferRequestEpic: Epic<OfferRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OfferRequestAction.getOfferRequest>>(OfferRequestActionType.GetOfferRequest),
  switchMap(({ payload }) => getOffer(payload).pipe(
    map(OfferRequestAction.getOfferRequestSuccess),
    catchError((error: AjaxError) => of(OfferRequestAction.getOfferRequestFailure(error)),
  )),
))

const getNewOfferRequestEpic: Epic<OfferRequestAction> = action$ => action$.pipe(
  ofType(OfferRequestActionType.GetNewOfferRequestCount),
  switchMap(() => getNewOfferRequestCount().pipe(
    map(OfferRequestAction.getNewOfferRequestCountSuccess),
    catchError((error: AjaxError) => of(OfferRequestAction.getOfferRequestFailure(error))),
  )),
)

const getOfferRequestCarsEpic: Epic<OfferRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OfferRequestAction.getOfferRequestSuccess>>(OfferRequestActionType.GetOfferRequestSuccess),
  switchMap(({ payload }) => getOfferCars(payload.client.uuid).pipe(
    map(OfferRequestAction.getOfferRequestsCarsSuccess),
    catchError((error: AjaxError) => of(OfferRequestAction.getOfferRequestsCarsFailure(error))),
  )),
)

const updateOfferRequestEpic: Epic<OfferRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof OfferRequestAction.updateOfferRequest>>(OfferRequestActionType.UpdateOfferRequest),
  switchMap(({ payload }) => updateOffer(payload).pipe(
    map(OfferRequestAction.updateOfferRequestSuccess),
    catchError((error: AjaxError) => of(OfferRequestAction.updateOfferRequestFailure(error))),
  )),
)

const updateOfferRequestSuccessEpic: Epic<OfferRequestAction> = action$ => action$.pipe(
  ofType(OfferRequestActionType.UpdateOfferRequestSuccess),
  tap(() => {
    history.push(Paths.OfferRequests)
  }),
  ignoreElements(),
)

export const offerRequestEpic = combineEpics(
  changeListParamsEpic,
  changeFilterOrSearchPhraseEpic,
  getOfferRequestsEpic,
  getOfferRequestEpic,
  getNewOfferRequestEpic,
  getOfferRequestCarsEpic,
  updateOfferRequestEpic,
  updateOfferRequestSuccessEpic,
)
