import { CarInsuranceAction, CarInsuranceActionType } from '../actions'
import { CarInsuranceState } from '../state'

const carInsuranceInitialState: CarInsuranceState = {
  data: null,
  loading: false,
}

// tslint:disable-next-line cyclomatic-complexity
export const carInsuranceReducer = (state: CarInsuranceState = carInsuranceInitialState, action: CarInsuranceAction): CarInsuranceState => {
  switch (action.type) {
    case CarInsuranceActionType.GetCarInsurance:
      return {
        data: null,
        loading: true,
      }

    case CarInsuranceActionType.GetCarInsuranceFailure:
      return {
        ...state,
        loading: false,
      }

    case CarInsuranceActionType.GetCarInsuranceSuccess:
      return {
        data: action.payload,
        loading: false,
      }
    case CarInsuranceActionType.UpdateCarInsurance:
      return {
        ...state,
        loading: true,
      }

    case CarInsuranceActionType.UpdateCarInsuranceFailure:
      return {
        data: null,
        loading: false,
      }

    case CarInsuranceActionType.UpdateCarInsuranceSuccess:
      return {
        data: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
