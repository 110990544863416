import styled from 'styled-components'
import { H2 } from 'styles'

export const ClientContainer = styled.div`
  position: relative;
`
export const H2Styled = styled(H2)`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-template-columns: auto;
  justify-content: left;
`

export const CheckboxContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  max-width: 95px;
  place-items: center;

  > div {
    margin: 0;
  }

  label {
    bottom: 12px;
  }
`
