import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
`

export const DetailedFiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 12px;
`

export const OpportunitySourceContainer = styled.div`
  grid-column: 1 / 3;
`

export const FilterContainer = styled.div`
  align-items: start;
  display: grid;
  grid-auto-flow: row;
  min-width: 230px;
`

export const FailureReasonFilterContainer = styled(FilterContainer)`
  grid-column: 2;
  grid-row: 3;
`

export const ResultFilterContainer = styled(FilterContainer)`
  grid-column: 1;
  grid-row: 3;
`

export const Checkboxes = styled.div`
  display: grid;
  margin-top: 10px;
`

export const CheckboxesTwoColumns = styled(Checkboxes)`
  grid-template-columns: 1fr 4fr;
`

export const CheckboxContainer = styled.div`
  margin-bottom: 5px;
`
