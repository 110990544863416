import { PagedResult } from 'actff-bo-lib/api'
import { Testable } from 'actff-bo-lib/global/testable'
import { TelephoneRequest } from 'actff-bo-lib/telephone-request'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { TelephoneRequestRow } from './TelephoneRequestRow'

type Props = Testable & {
  readonly telephoneRequests: PagedResult<TelephoneRequest>,
}

export const TelephoneRequestTable: React.FC<Props> = ({ telephoneRequests: { result }, testId }) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
          <Tr>
            <Th>{t('table.columns.data')}</Th>
            <Th>{t('table.columns.fullName')}</Th>
            <Th>{t('table.columns.type')}</Th>
            <Th>{t('table.columns.phone')}</Th>
            <Th>{t('table.columns.registrationNumber')}</Th>
            <Th>{t('table.columns.carName')}</Th>
            <Th>{t('table.columns.location')}</Th>
          </Tr>
        </thead>
        <tbody>
            {result.length && result.map((telephoneRequest, index) =>
              <TelephoneRequestRow key={telephoneRequest.uuid} telephoneRequest={telephoneRequest} testId={`${testId}request-${index}`} />,
            )}
          </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )
}
