import { Popover } from '@material-ui/core'
import { PersonType } from 'actff-bo-app/components/Client'
import { ContactInfoStyled } from 'actff-bo-app/components/Client/Styled'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { FieldValue } from 'actff-bo-app/components/Form'
import { Cell } from 'actff-bo-app/components/Grid'
import { Icon } from 'actff-bo-app/components/Icon'
import { Link } from 'actff-bo-app/components/Popover'
import { Counterparty, OwnershipType, SearchPersonSource } from 'actff-bo-lib/car/dto'
import { Client, formatPhoneNumber } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu/dto'
import i18next from 'i18next'
import { Map } from 'immutable'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { H2 } from 'styles'
import { Address } from './Address'
import { AddUserLinkButton } from './AddUserLinkButton'
import { SearchAppPerson, SearchCounterpartyPerson } from './SearchPerson'
import { Container, Content, Header, IconContainer, PopoverContentContainer, SectionHeader } from './Styled'

type Props = {
  readonly clearList: () => void,
  readonly onChangeToDriver?: (person: Client | Counterparty) => void,
  readonly onChangeToOwner: (person: Client | Counterparty) => void,
  readonly onDisconnectPerson: (person: Client | Counterparty) => void,
  readonly onConfirmSelectPerson: (person: Client | Counterparty) => void,
  readonly ownershipType: OwnershipType,
  readonly searchSource: SearchPersonSource,
  readonly person: Client | Counterparty,
  readonly testId: string,
}

type TaxNumberOrUserTypeProps = {
  readonly isAppDriver: boolean,
  readonly person: Client | Counterparty,
  readonly testId: string,
  readonly t: i18next.TFunction,
}

const headerByOwnershipTypeMap: Map<OwnershipType, string> = Map([
  [OwnershipType.DmsOwner, 'carView.basicData.header.dmsOwner'],
  [OwnershipType.AppDriver, 'carView.basicData.header.appUser'],
  [OwnershipType.DmsDriver, 'carView.basicData.header.dmsUser'],
]) as Map<OwnershipType, string>

const TaxNumberOrUserType: FC<TaxNumberOrUserTypeProps> = ({ isAppDriver, person, testId, t }) => (
  <>
    <label>{t(isAppDriver ? 'clientView.basicData.type' : t('taxNumber'))}:</label>
    {
      isAppDriver
      ? <PersonType person={person as Client} />
      : <FieldValue data-testid={`${testId}tax-number`}>{(person as Counterparty).nip}</FieldValue>
    }
  </>
)

export const PersonData: FC<Props> = ({
  clearList,
  onConfirmSelectPerson,
  onChangeToDriver,
  onChangeToOwner,
  onDisconnectPerson,
  ownershipType,
  searchSource,
  person,
  testId,
}) => {
  const { t } = useTranslation()
  const [appPersonMenuOpen, setAppPersonMenuOpen] = useState(false)
  const [searchAppPersonOpen, setSearchAppPersonOpen] = useState(false)
  const [confirmDisconnectPersonOpen, setConfirmDisconnectPersonOpen] = useState(false)
  const [confirmChangeToDriverOpen, setConfirmChangeToDriverOpen] = useState(false)
  const [confirmChangeToOwnerOpen, setConfirmChangeToOwnerOpen] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState<EventTarget & Element | null>(null)
  const isAppDriver = ownershipType === OwnershipType.AppDriver
  const isOwner = ownershipType === OwnershipType.DmsOwner

  const togglePersonMenuOpen = () => { setAppPersonMenuOpen(!appPersonMenuOpen) }
  const toggleConfirmDisconnectPersonOpen = () => { setConfirmDisconnectPersonOpen(!confirmDisconnectPersonOpen) }
  const toggleChangeToDriverOpen = () => { setConfirmChangeToDriverOpen(!confirmChangeToDriverOpen) }
  const toggleChangeToOwnerOpen = () => { setConfirmChangeToOwnerOpen(!confirmChangeToOwnerOpen) }

  const toggleSearchPersonOpen = () => {
    if (searchAppPersonOpen) {
      clearList()
    }

    setSearchAppPersonOpen(!searchAppPersonOpen)
  }

  const handleMenuIconClick = (event: React.MouseEvent) => {
    togglePersonMenuOpen()
    setPopoverAnchor(event.currentTarget)
  }

  const handleAddPersonClick = () => { toggleSearchPersonOpen() }

  const handleChangePersonClick = () => {
    togglePersonMenuOpen()
    toggleSearchPersonOpen()
  }

  const handleChangeToDriver = () => {
    toggleChangeToDriverOpen()
  }

  const handleChangeToOwner = () => {
    toggleChangeToOwnerOpen()
  }

  const handleConfirmChangeToOwner = () => {
    toggleChangeToOwnerOpen()
    togglePersonMenuOpen()
    if (onChangeToOwner) {
      onChangeToOwner(person)
    }
  }

  const handleConfirmChangeToDriver = () => {
    toggleChangeToDriverOpen()
    togglePersonMenuOpen()
    if (onChangeToDriver) {
      onChangeToDriver(person)
    }
  }

  const handleDisconnectPersonClick = () => {
    togglePersonMenuOpen()
    toggleConfirmDisconnectPersonOpen()
  }

  const handleConfirmDisconnectPerson = () => {
    toggleConfirmDisconnectPersonOpen()
    onDisconnectPerson(person)
  }

  const handleConfirmSelectPerson = (selectedPerson: Client | Counterparty) => {
    toggleSearchPersonOpen()
    onConfirmSelectPerson(selectedPerson)
  }

  const getSearchDialog = () => (
    searchSource === SearchPersonSource.Ndc ? (
      <SearchCounterpartyPerson
        header={
          ownershipType === OwnershipType.DmsOwner
            ? 'ownership.searchPerson.dmsOwner.header'
            : 'ownership.searchPerson.dmsDriver.header'
        }
        open={searchAppPersonOpen}
        onClose={toggleSearchPersonOpen}
        onConfirm={handleConfirmSelectPerson}
      />
    ) : (
      <SearchAppPerson
        open={searchAppPersonOpen}
        onClose={toggleSearchPersonOpen}
        onConfirm={handleConfirmSelectPerson}
      />
    )
  )

  const getChangeOwnershipTypeLink = (changeToOwner: () => void, changeToDriver: () => void) => {
    if (ownershipType === OwnershipType.AppDriver) {
      return null
    }

    return ownershipType === OwnershipType.DmsDriver
      ? (
        <Link onClick={changeToOwner}>
          <Icon type={IconType.Undo2}/>
          <span>{t('user.actions.changeToOwner')}</span>
        </Link>
      ) : (
        <Link onClick={changeToDriver}>
          <Icon type={IconType.Undo2}/>
          <span>{t('user.actions.changeToDriver')}</span>
        </Link>
      )
  }

  return (
    <Container isOwner={isOwner}>
      <Header>
        <SectionHeader>
          {t(headerByOwnershipTypeMap.get(ownershipType, 'user'))}
          {
            person && (
              <IconContainer onClick={handleMenuIconClick}>
                <Icon type={IconType.More} />
              </IconContainer>
            )
          }
        </SectionHeader>
        <AddUserLinkButton ownershipType={ownershipType} onClick={handleAddPersonClick} show={!person} />
      </Header>
      {
        person &&
          (
            <Content>
              <Cell>
                <div>
                  <div><H2>{(person as Counterparty).name || (`${(person as Client).firstName} ${(person as Client).lastName}`)}</H2></div>
                  <Popover
                    anchorOrigin={{
                      horizontal: 'right',
                      vertical: 'bottom',
                    }}
                    transformOrigin={{
                      horizontal: 'left',
                      vertical: 'top',
                    }}
                    onClose={togglePersonMenuOpen}
                    open={appPersonMenuOpen}
                    anchorEl={popoverAnchor}
                  >
                    <PopoverContentContainer>
                      <Link onClick={handleChangePersonClick}>
                        <Icon type={IconType.Refresh}/>
                        <span>{t('user.actions.changePerson')}</span>
                      </Link>
                      {getChangeOwnershipTypeLink(handleChangeToOwner, handleChangeToDriver)}
                      <Link onClick={handleDisconnectPersonClick}>
                        <Icon type={IconType.Trash2}/>
                        <span>{t('user.actions.disconnectPerson')}</span>
                      </Link>
                    </PopoverContentContainer>
                  </Popover>
                </div>
                <ContactInfoStyled>
                  <Icon type={IconType.Phone}/>
                  {formatPhoneNumber(person.phone)}
                </ContactInfoStyled>
              </Cell>
              <Cell>
                <TaxNumberOrUserType isAppDriver={isAppDriver} person={person} testId={testId} t={t} />
              </Cell>
              <Cell>
                <label>{t('user.userId')}:</label>
                <FieldValue data-testid={`${testId}user-id`}>{person.id}</FieldValue>
              </Cell>
              <Cell>
                <label>{t('email')}:</label>
                <FieldValue data-testid={`${testId}email`}>{person.email}</FieldValue>
              </Cell>
              <Cell>
                {<label>{t('address')}:</label>}
                <FieldValue data-testid={`${testId}address`}><Address address={(person as Counterparty).address} /></FieldValue>
              </Cell>
            </Content>
          )
      }

      {getSearchDialog()}

      <Dialog
        content='confirmDialog.ownership.disconnectPerson.content'
        open={confirmDisconnectPersonOpen}
        onConfirm={handleConfirmDisconnectPerson}
        onClose={toggleConfirmDisconnectPersonOpen}
        title='confirmDialog.ownership.disconnectPerson.title'
      />
      <Dialog
        content='confirmDialog.ownership.changeToOwner.content'
        open={confirmChangeToOwnerOpen}
        onConfirm={handleConfirmChangeToOwner}
        onClose={toggleChangeToOwnerOpen}
        title='confirmDialog.ownership.changeToOwner.title'
      />
      <Dialog
        content='confirmDialog.ownership.changeToDriver.content'
        open={confirmChangeToDriverOpen}
        onConfirm={handleConfirmChangeToDriver}
        onClose={toggleChangeToDriverOpen}
        title='confirmDialog.ownership.changeToDriver.title'
      />
    </Container>
  )
}
