import { ValueForInsuranceCalculation } from 'actff-bo-lib/car'

export type InsurancePolicy = {
  readonly type: OpportunityInsuranceType,
  readonly number: string,
  readonly insurer: string,
  readonly issued: Date,
  readonly starts: Date,
  readonly expires: Date,
  readonly marketValue: string,
  readonly calculationBase: ValueForInsuranceCalculation,
  readonly grossPremium: string,
  readonly insuranceValue: string,
  readonly sellType: OpportunityInsuranceSellTypes,
}

export type NewPolicy = InsurancePolicy & {
  readonly paymentType: OpportunityInsurancePaymentTypes,
  readonly paymentMethod: OpportunityInsurancePaymentMethods,
  readonly installmentCount: number,
  readonly installments: ReadonlyArray<PolicyInstallment>,
}

export type PolicyInstallment = {
  readonly amount: string,
  readonly dueDate: Date,
  readonly contactDate: Date,
}

export enum OpportunityInsuranceType {
  PACKAGE = 'PACKAGE',
  OC = 'OC',
  OC_SHORT_TERM = 'OC_SHORT_TERM',
  AC = 'AC',
  GAP = 'GAP',
  AUTOSZYBA = 'AUTOSZYBA',
  EXTENDED_WARRANTY = 'EXTENDED_WARRANTY',
}

export enum OpportunityInsuranceSellTypes {
  NEW = 'NEW',
  NEW_SELECT = 'NEW_SELECT',
  RENEWAL_1 = 'RENEWAL_1',
  RENEWAL_2 = 'RENEWAL_2',
  RENEWAL_3 = 'RENEWAL_3',
  RENEWAL_4 = 'RENEWAL_4',
  RENEWAL_5 = 'RENEWAL_5',
  RENEWAL_6 = 'RENEWAL_6',
  RENEWAL_7 = 'RENEWAL_7',
  RENEWAL_8 = 'RENEWAL_8',
  RENEWAL_9 = 'RENEWAL_9',
  RENEWAL_10 = 'RENEWAL_10',
  RENEWAL_10PLUS = 'RENEWAL_10PLUS',
  OC_SHORT_TERM = 'OC_SHORT_TERM',
  EXTENDED_WARRANTY = 'EXTENDED_WARRANTY',
  GAP = 'GAP',
  AUTOSZYBA = 'AUTOSZYBA',
  NEW_USED_CARS = 'NEW_USED_CARS',
  INITIAL_OFFER = 'INITIAL_OFFER',
}

export enum OpportunityInsurancePaymentMethods {
  CASH = 'CASH',
  TRANSFER = 'TRANSFER',
}

export enum OpportunityInsurancePaymentTypes {
  ONE_TIME = 'ONE_TIME',
  INSTALLMENT = 'INSTALLMENT',
}

export enum OpportunityInsuranceSource {
  DEALER = 'DEALER',
  NOT_DEALER = 'NOT_DEALER',
}

export const OpportunityInsurerOther = 'OTHER'

export const AutoSelectedInsuranceSellTypes: ReadonlyArray<OpportunityInsuranceSellTypes> =
  [
    OpportunityInsuranceSellTypes.OC_SHORT_TERM,
    OpportunityInsuranceSellTypes.EXTENDED_WARRANTY,
    OpportunityInsuranceSellTypes.GAP,
    OpportunityInsuranceSellTypes.AUTOSZYBA,
  ]
