import { Popover } from '@material-ui/core'
import { BtnType } from 'actff-bo-app/components/Button'
import { ExpandCollapse } from 'actff-bo-app/components/ExpandCollapse'
import { PopoverContentContainer } from 'actff-bo-app/components/Popover'
import { Li, OpportunityStatusButton } from 'actff-bo-app/Crm/Styled'
import { OpportunityPurchaseStatusType, OpportunitySaleStatusType } from 'actff-bo-lib/crm/trade'
import { getTranslation } from 'actff-bo-lib/i18n'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OpportunityStatusContainer } from './Styled'

type StatusType = OpportunityPurchaseStatusType | OpportunitySaleStatusType

type Props = {
  readonly currentStatus: StatusType,
  readonly onChange: (type: StatusType) => void,
  readonly statuses: ReadonlyArray<StatusType>,
}

export const OpportunityStatus: FC<Props> = ({ currentStatus, onChange, statuses }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleStatusButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const handleLiClick = (type: StatusType) => () => {
    handleClose()
    onChange(type)
  }

  return (
    <OpportunityStatusContainer>
      <label>{t('opportunity.status')}:</label>
      <div>
        <OpportunityStatusButton onClick={handleStatusButtonClick} type={BtnType.Button}>
          {getTranslation(t, 'crmTrade.status.', currentStatus)}
          <ExpandCollapse expanded={open} />
        </OpportunityStatusButton>
      </div>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <PopoverContentContainer>
          <ul>
            {statuses.map(status => (
              <Li key={status} onClick={handleLiClick(status)}>{getTranslation(t, 'crmTrade.status.', status)}</Li>
            ))}
          </ul>
        </PopoverContentContainer>
      </Popover>
    </OpportunityStatusContainer>
  )
}
