import styled from 'styled-components'
import { colors } from 'styles'

type ContainerProps = {
  readonly maxWidth: number,
}

export const Container = styled.div<ContainerProps>`
  max-width: ${({ maxWidth }: ContainerProps) => maxWidth ? `${maxWidth}px` : '330px'};
`

export const customStyles = () => ({
  control: (base: object) => ({
    ...base,
    backgroundColor: colors.athensGray,
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: (base: object) => ({
    ...base,
    backgroundColor: colors.athensGray,
  }),
  singleValue: (base: object) => ({
    ...base,
    color: 'black',
  }),
})
