import { ListFilters } from 'actff-bo-app/components/ListFilters/ListFilters'
import { ListLocationsFiltersForm } from 'actff-bo-app/components/ListFilters/ListLocationsFilters'
import { Loader } from 'actff-bo-app/components/Loader'
import { Search } from 'actff-bo-app/components/Search'
import { NoItems, WithPagination } from 'actff-bo-app/components/Table'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { IconType, Path, Paths } from 'actff-bo-lib/menu'
import { NdcCarAction } from 'actff-bo-lib/ndc-car/actions'
import {
  selectIsLoadingNdcCars,
  selectNdcCars,
  selectNdcCarsCurrentPage,
  selectNdcCarsDealerLocationFilters,
  selectNdcCarsSearchPhrase,
} from 'actff-bo-lib/ndc-car/selectors'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { H2, Header } from 'styles'

import { NdcCarTable } from './NdcCarTable'

const NdcCarsHeaderContainer = styled(Header)`
  display: grid;
  grid-auto-flow: column;
  justify-items: stretch;
`

const NdcCarsHeader = styled(H2)`
  grid-area: unset;
`

const NdcSearchContainer = styled.div`
  align-self: center;
  justify-self: right;
`

const NdcCarsFiltersContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto 1fr;
  align-items: center;
`

const testId = 'ndc-car-list__'

export const NdcCars = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const currentPage = useSelector(selectNdcCarsCurrentPage)
  const ndcCars = useSelector(selectNdcCars)
  const loading = useSelector(selectIsLoadingNdcCars)
  const searchPhrase = useSelector(selectNdcCarsSearchPhrase)
  const dealerLocationFilters = useSelector(selectNdcCarsDealerLocationFilters)

  const onPageChange = useMemo(() => (nextPage: number) => { dispatch(NdcCarAction.changePage(nextPage)) }, [dispatch])
  const onSearchPhraseChange = useMemo(() => (nextSearchPhrase: string) => {
    dispatch(NdcCarAction.changeSearchPhrase(nextSearchPhrase))
  }, [dispatch])
  const onDealerLocationChange = useMemo(() => (locations: ReadonlyArray<DealerLocationKey>) => {
    dispatch(NdcCarAction.changeDealerLocations(locations))
  }, [dispatch])
  const getNdcCars = useMemo(() => () => { dispatch(NdcCarAction.getNdcCars()) }, [dispatch])

  useEffect(() => {
    getNdcCars()
  }, [getNdcCars])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <NdcCarsHeaderContainer>
        <NdcCarsFiltersContainer>
          <NdcCarsHeader>{t('ndcCars.header')}</NdcCarsHeader>
          <ListFilters
            filtersCaption={t('caption.location')}
            filtersComponent={filterRendererProps => <ListLocationsFiltersForm {...filterRendererProps} isNdc={true} />}
            filterValues={dealerLocationFilters}
            onSubmit={onDealerLocationChange}
          />
        </NdcCarsFiltersContainer>
        <NdcSearchContainer>
          <Search initialValues={{ searchPhrase }} onSearch={onSearchPhraseChange} />
        </NdcSearchContainer>
      </NdcCarsHeaderContainer>
      {ndcCars?.result.length
        ? (
          <WithPagination
            currentPage={currentPage}
            onPageChange={onPageChange}
            pageCount={ndcCars.noOfPages}
            path={Paths.NdcCars as Path}
            testId={testId}
            rowsInCurrentPage={ndcCars.result.length}
            withMarginPages={false}
          >
            <NdcCarTable ndcCars={ndcCars} />
          </WithPagination>
        )
        : <NoItems icon={IconType.NoCars} text={t('carList.noCars')} />
      }
    </>
  )
}
