import { BtnType } from 'actff-bo-app/components/Button'
import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu/dto'
import Lightbox from 'lightbox-react'
import * as React from 'react'
import Cropper from 'react-easy-crop'
import { useTranslation } from 'react-i18next'
import { OrangeButton } from 'styles'

import { ButtonsPanel, CropperWrapper, ImageWrapper, SettingButton, SettingsButtons } from './Styled'

type Props = {
  readonly image: string,
}

const zoomStep = 0.2
const minZoom = 1
const maxZoom = 3

export const ZoomImage: React.FC<Props> = ({ image }) => {
  const [ showLarge, setShowLarge ] = React.useState(false)
  const [ zoom, setZoom ] = React.useState(minZoom)
  const [ crop, setCrop ] = React.useState({ x: 0, y: 0 })
  const [ rotation, setRotation ] = React.useState(0)
  const { t } = useTranslation()

  const rotate = () => {
    // tslint:disable no-magic-numbers
    const nextRotation = rotation - 90
    setRotation(nextRotation < -360 ? 0 : nextRotation)
  }

  const zoomIn = () => {
    const increasedZoom = zoom + zoomStep
    setZoom(increasedZoom > maxZoom ? zoom : increasedZoom)
  }

  const zoomOut = () => {
    const decreasedZoom = zoom - zoomStep
    setZoom(decreasedZoom < minZoom ? zoom : decreasedZoom)
  }

  const toggleShowLarge = () => {
    setShowLarge(!showLarge)
  }

  React.useEffect(() => {
    setZoom(minZoom)
  }, [image])

  return (
    <>
      <ImageWrapper>

        <CropperWrapper>
          <Cropper
            crop={crop}
            image={image}
            onCropChange={setCrop}
            rotation={rotation}
            showGrid={false}
            style={{ cropAreaStyle: { border: 'none', boxShadow: 'none' } }}
            zoom={zoom}
          />
        </CropperWrapper>

        <SettingsButtons>
          <SettingButton
            onClick={rotate}
            type={BtnType.Button}
          >
            <Icon type={IconType.Rotate} />
          </SettingButton>
          <SettingButton disabled={zoom === maxZoom} onClick={zoomIn} type={BtnType.Button}>+</SettingButton>
          <SettingButton disabled={zoom === minZoom} onClick={zoomOut} type={BtnType.Button}>-</SettingButton>
        </SettingsButtons>

        <ButtonsPanel>
          <OrangeButton onClick={toggleShowLarge} type={BtnType.Button}>{t('caption.photoPreview')}</OrangeButton>
        </ButtonsPanel>

      </ImageWrapper>

      {showLarge && <Lightbox mainSrc={image} onCloseRequest={toggleShowLarge} />}
    </>
  )
}
