import { AttachmentAction, AttachmentActionType } from './actions'
import { Attachment, AttachmentUploadResultType, AttachmentUploadType, BlobUrl, IdentifiedUrl, Url } from './dto'

export type AttachmentState = {
  readonly attachmentThumbnails: Map<Url, BlobUrl>,
  readonly attachments: Map<Url, BlobUrl>,

  readonly upload: {
    readonly props: any, // tslint:disable-line no-any
    readonly toUpload: ReadonlyArray<Attachment>,
    readonly uploaded: ReadonlyArray<Url | IdentifiedUrl | AttachmentUploadResultType>,
    readonly type: AttachmentUploadType,
  },
}

const initialState: AttachmentState = {
  attachmentThumbnails: new Map(),
  attachments: new Map(),
  upload: {
    props: null,
    toUpload: [],
    type: AttachmentUploadType.Inspection,
    uploaded: [],
  },
}

// tslint:disable-next-line cyclomatic-complexity
export const attachmentReducer = (state: AttachmentState = initialState, action: AttachmentAction): AttachmentState => {
  switch (action.type) {
    case AttachmentActionType.GetAttachmentThumbnailSuccess:
      return {
        ...state,
        attachmentThumbnails: new Map(state.attachmentThumbnails).set(action.payload.url, action.payload.blob),
      }

    case AttachmentActionType.GetAttachmentThumbnailFailure:
      return {
        ...state,
        attachmentThumbnails: new Map(state.attachmentThumbnails).set(action.payload.url, action.payload.error.message as BlobUrl),
      }

    case AttachmentActionType.GetAttachmentSuccess:
      return {
        ...state,
        attachments: new Map(state.attachments).set(action.payload.url, action.payload.blob),
      }

    case AttachmentActionType.SendAttachments:
      const { attachments, type, props } = action.payload

      return {
        ...state,
        upload: {
          props,
          toUpload: [...attachments],
          type: type as AttachmentUploadType,
          uploaded: [],
        },
      }

    case AttachmentActionType.SendSingleAttachmentSuccess:
      return {
        ...state,
        upload: {
          ...state.upload,
          uploaded: [...state.upload.uploaded, action.payload],
        },
      }

    case AttachmentActionType.SendSingleAttachmentFailure:
      return {
        ...state,
        upload: {
          ...state.upload,
          uploaded: [...state.upload.uploaded, AttachmentUploadResultType.Failure],
        },
      }

    case AttachmentActionType.SendAttachmentsComplete:
    case AttachmentActionType.SendAttachmentsFailure:
      return {
        ...state,
        upload: {
          ...initialState.upload,
        },
      }

    default:
      return state
  }
}
