import { Testable } from 'actff-bo-lib/global/testable'
import { FieldProps } from 'formik'
import * as React from 'react'
import styled from 'styled-components/macro'

import { FormCheckbox } from './FormStyled'

type ReactCheckboxFieldProps = Testable & {
  readonly disabled?: boolean,
  readonly id: string,
  readonly label: string,
  readonly labelTruncated?: boolean,
  readonly labelWidth?: string,
  readonly onChange?: () => void,
  readonly slim?: boolean,
  readonly value?: boolean,
}

type LabelStyledProps = {
  readonly truncated?: boolean,
  readonly width?: string,
}

const LabelStyled = styled.label<LabelStyledProps>`
  ${({ truncated, width }: LabelStyledProps) => truncated && `
    white-space: nowrap;
    text-overflow: ellipsis;
    width: ${width && `${width}px` || 'auto'};
    overflow: hidden;
  `}
`

export const CheckboxInputField: React.FC<HTMLElement & ReactCheckboxFieldProps & FieldProps> = ({
  disabled = false,
  field: {name, value: fieldValue, onBlur },
  form,
  id,
  label,
  labelTruncated,
  labelWidth,
  onChange,
  slim = false,
  value,
  testId,
}) => {
  // tslint:disable-next-line:cyclomatic-complexity
  const handleOnChange = () => {
    if (value !== undefined && value === fieldValue) {
      form.setFieldValue(name, null)

      return
    }

    if (value !== undefined && fieldValue === null) {
      form.setFieldValue(name, value)

      return
    }

    form.setFieldValue(name, !fieldValue)
    onChange && onChange()
  }

  const getCheckedValue = () => {
    if (fieldValue === null) {
      return false
    }

    if (value === undefined) {
      return fieldValue
    }

    return value === fieldValue
  }

  return (
    <FormCheckbox slim={slim}>
      <input
        disabled={disabled}
        id={id}
        checked={getCheckedValue()}
        name={name}
        type='checkbox'
        onChange={handleOnChange}
        onBlur={onBlur}
        {...(testId && { 'data-testid': `${testId}` })}
      />
      <LabelStyled
        truncated={labelTruncated}
        width={labelWidth}
        htmlFor={id}
        {...(testId && { 'data-testid': `${testId}-label` })}
      >
        {label}
      </LabelStyled>
    </FormCheckbox>
  )

}
