import { NominalString } from 'actff-bo-lib/global'

export enum OpportunityPaymentsFor {
  ALLIANZ = 'ALLIANZ',
  CONSULTANT = 'CONSULTANT',
  PZU_EVEREST = 'PZU_EVEREST',
  GAP = 'GAP',
}

export type InsurerName = NominalString<Insurer>

export type Insurer = {
  readonly label: InsurerName,
  readonly value: InsurerName,
}

export type BankAccount = NominalString<string>

export type PaymentsInstallment = {
  readonly amount: string,
  readonly date: Date,
}

export type OpportunityPayments = {
  readonly receiver: string,
  readonly bankAccount: string,
  readonly installments: ReadonlyArray<PaymentsInstallment>,
}

export type OpportunityPaymentsConfirmationCommissions = {
  readonly value: string,
  readonly correction: string,
  readonly valueAfterCorrection: string,
}

export type OpportunityPaymentsConfirmation = {
  readonly policyConfirmed: boolean,
  readonly commissions: ReadonlyArray<OpportunityPaymentsConfirmationCommissions>,
}
