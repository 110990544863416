import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { objectDiff } from 'actff-bo-lib/global'
import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

import { OpportunityService } from './dto'

const selectCrmServiceFeature = (state: State) => state.crmService

export const selectOpportunityList = (type: OpportunityListType) =>
  createSelector(selectCrmServiceFeature, opportunity => opportunity.list[type])
export const selectOpportunityListCurrentPage = (type: OpportunityListType) =>
  createSelector(selectOpportunityList(type), list => list.currentPage)
export const selectOpportunityListTimeFrom = (type: OpportunityListType) => createSelector(selectOpportunityList(type), list => list.from)
export const selectOpportunityListTimeTo = (type: OpportunityListType) => createSelector(selectOpportunityList(type), list => list.to)
export const selectOpportunityListData = (type: OpportunityListType) =>
  createSelector(selectOpportunityList(type), list => list.list.data)
export const selectOpportunityListLoading = (type: OpportunityListType) =>
  createSelector(selectOpportunityList(type), list => list.list.loading)

export const selectCurrentOpportunity =
  createSelector(selectCrmServiceFeature, crmService => crmService.opportunity.currentOpportunity)

export const selectCurrentLead = createSelector(selectCrmServiceFeature, crmService => crmService.lead.data)

export const selectIsLoadingLead = createSelector(selectCrmServiceFeature, crmService => crmService.lead.loading)

export const selectDraftOpportunity = createSelector(selectCrmServiceFeature, crmService => crmService.opportunity.draftOpportunity)

export const selectDraftOpportunityDiffAndId =
  createSelector(selectCurrentOpportunity, selectDraftOpportunity, (current, draft) =>
  current && draft
    ? ({
      ...objectDiff<OpportunityService>(current, draft),
      uuid: current.uuid,
    })
    : {},
)

export const selectOpportunitySearchPhrase = createSelector(selectCrmServiceFeature, crmService => crmService.filters.searchPhrase)

export const selectOpportunityListsFilters = createSelector(selectCrmServiceFeature, crmService => crmService.filters.filters)

export const selectOpportunityListAssignmentFilter =
  createSelector(selectCrmServiceFeature, crmService => crmService.filters.assignmentFilter)

export const selectOpportunityListDealerLocationsFilters =
  createSelector(selectCrmServiceFeature, crmService => crmService.filters.locationFilters)

export const selectOpportunityListBrandFilters =
  createSelector(selectCrmServiceFeature, crmService => crmService.filters.brandFilters)
