import { QuickCampaignSuccessParams } from 'actff-bo-lib/campaign'
import { ToastBodyProps } from 'actff-bo-lib/toast'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const QuickCampaignToastSuccess: FC<ToastBodyProps<QuickCampaignSuccessParams>> = ({ params }) => {
  const { t } = useTranslation()

  return (
    <>
      {t('campaign.quickCampaign.toast.success', { count: params?.count, content: params?.content })}.
    </>
  )
}
