import { Paper, Popover } from '@material-ui/core'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Icon } from 'actff-bo-app/components/Icon'
import { OpportunitySale } from 'actff-bo-lib/crm/trade'
import { getTranslation } from 'actff-bo-lib/i18n'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

const StatusContent = styled.div`
  display: grid;
  grid-auto-flow: row;
`

const StatusDate = styled.div`
  color: ${colors.dark};
  font-weight: 300;
`

const StatusIcon = styled(Icon)`
  padding-left: 6px;
  position: relative;
  top: 6px;
`

const PopoverContent = styled.div`
  padding: 12px 14px;
  max-width: 440px;
`

const Separator = styled.hr`
  background-color: ${colors.mysticGray};
  border: none;
  clear: both;
  height: 1px;
  width: 100%;
`

export const OpportunityRowStatus: FC<OpportunitySale> = ({ comments, actionDate, status }) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)
  const open = Boolean(popoverAnchor)
  const { t } = useTranslation()

  const handleIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
    event.stopPropagation()
    event.preventDefault()
  }

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setPopoverAnchor(null)
  }

  const lastComment = comments[comments.length - 1]?.comment

  return (
    <>
      <StatusContent>
        <span>
          {getTranslation(t, 'crmTrade.status.', status)}
          {lastComment && <StatusIcon onClick={handleIconClick} type={IconType.Comment} />}
        </span>
        <StatusDate><FormattedDate date={actionDate} /></StatusDate>
      </StatusContent>

      <Popover
        anchorEl={popoverAnchor}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <Paper>
          {lastComment && (
            <PopoverContent>
              <b>{t('opportunity.comments.last')}:</b><br />
              {lastComment}
              <Separator />
              {t('opportunity.comments.rest')}: <b>{comments.length - 1}</b>
            </PopoverContent>
          )}
        </Paper>
      </Popover>
    </>
  )
}
