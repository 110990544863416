import { Icon } from 'actff-bo-app/components/Icon'
import styled from 'styled-components'

export const Notifications = styled.div`
  font-size: 10px;
  letter-spacing: .05em;
  line-height: 15px;
`

export const ContactInfoStyled = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-area: filters;
  grid-template-columns: repeat(6, fit-content(100%));
`

export const IconStyled = styled(Icon)`
  cursor: pointer;
`

export const ClientContainer = styled.div`
  position: relative;
`
