import { FormCell, FormHeader } from 'actff-bo-app/components/Form'
import { OpportunityCarData } from 'actff-bo-lib/crm/service'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly car: OpportunityCarData,
}

const CarHeader = styled(FormHeader)`
  font-weight: 300;
  grid-area: car-header;
  padding: 24px 32px;
`

const CarDataFirst = styled(FormCell)`
  grid-area: car-data-first;
`

const CarDataSecond = styled(FormCell)`
  grid-area: car-data-second;
`

export const OpportunityViewCar: React.FC<Props> = ({ car }) => {
  const { t } = useTranslation()

  return (
    <>
      <CarHeader>{t('opportunity.carData.title')}:</CarHeader>
      <CarDataFirst>
        <label>{t('opportunity.carData.registrationNumber')}:</label>
        <span>{car.registrationNumber}</span>
      </CarDataFirst>
      <CarDataSecond>
        <label>{t('opportunity.carData.vin')}:</label>
        <span>{car.vin}</span>
      </CarDataSecond>
    </>
  )
}
