import { Pagination } from 'actff-bo-app/components/Table/Pagination'
import NoPhoto from 'assets/no-photo.svg'
import * as React from 'react'

import { ImagesWrapper, PaginationWrapper } from './Styled'
import { ZoomImage } from './ZoomImage'

type Props = {
  readonly images: ReadonlyArray<string>,
}

type State = {
  readonly currentImage: number,
}

export class ImageCarousel extends React.Component<Props, State> {

  public readonly state: State = {
    currentImage: 0,
  }

  public render(): React.ReactNode {
    const { images } = this.props
    const { currentImage } = this.state

    return (
      <ImagesWrapper>
        <ZoomImage image={images.length > 0 ? images[currentImage] : NoPhoto} />

        <PaginationWrapper>
          <Pagination
            currentPage={currentImage + 1}
            onPageChange={this.onPageChange}
            pageCount={images.length}
            rowsInCurrentPage={images.length}
            showSummary={false}
          />
        </PaginationWrapper>
      </ImagesWrapper>
    )
  }

  private readonly onPageChange = (page: number) => {
    this.setState({ currentImage: page - 1 })
  }
}
