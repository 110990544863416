import { Container, Header } from 'actff-bo-app/Admin/Styled'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { addHoliday, deleteHoliday, getHolidays } from 'actff-bo-lib/calendar/dao'
import { Holiday } from 'actff-bo-lib/calendar/dto'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import sortBy from 'lodash/sortBy'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { H2 } from 'styles'

import { ViewTabs } from '../ViewTabs'
import { HolidaysTable } from './HolidaysTable'
import { YearPicker } from './YearPicker'

const HeaderStyled = styled(Header)`
  display: flex;
  align-items: center;
`

const testId = 'admin-view-dealership--insurers-questions__'

const defaultYear = new Date().getFullYear()

const sortHolidaysByDate = (holidays: ReadonlyArray<Holiday>) => {
  const mappedHolidays = holidays.map(item => ({
    ...item,
    date: parseToDateOrNull(item.date),
  }))

  return sortBy(mappedHolidays, ['date'])
}

const HolidaysComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [currentYear, setCurrentYear] = useState(defaultYear)

  const { data: holidays, isLoading, isError, refetch } = useQuery(QueryKeys.GET_HOLIDAYS, async () =>
      await getHolidays(currentYear),
    { retry: false, refetchOnWindowFocus: false },
  )

  useEffect(() => { refetch() }, [currentYear])

  const addHolidayMutation = useMutation(
    QueryKeys.ADD_HOLIDAY,
    addHoliday,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.dealership.addHoliday.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch()
        dispatch(displaySuccessToast('admin.dealership.addHoliday.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const deleteHolidayMutation = useMutation(
    QueryKeys.DELETE_HOLIDAY,
    deleteHoliday,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.dealership.deleteHoliday.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch()
        dispatch(displaySuccessToast('admin.dealership.deleteHoliday.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const handleAdd = async (holiday: Holiday) => {
    addHolidayMutation.mutate(holiday)
  }

  const handleDelete = async (holiday: Holiday) => {
    deleteHolidayMutation.mutate(holiday.id)
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <LocalLoader />
      </>
    )
  }

  if (!holidays) {
    return (
      <>
        <ViewTabs testId={testId} />
        <NoData>{t('caption.noData')}</NoData>
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('api.action.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <HeaderStyled>
          <H2>{t('admin.dealership.holidays.subheader')}</H2>
          <YearPicker onYearChange={setCurrentYear} year={currentYear}/>
        </HeaderStyled>
        <HolidaysTable holidays={sortHolidaysByDate(holidays)} onAdd={handleAdd} onDelete={handleDelete} />
      </Container>
    </>
  )
}

export const Holidays = withAdminViewHeader(HolidaysComponent)
