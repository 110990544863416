import { ToastBodyComponentType } from './body-component-type'

export enum ToastType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Default = 'default',
}

export type ToastBodyProps<T = any> = { // tslint:disable-line
  readonly params?: T,
}

export type Toast = {
  readonly id: string,
  readonly title: string,
  readonly type: ToastType,
  readonly autoClose?: number | false,
  readonly body?: string | ToastBodyComponentType,
  readonly bodyProps?: ToastBodyProps,
}
