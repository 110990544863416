import { Filters } from 'actff-bo-app/components/Filters'
import { HeaderLeft } from 'actff-bo-app/components/Grid'
import { ListBrandFiltersForm } from 'actff-bo-app/components/ListFilters/ListBrandFilters'
import { ListFilters } from 'actff-bo-app/components/ListFilters/ListFilters'
import { ListLocationsFiltersForm } from 'actff-bo-app/components/ListFilters/ListLocationsFilters'
import { Loader } from 'actff-bo-app/components/Loader'
import { Search } from 'actff-bo-app/components/Search'
import { NoItems, WithPagination } from 'actff-bo-app/components/Table'
import { PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { Filters as FiltersType } from 'actff-bo-lib/global'
import { IconType, Path, Paths } from 'actff-bo-lib/menu'
import {
  OfferRequest,
  OfferRequestAction,
  OfferRequestFilterValues,
  selectIsLoadingOfferRequests,
  selectNewOfferRequestCount,
  selectOfferRequestBrandFilters,
  selectOfferRequestDealerLocationFilters,
  selectOfferRequestListFilters,
  selectOfferRequests,
  selectOfferRequestsCurrentPage,
  selectOfferRequestSearchPhrase,
} from 'actff-bo-lib/offer-request'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { H2, HeaderFiltersContainer, HeaderList, HeaderSearch, Small } from 'styles'

import { OfferRequestTable } from './OfferRequestTable'

type StateToProps = {
  readonly brandFilters: ReadonlyArray<string>,
  readonly currentPage: number,
  readonly dealerLocationFilters: ReadonlyArray<DealerLocationKey>,
  readonly loading: boolean,
  readonly newOfferRequestCount: number,
  readonly offerRequestListFilters: FiltersType<OfferRequestFilterValues>,
  readonly offerRequests: PagedResult<OfferRequest> | null,
  readonly searchPhrase?: string,
}

type DispatchToProps = {
  readonly getNewOfferRequestCount: () => void,
  readonly getOfferRequests: () => void,
  readonly onBrandChange: (brands: ReadonlyArray<string>) => void,
  readonly onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => void,
  readonly onListFilterChange: (param: string, value: OfferRequestFilterValues) => void,
  readonly onPageChange: (nextPage: number) => void,
  readonly onSearchPhraseChange: (searchPhrase?: string) => void,
}

type OfferRequestsListProps = StateToProps & DispatchToProps & WithTranslation

const testId = 'offer-request-list__'

class OfferRequestListComponent extends React.Component<OfferRequestsListProps> {
  public componentDidMount(): void {
    this.props.getOfferRequests()
    this.props.getNewOfferRequestCount()
  }

  public render(): React.ReactNode {
    const {
      brandFilters,
      currentPage,
      dealerLocationFilters,
      newOfferRequestCount,
      loading,
      onPageChange,
      offerRequests,
      offerRequestListFilters,
      onBrandChange,
      onDealerLocationChange,
      onListFilterChange,
      onSearchPhraseChange,
      searchPhrase,
      t,
    } = this.props

    if (loading) {
      return <Loader />
    }

    return offerRequests
      && (
        <>
          <HeaderList>
            <H2 data-testid={`${testId}header`}>{t('offers.header')}</H2>
            <Small>{t('telephoneRequest.newRequests', { count: newOfferRequestCount })}</Small>
            <HeaderFiltersContainer>
              <Filters filters={offerRequestListFilters} onFilterChange={onListFilterChange} testId={testId}/>
              <HeaderLeft>
                <ListFilters
                  filtersCaption={t('caption.location')}
                  filtersComponent={filterRendererProps => <ListLocationsFiltersForm {...filterRendererProps} />}
                  filterValues={dealerLocationFilters}
                  onSubmit={onDealerLocationChange}
                />
                <ListFilters
                  filtersCaption={t('caption.brand')}
                  filtersComponent={filterRendererProps => <ListBrandFiltersForm {...filterRendererProps} />}
                  filterValues={brandFilters}
                  onSubmit={onBrandChange}
                />
              </HeaderLeft>
              <HeaderSearch>
                <Search
                  initialValues={{ searchPhrase }}
                  onSearch={onSearchPhraseChange}
                  testId={testId}
                />
              </HeaderSearch>
            </HeaderFiltersContainer>
          </HeaderList>
          {
            offerRequests.result.length ? (
              <WithPagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageCount={offerRequests.noOfPages}
                path={Paths.OfferRequests as Path}
                rowsInCurrentPage={offerRequests.result.length}
                testId={testId}
              >
                <OfferRequestTable offerRequests={offerRequests} testId={testId} />
              </WithPagination>
            ) : <NoItems icon={IconType.NoCars} text='brak' />
          }
        </>
      )
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = (state: State) => ({
  brandFilters: selectOfferRequestBrandFilters(state),
  currentPage: selectOfferRequestsCurrentPage(state),
  dealerLocationFilters: selectOfferRequestDealerLocationFilters(state),
  loading: selectIsLoadingOfferRequests(state),
  newOfferRequestCount: selectNewOfferRequestCount(state),
  offerRequestListFilters: selectOfferRequestListFilters(state),
  offerRequests: selectOfferRequests(state),
  searchPhrase: selectOfferRequestSearchPhrase(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = (dispatch: Dispatch) => ({
  getNewOfferRequestCount: () => { dispatch(OfferRequestAction.getNewOfferRequestCount()) },
  getOfferRequests: () => { dispatch(OfferRequestAction.getOfferRequests()) },
  onBrandChange: (brands: ReadonlyArray<string>) => { dispatch(OfferRequestAction.changeBrands(brands)) },
  onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => {
    dispatch(OfferRequestAction.changeDealerLocations(locations))
  },
  onListFilterChange: (param: string, value: OfferRequestFilterValues) => { dispatch(OfferRequestAction.changeListFilter(param, value)) },
  onPageChange: (nextPage: number) => { dispatch(OfferRequestAction.changePage(nextPage)) },
  onSearchPhraseChange: (searchPhrase: string) => { dispatch(OfferRequestAction.changeSearchPhrase(searchPhrase)) },
})

export const OfferRequestList = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(OfferRequestListComponent)
