import { axiosApi } from 'actff-bo-lib/api'
import { buildUrl } from 'actff-bo-lib/global'
import { CarAssistance, CarLiquids, CarVersionMutationParams } from './dto'

export const getCarModels = (brands: ReadonlyArray<string | null>) =>
  axiosApi.get<ReadonlyArray<string>>(`/dealer-settings/car-model${brands ? `?brand=${brands.toString()}` : ''}`)

export const getCarVersions = (brands: ReadonlyArray<string | null>) =>
  axiosApi.get<ReadonlyArray<string>>(`/dealer-settings/car-version${brands ? `?brand=${brands.toString()}` : ''}`)

export const addCarModel = (brandName: string | null, modelName: string) =>
  axiosApi.post('/dealer-settings/car-model', { brandName, modelName })

export const addCarVersion = ({ brand, versionName }: CarVersionMutationParams) =>
  axiosApi.post(`/dealer-settings/car-version/${brand}`, { versionName })

export const deleteCarModel = (brandName: string | null, modelName: string | null) =>
  axiosApi.delete(`/dealer-settings/car-model/${brandName}/${modelName}`)

export const deleteCarVersion = ({ brand, versionName }: CarVersionMutationParams) =>
  axiosApi.delete<string, string>(`/dealer-settings/car-version/${brand}/${versionName}`)

export const getBrakeLiquids = (brands: ReadonlyArray<string | null>) =>
  axiosApi.get<ReadonlyArray<string>>(buildUrl(`/dealer-settings/brake-liquid?brand=${brands.toString()}`))

export const addBrakeLiquid = (brandName: string | null, brakeLiquidName: string) =>
  axiosApi.post(buildUrl('/dealer-settings/brake-liquid'), { brandName, brakeLiquidName })

export const deleteBrakeLiquid = (brand: string | null, brakeLiquidName: string) =>
  axiosApi.delete(`/dealer-settings/brake-liquid/${brakeLiquidName}/brand/${brand}`)

export const getCarLiquids = (brands: ReadonlyArray<string | null>) =>
  axiosApi.get<ReadonlyArray<CarLiquids>>(buildUrl(`/dealer-settings/engine-code-oil${brands ? `?brand=${brands.toString()}` : ''}`))

export const addCarLiquids = (brandName: string | null, carLiquids: CarLiquids) =>
  axiosApi.post('/dealer-settings/engine-code-oil', { brandName, ...carLiquids })

export const getBrandFuelTypes = (brands: ReadonlyArray<string | null>) =>
  axiosApi.get<ReadonlyArray<string>>(buildUrl(`/dealer-settings/fuel-types${brands ? `?brand=${brands.toString()}` : ''}`))

export const deleteCarLiquid = (brand: string | null, engineCode?: string, engineOil?: string) =>
  axiosApi.delete(`/dealer-settings/engine-code/${engineCode}/engine-oil/${engineOil}/brand/${brand}`)

export const getAssistance = (brands: ReadonlyArray<string | null>) =>
  axiosApi.get<ReadonlyArray<CarAssistance>>(buildUrl(`/dealer-settings/assistance-insurers?brand=${brands.toString()}`))

export const addAssistance = (brandName: string | null, assistanceName: string, assistancePhoneNumber: string) =>
  axiosApi.post('/dealer-settings/assistance-insurers', { brandName, assistanceName, assistancePhoneNumber })

export const deleteAssistance = (brand: string | null, assistanceName: string) =>
  axiosApi.delete(`/dealer-settings/assistance-insurers/${assistanceName}/brand/${brand}`)
