import styled from 'styled-components'

export const CheckboxLabel = styled.label`
  cursor: pointer;
`

export const CheckboxContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 30px 1fr;
  margin: 15px 0 22px 0;
`
