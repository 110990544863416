import { format as dateFnsFormat } from 'date-fns'
import { getLocale } from '.'

export const dashDateFormat = 'YYYY-MM-DD'
export const dayLocalYearFormat = 'DD MMMM YYYY'
export const dateTimeFormat = 'YYYY-MM-DDThh:mm'
export const defaultDateFormat = 'DD MMMM YYYY'
export const defaultShortDateFormat = 'DD.MM.YYYY'
export const defaultTimeFormat = 'HH:mm'
export const yearDateFormat = 'HH:mm'
export const localTimeFormat = 'HH:mm:ss'

export const defaultDateTimeFormat = `${defaultDateFormat}, ${defaultTimeFormat}`

export const formatDate = (format: string = defaultDateFormat) =>
  (date: Date | string) => dateFnsFormat(date, format, { locale: getLocale() })

export const formatNullableDate = (format: string = defaultDateFormat) =>
  (date: Date | string | null | undefined) => date && dateFnsFormat(date, format, { locale: getLocale() })

export const isISODate = (date: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)) {
    return false
  }

  const dateObject = new Date(date)

  return dateObject.toISOString() === date
}

export const isLocalTime = (time: string) =>
  /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/.test(time)

export const formatToDashDate = formatNullableDate(dashDateFormat)
export const formatToDateTime = formatNullableDate(dateTimeFormat)

export const formatLocalTimeToDate = (time: string): Date | undefined => {
  if (!isLocalTime(time)) {
    return undefined
  }

  const [hours, minutes, seconds] = time.split(':').map(Number)

  return new Date(0, 0, 0, hours, minutes, seconds)
}
