import { InsurerRow } from 'actff-bo-app/Admin/Dealership/Insurers/InsurerRow'
import { NewInsurerDialog } from 'actff-bo-app/Admin/Dealership/Insurers/NewInsurerDialog'
import { Footer } from 'actff-bo-app/Admin/Footer'
import { Container } from 'actff-bo-app/Admin/Styled'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { Table, Th } from 'actff-bo-app/components/EditableList/Styled'
import { DealershipInsurer } from 'actff-bo-lib/admin/dealership'
import React, { FC, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Tr } from 'styles'

type Props = {
  readonly onAdd: (newInsurer: DealershipInsurer) => void,
  readonly onDelete: (insurerName: string | null) => void,
  readonly onSave: (insurers: ReadonlyArray<DealershipInsurer>) => void,
  readonly insurers: ReadonlyArray<DealershipInsurer>,
}

type Form = {
  readonly insurers: {
    readonly [index: number]: DealershipInsurer,
  },
}

export const InsurersTable: FC<Props> = ({ insurers, onAdd, onSave, onDelete }) => {
  const { t } = useTranslation()

  const [addNewInsurerOpen, setAddNewInsurerOpen] = useState(false)
  const [insurerToDelete, setInsurerToDelete] = useState<string | null>(null)
  const toggleAddDialogOpen = () => { setAddNewInsurerOpen(!addNewInsurerOpen) }

  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
  } = useForm<Form>({
    defaultValues: {
      insurers,
    },
  })

  const { isDirty, isSubmitting, touched } = formState
  const isSaveDisabled = () => !isDirty || isSubmitting || !touched

  const { fields } = useFieldArray({
    control,
    name: 'insurers',
  })

  useEffect(() => {
    reset({
      insurers,
    })
  }, [insurers])

  const handleDelete = (name: string) => () => {
    setInsurerToDelete(name)
  }

  const onDeleteDialogConfirm = async () => {
    onDelete(insurerToDelete)
    setInsurerToDelete(null)
  }

  const onDeleteDialogClose = () => {
    setInsurerToDelete(null)
  }

  const handleSave = handleSubmit((formValues: Form) => {
    onSave(Object.values(formValues.insurers))
  })

  return (
    <form>
      <Container noPadding={true}>
        <Table>
          <thead>
          <Tr>
            <Th>{t('caption.listNumber')}</Th>
            <Th>{t('admin.dealership.insurers.insurerName')}</Th>
            <Th>{t('admin.dealership.insurers.main')}</Th>
          </Tr>
          </thead>
          <tbody>
          {fields.map((field, index) => (
            <InsurerRow
              control={control}
              key={field.insurerName}
              index={index}
              insurer={field as DealershipInsurer}
              onDelete={handleDelete(field.insurerName)}
              register={register}
            />
          ))}
          </tbody>
        </Table>
      </Container>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.delete.content'
        contentProps={{ item: insurerToDelete || '' }}
        open={!!insurerToDelete}
        onConfirm={onDeleteDialogConfirm}
        onClose={onDeleteDialogClose}
        title='contentManagement.dialog.delete.title'
      />

      <NewInsurerDialog onAdd={onAdd} open={addNewInsurerOpen} toggleOpen={toggleAddDialogOpen} />
      <Footer
        isSaveDisabled={isSaveDisabled()}
        onAddClick={toggleAddDialogOpen}
        onSave={handleSave}
        onAddTLabel='caption.add'
      />
    </form>
  )
}
