import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from './Styled'

type Props = {
  readonly handleClick?: () => void,
}

export const OpportunityUnassignedPopoverContent: FC<Props> = ({ handleClick }) => {
  const { t } = useTranslation()

  return (
    <>
      {t('opportunity.unassigned')}<br />
      <Link
        onClick={handleClick}
        noPadding={true}
        noHeight={true}
        textAlign='left'
      >
        {t('opportunity.assign.assignToCurrentUser')}
      </Link>
    </>
  )
}
