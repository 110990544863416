import { QuickCampaignChannel, QuickCampaignDto } from 'actff-bo-lib/campaign'
import { FormValues } from 'actff-bo-lib/form'
import { mapFormValuesToKeys } from 'actff-bo-lib/form/map-values-to-keys'
import { QuickCampaignForm } from './Form'

export const mapFormValuesToDto = (values: QuickCampaignForm): QuickCampaignDto => {
  const { channel, chosenUsers, message, phones, ...restValues } = values
  const { brandsGroupSelector, chosenLocations, locationsGroupSelector, ...restValuesWithoutGroupSelectors } = restValues

  const mapped = Object.entries(restValuesWithoutGroupSelectors).reduce((prev, [key, value]) => ({
    ...prev,
    [key]: mapFormValuesToKeys(value as FormValues),
  }), {})

  return {
    channel: channel as QuickCampaignChannel,
    locationKeyBrands: mapped,
    message,
    phones: chosenUsers ? [] : phones.split('\n'),
  }
}
