import { Link } from 'actff-bo-app/components/Link'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Td as TdStyled } from 'styles'

type TdChildren = JSX.Element | string | number | object | undefined

type Props = Testable & {
  readonly children: TdChildren,
  readonly href?: string,
  readonly styles?: React.CSSProperties,
}

export const TdDecorator = (
  route: string,
  isSelectableAsProcessed?: boolean,
  onClick?: () => void,
  isSelected?: boolean,
  noPadding?: boolean,
): FC<Props> =>
  ({ children, testId, styles }) => {
    const isLinkRouteDisabled = isSelectableAsProcessed && !isSelected

    return (
      <TdStyled styles={styles} onClick={onClick} noPadding={noPadding}>
        <Link disabled={isLinkRouteDisabled} href={route} testId={testId}>{children}</Link>
      </TdStyled>
    )
}
