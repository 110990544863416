export enum UserPermissions {
  // TODO remove Unauthorized after test phase
  Unauthorized = 'UNAUTHORIZED',
  AdminAllClients = 'ADMIN_ALL_CLIENTS',
  AdminAllCars = 'ADMIN_ALL_CARS',
  AdminAllService = 'ADMIN_ALL_SERVICE',
  AdminAllInsurance = 'ADMIN_ALL_INSURANCE',
  AdminAllFinance = 'ADMIN_ALL_FINANCE',
  ServiceViewCar = 'SERVICE_VIEW_CAR',
  ServiceViewClient = 'SERVICE_VIEW_CLIENT',
  ServiceEditCar = 'SERVICE_EDIT_CAR',
  ServiceViewCars = 'SERVICE_VIEW_CARS',
  ServiceEditService = 'SERVICE_EDIT_SERVICE',
  ServiceViewService = 'SERVICE_VIEW_SERVICE',
  ServiceViewFinance = 'SERVICE_VIEW_FINANCE',
  ServiceEditFinance = 'SERVICE_EDIT_FINANCE',
  ServiceViewInsurance = 'SERVICE_VIEW_INSURANCE',
  ServiceEditInsurance = 'SERVICE_EDIT_INSURANCE',
  FinanceViewCar = 'FINANCE_VIEW_CAR',
  FinanceViewClient = 'FINANCE_VIEW_CLIENT',
  FinanceEditCar = 'FINANCE_EDIT_CAR',
  FinanceViewCars = 'FINANCE_VIEW_CARS',
  FinanceEditService = 'FINANCE_EDIT_SERVICE',
  FinanceViewService = 'FINANCE_VIEW_SERVICE',
  FinanceEditFinance = 'FINANCE_EDIT_FINANCE',
  FinanceViewFinance = 'FINANCE_VIEW_FINANCE',
  FinanceEditInsurance = 'FINANCE_EDIT_INSURANCE',
  FinanceViewInsurance = 'FINANCE_VIEW_INSURANCE',
  InsuranceViewCar = 'INSURANCE_VIEW_CAR',
  InsuranceViewClient = 'INSURANCE_VIEW_CLIENT',
  InsuranceEditCar = 'INSURANCE_EDIT_CAR',
  InsuranceViewCars = 'INSURANCE_VIEW_CARS',
  InsuranceViewService = 'INSURANCE_VIEW_SERVICE',
  InsuranceEditService = 'INSURANCE_EDIT_SERVICE',
  InsuranceViewFinance = 'INSURANCE_VIEW_FINANCE',
  InsuranceEditFinance = 'INSURANCE_EDIT_FINANCE',
  InsuranceViewInsurance = 'INSURANCE_VIEW_INSURANCE',
  InsuranceEditInsurance = 'INSURANCE_EDIT_INSURANCE',
}

export const LoggedInPermissions: ReadonlyArray<UserPermissions> = [ // TODO: Temporary solution while backend is not present
  UserPermissions.ServiceViewService,
  UserPermissions.FinanceViewService,
  UserPermissions.InsuranceViewService,
  UserPermissions.AdminAllService,
]
