import { FormHeader as FormHeaderStyled } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { colors, Td as TdComponent, Tr as TrComponent } from 'styles'

type TrProps = {
  readonly disabled: boolean,
  readonly selected: boolean,
}

type TdProps = {
  readonly color?: string,
}

export const Container = styled.div`
  padding: 24px 0;
`

export const FormHeader = styled(FormHeaderStyled)`
  padding-left: 24px;
`

export const HeaderContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 200px;
  padding-right: 24px;
`

export const Header = styled.div`
  font-weight: bold;
  margin: 24px;
`

export const LabelStyled = styled.label`
  bottom: 12px;
  left: 25px;
`

export const Td = styled(TdComponent)<TdProps>`
  ${({ color }: TdProps) => color && `color: ${color}`};
  padding: 24px;
`

export const Tr = styled(TrComponent)<TrProps>`
  cursor: pointer;
  ${({ disabled }: TrProps) => disabled && `color: ${colors.gray}`};

  td {
    ${({ selected }: TrProps) => selected && `background-color: ${colors.sprintWoodGold}`};
  }
`
