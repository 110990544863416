export enum Paths {
  Admin = '/admin',

  // Admin - dealer location settings
  AdminLocationsOpeningHours = '/admin/locations/opening-hours',
  AdminLocationsHolidays = '/admin/locations/holidays',
  AdminLocationsVisitAvailability = '/admin/locations/visit-availability',
  AdminLocationsContactDetails = '/admin/locations/contact-details',
  AdminLocationsD2DCities = '/admin/locations/d2d-cities',
  AdminLocationsBrands = '/admin/locations/location-brands',
  AdminLocationsApplicationLinks = '/admin/locations/application-links',

  // Admin - brands
  AdminCarModels = '/admin/brands/car-models',
  AdminCarVersions = '/admin/brands/car-versions',
  AdminBrakeLiquids = '/admin/brands/brake-liquids',
  AdminEngines = '/admin/brands/engines',
  AdminAssistance = '/admin/brands/assistance',

  // Admin - dealership
  AdminDealershipInsurers = '/admin/dealership/insurers',
  AdminDealershipInsurersQuestions = '/admin/dealership/insurers-questions',
  AdminDealershipRegulationsAndApprovals = '/admin/dealership/regulations-and-approvals',
  AdminDealershipHolidays = '/admin/dealership/holidays',
  AdminDealershipUsers = '/admin/dealership/users',

  // Admin - technical condition of cars
  AdminTechnicalCondition = '/admin/technical-condition',
  AdminTechnicalConditionEquipment = '/admin/technical-condition/equipment',
  AdminTechnicalConditionVida = '/admin/technical-condition/tester-vida',

  // Admin - Employee schedule
  AdminEmployeeSchedule = '/admin/employee-schedule',

  AdminTermsAndConditions = '/admin/terms-and-conditions',
  AdminCarsTechnicalState = '/admin/cars-technical-state',

  // Car
  CarList = '/cars',
  CarNew = '/:clientId/cars/new',
  CarViewBasicData = '/cars/:carId/basic-data',
  CarViewService = '/cars/:carId/service',
  CarViewFinances = '/cars/:carId/finance',
  CarViewInsurance = '/cars/:carId/insurance',
  CarViewEquipment = '/cars/:carId/equipment',
  CarViewInspections = '/cars/:carId/inspection',
  CarViewNewInspection = '/cars/:carId/inspection/new',
  CarViewInspection = '/cars/:carId/inspection/existing/:inspectionId',
  CarViewTires = '/cars/:carId/tires',

  // NdcCar
  NdcCars = '/cars/ndc',
  NdcCarViewBasicData = '/cars/ndc/:vin/basic-data',
  NdcCarViewEquipment = '/cars/ndc/:vin/equipment',
  NdcCarViewTires = '/cars/ndc/:vin/tires',
  NdcCarViewInspections = '/cars/ndc/:vin/inspection',
  NdcCarViewNewInspection = '/cars/ndc/:vin/inspection/new',
  NdcCarViewInspection = '/cars/ndc/:vin/inspection/existing/:inspectionId',

  Chat = '/chat',
  ChatActive = '/chat/active',
  ChatArchived = '/chat/archived',
  ChatView = '/chat/:threadId',

  Client = '/clients',
  ClientList = '/clients/list',
  ClientNew = '/clients/new',
  ClientViewBasicData = '/clients/:clientId/basic-data',
  ClientViewCars = '/clients/:clientId/cars',
  ClientViewHistory = '/clients/:clientId/history',
  ClientViewApprovals = '/clients/:clientId/approvals',
  ClientViewOpportunities = '/clients/:clientId/opportunities',
  ClientViewPreferences = '/clients/:clientId/preferences',
  ClientViewInvoices = '/clients/:clientId/invoices',

  Dashboard = '/dashboard',

  Login = '/login',
  Logout = '/logout',

  Main = '/',

  CrmService = '/crm-service',
  CrmServiceOpportunityList = '/crm-service/opportunities',
  CrmServiceOpportunity = '/crm-service/opportunities/:opportunityId',
  CrmServiceOpportunityManual = '/crm-service/opportunities/manual/:vin',

  CrmServiceQuickCampaign = '/crm-service/quick-campaign',

  CrmInsurance = '/crm-insurance',
  CrmInsuranceOpportunityList = '/crm-insurance/opportunities',
  CrmInsuranceOpportunityNew = '/crm-insurance/opportunity/manual',
  CrmInsuranceOpportunity = '/crm-insurance/opportunity/:opportunityId',
  CrmInsuranceReport = '/crm-insurance/report/:type',
  CrmInsuranceReportIndex = '/crm-insurance/report',

  CrmTrade = '/crm-trade',
  CrmTradePurchaseOpportunityList = '/crm-trade/purchase/opportunities',
  CrmTradePurchaseOpportunity = '/crm-trade/purchase/opportunity/:opportunityId',
  CrmTradeSaleOpportunityList = '/crm-trade/sale/opportunities',
  CrmTradeSaleOpportunityNew = '/crm-trade/sale/opportunity/manual',
  CrmTradeSaleOpportunity = '/crm-trade/sale/opportunity/:opportunityId',

  Calendar = '/calendar',
  CalendarServiceAdvisors = '/calendar/service-advisors',
  CalendarMechanics = '/calendar/mechanics',
  CalendarElectronicsEngineers = '/calendar/electronics-engineers',
  CalendarReplacementCars = '/calendar/replacement-cars',

  TaskList = '/task-list',

  ServiceRequests = '/task-list/service-requests',
  ServiceRequestsView = '/task-list/service-requests/:serviceRequestId',

  OfferRequests = '/task-list/offer-requests',
  OfferRequestsView = '/task-list/offer-requests/:offerId',

  TelephoneRequests = '/task-list/telephone-requests',
  TelephoneRequestsView = '/task-list/telephone-requests/:telephoneRequestId',

  TestDriveRequests = '/task-list/test-drive-requests',
  TestDriveRequestsView = '/task-list/test-drive-requests/:testDriveRequestId',
}
