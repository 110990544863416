// tslint:disable max-file-line-count
import { BtnType } from 'actff-bo-app/components/Button'
import { Avatar } from 'actff-bo-app/components/Client'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FormFooter, FormFooterLeft, FormFooterRight, FormHeader } from 'actff-bo-app/components/Form'
import { Overlay } from 'actff-bo-app/components/Overlay'
import { UploadFileProgress } from 'actff-bo-app/components/UploadFile'
import {
  Content,
  OpportunityAdditionalInformationContainer,
  OpportunityContent,
  OpportunityHistory,
  OpportunityInternalComments,
  OpportunityLeadSourceName,
  OpportunityMainData,
  OpportunityPushContainer,
} from 'actff-bo-app/Crm/Styled'
import { OpportunityAccomplishmentDetails as OpportunityAccomplishmentDetailsType } from 'actff-bo-lib/crm'
import {
  CrmServiceAction,
  internalCommentRequired,
  isOpportunityFormValid,
  LeadSourceTypeNameMap,
  OpportunityCreationModeNameMap,
  OpportunityId,
  OpportunityService,
  OpportunityStatus,
  selectDraftOpportunity,
} from 'actff-bo-lib/crm/service'
import { defaultShortDateFormat } from 'actff-bo-lib/date'
import { Attachment, hasErrors } from 'actff-bo-lib/global'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { selectSelectableServices, Service, ServiceAction } from 'actff-bo-lib/service'
import { State as GlobalState } from 'actff-bo-lib/store'
import { selectMe, User } from 'actff-bo-lib/user'
import { Form, FormikProps, withFormik } from 'formik'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import { Header, HeaderFiltersContainer, LinkButton, PrimaryButton } from 'styles'
import { object, string } from 'yup'

import { OpportunityViewCar } from '../OpportunityCar'
import { OpportunityViewClient } from '../OpportunityClient'
import { OpportunityAddInternalComment } from './OpportunityAddInternalComment'
import { OpportunityAdditionalInformation } from './OpportunityAdditionalInformation'
import { OppoetunityDescription } from './OpportunityDescription'
import { OpportunityLastPushDate } from './OpportunityLastPushDate'
import { OpportunityViewService } from './OpportunityService'
import { OpportunityStatus as OpportunityStatusComponent } from './OpportunityStatus'
import { OpportunityTitle } from './OpportunityTitle'

type OpportunityViewParams = {
  readonly opportunityId: OpportunityId,
}

type StateToProps = {
  readonly availableServices: ReadonlyArray<Service>,
  readonly currentLanguage: Language,
  readonly currentUser: User | null,
  readonly opportunity: OpportunityService | null,
}

type State = {
  readonly additionalInformationFormErrorsCount: number,
  readonly isCommentValid: boolean,
  readonly isUpdating: boolean,
  readonly statusChangeExpanded: boolean,
}

type DispatchToProps = {
  readonly clearOpportunity: () => void,
  readonly getCurrentOpportunity: (opportunityId: OpportunityId) => void,
  readonly getServices: () => void,
  readonly updateDraftOpportunity: (opportunity: Partial<OpportunityService>) => void,
  readonly updateOpportunityFromDraft: (opportunity: OpportunityService, comment: string, attachments: ReadonlyArray<Attachment>) => void,
  readonly updateStatusAndResetDraftOpportunity: (status: OpportunityStatus) => void,
}

export type OpportunityInternalCommentValues = {
  readonly attachments: ReadonlyArray<Attachment>,
  readonly comment: string,
}

type OpportunityViewComponentProps =
  StateToProps
  & DispatchToProps
  & RouteComponentProps<OpportunityViewParams>
  & WithTranslation
  & FormikProps<OpportunityInternalCommentValues>

const maxCommentLength = 500

export class OpportunityViewComponent extends React.Component<OpportunityViewComponentProps, State> {
  public readonly state: State = {
    additionalInformationFormErrorsCount: 0,
    isCommentValid: false,
    isUpdating: false,
    statusChangeExpanded: false,
  }

  public componentDidUpdate(oldProps: OpportunityViewComponentProps): void {
    if (oldProps.errors !== this.props.errors) {
      this.setState({ isCommentValid: !hasErrors(this.props.errors)})
    }
  }

  public componentDidMount(): void {
    this.props.getServices()
    this.props.getCurrentOpportunity(this.getOpportunityId(this.props))
  }

  public componentWillUnmount(): void {
    this.props.clearOpportunity()
  }

  public render(): React.ReactNode {
    const {
      availableServices,
      currentLanguage,
      handleChange,
      handleSubmit,
      opportunity,
      setFieldValue,
      t,
      updateDraftOpportunity,
      values,
    } = this.props
    const { statusChangeExpanded } = this.state
    const service = availableServices.find(s => s.key === opportunity?.serviceKey)

    const onAttachmentChange = (attachments: ReadonlyArray<Attachment>) => setFieldValue('attachments', [
      ...values.attachments,
      ...attachments,
    ])

    const onAttachmentDelete = (file: Attachment) => () => {
      this.props.setFieldValue('attachments', [
        ...values.attachments.filter(attachment => attachment.file !== file.file),
      ])
    }

    return (
      opportunity ? (
        <>
          <Form>
            {statusChangeExpanded && <Overlay onClick={this.toggleExpanded} />}
            <Header>
              <OpportunityTitle service={service} />
              <HeaderFiltersContainer>
                <div>
                  <label>{t('opportunity.leadSourceName')}: </label>
                  <OpportunityLeadSourceName>{t(LeadSourceTypeNameMap.get(opportunity.leadSourceName, ''))}</OpportunityLeadSourceName>
                </div>
              </HeaderFiltersContainer>
            </Header>
            <Content>
              <OpportunityContent>
                <OpportunityStatusComponent
                  currentStatus={opportunity.status}
                  expanded={statusChangeExpanded}
                  onChange={this.handleStatusChange}
                  onClose={this.toggleExpanded}
                />
                <OpportunityMainData>
                  <Avatar firstName={opportunity.assignee?.firstName} lastName={opportunity.assignee?.lastName} />
                  <div>
                    <label>{t(OpportunityCreationModeNameMap.get(opportunity.creationMode, ''))}</label>
                    <div>
                      {t('opportunity.created')}: <FormattedDate date={opportunity.created} format={defaultShortDateFormat} />
                    </div>
                    <div>
                      {t('opportunity.startDate')}: <FormattedDate date={opportunity.startDate} format={defaultShortDateFormat} />
                    </div>
                  </div>
                </OpportunityMainData>
                <OpportunityPushContainer>
                  <label>{t('opportunity.push.label')} ({opportunity.pushCount}):</label>
                  <OpportunityLastPushDate lastPushDate={opportunity.lastPushDate} />
                </OpportunityPushContainer>
                <OpportunityAdditionalInformationContainer>
                  <OpportunityAdditionalInformation
                    currentLanguage={currentLanguage}
                    handleAccomplishmentDetailsChange={this.handleAccomplishmentDetailsChange}
                    handleDateChange={this.handleDateChange}
                    handleValidateChange={this.handleValidateChange}
                    opportunity={opportunity}
                    updateOpportunity={updateDraftOpportunity}
                  />
                </OpportunityAdditionalInformationContainer>
                <OppoetunityDescription description={opportunity.description} />
                <OpportunityViewClient client={opportunity.client} />
                <OpportunityViewCar car={opportunity.car} />
                <OpportunityViewService lastServiceDate={opportunity.lastServiceDate} nextServiceDate={opportunity.nextServiceDate} />
                <OpportunityInternalComments
                  className='internal-comments'
                  comments={opportunity.comments}
                  currentLanguage={currentLanguage}
                />
                <OpportunityAddInternalComment
                  addFormVisible={internalCommentRequired(opportunity)}
                  onAttachmentChange={onAttachmentChange}
                  onAttachmentDelete={onAttachmentDelete}
                  onChange={handleChange}
                  required={internalCommentRequired(opportunity)}
                  values={values}
                />
              </OpportunityContent>
              <OpportunityHistory>
                <FormHeader>
                  {t('opportunity.serviceHistory')}
                </FormHeader>
                Wkrótce…
              </OpportunityHistory>
            </Content>
            <FormFooter>
              <FormFooterLeft>
                {opportunity.serviceRequestUuid && (
                  <LinkButton
                    onClick={this.handleClickOnServiceRequestLink}
                    type={BtnType.Button}
                    noPadding={true}
                  >
                    {t('opportunity.goToServiceRequest')}
                  </LinkButton>
                )}
              </FormFooterLeft>
              <FormFooterRight>
                <PrimaryButton
                  disabled={this.isAcceptButtonDisabled()}
                  onClick={handleSubmit}
                  type={BtnType.Button}
                >
                  {t('caption.accept')}
                </PrimaryButton>
              </FormFooterRight>
            </FormFooter>
          </Form>
          <UploadFileProgress />
        </>
      )
      : null
    )
  }

  private readonly isAcceptButtonDisabled = () => {
    const { opportunity, values } = this.props
    const { additionalInformationFormErrorsCount, isCommentValid, isUpdating } = this.state

    return !isOpportunityFormValid(opportunity, isCommentValid, values.comment)
      || isUpdating
      || !!additionalInformationFormErrorsCount
  }

  private readonly getOpportunityId = (props: OpportunityViewComponentProps) => props.match.params.opportunityId as OpportunityId

  private readonly handleAccomplishmentDetailsChange = (values: OpportunityAccomplishmentDetailsType) =>
    this.props.updateDraftOpportunity({ ...values })

  private readonly handleClickOnServiceRequestLink = () =>
    history.push(createRoute(Paths.ServiceRequestsView, { serviceRequestId: this.props.opportunity?.serviceRequestUuid }))

  private readonly handleDateChange = (dateField: keyof Pick<OpportunityService, 'retryDate' | 'appointmentDate'>) => (date: Date) => {
    this.props.updateDraftOpportunity({
      [dateField]: date,
    })
  }

  private readonly handleValidateChange = (additionalInformationFormHasErrors: boolean) => {
    this.setState({
      additionalInformationFormErrorsCount: additionalInformationFormHasErrors
        ? this.state.additionalInformationFormErrorsCount + 1
        : this.state.additionalInformationFormErrorsCount - 1,
    })
  }

  private readonly handleStatusChange = (status: OpportunityStatus) => () => {
    this.props.updateStatusAndResetDraftOpportunity(status)
    this.resetAdditionalInformationFormErrorCount()
    this.toggleExpanded()
  }

  private readonly resetAdditionalInformationFormErrorCount = () => {
    this.setState({ additionalInformationFormErrorsCount: 0 })
  }

  private readonly toggleExpanded = () => {
    this.setState({ statusChangeExpanded: !this.state.statusChangeExpanded })
  }
}

const validationSchema = (props: OpportunityViewComponentProps) => object<OpportunityInternalCommentValues>().shape({
  comment: internalCommentRequired(props.opportunity)
    ? string().required().min(1).max(maxCommentLength)
    : string(),
})

const formik = withFormik<OpportunityViewComponentProps, OpportunityInternalCommentValues>({
  handleSubmit: ({ attachments, comment }, { props: { opportunity, updateOpportunityFromDraft }, resetForm }) => {
    resetForm()
    opportunity && updateOpportunityFromDraft(opportunity, comment, attachments)
  },
  mapPropsToValues: () => ({ attachments: [], comment: '' }),
  validateOnMount: true,
  validationSchema,
})

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  availableServices: selectSelectableServices(state),
  currentLanguage: selectCurrentLanguage(state),
  currentUser: selectMe(state),
  opportunity: selectDraftOpportunity(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, OpportunityViewComponentProps> = dispatch => ({
  clearOpportunity: () => { dispatch(CrmServiceAction.clearOpportunity()) },
  getCurrentOpportunity: (opportunityId: OpportunityId) => { dispatch(CrmServiceAction.getOpportunity(opportunityId)) },
  getServices: () => { dispatch(ServiceAction.getServices()) },
  updateDraftOpportunity: (opportunity: Partial<OpportunityService>) => { dispatch(CrmServiceAction.updateDraftOpportunity(opportunity)) },
  updateOpportunityFromDraft: (opportunity: OpportunityService, comment: string, attachments: ReadonlyArray<Attachment>) => {
    dispatch(CrmServiceAction.updateOpportunityFromDraft(opportunity, comment, attachments))
  },
  updateStatusAndResetDraftOpportunity: (status: OpportunityStatus) => {
    dispatch(CrmServiceAction.updateStatusAndResetDraftOpportunity(status))
  },
})

export const OpportunityView = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  formik,
)(OpportunityViewComponent)
