import {
  InputCheckboxFilter,
  InputDateFilter,
  InputMileageFilter,
  InputPriceRangeFilter,
  InputProductionYearRangeFilter,
  MultipleSelectFilter,
  RatingsSelectFilter,
} from 'actff-bo-app/components/Filters'
import { FiltersRendererProps } from 'actff-bo-app/components/TabFilters'
import { getCarLiquids, getCarModels, getCarVersions } from 'actff-bo-lib/admin/brands/dao'
import { CarLiquids } from 'actff-bo-lib/admin/brands/dto'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { detailedFiltersConfig } from 'actff-bo-lib/crm/trade/purchase/filters'
import { selectDealerBrands } from 'actff-bo-lib/dealership'
import { mapOptionsToArray, mapValuesToSelectOptions, SelectOption } from 'actff-bo-lib/global'
import React, { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { colors } from 'styles'

import {
  Container,
  FilterContainer,
  FilterContent,
  Label,
  SaleOpportunitiesCountFilterContainer,
  SpacedFilterContainer,
  SpacedFilterContent,
} from './Styled'

type Props = FiltersRendererProps
const engineFilterTrimLength = 2

export const PurchaseOpportunityListFilters: React.FC<Props> = ({ control, setValue }) => {
  const { t } = useTranslation()

  const [selectedBrands, setSelectedBrands] = useState<ReadonlyArray<SelectOption<string>>>([])

  useEffect(() => {
    if (selectedBrands.length === 0) {
      setValue('models', [])
      setValue('version', [])
      setValue('engine', [])
    }
  }, [selectedBrands])

  const { data: carVersions } = useQuery<ReadonlyArray<string>>([QueryKeys.GET_CAR_VERSIONS, selectedBrands], async () =>
    getCarVersions(mapOptionsToArray(selectedBrands) || []), { retry: false, refetchOnWindowFocus: false },
  )

  const { data: carModels } = useQuery<ReadonlyArray<string>>([QueryKeys.GET_CAR_MODELS, selectedBrands], async () =>
    getCarModels(mapOptionsToArray(selectedBrands) || []), { retry: false, refetchOnWindowFocus: false },
  )

  const { data: oilsAndEngines } = useQuery<ReadonlyArray<CarLiquids>>([QueryKeys.GET_OILS_AND_ENGINES, selectedBrands], async () =>
    getCarLiquids(mapOptionsToArray(selectedBrands) || []), { retry: false, refetchOnWindowFocus: false },
  )

  const productionYearFrom = useWatch<SelectOption<string>>({ control, name: 'productionYearMin' })
  const mileageFrom = useWatch<SelectOption<string>>({ control, name: 'mileageMin' })
  const priceFrom = useWatch<SelectOption<string>>({ control, name: 'priceMin' })

  const {
    brands,
    origin,
    clientSource,
    financeEnd,
    engine,
    hasInspection,
    mileage,
    models,
    newCarOffer,
    opportunityDate,
    opportunityType,
    plannedSale,
    price,
    productionYear,
    saleOpportunitiesCount,
    transmission,
    version,
  } = detailedFiltersConfig
  const { data: carBrands } = useSelector(selectDealerBrands)

  const handleBrandsChange = (_brands: ReadonlyArray<SelectOption<string>>) => {
    setSelectedBrands(_brands)
  }

  return (
    <Container>
      <SpacedFilterContainer>
        <FilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.opportunityType')}:</Label>
          <InputCheckboxFilter
            control={control}
            key={opportunityType[0].name}
            name={opportunityType[0].name}
            caption={opportunityType[0].caption}
            oppositeFieldName={opportunityType[1].name}
            setValue={setValue}
          />
          <InputCheckboxFilter
            control={control}
            key={opportunityType[1].name}
            name={opportunityType[1].name}
            caption={opportunityType[1].caption}
            oppositeFieldName={opportunityType[0].name}
            setValue={setValue}
          />
        </FilterContent>
      </SpacedFilterContainer>

      <SpacedFilterContainer>
        <FilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.showCars')}:</Label>
          <InputCheckboxFilter
            control={control}
            name={hasInspection[0].name}
            caption={hasInspection[0].caption}
            oppositeFieldName={hasInspection[1].name}
            setValue={setValue}
          />
          <InputCheckboxFilter
            control={control}
            name={hasInspection[1].name}
            caption={hasInspection[1].caption}
            oppositeFieldName={hasInspection[0].name}
            setValue={setValue}
          />
        </FilterContent>
      </SpacedFilterContainer>

      <SpacedFilterContainer>
        <FilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.carOrigin')}:</Label>
          {origin.map(filter => (
            <InputCheckboxFilter control={control} key={filter.name} name={filter.name} caption={filter.caption} />
          ))}
        </FilterContent>
      </SpacedFilterContainer>

      <FilterContent>
        <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.newCarOffer')}:</Label>
        {newCarOffer.map(filter => (
          <InputCheckboxFilter control={control} key={filter.name} name={filter.name} caption={filter.caption} />
        ))}
      </FilterContent>

      <SpacedFilterContainer>
        <SpacedFilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.opportunityDate')}:</Label>
          {opportunityDate.map(filter => (
            <InputDateFilter key={filter.name} name={filter.name} caption={filter.caption} control={control} />
          ))}
        </SpacedFilterContent>
      </SpacedFilterContainer>

      <SpacedFilterContainer>
        <SpacedFilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.endOfFinancing')}:</Label>
          {financeEnd.map(filter => (
            <InputDateFilter key={filter.name} name={filter.name} caption={filter.caption} control={control} />
          ))}
        </SpacedFilterContent>
      </SpacedFilterContainer>

      <SpacedFilterContainer>
        <SpacedFilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.plannedSellDate')}:</Label>
          {plannedSale.map(filter => (
            <InputDateFilter key={filter.name} name={filter.name} caption={filter.caption} control={control} />
          ))}
        </SpacedFilterContent>
      </SpacedFilterContainer>

      <SpacedFilterContainer>
        <SpacedFilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.productionYear')}:</Label>
          <InputProductionYearRangeFilter name={productionYear[0].name} caption={productionYear[0].caption} control={control} />
          <InputProductionYearRangeFilter
            name={productionYear[1].name}
            caption={productionYear[1].caption}
            control={control}
            startFrom={productionYearFrom}
          />
        </SpacedFilterContent>
      </SpacedFilterContainer>

      <SpacedFilterContent>
        <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.knownMileage')}:</Label>
        <InputMileageFilter name={mileage[0].name} caption={mileage[0].caption} control={control} />
        <InputMileageFilter name={mileage[1].name} caption={mileage[1].caption} control={control} startFrom={mileageFrom} />
      </SpacedFilterContent>

      <SpacedFilterContainer>
        <SpacedFilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.price')}:</Label>
          {price.map(filter => (
            <InputPriceRangeFilter key={filter.name} name={filter.name} caption={filter.caption} control={control} startFrom={priceFrom} />
          ))}
        </SpacedFilterContent>
      </SpacedFilterContainer>

      <FilterContainer>
        <RatingsSelectFilter control={control} label='crmTrade.opportunities.filters.inspectionRating' name='ratings' />
      </FilterContainer>

      <SpacedFilterContent>
        <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.saleOpportunitiesCount')}:</Label>
        <SaleOpportunitiesCountFilterContainer>
          {saleOpportunitiesCount.map(filter => (
            <InputCheckboxFilter
              control={control}
              key={filter.name}
              name={filter.name}
              caption={filter.caption}
              shouldTranslate={false}
            />
          ))}
        </SaleOpportunitiesCountFilterContainer>
      </SpacedFilterContent>
      {brands.map(filter => (
        <MultipleSelectFilter
          key={filter.name}
          label='crmTrade.opportunities.filters.brand'
          control={control}
          name={filter.name}
          onChange={handleBrandsChange}
          options={mapValuesToSelectOptions(carBrands)}
        />
      ))}
      {models.map(filter => (
        <MultipleSelectFilter
          key={filter.name}
          label='crmTrade.opportunities.filters.model'
          control={control}
          name={filter.name}
          options={mapValuesToSelectOptions(carModels)}
        />
      ))}
      {version.map(filter => (
        <MultipleSelectFilter
          key={filter.name}
          label='crmTrade.opportunities.filters.version'
          control={control}
          name={filter.name}
          options={mapValuesToSelectOptions(carVersions)}
          trimLength={filter.trimLength}
        />
      ))}
      {engine.map(filter => (
        <MultipleSelectFilter
          key={filter.name}
          label='crmTrade.opportunities.filters.engine'

          control={control}
          name={filter.name}
          options={mapValuesToSelectOptions(oilsAndEngines?.map(entry => entry.engineCode)) || []}
          trimLength={engineFilterTrimLength}
        />
      ))}
      {transmission.map(filter => (
        <MultipleSelectFilter
          key={filter.name}
          label='crmTrade.opportunities.filters.transmission'

          control={control}
          name={filter.name}
          options={filter.options}
        />
      ))}
      <SpacedFilterContainer>
        <FilterContent>
          <Label color={colors.black} uppercase={false}>{t('crmTrade.opportunities.filters.clientSource')}:</Label>
          {clientSource.map(filter => (
            <InputCheckboxFilter control={control} key={filter.name} name={filter.name} caption={filter.caption} />
          ))}
        </FilterContent>
      </SpacedFilterContainer>

    </Container>
  )
}
