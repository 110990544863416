import { websocketPrefixedUrlWithAuthToken } from 'actff-bo-lib/api/request-utils'
import {
  OpportunityInsuranceId,
  OpportunityViewerMessage,
  OpportunityViewerPayloadType,
} from 'actff-bo-lib/crm/insurance/dto'
import { selectCurrentOpportunity } from 'actff-bo-lib/crm/insurance/selectors'
import { retryWithDelay } from 'actff-bo-lib/global/operators'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { forkJoin, iif, interval, merge, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import {
  catchError,
  concatMap,
  filter,
  flatMap,
  ignoreElements,
  map,
  mergeAll,
  reduce,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { webSocket } from 'rxjs/webSocket'

import { CrmInsuranceAction, CrmInsuranceActionType } from '../actions'
import { CrmInsuranceOpportunityAction } from '../actions/opportunity'
import { createOpportunity, deleteOpportunity } from '../dao'
import {
  mapOpportunityFieldsFromRequests,
  mapOpportunityFieldsToRequests,
  mapToUpdateProcessRequest,
} from '../model-to-requests'

const socketConnectionRetryCount = 5
const socketConnectionRetryDelay = 1000
const echoMessageTimeInterval = 10000

const getOpportunityEpic: Epic<CrmInsuranceAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmInsuranceAction.getOpportunity>
    | ReturnType<typeof CrmInsuranceAction.saveOpportunitySuccess>
    >(
      CrmInsuranceActionType.GetOpportunity,
      CrmInsuranceActionType.SaveOpportunitySuccess,
  ),
  switchMap(({ payload }) => forkJoin(mapOpportunityFieldsFromRequests(payload)).pipe(
    mergeAll(),
    reduce((opportunity, [key, value]) => ({...opportunity, [key as string]: value}), {}),
    map(CrmInsuranceAction.getOpportunitySuccess),
    catchError((error: AjaxError) => of(CrmInsuranceAction.getOpportunityFailure(error))),
  )),
)

const saveOpportunityEpic: Epic<CrmInsuranceAction, CrmInsuranceAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.saveOpportunity>>(CrmInsuranceActionType.SaveOpportunity),
  switchMap(({ payload }) =>
    iif(
      () => !!payload.uuid,
      of(payload.uuid),
      createOpportunity(payload.opportunity.process).pipe(
        map(opportunity => opportunity.uuid),
        catchError((error: AjaxError) => of(CrmInsuranceAction.saveOpportunityFailure(error))),
      ),
    ).pipe(
      filter(Boolean),
      withLatestFrom(store$),
      switchMap(([uuid, store]) =>
        mapToUpdateProcessRequest(
          uuid as OpportunityInsuranceId,
          payload.opportunity.process,
          selectCurrentOpportunity(store).data?.process,
        )
          .pipe(
            concatMap(() => forkJoin(
              mapOpportunityFieldsToRequests(uuid as OpportunityInsuranceId, payload.opportunity, selectCurrentOpportunity(store).data),
            )),
          )
          .pipe(
            map(results => {
              const failures = results
                .filter(
                  ({ type }) => type === CrmInsuranceActionType.SaveOpportunityDataFailure,
                ) as ReadonlyArray<ReturnType<typeof CrmInsuranceAction.saveOpportunityDataFailure>>

              if (failures.length > 0) {
                return CrmInsuranceAction.saveOpportunityFailure({
                  ...failures[0].payload.ajaxError,
                  response: failures
                    .map(action => action.payload)
                    .reduce((errors, fieldErrors) => ({
                        ...errors,
                        ...fieldErrors.errors,
                      }),
                      {},
                    ),
                })
              }

              if (!payload.uuid) {
                history.push(createRoute(Paths.CrmInsuranceOpportunity, { opportunityId: uuid }))
              }

              return CrmInsuranceAction.saveOpportunitySuccess(uuid as OpportunityInsuranceId)
            }),
            catchError((error: AjaxError) => of(CrmInsuranceAction.saveOpportunityFailure(error))),
      )),
    ),
  ),
)

const saveOpportunitySuccessEpic: Epic<CrmInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.saveOpportunitySuccess>>(CrmInsuranceActionType.SaveOpportunitySuccess),
  ignoreElements(),
)

const alertOnFailureActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.saveOpportunityFailure>>(CrmInsuranceActionType.SaveOpportunityFailure),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'toast.form.formSave.failure',
      title: 'toast.form.formSave.failure',
      type: ToastType.Error,
    }),
  ]),
)

const informOnSuccessActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.saveOpportunitySuccess>>(CrmInsuranceActionType.SaveOpportunitySuccess),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      id: 'toast.form.formSave.success',
      title: 'toast.form.formSave.success',
      type: ToastType.Success,
    }),
  ]),
)

const connectOpportunityViewersSocketEpic: Epic<CrmInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.connectOpportunityViewersSocket>>(CrmInsuranceActionType.ConnectOpportunityViewersSocket),
  flatMap(({ payload: connectUrl }) => {
    const socket$ = webSocket<OpportunityViewerMessage>({
      deserializer: ({ data }) => JSON.parse(data),
      url: websocketPrefixedUrlWithAuthToken(`/insurance/viewers/${connectUrl}`),
    })

    return merge(
      socket$.pipe(
        filter(message => message.type !== OpportunityViewerPayloadType.ECHO),
        map(CrmInsuranceAction.receivedOpportunityViewersUpdate),
      ),
      interval(echoMessageTimeInterval).pipe(
        tap(() => socket$.next({ type: OpportunityViewerPayloadType.ECHO })),
        ignoreElements(),
      ),
    )
      .pipe(
        takeUntil(action$.pipe(
          ofType<ReturnType<typeof CrmInsuranceAction.disconnectOpportunityViewersSocket>>(
              CrmInsuranceActionType.DisconnectOpportunityViewersSocket,
            ),
            filter(({ payload: disconnectUrl }) => disconnectUrl === connectUrl),
          ),
        ),
        retryWithDelay(socketConnectionRetryDelay, socketConnectionRetryCount),
        catchError(() =>
          of(CrmInsuranceOpportunityAction.connectOpportunityViewersSocketFailure()),
        ),
      )
  }),
)

const deleteOpportunityEpic: Epic<CrmInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.deleteOpportunity>>(CrmInsuranceActionType.DeleteOpportunity),
  switchMap(({ payload }) => deleteOpportunity(payload).pipe(
    map(() => CrmInsuranceAction.deleteOpportunitySuccess(payload)),
    catchError((error: AjaxError) => of(CrmInsuranceAction.deleteOpportunityFailure(error)),
  )),
))

const deleteOpportunitySuccessEpic: Epic<CrmInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.deleteOpportunitySuccess>>(CrmInsuranceActionType.DeleteOpportunitySuccess),
  tap(() => [
    history.push(Paths.CrmInsuranceOpportunityList),
  ]),
  ignoreElements(),
)

const alertOnDeleteOpportunityFailureActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.deleteOpportunityFailure>>(CrmInsuranceActionType.DeleteOpportunityFailure),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'toast.form.formDelete.failure',
      title: 'toast.form.formDelete.failure',
      type: ToastType.Error,
    }),
  ]),
)

const informOnDeleteOpportunitySuccessActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.deleteOpportunitySuccess>>(CrmInsuranceActionType.DeleteOpportunitySuccess),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      id: 'toast.form.formDelete.success',
      title: 'toast.form.formDelete.success',
      type: ToastType.Success,
    }),
  ]),
)

const alertOnConnectionFailureActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.connectOpportunityViewersSocketFailure>>(
    CrmInsuranceActionType.ConnectOpportunityViewersSocketFailure,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      id: 'toast.connectionOpportunityViewersSocketFailure',
      title: 'toast.connectionOpportunityViewersSocketFailure',
      type: ToastType.Error,
    }),
  ]),
)

export const crmOpportunityEpic = combineEpics(
  alertOnFailureActionEpic,
  getOpportunityEpic,
  informOnSuccessActionEpic,
  saveOpportunityEpic,
  saveOpportunitySuccessEpic,
  connectOpportunityViewersSocketEpic,
  deleteOpportunityEpic,
  alertOnDeleteOpportunityFailureActionEpic,
  informOnDeleteOpportunitySuccessActionEpic,
  deleteOpportunitySuccessEpic,
  alertOnConnectionFailureActionEpic,
)
