import styled from 'styled-components'
import { theme } from 'styles'

export const PopoverContentContainer = styled.div`
  display: grid;
  padding: 10px;
`

export const Link = styled.div`
  color: ${theme.button.link.text};
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 8px 8px 8px;
  transition: all ease-out 200ms;

  &:hover {
    color: ${theme.button.link.hovered.color};
  }
`
