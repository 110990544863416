import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'

export const CommentsContainer = styled(FormCell)`
  padding-right: 32px;
`

export const CommentHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 10px 0;
`
