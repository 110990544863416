import { OpportunityAdditionalInformationContainer } from 'actff-bo-app/Crm/Styled'
import { OpportunityPurchase, OpportunityPurchaseStatusType, OpportunitySale, OpportunitySaleStatusType } from 'actff-bo-lib/crm/trade'
import { Testable } from 'actff-bo-lib/global/testable'
import { Language } from 'actff-bo-lib/i18n'
import * as React from 'react'
import { Control } from 'react-hook-form'
import { FieldErrors, Ref, ValidationRules } from 'react-hook-form/dist/types/form'
import { OpportunityAccomplishmentDetails } from './OpportunityAccomplishmentDetails'
import { OpportunityFailureReason } from './OpportunityFailureReason'

type Props = Testable & {
  readonly control: Control,
  readonly errors: FieldErrors,
  readonly register: (rules?: ValidationRules) => (ref: Ref | null) => void,
  readonly currentLanguage: Language,
  readonly currentStatus?: OpportunityPurchaseStatusType | OpportunitySaleStatusType,
  readonly opportunity: OpportunityPurchase | OpportunitySale,
}

export const OpportunityAdditionalInformation: React.FC<Props> = ({
  control,
  currentStatus,
  errors,
  opportunity,
  register,
}) => {
  switch (currentStatus) {
    case OpportunityPurchaseStatusType.SUCCESS:
      const { invoiceNumber } = opportunity

      return (
        <OpportunityAdditionalInformationContainer>
          <OpportunityAccomplishmentDetails
            invoiceNumber={invoiceNumber}
            errors={errors}
            register={register}
          />
        </OpportunityAdditionalInformationContainer>
      )

    case OpportunityPurchaseStatusType.FAILURE:
      return (
        <OpportunityAdditionalInformationContainer>
          <OpportunityFailureReason
            control={control}
            errors={errors}
            failureExplanation={opportunity.failureExplanation}
            failureReason={opportunity.failureReason}
            register={register}
          />
        </OpportunityAdditionalInformationContainer>
      )

    default:
      return null
  }
}
