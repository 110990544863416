import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Link as LinkComponent } from 'react-router-dom'
import styled from 'styled-components'

type Props = Testable & {
  readonly bgColor?: string,
  readonly children: JSX.Element | string | number | object | undefined,
  readonly href: string,
  readonly disabled?: boolean,
}

type LinkStyledProps = {
  readonly bgColor?: string,
  readonly disabled?: boolean,
}

const LinkStyled = styled(LinkComponent)<LinkStyledProps>`
  ${({ bgColor }: LinkStyledProps) => bgColor && `background-color: ${bgColor};`}
  display: block;
  padding: 20px 25px;
  ${({ disabled }: LinkStyledProps) => disabled && 'pointer-events: none;'}
`

export const Link: FC<Props> = ({ children, bgColor, disabled, testId, href }) => (
  <LinkStyled to={href} data-testid={testId} disabled={disabled} bgColor={bgColor}>{children}</LinkStyled>
)
