import { FormCell, FormFooter } from 'actff-bo-app/components/Form'
import { Icon } from 'actff-bo-app/components/Icon'
import { Popup } from 'actff-bo-app/components/Popup'
import styled from 'styled-components'
import { colors, LinkButton, Span, theme } from 'styles'

export const ContactApprovalsForm = styled.form`
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 1fr 1fr;
  padding-bottom: 80px;
`

export const ContactApprovalsHeader = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: unset;
  justify-content: space-between;
  padding: 0 20px;
`

export const ContactApprovalsContactHeader = styled(ContactApprovalsHeader)`
  grid-template-areas: 'header';
  grid-auto-flow: column;
  grid-template-columns: 1fr 200px 0 200px;
`
export const ContactApprovalsRegistrationHeader = styled(ContactApprovalsHeader)`
  grid-template-areas: 'header';
  grid-auto-flow: column;
`

export const ApprovalsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1fr);
  grid-gap: 10px;

  > * {
    border-bottom: 1px solid ${colors.solitude};
  }
`

export const Approval = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  padding-left: 20px;
`

export const ApprovalTypeStyled = styled.div`
  display: grid;
`

export const SeeContactApprovalsHistoryStyled = styled(FormCell)`
  justify-items: left;
`

export const SeeRegistrationApprovalsHistoryStyled = styled.div`
  grid-column: 4 / 5;
`

export const ContactApprovalsFooter = styled(FormFooter)`
  border-top: none;
  justify-content: space-between;
`

export const NoData = styled(Span)`
  padding: 30px;
`

export const ReadApprovalButton = styled(LinkButton)`
  display: grid;
  justify-items: left;

  span {
    display: grid;
    justify-items: left;
  }
`

export const PopupStyled = styled(Popup)`
  @media print {
    display: none;
  }
`

export const ApprovalContent = styled.p`
  @media print {
    display: block !important;
  }
`

export const ApprovalPopupFooter = styled.footer`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(2, 140px);
  justify-content: end;
`

export const DownloadButtonStyled = styled.button`
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  color: ${theme.button.link.text};
  height: 32px;
`

export const DownloadButtonIcon = styled(Icon)`
  margin-right: 5px;
`
