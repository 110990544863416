import { FormCell, FormHeader } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import Lightbox from 'lightbox-react'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CommentContent } from '../ServiceRequestViewStyled'

type Props = {
  readonly clientAttachments: ReadonlyArray<string> | null,
  readonly clientComment: string,
}

type State = {
  readonly currentImageUrl: string | null,
}

type AttachmentProps = {
  readonly imageUrl: string,
  readonly onImageClick: (image: string) => () => void,
}

type ServiceRequestClientCommentsProps = Testable & Props & WithTranslation

const AttachmentsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
`

const AttachmentContainer = styled.figure`
  margin: 0;
`

const AttachmentContent = styled.img`
  border-radius: 3px;
  cursor: pointer;
  height: auto;
  width: 100%;
`

const Attachment: React.FC<AttachmentProps> = ({ imageUrl, onImageClick }) => (
  <AttachmentContainer>
    <AttachmentContent onClick={onImageClick(imageUrl)} src={imageUrl} />
  </AttachmentContainer>
)

class ServiceRequestClientCommentsComponent extends React.Component<ServiceRequestClientCommentsProps, State> {
  public readonly state: State = {
    currentImageUrl: null,
  }

  public render(): React.ReactNode {
    const { clientAttachments, clientComment, t, testId } = this.props
    const { currentImageUrl } = this.state

    return (
      <>
        {currentImageUrl && <Lightbox mainSrc={currentImageUrl} onCloseRequest={this.closeLightbox} />}
        <FormCell>
          <FormHeader>
            {t('serviceRequest.clientComments.header')}
          </FormHeader>
          <CommentContent data-testid={`${testId}client-comment`}>
            {clientComment}
          </CommentContent>
          {clientAttachments &&
            <>
              <label>{t('serviceRequest.clientComments.attachments')}</label>
              <AttachmentsContainer data-testid={`${testId}client-attachments`}>
                {clientAttachments.map((image, index) => <Attachment key={index} onImageClick={this.openLightbox} imageUrl={image} />)}
              </AttachmentsContainer>
            </>
          }
        </FormCell>
      </>
    )
  }

  public readonly closeLightbox = () => { this.setState({ currentImageUrl: null })}
  public readonly openLightbox = (image: string) => () => { this.setState({ currentImageUrl: image }) }
}

export const ServiceRequestClientComments = withTranslation()(ServiceRequestClientCommentsComponent)
