import styled from 'styled-components'
import { tabletBoundary, theme } from 'styles'

export const Ul = styled.ul`
  border-bottom: 1px solid ${theme.tabs.border};
  display: grid;
  grid-column-gap: 8px;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  height: 40px;
  margin: -40px 0 0 32px;
  @media screen and (max-width: ${tabletBoundary}) {
    margin: -40px 0 0 0;
    overflow-x: auto;
    overflow-y: hidden;
  }
`
