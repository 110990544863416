import styled from 'styled-components'

type FilterContainerProps = {
  readonly isPurchase?: boolean,
}

export const CheckboxContainer = styled.div`
  margin-bottom: 5px;
`

export const FiltersContainer = styled.div<FilterContainerProps>`
  align-items: start;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${({ isPurchase }: FilterContainerProps) => isPurchase ? 'minmax(auto, 60%) auto 1fr' : 'auto 1fr'};
`
