import { CarInfo } from 'actff-bo-lib/car'
import { ClientBasicData } from 'actff-bo-lib/client'
import { OpportunityAccomplishmentDetails } from 'actff-bo-lib/crm/dto'
import { toPriceString } from 'actff-bo-lib/currency'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { DealerLocation, DealerLocationKey } from 'actff-bo-lib/dealership'
import { Attachment, NominalString, Url } from 'actff-bo-lib/global'
import { ServiceKey } from 'actff-bo-lib/service'
import { ServiceRequestId } from 'actff-bo-lib/service-request'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import { Map } from 'immutable'

export enum OpportunityType {
  NextMOT = 'NEXT_MOT',
}

export enum OpportunityStatus {
  ACCOMPLISHED = 'ACCOMPLISHED',
  ARRANGED = 'ARRANGED',
  AFTER_APPOINTMENT_LIST = 'AFTER_APPOINTMENT_LIST',
  CANCELLED = 'CANCELLED',
  CANCELLED_LIST = 'CANCELLED_LIST',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  NEW = 'NEW',
  NEW_LIST = 'NEW_LIST',
  MISSED = 'MISSED',
  POSTPONED = 'POSTPONED',
  PUSH_SENT = 'PUSH_SENT',
  RETRY = 'RETRY',
  RETRY_LIST = 'RETRY_LIST',
}

export type OpportunityFilterValues = OpportunityStatus | OpportunityCreationMode

export const OpportunityStatusNameMap: Map<OpportunityStatus, string> = Map([
  [OpportunityStatus.ACCOMPLISHED, 'opportunity.status.accomplished'],
  [OpportunityStatus.ARRANGED, 'opportunity.status.arranged'],
  [OpportunityStatus.AFTER_APPOINTMENT_LIST, 'opportunity.status.afterAppointment'],
  [OpportunityStatus.CANCELLED, 'opportunity.status.cancelled'],
  [OpportunityStatus.CANCELLED_LIST, 'opportunity.status.cancelled'],
  [OpportunityStatus.EXPIRED, 'opportunity.status.expired'],
  [OpportunityStatus.FAILED, 'opportunity.status.failed'],
  [OpportunityStatus.NEW, 'opportunity.status.new'],
  [OpportunityStatus.NEW_LIST, 'opportunity.status.new'],
  [OpportunityStatus.MISSED, 'opportunity.status.missed'],
  [OpportunityStatus.POSTPONED, 'opportunity.status.postponed'],
  [OpportunityStatus.PUSH_SENT, 'opportunity.status.pushSent'],
  [OpportunityStatus.RETRY, 'opportunity.status.retry'],
  [OpportunityStatus.RETRY_LIST, 'opportunity.status.retry'],
]) as Map<OpportunityStatus, string>

export enum LeadSourceType {
  NDC = 'NDC',
  WILSON = 'WILSON',
}

export const LeadSourceTypeNameMap: Map<LeadSourceType, string> = Map([
  [LeadSourceType.NDC, 'opportunity.leadSourceName.ndc'],
  [LeadSourceType.WILSON, 'opportunity.leadSourceName.wilson'],
]) as Map<LeadSourceType, string>

export type CarService = {
  readonly key: string,
  readonly name: string,
  readonly lastServiceDate: Date,
  readonly nextServiceDate: Date,
}

export enum OpportunityFailureReason {
  CAR_SOLD = 'CAR_SOLD',
  OTHER = 'OTHER',
  OTHER_SERVICE_VISIT = 'OTHER_SERVICE_VISIT',
}

export const OpportunityFailureReasonNameMap: Map<OpportunityFailureReason, string> = Map([
  [OpportunityFailureReason.OTHER_SERVICE_VISIT, 'opportunity.failureReason.otherServiceVisit'],
  [OpportunityFailureReason.CAR_SOLD, 'opportunity.failureReason.carSold'],
  [OpportunityFailureReason.OTHER, 'opportunity.failureReason.other'],
]) as Map<OpportunityFailureReason, string>

export enum OpportunityCreationMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export const OpportunityCreationModeNameMap: Map<OpportunityCreationMode, string> = Map([
  [OpportunityCreationMode.AUTOMATIC, 'opportunity.creationMode.automatic'],
  [OpportunityCreationMode.MANUAL, 'opportunity.creationMode.manual'],
]) as Map<OpportunityCreationMode, string>

export type OpportunityCarData = Pick<CarInfo, 'vin' | 'registrationNumber'>

export type OpportunityId = NominalString<OpportunityService>

export type OpportunityService = {
  readonly uuid: OpportunityId,
  readonly appointmentDate: Date | null,
  readonly car: OpportunityCarData,
  readonly client: ClientBasicData,
  readonly comments: ReadonlyArray<ServiceOpportunityInternalComment>,
  readonly created: Date,
  readonly creationMode: OpportunityCreationMode,
  readonly dealerLocation: DealerLocation | null,
  readonly description: string,
  readonly lastStatusUpdate: Date,
  readonly lastPushDate: Date,
  readonly lastServiceDate: Date,
  readonly leadSourceName: LeadSourceType,
  readonly locationKey: DealerLocationKey,
  readonly nextServiceDate: Date,
  readonly pushCount: number,
  readonly retryDate: Date | null,
  readonly services: ReadonlyArray<CarService>,
  readonly serviceKey: string,
  readonly startDate: Date,
  readonly status: OpportunityStatus,
  readonly type: OpportunityType,
  readonly failureReason: OpportunityFailureReason,
  readonly serviceRequestUuid?: ServiceRequestId,
  readonly assignee: UserWithoutPermissions | null,
} & OpportunityAccomplishmentDetails

export const opportunityUnassigned: UserId = 'UNASSIGNED' as UserId

export type OpportunityInternalCommentDto = Pick<ServiceOpportunityInternalComment, 'attachments' | 'author' | 'comment'>

export type OpportunitySendCommentDto = {
  readonly attachments?: ReadonlyArray<Url>,
  readonly comment: string,
}

export type UpdateOpportunityFromDraftDto = { readonly opportunity: OpportunityService } & {
  readonly attachments: ReadonlyArray<Attachment>,
  readonly comment: string,
}

export type OpportunityArrangedDto = Partial<OpportunitySendCommentDto> & {
  readonly author: UserWithoutPermissions,
  readonly appointmentDate: Date | null,
  readonly uuid: OpportunityId,
}

export type OpportunityManualDto = {
  readonly author: UserWithoutPermissions,
  readonly description: string,
  readonly serviceKey: ServiceKey,
  readonly startDate: string,
}

export type OpportunityManualPayload = {
  readonly opportunity: OpportunityManualDto,
  readonly vin: string,
}

export type OpportunityManualDtoForm = Omit<OpportunityManualDto, 'author'>

export type Lead = Pick<OpportunityService, 'lastServiceDate' | 'nextServiceDate' | 'car' | 'client' | 'leadSourceName'>

export type OpportunityAccomplishmentDetailsDto = Partial<OpportunitySendCommentDto> & OpportunityAccomplishmentDetails & {
  readonly author: UserWithoutPermissions,
  readonly appointmentDate: Date | null,
  readonly uuid: OpportunityId,
}

export type OpportunityRetryDto = OpportunitySendCommentDto & {
  readonly author: UserWithoutPermissions,
  readonly retryDate: Date | null,
  readonly uuid: OpportunityId,
}

export type OpportunityFailedDto = Partial<OpportunitySendCommentDto> & { // TODO: Send author on every status change
  readonly author: UserWithoutPermissions,
  readonly failureReason: OpportunityFailureReason,
  readonly uuid: OpportunityId,
}

export type OpportunityMissedDto = OpportunitySendCommentDto & {
  readonly author: UserWithoutPermissions,
  readonly uuid: OpportunityId,
}

export const createOpportunity = (data: any) => ({ // tslint:disable-line no-any
  ...data,
  appointmentDate: parseToDateOrNull(data.appointmentDate),
  lastPushDate: parseToDateOrNull(data.lastPushDate),
  lastServiceDate: parseToDateOrNull(data.lastServiceDate),
  nextServiceDate: parseToDateOrNull(data.nextServiceDate),
  retryDate: parseToDateOrNull(data.retryDate),
  startDate: parseToDateOrNull(data.startDate),
})

export const createAccomplishmentDetailsDto = (accomplishmentDetails: OpportunityAccomplishmentDetails) =>
({
  ...accomplishmentDetails,
  labourProfit: toPriceString(accomplishmentDetails.labourProfit),
  partsProfit: toPriceString(accomplishmentDetails.partsProfit),
})

export type ServiceOpportunityInternalCommentId = NominalString<ServiceOpportunityInternalComment>

export type ServiceOpportunityInternalComment = {
  readonly uuid: ServiceOpportunityInternalCommentId,
  readonly created: Date,
  readonly updated: Date,
  readonly comment: string,
  readonly author: UserWithoutPermissions,
  readonly opportunityStatus: OpportunityStatus,
  readonly attachments: ReadonlyArray<Url>,
}
