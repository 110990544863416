import { Loader } from 'actff-bo-app/components/Loader'
import { WithPagination } from 'actff-bo-app/components/Table/Pagination'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { Client } from 'actff-bo-lib/client'
import { getClientsWithAxios } from 'actff-bo-lib/client/dao'
import { Path } from 'actff-bo-lib/menu/dto'
import { Paths } from 'actff-bo-lib/menu/initial-menu'
import React, { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AppPersonList } from './PersonList'
import { SearchPersonDialog } from './SearchPersonDialog'

type Props = {
  readonly open: boolean,
  readonly onClose: () => void,
  readonly onConfirm: (person: Client) => void,
}

const testId = 'search-app-person__'

export const SearchAppPerson: FC<Props> = ({ onClose, onConfirm, open }) => {
  const [selectedAppPerson, setSelectedAppPerson] = useState<Client | null>(null)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)

  const handleSelectPerson = (person: Client) => { setSelectedAppPerson(person) }

  const { data: clients, refetch: getClients, isLoading } = useQuery(QueryKeys.GET_CLIENTS, async () =>
      await getClientsWithAxios({ page: currentPage - 1, searchText: searchPhrase }),
    { retry: false },
  )

  useEffect(() => { getClients() }, [searchPhrase, currentPage])

  const getClientsList = () => clients?.result || []
  const getPageCount = () => clients?.noOfPages || 0
  const getRowsInCurrentPage = () => clients?.result.length || 0

  const handleConfirm = () => {
    if (selectedAppPerson) {
      onConfirm(selectedAppPerson)
      setSelectedAppPerson(null)
    }
  }

  const handleClose = () => {
    setSearchPhrase('')
    setSelectedAppPerson(null)
    onClose()
  }

  return (
    <SearchPersonDialog
      confirmDisabled={!selectedAppPerson}
      header='ownership.searchPerson.app.header'
      onClose={handleClose}
      onConfirm={handleConfirm}
      onSearch={setSearchPhrase}
      searchPhrase={searchPhrase}
      open={open}
      testId={`${testId}dialog`}
    >
      {
        isLoading ? <Loader /> : (
          <WithPagination
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            pageCount={getPageCount()}
            path={Paths.CarViewBasicData as Path}
            rowsInCurrentPage={getRowsInCurrentPage()}
            testId={testId}
          >
            <AppPersonList
              list={getClientsList()}
              onPersonClick={handleSelectPerson}
              selectedPerson={selectedAppPerson}
            />
          </WithPagination>
        )
      }
    </SearchPersonDialog>
  )
}
