import { api, PagedResult } from 'actff-bo-lib/api'
import { Car, CarInfo } from 'actff-bo-lib/car'
import { ClientId } from 'actff-bo-lib/client'
import { buildUrl, Filters, getFiltersAsObject, getSimpleFiltersAsObject } from 'actff-bo-lib/global'
import { Observable } from 'rxjs'

import { DealerLocationKey } from '../dealership'

import { OfferRequest, OfferRequestCount, OfferRequestFilterValues, OfferRequestId, UpdateOfferRequestDto } from './dto'

export const getOffer = (uuid: OfferRequestId): Observable<OfferRequest> => api.get<OfferRequest>(`/offer-request/${uuid}`)

export const getNewOfferRequestCount = () => api.get<OfferRequestCount>('/offer-request/count/new')

export const getOfferCars = (clientUuid: ClientId): Observable<PagedResult<CarInfo>> =>
  api.get<PagedResult<Car>>(`/car?clientUuid=${clientUuid}`)

export const getOffers = (
  filters: Filters<OfferRequestFilterValues>,
  page: number,
  searchText?: string,
  location?: ReadonlyArray<DealerLocationKey>,
  brand?: ReadonlyArray<string>,
): Observable<PagedResult<OfferRequest>> =>
  api.get<PagedResult<OfferRequest>>(
    buildUrl('/offer-request', {
      page,
      ...getFiltersAsObject(filters),
      searchText,
      ...getSimpleFiltersAsObject({ location, brand }),
    }),
  )

export const updateOffer = (dto: UpdateOfferRequestDto) => api.patch(`/offer-request/${dto.uuid}`, { status: dto.status })
