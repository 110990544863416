import { CheckboxInputField, Field } from 'actff-bo-app/components/Form'
import { ValidationError } from 'actff-bo-app/components/ValidationError'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'

import { LabelStyled } from '../Styled'

type Props = Testable & {
  readonly t: i18next.TFunction,
}

const StatusStyled = styled.div`
  display: grid;
  grid-area: car-status;
`

const CheckboxContainer = styled.div`
  display: grid;
`

const clientCarStatusFormikID = 'carStatuses'
const clientAccountStatusFormikID = 'clientStatuses'

// /* Temporary solution due to AUT-1328
enum ClientListClientCarStatusFilterType {
  NoCars = 'NO_CARS',
  UnapprovedCars = 'UNAPPROVED_CARS',
  AllCarsApproved = 'ALL_CARS_APPROVED',
}

export const Status: React.FC<Props> = ({ testId, t }) => (
  <StatusStyled>
    <LabelStyled dark={true} uppercase={false}>Status</LabelStyled>
    <CheckboxContainer>
      {
        Object.keys(ClientListClientCarStatusFilterType).map(statusFilter => (
          <Field
            label={t(`client.status.${statusFilter}`)}
            id={`${clientCarStatusFormikID}[${statusFilter}]`}
            name={`${clientCarStatusFormikID}[${statusFilter}]`}
            component={CheckboxInputField}
            key={statusFilter}
            slim={true}
            testId={`${testId}car-status--${statusFilter}`}
          />
        ))
      }
      {/* Temporary solution due to AUT-1328 */}
      <Field
        label={t('client.status.Deleted')}
        id={`${clientAccountStatusFormikID}.Deleted`}
        name={`${clientAccountStatusFormikID}.Deleted`}
        component={CheckboxInputField}
        slim={true}
        testId={`${testId}status--deleted`}
      />
      <Field
        label={t('client.status.Archived')}
        id={`${clientAccountStatusFormikID}.Locked`}
        name={`${clientAccountStatusFormikID}.Locked`}
        component={CheckboxInputField}
        slim={true}
        testId={`${testId}status--locked`}
      />
    </CheckboxContainer>
    <ValidationError name='type' />
  </StatusStyled>
)
