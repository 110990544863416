import { ClientFullName, PersonType } from 'actff-bo-app/components/Client'
import { Label } from 'actff-bo-app/components/Label'
import { Client } from 'actff-bo-lib/client'
import { formatPhoneNumber } from 'actff-bo-lib/client/helpers'
import React, { FC } from 'react'

import { LabelStyled, Td, Tr } from '../../Styled'

type Props = {
  readonly onClick: (person: Client) => void,
  readonly person: Client,
  readonly selected: boolean,
  readonly testId: string,
}

export const AppPersonRow: FC<Props> = ({ onClick, selected, person, testId }) => (
  <Tr onClick={onClick} selected={selected}>
    <Td dataTestId={`${testId}-selected`}><Label><input type='radio' readOnly={true} checked={selected} /><LabelStyled /></Label> </Td>
    <Td dataTestId={`${testId}-full-name`}><ClientFullName client={person} /></Td>
    <Td dataTestId={`${testId}-phone`}>{formatPhoneNumber(person.phone)}</Td>
    <Td dataTestId={`${testId}-email`}>{person.email}</Td>
    <Td dataTestId={`${testId}-id`}>{person.id}</Td>
    <Td dataTestId={`${testId}-type`}><PersonType person={person} /></Td>
  </Tr>
)
