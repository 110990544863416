import { RadioButtonField } from 'actff-bo-app/components/Form'
import { OpportunityFailureReason as OpportunityFailureReasonType, OpportunityFailureReasonNameMap } from 'actff-bo-lib/crm/service'
import { Field, FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'
import { colors } from 'styles'
import { mixed, object } from 'yup'

type Values = {
  readonly failureReason?: OpportunityFailureReasonType,
}

type Props = {
  readonly editMode?: boolean,
  readonly failureReason?: OpportunityFailureReasonType,
  readonly onChange: (values: Values) => void,
}

type OpportunityFailureReasonProps = Props & FormikProps<Values> & WithTranslation

const FailureReasonContainer = styled.div`
  border-top: 1px solid ${colors.mystic};
  display: grid;
  grid-auto-flow: rows;
  padding: 24px 32px;
`

class OpportunityFailureReasonComponent extends React.Component<OpportunityFailureReasonProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: OpportunityFailureReasonProps): void {
    if (nextProps.values !== this.props.values) {
      this.props.onChange(nextProps.values)
    }
  }

  public render(): React.ReactNode {
    const { editMode = true, t } = this.props

    return (
      <FailureReasonContainer>
        <label htmlFor='failureReason'>
          {t('opportunity.failureReason.caption')}
        </label>
        <Field
          component={RadioButtonField}
          disabled={!editMode}
          htmlFor='failureReasonCarSold'
          id='failureReasonCarSold'
          label={t(OpportunityFailureReasonNameMap.get(OpportunityFailureReasonType.CAR_SOLD, ''))}
          name='failureReason'
          value={OpportunityFailureReasonType.CAR_SOLD}
        />
        <Field
          component={RadioButtonField}
          disabled={!editMode}
          htmlFor='failureReasonOther'
          id='failureReasonOther'
          label={t(OpportunityFailureReasonNameMap.get(OpportunityFailureReasonType.OTHER, ''))}
          name='failureReason'
          value={OpportunityFailureReasonType.OTHER}
        />
        <Field
          component={RadioButtonField}
          disabled={!editMode}
          htmlFor='failureReasonOtherServiceVisit'
          id='failureReasonOtherServiceVisit'
          label={t(OpportunityFailureReasonNameMap.get(OpportunityFailureReasonType.OTHER_SERVICE_VISIT, ''))}
          name='failureReason'
          value={OpportunityFailureReasonType.OTHER_SERVICE_VISIT}
        />
      </FailureReasonContainer>
    )
  }
}

const validationSchema = () => object<Values>().shape({
  failureReason: mixed().required().oneOf(Object.values(OpportunityFailureReasonType)),
})

const formik = withFormik<Props, Values>({
  handleSubmit: () => null,
  mapPropsToValues: ({ failureReason }) => ({ failureReason }),
  validationSchema,
})

export const OpportunityFailureReason = compose(
  formik,
  withTranslation(),
)(OpportunityFailureReasonComponent)
