import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectOpportunityPurchaseFeature = (state: State) => state.crmTrade.purchase

export const selectOpportunityPurchaseListByType = (type: OpportunityListType) =>
  createSelector(selectOpportunityPurchaseFeature, opportunity => opportunity[type])

export const selectOpportunityPurchaseListCurrentPage = (type: OpportunityListType) =>
  createSelector(selectOpportunityPurchaseListByType(type), list => list.currentPage)

export const selectOpportunityPurchaseListTimeFrom =
  (type: OpportunityListType) => createSelector(selectOpportunityPurchaseListByType(type), list => list.from)

export const selectOpportunityPurchaseListTimeTo =
  (type: OpportunityListType) => createSelector(selectOpportunityPurchaseListByType(type), list => list.to)

export const selectOpportunityPurchaseList = (type: OpportunityListType) =>
  createSelector(selectOpportunityPurchaseListByType(type), list => list.list)

export const selectCurrentOpportunityPurchase =
  createSelector(selectOpportunityPurchaseFeature, purchase => purchase.currentOpportunity)

export const selectOpportunityPurchaseSearchPhrase =
  createSelector(selectOpportunityPurchaseFeature, purchase => purchase.searchPhrase)

export const selectOpportunityPurchaseListsStatusFilters =
  createSelector(selectOpportunityPurchaseFeature, purchase => purchase.statusFilters)

export const selectOpportunityPurchaseListsDetailedFilters =
  createSelector(selectOpportunityPurchaseFeature, purchase => purchase.detailedFiltersFormValues)

export const selectOpportunityPurchaseListsStringifiedDetailedFilters =
  createSelector(selectOpportunityPurchaseFeature, purchase => purchase.stringifiedDetailedFilters)

export const selectOpportunityPurchaseListAssignmentFilter =
  createSelector(selectOpportunityPurchaseFeature, purchase => purchase.assignmentFilter)

export const selectMatchedSaleOpportunities =
  createSelector(selectOpportunityPurchaseFeature, purchase => purchase.currentMatchedSaleOpportunities)
