import { SelectOption } from 'actff-bo-lib/global'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly onChange: (value: string) => void,
  readonly options: ReadonlyArray<SelectOption<string>>,
  readonly transKey?: string,
  readonly value: string,
}

export const ReportSelect: React.FC<Props> = ({ onChange, options, transKey = '', value }) => {
  const { t } = useTranslation()

  return (
    <div className='rdg-filter-container'>
      <select
        className='rdg-filter'
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        <option value=''>{t('caption.all')}</option>
        {options.map(({ value: optionValue, label }) => (
          <option key={optionValue} value={`${transKey}${optionValue}`}>{t(`${transKey}${label}`)}</option>
        ))}
      </select>
    </div>
  )
}
