// tslint:disable max-file-line-count
import { BtnType } from 'actff-bo-app/components/Button'
import { CollapsableSection, CollapsableSectionWithCheckbox } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput, FormattedDate } from 'actff-bo-app/components/DateTime'
import { Dialog } from 'actff-bo-app/components/Dialog'
import {
  FieldValue,
  FormCell,
  FormFooter,
  FormFooterLeft,
  FormFooterRight,
  FormHeader,
  MultipleChoiceFieldGroup,
} from 'actff-bo-app/components/Form'
import { Icon } from 'actff-bo-app/components/Icon'
import { Rating } from 'actff-bo-app/components/Rating'
import { Tooltip } from 'actff-bo-app/components/Tooltip'
import {
  CarInfoWithClient,
  ChoiceElementStatus,
  ChoiceElementStatusValues,
  FormValuesT,
  FormValueT,
  getCommentAttachments,
  getInspectionDefaultValues,
  getInspectionDto,
  getPaintCoatAttachments,
  Inspection,
  InspectionBasicDataOrigin,
  InspectionChoiceElement,
  InspectionCommentType,
  InspectionDto,
  InspectionPaintCoatFormElement,
  mapPaintCoatToDefaultValues,
} from 'actff-bo-lib/car'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { Attachment } from 'actff-bo-lib/global'
import { selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { IconType } from 'actff-bo-lib/menu'
import { selectMeWithoutPermissions } from 'actff-bo-lib/user'
import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { PrimaryButton } from 'styles'

import { Photos, RoadTestAdditionalInformation } from './Sections'
import { Comments } from './Sections/Comments'
import { ComputerDiagnostic } from './Sections/ComputerDiagnostic'
import { PaintCoat } from './Sections/PaintCoat'
import {
  BasicData,
  BasicDataSecondContainer,
  Form,
  KnownMileageContainer,
  KnownMileageFakeContainer,
  OriginValuesContainer,
  RemarkSection,
  SectionErrorContainer,
  Separator,
  Subheader,
} from './Styled'

type Props = {
  readonly car: CarInfoWithClient,
  readonly defaultValues: Inspection,
  readonly handleSubmit: (inspection: InspectionDto, attachments?: ReadonlyArray<Attachment>) => void,
}

const testId = 'car-view-inspection-form__'

export const InspectionForm: FC<Props> = ({ car, defaultValues, handleSubmit }) => {
  const { t } = useTranslation()
  const currentLanguage = useSelector(selectCurrentLanguage)
  const me = useSelector(selectMeWithoutPermissions)
  const [ratingModalVisible, toggleRatingModalVisible] = useState(false)
  const [paintCoat, setPaintCoat] = useState<FormValueT<InspectionPaintCoatFormElement>>({})

  const {
    control,
    formState,
    register,
    handleSubmit: RHFHandleSubmit,
    watch,
    setValue,
  } = useForm<FormValuesT>({ defaultValues: getInspectionDefaultValues(car, me, defaultValues) })

  useEffect(() => {
    setPaintCoat(mapPaintCoatToDefaultValues(t, defaultValues.paintCoat))
  }, [t, defaultValues])

  const { claims = 0, evaluations = 0, expenses = 0 } = defaultValues

  const handleToggleRatingModalVisible = () => toggleRatingModalVisible(!ratingModalVisible)

  const onSubmit = RHFHandleSubmit((submittedValues: FormValuesT) => {

    const { attachments = [], ...inspection } = submittedValues
    const paintCoatAttachments = getPaintCoatAttachments(paintCoat)
    const commentAttachments = getCommentAttachments(inspection.comments)

    const allAttachments: ReadonlyArray<Attachment> = [...attachments, ...paintCoatAttachments, ...commentAttachments]

    handleSubmit(getInspectionDto(inspection, paintCoat, me), allAttachments)
    handleToggleRatingModalVisible()
  })

  const handleSetPaintCoat = (updatedPaintCoat: FormValueT<InspectionPaintCoatFormElement>) => {
    setPaintCoat(prevState => ({ ...prevState, ...updatedPaintCoat}))
  }

  const getCommentsForSection = (section: InspectionCommentType) =>
    defaultValues.comments?.filter(comment => comment.section === section)

  const getFilledForSection = (elements: ReadonlyArray<InspectionChoiceElement>) => {
    const elementsFilled = elements.filter(element => element.status).length
    const allElements = elements.length
    const hasErrors = elements.some(element => element.status === ChoiceElementStatusValues.NOT_OK)

    return (
      <>
        {t('carView.inspection.form.filled')} {elementsFilled} / {allElements}
        {hasErrors && (
          <SectionErrorContainer>
            <Tooltip title={t('carView.inspection.sectionError')}>
              <Icon type={IconType.ErrorCircle} />
            </Tooltip>
          </SectionErrorContainer>
        )}
      </>
    )
  }
  const inspectionDate = watch('basicData.inspectionDate') || new Date()

  // tslint:disable-next-line cyclomatic-complexity
  return (
    <Form onSubmit={onSubmit}>
      <FormCell>
        <FormHeader>{t('carView.inspection.form.basicData.firstHeader')}</FormHeader>
      </FormCell>
      <BasicData>
        <FormCell>
          <label>{t('carView.inspection.form.carModel')}:</label>
          <FieldValue>{car.model}</FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carView.inspection.form.vin')}:</label>
          <FieldValue>{car.vin}</FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carView.inspection.form.registrationNumber')}:</label>
          <FieldValue>{car.registrationNumber}</FieldValue>
        </FormCell>
        <KnownMileageContainer>
          <FormCell>
            <label data-testid={`${testId}known-mileage--label`}>{t('carView.inspection.form.basicData.knownMileage')}:</label>
            <input data-testid={`${testId}known-mileage`} name='basicData.knownMileage' ref={register} type='number' />
          </FormCell>
          <KnownMileageFakeContainer>
            <input
              data-testid={`${testId}known-mileage-fake`}
              id='known-mileage-fake'
              name='basicData.knownMileageFake'
              ref={register}
              type='checkbox'
            />
            <label
              data-testid={`${testId}known-mileage-fake--label`}
              htmlFor='known-mileage-fake'
            >
              {t('carView.inspection.form.basicData.knownMileageFake')}
            </label>
          </KnownMileageFakeContainer>
        </KnownMileageContainer>
        <FormCell>
          <label data-testid={`${testId}model-year--label`}>{t('carView.inspection.form.basicData.modelYear')}:</label>
          <FieldValue>{car.modelYear}</FieldValue>
        </FormCell>
        <FormCell>
          <label data-testid={`${testId}version--label`}>{t('carView.inspection.form.basicData.version')}:</label>
          <FieldValue>{car.version}</FieldValue>
        </FormCell>
      </BasicData>
      <BasicData>
        <FormCell>
          <label data-testid={`${testId}last-service-date--label`}>{t('carView.inspection.form.basicData.lastServiceDate')}:</label>
          <Controller
            render={({ value }) => <DatePickerInput disabled={true} date={value} testId={`${testId}last-service-date`} />}
            control={control}
            name='basicData.lastServiceDate'
          />
        </FormCell>
        <FormCell>
          <label data-testid={`${testId}mot-date--label`}>{t('carView.inspection.form.basicData.motDate')}:</label>
          <Controller
            render={({ value }) => <DatePickerInput disabled={true} date={value} testId={`${testId}mot-date`} />}
            control={control}
            name='basicData.motDate'
          />
        </FormCell>
        <FormCell>
          <label>{t('carView.inspection.form.basicData.firstRegistrationDate')}:</label>
          <FormattedDate date={car.firstRegistrationDate} format={defaultDateFormat} />
        </FormCell>
        <FormCell>
          <label>{t('carView.inspection.form.basicData.origin')}:</label>
          <OriginValuesContainer>
            {Object.values(InspectionBasicDataOrigin).map(value => (
              <React.Fragment key={value}>
                <input
                  data-testid={`${testId}origin--${value}`}
                  id={`basicData-${value}`}
                  name='basicData.origin'
                  type='radio'
                  ref={register}
                  value={value}
                />
                <label data-testid={`${testId}--${value}-label`} htmlFor={`basicData-${value}`}>
                  {t(`carView.inspection.form.basicData.origin.${value}`)}
                </label>
              </React.Fragment>
            ))}
          </OriginValuesContainer>
        </FormCell>
      </BasicData>
      <Separator />
      <RemarkSection>
        <div>
          <FormCell>
            <FormHeader>{t('carView.inspection.form.basicData.secondHeader')}</FormHeader>
          </FormCell>
          <BasicDataSecondContainer>
            <FormCell>
              <label data-testid={`${testId}author--label`}>{t('carView.inspection.form.basicData.author')}:</label>
              <input data-testid={`${testId}author`} disabled={true} name='basicData.author' ref={register} type='text' />
            </FormCell>
            <FormCell>
              <label data-testid={`${testId}inspection-date--label`}>{t('carView.inspection.form.basicData.inspectionDate')}:</label>
              <Controller
                as={<DatePickerInput date={inspectionDate} testId={`${testId}inspection-date`} />}
                control={control}
                name='basicData.inspectionDate'
              />
            </FormCell>
          </BasicDataSecondContainer>
        </div>
      </RemarkSection>
      <PaintCoat
        elements={(Object.keys(paintCoat).length > 0 && paintCoat) || mapPaintCoatToDefaultValues(t, defaultValues.paintCoat)}
        setPaintCoatValue={handleSetPaintCoat}
      />
      <Separator />
      <FormCell>
        <FormHeader>{t('carView.inspection.form.basicData.thirdHeader')}</FormHeader>
      </FormCell>
      <CollapsableSection
        testId={`${testId}road-test`}
        title={t('carView.inspection.form.roadTest.header')}
        subtitle={getFilledForSection(defaultValues.roadTest)}
      >
        <MultipleChoiceFieldGroup
          additionalDescription={RoadTestAdditionalInformation}
          control={control}
          elements={defaultValues.roadTest}
          group='roadTest'
          register={register}
          setValue={setValue}
          testId={`${testId}road-test`}
          values={ChoiceElementStatus}
        />
        <Comments
          control={control}
          comments={getCommentsForSection(InspectionCommentType.ROAD_TEST)}
          currentLanguage={currentLanguage}
          name={`comments[${InspectionCommentType.ROAD_TEST}]`}
          refAttr={register}
          setValue={setValue}
          testId={`${testId}comments-road-test`}
        />
      </CollapsableSection>
      <CollapsableSection
        testId={`${testId}visual-quality-control`}
        title={t('carView.inspection.form.visualQualityControl.header')}
        subtitle={getFilledForSection(defaultValues.visualQualityControl)}
      >
        <MultipleChoiceFieldGroup
          control={control}
          elements={defaultValues.visualQualityControl}
          group='visualQualityControl'
          register={register}
          setValue={setValue}
          testId={`${testId}visual-quality-control`}
          values={ChoiceElementStatus}
        />
        <Comments
          control={control}
          comments={getCommentsForSection(InspectionCommentType.VISUAL_QUALITY_CONTROL)}
          currentLanguage={currentLanguage}
          name={`comments[${InspectionCommentType.VISUAL_QUALITY_CONTROL}]`}
          refAttr={register}
          setValue={setValue}
          testId={`${testId}comments-visual-quality-control`}
        />
      </CollapsableSection>

      <CollapsableSection
        testId={`${testId}workshop-control`}
        title={t('carView.inspection.form.workshopControl.header')}
        subtitle={getFilledForSection([...defaultValues.workshopControl.functionalCheck, ...defaultValues.workshopControl.motCheck || []])}
      >
        <ComputerDiagnostic elements={defaultValues.workshopControl.computerDiagnostic} register={register} />
        <Subheader>{t('carView.inspection.form.workshopControl.functionalCheck.header')}</Subheader>
        <MultipleChoiceFieldGroup
          control={control}
          elements={defaultValues.workshopControl.functionalCheck}
          group='workshopControl.functionalCheck'
          register={register}
          setValue={setValue}
          testId={`${testId}functional-check`}
          values={ChoiceElementStatus}
        />
        <Comments
          control={control}
          comments={getCommentsForSection(InspectionCommentType.FUNCTIONAL_CHECK)}
          currentLanguage={currentLanguage}
          name={`comments[${InspectionCommentType.FUNCTIONAL_CHECK}]`}
          refAttr={register}
          setValue={setValue}
          testId={`${testId}comments-functional-check`}
        />
        {defaultValues.workshopControl.motCheck?.length > 0 && (
          <>
            <Subheader>{t('carView.inspection.form.workshopControl.motCheck.header')}</Subheader>
            <MultipleChoiceFieldGroup
              control={control}
              elements={defaultValues.workshopControl.motCheck}
              group='workshopControl.motCheck'
              register={register}
              setValue={setValue}
              testId={`${testId}mot-check`}
              values={ChoiceElementStatus}
            />
            <Comments
              control={control}
              comments={getCommentsForSection(InspectionCommentType.MOT_CHECK)}
              currentLanguage={currentLanguage}
              name={`comments[${InspectionCommentType.MOT_CHECK}]`}
              refAttr={register}
              setValue={setValue}
              testId={`${testId}comments-mot-check`}
            />
          </>
        )}
      </CollapsableSection>
      <Separator />
      <FormCell>
        <FormHeader>{t('carView.inspection.form.basicData.fourthHeader')}</FormHeader>
      </FormCell>
      <CollapsableSectionWithCheckbox
        expandable={!!claims}
        testId={`${testId}claims`}
        title={`${t('carView.inspection.form.claims.header')} (${claims})`}
      >
        <label>{t('carView.inspection.form.claims.description')}:</label>
        <br />
        <input type='number' name='claims' ref={register} />
        <Comments
          control={control}
          comments={getCommentsForSection(InspectionCommentType.CLAIMS)}
          currentLanguage={currentLanguage}
          name={`comments[${InspectionCommentType.CLAIMS}]`}
          refAttr={register}
          setValue={setValue}
          testId={`${testId}comments-claims`}
        />
      </CollapsableSectionWithCheckbox>
      <CollapsableSectionWithCheckbox
        expandable={!!expenses}
        testId={`${testId}expenses`}
        title={`${t('carView.inspection.form.expenses.header')} (${expenses})`}
      >
        <label>{t('carView.inspection.form.expenses.description')}:</label>
        <br />
        <input type='number' name='expenses' ref={register} />
        <Comments
          control={control}
          comments={getCommentsForSection(InspectionCommentType.EXPENSES)}
          currentLanguage={currentLanguage}
          name={`comments[${InspectionCommentType.EXPENSES}]`}
          refAttr={register}
          setValue={setValue}
          testId={`${testId}comments-expenses`}
        />
      </CollapsableSectionWithCheckbox>
      <CollapsableSectionWithCheckbox
        expandable={!!evaluations}
        testId={`${testId}evaluations`}
        title={`${t('carView.inspection.form.evaluations.header')} (${evaluations})`}
      >
        <label>{t('carView.inspection.form.evaluations.description')}:</label>
        <br />
        <input type='number' name='evaluations' ref={register} />
        <Comments
          control={control}
          comments={getCommentsForSection(InspectionCommentType.EVALUATIONS)}
          currentLanguage={currentLanguage}
          name={`comments[${InspectionCommentType.EVALUATIONS}]`}
          refAttr={register}
          setValue={setValue}
          testId={`${testId}comments-evaluations`}
        />
      </CollapsableSectionWithCheckbox>
      <Photos control={control} photoUrls={defaultValues.photos} formFieldName='attachments' setValue={setValue} />
      <FormCell>
        <FormHeader>
          {t('carView.additionalRemarks.header')}&nbsp;
          ({getCommentsForSection(InspectionCommentType.ADDITIONAL_REMARKS)?.length || 0})
        </FormHeader>
        <Comments
          additionalRemarks={true}
          control={control}
          comments={getCommentsForSection(InspectionCommentType.ADDITIONAL_REMARKS)}
          currentLanguage={currentLanguage}
          name={`comments[${InspectionCommentType.ADDITIONAL_REMARKS}]`}
          refAttr={register}
          setValue={setValue}
          testId={`${testId}additional-remarks`}
        />
      </FormCell>
      <Dialog
        content='carView.inspection.modal.content'
        confirmDisabled={formState.isSubmitting}
        onClose={handleToggleRatingModalVisible}
        onConfirm={onSubmit}
        open={ratingModalVisible}
        title='carView.inspection.modal.header'
      >
        <Rating name='rating' control={control} />
      </Dialog>
      <FormFooter>
        <FormFooterLeft />
        <FormFooterRight>
          <PrimaryButton
            data-testid={`${testId}button-submit`}
            onClick={handleToggleRatingModalVisible}
            type={BtnType.Button}
          >
            {t('caption.save')}
          </PrimaryButton>
        </FormFooterRight>
      </FormFooter>
    </Form>
  )
}
