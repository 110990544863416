export type DashboardSummaryData = {
  readonly clients: {
    readonly unapprovedCars: number,
    readonly all: number,
  },
  readonly cars: {
    readonly unapproved: number,
    readonly all: number,
  },
  readonly serviceRequests: {
    readonly statusNew: number,
    readonly all: number,
    readonly createdByClients: number,
    readonly createdByBackoffice: number,
  },
}

export type DashboardDataFromApi = ReadonlyArray<{
  readonly date: string,
  readonly values: ReadonlyArray<number>,
}>

export type DashboardData = ReadonlyArray<{
  readonly label: string | ReadonlyArray<string>,
  readonly values: ReadonlyArray<number>,
}>

export enum DashboardDataUrlType {
  Clients = '/dashboard/clients',
  Cars = '/dashboard/cars',
  NewOpportunities = '/crm/dashboard/opportunity/new',
  AccomplishedOpportunitiesWithStartDate = '/crm/dashboard/opportunity/accomplished-with-start-date',
  AccomplishedOpportunitiesWithAccomplishDate = '/crm/dashboard/opportunity/accomplished-with-accomplish-date',
  ServiceRequests = '/dashboard/serviceRequests',
}

export enum DashboardDataGroupByType {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR',
}

export type DashboardDataTypeOption = {
  readonly label: string,
  readonly value: DashboardDataUrlType,
}

export const DashboardTypeNameMap: Map<DashboardDataUrlType, string> = new Map([
  [DashboardDataUrlType.Clients, 'dashboard.type.clients'],
  [DashboardDataUrlType.Cars, 'dashboard.type.cars'],
  [DashboardDataUrlType.NewOpportunities, 'dashboard.type.newOpportunities'],
  [DashboardDataUrlType.AccomplishedOpportunitiesWithStartDate, 'dashboard.type.accomplishedWithStartDate'],
  [DashboardDataUrlType.AccomplishedOpportunitiesWithAccomplishDate, 'dashboard.type.accomplishedWithAccomplishDate'],
  [DashboardDataUrlType.ServiceRequests, 'dashboard.type.serviceRequests'],
])

export const DashboardTypeLegendMap: Map<DashboardDataUrlType, ReadonlyArray<string>> = new Map([
  [DashboardDataUrlType.ServiceRequests, ['dashboard.legend.createdBy.client', 'dashboard.legend.createdBy.backoffice']],
])

export const toDashboardData = (fromApi: DashboardDataFromApi): DashboardData => fromApi.map(element => ({
  ...element,
  label: element.date,
}))
