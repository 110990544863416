export type RouteParameters = {
  readonly [key: string]: any, // tslint:disable-line no-any
}

export const createRoute = <T extends string>(route: T, parameters?: RouteParameters): string =>
  parameters
    ? Object.keys(parameters)
        .reduce(
          (acc: string, key: string) => acc.replace(`:${key}`, parameters[key] && parameters[key].toString()),
          route,
        )
    : route
