import { withClientViewHeader } from 'actff-bo-app/Clients/ClientView/withClientViewHeader'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { Padder } from 'actff-bo-app/components/Padder'
import { MatchedPurchaseOpportunities } from 'actff-bo-app/Crm/Trade/MatchedOpportunities/MatchedPurchaseOpportunities'
import { MatchedSaleOpportunities } from 'actff-bo-app/Crm/Trade/MatchedOpportunities/MatchedSaleOpportunities'
import { Client, ClientAction } from 'actff-bo-lib/client'
import { UserWithoutPermissions } from 'actff-bo-lib/user'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

type Props = {
  readonly client: Client,
  readonly currentUser: UserWithoutPermissions,
}

const MatchedPurchaseOpportunitiesStyled = styled(MatchedPurchaseOpportunities)`
  padding-top: 24px;
`

const OpportunitiesComponent: FC<Props> = ({ client, currentUser }) => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(ClientAction.clearClientTradeData())
  }, [])

  return (
    <Padder padding='24px 0 100px'>
      <MatchedSaleOpportunities clientId={client.uuid} currentUser={currentUser} />
      <MatchedPurchaseOpportunitiesStyled clientId={client.uuid} currentUser={currentUser} />
      <FixedFooter />
    </Padder>
  )
}

export const Opportunities = withClientViewHeader(OpportunitiesComponent)
