import { Icon } from 'actff-bo-app/components/Icon'
import { ContactApproval } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type ApprovalCurrentStateProps = {
  readonly approvals: ReadonlyArray<ContactApproval>,
  readonly type: ApprovalGroupType,
}

type ApprovalHeaderTypeProps = {
  readonly type: ApprovalGroupType,
}

export enum ApprovalGroupType {
  Push = 'clientView.approvals.groupType.push',
  Email = 'clientView.approvals.groupType.email',
  SMS = 'clientView.approvals.groupType.sms',
  TermsAndConditions = 'clientView.approvals.groupType.termsAndConditions',
  PersonalData = 'clientView.approvals.groupType.personalData',
  Profiling = 'clientView.approvals.groupType.profiling',
}

const ApprovalGroupHeader = styled.span`
  font-weight: 600;
  padding: 12px 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: left;
  align-items: center;
  grid-column-gap: 8px;
`

const ApprovalHeaderIcon = styled(Icon)`
  width: 16px;
`

const ApprovalHeaderIconAndDescription: React.FC<ApprovalHeaderTypeProps> = ({ type }) => {
  const { t } = useTranslation()
  // tslint:disable cyclomatic-complexity
  switch (type) {
    case ApprovalGroupType.Push:
      return <><ApprovalHeaderIcon type={IconType.Ring} />{t(type)} </>
    case ApprovalGroupType.SMS:
      return <><ApprovalHeaderIcon type={IconType.Sms} />{t(type)} </>
    case ApprovalGroupType.Email:
      return <><ApprovalHeaderIcon type={IconType.EmailEnvelope} />{t(type)} </>
    case ApprovalGroupType.PersonalData:
    case ApprovalGroupType.Profiling:
    case ApprovalGroupType.TermsAndConditions:
      return <>{t(type)} </>
    default:
      return null
  }
  // tslint:enable
}

export const ApprovalHeaderWithCurrentState: React.FC<ApprovalCurrentStateProps> = ({ approvals, type }) => {
  const { t } = useTranslation()

  return (
    <ApprovalGroupHeader>
      <ApprovalHeaderIconAndDescription type={type} />
      {approvals[0].approved
        ? t('clientView.approvals.approved')
        : t('clientView.approvals.disapproved')
      }
      &nbsp;({t('clientView.approvals.currentState')})
    </ApprovalGroupHeader>
  )
}
