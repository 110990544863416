/* tslint:disable:max-line-length */
import React, { FC } from 'react'
import { Color } from 'styles'

type Props = {
  readonly engineCover?: Color,
  readonly frontBumper?: Color,
  readonly frontLeftDoor?: Color,
  readonly frontLeftFender?: Color,
  readonly frontRightDoor?: Color,
  readonly frontRightFender?: Color,
  readonly leftSill?: Color,
  readonly rearBumper?: Color,
  readonly rearLeftDoor?: Color,
  readonly rearLeftFender?: Color,
  readonly rearRightDoor?: Color,
  readonly rearRightFender?: Color,
  readonly rightSill?: Color,
  readonly roof?: Color,
  readonly trunkLid?: Color,
}

export const PaintCoatCarSvg: FC<Props> = ({
  engineCover = '#d6f0ff',
  frontBumper = '#d6f0ff',
  frontLeftDoor = '#d6f0ff',
  frontLeftFender= '#d6f0ff',
  frontRightDoor = '#d6f0ff',
  frontRightFender = '#d6f0ff',
  leftSill = '#d6f0ff',
  rearBumper = '#d6f0ff',
  rearLeftDoor = '#d6f0ff',
  rearLeftFender = '#d6f0ff',
  rearRightDoor = '#d6f0ff',
  rearRightFender = '#d6f0ff',
  rightSill = '#d6f0ff',
  roof = '#d6f0ff',
  trunkLid = '#d6f0ff',
}) => (
  <svg viewBox='0 0 960 640' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='960' height='640' fill='white'/>
    <path d='M758.03 320.002C758.03 334.636 756.636 367.111 756.636 367.111C756.545 369.317 756.129 371.498 755.402 373.582C750.915 386.637 742.965 398.227 732.402 407.114C721.839 416 709.059 421.85 695.43 424.037L668.032 428.438C608.796 427.402 549.561 428.437 490.345 427.422C417.908 426.287 359.907 429.413 359.907 429.413L288.347 428.537C272.614 428.349 257.245 423.778 243.967 415.336C230.689 406.894 220.028 394.917 213.182 380.75C209.776 373.661 208.007 365.897 208.005 358.032V282.37C208.005 274.498 209.774 266.727 213.182 259.631C220.014 245.446 230.669 233.448 243.948 224.988C257.227 216.528 272.603 211.942 288.347 211.745L359.907 210.869C359.907 210.869 417.908 213.935 490.345 212.86C549.561 211.924 608.796 212.86 668.032 211.824L695.35 216.225C708.977 218.418 721.754 224.27 732.316 233.156C742.878 242.042 750.829 253.629 755.322 266.68C756.048 268.765 756.464 270.945 756.557 273.151C756.557 273.151 758.03 305.367 758.03 320.002Z' fill='#F8F8F8' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M263.937 231.814C268.383 230.623 272.889 229.672 277.437 228.966C286.098 227.612 292.45 227.533 305.053 227.373L329.763 227.075C338.902 227.075 351.307 227.075 366.201 227.075' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M486.583 225.364L510.217 225.424' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M493.328 212.54C494.552 214.131 495.581 215.862 496.394 217.697C497.468 220.135 498.141 222.73 498.385 225.383L508.341 243.303' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M499.351 243.343L517.37 243.303' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M599.479 226.836L609.892 227.154C610.211 226.995 617.697 223.013 617.618 216.323C617.605 214.837 617.236 213.376 616.542 212.062' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M582.573 240.974L590.916 240.436' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M386.924 220.585C388.916 224.029 395.307 225.264 399.19 223.034C400.663 222.198 401.34 221.043 404.725 212.182C408.349 202.665 408.468 201.49 407.791 200.733C405.939 198.742 399.508 201.112 395.307 204.218C390.329 207.882 384.077 215.766 386.924 220.585Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M218.043 269.068C225.291 264.329 263.68 239.659 295.995 235.717C307.783 234.263 322.179 234.064 322.179 234.064C332.43 233.932 342.68 234.378 352.882 235.398' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M695.028 247.483C726.448 244.835 737.877 246.906 742.078 249.614L750.46 255.129' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M433.083 248.858C460.361 250.849 489.173 252.262 519.418 252.7C555.258 253.198 589.106 252.342 620.785 250.55' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M484.711 215.13C484.711 213.976 478.937 212.96 472.008 212.88C465.079 212.801 459.544 213.657 459.623 214.872C459.703 216.086 465.378 217.022 472.307 217.102C479.236 217.181 484.851 216.206 484.711 215.13Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M603.577 214.611C603.577 213.457 597.803 212.461 590.874 212.361C583.945 212.262 578.409 213.138 578.489 214.353C578.569 215.567 584.243 216.523 591.172 216.603C598.102 216.682 603.657 215.766 603.577 214.611Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M603.583 212.062H671.719' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M280.561 215.288L386.209 211.684' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M696.961 220.146L616.56 212.062' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M751.853 267.754L751.315 277.073C747.452 263.939 740.502 251.92 731.046 242.02C721.59 232.12 709.901 224.627 696.958 220.167C709.676 222.085 721.587 227.576 731.306 236.001C741.024 244.426 748.15 255.437 751.853 267.754Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M751.853 372.268L751.315 362.93C747.449 376.065 740.498 388.085 731.043 397.987C721.587 407.89 709.9 415.388 696.958 419.856C709.669 417.92 721.571 412.422 731.287 404C741.002 395.578 748.133 384.576 751.853 372.268Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M217.803 262.916C226.444 256.684 235.603 250.372 245.28 244.061C251.751 239.82 258.123 235.817 264.315 232.114L280.563 215.428C270.092 217.37 260.093 221.311 251.114 227.037C237.151 235.982 225.689 248.328 217.803 262.916Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M217.803 377.165C226.444 383.377 235.603 389.689 245.28 396.001C251.751 400.242 258.123 404.244 264.315 407.947L280.563 424.633C270.089 422.701 260.089 418.759 251.114 413.025C237.157 404.08 225.696 391.742 217.803 377.165Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M263.937 408.267C268.384 409.456 272.89 410.413 277.437 411.134C286.098 412.488 292.45 412.567 305.053 412.707L329.763 413.025C338.902 413.025 351.307 413.025 366.201 413.025' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M486.583 414.619L510.217 414.579' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M493.328 427.461C494.552 425.87 495.581 424.139 496.394 422.304C497.468 419.873 498.14 417.284 498.385 414.638L508.341 396.718' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M499.351 396.659L517.37 396.679' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M599.479 413.164L609.892 412.846C610.211 413.005 617.697 417.007 617.618 423.677C617.607 425.164 617.238 426.625 616.542 427.938' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M582.573 399.009L590.916 399.546' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M386.924 419.417C388.916 415.972 395.307 414.758 399.19 416.968C400.663 417.804 401.34 418.959 404.725 427.819C408.349 437.357 408.468 438.512 407.791 439.268C405.939 441.259 399.508 438.89 395.307 435.804C390.329 432.16 384.077 424.195 386.924 419.417Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M218.043 370.954C225.351 375.673 263.839 400.303 296.055 404.286C307.843 405.719 322.238 405.918 322.238 405.918C332.489 406.055 342.74 405.616 352.941 404.604' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M215.032 384.054L218.019 376.866C217.162 358.11 215.45 339.393 215.45 320.617C215.45 301.443 217.162 282.288 218.019 263.134L215.032 255.946' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M486.561 225.384C445.411 225.384 405.164 225.961 365.82 227.116C383.816 231.818 402.055 235.541 420.456 238.266C432.064 239.998 455.499 243.045 499.324 243.344C496.397 239.122 489.926 229.864 486.561 225.384Z' fill='#A5A4A4'/>
    <path d='M599.478 226.836C570.275 226.08 540.515 225.609 510.197 225.423L517.364 243.343L526.942 243.223C543.189 242.925 561.826 242.287 582.573 241.013L599.478 226.836Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M641.204 233.367L637.322 232.112C628.439 229.226 619.213 227.532 609.884 227.075L590.889 240.356C606.499 239.32 623.178 237.88 640.926 236.035C641.215 235.971 641.478 235.817 641.675 235.596C641.873 235.374 641.996 235.096 642.027 234.801C642.058 234.506 641.995 234.209 641.847 233.951C641.7 233.694 641.475 233.489 641.204 233.367Z' fill='#A5A4A4'/>
    <path d='M432.423 320.001C432.423 333.322 432.782 352.078 433.737 366.753C434.215 374.027 434.806 381.115 435.51 388.018C435.584 388.751 435.373 389.485 434.921 390.067C434.469 390.649 433.81 391.035 433.08 391.144L356.204 404.404C353.66 404.852 351.042 404.57 348.653 403.59C346.263 402.611 344.201 400.974 342.704 398.869C335.934 389.192 334.52 379.376 333.445 369.002C332.211 357.056 331.155 337.523 331.136 320.021C331.116 302.519 332.211 282.907 333.445 271.04C334.52 260.746 335.934 250.949 342.704 241.173V241.073C344.201 238.969 346.263 237.332 348.653 236.352C351.042 235.373 353.66 235.09 356.204 235.538L433.08 248.859C433.8 248.986 434.445 249.381 434.885 249.965C435.325 250.549 435.527 251.278 435.45 252.005C434.753 258.894 434.176 265.942 433.678 273.25C432.782 287.944 432.384 306.72 432.423 320.001Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M486.561 414.639C445.411 414.639 405.164 414.055 365.82 412.887C383.816 408.184 402.055 404.462 420.456 401.737C432.064 400.024 455.499 396.958 499.324 396.659C496.397 400.881 489.926 410.139 486.561 414.639Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M599.478 413.166C570.275 413.949 540.515 414.42 510.197 414.579L517.364 396.659C520.471 396.659 523.656 396.659 526.942 396.799C543.169 397.078 561.826 397.715 582.573 398.989L599.478 413.166Z' fill='#A5A4A4'/>
    <path d='M641.204 406.654L637.322 407.889C628.435 410.747 619.209 412.415 609.884 412.846L590.889 399.566C606.499 400.621 623.205 402.015 640.926 403.906C641.246 403.943 641.544 404.088 641.77 404.318C641.997 404.547 642.138 404.847 642.17 405.168C642.203 405.489 642.125 405.811 641.949 406.081C641.773 406.351 641.511 406.554 641.204 406.654Z' fill='#A5A4A4'/>
    <path d='M623.667 320.003C623.667 333.343 623.667 352.04 622.692 366.595C622.2 374.413 621.583 382.039 620.84 389.473L693.376 392.539C695.742 392.639 698.103 392.24 700.305 391.369C702.507 390.497 704.501 389.173 706.159 387.482C708.031 385.57 709.922 383.519 711.834 381.329C712.531 380.553 713.188 379.776 713.825 379C720.263 371.315 723.787 361.607 723.78 351.582V288.424C723.789 278.404 720.264 268.703 713.825 261.026C713.168 260.25 712.511 259.453 711.834 258.677C709.843 256.487 708.031 254.456 706.159 252.544C704.504 250.849 702.51 249.522 700.308 248.65C698.105 247.779 695.743 247.382 693.376 247.487L620.84 250.553C621.583 257.96 622.2 265.579 622.692 273.411C623.608 287.986 623.667 306.722 623.667 320.003Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M486.561 225.384C445.411 225.384 405.164 225.961 365.82 227.116C383.816 231.818 402.055 235.541 420.456 238.266C432.064 239.998 455.499 243.045 499.324 243.344C496.397 239.122 489.926 229.864 486.561 225.384Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M599.478 226.836C570.275 226.08 540.515 225.609 510.197 225.423L517.364 243.343L526.942 243.223C543.189 242.925 561.826 242.287 582.573 241.013L599.478 226.836Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M641.204 233.367L637.322 232.112C628.439 229.226 619.213 227.532 609.884 227.075L590.889 240.356C606.499 239.32 623.178 237.88 640.926 236.035C641.215 235.971 641.478 235.817 641.675 235.596C641.873 235.374 641.996 235.096 642.027 234.801C642.058 234.506 641.995 234.209 641.847 233.951C641.7 233.694 641.475 233.489 641.204 233.367Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M432.423 320.001C432.423 333.322 432.782 352.078 433.737 366.753C434.215 374.027 434.806 381.115 435.51 388.018C435.584 388.751 435.373 389.485 434.921 390.067C434.469 390.649 433.81 391.035 433.08 391.144L356.204 404.404C353.66 404.852 351.042 404.57 348.653 403.59C346.263 402.611 344.201 400.974 342.704 398.869V398.869C335.934 389.192 334.52 379.376 333.445 369.002C332.211 357.056 331.155 337.523 331.136 320.021C331.116 302.519 332.211 282.907 333.445 271.04C334.52 260.746 335.934 250.949 342.704 241.173V241.073C344.201 238.969 346.263 237.332 348.653 236.352C351.042 235.373 353.66 235.09 356.204 235.538L433.08 248.859C433.8 248.986 434.445 249.381 434.885 249.965C435.325 250.549 435.527 251.278 435.45 252.005C434.753 258.894 434.176 265.942 433.678 273.25C432.782 287.944 432.384 306.72 432.423 320.001Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M486.561 414.639C445.411 414.639 405.164 414.055 365.82 412.887C383.816 408.184 402.055 404.462 420.456 401.737C432.064 400.024 455.499 396.958 499.324 396.659C496.397 400.881 489.926 410.139 486.561 414.639Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M599.478 413.166C570.275 413.949 540.515 414.42 510.197 414.579L517.364 396.659C520.471 396.659 523.656 396.659 526.942 396.799C543.169 397.078 561.826 397.715 582.573 398.989L599.478 413.166Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M641.204 406.654L637.322 407.889C628.435 410.747 619.209 412.415 609.884 412.846L590.889 399.566C606.499 400.621 623.205 402.015 640.926 403.906C641.246 403.943 641.544 404.088 641.77 404.318C641.997 404.547 642.138 404.847 642.17 405.168C642.203 405.489 642.125 405.811 641.949 406.081C641.773 406.351 641.511 406.554 641.204 406.654Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M623.667 320.003C623.667 333.343 623.667 352.04 622.692 366.595C622.2 374.413 621.583 382.039 620.84 389.473L693.376 392.539C695.742 392.639 698.103 392.24 700.305 391.369C702.507 390.497 704.501 389.173 706.159 387.482C708.031 385.57 709.922 383.519 711.834 381.329C712.531 380.553 713.188 379.776 713.825 379C720.263 371.315 723.787 361.607 723.78 351.582V288.424C723.789 278.404 720.264 268.703 713.825 261.026C713.168 260.25 712.511 259.453 711.834 258.677C709.843 256.487 708.031 254.456 706.159 252.544C704.504 250.849 702.51 249.522 700.308 248.65C698.105 247.779 695.743 247.382 693.376 247.487L620.84 250.553C621.583 257.96 622.2 265.579 622.692 273.411C623.608 287.986 623.667 306.722 623.667 320.003Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M695.028 392.517C726.448 395.165 737.877 393.114 742.078 390.386L750.46 384.871' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M433.083 391.143C460.361 389.152 489.173 387.738 519.418 387.32C555.258 386.802 589.106 387.659 620.785 389.471' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M484.711 424.952C484.711 426.107 478.937 427.103 472.008 427.182C465.079 427.262 459.544 426.406 459.623 425.191C459.703 423.977 465.378 423.021 472.307 422.941C479.236 422.862 484.851 423.798 484.711 424.952Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M603.577 425.39C603.577 426.545 597.803 427.561 590.874 427.64C583.945 427.72 578.409 426.864 578.489 425.649C578.569 424.435 584.243 423.499 591.172 423.399C598.102 423.3 603.657 424.196 603.577 425.39Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M603.583 427.919H671.719' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M280.561 424.714L386.209 428.298' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M696.961 419.837L616.56 427.921' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M751.339 362.929L751.857 320.001L751.339 277.073' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M621.04 388.914C627.012 336.149 623.033 291.017 621.041 250.531C552.347 254.514 493.608 254.514 435.867 249.536C431.884 309.269 431.887 318.229 433.877 389.909C519.493 385.927 528.453 385.927 621.04 388.914Z' fill={roof}/>
    <path d='M621.04 388.914C627.012 336.149 623.033 291.017 621.041 250.531C552.347 254.514 493.608 254.514 435.867 249.536C431.884 309.269 431.887 318.229 433.877 389.909C519.493 385.927 528.453 385.927 621.04 388.914Z' fill={roof} />
    <path d='M621.04 388.914C627.012 336.149 623.033 291.017 621.041 250.531C552.347 254.514 493.608 254.514 435.867 249.536C431.884 309.269 431.887 318.229 433.877 389.909C519.493 385.927 528.453 385.927 621.04 388.914Z' stroke='#282828' strokeWidth='2'/>
    <path d='M217.839 370.994C213.857 331.968 216.18 287.036 217.839 269.448C239.74 253.518 282.548 226.638 352.237 235.598C343.277 234.627 331.331 242.567 331.331 325.198C331.331 354.07 332.326 403.25 352.237 404.843C309.097 409.821 276.575 406.834 217.839 370.994Z' fill={engineCover} stroke='#282828' strokeWidth='2'/>
    <path d='M907.791 235.305L907.791 405.298L918.593 405.298L918.593 235.305L907.791 235.305Z' fill='#F8F8F8' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M838.464 421.198C835.969 420.368 833.548 419.333 831.224 418.104L829.589 417.189L824.022 420.342L826.26 427.544C828.207 426.648 830.27 425.597 832.352 424.566C834.435 423.534 836.518 422.308 838.464 421.198Z' fill='white' stroke='#282828' strokeWidth='2' strokeMiterlimit='10'/>
    <path d='M819.116 417.949C815.224 417.949 811.993 422.873 811.993 428.965C811.993 435.057 815.185 440.001 819.116 440.001C823.048 440.001 826.24 435.057 826.24 428.965C826.24 422.873 823.048 417.949 819.116 417.949Z' fill='#A5A4A4' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M823.691 428.964C823.691 424.288 821.643 420.498 819.117 420.498C816.591 420.498 814.543 424.288 814.543 428.964C814.543 433.64 816.591 437.431 819.117 437.431C821.643 437.431 823.691 433.64 823.691 428.964Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeMiterlimit='10'/>
    <path d='M838.464 218.8C835.969 219.631 833.548 220.666 831.224 221.895C830.659 222.206 830.114 222.498 829.589 222.81L824.022 219.657L826.26 212.455C828.207 213.351 830.27 214.402 832.352 215.433C834.435 216.465 836.518 217.672 838.464 218.8Z' fill='white' stroke='#282828' strokeWidth='2' strokeMiterlimit='10'/>
    <path d='M819.116 222.054C815.224 222.054 811.993 217.11 811.993 211.037C811.993 204.965 815.185 200.002 819.116 200.002C823.048 200.002 826.24 204.945 826.24 211.037C826.24 217.129 823.048 222.054 819.116 222.054Z' fill='#A5A4A4' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M823.691 211.017C823.691 206.341 821.643 202.55 819.117 202.55C816.591 202.55 814.543 206.341 814.543 211.017C814.543 215.693 816.591 219.484 819.117 219.484C821.643 219.484 823.691 215.693 823.691 211.017Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeMiterlimit='10'/>
    <path d='M867.251 425.889L933.835 425.889C934.713 425.891 935.582 425.72 936.393 425.386C937.204 425.051 937.941 424.56 938.562 423.939C939.182 423.319 939.674 422.582 940.008 421.771C940.343 420.959 940.514 420.09 940.511 419.213L940.511 403.117C940.514 402.238 940.343 401.368 940.009 400.556C939.674 399.744 939.183 399.005 938.563 398.383C937.943 397.761 937.206 397.268 936.395 396.931C935.584 396.595 934.714 396.421 933.835 396.421L910.479 396.421L867.251 425.889Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M867.251 214.109L933.835 214.109C934.713 214.107 935.582 214.278 936.393 214.612C937.204 214.947 937.941 215.439 938.562 216.059C939.182 216.679 939.674 217.416 940.008 218.227C940.343 219.039 940.514 219.908 940.511 220.785L940.511 236.881C940.514 237.76 940.343 238.63 940.009 239.442C939.674 240.254 939.183 240.993 938.563 241.615C937.943 242.237 937.206 242.73 936.395 243.067C935.584 243.404 934.714 243.577 933.835 243.577L910.479 243.577L867.251 214.109Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M912.794 319.989L912.794 226.311C912.798 224.849 912.296 223.431 911.374 222.296C910.452 221.161 909.166 220.379 907.734 220.083L879.2 214.244L846.794 214.244L839.398 218.643C836.562 219.5 833.817 220.634 831.204 222.029C827.891 223.789 824.8 225.939 821.998 228.433C814.244 231.082 806.692 234.289 799.401 238.028C793.48 241.072 787.742 244.458 782.215 248.169C780.549 249.288 779.159 250.769 778.148 252.501C777.137 254.234 776.531 256.173 776.375 258.173L775.694 267.476C774.488 285.694 773.962 303.27 774.001 320.028C774.04 336.785 774.488 354.478 775.694 372.676L776.376 381.999C776.531 383.999 777.137 385.938 778.148 387.67C779.159 389.403 780.549 390.884 782.215 392.003C787.742 395.714 793.48 399.099 799.401 402.143C806.692 405.883 814.244 409.09 821.998 411.739C827.03 416.197 832.965 419.517 839.398 421.471L846.794 425.869L879.201 425.869L907.734 419.933C909.166 419.637 910.452 418.855 911.374 417.72C912.296 416.586 912.798 415.167 912.794 413.705L912.794 319.989Z' fill='#F8F8F8' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M912.788 226.311L912.788 319.989L912.788 413.705C912.792 415.167 912.29 416.586 911.368 417.72C910.446 418.855 909.16 419.637 907.728 419.933L879.195 425.869L862.36 425.869L839.977 413.705C854.575 413.705 864.112 413.355 863.333 386.106C862.555 358.858 863.009 286.519 863.333 253.755C863.478 239.158 862.36 226.311 839.977 226.311C839.977 226.311 850.682 220.083 862.36 214.244L879.195 214.244L907.728 220.083C909.16 220.379 910.446 221.161 911.368 222.296C912.29 223.431 912.792 224.849 912.788 226.311Z' fill={rearBumper}/>
    <path d='M912.788 226.311L912.788 319.989L912.788 413.705C912.792 415.167 912.29 416.586 911.368 417.72C910.446 418.855 909.16 419.637 907.728 419.933L879.195 425.869L862.36 425.869L839.977 413.705C854.575 413.705 864.112 413.355 863.333 386.106C862.555 358.858 863.009 286.519 863.333 253.755C863.478 239.158 862.36 226.311 839.977 226.311C839.977 226.311 850.682 220.083 862.36 214.244L879.195 214.244L907.728 220.083C909.16 220.379 910.446 221.161 911.368 222.296C912.29 223.431 912.792 224.849 912.788 226.311Z' fill={rearBumper} />
    <path d='M912.788 226.311L912.788 319.989L912.788 413.705C912.792 415.167 912.29 416.586 911.368 417.72C910.446 418.855 909.16 419.637 907.728 419.933L879.195 425.869L862.36 425.869L839.977 413.705C854.575 413.705 864.112 413.355 863.333 386.106C862.555 358.858 863.009 286.519 863.333 253.755C863.478 239.158 862.36 226.311 839.977 226.311C839.977 226.311 850.682 220.083 862.36 214.244L879.195 214.244L907.728 220.083C909.16 220.379 910.446 221.161 911.368 222.296C912.29 223.431 912.792 224.849 912.788 226.311Z' stroke='#282828' strokeWidth='2'/>
    <path d='M839.397 413.708C843.309 413.987 847.241 413.712 851.075 412.891L851.29 412.891C854.683 411.982 857.679 409.975 859.81 407.182C861.941 404.389 863.085 400.969 863.065 397.456L863.065 320.05' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M814.99 319.992L814.99 272.326C814.989 264.329 814.213 256.351 812.674 248.503L812.674 248.094C807.252 248.352 801.847 248.891 796.48 249.71C793.405 250.177 790.466 250.703 787.683 251.306C787.06 261.466 786.593 271.898 786.282 282.603C785.892 295.449 785.756 307.912 785.873 319.992C785.769 332.085 785.905 344.555 786.282 357.401C786.593 368.105 787.06 378.538 787.683 388.698C790.466 389.282 793.405 389.827 796.48 390.294C801.846 391.116 807.251 391.649 812.674 391.89L812.674 391.5C814.213 383.653 814.989 375.675 814.99 367.677L814.99 319.992Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M839.397 226.312C843.309 226.033 847.241 226.308 851.075 227.129C851.075 227.129 851.212 227.129 851.29 227.129C854.683 228.044 857.678 230.057 859.808 232.852C861.938 235.647 863.083 239.069 863.065 242.583L863.065 319.989' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M830.193 413.9C833.269 413.9 836.324 413.763 839.4 413.705L842.845 366.993L833.892 360.512C828.787 370.541 826.214 381.666 826.398 392.918C826.554 400.068 827.835 407.148 830.193 413.9Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M830.193 226.101C833.269 226.101 836.324 226.237 839.4 226.296L842.845 273.008L833.892 279.489C828.787 269.46 826.214 258.334 826.398 247.082C826.554 239.933 827.835 232.852 830.193 226.101Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M894.558 371.959L900.397 380.348C901.22 381.53 901.661 382.936 901.662 384.376L901.662 400.336C898.83 397.421 896.636 393.947 895.22 390.138C893.132 384.291 892.901 377.942 894.558 371.959Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M894.558 268.042L900.397 259.653C901.218 258.47 901.659 257.065 901.662 255.624L901.662 239.645C898.836 242.572 896.642 246.051 895.22 249.863C893.132 255.71 892.901 262.059 894.558 268.042Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M903.9 219.251L903.9 228.496L900.358 232.155C892.941 239.928 888.801 250.257 888.796 261L888.796 378.968C888.801 389.711 892.941 400.04 900.358 407.812L903.9 411.491L903.9 420.755' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M814.678 320.905C814.678 356.912 814.678 375.402 812.731 391.946C816.308 391.946 821.097 391.853 826.356 391.613C826.356 376.555 831.546 364.8 834.141 360.804L842.9 366.643C850.685 358.858 851.658 360.804 851.658 320.905C851.658 278.693 851.658 284.897 842.9 273.219L834.141 279.058C831.87 275.251 827.134 263.912 826.356 249.018C818.155 248.689 812.731 248.89 812.731 248.89C815.651 267.38 814.678 284.897 814.678 320.905Z' fill={trunkLid}/>
    <path d='M814.678 320.905C814.678 356.912 814.678 375.402 812.731 391.946C816.308 391.946 821.097 391.853 826.356 391.613C826.356 376.555 831.546 364.8 834.141 360.804L842.9 366.643C850.685 358.858 851.658 360.804 851.658 320.905C851.658 278.693 851.658 284.897 842.9 273.219L834.141 279.058C831.87 275.251 827.134 263.912 826.356 249.018C818.155 248.689 812.731 248.89 812.731 248.89C815.651 267.38 814.678 284.897 814.678 320.905Z' fill={trunkLid} />
    <path d='M814.678 320.905C814.678 356.912 814.678 375.402 812.731 391.946C816.308 391.946 821.097 391.853 826.356 391.613C826.356 376.555 831.546 364.8 834.141 360.804L842.9 366.643C850.685 358.858 851.658 360.804 851.658 320.905C851.658 278.693 851.658 284.897 842.9 273.219L834.141 279.058C831.87 275.251 827.134 263.912 826.356 249.018C818.155 248.689 812.731 248.89 812.731 248.89C815.651 267.38 814.678 284.897 814.678 320.905Z' stroke='#282828' strokeWidth='2'/>
    <path d='M833.889 279.487L833.889 319.99L833.889 360.493' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M842.842 273.027L846.987 277.581C849.693 280.559 851.192 284.438 851.191 288.461L851.191 351.542C851.192 355.565 849.693 359.444 846.987 362.422L842.842 366.976' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M812.678 248.502C818.517 248.385 824.57 248.502 830.993 248.911C840.741 249.522 850.436 250.79 860.013 252.706C863.668 253.431 866.959 255.399 869.326 258.277C871.693 261.155 872.99 264.765 872.995 268.491L872.995 371.51C872.99 375.237 871.693 378.846 869.326 381.724C866.959 384.602 863.668 386.571 860.013 387.295C850.438 389.244 840.743 390.545 830.993 391.188C824.57 391.597 818.459 391.713 812.678 391.597' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M787.606 388.696C785.356 388.073 783.178 387.219 781.105 386.147C779.523 385.332 778.006 384.395 776.57 383.344' fill='white'/>
    <path d='M787.606 388.696C785.356 388.073 783.178 387.219 781.105 386.147C779.523 385.332 778.006 384.395 776.57 383.344' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M787.606 251.304C785.356 251.927 783.178 252.782 781.105 253.854C779.523 254.669 778.006 255.606 776.57 256.656' fill='white'/>
    <path d='M787.606 251.304C785.356 251.927 783.178 252.782 781.105 253.854C779.523 254.669 778.006 255.606 776.57 256.656' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M839.397 413.705C847.507 417.715 855.623 421.731 863.746 425.753' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M839.397 226.315C847.514 222.305 855.786 218.14 863.902 214.131' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M785.833 304.694L785.833 335.29L789.355 335.29L789.355 304.694L785.833 304.694Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M906.375 272.228L906.375 280.169C906.375 283.039 908.702 285.366 911.572 285.366L912.487 285.366C915.357 285.366 917.683 283.039 917.683 280.169L917.683 272.228C917.683 269.358 915.357 267.031 912.487 267.031L911.572 267.031C908.702 267.031 906.375 269.358 906.375 272.228Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M912.032 273.025C911.413 273.01 910.803 273.179 910.281 273.512C909.759 273.845 909.348 274.325 909.1 274.893C908.852 275.46 908.779 276.089 908.891 276.698C909.002 277.307 909.292 277.869 909.724 278.313C910.156 278.756 910.711 279.06 911.317 279.187C911.923 279.314 912.553 279.257 913.127 279.023C913.701 278.79 914.192 278.391 914.537 277.878C914.883 277.364 915.068 276.759 915.068 276.14C915.073 275.736 914.999 275.335 914.849 274.96C914.699 274.585 914.477 274.243 914.195 273.954C913.913 273.664 913.577 273.434 913.206 273.274C912.835 273.115 912.436 273.031 912.032 273.025Z' fill='#636060' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M906.375 360.418L906.375 368.359C906.375 371.229 908.702 373.556 911.572 373.556L912.487 373.556C915.357 373.556 917.683 371.229 917.683 368.359L917.683 360.418C917.683 357.548 915.357 355.221 912.487 355.221L911.572 355.221C908.702 355.221 906.375 357.548 906.375 360.418Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M912.032 361.292C911.412 361.276 910.802 361.446 910.279 361.779C909.756 362.113 909.345 362.595 909.098 363.164C908.851 363.732 908.779 364.362 908.892 364.971C909.005 365.581 909.297 366.143 909.731 366.586C910.165 367.028 910.722 367.331 911.329 367.456C911.937 367.58 912.567 367.52 913.141 367.284C913.714 367.048 914.204 366.646 914.547 366.129C914.89 365.613 915.072 365.006 915.068 364.386C915.073 363.574 914.757 362.793 914.188 362.213C913.619 361.633 912.844 361.302 912.032 361.292Z' fill='#636060' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M59.3447 404.64L59.3447 235.409L43.505 235.409L43.505 404.64L59.3447 404.64Z' fill='#F8F8F8' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M56.8884 370.633L56.8884 269.387L41.1289 269.387L41.1289 370.633L56.8884 370.633Z' fill='#F8F8F8' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M95.4575 212.517L26.8723 212.517C25.9686 212.517 25.0738 212.695 24.2392 213.042C23.4046 213.388 22.6465 213.896 22.0084 214.536C21.3703 215.176 20.8648 215.935 20.5208 216.771C20.1768 217.606 20.0011 218.502 20.0038 219.405L20.0038 235.966C20.0038 237.788 20.7274 239.535 22.0155 240.823C23.3036 242.111 25.0506 242.835 26.8723 242.835L50.9022 242.835L95.4575 212.517Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M95.4575 427.484L26.8723 427.484C25.9686 427.484 25.0738 427.306 24.2392 426.959C23.4046 426.613 22.6465 426.105 22.0084 425.465C21.3703 424.825 20.8648 424.066 20.5208 423.23C20.1768 422.394 20.0011 421.499 20.0038 420.596L20.0038 404.035C20.0038 402.213 20.7274 400.466 22.0155 399.178C23.3036 397.89 25.0506 397.166 26.8723 397.166L50.9022 397.166L95.4575 427.484Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M50.9007 320.011L50.9007 417.453C50.8976 418.792 51.3589 420.09 52.206 421.127C53.0532 422.164 54.2338 422.875 55.5465 423.14C63.1359 424.641 69.4037 425.422 73.9494 425.863C81.4787 426.604 85.5437 426.564 95.436 427.485C102.945 428.186 109.033 429.007 112.938 429.487C115.451 428.494 117.893 427.33 120.247 426.003C121.989 425.002 123.551 424.001 124.973 423.019C127.741 422.308 130.377 421.159 132.782 419.615C134.801 418.321 136.639 416.765 138.249 414.989C154.038 410.562 168.919 403.376 182.204 393.763C184.335 392.22 186.111 390.239 187.413 387.952C188.716 385.666 189.513 383.127 189.753 380.507C190.054 377.042 190.354 373.558 190.614 370.034C191.896 352.532 192.296 335.791 192.296 319.971C192.296 304.152 191.896 287.351 190.614 269.909C190.354 266.385 190.054 262.9 189.753 259.436C189.513 256.816 188.716 254.277 187.413 251.991C186.111 249.704 184.335 247.723 182.204 246.18C168.919 236.567 154.038 229.381 138.249 224.953C136.639 223.177 134.801 221.622 132.782 220.328C130.382 218.781 127.743 217.637 124.973 216.943C123.449 215.87 121.871 214.874 120.247 213.96C117.897 212.619 115.455 211.447 112.938 210.455C109.033 211.016 102.925 211.837 95.436 212.458C85.5438 213.379 81.4187 213.339 73.9494 214.08C69.4037 214.52 63.1359 215.321 55.5465 216.803C54.2236 217.069 53.0351 217.789 52.1868 218.839C51.3385 219.888 50.8836 221.201 50.9007 222.55L50.9007 320.011Z' fill='#F8F8F8' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M50.8999 417.452L50.8999 320.01L50.8999 222.549C50.8827 221.2 51.3376 219.887 52.186 218.838C53.0343 217.788 54.2227 217.068 55.5457 216.802C63.1351 215.32 69.4029 214.519 73.9485 214.079C81.4178 213.338 85.5429 213.378 95.4352 212.457C99.1284 212.151 102.486 211.796 105.408 211.448L115.421 220.948L122.43 218.838L120.427 237.969L112.417 253.989C117.423 307.055 116.422 330.083 112.417 386.153L109.413 379.144C106.743 376.474 102.405 376.121 102.405 392.16C102.405 408.2 111.416 416.524 115.421 419.194L105.408 428.552C102.49 428.204 99.1349 427.829 95.4352 427.484C85.5429 426.563 81.4779 426.603 73.9485 425.862C69.4029 425.421 63.1351 424.64 55.5457 423.139C54.2329 422.874 53.0524 422.163 52.2052 421.126C51.3581 420.089 50.8967 418.791 50.8999 417.452Z' fill={frontBumper}/>
    <path d='M50.8999 417.452L50.8999 320.01L50.8999 222.549C50.8827 221.2 51.3376 219.887 52.186 218.838C53.0343 217.788 54.2227 217.068 55.5457 216.802C63.1351 215.32 69.4029 214.519 73.9485 214.079C81.4178 213.338 85.5429 213.378 95.4352 212.457C99.1284 212.151 102.486 211.796 105.408 211.448L115.421 220.948L122.43 218.838L120.427 237.969L112.417 253.989C117.423 307.055 116.422 330.083 112.417 386.153L109.413 379.144C106.743 376.474 102.405 376.121 102.405 392.16C102.405 408.2 111.416 416.524 115.421 419.194L105.408 428.552C102.49 428.204 99.1349 427.829 95.4352 427.484C85.5429 426.563 81.4779 426.603 73.9485 425.862C69.4029 425.421 63.1351 424.64 55.5457 423.139C54.2329 422.874 53.0524 422.163 52.2052 421.126C51.3581 420.089 50.8967 418.791 50.8999 417.452Z' fill={frontBumper} />
    <path d='M50.8999 417.452L50.8999 320.01L50.8999 222.549C50.8827 221.2 51.3376 219.887 52.186 218.838C53.0343 217.788 54.2227 217.068 55.5457 216.802C63.1351 215.32 69.4029 214.519 73.9485 214.079C81.4178 213.338 85.5429 213.378 95.4352 212.457C99.1284 212.151 102.486 211.796 105.408 211.448L115.421 220.948L122.43 218.838L120.427 237.969L112.417 253.989C117.423 307.055 116.422 330.083 112.417 386.153L109.413 379.144C106.743 376.474 102.405 376.121 102.405 392.16C102.405 408.2 111.416 416.524 115.421 419.194L105.408 428.552C102.49 428.204 99.1349 427.829 95.4352 427.484C85.5429 426.563 81.4779 426.603 73.9485 425.862C69.4029 425.421 63.1351 424.64 55.5457 423.139C54.2329 422.874 53.0524 422.163 52.2052 421.126C51.3581 420.089 50.8967 418.791 50.8999 417.452Z' stroke='#222222' strokeWidth='2'/>
    <path d='M64.8148 250.686L64.5744 250.546C63.8214 250.11 63.1913 249.49 62.7431 248.744C62.2948 247.998 62.043 247.151 62.0113 246.281L61.2103 224.754C61.1828 223.915 61.3601 223.082 61.7268 222.327C62.0935 221.572 62.6386 220.918 63.315 220.421C63.9914 219.924 64.7787 219.6 65.6087 219.475C66.4387 219.351 67.2865 219.431 68.0788 219.708L72.8648 221.39C74.0214 221.803 74.9933 222.614 75.6061 223.678C76.2189 224.742 76.4327 225.99 76.209 227.197L72.4443 247.222C72.2867 248.026 71.9393 248.782 71.4309 249.425C70.9226 250.068 70.2679 250.58 69.5216 250.919C68.7752 251.258 67.9587 251.413 67.14 251.373C66.3213 251.332 65.5241 251.097 64.8148 250.686Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M81.8818 320.153C81.5246 336.534 83.1382 352.898 86.6878 368.894C87.2284 371.337 87.8092 373.72 88.4099 376.022C88.5215 376.682 88.8023 377.301 89.225 377.82C89.6478 378.338 90.1979 378.738 90.8215 378.981C91.4451 379.223 92.121 379.299 92.7829 379.202C93.4448 379.104 94.0701 378.837 94.5976 378.425C96.5516 376.826 98.3731 375.072 100.044 373.179L100.165 373.039C105.74 366.501 108.856 358.221 108.975 349.629L108.975 347.627C109.196 338.135 108.975 328.944 108.975 320.093C108.975 311.242 109.216 302.07 108.975 292.579L108.975 290.576C108.853 281.985 105.737 273.707 100.165 267.167L100.044 267.027C98.3679 265.139 96.5468 263.385 94.5976 261.78C94.0701 261.369 93.4448 261.101 92.7829 261.004C92.121 260.907 91.4452 260.983 90.8215 261.225C90.1979 261.467 89.6478 261.867 89.225 262.386C88.8023 262.904 88.5215 263.524 88.4099 264.183C87.8092 266.506 87.2284 268.889 86.6878 271.332C83.1303 287.354 81.5166 303.745 81.8818 320.153Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M60.7659 320.01C60.7659 336.764 61.1464 353.518 61.9073 370.272C61.9226 370.685 62.0438 371.086 62.2591 371.438C62.4744 371.79 62.7766 372.08 63.1367 372.281C63.4968 372.482 63.9026 372.588 64.315 372.586C64.7275 372.585 65.1328 372.478 65.4918 372.275L69.0362 370.112C69.3727 369.902 69.6468 369.605 69.8299 369.253C70.013 368.901 70.0985 368.506 70.0774 368.11C69.3832 352.09 69.0228 336.07 68.9961 320.05C68.9694 304.03 69.3298 288.01 70.0774 271.99C70.0985 271.594 70.013 271.199 69.8299 270.847C69.6468 270.495 69.3727 270.198 69.0362 269.988L65.4918 267.825C65.1328 267.622 64.7275 267.515 64.315 267.514C63.9026 267.513 63.4968 267.618 63.1367 267.819C62.7766 268.02 62.4744 268.311 62.2591 268.662C62.0438 269.014 61.9226 269.415 61.9073 269.828C61.1464 286.528 60.7659 303.256 60.7659 320.01Z' fill='#A5A4A4' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M60.7659 320.01C60.7659 336.764 61.1464 353.518 61.9073 370.272C61.9226 370.685 62.0438 371.086 62.2591 371.438C62.4744 371.79 62.7766 372.08 63.1367 372.281C63.4968 372.482 63.9026 372.588 64.315 372.586C64.7275 372.585 65.1328 372.478 65.4918 372.275L69.0362 370.112C69.3727 369.902 69.6468 369.605 69.8299 369.253C70.013 368.901 70.0985 368.506 70.0774 368.11C69.3832 352.09 69.0228 336.07 68.9961 320.05C68.9694 304.03 69.3298 288.01 70.0774 271.99C70.0985 271.594 70.013 271.199 69.8299 270.847C69.6468 270.495 69.3727 270.198 69.0362 269.988L65.4918 267.825C65.1328 267.622 64.7275 267.515 64.315 267.514C63.9026 267.513 63.4968 267.618 63.1367 267.819C62.7766 268.02 62.4744 268.311 62.2591 268.662C62.0438 269.014 61.9226 269.415 61.9073 269.828C61.1464 286.528 60.7659 303.256 60.7659 320.01Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M64.8148 389.319L64.5744 389.459C63.8214 389.895 63.1913 390.515 62.7431 391.261C62.2948 392.007 62.043 392.855 62.0113 393.724L61.2103 415.251C61.1828 416.09 61.3601 416.923 61.7268 417.678C62.0935 418.433 62.6386 419.087 63.315 419.584C63.9914 420.081 64.7787 420.405 65.6087 420.53C66.4387 420.654 67.2865 420.574 68.0788 420.297L72.8648 418.615C74.0214 418.203 74.9933 417.391 75.6061 416.327C76.2189 415.263 76.4327 414.016 76.209 412.808L72.4443 392.783C72.2867 391.979 71.9393 391.224 71.4309 390.581C70.9226 389.938 70.2679 389.425 69.5216 389.086C68.7752 388.748 67.9587 388.592 67.14 388.632C66.3213 388.673 65.5241 388.908 64.8148 389.319Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M122.133 219.408L124.996 216.925' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M138.266 224.934C135.094 225.203 132.028 226.211 129.315 227.877C126.575 229.658 124.231 231.982 122.426 234.706C121.496 235.978 120.659 237.317 119.923 238.711' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M146.594 227.097C145.013 228.389 143.502 229.767 142.069 231.222C134.359 239.032 132.477 245.54 127.671 252.609C123.951 257.981 119.323 262.664 113.994 266.446' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M142.067 231.224C155.993 233.216 169.353 238.086 181.295 245.522' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M147.222 320.015C147.075 336.369 146.728 353.056 146.181 370.077C145.794 381.732 145.313 393.233 144.739 404.58C152.357 402.654 159.815 400.144 167.047 397.071C171.372 395.248 175.397 393.306 179.062 391.364C180.051 390.828 180.883 390.044 181.476 389.089C182.07 388.134 182.404 387.041 182.446 385.917C182.706 378.267 182.926 370.518 183.067 362.688C183.367 348.15 183.948 333.912 183.948 320.015C183.948 306.118 183.367 291.86 183.067 277.322C182.926 269.492 182.706 261.743 182.446 254.093C182.4 252.97 182.064 251.878 181.471 250.924C180.878 249.969 180.048 249.185 179.062 248.646C175.377 246.644 171.352 244.761 167.047 242.939C159.79 239.848 152.305 237.324 144.659 235.39C145.233 246.724 145.714 258.225 146.101 269.893C146.715 286.94 147.089 303.648 147.222 320.015Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M122.692 221.21C121.971 226.636 120.509 235.848 120.189 237.91C120.142 238.189 120.054 238.458 119.928 238.711L108.835 260.739C108.659 261.162 108.354 261.519 107.963 261.757C107.571 261.996 107.115 262.104 106.658 262.066C106.201 262.028 105.768 261.846 105.422 261.547C105.075 261.247 104.833 260.845 104.73 260.398L103.348 255.953L103.348 255.652C102.907 253.229 100.464 237.93 109.956 226.276C112.586 223.041 115.895 220.422 119.648 218.606C120.031 218.454 120.449 218.409 120.855 218.476C121.262 218.544 121.643 218.721 121.956 218.989C122.269 219.257 122.503 219.605 122.633 219.997C122.763 220.388 122.783 220.807 122.692 221.21Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M122.687 418.793C121.966 413.366 120.504 404.154 120.184 402.092C120.14 401.819 120.052 401.555 119.923 401.311L108.83 379.283C108.654 378.86 108.349 378.503 107.958 378.265C107.566 378.026 107.109 377.918 106.653 377.956C106.196 377.994 105.763 378.176 105.417 378.476C105.07 378.775 104.828 379.178 104.724 379.624L103.343 384.069L103.343 384.37C102.902 386.793 100.459 402.092 109.951 413.766C112.587 416.991 115.894 419.601 119.643 421.416C120.028 421.57 120.448 421.616 120.857 421.548C121.266 421.48 121.648 421.3 121.962 421.03C122.277 420.759 122.51 420.407 122.638 420.013C122.766 419.618 122.783 419.196 122.687 418.793Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M112.018 385.757C112.979 378.347 113.78 370.651 114.421 362.668C115.623 347.549 116.063 333.231 115.943 319.855L115.943 320.155C116.063 306.778 115.623 292.461 114.421 277.342C113.78 269.332 112.979 261.636 112.018 254.253' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M64.019 228.378C64.019 231.021 66.1259 233.164 68.7248 233.164C71.3238 233.164 73.4307 231.021 73.4307 228.378C73.4307 225.735 71.3238 223.592 68.7248 223.592C66.1259 223.592 64.019 225.735 64.019 228.378Z' fill='white' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M68.7238 406.859C69.6736 406.843 70.6067 407.11 71.4042 407.626C72.2017 408.143 72.8276 408.884 73.2021 409.757C73.5767 410.63 73.683 411.595 73.5074 412.529C73.3319 413.462 72.8826 414.323 72.2165 415C71.5505 415.677 70.6979 416.141 69.7674 416.332C68.8369 416.524 67.8705 416.434 66.9913 416.074C66.1122 415.714 65.3599 415.101 64.8304 414.312C64.3009 413.523 64.0181 412.595 64.0179 411.645C64.0126 411.022 64.1302 410.404 64.3638 409.826C64.5974 409.248 64.9426 408.722 65.3796 408.278C65.8166 407.833 66.3368 407.479 66.9106 407.236C67.4844 406.992 68.1005 406.864 68.7238 406.859Z' fill='white' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M115.962 419.274L105.029 428.525' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M122.133 420.594L124.996 423.097' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M138.266 415.071C135.094 414.801 132.028 413.793 129.315 412.127C126.575 410.347 124.231 408.022 122.426 405.298C121.496 404.026 120.659 402.688 119.923 401.293' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M146.594 412.908C145.008 411.622 143.497 410.244 142.069 408.783C134.359 400.974 132.477 394.465 127.671 387.397C123.951 382.024 119.323 377.341 113.994 373.559' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M142.067 408.779C147.792 407.93 153.433 406.59 158.928 404.774C166.776 402.234 174.287 398.751 181.295 394.401' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M147.362 222.689C151.367 222.689 154.711 217.603 154.711 211.335C154.711 205.067 151.427 200.001 147.362 200.001C143.297 200.001 140.032 205.067 140.032 211.335C140.032 217.603 143.317 222.689 147.362 222.689Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M140.027 212.797C138.024 213.798 135.861 214.8 133.759 215.881L129.433 218.044L136.642 222.99C138.545 222.069 140.447 221.148 142.349 220.206C142.814 218.724 142.757 217.127 142.189 215.681C141.746 214.54 140.998 213.542 140.027 212.797Z' fill='white' stroke='#222222' strokeWidth='2' strokeMiterlimit='10'/>
    <path d='M140.052 428.667C140.052 434.926 143.334 440.001 147.382 440.001C151.429 440.001 154.711 434.926 154.711 428.667C154.711 422.407 151.429 417.332 147.382 417.332C143.334 417.332 140.052 422.407 140.052 428.667Z' fill='#DDDDDD' stroke='#222222' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M140.027 427.206C138.024 426.205 135.861 425.203 133.759 424.122L129.433 421.959L136.642 417.013L142.349 419.797C142.814 421.279 142.757 422.876 142.189 424.322C141.746 425.463 140.998 426.461 140.027 427.206Z' fill='white' stroke='#222222' strokeWidth='2' strokeMiterlimit='10'/>
    <path d='M232.434 599.979L279.694 609.198L674.934 608.719L697.394 593.818C696.714 563.378 671.874 539.018 642.714 538.838C612.034 538.598 585.974 565.138 587.234 597.198L369.794 597.618C371.374 565.058 346.514 537.838 316.774 535.958C284.774 533.958 255.074 561.958 256.354 597.198L232.434 599.979Z' fill='#F8F8F8' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M759.956 564.557L757.716 561.437L757.976 547.217C758.012 545.647 757.565 544.104 756.696 542.797L748.176 529.997L710.716 526.097L700.716 518.097L748.716 509.977L752.116 503.517L724.276 498.157C722.436 498.417 718.716 498.777 718.716 498.777C704.262 495.017 689.536 490.977 674.536 486.657C657.709 481.817 641.356 476.85 625.476 471.757L626.796 466.677C565.274 453.269 501.671 452.48 439.836 464.357L442.936 468.357C427.29 473.309 412.034 479.419 397.296 486.637C386.576 491.897 376.876 497.357 368.176 502.757C363.985 505.342 359.284 506.987 354.396 507.577C351.46 508.048 348.489 508.262 345.516 508.217L342.136 506.577L338.316 504.717C318.136 505.217 291.176 506.997 260.436 517.677C244.24 523.296 228.648 530.525 213.896 539.257C212.971 542.892 212.349 546.598 212.036 550.337C211.636 555.217 214.336 585.537 214.816 588.877C214.956 589.857 215.096 590.877 215.256 591.757C215.535 593.515 216.372 595.136 217.643 596.382C218.914 597.628 220.552 598.433 222.316 598.677C225.616 599.137 228.976 599.557 232.316 599.977C243.816 601.357 265.616 603.057 265.616 603.057C261.996 579.217 272.876 556.677 291.816 547.057C293.096 546.397 312.616 536.797 332.816 546.137C353.016 555.477 364.816 579.217 360.816 604.697L598.176 605.317C598.176 605.317 592.876 565.017 621.936 549.837C642.716 539.017 668.956 547.017 680.136 561.017C691.316 575.017 692.896 601.177 692.996 601.497C696.616 601.377 716.516 600.737 731.516 598.617C735.721 598.126 739.816 596.943 743.636 595.117C750.741 591.715 756.551 586.101 760.196 579.117C760.802 577.963 761.118 576.68 761.116 575.377V568.257C761.135 566.931 760.728 565.634 759.956 564.557Z' fill='#F8F8F8' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M506.518 507.578L514.518 464.578C544.119 462.978 576.517 469.579 596.517 473.579L619.519 503.578C642.519 527.578 613.519 542.578 581.519 593.578H499.518C493.517 556.578 502.518 518.578 506.518 507.578Z' fill={rearLeftDoor}/>
    <path d='M506.518 507.578L514.518 464.578C544.119 462.978 576.517 469.579 596.517 473.579L619.519 503.578C642.519 527.578 613.519 542.578 581.519 593.578H499.518C493.517 556.578 502.518 518.578 506.518 507.578Z' fill={rearLeftDoor} />
    <path d='M506.518 507.578L514.518 464.578C544.119 462.978 576.517 469.579 596.517 473.579L619.519 503.578C642.519 527.578 613.519 542.578 581.519 593.578H499.518C493.517 556.578 502.518 518.578 506.518 507.578Z' stroke='#282828' strokeWidth='2'/>
    <path d='M378.873 511.719C376.338 518.802 374.411 526.088 373.113 533.499C369.673 553.575 370.656 574.161 375.993 593.819H581.093C595.213 573.579 606.773 557.339 617.453 542.959C622.133 536.639 629.273 527.179 627.113 516.439C625.949 511.363 623.221 506.781 619.313 503.339L596.813 473.799' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M506.513 507.578L514.513 464.578C443.312 464.578 388.847 495.911 370.515 511.578H379.513C365.913 545.978 371.846 580.578 376.513 593.578H499.513C493.913 552.778 501.847 519.244 506.513 507.578Z' fill={frontLeftDoor} stroke='#282828' strokeWidth='2'/>
    <path d='M221.972 567.619C223.323 565.702 223.869 563.333 223.492 561.019C222.694 555.332 220.857 549.841 218.072 544.819C216.916 542.782 215.577 540.854 214.072 539.059C213.158 547.024 212.857 555.048 213.172 563.059C213.332 567.059 213.652 570.939 214.072 574.599C216.143 573.511 218.026 572.1 219.652 570.419C220.494 569.545 221.27 568.609 221.972 567.619Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M369.813 511.718L631.433 503.018C636.354 502.823 641.052 500.913 644.713 497.618L649.313 493.518C649.782 493.111 650.136 492.587 650.337 491.998C650.538 491.41 650.58 490.779 650.458 490.169C650.336 489.56 650.055 488.994 649.643 488.528C649.231 488.062 648.703 487.714 648.113 487.518C628.778 480.946 608.975 475.839 588.873 472.238C555.813 466.238 508.013 457.718 452.013 472.638C422.487 480.618 394.641 493.857 369.813 511.718V511.718Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M226.454 576.597L243.114 572.597C244.428 572.293 245.808 572.502 246.972 573.183C248.136 573.863 248.996 574.963 249.374 576.257L250.634 580.397C250.873 581.162 250.938 581.971 250.825 582.764C250.712 583.558 250.424 584.316 249.982 584.984C249.54 585.653 248.954 586.214 248.268 586.628C247.582 587.042 246.812 587.298 246.014 587.377L228.714 589.017C227.533 589.111 226.355 588.807 225.366 588.155C224.377 587.503 223.634 586.54 223.254 585.417L222.694 583.777C222.423 583.061 222.307 582.296 222.353 581.531C222.399 580.767 222.606 580.021 222.961 579.342C223.317 578.664 223.812 578.069 224.414 577.595C225.016 577.122 225.711 576.782 226.454 576.597Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M246.473 579.917C246.473 576.977 245.453 574.597 244.193 574.597C242.933 574.597 241.933 576.977 241.933 579.917C241.933 582.857 242.953 585.217 244.193 585.217C245.433 585.217 246.473 582.837 246.473 579.917Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M354.452 507.577C359.34 506.987 364.042 505.343 368.232 502.757C376.932 497.357 386.632 491.897 397.352 486.637C412.091 479.42 427.346 473.31 442.992 468.357L439.892 464.357C425.292 469.277 409.892 475.057 393.652 481.937C374.852 489.937 357.652 498.297 342.192 506.597L345.572 508.237C348.545 508.276 351.517 508.055 354.452 507.577Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M506.173 468.059C488.184 468.733 470.326 471.416 452.933 476.059C427.699 482.852 403.691 493.577 381.793 507.839L494.713 504.019C497.913 494.219 503.273 477.439 506.173 468.059Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M589.051 475.839L588.271 475.699C570.431 472.499 548.591 468.579 523.531 467.959L520.711 503.139L605.111 500.319L589.051 475.839Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M600.036 477.979L616.716 499.979L631.316 499.499C635.42 499.356 639.345 497.786 642.416 495.059L647.016 490.899C631.626 485.664 615.938 481.349 600.036 477.979Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M674.591 486.638C689.578 490.958 704.304 494.998 718.771 498.758C718.771 498.758 722.491 498.398 724.331 498.138C708.211 491.938 691.091 485.858 672.951 480.138C657.051 475.058 641.651 470.618 626.851 466.738L625.531 471.818C641.438 476.845 657.791 481.785 674.591 486.638Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M260.251 525.199C273.909 521.003 287.871 517.867 302.011 515.819C315.831 513.819 327.371 513.259 341.871 512.539C353.431 511.959 363.051 511.779 369.871 511.719' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M254.709 538.318L278.549 557.338' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M489.892 520.797C489.892 519.637 484.292 518.797 477.432 518.917C470.572 519.037 465.032 520.057 465.052 521.217C465.072 522.377 470.652 523.217 477.512 523.117C484.372 523.017 489.912 521.957 489.892 520.797Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M618.233 518.699C618.233 517.539 612.633 516.699 605.773 516.799C598.913 516.899 593.353 517.959 593.373 519.119C593.393 520.279 598.973 521.119 605.833 520.999C612.693 520.879 618.253 519.859 618.233 518.699Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M514.812 464.357L506.172 507.137C504.552 512.777 503.092 518.777 501.852 525.137C497.448 547.778 496.517 570.957 499.092 593.877' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M260.711 525.179C260.586 525.149 260.456 525.149 260.331 525.179C253.272 526.751 246.385 529.015 239.771 531.939C233.57 534.667 227.638 537.968 222.051 541.799C221.835 541.926 221.67 542.123 221.584 542.358C221.498 542.593 221.497 542.85 221.579 543.087C221.662 543.323 221.824 543.523 222.037 543.652C222.251 543.782 222.503 543.834 222.751 543.799C227.491 543.319 232.411 542.679 237.511 541.799C252.657 539.3 267.512 535.279 281.851 529.799C282.053 529.703 282.22 529.545 282.327 529.349C282.435 529.152 282.477 528.927 282.448 528.704C282.419 528.482 282.321 528.275 282.167 528.112C282.013 527.95 281.811 527.84 281.591 527.799L260.711 525.179Z' fill='#A5A4A4'/>
    <path d='M710.711 526.1L748.131 530C748.905 526.349 749.314 522.631 749.351 518.9C749.392 515.908 749.184 512.917 748.731 509.96L700.731 518.08L710.711 526.1Z' fill='#A5A4A4'/>
    <path d='M260.711 525.179C260.586 525.149 260.456 525.149 260.331 525.179C253.272 526.751 246.385 529.015 239.771 531.939C233.57 534.667 227.638 537.968 222.051 541.799C221.835 541.926 221.67 542.123 221.584 542.358C221.498 542.593 221.497 542.85 221.579 543.087C221.662 543.323 221.824 543.523 222.037 543.652C222.251 543.782 222.503 543.834 222.751 543.799C227.491 543.319 232.411 542.679 237.511 541.799C252.657 539.3 267.512 535.279 281.851 529.799C282.053 529.703 282.22 529.545 282.327 529.349C282.435 529.152 282.477 528.927 282.448 528.704C282.419 528.482 282.321 528.275 282.167 528.112C282.013 527.95 281.811 527.84 281.591 527.799L260.711 525.179Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M710.711 526.1L748.131 530C748.905 526.349 749.314 522.631 749.351 518.9C749.392 515.908 749.184 512.917 748.731 509.96L700.731 518.08L710.711 526.1Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M260.711 525.179C260.586 525.149 260.456 525.149 260.331 525.179C253.272 526.751 246.385 529.015 239.771 531.939C233.57 534.667 227.638 537.968 222.051 541.799C221.835 541.926 221.67 542.123 221.584 542.358C221.498 542.593 221.497 542.85 221.579 543.087C221.662 543.323 221.824 543.523 222.037 543.652C222.251 543.782 222.503 543.834 222.751 543.799C227.491 543.319 232.411 542.679 237.511 541.799C252.657 539.3 267.512 535.279 281.851 529.799C282.053 529.703 282.22 529.545 282.327 529.349C282.435 529.152 282.477 528.927 282.448 528.704C282.419 528.482 282.321 528.275 282.167 528.112C282.013 527.95 281.811 527.84 281.591 527.799L260.711 525.179Z' stroke='#303030' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M710.711 526.1L748.131 530C748.905 526.349 749.314 522.631 749.351 518.9C749.392 515.908 749.184 512.917 748.731 509.96L700.731 518.08L710.711 526.1Z' stroke='#303030' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M644.412 628.159C666.338 628.159 684.112 610.805 684.112 589.399C684.112 567.992 666.338 550.639 644.412 550.639C622.487 550.639 604.712 567.992 604.712 589.399C604.712 610.805 622.487 628.159 644.412 628.159Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M644.413 618.818C661.059 618.818 674.553 605.647 674.553 589.398C674.553 573.15 661.059 559.978 644.413 559.978C627.767 559.978 614.273 573.15 614.273 589.398C614.273 605.647 627.767 618.818 644.413 618.818Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M647.552 589.159C647.568 589.788 647.396 590.407 647.057 590.937C646.719 591.468 646.23 591.885 645.653 592.136C645.076 592.387 644.437 592.46 643.819 592.346C643.2 592.231 642.629 591.935 642.18 591.494C641.731 591.054 641.424 590.489 641.297 589.873C641.171 589.256 641.232 588.616 641.471 588.035C641.711 587.453 642.119 586.956 642.643 586.607C643.167 586.259 643.783 586.075 644.412 586.079C644.821 586.073 645.226 586.149 645.606 586.301C645.985 586.453 646.331 586.679 646.623 586.965C646.915 587.251 647.147 587.593 647.306 587.969C647.466 588.345 647.549 588.75 647.552 589.159V589.159Z' stroke='#282828' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M644.417 615.997C645.541 616.024 646.666 615.951 647.777 615.777L649.037 615.517C649.528 615.408 649.966 615.133 650.277 614.737L651.937 612.637C652.243 612.246 652.41 611.764 652.41 611.267C652.41 610.771 652.243 610.288 651.937 609.897L646.157 602.637C645.949 602.379 645.685 602.17 645.386 602.026C645.087 601.883 644.759 601.809 644.427 601.809C644.095 601.809 643.767 601.883 643.468 602.026C643.168 602.17 642.905 602.379 642.697 602.637L636.917 609.897C636.607 610.287 636.438 610.77 636.438 611.267C636.438 611.765 636.607 612.248 636.917 612.637L638.577 614.737C638.877 615.145 639.32 615.424 639.817 615.517C640.197 615.617 640.617 615.697 641.057 615.777C642.168 615.95 643.293 616.024 644.417 615.997Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M669.728 597.538C670.111 596.475 670.386 595.376 670.548 594.258C670.548 593.818 670.668 593.398 670.708 592.998C670.744 592.495 670.61 591.995 670.328 591.578L668.848 589.358C668.567 588.946 668.156 588.64 667.682 588.488C667.207 588.336 666.696 588.346 666.228 588.518L657.548 591.818C657.237 591.934 656.957 592.119 656.728 592.358C656.499 592.598 656.327 592.886 656.225 593.202C656.123 593.517 656.094 593.851 656.139 594.18C656.185 594.508 656.304 594.822 656.488 595.098L661.628 602.838C661.909 603.249 662.319 603.555 662.793 603.707C663.268 603.859 663.78 603.849 664.248 603.678L666.748 602.738C667.216 602.558 667.61 602.227 667.868 601.798C668.068 601.458 668.288 601.078 668.488 600.698C669.002 599.687 669.417 598.628 669.728 597.538Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M660.492 568.038C659.608 567.332 658.657 566.716 657.652 566.198C657.284 565.987 656.903 565.8 656.511 565.638C656.051 565.44 655.538 565.398 655.052 565.518L652.472 566.198C651.989 566.325 651.564 566.611 651.267 567.011C650.969 567.411 650.815 567.9 650.832 568.398L651.092 577.678C651.114 578.002 651.207 578.318 651.365 578.602C651.522 578.887 651.74 579.133 652.004 579.324C652.267 579.514 652.569 579.645 652.888 579.706C653.208 579.767 653.536 579.758 653.852 579.678L662.852 577.338C663.334 577.211 663.759 576.925 664.057 576.525C664.355 576.125 664.508 575.636 664.492 575.138V572.478C664.47 571.974 664.288 571.491 663.972 571.098C663.712 570.798 663.432 570.478 663.112 570.158C662.31 569.367 661.432 568.657 660.492 568.038Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M628.415 568.118C627.5 568.784 626.644 569.527 625.855 570.338L625.015 571.278C624.689 571.666 624.506 572.152 624.495 572.658V575.318C624.487 575.817 624.646 576.305 624.947 576.704C625.247 577.102 625.673 577.389 626.155 577.518L635.155 579.838C635.47 579.918 635.799 579.928 636.118 579.866C636.438 579.805 636.739 579.675 637.003 579.484C637.266 579.293 637.484 579.047 637.642 578.762C637.799 578.478 637.893 578.163 637.915 577.838L638.175 568.558C638.187 568.058 638.029 567.569 637.728 567.169C637.426 566.77 636.999 566.484 636.515 566.358L633.935 565.678C633.448 565.558 632.935 565.6 632.475 565.798L631.335 566.358C630.309 566.853 629.332 567.443 628.415 568.118Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M618.954 597.578C619.282 598.659 619.704 599.71 620.214 600.718C620.414 601.118 620.634 601.478 620.834 601.818C621.092 602.247 621.486 602.578 621.954 602.758L624.474 603.698C624.941 603.873 625.453 603.883 625.926 603.727C626.399 603.57 626.805 603.256 627.074 602.838L632.214 595.098C632.398 594.82 632.516 594.505 632.56 594.176C632.604 593.847 632.573 593.512 632.469 593.196C632.365 592.88 632.191 592.592 631.96 592.354C631.729 592.115 631.446 591.932 631.134 591.818L622.454 588.538C621.985 588.365 621.47 588.355 620.995 588.511C620.519 588.668 620.11 588.98 619.834 589.398L618.354 591.618C618.079 592.037 617.952 592.537 617.994 593.038C617.994 593.438 617.994 593.858 618.134 594.298C618.31 595.413 618.585 596.511 618.954 597.578Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M313.914 628.159C335.84 628.159 353.614 610.805 353.614 589.399C353.614 567.992 335.84 550.639 313.914 550.639C291.989 550.639 274.214 567.992 274.214 589.399C274.214 610.805 291.989 628.159 313.914 628.159Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M313.915 618.818C330.561 618.818 344.055 605.647 344.055 589.398C344.055 573.15 330.561 559.978 313.915 559.978C297.269 559.978 283.775 573.15 283.775 589.398C283.775 605.647 297.269 618.818 313.915 618.818Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M317.054 589.159C317.07 589.788 316.898 590.407 316.559 590.937C316.221 591.468 315.732 591.885 315.155 592.136C314.578 592.387 313.939 592.46 313.321 592.346C312.702 592.231 312.131 591.935 311.682 591.494C311.233 591.054 310.926 590.489 310.799 589.873C310.673 589.256 310.734 588.616 310.973 588.035C311.213 587.453 311.621 586.956 312.145 586.607C312.669 586.259 313.285 586.075 313.914 586.079C314.323 586.073 314.729 586.149 315.108 586.301C315.487 586.453 315.833 586.679 316.125 586.965C316.417 587.251 316.649 587.593 316.809 587.969C316.968 588.345 317.051 588.75 317.054 589.159V589.159Z' stroke='#282828' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M313.915 615.999C315.046 616.026 316.177 615.952 317.295 615.779L318.535 615.519C319.026 615.41 319.464 615.135 319.775 614.739L321.435 612.639C321.745 612.25 321.913 611.767 321.913 611.269C321.913 610.772 321.745 610.289 321.435 609.899L315.635 602.639C315.429 602.381 315.167 602.172 314.869 602.029C314.571 601.886 314.245 601.811 313.915 601.811C313.584 601.811 313.258 601.886 312.96 602.029C312.662 602.172 312.401 602.381 312.195 602.639L306.395 609.899C306.085 610.289 305.916 610.772 305.916 611.269C305.916 611.767 306.085 612.25 306.395 612.639L308.055 614.739C308.362 615.138 308.802 615.415 309.295 615.519L310.535 615.779C311.653 615.953 312.784 616.027 313.915 615.999Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M339.25 597.538C339.624 596.472 339.899 595.374 340.07 594.258C340.07 593.818 340.07 593.398 340.21 592.998C340.257 592.494 340.122 591.99 339.83 591.578L338.35 589.358C338.069 588.946 337.658 588.64 337.184 588.488C336.709 588.336 336.198 588.346 335.73 588.518L327.05 591.818C326.739 591.934 326.459 592.119 326.23 592.358C326.001 592.598 325.829 592.886 325.727 593.202C325.626 593.517 325.596 593.851 325.642 594.18C325.687 594.508 325.806 594.822 325.99 595.098L331.13 602.838C331.411 603.249 331.821 603.555 332.296 603.707C332.77 603.859 333.282 603.849 333.75 603.678L336.25 602.738C336.721 602.564 337.117 602.232 337.37 601.798C337.59 601.458 337.79 601.078 337.99 600.698C338.501 599.683 338.923 598.626 339.25 597.538Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M329.993 568.038C329.114 567.336 328.17 566.72 327.173 566.198C326.773 565.978 326.393 565.798 326.033 565.638C325.566 565.44 325.047 565.398 324.553 565.518L321.973 566.198C321.493 566.328 321.07 566.615 320.773 567.014C320.475 567.413 320.32 567.901 320.333 568.398L320.593 577.678C320.615 578.005 320.708 578.322 320.867 578.608C321.026 578.895 321.246 579.142 321.512 579.333C321.778 579.524 322.082 579.654 322.404 579.714C322.726 579.774 323.057 579.761 323.373 579.678L332.353 577.338C332.837 577.215 333.264 576.929 333.563 576.528C333.861 576.128 334.013 575.637 333.993 575.138V572.478C333.972 571.974 333.79 571.491 333.473 571.098L332.633 570.158C331.821 569.371 330.937 568.661 329.993 568.038Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M297.913 568.118C297.003 568.789 296.147 569.532 295.353 570.338L294.513 571.278C294.197 571.671 294.015 572.155 293.993 572.658V575.318C293.982 575.818 294.139 576.308 294.441 576.707C294.742 577.106 295.169 577.392 295.653 577.518L304.633 579.838C304.949 579.922 305.279 579.934 305.599 579.875C305.92 579.815 306.224 579.685 306.488 579.493C306.752 579.302 306.97 579.054 307.127 578.768C307.284 578.481 307.375 578.164 307.393 577.838L307.653 568.558C307.665 568.058 307.507 567.569 307.206 567.169C306.905 566.77 306.477 566.484 305.993 566.358L303.413 565.678C302.927 565.558 302.414 565.6 301.953 565.798L300.813 566.358C299.795 566.854 298.824 567.444 297.913 568.118Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M288.472 597.577C288.783 598.661 289.199 599.712 289.712 600.717L290.332 601.817C290.593 602.252 290.995 602.584 291.472 602.757L293.972 603.697C294.439 603.872 294.951 603.882 295.424 603.726C295.897 603.569 296.303 603.256 296.572 602.837L301.712 595.017C301.896 594.741 302.015 594.427 302.061 594.099C302.106 593.77 302.077 593.436 301.975 593.121C301.873 592.805 301.701 592.517 301.472 592.277C301.243 592.038 300.963 591.853 300.652 591.737L291.952 588.457C291.483 588.284 290.968 588.274 290.493 588.43C290.017 588.587 289.608 588.899 289.332 589.317L287.852 591.537C287.577 591.956 287.45 592.456 287.492 592.957C287.492 593.357 287.492 593.777 287.652 594.217C287.814 595.361 288.088 596.487 288.472 597.577Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M670.711 552.718L685.711 549.418C692.944 547.84 699.274 543.498 703.351 537.318L710.751 526.098C723.593 538.36 740.27 545.819 757.971 547.218' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M282.433 528.577C354.893 522.917 431.213 518.977 511.113 517.357C576.753 516.037 640.013 516.397 700.693 518.077' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M690.709 586.738C700.829 586.458 709.609 585.978 716.709 585.498C731.709 584.458 735.909 583.638 740.009 581.318C743.149 579.558 743.509 578.478 749.749 573.958C754.329 570.658 758.389 568.178 761.109 566.598' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M227.416 538.319C226.375 536.642 225.102 535.121 223.636 533.799' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M398.712 507.579L404.992 511.839L397.292 518.079C396.693 518.195 396.075 518.173 395.485 518.013C394.896 517.854 394.351 517.561 393.892 517.159C393.131 516.424 392.692 515.417 392.672 514.359L398.672 507.579' fill='#DBDBDB'/>
    <path d='M398.712 507.579L404.992 511.839L397.292 518.079C396.693 518.195 396.075 518.173 395.485 518.013C394.896 517.854 394.351 517.561 393.892 517.159C393.131 516.424 392.692 515.417 392.672 514.359L398.672 507.579' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M414.51 498.558C412.725 498.349 410.925 498.288 409.13 498.378C404.53 498.498 399.55 498.618 397.67 501.518C397.115 502.614 396.909 503.853 397.081 505.069C397.253 506.285 397.793 507.419 398.63 508.318C401.37 511.718 407.27 512.778 411.45 511.318C412.29 507.038 413.31 502.785 414.51 498.558Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M254.514 538.579L278.514 556.579C316.514 522.579 361.514 552.579 362.514 593.58H376.514C367.714 566.38 372.18 534.912 375.514 522.579C340.514 523.579 314.514 525.579 283.514 528.579C273.514 533.579 265.514 535.579 254.514 538.579Z' fill={frontLeftFender} stroke='#282828' strokeWidth='2'/>
    <path d='M360.509 604.578C361.309 599.778 361.509 595.579 361.509 593.579C441.51 593.578 597.509 593.578 597.509 593.578V605.578C519.51 605.578 383.509 604.578 360.509 604.578Z' fill={leftSill}/>
    <path d='M360.509 604.578C361.309 599.778 361.509 595.579 361.509 593.579C441.51 593.578 597.509 593.578 597.509 593.578V605.578C519.51 605.578 383.509 604.578 360.509 604.578Z' fill={leftSill} />
    <path d='M360.509 604.578C361.309 599.778 361.509 595.579 361.509 593.579C441.51 593.578 597.509 593.578 597.509 593.578V605.578C519.51 605.578 383.509 604.578 360.509 604.578Z' stroke='#282828' strokeWidth='2'/>
    <path d='M627.514 516.578C631.514 531.578 601.514 561.578 581.514 593.578H597.514C597.514 551.035 638.514 532.578 670.514 552.786L670.719 552.748C687.603 549.562 698.563 547.494 710.514 526.578L700.514 517.578L627.514 516.578Z' fill={rearLeftFender}/>
    <path d='M627.514 516.578C631.514 531.578 601.514 561.578 581.514 593.578H597.514C597.514 551.035 638.514 532.578 670.514 552.786L670.719 552.748C687.603 549.562 698.563 547.494 710.514 526.578L700.514 517.578L627.514 516.578Z' fill={rearLeftFender} />
    <path d='M627.514 516.578C631.514 531.578 601.514 561.578 581.514 593.578H597.514C597.514 551.035 638.514 532.578 670.514 552.786L670.719 552.748C687.603 549.562 698.563 547.494 710.514 526.578L700.514 517.578L627.514 516.578Z' stroke='#282828' strokeWidth='2'/>
    <path d='M232.434 40.1798L279.694 30.9598L674.934 31.4398L697.394 46.3398C696.714 76.7798 671.874 101.14 642.714 101.32C612.034 101.56 585.974 75.0198 587.234 42.9598L369.794 42.5398C371.374 75.0998 346.514 102.32 316.774 104.2C284.774 106.2 255.074 78.1998 256.354 42.9598L232.434 40.1798Z' fill='#F8F8F8' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M759.956 75.6009L757.716 78.7209L757.976 92.9409C758.012 94.5106 757.565 96.0536 756.696 97.3609L748.176 110.161L710.716 114.061L700.716 122.061L748.716 130.181L752.116 136.641L724.276 142.001C722.436 141.741 718.716 141.381 718.716 141.381C704.262 145.141 689.536 149.181 674.536 153.501C657.709 158.341 641.356 163.308 625.476 168.401L626.796 173.481C565.274 186.888 501.671 187.677 439.836 175.801L442.936 171.801C427.29 166.848 412.034 160.738 397.296 153.521C386.576 148.261 376.876 142.801 368.176 137.401C363.985 134.815 359.284 133.171 354.396 132.581C351.46 132.109 348.489 131.895 345.516 131.941L342.136 133.581L338.316 135.441C318.136 134.941 291.176 133.161 260.436 122.481C244.24 116.862 228.648 109.632 213.896 100.901C212.971 97.2651 212.349 93.5592 212.036 89.8209C211.636 84.9409 214.336 54.6209 214.816 51.2809C214.956 50.3009 215.096 49.2809 215.256 48.4009C215.535 46.6429 216.372 45.0212 217.643 43.7752C218.914 42.5292 220.552 41.7249 222.316 41.4809C225.616 41.0209 228.976 40.6009 232.316 40.1809C243.816 38.8009 265.616 37.1009 265.616 37.1009C261.996 60.9409 272.876 83.4809 291.816 93.1009C293.096 93.7609 312.616 103.361 332.816 94.0209C353.016 84.6809 364.816 60.9409 360.816 35.4609L598.176 34.8409C598.176 34.8409 592.876 75.1409 621.936 90.3209C642.716 101.141 668.956 93.1409 680.136 79.1409C691.316 65.1409 692.896 38.9809 692.996 38.6609C696.616 38.7809 716.516 39.4209 731.516 41.5409C735.721 42.0318 739.816 43.2144 743.636 45.0409C750.741 48.443 756.551 54.057 760.196 61.0409C760.802 62.1941 761.118 63.478 761.116 64.7809V71.9009C761.135 73.2266 760.728 74.5234 759.956 75.6009Z' fill='#F8F8F8' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M506.518 132.58L514.518 175.58C544.119 177.18 576.517 170.579 596.517 166.579L619.519 136.58C642.519 112.58 613.519 97.5803 581.519 46.5803H499.518C493.517 83.5803 502.518 121.58 506.518 132.58Z' fill={rearRightDoor}/>
    <path d='M506.518 132.58L514.518 175.58C544.119 177.18 576.517 170.579 596.517 166.579L619.519 136.58C642.519 112.58 613.519 97.5803 581.519 46.5803H499.518C493.517 83.5803 502.518 121.58 506.518 132.58Z' fill={rearRightDoor} />
    <path d='M506.518 132.58L514.518 175.58C544.119 177.18 576.517 170.579 596.517 166.579L619.519 136.58C642.519 112.58 613.519 97.5803 581.519 46.5803H499.518C493.517 83.5803 502.518 121.58 506.518 132.58Z' stroke='#282828' strokeWidth='2'/>
    <path d='M378.873 128.439C376.338 121.356 374.411 114.07 373.113 106.659C369.673 86.5826 370.656 65.9971 375.993 46.3393H581.093C595.213 66.5793 606.773 82.8193 617.453 97.1993C622.133 103.519 629.273 112.979 627.113 123.719C625.949 128.795 623.221 133.377 619.313 136.819L596.813 166.359' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M506.513 132.579L514.513 175.579C443.312 175.579 388.847 144.246 370.515 128.579H379.513C365.913 94.1792 371.846 59.5792 376.513 46.5792H499.513C493.913 87.3792 501.847 120.913 506.513 132.579Z' fill={frontRightDoor} stroke='#282828' strokeWidth='2'/>
    <path d='M221.972 72.5382C223.323 74.4546 223.869 76.8241 223.492 79.1382C222.694 84.8248 220.857 90.3161 218.072 95.3382C216.916 97.3752 215.577 99.3031 214.072 101.098C213.158 93.1331 212.857 85.1095 213.172 77.0982C213.332 73.0982 213.652 69.2182 214.072 65.5582C216.143 66.6461 218.026 68.0571 219.652 69.7382C220.494 70.6124 221.27 71.5484 221.972 72.5382Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M369.813 128.439L631.433 137.139C636.354 137.334 641.052 139.244 644.713 142.539L649.313 146.639C649.782 147.046 650.136 147.571 650.337 148.159C650.538 148.747 650.58 149.378 650.458 149.988C650.336 150.597 650.055 151.164 649.643 151.629C649.231 152.095 648.703 152.443 648.113 152.639C628.778 159.211 608.975 164.318 588.873 167.919C555.813 173.919 508.013 182.439 452.013 167.519C422.487 159.539 394.641 146.3 369.813 128.439V128.439Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M226.454 63.5599L243.114 67.5599C244.428 67.8644 245.808 67.655 246.972 66.9745C248.136 66.2939 248.996 65.1939 249.374 63.8999L250.634 59.7599C250.873 58.9948 250.938 58.1862 250.825 57.3928C250.712 56.5994 250.424 55.841 249.982 55.1728C249.54 54.5045 248.954 53.9431 248.268 53.5292C247.582 53.1153 246.812 52.8594 246.014 52.7799L228.714 51.1399C227.533 51.0463 226.355 51.3497 225.366 52.0018C224.377 52.6539 223.634 53.6175 223.254 54.7398L222.694 56.3799C222.423 57.096 222.307 57.8614 222.353 58.6258C222.399 59.3902 222.606 60.1362 222.961 60.8146C223.317 61.493 223.812 62.0884 224.414 62.5616C225.016 63.0348 225.711 63.375 226.454 63.5599Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M246.473 60.2407C246.473 63.1807 245.453 65.5607 244.193 65.5607C242.933 65.5607 241.933 63.1807 241.933 60.2407C241.933 57.3007 242.953 54.9407 244.193 54.9407C245.433 54.9407 246.473 57.3207 246.473 60.2407Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M354.452 132.58C359.34 133.17 364.042 134.814 368.232 137.4C376.932 142.8 386.632 148.26 397.352 153.52C412.091 160.737 427.346 166.847 442.992 171.8L439.892 175.8C425.292 170.88 409.892 165.1 393.652 158.22C374.852 150.22 357.652 141.86 342.192 133.56L345.572 131.92C348.545 131.881 351.517 132.101 354.452 132.58Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M506.173 172.099C488.184 171.425 470.326 168.742 452.933 164.099C427.699 157.306 403.691 146.581 381.793 132.319L494.713 136.139C497.913 145.939 503.273 162.719 506.173 172.099Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M589.051 164.318L588.271 164.458C570.431 167.658 548.591 171.578 523.531 172.198L520.711 137.018L605.111 139.838L589.051 164.318Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M600.036 162.179L616.716 140.179L631.316 140.659C635.42 140.802 639.345 142.372 642.416 145.099L647.016 149.259C631.626 154.494 615.938 158.809 600.036 162.179Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M674.591 153.519C689.578 149.199 704.304 145.159 718.771 141.399C718.771 141.399 722.491 141.759 724.331 142.019C708.211 148.219 691.091 154.299 672.951 160.019C657.051 165.099 641.651 169.539 626.851 173.419L625.531 168.339C641.438 163.312 657.791 158.372 674.591 153.519Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M260.251 114.958C273.909 119.153 287.871 122.289 302.011 124.338C315.831 126.338 327.371 126.898 341.871 127.618C353.431 128.198 363.051 128.378 369.871 128.438' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M254.709 101.839L278.549 82.8188' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M489.892 119.36C489.892 120.52 484.292 121.36 477.432 121.24C470.572 121.12 465.032 120.1 465.052 118.94C465.072 117.78 470.652 116.94 477.512 117.04C484.372 117.14 489.912 118.2 489.892 119.36Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M618.233 121.459C618.233 122.619 612.633 123.459 605.773 123.359C598.913 123.259 593.353 122.199 593.373 121.039C593.393 119.879 598.973 119.039 605.833 119.159C612.693 119.279 618.253 120.299 618.233 121.459Z' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M514.812 175.8L506.172 133.02C504.552 127.38 503.092 121.38 501.852 115.02C497.448 92.3792 496.517 69.2002 499.092 46.2796' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M260.711 114.979C260.586 115.009 260.456 115.009 260.331 114.979C253.272 113.407 246.385 111.143 239.771 108.219C233.57 105.491 227.638 102.19 222.051 98.3592C221.835 98.2324 221.67 98.0347 221.584 97.7999C221.498 97.5651 221.497 97.3077 221.579 97.0717C221.662 96.8356 221.824 96.6356 222.037 96.5057C222.251 96.3758 222.503 96.324 222.751 96.3592C227.491 96.8392 232.411 97.4792 237.511 98.3592C252.657 100.859 267.512 104.879 281.851 110.359C282.053 110.455 282.22 110.613 282.327 110.809C282.435 111.006 282.477 111.232 282.448 111.454C282.419 111.676 282.321 111.883 282.167 112.046C282.013 112.209 281.811 112.318 281.591 112.359L260.711 114.979Z' fill='#A5A4A4'/>
    <path d='M710.711 114.058L748.131 110.159C748.905 113.809 749.314 117.527 749.351 121.258C749.392 124.25 749.184 127.241 748.731 130.198L700.731 122.078L710.711 114.058Z' fill='#A5A4A4'/>
    <path d='M260.711 114.979C260.586 115.009 260.456 115.009 260.331 114.979C253.272 113.407 246.385 111.143 239.771 108.219C233.57 105.491 227.638 102.19 222.051 98.3592C221.835 98.2324 221.67 98.0347 221.584 97.7999C221.498 97.5651 221.497 97.3077 221.579 97.0717C221.662 96.8356 221.824 96.6356 222.037 96.5057C222.251 96.3758 222.503 96.324 222.751 96.3592C227.491 96.8392 232.411 97.4792 237.511 98.3592C252.657 100.859 267.512 104.879 281.851 110.359C282.053 110.455 282.22 110.613 282.327 110.809C282.435 111.006 282.477 111.232 282.448 111.454C282.419 111.676 282.321 111.883 282.167 112.046C282.013 112.209 281.811 112.318 281.591 112.359L260.711 114.979Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M710.711 114.058L748.131 110.159C748.905 113.809 749.314 117.527 749.351 121.258C749.392 124.25 749.184 127.241 748.731 130.198L700.731 122.078L710.711 114.058Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M260.711 114.979C260.586 115.009 260.456 115.009 260.331 114.979C253.272 113.407 246.385 111.143 239.771 108.219C233.57 105.491 227.638 102.19 222.051 98.3592C221.835 98.2324 221.67 98.0347 221.584 97.7999C221.498 97.5651 221.497 97.3077 221.579 97.0717C221.662 96.8356 221.824 96.6356 222.037 96.5057C222.251 96.3758 222.503 96.324 222.751 96.3592C227.491 96.8392 232.411 97.4792 237.511 98.3592C252.657 100.859 267.512 104.879 281.851 110.359C282.053 110.455 282.22 110.613 282.327 110.809C282.435 111.006 282.477 111.232 282.448 111.454C282.419 111.676 282.321 111.883 282.167 112.046C282.013 112.209 281.811 112.318 281.591 112.359L260.711 114.979Z' stroke='#303030' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M710.711 114.058L748.131 110.159C748.905 113.809 749.314 117.527 749.351 121.258C749.392 124.25 749.184 127.241 748.731 130.198L700.731 122.078L710.711 114.058Z' stroke='#303030' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M644.412 11.9983C666.338 11.9983 684.112 29.3518 684.112 50.7583C684.112 72.1649 666.338 89.5183 644.412 89.5183C622.487 89.5183 604.712 72.1649 604.712 50.7583C604.712 29.3518 622.487 11.9983 644.412 11.9983Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M644.413 21.3399C661.059 21.3399 674.553 34.5117 674.553 50.7599C674.553 67.0081 661.059 80.1799 644.413 80.1799C627.767 80.1799 614.273 67.0081 614.273 50.7599C614.273 34.5117 627.767 21.3399 644.413 21.3399Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M647.552 50.9996C647.568 50.3706 647.396 49.7512 647.057 49.2207C646.719 48.6902 646.23 48.2727 645.653 48.0218C645.076 47.7709 644.437 47.698 643.819 47.8124C643.2 47.9267 642.629 48.2232 642.18 48.6638C641.731 49.1044 641.424 49.669 641.297 50.2854C641.171 50.9018 641.232 51.5419 641.471 52.1236C641.711 52.7054 642.119 53.2024 642.643 53.5508C643.167 53.8993 643.783 54.0834 644.412 54.0796C644.821 54.0849 645.226 54.0093 645.606 53.8571C645.985 53.7049 646.331 53.4792 646.623 53.193C646.915 52.9067 647.147 52.5656 647.306 52.1891C647.466 51.8127 647.549 51.4084 647.552 50.9996V50.9996Z' stroke='#282828' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M644.417 24.1595C645.541 24.1324 646.666 24.206 647.777 24.3795L649.037 24.6395C649.528 24.7487 649.966 25.0243 650.277 25.4196L651.937 27.5195C652.243 27.9104 652.41 28.3928 652.41 28.8895C652.41 29.3863 652.243 29.8687 651.937 30.2595L646.157 37.5195C645.949 37.7782 645.685 37.987 645.386 38.1304C645.087 38.2739 644.759 38.3483 644.427 38.3483C644.095 38.3483 643.767 38.2739 643.468 38.1304C643.168 37.987 642.905 37.7782 642.697 37.5195L636.917 30.2595C636.607 29.8701 636.438 29.3872 636.438 28.8895C636.438 28.3919 636.607 27.9089 636.917 27.5195L638.577 25.4196C638.877 25.0121 639.32 24.7333 639.817 24.6395C640.197 24.5395 640.617 24.4595 641.057 24.3795C642.168 24.2067 643.293 24.1331 644.417 24.1595Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M669.728 42.6206C670.111 43.6833 670.386 44.7823 670.548 45.9006C670.548 46.3406 670.668 46.7606 670.708 47.1606C670.744 47.6632 670.61 48.1635 670.328 48.5806L668.848 50.8006C668.567 51.2121 668.156 51.5181 667.682 51.6703C667.207 51.8224 666.696 51.812 666.228 51.6406L657.548 48.3406C657.237 48.2245 656.957 48.0396 656.728 47.8C656.499 47.5603 656.327 47.272 656.225 46.9567C656.123 46.6413 656.094 46.3069 656.139 45.9786C656.185 45.6503 656.304 45.3365 656.488 45.0606L661.628 37.3206C661.909 36.9091 662.319 36.6031 662.793 36.451C663.268 36.2988 663.78 36.3093 664.248 36.4806L666.748 37.4206C667.216 37.5999 667.61 37.9308 667.868 38.3606C668.068 38.7006 668.288 39.0806 668.488 39.4606C669.002 40.4716 669.417 41.5298 669.728 42.6206Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M660.492 72.1204C659.608 72.8264 658.657 73.4428 657.652 73.9604C657.284 74.171 656.903 74.3581 656.511 74.5204C656.051 74.7184 655.538 74.7606 655.052 74.6404L652.472 73.9604C651.989 73.8338 651.564 73.5471 651.267 73.1474C650.969 72.7477 650.815 72.2586 650.832 71.7604L651.092 62.4804C651.114 62.156 651.207 61.8405 651.365 61.5562C651.522 61.2718 651.74 61.0255 652.004 60.8347C652.267 60.6439 652.569 60.5134 652.888 60.4521C653.208 60.3909 653.536 60.4006 653.852 60.4804L662.852 62.8204C663.334 62.947 663.759 63.2337 664.057 63.6334C664.355 64.0331 664.508 64.5221 664.492 65.0204V67.6804C664.47 68.1841 664.288 68.6677 663.972 69.0604C663.712 69.3604 663.432 69.6804 663.112 70.0004C662.31 70.7913 661.432 71.5014 660.492 72.1204Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M628.415 72.0387C627.5 71.3724 626.644 70.6297 625.855 69.8187L625.015 68.8787C624.689 68.4913 624.506 68.0045 624.495 67.4986V64.8387C624.487 64.3394 624.646 63.8518 624.947 63.4532C625.247 63.0546 625.673 62.7679 626.155 62.6387L635.155 60.3187C635.47 60.2388 635.799 60.2292 636.118 60.2904C636.438 60.3516 636.739 60.4822 637.003 60.673C637.266 60.8638 637.484 61.1101 637.642 61.3945C637.799 61.6788 637.893 61.9943 637.915 62.3187L638.175 71.5987C638.187 72.0988 638.029 72.5881 637.728 72.9874C637.426 73.3867 636.999 73.6726 636.515 73.7986L633.935 74.4787C633.448 74.5989 632.935 74.5567 632.475 74.3586L631.335 73.7986C630.309 73.3035 629.332 72.7144 628.415 72.0387Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M618.954 42.5807C619.282 41.4993 619.704 40.4486 620.214 39.4407C620.414 39.0407 620.634 38.6808 620.834 38.3408C621.092 37.911 621.486 37.58 621.954 37.4008L624.474 36.4608C624.941 36.2854 625.453 36.275 625.926 36.4315C626.399 36.588 626.805 36.9018 627.074 37.3207L632.214 45.0607C632.398 45.3379 632.516 45.653 632.56 45.9824C632.604 46.3117 632.573 46.6468 632.469 46.9624C632.365 47.278 632.191 47.566 631.96 47.8047C631.729 48.0435 631.446 48.2267 631.134 48.3408L622.454 51.6207C621.985 51.7937 621.47 51.803 620.995 51.6468C620.519 51.4907 620.11 51.1785 619.834 50.7607L618.354 48.5408C618.079 48.1212 617.952 47.621 617.994 47.1207C617.994 46.7207 617.994 46.3007 618.134 45.8607C618.31 44.7453 618.585 43.6477 618.954 42.5807Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M313.914 11.9983C335.84 11.9983 353.614 29.3518 353.614 50.7583C353.614 72.1649 335.84 89.5183 313.914 89.5183C291.989 89.5183 274.214 72.1649 274.214 50.7583C274.214 29.3518 291.989 11.9983 313.914 11.9983Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M313.915 21.3399C330.561 21.3399 344.055 34.5117 344.055 50.7599C344.055 67.0081 330.561 80.1799 313.915 80.1799C297.269 80.1799 283.775 67.0081 283.775 50.7599C283.775 34.5117 297.269 21.3399 313.915 21.3399Z' fill='white' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M317.054 50.9996C317.07 50.3706 316.898 49.7512 316.559 49.2207C316.221 48.6902 315.732 48.2727 315.155 48.0218C314.578 47.7709 313.939 47.698 313.321 47.8124C312.702 47.9267 312.131 48.2232 311.682 48.6638C311.233 49.1044 310.926 49.669 310.799 50.2854C310.673 50.9018 310.734 51.5419 310.973 52.1236C311.213 52.7054 311.621 53.2024 312.145 53.5508C312.669 53.8993 313.285 54.0834 313.914 54.0796C314.323 54.0849 314.729 54.0093 315.108 53.8571C315.487 53.7049 315.833 53.4792 316.125 53.193C316.417 52.9067 316.649 52.5656 316.809 52.1891C316.968 51.8127 317.051 51.4084 317.054 50.9996V50.9996Z' stroke='#282828' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    <path d='M313.915 24.1586C315.046 24.1321 316.177 24.2057 317.295 24.3786L318.535 24.6386C319.026 24.7478 319.464 25.0234 319.775 25.4186L321.435 27.5186C321.745 27.908 321.913 28.391 321.913 28.8886C321.913 29.3863 321.745 29.8692 321.435 30.2586L315.635 37.5186C315.429 37.777 315.167 37.9856 314.869 38.129C314.571 38.2724 314.245 38.3469 313.915 38.3469C313.584 38.3469 313.258 38.2724 312.96 38.129C312.662 37.9856 312.401 37.777 312.195 37.5186L306.395 30.2586C306.085 29.8692 305.916 29.3863 305.916 28.8886C305.916 28.391 306.085 27.908 306.395 27.5186L308.055 25.4186C308.362 25.0196 308.802 24.743 309.295 24.6386L310.535 24.3786C311.653 24.2051 312.784 24.1315 313.915 24.1586Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M339.25 42.6206C339.624 43.6861 339.899 44.7842 340.07 45.9006C340.07 46.3406 340.07 46.7606 340.21 47.1606C340.257 47.6641 340.122 48.1677 339.83 48.5806L338.35 50.8006C338.069 51.2121 337.658 51.5181 337.184 51.6703C336.709 51.8224 336.198 51.812 335.73 51.6406L327.05 48.3406C326.739 48.2245 326.459 48.0396 326.23 47.8C326.001 47.5603 325.829 47.272 325.727 46.9567C325.626 46.6413 325.596 46.3069 325.642 45.9786C325.687 45.6503 325.806 45.3365 325.99 45.0606L331.13 37.3206C331.411 36.9091 331.821 36.6031 332.296 36.451C332.77 36.2988 333.282 36.3093 333.75 36.4806L336.25 37.4206C336.721 37.5942 337.117 37.9266 337.37 38.3606C337.59 38.7006 337.79 39.0806 337.99 39.4606C338.501 40.4752 338.923 41.5326 339.25 42.6206Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M329.993 72.1203C329.114 72.8219 328.17 73.4381 327.173 73.9603C326.773 74.1803 326.393 74.3603 326.033 74.5203C325.566 74.7185 325.047 74.7606 324.553 74.6403L321.973 73.9603C321.493 73.8305 321.07 73.543 320.773 73.144C320.475 72.7451 320.32 72.2578 320.333 71.7603L320.593 62.4803C320.615 62.1538 320.708 61.8361 320.867 61.5499C321.026 61.2638 321.246 61.0162 321.512 60.8251C321.778 60.634 322.082 60.504 322.404 60.4444C322.726 60.3848 323.057 60.3971 323.373 60.4803L332.353 62.8203C332.837 62.9436 333.264 63.2295 333.563 63.6299C333.861 64.0304 334.013 64.5212 333.993 65.0203V67.6803C333.972 68.184 333.79 68.6676 333.473 69.0603L332.633 70.0003C331.821 70.7871 330.937 71.4968 329.993 72.1203Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M297.913 72.0387C297.003 71.3675 296.147 70.6252 295.353 69.8187L294.513 68.8787C294.197 68.486 294.015 68.0024 293.993 67.4986V64.8387C293.982 64.3386 294.139 63.8492 294.441 63.4499C294.742 63.0506 295.169 62.7647 295.653 62.6387L304.633 60.3187C304.949 60.2351 305.279 60.2226 305.599 60.2823C305.92 60.342 306.224 60.4724 306.488 60.6638C306.752 60.8552 306.97 61.103 307.127 61.3893C307.284 61.6755 307.375 61.9929 307.393 62.3187L307.653 71.5987C307.665 72.0988 307.507 72.5881 307.206 72.9874C306.905 73.3867 306.477 73.6726 305.993 73.7986L303.413 74.4787C302.927 74.5989 302.414 74.5567 301.953 74.3586L300.813 73.7986C299.795 73.3024 298.824 72.7133 297.913 72.0387Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M288.472 42.5803C288.783 41.4961 289.199 40.4445 289.712 39.4403L290.332 38.3403C290.593 37.9049 290.995 37.5729 291.472 37.4003L293.972 36.4603C294.439 36.2849 294.951 36.2746 295.424 36.4311C295.897 36.5876 296.303 36.9014 296.572 37.3203L301.712 45.1403C301.896 45.4162 302.015 45.73 302.061 46.0583C302.106 46.3867 302.077 46.721 301.975 47.0364C301.873 47.3518 301.701 47.6401 301.472 47.8797C301.243 48.1193 300.963 48.3042 300.652 48.4203L291.952 51.7003C291.483 51.8733 290.968 51.8826 290.493 51.7264C290.017 51.5703 289.608 51.2581 289.332 50.8403L287.852 48.6203C287.577 48.2007 287.45 47.7006 287.492 47.2003C287.492 46.8003 287.492 46.3803 287.652 45.9403C287.814 44.7959 288.088 43.6703 288.472 42.5803Z' fill='#DBDBDB' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M670.711 87.4402L685.711 90.7402C692.944 92.3184 699.274 96.6605 703.351 102.84L710.751 114.06C723.593 101.798 740.27 94.3389 757.971 92.9402' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M282.433 111.58C354.893 117.24 431.213 121.18 511.113 122.8C576.753 124.12 640.013 123.76 700.693 122.08' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M690.709 53.42C700.829 53.7 709.609 54.18 716.709 54.66C731.709 55.7 735.909 56.52 740.009 58.84C743.149 60.6 743.509 61.68 749.749 66.2C754.329 69.5 758.389 71.9799 761.109 73.5599' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M227.416 101.839C226.375 103.516 225.102 105.037 223.636 106.359' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M398.712 132.579L404.992 128.319L397.292 122.079C396.693 121.963 396.075 121.986 395.485 122.145C394.896 122.305 394.351 122.597 393.892 122.999C393.131 123.735 392.692 124.741 392.672 125.799L398.672 132.579' fill='#DBDBDB'/>
    <path d='M398.712 132.579L404.992 128.319L397.292 122.079C396.693 121.963 396.075 121.986 395.485 122.145C394.896 122.305 394.351 122.597 393.892 122.999C393.131 123.735 392.692 124.741 392.672 125.799L398.672 132.579' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M414.51 141.6C412.725 141.809 410.925 141.87 409.13 141.78C404.53 141.66 399.55 141.54 397.67 138.64C397.115 137.544 396.909 136.305 397.081 135.089C397.253 133.873 397.793 132.739 398.63 131.84C401.37 128.44 407.27 127.38 411.45 128.84C412.29 133.12 413.31 137.373 414.51 141.6Z' fill='#DDDDDD' stroke='#282828' strokeWidth='2' strokeLinejoin='round'/>
    <path d='M254.514 101.579L278.514 83.5795C316.514 117.579 361.514 87.5795 362.514 46.5786H376.514C367.714 73.7786 372.18 105.246 375.514 117.579C340.514 116.579 314.514 114.579 283.514 111.579C273.514 106.579 265.514 104.579 254.514 101.579Z' fill={frontRightFender} stroke='#282828' strokeWidth='2'/>
    <path d='M360.509 35.5799C361.309 40.3799 361.509 44.5793 361.509 46.5793C441.51 46.5802 597.509 46.5798 597.509 46.5798V34.5799C519.51 34.5798 383.509 35.5799 360.509 35.5799Z' fill={rightSill}/>
    <path d='M360.509 35.5799C361.309 40.3799 361.509 44.5793 361.509 46.5793C441.51 46.5802 597.509 46.5798 597.509 46.5798V34.5799C519.51 34.5798 383.509 35.5799 360.509 35.5799Z' fill={rightSill} />
    <path d='M360.509 35.5799C361.309 40.3799 361.509 44.5793 361.509 46.5793C441.51 46.5802 597.509 46.5798 597.509 46.5798V34.5799C519.51 34.5798 383.509 35.5799 360.509 35.5799Z' stroke='#282828' strokeWidth='2'/>
    <path d='M627.514 123.58C631.514 108.58 601.514 78.5799 581.514 46.5799H597.514C597.514 89.1226 638.514 107.58 670.514 87.3717L670.719 87.4104C687.603 90.5963 698.563 92.6643 710.514 113.58L700.514 122.58L627.514 123.58Z' fill={rearRightFender}/>
    <path d='M627.514 123.58C631.514 108.58 601.514 78.5799 581.514 46.5799H597.514C597.514 89.1226 638.514 107.58 670.514 87.3717L670.719 87.4104C687.603 90.5963 698.563 92.6643 710.514 113.58L700.514 122.58L627.514 123.58Z' fill={rearRightFender} />
    <path d='M627.514 123.58C631.514 108.58 601.514 78.5799 581.514 46.5799H597.514C597.514 89.1226 638.514 107.58 670.514 87.3717L670.719 87.4104C687.603 90.5963 698.563 92.6643 710.514 113.58L700.514 122.58L627.514 123.58Z' stroke='#282828' strokeWidth='2'/>
  </svg>
)
