import { ThreadBasicData } from 'actff-bo-lib/chat'
import { Loadable } from 'actff-bo-lib/global'
import * as React from 'react'

import { ChatNewThreadResult } from './ChatNewThreadResult'

type Props = {
  readonly onClick: (result: ThreadBasicData) => () => void,
  readonly searchThreadResults: Loadable<ReadonlyArray<ThreadBasicData>>,
}

export const ChatNewThreadResults: React.FC<Props> = ({ onClick, searchThreadResults }) => (
  <>
    {searchThreadResults.data
      ? searchThreadResults.data.map(result =>
          <ChatNewThreadResult key={result.carUuid} onClick={onClick(result)} result={result} />,
        )
      : null
    }
  </>
)
