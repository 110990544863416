import { FormSection, OpportunityInsuranceId, OpportunityStatus } from 'actff-bo-lib/crm/insurance'

export const sectionExpanded = (opportunityId?: OpportunityInsuranceId, status?: OpportunityStatus): ReadonlyArray<FormSection> => {
  const defaultExpandedSections: ReadonlyArray<FormSection> = ['process']

  const newOpportunityExpandedSections: ReadonlyArray<FormSection> = ['car']

  // tslint:disable-next-line: cyclomatic-complexity
  const existingOpportunityExpandedSections = (): ReadonlyArray<FormSection> => {
    switch (status) {
      case OpportunityStatus.BEFORE_NOTIFICATION:
        return ['process']
      case OpportunityStatus.BEFORE_CONTACT:
      case OpportunityStatus.RETRY_CONTACT:
        return ['contactHistory']
      case OpportunityStatus.BEFORE_OFFER:
      case OpportunityStatus.BEFORE_PICKUP:
      case OpportunityStatus.BEFORE_SIGNATURE:
      case OpportunityStatus.BEFORE_VERIFICATION:
        return ['offer']
      case OpportunityStatus.BEFORE_DECISION:
        return ['offer', 'newPolicy']
      case OpportunityStatus.BEFORE_PAYMENT:
        return ['newPolicy']
      case OpportunityStatus.UNKNOWN:
        return ['offer', 'driver', 'process']
      default:
        return []
    }
  }

  return [...defaultExpandedSections, ...opportunityId ? existingOpportunityExpandedSections() : newOpportunityExpandedSections]
}
