import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

export const selectDealerLocationSettingsFeature = (state: State) => state.admin.locationSettings
export const selectDealerLocationsFeature = (state: State) => state.admin.dealerLocations

export const selectOpeningHours = createSelector(
  selectDealerLocationSettingsFeature,
  locationSettings => locationSettings.openingHours,
)

export const selectAdminDealerSettings = createSelector(
  selectDealerLocationSettingsFeature,
  locationSettings => locationSettings.dealerSettings,
)

export const selectCurrentLocationApplicationLinks = createSelector(
  selectDealerLocationsFeature,
  dealerLocations => dealerLocations.applicationLinks,
)

export const selectDealerLocationBrands = createSelector(
  selectDealerLocationsFeature,
  dealerLocations => dealerLocations.locationBrands,
)
