import { getServiceName, Service } from 'actff-bo-lib/service'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, layers } from 'styles'

type Props = {
  readonly services: ReadonlyArray<Service>,
}

const AdditionalServicesContainer = styled.span`
  background: ${colors.mystic};
  border-radius: 2px;
  cursor: pointer;
  font-weight: 600;
  margin-left: 5px;
  padding: 2px 5px;
  position: relative;

  div {
    display: none;
    background: ${colors.white};
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    left: 50%;
    min-width: 150px;
    padding: 12px 14px;
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% + 15px)) translateX(-50%);
    z-index: ${layers.tooltip};

    :after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }
  }

  &:hover > div {
    display: grid;
    grid-auto-flow: row;
  }
`

const AdditionalServicesNumber: React.FC<Props> = ({ services }) => {
  const { t } = useTranslation()

  return (
    services.length > 0
      ? (
        <AdditionalServicesContainer>
          +{services.length}
          <div>
            {services.map(service => <span key={service.uuid}>{getServiceName(service, t)}</span>)}
          </div>
        </AdditionalServicesContainer>
      )
      : null
  )
}

export const ServiceRequestServices: React.FC<Props> = ({ services }) => {
  const { t } = useTranslation()
  const [first, ...rest] = services

  return first
    ? (
      <>
        {getServiceName(first, t)}
        <AdditionalServicesNumber services={rest} />
      </>
    )
    : null
}
