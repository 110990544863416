import { Paper, Popper } from '@material-ui/core'
import { OpportunityFailureReason, OpportunityResult } from 'actff-bo-lib/crm/insurance'
import { IconType } from 'actff-bo-lib/menu'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconWrapper, PopperContent, StatusIcon } from './Styled'

type Props = {
  readonly result: OpportunityResult | null,
  readonly failureReason: OpportunityFailureReason | null,
}

export const OpportunityResultIcon: React.FC<Props> = ({ failureReason, result }) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)
  const open = Boolean(popoverAnchor)
  const { t } = useTranslation()

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setPopoverAnchor(null)
  }

  const getFailureReason = () => result !== OpportunityResult.SUCCESS ?
    (
      <>
        <b>{t('crmInsurance.process.failureReason')}:</b>&nbsp;

        {t(`crmInsurance.failureReason.${failureReason?.toLowerCase()}`)}
      </>
    )
    : null

  const getResultIcon = () => {
    switch (result) {
      case OpportunityResult.DEFINITIVE_FAILURE:
        return IconType.StatusDefinitiveFailure
      case OpportunityResult.FAILURE:
        return IconType.StatusFailure
      case OpportunityResult.SUCCESS:
        return IconType.StatusSuccess
      default:
        return null
    }
  }

  return (
    <>
      <IconWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <StatusIcon type={getResultIcon()} />
      </IconWrapper>

      <Popper
        anchorEl={popoverAnchor}
        open={open}
        placement='top'
      >
        <Paper>
          <PopperContent>
            <b>{t(`crmInsurance.result.${result?.toLowerCase()}`)}</b><br />
            {getFailureReason()}
          </PopperContent>
        </Paper>
      </Popper>
    </>
  )
}
