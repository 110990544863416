import { OpportunityFailureReason, OpportunityResult, OpportunityStatus } from 'actff-bo-lib/crm/insurance'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { OpportunityResultIcon } from './OpportunityResultIcon'

const StatusContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  width: 150px;
`

type OpportunityRowStatusProps = {
  readonly status: OpportunityStatus | null,
  readonly result: OpportunityResult | null,
  readonly failureReason: OpportunityFailureReason | null,
}

export const OpportunityRowStatus: FC<OpportunityRowStatusProps> = ({ status, failureReason, result }) => {
  const { t } = useTranslation()

  return (
    <>
      <StatusContent>
        <span>
          {t(`crmInsurance.status.${status?.toLowerCase()}`)}
          {status === OpportunityStatus.CLOSED && <OpportunityResultIcon result={result} failureReason={failureReason} />}
        </span>
      </StatusContent>
    </>
  )
}
