import { api, PagedResult } from 'actff-bo-lib/api'
import { Client } from 'actff-bo-lib/client'
import { buildUrl } from 'actff-bo-lib/global/url'
import { Observable } from 'rxjs'

import { AppPersonId, CarInfo, Counterparty, OwnedCar } from '../dto'

export const getOwnershipCounterpartyList = (page: number, searchText?: string): Observable<PagedResult<Counterparty>> =>
  api.get<PagedResult<Counterparty>>(
    buildUrl('/cars/ownership/counterparty', { page, searchText }),
  )

export const getOwnedCar = (vin: string) => api.get<OwnedCar>(`/cars/ownership/${vin}`)

export const getAppPersonCars = (appPersonId: AppPersonId, page?: number) =>
  api.get<PagedResult<CarInfo>>(buildUrl('/car', { clientUuid: appPersonId, page }))

export const deleteOwnershipAppDriver = (vin: string): Observable<OwnedCar> =>
  api.delete<OwnedCar>(`/cars/ownership/${vin}/app-driver`)

export const deleteOwnershipNdcDriver = (vin: string): Observable<OwnedCar> =>
  api.delete<OwnedCar>(`/cars/ownership/${vin}/ndc-driver`)

export const deleteOwnershipNdcOwner = (vin: string): Observable<OwnedCar> =>
  api.delete<OwnedCar>(`/cars/ownership/${vin}/ndc-owner`)

export const updateOwnershipAppDriver = (vin: string, appPerson: Client): Observable<OwnedCar> =>
  api.put<OwnedCar, Client>(`/cars/ownership/${vin}/app-driver`, appPerson)

export const updateOwnershipNdcDriver = (vin: string, ndcPerson: Counterparty): Observable<OwnedCar> =>
  api.put<OwnedCar, Counterparty>(`/cars/ownership/${vin}/ndc-driver`, ndcPerson)

export const updateOwnershipNdcOwner = (vin: string, ndcPerson: Counterparty): Observable<OwnedCar> =>
  api.put<OwnedCar, Counterparty>(`/cars/ownership/${vin}/ndc-owner`, ndcPerson)
