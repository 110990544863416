import { FiltersRendererProps } from 'actff-bo-app/components/TabFilters'
import { selectDealerBrands } from 'actff-bo-lib/dealership'
import { selectMe } from 'actff-bo-lib/user'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const DealerBrandContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
`

type Props = FiltersRendererProps & {
  readonly filterFormNamePrefix?: string,
}

export const ListBrandFiltersForm: FC<Props> = ({ control, filterFormNamePrefix }) => {
  const { data } = useSelector(selectDealerBrands)
  const me = useSelector(selectMe)

  return (
    data && (
      <DealerBrandContainer>
        {data.map(brand =>
          (
            <div key={brand}>
              <Controller
                render={({ onChange, value }) => (
                  <input
                    checked={value}
                    disabled={!me?.brands.some(_brand => _brand === brand)}
                    id={`filter-brands-${brand}`}
                    onChange={() => onChange(!value)}
                    type='checkbox'
                    value={value}
                  />
                )}
                control={control}
                name={`${filterFormNamePrefix ? `${filterFormNamePrefix}.${brand}` : brand}`}
              />
              <label id={`filter-brands-${brand}`} htmlFor={`filter-brands-${brand}`}>{brand}</label>
            </div>
          ))}
      </DealerBrandContainer>
    )
  )
}
