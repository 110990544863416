import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { CarId, CarTires, CarTiresWithUuid } from '../dto'

export enum CarTiresActionType {
  GetCarTires = '[CAR] GET CAR TIRES',
  GetCarTiresFailure = '[CAR] GET CAR TIRES FAILURE',
  GetCarTiresSuccess = '[CAR] GET CAR TIRES SUCCESS',

  UpdateCarTires = '[CAR] UPDATE CAR TIRES',
  UpdateCarTiresFailure = '[CAR] UPDATE CAR TIRES FAILURE',
  UpdateCarTiresSuccess = '[CAR] UPDATE CAR TIRES SUCCESS',
}

export const CarTiresAction = {
  getCarTires: (carId: CarId) => createAction(CarTiresActionType.GetCarTires, carId),
  getCarTiresFailure: (error: AjaxError) => createAction(CarTiresActionType.GetCarTiresFailure, error),
  getCarTiresSuccess: (carTires: CarTires) => createAction(CarTiresActionType.GetCarTiresSuccess, carTires),

  updateCarTires: (payload: CarTiresWithUuid) => createAction(CarTiresActionType.UpdateCarTires, payload),
  updateCarTiresFailure: (error: AjaxError) => createAction(CarTiresActionType.UpdateCarTiresFailure, error),
  updateCarTiresSuccess: (carTires: CarTires) => createAction(CarTiresActionType.UpdateCarTiresSuccess, carTires),
}

export type CarTiresAction = ActionsUnion<typeof CarTiresAction>
