import { AppPerson, Counterparty } from 'actff-bo-lib/car/dto/ownership'
import { Client } from 'actff-bo-lib/client'
import { OpportunityCarDriver, OpportunityCarOwner, OpportunityContactPerson } from 'actff-bo-lib/crm/insurance'
import { DealerLocation } from 'actff-bo-lib/dealership'
import { NominalString } from 'actff-bo-lib/global'

import { CarFinance, CarFinanceDto } from './finance'
import { CarInsurance, CarInsuranceDto } from './insurance'
import { CarService } from './service'

export type CarId = NominalString<CarInfo>

export enum CarStatus {
  ACCEPTED = 'ACCEPTED',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  INSURANCE_NOT_ACCEPTED = 'INSURANCE_NOT_ACCEPTED',
  FINANCE_NOT_ACCEPTED = 'FINANCE_NOT_ACCEPTED',
}

export type CarFilterValues = CarStatus

export enum CarColor {
  beige = 'BEIGE',
  white = 'WHITE',
  burgundy = 'BURGUNDY',
  brown = 'BROWN',
  black = 'BLACK',
  red = 'RED',
  purple = 'PURPLE',
  blue = 'BLUE',
  silver = 'SILVER',
  gray = 'GRAY',
  green = 'GREEN',
  gold = 'GOLD',
  yellow = 'YELLOW',
  other = 'OTHER',
}

export enum CarEngineRange {
  small = '0-1200',
  medium = '1201-1600',
  big = '1600+',
}

export enum CarFuelShortList {
  Gasoline = 'GASOLINE',
  Diesel = 'DIESEL',
}

export type CarInfo = {
  readonly alias: string,
  readonly attachmentList: ReadonlyArray<string>,
  readonly brand: string,
  readonly client: Client,
  readonly carOrigin: string,
  readonly carReleaseDate: Date | null,
  readonly created: Date,
  readonly dealerLocation: DealerLocation | null,
  readonly dms: boolean,
  readonly engineCode: string,
  readonly enginePower: string,
  readonly firstRegistrationDate: Date | null,
  readonly fuel: string,
  readonly fuelType?: string,
  readonly knownMileage: string,
  readonly lastServiceDate: Date,
  readonly marketValue?: string,
  readonly model: string,
  readonly mot: Date | null,
  readonly modelYear: string,
  readonly owner?: Counterparty | AppPerson,
  readonly productionYear: string,
  readonly registrationNumber: string,
  readonly serviceAcceptanceDate: Date,
  readonly serviceDetails: CarService,
  readonly status: CarStatus,
  readonly uuid: CarId,
  readonly version: string,
  readonly vin: string,
  readonly wilsonCarUuid?: CarId,
}

export type CarInfoDto =
  Partial<Omit<CarInfo,
    'alias'
    | 'attachmentList'
    | 'client'
    | 'created'
    | 'knownMileage'
    | 'lastServiceDate'
    | 'modelYear'
    | 'mot'
    | 'serviceAcceptanceDate'
  >>

export const defaultBrakeLiquid = 'DOT-4'

export type CarFormDataDto = CarInsuranceDto | CarFinanceDto

export enum CarEngineFuelType {
  Diesel = 'DIESEL',
  Gasoline = 'GASOLINE',
  GasolineCng = 'GASOLINECNG',
  GasolineLpg = 'GASOLINELPG',
  NA = 'NA',
}

export type CarEngineCode = string

export type CarInfoWithClient = CarInfo & {
  readonly client: Client,
}

export type CarInfoWithFinance = CarInfo & {
  readonly finance: CarFinance,
}

export type CarInfoWithInsurance = CarInfo & {
  readonly insurance: CarInsurance,
}

export type CarServiceWithCarUuid = CarService & {
  readonly carUuid: CarId,
}

export type CarFinanceWithCarUuid = CarFinance & {
  readonly carUuid: CarId,
}

export type Car = CarInfoWithFinance & CarInfoWithInsurance & CarInfoWithClient

export type NewCarsCount = {
  readonly carsCount: number,
}

export type CarDataExistencePayload = {
  readonly exists: boolean,
}

type Address = {
  readonly street: string,
  readonly postCode: string,
  readonly city: string,
}

type ContactInfo = {
  readonly mobile: string,
  readonly phone: string,
  readonly email: string,
}

export type SearchCarResult = {
  readonly brand: string,
  readonly carKind: string,
  readonly firstRegistrationDate: string,
  readonly model: string,
  readonly productionYear: string,
  readonly registrationNumber: string,
  readonly version: string,
  readonly vin: string,
  readonly client?: OpportunityContactPerson,
  readonly driver?: OpportunityCarDriver & { readonly address: Address } & { readonly contactInfo: ContactInfo },
  readonly owner?: OpportunityCarOwner & { readonly address: Address } & { readonly contactInfo: ContactInfo },
}

export type withEngineCodeFn = (engineCode?: CarEngineCode) => void

export const NativeCarBrand = 'volvo'
export const NativeCarBrandLabel = 'Volvo'

export const carEarliestProductionYear = 1995
export const yearNow = new Date().getFullYear()
export const carMaxKnownMileage = 400000
export const carMaxPrice = 500000
export const carKnownMileageInterval = 50000
export const priceInterval = 20000
