import { getClient } from 'actff-bo-lib/client/dao'
import { isNotEmptyString } from 'actff-bo-lib/dictionary/helpers'
import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { createRoute } from 'actff-bo-lib/menu'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { maxPage, redirectToList } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { LOCATION_CHANGE } from 'connected-react-router'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { forkJoin, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import {
  CarAction,
  CarActionType,
  CarFinanceAction,
  CarFinanceActionType,
  CarInsuranceAction,
  CarInsuranceActionType,
  NewCarAction,
  NewCarActionType,
} from '../actions'
import { CarTiresAction, CarTiresActionType } from '../actions/tires'
import {
  checkRegistrationNumberExistence,
  checkVinExistence,
  createCar,
  deleteCar,
  getCar,
  getCarAttachment,
  getCarFinance,
  getCarInsurance,
  getCars,
  getCarTires,
  getNdcCar,
  getNewCarsCount,
  updateCar,
  updateCarFinance,
  updateCarInsurance,
  updateCarStatus,
  updateCarTires,
} from '../dao'
import { CarId, CarStatus } from '../dto'
import {
  selectCarListDealerBrandFilters,
  selectCarListDealerLocationFilters,
  selectCarListFilters,
  selectCarListSearchPhrase,
  selectCarsNoOfPages,
  selectCurrentCar,
  selectCurrentCarAttachmentList,
  selectCurrentPage,
} from '../selectors'
import { carEquipmentEpic } from './equipment'
import { carInspectionEpic } from './inspection'
import { ownershipEpic } from './ownership'

// tslint:disable max-file-line-count
// TODO: Split this epic into smaller ones

const changeListParamsEpic: Epic<CarAction, CarAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof CarAction.changePage>>(CarActionType.ChangePage),
  withLatestFrom(store$),
  tap(([, store]) => {
    redirectToList(Paths.CarList, selectCurrentPage(store))
  }),
  map(CarAction.getCars),
)

const checkVinExistenceEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.checkVinExistence>>(CarActionType.CheckVinExistence),
  switchMap(({ payload }) => checkVinExistence(payload.uuid, payload.vin).pipe(
    map(CarAction.checkVinExistenceSuccess),
    catchError((error: AjaxError) => of(CarAction.checkVinExistenceFailure(error)),
  )),
))

const checkRegistrationNumberExistenceEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.checkRegistrationNumberExistence>>(CarActionType.CheckRegistrationNumberExistence),
  switchMap(({ payload }) => checkRegistrationNumberExistence(payload.uuid, payload.registrationNumber).pipe(
    map(CarAction.checkRegistrationNumberExistenceSuccess),
    catchError((error: AjaxError) => of(CarAction.checkRegistrationNumberExistenceFailure(error)),
  )),
))

const changeFilterOrSearchPhraseEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType(
    CarActionType.ChangeListFilter,
    CarActionType.ChangeSearchPhrase,
    CarActionType.ChangeDealerLocationFilters,
    CarActionType.ChangeBrandFilters,
  ),
  map(() => CarAction.changePage(1)),
)

const reinitializeDealerFiltersWhenEmptySearchingEpic: Epic<CarAction, CarAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof CarAction.changeSearchPhrase>>(CarActionType.ChangeSearchPhrase),
  withLatestFrom(store$),
  filter(([{ payload }]) => payload === ''),
  map(([, { user }]) => CarAction.changeDealerLocationFilters(user.me?.locations.map(location => location.key) || [])),
)

const createCarEpic: Epic<NewCarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof NewCarAction.createCar>>(NewCarActionType.CreateCar),
  switchMap(({ payload }) => createCar(payload.carDto, payload.clientUuid).pipe(
    map(NewCarAction.createCarSuccess),
    catchError((error: AjaxError) => of(NewCarAction.createCarFailure(error))),
  )),
)

const createCarSuccessEpic: Epic<NewCarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof NewCarAction.createCarSuccess>>(NewCarActionType.CreateCarSuccess),
  tap(({ payload }) => history.push(createRoute(Paths.ClientViewCars, { clientId: payload.client.uuid }))),
  ignoreElements(),
)

const getCarEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.getCar>>(CarActionType.GetCar),
  switchMap(({ payload }) => getCar(payload).pipe(
    map(CarAction.getCarSuccess),
    catchError((error: AjaxError) => of(CarAction.getCarFailure(error)),
  )),
))

const getNdcCarEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.getNdcCar>>(CarActionType.GetNdcCar),
  switchMap(({ payload }) => getNdcCar(payload).pipe(
    map(CarAction.getNdcCarSuccess),
    catchError((error: AjaxError) => of(CarAction.getNdcCarFailure(error)),
  )),
))

const getCarsEpic: Epic<CarAction> = (action$, store$) => action$.pipe(
  ofType(CarActionType.GetCars),
  withLatestFrom(store$),
  switchMap(([, state]) => getCars(
    selectCarListFilters(state),
    selectCarListDealerLocationFilters(state),
    selectCarListDealerBrandFilters(state),
    selectCurrentPage(state) - 1,
    encodeStringIfExists(selectCarListSearchPhrase(state)),
  ).pipe(
    map(CarAction.getCarsSuccess),
    catchError((error: AjaxError) => of(CarAction.getCarsFailure(error)),
  )),
))

const getCarFinanceEpic: Epic<CarFinanceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarFinanceAction.getCarFinance>>(CarFinanceActionType.GetCarFinance),
  switchMap(({ payload }) => getCarFinance(payload).pipe(
    map(CarFinanceAction.getCarFinanceSuccess),
    catchError((error: AjaxError) => of(CarFinanceAction.getCarFinanceFailure(error)),
  )),
))

const getCarInsuranceEpic: Epic<CarInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInsuranceAction.getCarInsurance>>(CarInsuranceActionType.GetCarInsurance),
  switchMap(({ payload }) => getCarInsurance(payload).pipe(
    map(CarInsuranceAction.getCarInsuranceSuccess),
    catchError((error: AjaxError) => of(CarInsuranceAction.getCarInsuranceFailure(error)),
  )),
))

const getCarTiresEpic: Epic<CarTiresAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarTiresAction.getCarTires>>(CarTiresActionType.GetCarTires),
  switchMap(({ payload }) => getCarTires(payload).pipe(
    map(CarTiresAction.getCarTiresSuccess),
    catchError((error: AjaxError) => of(CarTiresAction.getCarTiresFailure(error))),
  )),
)

const getCarAttachmentsEpic: Epic<CarAction, CarAction, State> = (action$, state$) => action$.pipe(
  ofType(CarActionType.GetCarSuccess),
  withLatestFrom(state$),
  map(([, state]) => selectCurrentCarAttachmentList(state)),
  filter<ReadonlyArray<string>>(Boolean),
  map(attachments => attachments.map(getCarAttachment)),
  switchMap(attachments => forkJoin(attachments).pipe(
    map(CarAction.getCarAttachmentsSuccess),
    catchError((error: AjaxError) => of(CarAction.getCarAttachmentsFailure(error))),
  )),
)

const getNewCarsCountEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType(CarActionType.GetNewCarsCount),
  switchMap(() => getNewCarsCount().pipe(
    map(CarAction.getNewCarsCountSuccess),
    catchError((error: AjaxError) => of(CarAction.getNewCarsCountFailure(error))),
  )),
)

const getSelectedClient: Epic<NewCarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof NewCarAction.getSelectedClient>>(NewCarActionType.GetSelectedClient),
  switchMap(({ payload }) => getClient(payload).pipe(
    map(NewCarAction.getSelectedClientSuccess),
    catchError((error: AjaxError) => of(NewCarAction.getSelectedClientFailure(error))),
  )),
)

const updateCarEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.updateCar>>(CarActionType.UpdateCar),
  switchMap(({ payload }) => updateCar(payload).pipe(
    map(CarAction.updateCarSuccess),
    catchError((error: AjaxError) => of(CarAction.updateCarFailure(error))),
  )),
)

const rejectCarEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.rejectCar>>(CarActionType.RejectCar),
  switchMap(({ payload }) => updateCarStatus({
    status: CarStatus.REJECTED,
    uuid: payload,
  }).pipe(
    map(CarAction.rejectCarSuccess),
    catchError((error: AjaxError) => of(CarAction.rejectCarFailure(error)),
  )),
))

const deleteCarEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.deleteCar>>(CarActionType.DeleteCar),
  switchMap(({ payload }) => deleteCar(payload).pipe(
    map(() => CarAction.deleteCarSuccess(payload)),
    catchError((error: AjaxError) => of(CarAction.deleteCarFailure(error)),
  )),
))

const refetchCarWhenUpdateFromEquipmentEpic: Epic<CarAction, CarAction> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof CarAction.updateCarSuccess>>(CarActionType.UpdateCarSuccess),
  withLatestFrom(state$),
  filter(() => history.location.pathname.includes('equipment')),
  map(([, store]) => selectCurrentCar(store)),
  map(loadableCar => loadableCar && CarAction.getCar(loadableCar.data?.uuid as CarId)),
)

const redirectOnCarDataUpdateSuccessEpic: Epic<CarAction | CarFinanceAction | CarInsuranceAction | CarTiresAction> = action$ =>
  action$.pipe(
    ofType<
      ReturnType<typeof CarAction.updateCarSuccess>
      | ReturnType<typeof CarFinanceAction.updateCarFinanceSuccess>
      | ReturnType<typeof CarInsuranceAction.updateCarInsuranceSuccess>
      | ReturnType<typeof CarTiresAction.updateCarTiresSuccess>
    >
    (
      CarActionType.UpdateCarSuccess,
      CarFinanceActionType.UpdateCarFinanceSuccess,
      CarInsuranceActionType.UpdateCarInsuranceSuccess,
      CarTiresActionType.UpdateCarTiresSuccess,
    ),
    tap(() => !history.location.pathname.includes('equipment') && history.push(Paths.CarList)),
    ignoreElements(),
)

const changePageWhenOutOfPagesRangeEpic: Epic<CarAction, CarAction> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof CarAction.getCarsSuccess>>(CarActionType.GetCarsSuccess),
  withLatestFrom(state$),
  filter(([, store]) => selectCurrentPage(store) > (selectCarsNoOfPages(store) || maxPage)),
  map(([, store]) => CarAction.changePage(selectCarsNoOfPages(store) || 1)),
)

const changeToFirstPageWhenRouteOutsideListEpic: Epic<AnyAction, CarAction> = (action$, state$) => action$.pipe(
  ofType(LOCATION_CHANGE),
  withLatestFrom(state$),
  filter(([action]) => action.payload.location.pathname === Paths.CarList),
  filter(([action]) => !isNotEmptyString(action.payload.location.search)),
  map(() => CarAction.changePage( 1)),
)

const redirectToCarListEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType(CarActionType.DeleteCarSuccess),
  tap(() => {
    history.push(Paths.CarList)
  }),
  ignoreElements(),
)

const revertCarEpic: Epic<CarAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarAction.revertCar>>(CarActionType.RevertCar),
  switchMap(({ payload }) => updateCarStatus({
    status: CarStatus.NEW,
    uuid: payload,
  }).pipe(
    map(CarAction.revertCarSuccess),
    catchError((error: AjaxError) => of(CarAction.revertCarFailure(error)),
  )),
))

const updateCarFinanceEpic: Epic<CarFinanceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarFinanceAction.updateCarFinance>>(CarFinanceActionType.UpdateCarFinance),
  switchMap(({ payload }) => updateCarFinance(payload).pipe(
    map(CarFinanceAction.updateCarFinanceSuccess),
    catchError((error: AjaxError) => of(CarFinanceAction.updateCarFinanceFailure(error)),
  )),
))

const updateCarInsuranceEpic: Epic<CarInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInsuranceAction.updateCarInsurance>>(CarInsuranceActionType.UpdateCarInsurance),
  switchMap(({ payload }) => updateCarInsurance(payload).pipe(
    map(CarInsuranceAction.updateCarInsuranceSuccess),
    catchError((error: AjaxError) => of(CarInsuranceAction.updateCarInsuranceFailure(error)),
  )),
))

const updateCarTiresEpic: Epic<CarTiresAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarTiresAction.updateCarTires>>(CarTiresActionType.UpdateCarTires),
  switchMap(({ payload }) => updateCarTires(payload).pipe(
    map(CarTiresAction.updateCarTiresSuccess),
    catchError((error: AjaxError) => of(CarTiresAction.updateCarTiresFailure(error)),
  )),
))

const notifyOnUpdateCarSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof NewCarAction.createCarSuccess>
    | ReturnType<typeof CarAction.updateCarSuccess>
    | ReturnType<typeof CarFinanceAction.updateCarFinanceSuccess>
    | ReturnType<typeof CarInsuranceAction.updateCarInsuranceSuccess>
    | ReturnType<typeof CarTiresAction.updateCarTiresSuccess>
    >
  (
    NewCarActionType.CreateCarSuccess,
    CarActionType.UpdateCarSuccess,
    CarFinanceActionType.UpdateCarFinanceSuccess,
    CarInsuranceActionType.UpdateCarInsuranceSuccess,
    CarTiresActionType.UpdateCarTiresSuccess,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: 'toast.changesSaved',
      id: 'toast.success',
      title: 'toast.success',
      type: ToastType.Success,
    }),
  ]),
)

const notifyOnUpdateCarFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CarAction.updateCarFailure>
    | ReturnType<typeof NewCarAction.createCarFailure>
    | ReturnType<typeof CarFinanceAction.updateCarFinanceFailure>
    | ReturnType<typeof CarInsuranceAction.updateCarInsuranceFailure>
    | ReturnType<typeof CarTiresAction.updateCarTiresFailure>
    >
  (
    CarActionType.UpdateCarFailure,
    NewCarActionType.CreateCarFailure,
    CarFinanceActionType.UpdateCarFinanceFailure,
    CarInsuranceActionType.UpdateCarInsuranceFailure,
    CarTiresActionType.UpdateCarTiresFailure,
  ),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'carView.form.update.failure',
      title: 'carView.form.update.failure',
      type: ToastType.Error,
    })],
  ))

export const carEpic = combineEpics(
  carEquipmentEpic,
  carInspectionEpic,
  ownershipEpic,
  changeListParamsEpic,
  changeFilterOrSearchPhraseEpic,
  changePageWhenOutOfPagesRangeEpic,
  changeToFirstPageWhenRouteOutsideListEpic,
  checkRegistrationNumberExistenceEpic,
  checkVinExistenceEpic,
  createCarEpic,
  createCarSuccessEpic,
  deleteCarEpic,
  getCarAttachmentsEpic,
  getCarEpic,
  getCarFinanceEpic,
  getCarInsuranceEpic,
  getCarTiresEpic,
  getCarsEpic,
  getCarsEpic,
  getNdcCarEpic,
  getNewCarsCountEpic,
  getSelectedClient,
  notifyOnUpdateCarFailureEpic,
  notifyOnUpdateCarSuccessEpic,
  redirectOnCarDataUpdateSuccessEpic,
  redirectToCarListEpic,
  refetchCarWhenUpdateFromEquipmentEpic,
  reinitializeDealerFiltersWhenEmptySearchingEpic,
  rejectCarEpic,
  revertCarEpic,
  updateCarEpic,
  updateCarFinanceEpic,
  updateCarInsuranceEpic,
  updateCarTiresEpic,
)
