import { BtnType } from 'actff-bo-app/components/Button'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { Modal } from 'actff-bo-app/components/Modal'
import { CssPosition } from 'actff-bo-lib/style'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LinkButton, PrimaryButton } from 'styles'

type Props = {
  readonly autoWidth?: boolean,
  readonly confirmDisabled?: boolean,
  readonly title: string,
  readonly onConfirm: (event: React.MouseEvent) => void,
  readonly onClose: (event: React.MouseEvent) => void,
  readonly open: boolean,
  readonly confirmTLabel?: string,
  readonly content?: string,
  readonly contentProps?: { readonly [key: string]: string},
  readonly isLoading?: boolean,
  readonly withCancel?: boolean,
}

const Footer = styled.footer`
  display: inline-grid;
  gap: 16px;
  grid-template-columns: 100px 100px;
  justify-content: end;
  padding: 24px 0;
`

const Content = styled.div`
  align-items: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 180%;
`

export const Dialog: React.FC<Props> = ({
  autoWidth,
  children,
  confirmDisabled = false,
  confirmTLabel,
  content,
  contentProps,
  isLoading,
  open,
  onClose,
  onConfirm,
  title,
  withCancel = true,
}) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} title={title} autoWidth={autoWidth}>
      {isLoading && <LocalLoader height='100%' position={CssPosition.Absolute}/>}

      <Content>
        {content && t(content, contentProps)}
        {children}
      </Content>
      <Footer>
        {withCancel && (
          <LinkButton
            onClick={onClose}
            noPadding={true}
            type={BtnType.Button}
          >
            {t('caption.cancel')}
          </LinkButton>
        )}
        <PrimaryButton
          disabled={confirmDisabled}
          onClick={onConfirm}
          noPadding={true}
          type={BtnType.Button}
        >
          {t(confirmTLabel ?? 'caption.confirm')}
        </PrimaryButton>
      </Footer>
    </Modal>
  )
}
