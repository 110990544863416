import { withCarViewHeader } from 'actff-bo-app/Cars/CarView/withCarViewHeader'
import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { Loader } from 'actff-bo-app/components/Loader'
import { OwnershipAction } from 'actff-bo-lib/car/actions'
import { CarInfoWithClient, Counterparty, OwnershipType, SearchPersonSource } from 'actff-bo-lib/car/dto'
import { selectCurrentOwnedCar, selectIsFetchOwnedCarFailed } from 'actff-bo-lib/car/selectors'
import { Client, ClientAction } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu/dto'
import { Paths } from 'actff-bo-lib/menu/initial-menu'
import { history } from 'actff-bo-lib/router'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CarData } from './CarData'
import { PersonData } from './PersonData'
import { Footer } from './Styled'

type Props = {
  readonly car: CarInfoWithClient,
}

const testId = 'car-view-basic-data__'

const CarViewBasicDataComponent: FC<Props> = ({ car }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { vin: paramsVin } = useParams<{ readonly vin: string }>()

  const { loading: ownedCarLoading, data: ownedCar } = useSelector(selectCurrentOwnedCar)
  const isFetchOwnedCarFailed = useSelector(selectIsFetchOwnedCarFailed)

  const getOwnedCar = useMemo(() => (vin: string) => {
    dispatch(OwnershipAction.getOwnedCarByVin(vin))
  }, [dispatch])

  const memoizedShouldGetOwnedCar = useCallback(
    () => (!ownedCar || ownedCar.vin !== (paramsVin || car.vin)) && !isFetchOwnedCarFailed,
    [ownedCar, paramsVin, car.vin, isFetchOwnedCarFailed],
  )

  const clearCurrentCounterpartyList = () => { dispatch(OwnershipAction.clearCurrentCounterpartyList()) }
  const clearCurrentAppPersonList = () => { dispatch(ClientAction.clearList()) }
  const updateNdcOwner = (vin: string, ndcPerson: Counterparty) => { dispatch(OwnershipAction.updateNdcOwner(vin, ndcPerson)) }
  const updateAppDriver = (vin: string, appPerson: Client) => { dispatch(OwnershipAction.updateAppDriver(vin, appPerson)) }
  const updateNdcDriver = (vin: string, ndcPerson: Counterparty) => { dispatch(OwnershipAction.updateNdcDriver(vin, ndcPerson)) }
  const deleteNdcOwner = (vin: string) => { dispatch(OwnershipAction.deleteNdcOwner(vin)) }
  const deleteAppDriver = (vin: string) => { dispatch(OwnershipAction.deleteAppDriver(vin)) }
  const deleteNdcDriver = (vin: string) => { dispatch(OwnershipAction.deleteNdcDriver(vin)) }
  const handleGoBackClick = () => { history.push(Paths.NdcCars) }

  useEffect(() => {
    if (memoizedShouldGetOwnedCar()) {
      getOwnedCar(paramsVin || car.vin)
    }

  }, [car.vin, memoizedShouldGetOwnedCar, getOwnedCar, ownedCar, paramsVin])

  if (ownedCarLoading) {
    return <Loader />
  }

  const handleSelectAppDriver = (vin: string) => (person: Client) => { updateAppDriver(vin, person) }
  const handleSelectDmsOwner = (vin: string) => (person: Counterparty) => { updateNdcOwner(vin, person) }
  const handleSelectDmsDriver = (vin: string) => (person: Counterparty) => { updateNdcDriver(vin, person) }
  const handleChangeToDriver = (vin: string) => (person: Counterparty) => { updateNdcDriver(vin, person) }
  const handleChangeToOwner = (vin: string) => (person: Counterparty) => { updateNdcOwner(vin, person) }
  const handleDisconnectDmsOwner = (vin: string) => () => { deleteNdcOwner(vin) }
  const handleDisconnectAppDriver = (vin: string) => () => { deleteAppDriver(vin) }
  const handleDisconnectDmsDriver = (vin: string) => () => { deleteNdcDriver(vin) }

  return (
    ownedCar && (
      <>
        <CarData car={car} />

        <PersonData
          clearList={clearCurrentCounterpartyList}
          onChangeToDriver={handleChangeToDriver(ownedCar.vin)}
          onChangeToOwner={handleChangeToOwner(ownedCar.vin)}
          onDisconnectPerson={handleDisconnectDmsOwner(ownedCar.vin)}
          onConfirmSelectPerson={handleSelectDmsOwner(ownedCar.vin)}
          ownershipType={OwnershipType.DmsOwner}
          searchSource={SearchPersonSource.Ndc}
          person={ownedCar.ndcOwner}
          testId={`${testId}owner-dms`}
        />
        <PersonData
          clearList={clearCurrentAppPersonList}
          onDisconnectPerson={handleDisconnectAppDriver(ownedCar.vin)}
          onChangeToOwner={handleChangeToOwner(ownedCar.vin)}
          onConfirmSelectPerson={handleSelectAppDriver(ownedCar.vin)}
          ownershipType={OwnershipType.AppDriver}
          searchSource={SearchPersonSource.App}
          person={ownedCar.appDriver}
          testId={`${testId}app-driver`}
        />
        <PersonData
          clearList={clearCurrentCounterpartyList}
          onChangeToDriver={handleChangeToDriver(ownedCar.vin)}
          onChangeToOwner={handleChangeToOwner(ownedCar.vin)}
          onConfirmSelectPerson={handleSelectDmsDriver(ownedCar.vin)}
          onDisconnectPerson={handleDisconnectDmsDriver(ownedCar.vin)}
          ownershipType={OwnershipType.DmsDriver}
          searchSource={SearchPersonSource.Ndc}
          person={ownedCar.ndcDriver}
          testId={`${testId}dms-driver`}
        />

        <Footer>
          <ButtonWithIcon
            caption={t('caption.back')}
            iconType={IconType.Back}
            onClick={handleGoBackClick}
            testId={`${testId}go-back`}
            theme={BtnTheme.Link}
          />
        </Footer>
      </>
    )
  )
}

export const CarViewBasicData = withCarViewHeader(CarViewBasicDataComponent)
