import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { PurchaseOpportunityTable } from 'actff-bo-app/Crm/Trade/Purchase'

import { SaleOpportunityTable } from 'actff-bo-app/Crm/Trade/Sale/OpportunityList'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { crmTradeActionsMap, TradeOpportunityType } from 'actff-bo-lib/crm/trade'
import { selectOpportunityPurchaseListTimeFrom, selectOpportunityPurchaseListTimeTo } from 'actff-bo-lib/crm/trade/purchase'
import { selectOpportunitySaleListTimeFrom, selectOpportunitySaleListTimeTo } from 'actff-bo-lib/crm/trade/sale'
import { addDays, endOfToday, subDays } from 'date-fns'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { OpportunityListDayOffset } from './OpportunityListDayOffset'
import { Container, OpportunityListExpandCollapseButton, OpportunityListHeader, OpportunityListHeaderContainer } from './Styled'

type Props = {
  readonly title: string,
  readonly type: OpportunityListType,
  readonly changeTimeFrom?: boolean,
  readonly changeTimeTo?: boolean,
  readonly opportunityType: TradeOpportunityType,
}

export const OpportunityList: FC<Props> = ({
  changeTimeFrom,
  changeTimeTo,
  opportunityType,
  title,
  type,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(true)

  const actions = crmTradeActionsMap.get(opportunityType)
  const isPurchase = opportunityType === TradeOpportunityType.Purchase

  const getTimeFromSelector = (listType: OpportunityListType) =>
    isPurchase ? selectOpportunityPurchaseListTimeFrom(listType) : selectOpportunitySaleListTimeFrom(listType)

  const getTimeToSelector = (listType: OpportunityListType) =>
    isPurchase ? selectOpportunityPurchaseListTimeTo(listType) : selectOpportunitySaleListTimeTo(listType)

  const getOpportunities = useMemo(() => () => {
    dispatch(actions.getOpportunities(type))
  }, [actions, dispatch, type])

  const onTimeFromChange = useMemo(() => (date: Date) => {
    dispatch(actions.changeOpportunityListTimeFrom(date, type))
  }, [actions, dispatch, type])

  const onTimeToChange = useMemo(() => (date: Date) => {
    dispatch(actions.changeOpportunityListTimeTo(date, type))
  }, [actions, dispatch, type])

  useEffect(() => { getOpportunities() }, [getOpportunities])

  const timeFrom = useSelector(getTimeFromSelector(type))
  const timeTo = useSelector(getTimeToSelector(type))

  const toggleExpanded = () => { setExpanded(!expanded) }
  const handleTimeFromChange = (dateOffset: number) => { onTimeFromChange(subDays(endOfToday(), dateOffset)) }
  const handleTimeToChange = (dateOffset: number) => { onTimeToChange(addDays(endOfToday(), dateOffset)) }
  const showTimeFromOffset = () => changeTimeFrom && <OpportunityListDayOffset currentDate={timeFrom} onSubmit={handleTimeFromChange} />

  const showTimeToOffset = () => changeTimeTo && (
    <>
      <OpportunityListDayOffset currentDate={timeTo} onSubmit={handleTimeToChange} />
      {t('caption.days')}
    </>
  )

  return (
    <>
      <OpportunityListHeaderContainer>
        <OpportunityListHeader>
          {showTimeFromOffset()}
          {t(title)}
          {showTimeToOffset()}
        </OpportunityListHeader>
        <OpportunityListExpandCollapseButton>
          <ExpandCollapseButton expanded={expanded} onClick={toggleExpanded} />
        </OpportunityListExpandCollapseButton>
      </OpportunityListHeaderContainer>
      <Container expanded={expanded}>
        {isPurchase ? (
          <PurchaseOpportunityTable type={type} />
        ) : (
          <SaleOpportunityTable type={type} />
        )}
      </Container>
    </>
  )
}
