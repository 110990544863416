import { adminRootReducer } from 'actff-bo-lib/admin/reducer'
import { approvalsContentReducer } from 'actff-bo-lib/approvals-content'
import { AuthActionType, authReducer } from 'actff-bo-lib/auth'
import { campaignReducer } from 'actff-bo-lib/campaign'
import { carRootReducer } from 'actff-bo-lib/car'
import { chatReducer } from 'actff-bo-lib/chat'
import { clientReducer } from 'actff-bo-lib/client'
import { crmInsuranceReducer } from 'actff-bo-lib/crm/insurance/reducer'
import { crmServiceReducer } from 'actff-bo-lib/crm/service/reducer'
import { crmTradeReducer } from 'actff-bo-lib/crm/trade'
import { dashboardReducer } from 'actff-bo-lib/dashboard'
import { dealershipSlice } from 'actff-bo-lib/dealership'
import { debugToolsReducer } from 'actff-bo-lib/debug-tools'
import { dictionaryReducer } from 'actff-bo-lib/dictionary'
import { attachmentReducer } from 'actff-bo-lib/global/attachment'
import { i18nReducer } from 'actff-bo-lib/i18n'
import { menuReducer } from 'actff-bo-lib/menu/reducer'
import { ndcCarReducer } from 'actff-bo-lib/ndc-car'
import { notificationReducer } from 'actff-bo-lib/notification'
import { offerRequestReducer } from 'actff-bo-lib/offer-request'
import { serviceReducer } from 'actff-bo-lib/service'
import { serviceRequestReducer } from 'actff-bo-lib/service-request'
import { telephoneRequestReducer } from 'actff-bo-lib/telephone-request'
import { testDriveRequestReducer } from 'actff-bo-lib/test-drive-request'
import { toastReducer } from 'actff-bo-lib/toast'
import { uiSlice } from 'actff-bo-lib/ui'
import { userReducer } from 'actff-bo-lib/user'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { AnyAction, combineReducers } from 'redux'
import { State } from './state'

const appReducer = (history: History) => combineReducers<State>({
  admin: adminRootReducer,
  approvalsContent: approvalsContentReducer,
  attachment: attachmentReducer,
  auth: authReducer,
  campaign: campaignReducer,
  car: carRootReducer,
  chat: chatReducer,
  client: clientReducer,
  crmInsurance: crmInsuranceReducer,
  crmService: crmServiceReducer,
  crmTrade: crmTradeReducer,
  dashboard: dashboardReducer,
  dealership: dealershipSlice.reducer,
  debugTools: debugToolsReducer,
  dictionary: dictionaryReducer,
  i18n: i18nReducer,
  menu: menuReducer,
  ndcCar: ndcCarReducer,
  notification: notificationReducer,
  offerRequest: offerRequestReducer,
  router: connectRouter(history),
  service: serviceReducer,
  serviceRequest: serviceRequestReducer,
  telephoneRequest: telephoneRequestReducer,
  testDriveRequest: testDriveRequestReducer,
  toast: toastReducer,
  ui: uiSlice.reducer,
  user: userReducer,
})

export const rootReducer = (history: History) => (state: State, action: AnyAction) =>
  appReducer(history)(action.type === AuthActionType.Logout ? undefined : state, action)
