import styled from 'styled-components'
import { colors } from 'styles'

export const Switch = styled.input`
  &[type="checkbox"]:checked,
  &[type="checkbox"]:not(:checked) {
    position: relative;
    cursor: pointer;
    appearance: none;
    width: 40px;
    height: 21px;
    border-radius: 20px;
    border: 2px solid ${colors.midnightBlue};
    margin: 0;
    outline: none;
    transition: 0.3s;
    visibility: visible;
  }

  &[type="checkbox"]:checked {
    border-color: ${colors.midnightBlue};
    background-color: ${colors.midnightBlue};
  }

  &[type="checkbox"]::before {
    content: '';
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background: ${colors.midnightBlue};
    top: 2px;
    left: 3px;
    transition: 0.3s ease-in-out;
  }

  &[type="checkbox"]:checked::before {
    transform: translateX(17px);
    background: ${colors.white};
  }

  &[type="checkbox"]:checked + label,
  &[type="checkbox"]:not(:checked) + label {
    padding-left: 5px;
    font-weight: bold;
  }

  &[type="checkbox"]:checked + label:before,
  &[type="checkbox"]:not(:checked) + label:before,
  &[type="checkbox"]:checked + label:after,
  &[type="checkbox"]:not(:checked) + label:after {
    content: none;
  }
`
