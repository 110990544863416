import { SpanStyled } from 'actff-bo-app/Cars/CarView/Styled'
import { Loader } from 'actff-bo-app/components/Loader'
import {
  Car,
  CarFinance,
  CarFinanceAction,
  CarFinanceWithCarUuid,
  CarId,
  selectCurrentCarFinance,
  selectIsLoadingCarFinance,
} from 'actff-bo-lib/car'
import { Language } from 'actff-bo-lib/i18n'
import { State } from 'actff-bo-lib/store'
import { selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import { hasPermission } from 'actff-bo-lib/user/has-permission'
import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'
import { withCarViewHeader } from '../withCarViewHeader'
import { CarViewFinanceForm } from './CarViewFinanceForm'
import { CarViewFinanceReadonly } from './CarViewFinanceReadonly'

type DispatchToProps = {
  readonly getCarFinance: (carId: CarId) => void,
  readonly updateCarFinance: (carFinance: CarFinanceWithCarUuid) => void,
}

type StateToProps = {
  readonly carFinance: CarFinance | null,
  readonly isLoading: boolean,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

type CarViewFinanceComponentProps = {
  readonly car: Car,
  readonly currentLanguage: Language,
  readonly rejectCar: (carId: CarId) => void,
  readonly deleteCar: (carId: CarId) => void,
  readonly revertCar: (carId: CarId) => void,
}

export type CarViewFinanceProps = StateToProps & DispatchToProps & WithTranslation & CarViewFinanceComponentProps

class CarViewFinanceComponent extends React.Component<CarViewFinanceProps> {
  public componentDidMount(): void {
    this.getCarFinance()
  }

  public render(): React.ReactNode {
    const {
      car,
      carFinance,
      deleteCar,
      isLoading,
      rejectCar,
      revertCar,
      updateCarFinance,
      userPermissions,
      t,
    } = this.props

    if (isLoading) {
      return <Loader />
    }

    if (hasPermission([
      UserPermissions.ServiceEditFinance,
      UserPermissions.FinanceEditFinance,
      UserPermissions.InsuranceEditFinance,
    ])(userPermissions)) {
      return (
        <CarViewFinanceForm
          car={car}
          carFinance={carFinance}
          deleteCar={deleteCar}
          rejectCar={rejectCar}
          revertCar={revertCar}
          updateCarFinance={updateCarFinance}
        />
      )
    }

    if (!carFinance) {
      return <SpanStyled>{t('carView.form.finance.noData')}</SpanStyled>
    }

    return (
      <CarViewFinanceReadonly carFinance={carFinance} />
    )
  }

  private readonly getCarFinance = () => { this.props.getCarFinance(this.props.car.uuid) }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getCarFinance: (carId: CarId) => { dispatch(CarFinanceAction.getCarFinance(carId)) },
  updateCarFinance: (carFinance: CarFinanceWithCarUuid) => { dispatch(CarFinanceAction.updateCarFinance(carFinance)) },
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  carFinance: selectCurrentCarFinance(state),
  isLoading: selectIsLoadingCarFinance(state),
  userPermissions: selectUserPermissions(state),
})

export const CarViewFinance = compose(
  withCarViewHeader,
  connect(mapStateToProps, mapDispatchToProps),
)(CarViewFinanceComponent)
