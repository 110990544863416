import { UserPermissions } from 'actff-bo-lib/user/dto/permissions'

import { ComponentType, MenuItem, Path } from '../../dto'
import { Paths } from '../../initial-menu/paths'

export const exampleMenuItem: MenuItem = {
  access: [UserPermissions.ServiceViewCars, UserPermissions.ServiceViewCar],
  children: [],
  component: '' as ComponentType,
  expanded: false,
  name: 'Example item',
  path: Paths.Dashboard as Path,
  wrappers: [],
}

export const exampleUserPermissions: ReadonlyArray<UserPermissions> = [UserPermissions.ServiceViewCars]

export const examplePermissionWithUserAccess = exampleUserPermissions
export const examplePermissionWithoutUserAccess: ReadonlyArray<UserPermissions> = [UserPermissions.ServiceViewService]
