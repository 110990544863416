import { api, PagedResult } from 'actff-bo-lib/api'
import { ClientPreferences } from 'actff-bo-lib/client'
import { OpportunityPurchase, OpportunityPurchaseId, OpportunitySaleId } from 'actff-bo-lib/crm/trade/dto'
import { normalizePreferencesAsDetailedFilters } from 'actff-bo-lib/crm/trade/purchase/filters'
import { buildUrl } from 'actff-bo-lib/global'
import * as queryString from 'querystring'
import { Observable } from 'rxjs'

export const updateOpportunityPurchase = (data: Partial<OpportunityPurchase>) => {
  const { uuid, ...dataToPatch } = data

  return api.patch(`/trade/purchase/${uuid}`, dataToPatch)
}

export const matchSaleOpportunities = (uuid: OpportunityPurchaseId, opportunities: ReadonlyArray<OpportunitySaleId>) =>
  api.post<ReadonlyArray<OpportunitySaleId>, ReadonlyArray<OpportunitySaleId>>(`/trade/matched/purchase/${uuid}`, opportunities)

export const getMatchingPurchaseOpportunities = (
  page: number,
  preferences: ClientPreferences | null,
  search?: string,
): Observable<PagedResult<OpportunityPurchase>> => {
  const normalizedPreferencesAsDetailedFilters = normalizePreferencesAsDetailedFilters(preferences)
  const filtersByPreferences = queryString.stringify(normalizedPreferencesAsDetailedFilters || { arrayFormat: 'comma' })

  return api.get(
    `${buildUrl('/trade/purchase', { page, search })}&${filtersByPreferences}`,
  )
}
