import { filtersInitialState } from 'actff-bo-lib/client/filters-initial-state'
import { updateStateFieldIfExists } from 'actff-bo-lib/store/helpers'

import { ClientAction, ClientActionType, ContactApprovalsAction, ContactApprovalsActionType } from './actions'
import { ClientStatus } from './dto'
import { ClientState, initialState } from './state'

// tslint:disable-next-line:cyclomatic-complexity
export const clientReducer = (
  state: ClientState = initialState,
  action: ContactApprovalsAction | ClientAction,
): ClientState => {
  switch (action.type) {
    case ClientActionType.ArchiveClientSuccess:
      return {
        ...state,
        currentClient: {
          ...updateStateFieldIfExists(state.currentClient, 'data', { status: ClientStatus.Archived }),
          loading: false,
        },
      }

    case ClientActionType.ChangeFilters:
      return {
        ...state,
        currentPage: 1,
        filters: {
          ...action.payload,
        },
      }

    case ClientActionType.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case ClientActionType.ChangeSearchPhrase:
      return {
        ...state,
        list: {
          ...state.list,
          searchPhrase: action.payload,
        },
      }

    case ClientActionType.ClearFilters:
      return {
        ...state,
        filters: filtersInitialState,
      }

    case ClientActionType.ClearList:
      return {
        ...state,
        list: initialState.list,
      }

    case ClientActionType.ClearClientTradeData:
      return {
        ...state,
        currentClientMatchedPurchaseOpportunities: {
          data: null,
          loading: false,
        },
        currentClientMatchedSaleOpportunity: {
          data: null,
          loading: false,
        },
        currentClientPreferences: {
          data: null,
          loading: false,
        },
        currentPurchaseOpportunitiesSearch: {
          data: null,
          loading: false,
        },
      }

    case ClientActionType.GetClientMatchedPurchaseOpportunitiesSuccess:
      return {
        ...state,
        currentClientMatchedPurchaseOpportunities: {
          data: action.payload,
          loading: false,
        },
      }

    case ClientActionType.GetClientMatchedSaleOpportunitySuccess:
      return {
        ...state,
        currentClientMatchedSaleOpportunity: {
          data: action.payload,
          loading: false,
        },
      }

    case ClientActionType.GetClientPersonalDataHistory:
      return {
        ...state,
        currentClientPersonalDataHistory: {
          data: null,
          loading: true,
        },
      }

    case ClientActionType.GetClientPersonalDataHistorySuccess:
      return {
        ...state,
        currentClientPersonalDataHistory: {
          data: action.payload,
          loading: false,
        },
      }

    case ClientActionType.GetClientPreferences:
      return {
        ...state,
        currentClientPreferences: {
          data: null,
          loading: true,
        },
      }

    case ClientActionType.GetClientPreferencesFailure:
      return {
        ...state,
        currentClientPreferences: {
          data: null,
          loading: false,
        },
      }

    case ClientActionType.GetClientPreferencesSuccess:
      const { clientUuid, ...preferences } = action.payload

      return {
        ...state,
        currentClientPreferences: {
          data: preferences,
          loading: false,
        },
      }

    case ClientActionType.SaveClientPreferencesSuccess:
      const { clientUuid: _clientId, ...restPreferences } = action.payload

      return {
        ...state,
        currentClientPreferences: {
          data: restPreferences,
          loading: false,
        },
      }

    case ContactApprovalsActionType.UpdateContactApprovals:
    case ContactApprovalsActionType.GetContactApprovals:
      return {
        ...state,
        currentContactApprovals: {
          data: null,
          loading: true,
        },
      }

    case ContactApprovalsActionType.UpdateContactApprovalsFailure:
    case ContactApprovalsActionType.GetContactApprovalsFailure:
        return {
          ...state,
          currentContactApprovals: {
            data: null,
            loading: false,
          },
        }

      case ContactApprovalsActionType.GetContactApprovalsSuccess:
        return {
          ...state,
          currentContactApprovals: {
            data: action.payload.contactApprovals,
            loading: false,
          },
        }

      case ContactApprovalsActionType.UpdateContactApprovalsSuccess:
        return {
          ...state,
          currentContactApprovals: {
            data: action.payload.contactApprovals,
            loading: false,
          },
        }

      case ClientActionType.GetClients:
        return {
          ...state,
          list: {
            ...state.list,
            loading: true,
          },
        }

      case ClientActionType.GetClientsFailure:
        return {
          ...state,
          list: {
            ...state.list,
            loading: false,
          },
        }

      case ClientActionType.GetClientsSuccess:
        return {
          ...state,
          list: {
            ...state.list,
            data: action.payload,
            loading: false,
          },
        }

      case ClientActionType.GetClient:
        return {
          ...state,
          currentClient: {
            ...state.currentClient,
            data: null,
            loading: true,
          },
        }

      case ClientActionType.GetClientFailure:
        return {
          ...state,
          currentClient: {
            ...state.currentClient,
            loading: false,
          },
        }

      case ClientActionType.GetClientSuccess:
        return {
          ...state,
          currentClient: {
            data: action.payload,
            loading: false,
          },
        }

      case ClientActionType.GetClientCars:
        return {
          ...state,
          currentClientCars: {
            data: null,
            loading: true,
          },
        }

      case ClientActionType.GetClientCarsFailure:
        return {
          ...state,
          currentClientCars: {
            data: null,
            loading: false,
          },
        }

      case ClientActionType.GetClientCarsSuccess:
        return {
          ...state,
          currentClientCars: {
            data: action.payload,
            loading: false,
          },
        }

      case ClientActionType.ChangeClientCarsPage:
        return {
          ...state,
          currentClientCarsPage: action.payload,
        }

      case ClientActionType.GetClientCountSuccess:
        return {
          ...state,
          clientCount: action.payload.clientCount,
        }

      case ClientActionType.RestoreClientSuccess:
        return {
          ...state,
          currentClient: {
            ...updateStateFieldIfExists(state.currentClient, 'data', { status: ClientStatus.Unverified }),
            loading: false,
          },
        }

      case ClientActionType.RestoreClientFailure:
        return {
          ...state,
          currentClient: {
            ...state.currentClient,
            loading: false,
          },
        }

      case ClientActionType.ArchiveClient:
      case ClientActionType.RestoreClient:
      case ClientActionType.UpdateClient:
        return {
          ...state,
          currentClient: {
            ...state.currentClient,
            loading: true,
          },
        }

      case ClientActionType.UpdateClientSuccess:
        return {
          ...state,
          currentClient: {
            data: action.payload,
            loading: false,
          },
        }

      case ClientActionType.UpdateClientFailure:
        return {
          ...state,
          currentClient: {
            data: null,
            loading: false,
          },
        }

      default:
        return state
    }
// tslint:disable-next-line:max-file-line-count
}
