import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'
import { NotificationPayload, NotificationToken } from './dto'

export enum NotificationActionType {
  Init = '[NOTIFICATION] INIT FCM',

  ReceivedNewMessage = '[NOTIFICATION] RECEIVED NEW MESSAGE',

  RefreshToken = '[NOTIFICATION] REFRESH TOKEN',

  RegisterToken = '[NOTIFICATION] REGISTER TOKEN',
  RegisterTokenFailure = '[NOTIFICATION] REGISTER TOKEN FAILURE',
  RegisterTokenSuccess = '[NOTIFICATION] REGISTER TOKEN SUCCESS',

  RequestPermission = '[NOTIFICATION] REQUEST PERMISSION',
  RequestPermissionFailure = '[NOTIFICATION] REQUEST PERMISSION FAILURE',

  UnregisterToken = '[NOTIFICATION] UNREGISTER TOKEN',
  UnregisterTokenFailure = '[NOTIFICATION] UNREGISTER TOKEN FAILURE',
  UnregisterTokenSuccess = '[NOTIFICATION] UNREGISTER TOKEN SUCCESS',
}

export const NotificationAction = {
  init: () => createAction(NotificationActionType.Init),

  receivedNewMessage: (payload: NotificationPayload) => createAction(NotificationActionType.ReceivedNewMessage, payload),

  refreshToken: () => createAction(NotificationActionType.RefreshToken),

  registerToken: (token: NotificationToken) => createAction(NotificationActionType.RegisterToken, token),
  registerTokenFailure: (error: AjaxError) => createAction(NotificationActionType.RegisterTokenFailure, error),
  registerTokenSuccess: () => createAction(NotificationActionType.RegisterTokenSuccess),

  requestPermission: (permission: NotificationPermission) => createAction(NotificationActionType.RequestPermission, permission),
  requestPermissionFailure: () => createAction(NotificationActionType.RequestPermissionFailure),

  unregisterToken: () => createAction(NotificationActionType.UnregisterToken),
  unregisterTokenFailure: (error: AjaxError) => createAction(NotificationActionType.UnregisterTokenFailure, error),
  unregisterTokenSuccess: () => createAction(NotificationActionType.UnregisterTokenSuccess),
}

export type NotificationAction = ActionsUnion<typeof NotificationAction>
