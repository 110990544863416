import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectCarFeature = (state: State) => state.car.currentCarEquipment

export const selectCurrentCarEquipmentData =
  createSelector(selectCarFeature, carEquipment => carEquipment.data)

export const selectCurrentCarEquipmentItems =
  createSelector(selectCarFeature, carEquipment => carEquipment.items)

export const selectCurrentCarEquipmentItemsEdit =
  createSelector(selectCarFeature, carEquipment => carEquipment.itemsEdit)
