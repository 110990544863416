import { Field, FormCell, InputField } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'

import { LabelStyled } from '../Styled'

type Props = Testable & {
  readonly t: i18next.TFunction,
}

const Container = styled.div`
  display: grid;
  grid-area: cars-count;
`

const Fields = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
`

const FormCellStyled = styled(FormCell)`
  margin-bottom: 10px;
  padding: 0;
  padding-right: 10px;

  input {
    max-width: 100px;
  }
`

export const CarsCountRange: React.FC<Props> = ({ testId, t }) => (
    <Container>
      <LabelStyled dark={true} uppercase={false}>{t('car.carsCount')}</LabelStyled>
      <Fields>
        <FormCellStyled slim={true}>
          <label htmlFor='carsCountFrom'>{t('label.from')}:</label>
          <Field
            component={InputField}
            id='carsCountFrom'
            minNumberValue={0}
            name='carsCountFrom'
            testId={`${testId}cars-count--from`}
            type='number'
          />
        </FormCellStyled>
        <FormCellStyled slim={true}>
          <label htmlFor='carsCountTo'>{t('label.to')}:</label>
          <Field
            component={InputField}
            id='carsCountTo'
            minNumberValue={0}
            name='carsCountTo'
            testId={`${testId}cars-count--to`}
            type='number'
          />
        </FormCellStyled>
      </Fields>
    </Container>
  )
