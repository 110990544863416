import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { FormCell } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'

type Props = Testable
  & SectionFormType
  & {
    readonly readOnly?: boolean,
  }

export const SectionOwner: React.FC<Props> = ({ errors, expanded, register, testId, readOnly }) => {
  const { t } = useTranslation()

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.owner'), !!errors.owner)}>
      <SectionForm>
        <FormCell>
          <label>{t('crmInsurance.owner.name')}:</label>
          <input type='text' name='owner.name' ref={register} data-testid={`${testId}owner-name`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.owner.firstName')}:</label>
          <input type='text' name='owner.firstName' ref={register} data-testid={`${testId}owner-firstName`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.owner.lastName')}:</label>
          <input type='text' name='owner.lastName' ref={register} data-testid={`${testId}owner-lastName`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.owner.nip')}:</label>
          <input type='text' name='owner.nip' ref={register} data-testid={`${testId}owner-nip`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.owner.postCode')}:</label>
          <input type='text' name='owner.postCode' ref={register} data-testid={`${testId}owner-postCode`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.owner.city')}:</label>
          <input type='text' name='owner.city' ref={register} data-testid={`${testId}owner-city`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.owner.street')}:</label>
          <input type='text' name='owner.street' ref={register} data-testid={`${testId}owner-street`} disabled={readOnly} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.owner.streetNo')}:</label>
          <input type='text' name='owner.streetNo' ref={register} data-testid={`${testId}owner-streetNo`} disabled={readOnly} />
        </FormCell>
      </SectionForm>
    </CollapsableSection>
  )
}
