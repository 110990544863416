import { Testable } from 'actff-bo-lib/global/testable'
import { Field, Form, FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'

type Props = Testable & {
  readonly onSearch: (searchPhrase?: string) => void,
  readonly initialValues: Values,
  readonly placeholder?: string,
}

type Values = {
  readonly searchPhrase?: string,
}

type SearchComponentProps = Props & FormikProps<Values>

const FormStyled = styled(Form)`
  align-self: baseline;
  justify-self: right;
  width: 260px;
`

const FieldStyled = styled(Field)`
  letter-spacing: -0.5px;
  width: 100%;
`

const SearchComponent: React.FC<SearchComponentProps> = ({ errors, placeholder, touched, testId }) => {
  const { t } = useTranslation()

  return (
    <FormStyled>
      <FieldStyled
        error={errors.searchPhrase}
        data-testid={`${testId}search`}
        touched={(!!touched.searchPhrase).toString()}
        name='searchPhrase'
        placeholder={placeholder || t('serviceRequestList.search.placeholder')}
      />
    </FormStyled>
  )
}

const formik = withFormik<Props, Values>({
  handleSubmit: (values: Values, { props }) => {
    props.onSearch(values.searchPhrase)
  },
  mapPropsToValues: ({ initialValues }) => ({
    searchPhrase: initialValues.searchPhrase,
  }),
})

export const Search = compose(formik)(SearchComponent)
