import { NominalString } from 'actff-bo-lib/global'
import i18next from 'i18next'
import { getServiceName } from './helpers'

export type ServiceID = NominalString<Service>
export type ServiceKey = NominalString<Service>

export type Service = {
  readonly uuid: ServiceID,
  readonly key: ServiceKey,
  readonly name: string,
  readonly description: string,
  readonly selectable: boolean,
  readonly parent?: ServiceKey,
}

export const NotDisplayedParentLabels: ReadonlyArray<string> = [
  'Review',
  'WheelAlignment',
  'Failure',
  'Brakes',
]

export const placeholderService: Service = {
  description: '',
  key: 'placeholder' as ServiceKey,
  name: 'serviceRequest.services.default',
  selectable: true,
  uuid: 'defaultServiceRequest' as ServiceID,
}

export const getServicesSelectElements = (availableServices: ReadonlyArray<Service>, t: i18next.TFunction, byKey = false) =>
  availableServices.map(service => ({
    label: getServiceName(service, t),
    value: byKey ? service.key : service.uuid,
  }))
