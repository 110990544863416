import { ErrorMessage } from '@hookform/error-message'
import { ValueForInsuranceCalculation } from 'actff-bo-lib/car'
import { OpportunityInsuranceType } from 'actff-bo-lib/crm/insurance'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormCell, RadioBoxes } from '../FormStyled'
import { MoneyInput } from '../RHFControllers'
import { TranslatedErrorMessage } from '../TranslatedErrorMessage'
import { insuranceValueCalculator } from './value-calculator'

type Props = Pick<UseFormMethods, 'register' | 'control' | 'errors' | 'setValue' | 'watch'>
  & Testable
  & {
    readonly name: string,
    readonly withLabelPrefix?: boolean,
    readonly insuranceType?: OpportunityInsuranceType,
    readonly isIssuePolicySelected?: boolean,
    readonly readOnly?: boolean,
  }

export const MarketValue: React.FC<Props> = ({
  name,
  withLabelPrefix = false,
  insuranceType,
  isIssuePolicySelected,
  control,
  errors,
  register,
  setValue,
  testId,
  watch,
  readOnly,
}) => {
  const { t } = useTranslation()

  const getFieldLabel = (label: string) => withLabelPrefix ? `${t('marketValueComponent.labelPrefix')} ${label}` : label
  const isMarketValueRequired = insuranceType !== OpportunityInsuranceType.OC
    && insuranceType !== OpportunityInsuranceType.OC_SHORT_TERM
    && isIssuePolicySelected

  React.useEffect(() => {
    const insuranceValue = insuranceValueCalculator(
      watch(`${name}.marketValue`),
      watch(`${name}.calculationBase`) as ValueForInsuranceCalculation,
    )

    setValue(`${name}.insuranceValue`, insuranceValue)
  }, [watch(`${name}.marketValue`), watch(`${name}.calculationBase`)])

  return (
    <>
      <FormCell>
        <label>{getFieldLabel(t('marketValueComponent.marketValue'))}:</label>
        <MoneyInput
          control={control}
          rules={{ required: isMarketValueRequired && 'caption.error.required' }}
          name={`${name}.marketValue`}
          disabled={readOnly}
        />
        <ErrorMessage errors={errors} name={`${name}.marketValue`} as={TranslatedErrorMessage} />
      </FormCell>
      <FormCell>
        <label>{getFieldLabel(t('marketValueComponent.valueForCalculation'))}:</label>
        <RadioBoxes>
          <div>
            <input
              defaultChecked={true}
              data-testid={`${testId}-net`}
              id={`${name}.marketValue-net`}
              name={`${name}.calculationBase`}
              ref={register}
              type='radio'
              onChange={Number}
              value={ValueForInsuranceCalculation.NET}
              disabled={readOnly}
            />
            <label htmlFor={`${name}.marketValue-net`}>{t('caption.net')}</label>
          </div>
          <div>
            <input
              data-testid={`${testId}-gross`}
              id={`${name}.marketValue-gross`}
              name={`${name}.calculationBase`}
              ref={register}
              type='radio'
              value={ValueForInsuranceCalculation.GROSS}
              disabled={readOnly}
            />
            <label htmlFor={`${name}.marketValue-gross`}>{t('caption.gross')}</label>
          </div>
          <div>
            <input
              data-testid={`${testId}-custom`}
              id={`${name}.marketValue-custom`}
              name={`${name}.calculationBase`}
              ref={register}
              type='radio'
              value={ValueForInsuranceCalculation.CUSTOM}
              disabled={readOnly}
            />
            <label htmlFor={`${name}.marketValue-custom`}>{t('caption.net50')}</label>
          </div>
        </RadioBoxes>
      </FormCell>
      <FormCell>
        <label>{getFieldLabel(t('marketValueComponent.insuranceValue'))}:</label>
        <MoneyInput
          control={control}
          disabled={true}
          name={`${name}.insuranceValue`}
        />
      </FormCell>
    </>
  )

}
