import { IconType, Path } from 'actff-bo-lib/menu/dto'
import { LoggedInPermissions, UserPermissions } from 'actff-bo-lib/user'

import { ComponentType } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

// tslint:disable object-literal-sort-keys
export const crmServiceMenu = {
  access: LoggedInPermissions,
  component: ComponentType.CrmServiceOpportunityLists,
  expanded: false,
  icon: IconType.CrmService,
  name: 'menu.crm',
  path: Paths.CrmService as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmServiceOpportunityLists,
      expanded: false,
      name: 'menu.crm.chance',
      path: Paths.CrmServiceOpportunityList as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.CrmServiceOpportunityView,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.CrmServiceOpportunity as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: LoggedInPermissions,
          component: ComponentType.CrmServiceOpportunityManual,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.CrmServiceOpportunityManual as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: [UserPermissions.AdminAllService],
      component: ComponentType.QuickCampaign,
      expanded: false,
      name: 'menu.crm.quickNotifications',
      path: Paths.CrmServiceQuickCampaign as Path,
      ...LoggedInWrappers,
      children: [],
      },
    ],
}
