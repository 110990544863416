import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { H2 } from 'styles'

import { Container, Header } from '../../Styled'
import { withAdminViewHeader } from '../../withAdminViewHeader'
import { ViewTabs } from '../ViewTabs'
import { EquipmentList } from './EquipmentList/EquipmentList'

const testId = 'admin-view-d2d-cities__'

const AdminEquipmentComponent: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.technicalCondition.equipment.header')}</H2></Header>
        <EquipmentList />
      </Container>
    </>
  )
}

export const AdminEquipment = withAdminViewHeader(AdminEquipmentComponent)
