import { ValidationError } from 'actff-bo-app/components/ValidationError'
import { ApprovalsGroupType } from 'actff-bo-lib/client/dto'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'

import { LabelStyled } from '../../Styled'
import { ApprovalsGroup } from './ApprovalsGroup'

type Props = Testable & {
  readonly t: i18next.TFunction,
}

const ApprovalsStyled = styled.div`
  display: grid;
  grid-area: approvals;
`

const ApprovalsContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
`

export const Approvals: React.FC<Props> = ({ testId, t }) => (
  <ApprovalsStyled>
    <LabelStyled dark={true} uppercase={false}>{t('clientView.approvals')}</LabelStyled>
    <ApprovalsContainer>
      {Object.values(ApprovalsGroupType).map(type => (
        <ApprovalsGroup type={type} key={type} testId={`${testId}approvals-${type}`} t={t} />
      ))}
    </ApprovalsContainer>
    <ValidationError name='type' />
  </ApprovalsStyled>
)
