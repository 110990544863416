import { AttachmentPreview } from 'actff-bo-app/components/AttachmentPreview'
import { Thumbnail } from 'actff-bo-app/components/Thumbnail'
import { Url } from 'actff-bo-lib/global'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, LinkButton, theme } from 'styles'

type Props = {
  readonly attachments: ReadonlyArray<Url>,
}

const thumbnailSize = 200
const smallThumbnailSize = 60
const gridGap = 10

const visibleThumbnailsCount = 3

export const ImageViewerWrapper = styled.div`
  background: ${theme.imageViewer.background};
  border-radius: 4px;
  margin: 0 auto;
  padding: 0 10px;
  width: 220px;
`

export const ImageViewer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, ${smallThumbnailSize}px);
  grid-template-rows: ${thumbnailSize} ${smallThumbnailSize}px ${smallThumbnailSize}px;
  grid-gap: ${gridGap}px;
  height: 100%;
  justify-content: center;
  max-height: 65%;
  overflow: hidden;
  padding: 12px;
  position: relative;
  touch-action: manipulation;
`

export const MainThumbnail = styled.div`
  grid-column: span 3;
  grid-row: 1 / 2;
`

export const ShowMoreButton = styled.button`
  background-color: ${colors.white};
  border: 2px solid ${colors.straw};
  border-radius: 5px;
  color: ${colors.straw};
  font-size: 16px;
  font-weight: bold;
  height: ${smallThumbnailSize}px;
  width: ${smallThumbnailSize}px;
`

export const AttachmentsViewer: FC<Props> = ({ attachments }) => {
  const { t } = useTranslation()

  const [currentAttachment, setCurrentAttachment] = useState(attachments[0])
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0)
  const [visibleThumbnails, setVisibleThumbnails] = useState(attachments.slice(1, visibleThumbnailsCount))

  const [isLightboxOpen, setLightboxOpen] = useState(false)
  const [isViewMoreVisible, setViewMoreVisible] = useState(true)

  const movePrev = () => {
    if (currentAttachmentIndex > 0) {
      setCurrentAttachmentIndex(currentAttachmentIndex - 1)
      setCurrentAttachment(attachments[currentAttachmentIndex - 1])
    }
  }

  const moveNext = () => {
    if (attachments && currentAttachmentIndex < attachments.length) {
      setCurrentAttachmentIndex(currentAttachmentIndex + 1)
      setCurrentAttachment(attachments[currentAttachmentIndex + 1])
    }
  }

  const setCurrentAttachmentForPreview = (index: number) => {
    setCurrentAttachmentIndex(index)
    setCurrentAttachment(attachments[index])
  }

  const openAttachmentPreview = (attachmentUrl: Url) => {
    const currentIndex = attachments?.findIndex(url => attachmentUrl === url) || 0
    setCurrentAttachmentForPreview(currentIndex)
    setLightboxOpen(true)
  }

  const closeAttachmentPreview = () => {
    setLightboxOpen(false)
  }

  const showMoreThumbnails = () => {
    setVisibleThumbnails(attachments.slice(1, attachments.length))
    setViewMoreVisible(false)
  }

  const openPhotosPreview = () => {
    setCurrentAttachmentForPreview(0)
    setLightboxOpen(true)
  }

  const Preview = () =>
    currentAttachment ? (
      <AttachmentPreview
        url={currentAttachment}
        prevUrl={attachments[currentAttachmentIndex - 1]}
        nextUrl={attachments[currentAttachmentIndex + 1]}
        onMovePrevRequest={movePrev}
        onMoveNextRequest={moveNext}
        isOpen={isLightboxOpen}
        onLightboxClose={closeAttachmentPreview}
      />
    ) : null

  const ShowMore = () =>
    attachments.length > visibleThumbnailsCount ? (
      <ShowMoreButton onClick={showMoreThumbnails}>+{attachments.length - visibleThumbnailsCount}</ShowMoreButton>
    ) : null

  return (
    attachments.length > 0 ? (
      <ImageViewerWrapper>
        <ImageViewer>
          <MainThumbnail>
            <Thumbnail
              url={attachments[0]}
              thumbnailSize={thumbnailSize}
              onLightboxOpen={openAttachmentPreview}
            />
          </MainThumbnail>
          {visibleThumbnails && visibleThumbnails.map(url =>
            <Thumbnail key={url} url={url} thumbnailSize={smallThumbnailSize} onLightboxOpen={openAttachmentPreview}/>)}
          {isViewMoreVisible && <ShowMore />}
          <Preview />
        </ImageViewer>
        <LinkButton noPadding={true} onClick={openPhotosPreview}>{t('caption.photosPreview')}</LinkButton>
      </ImageViewerWrapper>
    ) : null
  )
}
