import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectNdcCarsFeature = (state: State) => state.ndcCar

export const selectNdcCars = createSelector(selectNdcCarsFeature, state => state.list.data)

export const selectIsLoadingNdcCars = createSelector(selectNdcCarsFeature, state => state.list.loading)

export const selectNdcCarsCurrentPage = createSelector(selectNdcCarsFeature, state => state.currentPage)

export const selectNdcCarsNoOfPages = createSelector(selectNdcCarsFeature, state => state.list.data?.noOfPages)

export const selectNdcCarsSearchPhrase = createSelector(selectNdcCarsFeature, state => state.list.searchPhrase)

export const selectNdcCarsDealerLocationFilters = createSelector(selectNdcCarsFeature, state => state.list.locationFilters)
