import { useEffect, useState } from 'react'

type Props<T> = {
  readonly ignoreUpdatesNumber?: number,
  readonly value: T,
  readonly didUpdate: (value: T) => void,
}

export function Observer<T>({ ignoreUpdatesNumber, value, didUpdate }: Props<T>): null {
  const [updatesCounter, setUpdatesCounter] = useState(0)

  useEffect(() => {
    setUpdatesCounter(updatesCounter + 1)
    if (ignoreUpdatesNumber && updatesCounter <= ignoreUpdatesNumber) { return }
    didUpdate(value)
  }, [value])

  return null
}
