import { MultipleSelect } from 'actff-bo-app/components/MultipleSelect'
import { SelectOption } from 'actff-bo-lib/global'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { multiSelectStyles, singleOptionStyles } from './Styled'

type Props = {
  readonly onChange: (value: SelectOption<string>) => void,
  readonly value: SelectOption<string>,
  readonly options: ReadonlyArray<string>,
  readonly transKey?: string,
}

const trimLength = 1

export const ReportMultiSelect: React.FC<Props> = ({ onChange, transKey = '', value, options }) => {
  const { t } = useTranslation()

  return (
    <MultipleSelect
      autoFocus={false}
      autoBlur={true}
      defaultMenuIsOpen={false}
      isClearable={false}
      // @ts-ignore mismatch with react-select typings
      isMulti={true}
      hideSelectedOptions={false}
      trimFilter={true}
      closeMenuOnSelect={false}
      trimLength={trimLength}
      customStyles={multiSelectStyles}
      singleOptionStyles={singleOptionStyles}
      placeholder={t('caption.all')}
      options={options.map(key => ({
        label: t(`${transKey}${key.toLowerCase()}`),
        value: `${transKey}${key.toLowerCase()}`,
      }))}
      shouldTranslate={false}
      value={value}
      onChange={onChange}
    />
  )
}
