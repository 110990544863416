import { PagedResult } from 'actff-bo-lib/api'
import { WithDealershipFilters } from 'actff-bo-lib/dealership'
import { Loadable } from 'actff-bo-lib/global'
import { updateStateFieldIfExists } from 'actff-bo-lib/store/helpers'
import { UserAction, UserActionType } from 'actff-bo-lib/user'

import { ChatAction, ChatActionType } from './actions'
import { Conversation, Thread, ThreadBasicData } from './dto'

export type ChatState = {
  readonly currentConversation: Partial<Conversation> & { readonly isNew?: boolean },
  readonly currentPage: number,
  readonly dealershipFilters: WithDealershipFilters,
  readonly searchThreadsResults: Loadable<ReadonlyArray<ThreadBasicData>>,
  readonly threadList: Loadable<PagedResult<Thread>>,
}

export const initialDealershipFilters = {
  brandFilters: [],
  locationFilters: [],
}

const initialState: ChatState = {
  currentConversation: {},
  currentPage: 1,
  dealershipFilters: initialDealershipFilters,
  searchThreadsResults: {
    data: null,
    loading: false,
  },
  threadList: {
    data: null,
    loading: false,
  },
}

// tslint:disable-next-line cyclomatic-complexity
export const chatReducer = (state: ChatState = initialState, action: ChatAction | UserAction): ChatState => {
  switch (action.type) {
    case ChatActionType.GetThreadsSuccess:
      if (action.payload.pageNo !== 0) {
        const existingThreads = state.threadList.data?.result.filter(thread =>
          action.payload.result.every(incomingThread => incomingThread.threadUuid !== thread.threadUuid),
        )

        return updateStateFieldIfExists(state, 'threadList', {
          data: {
            ...action.payload,
            result: [
              ...existingThreads || [],
              ...action.payload.result,
            ],
          },
        })
      }

      return {
        ...state,
        threadList: {
          data: action.payload,
          loading: false,
        },
      }
    case ChatActionType.GetChatConversationCarSuccess:
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          car: action.payload,
        },
      }
    case ChatActionType.GetChatConversationMessagesSuccess:
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          messages: action.payload,
        },
      }

    case ChatActionType.ChangeDealershipFilters:
      return {
        ...state,
        dealershipFilters: action.payload,
      }

    case ChatActionType.ClearActiveThread:
      return {
        ...state,
        currentConversation: {},
      }

    case ChatActionType.LoadMoreThreads:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      }

    case ChatActionType.LoadMoreMessagesSuccess:
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          messages: [
            ...action.payload,
            ...state.currentConversation.messages ? state.currentConversation.messages : [],
          ],
        },
      }

    case ChatActionType.ReceivedChatConversationMessage:
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          messages: [
            ...state.currentConversation.messages ? state.currentConversation.messages : [],
            action.payload,
          ],
        },
      }

    case ChatActionType.ReceivedChatThreadUpdate:
      const existingThread = state.threadList.data?.result.find(thread => thread.threadUuid === action.payload.threadUuid)
      const newThread: Thread = {
        ...existingThread,
        ...action.payload,
      }

      const result = [
        ...(state.threadList.data?.result.filter(thread => thread.threadUuid !== action.payload.threadUuid) || []),
        newThread,
      ].sort((firstThread, secondThread) => secondThread.lastMessageTime.getTime() - firstThread.lastMessageTime.getTime())

      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          thread: state.currentConversation.thread && state.currentConversation.thread.threadUuid === action.payload.threadUuid
            ? newThread
            : state.currentConversation.thread,
        },
        threadList: {
          ...state.threadList,
          data: {
            noOfPages: 0,
            pageNo: 0,
            ...state.threadList.data,
            result,
          },
        },
      }

    case ChatActionType.SelectActiveThread:
      const threadSelectedFromList = state.threadList.data?.result.filter(thread => thread.threadUuid === action.payload)[0]
      const existing = action.payload
        ? ({
          ...state.currentConversation.thread,
          threadUuid: action.payload,
        }) as Thread // tslint:disable-line: no-object-literal-type-assertion
        : undefined

      return {
        ...state,
        currentConversation: {
          thread: threadSelectedFromList || existing,
        },
      }

    case ChatActionType.UpdateChatThreadSuccess:
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          thread: state.currentConversation.thread && state.currentConversation.thread.threadUuid === action.payload.threadUuid
            ? action.payload
            : state.currentConversation.thread,
        },
      }

    case ChatActionType.ResetSearchThreadResults:
      return {
        ...state,
        searchThreadsResults: {
          data: [],
          loading: false,
        },
      }

    case ChatActionType.SearchThreads:
      return {
        ...state,
        searchThreadsResults: {
          ...state.searchThreadsResults,
          loading: true,
        },
      }
    case ChatActionType.SearchThreadsFailure:
      return {
        ...state,
        searchThreadsResults: {
          data: [],
          loading: false,
        },
      }
    case ChatActionType.SearchThreadsSuccess:
      return {
        ...state,
        searchThreadsResults: {
          data: action.payload,
          loading: false,
        },
      }

    case ChatActionType.StartNewThread:
      return {
        ...state,
        currentConversation: {
          ...initialState.currentConversation,
          isNew: true,
          thread: action.payload,
        },
      }

    case ChatActionType.CreateNewThreadSuccess:
      return {
        ...state,
      }

    case UserActionType.GetMeSuccess:
      return {
        ...state,
        dealershipFilters: {
          brandFilters: action.payload.brands || [],
          locationFilters: action.payload.locations?.map(location => location.key) || [],
        },
      }

    default:
      return state
  }
}
