import * as React from 'react'

export const withoutProps = (omitProps: ReadonlyArray<string>) => (component: React.ComponentType) => {
  const omitSingle = (object: object & { readonly [key: string]: any }, key: string): {} => { // tslint:disable-line no-any
    if (!key || !(key in object)) {
      return object
    }
    const { [key]: deleted, ...otherKeys } = object

    return otherKeys
  }

  const omit = (props = {}, propsToOmit: ReadonlyArray<string>) =>
    propsToOmit
      ? propsToOmit.reduce((prev: {}, key: string) => key in prev ? omitSingle(prev, key) : prev, props)
      : props

  const ComponentWithOmittedProps: React.FC = ({ children, ...props }) =>
    React.createElement(component, omit(props, omitProps), children)

  return ComponentWithOmittedProps
}
