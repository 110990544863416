import { CarId } from 'actff-bo-lib/car'
import { ChatAction, MessagePayload, ThreadBasicData } from 'actff-bo-lib/chat'
import { connector } from 'actff-bo-lib/global'
import * as React from 'react'
import { MapDispatchToProps } from 'react-redux'
import { compose } from 'redux'

import { ChatClientBar } from './ChatClientBar'
import { ChatConversationSendMessage } from './ChatConversationSendMessage'
import { ChatContainer, ChatConversationContainer, ChatSendMessageContainer, TopBar } from './ChatConversationStyled'
import { ChatThreadStatus } from './ChatThreadStatus'

type Props = {
  readonly thread: ThreadBasicData,
}

type DispatchToProps = {
  readonly createNewThread: (carId: CarId, messagePayload: MessagePayload) => void,
}

type ChatNewConversationComponentProps = Props & DispatchToProps

class ChatNewConversationComponent extends React.Component<ChatNewConversationComponentProps> {

  public render(): React.ReactNode {
    const { thread } = this.props
    const { model, registrationNumber } = thread

    return (
      <ChatContainer>
        <TopBar>
          {thread && <ChatClientBar car={{ model, registrationNumber }} client={thread.client} />}
        </TopBar>
        <TopBar statusBar={true}>
          <ChatThreadStatus thread={thread} />
        </TopBar>
        <ChatConversationContainer />
        <ChatSendMessageContainer>
          <ChatConversationSendMessage onMessageSend={this.handleMessageSend} />
        </ChatSendMessageContainer>
      </ChatContainer>
    )
  }

  private readonly handleMessageSend = (messagePayload: MessagePayload) =>
    this.props.createNewThread(this.props.thread.carUuid, messagePayload)
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  createNewThread: (carId: CarId, messagePayload: MessagePayload) => { dispatch(ChatAction.createNewThread(carId, messagePayload)) },
})

export const ChatNewConversation = compose(
  connector(null, mapDispatchToProps),
)(ChatNewConversationComponent)
