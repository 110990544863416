import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { DictionaryType } from 'actff-bo-lib/dictionary/dto'
import { isString } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TdDelete } from './Styled'

type Props = {
  readonly index: number,
  readonly item: DictionaryType<string> | string,
  readonly onDelete: () => void,
}

const Menu = styled.span`
  float: right;
`

export const Item: FC<Props> = ({ index, onDelete, item }) => {
  const { t } = useTranslation()
  const [menuVisible, setMenuVisible] = useState(false)

  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }

  const handleStopEvents = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const getLabel = (_item: DictionaryType<string> | string) => isString(_item) ? _item : _item.label

  return (
    <tr>
      <td onMouseOver={toggleMenu} onMouseLeave={toggleMenu}>{index + 1}</td>
      <TdDelete onMouseOver={toggleMenu} onMouseLeave={toggleMenu}>
        {getLabel(item)}
        {menuVisible && (
          <Menu
            onMouseOver={handleStopEvents}
            onMouseLeave={toggleMenu}
          >
            <ButtonWithIcon
              caption={t('caption.delete')}
              onClick={onDelete}
              iconType={IconType.Trash}
              noPadding={true}
              theme={BtnTheme.Link}
            />
          </Menu>
        )}
      </TdDelete>
    </tr>
  )
}
