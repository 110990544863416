import { getToken } from 'actff-bo-lib/auth/storage'
import { AjaxResponse } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { AppConfig, getConfig } from './env.config'

export type Headers = {
  readonly [key: string]: string,
}

export const defaultAPIRequestDebounceTime = 1000

export const getAuthorizationHeader = () => {

  const token = getToken()

  return token ? `Bearer ${token}` : ''
}

// TODO: Tests for getConfig and prefixedUrl
export const requestUrl = getConfig(AppConfig.APP_API_URL)

export const prefixedUrl = (url: string): string => `${requestUrl}${url}`

export const websocketPrefixedUrl = (path: string) =>
  `${requestUrl.replace(/(http:\/\/|https:\/\/)/g, match => match === 'http://' ? 'ws://' : 'wss://')}${path}`
export const websocketPrefixedUrlWithAuthToken = (path: string) => {
  const token = getToken()

  const url = websocketPrefixedUrl(path)

  return token ? `${url}?token=${token}` : url

}

export const defaultHeaders = {
  Accept: 'application/json',
}

export const headersForRequestWithPayload = {
  'Content-Type': 'application/json;charset=UTF-8',
}

export const mergeToDefaultHeaders = (headers?: Headers): Headers => ({
  Authorization: getAuthorizationHeader(),
  ...defaultHeaders,
  ...headers,
})

export const mergeToHeadersWithPayload = (headers?: Headers): Headers =>
  mergeToDefaultHeaders({
    ...headersForRequestWithPayload,
    ...headers,
  })

export const toResponse = <T>() => map<AjaxResponse, T>(({ response }) => response)

export const toResponseBlob = () => map<AjaxResponse, string>(({ response }) => URL.createObjectURL(response))
