import { TdWithDelete } from 'actff-bo-app/Admin/Styled'
import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { CarLiquids } from 'actff-bo-lib/admin/brands/dto'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly index: number,
  readonly carLiquids: CarLiquids,
  readonly onDelete: (carLiquids: CarLiquids) => void,
}

const Menu = styled.span`
  float: right;
`

export const CarLiquidsRow: FC<Props> = ({ index, onDelete, carLiquids }) => {
  const { t, i18n } = useTranslation()

  const [menuVisible, setMenuVisible] = useState(false)

  const showMenu = () => {
    setMenuVisible(true)
  }

  const hideMenu = () => {
    setMenuVisible(false)
  }

  const handleStopEvents = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDelete = (carLiquidsToDelete: CarLiquids) => () => { onDelete(carLiquidsToDelete) }

  const getFuelTranslation = (fuelType: string) => {
    if (!fuelType) {
      return t('car.fuelType.na')
    }

    if (!i18n.exists(`car.fuelType.${fuelType.toLowerCase()}`)) {
      return fuelType
    }

    return t(`car.fuelType.${fuelType.toLowerCase()}`)
  }

  return (
    <tr>
      <td onMouseOver={showMenu} onMouseLeave={hideMenu}>{index + 1}</td>
      <td onMouseOver={showMenu} onMouseLeave={hideMenu}>{carLiquids.engineCode}</td>
      <td onMouseOver={showMenu} onMouseLeave={hideMenu}>{carLiquids.oilCode}</td>
      <TdWithDelete onMouseOver={showMenu} onMouseLeave={hideMenu}>
        {getFuelTranslation(carLiquids.fuelType)}
        {menuVisible && (
          <Menu
            onMouseOver={handleStopEvents}
            onMouseLeave={hideMenu}
          >
            <ButtonWithIcon
              caption={t('caption.delete')}
              onClick={handleDelete(carLiquids)}
              iconType={IconType.Trash}
              noPadding={true}
              theme={BtnTheme.Link}
            />
          </Menu>
        )}
      </TdWithDelete>
    </tr>
  )
}
