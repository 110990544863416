import { Loader } from 'actff-bo-app/components/Loader'
import { Overlay } from 'actff-bo-app/components/Overlay'
import { ChatAction, ChatViewUrlParams, selectSearchThreadResults, ThreadBasicData, ThreadId } from 'actff-bo-lib/chat'
import { Loadable } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu'
import { State as GlobalState } from 'actff-bo-lib/store'
import { Field, FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'

import { ChatNewThreadButton } from './ChatNewThreadButton'
import { ChatNewThreadResults } from './ChatNewThreadResults'
import {
  ChatNewThreadContainer,
  ChatNewThreadDropdownContainer,
  ChatNewThreadForm,
  ChatNewThreadResultsContainer,
  FieldContainer,
  FieldIcon,
} from './ChatNewThreadStyled'

type State = {
  readonly expanded: boolean,
}

type DispatchToProps = {
  readonly onSearch: (phrase: string) => void,
  readonly resetSearchThreadResults: () => void,
  readonly startNewThread: (thread: ThreadBasicData) => void,
}

type StateToProps = {
  readonly searchThreadResults: Loadable<ReadonlyArray<ThreadBasicData>>,
}

type FormProps = {
  readonly searchPhrase: string,
}

type ChatNewThreadComponentProps =
  RouteComponentProps<ChatViewUrlParams>
    & FormikProps<FormProps>
    & DispatchToProps
    & State
    & StateToProps
    & WithTranslation

class ChatNewThreadComponent extends React.Component<ChatNewThreadComponentProps> {
  public readonly state: State = {
    expanded: false,
  }

  public render(): React.ReactNode {
    const { expanded } = this.state
    const { t, searchThreadResults } = this.props
    const defaultTopOffset = 58

    return (
      <ChatNewThreadContainer>
        {expanded && <Overlay onClick={this.toggleExpanded} />}
        {expanded && <ChatNewThreadDropdownContainer className='thread-results' topOffset={defaultTopOffset}>
          <ChatNewThreadForm>
            <label>{t('chat.clients')}</label>
            <FieldContainer>
              <Field name='searchPhrase' placeholder={t('caption.search')} />
              <FieldIcon type={IconType.Search} />
            </FieldContainer>
            {searchThreadResults.loading && <Loader />}
          </ChatNewThreadForm>
          <ChatNewThreadResultsContainer>
            <ChatNewThreadResults onClick={this.handleThreadResultClick} searchThreadResults={searchThreadResults} />
          </ChatNewThreadResultsContainer>
        </ChatNewThreadDropdownContainer>}
        <ChatNewThreadButton expanded={expanded} onClick={this.toggleExpanded} />
      </ChatNewThreadContainer>
    )
  }

  private readonly toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  private readonly handleThreadResultClick = (result: ThreadBasicData) => () => {
    const { carUuid, client, model, registrationNumber, threadUuid } = result

    this.toggleExpanded()
    this.props.resetForm()
    this.props.resetSearchThreadResults()

    if (this.getThreadIdFromUrl() && this.getThreadIdFromUrl() === threadUuid) {
      return
    }

    this.props.startNewThread({ carUuid, client, model, registrationNumber})
  }

  private readonly getThreadIdFromUrl = () => this.props.match.params.threadId as ThreadId
}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  searchThreadResults: selectSearchThreadResults(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  onSearch: (phrase: string) => { dispatch(ChatAction.searchThreads(phrase)) },
  resetSearchThreadResults: () => { dispatch(ChatAction.resetSearchThreadResults()) },
  startNewThread: (thread: ThreadBasicData) => {
    dispatch(ChatAction.startNewThread(thread))
  },
})

const formik = withFormik<DispatchToProps, FormProps>({
  handleSubmit: (({ searchPhrase }, { props }) => {
    props.onSearch(searchPhrase)
  }),
  mapPropsToValues: () => ({
    searchPhrase: '',
  }),
})

export const ChatNewThread = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(formik(ChatNewThreadComponent))
