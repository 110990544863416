import { CarCard } from 'actff-bo-app/components/CarCard'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FormCell, FormHeader } from 'actff-bo-app/components/Form'
import { CarInfo } from 'actff-bo-lib/car'
import { OpportunityPurchaseCarInsurance, OpportunityPurchaseEquipment, OpportunityPurchaseInspection } from 'actff-bo-lib/crm/trade'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { getTranslation } from 'actff-bo-lib/i18n'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import React from 'react'
import { Control } from 'react-hook-form'
import { Ref } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

import { CarGallery } from '../../Car'
import { Rating } from './InspectionRating'

type Props = Testable & {
  readonly car: CarInfo,
  readonly equipment: OpportunityPurchaseEquipment,
  readonly control: Control,
  readonly inspection: OpportunityPurchaseInspection | null,
  readonly innerValuation: string | null,
  readonly insurance: OpportunityPurchaseCarInsurance,
  readonly register: (ref: Ref | null) => void,
}

const Container = styled.div`
  border-bottom: 1px solid ${colors.mystic};
  border-top: 1px solid ${colors.mystic};
  display: grid;
  grid-auto-flow: row;
  grid-area: car-data;
  padding: 30px 30px 30px;
`

const CarDataHeader = styled(FormHeader)`
  padding: 0 0 24px;
`

const CarDataContainer = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 1fr;
`

const CarValueWrapper = styled(FormCell)`
  border-right: 1px solid ${colors.mystic};
  grid-row-gap: 20px;
  padding: 0 34px;
`

const CarValueCell = styled.div`
  display: grid;
`

export const CarData: React.FC<Props> = ({
  car,
  equipment,
  inspection,
}) => {
  const { t } = useTranslation()
  const { firstRegistrationDate, fuelType } = car

  const handleInspectionRatingClick = () => {
    if (!inspection) { return null }

    return car.wilsonCarUuid ?
      history.push(createRoute(Paths.CarViewInspection, { carId: car.wilsonCarUuid, inspectionId: inspection.uuid }))
      : history.push(createRoute(Paths.NdcCarViewInspection, { vin: car.vin, inspectionId: inspection.uuid }))
  }

  return (
    <>
      <Container>
        <CarDataHeader> {t('crmTrade.opportunityPurchase.header.carTechnicalDetails')}</CarDataHeader>
        <CarDataContainer>
          <div>
            <CarCard active={true} car={car} fullHeight={true} />
          </div>
          <CarValueWrapper>
            {inspection ? (
              <Rating
                onClick={handleInspectionRatingClick}
                inspection={inspection}
              />
            ) : <label>{t('inspection.lackOfInspection')}:</label>}
            <CarValueCell>
              <label>{t('carView.inspection.table.header.inspectionDate')}:</label>
              <FormattedDate date={inspection?.created} format={defaultDateFormat} />
            </CarValueCell>
            <CarValueCell>
              <label>{t('crmTrade.opportunityPurchase.firstRegistrationDate')}:</label>
              <FormattedDate date={firstRegistrationDate} format={defaultDateFormat} />
            </CarValueCell>
            <CarValueCell>
              <label>{t('crmTrade.opportunityPurchase.transmission')}:</label>
              {getTranslation(t, 'car.transmission.', equipment?.transmission)}
            </CarValueCell>
            <CarValueCell>
              <label>{t('crmTrade.opportunityPurchase.carOrigin')}:</label>
              {getTranslation(t, 'car.origin.', inspection?.origin)}
            </CarValueCell>
            <CarValueCell>
              <label>{t('crmTrade.opportunityPurchase.fuelType')}:</label>
              {getTranslation(t, 'car.fuelType.', fuelType)}
            </CarValueCell>
            <CarValueCell>
              <label>{t('crmTrade.opportunityPurchase.enginePower')}:</label>
              {equipment?.enginePower}
            </CarValueCell>
          </CarValueWrapper>
          <CarGallery inspection={inspection} />
        </CarDataContainer>
      </Container>
    </>
  )
}
