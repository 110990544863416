import { Testable } from 'actff-bo-lib/global/testable'
import { ServiceRequestStatus } from 'actff-bo-lib/service-request'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly active: boolean,
  readonly name: string,
  readonly onClick: (type: ServiceRequestStatus) => void,
  readonly type: ServiceRequestStatus,
}

const ServiceRequestStatusItemStyled = styled.li`
  cursor: pointer;
  &:last-child {
    border-top: 1px solid ${colors.mysticGray};
    margin-top: 8px;
    padding: 8px 0;
  }
`

const ServiceRequestStatusItemActiveStyled = styled(ServiceRequestStatusItemStyled)`
  font-weight: 600;
`

export const ServiceRequestStatusItem: React.FC<Props> = ({ active, name, onClick, testId, type }) => {
  const { t } = useTranslation()
  const handleClick = () => { onClick(type) }
  const Container = active ? ServiceRequestStatusItemActiveStyled : ServiceRequestStatusItemStyled

  return (<Container data-testid={`${testId}status--${type}`} onClick={handleClick}>{t(name)}</Container>)
}
