import { ToastAction, ToastType } from 'actff-bo-lib/toast'

export enum ToastActionType {
  GET = 'GET',
  CREATE_OR_UPDATE = 'CREATE_OR_UPDATE',
}

const getSuccessActionTitle = (actionType: ToastActionType) => {
  switch (actionType) {
    case ToastActionType.CREATE_OR_UPDATE:
      return 'toast.updateActionSuccess'
    default:
      return 'toast.actionSuccess'
  }
}

const getFailureActionTitle = (actionType: ToastActionType) => {
  switch (actionType) {
    case ToastActionType.GET:
      return 'toast.getActionFailure'
    case ToastActionType.CREATE_OR_UPDATE:
      return 'toast.updateActionFailure'
    default:
      return 'toast.actionSuccess'
  }
}

export const displaySuccessToast = (id: string, actionType: ToastActionType, body?: string) => (
  ToastAction.displayToast({
    autoClose: 3000,
    body,
    id,
    title: getSuccessActionTitle(actionType),
    type: ToastType.Success,
  })
)

export const displayFailureToast = (id: string, actionType: ToastActionType, body?: string) => (
  ToastAction.displayToast({
    autoClose: 3000,
    body,
    id,
    title: getFailureActionTitle(actionType),
    type: ToastType.Error,
  })
)
