import { RadioValue } from 'actff-bo-app/components/Form'
import { Label } from 'actff-bo-app/components/Label'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = {
  readonly disabled: boolean,
  readonly isHeader?: boolean,
  readonly fieldName?: string,
  readonly headerChange?: (value: RadioValue | null) => void,
  readonly label?: string,
  readonly items?: ReadonlyArray<string | undefined>,
  readonly onChange?: (value: RadioValue | null) => void,
  readonly value?: RadioValue,
}

type ItemContainerProps = {
  readonly isHeader: boolean,
}

type LabelStyledProps = {
  readonly isHeader: boolean,
}

const ItemContainer = styled.div<ItemContainerProps>`
  ${({ isHeader }: ItemContainerProps) => isHeader && `background-color: ${colors.athensGray}`};
  display: grid;
  grid-template-columns: 100px 100px 1fr;
  height: 50px;
  padding: 0;
  width: 100%;
`

const LabelStyled = styled(Label)<LabelStyledProps>`
  bottom: ${({ isHeader = false }: LabelStyledProps) => isHeader ? '0' : '8px'};
  left: 10px;
`

const RadioBox = styled.div`
  border-right: 1px solid ${colors.mysticGray};
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 50px;
  padding-left: 15px;
  place-items: center;
  width: 100px;
`

const TextBox = styled.div`
  align-items: center;
  display: grid;
  padding-left: 20px;
`

export const EquipmentItem: FC<Props> = ({ disabled, headerChange, isHeader = false, items, label, fieldName, onChange, value }) => {
  const { t } = useTranslation()

  const isChecked = (radioValue: RadioValue) => {
    if (items) {
      return Object.entries(items).every(([, elementValue]) => elementValue === radioValue)
    }

    return value === radioValue
  }

  const handleChange = (valueToSet: RadioValue, fn?: (value: RadioValue | null) => void) => {
    if (!fn) {
      return
    }

    if (valueToSet === value) {
      fn(null)

      return
    }

    fn(valueToSet)
  }

  const handleClick = (valueToSet: RadioValue) => () => {
    if (disabled) {
      return
    }

    handleChange(valueToSet, onChange)

    if (headerChange) {
      handleChange(valueToSet, headerChange)
    }
  }

  return (
    <ItemContainer isHeader={isHeader}>
      <RadioBox>
        <input checked={isChecked(RadioValue.Yes)} disabled={disabled} type='radio' value={RadioValue.Yes} name={fieldName} />
        <LabelStyled disabled={disabled} htmlFor={fieldName} onClick={handleClick(RadioValue.Yes)} isHeader={isHeader}>
          {isHeader && <span>{t('caption.yes')}</span>}
        </LabelStyled>
      </RadioBox>
      <RadioBox>
        <input checked={isChecked(RadioValue.No)} disabled={disabled} type='radio' value={RadioValue.No} name={fieldName} />
        <LabelStyled disabled={disabled} htmlFor={fieldName} onClick={handleClick(RadioValue.No)} isHeader={isHeader}>
          {isHeader && <span>{t('caption.no')}</span>}
        </LabelStyled>
      </RadioBox>
      <TextBox>{label}</TextBox>
    </ItemContainer>
  )
}
