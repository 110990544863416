import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { FormFooterRight } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = Testable & {
  readonly onFormOpen: () => void,
  readonly onRemoveEvents: () => void,
}

export const EmployeeScheduleFooter: FC<Props> = ({ onFormOpen, onRemoveEvents, testId }) => {
  const { t } = useTranslation()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const toggleOpenConfirmDialog = () => {
    setConfirmDialogOpen(!confirmDialogOpen)
  }

  return (
    <FixedFooter>
      <FormFooterRight>
        <ButtonWithIcon
          caption={t('caption.delete')}
          iconType={IconType.Trash}
          onClick={toggleOpenConfirmDialog}
          theme={BtnTheme.Link}
          testId={`${testId}remove`}
        />
        <ButtonWithIcon
          caption={t('admin.employeeSchedule.editSelected')}
          iconType={IconType.Edit}
          onClick={onFormOpen}
          testId={`${testId}edit`}
        />
      </FormFooterRight>
      <Dialog
        content='confirmDialog.employeeScheduler.delete.content'
        open={confirmDialogOpen}
        onConfirm={onRemoveEvents}
        onClose={toggleOpenConfirmDialog}
        title='confirmDialog.employeeScheduler.delete.title'
      />
    </FixedFooter>
  )
}
