import { Drawer } from '@material-ui/core'
import { emptyShift } from 'actff-bo-lib/admin'

import styled from 'styled-components'
import { colors } from 'styles'

type ShiftOptionContentProps = {
  readonly colour: string,
}

export const DrawerStyled = styled(Drawer)`
  .MuiDrawer-paper {
    padding: 30px 40px;
    width: 500px;
  }
`

export const DrawerHeader = styled.div`
  border-bottom: 1px solid ${colors.mysticGray};
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 22px;
`

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Footer = styled.div`
  border-top: 1px solid ${colors.mysticGray};
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`

export const DayFormContainer = styled.div`
  display: grid;
  grid-template-areas:
    'number date'
    '. shift'
    '. time';
  grid-template-columns: 20px 1fr;
  grid-row-gap: 10px;
  padding: 20px 10px;
`

export const DayContainerNumber = styled.div`
  display: grid;
  font-weight: 600;
  grid-area: 'number';
  grid-row: 1 / 6;
  width: 10px;
  color: ${colors.dustyGray};
`

export const DateContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-area: 'date';
`

export const ShiftContainer = styled.div`
  grid-area: 'shift';
`

export const TimeContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-area: 'time';
`

export const RowContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;

  .DayPickerInput {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
  }
`

export const ResourceContainer = styled.div`
  border-top: 1px solid ${colors.mysticGray};
  grid-auto-flow: row;
  padding: 20px 0;
`

export const ResourceRow = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: row;
`

export const Separator = styled.span`
  text-align: center;
`

export const AddButtonContainer = styled.span`
  text-align: right;
  margin: 0 -35px 20px 0;
`

export const RepeatCycleContainer = styled.div`
  border-top: 1px solid ${colors.mysticGray};
  display: grid;
  grid-row-gap: 10px;
  grid-auto-flow: row;
  padding: 20px 0;
`

export const RepeatEveryContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 70px 1fr;
  grid-gap: 10px;
  align-items: center;
  font-weight: normal;
`

export const ShiftOptionContent = styled.div`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    height: 15px;
    width: 15px;
    background-color: ${({ colour }: ShiftOptionContentProps) => colour || emptyShift.colour};
    border-radius: 3px;
    margin-right: 10px;
  }
`
