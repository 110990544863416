import { BtnType } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { PrimaryButton } from 'styles'

import { Loader } from '../Loader'

type Props = {
  readonly contentRef: React.MutableRefObject<Element | undefined>,
  readonly debounceTime?: number,
  readonly onPrintEnd: () => void,
  readonly onPrintStart: () => void,
} & Testable

const renderDebounceTime = 2000

export const PrintButton: React.FC<Props> = ({ contentRef, debounceTime = renderDebounceTime, onPrintEnd, onPrintStart, testId }) => {
  const { t } = useTranslation()
  const [isPrinting, setIsPrinting] = useState(false)

  const print = useReactToPrint({
    content: () => contentRef?.current as Element,
    onAfterPrint: () => {
      setIsPrinting(false)
      onPrintEnd()
    },
  })

  const handlePrint = () => {
    setIsPrinting(true)
    onPrintStart()
    setTimeout(() => { print && print() }, debounceTime)
  }

  return (
    <>
      {isPrinting && <Loader opacity='1' />}
      <PrimaryButton data-testid={`${testId}print-btn`} onClick={handlePrint} type={BtnType.Button}>
        {t('caption.print')}
      </PrimaryButton>
    </>
  )
}
