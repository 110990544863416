import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import {
  assignTradeOpportunity,
  getMatchedTradeOpportunities,
  getTradeOpportunities,
  getTradeOpportunity,
  sendTradeOpportunityInternalComment,
  sendTradeOpportunityInternalEvent,
  unassignTradeOpportunity,
} from 'actff-bo-lib/crm/trade/dao'
import {
  OpportunityPurchase,
  OpportunitySale,
  OpportunitySaleId,
  OpportunityTradeInternalEventDto,
  TradeOpportunityType,
} from 'actff-bo-lib/crm/trade/dto'
import { CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleActionType } from 'actff-bo-lib/crm/trade/sale/actions'
import {
  getOpportunitySaleByClient,
  matchPurchaseOpportunities,
  updateOpportunitySale,
} from 'actff-bo-lib/crm/trade/sale/dao'
import {
  selectCurrentOpportunitySale,
  selectMatchedPurchaseOpportunities,
  selectOpportunitySaleListAssignmentFilter,
  selectOpportunitySaleListCurrentPage,
  selectOpportunitySaleListsStatusFilters,
  selectOpportunitySaleListTimeFrom,
  selectOpportunitySaleListTimeTo,
  selectOpportunitySaleSearchPhrase,
} from 'actff-bo-lib/crm/trade/sale/selectors'
import {
  Attachment,
  AttachmentAction,
  AttachmentActionType,
  AttachmentUploadType,
  haveAttachmentsToUpload,
} from 'actff-bo-lib/global'
import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { Paths } from 'actff-bo-lib/menu'
import { redirectToList } from 'actff-bo-lib/pagination'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { hasPermission, selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { iif, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

type OpportunityOrAttachmentAction = CrmTradeOpportunitySaleAction | AttachmentAction

const matchPurchaseOpportunitiesByClientEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesByClient>
    >(CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesByClient),
  switchMap(({ payload }) => getOpportunitySaleByClient(payload.clientId).pipe(
    map((opportunity: OpportunitySale) =>
      CrmTradeOpportunitySaleAction.matchPurchaseOpportunities(opportunity.uuid, payload.opportunities)),
    catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesByClientFailure(error))),
    ),
  ))

const matchPurchaseOpportunitiesEpic: Epic<CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunitySaleAction.matchPurchaseOpportunities>
    >(CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunities),
  switchMap(({ payload }) => matchPurchaseOpportunities(payload.saleId, payload.opportunities).pipe(
    map(opportunities => CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesSuccess(opportunities)),
    catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesFailure(error))),
  ),
))

const unmatchPurchaseOpportunityEpic: Epic<CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleAction, State> =
  (action$, state$) => action$.pipe(
    ofType<
      ReturnType<typeof CrmTradeOpportunitySaleAction.unmatchPurchaseOpportunity>
      >(CrmTradeOpportunitySaleActionType.UnmatchPurchaseOpportunity),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      matchPurchaseOpportunities(
        selectCurrentOpportunitySale(state)?.uuid,
        selectMatchedPurchaseOpportunities(state).filter(opportunity => opportunity.uuid !== payload).map(purchase => purchase.uuid),
      ).pipe(
        map(opportunities => CrmTradeOpportunitySaleAction.unmatchPurchaseOpportunitySuccess(opportunities)),
        catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.unmatchPurchaseOpportunityFailure(error))),
      ),
))

const getOpportunitySaleByClientEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunitySaleAction.getOpportunityByClient>
    >(CrmTradeOpportunitySaleActionType.GetOpportunityByClient),
  switchMap(({ payload }) => getOpportunitySaleByClient(payload.clientId).pipe(
    map(CrmTradeOpportunitySaleAction.getOpportunityByClientSuccess),
    catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.getOpportunityByClientFailure(error))),
    ),
  ))

const assignOpportunityEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.assignOpportunity>>(CrmTradeOpportunitySaleActionType.AssignOpportunity),
  switchMap(({ payload }) => assignTradeOpportunity<OpportunitySale>(payload.uuid, payload.user, TradeOpportunityType.Sale).pipe(
    map(opportunity => CrmTradeOpportunitySaleAction.assignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.assignOpportunityFailure(error))),
    ),
  ))

const changeFilterOrSearchPhraseEpic: Epic<CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleAction, State> =
  (action$, state$) =>
    action$.pipe(
      ofType(
        CrmTradeOpportunitySaleActionType.ChangeListsStatusFilters,
        CrmTradeOpportunitySaleActionType.ChangeSearchPhrase,
        CrmTradeOpportunitySaleActionType.ChangeAssignmentFilter),
      withLatestFrom(state$),
      switchMap(([, store]) => {
        const userPermissions = selectUserPermissions(store)
        const defaultActionsToReturn: ReadonlyArray<CrmTradeOpportunitySaleAction> = [
          CrmTradeOpportunitySaleAction.resetPaginationOnLists(),
          CrmTradeOpportunitySaleAction.getOpportunities(OpportunityListType.future),
          CrmTradeOpportunitySaleAction.getOpportunities(OpportunityListType.overdue),
          CrmTradeOpportunitySaleAction.getOpportunities(OpportunityListType.new),
        ]

        return hasPermission([UserPermissions.AdminAllService])(userPermissions)
          ? ([
            ...defaultActionsToReturn,
            CrmTradeOpportunitySaleAction.getOpportunities(OpportunityListType.all),
          ])
          : defaultActionsToReturn
      }),
    )

const getOpportunitiesEpic: Epic<CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleAction, State> =
  (action$, state$) => action$.pipe(
    ofType<
      ReturnType<typeof CrmTradeOpportunitySaleAction.getOpportunities>
      | ReturnType<typeof CrmTradeOpportunitySaleAction.changeOpportunityListCurrentPage>
      | ReturnType<typeof CrmTradeOpportunitySaleAction.changeOpportunityListTimeFrom>
      | ReturnType<typeof CrmTradeOpportunitySaleAction.changeOpportunityListTimeTo>
      >(
      CrmTradeOpportunitySaleActionType.GetOpportunities,
      CrmTradeOpportunitySaleActionType.ChangeOpportunityListCurrentPage,
      CrmTradeOpportunitySaleActionType.ChangeOpportunityListTimeFrom,
      CrmTradeOpportunitySaleActionType.ChangeOpportunityListTimeTo,
    ),
    withLatestFrom(state$),
    mergeMap(([{ payload: { type } }, store]) => {
      const page = selectOpportunitySaleListCurrentPage(type)(store) - 1
      const assignmentFilter = selectOpportunitySaleListAssignmentFilter(store)
      const statusFilters = selectOpportunitySaleListsStatusFilters(store)
      const fromDate = selectOpportunitySaleListTimeFrom(type)(store)
      const toDate = selectOpportunitySaleListTimeTo(type)(store)
      const search = encodeStringIfExists(selectOpportunitySaleSearchPhrase(store))

      return getTradeOpportunities<OpportunitySale>({
        assignmentFilter,
        fromDate,
        opportunityType: TradeOpportunityType.Sale,
        page,
        search,
        statusFilters,
        toDate,
        type,
      }).pipe(
        map(list => CrmTradeOpportunitySaleAction.getOpportunitiesSuccess(type, list)),
        catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.getOpportunitiesFailure(type, error))),
      )
    }),
  )

const getOpportunityEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.getOpportunity>>(CrmTradeOpportunitySaleActionType.GetOpportunity),
  switchMap(({ payload }) => getTradeOpportunity<OpportunitySale>(payload, TradeOpportunityType.Sale).pipe(
    map(CrmTradeOpportunitySaleAction.getOpportunitySuccess),
    catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.getOpportunityFailure(error))),
  )),
)

const sendOpportunityInternalEventWithAttachmentsEpic: Epic<OpportunityOrAttachmentAction, OpportunityOrAttachmentAction, State> =
  (action$, state$) =>
    action$.pipe(
      ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.sendInternalEventWithAttachments>>(
        CrmTradeOpportunitySaleActionType.SendInternalEventWithAttachments,
      ),
      withLatestFrom(state$),
      mergeMap(([{ payload: { opportunityId, internalEvent, attachments } }]) =>
        iif(
          () => haveAttachmentsToUpload(attachments),
          of(
            AttachmentAction.sendAttachments<OpportunityTradeInternalEventDto | OpportunitySaleId>(
              attachments as ReadonlyArray<Attachment>,
              AttachmentUploadType.OpportunitySaleEvent,
              { internalEvent, opportunityId }),
          ),
          of(CrmTradeOpportunitySaleAction.sendInternalEvent(
            opportunityId,
            internalEvent,
          )),
        ),
      ),
    )

const uploadAttachmentsSuccessEpic: Epic<OpportunityOrAttachmentAction, OpportunityOrAttachmentAction, State> = action$ => action$.pipe(
  ofType<ReturnType<typeof AttachmentAction.sendAttachmentsComplete>>(AttachmentActionType.SendAttachmentsComplete),
  pluck('payload'),
  filter(({ type }) => type === AttachmentUploadType.OpportunitySaleEvent),
  map(({ urls, props: { internalEvent, opportunityId } }) => {
    const eventWithAttachments = {
      ...(internalEvent as OpportunityTradeInternalEventDto),
      attachments: urls,
    }

    return CrmTradeOpportunitySaleAction.sendInternalEvent(
      opportunityId as OpportunitySaleId,
      eventWithAttachments as OpportunityTradeInternalEventDto,
    )
  }),
)

const sendOpportunityInternalEventEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.sendInternalEvent>>(CrmTradeOpportunitySaleActionType.SendInternalEvent),
  switchMap(({ payload }) =>
    sendTradeOpportunityInternalEvent<OpportunitySale>(payload.opportunityId, payload.internalEvent, TradeOpportunityType.Sale).pipe(
      map(CrmTradeOpportunitySaleAction.sendInternalEventSuccess),
      catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.sendInternalEventFailure(error))),
  )),
)

const getMatchedPurchaseOpportunitiesEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.getOpportunitySuccess>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.getOpportunityByClientSuccess>>(
    CrmTradeOpportunitySaleActionType.GetOpportunitySuccess,
    CrmTradeOpportunitySaleActionType.GetOpportunityByClientSuccess,
  ),
  switchMap(({ payload }) =>
    getMatchedTradeOpportunities<OpportunitySaleId, OpportunityPurchase>(payload.uuid, TradeOpportunityType.Sale).pipe(
      map(CrmTradeOpportunitySaleAction.getMatchedPurchaseOpportunitiesSuccess),
      catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.getMatchedPurchaseOpportunitiesFailure(error))),
    ),
))

const sendOpportunityInternalCommentEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunitySaleAction.sendInternalComment>
    >(CrmTradeOpportunitySaleActionType.SendInternalComment),
  switchMap(({ payload }) =>
    sendTradeOpportunityInternalComment(payload.opportunityId, payload.internalCommentDto, TradeOpportunityType.Sale).pipe(
      map(CrmTradeOpportunitySaleAction.sendInternalCommentSuccess),
      catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.sendInternalCommentFailure(error))),
    )),
)

const unassignOpportunityEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.unassignOpportunity>>
  (CrmTradeOpportunitySaleActionType.UnassignOpportunity),
  switchMap(({ payload }) => unassignTradeOpportunity<OpportunitySale>(payload.uuid, TradeOpportunityType.Sale).pipe(
    map(opportunity => CrmTradeOpportunitySaleAction.unassignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.unassignOpportunityFailure(error))),
    ),
  ))

const updateOpportunityEpic: Epic<CrmTradeOpportunitySaleAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.updateOpportunity>>
  (CrmTradeOpportunitySaleActionType.UpdateOpportunity),
  switchMap(({ payload }) => updateOpportunitySale(payload).pipe(
    map((opportunity: OpportunitySale) => CrmTradeOpportunitySaleAction.updateOpportunitySuccess(opportunity)),
    catchError((error: AjaxError) => of(CrmTradeOpportunitySaleAction.updateOpportunityFailure(error))),
    ),
  ))

const redirectToListOnUpdateSuccessEpic: Epic<CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleAction, State> =
  (action$, store$) => action$.pipe(
    ofType<ReturnType<typeof CrmTradeOpportunitySaleAction.updateOpportunitySuccess>>
    (CrmTradeOpportunitySaleActionType.UpdateOpportunitySuccess),
    withLatestFrom(store$),
    tap(() => {
      redirectToList(Paths.CrmTradeSaleOpportunityList, 1)
    }),
    ignoreElements(),
  )

const alertOnFailureActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunitySaleAction.sendInternalCommentFailure>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.sendInternalEventFailure>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.getOpportunityByClientFailure>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesByClientFailure>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.unmatchPurchaseOpportunityFailure>
    >(
    CrmTradeOpportunitySaleActionType.SendInternalCommentFailure,
    CrmTradeOpportunitySaleActionType.SendInternalEventFailure,
    CrmTradeOpportunitySaleActionType.GetOpportunityByClientFailure,
    CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesByClientFailure,
    CrmTradeOpportunitySaleActionType.UnmatchPurchaseOpportunityFailure,
  ),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'api.actionFailure',
      title: 'api.actionFailure',
      type: ToastType.Error,
    }),
  ]),
)

const informOnSuccessActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunitySaleAction.sendInternalCommentSuccess>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.sendInternalEventSuccess>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.updateOpportunitySuccess>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesSuccess>
    | ReturnType<typeof CrmTradeOpportunitySaleAction.unmatchPurchaseOpportunitySuccess>
    >(
    CrmTradeOpportunitySaleActionType.SendInternalCommentSuccess,
    CrmTradeOpportunitySaleActionType.SendInternalEventSuccess,
    CrmTradeOpportunitySaleActionType.UpdateOpportunitySuccess,
    CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesSuccess,
    CrmTradeOpportunitySaleActionType.UnmatchPurchaseOpportunitySuccess,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      id: 'toast.actionSuccess',
      title: 'toast.actionSuccess',
      type: ToastType.Success,
    }),
  ]),
)

export const opportunitySaleEpic = combineEpics(
  alertOnFailureActionEpic,
  assignOpportunityEpic,
  changeFilterOrSearchPhraseEpic,
  getMatchedPurchaseOpportunitiesEpic,
  getOpportunitiesEpic,
  getOpportunityEpic,
  getOpportunitySaleByClientEpic,
  informOnSuccessActionEpic,
  matchPurchaseOpportunitiesByClientEpic,
  matchPurchaseOpportunitiesEpic,
  unmatchPurchaseOpportunityEpic,
  redirectToListOnUpdateSuccessEpic,
  sendOpportunityInternalCommentEpic,
  sendOpportunityInternalEventEpic,
  sendOpportunityInternalEventWithAttachmentsEpic,
  unassignOpportunityEpic,
  updateOpportunityEpic,
  uploadAttachmentsSuccessEpic,
// tslint:disable-next-line:max-file-line-count
)
