import { AmountType, CarFinance, CarFinanceId, CurrencyType } from 'actff-bo-lib/car/dto'

export const carFinanceInitialValues: CarFinance = {
  amount: 0,
  amountType: AmountType.Net,
  currency: CurrencyType.Pln,
  financeEndDate: null,
  financeType: '',
  inHouse: false,
  lessorName: '',
  uuid: '' as CarFinanceId,
}
