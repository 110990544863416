interface IProcessEnv {
  readonly [key: string]: string | undefined
}

export enum AppConfig {
  APP_API_URL = 'REACT_APP_API_URL',
  LOGROCKET_ID = 'REACT_APP_LOGROCKET_ID',
  FCM_MESSAGING_SENDER_ID = 'REACT_APP_FCM_MESSAGING_SENDER_ID',
  FCM_API_KEY = 'REACT_APP_FCM_API_KEY',
  FCM_APP_ID = 'REACT_APP_FCM_APP_ID',
  FCM_AUTH_DOMAIN = 'REACT_APP_FCM_AUTH_DOMAIN',
  FCM_DATABASE_URL = 'REACT_APP_FCM_DATABASE_URL',
  FCM_PROJECT_ID = 'REACT_APP_FCM_PROJECT_ID',
  FCM_STORAGE_BUCKET = 'REACT_APP_FCM_STORAGE_BUCKET',
  FCM_PUBLIC_VAPID_KEY = 'REACT_APP_FCM_PUBLIC_VAPID_KEY',
  APP_ENV = 'REACT_APP_ENV',
  PUBLIC_URL = 'PUBLIC_URL',
  VERSION = 'REACT_APP_VERSION',
}

export const EnvConfig: IProcessEnv = process.env

export const getConfig = (field: AppConfig, defaultValue = '') => EnvConfig[field] || defaultValue

export const isTestEnvironment = getConfig(AppConfig.APP_ENV) === 'test'

export type EnvSelector<T> = {
  readonly dev?: T,
  readonly test?: T,
  readonly stg?: T,
  readonly prod?: T,
  readonly default: T,
}

export const envSelector = <T>(variants: EnvSelector<T>) => {
  const envName = getConfig(AppConfig.APP_ENV)

  return variants.hasOwnProperty(envName)
    ? variants[envName]
    : variants.default
}
