import { LoggedInPermissions } from 'actff-bo-lib/user/dto/permissions'

import { ComponentType, IconType, Path } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

// tslint:disable object-literal-sort-keys
export const chatMenu = {
  access: LoggedInPermissions,
  component: ComponentType.ChatView,
  expanded: false,
  icon: IconType.Chat,
  name: 'menu.chat',
  path: Paths.Chat as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      component: ComponentType.ChatView,
      expanded: false,
      path: Paths.ChatView as Path,
      hiddenInMenu: true,
      ...LoggedInWrappers,
      children: [],
    },
  ],
}
