import { FormCell } from 'actff-bo-app/components/Form'
import { defaultDateFormat, defaultTimeFormat, defaultTimeInterval } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { addHours, addMinutes, endOfToday, format, getHours, getMinutes, isToday, startOfDay, startOfToday } from 'date-fns'
import { InjectedFormikProps } from 'formik'
import * as React from 'react'
import { Modifier } from 'react-day-picker/types/common'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DatePickerInput } from './DatePickerInput'
import { TimePicker } from './TimePicker'

type Props = {
  readonly editMode: boolean,
  readonly onDateTimeChange: (date: Date | null) => void,
  readonly locale: {},
  readonly dateTime: Date | null,
  readonly className?: string,
  readonly dateCaption?: string,
  readonly disabledDays?: ReadonlyArray<Modifier>,
  readonly futureDate?: boolean,
  readonly timeCaption?: string,
}

type DateTimeComponentProps = Testable & Props & WithTranslation & InjectedFormikProps<any, any> // tslint:disable-line

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;

  input {
    width: 100% !important;
  }
`

class DateTimeComponent extends React.Component<DateTimeComponentProps> {

  public render(): React.ReactNode {
    const { className, dateCaption = 'caption.date', t, timeCaption = 'caption.time' } = this.props

    return (
      <Container className={className}>
        <FormCell>
          <label>{t(dateCaption)}</label>
          {this.renderDate()}
        </FormCell>
        <FormCell>
          <label>{t(timeCaption)}</label>
          {this.renderTime()}
        </FormCell>
      </Container>
    )
  }

  private readonly handleDateChange = (date: Date | null) => {
    const { dateTime, onDateTimeChange } = this.props

    if (!date) {
      this.setFormValue(null)

      return onDateTimeChange(null)
    }

    const hours = dateTime ? getHours(dateTime) : 0
    const minutes = dateTime ? getMinutes(dateTime) : 0
    const beginningOfTheDay = startOfDay(date)
    const dateValue = addHours(addMinutes(beginningOfTheDay, minutes), hours)
    this.setFormValue(dateValue)

    return onDateTimeChange(addHours(addMinutes(beginningOfTheDay, minutes), hours))
  }

  private readonly handleTimeChange = (date: Date) => {
    const { onDateTimeChange } = this.props
    const hours = getHours(date)
    const minutes = getMinutes(date)
    const dateValue = addHours(addMinutes(startOfDay(date), minutes), hours)
    this.setFormValue(dateValue)

    return onDateTimeChange(dateValue)
  }

  private readonly setFormValue = (value: Date | null) => {
    const { field, form } = this.props

    form.setFieldValue(field.name, value)
  }

  private readonly renderDate = () => {
    const { dateTime, disabledDays, editMode, field, form, locale, testId } = this.props
    const { errors } = form

    return (
      editMode
        ? (
          <DatePickerInput
            date={dateTime}
            disabledDays={disabledDays}
            error={errors[field.name]}
            format={defaultDateFormat}
            onChange={this.handleDateChange}
            testId={`${testId}-date`}
          />
        )
        : dateTime && <span data-testid={`${testId}-date`}>{format(dateTime, defaultDateFormat, { locale })}</span>
    )
  }

  private readonly getTimeMin = () => {
    const { dateTime, futureDate } = this.props

    return futureDate && isToday(dateTime) ? new Date() : startOfToday()
  }

  private readonly renderTime = () => {
    const { dateTime, editMode, field, form, locale, testId } = this.props
    const { errors } = form

    return (
      editMode
        ? (
          <TimePicker
            date={dateTime}
            error={errors[field.name]}
            format={defaultTimeFormat}
            interval={defaultTimeInterval}
            minTime={this.getTimeMin()}
            maxTime={endOfToday()}
            onChange={this.handleTimeChange}
            testId={`${testId}-time`}
          />
        )
        : dateTime && <span data-testid={`${testId}-time`}>{format(dateTime, defaultTimeFormat, { locale })}</span>
    )
  }
}

export const DateTime = withTranslation()(DateTimeComponent)
