import { Popover } from '@material-ui/core'
import { ExpandCollapse } from 'actff-bo-app/components/ExpandCollapse'

import {
  CurrentAssignmentFilterCaptionContainer,
  Divider,
  ExpandCollapseContainer,
  Filters,
  Link,
  PopoverContentContainer,
} from 'actff-bo-app/Crm/Trade/OpportunityAssign/Styled'
import { opportunityUnassigned } from 'actff-bo-lib/crm/service'
import { TradeOpportunityType } from 'actff-bo-lib/crm/trade'
import { CrmTradeOpportunityPurchaseAction, selectOpportunityPurchaseListAssignmentFilter } from 'actff-bo-lib/crm/trade/purchase'
import { CrmTradeOpportunitySaleAction, selectOpportunitySaleListAssignmentFilter } from 'actff-bo-lib/crm/trade/sale'
import { selectMe, UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

type Props = {
  readonly userList: ReadonlyArray<UserWithoutPermissions> | null,
  readonly opportunityType: TradeOpportunityType,
}

export const OpportunityAssignFilters: FC<Props> = ({ userList, opportunityType }) => {
  const { t } = useTranslation()
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)
  const dispatch = useDispatch()
  const getChangeAssigmentFilterAction = () => opportunityType === TradeOpportunityType.Purchase
    ? CrmTradeOpportunityPurchaseAction.changeAssignmentFilter : CrmTradeOpportunitySaleAction.changeAssignmentFilter

  const getCurrentAssignmentFilterSelector = () => opportunityType === TradeOpportunityType.Purchase
    ? selectOpportunityPurchaseListAssignmentFilter : selectOpportunitySaleListAssignmentFilter

  const changeAssignmentFilter = (userId: UserId | null) => () => {
    dispatch(getChangeAssigmentFilterAction()(userId))
    setPopoverAnchor(null)
  }

  const currentUser = useSelector(selectMe)
  const currentAssignmentFilter = useSelector(getCurrentAssignmentFilterSelector())

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setPopoverAnchor(null)
  }

  const open = Boolean(popoverAnchor)

  const currentAssignmentFilterCaption = () => {
    switch (currentAssignmentFilter) {
      case currentUser?.uuid:
        return t('opportunity.assign.filters.my')
      case opportunityUnassigned:
        return t('opportunity.unassigned')
      default:
        const assignedUser = userList?.find(user => user.uuid === currentAssignmentFilter)

        return assignedUser ? `${assignedUser.firstName} ${assignedUser.lastName}` : t('opportunity.assign.filters.all')
    }
  }

  return (
    <>
      <Filters onClick={handleClick}>
        <div>
          {t('opportunity.assign.filters.caption')}<br />
          <CurrentAssignmentFilterCaptionContainer>{currentAssignmentFilterCaption()}</CurrentAssignmentFilterCaptionContainer>
        </div>
        <ExpandCollapseContainer><ExpandCollapse expanded={open} /></ExpandCollapseContainer>
      </Filters>
      <Popover
        anchorEl={popoverAnchor}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
      >
        <PopoverContentContainer>
          <Link
            className={!currentAssignmentFilter && 'active'}
            onClick={changeAssignmentFilter(null)}
            noPadding={true}
            noHeight={true}
            textAlign='left'
          >
            {t('opportunity.assign.filters.all')}
          </Link>
          <Link
            className={(currentAssignmentFilter === currentUser?.uuid) && 'active'}
            onClick={changeAssignmentFilter(currentUser?.uuid as UserId)}
            noPadding={true}
            noHeight={true}
            textAlign='left'
          >
            {t('opportunity.assign.filters.my')}
          </Link>
          <Link
            className={(currentAssignmentFilter === opportunityUnassigned) && 'active'}
            onClick={changeAssignmentFilter(opportunityUnassigned)}
            noPadding={true}
            noHeight={true}
            textAlign='left'
          >
            {t('opportunity.unassigned')}
          </Link>
          <Divider />
          {userList?.map(user => (
            <Link
              className={currentAssignmentFilter === user.uuid && 'active'}
              key={user.uuid}
              noHeight={true}
              noPadding={true}
              onClick={changeAssignmentFilter(user.uuid)}
              textAlign='left'
            >
              {user.firstName} {user.lastName}
            </Link>
          ))}
        </PopoverContentContainer>
      </Popover>
    </>
  )
}
