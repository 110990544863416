import { User } from 'actff-bo-lib/user'
import { Client, ClientId } from './client'

export type ContactApproval = {
  readonly approved: boolean,
  readonly changeDate: Date,
  readonly content: string,
  readonly user: User | null,
  readonly client: Client | null,
}

type ContactApprovalToAPI = {
  readonly approved: boolean,
}

export type ContactApprovals = {
  readonly marketingOffers: {
    readonly general: ReadonlyArray<ContactApproval>,
    readonly pushNotifications: ReadonlyArray<ContactApproval>,
    readonly sms: ReadonlyArray<ContactApproval>,
    readonly email: ReadonlyArray<ContactApproval>,
  },
  readonly tradeOffers: {
    readonly general: ReadonlyArray<ContactApproval>,
    readonly pushNotifications: ReadonlyArray<ContactApproval>,
    readonly sms: ReadonlyArray<ContactApproval>,
    readonly email: ReadonlyArray<ContactApproval>,
  },
  readonly personalData: {
    readonly general: ReadonlyArray<ContactApproval>,
  },
  readonly profile: {
    readonly general: ReadonlyArray<ContactApproval>,
  },
  readonly termsAndConditions: {
    readonly general: ReadonlyArray<ContactApproval>,
  },
}

export type ApprovalCounts = {
  readonly tradeOffers: number,
  readonly marketingOffers: number,
  readonly personalData: number,
}

export enum ApprovalsGroupType {
  MarketingApprovals = 'marketingApprovals',
  TradeApprovals = 'tradeApprovals',
}

export type ContactApprovalsFromAPI = {
  readonly contactApprovals: ContactApprovals,
}
export type ContactApprovalsToAPI = {
  readonly contactApprovals: {
    readonly marketingOffers: {
      readonly general: ContactApprovalToAPI,
      readonly pushNotifications: ContactApprovalToAPI,
      readonly sms: ContactApprovalToAPI,
      readonly email: ContactApprovalToAPI,
    },
    readonly tradeOffers: {
      readonly general: ContactApprovalToAPI,
      readonly pushNotifications: ContactApprovalToAPI,
      readonly sms: ContactApprovalToAPI,
      readonly email: ContactApprovalToAPI,
    },
    readonly personalData: {
      readonly general: ContactApprovalToAPI,
    },
    readonly profile: {
      readonly general: ContactApprovalToAPI,
    },
    readonly termsAndConditions: {
      readonly general: ContactApprovalToAPI,
    },
  },
}

export type ContactApprovalsWithClientIdToAPI = {
  readonly clientUuid: ClientId,
  readonly contactApprovals: ContactApprovalsToAPI,
}

export enum ContactApprovalType {
  email = 'email',
  marketingOffers = 'marketingOffers',
  personalData = 'personalData',
  profile = 'profile',
  pushNotifications = 'pushNotifications',
  sms = 'sms',
  termsAndConditions = 'termsAndConditions',
  tradeOffers = 'tradeOffers',
}
