import { DatePickerInputField, Field } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import { startOfYesterday } from 'date-fns'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'

import { LabelStyled } from '../Styled'

type Props = Testable & {
  readonly t: i18next.TFunction,
}

const Container = styled.div`
  display: grid;
  grid-area: registration-date;
`

const RegistrationDateFromContainer = styled.div`
  margin-bottom: 12px;
`

export const RegistrationDate: React.FC<Props> = ({ testId, t }) => (
  <Container>
    <LabelStyled dark={true} uppercase={false}>{t('car.registrationDate')}</LabelStyled>
    <RegistrationDateFromContainer>
      <label htmlFor='registrationDateFrom'>{t('label.from')}:</label>
      <Field
        component={DatePickerInputField}
        disabledDays={{ after: startOfYesterday() }}
        id='registrationDateFrom'
        name='registrationDateFrom'
        testId={`${testId}registration-date--from`}
      />
    </RegistrationDateFromContainer>
    <label htmlFor='registrationDateTo'>{t('label.to')}:</label>
    <Field
      component={DatePickerInputField}
      disabledDays={{ after: startOfYesterday() }}
      id='registrationDateTo'
      name='registrationDateTo'
      testId={`${testId}registration-date--to`}
    />
  </Container>
)
