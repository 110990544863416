import { getDealerLocationsSuccess } from '../dealership'
import { MenuAction, MenuActionType } from './actions'
import { MenuItem } from './dto'
import { getInitialMenu } from './initial-menu/get-initial-menu'

export type MenuState = {
  readonly disabled: boolean,
  readonly expanded: boolean,
  readonly items: ReadonlyArray<MenuItem>,
}

const initialState = {
  disabled: false,
  expanded: true,
  items: getInitialMenu(),
}

// tslint:disable-next-line:cyclomatic-complexity
export const menuReducer = (state: MenuState = initialState, action: MenuAction): MenuState => {
  switch (action.type) {
    case MenuActionType.ToggleDisableMenu:
      return {
        ...state,
        disabled: !state.disabled,
      }

    case MenuActionType.ToggleMenu:
      return {
        ...state,
        expanded: !state.expanded,
      }

    case MenuActionType.ToggleMenuItem:
      const { payload } = action
      const expandItem = (item: MenuItem): MenuItem =>
        item.path === payload
          ? {
            ...item,
            expanded: !item.expanded,
          }
          : {
            ...item,
            children: item.children.map(expandItem),
          }

      return {
        ...state,
        items: state.items.map(expandItem),
      }

    case getDealerLocationsSuccess.type:
      return {
        ...state,
        items: getInitialMenu(),
      }

    default:
      return state
  }
}
