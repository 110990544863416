import { Observable } from 'rxjs'
import { ajax } from 'rxjs/ajax'

import {
  Headers,
  mergeToDefaultHeaders,
  mergeToHeadersWithPayload,
  prefixedUrl,
  toResponse,
  toResponseBlob,
} from './request-utils'

const getPlainText = (url: string, headers?: Headers): Observable<string> => ajax({
    headers: mergeToDefaultHeaders({
      ...headers,
      Accept: 'text/plain',
      'Content-Type': 'text/plain',
    }),
    responseType: 'text/plain',
    url: prefixedUrl(url),
  }).pipe(
    toResponse<string>(),
  )

const get = <T>(url: string, headers?: Headers): Observable<T> => ajax.get(
  prefixedUrl(url),
  mergeToDefaultHeaders(headers),
).pipe(
  toResponse<T>(),
)

const getObjectUrl = (url: string, headers?: Headers): Observable<string> =>
  ajax({
    headers: mergeToDefaultHeaders(headers),
    responseType: 'blob',
    url,
  }).pipe(
    toResponseBlob(),
  )

const post = <T, U>(url: string, data: U, headers?: Headers, autoFillContentType?: boolean): Observable<T> => ajax.post(
  prefixedUrl(url),
  data,
  autoFillContentType
    ? mergeToDefaultHeaders(headers)
    : mergeToHeadersWithPayload(headers),
).pipe(
  toResponse<T>(),
)

const put = <T, U>(url: string, data: U, headers?: Headers): Observable<T> => ajax.put(
  prefixedUrl(url),
  data,
  mergeToHeadersWithPayload(headers),
).pipe(
  toResponse<T>(),
)

const patch = <T, U>(url: string, data: U, headers?: Headers, autoFillContentType?: boolean): Observable<T> => ajax.patch(
  prefixedUrl(url),
  data,
  autoFillContentType
    ? mergeToDefaultHeaders(headers)
    : mergeToHeadersWithPayload(headers),
).pipe(
  toResponse<T>(),
)

const _delete = <T>(url: string, headers?: Headers): Observable<T> => ajax.delete(
  prefixedUrl(url),
  mergeToDefaultHeaders(headers),
).pipe(
  toResponse<T>(),
)

export const api = {
  delete: _delete,
  get,
  getObjectUrl,
  getPlainText,
  patch,
  post,
  put,
}
