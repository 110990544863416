import { Loader } from 'actff-bo-app/components/Loader'
import { Search } from 'actff-bo-app/components/Search'
import { WithPagination } from 'actff-bo-app/components/Table'
import { OpportunityListType } from 'actff-bo-lib/crm'
import { OpportunityPurchase, OpportunitySaleId } from 'actff-bo-lib/crm/trade'
import {
  CrmTradeOpportunityPurchaseAction,
  selectOpportunityPurchaseList,
  selectOpportunityPurchaseListCurrentPage,
  selectOpportunityPurchaseSearchPhrase,
} from 'actff-bo-lib/crm/trade/purchase'
import { selectMatchedPurchaseOpportunities } from 'actff-bo-lib/crm/trade/sale'
import { Testable } from 'actff-bo-lib/global/testable'
import { Path, Paths } from 'actff-bo-lib/menu'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Th, Tr } from 'styles'
import { OpportunityRow } from './OpportunityRow'
import { Container, FormHeader, Header, HeaderContainer } from './Styled'

type Props = Testable & {
  readonly saleUuid?: OpportunitySaleId,
  readonly onOpportunityClick: (opportunity: OpportunityPurchase) => void,
  readonly selectedOpportunities: ReadonlyArray<OpportunityPurchase>,
  readonly onSetSelectedOpportunities: (opportunities: ReadonlyArray<OpportunityPurchase>) => void,
}

export const SearchOpportunities: FC<Props> = ({
  onOpportunityClick,
  onSetSelectedOpportunities,
  saleUuid,
  selectedOpportunities,
  testId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onSearch = (phrase: string) => { dispatch(CrmTradeOpportunityPurchaseAction.changeSearchMatchingOpportunitiesSearchPhrase(phrase)) }
  const searchPhrase = useSelector(selectOpportunityPurchaseSearchPhrase)
  const currentPage = useSelector(selectOpportunityPurchaseListCurrentPage(OpportunityListType.all))
  const matchedPurchaseOpportunities = useSelector(selectMatchedPurchaseOpportunities)

  const {
    data: matchingOpportunities,
    loading: loadingMatchingOpportunities,
  } = useSelector(selectOpportunityPurchaseList(OpportunityListType.all))

  const getNoOfPages = () => matchingOpportunities?.noOfPages || 0
  const getLength = () => matchingOpportunities?.result.length || 0

  useEffect(() => {
    if (matchedPurchaseOpportunities) { onSetSelectedOpportunities(matchedPurchaseOpportunities) }
  }, [matchedPurchaseOpportunities, onSetSelectedOpportunities])

  const handleChangePage = (nextPage: number) => {
    dispatch(CrmTradeOpportunityPurchaseAction.changeSearchMatchingOpportunitiesCurrentPage(nextPage))
  }

  if (loadingMatchingOpportunities) { return <Loader /> }

  return (
    <WithPagination
      currentPage={currentPage || 0}
      onPageChange={handleChangePage}
      pageCount={getNoOfPages()}
      path={Paths.ClientViewPreferences as Path}
      rowsInCurrentPage={getLength()}
      testId={testId}
    >
      <Container>
        <FormHeader>{t('preferences.searchOpportunities.header')}</FormHeader>
        <HeaderContainer>
          <Header>{t('preferences.searchOpportunities.assignCount', { assignCount: matchedPurchaseOpportunities.length})}</Header>
          <Search
            testId={testId}
            onSearch={onSearch}
            initialValues={{ searchPhrase }}
          />
        </HeaderContainer>
        <Table>
          <thead>
          <Tr>
            <Th />
            <Th>{t('table.columns.status')}:</Th>
            <Th>{t('table.columns.chanceDate')}:</Th>
            <Th>{t('table.columns.fullName')}:</Th>
            <Th>{t('car.vinNumber')}:</Th>
            <Th>{t('table.columns.carModel')}:</Th>
            <Th>{t('table.columns.productionYear')}:</Th>
            <Th>{t('table.columns.knownMileage')}:</Th>
            <Th>{t('table.columns.fuelType')}:</Th>
            <Th>{t('table.columns.price')}:</Th>
            <Th>{t('crmTrade.sale.preferences.searchOpportunities.columns.matchesCount')}:</Th>
          </Tr>
          </thead>
          <tbody>
          {matchingOpportunities?.result.map((opportunity: OpportunityPurchase, index: number) => (
            <OpportunityRow
              key={opportunity.uuid}
              opportunity={opportunity}
              onClick={() => onOpportunityClick(opportunity)}
              saleUuid={saleUuid}
              selected={selectedOpportunities.some(entry => entry.uuid === opportunity.uuid)}
              testId={`${testId}row-${index}`}
            />
          ))}
          </tbody>
        </Table>
      </Container>
    </WithPagination>
  )
}
