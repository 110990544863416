import { Container, Header } from 'actff-bo-app/Admin/Styled'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { EditableList } from 'actff-bo-app/components/EditableList'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { addBrakeLiquid, deleteBrakeLiquid, getBrakeLiquids } from 'actff-bo-lib/admin/brands/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { selectCurrentBrand } from 'actff-bo-lib/dealership'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { H2 } from 'styles'
import { ViewTabs } from '../ViewTabs'

const testId = 'admin-view-brake-liquids__'

const BrakeLiquidsComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentBrand = useSelector(selectCurrentBrand)
  const enabled = currentBrand !== null

  const { data: brakeLiquids, isLoading, isError, refetch } = useQuery([QueryKeys.GET_CAR_MODELS, currentBrand], async () =>
    await getBrakeLiquids([currentBrand]), { retry: false, enabled },
  )

  const handleDelete = async (brakeLiquid: string) => {
    try {
      await deleteBrakeLiquid(currentBrand, brakeLiquid)
      dispatch(displaySuccessToast('admin.brands.brakeLiquids.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      await refetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.brands.brakeLiquids.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  const handleAdd = async (newBrakeLiquid: string) => {
    try {
      await addBrakeLiquid(currentBrand, newBrakeLiquid)
      dispatch(displaySuccessToast('admin.brands.brakeLiquids.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      await refetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.brands.brakeLiquids.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <Container>
          <Header><H2>{t('admin.brands.brakeLiquids.subheader')}</H2></Header>
          <LocalLoader />
        </Container>
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('admin.brands.brakeLiquids.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.brands.brakeLiquids.subheader')}</H2></Header>
        <EditableList
          tableHeaderCaptionTKey={'table.columns.liquidName'}
          addButtonCaptionTKey={'admin.brands.brakeLiquids.addBrakeLiquid'}
          items={brakeLiquids}
          noPadding={true}
          onAdd={handleAdd}
          onDelete={handleDelete}
        />
      </Container>
    </>
  )
}

export const BrakeLiquids = withAdminViewHeader(BrakeLiquidsComponent)
