import { api, axiosApi } from 'actff-bo-lib/api'
import { CarLeaveOption } from 'actff-bo-lib/service-request/dto'

import { DictionaryType } from './dto'

export const getCarTypes = () => api.get<ReadonlyArray<DictionaryType<string>>>('/dictionary/carType')
export const getFuelTypes = () => api.get<ReadonlyArray<DictionaryType<string>>>('/dictionary/fuelType')
export const getFuelTypesWithAxios = () => axiosApi.get<ReadonlyArray<DictionaryType<string>>>('/dictionary/fuelType')
export const getCarLeaveOptionNames = () => api.get<ReadonlyArray<DictionaryType<CarLeaveOption>>>('/dictionary/carLeaveOption')
export const getTireTypes = () => api.get<ReadonlyArray<DictionaryType<string>>>('/dictionary/tireType')
