import { ClientBasicData, ClientStatus } from 'actff-bo-lib/client'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TypeIcons } from './TypeIcons'

type Props = {
  readonly client: ClientBasicData,
  readonly className?: string,
}

export const ClientType: FC<Props> = ({ client, className }) => {
  const { t } = useTranslation()

  return client?.status !== ClientStatus.Deleted
    ? client?.type && client.type.length > 0
      ? (
        <TypeIcons className={className} person={client} />
      )
      : null
    : <>{t('caption.noData')}</>
}
