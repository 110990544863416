import { FormValueT } from 'actff-bo-lib/car'
import { useEffect } from 'react'
import { FieldErrors } from 'react-hook-form/dist/types/form'

export const useScrollToErrorField = (errors: FieldErrors<FormValueT>) => {
  useEffect(() => {
    if (Object.keys(errors)?.length > 0) {
      const elements = Object.keys(errors)
        .map(key => Object.keys(errors[key] || {}).map(_key => `${key}-${_key}`))
        .map(fieldName => document.querySelector(`#${fieldName}`))

      // Scroll to first element with error on the list
      elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [errors])
}
