import { ContactDetailsForm } from 'actff-bo-app/Admin/DealerLocations/ContactDeatils/ContactDetailsForm'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  AdminDealerSettings as AdminDealerSettingsDto,
  AdminLocationSettingsAction,
  selectAdminDealerSettings,
} from 'actff-bo-lib/admin/dealer-locations'
import { selectCurrentLocation } from 'actff-bo-lib/dealership'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ViewTabs } from '../ViewTabs'

const testId = 'admin-view-dealership__'

const ContactDetailsComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentLocation = useSelector(selectCurrentLocation)
  const getDealerSettings = () => dispatch(AdminLocationSettingsAction.getDealerSettings(currentLocation?.key))
  const { loading, data: dealerSettings } = useSelector(selectAdminDealerSettings)

  const saveAdminDealership = (settings: AdminDealerSettingsDto) =>
    dispatch(AdminLocationSettingsAction.updateDealerSettings(settings, currentLocation?.key))

  useEffect(() => {
    getDealerSettings()
  }, [currentLocation])

  const renderContent = () => dealerSettings
    ? <ContactDetailsForm dealerSettings={dealerSettings} onSubmitForm={saveAdminDealership} />
    : <NoData>{t('caption.noData')}</NoData>

  return (
    <>
      <ViewTabs testId={testId} />
      {loading ? <LocalLoader /> : renderContent()}
    </>
  )
}

export const ContactDetails = withAdminViewHeader(ContactDetailsComponent)
