import { CarInfo } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { NominalString } from 'actff-bo-lib/global'

export enum TelephoneRequestStatus {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export type TelephoneRequest = {
  readonly uuid: TelephoneRequestId,
  readonly car: CarInfo,
  readonly client: Client,
  readonly requestTime: Date,
  readonly status: TelephoneRequestStatus,
  readonly locationKey: DealerLocationKey,
}

export type UpdateTelephoneRequestDto = Pick<TelephoneRequest, 'uuid' | 'status'>

export type TelephoneRequestId = NominalString<TelephoneRequest>

export type TelephoneRequestCount = {
  readonly requestCount: number,
}

export type TelephoneRequestFilterValues = TelephoneRequestStatus
