import {
  FilterType,
  OpportunityInsuranceSellTypes,
  OpportunityInsuranceType,
  OpportunitySourceName,
  ReportColumns,
  SalesReport,
  SalesReportSummary,
} from 'actff-bo-lib/crm/insurance'
import { roundToTwoDecimalPlaces } from 'actff-bo-lib/currency'
import React from 'react'

import { ReportInput } from './ReportInput'
import { ReportInsurerSelect } from './ReportInsurerSelect'
import { ReportMultiSelect } from './ReportMultiSelect'
import { ReportSelect } from './ReportSelect'

export const SalesReportColumns: ReportColumns<SalesReport, SalesReportSummary> = [
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportMultiSelect
        onChange={onChange}
        value={value}
        transKey='crmInsurance.insuranceSellType.'
        options={Object.keys(OpportunityInsuranceSellTypes)}
      />
    ),
    filterType: FilterType.Multi,
    key: 'sellType',
    name: 'sellType',
  },
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportInsurerSelect onChange={onChange} value={value} />
    ),
    filterType: FilterType.Equal,
    key: 'insurer',
    name: 'insurer',
  },
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportSelect
        onChange={onChange}
        value={value}
        options={Object.keys(OpportunityInsuranceType).map(key => ({ label: key.toLowerCase(), value: key.toLowerCase() }))}
        transKey='crmInsurance.insuranceType.'
      />
    ),
    filterType: FilterType.Equal,
    key: 'type',
    name: 'type',
  },
  {
    filterRenderer: ({ onChange, value }) => (<ReportInput onChange={onChange} value={value} />),
    key: 'number',
    name: 'number',
    summaryFormatter: ({ row: { totalNumber } }) => (<>{totalNumber}</>),
  },
  { key: 'issued', name: 'issued' },
  {
    filterRenderer: ({ onChange, value }) => (<ReportInput onChange={onChange} value={value} />),
    key: 'driverFullName',
    name: 'driverFullName',
  },
  { hideOnPrint: true, key: 'ownerFullName', name: 'ownerFullName' },
  {
    cellClass: 'money-value',
    formatter: ({ row: { grossPremium } }) => (<>{roundToTwoDecimalPlaces(+grossPremium)}</>),
    groupFormatter: ({ childRows }) => (
      <div className='money-value'>
        {roundToTwoDecimalPlaces(childRows.reduce((prev, { grossPremium }) => prev + Number(grossPremium), 0))} zł
      </div>
    ),
    key: 'grossPremium',
    name: 'grossPremium',
    summaryCellClass: 'money-value',
    summaryFormatter: ({ row: { totalGrossPremium } }) => (<>{roundToTwoDecimalPlaces(totalGrossPremium)} zł</>),
  },
  {
    cellClass: 'money-value',
    formatter: ({ row: { insuranceValue } }) => (<>{roundToTwoDecimalPlaces(+insuranceValue)}</>),
    groupFormatter: ({ childRows }) => (
      <div className='money-value'>
        {roundToTwoDecimalPlaces(childRows.reduce((prev, { insuranceValue }) => prev + Number(insuranceValue), 0))} zł
      </div>
    ),
    hideOnPrint: true,
    key: 'insuranceValue',
    name: 'insuranceValue',
    summaryCellClass: 'money-value',
    summaryFormatter: ({ row: { totalInsuranceValue } }) => (<>{roundToTwoDecimalPlaces(totalInsuranceValue)} zł</>),
  },
  {
    cellClass: 'money-value',
    formatter: ({ row: { commissionsValue } }) => (<>{roundToTwoDecimalPlaces(+commissionsValue)}</>),
    groupFormatter: ({ childRows }) => (
      <div className='money-value'>
        {roundToTwoDecimalPlaces(childRows.reduce((prev, { commissionsValue }) => prev + Number(commissionsValue), 0))} zł
      </div>
    ),
    hideOnPrint: true,
    key: 'commissionsValue',
    name: 'commissionsValue',
    summaryCellClass: 'money-value',
    summaryFormatter: ({ row: { totalCommissionsValue } }) => (<>{roundToTwoDecimalPlaces(totalCommissionsValue)} zł</>),
  },
  {
    filterRenderer: ({ onChange, value }) => (<ReportInput onChange={onChange} value={value} />),
    hideOnPrint: true,
    key: 'vin',
    name: 'vin',
  },
  {
    filterRenderer: ({ onChange, value }) => (<ReportInput onChange={onChange} value={value} />),
    hideOnPrint: true,
    key: 'brand',
    name: 'brand',
  },
  { hideOnPrint: true, key: 'model', name: 'model' },
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportSelect
        onChange={onChange}
        value={value}
        options={[true, false].map(confirm => ({ value: confirm.toString(), label: confirm.toString() }))}
        transKey='crmInsurance.confirm.'
      />
    ),
    filterType: FilterType.Equal,
    hideOnPrint: true,
    key: 'opportunityRenewed',
    name: 'opportunityRenewed',
  },
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportSelect
        onChange={onChange}
        value={value}
        options={[true, false].map(confirm => ({ value: confirm.toString(), label: confirm.toString() }))}
        transKey='crmInsurance.confirm.'
      />
    ),
    filterType: FilterType.Equal,
    hideOnPrint: true,
    key: 'opportunityClosed',
    name: 'opportunityClosed',
  },
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportSelect
        onChange={onChange}
        value={value}
        options={[true, false].map(confirm => ({ value: confirm.toString(), label: confirm.toString() }))}
        transKey='crmInsurance.confirm.'
      />
    ),
    filterType: FilterType.Equal,
    hideOnPrint: true,
    key: 'policyConfirmed',
    name: 'policyConfirmed',

  },
  {
    filterRenderer: ({ onChange, value }) => (<ReportInput onChange={onChange} value={value} />),
    hideOnPrint: true,
    key: 'assigneeFullName',
    name: 'assigneeFullName',
  },
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportMultiSelect
        onChange={onChange}
        value={value}
        transKey='crmInsurance.insuranceSourceName.'
        options={Object.keys(OpportunitySourceName)}
      />
    ),
    filterType: FilterType.Multi,
    key: 'sourceName',
    name: 'sourceName',
  },
]

export const SalesReportSummaryRowsFn = (rows: ReadonlyArray<SalesReport>): ReadonlyArray<SalesReportSummary> => [{
  id: 'total_0',
  totalCommissionsValue: rows.reduce((prev, { commissionsValue }) => prev + Number(commissionsValue), 0),
  totalGrossPremium: rows.reduce((prev, { grossPremium }) => prev + Number(grossPremium), 0),
  totalInsuranceValue: rows.reduce((prev, { insuranceValue }) => prev + Number(insuranceValue), 0),
  totalNumber: rows.length,
}]

export const SalesReportFilters = {
  insurer: '',
  number: '',
  sellType: '',
  sourceName: '',
  type: '',
  vin: '',
}
