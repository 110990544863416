import styled from 'styled-components'
import { colors, Header } from 'styles'

export const HeaderStyled = styled(Header)`
  padding-bottom: 0 !important;
`

export const HeaderFiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
`

type ButtonProps = {
  readonly selected: boolean,
}

export const FilterButton = styled.button<ButtonProps>`
  background-color: ${({ selected }: ButtonProps) => selected
    ? `${colors.straw}`
    : `${colors.white}`
  };
  border: ${({ selected }: ButtonProps) => selected
    ? '1.5px solid transparent'
    : `1.5px solid ${colors.swirlGold}`
  };
  border-radius: 3px;
  font-size: 12px;
  font-weight: ${({ selected }: ButtonProps) => selected ? 'bold' : 'normal' };
  margin: 0 12px 12px 0;
  min-height: 38px;
  padding: 0 12px;
`

type FiltersContainerProps = {
  readonly right: boolean,
}

export const FiltersContainer = styled.div<FiltersContainerProps>`
  display: inline-block;
  ${({ right }: FiltersContainerProps) => right && 'text-align: right;'}
  width: 100%;
`

export const DatePickerContainer = styled.div`
  display: inline-block;
  margin-right: 20px;

  label, & > div {
    display: inline-block;
  }

  label {
    margin-right: 10px;
  }
`

export const ReportWrapper = styled.div`
  overflow: auto;

  .money-value {
    text-align: right;
  }

  .rdg-filter-row {
    contain: none;
  }


  .rdg-cell {
    -webkit-user-select: text !important;
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;

    contain: none;
    overflow: visible;
  }

  @media print {
    max-width: 99%;
    min-width: 99%;
    overflow: visible;
    width: 99%;

    @page {
      size: landscape;
    }
  }
`
