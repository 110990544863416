import { Client, ClientPreferences, ClientStatus, ClientType } from 'actff-bo-lib/client/dto'
import { NominalString } from 'actff-bo-lib/global/data-types/nominal-types'

export type OwnerCarId = NominalString<OwnedCar>
export type AppPersonId = NominalString<AppPerson>
export type CounterpartyId = NominalString<Counterparty>

export type PersonAddress = {
  readonly streetPrefix: string,
  readonly street: string,
  readonly postCode: string,
  readonly city: string,
}

export const isAppPerson = (person: Counterparty | AppPerson | Client): person is AppPerson => person && person.hasOwnProperty('type')
export const isCounterparty = (person: Counterparty | AppPerson | Client | undefined): person is Counterparty =>
  !!(person && person.hasOwnProperty('name'))

export type AppPerson = {
  readonly email: string,
  readonly id: string,
  readonly name: string,
  readonly phone: string,
  readonly preferences?: Partial<ClientPreferences>,
  readonly status?: ClientStatus,
  readonly type: ReadonlyArray<ClientType>,
  readonly uuid: AppPersonId,
}

export type Counterparty = {
  readonly address: PersonAddress,
  readonly email: string,
  readonly id: CounterpartyId,
  readonly name: string,
  readonly nip: string,
  readonly phone: string,
  readonly preferences?: Partial<ClientPreferences>,
}

export enum SearchPersonSource {
  App,
  Ndc,
}

export enum OwnershipType {
  AppDriver = 'APP_DRIVER',
  DmsDriver = 'DMS_DRIVER',
  DmsOwner = 'DMS_OWNER',
}

type OverridesHistory = {
  readonly updated: Date,
  readonly next: Client | Counterparty,
}

export type OwnedCar = {
  readonly appDriver: Client,
  readonly appDriverHistory: ReadonlyArray<OverridesHistory>,
  readonly appDriverOverrides: ReadonlyArray<OverridesHistory>,
  readonly firstRegistration: Date,
  readonly ndcCarId: string,
  readonly ndcDriver: Counterparty,
  readonly ndcDriverOverrides: ReadonlyArray<OverridesHistory>,
  readonly ndcOwner: Counterparty,
  readonly ndcOwnerOverrides: ReadonlyArray<OverridesHistory>,
  readonly registrationNumber: string,
  readonly uuid: OwnerCarId,
  readonly vin: string,
}
