import { ClientType } from 'actff-bo-app/components/Client'
import { NewThreadId, ThreadBasicData } from 'actff-bo-lib/chat'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = {
  readonly onClick: () => void,
  readonly result: ThreadBasicData,
}

const ResultContainer = styled(NavLink)`
  display: grid;
  grid-auto-flow: row;
  padding: 8px 16px;

  &:hover {
    cursor: pointer;
    background-color: ${colors.athensGray};
  }
`

const ResultClientType = styled(ClientType)`
  display: inline;
  max-width: 60px;
`

const ResultTypeNameContainer = styled.div`
  display: inline;
`

const ResultRegistrationNumber = styled.span`
  font-size: 10px;
`

export const ChatNewThreadResult: React.FC<Props> = ({ onClick, result }) => {
  const { client, registrationNumber } = result
  const threadId = result.threadUuid ? result.threadUuid : NewThreadId

  return (
    <ResultContainer onClick={onClick} to={createRoute(Paths.ChatView, { threadId })}>
      <ResultTypeNameContainer>
        <ResultClientType client={client} className='type' />
        <span>{client.firstName} {client.lastName}</span>
      </ResultTypeNameContainer>
      <ResultRegistrationNumber>{registrationNumber}</ResultRegistrationNumber>
    </ResultContainer>
  )
}
