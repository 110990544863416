import { PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { WithDealershipFilters } from 'actff-bo-lib/dealership'
import { Filters, Loadable, Searchable } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { parseUrl } from 'actff-bo-lib/pagination'
import { UserAction, UserActionType } from 'actff-bo-lib/user'

import { OfferRequestAction, OfferRequestActionType } from './actions'
import { OfferRequest, OfferRequestFilterValues, OfferRequestStatus, OfferRequestType } from './dto'

export type OfferRequestState = {
  readonly currentPage: number,
  readonly currentOfferRequest: OfferRequest | null,
  readonly currentOfferRequestCars: PagedResult<CarInfo> | null,
  readonly list: Searchable<WithDealershipFilters<Loadable<PagedResult<OfferRequest>>>>,
  readonly listFilters: Filters<OfferRequestFilterValues>,
  readonly newOfferRequestCount: number,
}

const parsedUrl = parseUrl(Paths.OfferRequests)
const initialState: OfferRequestState = {
  currentOfferRequest: null,
  currentOfferRequestCars: null,
  currentPage: parsedUrl.page ? +parsedUrl.page : 1,
  list: {
    brandFilters: [],
    data: null,
    loading: false,
    locationFilters: [],
  },
  listFilters: {
    status: [
      {
        caption: 'filters.new',
        selected: false,
        value: OfferRequestStatus.NEW,
      },
      {
        caption: 'filters.cancelled',
        selected: false,
        value: OfferRequestStatus.CANCELED,
      },
      {
        caption: 'filters.completed',
        selected: false,
        value: OfferRequestStatus.COMPLETED,
      },
    ],
    type: [
      {
        caption: 'filters.offer.buyNewCar',
        selected: false,
        value: OfferRequestType.FINANCE,
      },
      {
        caption: 'filters.offer.renewInsurance',
        selected: false,
        value: OfferRequestType.RENEW_INSURANCE,
      },
      {
        caption: 'filters.offer.insurance',
        selected: false,
        value: OfferRequestType.INSURANCE,
      },
    ],
  },
  newOfferRequestCount: 0,
}

// tslint:disable cyclomatic-complexity
export const offerRequestReducer = (
  state: OfferRequestState = initialState,
  action: OfferRequestAction | UserAction,
): OfferRequestState => {
  switch (action.type) {
    case OfferRequestActionType.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case OfferRequestActionType.ChangeListFilter:
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          [action.payload.param]: [
            ...state.listFilters[action.payload.param]
              .map(filter => filter.value === action.payload.value
                ? ({
                  ...filter,
                  selected: !filter.selected,
                })
                : ({ ...filter }),
              ),
          ],
        },
      }

    case OfferRequestActionType.ChangeSearchPhrase:
      return {
        ...state,
        list: {
          ...state.list,
          searchPhrase: action.payload,
        },
      }

    case OfferRequestActionType.ChangeDealerLocations:
      return {
        ...state,
        list: {
          ...state.list,
          locationFilters: action.payload,
        },
      }

    case OfferRequestActionType.ChangeBrands:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload,
        },
      }

    case OfferRequestActionType.GetOfferRequests:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }

    case OfferRequestActionType.GetOfferRequestsSuccess:
      return {
        ...state,
          list: {
            ...state.list,
            data: action.payload,
            loading: false,
        },
      }

    case OfferRequestActionType.GetOfferRequestsFailure:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
      }

    case OfferRequestActionType.GetNewOfferRequestCountSuccess:
      return {
        ...state,
        newOfferRequestCount: action.payload.requestCount,
      }

    case OfferRequestActionType.GetOfferRequestSuccess:
      return {
        ...state,
        currentOfferRequest: action.payload,
      }

    case OfferRequestActionType.GetOfferRequestCarsSuccess:
      return {
        ...state,
        currentOfferRequestCars: {
          ...action.payload,
          result: action.payload.result.filter(car => car.uuid !== state.currentOfferRequest?.car.uuid),
        },
      }

    case OfferRequestActionType.ClearOfferRequest:
      return {
        ...state,
        currentOfferRequest: null,
        currentOfferRequestCars: null,
      }

    case UserActionType.GetMeSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload.brands,
          locationFilters: action.payload.locations.map(location => location.key),
        },
      }

    default:
      return state
  }
}
