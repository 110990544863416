import { ErrorMessage } from '@hookform/error-message'
import { FormCell } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import React, { FC } from 'react'
import { FieldErrors, Ref, ValidationRules } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly errors: FieldErrors,
  readonly register: (rules?: ValidationRules) => (ref: Ref | null) => void,
  readonly invoiceNumber?: string,
}

type OpportunityAccomplishmentDetailsProps = Props

const OpportunityAccomplishmentDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
`

export const OpportunityAccomplishmentDetails: FC<OpportunityAccomplishmentDetailsProps> = ({
  invoiceNumber,
  errors,
  register,
}) => {
  const { t } = useTranslation()

  return (
    <OpportunityAccomplishmentDetailsContainer>
      <FormCell>
        <label htmlFor='invoiceNumber'>{t('opportunity.form.invoiceNumber.label')}:</label>
        <input
          defaultValue={invoiceNumber}
          name='invoiceNumber'
          ref={register({ required: 'caption.error.required' })}
          type='text'
          placeholder={t('opportunity.form.invoiceNumber.placeholder')}
        />
        <ErrorMessage errors={errors} name='invoiceNumber' as={TranslatedErrorMessage} />
      </FormCell>
    </OpportunityAccomplishmentDetailsContainer>
  )
}
