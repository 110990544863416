// @ts-nocheck
// TODO: Consider if this component is used properly

import { Field as FormikField, FieldProps } from 'formik'
import * as React from 'react'
import styled from 'styled-components'
import { colors, theme } from 'styles'

export const FieldComponent: React.FC<FieldProps> = props => (
  <FormikField autoComplete='off' autoCorrect='off' autoCapitalize='characters' spellCheck='false' {...props} />
)

type ValidationFieldProps = {
  readonly error?: boolean,
  readonly touched?: boolean,
}

export const Field = styled(({ error, touched, ...rest }) =>
  <FieldComponent touched={touched && touched.toString()} error={error} {...rest} />)`
  ${({ disabled }) => disabled && `background-color: ${colors.athensGray}`};
  ${({ error, touched }: ValidationFieldProps) => touched && error && `border: 1.5px solid ${theme.form.error}`};
  text-transform: uppercase;
`
