import { Url } from 'actff-bo-lib/global/attachment'
import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

export const selectAttachmentFeature = (state: State) => state.attachment
export const selectAttachmentThumbnails = createSelector(selectAttachmentFeature, attachment => attachment.attachmentThumbnails)
export const selectAttachments = createSelector(selectAttachmentFeature, attachment => attachment.attachments)

export const selectAttachmentThumbnailForUrl = (url: Url) =>
  createSelector(selectAttachmentThumbnails, thumbnails => thumbnails.get(url))

export const selectAttachmentForUrl = (url: Url) =>
  createSelector(selectAttachments, attachments => attachments.get(url))

export const selectAttachmentsUpload = createSelector(selectAttachmentFeature, attachment => attachment.upload)

export const selectAttachmentsUploadedCount = createSelector(selectAttachmentFeature, attachment => attachment.upload.uploaded.length)
export const selectAttachmentsToUploadCount = createSelector(selectAttachmentFeature, attachment => attachment.upload.toUpload.length)
