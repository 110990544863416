import { Map } from 'immutable'
import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

export enum AvatarStyle {
  Default,
  Filled,
  Dashed,
}

type Props = {
  readonly firstName?: string,
  readonly lastName?: string,
  readonly className?: string,
  readonly style?: AvatarStyle,
  readonly onClick?: (event: React.MouseEvent<HTMLDivElement>) => void,
}

const AvatarStyled = styled.div<Props>`
  align-content: center;
  border-radius: 50%;
  border: 3px solid ${colors.mystic};
  display: grid;
  font-weight: bold;
  height: 42px;
  justify-content: center;
  width: 42px;
`

const FilledAvatar = styled(AvatarStyled)`
  background: ${colors.midnightBlue};
  border-color: ${colors.midnightBlue};
  color: ${colors.white};
`

const DashedAvatar = styled(AvatarStyled)`
  border-style: dashed;
`

const avatarStyleComponentMap = Map([
  [AvatarStyle.Default, AvatarStyled],
  [AvatarStyle.Filled, FilledAvatar],
  [AvatarStyle.Dashed, DashedAvatar],
]) as Map<AvatarStyle, ReactElement>

const getInitials = (firstName?: string, lastName?: string) => (
  <>
    {firstName && firstName.substr(0, 1)}
    {lastName && lastName.substr(0, 1)}
  </>
)

export const Avatar: FC<Props> = ({ className, firstName, style = AvatarStyle.Default, lastName }) => {
  const AvatarComponent = avatarStyleComponentMap.get(style) || AvatarStyled

  return (
    <AvatarComponent className={className}>
      {(firstName || lastName) ? getInitials(firstName, lastName) : <>&mdash;</>}
    </AvatarComponent>
  )
}
