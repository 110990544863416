import {
  ChatAction,
  ChatViewUrlParams,
  NewThreadId,
  selectCurrentConversationThread,
  selectIsNewCurrentConversation,
  selectThreadList,
  Thread,
  ThreadBasicData,
  ThreadId,
} from 'actff-bo-lib/chat'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { compose } from 'redux'
import styled from 'styled-components'

import { ChatConversation, ChatNewConversation } from './ChatConversation'
import { ChatThreadList } from './ChatThreadList'

type StateToProps = {
  readonly currentConversationThread: ThreadBasicData | null,
  readonly isNewCurrentConversation: boolean,
  readonly threadList: ReadonlyArray<Thread>,
}

type DispatchToProps = {
  readonly clearActiveThread: () => void,
  readonly selectActiveThread: (id: ThreadId) => void,
  readonly getThreads: () => void,
}

type ChatViewComponentProps = RouteComponentProps<ChatViewUrlParams> & StateToProps & DispatchToProps

const ChatViewContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr;
  height: 100%;
`

class ChatViewComponent extends React.Component<ChatViewComponentProps> {

  public componentWillUnmount(): void {
    if (this.getThreadIdFromUrl()) {
      this.props.clearActiveThread()
    }
  }

  public componentDidMount(): void {
    this.props.getThreads()
  }

  public componentDidUpdate(oldProps: ChatViewComponentProps): void {
    if (this.isNewThread()) {
      return
    }

    if (this.shouldUpdateComponent(oldProps)) {
      this.props.selectActiveThread(this.getThreadIdFromUrl())
    }
  }

  public render(): React.ReactNode {
    const { currentConversationThread, threadList } = this.props

    return (
      <ChatViewContainer>
        <ChatThreadList currentThreadId={this.getThreadIdFromUrl()} threadList={threadList} />
        {this.isNewThread()
          ? this.displayNewChatConversation(currentConversationThread)
          : this.displayExistingChatConversation()
        }
      </ChatViewContainer>
    )
  }

  private readonly shouldUpdateComponent = (oldProps: ChatViewComponentProps) =>
  this.threadInUrlChangedAndIsNotNull(oldProps)
      || this.threadListJustLoadedAndThreadInUrlPresent(oldProps)
      || this.threadNotLoaded()
      || this.threadIsNew()

  private readonly displayExistingChatConversation = () => this.getThreadIdFromUrl() && <ChatConversation />

  private readonly displayNewChatConversation = (thread: ThreadBasicData | null) => thread && <ChatNewConversation thread={thread} />

  private readonly getThreadIdFromUrl = () => this.props.match.params.threadId as ThreadId

  private readonly isNewThread = () => (this.getThreadIdFromUrl() === NewThreadId)
    && this.props.currentConversationThread
    && !this.props.currentConversationThread.threadUuid

  private readonly threadNotLoaded = (): boolean =>
    !this.props.currentConversationThread

  private readonly threadIsNew = (): boolean => !!(this.props.currentConversationThread && this.props.isNewCurrentConversation)

  private readonly threadInUrlChangedAndIsNotNull = (oldProps: ChatViewComponentProps): boolean =>
    oldProps.match.params.threadId !== this.getThreadIdFromUrl()

  private readonly threadListJustLoadedAndThreadInUrlPresent = (oldProps: ChatViewComponentProps): boolean =>
    (oldProps.threadList.length === 0 && this.props.threadList.length > 0) && !!this.getThreadIdFromUrl()

}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  currentConversationThread: selectCurrentConversationThread(state),
  isNewCurrentConversation: selectIsNewCurrentConversation(state),
  threadList: selectThreadList(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  clearActiveThread: () => { dispatch(ChatAction.clearActiveThread())},
  getThreads: () => { dispatch(ChatAction.getThreads()) },
  selectActiveThread: (threadId: ThreadId) => { dispatch(ChatAction.selectActiveThread(threadId)) },
})

export const ChatView = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ChatViewComponent)
