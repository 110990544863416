import { FormHeader } from 'actff-bo-app/components/Form'
import { PopoverContentContainer as PopoverContentContainerStyled } from 'actff-bo-app/components/Popover'
import styled from 'styled-components'
import { colors, Td as TdComponent, Tr as TrComponent } from 'styles'

type ContainerProps = {
  readonly isOwner?: boolean,
}

type TrProps = {
  readonly selected: boolean,
}

export const CarContainer = styled.div`
  padding: 24px;
`

export const Container = styled(CarContainer)<ContainerProps>`
  ${({ isOwner = false }: ContainerProps) => isOwner && `background-color: ${colors.athensGray}`};
  border-top: 1px solid ${colors.athensGray};
  padding: 24px;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 3fr;
`

export const SectionHeader = styled(FormHeader)`
  align-items: center;
  display: grid;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  justify-content: left;
  line-height: 27px;
`

export const Header = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas: 'user . . addUserButtoon';
  grid-template-columns: repeat(4, 3fr);
`

export const PersonName = styled.div`
  padding-bottom: 8px;
`

export const IconContainer = styled.div`
  cursor: pointer;
  display: grid;
  height: 20px;
  place-items: center;
  width: 20px;
`

export const AddUserContainer = styled.div`
  display: grid;
  grid-area: addUserButtoon;
`

export const LabelStyled = styled.label`
  bottom: 12px;
  left: 25px;
`

export const Placeholder = styled.p`
  margin: 24px;
`

export const Td = styled(TdComponent)`
  padding: 24px;
`

export const Tr = styled(TrComponent)<TrProps>`
  cursor: pointer;

  td {
    ${({ selected }: TrProps) => selected && `background-color: ${colors.sprintWoodGold}`};
  }
`

export const Footer = styled.footer`
  border-top: 1px solid ${colors.athensGray};
  padding: 24px;
`

export const PopoverContentContainer = styled(PopoverContentContainerStyled)`
  border-top: 1px solid ${colors.athensGray};
  padding: 0;
`
