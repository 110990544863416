import { ErrorMessage } from '@hookform/error-message'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import {
  FormCell,
  FormSeparator,
  getDateValue,
  MarketValue,
  MoneyInput,
  renderSelectController,
} from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { DealershipInsurer } from 'actff-bo-lib/admin/dealership'
import {
  AutoSelectedInsuranceSellTypes,
  OpportunityInsurancePaymentTypes,
  OpportunityInsuranceSellTypes,
  OpportunityInsuranceType,
} from 'actff-bo-lib/crm/insurance'
import { SelectOption } from 'actff-bo-lib/global'
import { mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import { CssElementsPlacement } from 'actff-bo-lib/style'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { insurerOptionValueMapper, insurersToSelectOptions } from '../insurer-option-mapper'
import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'
import { NewPolicyDate } from './NewPolicyDate'
import { NewPolicyInstallment } from './NewPolicyInstallment'
import { NewPolicyPayment } from './NewPolicyPayment'

type Props = Testable
  & SectionFormType
  & {
    readonly installmentCount: number,
    readonly isIssuePolicySelected: boolean,
    readonly isSuccessOrFailureSelected: boolean,
    readonly isClientsDecisionSelected: boolean,
    readonly onValidate: (name: string) => void,
    readonly insurers: ReadonlyArray<DealershipInsurer> | undefined,
}

const installmentMinCount = 2

export const SectionNewPolicy: React.FC<Props> = ({
  control,
  errors,
  expanded,
  formState,
  installmentCount,
  insurers,
  isClientsDecisionSelected,
  isIssuePolicySelected,
  isSuccessOrFailureSelected,
  onValidate,
  register,
  setValue,
  testId,
  watch,
  readOnly,
}) => {
  const { t } = useTranslation()
  const formProps = { control, errors, formState, register, setValue, watch }

  const insuranceTypes = mapToOptions(Object.keys(OpportunityInsuranceType), 'crmInsurance.insuranceType', t)
  const insuranceSellTypes = mapToOptions(Object.keys(OpportunityInsuranceSellTypes), 'crmInsurance.insuranceSellType', t)

  const insuranceType = watch('newPolicy.type')
  const paymentType = watch('newPolicy.paymentType')
  const offerMarketValue = watch('offer.marketValue')
  const offerCalculationBase = watch('offer.calculationBase')
  const offerInsuranceValue = watch('offer.insuranceValue')

  const calculateInstallmentCount = () =>
    paymentType === OpportunityInsurancePaymentTypes.ONE_TIME
      ? 1
      : installmentCount > 1
      ? installmentCount
      : installmentMinCount

  const isFieldRequired = () => isIssuePolicySelected && 'caption.error.required'
  const shouldValidateNewPolicyDate = isSuccessOrFailureSelected || isClientsDecisionSelected
  const isSellTypeRequired = () => (isSuccessOrFailureSelected || isClientsDecisionSelected) && 'caption.error.required'

  const triggerValidation = (name: string) => isIssuePolicySelected && onValidate(name)
  const triggerDateValidation = (name: string) => shouldValidateNewPolicyDate && onValidate(name)

  const handlePolicyTypeChange = () => {
    setValue('newPolicy.starts', null)
    setValue('newPolicy.expires', null)
  }

  React.useEffect(() => {
    if (formState.dirtyFields.offer?.marketValue || formState.dirtyFields.offer?.offerCalculationBase) {
      setValue('newPolicy.marketValue', offerMarketValue)
      setValue('newPolicy.insuranceValue', offerInsuranceValue)
      setValue('newPolicy.calculationBase', offerCalculationBase)
    }
  }, [offerMarketValue, offerInsuranceValue, offerCalculationBase, formState.dirtyFields.offer, setValue])

  React.useEffect(() => {
    if (AutoSelectedInsuranceSellTypes.includes(insuranceType)) {
      setValue('newPolicy.sellType', OpportunityInsuranceSellTypes[insuranceType])
    }
  }, [insuranceType, setValue])

  React.useEffect(() => {
    const count = calculateInstallmentCount()

    paymentType && setValue('newPolicy.installmentCount', count)
    triggerValidation('newPolicy.installmentCount')
  }, [paymentType])

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.newPolicy'), !!errors.newPolicy)}>
      <SectionForm>

        <FormCell id='newPolicy-type'>
          <label>{t('crmInsurance.newPolicy.type')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              onChange: handlePolicyTypeChange,
              options: [...insuranceTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-type`,
              transKey: 'crmInsurance.insuranceType',
            })}
            control={control}
            rules={{ required: isFieldRequired() }}
            name='newPolicy.type'
          />
          <ErrorMessage errors={errors} name='newPolicy.type' as={TranslatedErrorMessage} />
        </FormCell>

        <FormCell>
          <label>{t('crmInsurance.newPolicy.number')}:</label>
          <input
            type='text'
            name='newPolicy.number'
            ref={register({ required: isFieldRequired() })}
            data-testid={`${testId}newPolicy-number`}
            disabled={readOnly}
          />
          <ErrorMessage errors={errors} name='newPolicy.number' as={TranslatedErrorMessage} />
        </FormCell>

        <FormCell id='newPolicy-insurer'>
          <label>{t('crmInsurance.newPolicy.insurer')}:</label>
          <Controller
            render={renderSelectController<string>({
              clearable: true,
              creatable: true,
              customValueMapper: insurerOptionValueMapper(t),
              disabled: readOnly,
              options: [...insurersToSelectOptions(insurers)],
              t,
              testId: `${testId}-insurer`,
            })}
            control={control}
            rules={{ required: isFieldRequired() }}
            name='newPolicy.insurer'
          />
          <ErrorMessage errors={errors} name='newPolicy.insurer' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell />

        <FormCell className='datepicker-fullwidth' id='newPolicy-issued'>
          <label>{t('crmInsurance.newPolicy.issueDate')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                date={getDateValue(value)}
                disabled={readOnly}
                onChange={onChange}
                timeDisabled={true}
                testId={`${testId}newPolicy-issueDate`}
              />
            )}
            control={control}
            rules={{ required: isFieldRequired() }}
            name='newPolicy.issued'
          />
          <ErrorMessage errors={errors} name='newPolicy.issued' as={TranslatedErrorMessage} />
        </FormCell>

        <NewPolicyDate
          {...formProps}
          shouldValidateNewPolicyDate={shouldValidateNewPolicyDate}
          insuranceType={insuranceType}
          onTriggerDateValidation={triggerDateValidation}
          readOnly={readOnly}
        />

        <FormCell />

        <MarketValue
          {...formProps}
          insuranceType={insuranceType}
          isIssuePolicySelected={isIssuePolicySelected}
          name='newPolicy'
          testId={testId}
          withLabelPrefix={true}
          readOnly={readOnly}
        />

        <FormCell id='newPolicy-grossPremium'>
          <label>{t('crmInsurance.newPolicy.grossPremium')}:</label>
          <MoneyInput
            control={control}
            rules={{ required: isFieldRequired() }}
            data-testid={`${testId}newPolicy-grossPremium`}
            name='newPolicy.grossPremium'
            disabled={readOnly}
          />
          <ErrorMessage errors={errors} name='newPolicy.grossPremium' as={TranslatedErrorMessage} />
        </FormCell>

        <FormCell id='newPolicy-sellType'>
          <label>{t('crmInsurance.newPolicy.sellType')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              menuPlacement: CssElementsPlacement.Top,
              options: [...insuranceSellTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-sellType`,
              transKey: 'crmInsurance.insuranceSellType',
            })}
            control={control}
            rules={{ required: isSellTypeRequired() }}
            name='newPolicy.sellType'
          />
          <ErrorMessage errors={errors} name='newPolicy.sellType' as={TranslatedErrorMessage} />
        </FormCell>

        <NewPolicyPayment
          {...formProps}
          paymentType={paymentType}
          readOnly={readOnly}
          testId={testId}
          isIssuePolicySelected={isIssuePolicySelected}
        />

        {installmentCount > 1 && (
          <>
            <FormSeparator />
            {Array(installmentCount).fill(null).map((_, index) => (
              <React.Fragment key={index}>
                <NewPolicyInstallment
                  {...formProps}
                  index={index}
                  installmentCount={installmentCount}
                  readOnly={readOnly}
                />
              </React.Fragment>
            ))}
        </>
      )}
      </SectionForm>
    </CollapsableSection>
  )
}
