import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { ClientId } from 'actff-bo-lib/client'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { AjaxError } from 'rxjs/ajax'

import {
  TelephoneRequest,
  TelephoneRequestCount,
  TelephoneRequestFilterValues,
  TelephoneRequestId,
  UpdateTelephoneRequestDto,
} from './dto'

export enum TelephoneRequestActionType {
  ChangeBrands = '[TELEPHONE REQUEST] CHANGE CAR BRANDS',
  ChangeDealerLocations = '[TELEPHONE REQUEST] CHANGE DEALER LOCATIONS',
  ChangeListFilter = '[TELEPHONE REQUEST] CHANGE LIST FILTER',
  ChangePage = '[TELEPHONE REQUEST] CHANGE PAGE',
  ChangeSearchPhrase = '[TELEPHONE REQUEST] CHANGE SEARCH PHRASE',

  ClearTelephoneRequest = '[TELEPHONE REQUEST] CLEAR TELEPHONE REQUEST',

  GetTelephoneRequest = '[TELEPHONE REQUEST] GET TELEPHONE REQUEST',
  GetTelephoneRequestFailure = '[TELEPHONE REQUEST] GET TELEPHONE REQUEST FAILURE',
  GetTelephoneRequestSuccess = '[TELEPHONE REQUEST] GET TELEPHONE REQUEST SUCCESS',

  GetNewTelephoneRequestCount = '[TELEPHONE REQUEST] GET NEW TELEPHONE REQUEST COUNT',
  GetNewTelephoneRequestCountFailure = '[TELEPHONE REQUEST] GET NEW TELEPHONE REQUEST COUNT FAILURE',
  GetNewTelephoneRequestCountSuccess = '[TELEPHONE REQUEST] GET NEW TELEPHONE REQUEST COUNT SUCCESS',

  GetTelephoneRequestCars = '[TELEPHONE REQUEST] GET TELEPHONE CARS REQUEST',
  GetTelephoneRequestCarsFailure = '[TELEPHONE REQUEST] GET TELEPHONE REQUEST CARS FAILURE',
  GetTelephoneRequestCarsSuccess = '[TELEPHONE REQUEST] GET TELEPHONE REQUEST CARS SUCCESS',

  GetTelephoneRequests = '[TELEPHONE REQUEST] GET TELEPHONE REQUESTS',
  GetTelephoneRequestsFailure = '[TELEPHONE REQUEST] GET TELEPHONE REQUESTS FAILURE',
  GetTelephoneRequestsSuccess = '[TELEPHONE REQUEST] GET TELEPHONE REQUESTS SUCCESS',

  UpdateTelephoneRequest = '[TELEPHONE REQUEST] UPDATE TELEPHONE REQUEST',
  UpdateTelephoneRequestFailure = '[TELEPHONE REQUEST] UPDATE TELEPHONE REQUEST FAILURE',
  UpdateTelephoneRequestSuccess = '[TELEPHONE REQUEST] UPDATE TELEPHONE REQUEST SUCCESS',
}

export const TelephoneRequestAction = {
  changeBrands: (brands: ReadonlyArray<string>) =>
    createAction(TelephoneRequestActionType.ChangeBrands, brands),
  changeDealerLocations: (dealerLocations: ReadonlyArray<DealerLocationKey>) =>
    createAction(TelephoneRequestActionType.ChangeDealerLocations, dealerLocations),
  changeListFilter: (param: string, value: TelephoneRequestFilterValues) =>
    createAction(TelephoneRequestActionType.ChangeListFilter, { param, value }),
  changePage: (page: number) => createAction(TelephoneRequestActionType.ChangePage, page),
  changeSearchPhrase: (searchPhrase: string) => createAction(TelephoneRequestActionType.ChangeSearchPhrase, searchPhrase),

  clearTelephoneRequest: () => createAction(TelephoneRequestActionType.ClearTelephoneRequest),

  getTelephoneRequest: (uuid: TelephoneRequestId) => createAction(TelephoneRequestActionType.GetTelephoneRequest, uuid),
  getTelephoneRequestFailure: (error: AjaxError) => createAction(TelephoneRequestActionType.GetTelephoneRequestFailure, error),
  getTelephoneRequestSuccess: (telephoneRequest: TelephoneRequest) =>
    createAction(TelephoneRequestActionType.GetTelephoneRequestSuccess, telephoneRequest),

  getTelephoneRequestCars: (clientUuid: ClientId) => createAction(TelephoneRequestActionType.GetTelephoneRequestCars, clientUuid),
  getTelephoneRequestCarsFailure: (error: AjaxError) => createAction(TelephoneRequestActionType.GetTelephoneRequestCarsFailure, error),
  getTelephoneRequestCarsSuccess: (car: PagedResult<CarInfo>) =>
    createAction(TelephoneRequestActionType.GetTelephoneRequestCarsSuccess, car),

  getNewTelephoneRequestCount: () => createAction(TelephoneRequestActionType.GetNewTelephoneRequestCount),
  getNewTelephoneRequestCountFailure: (error: AjaxError) =>
    createAction(TelephoneRequestActionType.GetNewTelephoneRequestCountFailure, error),
  getNewTelephoneRequestCountSuccess: (count: TelephoneRequestCount) =>
    createAction(TelephoneRequestActionType.GetNewTelephoneRequestCountSuccess, count),

  getTelephoneRequests: () => createAction(TelephoneRequestActionType.GetTelephoneRequests),
  getTelephoneRequestsFailure: (error: AjaxError) => createAction(TelephoneRequestActionType.GetTelephoneRequestsFailure, error),
  getTelephoneRequestsSuccess: (telephoneRequests: PagedResult<TelephoneRequest>) =>
    createAction(TelephoneRequestActionType.GetTelephoneRequestsSuccess, telephoneRequests),

  updateTelephoneRequest: (dto: UpdateTelephoneRequestDto) =>
    createAction(TelephoneRequestActionType.UpdateTelephoneRequest, dto),
  updateTelephoneRequestFailure: (error: AjaxError) =>
    createAction(TelephoneRequestActionType.UpdateTelephoneRequestFailure, error),
  updateTelephoneRequestSuccess: (telephoneRequest: TelephoneRequest) =>
    createAction(TelephoneRequestActionType.UpdateTelephoneRequestSuccess, telephoneRequest),
}

export type TelephoneRequestAction = ActionsUnion<typeof TelephoneRequestAction>
