import { Avatar } from 'actff-bo-app/components/Client'
import { UploadFile } from 'actff-bo-app/components/UploadFile'
import { Field } from 'formik'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components/macro'
import { colors, theme } from 'styles'

type ChatConversationContainerProps = {
  readonly offset?: number,
}

type ChatConversationProps = {
  readonly receptionMessage: boolean,
}

type TopBarProps = {
  readonly statusBar?: boolean,
}

export const ChatContainer = styled.div`
  display: grid;
  grid-template-areas:
    'top-bar'
    'status-bar'
    'conversation'
    'new-message'
  ;
  grid-template-rows: 60px 60px auto auto;
`

export const MessageTime = styled.span`
  color: ${colors.dustyGray};
  font-size: 10px;
`

export const MessageDayContainer = styled.div`
  display: grid;
  place-items: center;
`

export const MessageDay = styled.span`
  color: ${colors.dustyGray};
  font-weight: normal;
  padding: 8px 0;
`

export const TopBar = styled.div`
  align-content: center;
  border-top: 1px solid ${theme.chat.conversation.border};
  display: grid;
  grid-area: ${({ statusBar }: TopBarProps) => statusBar ? 'status-bar' : 'top-bar'};
  grid-template-areas: 'client menu';
  height: 60px;
  padding: 12px 32px;
  position: relative;
`

export const TopBarMenu = styled.button`
  align-self: center;
  justify-self: right;
  grid-area: menu;
`

export const TopBarMenuItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`

export const ChatConversationContainer = styled.div`
  border-top: 1px solid ${theme.chat.conversation.border};
  grid-area: conversation;
  max-height: ${({ offset }: ChatConversationContainerProps) => offset ? `calc(100vh - 180px - ${offset}px)` : 'calc(100vh - 240px)'};
  overflow: auto;
  padding: 0 32px;
`

export const ChatConversationItem = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 8px;
  grid-template-columns: ${({ receptionMessage }: ChatConversationProps) => receptionMessage
    ? '22px auto 42px'
    : '42px auto 20px'
  };
  grid-template-areas: ${({ receptionMessage }: ChatConversationProps) => receptionMessage
    ? '\'visibility message avatar\' \'. status .\''
    : '\'avatar message visibility\' \'. status .\''
  };
  justify-content: ${({ receptionMessage }: ChatConversationProps) => receptionMessage
    ? 'end'
    : 'start'
  };
  margin: 16px 0;
`

export const ChatMessage = styled.div`
  background: ${({ receptionMessage }: ChatConversationProps) => receptionMessage
    ? theme.chat.conversation.background.receptionMessage
    : theme.chat.conversation.background.clientMessage
  };
  border-radius: 4px;
  color: ${({ receptionMessage }: ChatConversationProps) => receptionMessage
    ? theme.chat.conversation.color.receptionMessage
    : theme.chat.conversation.color.clientMessage};
  display: grid;
  grid-area: message;
  justify-self: ${({ receptionMessage }: ChatConversationProps) => receptionMessage ? 'right' : 'left'};
  letter-spacing: 0.05em;
  max-width: 100%;
  overflow: auto;
  padding: 10px 16px;
`

export const ChatAvatar = styled(Avatar)`
  display: grid;
  grid-area: avatar;
  justify-self: center;
`

export const ChatMessageStatus = styled.span`
  display: grid;
  font-size: 10px;
  grid-area: status;
  justify-self: ${({ receptionMessage }: ChatConversationProps) => receptionMessage ? 'right' : 'left'};
`

export const ChatNewMessageForm = styled.form`
  align-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  grid-template-columns: auto 100px;
  max-height: 100px;
`

export const ChatNewMessageInput = styled(TextareaAutosize)`
  border: none;
  font-size: 13px;
  padding: 5px 5px 5px 10px;
  resize: none;
  width: calc(100% - 40px);

  &:focus {
    outline: none;
  }
`

export const ChatNewMessageContainer = styled.div`
  border: 1px solid ${colors.solitude};
  position: relative;
  width: 100%;
`

export const FieldStyled = styled(Field)`
  cursor: pointer;
  bottom: 5px;
`

export const ChatUploadAttachment = styled(UploadFile)`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -7px;
`

export const ChatConversationCloseMessageContainer = styled.div`
  border-top: 1px solid ${colors.solitude};
  border-bottom: 1px solid ${colors.solitude};
  display: inline-block;
  font-size: 10px;
  margin: 10px 0;
  padding: 16px 0;
  width: 100%;
`

export const ChatSendMessageContainer = styled.div`
  align-self: end;
  grid-area: new-message;
  padding: 10px 32px;
`

export const ChatAttachmentsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`
