import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { AdditionalDescriptionContainer } from '../Styled'

export const RoadTestAdditionalInformation: FC = () => {
  const { t } = useTranslation()

  return (
    <AdditionalDescriptionContainer>
      <Icon type={IconType.Bulb} />
      {t('carView.inspection.form.roadTest.description')}
    </AdditionalDescriptionContainer>
  )
}
