import { CarInfo } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { NominalString } from 'actff-bo-lib/global'
import { Map } from 'immutable'

export enum OfferRequestStatus {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export enum OfferRequestType {
  FINANCE = 'FINANCE',
  INSURANCE = 'INSURANCE',
  RENEW_INSURANCE = 'RENEW_INSURANCE',
}

export const OfferRequestTypeMap: Map<OfferRequestType, string> = Map([
  [OfferRequestType.FINANCE, 'offerRequest.type.buyNewCar'],
  [OfferRequestType.INSURANCE, 'offerRequest.type.insurance'],
  [OfferRequestType.RENEW_INSURANCE, 'offerRequest.type.renewInsurance'],
]) as Map<OfferRequestType, string>

export type OfferRequest = {
  readonly uuid: OfferRequestId,
  readonly car: CarInfo,
  readonly client: Client,
  readonly requestTime: Date,
  readonly status: OfferRequestStatus,
  readonly type: OfferRequestType,
  readonly answers: ReadonlyArray<OfferRequestAnswer>,
  readonly locationKey: DealerLocationKey,
}

export type OfferRequestAnswer = {
  readonly question: string,
  readonly agreed: boolean,
}

export type OfferRequestId = NominalString<OfferRequest>

export type UpdateOfferRequestDto = Pick<OfferRequest, 'uuid' | 'status'>

export type OfferRequestCount = {
  readonly requestCount: number,
}

export type OfferRequestFilterValues = OfferRequestStatus | OfferRequestType
