import { FormCell } from 'actff-bo-app/components/Form'
import { Form } from 'formik'
import styled from 'styled-components'

export const Container = styled(Form)`
  display: grid;
  grid-template-areas:
    'changing-header .'
    'changing .'
    'change-type .'
    'tires-header .'
    'tire-type last-change'
    'storing-header .'
    'storing .'
    'storage-date storage-number'
    'damaged .'
  ;
  margin: 28px 32px 0 0;
`

export const ChangingHeader = styled(FormCell)`
  grid-area: changing-header;
`

export const Changing = styled(FormCell)`
  grid-area: changing;
`

export const ChangeType = styled(FormCell)`
  grid-area: change-type;

  div {
    display: inline-block;
  }
`

export const TiresHeader = styled(FormCell)`
  grid-area: tires-header;
`

export const TireType = styled(FormCell)`
  grid-area: tire-type;
`

export const LastChange = styled(FormCell)`
  grid-area: last-change;
`

export const StoringHeader = styled(FormCell)`
  grid-area: storing-header;
`

export const Storing = styled(FormCell)`
  grid-area: storing;
`

export const StorageDate = styled(FormCell)`
  grid-area: storage-date;
`

export const StorageNumber = styled(FormCell)`
  grid-area: storage-number;
`

export const Damaged = styled(FormCell)`
  grid-area: damaged;
`
