import { either } from 'fp-ts'
import { Lazy, pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { UseQueryResult } from 'react-query'

type ValidationError = {
  readonly type: 'ValidationError'
  readonly errors: t.Errors,
}
const validationError = (errors: t.Errors): ValidationError => ({
  errors,
  type: 'ValidationError',
})

type RequestError<T> = {
  readonly type: 'RequestError'
  readonly payload: T,
}
const requestError = <T>(payload: T): RequestError<T> => ({
  payload,
  type: 'RequestError',
})

export const foldQuery =
  <O, OO>(codec: t.Type<O, OO>) =>
    <T, E, R>(
      onLoading: Lazy<R>,
      onError: (e: RequestError<E> | ValidationError) => R,
      onSuccess: (d: O) => R,
    ) => (query: UseQueryResult<T, E>) => {
      if (query.isLoading) {
        return onLoading()
      }

      if (query.isError) {
        return onError(requestError(query.error))
      }

      if (query.isSuccess) {
        return pipe(
          query.data,
          codec.decode,
          either.bimap(validationError, onSuccess),
          either.getOrElse(onError),
        )
      }

      return
    }
