import { createGlobalStyle } from 'styled-components'

import { colors } from './colors'

export const ToastStyles = createGlobalStyle`
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    line-height: normal;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 0;
    width: auto;
  }

  .toast {
    border-radius: 5px !important;
    cursor: default !important;
    padding: 12px 20px 20px !important;
  }

  .toast--success {
    background: ${colors.shamrock} !important;
  }

  .toast--error {
    background: ${colors.flamingoRed} !important;
  }

  .toast--info {
    background: ${colors.midnightBlue} !important
  }

  .toast--warn {
    background: ${colors.kumera} !important
  }
`
