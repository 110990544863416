import { D2DCities } from 'actff-bo-app/Admin'
import { Assistance } from 'actff-bo-app/Admin/Brands/Assistance/Assistance'
import { BrakeLiquids } from 'actff-bo-app/Admin/Brands/BrakeLiquids'
import { CarLiquids } from 'actff-bo-app/Admin/Brands/CarLiquids/CarLiquids'
import { CarModels } from 'actff-bo-app/Admin/Brands/CarModels'
import { CarVersions } from 'actff-bo-app/Admin/Brands/CarVersions'
import { DealerApplicationLinks } from 'actff-bo-app/Admin/DealerLocations/ApplicationLinks'
import { Brands } from 'actff-bo-app/Admin/DealerLocations/Brands'
import { ContactDetails } from 'actff-bo-app/Admin/DealerLocations/ContactDeatils'
import { OpeningHours } from 'actff-bo-app/Admin/DealerLocations/OpeningHours'
import { VisitAvailability } from 'actff-bo-app/Admin/DealerLocations/VisitAvailability'
import { Holidays, Insurers, InsurersQuestions, RegulationsAndApprovals, Users } from 'actff-bo-app/Admin/Dealership'
import { EmployeeSchedule } from 'actff-bo-app/Admin/EmployeeSchedule/EmployeeSchedule'
import { AdminEquipment } from 'actff-bo-app/Admin/TechnicalCondition/Equipment'
import { AdminTesterVIDA } from 'actff-bo-app/Admin/TechnicalCondition/TesterVIDA/AdminTesterVIDA'
import { Login } from 'actff-bo-app/Auth/Login/Login'
import {
  CalendarElectronicsEngineers,
  CalendarMechanics,
  CalendarReplacementCars,
  CalendarServiceAdvisors,
} from 'actff-bo-app/Calendar'
import { QuickCampaign } from 'actff-bo-app/Campaign'
import { CarList } from 'actff-bo-app/Cars/CarList'
import { CarNew } from 'actff-bo-app/Cars/CarNew'
import {
  CarViewFinance,
  CarViewInsurance,
  CarViewService,
  CarViewTires,
  Inspection,
  InspectionList,
} from 'actff-bo-app/Cars/CarView'
import { CarViewBasicData } from 'actff-bo-app/Cars/CarView/CarViewBasicData'
import { CarViewEquipment } from 'actff-bo-app/Cars/CarView/CarViewEquipment'
import { ChatView } from 'actff-bo-app/Chat/ChatView'
import { ClientNew } from 'actff-bo-app/Clients'
import { ClientList } from 'actff-bo-app/Clients/ClientList'
import { Approvals, BasicData, ClientViewCars, Preferences } from 'actff-bo-app/Clients/ClientView'
import { Opportunities } from 'actff-bo-app/Clients/ClientView/Opportunities'
import * as CrmInsurance from 'actff-bo-app/Crm/Insurance'
import * as CrmService from 'actff-bo-app/Crm/Service'
import * as CrmTrade from 'actff-bo-app/Crm/Trade'
import { Dashboard } from 'actff-bo-app/Dashboard'
import { Main } from 'actff-bo-app/Main'
import { NdcCars } from 'actff-bo-app/NdcCars'
import { OfferRequestList } from 'actff-bo-app/OfferRequest/OfferRequestList'
import { OfferRequestView } from 'actff-bo-app/OfferRequest/OfferRequestView'
import { ServiceRequestList } from 'actff-bo-app/ServiceRequest/ServiceRequestList'
import { ServiceRequestView } from 'actff-bo-app/ServiceRequest/ServiceRequestView'
import { TelephoneRequestList } from 'actff-bo-app/TelephoneRequest/TelephoneRequestList'
import { TelephoneRequestView } from 'actff-bo-app/TelephoneRequest/TelephoneRequestView'
import { TestDriveRequestList, TestDriveRequestView } from 'actff-bo-app/TestDriveRequest'
import { ComponentType as AppComponentType } from 'actff-bo-lib/menu'
import { ComponentType } from 'react'

export const ComponentMap: Map<AppComponentType, ComponentType> = new Map([
  [AppComponentType.AdminBrakeLiquids, BrakeLiquids],
  [AppComponentType.AdminOilsAndEngines, CarLiquids],
  [AppComponentType.AdminAssistance, Assistance],
  [AppComponentType.AdminCarModels, CarModels],
  [AppComponentType.AdminCarVersions, CarVersions],
  [AppComponentType.AdminInsurers, Insurers],
  [AppComponentType.AdminInsurersQuestions, InsurersQuestions],
  [AppComponentType.AdminRegulationsAndApprovals, RegulationsAndApprovals],
  [AppComponentType.AdminHolidays, Holidays],
  [AppComponentType.AdminUsers, Users],
  [AppComponentType.AdminOpeningHours, OpeningHours],
  [AppComponentType.AdminVisitAvailability, VisitAvailability],
  [AppComponentType.AdminContactDetails, ContactDetails],
  [AppComponentType.AdminD2DCities, D2DCities],
  [AppComponentType.AdminLocationsBrands, Brands],
  [AppComponentType.AdminApplicationLinks, DealerApplicationLinks],
  [AppComponentType.AdminEmployeeSchedule, EmployeeSchedule],
  [AppComponentType.AdminEquipment, AdminEquipment],
  [AppComponentType.AdminTesterVIDA, AdminTesterVIDA],

  [AppComponentType.CarList, CarList],
  [AppComponentType.CarNew, CarNew],
  [AppComponentType.CarViewBasicData, CarViewBasicData],
  [AppComponentType.CarViewService, CarViewService],
  [AppComponentType.CarViewFinance, CarViewFinance],
  [AppComponentType.CarViewInsurance, CarViewInsurance],
  [AppComponentType.CarViewInspection, Inspection],
  [AppComponentType.CarViewEquipment, CarViewEquipment],
  [AppComponentType.CarViewInspections, InspectionList],
  [AppComponentType.CarViewTires, CarViewTires],

  [AppComponentType.CrmTradeOpportunityLists, CrmTrade.TradeOpportunityLists],
  [AppComponentType.CrmTradePurchaseOpportunity, CrmTrade.OpportunityPurchaseView],
  [AppComponentType.CrmTradeSaleOpportunity, CrmTrade.OpportunitySaleView],

  [AppComponentType.CrmInsuranceOpportunityLists, CrmInsurance.OpportunityLists],
  [AppComponentType.CrmInsuranceOpportunity, CrmInsurance.OpportunityView],
  [AppComponentType.CrmInsuranceReport, CrmInsurance.OpportunityReport],

  [AppComponentType.CrmServiceOpportunityLists, CrmService.OpportunityLists],
  [AppComponentType.CrmServiceOpportunityManual, CrmService.OpportunityManual],
  [AppComponentType.CrmServiceOpportunityView, CrmService.OpportunityView],

  [AppComponentType.CalendarServiceAdvisors, CalendarServiceAdvisors],
  [AppComponentType.CalendarMechanics, CalendarMechanics],
  [AppComponentType.CalendarElectronicsEngineers, CalendarElectronicsEngineers],
  [AppComponentType.CalendarReplacementCars, CalendarReplacementCars],

  [AppComponentType.ChatView, ChatView],

  [AppComponentType.ClientNew, ClientNew],
  [AppComponentType.ClientList, ClientList],
  [AppComponentType.ClientViewBasicData, BasicData],
  [AppComponentType.ClientViewCars, ClientViewCars],
  [AppComponentType.ClientViewApprovals, Approvals],
  [AppComponentType.ClientViewOpportunities, Opportunities],
  [AppComponentType.ClientViewPreferences, Preferences],

  [AppComponentType.Dashboard, Dashboard],

  [AppComponentType.Login, Login],

  [AppComponentType.Main, Main],

  [AppComponentType.NdcCars, NdcCars],

  [AppComponentType.OfferRequestList, OfferRequestList],
  [AppComponentType.OfferRequestView, OfferRequestView],
  [AppComponentType.QuickCampaign, QuickCampaign],

  [AppComponentType.ServiceRequestView, ServiceRequestView],
  [AppComponentType.ServiceRequestList, ServiceRequestList],

  [AppComponentType.TelephoneRequestView, TelephoneRequestView],
  [AppComponentType.TelephoneRequestList, TelephoneRequestList],

  [AppComponentType.TestDriveRequestView, TestDriveRequestView],
  [AppComponentType.TestDriveRequestList, TestDriveRequestList],
])
