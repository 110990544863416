import { BtnType } from 'actff-bo-app/components/Button'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { Loader } from 'actff-bo-app/components/Loader'
import { getDealershipInsurers } from 'actff-bo-lib/admin/dealership'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { ValueForInsuranceCalculation } from 'actff-bo-lib/car'
import {
  ContactHistoryAttempt,
  ContactHistoryOutcome,
  CrmInsuranceAction,
  FormSection,
  OpportunityInsurance,
  OpportunityInsuranceId,
  OpportunityOfferDecision,
  OpportunityResult,
  selectCurrentOpportunity,
  selectCurrentOpportunityErrors,
} from 'actff-bo-lib/crm/insurance'
import { cleanOpportunityValues } from 'actff-bo-lib/crm/insurance/utils'
import { useScrollToErrorField } from 'actff-bo-lib/form'
import { Paths, selectMenuDisabled } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { Location } from 'history'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, Prompt, RouteComponentProps, useLocation, withRouter } from 'react-router-dom'
import { useBeforeUnload } from 'react-use'

import { useOpportunityViewerStatus } from './opportunity-viewer-status'
import { OpportunityFooter } from './OpportunityFooter'
import { OpportunityHeader } from './OpportunityHeader'
import { sectionExpanded } from './section-expanded'
import { SectionCar } from './SectionCar'
import { SectionContactHistory } from './SectionContactHistory'
import { SectionContactPerson } from './SectionContactPerson'
import { SectionDriver } from './SectionDriver'
import { SectionNewPolicy } from './SectionNewPolicy'
import { SectionOffer } from './SectionOffer'
import { SectionOldPolicy } from './SectionOldPolicy'
import { SectionOwner } from './SectionOwner'
import { SectionPayments } from './SectionPayments'
import { SectionPolicyConfirmation } from './SectionPolicyConfirmation'
import { SectionProcess } from './SectionProcess'
import { FormContent } from './Styled'

type OpportunityViewProps = {
  readonly opportunityId?: OpportunityInsuranceId,
}

const testId = 'crm-insurance__view--'

const handleGoBackClick = () => {
  history.push(Paths.CrmInsuranceOpportunityList)
}

const formSections: ReadonlyArray<FormSection> = [
  'car', 'confirmation', 'contactHistory', 'contactPerson', 'driver', 'newPolicy', 'offer', 'oldPolicy', 'owner', 'payments', 'process',
]

const enterKeyCode = 13

const preventSubmitByEnterKey = (event: React.KeyboardEvent<HTMLFormElement>) => {
  const element = event.nativeEvent.target as HTMLElement

  if (element.tagName !== 'TEXTAREA' && event.keyCode === enterKeyCode) {
    event.preventDefault()
  }
}

const isRedirectingAfterManualOpportunitySave = (from: string, to: string) =>
  from === Paths.CrmInsuranceOpportunityNew && matchPath(to, { path: Paths.CrmInsuranceOpportunity })

const OpportunityViewComponent: React.FC<RouteComponentProps<OpportunityViewProps>> = ({ ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()

  const { data: insurers } = useQuery(QueryKeys.GET_INSURERS, async () =>
      await getDealershipInsurers(),
    { retry: false, refetchOnWindowFocus: false },
  )

  const { opportunityId } = props.match.params
  const { data: opportunity, loading } = useSelector(selectCurrentOpportunity)
  const opportunityErrors = useSelector(selectCurrentOpportunityErrors)
  const menuDisabled = useSelector(selectMenuDisabled)
  const { isEditedByOtherUser, userName: editingUserName } = useOpportunityViewerStatus(opportunityId)

  const [readOnly, setReadOnly] = useState(false)

  const { control, errors, getValues, formState, handleSubmit, register, reset, setError, setValue, trigger, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'all',
  })

  const formProps = { control, errors, formState, getValues, register, reset, setValue, watch }

  useScrollToErrorField(errors)

  const retriggerValidation = () => { void trigger() }
  const retriggerFieldValidation = (name: string) => { void trigger(name) }
  const shouldSaveButtonBeDisabled = () => !formState.isDirty
  const getCalculationBaseValue = (calculationBase: ValueForInsuranceCalculation) => calculationBase || ValueForInsuranceCalculation.GROSS

  const onSubmit = (opportunityValues: OpportunityInsurance) => {
    const currentlyEditedOpportunityId = opportunityId ?? opportunity?.process?.uuid

    setReadOnly(true)
    dispatch(CrmInsuranceAction.saveOpportunity(currentlyEditedOpportunityId, cleanOpportunityValues(opportunityValues)))
  }

  const handleEdit = () => setReadOnly(false)

  const installmentCount = watch('newPolicy.installmentCount')
  const opportunityResult = watch('process.result')
  const isContactSucceed = (watch('contactHistory.attempts') || [])
    .filter(({ outcome }: ContactHistoryAttempt) => outcome === ContactHistoryOutcome.SUCCESS)
    .length > 0
  const isIssuePolicySelected = watch('offer.clientsDecision') === OpportunityOfferDecision.ISSUE_POLICY
  const isClientsDecisionSelected = !!watch('offer.clientsDecision')
  const isSuccessOrFailureSelected = opportunityResult === OpportunityResult.SUCCESS || opportunityResult === OpportunityResult.FAILURE

  const isSectionExpanded = (section: FormSection) => sectionExpanded(opportunityId, opportunity?.process?.status).includes(section)

  const shouldDisplayUnsavedChangesAlert = () => !shouldSaveButtonBeDisabled() || menuDisabled

  React.useEffect(() => {
    opportunityId
      ? dispatch(CrmInsuranceAction.getOpportunity(opportunityId))
      : dispatch(CrmInsuranceAction.clearOpportunity())
  }, [dispatch, opportunityId])

  React.useEffect(() => {
    opportunity && opportunity?.process?.uuid === opportunityId
      ? reset({
        ...opportunity,
        newPolicy: {
          ...opportunity.newPolicy,
          calculationBase: getCalculationBaseValue(opportunity.newPolicy.calculationBase),
        },
        offer: {
          ...opportunity.offer,
          calculationBase: getCalculationBaseValue(opportunity.offer.calculationBase),
        },
        oldPolicy: {
          ...opportunity.oldPolicy,
          calculationBase: getCalculationBaseValue(opportunity.oldPolicy.calculationBase),
        },
      })
      : reset({
        ...formSections.reduce((initialObj, current) => ({ ...initialObj, [current.toString()]: {}}), {}),
        newPolicy: { calculationBase: ValueForInsuranceCalculation.GROSS },
        offer: { calculationBase: ValueForInsuranceCalculation.GROSS },
        oldPolicy: { calculationBase: ValueForInsuranceCalculation.GROSS },
      })
  }, [opportunity, opportunityId, reset])

  React.useEffect(() => {
    opportunityErrors && Object.entries(opportunityErrors).map(
      ([field, message]) => setError(field, { message: `caption.error.${message}`, type: 'response' }),
    )
  }, [opportunityErrors, setError])

  React.useEffect(() => {
    formState.isSubmitted && retriggerValidation()
  }, [isIssuePolicySelected])

  useBeforeUnload(shouldDisplayUnsavedChangesAlert(), `${t('caption.unsavedChanges')}`)

  if (loading) { return <Loader /> }

  return (
    <>
      <Prompt
        when={shouldDisplayUnsavedChangesAlert()}
        message={(to: Location) =>
          isRedirectingAfterManualOpportunitySave(location.pathname, to.pathname) ? true : `${t('caption.unsavedChanges')}`
        }
      />
      <form onKeyDown={preventSubmitByEnterKey} onSubmit={handleSubmit(onSubmit)}>
        <OpportunityHeader opportunityId={opportunityId} isEditedByOtherUser={isEditedByOtherUser} editingUserName={editingUserName} />
        <FormContent>
          <SectionProcess
            expanded={isSectionExpanded('process')}
            uuid={opportunityId}
            process={opportunity?.process}
            {...formProps}
            testId={testId}
            readOnly={readOnly}
          />
          <SectionCar expanded={isSectionExpanded('car')} {...formProps} testId={testId} readOnly={readOnly}/>
          <SectionContactHistory expanded={isSectionExpanded('contactHistory')} {...formProps} testId={testId} readOnly={readOnly} />
          <SectionOffer
            expanded={isSectionExpanded('offer')}
            isContactSucceed={isContactSucceed}
            {...formProps}
            testId={testId}
            readOnly={readOnly}
          />
          <SectionContactPerson
            {...formProps}
            contactPerson={opportunity?.contactPerson}
            expanded={isSectionExpanded('contactPerson')}
            testId={testId}
            readOnly={readOnly}
          />
          <SectionDriver
            expanded={isSectionExpanded('driver')}
            driverPhone={opportunity?.driver.phone}
            {...formProps}
            testId={testId}
            readOnly={readOnly}
          />
          <SectionOwner expanded={isSectionExpanded('owner')} {...formProps} testId={testId} readOnly={readOnly} />
          <SectionOldPolicy
            expanded={isSectionExpanded('oldPolicy')}
            {...formProps}
            insurers={insurers}
            oldPolicy={opportunity?.oldPolicy}
            testId={testId}
            readOnly={readOnly}
          />
          <SectionNewPolicy
            {...formProps}
            expanded={isSectionExpanded('newPolicy')}
            installmentCount={installmentCount}
            insurers={insurers}
            isClientsDecisionSelected={isClientsDecisionSelected}
            isIssuePolicySelected={isIssuePolicySelected}
            isSuccessOrFailureSelected={isSuccessOrFailureSelected}
            onValidate={retriggerFieldValidation}
            setValue={setValue}
            testId={testId}
            readOnly={readOnly}
          />
          <SectionPayments
            expanded={isSectionExpanded('payments')}
            {...formProps}
            insurers={insurers}
            installmentCount={installmentCount}
            testId={testId}
            readOnly={readOnly}
          />
          <SectionPolicyConfirmation
            {...formProps}
            expanded={isSectionExpanded('confirmation')}
            installmentCount={installmentCount}
            testId={testId}
            readOnly={readOnly}
          />
        </FormContent>
        <FixedFooter>
          <OpportunityFooter
            onCancel={handleGoBackClick}
            onEdit={handleEdit}
            onSave={retriggerValidation}
            saveButtonType={BtnType.Submit}
            isSaveDisabled={shouldSaveButtonBeDisabled()}
            isEditMode={!readOnly}
            isEditedByOtherUser={isEditedByOtherUser}
            testId={testId}
          />
        </FixedFooter>
      </form>
    </>
  )
}

// @ts-ignore: mismatch with react-router types, which not handle functional components
export const OpportunityView = withRouter(OpportunityViewComponent)
