import { FormValues } from 'actff-bo-lib/form'
import { FormRange, SelectOption } from 'actff-bo-lib/global'

export type ClientPreferencesForm = {
  readonly brands: FormValues,
  readonly equipment: ReadonlyArray<string>,
  readonly fuelTypes: FormValues,
  readonly mileage: FormRange<SelectOption<string | null>>,
  readonly models: FormValues,
  readonly modelVersions: FormValues,
  readonly price: FormRange<SelectOption<string | null>>,
  readonly productionYear: FormRange<SelectOption<string | null>>,
  readonly purchaseDate: FormRange<Date>,
  readonly transmissions: FormValues,
  readonly version: string,
}

export const defaultValues: ClientPreferencesForm = {
  brands: {},
  equipment: [],
  fuelTypes: {},
  mileage:  {
    from: null,
    to: null,
  },
  modelVersions: {},
  models: {},
  price: {
    from: null,
    to: null,
  },
  productionYear: {
    from: null,
    to: null,
  },
  purchaseDate: {
    from: null,
    to: null,
  },
  transmissions: {},
  version: '',
}
