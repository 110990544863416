import { noCentsDivider } from 'actff-bo-lib/currency'
import * as React from 'react'
import { Controller, UseFormMethods, ValidationRules } from 'react-hook-form'

type Props = Pick<UseFormMethods, 'control'>
  & React.InputHTMLAttributes<HTMLInputElement>
  & { readonly name: string, readonly rules?: ValidationRules }

const integerLimit = 9999999

export const MoneyInput: React.FC<Props> = ({ control, name, rules, ...props }) => {
  const handleChange = (onChange: (value: number | string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = Number(event.currentTarget.value)

    if (event.currentTarget.value === '') {
      onChange('')

      return
    }

    if (currentValue < integerLimit) {
      return onChange((currentValue * noCentsDivider).toFixed(0))
    }

    return
  }

  const getValue = (value?: number | string) => {
    const numberValue = Number(value)

    if (isNaN(numberValue) || value === '') {
      return ''
    }

    return numberValue / noCentsDivider
  }

  return (
    <Controller
      render={({onChange, value}) => (
        <input
          onChange={handleChange(onChange)}
          type='number'
          step='0.01'
          value={getValue(value)}
          {...props}
        />
      )}
      control={control}
      name={name}
      rules={rules}
    />
  )
}
