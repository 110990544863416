import { ContactApproval, getFullName } from 'actff-bo-lib/client'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type ApprovalChanger = {
  readonly approval: ContactApproval,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ApprovalChanger: FC<{ readonly approval: ContactApproval }> = ({ approval }) => {
  const { t } = useTranslation()

  return (
    <>
      {approval.client ? getFullName(approval.client) : null}
      {approval.user ? `${t('clientView.approvals.reception')}: ${getFullName(approval.user)}` : null}
    </>
  )
}
