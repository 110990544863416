import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'

import { IconMap } from './IconTypes'

type IconProps = {
  readonly type: IconType,
  readonly className?: string,
  readonly onClick?: (event: React.MouseEvent) => void,
}

export const Icon: React.FC<IconProps> = ({ className, onClick, type }) =>
  <img color='red' alt={`icon-${type}`} className={className} onClick={onClick} src={IconMap.get(type)} />
