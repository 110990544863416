import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

export enum I18nActionType {
  Init = '[I18N] INIT',
  InitFailure = '[I18N] INIT FAILURE',
  InitSuccess = '[I18N] INIT SUCCESS',
}

export const I18nAction = {
  init: () => createAction(I18nActionType.Init),
  initFailure: (error: AjaxError) => createAction(I18nActionType.InitFailure, error),
  initSuccess: () => createAction(I18nActionType.InitSuccess),
}

export type I18nAction = ActionsUnion<typeof I18nAction>
