import { FormattedDate } from 'actff-bo-app/components/DateTime'
import * as React from 'react'

type ApprovalTimeProps = {
  readonly date: Date,
}

export const ApprovalTime: React.FC<ApprovalTimeProps> = ({ date }) => (
  <span><FormattedDate date={date} format='HH:mm' /></span>
)
