import { FilterType, OpportunitySourceName, PaymentsReport, PaymentsReportSummary, ReportColumns } from 'actff-bo-lib/crm/insurance'
import { roundToTwoDecimalPlaces } from 'actff-bo-lib/currency'
import React from 'react'

import { ReportInput } from './ReportInput'
import { ReportInsurerSelect } from './ReportInsurerSelect'
import { ReportMultiSelect } from './ReportMultiSelect'

/* eslint-disable react/display-name */
export const PaymentsReportColumns: ReportColumns<PaymentsReport, PaymentsReportSummary> = [
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportInsurerSelect onChange={onChange} value={value} />
    ),
    filterType: FilterType.Equal,
    key: 'insurer',
    name: 'insurer',
  },
  { key: 'sellType', name: 'sellType' },
  {
    filterRenderer: ({ onChange, value }) => (<ReportInput onChange={onChange} value={value} />),
    key: 'number',
    name: 'number',
    summaryFormatter: ({ row: { totalNumber } }) => (<>{totalNumber}</>),
  },
  { key: 'issued', name: 'issued', hideOnPrint: true },
  { key: 'driverFullName', name: 'driverFullName', width: 170 },
  { key: 'registrationNumber', name: 'registrationNumber' },
  { key: 'date', name: 'date' },
  {
    cellClass: 'money-value',
    formatter: ({ row: { amount }}) => (<>{roundToTwoDecimalPlaces(+amount)}</>),
    groupFormatter: ({ childRows }) => (
      <div className='money-value'>
        {roundToTwoDecimalPlaces(childRows.reduce((prev, { amount }) => prev + Number(amount), 0))} zł
      </div>
    ),
    key: 'amount',
    name: 'amount',
    summaryCellClass: 'money-value',
    summaryFormatter: ({ row: { totalAmount } }) => (<>{roundToTwoDecimalPlaces(totalAmount)} zł</>),
    width: 90,
  },
  { key: 'receiver', name: 'receiver' },
  { key: 'bankAccount', name: 'bankAccount', width: 180 },
  {
    filterRenderer: ({ onChange, value }) => (
      <ReportMultiSelect
        onChange={onChange}
        value={value}
        transKey='crmInsurance.insuranceSourceName.'
        options={Object.keys(OpportunitySourceName)}
      />
    ),
    filterType: FilterType.Multi,
    key: 'sourceName',
    name: 'sourceName',
  },
]

export const PaymentsReportSummaryRowsFn = (rows: ReadonlyArray<PaymentsReport>): ReadonlyArray<PaymentsReportSummary> => [{
  id: 'total_0',
  totalAmount: rows.reduce((prev, { amount }) => prev + Number(amount), 0),
  totalNumber: rows.length,
}]

export const PaymentsReportFilters = {
  insurer: '',
  number: '',
}
