import { ErrorMessage } from '@hookform/error-message'
import { BtnType } from 'actff-bo-app/components/Button'
import { DatePickerInput, TimePicker } from 'actff-bo-app/components/DateTime'
import { Select } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Label } from 'actff-bo-app/components/Label'
import { emptyShift, Shift } from 'actff-bo-lib/admin/employee-scheduler'
import { DatePickerInputPosition, formatLocalTimeToDate } from 'actff-bo-lib/date'
import { SelectOption } from 'actff-bo-lib/global'
import { addDays, addHours, endOfToday, isAfter, isBefore, isEqual, startOfToday, startOfYesterday, subHours } from 'date-fns'
import React, { FC } from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { ArrayField } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import { LinkButton } from 'styles'

import {
  AddButtonContainer,
  DateContainer,
  DayContainerNumber,
  DayFormContainer as ElementContainer,
  RowContainer,
  Separator,
  ShiftContainer,
  ShiftOptionContent,
  TimeContainer,
} from './Styled'

type Props = Pick<UseFormMethods, 'control' | 'watch' | 'getValues' | 'setValue' | 'errors'> & {
  readonly shifts?: ReadonlyArray<Shift>,
  readonly fields?: ReadonlyArray<Partial<ArrayField<Record<string, unknown>, 'id'>>>,
  readonly append: (value: object) => void,
  readonly isContainedInRepeatCycle: boolean,
}

const halfHour = 0.5
const hoursOffset = 2

const mapShiftToSelect = (shift: Shift): SelectOption<Shift> => ({
  label: shift.name,
  value: shift,
})

const mapShiftsToSelect = (shifts?: ReadonlyArray<Shift>): ReadonlyArray<SelectOption<Shift>> =>
  shifts
    ? shifts.map(mapShiftToSelect)
    : []

export const Days: FC<Props> = ({ control, setValue, getValues, errors, shifts, fields, append, isContainedInRepeatCycle }) => {
  const { t } = useTranslation()

  const dates = getValues().elements

  const unavailableDaysFrom = (index: number) => {
    if (index === 0) {
      return startOfYesterday()
    }

    return dates && addDays(dates[index - 1]?.to, 1)
  }

  const unavailableDaysTo = (index: number) => dates && dates[index]?.from

  const disableDaysTo = (index: number) => {
    if (dates) {
      return dates[index]?.from === undefined
    }

    return false
  }

  const unavailableHoursFrom = (index: number) => {
    if (dates) {
      return subHours(dates[index]?.hoursTo, halfHour)
    }

    return endOfToday()
  }

  const unavailableHoursTo = (index: number) => {
    if (dates) {
      return addHours(dates[index]?.hoursFrom, halfHour)
    }

    return endOfToday()
  }

  const dateValidation = (isValid: boolean, errorCaption: string) => {
    if (!isValid) {
      return errorCaption
    }

    if (!isContainedInRepeatCycle) {
      return 'caption.error.exceedsRepeatCycle'
    }

    return undefined
  }

  const fromDateValidation = (index: number) => (fromDate: Date) => {
    if (!dates) {
      return undefined
    }

    const toDate = dates[index]?.to
    const isBeforeOrEqual = isBefore(fromDate, toDate) || isEqual(subHours(fromDate, hoursOffset), toDate)

    return dateValidation(isBeforeOrEqual, 'caption.error.dateIsAfter')
  }

  const toDateValidation = (index: number) => (toDate: Date) => {
    if (!dates) {
      return undefined
    }

    const fromDate = dates[index]?.from
    const isAfterOrEqual = isAfter(toDate, fromDate) || isEqual(fromDate, subHours(toDate, hoursOffset))

    return dateValidation(isAfterOrEqual, 'caption.error.dateIsBefore')
  }

  return (
    <>
      {fields?.map((_, index) => (
        <ElementContainer key={index}>
          <DayContainerNumber>{index + 1}.</DayContainerNumber>
          <DateContainer>
            <Label>{t('admin.employeeSchedule.form.days')}</Label>
            <RowContainer>
              <div>
                <Controller
                  render={({ onChange, value }) => (
                    <DatePickerInput
                      date={value}
                      onChange={onChange}
                      timeDisabled={true}
                      disabledDays={{ before: unavailableDaysFrom(index) }}
                    />
                  )}
                  control={control}
                  rules={{ required: 'caption.error.required', validate: fromDateValidation(index) }}
                  name={`elements.${index}.from`}
                />
                <ErrorMessage errors={errors} name={`elements.${index}.from`} as={TranslatedErrorMessage} />
              </div>
              <Separator>-</Separator>
              <div>
                <Controller
                  render={({ onChange, value }) => (
                    <DatePickerInput
                      date={value}
                      onChange={onChange}
                      timeDisabled={true}
                      disabledDays={{ before: unavailableDaysTo(index) }}
                      disabled={disableDaysTo(index)}
                      position={DatePickerInputPosition.RIGHT}
                    />
                  )}
                  control={control}
                  rules={{ required: 'caption.error.required', validate: toDateValidation(index) }}
                  name={`elements.${index}.to`}
                />
                <ErrorMessage errors={errors} name={`elements.${index}.to`} as={TranslatedErrorMessage} />
              </div>
            </RowContainer>
          </DateContainer>
          <Label>{t('admin.employeeSchedule.form.shift')}</Label>
          <ShiftContainer>
            <Controller
              render={({ onChange, value }) => {
                const handleShiftChange = (nextValue: SelectOption<Shift>) => {
                  const { from, to } = nextValue.value
                  setValue(`elements.${index}.hoursFrom`, formatLocalTimeToDate(from || ''))
                  setValue(`elements.${index}.hoursTo`, formatLocalTimeToDate(to || ''))

                  onChange(nextValue)
                }

                const formatOptionLabel = ({ value: shift, label }: SelectOption<Shift>) => {
                  if (!shift) {
                    return (
                      <ShiftOptionContent>{t('caption.other')}</ShiftOptionContent>
                    )
                  }

                  const timeFormatLength = 5
                  const from = shift.from?.substring(0, timeFormatLength)
                  const to = shift.to?.substring(0, timeFormatLength)
                  const optionLabel = (from && to) ? `${label} (${from} - ${to})` : t(label)

                  return (
                    <ShiftOptionContent colour={shift.colour}>{optionLabel}</ShiftOptionContent>
                  )
                }

                return (
                  <Select<Shift>
                    onChange={handleShiftChange}
                    options={mapShiftsToSelect(shifts)}
                    formatOptionLabel={formatOptionLabel}
                    defaultValue={mapShiftToSelect(emptyShift)}
                    value={value}
                  />
                )
              }}
              control={control}
              name={`elements.${index}.shift`}
            />
          </ShiftContainer>
          <TimeContainer>
            <Label>{t('admin.employeeSchedule.form.hours')}</Label>
            <RowContainer>
              <div>
                <Controller
                  render={({ onChange, value }) => (
                    <TimePicker
                      date={value}
                      onChange={onChange}
                      minTime={startOfToday()}
                      maxTime={unavailableHoursFrom(index)}
                    />
                  )}
                  control={control}
                  rules={{ required: 'caption.error.required' }}
                  name={`elements.${index}.hoursFrom`}
                />
                <ErrorMessage errors={errors} name={`elements.${index}.hoursFrom`} as={TranslatedErrorMessage} />
              </div>
              <Separator>-</Separator>
              <div>
                <Controller
                  render={({ onChange, value }) => {
                    const handleTimeChange = (param: Date) => {
                      setValue(`elements.${index}.shift`, mapShiftToSelect(emptyShift))
                      onChange(param)
                    }

                    return (
                      <TimePicker
                        date={value}
                        onChange={handleTimeChange}
                        minTime={unavailableHoursTo(index)}
                        maxTime={endOfToday()}
                      />
                    )
                  }}
                  control={control}
                  rules={{ required: 'caption.error.required' }}
                  name={`elements.${index}.hoursTo`}
                />
                <ErrorMessage errors={errors} name={`elements.${index}.hoursTo`} as={TranslatedErrorMessage} />
              </div>
            </RowContainer>
          </TimeContainer>
        </ElementContainer>
      ))
      }
      <AddButtonContainer>
        <LinkButton
          onClick={() => { append({}) }}
          type={BtnType.Button}
        >
          + {t('admin.employeeSchedule.form.addDay')}
        </LinkButton>
      </AddButtonContainer>
    </>
  )
}
