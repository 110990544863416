import { TdWithDelete } from 'actff-bo-app/Admin/Styled'
import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { DealershipInsurer } from 'actff-bo-lib/admin/dealership'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Ref } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly control: Control,
  readonly register: (ref: Ref | null) => void,
  readonly index: number,
  readonly insurer: DealershipInsurer,
  readonly onDelete: (insurerName: string) => void,
}

const Menu = styled.span`
  float: right;
`

const InsurerNameTd = styled.td`
  min-width: 250px;
`

const IsMainContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.label`
  left: 10px;
  bottom: 10px;
`

const InsurerNameFormField = styled.input`
  display: none;
`

export const InsurerRow: FC<Props> = ({ control, register, index, onDelete, insurer }) => {
  const { t } = useTranslation()
  const [menuVisible, setMenuVisible] = useState(false)

  const showMenu = () => {
    setMenuVisible(true)
  }

  const hideMenu = () => {
    setMenuVisible(false)
  }

  const handleStopEvents = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDelete = (insurerName: string) => () => { onDelete(insurerName) }

  return (
    <tr>
      <td onMouseOver={showMenu} onMouseLeave={hideMenu}>{index + 1}</td>
      <InsurerNameTd onMouseOver={showMenu} onMouseLeave={hideMenu}>{insurer.insurerName}</InsurerNameTd>
      <TdWithDelete onMouseOver={showMenu} onMouseLeave={hideMenu}>
        <IsMainContainer>
          <Controller
            render={({ value, onChange }) =>
              <>
                <input
                  checked={value}
                  id={`insurers.${index}.main`}
                  onChange={() => { onChange(!value) }}
                  type='checkbox'
                />
                <Label htmlFor={`insurers.${index}.main`} />
              </>}
            control={control}
            name={`insurers.${index}.main`}
          />
        </IsMainContainer>
        {menuVisible && (
          <Menu
            onMouseOver={handleStopEvents}
            onMouseLeave={hideMenu}
          >
            <ButtonWithIcon
              caption={t('caption.delete')}
              onClick={handleDelete(insurer.insurerName)}
              iconType={IconType.Trash}
              noPadding={true}
              theme={BtnTheme.Link}
            />
          </Menu>
        )}
        <InsurerNameFormField
          name={`insurers.${index}.insurerName`}
          ref={register}
          id={`insurers.${index}.insurerName`}
          type='text'
        />
      </TdWithDelete>
    </tr>
  )
}
