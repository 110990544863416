import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import {
  CarFinance,
  CarFinanceWithCarUuid,
  CarId,
} from '../dto'

export enum CarFinanceActionType {
  GetCarFinance = '[CAR] GET CAR FINANCE',
  GetCarFinanceFailure = '[CAR] GET CAR FINANCE FAILURE',
  GetCarFinanceSuccess = '[CAR] GET CAR FINANCE SUCCESS',

  UpdateCarFinance = '[CAR] UPDATE CAR FINANCE',
  UpdateCarFinanceFailure = '[CAR] UPDATE CAR FINANCE FAILURE',
  UpdateCarFinanceSuccess = '[CAR] UPDATE CAR FINANCE SUCCESS',
}

export const CarFinanceAction = {
  getCarFinance: (carId: CarId) => createAction(CarFinanceActionType.GetCarFinance, carId),
  getCarFinanceFailure: (error: AjaxError) => createAction(CarFinanceActionType.GetCarFinanceFailure, error),
  getCarFinanceSuccess: (carFinance: CarFinance) =>
    createAction(CarFinanceActionType.GetCarFinanceSuccess, carFinance),
  updateCarFinance: (payload: CarFinanceWithCarUuid) => createAction(CarFinanceActionType.UpdateCarFinance, payload),
  updateCarFinanceFailure: (error: AjaxError) => createAction(CarFinanceActionType.UpdateCarFinanceFailure, error),
  updateCarFinanceSuccess: (carService: CarFinance) =>
    createAction(CarFinanceActionType.UpdateCarFinanceSuccess, carService),
}

export type CarFinanceAction = ActionsUnion<typeof CarFinanceAction>
