import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 2fr 5fr;
  grid-row-gap: 20px;
  overflow-x: auto;
`

export const Summary = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  grid-template-columns: 2fr 2fr 4fr;
  padding: 0 32px;
`
export const DetailedDataContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: 20px;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: auto 4fr;
  grid-template-areas:
    'type group-by date'
    'chart chart chart';
  grid-row-gap: 20px;
  padding: 0 32px;

  &.select {
    grid-area: select;
  }
`

export const DetailedDataGroupBy = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-area: group-by;
`

export const DetailedDataDate = styled.div`
  display: grid;
  grid-area: date;
  grid-auto-flow: column;
  grid-column-gap: 10px;
`

export const DetailedDataChart = styled.div`
  grid-area: chart;
`
