import { isString } from 'actff-bo-lib/global'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { FC } from 'react'
import { isError } from 'react-query'
import { useDispatch } from 'react-redux'

type Props = {
  readonly alertId: string,
  readonly body?: unknown,
  readonly type?: ToastActionType,
}

export const Alert: FC<Props> = ({ alertId, body, type = ToastActionType.GET }) => {
  const dispatch = useDispatch()
  const _body: string | undefined = isError(body) ? body.toString() : isString(body) ? body : undefined

  console.error(_body) // tslint:disable-line no-console
  dispatch(displayFailureToast(alertId, type, _body))

  return null
}
