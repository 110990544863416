import styled from 'styled-components'
import { colors } from 'styles'

export const FilterContent = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-row-gap: 10px;
  grid-template-columns: 40px 1fr;
  width: 100%;

  .DayPickerInput {
    width: 100%;

    input {
      width: 100%;
    }
  }
`

export const CheckboxFilterContent = styled.div`
  grid-template-columns: 1fr 40px;
`

export const Label = styled.label`
  color: ${colors.black};
  text-transform: none;
`

export const RatingCheckboxLabel = styled.label`
  bottom: 7px;
`
