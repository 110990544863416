import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import { CarCard } from 'actff-bo-app/components/CarCard'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Loader } from 'actff-bo-app/components/Loader'
import { PagedResult } from 'actff-bo-lib/api'
import { CarId } from 'actff-bo-lib/car'
import { CarInfo } from 'actff-bo-lib/car/dto'
import { IconType } from 'actff-bo-lib/menu/dto'
import {
  OfferRequest,
  OfferRequestAction,
  OfferRequestId,
  OfferRequestStatus,
  OfferRequestTypeMap,
  selectCurrentOfferRequest,
  selectCurrentOfferRequestCars,
  UpdateOfferRequestDto,
} from 'actff-bo-lib/offer-request'
import { State as GlobalState } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import styled from 'styled-components'
import { colors, H2, Header, Small } from 'styles'

import { OfferRequestAnswers } from './OfferRequestAnswer'
import { OfferRequestCars } from './OfferRequestCars'
import { OfferRequestClient } from './OfferRequestClient'

type State = {
  readonly activeCar: CarInfo | null,
}

type StateToProps = {
  readonly cars: PagedResult<CarInfo> | null,
  readonly offerRequest: OfferRequest | null,
}

type DispatchToProps = {
  readonly clearOfferRequest: () => void,
  readonly getOfferRequest: (uuid: OfferRequestId) => void,
  readonly updateOfferRequest: (dto: UpdateOfferRequestDto) => void,
}

type OfferRequestViewParams = {
  readonly offerId: string,
}

type OfferRequestViewProps = StateToProps & DispatchToProps & RouteComponentProps<OfferRequestViewParams> & WithTranslation

const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    'content comments'
    'footer comments';
  border-bottom: 1px solid ${colors.mysticGray};
`

const OfferContent = styled.div`
  padding: 16px 32px;
`

const OfferCars = styled.div`
  display: grid;
  grid-area: content;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'active-car questions questions'
    'car-headers car-headers car-headers'
  ;
  grid-gap: 25px;
  margin-top: 32px;
`

const OfferInternalComments = styled.div`
  border-left: 1px solid ${colors.mysticGray};
  grid-area: comments;
`

const Footer = styled.footer`
  border-top: 1px solid ${colors.mysticGray};
  display: grid;
  grid-area: footer;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  justify-content: end;
  padding: 16px 32px;
`

const First = styled(CarCard)`
  grid-area: active-car;
`

const testId = 'offer-request__'

class OfferRequestViewComponent extends React.Component<OfferRequestViewProps, State> {
  public readonly state: State = {
    activeCar: null,
  }

  public componentDidMount(): void {
    this.props.getOfferRequest(this.getServiceRequestId())
  }

  public componentDidUpdate(prevProps: OfferRequestViewProps): void {
    if (!prevProps.offerRequest && this.props.offerRequest) {
      this.setState({
        activeCar: this.props.offerRequest.car,
      })
    }
  }

  public componentWillUnmount(): void {
    this.props.clearOfferRequest()
  }

  public render(): React.ReactNode {
    const { cars, offerRequest, t } = this.props

    return offerRequest && cars
      ? (
        <>
          <Header>
            <H2 data-testid={`${testId}header`}>{t('offerRequest.header')} - {t(OfferRequestTypeMap.get(offerRequest.type, ''))}</H2>
            <Small data-testid={`${testId}request-time`}><FormattedDate date={offerRequest.requestTime} /></Small>
          </Header>
          <Content>
            <OfferContent>
              <OfferRequestClient client={offerRequest.client} testId={`${testId}client-`} />
              <OfferCars>
                <First
                  active={this.isCarActive(offerRequest.car.uuid)}
                  car={offerRequest.car}
                  numeral={1}
                  testId={`${testId}car-0--`}
                />
                <OfferRequestAnswers answers={offerRequest.answers} testId={`${testId}answers-`} />
                {cars.result.length > 1 && <OfferRequestCars cars={cars} testId={testId} />}
              </OfferCars>
            </OfferContent>
            <OfferInternalComments />
            <Footer>
              {this.renderStatusButtons()}
            </Footer>
          </Content>
        </>
      )
      : <Loader />
  }

  private readonly renderStatusButtons = () => {
    const { offerRequest, t } = this.props
    const { CANCELED, COMPLETED } = OfferRequestStatus

    if (!offerRequest) {
      return null
    }

    const { status } = offerRequest

    return (
      <>
        {status !== COMPLETED &&
          <ButtonWithIcon
            caption={t('offerRequest.status.canceled')}
            disabled={status === CANCELED}
            iconType={IconType.Cross}
            onClick={this.handleStatusButtonClick(CANCELED)}
            testId={`${testId}button--canceled`}
          />
        }
        {status !== CANCELED &&
          <ButtonWithIcon
            caption={t('offerRequest.status.completed')}
            disabled={status === COMPLETED}
            iconType={IconType.CheckMark}
            onClick={this.handleStatusButtonClick(COMPLETED)}
            testId={`${testId}button--completed`}
          />
        }
      </>
    )
  }

  private readonly handleStatusButtonClick = (status: OfferRequestStatus) => () => {
    const { offerRequest } = this.props

    if (offerRequest) {
      this.props.updateOfferRequest({ status, uuid: offerRequest.uuid })
    }
  }

  private readonly isCarActive = (carUuid: CarId): boolean =>
    !!this.state.activeCar && this.state.activeCar.uuid === carUuid

  private readonly getServiceRequestId = () => this.props.match.params.offerId as OfferRequestId
}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  cars: selectCurrentOfferRequestCars(state),
  offerRequest: selectCurrentOfferRequest(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  clearOfferRequest: () => { dispatch(OfferRequestAction.clearOfferRequest()) },
  getOfferRequest: uuid => { dispatch(OfferRequestAction.getOfferRequest(uuid)) },
  updateOfferRequest: dto => { dispatch(OfferRequestAction.updateOfferRequest(dto)) },
})

export const OfferRequestView = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(OfferRequestViewComponent)
