import { Chart } from 'actff-bo-app/components/Chart'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { reactSelectStyles, selectTheme } from 'actff-bo-app/components/Form'
import {
  DashboardAction,
  DashboardData,
  DashboardDataGroupByType,
  DashboardDataTypeOption,
  DashboardDataUrlType,
  DashboardSummaryData,
  DashboardTypeLegendMap,
  DashboardTypeNameMap,
  selectDashboardDetailedData,
  selectDashboardDetailedDataDateRange,
  selectDashboardDetailedDataGroupBy,
  selectDashboardSummaryData,
  selectDashbordDetailedDataType,
} from 'actff-bo-lib/dashboard'
import { DateRange } from 'actff-bo-lib/date'
import { State } from 'actff-bo-lib/store'
import { endOfDay, startOfDay } from 'date-fns'
import i18next from 'i18next'
import React, { Component, ReactNode } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import Select from 'react-select'
import { compose } from 'redux'
import { H2, Header, LinkButton } from 'styles'

import { DashboardSummary } from './DashboardSummary'
import { Container, DetailedDataChart, DetailedDataContainer, DetailedDataDate, DetailedDataGroupBy } from './Styled'

type StateToProps = {
  readonly detailedData: DashboardData | null,
  readonly detailedDataType: DashboardDataUrlType,
  readonly detailedDataGroupBy: DashboardDataGroupByType,
  readonly detailedDataDateRange: DateRange,
  readonly summaryData: DashboardSummaryData | null,
}

type DispatchToProps = {
  readonly changeDashboardDetailedDataDateRange: (range: DateRange) => void,
  readonly changeDashboardDetailedDataType: (dataType: DashboardDataUrlType) => void,
  readonly changeDashboardDetailedDataGroupBy: (groupBy: DashboardDataGroupByType) => void,
  readonly getDashboardDetailedData: () => void,
  readonly getDashboardSummaryData: () => void,
}

type Props = StateToProps & DispatchToProps & WithTranslation

const getDefaultValue = (dataType: DashboardDataUrlType, t: i18next.TFunction) => ({
  label: t(DashboardTypeNameMap.get(dataType) || ''),
  value: dataType,
})

const getDashboardSelectOptions = (t: i18next.TFunction) =>
  Object.values(DashboardDataUrlType).map(dataType => ({
    label: t(DashboardTypeNameMap.get(dataType) || ''),
    value: dataType,
  }))

const testId = 'dashboard__'
const statisticsStartYear = 2019
const startYearOffset = new Date().getFullYear() - statisticsStartYear

class DashboardComponent extends Component<Props> {
  public componentDidMount(): void {
    this.props.getDashboardSummaryData()
    this.props.getDashboardDetailedData()
  }

  public render(): ReactNode {
    const { detailedData, detailedDataDateRange, detailedDataType, summaryData, t } = this.props
    const { from, to } = detailedDataDateRange

    return (
      <Container>
        <Header>
          <H2 data-testid={`${testId}header`}>{t('dashboard.header')}</H2>
        </Header>
        {summaryData && <DashboardSummary summaryData={summaryData} />}
        <DetailedDataContainer>
          <Select
            className='select'
            defaultValue={getDefaultValue(detailedDataType, t)}
            onChange={this.changeDashboardDetailedDataType}
            options={getDashboardSelectOptions(t)}
            styles={reactSelectStyles}
            theme={selectTheme}
          />
          <DetailedDataGroupBy>
            <LinkButton
              className={this.getGroupByButtonClassName(DashboardDataGroupByType.Day)}
              data-testid={`${testId}group-by--day`}
              onClick={this.changeDashboardDetailedDataGroupBy(DashboardDataGroupByType.Day)}
            >
              {t('caption.day')}
            </LinkButton>
            <LinkButton
              className={this.getGroupByButtonClassName(DashboardDataGroupByType.Month)}
              data-testid={`${testId}group-by--month`}
              onClick={this.changeDashboardDetailedDataGroupBy(DashboardDataGroupByType.Month)}
            >
              {t('caption.month')}
            </LinkButton>
            <LinkButton
              className={this.getGroupByButtonClassName(DashboardDataGroupByType.Year)}
              data-testid={`${testId}group-by--year`}
              onClick={this.changeDashboardDetailedDataGroupBy(DashboardDataGroupByType.Year)}
            >
              {t('caption.year')}
            </LinkButton>
          </DetailedDataGroupBy>
          <DetailedDataDate>
            <DatePickerInput
              clickUnselectsDay={false}
              date={from}
              disabledDays={[{ after: to }]}
              futureYearsOffset={1}
              onChange={this.handleDetailedDataDateChange('from')}
              pastYearsOffset={startYearOffset}
            />
            <DatePickerInput
              clickUnselectsDay={false}
              date={to}
              futureYearsOffset={1}
              onChange={this.handleDetailedDataDateChange('to')}
              pastYearsOffset={startYearOffset}
            />
          </DetailedDataDate>
          {detailedData && (
            <DetailedDataChart>
              <Chart
                data={detailedData}
                // tslint:disable-next-line: no-unnecessary-callback-wrapper
                legend={DashboardTypeLegendMap.get(detailedDataType)?.map(legend => t(legend))}
                type='bar'
              />
            </DetailedDataChart>
          )}
        </DetailedDataContainer>
      </Container>
    )
  }

  private readonly getGroupByButtonClassName = (type: DashboardDataGroupByType): string =>
    this.props.detailedDataGroupBy === type ? 'active' : ''

  // tslint:disable-next-line cyclomatic-complexity
  private readonly handleDetailedDataDateChange = (keyOfDate: keyof DateRange) => (date: Date | null) => {
    const { changeDashboardDetailedDataDateRange, detailedDataDateRange } = this.props
    const { from: previousFrom, to: previousTo } = detailedDataDateRange

    const from = (keyOfDate === 'from' && date && startOfDay(date)) || previousFrom
    const to = (keyOfDate === 'to' && date && endOfDay(date)) || previousTo

    changeDashboardDetailedDataDateRange({ from, to })
  }

  private readonly changeDashboardDetailedDataType = (val: DashboardDataTypeOption) =>
    this.props.changeDashboardDetailedDataType(val.value)

  private readonly changeDashboardDetailedDataGroupBy = (groupByType: DashboardDataGroupByType) => () =>
    this.props.changeDashboardDetailedDataGroupBy(groupByType)
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  detailedData: selectDashboardDetailedData(state),
  detailedDataDateRange: selectDashboardDetailedDataDateRange(state),
  detailedDataGroupBy: selectDashboardDetailedDataGroupBy(state),
  detailedDataType: selectDashbordDetailedDataType(state),
  summaryData: selectDashboardSummaryData(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  changeDashboardDetailedDataDateRange: (range: DateRange) => { dispatch(DashboardAction.changeDashboardDetailedDataDateRange(range)) },
  changeDashboardDetailedDataGroupBy: (groupByType: DashboardDataGroupByType) => {
    dispatch(DashboardAction.changeDashboardDetailedDataGroupBy(groupByType))
  },
  changeDashboardDetailedDataType: (dataType: DashboardDataUrlType) => {
    dispatch(DashboardAction.changeDashboardDetailedDataType(dataType))
  },
  getDashboardDetailedData: () => { dispatch(DashboardAction.getDashboardDetailedData()) },
  getDashboardSummaryData: () => { dispatch(DashboardAction.getDashboardSummaryData()) },
})

export const Dashboard = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(DashboardComponent)
