import { DatePickerInput as DatePickerInputComponent } from 'actff-bo-app/components/DateTime'
import { FormHeader, MoneyInput as MoneyInputComponent, RadioBoxes } from 'actff-bo-app/components/Form'
import { PlannedSellDate } from 'actff-bo-app/Crm/Trade/Car/CarValue/PlannedSellDate'
import { OpportunityPurchaseCarInsurance } from 'actff-bo-lib/crm/trade'
import { formatDate } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Ref } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly control: Control,
  readonly innerValuation: string | null,
  readonly insurance: OpportunityPurchaseCarInsurance,
  readonly register: (ref: Ref | null) => void,
}

const Container = styled.div`
  border-bottom: 1px solid ${colors.mystic};
  grid-area: car-value;
  padding: 0 30px 30px;
`

const CarDataHeader = styled(FormHeader)`
  background-color: white;
  bottom: 14px;
  padding: 0 0 24px 13px;
  position: relative;
  right: 12px;
  width: 240px;
`

const DataContainer = styled.div`
  display: grid;
  grid-column-gap: 75px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  padding-right: 120px;
`

const DataCell = styled.div`
  .DayPickerInput {
    display: initial;
  }

  input {
    width: 100%;
  }
`

const MoneyInput = styled(MoneyInputComponent)`
  input {
    &:disabled {
      background-color: white;
      color: black !important;
    }
  }
`

const DatePickerInput = styled(DatePickerInputComponent)`
  > input {
    background-color: white;
    color: black !important;
  }
`

const InsuranceInput = styled.input`
  &:read-only {
    background-color: ${colors.athensGray};
  }
`

const ValuationInput = styled.div`
  display: grid;
`

const YesNoBox = styled(RadioBoxes)`
  grid-template-columns: 70px 70px;
`

export const CarValue: React.FC<Props> = ({
  control,
  insurance,
  register,
  testId,
}) => {
  const { t } = useTranslation()
  const insuranceExpirationDate = insurance?.startDate && formatDate()(insurance.startDate)

  // TODO consider migrate `isNewCar` field name to `newCarOffer` or something similar
  return (
    <>
      <Container>
        <CarDataHeader> {t('carValue')}</CarDataHeader>
        <DataContainer>
          <ValuationInput>
            <label>{t('crmTrade.opportunityPurchase.valuation')}:</label>
            <MoneyInput control={control} name='innerValuation' />
          </ValuationInput>
          <DataCell>
            <label>{t('crmTrade.opportunityPurchase.insuranceValue')}:</label>
            <InsuranceInput name='insuranceValue' readOnly={true} ref={register} />
          </DataCell>
          <DataCell>
            <label>{t('carView.form.insurance.fromDate')}:</label>
            <Controller
              render={({ value }) => <DatePickerInput disabled={true} readOnly={true} date={value} testId={`${testId}insuranceFrom`} />}
              control={control}
              defaultValue={insuranceExpirationDate}
              name='insuranceFrom'
            />
          </DataCell>
          <PlannedSellDate control={control} />
          <DataCell>
            <label>{t('crmTrade.opportunityPurchase.newCarOffer')}:</label>
            <YesNoBox>
              <div>
                <input
                  defaultChecked={true}
                  data-testid={`${testId}-isNewCar-yes`}
                  id='isNewCar-yes'
                  name='isNewCar'
                  ref={register}
                  type='radio'
                  value='yes'
                />
                <label htmlFor='isNewCar-yes'>{t('caption.yes')}</label>
              </div>
              <div>
                <input
                  data-testid={`${testId}-isNewCar-no`}
                  id='isNewCar-no'
                  name='isNewCar'
                  ref={register}
                  type='radio'
                  value='no'
                />
                <label htmlFor='isNewCar-no'>{t('caption.no')}</label>
              </div>
            </YesNoBox>
          </DataCell>
        </DataContainer>
      </Container>
    </>
  )
}
