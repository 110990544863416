import { Client, ClientId, ClientStatus } from 'actff-bo-lib/client'

export const clientInitialValues: Client = {
  approvalCounts: {
    marketingOffers: 0,
    personalData: 0,
    tradeOffers: 0,
  },
  carsCount: 0,
  deletionDate: new Date(),
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  phone: '',
  registrationDate: new Date(),
  status: ClientStatus.NoCars,
  type: [],
  uuid: '' as ClientId,
}
