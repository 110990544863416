import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { CrmInternalCommentDto, OpportunityListType } from 'actff-bo-lib/crm/dto'
import {
  OpportunityPurchase,
  OpportunityPurchaseFilterValues,
  OpportunityPurchaseId,
  OpportunitySale,
  OpportunitySaleId,
  OpportunityTradeInternalEvent,
  OpportunityTradeInternalEventDto,
} from 'actff-bo-lib/crm/trade/dto'

import { DetailedFiltersFormValues } from 'actff-bo-lib/crm/trade/purchase/filters/filters'
import { Attachment } from 'actff-bo-lib/global/attachment'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import { AjaxError } from 'rxjs/ajax'

export enum CrmTradeOpportunityPurchaseActionType {
  AssignOpportunity = '[CRM TRADE PURCHASE] ASSIGN OPPORTUNITY',
  AssignOpportunityFailure = '[CRM TRADE PURCHASE] ASSIGN OPPORTUNITY FAILURE',
  AssignOpportunitySuccess = '[CRM TRADE PURCHASE] ASSIGN OPPORTUNITY SUCCESS',

  ChangeOpportunityListCurrentPage = '[CRM TRADE PURCHASE] CHANGE OPPORTUNITY LIST CURRENT PAGE',
  ChangeSearchMatchingOpportunitiesCurrentPage = '[CRM TRADE PURCHASE] CHANGE SEARCH MATCHING OPPORTUNITIES CURRENT PAGE',
  ChangeOpportunityListTimeFrom = '[CRM TRADE PURCHASE] CHANGE OPPORTUNITY LIST TIME FROM',
  ChangeOpportunityListTimeTo = '[CRM TRADE PURCHASE] CHANGE OPPORTUNITY LIST TIME TO',

  ChangeSearchPhrase = '[CRM TRADE PURCHASE] CHANGE SEARCH PHRASE',
  ChangeSearchMatchingOpportunitiesSearchPhrase = '[CRM TRADE PURCHASE] CHANGE SEARCH MATCHING OPPORTUNITIES SEARCH PHRASE',
  ChangeAssignmentFilter = '[CRM TRADE PURCHASE] CHANGE ASSIGNMENT FILTER',
  ChangeListsStatusFilters = '[CRM TRADE PURCHASE] CHANGE LISTS STATUS FILTER',
  ClearListsStatusFilters = '[CRM TRADE PURCHASE] CLEAR LISTS STATUS FILTERS',

  ClearCurrentOpportunity = '[CRM TRADE PURCHASE] CLEAR CURRENT OPPORTUNITY',
  ClearPurchaseState = '[CRM TRADE PURCHASE] CLEAR PURCHASE STATE',

  ChangeListsDetailedFilters = '[CRM TRADE PURCHASE] CHANGE LISTS DETAILED FILTERS',
  ClearListsDetailedFilters = '[CRM TRADE PURCHASE] CLEAR LISTS DETAILED FILTERS',

  GetOpportunity = '[CRM TRADE PURCHASE] GET OPPORTUNITY',
  GetOpportunityFailure = '[CRM TRADE PURCHASE] GET OPPORTUNITY FAILURE',
  GetOpportunitySuccess = '[CRM TRADE PURCHASE] GET OPPORTUNITY SUCCESS',

  GetOpportunities = '[CRM TRADE PURCHASE] GET OPPORTUNITIES',
  GetOpportunitiesFailure = '[CRM TRADE PURCHASE] GET OPPORTUNITIES FAILURE',
  GetOpportunitiesSuccess = '[CRM TRADE PURCHASE] GET OPPORTUNITIES SUCCESS',

  GetMatchingPurchaseOpportunities = '[CRM TRADE PURCHASE] GET MATCHING PURCHASE OPPORTUNITIES',
  GetMatchingPurchaseOpportunitiesFailure = '[CRM TRADE PURCHASE] GET MATCHING PURCHASE OPPORTUNITIES FAILURE',
  GetMatchingPurchaseOpportunitiesSuccess = '[CRM TRADE PURCHASE] GET MATCHING PURCHASE OPPORTUNITIES SUCCESS',

  GetMatchedSaleOpportunities = '[CRM TRADE PURCHASE] GET MATCHED SALE OPPORTUNITIES',
  GetMatchedSaleOpportunitiesFailure = '[CRM TRADE PURCHASE] GET MATCHED SALE OPPORTUNITIES FAILURE',
  GetMatchedSaleOpportunitiesSuccess = '[CRM TRADE PURCHASE] GET MATCHED SALE OPPORTUNITIES SUCCESS',

  MatchSaleOpportunities = '[CRM TRADE SALE] MATCH SALE OPPORTUNITIES',
  MatchSaleOpportunitiesFailure = '[CRM TRADE SALE] MATCH SALE OPPORTUNITIES FAILURE',
  MatchSaleOpportunitiesSuccess = '[CRM TRADE SALE] MATCH SALE OPPORTUNITIES SUCCESS',

  ResetPaginationOnLists = '[CRM TRADE PURCHASE] RESET PAGINATION ON LISTS',

  SaveStringifiedDetailedFilters = '[CRM TRADE PURCHASE] SAVE STRINGIFIED DETAILED FILTERS',

  SendInternalEventWithAttachments = '[CRM TRADE PURCHASE] SEND INTERNAL EVENT WITH ATTACHMENTS',
  SendInternalEventWithAttachmentsFailure = '[CRM TRADE PURCHASE] SEND INTERNAL EVENT WITH ATTACHMENTS FAILURE',
  SendInternalEventWithAttachmentsSuccess = '[CRM TRADE PURCHASE] SEND INTERNAL EVENT WITH ATTACHMENTS SUCCESS',

  SendInternalEvent = '[CRM TRADE PURCHASE] SEND INTERNAL EVENT',
  SendInternalEventFailure = '[CRM TRADE PURCHASE] SEND INTERNAL EVENT FAILURE',
  SendInternalEventSuccess = '[CRM TRADE PURCHASE] SEND INTERNAL EVENT SUCCESS',

  SendInternalComment = '[CRM TRADE PURCHASE] SEND INTERNAL COMMENT',
  SendInternalCommentFailure = '[CRM TRADE PURCHASE] SEND INTERNAL COMMENT FAILURE',
  SendInternalCommentSuccess = '[CRM TRADE PURCHASE] SEND INTERNAL COMMENT SUCCESS',

  UnassignOpportunity = '[CRM TRADE PURCHASE] UNASSIGN OPPORTUNITY',
  UnassignOpportunityFailure = '[CRM TRADE PURCHASE] UNASSIGN OPPORTUNITY FAILURE',
  UnassignOpportunitySuccess = '[CRM TRADE PURCHASE] UNASSIGN OPPORTUNITY SUCCESS',

  UpdateOpportunity = '[CRM TRADE PURCHASE] UPDATE OPPORTUNITY',
  UpdateOpportunityFailure = '[CRM TRADE PURCHASE] UPDATE OPPORTUNITY FAILURE',
  UpdateOpportunitySuccess = '[CRM TRADE PURCHASE] UPDATE OPPORTUNITY SUCCESS',
}

export const CrmTradeOpportunityPurchaseAction = {
  assignOpportunity: (uuid: OpportunityPurchaseId, user: UserWithoutPermissions, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunityPurchaseActionType.AssignOpportunity, { uuid, user, opportunityListType }),
  assignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.AssignOpportunityFailure, error),
  assignOpportunitySuccess: (opportunity: OpportunityPurchase, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunityPurchaseActionType.AssignOpportunitySuccess, { opportunity, opportunityListType }),

  changeOpportunityListCurrentPage: (page: number, type: OpportunityListType) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListCurrentPage, { page, type }),
  changeOpportunityListTimeFrom: (date: Date, type: OpportunityListType) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListTimeFrom, { date, type } ),
  changeOpportunityListTimeTo: (date: Date, type: OpportunityListType) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListTimeTo, { date, type }),
  changeSearchMatchingOpportunitiesCurrentPage: (page: number) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeSearchMatchingOpportunitiesCurrentPage, { page }),

  clearCurrentOpportunity: () => createAction(CrmTradeOpportunityPurchaseActionType.ClearCurrentOpportunity),
  clearPurchaseState: () => createAction(CrmTradeOpportunityPurchaseActionType.ClearPurchaseState),

  changeAssignmentFilter: (filter: UserId | null) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeAssignmentFilter, filter),

  changeListsStatusFilters: (param: string, value: OpportunityPurchaseFilterValues) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeListsStatusFilters, { param, value }),
  changeSearchMatchingOpportunitiesSearchPhrase: (searchPhrase: string) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeSearchMatchingOpportunitiesSearchPhrase, searchPhrase),
  changeSearchPhrase: (searchPhrase: string) => createAction(CrmTradeOpportunityPurchaseActionType.ChangeSearchPhrase, searchPhrase),
  clearListsStatusFilters: () => createAction(CrmTradeOpportunityPurchaseActionType.ClearListsStatusFilters),

  changeListsDetailedFilters: (values: DetailedFiltersFormValues) =>
    createAction(CrmTradeOpportunityPurchaseActionType.ChangeListsDetailedFilters, values),
  clearListsDetailedFilters: () => createAction(CrmTradeOpportunityPurchaseActionType.ClearListsDetailedFilters),

  getOpportunity: (uuid: OpportunityPurchaseId) => createAction(CrmTradeOpportunityPurchaseActionType.GetOpportunity, uuid),
  getOpportunityFailure: (error: AjaxError) => createAction(CrmTradeOpportunityPurchaseActionType.GetOpportunityFailure, error),
  getOpportunitySuccess: (opportunity: OpportunityPurchase) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetOpportunitySuccess, opportunity),

  resetPaginationOnLists: () => createAction(CrmTradeOpportunityPurchaseActionType.ResetPaginationOnLists),

  getMatchedSaleOpportunities: (opportunityId: OpportunityPurchaseId) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetMatchedSaleOpportunities, { opportunityId }),
  getMatchedSaleOpportunitiesFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetMatchedSaleOpportunitiesFailure, error),
  getMatchedSaleOpportunitiesSuccess: (opportunities: ReadonlyArray<OpportunitySale>) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetMatchedSaleOpportunitiesSuccess, opportunities),

  getMatchingPurchaseOpportunities: () => createAction(CrmTradeOpportunityPurchaseActionType.GetMatchingPurchaseOpportunities),
  getMatchingPurchaseOpportunitiesFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetMatchingPurchaseOpportunitiesFailure, error),
  getMatchingPurchaseOpportunitiesSuccess: (opportunityList: PagedResult<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetMatchingPurchaseOpportunitiesSuccess, opportunityList),

  matchSaleOpportunities: (purchaseId: OpportunityPurchaseId, opportunities: ReadonlyArray<OpportunitySaleId>) =>
    createAction(CrmTradeOpportunityPurchaseActionType.MatchSaleOpportunities, { purchaseId, opportunities }),
  matchSaleOpportunitiesFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.MatchSaleOpportunitiesFailure, error),
  matchSaleOpportunitiesSuccess: (opportunities: ReadonlyArray<OpportunitySaleId>) =>
    createAction(CrmTradeOpportunityPurchaseActionType.MatchSaleOpportunitiesSuccess, opportunities),

  getOpportunities: (type: OpportunityListType) => createAction(CrmTradeOpportunityPurchaseActionType.GetOpportunities, { type }),
  getOpportunitiesFailure: (type: OpportunityListType, error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetOpportunitiesFailure, { type, error }),
  getOpportunitiesSuccess: (type: OpportunityListType, opportunityList: PagedResult<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunityPurchaseActionType.GetOpportunitiesSuccess, { type, opportunityList }),

  sendInternalEventWithAttachments: (
    opportunityId: OpportunityPurchaseId,
    internalEvent: OpportunityTradeInternalEventDto,
    attachments?: ReadonlyArray<Attachment>,
  ) => createAction(CrmTradeOpportunityPurchaseActionType.SendInternalEventWithAttachments, { opportunityId, internalEvent, attachments }),
  sendInternalEventWithAttachmentsFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalEventWithAttachmentsFailure, error),
  sendInternalEventWithAttachmentsSuccess: (internalEvent: OpportunityTradeInternalEvent) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalEventWithAttachmentsSuccess, internalEvent),

  saveStringifiedDetailedFilters: (stringifiedDetailedFilters: string) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SaveStringifiedDetailedFilters, stringifiedDetailedFilters),

  sendInternalEvent: (opportunityId: OpportunityPurchaseId, internalEvent: OpportunityTradeInternalEventDto) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalEvent, { opportunityId, internalEvent }),
  sendInternalEventFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalEventFailure, error),
  sendInternalEventSuccess: (opportunity: OpportunityPurchase) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalEventSuccess, opportunity),

  sendInternalComment: (opportunityId: OpportunityPurchaseId, internalCommentDto: CrmInternalCommentDto) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalComment, { opportunityId, internalCommentDto }),
  sendInternalCommentFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalCommentFailure, error),
  sendInternalCommentSuccess: (opportunity: OpportunityPurchase) =>
    createAction(CrmTradeOpportunityPurchaseActionType.SendInternalCommentSuccess, opportunity),

  unassignOpportunity: (uuid: OpportunityPurchaseId, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunityPurchaseActionType.UnassignOpportunity, { uuid, opportunityListType }),
  unassignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.UnassignOpportunityFailure, error),
  unassignOpportunitySuccess: (opportunity: OpportunityPurchase, opportunityListType: OpportunityListType) =>
    createAction(CrmTradeOpportunityPurchaseActionType.UnassignOpportunitySuccess, { opportunity, opportunityListType }),

  updateOpportunity: (opportunity: Partial<OpportunityPurchase>) =>
    createAction(CrmTradeOpportunityPurchaseActionType.UpdateOpportunity, opportunity),
  updateOpportunityFailure: (error: AjaxError) =>
    createAction(CrmTradeOpportunityPurchaseActionType.UpdateOpportunityFailure, error),
  updateOpportunitySuccess: (opportunity: OpportunityPurchase) =>
    createAction(CrmTradeOpportunityPurchaseActionType.UpdateOpportunitySuccess, opportunity),
}

export type CrmTradeOpportunityPurchaseAction = ActionsUnion<typeof CrmTradeOpportunityPurchaseAction>
