import { ComponentMap } from 'actff-bo-app/components/ComponentType'
import { NullComponent } from 'actff-bo-app/components/NullComponent'
import { withAuthorization } from 'actff-bo-app/routing'
import { MenuItem, WrapperType } from 'actff-bo-lib/menu'

import { wrapperTypeToWrapper } from './Wrapper'

export const getWrapperProps = (type: WrapperType, item: MenuItem) => item.wrappersProps
  ? item.wrappersProps.get(type) || null
  : null

export const getWrappedComponent = (item: MenuItem): React.ComponentType => {
  const { access, component: componentType, wrappers } = item
  const component = ComponentMap.get(componentType) || NullComponent

  const wrappedComponent = wrappers
    ? wrappers
      .map(wrapperType => ({ wrapperType, wrapperProps: getWrapperProps(wrapperType, item) }))
      .map(({ wrapperType, wrapperProps }) => ({ wrapper: wrapperTypeToWrapper(wrapperType), wrapperProps }))
      .reduceRight((prev, { wrapper, wrapperProps}) => wrapper(wrapperProps)(prev), component)
    : component

  return access.length > 0 ? withAuthorization(wrappedComponent, access) : wrappedComponent
}
