import { PagedResult } from 'actff-bo-lib/api'
import { Testable } from 'actff-bo-lib/global/testable'
import { OfferRequest } from 'actff-bo-lib/offer-request'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { OfferRequestRow } from './OfferRequestRow'

type Props = Testable & {
  readonly offerRequests: PagedResult<OfferRequest>,
}

export const OfferRequestTable: React.FC<Props> = ({ offerRequests: { result }, testId }) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
          <Tr>
            <Th>{t('table.columns.data')}</Th>
            <Th>{t('table.columns.fullName')}</Th>
            <Th>{t('table.columns.type')}</Th>
            <Th>{t('table.columns.phone')}</Th>
            <Th>{t('table.columns.carName')}</Th>
            <Th>{t('offerRequest.type')}</Th>
            <Th>{t('table.columns.location')}</Th>
          </Tr>
        </thead>
        <tbody>
            {result.length && result.map((offerRequest, index) =>
              <OfferRequestRow key={offerRequest.uuid} offerRequest={offerRequest} testId={`${testId}request-${index}-`} />,
            )}
          </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )
}
