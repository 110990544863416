import { ClientFullName } from 'actff-bo-app/components/Client'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { WithStyledRow } from 'actff-bo-app/components/Table'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { OpportunityListType, OpportunityStatus } from 'actff-bo-lib/crm'
import { OpportunityService } from 'actff-bo-lib/crm/service'
import { selectDealerLocationByKey } from 'actff-bo-lib/dealership'
import { createRoute } from 'actff-bo-lib/menu/helpers'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { getServiceName, selectSelectableServices } from 'actff-bo-lib/service'
import { selectMe, UserWithoutPermissions } from 'actff-bo-lib/user'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Tr as TrStyled, TrDanger, TrWarning } from 'styles'

import { OpportunityAssign } from '../OpportunityAssign/OpportunityAssign'
import { OpportunityRowStatus } from './OpportunityRowStatus'

type Props = {
  readonly opportunity: OpportunityService,
  readonly rowComponent: typeof TrStyled | typeof TrDanger | typeof TrWarning,
  readonly opportunityListType: OpportunityListType,
  readonly userList: ReadonlyArray<UserWithoutPermissions> | null,
}

const isOpportunityInRetryStatus = (opportunity: OpportunityService) =>
    opportunity.status === OpportunityStatus.RETRY
    || opportunity.status === OpportunityStatus.RETRY_LIST

export const OpportunityRow: React.FC<Props> = ({ opportunity, rowComponent: Tr, opportunityListType, userList }) => {
  const { assignee, car, client, lastServiceDate, startDate, serviceKey, uuid } = opportunity
  const href = createRoute(Paths.CrmServiceOpportunity, { opportunityId: uuid })
  const Td = TdDecorator(href)

  const currentUser = useSelector(selectMe)
  const services = useSelector(selectSelectableServices)
  const dealerLocation = useSelector(selectDealerLocationByKey(opportunity.locationKey))
  const { t } = useTranslation()
  const Row = isOpportunityInRetryStatus(opportunity) ? WithStyledRow : Tr
  const service = services.find(s => s.key === serviceKey)

  return (
    <>
      <Row clickable={true} dangerStyleLimit={0} isStyled={true} time={opportunity.retryDate}>
        <Td>
          <OpportunityAssign
            assignee={assignee}
            currentUser={currentUser}
            opportunityId={uuid}
            opportunityListType={opportunityListType}
            userList={userList}
          />
        </Td>
        <Td>
          <OpportunityRowStatus {...opportunity} />
        </Td>
        <Td>{car.registrationNumber}</Td>
        <Td><ClientFullName client={client} /></Td>
        <Td>{client.phone}</Td>
        <Td>{service && getServiceName(service, t)}</Td>
        <Td><FormattedDate date={lastServiceDate} /></Td>
        <Td><FormattedDate date={startDate} /></Td>
        <Td>{dealerLocation?.name}</Td>
      </Row>
    </>
  )
}
