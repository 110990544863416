import { ToastBodyComponentType } from 'actff-bo-lib/toast'
import { Set } from 'immutable'

export enum ErrorKeys {
  VinExists = 'vin.exists',
  RegistrationNumberExists = 'registrationNumber.exists',
  ServiceRequestWithServiceExists = 'service.exists',
}

export type ApiResponseError = {
  readonly key: ErrorKeys,
  readonly title: string,
  readonly body?: ToastBodyComponentType,
}

export const Errors: Set<ApiResponseError> = Set([
  {
    key: ErrorKeys.VinExists,
    title: 'carView.form.update.failure.vinExists',
  },
  {
    key: ErrorKeys.RegistrationNumberExists,
    title: 'carView.form.update.failure.registrationNumberExists',
  },
  {
    body: ToastBodyComponentType.ServiceRequestWithServiceExists,
    key: ErrorKeys.ServiceRequestWithServiceExists,
    title: 'serviceRequest.errors.exists.title',
  },
])
