import { ClientPersonalDataVersion, getFullName } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type PersonalDataChangerProps = {
  readonly personalData: ClientPersonalDataVersion,
}

export const PersonalDataChanger: React.FC<PersonalDataChangerProps> = ({ personalData }) => {
  const { t } = useTranslation()

  return (
    <>
      {personalData.client ? getFullName(personalData.client) : null}
      {personalData.user ? `${t('clientView.approvals.reception')}: ${getFullName(personalData.user)}` : null}
    </>
  )
}
