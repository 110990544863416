import { CarEquipmentAction, CarEquipmentActionType } from '../actions'
import { CarEquipmentState } from '../state'

const carEquipmentInitialState: CarEquipmentState = {
  data: {
    data: null,
    loading: false,
  },
  items: {
    data: [],
    loading: false,
  },
  itemsEdit: null,
}

// tslint:disable-next-line cyclomatic-complexity
export const carEquipmentReducer = (state: CarEquipmentState = carEquipmentInitialState, action: CarEquipmentAction): CarEquipmentState => {
  switch (action.type) {
    case CarEquipmentActionType.GetCarEquipmentData:
      return {
        ...state,
        data: {
          data: null,
          loading: true,
        },
      }

    case CarEquipmentActionType.GetCarEquipmentDataFailure:
      return {
        ...state,
        data: {
          data: null,
          loading: false,
        },
      }

    case CarEquipmentActionType.GetCarEquipmentDataSuccess:
      return {
        ...state,
        data: {
          data: action.payload,
          loading: false,
        },
      }

    case CarEquipmentActionType.UpdateCarEquipmentData:
      return {
        ...state,
        data: {
          data: action.payload.data,
          loading: true,
        },
      }

    case CarEquipmentActionType.UpdateCarEquipmentDataFailure:
      return {
        ...state,
        data: {
          ...state.data,
          loading: false,
        },
      }

    case CarEquipmentActionType.UpdateCarEquipmentDataSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          loading: false,
        },
      }

    case CarEquipmentActionType.GetCarEquipmentItems:
      return {
        ...state,
        items: {
          data: null,
          loading: true,
        },
      }

    case CarEquipmentActionType.GetCarEquipmentItemsFailure:
      return {
        ...state,
        items: {
          data: null,
          loading: false,
        },
      }

    case CarEquipmentActionType.GetCarEquipmentItemsSuccess:
      return {
        ...state,
        items: {
          data: action.payload,
          loading: false,
        },
      }

    case CarEquipmentActionType.GetCarEquipmentItemsEdit:
      return {
        ...state,
        itemsEdit: null,
      }

    case CarEquipmentActionType.GetCarEquipmentItemsEditSuccess:
      return {
        ...state,
        itemsEdit: action.payload,
      }

    case CarEquipmentActionType.UpdateCarEquipmentItems:
      return {
        ...state,
        items: {
          data: action.payload.items,
          loading: true,
        },
      }

    case CarEquipmentActionType.UpdateCarEquipmentItemsFailure:
      return {
        ...state,
        items: {
          data: null,
          loading: false,
        },
      }

    case CarEquipmentActionType.UpdateCarEquipmentItemsSuccess:
      return {
        ...state,
        items: {
          ...state.items,
          loading: false,
        },
        itemsEdit: null,
      }

      default:
      return state
  }
}
