import { BtnTheme } from 'actff-bo-app/components/Button'
import { HeaderContainer } from 'actff-bo-app/components/Grid'
import { Loader } from 'actff-bo-app/components/Loader'
import { Search } from 'actff-bo-app/components/Search'
import { NoItems, WithPagination } from 'actff-bo-app/components/Table'
import { NotFound } from 'actff-bo-app/NotFound'
import { PagedResult } from 'actff-bo-lib/api'
import {
  Client,
  ClientAction,
  ClientFilters,
  selectClientCount,
  selectClientListSearchPhrase,
  selectClientsData,
  selectCurrentFilters,
  selectCurrentPage,
  selectIsLoadingClients,
} from 'actff-bo-lib/client'
import { IconType, Path, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { H2, HeaderList, Small } from 'styles'
import { ClientListFilters } from './ClientListFilters'

import { ClientTable } from './ClientTable'
import { AddNewClientButton, Container } from './Styled'

type StateToProps = {
  readonly clientCount: number,
  readonly clients: PagedResult<Client> | null,
  readonly currentFilters: ClientFilters,
  readonly currentPage: number,
  readonly isLoading: boolean,
  readonly searchPhrase?: string,
}

type DispatchToProps = {
  readonly changeFilters: (filters: ClientFilters) => void,
  readonly clearFilters: () => void,
  readonly getClientCount: () => void,
  readonly getClients: () => void,
  readonly onPageChange: (nextPage: number) => void,
  readonly onSearchPhraseChange: (searchPhrase?: string) => void,
}

type ClientListComponentProps = StateToProps & DispatchToProps & WithTranslation

const testId = 'car-list__'

export class ClientListComponent extends React.Component<ClientListComponentProps> {

  public componentDidMount(): void {
    this.props.getClients()
    this.props.getClientCount()
  }

  public render(): React.ReactNode {
    const {
      changeFilters,
      clientCount,
      clients,
      clearFilters,
      currentFilters,
      currentPage,
      getClients,
      isLoading,
      onPageChange,
      onSearchPhraseChange,
      searchPhrase,
      t,
    } = this.props

    if (isLoading) {
      return <Loader />
    }

    if (!clients) {
      return <NotFound />
    }

    return (
      <Container>
        <HeaderList>
          <H2 data-testid={`${testId}header`}>{t('clientList.tableHeader')}</H2>
          <Small>{t('clientView.clients', { count: clientCount })}</Small>
          <HeaderContainer>
            <ClientListFilters
              changeFilters={changeFilters}
              clearFilters={clearFilters}
              currentFilters={currentFilters}
              getClients={getClients}
            />
            <Search initialValues={{ searchPhrase }} onSearch={onSearchPhraseChange} testId={testId} />
          </HeaderContainer>
        </HeaderList>
        {
          clients.result.length
            ? (
              <WithPagination
                additionalActionButton={
                  <AddNewClientButton
                    caption={t('caption.addClient')}
                    iconType={IconType.Plus}
                    theme={BtnTheme.Minor}
                    onClick={this.handleAddNewClientClick}
                  />
                }
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageCount={clients.noOfPages}
                path={Paths.ClientList as Path}
                rowsInCurrentPage={clients.result.length}
                testId={testId}
              >
                <ClientTable clients={clients} />
              </WithPagination>
            )
            : <NoItems icon={IconType.NoCars} text={t('clientList.noClients')} />
        }
      </Container>
    )
  }

  private readonly handleAddNewClientClick = () => { history.replace(Paths.ClientNew) }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  clientCount: selectClientCount(state),
  clients: selectClientsData(state),
  currentFilters: selectCurrentFilters(state),
  currentPage: selectCurrentPage(state),
  isLoading: selectIsLoadingClients(state),
  searchPhrase: selectClientListSearchPhrase(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  changeFilters: (filters: ClientFilters) => { dispatch(ClientAction.changeFilters(filters)) },
  clearFilters: () => { dispatch(ClientAction.clearFilters()) },
  getClientCount: () => { dispatch(ClientAction.getClientCount()) },
  getClients: () => { dispatch(ClientAction.getClients()) },
  onPageChange: (nextPage: number) => { dispatch(ClientAction.changePage(nextPage)) },
  onSearchPhraseChange: (searchPhrase: string) => { dispatch(ClientAction.changeSearchPhrase(searchPhrase)) },
})

export const ClientList = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ClientListComponent),
)
