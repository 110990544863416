import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Rating } from 'actff-bo-app/components/Rating'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { CarId, InspectionListElement, InspectionStatus } from 'actff-bo-lib/car'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { Map } from 'immutable'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tr } from 'styles'

type Props = Testable & {
  readonly element: InspectionListElement,
  readonly carId: CarId,
  readonly isNdcCar: boolean,
  readonly vin: string,
}

const InspectionStatusNameMap = Map([
  [InspectionStatus.COMPLETED, 'carView.inspection.status.completed'],
  [InspectionStatus.IN_PROGRESS, 'carView.inspection.status.inprogress'],
]) as Map<InspectionStatus, string>

export const InspectionRow: FC<Props> = ({ carId, element, isNdcCar, testId, vin }) => {
  const { t } = useTranslation()
  const route = isNdcCar
    ? createRoute(Paths.NdcCarViewInspection, { vin, inspectionId: element.uuid })
    : createRoute(Paths.CarViewInspection, { carId, inspectionId: element.uuid })
  const Td = TdDecorator(route)

  return (
    <Tr>
      <Td><Rating defaultValue={element.rating} name='rating' readonly={true} /></Td>
      <Td testId={`${testId}--inspectionDate`}><FormattedDate date={element.inspectionDate} format={defaultDateFormat} /></Td>
      <Td testId={`${testId}--lastServiceDate`}><FormattedDate date={element.lastServiceDate} format={defaultDateFormat} /></Td>
      <Td testId={`${testId}--motDate`}><FormattedDate date={element.motDate} format={defaultDateFormat} /></Td>
      <Td testId={`${testId}--status`}>{t(InspectionStatusNameMap.get(element.status, ''))}</Td>
    </Tr>
  )
}
