import { EventInput } from '@fullcalendar/core'

type EventExtendedProps = {
  readonly eventId: string,
  readonly resourceLabel: string,
  readonly selected: boolean,
}

export const markSelectedEvents = (selectedEventIds: ReadonlyArray<number>, events?: ReadonlyArray<EventInput>) =>
  events?.map((event, index) => {
    const isSelected = selectedEventIds.length === 1 ?
      index === selectedEventIds[0] :
      index >= selectedEventIds[0] && index <= selectedEventIds[selectedEventIds.length - 1]

    return {
      ...event,
      extendedProps: {
        ...event.extendedProps as EventExtendedProps,
        selected: isSelected,
      },
    }
  })

export const getSelectedEvents = (currentlySelectedIds: ReadonlyArray<number>, events?: ReadonlyArray<EventInput>) => {
  const firstItem = currentlySelectedIds[0]
  const lastItem = currentlySelectedIds[currentlySelectedIds.length - 1]

  const leftSelection = events?.slice(lastItem + 1, firstItem)
  const rightSelection = events?.slice(firstItem, lastItem + 1)

  return firstItem <= lastItem ? rightSelection : leftSelection
}
