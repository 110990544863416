import { CampaignAction, CampaignActionType } from './actions'

export type CampaignState = {
  readonly smsCost: string | null,
}

const defaultCampaignState: CampaignState = {
  smsCost: null,
}

export const campaignReducer = (state: CampaignState = defaultCampaignState, action: CampaignAction): CampaignState => {
  switch (action.type) {
    case CampaignActionType.GetSmsCostSuccess:
      return {
        smsCost: action.payload,
      }

    default:
      return state
  }
}
