import { ErrorMessage } from '@hookform/error-message'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell, getDateValue } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { OpportunityInsuranceType } from 'actff-bo-lib/crm/insurance'
import { convertToUTC } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { addDays } from 'date-fns'
import addYears from 'date-fns/add_years'
import subDays from 'date-fns/sub_days'
import * as React from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { dateValidation } from '../date-validation'

type Props = Pick<UseFormMethods, 'register' | 'control' | 'errors' | 'setValue' | 'watch'>
  & Testable
  & {
    readonly shouldValidateNewPolicyDate: boolean,
    readonly insuranceType: OpportunityInsuranceType,
    readonly onTriggerDateValidation: (name: string) => void,
    readonly readOnly?: boolean,
  }

const ocShortTermDuration = 29

export const NewPolicyDate: React.FC<Props> = ({
  control,
  errors,
  setValue,
  testId,
  onTriggerDateValidation,
  shouldValidateNewPolicyDate,
  insuranceType,
  readOnly,
}) => {
  const { t } = useTranslation()
  const shouldAutocompleteNewPolicyExpires =
    insuranceType !== OpportunityInsuranceType.GAP &&
    insuranceType !== OpportunityInsuranceType.EXTENDED_WARRANTY

  const isStartsFieldRequired = () => shouldValidateNewPolicyDate && 'caption.error.required'
  const isExpiresFieldRequired = () => (shouldValidateNewPolicyDate || !shouldAutocompleteNewPolicyExpires) && 'caption.error.required'

  const handlePolicyStartsChange = (onChange: (value: Date) => void) => (value: Date) => {
    if (shouldAutocompleteNewPolicyExpires) {
      const expirationDate = insuranceType === OpportunityInsuranceType.OC_SHORT_TERM
        ? addDays(value, ocShortTermDuration)
        : subDays(addYears(value, 1), 1)

      setValue('newPolicy.expires', convertToUTC(expirationDate))
      onTriggerDateValidation('newPolicy.expires')
    }
    onChange(value)
  }

  return (
    <>
      <FormCell className='datepicker-fullwidth' id='newPolicy-starts'>
        <label>{t('crmInsurance.newPolicy.fromDate')}:</label>
        <Controller
          render={({ onChange, value }) => (
            <DatePickerInput
              date={getDateValue(value)}
              onChange={handlePolicyStartsChange(onChange)}
              timeDisabled={true}
              testId={`${testId}newPolicy-fromDate`}
              disabled={readOnly}
            />
          )}
          control={control}
          rules={{ required: isStartsFieldRequired(), validate: dateValidation(shouldValidateNewPolicyDate) }}
          name='newPolicy.starts'
        />
        <ErrorMessage errors={errors} name='newPolicy.starts' as={TranslatedErrorMessage} />
      </FormCell>
      <FormCell className='datepicker-fullwidth' id='newPolicy-expires'>
        <label>{t('crmInsurance.newPolicy.toDate')}:</label>
        <Controller
          render={({ onChange, value }) => (
            <DatePickerInput
              date={getDateValue(value)}
              onChange={onChange}
              timeDisabled={true}
              testId={`${testId}newPolicy-toDate`}
              disabled={readOnly}
            />
          )}
          control={control}
          rules={{ required: isExpiresFieldRequired() }}
          name='newPolicy.expires'
        />
        <ErrorMessage errors={errors} name='newPolicy.expires' as={TranslatedErrorMessage} />
      </FormCell>
    </>
  )
}
