import { ErrorMessage } from '@hookform/error-message'
import { FormSegment, Input } from 'actff-bo-app/Admin/Dealership/Users/Styled'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { Label } from 'actff-bo-app/components/EditableList/Styled'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { DealershipInsurer } from 'actff-bo-lib/admin/dealership'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

type Props = {
  readonly onAdd: (insurer: DealershipInsurer) => void,
  readonly open: boolean,
  readonly toggleOpen: () => void,
}

type Form = {
  readonly insurerName: string,
  readonly main: boolean,
}

const AddingDialogContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 30px;
  align-items: start;
  padding-bottom: 24px;
`

const MainLabel = styled.label`
  top: 6px;
`

const FormSegmentStyled = styled(FormSegment)`
  width: 100%;
`

export const NewInsurerDialog: FC<Props> = ({ onAdd, open, toggleOpen }) => {
  const { t } = useTranslation()

  const { errors, handleSubmit, register, control } = useForm<Form>({
    defaultValues: {
      insurerName: '',
      main: false,
    },
  })

  const onDialogConfirm = handleSubmit(formValues => {
    onAdd(formValues)
    toggleOpen()
  })

  const onDialogClose = () => {
    toggleOpen()
  }

  return (
    <Dialog
      autoWidth={true}
      open={open}
      onConfirm={onDialogConfirm}
      onClose={onDialogClose}
      title='admin.dealership.insurers.addInsurer.dialog.title'
    >
      <AddingDialogContent>
        <FormSegmentStyled>
          <Label>{t('admin.dealership.insurers.insurerName')}:</Label>
          <Input
            type='text'
            name='insurerName'
            ref={register({
              ...getValidator({ type: ValidationType.Required }),
            })}
          />
          <ErrorMessage errors={errors} name='insurerName' as={TranslatedErrorMessage} />
        </FormSegmentStyled>
        <FormSegmentStyled>
          <Label>{t('admin.dealership.insurers.main')}:</Label>
          <Controller
            render={({ value, onChange }) =>
              <input
                checked={value}
                id='main'
                onChange={() => { onChange(!value) }}
                type='checkbox'
              />}
            control={control}
            name='main'
          />
          <MainLabel htmlFor='main' />
        </FormSegmentStyled>
      </AddingDialogContent>
    </Dialog>
  )
}
