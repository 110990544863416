import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'

import { CrmInsuranceAction, CrmInsuranceActionType } from '../actions'
import { getReport } from '../dao'

const getReportEpic: Epic<CrmInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.getReport>>(CrmInsuranceActionType.GetReport),
  switchMap(({ payload }) => getReport(payload.type, payload.dateRange).pipe(
    map(CrmInsuranceAction.getReportSuccess),
    catchError((error: AjaxError) => of(CrmInsuranceAction.getReportFailure(error))),
  )),
)

export const crmInsuranceReportEpic = combineEpics(
  getReportEpic,
)
