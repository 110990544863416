import { CarTransmissionType, ratingFactor } from 'actff-bo-lib/car'
import { ClientPreferences } from 'actff-bo-lib/client'
import { ListFilter } from 'actff-bo-lib/crm/trade/dto'
import { noCentsDivider } from 'actff-bo-lib/currency'
import { CarModel } from 'actff-bo-lib/dealership'
import { getObjectFiltersAsArrayString, mapOptionsToArray, SelectOption } from 'actff-bo-lib/global'
import queryString, { StringifyOptions } from 'query-string'

export type DetailedFilters = {
  readonly brands: ReadonlyArray<ListFilter<SelectOption<string| null>>>,
  readonly opportunityType: ReadonlyArray<ListFilter<boolean>>,
  readonly opportunityDate: ReadonlyArray<ListFilter<string | null>>,
  readonly financeEnd: ReadonlyArray<ListFilter<string | null>>,
  readonly origin: ReadonlyArray<ListFilter<boolean>>,
  readonly clientSource: ReadonlyArray<ListFilter<boolean>>,
  readonly hasInspection: ReadonlyArray<ListFilter<boolean>>,
  readonly models: ReadonlyArray<ListFilter<SelectOption<CarModel| null>>>,
  readonly engine: ReadonlyArray<ListFilter<SelectOption<string| null>>>,
  readonly productionYear: ReadonlyArray<ListFilter<string | null>>,
  readonly mileage: ReadonlyArray<ListFilter<string | null>>,
  readonly plannedSale: ReadonlyArray<ListFilter<string | null>>,
  readonly price: ReadonlyArray<ListFilter<string | null>>,
  readonly ratings: ReadonlyArray<ListFilter<SelectOption<string | null>>>,
  readonly transmission: ReadonlyArray<ListFilter<SelectOption<CarTransmissionType | null>>>,
  readonly version: ReadonlyArray<ListFilter<SelectOption<string| null>>>,
  readonly newCarOffer: ReadonlyArray<ListFilter<boolean>>,
  readonly saleOpportunitiesCount: ReadonlyArray<ListFilter<boolean>>,
}

export type DetailedFiltersFormValues = {
  readonly brands?: ReadonlyArray<SelectOption<string>>,
  readonly own?: boolean,
  readonly country?: boolean,
  readonly hasInspection?: boolean,
  readonly hasNotInspection?: boolean,
  readonly importFilter?: boolean,
  readonly engine?: ReadonlyArray<SelectOption<string>>,
  readonly models?: ReadonlyArray<SelectOption<string>>,
  readonly plannedSaleMin?: Date | null,
  readonly plannedSaleMax?: Date | null,
  readonly financeEndMin?: Date | null,
  readonly financeEndMax?: Date | null,
  readonly ratings?: ReadonlyArray<SelectOption<number>>,
  readonly priceMin?: SelectOption<string> | null,
  readonly priceMax?: SelectOption<string> | null,
  readonly productionYearMin?: SelectOption<Date> | null,
  readonly productionYearMax?: SelectOption<Date> | null,
  readonly from?: Date | null,
  readonly fromFinance?: boolean,
  readonly to?: Date | null,
  readonly mileageMin?: SelectOption<number> | null,
  readonly mileageMax?: SelectOption<number> | null,
  readonly tradePotential?: boolean,
  readonly transmission?: ReadonlyArray<SelectOption<CarTransmissionType>>,
  readonly version?: ReadonlyArray<SelectOption<CarTransmissionType>>,
}

const skipFalseValueFilters = (filters: DetailedFiltersFormValues | null) => filters ?
  Object.entries(filters).reduce((prev, [key, value]) => ({
    ...prev,
    ...(value && { [key]: value }),
  }), {}) : {}

const multiplyMoneyValue = (value: string | number | null | undefined): string => value ? (Number(value) * noCentsDivider).toString() : ''

const maxRatingFilterValue = 5
const halfRatingFilterValue = 0.5

const getRatings = (selectedFilterRatings: ReadonlyArray<SelectOption<number>> | undefined) => selectedFilterRatings &&
  mapOptionsToArray<number>(selectedFilterRatings)?.reduce((prev, curr) =>
  curr < maxRatingFilterValue ? [...prev, curr, curr + halfRatingFilterValue] : [...prev, curr], [])
    .map(rating => rating * ratingFactor)

export const normalizeDetailedFilters = (filters: DetailedFiltersFormValues | null) => {
  if (!filters) { return {} }
  const { own, country, importFilter, ...restFilters } = filters

  const {
    brands,
    engine,
    models,
    from,
    to,
    financeEndMin,
    financeEndMax,
    ratings,
    mileageMin,
    mileageMax,
    plannedSaleMin,
    plannedSaleMax,
    priceMax,
    priceMin,
    productionYearMin,
    productionYearMax,
    transmission,
    version,
  } = filters

  return {
    ...skipFalseValueFilters(restFilters),
    brands: mapOptionsToArray<string>(brands),
    engine: mapOptionsToArray<string>(engine),
    financeEndMax: financeEndMax?.toISOString(),
    financeEndMin: financeEndMin?.toISOString(),
    from: from?.toISOString(),
    mileageMax: mileageMax?.value,
    mileageMin: mileageMin?.value,
    models: mapOptionsToArray<CarModel>(models),
    origin: getObjectFiltersAsArrayString({ own, country, import: importFilter }),
    plannedSaleMax: plannedSaleMax?.toISOString(),
    plannedSaleMin: plannedSaleMin?.toISOString(),
    priceMax: multiplyMoneyValue(priceMax?.value),
    priceMin: multiplyMoneyValue(priceMin?.value),
    productionYearMax: productionYearMax?.value,
    productionYearMin: productionYearMin?.value,
    ratings: getRatings(ratings),
    to: to?.toISOString(),
    transmission: mapOptionsToArray<CarTransmissionType>(transmission),
    version: mapOptionsToArray<CarTransmissionType>(version),
  }
}

const getFalseableFilters = (filters: DetailedFiltersFormValues | null): string | null => {
  if (!filters) {
    return ''
  }

  const stringifyOptions: StringifyOptions = {
    skipEmptyString: true,
    sort: false,
  }

  const { hasInspection, hasNotInspection, fromFinance, tradePotential } = filters
  const hasSelectedOpportunityTypeFilter = () => fromFinance || tradePotential
  const hasSelectedInspectionFilter = () => hasInspection || hasNotInspection

  return queryString.stringify({
    ...( hasSelectedOpportunityTypeFilter() ? { fromFinance } : {}),
    ...(hasSelectedInspectionFilter() ? { hasInspection } : {}),
  }, stringifyOptions)
}

export const stringifyDetailedFilters = (filters: DetailedFiltersFormValues | null): string => {
  if (!filters) {
    return ''
  }

  const { hasInspection, hasNotInspection, fromFinance, tradePotential, ...restFilters } = filters

  return `${queryString.stringify(normalizeDetailedFilters(restFilters), {
    skipEmptyString: true,
    skipNull: true,
    sort: false,
  })}&${getFalseableFilters(filters)}`
}

function skipNullFormRangeValue<T>(value: T | null | undefined, filterName: string): {} {
  return value ? { [filterName]: value } : {}
}

export const normalizePreferencesAsDetailedFilters = (preferences: ClientPreferences | null) => {
  if (!preferences) { return {} }

  const { brands, mileage, productionYear, price, purchaseDate, transmissions, modelVersions, fuelTypes, ...restPreferences } = preferences

  return {
    ...restPreferences,
    ...(skipNullFormRangeValue<string | null>(mileage.to, 'mileageMax')),
    ...(skipNullFormRangeValue<string | null>(mileage.from, 'mileageMin')),
    ...(skipNullFormRangeValue<string | null>(productionYear.from, 'productionYearMin')),
    ...(skipNullFormRangeValue<string | null>(productionYear.to, 'productionYearMax')),
    ...(skipNullFormRangeValue<string | null>(purchaseDate?.from && new Date(purchaseDate.from).toISOString(), 'plannedSaleMin')),
    ...(skipNullFormRangeValue<string | null>(purchaseDate?.to && new Date(purchaseDate.to).toISOString(), 'plannedSaleMax')),
    ...(skipNullFormRangeValue<string | null>(multiplyMoneyValue(price.from), 'priceMin')),
    ...(skipNullFormRangeValue<string | null>(multiplyMoneyValue(price.to), 'priceMax')),
    // TODO unify namings for Preferences and Purchase filters
    brands,
    fuelType: fuelTypes,
    transmission: transmissions,
    version: modelVersions,
  }
}
