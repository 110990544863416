import { ErrorMessage } from '@hookform/error-message'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { AdminDealerSettings } from 'actff-bo-lib/admin/dealer-locations'
import { phoneRegExp } from 'actff-bo-lib/form'
import { hasErrors } from 'actff-bo-lib/global/data-types'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { H2 } from 'styles'

import { Form, FormCell, Header } from '../../Styled'

type Props = {
  readonly dealerSettings: AdminDealerSettings,
  readonly onSubmitForm: (dealership: AdminDealerSettings) => void,
}

type AdminDealershipFormType = Pick<AdminDealerSettings, 'address' | 'contact'>

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  padding-bottom: 82px;
`

export const ContactDetailsForm: FC<Props> = ({ dealerSettings, onSubmitForm }) => {
  const { t } = useTranslation()

  const {
    errors,
    formState,
    register,
    reset,
    handleSubmit,
  } = useForm<AdminDealershipFormType>({
    defaultValues: {},
    mode: 'onSubmit',
  })

  useEffect(() => {
    reset(dealerSettings)
  }, [dealerSettings])

  const onSubmit = (formValues: AdminDealershipFormType) => {
    const submitData = {
      ...dealerSettings,
      ...formValues,
    }

    onSubmitForm({
      ...submitData,
    })
  }

  const resetForm = () => { reset(dealerSettings) }
  const isSubmitFormDisabled = () => !formState.isDirty || hasErrors(errors)

  return (
    <Form>
      <Header><H2>{t('admin.dealerLocations.dealership.header')}</H2></Header>
      <Container>
        <FormCell>
          <label>{t('email')}:</label>
          <input type='text' name='contact.email' ref={register} />
        </FormCell>
        <FormCell>
          <label>{t('admin.dealerLocations.dealership.receptionPhoneNumber')}:</label>
          <input
            type='text'
            name='contact.receptionPhone'
            ref={register({ pattern: { value: phoneRegExp, message: 'caption.error.phoneNumber' } })}
          />
          <ErrorMessage errors={errors} name='contact.receptionPhone' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell>
          <label>{t('admin.dealerLocations.dealership.officePhoneNumber')}:</label>
          <input
            type='text'
            name='contact.officePhone'
            ref={register({ pattern: { value: phoneRegExp, message: 'caption.error.phoneNumber' } })}
          />
          <ErrorMessage errors={errors} name='contact.officePhone' as={TranslatedErrorMessage} />
        </FormCell>
        <div />

        <FormCell>
          <label>{t('postCode')}:</label>
          <input type='text' name='address.postcode' ref={register} />
        </FormCell>
        <FormCell>
          <label>{t('city')}:</label>
          <input type='text' name='address.city' ref={register} />
        </FormCell>
        <FormCell>
          <label>{t('street')}:</label>
          <input type='text' name='address.street' ref={register} />
        </FormCell>
        <FormCell>
          <label>{t('buildNo')}:</label>
          <input type='text' name='address.houseNumber' ref={register} />
        </FormCell>
      </Container>
      <FixedFooter isSaveDisabled={isSubmitFormDisabled()} onCancel={resetForm} onSave={handleSubmit(onSubmit)} />
    </Form>
  )
}
