// tslint:disable: ordered-imports Fullcalendar library must be imported before plugins
import FullCalendar, { DateSpanApi } from '@fullcalendar/react'
import { DatesSetArg, EventClickArg } from '@fullcalendar/common'
import { EventContentArg } from '@fullcalendar/core'
import plLocale from '@fullcalendar/core/locales/pl'
import interactionPlugin from '@fullcalendar/interaction'
import { ResourceLabelContentArg } from '@fullcalendar/resource-common'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { Loader } from 'actff-bo-app/components/Loader'
import {
  mapScheduleFormToDeleteRules,
  mapScheduleToEvents,
  mapScheduleToResources,
  mapSelectionToDefaultValues,
  mapSelectionToFormValues,
  CalendarResourceType,
  ScheduleRule,
  ScheduleRuleForm,
} from 'actff-bo-lib/admin/employee-scheduler'
import { deleteRules, getResourcesAvailability, getShifts } from 'actff-bo-lib/admin/employee-scheduler/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { addWeeks, startOfToday } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useToggle } from 'react-use'
import { H2, Header } from 'styles'

import { schedulerHeaderToolbarConfig, schedulerInitialView, resourceAreaWidth, resourceWeekWithoutTime } from './config'
import { getSelectedEvents, markSelectedEvents } from './event-selector'
import { EmployeeScheduleFooter } from './EmployeeScheduleFooter'
import { EventContent } from './EventContent'
import { Form } from './Form/Form'
import { ResourceContent } from './ResourceContent/ResourceContent'
import { Scheduler } from './Styled'

const testId = 'admin-view-scheduler__'

const resourceIsNotParent = (resourceId?: string) =>
  !!resourceId && !(resourceId in CalendarResourceType)

const selectAllow = (span: DateSpanApi) =>
  resourceIsNotParent(span.resource?._resource.id)

export const EmployeeSchedule: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: shifts } = useQuery(QueryKeys.GET_SHIFTS, async () =>
    await getShifts(), { retry: false },
  )

  const defaultFromDate = startOfToday()
  const defaultToDate = addWeeks(defaultFromDate, 1)

  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)

  const [formOpened, toggleFormOpened] = useToggle(false)
  const [formValues, setFormValues] = useState<ScheduleRuleForm>()

  const [selectedEventIds, setSelectedEventIds] = useState<ReadonlyArray<number>>([])

  const { data: schedule, refetch, isLoading, isError } = useQuery(QueryKeys.SCHEDULE, async () =>
    await getResourcesAvailability(fromDate, toDate),
    { retry: false },
  )

  useEffect(() => { refetch() }, [fromDate, toDate])

  const removeEvents = async (rulesData?: ScheduleRule) => {
    try {
      await deleteRules(rulesData)
      dispatch(displaySuccessToast('admin.employeeSchedule.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      clearSelection()
      await refetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.employeeSchedule.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  const resources = mapScheduleToResources(schedule, t)

  const eventsFromSchedule = mapScheduleToEvents(shifts, schedule)
  const events = markSelectedEvents(selectedEventIds, eventsFromSchedule)

  const clearSelection = () => setSelectedEventIds([])

  const handleDatesSet = (data: DatesSetArg) => {
    const startDate = new Date(data.startStr)
    const endDate = new Date(data.endStr)

    setFromDate(startDate)
    setToDate(endDate)
  }

  const handleSingleEventClick = (index: number) => {
    if (index !== selectedEventIds[0]) {
      setSelectedEventIds([index])
    }

    // clear selection
    if (index === selectedEventIds[0]) {
      setSelectedEventIds([])
    }
  }

  const handleEventClick = (eventData: EventClickArg) => {
    const id = eventData.event.extendedProps.eventId
    const index = events?.findIndex(event => event.extendedProps.eventId === id) || 0
    const currentlySelectedIds: ReadonlyArray<number> = [...selectedEventIds, index]

    if (eventData.jsEvent.shiftKey) {
      setSelectedEventIds(currentlySelectedIds)
    } else {
      handleSingleEventClick(index)
    }

    const selectedEvents = getSelectedEvents(currentlySelectedIds, events)
    const editableValues = mapSelectionToFormValues(shifts, selectedEvents)
    setFormValues(editableValues)
  }

  const handleRemoveSelected = async () => {
    const rulesToRemove = mapScheduleFormToDeleteRules(formValues)

    await removeEvents(rulesToRemove)
  }

  const handleRemovePeriod = async (resourceId: string) => {
    const filteredByResource = events?.filter(event => event.resourceId === resourceId)
    const rulesToRemove = mapScheduleFormToDeleteRules(mapSelectionToFormValues(shifts, filteredByResource))

    await removeEvents(rulesToRemove)
  }

  const renderResourceContent = (resourceData: ResourceLabelContentArg) => (
    <ResourceContent resourceData={resourceData} onRemoveSchedule={handleRemovePeriod} onRefetch={refetch} testId={testId} />
  )

  const renderEventContent = (eventData: EventContentArg) => <EventContent eventData={eventData} />

  const renderFooter = () => selectedEventIds.length > 0 && (
    <EmployeeScheduleFooter onFormOpen={toggleFormOpened} onRemoveEvents={handleRemoveSelected} testId={testId} />
  )

  const renderForm = () => formOpened && (
    <Form
      formValues={formValues}
      toggleOpen={toggleFormOpened}
      resources={resources}
      shifts={shifts}
      onRefetch={refetch}
      onClearSelection={clearSelection}
      testId={testId}
    />
  )

  if (isLoading) { return <Loader /> }

  if (isError) {
    dispatch(displayFailureToast('admin.employeeSchedule.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <Scheduler>
        <Header>
          <H2>{t('admin.employeeSchedule')}</H2>
        </Header>
        <FullCalendar
          initialView={schedulerInitialView}
          views={resourceWeekWithoutTime(t)}
          plugins={[ resourceTimelinePlugin, interactionPlugin ]}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          resourceLabelContent={renderResourceContent}
          resources={resources}
          events={events}
          headerToolbar={schedulerHeaderToolbarConfig}
          resourceAreaWidth={resourceAreaWidth}
          locale={plLocale}
          datesSet={handleDatesSet}
          selectable={true}
          selectAllow={selectAllow}
          selectOverlap={false}
          select={event => {
            setFormValues(mapSelectionToDefaultValues(event))
            toggleFormOpened()
          }}
        />
      </Scheduler>
      {renderFooter()}
      {renderForm()}
    </>
  )
}
