import { Icon } from 'actff-bo-app/components/Icon'
import { Client, ClientBasicData, ClientType as ClientTypeType } from 'actff-bo-lib/client/dto'
import { IconType } from 'actff-bo-lib/menu/dto'
import { Map } from 'immutable'
import React, { FC } from 'react'
import styled from 'styled-components'

type TypeIconsProps = {
  readonly person: Client | ClientBasicData,
  readonly className?: string,
}

const TypeContainer = styled.div`
  img {
    margin-right: 5px;
  }
`

export const TypeIconMap: Map<ClientTypeType, IconType> = Map([
  [ClientTypeType.VIP, IconType.Crown],
  [ClientTypeType.Attention, IconType.Flag],
]) as Map<ClientTypeType, IconType>

export const TypeIcons: FC<TypeIconsProps> = ({ className, person }) => (
  <TypeContainer className={className}>
    {person.type.map((type => <Icon key={type} type={TypeIconMap.get(type, IconType.Flag)} />))}
  </TypeContainer>
)
