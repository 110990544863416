import { MultipleSelect } from 'actff-bo-app/components/MultipleSelect'
import { SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

import { Label } from './Styled'

type MultipleSelectFilterProps = Testable & {
  readonly closeMenuOnSelect?: boolean,
  readonly control: Control,
  readonly disabled?: boolean,
  readonly gridArea?: string;
  readonly label?: string,
  readonly maxWidth?: number,
  readonly menuPlacement?: string,
  readonly minWidth?: number,
  readonly name: string,
  readonly onChange?: (values: ReadonlyArray<SelectOption<string | null>> | null) => void,
  readonly options?: ReadonlyArray<SelectOption<string | null>> | null,
  readonly rules?: object,
  readonly shouldTranslate?: boolean,
  readonly tOptionKey?: string,
  readonly trimLength?: number,
}

const Container = styled.div`
  display: grid;
  grid-row-gap: 10px;
  max-width: 310px;
`

const MinWidth = 310
const MaxWidth = 310

export const MultipleSelectFilter: FC<MultipleSelectFilterProps> = ({
  closeMenuOnSelect = false,
  control,
  disabled,
  gridArea,
  label,
  maxWidth = MaxWidth,
  menuPlacement,
  minWidth = MinWidth,
  name,
  options,
  onChange,
  rules,
  shouldTranslate = false,
  testId,
  tOptionKey,
  trimLength,
}): React.ReactElement | null => {
  const { t } = useTranslation()
  const optionProps = { shouldTranslate, t, tOptionKey }

  if (!options) { return null }

  return (
    <Container gridArea={gridArea}>
      {label && <Label color={colors.black} uppercase={false}>{t(label)}:</Label>}
      <Controller
        as={<MultipleSelect onValueChange={onChange} {...optionProps} />}
        autoBlur={false}
        autoFocus={true}
        closeMenuOnSelect={closeMenuOnSelect}
        control={control}
        hideSelectedOptions={false}
        isClearable={true}
        isDisabled={disabled}
        isMulti={true}
        maxWidth={maxWidth}
        menuPlacement={menuPlacement}
        minWidth={minWidth}
        name={name}
        options={options}
        placeholder={t('placeholder.choose')}
        rules={rules}
        shouldTranslate={true}
        testId={`${testId}-${name}`}
        trimFilter={true}
        trimLength={trimLength}
      />
    </Container>
  )
}
