import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import {
  FieldValue,
  FormCell,
  FormCellFullWidth,
  FormCellWide,
  FormFooterLeft,
  FormHeader,
  FormWrapper,
  PrimaryCell,
} from 'actff-bo-app/components/Form'
import { CarInfoWithClient, defaultBrakeLiquid } from 'actff-bo-lib/car'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { DictionaryType, getDictionaryDataLabel } from 'actff-bo-lib/dictionary'
import { Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { history } from 'actff-bo-lib/router'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { CarModel } from '../../../../actff-bo-lib/dealership'

import { CarDocumentsViewer } from './CarDocumentsViewer'

export type Props = {
  readonly car: CarInfoWithClient,
  readonly carAttachments: ReadonlyArray<string> | null,
  readonly carModels: ReadonlyArray<DictionaryType<CarModel>>,
  readonly fuelTypes: ReadonlyArray<DictionaryType<string>>,
  readonly oilTypes: ReadonlyArray<DictionaryType<string>>,
}

type CarViewServiceReadonlyComponentType = Props & WithTranslation

const handleGoBackClick = () => {
  history.push(Paths.CarList)
}

const testId = 'car-view-service__'

const CarViewServiceReadonlyComponent: React.FC<CarViewServiceReadonlyComponentType> = ({
  car,
  carAttachments,
  fuelTypes,
  t,
}) => (
  <FormWrapper>
    <FormCellWide>
      <label>{t('carView.form.DMS')}</label>
      <FieldValue id='DMS' data-testid={`${testId}dms`}>{t(car.dms ? 'caption.yes' : 'caption.no')}</FieldValue>
    </FormCellWide>
    <CarDocumentsViewer carAttachments={carAttachments} />
    <FormCellWide slim={true}>
      <FormHeader>{t('carView.form.carDetails')}</FormHeader>
    </FormCellWide>
    <FormCell>
      <label htmlFor='registration'>{t('carView.form.registration')}</label>
      <FieldValue id='registration' data-testid={`${testId}registration`}>{car.registrationNumber}</FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='vin'>{t('carView.form.vin')}</label>
      <FieldValue id='vin' data-testid={`${testId}vin`}>{car.vin}</FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='carModel'>{t('carView.form.carModel')}</label>
      <FieldValue id='carModel' data-testid={`${testId}car-model`}>{car.model}</FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='carVersion'>{t('carView.form.carVersion')}</label>
      <FieldValue id='carVersion' data-testid={`${testId}car-version`}>{car.version}</FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='firstRegistrationDate'>{t('carView.form.firstRegistrationDate')}</label>
      <FieldValue id='firstRegistrationDate' data-testid={`${testId}first-registration-date`}>
        <FormattedDate date={car.firstRegistrationDate} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='engineCode'>{t('carView.form.engineCode')}</label>
      <FieldValue id='engineCode' data-testid={`${testId}engine-code`}>{car.engineCode}</FieldValue>
    </FormCell>

    <FormCell slim={true}>
      <FormHeader>{t('carView.form.service.technicalExamination')}</FormHeader>
    </FormCell>

    <FormCell slim={true}>
      <FormHeader>{t('carView.form.service.carService')}</FormHeader>
    </FormCell>

    <FormCell>
      <label htmlFor='mot'>{t('carView.form.service.mot')}</label>
      <FieldValue id='mot' data-testid={`${testId}mot`}>
        <FormattedDate date={car.serviceDetails.mot} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='lastServiceDate'>{t('carView.form.service.lastServiceDate')}</label>
      <FieldValue id='lastServiceDate' data-testid={`${testId}last-service-date`}>
        <FormattedDate date={car.serviceDetails.lastServiceDate} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>

    <FormCellFullWidth slim={true}>
      <FormHeader>{t('carView.form.service.liquids')}</FormHeader>
    </FormCellFullWidth>
    <FormCell>
      <label>{t('carView.form.service.brakeLiquid')}</label>
      <span id='brakeLiquid' data-testid={`${testId}brake-liquid`}>{defaultBrakeLiquid}</span>
    </FormCell>
    <FormCell>
      <label htmlFor='engineOil'>{t('carView.form.service.engineOil')}</label>
      <FieldValue id='engineOil' data-testid={`${testId}engine-oil`}>{car.serviceDetails.liquids.engineOil}</FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='fuel'>{t('carView.form.service.fuel')}</label>
      <FieldValue id='fuel' data-testid={`${testId}fuel`}>{getDictionaryDataLabel<string>(fuelTypes, car.fuel, t)}</FieldValue>
    </FormCell>
    <FormCell />

    <FormCellFullWidth slim={true}>
      <FormHeader>{t('carView.form.service.guarantee')}</FormHeader>
    </FormCellFullWidth>
    <FormCell>
      <label htmlFor='carReleaseDate'>{t('carView.form.service.carReleaseDate')}</label>
      <FieldValue id='carReleaseDate' data-testid={`${testId}car-release-date`}>
        <FormattedDate date={car.carReleaseDate} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='basicGuaranteeExpirationDate'>{t('carView.form.service.basicGuaranteeExpirationDate')}</label>
      <FieldValue id='basicGuaranteeExpirationDate' data-testid={`${testId}basic-guarantee-expiration-date`}>
        <FormattedDate date={car.serviceDetails.guarantee.basicGuaranteeExpirationDate} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='extendedGuaranteeExpirationDate'>{t('carView.form.service.extendedGuaranteeExpirationDate')}</label>
      <FieldValue id='extendedGuaranteeExpirationDate' data-testid={`${testId}extended-guarantee-expiration-date`}>
        <FormattedDate date={car.serviceDetails.guarantee.extendedGuaranteeExpirationDate} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>

    <PrimaryCell>
      <FormCell>
        <label htmlFor='dealerGuaranteeExpirationDate'>{t('carView.form.service.dealerGuaranteeExpirationDate')}</label>
        <FieldValue id='dealerGuaranteeExpirationDate' data-testid={`${testId}dealer-guarantee-expiration-date`}>
          <FormattedDate date={car.serviceDetails.guarantee.dealerGuaranteeExpirationDate} format={defaultDateFormat} />
        </FieldValue>
      </FormCell>

      <FormCell>
        <label htmlFor='dealerGuaranteeVisible'>{t('carView.form.service.dealerGuaranteeVisible')}</label>
        <FieldValue id='dealerGuaranteeVisible' data-testid={`${testId}dealer-guarantee-visible`}>
          {t(car.serviceDetails.guarantee.dealerGuaranteeVisible ? 'caption.yes' : 'caption.no')}
        </FieldValue>
      </FormCell>
    </PrimaryCell>

    <FixedFooter>
      <FormFooterLeft>
        <ButtonWithIcon
          onClick={handleGoBackClick}
          type={BtnType.Button}
          caption={t('caption.back')}
          iconType={IconType.Back}
          testId={`${testId}go-back`}
          theme={BtnTheme.Link}
        />
      </FormFooterLeft>
    </FixedFooter>
  </FormWrapper>
)

export const CarViewServiceReadonly = compose(
  withTranslation(),
)(CarViewServiceReadonlyComponent)
