import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { colors } from 'styles'

export const CommentsContainer = styled(FormCell)`
  padding-right: 32px;
`
export const AddButton = styled(ButtonWithIcon)`
  justify-self: start;
`
export const FooterContainer = styled.div`
  align-items: end;
  display: grid;
  grid-auto-flow: column;
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 100px 100px;
  justify-content: end;
`

export const Textarea = styled.textarea`
  border-radius: 3px;
  border: 1.5px solid ${colors.mysticGray};
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
  margin-top: 30px;
  min-height: 100px;
  outline: none;
  resize: vertical;
  width: 100%;
`
