import { Filters } from 'actff-bo-app/components/Filters'
import { HeaderLeft } from 'actff-bo-app/components/Grid'
import { ListBrandFiltersForm } from 'actff-bo-app/components/ListFilters/ListBrandFilters'
import { ListFilters } from 'actff-bo-app/components/ListFilters/ListFilters'
import { ListLocationsFiltersForm } from 'actff-bo-app/components/ListFilters/ListLocationsFilters'
import { Loader } from 'actff-bo-app/components/Loader'
import { Search } from 'actff-bo-app/components/Search'
import { NoItems, WithPagination } from 'actff-bo-app/components/Table'
import { PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { Filters as FiltersType } from 'actff-bo-lib/global'
import { Path, Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State } from 'actff-bo-lib/store'
import {
  selectIsLoadingTelephoneRequests,
  selectNewTelephoneRequestsCount,
  selectTelephoneRequestBrandFilters,
  selectTelephoneRequestCurrentPage,
  selectTelephoneRequestDealerLocationFilters,
  selectTelephoneRequestListFilters,
  selectTelephoneRequests,
  selectTelephoneRequestSearchPhrase,
  TelephoneRequest,
  TelephoneRequestAction,
  TelephoneRequestFilterValues,
} from 'actff-bo-lib/telephone-request'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { H2, HeaderFiltersContainer, HeaderList, HeaderSearch, Small } from 'styles'

import { TelephoneRequestTable } from './TelephoneRequestTable/TelephoneRequestTable'

type StateToProps = {
  readonly brandFilters: ReadonlyArray<string>,
  readonly currentPage: number,
  readonly dealerLocationFilters: ReadonlyArray<DealerLocationKey>,
  readonly loading: boolean,
  readonly newTelephoneRequestCount: number,
  readonly searchPhrase?: string,
  readonly telephoneRequestListFilters: FiltersType<TelephoneRequestFilterValues>,
  readonly telephoneRequests: PagedResult<TelephoneRequest> | null,
}

type DispatchToProps = {
  readonly getNewTelephoneRequestCount: () => void,
  readonly getTelephoneRequests: () => void,
  readonly onListFilterChange: (param: string, value: TelephoneRequestFilterValues) => void,
  readonly onBrandChange: (brands: ReadonlyArray<string>) => void,
  readonly onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => void,
  readonly onPageChange: (nextPage: number) => void,
  readonly onSearchPhraseChange: (searchPhrase?: string) => void,
}

type TelephoneRequestListProps = StateToProps & DispatchToProps & WithTranslation

const testId = 'telephone-request-list__'

class TelephoneRequestListComponent extends React.Component<TelephoneRequestListProps> {
  public componentDidMount(): void {
    this.props.getTelephoneRequests()
    this.props.getNewTelephoneRequestCount()
  }

  public render(): React.ReactNode {
    const {
      brandFilters,
      currentPage,
      dealerLocationFilters,
      loading,
      newTelephoneRequestCount,
      onBrandChange,
      onDealerLocationChange,
      onListFilterChange,
      onPageChange,
      onSearchPhraseChange,
      searchPhrase,
      telephoneRequests,
      telephoneRequestListFilters,
      t,
    } = this.props

    if (loading) {
      return <Loader />
    }

    return telephoneRequests
      && (
        <>
          <HeaderList>
            <H2>{t('telephoneRequests.header')}</H2>
            <Small>{t('telephoneRequest.newRequests', { count: newTelephoneRequestCount })}</Small>
            <HeaderFiltersContainer>
              <Filters filters={telephoneRequestListFilters} onFilterChange={onListFilterChange} testId={testId} />
              <HeaderLeft>
                <ListFilters
                  filtersCaption={t('caption.location')}
                  filtersComponent={filterRendererProps => <ListLocationsFiltersForm {...filterRendererProps} />}
                  filterValues={dealerLocationFilters}
                  onSubmit={onDealerLocationChange}
                />
                <ListFilters
                  filtersCaption={t('caption.brand')}
                  filtersComponent={filterRendererProps => <ListBrandFiltersForm {...filterRendererProps} />}
                  filterValues={brandFilters}
                  onSubmit={onBrandChange}
                />
              </HeaderLeft>
              <HeaderSearch>
                <Search
                  initialValues={{ searchPhrase }}
                  onSearch={onSearchPhraseChange}
                  testId={testId}
                />
              </HeaderSearch>
            </HeaderFiltersContainer>
          </HeaderList>
          {
            telephoneRequests.result.length ? (
              <WithPagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageCount={telephoneRequests.noOfPages}
                path={Paths.TelephoneRequests as Path}
                rowsInCurrentPage={telephoneRequests.result.length}
                testId={testId}
              >
                <TelephoneRequestTable telephoneRequests={telephoneRequests} testId={testId} />
              </WithPagination>
            ) : <NoItems icon={IconType.NoCars} text='brak' />
          }
        </>
      )
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = (state: State) => ({
  brandFilters: selectTelephoneRequestBrandFilters(state),
  currentPage: selectTelephoneRequestCurrentPage(state),
  dealerLocationFilters: selectTelephoneRequestDealerLocationFilters(state),
  loading: selectIsLoadingTelephoneRequests(state),
  newTelephoneRequestCount: selectNewTelephoneRequestsCount(state),
  searchPhrase: selectTelephoneRequestSearchPhrase(state),
  telephoneRequestListFilters: selectTelephoneRequestListFilters(state),
  telephoneRequests: selectTelephoneRequests(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = (dispatch: Dispatch) => ({
  getNewTelephoneRequestCount: () => { dispatch(TelephoneRequestAction.getNewTelephoneRequestCount()) },
  getTelephoneRequests: () => { dispatch(TelephoneRequestAction.getTelephoneRequests()) },
  onBrandChange: (brands: ReadonlyArray<string>) => { dispatch(TelephoneRequestAction.changeBrands(brands)) },
  onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => {
    dispatch(TelephoneRequestAction.changeDealerLocations(locations))
  },
  onListFilterChange: (param: string, value: TelephoneRequestFilterValues) => {
    dispatch(TelephoneRequestAction.changeListFilter(param, value))
  },
  onPageChange: (nextPage: number) => { dispatch(TelephoneRequestAction.changePage(nextPage)) },
  onSearchPhraseChange: (searchPhrase: string) => { dispatch(TelephoneRequestAction.changeSearchPhrase(searchPhrase)) },
})

export const TelephoneRequestList = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(TelephoneRequestListComponent)
