import { getDealershipInsurers } from 'actff-bo-lib/admin/dealership'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import React from 'react'
import { useQuery } from 'react-query'

import { insurersToSelectOptions } from '../../OpportunityView/insurer-option-mapper'

import { ReportSelect } from './ReportSelect'

type Props = {
  readonly onChange: (value: string) => void,
  readonly value: string,
}

export const ReportInsurerSelect: React.FC<Props> = ({ onChange, value }) => {
  const { data: insurers } = useQuery(QueryKeys.GET_INSURERS, async () =>
      await getDealershipInsurers(),
    { retry: false, refetchOnWindowFocus: false },
  )

  return (
    <ReportSelect
      onChange={onChange}
      value={value}
      options={insurersToSelectOptions(insurers)}
    />
  )

}
