import { colors } from './colors'

export const theme = {
  button: {
    link: {
      active: {
        text: colors.dark,
      },
      disabled: {
        text: colors.athensGray,
      },
      hovered: {
        color: colors.darkKumera,
      },
      text: colors.kumera,
    },
    minor: {
      background: colors.white,
      border: colors.yellowOrange,
      disabled: {
        background: colors.athensGray,
        text: colors.dark,
      },
      text: colors.dark,
    },
    primary: {
      background: colors.yellowOrange,
      disabled: {
        background: colors.athensGray,
        text: colors.dark,
      },
      hovered: {
        background: colors.darkerYellowOrange,
      },
      text: colors.dark,
    },
  },
  chat: {
    conversation: {
      background: {
        clientMessage: colors.athensGray,
        receptionMessage: colors.chathamsBlue,
      },
      border: colors.mysticGray,
      color: {
        clientMessage: colors.dark,
        receptionMessage: colors.white,
      },
    },
    list: {
      background: {
        active: colors.athensGray,
      },
      border: colors.mysticGray,
    },
  },
  form: {
    checkbox: {
      border: colors.nepalBlue,
      checked: colors.white,
      checkedBackground: colors.kumera,
      label: colors.dark,
    },
    error: colors.flamingoRed,
    footer: {
      background: colors.white,
      border: colors.athensGray,
    },
    input: {
      border: colors.mysticGray,
      text: colors.dark,
    },
    label: colors.dustyGray,
    primary: colors.athensGray,
    radio: {
      border: colors.nepalBlue,
      checked: colors.kumera,
      label: colors.dark,
    },
    select: {
      border: colors.mysticGray,
      color: colors.dark,
      disabled: colors.athensGray,
      hover: colors.swirlGold,
      hoverText: colors.white,
      indicator: colors.kumera,
      selected: colors.kumera,
    },
  },
  imageViewer: {
    background: colors.athensGray,
    button: colors.kumera,
  },
  logoContainer: {
    height: '60px',
  },
  menu: {
    background: {
      active: colors.chathamsBlue,
      primary: colors.midnightBlue,
    },
    indicator: colors.white,
    text: {
      active: colors.white,
      primary: colors.nepalBlue,
    },
  },
  pagination: {
    item: {
      background: {
        active: colors.swirlGold,
        primary: colors.white,
      },
      text: {
        primary: colors.dark,
      },
    },
  },
  table: {
    border: colors.mysticGray,
    group: {
      background: colors.athensGray,
    },
    header: {
      background: colors.mysticGray,
    },
    item: {
      background: {
        accent: colors.sprintWoodGold,
        danger: colors.provincialPink,
        primary: colors.white,
        success: colors.shamrock,
      },
      border: colors.mysticGray,
    },
  },
  tabs: {
    border: colors.mysticGray,
    item: {
      background: {
        active: colors.white,
        primary: colors.mysticGray,
      },
      border: {
        active: colors.mysticGray,
      },
    },
  },
  text: {
    primary: colors.dark,
  },
}
