import { CarId } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { NominalString } from 'actff-bo-lib/global'
import { Map } from 'immutable'

export type ThreadId = NominalString<Thread>

export const NewThreadId: ThreadId = 'new' as ThreadId

export enum ThreadType {
  ECHO = 'ECHO',
  THREAD = 'THREAD',
}

export enum ThreadStatus {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  RESPONDED = 'RESPONDED',
  CLOSED = 'CLOSED',
  NEW = 'NEW',
}

export const ThreadStatusNameMap: Map<ThreadStatus, string> = Map([
  [ThreadStatus.CLOSED, 'chat.thread.status.closed'],
  [ThreadStatus.RECEIVED, 'chat.thread.status.inprogress'],
  [ThreadStatus.RESPONDED, 'chat.thread.status.inprogress'],
  [ThreadStatus.SENT, 'chat.thread.status.inprogress'],
  [ThreadStatus.NEW, 'chat.thread.status.new'],
]) as Map<ThreadStatus, string>

export type Thread = {
  readonly threadUuid: ThreadId,
  readonly carUuid: CarId
  readonly client: Client,
  readonly lastMessage: string,
  readonly lastMessageTime: Date,
  readonly model: string,
  readonly registrationNumber: string,
  readonly status: ThreadStatus,
} & ThreadPayload

export type ThreadPayload = {
  readonly type: ThreadType,
}

export type ThreadBasicData = Pick<Thread, 'client' | 'carUuid' | 'registrationNumber' | 'model'> & Partial<Thread>

export const createThread = (data: any): Thread => ({ // tslint:disable-line
  ...data,
  ...(data.lastMessageTime
    ? { lastMessageTime: parseToDateOrNull(data.lastMessageTime) }
    : {}
  ),
})
