import { ClientFilters } from 'actff-bo-lib/client/dto'

export const filtersInitialState: ClientFilters = {
  carStatuses: {
    AllCarsApproved: false,
    NoCars: false,
    UnapprovedCars: false,
  },
  carsCountFrom: null,
  carsCountTo: null,
  clientStatuses: {
    Created: false,
    Deleted: false,
    Locked: false,
    Verified: false,
  },
  dms: null,
  marketingApprovals: {
    Email: false,
    PushNotifications: false,
    Sms: false,
  },
  registrationDateFrom: null,
  registrationDateTo: null,
  tradeApprovals: {
    Email: false,
    PushNotifications: false,
    Sms: false,
  },
  types: {
    Attention: false,
    Vip: false,
  },
}
