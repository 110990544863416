import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import {
  CheckboxInputField,
  DatePickerInputField,
  Field,
  FormCell,
  FormCellFullWidth,
  FormCellWide,
  FormHeader,
  FormWrapper,
  RadioButtonField,
  SelectField,
  YesNoInput,
} from 'actff-bo-app/components/Form'
import { insuranceValueCalculator } from 'actff-bo-app/components/Form/MarketValue/value-calculator'
import { ValidationError } from 'actff-bo-app/components/ValidationError'
import { getAssistance } from 'actff-bo-lib/admin/brands/dao'
import { getDealershipInsurers } from 'actff-bo-lib/admin/dealership'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { CarInsurance, checkIsCarRejected, ValueForInsuranceCalculation } from 'actff-bo-lib/car'
import { DatePickerInputPosition } from 'actff-bo-lib/date'
import { hasErrors, mapToOptions, mapValuesToSelectOptions } from 'actff-bo-lib/global'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { FormikProps } from 'formik'
import React, { FC, useEffect, useMemo } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { compose } from 'redux'

import { CarActionButtons } from '../CarActionButtons'
import { FormCheckboxGroup } from './Styled'
import { CarViewInsuranceFormProps, formik } from './withFormik'

type Props = FormikProps<CarInsurance> & WithTranslation & CarViewInsuranceFormProps

const testId = 'car-view-insurance-form__'

const CarViewInsuranceFormComponent: FC<Props> = ({
  car,
  deleteCar,
  errors,
  rejectCar,
  revertCar,
  setFieldValue,
  submitForm,
  t,
  touched,
  values,
}) => {
  const dispatch = useDispatch()

  const { data: insurers } = useQuery(QueryKeys.GET_INSURERS, async () =>
      await getDealershipInsurers(),
    { retry: false, refetchOnWindowFocus: false },
  )
  const mainInsurers = insurers?.filter(insurer => insurer.main).map(insurer => insurer.insurerName) ?? []

  const { data: assistance, isError } = useQuery([QueryKeys.GET_ASSISTANCE, car.uuid], async () =>
    await getAssistance([car.brand]), { retry: false, refetchOnWindowFocus: false },
  )

  const assistanceSelectOptions = useMemo(
    () => assistance && mapValuesToSelectOptions(assistance.map(element => element.assistanceName)),
    [assistance],
  )

  if (isError) {
    dispatch(displayFailureToast('admin.brands.assistance.get.failure', ToastActionType.GET))
  }

  useEffect(() => {
    if (values.marketValue && values.valueForInsuranceCalculation) {
      setFieldValue('insuranceValue', insuranceValueCalculator(values.marketValue, values.valueForInsuranceCalculation))
    }
  }, [values.valueForInsuranceCalculation, values.marketValue])

  return (
    <>
      <FormWrapper>
        <FormCellWide>
          <YesNoInput label={t('carView.form.insurance.inHouse')} name='inHouse' testId={`${testId}in-house`} />
          <ValidationError name='inHouse' testId={`${testId}in-house--error`} />
        </FormCellWide>
        <FormCellFullWidth slim={true}>
          <FormHeader>{t('carView.form.insurance.title')}</FormHeader>
        </FormCellFullWidth>
        <FormCell>
          <label htmlFor='inHouseInsurer'>{t('carView.form.insurance.inHouseInsurer')}</label>
          <Field
            component={SelectField}
            disabled={!values.inHouse}
            error={errors.assistanceInHouse?.insurer}
            id='inHouseInsurer'
            name='inHouseInsurer'
            options={mapToOptions(mainInsurers)}
            testId={`${testId}in-house-insurer`}
            touched={touched.assistanceInHouse?.insurer}
          />
          <ValidationError name='inHouseInsurer' testId={`${testId}in-house-insurer--error`} />
        </FormCell>
        <FormCell>
          <label>{t('carView.form.insurance.range')}</label>
          <FormCheckboxGroup>
            <Field
              component={CheckboxInputField}
              disabled={!values.inHouse}
              id='ac'
              label={t('carView.form.insurance.ac')}
              name='ac'
              slim={true}
              testId={`${testId}insurance--ac`}
            />
            <Field
              component={CheckboxInputField}
              disabled={!values.inHouse}
              id='oc'
              label={t('carView.form.insurance.oc')}
              name='oc'
              slim={true}
              testId={`${testId}insurance--oc`}
            />
            <Field
              component={CheckboxInputField}
              disabled={!values.inHouse}
              id='nnw'
              label={t('carView.form.insurance.nnw')}
              name='nnw'
              slim={true}
              testId={`${testId}insurance--nnw`}
            />
            <Field
              component={CheckboxInputField}
              disabled={!values.inHouse}
              id='assistance'
              label={t('carView.form.insurance.assistance')}
              name='assistance'
              slim={true}
              testId={`${testId}insurance--assistance`}
            />
          </FormCheckboxGroup>
        </FormCell>
        <FormCell />
        <FormCell />
        <FormCell>
          <label htmlFor='grossValue'>
            {t('carView.form.insurance.marketValue')}
          </label>
          <Field
            data-testid={`${testId}market-value`}
            disabled={!values.inHouse}
            error={errors.marketValue}
            id='marketValue'
            name='marketValue'
            placeholder='0'
            touched={touched.marketValue}
            type='text'
          />
        </FormCell>
        <FormCell>
          <label>{t('carView.form.insurance.valueForInsuranceCalculation')}</label>
          <div>
            <Field
              component={RadioButtonField}
              disabled={!values.inHouse}
              error={errors.valueForInsuranceCalculation}
              htmlFor={ValueForInsuranceCalculation.GROSS}
              id={ValueForInsuranceCalculation.GROSS}
              inline={true}
              label={t('carView.form.insurance.valueForInsuranceCalculation.gross')}
              name='valueForInsuranceCalculation'
              testId={`${testId}insurance-value-for-calculation--gross`}
              touched={touched.valueForInsuranceCalculation}
              value={ValueForInsuranceCalculation.GROSS}
            />
            <Field
              component={RadioButtonField}
              disabled={!values.inHouse}
              error={errors.valueForInsuranceCalculation}
              htmlFor={ValueForInsuranceCalculation.NET}
              id={ValueForInsuranceCalculation.NET}
              inline={true}
              label={t('carView.form.insurance.valueForInsuranceCalculation.net')}
              name='valueForInsuranceCalculation'
              testId={`${testId}insurance-value-for-calculation--net`}
              touched={touched.valueForInsuranceCalculation}
              value={ValueForInsuranceCalculation.NET}
            />
            <Field
              component={RadioButtonField}
              disabled={!values.inHouse}
              error={errors.valueForInsuranceCalculation}
              htmlFor={ValueForInsuranceCalculation.CUSTOM}
              id={ValueForInsuranceCalculation.CUSTOM}
              inline={true}
              label={t('carView.form.insurance.valueForInsuranceCalculation.custom')}
              name='valueForInsuranceCalculation'
              testId={`${testId}insurance-value-for-calculation--custom`}
              touched={touched.valueForInsuranceCalculation}
              value={ValueForInsuranceCalculation.CUSTOM}
            />
          </div>
        </FormCell>
        <FormCell>
          <label htmlFor='insuranceValue'>
            {t('carView.form.insurance.insuranceValue')}
          </label>
          <Field
            data-testid={`${testId}insurance-value`}
            disabled={true}
            id='insuranceValue'
            name='insuranceValue'
            placeholder='0'
            type='number'
          />
        </FormCell>
        <FormCell />
        <FormCell>
          <label htmlFor='inHousePolicyNumber'>{t('carView.form.insurance.inHousePolicyNumber')}</label>
          <Field
            data-testid={`${testId}in-house--policy-number`}
            disabled={!values.inHouse}
            error={errors.assistanceInHouse?.policyNumber}
            id='inHousePolicyNumber'
            name='inHousePolicyNumber'
            touched={touched.assistanceInHouse?.policyNumber}
            type='text'
          />
        </FormCell>
        <FormCell>
          <label htmlFor='inHouseExpirationDate'>{t('carView.form.insurance.inHouseExpirationDate')}</label>
          <Field
            component={DatePickerInputField}
            disabled={!values.inHouse}
            error={errors.assistanceInHouse?.expirationDate}
            id='inHouseExpirationDate'
            name='inHouseExpirationDate'
            testId={`${testId}in-house--expiration-date`}
            touched={touched.assistanceInHouse?.expirationDate}
          />
        </FormCell>
        <FormCellFullWidth>
          <YesNoInput
            label={t('carView.form.insurance.manufacturerAssistance')}
            name='manufacturerAssistance'
            testId={`${testId}manufacturer-assistance`}
          />
        </FormCellFullWidth>
        <FormCellFullWidth slim={true}>
          <FormHeader>{t('carView.form.insurance.manufacturerAssistance.title')}</FormHeader>
        </FormCellFullWidth>
        <FormCell>
          <label htmlFor='manufacturerInsurer'>{t('carView.form.insurance.manufacturerAssistance.company')}</label>
          <Field
            component={SelectField}
            disabled={!values.manufacturerAssistance}
            id='manufacturerInsurer'
            menuPlacement='top'
            name='manufacturerInsurer'
            options={assistanceSelectOptions}
            testId={`${testId}manufacturer--insurer`}
          />
        </FormCell>
        <FormCell>
          <label htmlFor='manufacturerExpirationDate'>{t('carView.form.insurance.manufacturerAssistance.expirationDate')}</label>
          <Field
            component={DatePickerInputField}
            disabled={!values.manufacturerAssistance}
            error={errors.expirationDate}
            id='manufacturerExpirationDate'
            name='manufacturerExpirationDate'
            position={DatePickerInputPosition.TOP}
            testId={`${testId}manufacturer--expiration-date`}
            touched={touched.expirationDate}
          />
        </FormCell>

      </FormWrapper>
      <FixedFooter>
        <CarActionButtons
          acceptDisabled={hasErrors(errors)}
          carId={car.uuid}
          inRejectedMode={checkIsCarRejected(car)}
          onAccept={submitForm}
          onReject={rejectCar}
          onDelete={deleteCar}
          onRevert={revertCar}
          testId={testId}
        />
      </FixedFooter>
    </>
  )
}

export const CarViewInsuranceForm = compose(
  formik,
  withTranslation(),
)(CarViewInsuranceFormComponent)
