import { buildSpecificFiltersUrl } from 'actff-bo-lib/client/build-specific-filters-url'
import { ClientFilters } from 'actff-bo-lib/client/dto'
import { DetailedFilters } from 'actff-bo-lib/crm/trade/purchase/filters/filters'

export type UrlParams = {
  readonly [key: string]: string | number | null | undefined | UrlParams,
}

type FiltersParam = ClientFilters | DetailedFilters | null

const objectToQueryParams = (queryParam: string, params: UrlParams) => Object.keys(params)
  .map(paramKey => `${queryParam}[${paramKey}]=${params[paramKey]}`)
  .join('&')

const getConnector = (url: string, currentUrl: string) => url === currentUrl ? '?' : '&'

export const buildUrl = (url: string, params: UrlParams = {}, filters: FiltersParam = null): string => {
  const filtersUrlString = buildSpecificFiltersUrl(filters)
  const filtersUrl = filtersUrlString === '&' ? '' : filtersUrlString
  const ValidFiltersUrl = filtersUrl || ''

  return Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null)
    .map(paramKey => typeof params[paramKey] === 'object'
      ? objectToQueryParams(paramKey, params[paramKey] as UrlParams)
      : `${paramKey}=${params[paramKey]}`,
    )
    .reduce(
      (urlWithParams, queryParam) => urlWithParams + getConnector(url, urlWithParams) + queryParam,
      url,
    ) + ValidFiltersUrl
}
