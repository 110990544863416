import { Language } from 'actff-bo-lib/i18n'

import * as plLocale from 'date-fns/locale/pl'
import { Map } from 'immutable'

export const LocaleMap: Map<Language, {}> = Map([
  [Language.pl, plLocale],
]) as Map<Language, {}>

export const defaultLocale = plLocale

export const getLocale = (currentLanguage: Language = Language.pl) => LocaleMap.get(currentLanguage, defaultLocale)
