import { DashboardData } from 'actff-bo-lib/dashboard/dto'
import { Chart as ChartJS, ChartColor, ChartDataSets, ChartType } from 'chart.js'
import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { colors, Opacity, withOpacity } from 'styles'

// Disable only readonly-array usage because of chart.js library which uses regular arrays in typings
// tslint:disable readonly-array
type Props = {
  readonly data: DashboardData;
  readonly type: ChartType,
  readonly dataColors?: ChartColor[],
  readonly title?: string,
  readonly legend?: ReadonlyArray<string>,
}

const CanvasContainer = styled.div`
  height: 100%;
  position: relative;
`

const defaultChartColors = [withOpacity(colors.midnightBlue, Opacity['70%']), withOpacity(colors.flamingoRed, Opacity['70%'])]

export const Chart: FC<Props> = ({ type, title, data, legend, dataColors }) => {
  const ref = useRef<HTMLCanvasElement>(null)
  // TODO: Refactor code below to more functional and non-mutating code
  const datasets = [] as ChartDataSets[]
  data.forEach(element => {
    element.values.forEach((value, index) => {
      datasets[index]
        // tslint:disable-next-line: no-object-mutation
        ? datasets[index] = ({
            ...datasets[index],
            data: [...datasets[index]?.data as [], value],
          })
        : datasets.push({
            backgroundColor: dataColors || defaultChartColors[index],
            data: [value],
            label: legend && legend[index],
          })
    })
  })

  useEffect(() => {
    const chart = ref.current && new ChartJS(ref.current, {
      data: {
        datasets,
        labels: data.map(element => element.label) as string[],
      },
      options: {
        legend: {
          display: !!legend,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
              stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        title: {
          display: !!title,
          text: title,
        },
        tooltips: {
          callbacks: {
            title: tooltipItem => tooltipItem.map(item => item.label?.replace(',', ' ')).join(' '),
          },
        },
      },
      type,
    })

    return () => {
      chart?.destroy()
    }
  })

  return <CanvasContainer><canvas ref={ref} /></CanvasContainer>
}
