import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { Attachment } from 'actff-bo-lib/global'
import { AjaxError } from 'rxjs/ajax'

import { Inspection, InspectionDto, InspectionId, InspectionListElement } from '../dto'

export enum CarInspectionActionType {
  ChangePage = '[CAR] INSPECTIONS CHANGE PAGE',

  ClearInspection = '[CAR] INSPECTION CLEAR',

  CreateInspectionAndSendAttachments = '[CAR] CREATE INSPECTION AND SEND ATTACHMENTS',
  CreateInspection = '[CAR] CREATE INSPECTION',
  CreateInspectionFailure = '[CAR] CREATE INSPECTION FAILURE',
  CreateInspectionSuccess = '[CAR] CREATE INSPECTION SUCCESS',

  GetInspection = '[CAR] GET INSPECTION',
  GetInspectionFailure = '[CAR] GET INSPECTION FAILURE',
  GetInspectionSuccess = '[CAR] GET INSPECTION SUCCESS',

  GetInspections = '[CAR] GET INSPECTIONS',
  GetInspectionsFailure = '[CAR] GET INSPECTIONS FAILURE',
  GetInspectionsSuccess = '[CAR] GET INSPECTIONS SUCCESS',

  SendInspectionAttachments = '[CAR] SEND INSPECTION ATTACHMENT',
  SendInspectionAttachmentsFailure = '[CAR] SEND INSPECTION ATTACHMENT FAILURE',
  SendInspectionAttachmentsSuccess = '[CAR] SEND INSPECTION ATTACHMENT SUCCESS',

  UpdateInspectionAndSendAttachments = '[CAR] UPDATE INSPECTION AND SEND ATTACHMENTS',
  UpdateInspection = '[CAR] UPDATE INSPECTION',
  UpdateInspectionFailure = '[CAR] UPDATE INSPECTION FAILURE',
  UpdateInspectionSuccess = '[CAR] UPDATE INSPECTION SUCCESS',
}

// tslint:disable object-literal-sort-keys
export const CarInspectionAction = {
  changePage: (page: number) => createAction(CarInspectionActionType.ChangePage, page),

  clearInspection: () => createAction(CarInspectionActionType.ClearInspection),

  createInspectionAndSendAttachments: (vin: string, inspection: InspectionDto, attachments?: ReadonlyArray<Attachment>) =>
    createAction(CarInspectionActionType.CreateInspectionAndSendAttachments, { attachments, inspection, vin }),
  createInspection: (vin: string, inspection: InspectionDto) =>
    createAction(CarInspectionActionType.CreateInspection, { inspection, vin }),
  createInspectionFailure: (error: AjaxError) => createAction(CarInspectionActionType.CreateInspectionFailure, error),
  createInspectionSuccess: (inspection: Inspection) =>
    createAction(CarInspectionActionType.CreateInspectionSuccess, inspection),

  getInspection: (vin: string, inspectionId?: InspectionId) =>
    createAction(CarInspectionActionType.GetInspection, { vin, inspectionId }),
  getInspectionFailure: (error: AjaxError) => createAction(CarInspectionActionType.GetInspectionFailure, error),
  getInspectionSuccess: (inspection: Inspection) =>
    createAction(CarInspectionActionType.GetInspectionSuccess, inspection),

  getInspections: () => createAction(CarInspectionActionType.GetInspections),
  getInspectionsFailure: (error: AjaxError) => createAction(CarInspectionActionType.GetInspectionsFailure, error),
  getInspectionsSuccess: (Inspections: PagedResult<InspectionListElement>) =>
    createAction(CarInspectionActionType.GetInspectionsSuccess, Inspections),

  sendInspectionAttachments: (
    attachments: ReadonlyArray<Attachment>,
    rest: { readonly vin: string, readonly inspection: InspectionDto, readonly inspectionId?: InspectionId },
  ) =>
    createAction(CarInspectionActionType.SendInspectionAttachments, { attachments, rest }),
  sendInspectionAttachmentsFailure: (error: AjaxError) => createAction(CarInspectionActionType.SendInspectionAttachmentsFailure, error),
  sendInspectionAttachmentsSuccess: (vin: string, inspection: InspectionDto, inspectionId?: InspectionId) =>
    createAction(CarInspectionActionType.SendInspectionAttachmentsSuccess, { inspection, vin, inspectionId }),

  updateInspectionAndSendAttachments: (
    vin: string,
    inspectionId: InspectionId,
    inspection: InspectionDto,
    attachments?: ReadonlyArray<Attachment>,
  ) =>
    createAction(CarInspectionActionType.UpdateInspectionAndSendAttachments, { attachments, inspectionId, inspection, vin }),
  updateInspection: (vin: string, inspectionId: InspectionId, inspection: InspectionDto) =>
    createAction(CarInspectionActionType.UpdateInspection, { inspectionId, inspection, vin }),
  updateInspectionFailure: (error: AjaxError) => createAction(CarInspectionActionType.UpdateInspectionFailure, error),
  updateInspectionSuccess: (inspection: Inspection) =>
    createAction(CarInspectionActionType.UpdateInspectionSuccess, inspection),
}

export type CarInspectionAction = ActionsUnion<typeof CarInspectionAction>
