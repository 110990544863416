import React from 'react'

type Props = {
  readonly onChange: (value: string) => void,
  readonly value: string,
}

export const ReportInput: React.FC<Props> = ({ onChange, value }) => (
  <div className='rdg-filter-container'>
    <input
      className='rdg-filter'
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  </div>
)
