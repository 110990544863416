import { ErrorMessage } from '@hookform/error-message'
import { CheckboxContainer } from 'actff-bo-app/components/Checkbox'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell, FormCellFullWidth, getDateValue, renderSelectController } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { getFullName } from 'actff-bo-lib/client'
import {
  OpportunityInsuranceId,
  OpportunityOfferDecision,
  OpportunityOfferStatus,
  OpportunityProcess,
  OpportunityResult,
  OpportunitySourceName,
  OpportunityStatus,
} from 'actff-bo-lib/crm/insurance'
import { defaultDateFormat, defaultShortDateFormat, getLocale } from 'actff-bo-lib/date'
import { selectDealerLocations } from 'actff-bo-lib/dealership'
import { getDealerLocationByKey } from 'actff-bo-lib/dealership/helpers/get-location'
import { SelectOption } from 'actff-bo-lib/global'
import { mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import { getTranslation, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { selectMeWithoutPermissions, selectUsers, UserAction, UserWithoutPermissions } from 'actff-bo-lib/user'
import { format } from 'date-fns'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'
import { SectionProcessFailureReasons } from './SectionProcessFailureReasons'

type Props = Testable
  & SectionFormType
  & {
    readonly process?: OpportunityProcess,
    readonly uuid?: OpportunityInsuranceId,
    readonly readOnly?: boolean,
  }

export const SectionProcess: React.FC<Props> = ({ control, errors, expanded, readOnly, register, process, setValue, testId, watch }) => {
  const { t } = useTranslation()
  const { data: userList } = useSelector(selectUsers)
  const currentLanguage = useSelector(selectCurrentLanguage)
  const currentUser = useSelector(selectMeWithoutPermissions)
  const { data: dealerLocations } = useSelector(selectDealerLocations)
  const locale = getLocale(currentLanguage)
  const dispatch = useDispatch()

  const getUserList = React.useMemo(() => () => { dispatch(UserAction.getUserList()) }, [dispatch])
  React.useEffect(() => { getUserList() }, [getUserList])

  const results = mapToOptions(Object.keys(OpportunityResult), 'crmInsurance.result', t)
  const sources = mapToOptions(Object.keys(OpportunitySourceName), 'crmInsurance.source', t)

  const mapDealerLocationsToSelectOptions = () =>
    dealerLocations?.map(location => ({ value: location.key, label: location.name })) || []

  const usersListOptions = userList?.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user,
  }))

  const clientsDecision = watch('offer.clientsDecision')

  const getActionDate = () => process?.actionDate
    ? format(process?.actionDate, defaultDateFormat, { locale })
    : t('crmInsurance.process.policyExpiredNeeded')

  const getCurrentUser = () => currentUser ? ({ label: getFullName(currentUser), value: currentUser }) : undefined
  const getMobileUserCheckboxKey = () => process?.inApp ? 'checked' : 'unchecked'

  React.useEffect(() => {
    clientsDecision === OpportunityOfferDecision.REJECTED && setValue('process.result', OpportunityResult.FAILURE)
  }, [clientsDecision, setValue])

  const notificationDateField = () => process?.pushSent
    ? (<strong>{t('crmInsurance.process.pushSent', { date: format(process.pushSent, defaultShortDateFormat) })}</strong>)
    : (
      <Controller
        render={({ onChange, value }) => (
          <DatePickerInput
            date={getDateValue(value)}
            disabled={readOnly}
            onChange={onChange}
            timeDisabled={true}
            testId={`${testId}process-notified`}
          />
        )}
        control={control}
        name='process.notified'
      />
    )

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.process'), !!errors.process)}>
      <SectionForm>
        <FormCell>
          <label>{t('crmInsurance.process.status')}:</label>
          <div>
            {getTranslation(
              t,
              'crmInsurance.status.',
              process?.status,
              t(`crmInsurance.status.${OpportunityStatus.UNKNOWN.toLowerCase()}`),
            )}
          </div>
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.offer.status')}:</label>
          <div>
            {getTranslation(
              t,
              'crmInsurance.offerStatus.',
              process?.offerStatus,
              t(`crmInsurance.offerStatus.${OpportunityOfferStatus.NOT_PREPARED.toLowerCase()}`),
            )}
          </div>
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.process.source')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...sources] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-sources`,
              transKey: 'crmInsurance.source',
            })}
            control={control}
            name='process.sourceName'
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.process.author')}:</label>
          <Controller
            render={renderSelectController<UserWithoutPermissions>({
              customValueMapper: (value: UserWithoutPermissions) => ({ value, label: getFullName(value) }),
              defaultValue: getCurrentUser(),
              disabled: readOnly,
              options: usersListOptions || [],
              t,
              testId: `${testId}-author`,
            })}
            control={control}
            name='process.author'
          />
        </FormCell>
        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.process.actionDate')}:</label>
          {getActionDate()}
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.process.assignedTo')}:</label>
          <Controller
            render={renderSelectController<UserWithoutPermissions>({
              customValueMapper: (value: UserWithoutPermissions) => ({ value, label: getFullName(value) }),
              defaultValue: getCurrentUser(),
              disabled: readOnly,
              options: usersListOptions || [],
              t,
              testId: `${testId}-assignee`,
            })}
            control={control}
            name='process.assignee'
          />
        </FormCell>
        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.process.notificationDate')}:</label>
          {notificationDateField()}
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.process.result')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly || clientsDecision === OpportunityOfferDecision.REJECTED,
              options: [...results] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-results`,
              transKey: 'crmInsurance.result',
            })}
            control={control}
            name='process.result'
            disabled={readOnly}
          />
        </FormCell>

        <SectionProcessFailureReasons
          register={register}
          watch={watch}
          setValue={setValue}
          errors={errors}
          control={control}
          readOnly={readOnly}
        />

        <FormCell id='process-locationKey'>
          <label>{t('crmInsurance.process.location')}:</label>
          <Controller
            render={renderSelectController({
              customValueMapper: (value: string) => {
                const dealerLocation = getDealerLocationByKey(value, dealerLocations)
                const label = dealerLocation?.name.toString() || ''

                return { value, label }
              },
              disabled: readOnly,
              options: mapDealerLocationsToSelectOptions(),
              t,
              testId: `${testId}-location`,
            })}
            control={control}
            name='process.locationKey'
            rules={{ required: 'caption.error.required' }}
          />
          <ErrorMessage errors={errors} name='process.locationKey' as={TranslatedErrorMessage} />
        </FormCell>

        <FormCellFullWidth>
          <CheckboxContainer>
            <div key={getMobileUserCheckboxKey()}>
              <input type='checkbox' readOnly={true} defaultChecked={process?.inApp} disabled={readOnly} /><label>&nbsp;</label>
            </div>
            <label>
              {t('crmInsurance.process.mobileUser')}
            </label>
          </CheckboxContainer>
        </FormCellFullWidth>
      </SectionForm>
    </CollapsableSection>
  )
}
