import styled from 'styled-components'
import { colors, theme } from 'styles'

type LabelProps = {
  readonly color?: string,
  readonly disabled?: boolean,
  readonly fontSize?: string,
  readonly fontWeight?: string,
  readonly error?: boolean;
  readonly uppercase?: boolean,
}

export const Label = styled.label<LabelProps>`
  color: ${({ color = theme.form.label, error }: LabelProps) => error ? colors.flamingoRed : color};
  ${({ fontSize }: LabelProps) => fontSize && `font-size: ${fontSize}px`};
  font-weight: ${({ fontWeight = '600' }: LabelProps) => fontWeight};
  text-transform: ${({ uppercase = true }: LabelProps) => uppercase ? 'uppercase' : 'initial'} !important;

  ${({ disabled = false }: LabelProps) => disabled &&
  `
    [type="radio"]:checked + label:after  {
      border: ${colors.dustyGray};
    }
  `
  };
`
