import { PagedResult } from 'actff-bo-lib/api'
import { CarInfoWithClient, CarStatus } from 'actff-bo-lib/car'
import { Testable } from 'actff-bo-lib/global/testable'
import { UserPermissions } from 'actff-bo-lib/user'
import { Map } from 'immutable'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { CarRow, CarRowHighlightParams } from './CarRow'

type Props = Testable & {
  readonly cars: PagedResult<CarInfoWithClient>,
  readonly permissions: ReadonlyArray<UserPermissions>,
}

// tslint:disable no-object-literal-type-assertion
const userPermissionRowParamsMap = Map([
  [UserPermissions.ServiceViewCars, { status: CarStatus.NEW, time: 'created' } as CarRowHighlightParams],
  [UserPermissions.FinanceViewCars, { status: CarStatus.FINANCE_NOT_ACCEPTED, time: 'serviceAcceptanceDate' } as CarRowHighlightParams],
  [UserPermissions.InsuranceViewCars, { status: CarStatus.INSURANCE_NOT_ACCEPTED, time: 'serviceAcceptanceDate' } as CarRowHighlightParams],
  [UserPermissions.AdminAllCars, { status: CarStatus.NEW, time: 'created' } as CarRowHighlightParams],
]) as Map<UserPermissions, CarRowHighlightParams>
// tslint:enable

const getCarRowHighlightParams = (permissions: ReadonlyArray<UserPermissions>) =>
  permissions
    .map(permission => userPermissionRowParamsMap.find((_, value) => value === permission))
    .filter(Boolean)[0]

export const CarTable: React.FC<Props> = ({
  cars: { result },
  permissions,
  testId,
}) => {
  const { t } = useTranslation()
  const highlightParams = getCarRowHighlightParams(permissions)

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
          <Tr>
            <Th>{t('carList.columns.data')}</Th>
            <Th>{t('carList.columns.client')}</Th>
            <Th>{t('carList.columns.email')}</Th>
            <Th>{t('carList.columns.alias')}</Th>
            <Th>{t('table.columns.location')}</Th>
            <Th>{t('table.columns.brand')}</Th>
          </Tr>
        </thead>
        <tbody>
          {result.length && result.map((car, index) =>
            <CarRow key={car.uuid} car={car} highlightParams={highlightParams} testId={`${testId}car-${index}`} />,
          )}
        </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )
}
