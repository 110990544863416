import { SelectOption } from 'actff-bo-lib/global'

export enum TireType {
  Winter = 'WINTER',
  Yearround = 'YEARROUND',
  Summer = 'SUMMER',
  Na = 'NA',
}

export type Tires = {
  readonly type: TireType,
  readonly lastChange: Date | null,
}

export type Liquids = {
  readonly brakeLiquid: string,
  readonly engineOil: string,
}

export type CarDictionaryValue = string

export type DictionaryType<T> = SelectOption<T>
