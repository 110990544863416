import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { ClientId } from 'actff-bo-lib/client'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { AjaxError } from 'rxjs/ajax'

import { OfferRequest, OfferRequestCount, OfferRequestFilterValues, OfferRequestId, UpdateOfferRequestDto } from './dto'

export enum OfferRequestActionType {
  ChangeBrands = '[OFFER] CHANGE CAR BRANDS',
  ChangeDealerLocations = '[OFFER] CHANGE DEALER LOCATIONS',
  ChangeListFilter = '[OFFER] CHANGE LIST FILTER',
  ChangePage = '[OFFER] CHANGE PAGE',
  ChangeSearchPhrase = '[OFFER] CHANGE SEARCH PHRASE',

  ClearOfferRequest = '[OFFER] CLEAR OFFER REQUEST',

  GetOfferRequest = '[OFFER] GET OFFER',
  GetOfferRequestFailure = '[OFFER] GET OFFER REQUEST FAILURE',
  GetOfferRequestSuccess = '[OFFER] GET OFFER REQUEST SUCCESS',

  GetNewOfferRequestCount = '[OFFER] GET NEW OFFER REQUEST COUNT',
  GetNewOfferRequestCountFailure = '[OFFER] GET NEW OFFER REQUEST COUNT FAILURE',
  GetNewOfferRequestCountSuccess = '[OFFER] GET NEW OFFER REQUEST COUNT SUCCESS',

  GetOfferRequestCars = '[OFFER] GET OFFER REQUEST CARS REQUEST',
  GetOfferRequestCarsFailure = '[OFFER] GET OFFER REQUEST CARS FAILURE',
  GetOfferRequestCarsSuccess = '[OFFER] GET OFFER REQUEST CARS SUCCESS',

  GetOfferRequests = '[OFFER] GET OFFER REQUESTS',
  GetOfferRequestsFailure = '[OFFER] GET OFFER REQUESTS FAILURE',
  GetOfferRequestsSuccess = '[OFFER] GET OFFER REQUESTS SUCCESS',

  UpdateOfferRequest = '[OFFER] UPDATE OFFER REQUEST',
  UpdateOfferRequestFailure = '[OFFER] UPDATE OFFER REQUEST FAILURE',
  UpdateOfferRequestSuccess = '[OFFER] UPDATE OFFER REQUEST SUCCESS',
}

export const OfferRequestAction = {
  changeBrands: (brands: ReadonlyArray<string>) =>
    createAction(OfferRequestActionType.ChangeBrands, brands),
  changeDealerLocations: (dealerLocations: ReadonlyArray<DealerLocationKey>) =>
    createAction(OfferRequestActionType.ChangeDealerLocations, dealerLocations),
  changeListFilter: (param: string, value: OfferRequestFilterValues) =>
    createAction(OfferRequestActionType.ChangeListFilter, { param, value }),
  changePage: (page: number) => createAction(OfferRequestActionType.ChangePage, page),
  changeSearchPhrase: (searchPhrase: string) => createAction(OfferRequestActionType.ChangeSearchPhrase, searchPhrase),

  clearOfferRequest: () => createAction(OfferRequestActionType.ClearOfferRequest),

  getOfferRequest: (uuid: OfferRequestId) => createAction(OfferRequestActionType.GetOfferRequest, uuid),
  getOfferRequestFailure: (error: AjaxError) => createAction(OfferRequestActionType.GetOfferRequestFailure, error),
  getOfferRequestSuccess: (telephoneRequest: OfferRequest) =>
    createAction(OfferRequestActionType.GetOfferRequestSuccess, telephoneRequest),

  getNewOfferRequestCount: () => createAction(OfferRequestActionType.GetNewOfferRequestCount),
  getNewOfferRequestCountFailure: (error: AjaxError) => createAction(OfferRequestActionType.GetNewOfferRequestCountFailure, error),
  getNewOfferRequestCountSuccess: (count: OfferRequestCount) => createAction(OfferRequestActionType.GetNewOfferRequestCountSuccess, count),

  getOfferRequestsCars: (clientUuid: ClientId) => createAction(OfferRequestActionType.GetOfferRequestCars, clientUuid),
  getOfferRequestsCarsFailure: (error: AjaxError) => createAction(OfferRequestActionType.GetOfferRequestCarsFailure, error),
  getOfferRequestsCarsSuccess: (car: PagedResult<CarInfo>) =>
    createAction(OfferRequestActionType.GetOfferRequestCarsSuccess, car),

  getOfferRequests: () => createAction(OfferRequestActionType.GetOfferRequests),
  getOfferRequestsFailure: (error: AjaxError) => createAction(OfferRequestActionType.GetOfferRequestsFailure, error),
  getOfferRequestsSuccess: (telephoneRequests: PagedResult<OfferRequest>) =>
    createAction(OfferRequestActionType.GetOfferRequestsSuccess, telephoneRequests),

  updateOfferRequest: (dto: UpdateOfferRequestDto) =>
    createAction(OfferRequestActionType.UpdateOfferRequest, dto),
  updateOfferRequestFailure: (error: AjaxError) =>
    createAction(OfferRequestActionType.UpdateOfferRequestFailure, error),
  updateOfferRequestSuccess: (telephoneRequest: OfferRequest) =>
    createAction(OfferRequestActionType.UpdateOfferRequestSuccess, telephoneRequest),
}

export type OfferRequestAction = ActionsUnion<typeof OfferRequestAction>
