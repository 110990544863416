import { Popover } from '@material-ui/core'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { Icon } from 'actff-bo-app/components/Icon'
import { Link } from 'actff-bo-app/components/Popover'
import { CarId, CarInfoWithClient, CarStatus } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { connector } from 'actff-bo-lib/global'
import { createRoute, IconType, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { selectCreateServiceRequestPending, ServiceRequestAction } from 'actff-bo-lib/service-request'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

enum ConfirmDialogType {
  OpportunityManual = 'OpportunityManual',
  ServiceRequest = 'ServiceRequest',
}

type Props = {
  readonly car: CarInfoWithClient,
  readonly client: Client,
}

type OwnState = {
  readonly confirmServiceRequestOpen: boolean,
  readonly confirmOpportunityManualOpen: boolean,
  readonly popoverAnchor: HTMLDivElement | null,
  readonly popoverOpen: boolean,
}

type StateToProps = {
  readonly createServiceRequestPending: boolean,
}

type DispatchToProps = {
  readonly createServiceRequest: (carUuid: CarId) => void,
}

type CarRowMenuProps = Props & DispatchToProps & StateToProps & WithTranslation

type ContainerProps = {
  readonly disabled: boolean,
}

const Container = styled.div`
  align-content: center;
  cursor: ${({ disabled }: ContainerProps) => disabled ? 'default' : 'pointer'};
  display: grid;
  height: 100%;
  padding: 10px;
`

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
`

const PopoverContentContainer = styled.div`
  display: grid;
`

class CarRowMenuComponent extends React.Component<CarRowMenuProps> {
  public readonly state: OwnState = {
    confirmOpportunityManualOpen: false,
    confirmServiceRequestOpen: false,
    popoverAnchor: null,
    popoverOpen: false,
  }

  public render(): React.ReactNode {
    const { car, t } = this.props
    const { confirmOpportunityManualOpen, confirmServiceRequestOpen, popoverAnchor, popoverOpen } = this.state

    return car.status === CarStatus.ACCEPTED && (
      <>
        <Container disabled={this.props.createServiceRequestPending}>
          <IconContainer onClick={this.handleMenuIconClick}>
            <Icon type={IconType.More} />
          </IconContainer>
          <Popover
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            onClose={this.handleClosePopover}
            open={popoverOpen}
            anchorEl={popoverAnchor}
          >
            <PopoverContentContainer>
              <Link onClick={this.handleCreateRequestLinkClick}>
                <Icon type={IconType.NewServiceRequest} />
                <span>{t('carList.columns.actions.createRequest')}</span>
              </Link>
              <Link onClick={this.handleCreateOpportunityLinkClick}>
                <Icon type={IconType.Cog} />
                <span>{t('carList.columns.actions.createOpportunity')}</span>
              </Link>
            </PopoverContentContainer>
          </Popover>
          <Dialog
            content='confirmDialog.serviceRequest.manual.content'
            open={confirmServiceRequestOpen}
            onConfirm={this.handleConfirmCreateServiceRequest}
            onClose={this.handleCloseConfirmDialog(ConfirmDialogType.ServiceRequest)}
            title='confirmDialog.serviceRequest.manual.title'
          />
          <Dialog
            content='confirmDialog.opportunity.manual.content'
            open={confirmOpportunityManualOpen}
            onConfirm={this.handleConfirmCreateOpportunity}
            onClose={this.handleCloseConfirmDialog(ConfirmDialogType.OpportunityManual)}
            title='confirmDialog.opportunity.manual.title'
          />
        </Container>
      </>
    )
  }

  public readonly handleCloseConfirmDialog = (modalType: ConfirmDialogType) => (event: React.MouseEvent) => {
    this.setState({
      [`confirm${modalType}Open`]: false,
    })
    event.stopPropagation()
  }

  public readonly handleClosePopover = (event: React.MouseEvent) => {
    this.setState({
      popoverAnchor: null,
      popoverOpen: false,
    })
    event.stopPropagation()
  }

  public readonly handleMenuIconClick = (event: React.MouseEvent) => {
    this.setState({
      popoverAnchor: event.currentTarget,
      popoverOpen: true,
    })

    event.stopPropagation()
  }

  public readonly handleCreateRequestLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.releasePopover()

    this.setState({
      confirmServiceRequestOpen: true,
    })
  }

  public readonly handleCreateOpportunityLinkClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    this.releasePopover()

    this.setState({
      confirmOpportunityManualOpen: true,
    })
  }

  public readonly handleConfirmCreateOpportunity = (event: React.MouseEvent) => {
    this.setState({
      modalOpen: false,
    })
    event.stopPropagation()

    history.push(createRoute(Paths.CrmServiceOpportunityManual, { vin: this.props.car.vin }))
  }

  public readonly handleConfirmCreateServiceRequest = (event: React.MouseEvent) => {
    const { createServiceRequest, createServiceRequestPending } = this.props

    this.setState({
      modalOpen: false,
    })
    event.stopPropagation()

    if (!createServiceRequestPending) {
      createServiceRequest(this.props.car.uuid)
    }
  }

  public readonly releasePopover = () => {
    this.setState({
      popoverAnchor: null,
      popoverOpen: false,
    })
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  createServiceRequestPending: selectCreateServiceRequestPending(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  createServiceRequest: carUuid => { dispatch(ServiceRequestAction.createServiceRequest(carUuid)) },
})

export const CarRowMenu = compose(
  connector(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(CarRowMenuComponent)
