import { Attachment } from 'actff-bo-app/components/Attachment'
import { BtnTheme, BtnType } from 'actff-bo-app/components/Button'
import { PreviewFiles, UploadButton, UploadFile } from 'actff-bo-app/components/UploadFile'
import { InspectionAttachmentAcceptTypes, InspectionComment } from 'actff-bo-lib/car'
import { defaultDateTimeFormat, getLocale } from 'actff-bo-lib/date'
import { Attachment as AttachmentAsType, AttachmentType } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { Language } from 'actff-bo-lib/i18n'
import { IconType } from 'actff-bo-lib/menu'
import { format } from 'date-fns'
import React, { FC, useState } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AddButton, CommentContent, CommentHeader, CommentsContainer, Textarea } from '../Styled'

type Props = Testable & {
  readonly control: Control,
  readonly name: string,
  readonly setValue: (name: string, value: ReadonlyArray<AttachmentType>) => void,
  readonly refAttr: () => void,
  readonly additionalRemarks?: boolean,
  readonly currentLanguage?: Language,
  readonly comments?: ReadonlyArray<InspectionComment>,
}

const PhotoContainer = styled.div`
  display: inline-block;
  margin-top: 24px;

  > * {
    margin-right: 10px;
  }
`

const AddComment: FC<Props> = ({ additionalRemarks = false, control, name, refAttr, setValue, testId}) => {
  const [isAddFormVisible, setAddFormVisible] = useState(false)
  const toggleIsAddFormVisible = () => setAddFormVisible(!isAddFormVisible)
  const { t } = useTranslation()
  const caption = additionalRemarks ? t('carView.additionalRemarks.add') : t('internalComments.add')
  // tslint:disable-next-line:no-any
  const watchedAttachments = useWatch<any>({ control, name: `${name}.attachments` })

  const handleDeleteAttachment = (file: AttachmentAsType) => () => {
    setValue(`${name}.attachments`, [
      ...watchedAttachments.filter((attachment: AttachmentAsType) => attachment.file !== file.file),
    ])
  }

  return isAddFormVisible
    ? (
      <>
        <Textarea data-testid={`${testId}-text-input`} name={`${name}.text`} ref={refAttr} />
        <PreviewFiles files={watchedAttachments} onDeleteFile={handleDeleteAttachment} />
        <Controller
          control={control}
          name={`${name}.attachments`}
          render={({ onChange, value = [] }) => (
            <UploadFile
              acceptTypes={InspectionAttachmentAcceptTypes.join(', ')}
              component={UploadButton}
              onChange={attachments => onChange([...value, ...attachments])}
              multiple={true}
            />
          )}
        />
      </>
    )
    : (
      <AddButton
        caption={caption}
        iconType={IconType.Plus}
        noPadding={true}
        onClick={toggleIsAddFormVisible}
        testId={`${testId}-add-button`}
        theme={BtnTheme.Link}
        type={BtnType.Button}
      />
    )
}

export const Comments: FC<Props> = ({ additionalRemarks = false, control, comments, currentLanguage, name, refAttr, setValue, testId }) => {
  const locale = getLocale(currentLanguage)
  const { t } = useTranslation()
  const thumbnailSize = 64

  return (
    <CommentsContainer data-test-id={`${testId}`}>
      {!additionalRemarks && <label>{t('internalComments.header')} ({comments?.length || 0}):</label>}
      {comments && comments.map((comment, index) => (
        <React.Fragment key={comment.uuid}>
          <CommentHeader data-testid={`${testId}-${index}--comment-header`}>
            <span>{comment.author.email}</span>
            <span>{format(comment.created, defaultDateTimeFormat, { locale })}</span>
          </CommentHeader>
          <CommentContent data-testid={`${testId}-${index}--comment-content`}>{comment.text}</CommentContent>
        <PhotoContainer>
          {comment.attachments?.length > 0 && (
            comment.attachments.map(photoUrl => <Attachment key={photoUrl} thumbnailSize={thumbnailSize} url={photoUrl} />)
          )}
        </PhotoContainer>
        </React.Fragment>
      ))}
      <AddComment
        additionalRemarks={additionalRemarks}
        control={control}
        name={name}
        refAttr={refAttr}
        setValue={setValue}
        testId={testId}
      />
    </CommentsContainer>
  )
}
