import { api, PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { ClientId } from 'actff-bo-lib/client'
import { buildUrl, Filters, getFiltersAsObject, getSimpleFiltersAsObject } from 'actff-bo-lib/global'
import { Observable } from 'rxjs'
import { DealerLocationKey } from '../dealership'

import {
  TelephoneRequest,
  TelephoneRequestCount,
  TelephoneRequestFilterValues,
  TelephoneRequestId,
  UpdateTelephoneRequestDto,
} from './dto'

export const getTelephoneRequest = (uuid: TelephoneRequestId): Observable<TelephoneRequest> =>
  api.get<TelephoneRequest>(`/telephone-request/${uuid}`)

export const getNewTelephoneRequestCount = () => api.get<TelephoneRequestCount>('/telephone-request/count/new')

export const getTelephoneRequestCars = (clientUuid: ClientId) => api.get<PagedResult<CarInfo>>(`/car?clientUuid=${clientUuid}`)

export const getTelephoneRequests = (
  filters: Filters<TelephoneRequestFilterValues>,
  page: number,
  searchText?: string,
  location?: ReadonlyArray<DealerLocationKey>,
  brand?: ReadonlyArray<string>,
): Observable<PagedResult<TelephoneRequest>> =>
  api.get<PagedResult<TelephoneRequest>>(
    buildUrl('/telephone-request', {
      page,
      ...getFiltersAsObject(filters),
      searchText,
      ...getSimpleFiltersAsObject({ location, brand }),
    }),
  )

export const updateTelephoneRequest = (dto: UpdateTelephoneRequestDto) =>
  api.patch(`/telephone-request/${dto.uuid}`, { status: dto.status })
