import { UserPermissions } from 'actff-bo-lib/user/dto/permissions'

import { MenuItem } from '../dto'
import { hasMenuAccess } from './has-menu-access'

export const filterAvailableMenuItems = (
  menu: ReadonlyArray<MenuItem>,
  userPermissions: ReadonlyArray<UserPermissions>,
): ReadonlyArray<MenuItem> =>
  menu
    .map(element => ({
      ...element,
      children: filterAvailableMenuItems(element.children, userPermissions)}
    ))
    .filter(element => hasMenuAccess(element.access, userPermissions))
