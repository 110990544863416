import { adminMenu } from 'actff-bo-lib/menu/initial-menu/admin'
import { LoggedInPermissions } from 'actff-bo-lib/user/dto/permissions'

import { ComponentType, IconType, MenuItem, Path } from '../dto'
import { calendarMenu } from './calendar'
import { chatMenu } from './chat'
import { clientMenu } from './client'
import { crmServiceMenu } from './crm'
import { crmInsuranceMenu } from './crm-insurance'
import { crmTradeMenu } from './crm-trade'
import { ndcCarMenu } from './ndc-car'
import { Paths } from './paths'
import { taskList } from './task-list'
import { AuthWrappers, LoggedInWrappers } from './wrappers'

// tslint:disable object-literal-sort-keys
export const getInitialMenu = (): ReadonlyArray<MenuItem> => [
  {
    access: [],
    children: [],
    component: ComponentType.Main,
    expanded: false,
    path: Paths.Main as Path,
    wrappers: [],
  },
  {
    access: LoggedInPermissions,
    component: ComponentType.Dashboard,
    expanded: false,
    icon: IconType.Dashboard,
    name: 'menu.dashboard',
    path: Paths.Dashboard as Path,
    ...LoggedInWrappers,
    children: [],
  },
  {
    ...clientMenu,
  },
  {
    ...ndcCarMenu,
  },
  {
    ...taskList,
  },
  {
    ...crmTradeMenu,
  },
  {
    ...crmServiceMenu,
  },
  {
    ...crmInsuranceMenu,
  },
  {
    ...calendarMenu,
  },
  {
    ...chatMenu,
  },
  {
    ...adminMenu,
  },
  {
    access: [],
    component: ComponentType.Login,
    expanded: false,
    icon: IconType.Chat,
    name: 'menu.login',
    path: Paths.Login as Path,
    ...AuthWrappers,
    children: [],
    hiddenInMenu: true,
  },
]
