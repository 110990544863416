import { NominalString } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { Map } from 'immutable'

export enum NotificationType {
  CancelledServiceRequest = 'CANCELLED_SERVICE_REQUEST',
  Car = 'NEW_CAR',
  ChatMessage = 'NEW_CHAT_MESSAGE',
  NewServiceRequest = 'NEW_SERVICE_REQUEST',
  NewTestDriveRequest = 'NEW_TEST_DRIVE',
  OfferRequest = 'NEW_OFFER_REQUEST',
  OpportunityRetryReminder = 'RETRY_REMINDER',
  TelephoneRequest = 'NEW_TELEPHONE_REQUEST',
}

export enum NotificationPermissionType {
  Denied = 'denied',
  Granted = 'granted',
  Default = 'default',
}

export type NotificationPayload = {
  readonly title: string,
  readonly subtitle: string,
  readonly icon: string,
  readonly type?: NotificationType,
  readonly id?: string,
}

export type NotificationToken = NominalString<'NotificationToken'>

export const NotificationTypeComponentMap: Map<NotificationType, Paths> = Map([
  [NotificationType.CancelledServiceRequest, Paths.ServiceRequestsView],
  [NotificationType.Car, Paths.CarList],
  [NotificationType.ChatMessage, Paths.ChatView],
  [NotificationType.NewServiceRequest, Paths.ServiceRequests],
  [NotificationType.NewTestDriveRequest, Paths.TestDriveRequests],
  [NotificationType.OfferRequest, Paths.OfferRequests],
  [NotificationType.OpportunityRetryReminder, Paths.CrmServiceOpportunityList],
  [NotificationType.TelephoneRequest, Paths.TelephoneRequests],

]) as Map<NotificationType, Paths>
