import { defaultDateTimeFormat, defaultLocale, LocaleMap, parseToDateOrNull } from 'actff-bo-lib/date'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { State } from 'actff-bo-lib/store'
import { format as dateFormat } from 'date-fns'
import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'

type Props = {
  readonly date?: Date | string | null,
  readonly format?: string,
}

type RootComponentProps = ConnectedProps<typeof connector>

const mapState = (state: State) => ({
  currentLanguage: selectCurrentLanguage(state),
})

const connector = connect(mapState)
class FormattedDateComponent extends React.Component<RootComponentProps & Props> {

  public render(): string | null {
    const { date, format } = this.props
    const parsedDate = parseToDateOrNull(date)
    const locale = this.getLocale(this.props.currentLanguage || defaultLocale)

    return parsedDate
      ? (
        dateFormat(parsedDate, format || defaultDateTimeFormat, { locale })
      ) : null
  }

  private readonly getLocale = (currentLanguage: Language) => LocaleMap.get(currentLanguage, defaultLocale)
}

export const FormattedDate = connector(FormattedDateComponent)
