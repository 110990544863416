import {
  OpportunityInsurance,
  OpportunityInsuranceId,
  OpportunityProcess,
} from 'actff-bo-lib/crm/insurance/dto/opportunity'
import { objectDiff } from 'actff-bo-lib/global'
import { Observable, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'

import { CrmInsuranceAction } from './actions'
import {
  getCar,
  getConfirmations,
  getContactHistory,
  getContactPerson,
  getDriver,
  getNewPolicy,
  getOffer,
  getOldPolicy,
  getOpportunity,
  getOwner,
  getPayments,
  updateCar,
  updateConfirmations,
  updateContactAttempts,
  updateContactPerson,
  updateDriver,
  updateNewPolicy,
  updateOffer,
  updateOldPolicy,
  updateOwner,
  updatePayments,
  updateProcess,
} from './dao/opportunity'

type OpportunityRequestsMap = {
  readonly [K in keyof Partial<OpportunityInsurance>]: (
    uuid: OpportunityInsuranceId,
    values?: OpportunityInsurance[K],
  ) => Observable<OpportunityInsurance[K]>
}

export const opportunityFieldToRequest: OpportunityRequestsMap = {
  car: updateCar,
  confirmation: updateConfirmations,
  contactHistory: updateContactAttempts,
  contactPerson: updateContactPerson,
  driver: updateDriver,
  newPolicy: updateNewPolicy,
  offer: updateOffer,
  oldPolicy: updateOldPolicy,
  owner: updateOwner,
  payments: updatePayments,
  process: updateProcess,
}

export const opporunityFieldFromRequest: OpportunityRequestsMap = {
  car: getCar,
  confirmation: getConfirmations,
  contactHistory: getContactHistory,
  contactPerson: getContactPerson,
  driver: getDriver,
  newPolicy: getNewPolicy,
  offer: getOffer,
  oldPolicy: getOldPolicy,
  owner: getOwner,
  payments: getPayments,
  process: getOpportunity,
}

export const mapOpportunityFieldsToRequests = (
  uuid: OpportunityInsuranceId,
  opportunity: OpportunityInsurance,
  sourceOpportunity: OpportunityInsurance | null,
) => Object.keys(opportunity)
  .filter(key => key !== 'process')
  .filter(key => !!opportunityFieldToRequest[key])
  .filter(key => {
    if (!sourceOpportunity || !sourceOpportunity[key]) {
      return true
    }

    const diff = objectDiff<OpportunityInsurance>(sourceOpportunity[key], opportunity[key])

    return Object.keys(diff).length > 0
  })
  .map(key => opportunityFieldToRequest[key](uuid, opportunity[key]).pipe(
    catchError((ajaxError: AjaxError) => of(
      CrmInsuranceAction.saveOpportunityDataFailure(
        Object.entries(ajaxError.response.errors)
          .reduce((errors, [ field, error ]) => ({ ...errors, [`${key}.${field}`]: error }), {}),
        ajaxError,
      ),
    )),
  ))

export const mapToUpdateProcessRequest = (
  uuid: OpportunityInsuranceId,
  process: OpportunityProcess,
  sourceProcess: OpportunityProcess | undefined | null,
): Observable<OpportunityProcess | null> => {
  if (!opportunityFieldToRequest.process || !sourceProcess) {
    return of(null)
  }

  const diff = objectDiff(sourceProcess, process)

  if (Object.keys(diff).length < 1) {
    return of(null)
  }

  return updateProcess(uuid, process)
}

export const mapOpportunityFieldsFromRequests = (uuid: OpportunityInsuranceId) => Object.keys(opporunityFieldFromRequest)
  .map(key => opporunityFieldFromRequest[key](uuid).pipe(
    map(values => ([key, values])),
    catchError(() => of([])),
  ))
