import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { CreateQuickCampaignResponseDto, QuickCampaignDto } from './dto'

export enum CampaignActionType {
  GetSmsCost = '[QUICK CAMPAIGN] GET SMS COST',
  GetSmsCostFailure = '[QUICK CAMPAIGN] GET SMS COST FAILURE',
  GetSmsCostSuccess = '[QUICK CAMPAIGN] GET SMS COST SUCCESS',

  SendQuickCampaign = '[QUICK CAMPAIGN] SEND QUICK CAMPAIGN',
  SendQuickCampaignFailure = '[QUICK CAMPAIGN] SEND QUICK CAMPAIGN FAILURE',
  SendQuickCampaignSuccess = '[QUICK CAMPAIGN] SEND QUICK CAMPAIGN SUCCESS',
}

export const CampaignAction = {
  getSmsCost: (dto: QuickCampaignDto) => createAction(CampaignActionType.GetSmsCost, dto),
  getSmsCostFailure: (error: AjaxError) => createAction(CampaignActionType.GetSmsCostFailure, error),
  getSmsCostSuccess: (cost: string) => createAction(CampaignActionType.GetSmsCostSuccess, cost),

  sendQuickCampaign: (dto: QuickCampaignDto) => createAction(CampaignActionType.SendQuickCampaign, dto),
  sendQuickCampaignFailure: (error: AjaxError) => createAction(CampaignActionType.SendQuickCampaignFailure, error),
  sendQuickCampaignSuccess: (dto: CreateQuickCampaignResponseDto) => createAction(CampaignActionType.SendQuickCampaignSuccess, dto),
}

export type CampaignAction = ActionsUnion<typeof CampaignAction>
