import { Loader } from 'actff-bo-app/components/Loader'
import { Wrapper } from 'actff-bo-app/components/Wrapper'
import { dealerLocationsExist } from 'actff-bo-lib/dealership'
import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { tabletBoundary, theme } from 'styles'

import { Navigation } from './Navigation'

const WithNavigationContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  height: calc(100vh - ${theme.logoContainer.height});
  width: 100%;
  @media screen and (max-width: ${tabletBoundary}) {
    display: inline-block;
  }
`

const testId = 'navigation__'

export const withNavigation: Wrapper = () => (Component: React.FC | React.ComponentClass) => () => {
  const dealerLocationsLoaded = useSelector(dealerLocationsExist)

  if (!dealerLocationsLoaded) { return <Loader /> }

  return (
    <WithNavigationContainer date-testid={`${testId}container`}>
      <Navigation testId={testId} />
      <div>
        <Component />
      </div>
    </WithNavigationContainer>
  )
}
