import { api } from 'actff-bo-lib/api'
import { Observable } from 'rxjs'

import {
  ContactHistory,
  InsurancePolicy,
  NewPolicy,
  OpportunityCar,
  OpportunityCarDriver,
  OpportunityCarOwner,
  OpportunityContactPerson,
  OpportunityInsurance,
  OpportunityInsuranceId,
  OpportunityOffer,
  OpportunityPayments,
  OpportunityPaymentsConfirmation,
  OpportunityProcess,
  OpportunityProcessCreationData,
} from '../dto'

export const getOpportunity = (uuid: OpportunityInsuranceId): Observable<OpportunityProcess> =>
  api.get(`/insurance/opportunity/${uuid}`)

export const getOldPolicy = (uuid: OpportunityInsuranceId): Observable<InsurancePolicy> =>
  api.get(`/insurance/opportunity/${uuid}/old-policy`)

export const getNewPolicy = (uuid: OpportunityInsuranceId): Observable<NewPolicy> =>
  api.get(`/insurance/opportunity/${uuid}/new-policy`)

export const getOffer = (uuid: OpportunityInsuranceId): Observable<OpportunityOffer> =>
  api.get(`/insurance/opportunity/${uuid}/offer`)

export const getContactHistory = (uuid: OpportunityInsuranceId): Observable<ContactHistory> =>
  api.get(`/insurance/opportunity/${uuid}/contact-attempts`)

export const getContactPerson = (uuid: OpportunityInsuranceId): Observable<OpportunityContactPerson> =>
  api.get(`/insurance/opportunity/${uuid}/contact-person`)

export const getDriver = (uuid: OpportunityInsuranceId): Observable<OpportunityCarDriver> =>
  api.get(`/insurance/opportunity/${uuid}/driver`)

export const getOwner = (uuid: OpportunityInsuranceId): Observable<OpportunityCarOwner> =>
  api.get(`/insurance/opportunity/${uuid}/owner`)

export const getCar = (uuid: OpportunityInsuranceId): Observable<OpportunityCar> =>
  api.get(`/insurance/opportunity/${uuid}/car`)

export const getPayments = (uuid: OpportunityInsuranceId): Observable<OpportunityPayments> =>
  api.get(`/insurance/opportunity/${uuid}/payments`)

export const getConfirmations = (uuid: OpportunityInsuranceId): Observable<OpportunityPaymentsConfirmation> =>
  api.get(`/insurance/opportunity/${uuid}/confirmation`)

export const createOpportunity = (process: OpportunityProcess) =>
  api.post<OpportunityInsurance, OpportunityProcessCreationData>('/insurance/opportunity/manual', process)

export const deleteOpportunity = (uuid: OpportunityInsuranceId) =>
  api.delete<OpportunityInsurance>(`/insurance/opportunity/${uuid}`)

export const updateContactPerson = (uuid: OpportunityInsuranceId, contactPerson: OpportunityContactPerson) =>
  api.patch<OpportunityContactPerson, OpportunityContactPerson>(`/insurance/opportunity/${uuid}/contact-person`, contactPerson)

export const updateCar = (uuid: OpportunityInsuranceId, car: OpportunityCar) =>
  api.patch<OpportunityCar, OpportunityCar>(`/insurance/opportunity/${uuid}/car`, car)

export const updateDriver = (uuid: OpportunityInsuranceId, driver: OpportunityCarDriver) =>
  api.patch<OpportunityCarDriver, OpportunityCarDriver>(`/insurance/opportunity/${uuid}/driver`, driver)

export const updateOwner = (uuid: OpportunityInsuranceId, owner: OpportunityCarOwner) =>
  api.patch<OpportunityCarOwner, OpportunityCarOwner>(`/insurance/opportunity/${uuid}/owner`, owner)

export const updateProcess = (uuid: OpportunityInsuranceId, process: OpportunityProcess) =>
  api.patch<OpportunityProcess, OpportunityProcess>(`/insurance/opportunity/${uuid}`, process)

export const updateOldPolicy = (uuid: OpportunityInsuranceId, policy: InsurancePolicy) =>
  api.patch<InsurancePolicy, InsurancePolicy>(`/insurance/opportunity/${uuid}/old-policy`, policy)

export const updateNewPolicy = (uuid: OpportunityInsuranceId, policy: NewPolicy) =>
  api.patch<NewPolicy, NewPolicy>(`/insurance/opportunity/${uuid}/new-policy`, policy)

export const updateOffer = (uuid: OpportunityInsuranceId, offer: OpportunityOffer) =>
  api.patch<OpportunityOffer, OpportunityOffer>(`/insurance/opportunity/${uuid}/offer`, offer)

export const updatePayments = (uuid: OpportunityInsuranceId, payments: OpportunityPayments) =>
  api.patch<OpportunityPayments, OpportunityPayments>(`/insurance/opportunity/${uuid}/payments`, payments)

export const updateConfirmations = (uuid: OpportunityInsuranceId, confirmation: OpportunityPaymentsConfirmation) =>
  api.patch<OpportunityPaymentsConfirmation, OpportunityPaymentsConfirmation>(
    `/insurance/opportunity/${uuid}/confirmation`,
    confirmation,
  )

export const updateContactAttempts = (uuid: OpportunityInsuranceId, contactHistory: ContactHistory) =>
  api.patch<ContactHistory, ContactHistory>(
    `/insurance/opportunity/${uuid}/contact-attempts`,
    contactHistory,
  )
