import { CheckboxInputField, Field } from 'actff-bo-app/components/Form'
import { Label } from 'actff-bo-app/components/Label'
import { CommunicationsMethod } from 'actff-bo-lib/client/dto/client'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly type: string,
  readonly t: i18next.TFunction,
}

const ApprovalsGroupStyled = styled.div`
  display: grid;
  padding-right: 50px;
`

export const ApprovalsGroup: React.FC<Props> = ({ type, testId, t }) => (
  <ApprovalsGroupStyled>
    <Label color={colors.dustyGray} uppercase={true}>{t(`client.approvals.${type}`)}</Label>
    {
      Object.keys(CommunicationsMethod).map(method => (
        <Field
          component={CheckboxInputField}
          id={`${type}[${method}]`}
          key={method}
          label={t(`client.approvals.${method}`)}
          name={`${type}[${method}]`}
          slim={true}
          testId={`${testId}--method`}
        />
      ))
    }
  </ApprovalsGroupStyled>
)
