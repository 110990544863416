// tslint:disable strict-type-predicates
export const updateStateFieldIfExists = <T extends object>(
  state: T,
  field: keyof T,
  diff: {},
): T => ({
  ...state,
  [field]: state[field] !== null
    ? {
        ...state[field],
        ...diff,
      }
    : state[field],
})
