import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { AjaxError } from 'rxjs/ajax'
import { AdminDealerSettings } from '../dto'

export enum AdminLocationSettingsActionType {
  GetDealerSettings = '[ADMIN] GET DEALER SETTINGS',
  GetDealerSettingsFailure = '[ADMIN] GET DEALER SETTINGS FAILURE',
  GetDealerSettingsSuccess = '[ADMIN] GET DEALER SETTINGS SUCCESS',

  UpdateDealerSettings = '[ADMIN] UPDATE DEALER SETTINGS',
  UpdateDealerSettingsFailure = '[ADMIN] UPDATE DEALER SETTINGS FAILURE',
  UpdateDealerSettingsSuccess = '[ADMIN] UPDATE DEALER SETTINGS SUCCESS',
}

export const AdminLocationSettingsAction = {
  getDealerSettings: (locationKey?: DealerLocationKey) => createAction(AdminLocationSettingsActionType.GetDealerSettings, locationKey),
  getDealerSettingsFailure: (error: AjaxError) => createAction(AdminLocationSettingsActionType.GetDealerSettingsFailure, error),
  getDealerSettingsSuccess: (dealerSettings: AdminDealerSettings) =>
    createAction(AdminLocationSettingsActionType.GetDealerSettingsSuccess, dealerSettings),

  updateDealerSettings: (payload: AdminDealerSettings, locationKey?: DealerLocationKey) =>
    createAction(AdminLocationSettingsActionType.UpdateDealerSettings, { payload, locationKey }),
  updateDealerSettingsFailure: (error: AjaxError) => createAction(AdminLocationSettingsActionType.UpdateDealerSettingsFailure, error),
  updateDealerSettingsSuccess: (dealerSettings: AdminDealerSettings) =>
    createAction(AdminLocationSettingsActionType.UpdateDealerSettingsSuccess, dealerSettings),
}

export type AdminLocationSettingsAction = ActionsUnion<typeof AdminLocationSettingsAction>
