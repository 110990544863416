import { H2Styled } from 'actff-bo-app/Clients/Styled'
import { BtnType } from 'actff-bo-app/components/Button'
import { ClientEmail, ClientFullName, ClientPhone } from 'actff-bo-app/components/Client'
import { Loader } from 'actff-bo-app/components/Loader'
import { Overlay } from 'actff-bo-app/components/Overlay'
import { PopupPosition } from 'actff-bo-app/components/Popup'
import { NotFound } from 'actff-bo-app/NotFound'
import { Client, ClientAction, ClientId, ClientStatus, selectCurrentClient, selectIsLoadingClient } from 'actff-bo-lib/client'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State } from 'actff-bo-lib/store'
import { selectMe, selectUserPermissions, User, UserPermissions } from 'actff-bo-lib/user'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import Popup from 'reactjs-popup'
import { compose } from 'redux'
import { colors, Header } from 'styles'

import { ClientViewTabs } from './ClientViewTabs'
import { ClientContainer, ContactInfoStyled, IconStyled, Notifications } from './Styled'

type DispatchToProps = {
  readonly getClient: (clientId: ClientId) => void,
}

type StateToProps = {
  readonly currentLanguage: Language,
  readonly client: Client | null,
  readonly currentUser: User | null,
  readonly isLoading: boolean,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

type ClientViewParams = {
  readonly clientId: string,
}

export type ClientViewProps = StateToProps & DispatchToProps & RouteComponentProps<ClientViewParams> & WithTranslation

const WithClientViewHeader = (Component: React.ComponentType) =>
  class extends React.Component<ClientViewProps> {
    public componentDidMount(): void {
      this.getClient()
    }

    public UNSAFE_componentWillReceiveProps(newProps: ClientViewProps): void {
      if (this.getClientId(this.props) !== this.getClientId(newProps)) {
        this.getClient()
      }
    }

    public render(): React.ReactNode {
      const { client, isLoading } = this.props

      if (isLoading) {
        return <Loader />
      }

      if (!client) {
        return <NotFound />
      }

      return (
        <ClientContainer>
          {client.status === ClientStatus.Archived &&
            (<Overlay color={colors.dark} height='calc(100% - 65px)' opacity='0.5' position='absolute' />)
          }
          <Header>
            <H2Styled>
              <ClientFullName client={client} />
              <IconStyled type={IconType.Sms} />
              <Popup
                trigger={<button type={BtnType.Button}><IconStyled type={IconType.Ring} /></button>}
                position={PopupPosition.BottomCenter}
              >
                <Notifications>
                  <p>Powiadomienia Push</p>
                  <p>- oferta handlowa</p>
                  <p>- oferta marketingowa</p>
                </Notifications>
              </Popup>
              <IconStyled type={IconType.EmailEnvelope} />
            </H2Styled>
            <ContactInfoStyled>
              <IconStyled type={IconType.IdCard} />{client.id}
              <IconStyled type={IconType.Phone} /><ClientPhone client={client} />
              <IconStyled type={IconType.Email} /><ClientEmail client={client} />
            </ContactInfoStyled>
          </Header>
          <ClientViewTabs client={client} />
          <Component {...this.props} />
        </ClientContainer>
      )
    }

    private readonly getClientId = (props: ClientViewProps) =>
      props.match.params.clientId as ClientId

    private readonly getClient = () => { this.props.getClient(this.getClientId(this.props)) }
  }

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getClient: (clientId: ClientId) => { dispatch(ClientAction.getClient(clientId)) },
})

const mapStateToProps: MapStateToProps<StateToProps, ClientViewProps, State> = state => ({
  client: selectCurrentClient(state),
  currentLanguage: selectCurrentLanguage(state),
  currentUser: selectMe(state),
  isLoading: selectIsLoadingClient(state),
  userPermissions: selectUserPermissions(state),
})

export const withClientViewHeader = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  WithClientViewHeader,
)
