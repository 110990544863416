import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { State } from 'actff-bo-lib/store'
import { selectMe, selectUserPermissions, User, UserPermissions } from 'actff-bo-lib/user'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import { AdminContainer } from './Styled'

type StateToProps = {
  readonly currentLanguage: Language,
  readonly currentUser: User | null,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

type AdminViewParams = {
  readonly clientId: string,
}

export type AdminViewProps = StateToProps & RouteComponentProps<AdminViewParams> & WithTranslation

const WithAdminViewHeader = (Component: React.ComponentType) =>
  class extends React.Component<AdminViewProps> {

    public render(): React.ReactNode {

      return (
        <AdminContainer>
          <Component {...this.props} />
        </AdminContainer>
      )
    }
  }

const mapStateToProps: MapStateToProps<StateToProps, AdminViewProps, State> = state => ({
  currentLanguage: selectCurrentLanguage(state),
  currentUser: selectMe(state),
  userPermissions: selectUserPermissions(state),
})

export const withAdminViewHeader = compose(
  withRouter,
  connect(mapStateToProps),
  withTranslation(),
  WithAdminViewHeader,
)
