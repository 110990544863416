import { DataGrid } from 'actff-bo-app/components/DataGrid'
import { Loader } from 'actff-bo-app/components/Loader'
import { CrmInsuranceAction, ReportType, selectReport } from 'actff-bo-lib/crm/insurance'
import { ReportColumns } from 'actff-bo-lib/crm/insurance/dto/report'
import { filterData, translateReport } from 'actff-bo-lib/crm/insurance/utils'
import { DateRange } from 'actff-bo-lib/date'
import * as React from 'react'
import { Filters } from 'react-data-grid'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

type Props<R, S> = {
  readonly columns: ReportColumns<R, S>,
  readonly filters?: Filters,
  readonly printMode?: boolean,
  readonly reportDateRange: DateRange,
  readonly summaryRowsFn?: (rows: ReadonlyArray<R>) => ReadonlyArray<S>,
  readonly type: ReportType,
}

export function InsuranceReport<R, S>({
  columns,
  filters: initialFilters,
  printMode = false,
  reportDateRange,
  summaryRowsFn,
  type,
}: Props<R, S>): React.ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const report = useSelector(selectReport)
  const [salesFilters, onSalesFiltersChange] = React.useState(initialFilters)
  const [paymentsFilters, onPaymentsFiltersChange] = React.useState(initialFilters)

  const getCurrentFilters = (filtersType: ReportType) => filtersType === ReportType.Sales ? salesFilters : paymentsFilters
  const getCurrentFiltersCallback = (filtersType: ReportType) =>
    filtersType === ReportType.Sales ? onSalesFiltersChange : onPaymentsFiltersChange

  const filters = getCurrentFilters(type)
  const onFiltersChange = getCurrentFiltersCallback(type)

  const columnsToPrint = columns.filter(({ hideOnPrint }) => !hideOnPrint)
  const reportData = (report.data || []).slice(1)
  const rows = translateReport<R>(
    filterData<R, S>(reportData, columns, filters),
    columns.map(({ key }) => key),
    'crmInsurance',
    t,
  )

  const data = !printMode
    ? { columns, rows }
    : {
      columns: columnsToPrint,
      rows: rows.map(row =>
        columnsToPrint.reduce(
          (filteredRow, { key }) => ({ ...filteredRow, [key]: row[key] }),
          {} as R, // tslint:disable-line no-object-literal-type-assertion
        ),
      ),
    }

  const getReport = React.useMemo(() => () => {
    dispatch(CrmInsuranceAction.getReport(type, reportDateRange))
  }, [dispatch, type, reportDateRange])

  const summaryRows = React.useMemo(() => {
    const summary = summaryRowsFn ? summaryRowsFn(data.rows) : undefined

    return summary
  }, [data.rows, summaryRowsFn])

  React.useEffect(() => {
    getReport()
  }, [getReport])

  return data
    ? (
      <DataGrid<R, S>
        rows={data.rows}
        cols={data.columns}
        printMode={printMode}
        summaryRows={summaryRows}
        filters={filters}
        onFiltersChange={onFiltersChange}
      />
    )
    : <Loader />
}
