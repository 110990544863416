export const initialDetailedFiltersFormValues = {
  actionDateFrom: '',
  actionDateTo: '',
  brands: [],
  chanceDateTo: '',
  clientSourceApplication: false,
  clientSourceDMS: false,
  clientSourceOutside: false,
  country: false,
  engine: [],
  financeEndMax: null,
  financeEndMin: null,
  from: null,
  fromFinance: false,
  hasInspection: false,
  hasNotInspection: false,
  importFilter: false,
  mileageMax: null,
  mileageMin: null,
  models: [],
  newCarOffer: false,
  own: false,
  plannedSellMax: null,
  plannedSellMin: null,
  priceMax: null,
  priceMin: null,
  productionYearMax: null,
  productionYearMin: null,
  ratings: [],
  saleOpportunitiesCount0: false,
  saleOpportunitiesCount1: false,
  saleOpportunitiesCount2: false,
  saleOpportunitiesCount3: false,
  to: null,
  tradePotential: false,
  transmission: [],
  version: [],
}
