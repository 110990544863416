import { SaveEditButton } from 'actff-bo-app/components/Button'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FieldValue, FormCell, FormHeader, Select } from 'actff-bo-app/components/Form'
import {
  CarDriveType,
  CarEquipmentData,
  CarEquipmentDataForm,
  CarEquipmentDataFormWithoutCar,
  CarInfo,
  CarInfoDto,
  CarTransmissionShortListType,
  CarTransmissionType,
} from 'actff-bo-lib/car/dto'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { getSelectOptions } from 'actff-bo-lib/form/get-select-options'
import { SelectOption } from 'actff-bo-lib/global/data-types'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CarData, Form, Header } from '../Styled'

type Props = Testable & {
  readonly car: CarInfo,
  readonly data: CarEquipmentData,
  readonly isNdcCar: boolean,
  readonly onSubmitForm: (carEquipment: CarEquipmentData, car: CarInfoDto) => void,
}

const getInitialValues = (car: CarInfo, data: CarEquipmentData, t: i18next.TFunction): CarEquipmentDataForm => ({
  modelYear: car.modelYear,
  productionYear: car.productionYear,
  ...data,
  drive: getSelectOptions(CarDriveType, t).find(type => type.value === data.drive) || null,
  transmission: getSelectOptions(CarTransmissionType, t).find(type => type.value === data.transmission) || null,
})

const getPreparedValues = (data: CarEquipmentData, formValues: CarEquipmentDataFormWithoutCar): CarEquipmentData => ({
  ...data,
  ...formValues,
  drive: formValues?.drive?.value as CarDriveType || data.drive,
  transmission: formValues?.transmission?.value as CarTransmissionType || data.transmission,
})

export const CarViewEquipmentDataForm: FC<Props> = ({ car, data, isNdcCar, onSubmitForm, testId }) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const { control, register, handleSubmit } = useForm<CarEquipmentDataForm>({ defaultValues: getInitialValues(car, data, t)})

  const onSubmit = handleSubmit(formValues => {
    const { productionYear, ...rest } = formValues
    onSubmitForm(getPreparedValues(data, rest), { productionYear, uuid: car.uuid })
    setEditMode(false)
  })

  const handleEditClick = () => {
    if (editMode) {
      onSubmit()
    }

    setEditMode(!editMode)
  }

  const handleSelectChange = ([ selectedOption ]: ReadonlyArray<SelectOption<string>>) => selectedOption
  const getTranslationValue = (value: string | number | null | undefined) => value && t(`carView.form.equipment.selectOption.${value}`)

  return (
    <Form>
      <Header>
        <FormHeader data-testid={`${testId}header-car`}>{t('carView.form.equipment.carHeader')}</FormHeader>
        <SaveEditButton className='edit-time' editMode={editMode} onClick={handleEditClick} t={t} />
      </Header>
      <CarData>
        <FormCell>
          <label>{t('carCard.vin')}:</label>
          <FieldValue data-testid={`${testId}vin`}>{car.vin}</FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carCard.model')}:</label>
          <FieldValue data-testid={`${testId}model`}>{car.model}</FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carCard.modelYear')}:</label>
          <FieldValue data-testid={`${testId}model-year`}>
            {car.modelYear}
          </FieldValue>
        </FormCell>

        <FormCell>
          <label>{t('carView.form.firstRegistrationDate')}:</label>
          <FieldValue data-testid={`${testId}first-registration-date`}>
            <FormattedDate date={car.firstRegistrationDate} format={defaultDateFormat} />
          </FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carCard.registrationNumber')}:</label>
          <FieldValue data-testid={`${testId}registration-number`}>{car.registrationNumber}</FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carCard.mileage')}:</label>
          <FieldValue data-testid={`${testId}mileage`}>{car.knownMileage}</FieldValue>
        </FormCell>

        <FormCell>
          <label>{t('carView.form.carVersion')}:</label>
          <FieldValue data-testid={`${testId}car-version`}>{car.version}</FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carView.fuel')}:</label>
          <FieldValue data-testid={`${testId}fuel`}>{car.fuel || t('car.fuelType.na')}</FieldValue>
        </FormCell>
        <FormCell>
          <label>{t('carView.form.engineCode')}:</label>
          <FieldValue data-testid={`${testId}engine-code--readonly`}>{car.engineCode}</FieldValue>
        </FormCell>

        {editMode ? (
          <>
            {!isNdcCar ? (
              <FormCell>
                <label>{t('carCard.productionYear')}:</label>
                <input data-testid={`${testId}production-year`} type='text' name='productionYear' ref={register}/>
              </FormCell>
            ) : (
              <FormCell>
                <label>{t('carCard.productionYear')}:</label>
                <FieldValue data-testid={`${testId}production-year`}>
                  {car.productionYear}
                </FieldValue>
              </FormCell>
            )}
            <FormCell>
              <label>{t('carView.form.color')}:</label>
              <input data-testid={`${testId}color`} type='text' name='color' ref={register} />
            </FormCell>
            <FormCell>
              <label>{t('carView.form.engineCapacity')}:</label>
              <input data-testid={`${testId}engine-capacity`} type='number' name='engineCapacity' ref={register} />
            </FormCell>
            <FormCell>
              <label>{t('carView.form.enginePower')}:</label>
              <input data-testid={`${testId}engine-power`} type='number' name='enginePower' ref={register} />
            </FormCell>

            <FormCell>
              <label>{t('carView.form.drive')}:</label>
              <Controller
                as={<Select />}
                data-testid={`${testId}drive`}
                control={control}
                name='drive'
                onChange={handleSelectChange}
                options={getSelectOptions(CarDriveType, t)}
              />
            </FormCell>
            <FormCell>
              <label>{t('carView.form.transmission')}:</label>
              <Controller
                as={<Select />}
                control={control}
                name='transmission'
                options={getSelectOptions(CarTransmissionShortListType, t)}
                data-testid={`${testId}transmission`}
                onChange={handleSelectChange}
              />
            </FormCell>
          </>
        ) : (
          <>
            <FormCell>
              <label>{t('carCard.productionYear')}:</label>
              <FieldValue data-testid={`${testId}production-year`}>
                {car.productionYear}
              </FieldValue>
            </FormCell>
            <FormCell>
              <label>{t('carView.form.color')}:</label>
              <FieldValue data-testid={`${testId}color`}>{data.color}</FieldValue>
            </FormCell>

            <FormCell>
              <label>{t('carView.form.engineCapacity')}:</label>
              <FieldValue data-testid={`${testId}engine-capacity--readonly`}>{data.engineCapacity}</FieldValue>
            </FormCell>
            <FormCell>
              <label>{t('carView.form.enginePower')}:</label>
              <FieldValue data-testid={`${testId}engine-power--readonly`}>{data.enginePower}</FieldValue>
            </FormCell>

            <FormCell>
              <label>{t('carView.form.drive')}:</label>
              <FieldValue data-testid={`${testId}drive--readonly`}>
                {getTranslationValue(data.drive)}
              </FieldValue>
            </FormCell>
            <FormCell>
              <label>{t('carView.form.transmission')}:</label>
              <FieldValue data-testid={`${testId}transmission--readonly`}>
                {getTranslationValue(data.transmission)}
              </FieldValue>
            </FormCell>
          </>
        )}
      </CarData>
    </Form>
  )
}
