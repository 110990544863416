import {
  CarEngineFuelType,
  carFormDataToCarInfoDto,
  CarId,
  CarInfo,
  CarInfoDto,
  CarInfoWithClient,
  CarStatus,
} from 'actff-bo-lib/car'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { CarModel, CarVersion } from 'actff-bo-lib/dealership'
import { DictionaryType } from 'actff-bo-lib/dictionary'
import { Loadable, SelectOption } from 'actff-bo-lib/global'
import { withFormik } from 'formik'
import { boolean, date, object, string } from 'yup'

import { carGuaranteeInitialValues, carServiceDetailsInitialValues } from './initialValues'

export type CarViewServiceFormProps = {
  readonly brakeLiquid: string | null,
  readonly brakeLiquids: ReadonlyArray<string> | null,
  readonly brand: string | null,
  readonly car: CarInfo,
  readonly carAttachments: ReadonlyArray<string> | null,
  readonly carModels: ReadonlyArray<DictionaryType<CarModel>>,
  readonly carVersions: ReadonlyArray<DictionaryType<CarVersion>> | null,
  readonly checkRegistrationNumberExistence: (carId: CarId, registrationNumber: string) => void,
  readonly checkVinExistence: (carId: CarId, vin: string) => void,
  readonly dealerBrands: Loadable<ReadonlyArray<string> | null>,
  readonly engineCode: string | null,
  readonly engineCodes: ReadonlyArray<SelectOption<string>> | null,
  readonly fuelType: CarEngineFuelType,
  readonly engineOil: string | null,
  readonly currentCarRegistrationNumberExists: boolean,
  readonly currentCarVinExists: boolean,
  readonly carModel: CarModel | null,
  readonly carVersion: string | null,
  readonly oilTypes: ReadonlyArray<DictionaryType<string>>,
  readonly onCarModelChange: (carModel: CarModel) => void,
  readonly onCarVersionChange: (version: string) => void,
  readonly onBrandChange: (brand: string) => void,
  readonly onBrakeLiquidChange: (brakeLiquid: string) => void,
  readonly onEngineCodeChange: (brand: string) => void,
  readonly onFuelTypeChange: (fuel: CarEngineFuelType) => void,
  readonly onOilTypeChange: (fuel: string) => void,
  readonly updateCar: (car: CarInfoDto) => void,
  readonly deleteCar?: (carId: CarId) => void,
  readonly rejectCar?: (carId: CarId) => void,
  readonly revertCar?: (carId: CarId) => void,
  readonly isNewCar?: boolean,
}

export type CarViewServiceFormValues = {
  readonly basicGuaranteeExpirationDate: Date | null,
  readonly brand: string,
  readonly brakeLiquid: string | null,
  readonly carModel: string | null,
  readonly carReleaseDate: Date | null,
  readonly carVersion: string,
  readonly dealerGuaranteeExpirationDate: Date | null,
  readonly dealerGuaranteeVisible: boolean,
  readonly dms: boolean,
  readonly engineCode: string,
  readonly engineOil: string | null,
  readonly extendedGuaranteeExpirationDate: Date | null,
  readonly firstRegistrationDate: Date | null,
  readonly lastServiceDate: Date | null,
  readonly mot: Date | null,
  readonly registration: string,
  readonly vin: string,
}

export const vinRegexp = /[a-zA-Z0-9]{9}[a-zA-Z0-9-]{2}[0-9]{6}/
const maxVinNumberLength = 17

const validationSchema = () =>
  object<CarViewServiceFormValues>().shape({
    basicGuaranteeExpirationDate: date()
      .required('carView.form.service.basicGuaranteeExpirationDate.isRequired')
      .nullable(true),
    brand: string().required('carView.form.service.brand.isRequired').nullable(true),
    carModel: string().required('carView.form.service.carModel.isRequired').nullable(true),
    carReleaseDate: date()
      .required('carView.form.service.carReleaseDate.isRequired')
      .nullable(true),
    dms: boolean().required('carView.form.service.dms.isRequired'),
    engineCode: string().required('carView.form.service.engineCode.isRequired').nullable(true),
    engineOil: string(),
    firstRegistrationDate: date()
      .required('carView.form.service.firstRegistrationDate.isRequired')
      .nullable(true),
    guaranteeNumber: string(),
    registration: string().required('carView.form.service.registration.isRequired'),
    vin: string()
      .matches(vinRegexp, 'carView.form.service.vin.invalid')
      .max(maxVinNumberLength, 'carView.form.service.vin.MaxLength')
      .required('carView.form.service.vin.isRequired'),
  })

export const formik = withFormik<CarViewServiceFormProps, CarViewServiceFormValues>({
  handleSubmit: (values: CarViewServiceFormValues, { props }) => {
    const car = props.car
    const fuelType = props.fuelType

    props.updateCar(carFormDataToCarInfoDto(
      car as CarInfoWithClient,
      fuelType,
      CarStatus.ACCEPTED,
      values,
    ))
  },
  // TODO: Move logic below to selector
  mapPropsToValues: ({ car, engineOil, brakeLiquid }) => {
    const {
      brand,
      carReleaseDate,
      dms,
      engineCode,
      firstRegistrationDate,
      model,
      registrationNumber,
      serviceDetails,
      version,
      vin,
    } = car

    const {
      basicGuaranteeExpirationDate,
      dealerGuaranteeExpirationDate,
      dealerGuaranteeVisible,
      extendedGuaranteeExpirationDate,
    } = (serviceDetails && serviceDetails.guarantee) || carGuaranteeInitialValues

    const { lastServiceDate, mot } = serviceDetails || carServiceDetailsInitialValues

    return {
      basicGuaranteeExpirationDate: parseToDateOrNull(basicGuaranteeExpirationDate),
      brakeLiquid,
      brand,
      carModel: model,
      carReleaseDate: parseToDateOrNull(carReleaseDate),
      carVersion: version,
      dealerGuaranteeExpirationDate: parseToDateOrNull(dealerGuaranteeExpirationDate),
      dealerGuaranteeVisible,
      dms,
      engineCode,
      engineOil,
      extendedGuaranteeExpirationDate: parseToDateOrNull(extendedGuaranteeExpirationDate),
      firstRegistrationDate: parseToDateOrNull(firstRegistrationDate),
      lastServiceDate: parseToDateOrNull(lastServiceDate),
      mot: parseToDateOrNull(mot),
      registration: registrationNumber,
      vin,
    }
  },
  validationSchema,
})
