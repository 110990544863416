import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormFooterLeft, FormFooterRight } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import { selectMenuExpanded } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { history } from 'actff-bo-lib/router'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { collapsedMenuWidth, expandedMenuWidth, layers, LinkButton, PrimaryButton, tabletBoundary } from 'styles'

const leftMarginOffset = 10

type Props = Testable & {
  readonly children?: React.ReactNode,
  readonly isSaveDisabled?: boolean,
  readonly onCancel?: () => void,
  readonly onSave?: () => void,
  readonly saveButtonType?: BtnType,
}

type FooterStyledProps = {
  readonly menuExpanded: boolean,
}

export const FooterStyled = styled.footer<FooterStyledProps>`
  align-items: center;
  background-color: white;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-auto-flow: column;
  height: 64px;
  left: ${({ menuExpanded }: FooterStyledProps) => menuExpanded ? `${expandedMenuWidth - leftMarginOffset}px` : `${collapsedMenuWidth}px`};
  padding: 0 35px 0 0;
  position: fixed;
  right: 0;
  z-index: ${layers.fixedFooter};

  @media screen and (max-width: ${tabletBoundary}) {
    left: 0;
  }
`

export const FixedFooter: FC<Props> = ({ children, isSaveDisabled, onCancel, onSave, saveButtonType, testId }) => {
  const { t } = useTranslation()
  const menuExpanded = useSelector(selectMenuExpanded)

  if (children) {
    return (
      <FooterStyled menuExpanded={menuExpanded}>
        {children}
      </FooterStyled>
    )
  }

  const handleGoBackClick = () => { history.goBack() }
  const handleSaveClick = () => { onSave && onSave() }

  const renderCancel = () => onCancel && (
    <LinkButton onClick={onCancel || handleGoBackClick} testId={`${testId}cancel`} type={BtnType.Button}>
      {t('caption.cancel')}
    </LinkButton>
  )

  const renderSave = () => onSave && (
    <PrimaryButton
      data-testid={`${testId}submit`}
      disabled={isSaveDisabled}
      type={saveButtonType || BtnType.Button}
      onClick={handleSaveClick}
    >
      {t('caption.save')}
    </PrimaryButton>
  )

  return (
    <FooterStyled menuExpanded={menuExpanded}>
      <FormFooterLeft>
        <ButtonWithIcon
          caption={t('caption.back')}
          iconType={IconType.Back}
          onClick={handleGoBackClick}
          testId={`${testId}go-back`}
          theme={BtnTheme.Link}
        />
      </FormFooterLeft>
      <FormFooterRight>
        {renderCancel()}
        {renderSave()}
      </FormFooterRight>
    </FooterStyled>
  )
}
