import { AuthAction, AuthActionType } from './actions'
import { Token } from './dto'

export type AuthState = {
  readonly loginFailure: boolean,
  readonly token: Token | null,
}

const defaultAuthState: AuthState = {
  loginFailure: false,
  token: null,
}

export const authReducer = (state: AuthState = defaultAuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionType.SetToken:
      return {
        ...state,
        loginFailure: false,
        token: action.payload,
      }

    case AuthActionType.LoginFailure:
      return {
        ...state,
        loginFailure: true,
      }

    default:
      return state
  }
}
