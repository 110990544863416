import { Testable } from 'actff-bo-lib/global/testable'
import { TabMenuItem } from 'actff-bo-lib/menu/dto'
import * as React from 'react'

import { TabItem } from './TabItem'
import { Ul } from './TabsStyled'

type TabsProps = Testable & {
  readonly tabItems: ReadonlyArray<TabMenuItem>,
  readonly autoWidth?: boolean,
}

export const Tabs: React.FC<TabsProps> = ({ tabItems, testId, autoWidth = false }) => (
  <Ul>
    {tabItems.map((item => <TabItem autoWidth={autoWidth} testId={testId} key={item.path} {...item} />))}
  </Ul>
)
