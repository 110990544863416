import { RatingComponent as Rating } from 'actff-bo-app/components/Rating'
import { ReactSelect } from 'actff-bo-app/components/Select'
import { SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Ref } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import { components } from 'react-select'
import { OptionProps } from 'react-select/src/components/Option'
import styled from 'styled-components/macro'
import { colors } from 'styles'

import { FilterContent, Label, RatingCheckboxLabel } from './Styled'

type MultipleSelectFilterProps = Testable & {
  readonly control: Control,
  readonly gridArea?: string;
  readonly label: string,
  readonly name: string,
  readonly register?: (ref: Ref | null) => void,
}

const Container = styled.div`
  align-content: start;
  display: grid;
  grid-row-gap: 15px;
  max-width: 300px;
`

const OptionContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  justify-content: left;
`

const ratingsCount = 6
const maxValue = 5
const halfRatingOffset = 0.5
const inputValueTrimLength = 2

const Option = (optionProps: OptionProps<SelectOption<string>, false>) => {
  const { children, data, isSelected } = optionProps

  return (
    <components.Option {...optionProps}>
      <OptionContainer>
        <input
          checked={isSelected}
          id={`filter--${children}`}
          readOnly={true}
          type='checkbox'
          value=''
        />
        <RatingCheckboxLabel id={`filter--${children}`} htmlFor={`filter--${children}`} />
        <Rating readonly={true} value={data.value} />
        {data.value < maxValue && `(${ Number(data.value) + halfRatingOffset})`}
      </OptionContainer>
    </components.Option>
  )
}

const MinWidth = 310

export const RatingsSelectFilter: FC<MultipleSelectFilterProps> = ({
  control,
  label,
  name,
  testId,
}): React.ReactElement | null => {
  const { t } = useTranslation()

  const options = Array.from({ length: ratingsCount }, (v, i ) => i).map(numberValue => ({
    label: `${numberValue} ${numberValue < maxValue ? `(${numberValue + halfRatingOffset})` : ''}`,
    value: numberValue,
  }))

  return (
    <FilterContent>
      <Container>
        <Label color={colors.black} uppercase={false}>{t(label)}:</Label>
        <Controller
          as={<ReactSelect />}
          control={control}
          isMulti={true}
          options={options}
          name={name}
          hideSelectedOptions={false}
          placeholder={t('placeholder.choose')}
          testId={`${testId}-${name}`}
          components={{ Option }}
          trimFilter={true}
          minWidth={MinWidth}
          trimLength={inputValueTrimLength}
        />
      </Container>
    </FilterContent>
  )
}
