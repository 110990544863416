import { Icon } from 'actff-bo-app/components/Icon'
import { Client } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type ApprovalClientProps = {
  readonly client: Client,
}

const ClientContainer = styled.div`
  background: ${colors.sprintWoodGold};
  grid-area: client-data;
  width: calc(100% + 80px);
  margin-left: -40px;
  padding: 32px 40px;
  display: grid;
  grid-auto-flow: row;
`

const ClientContainerHeader = styled.span`
  text-transform: uppercase;
  font-weight: 600;
`

const ClientName = styled.span`
  font-size: 18px;
  font-weight: 300;
`

const ClientData = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  justify-content: start;
`

const ClientDataItem = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  justify-content: start;
`

export const ApprovalClient: React.FC<ApprovalClientProps> = ({ client }) => {
  const { t } = useTranslation()

  return (
    <ClientContainer>
      <ClientContainerHeader>{t('clientView.approvals.clientData')}</ClientContainerHeader>
      <ClientName>
        {client.firstName} {client.lastName}
      </ClientName>
      <ClientData>
        <ClientDataItem>
          <Icon type={IconType.IdCard} /> {client.id}
        </ClientDataItem>
        <ClientDataItem>
          <Icon type={IconType.Phone} /> {client.phone}
        </ClientDataItem>
        <ClientDataItem>
          <Icon type={IconType.Email} /> {client.email}
        </ClientDataItem>
      </ClientData>
    </ClientContainer>
  )
}
