import { Icon } from 'actff-bo-app/components/Icon'
import { Tooltip } from 'actff-bo-app/components/Tooltip'
import { PagedResult } from 'actff-bo-lib/api'
import {
  OpportunityInsuranceId,
  OpportunityListItem,
  OpportunityListType,
  OpportunityStatus,
  selectSelectedAsProcessedUuid,
} from 'actff-bo-lib/crm/insurance'
import { IconType } from 'actff-bo-lib/menu'
import { selectUsersWithoutMe } from 'actff-bo-lib/user'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table, Th, Tr, TrDanger, TrSuccess, TrWarning } from 'styles'

import { OpportunityRow } from './OpporunityRow'

type Props = {
  readonly opportunities: PagedResult<OpportunityListItem>,
  readonly setSelectedAsProcessedUuid: (uuid: OpportunityInsuranceId) => void,
  readonly type: OpportunityListType,
}

const getRowComponent = (opportunity: OpportunityListItem): React.Component => {
  switch (opportunity.status) {
    case OpportunityStatus.BEFORE_OFFER:
      return opportunity.offerRequestFromApp
        ? TrDanger
        : TrWarning
    case OpportunityStatus.BEFORE_DECISION:
      return TrSuccess
    default:
      return Tr
  }
}

export const OpportunityTable: React.FC<Props> = ({ opportunities, setSelectedAsProcessedUuid, type }) => {
  const { t } = useTranslation()

  const { data: userList } = useSelector(selectUsersWithoutMe)
  const selectedAsProcessedUuid = useSelector(selectSelectedAsProcessedUuid)

  const handleSelectInsurance = (uuid: OpportunityInsuranceId) => {
    setSelectedAsProcessedUuid(uuid)
  }

  return (
    <Table>
      <thead>
        <Tr>
          <Th />
          <Th>{t('table.columns.status')}</Th>
          <Th>{t('table.columns.opportunityDate')}</Th>
          <Th>{t('table.columns.opportunityOrigin')}</Th>
          <Th>{t('table.columns.customer')}</Th>
          <Th>
            <Tooltip title={t('crmInsurance.process.mobileUser')}>
              <Icon type={IconType.MobilePhone} />
            </Tooltip>
          </Th>
          <Th>{t('table.columns.registrationNumberShort')}</Th>
          <Th>{t('table.columns.carProductionYear')}</Th>
          <Th>{t('table.columns.carModel')}</Th>
          <Th>{t('table.columns.location')}</Th>
          <Th>{t('table.columns.insurer')}</Th>
          <Th>{t('table.columns.policyExpirationDate')}</Th>
          <Th>{t('table.columns.sellType')}</Th>
          <Th>{t('table.columns.opportunitySource')}</Th>
        </Tr>
      </thead>
      <tbody>
        {opportunities.result.map(opportunity =>
          <OpportunityRow
            key={opportunity.uuid}
            opportunity={opportunity}
            rowComponent={getRowComponent(opportunity)}
            selectedInsuranceUuid={selectedAsProcessedUuid}
            onSelectInsurance={handleSelectInsurance}
            opportunityListType={type}
            userList={userList}
          />,
        )}
      </tbody>
    </Table>
  )
}
