import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { AjaxError } from 'rxjs/ajax'
import { OpeningHours } from '../dto'

export enum AdminOpeningHoursActionType {
  GetOpeningHours = '[SERVICE AND EMPLOYEES] GET OPENING HOURS',
  GetOpeningHoursFailure = '[SERVICE AND EMPLOYEES] GET OPENING HOURS FAILURE',
  GetOpeningHoursSuccess = '[SERVICE AND EMPLOYEES] GET OPENING HOURS SUCCESS',

  UpdateOpeningHours = '[SERVICE AND EMPLOYEES] UPDATE OPENING HOURS',
  UpdateOpeningHoursFailure = '[SERVICE AND EMPLOYEES] UPDATE OPENING HOURS FAILURE',
  UpdateOpeningHoursSuccess = '[SERVICE AND EMPLOYEES] UPDATE OPENING HOURS SUCCESS',

  ResetOpeningHours = '[SERVICE AND EMPLOYEES] RESET OPENING HOURS',
}

export const AdminOpeningHoursAction = {
  getOpeningHours: (locationKey?: DealerLocationKey) => createAction(AdminOpeningHoursActionType.GetOpeningHours, locationKey),
  getOpeningHoursFailure: (error: AjaxError) => createAction(AdminOpeningHoursActionType.GetOpeningHoursFailure, error),
  getOpeningHoursSuccess: (openingHours: ReadonlyArray<OpeningHours>) =>
    createAction(AdminOpeningHoursActionType.GetOpeningHoursSuccess, openingHours),

  updateOpeningHours: (payload: ReadonlyArray<OpeningHours>, locationKey?: DealerLocationKey) =>
    createAction(AdminOpeningHoursActionType.UpdateOpeningHours, { payload, locationKey }),
  updateOpeningHoursFailure: (error: AjaxError) => createAction(AdminOpeningHoursActionType.UpdateOpeningHoursFailure, error),
  updateOpeningHoursSuccess: (openingHours: ReadonlyArray<OpeningHours>) =>
    createAction(AdminOpeningHoursActionType.UpdateOpeningHoursSuccess, openingHours),
}

export type AdminOpeningHoursAction = ActionsUnion<typeof AdminOpeningHoursAction>
