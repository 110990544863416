import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { CarAssistance } from 'actff-bo-lib/admin/brands/dto'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TdWithDelete } from '../../Styled'

type Props = {
  readonly index: number,
  readonly assistance: CarAssistance,
  readonly onDelete: (assistance: CarAssistance) => void,
}

const Menu = styled.span`
  float: right;
`

export const AssistanceRow: FC<Props> = ({ index, onDelete, assistance }) => {
  const { t } = useTranslation()
  const [menuVisible, setMenuVisible] = useState(false)
  const showMenu = () => {
    setMenuVisible(true)
  }
  const hideMenu = () => {
    setMenuVisible(false)
  }

  const handleStopEvents = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDelete = (assistanceToDelete: CarAssistance) => () => { onDelete(assistanceToDelete) }

  return (
    <tr key={`${assistance} ${index}`}>
      <td onMouseOver={showMenu} onMouseLeave={hideMenu}>{index + 1}</td>
      <td onMouseOver={showMenu} onMouseLeave={hideMenu}>{assistance.assistanceName}</td>
      <TdWithDelete onMouseOver={showMenu} onMouseLeave={hideMenu}>
        {assistance.assistancePhoneNumber}
        {menuVisible && (
          <Menu
            onMouseOver={handleStopEvents}
            onMouseLeave={hideMenu}
          >
            <ButtonWithIcon
              caption={t('caption.delete')}
              onClick={handleDelete(assistance)}
              iconType={IconType.Trash}
              noPadding={true}
              theme={BtnTheme.Link}
            />
          </Menu>
        )}
      </TdWithDelete>
    </tr>
  )
}
