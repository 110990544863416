import { BtnType } from 'actff-bo-app/components/Button'
import { PreviewFiles } from 'actff-bo-app/components/UploadFile/PreviewFiles'
import { ChatViewUrlParams, MessagePayload, MessagePayloadType } from 'actff-bo-lib/chat'
import { Attachment as AttachmentAsType } from 'actff-bo-lib/global'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import { PrimaryButton } from 'styles'

import {
  ChatNewMessageContainer,
  ChatNewMessageForm,
  ChatNewMessageInput,
  ChatUploadAttachment,
  FieldStyled,
} from './ChatConversationStyled'

type Values = {
  readonly attachments: ReadonlyArray<AttachmentAsType>,
  readonly message: string,
}

type Props = {
  readonly onMessageSend: (messagePayload: MessagePayload) => void,
  readonly onContainerHeightChange?: () => void,
}

type DispatchToProps = {
  readonly displayAttachmentLimitWarning: (title: string) => void,
}

type ChatConversationSendMessageProps = RouteComponentProps<ChatViewUrlParams>
  & DispatchToProps
  & Props
  & FormikProps<Values>
  & WithTranslation

const initialValues: Values = {
  attachments: [],
  message: '',
}

const enterKeyCode = 13
// tslint:disable deprecation
// TODO type mismatch, find proper type
// tslint:disable-next-line:no-any
const handleKeyDown = (callback: () => void): any => (event: KeyboardEvent) => {
  if (event.keyCode === enterKeyCode) {
    event.preventDefault()
  }

  if (event.ctrlKey && event.keyCode === enterKeyCode) {
    callback()
  }
}

const maximumAttachments = 20
const maximumAttachmentSizeInBytes = 20 * 1024 * 1024 // tslint:disable-line no-magic-numbers
const maxRowsInMessageInput = 4

class ChatConversationSendMessageComponent extends React.Component<ChatConversationSendMessageProps> {
  public componentDidUpdate(oldProps: ChatConversationSendMessageProps): void {
    if (oldProps.values.attachments.length !== this.props.values.attachments.length && this.props.onContainerHeightChange) {
      this.props.onContainerHeightChange()
    }

    if (this.props.match.params !== oldProps.match.params) {
      this.props.resetForm()
    }
  }

  public render(): React.ReactNode {
    const { handleSubmit, handleChange, onContainerHeightChange, t, values } = this.props
    const { attachments } = values

    return (
      <>
        <PreviewFiles files={attachments} onDeleteFile={this.handleDeleteAttachment} />
        <ChatNewMessageForm onSubmit={handleSubmit}>
          <ChatNewMessageContainer>
            <ChatNewMessageInput
              maxRows={maxRowsInMessageInput}
              name='message'
              onChange={handleChange}
              onKeyDown={handleKeyDown(handleSubmit)}
              onHeightChange={onContainerHeightChange && onContainerHeightChange}
              type='text'
              value={values.message}
            />
            <FieldStyled
              name='attachments'
              acceptTypes='image/x-png, image/gif, image/jpeg, application/pdf, image/heif, image/heic'
              component={ChatUploadAttachment}
              className='upload-attachment'
              onChange={this.handleUploadAttachments}
              multiple={true}
            />
          </ChatNewMessageContainer>
          <PrimaryButton
            disabled={this.shouldDisableForm()}
            type={BtnType.Submit}
          >
            {t('caption.send')}
          </PrimaryButton>
        </ChatNewMessageForm>
      </>
    )
  }

  private readonly getAttachmentTotalSize = (attachments: ReadonlyArray<AttachmentAsType>) =>
    attachments.reduce((prev, attachment) => prev + attachment.file.size, 0)

  private readonly shouldDisableForm = () => {
    const { attachments } = this.props.values

    return attachments.length > maximumAttachments || this.getAttachmentTotalSize(attachments) > maximumAttachmentSizeInBytes
  }

  private readonly handleUploadAttachments = (files: ReadonlyArray<AttachmentAsType>) => {
    const { values } = this.props
    const { attachments } = values

    if (attachments.length + files.length > maximumAttachments) {
      this.props.displayAttachmentLimitWarning('attachments.maximum.number')
    }

    if (this.getAttachmentTotalSize(files) > maximumAttachmentSizeInBytes) {
      this.props.displayAttachmentLimitWarning('attachments.maximum.size')
    }

    this.props.setFieldValue('attachments', [
      ...attachments,
      ...files,
    ])
  }

  private readonly handleDeleteAttachment = (file: AttachmentAsType) => () => {
    const { values } = this.props
    const { attachments } = values

    this.props.setFieldValue('attachments', [
      ...attachments.filter(attachment => attachment.file !== file.file),
    ])
  }
}

const formik = withFormik<Props, Values>({
  handleSubmit: (({ attachments, message }, { props, resetForm }) => {
    props.onMessageSend({
      attachments,
      message,
      type: MessagePayloadType.MESSAGE,
    })

    resetForm()
  }),
  mapPropsToValues: () => initialValues,
})

const dispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  displayAttachmentLimitWarning: (title: string) => {
    dispatch(ToastAction.displayToast({
      body: '',
      id: title,
      title,
      type: ToastType.Warning,
    }))
  },
})

export const ChatConversationSendMessage = compose(
  formik,
  withRouter,
  withTranslation(),
  connect(null, dispatchToProps),
)(ChatConversationSendMessageComponent)
