import { OpportunityFailureReason, OpportunityService, OpportunityStatus } from 'actff-bo-lib/crm/service'

// tslint:disable cyclomatic-complexity

export const internalCommentRequired = (opportunity: OpportunityService | null) => opportunity
    ? (
      (opportunity.status === OpportunityStatus.FAILED && opportunity.failureReason === OpportunityFailureReason.OTHER)
        || opportunity.status === OpportunityStatus.RETRY
        || opportunity.status === OpportunityStatus.RETRY_LIST
        || opportunity.status === OpportunityStatus.MISSED
    )
    : false

export const isCommentEmpty = (comment: string): boolean => !comment || comment === ''

export const isOpportunityFormValid = (opportunity: OpportunityService | null, isCommentValid: boolean, comment: string): boolean => {
  if (!opportunity) {
    return false
  }

  switch (opportunity.status) {
    case OpportunityStatus.NEW:
    case OpportunityStatus.NEW_LIST:
      return !isCommentEmpty(comment)
    case OpportunityStatus.RETRY:
    case OpportunityStatus.RETRY_LIST:
      return isCommentValid
    case OpportunityStatus.FAILED:
      return (opportunity.failureReason && opportunity.failureReason !== OpportunityFailureReason.OTHER)
        || (internalCommentRequired(opportunity) && isCommentValid)
    case OpportunityStatus.MISSED:
      return internalCommentRequired(opportunity) && isCommentValid
    default:
      return true
  }
}
