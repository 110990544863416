import { AuthAction } from 'actff-bo-lib/auth'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { UserAction, UserActionType } from 'actff-bo-lib/user'
import firebase, { app, apps, messaging } from 'firebase'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { from, merge, of, Subject } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, flatMap, ignoreElements, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators'

import { NotificationAction, NotificationActionType } from './actions'
import { initFcm, initFcmSw, registerToken, unregisterToken } from './dao'
import { NotificationPayload, NotificationPermissionType } from './dto'

const initializeNotificationEpic: Epic<NotificationAction> = action$ => action$.pipe(
  ofType(NotificationActionType.Init),
  tap(() => {
    try {
      if (apps.length) {
        app().delete()
          .then(initFcm)
          .catch(() => of(ToastAction.displayToast({
            body: 'notification.error.init',
            id: 'caption.error',
            title: 'caption.error',
            type: ToastType.Error,
          })))
      } else {
        initFcm()
      }
    } catch (error) {
      of(ToastAction.displayToast({
        body: 'notification.error.init',
        id: 'caption.error',
        title: 'caption.error',
        type: ToastType.Error,
      }))
    }
  }),
  tap(initFcmSw),
  ignoreElements(),
)

const requestNotificationPermissionEpic: Epic<UserAction | NotificationAction> = action$ => action$.pipe(
  ofType(UserActionType.GetMeSuccess),
  map(() => firebase.messaging.isSupported()),
  filter(Boolean),
  switchMap(() => from(Notification.requestPermission()).pipe(
    map(permission => NotificationAction.requestPermission(permission)),
  )),
)

const notificationPermissionDeniedEpic: Epic<NotificationAction | ToastAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof NotificationAction.requestPermission>>(NotificationActionType.RequestPermission),
  filter(({ payload }) => payload === NotificationPermissionType.Default || payload === NotificationPermissionType.Denied),
  switchMap(() => [
    NotificationAction.requestPermissionFailure(),
    ToastAction.displayToast({
      body: '',
      id: 'notification.denied.title',
      title: 'notification.denied.title',
      type: ToastType.Info }),
  ]),
)

const getNotificationTokenEpic: Epic<NotificationAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof NotificationAction.requestPermission>>(
    NotificationActionType.RequestPermission
      || NotificationActionType.RefreshToken,
  ),
  filter(({ payload }) => payload === NotificationPermissionType.Granted),
  flatMap(() => from(messaging().getToken())),
  map(NotificationAction.registerToken),
)

const receivedNotificationEpic: Epic<NotificationAction> = action$ => action$.pipe(
  ofType(NotificationActionType.RegisterTokenSuccess),
  flatMap(() => {
    const subject$ = new Subject<NotificationPayload>()
    messaging().onMessage(({ data }) => subject$.next(data))

    return merge(
      subject$.pipe(map(NotificationAction.receivedNewMessage)),
    )
    .pipe(
      takeUntil(action$.pipe(
        ofType(NotificationActionType.UnregisterToken),
      )),
    )
  }),
)

const displayNotificationEpic: Epic<NotificationAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof NotificationAction.receivedNewMessage>>(NotificationActionType.ReceivedNewMessage),
  map(({ payload }) => payload),
  tap<NotificationPayload>(({ title, subtitle: body, icon, type }) =>
    new Notification(title, { body, icon, requireInteraction: true, tag: `${window.location.origin}-${type}` })),
  ignoreElements(),
)

// TODO: Prepare refresh token
// const refreshNotificationTokenEpic: Epic<NotificationAction> = action$ => action$.pipe(
//   ofType<ReturnType<typeof NotificationAction.requestPermission>>(NotificationActionType.RequestPermission),
//   filter(({ payload }) => payload === NotificationPermissionType.Granted),
//   tap(() => messaging().onTokenRefresh(() =>
//     switchMap(() => of(NotificationAction.refreshToken())),
//   )),
// )

const registerNotificationTokenEpic: Epic<NotificationAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof NotificationAction.registerToken>>(NotificationActionType.RegisterToken),
  switchMap(({ payload }) => registerToken(payload).pipe(
    map(NotificationAction.registerTokenSuccess),
    catchError((error: AjaxError) => of(NotificationAction.registerTokenFailure(error))),
  )),
)

const unregisterNotificationTokenEpic: Epic<NotificationAction, AnyAction, State> = (action$, state$) => action$.pipe(
  ofType(NotificationActionType.UnregisterToken),
  withLatestFrom(state$),
  map(([, { notification }]) => notification.notificationToken),
  switchMap(token =>
    token
      ? unregisterToken(token).pipe(
        switchMap(() => [
          NotificationAction.unregisterTokenSuccess(),
          AuthAction.logout(),
        ]),
        catchError((error: AjaxError) => of(NotificationAction.unregisterTokenFailure(error))),
      )
    : of(AuthAction.logout())),
)

export const notificationEpic = combineEpics(
  initializeNotificationEpic,
  requestNotificationPermissionEpic,
  registerNotificationTokenEpic,
  unregisterNotificationTokenEpic,
  // refreshNotificationTokenEpic,
  receivedNotificationEpic,
  displayNotificationEpic,
  getNotificationTokenEpic,
  notificationPermissionDeniedEpic,
)
