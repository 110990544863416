import { CarInfo } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { NominalString } from 'actff-bo-lib/global'
import { Service } from 'actff-bo-lib/service'
import { User } from 'actff-bo-lib/user'

import { CarLeaveOption, createCarLeaveOption } from './car-leave-option'
import { ServiceRequestStatus } from './status'

export type ServiceRequestId = NominalString<ServiceRequest>

export type ServiceRequest = {
  readonly uuid: ServiceRequestId,
  readonly car: CarInfo,
  readonly client: Client
  readonly status: ServiceRequestStatus,
  readonly carLeaveOption: CarLeaveOption,
  readonly services: ReadonlyArray<Service>,
  readonly attachmentList: ReadonlyArray<string>,
  readonly locationKey: DealerLocationKey,
  readonly createdTime: Date,
  readonly updatedTime: Date,
  readonly additionalRemarks: string,
  readonly interestedInEarlierDate: boolean,
  readonly internalComments: ReadonlyArray<string>,
  readonly requestNumber: string,
  readonly author: Omit<User, 'permissions'> | null,
}

export type ServiceRequestCount = {
  readonly requestCount: number,
}

export type ServiceRequestFilterValues = ServiceRequestStatus

export const toServiceRequest = (data: any) => ({ // tslint:disable-line no-any
    ...data,
    carLeaveOption: createCarLeaveOption(data.carLeaveOption),
    createdTime: parseToDateOrNull(data.createdTime),
})

export const parseRequestNumber = (requestNumber: string) => requestNumber.split('/')[0]
