import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { EditableList } from 'actff-bo-app/components/EditableList/EditableList'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  addDealerLocationD2DCity,
  deleteDealerLocationD2DCity,
  getDealerLocationD2DCities,
} from 'actff-bo-lib/admin/dealer-locations/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { selectCurrentLocation } from 'actff-bo-lib/dealership'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React, { FC } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { ViewTabs } from '../ViewTabs'

const testId = 'admin-view-d2d-cities__'

const D2DCitiesComponent: FC = () => {
  const dispatch = useDispatch()
  const currentLocation = useSelector(selectCurrentLocation)

  const { data: d2dCities, isLoading, isError, refetch } = useQuery([QueryKeys.GET_LOCATION_BRANDS, currentLocation], async () =>
    await getDealerLocationD2DCities(currentLocation?.key), { refetchOnWindowFocus: false, retry: false },
  )

  const addD2DCityMutation = useMutation(
    QueryKeys.ADD_LOCATION_D2D_CITY,
    addDealerLocationD2DCity,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.brands.locationD2DCity.get.failure', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch()
        dispatch(displaySuccessToast('admin.brands.addLocationD2DCity.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const deleteBrandMutation = useMutation(
    QueryKeys.DELETE_LOCATION_D2D_CITY,
    deleteDealerLocationD2DCity,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.brands.deleteLocationD2DCity.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch()
        dispatch(displaySuccessToast('admin.brands.deleteLocationD2DCity.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )
  const handleAdd = (name: string) => {
    currentLocation && addD2DCityMutation.mutate({ name, key: currentLocation.key })
  }

  const handleDelete = (name: string) => {
    currentLocation && deleteBrandMutation.mutate({ name, key: currentLocation.key })
  }

  if (!d2dCities) {
    return (
      <>
        <ViewTabs testId={testId} />
        <NoData />
      </>
    )
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <LocalLoader />
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('admin.brands.locationD2DCity.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <EditableList
        tableHeaderCaptionTKey={'table.columns.city'}
        addButtonCaptionTKey={'admin.contentManagement.d2dCities.addCity'}
        items={d2dCities}
        onAdd={handleAdd}
        onDelete={handleDelete}
      />
    </>
  )
}

export const D2DCities = withAdminViewHeader(D2DCitiesComponent)
