import { OpportunityPurchaseFilterValues, OpportunityPurchaseStatusType } from 'actff-bo-lib/crm/trade/dto'
import { Filters } from 'actff-bo-lib/global'
import { UserPermissions } from 'actff-bo-lib/user'

export const initialStatusFilters: Filters<OpportunityPurchaseFilterValues> = {
  statuses: [
    {
      caption: 'filters.opportunity.waitForFirstContact',
      selected: false,
      value: OpportunityPurchaseStatusType.WAIT_FOR_FIRST_CONTACT,
    },
    {
      caption: 'filters.opportunity.firstContact',
      selected: false,
      value: OpportunityPurchaseStatusType.FIRST_CONTACT,
    },
    {
      caption: 'filters.opportunity.retry',
      selected: false,
      value: OpportunityPurchaseStatusType.RETRY_CONTACT,
    },
    {
      caption: 'filters.opportunity.postponedContact',
      selected: false,
      value: OpportunityPurchaseStatusType.POSTPONED_CONTACT,
    },
    {
      caption: 'filters.opportunity.expired',
      selected: false,
      value: OpportunityPurchaseStatusType.EXPIRED,
    },
    {
      caption: 'filters.opportunity.buyOffer',
      requiredPermissions: [UserPermissions.AdminAllService],
      selected: false,
      value: OpportunityPurchaseStatusType.BUY_OFFER,
    },
    {
      caption: 'filters.opportunity.initialValuation',
      selected: false,
      value: OpportunityPurchaseStatusType.INITIAL_VALUATION,
    },
    {
      caption: 'filters.opportunity.inspection',
      selected: false,
      value: OpportunityPurchaseStatusType.INSPECTION,
    },
    {
      caption: 'filters.opportunity.waitingList',
      selected: false,
      value: OpportunityPurchaseStatusType.WAITING_LIST,
    },
    {
      caption: 'filters.opportunity.success',
      requiredPermissions: [UserPermissions.AdminAllService],
      selected: false,
      value: OpportunityPurchaseStatusType.SUCCESS,
    },
    {
      caption: 'filters.opportunity.failure',
      requiredPermissions: [UserPermissions.AdminAllService],
      selected: false,
      value: OpportunityPurchaseStatusType.FAILURE,
    },
  ],
}
