import { DateTime } from 'actff-bo-app/components/DateTime'
import { FormCell, FormHeader } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import { CarLeaveOptionAddress, D2D as D2DType } from 'actff-bo-lib/service-request'
import { Field, FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'

type Props = {
  readonly carLeaveOption: D2DType,
  readonly editMode: boolean,
  readonly locale: {},
  readonly onCarLeaveOptionChange: (carLeaveOption: D2DType) => void,
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

type AddressOwnProps = Testable & {
  readonly address: CarLeaveOptionAddress,
  readonly onAddressChange: (address: CarLeaveOptionAddress) => void,
}

type AddressProps = AddressOwnProps & FormikProps<CarLeaveOptionAddress> & WithTranslation

type D2DProps = Testable & Props & FormikProps<{}>

class AddressFormComponent extends React.Component<AddressProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: AddressProps): void {
    if (nextProps.values !== this.props.values) {
      this.props.onAddressChange(nextProps.values)
    }
  }

  public render(): React.ReactNode {
    const { t, testId } = this.props

    return (
      <Container>
        <FormCell>
          <label>{t('serviceRequest.carLeaveOption.d2d.street')}</label>
          <Field testId={`${testId}-street`} name='street' />
        </FormCell>
        <FormCell>
          <label>{t('serviceRequest.carLeaveOption.d2d.buildNo')}</label>
          <Field testId={`${testId}-build-number`} name='buildNo' />
        </FormCell>
        <FormCell>
          <label>{t('serviceRequest.carLeaveOption.d2d.city')}</label>
          <Field testId={`${testId}-city`} name='city' />
        </FormCell>
        <FormCell>
          <label>{t('serviceRequest.carLeaveOption.d2d.postCode')}</label>
          <Field testId={`${testId}-post-code`}name='postCode' />
        </FormCell>
      </Container>
    )
  }
}

const addressFormik = withFormik<AddressOwnProps, CarLeaveOptionAddress>({
  handleSubmit: () => null,
  mapPropsToValues: ({ address: { buildNo, city, postCode, street } }) => ({
    buildNo,
    city,
    postCode,
    street,
  }),
})

const AddressForm = compose(addressFormik, withTranslation())(AddressFormComponent)

const Address: React.FC<{ readonly address: CarLeaveOptionAddress} & Testable> = ({ address, testId }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <FormCell>
        <label>{t('serviceRequest.carLeaveOption.d2d.street')}</label>
        <span data-testid={`${testId}-street`}>{address.street}</span>
      </FormCell>
      <FormCell>
        <label>{t('serviceRequest.carLeaveOption.d2d.buildNo')}</label>
        <span data-testid={`${testId}-build-number`}>{address.buildNo}</span>
      </FormCell>
      <FormCell>
        <label>{t('serviceRequest.carLeaveOption.d2d.city')}</label>
        <span data-testid={`${testId}-city`}>{address.city}</span>
      </FormCell>
      <FormCell>
        <label>{t('serviceRequest.carLeaveOption.d2d.postCode')}</label>
        <span data-testid={`${testId}-post-code`}>{address.postCode}</span>
      </FormCell>
    </Container>
  )
}

const D2DComponent: React.FC<D2DProps> = ({ carLeaveOption, editMode, locale, onCarLeaveOptionChange, testId }) => {
  const handleCarLeaveOptionChange = (field: keyof D2DType) => (value: CarLeaveOptionAddress | Date | null) => {
    onCarLeaveOptionChange({
      ...carLeaveOption,
      [field]: value,
    })
  }
  const { t } = useTranslation()

  return (
    <Container>
      <FormCell slim={true}>
        <FormHeader>{t('serviceRequest.carLeaveOption.d2d.giveAway')}</FormHeader>
      </FormCell>
      <FormCell slim={true}>
        <FormHeader>{t('serviceRequest.carLeaveOption.d2d.giveBack')}</FormHeader>
      </FormCell>
      <Field
        component={DateTime}
        dateTime={carLeaveOption.giveAwayTime}
        editMode={editMode}
        locale={locale}
        onDateTimeChange={handleCarLeaveOptionChange('giveAwayTime')}
        className='give-away-time'
        testId={`${testId}give-away`}
      />
      <Field
        component={DateTime}
        dateTime={carLeaveOption.giveBackTime}
        editMode={editMode}
        locale={locale}
        onDateTimeChange={handleCarLeaveOptionChange('giveBackTime')}
        className='give-back-time'
        testId={`${testId}give-back`}
      />
      {editMode
        ? (
        <AddressForm
          address={carLeaveOption.takeAddress}
          onAddressChange={handleCarLeaveOptionChange('takeAddress')}
          testId={`${testId}take-address`}
        />
        )
        : <Address address={carLeaveOption.takeAddress} testId={`${testId}take-address`} />
      }
      {editMode
        ? (
        <AddressForm
          address={carLeaveOption.returnAddress}
          onAddressChange={handleCarLeaveOptionChange('returnAddress')}
          testId={`${testId}return-address`}
        />
        )
        : <Address address={carLeaveOption.returnAddress} testId={`${testId}return-address`} />
      }
    </Container>
  )
}

const formik = withFormik<Props, {}>({
  handleSubmit: () => null,
})

export const D2D = compose(
  formik,
)(D2DComponent)
