import i18next from 'i18next'

export enum SchedulerViewType {
  DAY = 'resourceTimelineDay',
  WEEK = 'resourceWeekWithoutTime',
  MONTH = 'resourceTimelineMonth',
}

export const schedulerInitialView = SchedulerViewType.WEEK

export const resourceAreaWidth = '15%'

export const schedulerHeaderToolbarConfig = {
  center: '',
  left: '',
  right: `prev,title,next,${SchedulerViewType.DAY},${SchedulerViewType.WEEK},${SchedulerViewType.MONTH}`,
}

export const resourceWeekWithoutTime = (t: i18next.TFunction) => ({
  resourceWeekWithoutTime: {
    buttonText: t('caption.week'),
    duration: { weeks: 1 },
    slotDuration: { days: 1 },
    type: 'resourceTimeline',
  },
})
