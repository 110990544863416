import { ErrorMessage } from '@hookform/error-message'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { FormCell, FormCellWide, handleBoolCheck, MoneyInput, RadioBoxes } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'

type Props = Testable
  & SectionFormType
  & {
    readonly installmentCount: number,
    readonly readOnly?: boolean,
  }

export const SectionPolicyConfirmation: React.FC<Props> = ({
  control,
  errors,
  expanded,
  watch,
  setValue,
  installmentCount,
  testId,
  readOnly,
}) => {
  const { t } = useTranslation()

  const finalCommission = (value: number, correction: number) => value - correction

  const finalCommissionCalculator = (value: number, correction: number) => {
    const valueAfterCorrection = finalCommission(value, correction)

    if (valueAfterCorrection < 0) { return 0 }

    return valueAfterCorrection
  }

  React.useEffect(() => {
    Array(installmentCount).fill(null).forEach((_, index) => {
      setValue(`confirmation.commissions[${index}].valueAfterCorrection`,
        finalCommissionCalculator(
          watch(`confirmation.commissions[${index}].value`),
          watch(`confirmation.commissions[${index}].correction`)))
    })
  }, [watch, setValue, installmentCount])

  const correctionValueValidation = (index: number) => (correction: number) => {
    const value = watch(`confirmation.commissions[${index}].value`)
    const valueAfterCorrection = finalCommission(value, correction)

    return (valueAfterCorrection < 0) ? 'caption.error.correctionValue' : undefined
  }

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.policyConfirmation'), !!errors.confirmation)}>
      <SectionForm>
        <FormCell>
          <label>{t('crmInsurance.policyConfirmation.isConfirmed')}:</label>
          <Controller
            render={({ value, onChange }) => (
              <RadioBoxes>
                <div>
                  <input
                    data-testid={`${testId}policyConfirmation-confirmed-yes`}
                    id='confirmed-yes'
                    type='radio'
                    name='confirmation.policyConfirmed'
                    defaultChecked={!!value}
                    onClick={handleBoolCheck(true, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='confirmed-yes'>{t('caption.yes')}</label>
                </div>
                <div>
                  <input
                    data-testid={`${testId}policyConfirmation-confirmed-no`}
                    id='confirmed-no'
                    type='radio'
                    name='confirmation.policyConfirmed'
                    defaultChecked={!value}
                    onClick={handleBoolCheck(false, onChange)}
                    disabled={readOnly}
                  />
                  <label htmlFor='confirmed-no'>{t('caption.no')}</label>
                </div>
              </RadioBoxes>
            )}
            control={control}
            name='confirmation.policyConfirmed'
          />
        </FormCell>
        <FormCell />
        <FormCellWide />

        {Array(installmentCount).fill(null).map((_, index) => (
          <React.Fragment key={index}>
            <FormCell>
              <label>{t('crmInsurance.policyConfirmation.value', { number: index + 1 })}:</label>
              <MoneyInput control={control} name={`confirmation.commissions[${index}].value`} disabled={readOnly} />
            </FormCell>
            <FormCell>
              <label>{t('crmInsurance.policyConfirmation.correction', { number: index + 1 })}:</label>
              <MoneyInput
                control={control}
                name={`confirmation.commissions[${index}].correction`}
                rules={{ validate: correctionValueValidation(index) }}
                disabled={readOnly}
              />
              <ErrorMessage errors={errors} name={`confirmation.commissions[${index}].correction`} as={TranslatedErrorMessage} />
            </FormCell>
            <FormCell>
              <label>{t('crmInsurance.policyConfirmation.finalCommission', { number: index + 1 })}:</label>
              <MoneyInput control={control} name={`confirmation.commissions[${index}].valueAfterCorrection`} disabled={readOnly} />
            </FormCell>
            <FormCell />
          </React.Fragment>
        ))}
      </SectionForm>
    </CollapsableSection>
  )
}
