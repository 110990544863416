import React, { CSSProperties, FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import styled from 'styled-components/macro'

type Props = {
  readonly control: Control,
  readonly disabled?: boolean,
  readonly name: string,
  readonly type?: string,
  readonly styles?: CSSProperties,
}

type InputProps = {
  readonly width?: string,
}

const Input = styled.input<InputProps>`
  ${({ width }: InputProps) => width && `width: ${width}`};
`

// Controlled RHF Input
export const ControlledInput: FC<Props> = ({ control, disabled = false, name, styles, type = 'text' }) => (
  <Controller
    render={({onChange, value}) => (
      <Input
        disabled={disabled}
        onChange={onChange}
        type={type}
        value={value}
        style={styles}
      />
    )}
    control={control}
    name={name}
  />
)
