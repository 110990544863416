import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CheckboxFilterContent } from './Styled'

type InputCheckboxFilterProps = {
  readonly control: Control,
  readonly name: string,
  readonly oppositeFieldName?: string,
  readonly caption: string,
  readonly shouldTranslate?: boolean,
  readonly setValue?: (key: string, value: boolean) => void,
}

export const InputCheckboxFilter: FC<InputCheckboxFilterProps> = ({
  control,
  name,
  caption,
  shouldTranslate = true,
  oppositeFieldName,
  setValue,
}) => {
  const { t } = useTranslation()

  const handleChange = (value: boolean) => {
    if (value && oppositeFieldName && setValue) {
      setValue(oppositeFieldName, !value)
    }
  }

  return (
    <CheckboxFilterContent>
      <Controller
        render={({ onChange, value }) => (
          <>
            <input
              onChange={() => {
                onChange(!value)
                handleChange(!value)
              }}
              id={`filter-${name}`}
              name={name}
              checked={value}
              type='checkbox'
            />
            <label id={`filter-${name}`} htmlFor={`filter-${name}`}>{shouldTranslate ? t(caption) : caption}</label>
          </>
        )}
        control={control}
        name={name}
      />
    </CheckboxFilterContent>
  )
}
