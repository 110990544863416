import { ToastAction, ToastActionType } from './actions'
import { Toast } from './dto'

export type ToastState = {
  readonly activeToasts: ReadonlyArray<Toast>,
}

const initialState: ToastState = {
  activeToasts: [],
}

export const toastReducer = (state: ToastState = initialState, action: ToastAction): ToastState => {
  switch (action.type) {
    case ToastActionType.DisplayToast: {
      return {
        ...state,
        activeToasts: [
          ...state.activeToasts,
          action.payload,
        ],
      }
    }

    case ToastActionType.HideToast: {
      return {
        ...state,
        activeToasts: state.activeToasts.filter(toast => toast.id !== action.payload),
      }
    }

    default:
      return state
  }
}
