import { PagedResult } from 'actff-bo-lib/api'
import { Testable } from 'actff-bo-lib/global/testable'
import { NdcCar } from 'actff-bo-lib/ndc-car'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { NdcCarRow } from './NdcCarRow'

type Props = Testable & {
  readonly ndcCars: PagedResult<NdcCar>,
}

export const NdcCarTable: FC<Props> = ({ ndcCars: { result }}) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
          <Tr>
            <Th>{t('ndcCarList.header.uuid')}</Th>
            <Th>{t('ndcCarList.header.vin')}</Th>
            <Th>{t('ndcCarList.header.registrationNumber')}</Th>
            <Th>{t('ndcCarList.header.owner')}</Th>
            <Th>{t('ndcCarList.header.phone')}</Th>
            <Th>{t('table.columns.location')}</Th>
          </Tr>
        </thead>
        <tbody>
          {result.length && result.map((ndcCar, index) =>
            <NdcCarRow ndcCar={ndcCar} key={`${ndcCar.vin} - ${index}`} />,
          )}
        </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )
}
