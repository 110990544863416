import { InputDateFilter, MultipleSelectFilter } from 'actff-bo-app/components/Filters'
import { Label } from 'actff-bo-app/components/Label'
import { FiltersRendererProps } from 'actff-bo-app/components/TabFilters'
import {
  OpportunityDefinitiveFailureReason,
  OpportunityFailureReason,
  OpportunityFailureResult,
  OpportunityInsuranceSellTypes,
  OpportunityResult,
  OpportunitySourceName,
  OpportunityStatus,
} from 'actff-bo-lib/crm/insurance'
import { InsuranceFilters } from 'actff-bo-lib/crm/insurance/filters'
import { mapToOptions, SelectOption } from 'actff-bo-lib/global'
import compact from 'lodash/compact'
import differenceBy from 'lodash/differenceBy'
import flatten from 'lodash/flatten'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CheckboxContainer,
  Checkboxes,
  CheckboxesTwoColumns,
  Container,
  DetailedFiltersContainer,
  FailureReasonFilterContainer,
  FilterContainer,
  OpportunitySourceContainer,
  ResultFilterContainer,
} from './Styled'

type Props = FiltersRendererProps & {
  readonly filters: InsuranceFilters,
}

const minSelectWidth = 200
const maxSelectWidth = 230

export const OpportunityListFilters: React.FC<Props> = ({ control, register, watch, setValue }) => {
  const { t } = useTranslation()

  const result = watch('result')
  const selectedFailureReasons = watch('failureReason')
  const resultValue = result.map((res: SelectOption<string>) => res.value)

  const anyFailureReasonSelected = OpportunityFailureResult.some(failureResult => resultValue.includes(failureResult))

  const failureSelected = resultValue.includes(OpportunityResult.FAILURE)
  const definitiveFailureSelected = resultValue.includes(OpportunityResult.DEFINITIVE_FAILURE)
  const onlyFailureSelected = failureSelected && !definitiveFailureSelected
  const onlyDefinitiveFailureSelected = definitiveFailureSelected && !failureSelected

  const disableFailureReason = !(result && anyFailureReasonSelected)

  const sellTypes = mapToOptions(Object.keys(OpportunityInsuranceSellTypes), 'crmInsurance.insuranceSellType', t)
  const results = mapToOptions(Object.keys(OpportunityResult), 'crmInsurance.result', t)
  const failureReasons = mapToOptions(Object.keys(OpportunityFailureReason), 'crmInsurance.failureReason', t)
  const definitiveFailureReasons = mapToOptions(Object.keys(OpportunityDefinitiveFailureReason), 'crmInsurance.failureReason', t)

  React.useEffect(() => {
    const failureReasonsToExclude = flatten([
      ...compact([(!failureSelected && failureReasons)]),
      ...compact([(!definitiveFailureSelected && definitiveFailureReasons)]),
    ])

    setValue('failureReason', differenceBy(selectedFailureReasons, failureReasonsToExclude, 'value'))
  }, [failureSelected, definitiveFailureSelected])

  const getFailureReasons = () => {
    if (onlyFailureSelected) {
      return failureReasons
    }

    if (onlyDefinitiveFailureSelected) {
      return definitiveFailureReasons
    }

    if (anyFailureReasonSelected) {
      return failureReasons.concat(definitiveFailureReasons)
    }

    return []
  }

  return (
    <Container>
      <div>
        <Label>{t('crmInsurance.filters.statuses')}</Label>
        <Checkboxes>
          {Object.keys(OpportunityStatus).map((status: OpportunityStatus) => (
            <CheckboxContainer key={status.toString()}>
              <input
                id={`filter-statuses-${status}`}
                name='status'
                value={status}
                type='checkbox'
                ref={register}
              />
              <label htmlFor={`filter-statuses-${status}`}>{t(`crmInsurance.status.${status.toLocaleLowerCase()}`)}</label>
            </CheckboxContainer>
            ),
          )}
        </Checkboxes>
      </div>

      <DetailedFiltersContainer>
        <FilterContainer>
          <Label>{t('crmInsurance.filters.expires')}</Label>
          <InputDateFilter
            name='expiresMin'
            caption='caption.from'
            control={control}
            timeDisabled={true}
          />
          <InputDateFilter
            name='expiresMax'
            caption='caption.to'
            control={control}
            timeDisabled={true}
          />
        </FilterContainer>

        <FilterContainer>
          <Label>{t('crmInsurance.filters.created')}</Label>
          <InputDateFilter
            name='createdMin'
            caption='caption.from'
            control={control}
            timeDisabled={true}
          />
          <InputDateFilter
            name='createdMax'
            caption='caption.to'
            control={control}
            timeDisabled={true}
          />
        </FilterContainer>

        <FilterContainer>
          <Label>{t('crmInsurance.filters.newPolicySellType')}</Label>
          <MultipleSelectFilter
            closeMenuOnSelect={true}
            control={control}
            key='newPolicySellType'
            name='newPolicySellType'
            options={sellTypes}
            trimLength={1}
            maxWidth={maxSelectWidth}
            minWidth={minSelectWidth}
          />
        </FilterContainer>

        <FilterContainer>
          <Label>{t('crmInsurance.filters.oldPolicySellType')}</Label>
          <MultipleSelectFilter
            closeMenuOnSelect={true}
            control={control}
            key='oldPolicySellType'
            name='oldPolicySellType'
            options={sellTypes}
            trimLength={1}
            maxWidth={maxSelectWidth}
            minWidth={minSelectWidth}
          />
        </FilterContainer>

        <ResultFilterContainer>
          <Label>{t('crmInsurance.filters.result')}</Label>
          <MultipleSelectFilter
            closeMenuOnSelect={true}
            control={control}
            key='result'
            name='result'
            options={results}
            trimLength={1}
            maxWidth={maxSelectWidth}
            minWidth={minSelectWidth}
          />
        </ResultFilterContainer>

        <FailureReasonFilterContainer>
          <Label>{t('crmInsurance.filters.failureReason')}</Label>
          <MultipleSelectFilter
            control={control}
            closeMenuOnSelect={true}
            disabled={disableFailureReason}
            key='failureReason'
            name='failureReason'
            options={getFailureReasons()}
            trimLength={1}
            maxWidth={maxSelectWidth}
            minWidth={minSelectWidth}
          />
        </FailureReasonFilterContainer>

      </DetailedFiltersContainer>
      <OpportunitySourceContainer>
        <Label>{t('crmInsurance.filters.source')}</Label>
        <CheckboxesTwoColumns>
          {Object.keys(OpportunitySourceName).map((sourceName: OpportunitySourceName) => (
            <CheckboxContainer key={sourceName.toString()}>
              <input
                id={`filter-sourceName-${sourceName}`}
                name='sourceName'
                value={sourceName}
                type='checkbox'
                ref={register}
              />
              <label htmlFor={`filter-sourceName-${sourceName}`}>
                {t(`crmInsurance.source.${sourceName.toLocaleLowerCase()}`)}
              </label>
            </CheckboxContainer>
            ),
          )}
        </CheckboxesTwoColumns>
      </OpportunitySourceContainer>

    </Container>
  )
}
