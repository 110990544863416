import { UserWithoutPermissions } from 'actff-bo-lib/user'
import { AssigneeStatus } from './client'

type Params = {
  readonly assignee: UserWithoutPermissions,
  readonly currentUser?: UserWithoutPermissions,
}

export const getAssigneeStatus = ({ assignee, currentUser }: Params): AssigneeStatus =>
  currentUser && assignee?.uuid === currentUser?.uuid
    ? AssigneeStatus.AssignedToCurrentUser
    : assignee
      ? AssigneeStatus.Assigned
      : AssigneeStatus.Unassigned
