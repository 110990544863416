import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

import { MultipleChoiceField } from './MultipleChoiceField'

type Choice = {
  readonly [key: string]: string,
}

export type MultipleChoiceFieldGroupElement = {
  readonly uuid: string,
  readonly status: any, // tslint:disable-line
  readonly name: string,
}

type Props = Testable & {
  readonly control: Control,
  readonly elements: ReadonlyArray<MultipleChoiceFieldGroupElement>
  readonly group: string,
  readonly register: () => void,
  readonly setValue: (key: string, value: string) => void,
  readonly values: Choice,
  readonly additionalDescription?: React.ComponentType,
}

const TopLabelContainer = styled.div`
  align-items: end;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 78px) 1fr;
  min-height: 140px;
`

const Label = styled.span`
  border-left: 1px solid ${colors.mysticGray};
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 30px;
  height: 100%;
  justify-content: left;
  padding: 14px 23px 0 0;
  transform: rotate(180deg);
  width: 78px;
  writing-mode: vertical-rl;

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    padding-left: 17px;
  }
`

export const MultipleChoiceFieldGroup: FC<Props> = ({
  additionalDescription: AdditionalDescription,
  control,
  elements,
  group,
  register,
  setValue,
  testId,
  values,
}) => {
  const groupElements = useWatch({ control, name: elements.map(element => `${group}[${element.uuid}]`) })
  const isGroupChecked = (value: string) => Object.entries(groupElements).every(([, elementValue]) => elementValue === value)

  const change = (value: string) => () => {
    const nextValue = isGroupChecked(value)
      ? ''
      : value

    elements.map(element => setValue(`${group}[${element.uuid}]`, nextValue))
  }

  const handleGroupInputClick = (value: string) => (event: React.MouseEvent<HTMLInputElement>) => {
    change(value)
    event.preventDefault()
    event.stopPropagation()
  }

  const { t } = useTranslation()

  return (
    <>
        <TopLabelContainer>
          {Object.entries(values).map(([key, value]) => (
              <Label key={value} onClick={change(value)} data-testid={`${testId}-label--${value}`}>
                <span>
                  <input
                    checked={isGroupChecked(value)}
                    id={`${group}${value}`}
                    onClick={handleGroupInputClick(value)}
                    readOnly={true}
                    type='radio'
                  />
                  <label htmlFor={`${group}${value}`} />
                </span>
                <span>{t(key)}</span>
              </Label>
          ))}
          {AdditionalDescription && (
            <AdditionalDescription data-testid={`${testId}-additional-description`}/>
          )}
        </TopLabelContainer>
        {elements.map((element, index) =>
          <MultipleChoiceField
            group={group}
            key={element.uuid}
            register={register}
            testId={`${testId}-element-${index}`}
            values={values}
            {...element}
          />,
        )}
    </>
  )
}
