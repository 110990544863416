import { Label } from 'actff-bo-app/components/Label'
import styled from 'styled-components'
import { FiltersContainer as FiltersContainerBase, TabPanel } from 'styles'

export const FiltersContainer = styled(FiltersContainerBase)`
  grid-gap: 30px;
  grid-template-columns: 2fr 1fr 2fr 3fr;
  grid-template-rows: auto;
  grid-template-areas:
    'car-status client-type registration-date approvals'
    'cars-count car-in-dms registration-date approvals'
    'footer footer footer footer';
`

type LabelStyledProps = {
  readonly dark: boolean,
}

export const TabPanelStyled = styled(TabPanel)`
  width: 60%;
`

export const LabelStyled = styled(Label)<LabelStyledProps>`
  margin-bottom: 10px;
`
