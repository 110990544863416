import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { FormFooterLeft, FormFooterRight } from 'actff-bo-app/components/Form'
import { Loader } from 'actff-bo-app/components/Loader'
import { MatchedPurchaseOpportunities } from 'actff-bo-app/Crm/Trade/MatchedOpportunities/MatchedPurchaseOpportunities'
import { getBrandFuelTypes } from 'actff-bo-lib/admin/brands/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { ClientAction, ClientId, ClientPreferences, selectClientPreferences } from 'actff-bo-lib/client'
import { maxMatchedOpportunityNumber, OpportunityPurchase } from 'actff-bo-lib/crm/trade'
import { CrmTradeOpportunityPurchaseAction } from 'actff-bo-lib/crm/trade/purchase'
import { CrmTradeOpportunitySaleAction, selectMatchedPurchaseOpportunities } from 'actff-bo-lib/crm/trade/sale'
import { selectCurrentOpportunitySale } from 'actff-bo-lib/crm/trade/sale/selectors'
import { selectCarModels, selectCarVersions, selectDealerBrands } from 'actff-bo-lib/dealership'
import { DictionaryAction } from 'actff-bo-lib/dictionary'
import { isEqualBy, mapToOption, mapValuesToSelectOptions } from 'actff-bo-lib/global'
import { mapArrayOfSelectableToFormObject } from 'actff-bo-lib/global/form-mappers/map-selectable-to-form-object'
import { IconType } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { LinkButton, PrimaryButton } from 'styles'

import { withClientViewHeader } from '../withClientViewHeader'
import { ClientPreferencesForm, defaultValues } from './Form'
import { PreferencesForm, preparePreferences } from './PreferencesForm'
import { SearchOpportunities } from './SearchOpportunities'

const Container = styled.div`
  padding-bottom: 100px;
`

const preparePreferencesFormData = (preferences: ClientPreferences): ClientPreferencesForm => ({
  ...preferences,
  brands: mapArrayOfSelectableToFormObject(preferences.brands),
  fuelTypes: mapArrayOfSelectableToFormObject(preferences.fuelTypes),
  mileage: {
    from: mapToOption({ value: preferences.mileage.from, defaultValue: '' }),
    to: mapToOption({ value: preferences.mileage.to }),
  },
  modelVersions: mapArrayOfSelectableToFormObject(preferences.modelVersions),
  models: mapArrayOfSelectableToFormObject(preferences.models),
  price: {
    from: mapToOption({ value: preferences.price.from }),
    to: mapToOption({ value: preferences.price.to }),
  },
  productionYear: {
    from: mapToOption({ value: preferences.productionYear.from }),
    to: mapToOption({ value: preferences.productionYear.to }),
  },
  purchaseDate: {
    from: preferences.purchaseDate.from ? new Date(preferences.purchaseDate.from) : null,
    to: preferences.purchaseDate.to ? new Date(preferences.purchaseDate.to) : null,
  },
  transmissions: mapArrayOfSelectableToFormObject(preferences.transmissions),
})

const testId = 'Car-view_preferences'

const PreferencesComponent: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { clientId } = useParams<{ readonly clientId: ClientId }>()

  const [selectedOpportunities, setSelectedOpportunities] = useState<ReadonlyArray<OpportunityPurchase>>([])
  const [confirmMatchOpen, setConfirmMatchOpen] = useState(false)

  const { control, handleSubmit, getValues, register, reset, setValue, formState } = useForm<ClientPreferencesForm>({
    defaultValues,
    reValidateMode: 'all',
  })

  const brandsFormValue = getValues('brands') || {}

  const { data: fuelTypes } = useQuery([QueryKeys.GET_BRAND_FUEL_TYPES, brandsFormValue], async () =>
    await getBrandFuelTypes(Object.entries(brandsFormValue).filter(([, value]) => !!value).map(([key]) => key)), {
    onError: async () => {
      dispatch(displayFailureToast(
        'admin.brands.getFuelTypes',
        ToastActionType.GET,
        'admin.brands.fuelTypes.getFailureAlert.body',
      ))
    },
    refetchOnWindowFocus: false,
    retry: false,
  })

  const getPreferences = () => { dispatch(ClientAction.getClientPreferences(clientId)) }
  const getFuelTypes = () => { dispatch(DictionaryAction.getFuelTypes()) }
  const clearPurchaseOpportunitiesList = () => dispatch(CrmTradeOpportunityPurchaseAction.clearPurchaseState())
  const clearSaleState = () => dispatch(CrmTradeOpportunitySaleAction.clearSaleState())

  const getOpportunitySale = () => { dispatch(CrmTradeOpportunitySaleAction.getOpportunityByClient(clientId)) }

  const savePreferences = (preferencesData: ClientPreferences) => {
    dispatch(ClientAction.saveClientPreferences({
      ...preferencesData,
      clientUuid: clientId,
    }))
  }
  const isSaveDisabled = () => !formState.isDirty && !formState.isSubmitted
  const isRevertFormDisabled = () => formState.isDirty
  const toggleConfirmMatchDialog = () => { setConfirmMatchOpen(!confirmMatchOpen) }
  const handleGoBackClick = () => { history.goBack() }

  const matchOpportunities = () => {
    dispatch(CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesByClient(
      clientId, selectedOpportunities.map(opportunity => opportunity.uuid)),
    )
    toggleConfirmMatchDialog()
  }

  const { data: preferences, loading: loadingPreferences } = useSelector(selectClientPreferences)
  const currentOpportunitySale = useSelector(selectCurrentOpportunitySale)
  const matchedPurchaseOpportunities = useSelector(selectMatchedPurchaseOpportunities)

  const areMatchedSameAsSelected = matchedPurchaseOpportunities.length > 0 &&
    isEqualBy<OpportunityPurchase>(matchedPurchaseOpportunities, selectedOpportunities, 'uuid')

  useEffect(() => {
    setSelectedOpportunities(matchedPurchaseOpportunities)
  }, [matchedPurchaseOpportunities])

  useEffect(() => {
    getFuelTypes()
    getFuelTypes()
    getPreferences()
  }, [clientId])

  useEffect(() => {
    if (preferences && !currentOpportunitySale) {
      getOpportunitySale()
    }
  }, [clientId, currentOpportunitySale, preferences])

  useEffect(() => {
    clearPurchaseOpportunitiesList()
    clearSaleState()

    if (preferences) {
      reset({ ...preparePreferencesFormData(preferences) })
    }
  }, [preferences])

  const { data: brands } = useSelector(selectDealerBrands)
  const { data: carModels } = useSelector(selectCarModels)
  const { data: carVersions } = useSelector(selectCarVersions)

  const handleSaveAndSearchClick = handleSubmit(formValues => {
    savePreferences(preparePreferences(formValues))
  })

  const handleResetClick = () => {
    if (preferences) {
      reset(preparePreferencesFormData(preferences))
    } else {
      reset(defaultValues)
    }
  }

  const handleOpportunityClick = (opportunity: OpportunityPurchase) => {
    if (selectedOpportunities.some(entry => entry.uuid === opportunity.uuid)) {
      setSelectedOpportunities(selectedOpportunities.filter(entry => entry.uuid !== opportunity.uuid))

      return
    }

    if (selectedOpportunities.length === maxMatchedOpportunityNumber) { return }

    setSelectedOpportunities([...selectedOpportunities, opportunity])
  }

  const isMatchSelectedOpportunitiesButtonDisabled = () => areMatchedSameAsSelected
    || (selectedOpportunities.length > maxMatchedOpportunityNumber)

  const getIsLoading = () => loadingPreferences
  if (getIsLoading()) { return <Loader /> }

  return (
    <Container>
      <PreferencesForm
        brandsFormValue={brandsFormValue}
        brands={mapValuesToSelectOptions(brands)}
        models={mapValuesToSelectOptions(carModels)}
        control={control}
        fuelTypes={fuelTypes}
        register={register}
        setValue={setValue}
        versions={mapValuesToSelectOptions(carVersions)}
      />
      <MatchedPurchaseOpportunities />
      <SearchOpportunities
        onOpportunityClick={handleOpportunityClick}
        selectedOpportunities={selectedOpportunities}
        onSetSelectedOpportunities={setSelectedOpportunities}
        saleUuid={currentOpportunitySale?.uuid}
      />

      <FixedFooter>
        <FormFooterLeft>
          <ButtonWithIcon
            caption={t('caption.back')}
            iconType={IconType.Back}
            onClick={handleGoBackClick}
            testId={`${testId}go-back`}
            theme={BtnTheme.Link}
          />
        </FormFooterLeft>
        <FormFooterRight>
          <LinkButton
            disabled={!isRevertFormDisabled()}
            onClick={handleResetClick}
            testId={`${testId}revert`}
            type={BtnType.Button}
          >
            {t('caption.revert')}
          </LinkButton>
          <LinkButton
            disabled={isMatchSelectedOpportunitiesButtonDisabled()}
            onClick={toggleConfirmMatchDialog}
            testId={`${testId}assign-selected-opportunities`}
            type={BtnType.Button}
          >
            {t('crmTrade.opportunity.assignOpportunities', { count: selectedOpportunities.length })}
          </LinkButton>
          <PrimaryButton
            data-testid={`${testId}submit`}
            disabled={isSaveDisabled()}
            type={BtnType.Button}
            onClick={handleSaveAndSearchClick}
          >
            {t('caption.saveAndSearch')}
          </PrimaryButton>
        </FormFooterRight>
      </FixedFooter>

      <Dialog
        content='preferences.confirmDialog.content'
        open={confirmMatchOpen}
        onConfirm={matchOpportunities}
        onClose={toggleConfirmMatchDialog}
        title='preferences.confirmDialog.title'
      />
    </Container>
  )
}

export const Preferences = withClientViewHeader(PreferencesComponent)
