import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import { AjaxError } from 'rxjs/ajax'

import { OpportunityInsuranceId, OpportunityListItem, OpportunityListType, OpportunityUpdatePayload } from '../dto'
import { InsuranceFilters } from '../filters'

export enum CrmInsuranceListActionType {
  AssignOpportunity = '[CRM INSURANCE] ASSIGN OPPORTUNITY',
  AssignOpportunityFailure = '[CRM INSURANCE] ASSIGN OPPORTUNITY FAILURE',
  AssignOpportunitySuccess = '[CRM INSURANCE] ASSIGN OPPORTUNITY SUCCESS',

  ChangeSearchPhrase = '[CRM INSURANCE] CHANGE SEARCH PHRASE',
  ChangeListsFilters = '[CRM INSURANCE] CHANGE LISTS FILTERS',
  ClearListsFilters = '[CRM INSURANCE] CLEAR LISTS FILTERS',
  ChangeListsDealerLocationFilters = '[CRM INSURANCE] CHANGE LISTS DEALER LOCATION FILTERS',
  ChangeListsBrandFilters = '[CRM INSURANCE] CHANGE LISTS BRAND FILTERS',

  ChangeAssignmentFilter = '[CRM INSURANCE] CHANGE ASSIGNMENT FILTER',
  ChangeOpportunityListCurrentPage = '[CRM INSURANCE] CHANGE OPPORTUNITY LIST CURRENT PAGE',
  ChangeOpportunityListExpandedState = '[CRM INSURANCE] CHANGE OPPORTUNITY LIST EXPANDED STATE',
  ChangeOpportunityListTimeFrom = '[CRM INSURANCE] CHANGE OPPORTUNITY LIST TIME FROM',
  ChangeOpportunityListTimeTo = '[CRM INSURANCE] CHANGE OPPORTUNITY LIST TIME TO',

  GetOpportunities = '[CRM INSURANCE] GET OPPORTUNITIES',
  GetOpportunitiesFailure = '[CRM INSURANCE] GET OPPORTUNITIES FAILURE',
  GetOpportunitiesSuccess = '[CRM INSURANCE] GET OPPORTUNITIES SUCCESS',

  ResetPaginationOnLists = '[CRM INSURANCE] RESET PAGINATION ON LISTS',

  ClearSelectedAsProcessedUuid = '[CRM INSURANCE] CLEAR SELECTED AS PROCESSED UUID',
  SetSelectedAsProcessedUuid = '[CRM INSURANCE] SET SELECTED AS PROCESSED UUID',

  UnassignOpportunity = '[CRM INSURANCE] UNASSIGN OPPORTUNITY',
  UnassignOpportunityFailure = '[CRM INSURANCE] UNASSIGN OPPORTUNITY FAILURE',
  UnassignOpportunitySuccess = '[CRM INSURANCE] UNASSIGN OPPORTUNITY SUCCESS',
}

// tslint:disable object-literal-sort-keys
export const CrmInsuranceListAction = {
  assignOpportunity: (uuid: OpportunityInsuranceId, user: UserWithoutPermissions, opportunityListType: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.AssignOpportunity, { uuid, user, opportunityListType }),
  assignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmInsuranceListActionType.AssignOpportunityFailure, error),
  assignOpportunitySuccess: (opportunity: OpportunityUpdatePayload, opportunityListType: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.AssignOpportunitySuccess, { opportunity, opportunityListType }),

  changeListFilters: (filters: InsuranceFilters) =>
    createAction(CrmInsuranceListActionType.ChangeListsFilters, filters),
  clearListFilters: () => createAction(CrmInsuranceListActionType.ClearListsFilters),
  changeSearchPhrase: (searchPhrase: string) => createAction(CrmInsuranceListActionType.ChangeSearchPhrase, searchPhrase),
  resetPaginationOnLists: () => createAction(CrmInsuranceListActionType.ResetPaginationOnLists),
  changeListsDealerLocationFilters: (filters: ReadonlyArray<DealerLocationKey>) =>
    createAction(CrmInsuranceListActionType.ChangeListsDealerLocationFilters, filters),
  changeListsBrandFilters: (brands: ReadonlyArray<string>) =>
    createAction(CrmInsuranceListActionType.ChangeListsBrandFilters, brands),

  changeAssignmentFilter: (filter: UserId | null) =>
    createAction(CrmInsuranceListActionType.ChangeAssignmentFilter, filter),

  changeOpportunityListCurrentPage: (page: number, type: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.ChangeOpportunityListCurrentPage, { page, type }),
  changeOpportunityListExpandedState: (expanded: boolean, type: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.ChangeOpportunityListExpandedState, { expanded, type }),
  changeOpportunityListTimeFrom: (date: Date, type: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.ChangeOpportunityListTimeFrom, { date, type } ),
  changeOpportunityListTimeTo: (date: Date, type: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.ChangeOpportunityListTimeTo, { date, type }),

  getOpportunities: (type: OpportunityListType) => createAction(CrmInsuranceListActionType.GetOpportunities, { type }),
  getOpportunitiesFailure: (type: OpportunityListType, error: AjaxError) =>
    createAction(CrmInsuranceListActionType.GetOpportunitiesFailure, { type, error }),
  getOpportunitiesSuccess: (type: OpportunityListType, opportunityList: PagedResult<OpportunityListItem>) =>
    createAction(CrmInsuranceListActionType.GetOpportunitiesSuccess, { type, opportunityList }),

  clearSelectedAsProcessedUuid: () => createAction(CrmInsuranceListActionType.ClearSelectedAsProcessedUuid),
  setSelectedAsProcessedUuid: (uuid: OpportunityInsuranceId) => createAction(CrmInsuranceListActionType.SetSelectedAsProcessedUuid, uuid),

  unassignOpportunity: (uuid: OpportunityInsuranceId, opportunityListType: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.UnassignOpportunity, { uuid, opportunityListType }),
  unassignOpportunityFailure: (error: AjaxError) =>
    createAction(CrmInsuranceListActionType.UnassignOpportunityFailure, error),
  unassignOpportunitySuccess: (opportunity: OpportunityUpdatePayload, opportunityListType: OpportunityListType) =>
    createAction(CrmInsuranceListActionType.UnassignOpportunitySuccess, { opportunity, opportunityListType }),
}

export type CrmInsuranceListAction = ActionsUnion<typeof CrmInsuranceListAction>
