import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { FieldValue, FormCell, FormCellFullWidth, FormCellWide, FormHeader, FormWrapper } from 'actff-bo-app/components/Form'
import { CarInsurance, CarInsuranceType, InsuranceValueForCalculationTranslationMap } from 'actff-bo-lib/car'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { Language } from 'actff-bo-lib/i18n'
import { Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { history } from 'actff-bo-lib/router'
import i18next from 'i18next'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'

const FieldValueGroup = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, min-content);
  column-gap: 5px;
`

export type Props = {
  readonly carInsurance: CarInsurance,
  readonly currentLanguage: Language,
}

type CarInsuranceRangeProps = {
  readonly carInsurance: CarInsurance,
  readonly t: i18next.TFunction,
}

type CarInsuranceValueForCalculationProps = {
  readonly carInsurance: CarInsurance,
  readonly t: i18next.TFunction,
}

const handleGoBackClick = () => {
  history.push(Paths.CarList)
}

const testId = 'car-view-insurance__'

const CarInsuranceRange: React.FC<CarInsuranceRangeProps> = ({ carInsurance, t }) => (
  <FieldValueGroup>
    {Object.values(CarInsuranceType)
      .filter(value => carInsurance[value])
      .map(value => (
        <FieldValue key={value} testId={`${testId}insurance--${value}`} >{t(`carView.form.insurance.${value}`)}</FieldValue>
      ))
    }
  </FieldValueGroup>
)

const CarInsuranceValueForCalculation: React.FC<CarInsuranceValueForCalculationProps> = ({ carInsurance, t }) => (
  <>
    <label htmlFor='valueForInsuranceCalculation'>{t('carView.form.insurance.valueForInsuranceCalculation')}</label>
    {carInsurance.valueForInsuranceCalculation && (
      <FieldValue id='valueForInsuranceCalculation' data-testid={`${testId}value-for-insurance-calculation`}>
        {t(InsuranceValueForCalculationTranslationMap.get(carInsurance.valueForInsuranceCalculation) || '')}
      </FieldValue>
    )}
  </>
)

const CarViewInsuranceReadonlyComponent: React.FC<Props & WithTranslation> = ({ carInsurance, t }) => (
  <FormWrapper>
  {/* INSURANCE IN HOUSE */}
    <FormCellWide>
      <label>{t('carView.form.insurance.inHouse')}</label>
      <FieldValue data-testid={`${testId}in-house`}>{t (carInsurance.inHouse ? 'caption.yes' : 'caption.no')}</FieldValue>
    </FormCellWide>

    {/* POLICY */}
    <FormCellFullWidth slim={true}>
      <FormHeader>{t('carView.form.insurance.title')}</FormHeader>
    </FormCellFullWidth>
    <FormCell>
      <label>{t('carView.form.insurance.inHouseInsurer')}</label>
      <FieldValue data-testid={`${testId}in-house--insurer`}>{carInsurance.assistanceInHouse?.insurer}</FieldValue>
    </FormCell>
    <FormCellWide>
      <label>{t('carView.form.insurance.range')}</label>
      <CarInsuranceRange carInsurance={carInsurance} t={t} />
    </FormCellWide>
    <FormCell />
    <FormCell>
      <label htmlFor='inHousePolicyNumber'>{t('carView.form.insurance.number')}</label>
      <FieldValue
        id='inHousePolicyNumber'
        data-testid={`${testId}in-house--policy-number`}
      >
        {carInsurance.assistanceInHouse?.policyNumber}
      </FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='inHouseExpirationDate'>{t('carView.form.insurance.inHouseExpirationDate')}</label>
      <FieldValue id='inHouseExpirationDate' data-testid={`${testId}in-house--expiration-date`}>
        <FormattedDate date={carInsurance.assistanceInHouse?.expirationDate} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>
    <FormCell />
    <FormCell />
    <FormCell>
      <label htmlFor='marketValue'>{t('carView.form.insurance.marketValue')}</label>
      <FieldValue id='marketValue' data-testid={`${testId}market-value`}>{carInsurance.marketValue}</FieldValue>
    </FormCell>
    <FormCell>
      <CarInsuranceValueForCalculation carInsurance={carInsurance} t={t} />
    </FormCell>
    <FormCell>
      <label htmlFor='insuranceValue'>{t('carView.form.insurance.insuranceValue')}</label>
      <FieldValue id='insuranceValue' data-testid={`${testId}insurance-value`}>{carInsurance.insuranceValue}</FieldValue>
    </FormCell>
    <FormCellFullWidth>
      <label htmlFor='manufacturerAssistance'>{t('carView.form.insurance.manufacturerAssistance')}</label>
      <FieldValue
        id='manufacturerAssistance'
        data-testid={`${testId}manufacturer-assistance`}
      >
        {t(carInsurance.manufacturerAssistance ? 'caption.yes' : 'caption.no')}
      </FieldValue>
    </FormCellFullWidth>

    {/* ASSISTANCE */}
    <FormCellFullWidth slim={true}>
      <FormHeader>{t('carView.form.insurance.manufacturerAssistance.title')}</FormHeader>
    </FormCellFullWidth>
    <FormCell>
      <label htmlFor='insurer'>{t('carView.form.insurance.manufacturerAssistance.company')}</label>
      <FieldValue id='insurer' data-testid={`${testId}insurer`}>{carInsurance.insurer}</FieldValue>
    </FormCell>
    <FormCell>
      <label htmlFor='expirationDate'>{t('carView.form.insurance.manufacturerAssistance.expirationDate')}</label>
      <FieldValue id='expirationDate' data-testid={`${testId}expiration-date`}>
        <FormattedDate date={carInsurance.expirationDate} format={defaultDateFormat} />
      </FieldValue>
    </FormCell>
    <FixedFooter>
      <ButtonWithIcon
        caption={t('caption.back')}
        iconType={IconType.Back}
        theme={BtnTheme.Link}
        onClick={handleGoBackClick}
        testId={`${testId}go-back`}
      />
    </FixedFooter>
  </FormWrapper>
)

export const CarViewInsuranceReadonly = withTranslation()(CarViewInsuranceReadonlyComponent)
