import { Container, Header } from 'actff-bo-app/Admin/Styled'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  getDealershipInsurersQuestions,
  updateDealershipInsurerQuestions,
} from 'actff-bo-lib/admin/dealership'
import { InsurerQuestion } from 'actff-bo-lib/admin/dealership/dto'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import {
  displayFailureToast,
  displaySuccessToast,
  ToastActionType,
} from 'actff-bo-lib/toast/display-toats'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { H2 } from 'styles'

import { ViewTabs } from '../ViewTabs'
import { InsurerQuestionsTable } from './InsurersQuestionsTable'

const testId = 'admin-view-dealership--insurers-questions__'

const InsurersQuestionsComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, isLoading, isError, refetch } = useQuery(QueryKeys.GET_INSURERS_QUESTIONS, async () =>
      await getDealershipInsurersQuestions(),
    { retry: false, refetchOnWindowFocus: false },
  )

  const updateInsurerQuestionsMutation = useMutation(
    QueryKeys.UPDATE_INSURERS_QUESTIONS,
    updateDealershipInsurerQuestions,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.dealership.updateInsurerQuestions.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        refetch()
        dispatch(displaySuccessToast('admin.dealership.updateInsurerQuestions.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const handleUpdate = (questions: ReadonlyArray<InsurerQuestion>) => {
    updateInsurerQuestionsMutation.mutate(questions)
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <LocalLoader />
      </>
    )
  }

  if (!data?.questions) {
    return (
      <>
        <ViewTabs testId={testId} />
        <NoData>{t('caption.noData')}</NoData>
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('api.action.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.dealership.insurersQuestions.subheader')}</H2></Header>
        <InsurerQuestionsTable
          items={data.questions}
          onUpdate={handleUpdate}
        />
      </Container>
    </>
  )
}

export const InsurersQuestions = withAdminViewHeader(InsurersQuestionsComponent)
