import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { Alert } from 'actff-bo-app/components/Alert'
import { EditableList } from 'actff-bo-app/components/EditableList/EditableList'
import { FormHeader } from 'actff-bo-app/components/Form'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import { addCarVersion, deleteCarVersion, getCarVersions } from 'actff-bo-lib/admin/brands/dao'
import { CarVersionsOutput } from 'actff-bo-lib/admin/brands/dto'
import { foldQuery } from 'actff-bo-lib/api/fold-query'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { selectCurrentBrand } from 'actff-bo-lib/dealership'
import { errorsToRecord, toErrorMessage } from 'actff-bo-lib/form/error-to-record'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from '../../Styled'
import { ViewTabs } from '../ViewTabs'

const testId = 'admin-view_brands_car-versions__'

const CarVersionsComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const currentBrand = useSelector(selectCurrentBrand)

  const query = useQuery<ReadonlyArray<string>>([QueryKeys.GET_CAR_VERSIONS, currentBrand], async () =>
    getCarVersions([currentBrand]), { retry: false, refetchOnWindowFocus: false },
  )

  const addCarVersionMutation = useMutation(
    QueryKeys.ADD_CAR_VERSION,
    addCarVersion,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.brands.addLocationCarVersion.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        query.refetch()
        dispatch(displaySuccessToast('admin.brands.addLocationCarVersion.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const deleteCarVersionMutation = useMutation(
    QueryKeys.DELETE_CAR_VERSION,
    deleteCarVersion,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.brands.carVersions.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        query.refetch()
        dispatch(displaySuccessToast('admin.brands.carVersions.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const handleAdd = async (versionName: string) => { addCarVersionMutation.mutate({ brand: currentBrand, versionName }) }

  const handleDelete = async (versionName: string) => {
    deleteCarVersionMutation.mutate({ brand: currentBrand, versionName })
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        {pipe(
          query,
          foldQuery(CarVersionsOutput)(
            () => <LocalLoader />,
            error => error.type === 'RequestError' ? (
              <>
                <NoData />
                <Alert alertId='admin.brands.carVersions.get.failure' body='api.actionFailure' />
              </>
            ) : (
              <>
                <NoData />
                <Alert
                  alertId='admin.brands.carVersions.get.failure'
                  body={pipe(
                    errorsToRecord(error.errors),
                    toErrorMessage,
                  )}
                />
              </>
            ),
            versions => (
              <>
                <FormHeader>{t('admin.brands.carVersions.subheader')}</FormHeader>
                <EditableList
                  tableHeaderCaptionTKey={'admin.brands.carVersions.version'}
                  addButtonCaptionTKey={'admin.brands.carVersions.addNewVersion'}
                  items={versions}
                  onAdd={handleAdd}
                  onDelete={handleDelete}
                />
              </>
            ),
          ),
        )}
      </Container>
    </>
  )
}

export const CarVersions = withAdminViewHeader(CarVersionsComponent)
