import { DropdownContent, DropdownPosition } from 'actff-bo-app/components/DropdownContent'
import { Icon } from 'actff-bo-app/components/Icon'
import { Overlay } from 'actff-bo-app/components/Overlay'
import {
  ChatAction,
  Conversation,
  groupMessagesByDay,
  Message,
  MessagePayload,
  selectCurrentConversation,
  Thread,
  ThreadId,
  ThreadStatus,
} from 'actff-bo-lib/chat'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State as GlobalState } from 'actff-bo-lib/store'
import { selectMe, User } from 'actff-bo-lib/user'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'

import { ChatClientBar } from './ChatClientBar'
import { ChatConversationElement } from './ChatConversationMessage'
import { ChatConversationSendMessage } from './ChatConversationSendMessage'
import {
  ChatContainer,
  ChatConversationContainer,
  ChatSendMessageContainer,
  MessageDay,
  MessageDayContainer,
  TopBar,
  TopBarMenu,
  TopBarMenuItem,
} from './ChatConversationStyled'
import { ChatThreadStatus } from './ChatThreadStatus'

type ChatViewParams = {
  readonly threadId: ThreadId,
}

type State = {
  readonly menuExpanded: boolean,
  readonly newMessageContainerHeight: number,
}

type StateToProps = {
  readonly conversation: Partial<Conversation>,
  readonly currentUser: User | null,
}

type DispatchToProps = {
  readonly connectChatConversationSocket: (threadId: ThreadId) => void,
  readonly disconnectChatConversationSocket: (threadId: ThreadId) => void,
  readonly sendChatConversationMessage: (message: MessagePayload, threadId: ThreadId) => void,
  readonly updateChatThread: (threadId: ThreadId, body: Partial<Thread>) => void,
}

type ChatConversationComponentProps =
  RouteComponentProps<ChatViewParams>
  & WithTranslation
  & StateToProps
  & DispatchToProps

class ChatConversationComponent extends React.Component<ChatConversationComponentProps, State> {
  public readonly state: State = {
    menuExpanded: false,
    newMessageContainerHeight: 0,
  }

  private readonly chatContainerRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()
  private readonly chatSendMessageRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  public shouldComponentUpdate(nextProps: ChatConversationComponentProps, nextState: State): boolean {
    return (
      this.threadInUrlChanged(nextProps)
        || (nextProps.conversation !== this.props.conversation)
        || !nextProps.conversation.car
        || nextState !== this.state
    )
  }

  public componentDidMount(): void {
    this.props.connectChatConversationSocket(this.getThreadIdFromUrl())
  }

  public componentDidUpdate(prevProps: ChatConversationComponentProps, prevState: State): void {
    const prevThreadId = prevProps.match.params.threadId

    if (prevThreadId !== this.getThreadIdFromUrl()) {
      this.props.disconnectChatConversationSocket(prevThreadId)
      this.props.connectChatConversationSocket(this.getThreadIdFromUrl())
    }

    if (this.shouldScrollToBottom(prevProps, prevState)) {
      this.scrollToBottomOfConversation()
    }
  }

  public componentWillUnmount(): void {
    this.props.disconnectChatConversationSocket(this.getThreadIdFromUrl())
  }

  public render(): React.ReactNode {
    const { conversation, t } = this.props
    const { menuExpanded, newMessageContainerHeight } = this.state
    const { messages } = conversation
    const dropdownTopOffset = 60
    const messagesGroupedByDay = groupMessagesByDay(messages)

    return (
      <ChatContainer>
        <TopBar>
          {this.renderClientBar()}
          <TopBarMenu onClick={this.toggleMenuExpanded}>
            <Icon type={IconType.More} />
          </TopBarMenu>
          {menuExpanded && (
            <>
              <Overlay onClick={this.toggleMenuExpanded} />
              <DropdownContent
                position={DropdownPosition.Right}
                topOffset={dropdownTopOffset}
              >
                <ul>
                  <TopBarMenuItem onClick={this.closeChatThread}>
                    <Icon type={IconType.Exit} />
                    {t('chat.close')}
                  </TopBarMenuItem>
                </ul>
              </DropdownContent>
            </>
          )}
        </TopBar>
        <TopBar statusBar={true}>
          <ChatThreadStatus thread={conversation.thread} />
        </TopBar>
        <ChatConversationContainer offset={newMessageContainerHeight} ref={this.chatContainerRef}>
          {Object.entries(messagesGroupedByDay).map(([day, dayMessages]) => (
            <>
              <MessageDayContainer><MessageDay>{day}</MessageDay></MessageDayContainer>
              {dayMessages.map(message => (
                <ChatConversationElement key={message.msgUuid} isReceptionMessage={this.isReceptionMessage(message)} message={message} />
              ))}
            </>
          ))}
        </ChatConversationContainer>
        <ChatSendMessageContainer ref={this.chatSendMessageRef}>
          <ChatConversationSendMessage
            onContainerHeightChange={this.recalculateMessageContainerOffset}
            onMessageSend={this.handleOnMessageSend()}
          />
        </ChatSendMessageContainer>
      </ChatContainer>
    )
  }

  private readonly getThreadIdFromUrl = (): ThreadId => this.props.match.params.threadId

  private readonly threadInUrlChanged = (nextProps: ChatConversationComponentProps): boolean =>
    nextProps.match.params.threadId !== this.getThreadIdFromUrl()

  private readonly handleOnMessageSend = () => (messagePayload: MessagePayload) =>
    this.props.sendChatConversationMessage(messagePayload, this.getThreadIdFromUrl())

  private readonly renderClientBar = (): React.ReactNode => {
    const { car, thread } = this.props.conversation

    return thread && car && <ChatClientBar car={car} client={thread.client} />
  }

  private readonly areMessagesInitiallyLoaded = (prevProps: ChatConversationComponentProps): boolean =>
    !(prevProps.conversation.messages) && !!this.props.conversation.messages

  private readonly isNewMessageReceived = (prevProps: ChatConversationComponentProps): boolean =>
    !!prevProps.conversation.messages
      && !!this.props.conversation.messages
      && (prevProps.conversation.messages.length !== this.props.conversation.messages.length)
      && (prevProps.conversation.messages[0].msgUuid === this.props.conversation.messages[0].msgUuid)

  private readonly shouldScrollToBottom = (prevProps: ChatConversationComponentProps, prevState: State): boolean =>
    this.areMessagesInitiallyLoaded(prevProps)
      || this.isNewMessageReceived(prevProps)
      || this.newMessageContainerHeightChanged(prevState)

  private readonly scrollToBottomOfConversation = () => {
    const { current } = this.chatContainerRef

    return current
      ? current.scrollTo(0, current.scrollHeight)
      : null
  }

  private readonly newMessageContainerHeightChanged = (prevState: State) =>
    prevState.newMessageContainerHeight !== this.state.newMessageContainerHeight

  private readonly isReceptionMessage = (message: Message): boolean => !!message.user

  private readonly toggleMenuExpanded = () => {
    this.setState({ menuExpanded: !this.state.menuExpanded })
  }

  private readonly closeChatThread = () => {
    this.props.updateChatThread(this.getThreadIdFromUrl(), { status: ThreadStatus.CLOSED })
  }

  private readonly recalculateMessageContainerOffset = () => {
    if (this.chatSendMessageRef.current ) {
      this.setState({
        newMessageContainerHeight: this.chatSendMessageRef.current.clientHeight,
      })
    }
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  conversation: selectCurrentConversation(state),
  currentUser: selectMe(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  connectChatConversationSocket: (threadId: ThreadId) => { dispatch(ChatAction.connectChatConversationSocket(threadId)) },
  disconnectChatConversationSocket: (threadId: ThreadId) => { dispatch(ChatAction.disconnectChatConversationSocket(threadId)) },
  sendChatConversationMessage: (message: MessagePayload, threadId: ThreadId) => {
    dispatch(ChatAction.sendChatConversationMessage(message, threadId))
  },
  updateChatThread: (threadId: ThreadId, body: Partial<Thread>) => { dispatch(ChatAction.updateChatThread(threadId, body)) },
})

export const ChatConversation = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ChatConversationComponent)
