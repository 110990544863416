import { SaveEditButton } from 'actff-bo-app/components/Button'
import { DateTime } from 'actff-bo-app/components/DateTime'
import { Field } from 'actff-bo-app/components/Form'
import { FormikEffect } from 'actff-bo-app/components/Form/FormikEffect'
import { hasErrors } from 'actff-bo-lib/global'
import { FormikProps, FormikState, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'
import { date, object } from 'yup'

type Props = {
  readonly locale: {},
  readonly onChange: (date: Date, hasErrors: boolean) => void,
  readonly visitTime: Date | null,
  readonly onValidateChange?: (hasErrors: boolean) => void,
  readonly editable?: boolean,
}

type FormProps = {
  readonly visitTime: Date | null,
}

type State = {
  readonly editMode: boolean,
}

type OpportunityVisitTimeProps = Props & FormikProps<FormProps> & Props & WithTranslation

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
`

class OpportunityVisitTimeComponent extends React.Component<OpportunityVisitTimeProps> {
  public readonly state: State = {
    editMode: false,
  }

  public render(): React.ReactNode {
    const { editable, locale, onChange, t, visitTime } = this.props
    const { editMode } = this.state

    return (
      <FormikEffect formik={this.props} onChange={this.handleValidateChange}>
        <Container>
          <Field
            component={DateTime}
            editMode={editMode}
            id='visitTime'
            locale={locale}
            name='visitTime'
            onDateTimeChange={onChange}
            dateTime={visitTime}
          />
          {editable && <SaveEditButton className='edit-time' editMode={editMode} onClick={this.toggleEditMode} t={t} />}
        </Container>
      </FormikEffect>
    )
  }

  public readonly toggleEditMode = () => this.setState({ editMode: !this.state.editMode })

  private readonly handleValidateChange = (props: FormikState<FormProps>, newProps: FormikState<FormProps>) => {
    if (hasErrors(props.errors) !== hasErrors(newProps.errors)) {
      this.props.onValidateChange && this.props.onValidateChange(hasErrors(newProps.errors))
    }
  }
}

const validationSchema = (props: Props) =>
  props.editable
    ? object<FormProps>().shape({
        visitTime: date().required(),
      })
    : object().shape({})

const formik = withFormik<Props, FormProps>({
  handleSubmit: () => null,
  mapPropsToValues: ({ visitTime }) => ({
    visitTime,
  }),
  validateOnMount: true,
  validationSchema,
})

export const OpportunityVisitTime = compose(
  formik,
  withTranslation(),
)(OpportunityVisitTimeComponent)
