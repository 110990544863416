import { CrmServiceAction } from '../actions'
import { OpportunityInternalCommentDto, OpportunityService, OpportunityStatus } from '../dto'

// tslint:disable-next-line cyclomatic-complexity
export const mapOpportunityStatusToAction = (
  opportunity: OpportunityService,
  opportunityInternalComment: OpportunityInternalCommentDto,
) => {
  switch (opportunity.status) {
    case OpportunityStatus.ARRANGED: {
      const { appointmentDate, uuid } = opportunity
      const { attachments, author } = opportunityInternalComment

      return CrmServiceAction.setOpportunityStatusArranged({
        ...(opportunityInternalComment.comment ? opportunityInternalComment : {}),
        appointmentDate,
        attachments,
        author,
        uuid,
      })
    }

    case OpportunityStatus.FAILED: {
      const { failureReason, uuid } = opportunity
      const { attachments, author } = opportunityInternalComment

      return CrmServiceAction.setOpportunityStatusFailed({
        ...(opportunityInternalComment.comment ? opportunityInternalComment : {}),
        attachments,
        author,
        failureReason,
        uuid,
      })
    }

    case OpportunityStatus.RETRY_LIST:
    case OpportunityStatus.RETRY: {
      const { retryDate, uuid } = opportunity
      const { attachments, author, comment } = opportunityInternalComment

      return CrmServiceAction.setOpportunityStatusRetry({ attachments, author, comment, retryDate, uuid })
    }

    case OpportunityStatus.ACCOMPLISHED: {
      const { appointmentDate, invoiceNumber, labourProfit, orderNumber, partsProfit, uuid } = opportunity
      const { attachments, author } = opportunityInternalComment

      return CrmServiceAction.setOpportunityStatusAccomplished({
        ...(opportunityInternalComment.comment ? opportunityInternalComment : {}),
        appointmentDate,
        attachments,
        author,
        invoiceNumber,
        labourProfit,
        orderNumber,
        partsProfit,
        uuid,
      })
    }

    case OpportunityStatus.MISSED: {
      const { uuid } = opportunity
      const { attachments, author, comment } = opportunityInternalComment

      return CrmServiceAction.setOpportunityStatusMissed({ attachments, author, comment, uuid })
    }

    default:
      return CrmServiceAction.setOpportunityStatusFailedSuccess(opportunity)
  }
}
