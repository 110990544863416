import { Container, Header } from 'actff-bo-app/Admin/Styled'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { Alert } from 'actff-bo-app/components/Alert'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  addDealershipInsurer,
  DealerInsurersOutput,
  DealershipInsurer,
  deleteDealershipInsurer,
  getDealershipInsurers,
  updateDealershipInsurers,
} from 'actff-bo-lib/admin/dealership'
import { foldQuery } from 'actff-bo-lib/api/fold-query'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { errorsToRecord, toErrorMessage } from 'actff-bo-lib/form/error-to-record'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { pipe } from 'fp-ts/lib/function'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { H2 } from 'styles'

import { ViewTabs } from '../ViewTabs'
import { InsurersTable } from './InsurersTable'

const testId = 'admin-view-dealership--insurers__'

const InsurersComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const query = useQuery(QueryKeys.GET_INSURERS, async () =>
      await getDealershipInsurers(),
    { retry: false, refetchOnWindowFocus: false },
  )

  const addInsurerMutation = useMutation(
    QueryKeys.ADD_NEW_INSURER,
    addDealershipInsurer,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.dealership.addInsurer.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        query.refetch()
        dispatch(displaySuccessToast('admin.dealership.addInsurer.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const deleteInsurerMutation = useMutation(
    QueryKeys.DELETE_INSURER,
    deleteDealershipInsurer,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.dealership.deleteInsurer.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        query.refetch()
        dispatch(displaySuccessToast('admin.dealership.deleteInsurer.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const updateInsurersMutation = useMutation(
    QueryKeys.UPDATE_INSURERS,
    updateDealershipInsurers,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.dealership.deleteInsurer.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        query.refetch()
        dispatch(displaySuccessToast('admin.dealership.deleteInsurer.success', ToastActionType.CREATE_OR_UPDATE))
      },
    },
  )

  const handleAdd = async (insurer: DealershipInsurer) => {
    addInsurerMutation.mutate(insurer)
  }

  const handleDelete = async (insurer: string) => {
    deleteInsurerMutation.mutate(insurer)
  }

  const handleSave = (insurers: ReadonlyArray<DealershipInsurer>) => {
    updateInsurersMutation.mutate(insurers)
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.dealership.insurers.subheader')}</H2></Header>
        {pipe(
          query,
          foldQuery(DealerInsurersOutput)(
            () => <LocalLoader />,
            error => error.type === 'RequestError' ? (
              <>
                <NoData />
                <Alert alertId='admin.dealership.insurers.get.failure' body='api.actionFailure' />
              </>
            ) : (
              <>
                <NoData />
                <Alert
                  alertId='admin.dealership.insurers.get.failure'
                  body={pipe(
                    errorsToRecord(error.errors),
                    toErrorMessage,
                  )}
                />
              </>
            ),
            insurers => (
              <InsurersTable insurers={insurers} onAdd={handleAdd} onSave={handleSave} onDelete={handleDelete} />
            ),
          ),
        )}
      </Container>
    </>
  )
}

export const Insurers = withAdminViewHeader(InsurersComponent)
