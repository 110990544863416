import { combineReducers } from 'redux'

import { crmInsuranceInsurerReducer, CrmInsuranceInsurerState } from './insurer'
import { crmInsuranceListReducer, CrmInsuranceListState } from './list'
import { crmInsuranceOpportunityReducer, CrmInsuranceOpportunityState } from './opportunity'
import { crmInsuranceReportReducer, CrmInsuranceReportState } from './report'

export type CrmInsuranceState = {
  readonly list: CrmInsuranceListState,
  readonly insurer: CrmInsuranceInsurerState,
  readonly opportunity: CrmInsuranceOpportunityState,
  readonly report: CrmInsuranceReportState,
}

export const crmInsuranceReducer = combineReducers<CrmInsuranceState>({
  insurer: crmInsuranceInsurerReducer,
  list: crmInsuranceListReducer,
  opportunity: crmInsuranceOpportunityReducer,
  report: crmInsuranceReportReducer,
})
