import { Client } from 'actff-bo-lib/client'
import { NominalString } from 'actff-bo-lib/global'

export enum TestDriveRequestStatus {
  NEW = 'NEW',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export type TestDriveRequest = {
  readonly uuid: TestDriveRequestId,
  readonly client: Client,
  readonly requestTime: Date,
  readonly status: TestDriveRequestStatus,
}

export type UpdateTestDriveRequestDto = Pick<TestDriveRequest, 'uuid' | 'status'>

export type TestDriveRequestId = NominalString<TestDriveRequest>

export type TestDriveRequestCount = {
  readonly requestCount: number,
}

export type TestDriveRequestFilterValues = TestDriveRequestStatus
