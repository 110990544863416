import { Loader } from 'actff-bo-app/components/Loader'
import { getCarLiquids } from 'actff-bo-lib/admin/brands/dao'
import { CarEngineCode, CarEngineFuelType, CarInfoWithClient, mapEngineCodeToFuelType } from 'actff-bo-lib/car'
import { ClientId } from 'actff-bo-lib/client'
import { CarModel } from 'actff-bo-lib/dealership'
import { mapValuesToSelectOptions } from 'actff-bo-lib/global'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'

import { CarViewServiceForm, CarViewServiceProps } from '../CarView'
import { CarNewHeader } from './CarNewHeader'
import { mapDispatchToProps, mapStateToProps, Props } from './Redux'

type State = {
  readonly currentBrakeLiquid: string | null,
  readonly currentBrand: string | null,
  readonly currentCarModel: CarModel | null,
  readonly currentEngineCode: string | null,
  readonly currentEngineOil: string | null,
  readonly currentFuelType: CarEngineFuelType,
  readonly currentCarVersion: string | null,
  readonly engineCodes: ReadonlyArray<string> | null,
}

class CarNewComponent extends React.Component<Props> {
  public static getDerivedStateFromProps(nextProps: CarViewServiceProps, prevState: State): State {
    if (!nextProps.oilTypes) { return prevState }

    const nextOilType = nextProps.oilTypes.length > 0 ? nextProps.oilTypes[0] : ''
    if (nextOilType !== prevState.currentEngineOil) {
      return {
        ...prevState,
        currentEngineOil: nextOilType,
      }
    }

    return prevState
  }

  public readonly state: State = {
    currentBrakeLiquid: null,
    currentBrand: null,
    currentCarModel: null,
    currentCarVersion: null,
    currentEngineCode: null,
    currentEngineOil: null,
    currentFuelType: CarEngineFuelType.NA,
    engineCodes: null,
  }

  public componentDidMount(): void {
    if (this.getClientId()) {
      this.props.getSelectedClient(this.getClientId())
    }

    this.getCarDictionaryData()
  }

  public componentDidUpdate(prevProps: Props): void {
    const { brakeLiquids: prevBrakeLiquids } = prevProps
    const { brakeLiquids } = this.props

    if (!isEqual(prevBrakeLiquids.data, brakeLiquids.data) && brakeLiquids.data?.length === 1) {
      this.setState({
        currentBrakeLiquid: brakeLiquids.data[0],
      })
    }
  }

  public componentWillUnmount(): void {
    this.props.clearOilTypes()
    this.props.clearBrakeLiquids()
    this.props.clearCarVersions()
  }

  public render(): React.ReactNode {
    const {
      brakeLiquids,
      carModels,
      carVersions,
      checkRegistrationNumberExistence,
      checkVinExistence,
      createCar,
      currentCarRegistrationNumberExists,
      currentCarVinExists,
      dealerBrands,
      isLoadingSelectedClient,
      oilTypes,
      selectedClient,
    } = this.props

    const {
      currentBrand,
      currentCarModel,
      currentCarVersion,
      currentBrakeLiquid,
      currentFuelType,
      currentEngineOil,
      currentEngineCode,
      engineCodes,
    } = this.state

    const { data: brakeLiquidsList } = brakeLiquids

    const newCarObject = {} as CarInfoWithClient // tslint:disable-line

    if (isLoadingSelectedClient) {
      return <Loader />
    }

    return (
      <>
        <CarNewHeader client={selectedClient} />
        <CarViewServiceForm
          brakeLiquid={currentBrakeLiquid}
          brakeLiquids={brakeLiquidsList}
          car={newCarObject}
          carAttachments={null}
          carModels={mapValuesToSelectOptions(carModels)}
          carVersions={mapValuesToSelectOptions(carVersions.data)}
          checkRegistrationNumberExistence={checkRegistrationNumberExistence}
          checkVinExistence={checkVinExistence}
          brand={currentBrand}
          carModel={currentCarModel}
          carVersion={currentCarVersion}
          currentCarRegistrationNumberExists={currentCarRegistrationNumberExists}
          currentCarVinExists={currentCarVinExists}
          dealerBrands={dealerBrands}
          fuelType={currentFuelType}
          isNewCar={true}
          engineCode={currentEngineCode}
          engineCodes={mapValuesToSelectOptions(engineCodes)}
          engineOil={currentEngineOil}
          oilTypes={mapValuesToSelectOptions(oilTypes)}
          onCarModelChange={this.handleCarModelChange}
          onCarVersionChange={this.handleCarVersionChange}
          onBrandChange={this.handleBrandChange}
          onBrakeLiquidChange={this.handleBrakeLiquidChange}
          onEngineCodeChange={this.handleEngineCodeChange}
          onFuelTypeChange={this.handleFuelTypeChange}
          onOilTypeChange={this.handleOilTypeChange}
          updateCar={createCar}
        />
      </>
    )
  }

  private readonly getClientId = () => this.props.match.params.clientId as ClientId

  private readonly getCarDictionaryData = () => {
    this.props.getFuelTypes()
    this.props.getTireTypes()
  }

  private readonly handleBrandChange = (brand: string) => {
    this.setState({
      currentBrakeLiquid: '',
      currentBrand: brand,
      currentCarVersion: null,
      currentEngineCode: null,
      currentEngineOil: '',
    })
    this.props.getBrakeLiquids(brand)
    this.props.getOilTypes(brand, this.state.currentEngineCode)
    this.getCarEngineCodes(brand)
    this.props.getCarModels(brand)
    this.props.getCarVersions(brand)
  }

  private readonly handleFuelTypeChange = (fuelType: CarEngineFuelType) => {
    this.setState({
      currentFuelType: fuelType,
    })
  }

  private readonly handleBrakeLiquidChange = (brakeLiquid: string) => {
    this.setState({
      currentBrakeLiquid: brakeLiquid,
    })
  }

  private readonly handleMapEngineCodeToFuelType = (engineCode: CarEngineCode) => {
    this.setState({
      currentFuelType: mapEngineCodeToFuelType(engineCode),
    })
  }

  private readonly handleCarModelChange = (carModel: CarModel) => {
    this.setState({
      currentCarModel: carModel,
    })
  }

  private readonly handleCarVersionChange = (version: string) => {
    this.setState({
      currentCarVersion: version,
    })
  }

  private readonly handleEngineCodeChange = (engineCode: string) => {
    this.setState({ currentEngineCode: engineCode })
    this.handleMapEngineCodeToFuelType(engineCode)
    this.getOilTypes(this.state.currentBrand, engineCode)
  }

  private readonly handleOilTypeChange = (oilType: string) => {
    this.setState({
      currentEngineOil: oilType,
    })
  }

  private readonly getCarEngineCodes = (brand: string) => {
    getCarLiquids([brand])
      .then(oilsAndEngines => {
        this.setState({
          engineCodes: oilsAndEngines.map(entry => entry.engineCode),
        })
      }).catch(() => { this.props.showFailureToast() })
  }

  private readonly getOilTypes = (brand: string | null, engineCode: CarEngineCode) => {
    this.props.getOilTypes(brand, engineCode)
  }

}

export const CarNew = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CarNewComponent)
