import { Logout } from 'actff-bo-app/Auth'
import { Loader } from 'actff-bo-app/components/Loader'
import { getWrappedComponent } from 'actff-bo-app/components/Wrapper'
import { NotFound } from 'actff-bo-app/NotFound'
import { NotificationHandler } from 'actff-bo-app/NotificationHandler'
import { PrivacyPolicy } from 'actff-bo-app/PrivacyPolicy'
import { AppConfig, getConfig } from 'actff-bo-lib/api'
import {
  dealerBrandsExist,
  dealerLocationsExist,
  getDealerBrandsAction,
  getDealerLocationsAction,
} from 'actff-bo-lib/dealership'
import { connector } from 'actff-bo-lib/global'
import { MenuItem } from 'actff-bo-lib/menu/dto'
import { selectMenuAsFlatStructure } from 'actff-bo-lib/menu/selectors'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { selectMe, User } from 'actff-bo-lib/user'
import { ConnectedRouter } from 'connected-react-router'
import * as React from 'react'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { containers } from 'styles'

type DispatchToProps = {
  readonly getDealerBrands: () => void,
  readonly getDealerLocations: () => void,
}

type StateToProps = {
  readonly dealerBrandsLoaded: boolean,
  readonly dealerLocationsLoaded: boolean,
  readonly me: User | null,
  readonly routes: ReadonlyArray<MenuItem>,
}

type RouterComponentProps = StateToProps & DispatchToProps

const Main = styled.main`${containers.main.style}`

export class RouterComponent extends React.Component<RouterComponentProps> {
  public componentDidMount(): void {
    const { getDealerLocations, getDealerBrands, me } = this.props

    if (me) {
      getDealerBrands()
      getDealerLocations()
    }
  }

  public render(): React.ReactNode {
    const { dealerBrandsLoaded, dealerLocationsLoaded, me } = this.props
    console.log(`Backoffice version - ${getConfig(AppConfig.VERSION)}`) // tslint:disable-line no-console

    if ((!dealerLocationsLoaded || !dealerBrandsLoaded) && me) { return <Loader /> }

    return (
      <ConnectedRouter history={history}>
        <Main>
          <Switch>
            {this.props.routes.map(this.renderRoute)}
            <Route exact={true} path='/logout' component={Logout} />
            <Route exact={true} path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact={true} path='/notifications/:type/:uuid' component={NotificationHandler} />
            <Route component={NotFound} />
          </Switch>
        </Main>
      </ConnectedRouter>
    )
  }

  private readonly renderRoute = (route: MenuItem, key: number) =>
    <Route key={key} exact={true} path={route.path} component={getWrappedComponent(route)} />
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getDealerBrands: () => dispatch(getDealerBrandsAction()),
  getDealerLocations: () => dispatch(getDealerLocationsAction()),
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  dealerBrandsLoaded: dealerBrandsExist(state),
  dealerLocationsLoaded: dealerLocationsExist(state),
  me: selectMe(state),
  routes: selectMenuAsFlatStructure(state),
})

export const Router = connector(mapStateToProps, mapDispatchToProps)(RouterComponent)
