import { CalendarResourceAvailability } from 'actff-bo-lib/admin/employee-scheduler/dto'
import { api, PagedResult } from 'actff-bo-lib/api'
import { axiosApi } from 'actff-bo-lib/api/axios-requests'
import { CrmInternalCommentDto, OpportunityListType } from 'actff-bo-lib/crm/dto'
import {
  createOpportunity,
  OpportunityPurchaseFilterValues,
  OpportunityPurchaseId,
  OpportunitySaleFilterValues,
  OpportunitySaleId,
  OpportunityTradeInternalEventDto,
  TradeOpportunityType,
} from 'actff-bo-lib/crm/trade/dto'
import { buildUrl, Filters, getStatusFiltersAsString } from 'actff-bo-lib/global'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import queryString from 'query-string'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

type GetTradeOpportunitiesProps = {
  readonly type: OpportunityListType,
  readonly opportunityType: TradeOpportunityType,
  readonly page: number,
  readonly statusFilters: Filters<OpportunityPurchaseFilterValues | OpportunitySaleFilterValues>,
  readonly detailedFilters?: string,
  readonly assignmentFilter: UserId | null,
  readonly fromDate?: Date,
  readonly toDate?: Date,
  readonly search?: string,
}

export const getTradeOpportunities = <T>({
  type,
  opportunityType,
  page,
  statusFilters,
  detailedFilters = '',
  assignmentFilter,
  fromDate,
  toDate,
  search,
}: GetTradeOpportunitiesProps): Observable<PagedResult<T>> => {
  const getFrom = () => fromDate && fromDate.toISOString()
  const getTo = () => toDate && toDate.toISOString()

  const statusFiltersString = queryString.stringify(getStatusFiltersAsString(statusFilters) || {})

  if (type === OpportunityListType.all) {
    return api.get(
      `${buildUrl(
        `/trade/${opportunityType}`,
        { assignee: assignmentFilter, page, search })}&${detailedFilters}&${statusFiltersString}&`,
    )
  }

  if (type === OpportunityListType.future) {
    return api.get(
      `${buildUrl(`/trade/${opportunityType}/future`, {
        assignee: assignmentFilter,
        from: getFrom(),
        page,
        search,
        to: getTo(),
      })}&${detailedFilters}&${statusFiltersString}&`)
  }

  return api.get(
    `${buildUrl(`/trade/${opportunityType}/${type}`,
      { assignee: assignmentFilter, page, search })}&${detailedFilters}&${statusFiltersString}&`,
  )
}

export const getTradeOpportunity = <T>(
  uuid: OpportunityPurchaseId | OpportunitySaleId,
  opportunityType: TradeOpportunityType,
  ): Observable<T> =>
    api.get(`/trade/${opportunityType}/${uuid}`).pipe(map(createOpportunity))

export const getMatchedTradeOpportunities = <R, T>(
  uuid: R,
  opportunityType: TradeOpportunityType,
  ): Observable<ReadonlyArray<T>> => api.get(`/trade/matched/${opportunityType}/${uuid}`)

export const getMatchedTradeOpportunitiesWithAxios = <R>(
  uuid: R,
  opportunityType: TradeOpportunityType,
  ) => axiosApi.get<ReadonlyArray<CalendarResourceAvailability>>(`/trade/matched/${opportunityType}/${uuid}`)

export const sendTradeOpportunityInternalEvent = <T>(
  id: OpportunityPurchaseId | OpportunitySaleId,
  internalEvent: OpportunityTradeInternalEventDto,
  opportunityType: TradeOpportunityType,
) => api.patch<T, OpportunityTradeInternalEventDto>(`/trade/${opportunityType}/${id}/business-event`, internalEvent)

export const sendTradeOpportunityInternalComment = <T>(
  opportunityId: OpportunityPurchaseId | OpportunitySaleId,
  commentDto: CrmInternalCommentDto,
  opportunityType: TradeOpportunityType) =>
  api.patch<T, CrmInternalCommentDto>(`/trade/${opportunityType}/${opportunityId}/comment`, commentDto)

export const assignTradeOpportunity = <T>(
  uuid: OpportunityPurchaseId | OpportunitySaleId,
  user: UserWithoutPermissions,
  opportunityType: TradeOpportunityType,
  ) => api.patch<T, UserWithoutPermissions>(`/trade/${opportunityType}/${uuid}/assign`, user)

export const unassignTradeOpportunity = <T>(uuid: OpportunityPurchaseId | OpportunitySaleId, opportunityType: TradeOpportunityType) =>
  api.delete<T>(`/trade/${opportunityType}/${uuid}/assign`)
