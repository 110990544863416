import { NominalString } from 'actff-bo-lib/global'

export type CarInsuranceId = NominalString<CarInsurance>

type CarAssistance = {
  readonly insurer: string,
  readonly policyNumber: string,
  readonly expirationDate: Date | null,
}

type Gap = {
  readonly insurer: string,
}

export enum ValueForInsuranceCalculation {
  GROSS = 'GROSS',
  NET = 'NET',
  CUSTOM = 'CUSTOM',
}

export const InsuranceValueForCalculationTranslationMap: Map<ValueForInsuranceCalculation, string> = new Map([
  [ValueForInsuranceCalculation.CUSTOM, 'carView.form.insurance.valueForInsuranceCalculation.custom'],
  [ValueForInsuranceCalculation.GROSS, 'carView.form.insurance.valueForInsuranceCalculation.gross'],
  [ValueForInsuranceCalculation.NET, 'carView.form.insurance.valueForInsuranceCalculation.net'],
]) as Map<ValueForInsuranceCalculation, string>

export type CarInsurance = {
  readonly ac: boolean,
  readonly assistance: boolean,
  readonly manufacturerAssistance: boolean,
  readonly assistanceInHouse: CarAssistance,
  readonly expirationDate: Date | null,
  readonly gap: Gap,
  readonly inHouse: boolean,
  readonly insurer: string,
  readonly nnw: boolean,
  readonly oc: boolean,
  readonly policyNumber: string,
  readonly uuid: CarInsuranceId,
  readonly marketValue: number | null,
  readonly insuranceValue: number | null,
  readonly valueForInsuranceCalculation: ValueForInsuranceCalculation | null,
}

export enum CarInsuranceType {
  Ac = 'ac',
  Oc = 'oc',
  Nnw = 'nnw',
  Assistance = 'assistance',
}

export type CarInsuranceDto = Omit<CarInsurance, 'uuid' | 'insuranceValue'>
