import { ReactSelect } from 'actff-bo-app/components/Select'
import { getLocationBrandsAction } from 'actff-bo-lib/admin/dealer-locations'
import { selectCurrentBrand, selectDealerBrands, setCurrentBrandAction } from 'actff-bo-lib/dealership'
import { mapToOptions, Option } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContainerMaxWidth } from './getContainerMaxWidth'

import { Container, customStyles } from './Styled'

export const BrandsDropdown: React.FC<Testable> = () => {
  const dispatch = useDispatch()

  const { data: brands } = useSelector(selectDealerBrands)

  useEffect(() => {
    if (!brands) {
      dispatch(getLocationBrandsAction())
    }
  }, [brands])

  const currentBrand = useSelector(selectCurrentBrand)
  const currentValue = currentBrand ? { label: currentBrand, value: currentBrand } : null

  const handleBrandChange = (selectOption: Option) => {
    dispatch(setCurrentBrandAction(selectOption.value))
  }

  const brandOptions = mapToOptions(brands)

  return (
    <Container maxWidth={getContainerMaxWidth(currentBrand)}>
      <ReactSelect
        customStyles={customStyles}
        isDisabled={brands?.length === 1}
        isClearable={false}
        hideSelectedOptions={true}
        hideDropdownIndicator={brands?.length === 1}
        options={brandOptions}
        onChange={handleBrandChange}
        value={currentValue}
      />
    </Container>
  )
}
