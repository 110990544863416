import { ErrorMessage } from '@hookform/error-message'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { MultipleSelectFilter } from 'actff-bo-app/components/Filters'
import { Select } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import { mapOptionsToArray, SelectOption } from 'actff-bo-lib/global'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { addUser } from 'actff-bo-lib/user'
import React, { FC } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'

import { mapOptionsToLocationKeys } from './map-options-to-locations'
import { FormSegment, Input, LabelStyled, NewUserFormContainer } from './Styled'

type Props = {
  readonly dialogOpen: boolean,
  readonly toggleAddUserDialogOpen: () => void,
  readonly brandSelectOptions: ReadonlyArray<SelectOption<string | null>>,
  readonly locationSelectOptions: ReadonlyArray<SelectOption<string | null>>,
  readonly rolesSelectOptions: ReadonlyArray<SelectOption<string | null>>,
  readonly onAddNewUser: () => void,
}

type Form = {
  readonly password: string,
  readonly passwordRepeat: string,
  readonly firstName: string,
  readonly lastName: string,
  readonly email: string,
  readonly locations: ReadonlyArray<SelectOption<string>>,
  readonly brands: ReadonlyArray<SelectOption<string>>,
  readonly roles: SelectOption<string>,
}

const selectWidth = 350
const multipleSelectTrimLength = 2

export const NewUserForm: FC<Props> = ({
  dialogOpen,
  toggleAddUserDialogOpen,
  locationSelectOptions,
  rolesSelectOptions,
  brandSelectOptions,
  onAddNewUser,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const addUserMutation = useMutation(
    QueryKeys.ADD_NEW_USER,
    addUser,
    {
      onError: async () => {
        dispatch(displayFailureToast('admin.users.addUser.error', ToastActionType.CREATE_OR_UPDATE))
      },
      onSuccess: async () => {
        onAddNewUser()
        toggleAddUserDialogOpen()
      },
    },
  )

  const { control, formState, register, handleSubmit, errors } = useForm<Form>({
    defaultValues: {
      brands: [],
      email: '',
      firstName: '',
      lastName: '',
      locations: [],
      password: '',
      passwordRepeat: '',
    },
  })

  const isSubmitDisabled = () => !formState.isDirty && !formState.isValid

  const passwordWatched = useWatch({
    control,
    defaultValue: '',
    name: 'password',
  })

  const handleAddNewUserConfirm = (formData: Form) => {
    const { passwordRepeat, ...formDataOmitted } = formData

    addUserMutation.mutate({
      ...formDataOmitted,
      brands: mapOptionsToArray<string>(formData.brands) || [],
      locations: mapOptionsToLocationKeys(formData.locations) || [],
      roles: [formData.roles.value],
    })
  }

  return (
    <Dialog
      confirmDisabled={isSubmitDisabled()}
      confirmTLabel='caption.save'
      isLoading={addUserMutation.isLoading}
      open={dialogOpen}
      onConfirm={handleSubmit(handleAddNewUserConfirm)}
      onClose={toggleAddUserDialogOpen}
      title='admin.dealership.users.add'
    >
      <NewUserFormContainer>
        <FormSegment>
          <LabelStyled>{t('caption.firstName')}:</LabelStyled>
          <Input
            type='text'
            name='firstName'
            ref={register({
              ...getValidator({ type: ValidationType.Pattern, msgTKey: 'form.validation.text.wrong', regExp: /^[a-zA-Z]+$/ }),
              ...getValidator({ type: ValidationType.Required }),
            })}
          />
          <ErrorMessage errors={errors} name='firstName' as={TranslatedErrorMessage} />
        </FormSegment>
        <FormSegment>
          <LabelStyled>{t('caption.lastName')}:</LabelStyled>
          <Input
            type='text'
            name='lastName'
            ref={register({
              ...getValidator({ type: ValidationType.Pattern, msgTKey: 'form.validation.text.wrong', regExp: /^[a-zA-Z]+$/ }),
              ...getValidator({ type: ValidationType.Required }),
            })}
          />
          <ErrorMessage errors={errors} name='lastName' as={TranslatedErrorMessage} />
        </FormSegment>
        <FormSegment>
          <LabelStyled>{t('caption.mail')}:</LabelStyled>
          <Input
            name='email'
            type='email'
            ref={register({
              ...getValidator({ type: ValidationType.Pattern, msgTKey: 'form.validation.mail.wrong', regExp: /^\S+@\S+$/i }),
              ...getValidator({ type: ValidationType.Required }),
            })}
          />
          <ErrorMessage errors={errors} name='email' as={TranslatedErrorMessage} />
        </FormSegment>
        <FormSegment>
          <LabelStyled>{t('caption.permissions')}:</LabelStyled>
          <Controller
            as={<Select />}
            control={control}
            name='roles'
            options={rolesSelectOptions}
            rules={{ ...getValidator({ type: ValidationType.Required }) }}
          />
          <ErrorMessage errors={errors} name='permissions' as={TranslatedErrorMessage} />
        </FormSegment>
        <FormSegment>
          <LabelStyled>{t('caption.location')}:</LabelStyled>
          <MultipleSelectFilter
            closeMenuOnSelect={true}
            control={control}
            key='locations'
            name='locations'
            options={locationSelectOptions}
            trimLength={multipleSelectTrimLength}
            menuPlacement='bottom'
            maxWidth={selectWidth}
            minWidth={selectWidth}
            rules={{ ...getValidator({ type: ValidationType.Required }) }}
          />
          <ErrorMessage errors={errors} name='locations' as={TranslatedErrorMessage} />
        </FormSegment>
        <FormSegment>
          <LabelStyled>{t('caption.brand')}:</LabelStyled>
          <MultipleSelectFilter
            closeMenuOnSelect={true}
            control={control}
            key='brands'
            name='brands'
            options={brandSelectOptions}
            trimLength={multipleSelectTrimLength}
            menuPlacement='bottom'
            maxWidth={selectWidth}
            minWidth={selectWidth}
            rules={{ ...getValidator({ type: ValidationType.Required }) }}
          />
          <ErrorMessage errors={errors} name='brands' as={TranslatedErrorMessage} />
        </FormSegment>
        <FormSegment>
          <LabelStyled>{t('caption.password')}:</LabelStyled>
          <Input
            type='password'
            name='password'
            ref={register({
              ...getValidator({ type: ValidationType.MaxLength }),
              ...getValidator({ type: ValidationType.MinLength }),
              ...getValidator({ type: ValidationType.Pattern }),
              ...getValidator({ type: ValidationType.Required }),
            })}
          />
          <ErrorMessage errors={errors} name='password' as={TranslatedErrorMessage} />
        </FormSegment>
        <FormSegment>
          <LabelStyled>{t('admin.dealership.users.password.repeat')}:</LabelStyled>
          <Input
            type='password'
            name='passwordRepeat'
            ref={register({
              ...getValidator({ type: ValidationType.MaxLength }),
              ...getValidator({ type: ValidationType.MinLength }),
              ...getValidator({ type: ValidationType.Required }),
              ...getValidator({ type: ValidationType.Pattern }),
              validate: value => value !== passwordWatched ? 'form.validation.password.notSame' : true,
            })}
          />
          <ErrorMessage errors={errors} name='passwordRepeat' as={TranslatedErrorMessage} />
        </FormSegment>

      </NewUserFormContainer>
    </Dialog>

  )
}
