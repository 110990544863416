import { QuickCampaignChannel, QuickCampaignDto, QuickCampaignPhoneNumbersRegexp } from 'actff-bo-lib/campaign'
import { mapKeysToFormValues } from 'actff-bo-lib/form/map-values-to-keys'
import { withFormik } from 'formik'
import { boolean, mixed, object, string } from 'yup'
import { DispatchToProps, Props } from '../QuickCampaignComponent'
import { QuickCampaignForm } from './Form'
import { mapFormValuesToDto } from './mapFormValuesToDto'

const validationSchema = () => object<QuickCampaignDto>().shape({
  channel: mixed().required(),
  chosenUsers: boolean().when('channel', {
    is: value => value === QuickCampaignChannel.SMS,
    then: boolean().required('campaign.quickCampaign.fields.phones.isRequired'),
  }),
  message: string().required().min(1),
  phones: mixed().when(['channel', 'chosenUsers'], {
    // @ts-ignore, wrongly typed Yup `is` function
    is: (...values) => values[0] === QuickCampaignChannel.SMS && !values[1],
    then: string()
      .matches(QuickCampaignPhoneNumbersRegexp, 'campaign.quickCampaign.fields.phones.invalid')
      .required('campaign.quickCampaign.fields.phones.isRequired').min(1),
  }),
})

export const formik = withFormik<DispatchToProps, QuickCampaignForm>({
  handleSubmit: (values, { props, resetForm, setSubmitting }) => {
    setSubmitting(true)
    props.sendQuickCampaign(mapFormValuesToDto(values))
    resetForm()
  },
  mapPropsToValues: ({ brands, dealerLocations, location }: Props) => {
    const mappedBrandsToFormValues = brands ? mapKeysToFormValues(brands) : {}

    const { search } = location
    const phone = new URLSearchParams(search).get('phone')

    const locations = dealerLocations
      ? dealerLocations.reduce((prev, curr) => ({
        ...prev,
        locationsGroupSelector: {
          ...prev.locationsGroupSelector,
          [curr.key]: true,
        },
        [curr.key]: mappedBrandsToFormValues,
      }), {
        locationsGroupSelector: {},
      })
      : {
        locationsGroupSelector: {},
      }

    if (phone) {
      return {
        brandsGroupSelector: {
          ...mappedBrandsToFormValues,
        },
        channel: QuickCampaignChannel.SMS,
        chosenLocations: true,
        chosenUsers: false,
        message: '',
        phones: `+${phone}`.split(' ').join(''),
        ...locations,
      }
    }

    return {
      brandsGroupSelector: {
        ...mappedBrandsToFormValues,
      },
      channel: null,
      chosenLocations: true,
      chosenUsers: true,
      message: '',
      phones: '',
      ...locations,
    }
  },
  validationSchema,
})
