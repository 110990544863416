import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { colors } from 'styles'

export const ManualOpportunityContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-areas:
    'form form'
    'client client'
    'car-header car-header'
    'car-data-first car-data-second'
    'service-first service-second';
`

export const DateFieldsContainer = styled.div`
  border-bottom: 1px solid ${colors.mystic};
  display: grid;
  padding: 24px;
`

export const FormCellStyled = styled(FormCell)`
  padding-left: 0;
  padding-top: 24px;
`

export const DateFieldContainer = styled(FormCellStyled)`
  width: 200px;
`
export const Description = styled.div`
  display: grid;
`

export const OpportunityForm = styled.div`
  display: grid;
  grid-area: form;
`

export const ServiceStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
