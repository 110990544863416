import { ServiceAction, ServiceActionType } from './actions'
import { Service } from './dto'

export type ServiceState = {
  readonly list: ReadonlyArray<Service>,
}

const initialState: ServiceState = {
  list: [],
}

export const serviceReducer = (state: ServiceState = initialState, action: ServiceAction): ServiceState => {
  switch (action.type) {
    case ServiceActionType.GetServicesSuccess:
      return {
        ...state,
        list: action.payload,
      }

    default:
      return state
  }
}
