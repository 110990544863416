import { OpportunityListType } from 'actff-bo-lib/crm'
import { Map } from 'immutable'
import React from 'react'
import { Tr, TrDanger, TrWarning } from 'styles'

export const ListTypeTrStyleMap = Map([
  [OpportunityListType.future, Tr],
  [OpportunityListType.all, Tr],
  [OpportunityListType.new, TrWarning],
  [OpportunityListType.overdue, TrDanger],
]) as Map<OpportunityListType, React.ReactNode>
