import styled from 'styled-components'
import { layers, theme } from 'styles'

export const Ul = styled.ul`
  padding: 0;
  position: relative;
`

export const UlActive = styled(Ul)`
  &:before {
    background: ${theme.menu.indicator};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
    z-index: ${layers.menuActiveIndicator};
  }
`

export const NavigationItemStyled = styled.li`
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 4fr 1fr;
  min-height: 48px;
  padding: 0 32px;
  position: relative;
`

export const NavigationItemActiveStyled = styled(NavigationItemStyled)`
  background: ${theme.menu.background.active};
  color: ${theme.menu.text.active};
`
