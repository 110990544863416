import { BtnType } from 'actff-bo-app/components/Button'
import { renderSelectController } from 'actff-bo-app/components/Form'
import { Modal } from 'actff-bo-app/components/Modal'
import { PreviewFiles, UploadButton, UploadFile } from 'actff-bo-app/components/UploadFile'
import { Textarea } from 'actff-bo-app/Crm/Styled'
import { FormValueT } from 'actff-bo-lib/car/helpers'
import {
  CrmTradeOpportunityAttachmentAcceptTypes,
  OpportunityTradeInternalEventDto,
  OpportunityTradeInternalEventFormWithAttachments,
} from 'actff-bo-lib/crm/trade'
import { Attachment as AttachmentType } from 'actff-bo-lib/global/attachment'
import { hasErrors, SelectOption } from 'actff-bo-lib/global/data-types'
import { Testable } from 'actff-bo-lib/global/testable'
import { UserWithoutPermissions } from 'actff-bo-lib/user/dto'
import React, { FC } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LinkButton, PrimaryButton } from 'styles'

type Props = Testable & {
  readonly currentUser: UserWithoutPermissions | null,
  readonly onClose: () => void,
  readonly open: boolean,
  readonly options: ReadonlyArray<SelectOption<string>>,
  readonly sendEvent: (event: OpportunityTradeInternalEventDto) => void,
}

export const DialogContent = styled.div`
  display: grid;
  grid-auto-flow: row;
`

export const DialogFooter = styled.div`
  display: grid;
  grid-template-columns: 100px 120px;
  justify-content: right;
  padding: 20px 0;
`

export const EventTypeContainer = styled.div`
  width: 300px;
`

export const TextareaStyled = styled(Textarea)`
  padding: 10px;
`

const modalWidth = 800

export const AddOpportunityEvent: FC<Props> = ({ currentUser, onClose, open, options, sendEvent, testId }) => {
  const { t } = useTranslation()
  const {
    control,
    errors,
    formState: { isDirty, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<OpportunityTradeInternalEventFormWithAttachments>(
    {
      defaultValues: {},
      mode: 'onChange',
    })
  const isSendButtonDisabled = () => hasErrors(errors as FormValueT<OpportunityTradeInternalEventDto>) || !isValid || !isDirty
  // tslint:disable-next-line:no-any
  const watchedAttachments = useWatch<any>({ control, name: 'attachments' })
  const handleCancel = () => {
    reset()
    onClose()
  }

  const handleSend = handleSubmit(( formValues: OpportunityTradeInternalEventFormWithAttachments) => {
    sendEvent(formValues)
    setValue('attachments', [])
  })

  if (!currentUser) { return null }

  const handleDeleteAttachment = (file: AttachmentType) => () => {
    setValue('attachments', [
      ...watchedAttachments.filter((attachment: AttachmentType) => attachment.file !== file.file),
    ])
  }

  return (
    <Modal onClose={onClose} open={open} title='registerEvent.title' width={modalWidth}>
      {open && (
        <>
          <DialogContent>
            <p>{t('crmTrade.eventModal.mainLabel')}</p>
            <label>{t('eventKind')}:</label>
            <EventTypeContainer>
              <Controller
                render={renderSelectController<string>({
                  options,
                  t,
                  testId: `${testId}-inHouseType`,
                  transKey: 'crmTrade.eventType',
                })}
                control={control}
                name='type'
                rules={{ required: true }}
              />
            </EventTypeContainer>
            <TextareaStyled name='comment' ref={register({ required: true })} />
            <PreviewFiles files={watchedAttachments} onDeleteFile={handleDeleteAttachment} />
            <Controller
              control={control}
              name='attachments'
              render={({ onChange, value: currentAttachments }) => (
                <UploadFile
                  acceptTypes={CrmTradeOpportunityAttachmentAcceptTypes.join(', ')}
                  component={UploadButton}
                  onChange={attachments => onChange([...(currentAttachments || []), ...attachments])}
                  multiple={true}
                />
              )}
            />
          </DialogContent>

          <DialogFooter>
            <LinkButton onClick={handleCancel} type={BtnType.Button}>
              {t('caption.cancel')}
            </LinkButton>
            <PrimaryButton
              disabled={isSendButtonDisabled()}
              type={BtnType.Button}
              onClick={handleSend}
            >
              {t('caption.send')}
            </PrimaryButton>
          </DialogFooter>
        </>
      )}
    </Modal>
  )
}
