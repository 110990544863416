import { BtnType } from 'actff-bo-app/components/Button'
import { connector } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { selectMe, User } from 'actff-bo-lib/user'
import Logo from 'assets/logo.svg'
import * as React from 'react'
import { MapStateToProps } from 'react-redux'
import { LogoContainer, LogoStyled, LogoutContainer, LogoutIcon, TopBarContainer } from './Styled'

type StateToProps = {
  readonly me: User | null,
}

type Props = StateToProps

const logout = () => {
  history.push(Paths.Logout)
}

const testId = 'top-bar__'

export class TopBarComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { me } = this.props

    return (
      <TopBarContainer>
        <LogoContainer>
          <LogoStyled src={Logo} alt='Autogala logo' />
        </LogoContainer>
        <LogoutContainer>
          <span id='currentUser'>{me && me.email}</span>
          <button data-testid={`${testId}logout`} type={BtnType.Button} onClick={logout}>
            <LogoutIcon type={IconType.Exit}/>
          </button>
        </LogoutContainer>
      </TopBarContainer>
    )
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  me: selectMe(state),
})

export const TopBar = connector(mapStateToProps, null)(TopBarComponent)
