import { DebugToolsAction } from 'actff-bo-lib/debug-tools'
import { I18nAction } from 'actff-bo-lib/i18n'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { NotificationAction } from 'actff-bo-lib/notification'
import { history } from 'actff-bo-lib/router'
import { UserAction } from 'actff-bo-lib/user/actions'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'

import { AuthAction, AuthActionType } from './actions'
import { login } from './dao'
import { decodeJwtPayload } from './decode-jwt-payload'
import { TokenPayload } from './dto'
import { getToken, removeToken, setToken } from './storage'

const loginEpic: Epic<AuthAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AuthAction.login>>(AuthActionType.Login),
  switchMap(({ payload }) => login(payload).pipe(
    map(AuthAction.loginSuccess),
    catchError((error: AjaxError) => of(AuthAction.loginFailure(error))),
  )),
)

const loginSuccessEpic: Epic<AuthAction | DebugToolsAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AuthAction.loginSuccess>>(AuthActionType.LoginSuccess),
  switchMap(({ payload }) => of(setToken(payload)).pipe(
    map(() => decodeJwtPayload<TokenPayload>(payload)),
    filter<TokenPayload>(Boolean),
    switchMap(decodedToken => [
      AuthAction.setToken(payload),
      DebugToolsAction.identifyLogrocket(decodedToken.sub),
    ],
  ))),
)

const setTokenEpic: Epic<AuthAction, AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AuthAction.setToken>>(AuthActionType.SetToken),
  map(({ payload }) => payload),
  switchMap(payload => payload
    ? [
      UserAction.getMe(),
    ]
    : [UserAction.setMeInitialized()],
  ),
)

const loadTokenEpic: Epic<AuthAction> = action$ => action$.pipe(
  ofType(AuthActionType.LoadToken),
  switchMap(() => of(getToken()).pipe(
    map(AuthAction.setToken),
  )),
)

const logoutEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType(AuthActionType.Logout),
  tap(() => {
    history.replace(Paths.Login)
    removeToken()
  }),
  switchMap(() => [
    DebugToolsAction.initLogrocket(),
    I18nAction.init(),
    UserAction.setMeInitialized(),
    NotificationAction.init(),
  ]),
)

export const authEpic = combineEpics(
  loadTokenEpic,
  loginEpic,
  loginSuccessEpic,
  logoutEpic,
  setTokenEpic,
)
