import { months } from 'actff-bo-lib/date'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

type Props = {
  readonly date: Date | null,
  readonly onChange: (date: Date | null) => void,
  readonly futureYearsOffset?: number,
  readonly pastYearsOffset?: number,
}

type YearMonthProps = Props & WithTranslation

const defaultPastYearsOffset = 25
const defaultFutureYearsOffset = 10

class YearMonthComponent extends React.Component<YearMonthProps> {
  public readonly month: React.RefObject<HTMLSelectElement> = React.createRef()
  public readonly year: React.RefObject<HTMLSelectElement> = React.createRef()

  public render(): React.ReactNode {
    const { date, t } = this.props
    const currentMonthValue = date ? date.getMonth() : new Date().getMonth()
    const currentYearValue = date ? date.getFullYear() : new Date().getFullYear()

    return (
      <div className='DayPicker-Caption'>
        <select name='month' onChange={this.handleChange} ref={this.month} value={currentMonthValue}>
          {months.map((month, i) => <option key={month} value={i}>{t(month)}</option>)}
        </select>
        <select name='year' onChange={this.handleChange} ref={this.year} value={currentYearValue}>
          {this.years().map(year => <option key={year} value={year}> {year} </option>)}
        </select>
      </div>
    )
  }

  private readonly years = () => {
    const currentYear = new Date().getFullYear()
    const futureYearsOffset = this.props.futureYearsOffset ?? defaultFutureYearsOffset
    const pastYearsOffset = this.props.pastYearsOffset ?? defaultPastYearsOffset

    const pastYears = Array(pastYearsOffset).fill(0).map((_, index) => currentYear - pastYearsOffset + index)
    const futureYears = Array(futureYearsOffset).fill(0).map((_, index) => currentYear + index + 1)

    return [...pastYears, currentYear, ...futureYears]
  }

  private readonly handleChange = () => {
    const { date, onChange } = this.props
    const changedDate = this.year.current && this.month.current ? new Date(+this.year.current.value, +this.month.current.value) : date

    onChange(changedDate)
  }
}

export const YearMonth = withTranslation()(YearMonthComponent)
