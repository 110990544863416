import { Toast } from 'actff-bo-app/components/Toast'
import { Router } from 'actff-bo-app/routing'
import { store } from 'actff-bo-lib/store'
import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Global } from 'styles'

import { Root } from './Root'

export const App: React.FC = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store()}>
        <Root>
          <Global/>
          <Toast/>
          <Router/>
        </Root>
      </Provider>
    </QueryClientProvider>
  )
}
