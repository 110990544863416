import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { CarCard } from 'actff-bo-app/components/CarCard'
import { PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly cars: PagedResult<CarInfo>,
}

const CarHeaders = styled.div`
  background: ${colors.mysticGray};
  display: grid;
  font-weight: 600;
  grid-area: car-headers;
  grid-auto-flow: column;
  padding: 24px 16px;
  width: 100%;
`
const ExpandCollapseButtonStyled = styled(ExpandCollapseButton)`
  justify-self: right;
`

export const OfferRequestCars: FC<Props> = ({ cars }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const handleToggle = () => setExpanded(!expanded)
  const startCountingFrom = 2

  return (
    <>
      <CarHeaders>
        <>{t('offerRequest.clientCars.header')}</>
        <ExpandCollapseButtonStyled expanded={expanded} onClick={handleToggle} />
      </CarHeaders>
      {expanded && (
        cars.result.map((car, index) =>
          <CarCard active={false} key={car.uuid} car={car} numeral={index + startCountingFrom} />,
      ))}
    </>
  )
}
