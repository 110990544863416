import { PagedResult } from 'actff-bo-lib/api'
import { Testable } from 'actff-bo-lib/global/testable'
import { TestDriveRequest } from 'actff-bo-lib/test-drive-request'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { TestDriveRequestRow } from './TestDriveRequestRow'

type Props = Testable & {
  readonly testDrives: PagedResult<TestDriveRequest>,
}

export const TestDriveRequestTable: React.FC<Props> = ({ testDrives: { result }, testId }) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
          <Tr>
            <Th>{t('table.columns.data')}</Th>
            <Th>{t('table.columns.fullName')}</Th>
            <Th>{t('table.columns.type')}</Th>
            <Th>{t('table.columns.phone')}</Th>
          </Tr>
        </thead>
        <tbody>
            {result.length && result.map((testDrive, index) =>
              <TestDriveRequestRow key={testDrive.uuid} testDrive={testDrive} testId={`${testId}request-${index}`} />,
            )}
          </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )
}
