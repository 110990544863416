import { Icon } from 'actff-bo-app/components/Icon/Icon'
import { withoutProps } from 'actff-bo-app/components/withoutProps'
import { MenuItem } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

type NavigationLinkProps = {
  readonly menuExpanded?: boolean,
  readonly menuItem: MenuItem,
}

type NavigationLinkStyledProps = {
  readonly menuExpanded: boolean,
}

const NavigationLinkStyled = styled(withoutProps(['menuExpanded'])(NavLink))`
  align-items: center;
  display: grid;
  grid-template-columns: ${({ menuExpanded }: NavigationLinkStyledProps) => menuExpanded ? '1fr 4fr' : '1fr'};
  grid-template-areas: ${({ menuExpanded }: NavigationLinkStyledProps) => menuExpanded ? '\'icon name\'' : '\'icon\''};
  grid-column-gap: 10px;
`

const NavigationLinkIconStyled = styled(Icon)`
  grid-area: icon;
`

const NavigationLinkName = styled.span`
  grid-area: name;
`

export const NavigationLink: React.FC<NavigationLinkProps> = ({ menuExpanded = true, menuItem }) => {
  const { t } = useTranslation()
  const { children, icon, omitTranslate, name, path } = menuItem
  const handleClick = (e: Event) => { children.length > 0 && e.preventDefault() }

  const getLinkName = () => menuExpanded && <NavigationLinkName>{name && omitTranslate ? name : t(name as string)}</NavigationLinkName>

  return (
    <NavigationLinkStyled
      key={path}
      menuExpanded={menuExpanded}
      onClick={handleClick}
      to={path}
    >
      {icon && <NavigationLinkIconStyled className='navigation-icon' type={icon} />}
      {getLinkName()}
    </NavigationLinkStyled>
  )
}
