import { Icon } from 'actff-bo-app/components/Icon'
import styled from 'styled-components'

export const HeaderFiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 0;
`

export const ListButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 100px auto;
  z-index: 1;
`

export const RefreshButtonIcon = styled(Icon)`
  margin-left: 10px;
`
