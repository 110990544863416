import { ContactApproval } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

import { ApprovalChanger } from './ApprovalChanger'
import { ApprovalDate } from './ApprovalDate'
import { ApprovalGroupType, ApprovalHeaderWithCurrentState } from './ApprovalHeaderWithCurrentState'
import { ApprovalState } from './ApprovalState'
import { ApprovalTime } from './ApprovalTime'

type ApprovalGroupProps = {
  readonly approvals: ReadonlyArray<ContactApproval>
  readonly approvalType?: ApprovalType,
  readonly groupType: ApprovalGroupType,
}

type IndicatorProps = {
  readonly approved: boolean,
}

export enum ApprovalType {
  Marketing = 'clientView.approvals.marketingOffers',
  Trade = 'clientView.approvals.tradeOffers',
}

const ApprovalGroupStyled = styled.div`
  border-bottom: 3px solid ${colors.solitude};
  display: grid;
  padding: 16px 0;

  &:last-child {
    border-bottom: none;
  }
`
const ApprovalContainer = styled.div`
  border-bottom: 1px solid ${colors.solitude};
  display: grid;
  grid-auto-flow: row;
  padding: 16px 0;
  page-break-inside: avoid;

  &:last-child {
    border-bottom: none;
  }
`

const ApprovalGroupSubheader = styled.span`
  color: ${colors.dustyGray};
  font-weight: 700;
  text-transform: uppercase;
  padding: 12px 0;
`

const ApprovalHeaderIndicator = styled.div`
  background: ${colors.white};
  border-radius: 100px;
  border: 2px solid;
  border-color: ${({ approved }: IndicatorProps) => approved ? colors.kumera : colors.nepalBlue};
  grid-area: indicator;
  height: 8px;
  margin-top: 5px;
  width: 8px;
`

const ApprovalHeader = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-areas: 'indicator content';
  justify-content: left;
  padding: 8px 0;
`

const ApprovalHeaderContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-area: content;
`

const ApprovalHeaderContentItem = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
`

export const ApprovalGroup: React.FC<ApprovalGroupProps> = ({ approvals, approvalType, groupType }) => {
  const { t } = useTranslation()

  return (
    <ApprovalGroupStyled>
      <ApprovalHeaderWithCurrentState approvals={approvals} type={groupType} />
      {approvalType && <ApprovalGroupSubheader>{t(approvalType)}</ApprovalGroupSubheader>}
      {approvals.map((approval, index) => (
        <ApprovalContainer key={index}>
          <ApprovalHeader>
            <ApprovalHeaderIndicator approved={approval.approved}/>
            <ApprovalHeaderContent>
              <ApprovalHeaderContentItem>
                <ApprovalDate date={approval.changeDate} />
                <ApprovalState approval={approval} />
              </ApprovalHeaderContentItem>
              <ApprovalHeaderContentItem>
                <ApprovalTime date={approval.changeDate} />
                <ApprovalChanger approval={approval} />
              </ApprovalHeaderContentItem>
            </ApprovalHeaderContent>
          </ApprovalHeader>
          <div>
            {t('clientView.approvals.content')}:
            <br />
            {approval.content}
          </div>
        </ApprovalContainer>
      ))}
    </ApprovalGroupStyled>
    )
}
