import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'

import { CrmInsuranceAction, CrmInsuranceActionType } from '../actions'
import { CrmInsuranceListActionType } from '../actions/list'
import { assignOpportunity, getOpportunities, unassignOpportunity } from '../dao'
import { OpportunityListType } from '../dto'
import {
  selectOpportunityListAssignmentFilter,
  selectOpportunityListBrandFilters,
  selectOpportunityListCurrentPage,
  selectOpportunityListDealerLocationsFilters,
  selectOpportunityListsFilters,
  selectOpportunityListTimeFrom,
  selectOpportunityListTimeTo,
  selectOpportunitySearchPhrase,
} from '../selectors'

const assignOpportunityEpic: Epic<CrmInsuranceAction, CrmInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.assignOpportunity>>(CrmInsuranceActionType.AssignOpportunity),
  switchMap(({ payload }) => assignOpportunity(payload.uuid, payload.user).pipe(
    map(opportunity => CrmInsuranceAction.assignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmInsuranceAction.assignOpportunityFailure(error))),
  ),
))

const unassignOpportunityEpic: Epic<CrmInsuranceAction, CrmInsuranceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmInsuranceAction.unassignOpportunity>>(CrmInsuranceActionType.UnassignOpportunity),
  switchMap(({ payload }) => unassignOpportunity(payload.uuid).pipe(
    map(opportunity => CrmInsuranceAction.unassignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmInsuranceAction.unassignOpportunityFailure(error))),
  ),
))

const changeFilterOrSearchPhraseEpic: Epic<CrmInsuranceAction, CrmInsuranceAction, State> = action$ => action$.pipe(
  ofType(
    CrmInsuranceListActionType.ClearListsFilters,
    CrmInsuranceListActionType.ChangeListsFilters,
    CrmInsuranceListActionType.ChangeSearchPhrase,
    CrmInsuranceListActionType.ChangeAssignmentFilter,
    CrmInsuranceListActionType.ChangeListsDealerLocationFilters,
    CrmInsuranceListActionType.ChangeListsBrandFilters,
  ),
  switchMap(() => [
    CrmInsuranceAction.resetPaginationOnLists(),
    CrmInsuranceAction.getOpportunities(OpportunityListType.future),
    CrmInsuranceAction.getOpportunities(OpportunityListType.overdue),
    CrmInsuranceAction.getOpportunities(OpportunityListType.new),
    CrmInsuranceAction.getOpportunities(OpportunityListType.all),
  ]),
)

const getOpportunitiesEpic: Epic<CrmInsuranceAction, CrmInsuranceAction, State> = (action$, state$) => action$.pipe(
  ofType<
    ReturnType<typeof CrmInsuranceAction.getOpportunities>
      | ReturnType<typeof CrmInsuranceAction.changeOpportunityListCurrentPage>
      | ReturnType<typeof CrmInsuranceAction.changeOpportunityListTimeFrom>
      | ReturnType<typeof CrmInsuranceAction.changeOpportunityListTimeTo>
  >(
    CrmInsuranceListActionType.GetOpportunities,
    CrmInsuranceListActionType.ChangeOpportunityListCurrentPage,
    CrmInsuranceListActionType.ChangeOpportunityListTimeFrom,
    CrmInsuranceListActionType.ChangeOpportunityListTimeTo,
  ),
  withLatestFrom(state$),
  mergeMap(([{ payload: { type } }, store]) => {
    const page = selectOpportunityListCurrentPage(type)(store) - 1
    const assignmentFilter = selectOpportunityListAssignmentFilter(store)
    const filters = selectOpportunityListsFilters(store)
    const from = selectOpportunityListTimeFrom(type)(store)
    const to = selectOpportunityListTimeTo(type)(store)
    const searchPhrase = encodeStringIfExists(selectOpportunitySearchPhrase(store) || undefined)
    const location = selectOpportunityListDealerLocationsFilters(store)
    const brand = selectOpportunityListBrandFilters(store)

    return getOpportunities(type, page, filters, assignmentFilter, location, brand, from, to, searchPhrase).pipe(
      map(list => CrmInsuranceAction.getOpportunitiesSuccess(type, list)),
      catchError((error: AjaxError) => of(CrmInsuranceAction.getOpportunitiesFailure(type, error))),
    )
  }),
)

export const crmOpportunityListEpic = combineEpics(
  assignOpportunityEpic,
  changeFilterOrSearchPhraseEpic,
  getOpportunitiesEpic,
  unassignOpportunityEpic,
)
