import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FilterContent } from './Styled'

const DatePickerInputStyled = styled(DatePickerInput)`
  .DayPickerInput {
    width: 100%;
  }

  input {
    width: 100%;
  }
`

type InputDateFilterProps = Testable & {
  readonly control: Control,
  readonly name: string,
  readonly caption?: string,
  readonly key?: string,
  readonly timeDisabled?: boolean,
}

export const InputDateFilter: FC<InputDateFilterProps> = ({ name, control, caption, key, testId, timeDisabled }): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <FilterContent key={key}>
      {caption && <label>{t(caption)}:</label>}
      <Controller
        render={({ onChange, value }) => (
          <DatePickerInputStyled
            date={value}
            onChange={onChange}
            testId={`${testId}-${name}`}
            timeDisabled={timeDisabled}
          />
        )}
        control={control}
        name={name}
      />
    </FilterContent>
  )
}
