import { connector } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { hasMenuAccess, isMenuPathActive, MenuAction, MenuItem, Path } from 'actff-bo-lib/menu'
import { State } from 'actff-bo-lib/store'
import { selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import * as React from 'react'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'

import { ExpandCollapse } from './ExpandCollapse'
import { NavigationItemChildren } from './NavigationItemChildren'
import { NavigationItemContainer } from './NavigationItemContainer'
import { NavigationLink } from './NavigationLink'

type NavigationItemComponentOwnProps = Testable & {
  readonly menuExpanded: boolean,
  readonly menuItem: MenuItem,
  readonly paramValue: string,
}

type StateToProps = {
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

type DispatchToProps = {
  readonly toggleNavigationItem: (path: Path) => () => void,
}

type NavigationItemComponentProps = StateToProps & DispatchToProps & NavigationItemComponentOwnProps & RouteComponentProps

const NavigationItemComponent: React.FC<NavigationItemComponentProps> = ({
  location,
  menuExpanded,
  menuItem,
  toggleNavigationItem,
  testId,
  userPermissions,
}) => hasMenuAccess(menuItem.access, userPermissions)
  ? (
    <>
      <NavigationItemContainer
        active={isMenuPathActive(location.pathname, menuItem.path)}
        key={`${menuItem.path}+${menuItem.paramValue}`}
        menuItemPath={menuItem.path}
        testId={`${testId}${menuItem.path.replace('/', '')}`}
        toggleNavigationItem={toggleNavigationItem}
      >
        <NavigationLink menuExpanded={menuExpanded} menuItem={menuItem}/>
        {menuExpanded && <ExpandCollapse menuItem={menuItem}/>}
      </NavigationItemContainer>
      {menuExpanded &&
        <NavigationItemChildren
          isSubmenuActive={isMenuPathActive(location.pathname, menuItem.path, menuItem.childrenHasMultipleTabs)}
          menuItem={menuItem}
          testId={testId}
          toggleNavigationItem={toggleNavigationItem}
        />
      }
    </>
  ) : null

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  userPermissions: selectUserPermissions(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  toggleNavigationItem: path => () => { dispatch(MenuAction.toggleMenuItem(path)) },
})

export const NavigationItem = compose(
  connector(mapStateToProps, mapDispatchToProps),
  withRouter,
)(NavigationItemComponent)
