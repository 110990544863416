import { BtnTheme, MinorButton } from 'actff-bo-app/components/Button'
import { FooterStyled } from 'actff-bo-app/components/FixedFooter'
import { Testable } from 'actff-bo-lib/global/testable'
import { selectMenuExpanded } from 'actff-bo-lib/menu'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { PrimaryButton } from 'styles'

type Props = Testable & {
  readonly isSaveDisabled?: boolean,
  readonly onAddClick?: () => void,
  readonly onAddTLabel?: string,
  readonly onSave?: (contentToSave?: unknown) => void,
}

const FixedFooterStyled = styled(FooterStyled)`
  display: grid;
  justify-content: right;
  grid-column-gap: 15px;
`

const ButtonStyled = styled(MinorButton)`
  width: 150px;
  padding: 5px 5px;
`

const PrimaryButtonStyled = styled(PrimaryButton)`
  width: 150px;
  padding: 5px 5px;
`

export const Footer: FC<Props> = ({ isSaveDisabled = false, onAddClick, onAddTLabel, onSave, testId }) => {
  const { t } = useTranslation()
  const menuExpanded = useSelector(selectMenuExpanded)

  return (
    <FixedFooterStyled menuExpanded={menuExpanded}>
      {onAddClick && (
        <ButtonStyled
          caption={t(onAddTLabel ?? 'caption.add')}
          theme={BtnTheme.Minor}
          onClick={onAddClick}
          testId={`${testId}add`}
        />
      )}
      {onSave && (
        <PrimaryButtonStyled
          type='button'
          disabled={isSaveDisabled}
          theme={BtnTheme.Primary}
          onClick={onSave}
          testId={`${testId}on-save`}
        >
          {t('caption.save')}
        </PrimaryButtonStyled>
      )}
    </FixedFooterStyled>
  )
}
