export type ISODateString = string

export type DateRange = {
  readonly from: Date | null,
  readonly to: Date | null,
}

export const parseToDateOrNull = (date: Date | string | null | undefined) => date ? new Date(date) : null

export const defaultTimeInterval = 15

export enum DatePickerInputPosition {
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
}
