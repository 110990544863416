import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly header: string,
  readonly expanded?: boolean,
}

const Container = styled.div`
  background: ${colors.athensGray};
  border: 1px solid ${colors.mystic};
  display: grid;
  grid-auto-flow: column;
  justify-items: stretch;
  padding: 12px 32px;
`

const Header = styled.span`
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
`

const ExpandCollapseButtonContainer = styled.span`
  justify-self: right;
`

export const WithExpandableHeader: FC<Props> = ({ expanded = false, children, header, testId }) => {
  const [isExpanded, setExpanded] = useState(expanded)
  const { t } = useTranslation()
  const toogleExpanded = () => setExpanded(!isExpanded)

  return (
    <>
      <Container data-testid={`${testId}container`}>
        <Header data-testid={`${testId}header`}>{t(header)}</Header>
        <ExpandCollapseButtonContainer>
          <ExpandCollapseButton expanded={isExpanded} testId={`${testId}`} onClick={toogleExpanded} />
        </ExpandCollapseButtonContainer>
      </Container>
      {isExpanded && children}
    </>
  )
}
