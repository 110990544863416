import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type EditSaveActionButtonProps = Testable & {
  readonly onEdit: (event?: React.MouseEvent<HTMLButtonElement>) => void,
  readonly onSave: () => void,
  readonly isInEditMode: boolean,
  readonly disabled?: boolean,
}

export const EditSaveActionButton: React.FC<EditSaveActionButtonProps> = ({
  disabled,
  isInEditMode,
  onEdit,
  onSave,
  testId,
}) => {
  const { t } = useTranslation()

  return (
    isInEditMode
      ? (
        <ButtonWithIcon
          caption={t('caption.save')}
          disabled={disabled}
          onClick={onSave}
          iconType={IconType.Accept}
          theme={BtnTheme.Link}
          type={BtnType.Submit}
          testId={`${testId}--save`}
        />
      )
      : (
        <ButtonWithIcon
          caption={t('caption.edit')}
          onClick={onEdit}
          iconType={IconType.Edit}
          theme={BtnTheme.Link}
          type={BtnType.Button}
          testId={`${testId}--edit`}
        />
      )
  )
}
