import { CalendarResourceType } from 'actff-bo-lib/admin'
import styled from 'styled-components'
import { colors } from 'styles'

export const Scheduler = styled.div`
  .fc-header-toolbar {
    background: transparent;
    margin-bottom: 0;
    margin-top: -100px;
    padding: 20px 15px;
  }

  .fc-resource-timeline {
    background: ${colors.white};
  }

  .fc-toolbar-title {
    display: inline-block;
    font-size: 16px;
  }

  .fc-timeline-header-row, .fc-day-sun {
    background-color: ${colors.whisperBlue};
  }

  .fc-prev-button, .fc-next-button {
    background: transparent;
    border: 0;
    color: ${colors.kumera};
    padding-top: 0;

    &:hover,
    &:active {
      background: transparent;
      color: ${colors.kumera};
    }
  }

  .fc-resourceTimelineMonth-button,
  .fc-resourceWeekWithoutTime-button,
  .fc-resourceTimelineDay-button {
    background: transparent;
    color: ${colors.black};
    padding: 5px 15px;
    border-radius: 0;
    border-color: ${colors.midnightBlue};

    &:hover,
    &:not(:disabled).fc-button-active {
      background: ${colors.midnightBlue};
    }
  }

  .fc-resourceWeekWithoutTime-button {
    border-left: 0;
    border-right: 0;
  }

  .fc-resourceTimelineDay-button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-left: 50px;
  }

  .fc-resourceTimelineMonth-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .fc-timeline-lane,
  .fc-resource,
  .fc-resource .fc-datagrid-cell-frame {
    // override automatically calculated cell height
    height: 80px !important;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  ${() =>
  Object.keys(CalendarResourceType).map(resourceKey =>
    `[data-resource-id="${resourceKey}"].fc-resource {
        background-color: ${colors.athensGray};
      }`,
  ).reduce((prev, curr) => (`${prev} ${curr}`))}

  .fc-timeline-event-harness {
    padding: 5px;
  }

  .fc-timeline-event {
    height: 70px;
    border-radius: 5px;
    position: relative;
    top: 5px;
    cursor: pointer;
  }

  .fc-resourceWeekWithoutTime-view,
  .fc-resourceTimelineDay-view {
    .fc-event-main {
      position: absolute;
      top: 10px;
      left: 10px;
      height: 100%;
      width: 100%;
    }
  }
`
