import { Tabs } from 'actff-bo-app/Navigation'
import { Testable } from 'actff-bo-lib/global/testable'
import { Path, Paths, TabMenuItem } from 'actff-bo-lib/menu'
import i18next from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { H2 } from 'styles'

import { BrandsDropdown } from '../DealershipDropdown'
import { ViewTabsHeader } from '../Styled'

type ViewTabsProps = Testable

const getTabItems = (t: i18next.TFunction, testId?: string): ReadonlyArray<TabMenuItem> => ([
  {
    name: t('admin.brands.carModels'),
    path: Paths.AdminCarModels as Path,
    testId: `${testId}tabs--car-models`,
  },
  {
    name: t('admin.brands.carVersions'),
    path: Paths.AdminCarVersions as Path,
    testId: `${testId}tabs--car-versions`,
  },
  {
    name: t('admin.brands.brakeLiquids'),
    path: Paths.AdminBrakeLiquids as Path,
    testId: `${testId}tabs--brake-liquids`,
  },
  {
    name: t('admin.brands.oilsAndEngines'),
    path: Paths.AdminEngines as Path,
    testId: `${testId}tabs--engines`,
  },
  {
    name: t('admin.brands.assistance'),
    path: Paths.AdminAssistance as Path,
    testId: `${testId}tabs--assistance`,
  },
])

export const ViewTabs: React.FC<ViewTabsProps> = ({ testId }) => {
  const { t } = useTranslation()

  return (
    <>
      <ViewTabsHeader>
        <div><H2>{t('admin.brands')}</H2></div>
        <BrandsDropdown testId={testId} />
      </ViewTabsHeader>
      <Tabs tabItems={getTabItems(t, testId)} />
    </>
  )
}
