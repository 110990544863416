import { NewCarAction, NewCarActionType } from '../actions'
import { NewCarState } from '../state'

export const newCarInitialState: NewCarState = {
  selectedClient: {
    data: null,
    loading: false,
  },
}
export const newCarReducer = (state: NewCarState = newCarInitialState, action: NewCarAction): NewCarState => {
  switch (action.type) {
    case NewCarActionType.GetSelectedClient:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          loading: true,
        },
      }

    case NewCarActionType.GetSelectedClientSuccess:
      return {
        ...state,
        selectedClient: {
          data: action.payload,
          loading: false,
        },
      }

    case NewCarActionType.GetSelectedClientFailure:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          loading: false,
        },
      }

    default:
      return state
  }
}
