import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { defaultShortDateFormat } from 'actff-bo-lib/date'
import * as React from 'react'
import styled from 'styled-components'

const FormattedDateStyled = styled.span`
  font-size: 13px;
  font-weight: 600;
`

type ApprovalDateProps = {
  readonly date: Date,
}

export const ApprovalDate: React.FC<ApprovalDateProps> = ({ date }) => (
  <FormattedDateStyled><FormattedDate date={date} format={defaultShortDateFormat} />:</FormattedDateStyled>
)
