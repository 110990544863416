import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

export const selectTelephoneRequestFeature = (state: State) => state.telephoneRequest

export const selectTelephoneRequests = createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.list.data)

export const selectIsLoadingTelephoneRequests =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.list.loading)

export const selectNewTelephoneRequestsCount =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.newTelephoneRequestCount)

export const selectTelephoneRequestCurrentPage =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.currentPage)

export const selectCurrentTelephoneRequest =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.currentTelephoneRequest)

export const selectCurrentTelephoneRequestCars =
  createSelector(selectTelephoneRequestFeature, selectCurrentTelephoneRequest,
      (telephoneRequestFeature, currentTelephoneRequest) => telephoneRequestFeature.currentTelephoneRequestCars && currentTelephoneRequest
        ? ({
          ...telephoneRequestFeature.currentTelephoneRequestCars,
          result: telephoneRequestFeature.currentTelephoneRequestCars.result.filter(car => car.uuid !== currentTelephoneRequest.car.uuid),
        })
        : null,
      )

export const selectTelephoneRequestListFilters =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.listFilters)

export const selectTelephoneRequestSearchPhrase =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.list.searchPhrase)

export const selectTelephoneRequestDealerLocationFilters =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.list.locationFilters)

export const selectTelephoneRequestBrandFilters =
  createSelector(selectTelephoneRequestFeature, telephoneRequest => telephoneRequest.list.brandFilters)
