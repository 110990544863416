import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

import { OpportunityListType } from './dto'

const selectOpportunityFeature = (state: State) => state.crmInsurance.opportunity
const selectOpportunityListFeature = (state: State) => state.crmInsurance.list
const selectOpportunityInsurer = (state: State) => state.crmInsurance.insurer
const selectOpportunityReport = (state: State) => state.crmInsurance.report

export const selectOpportunityList = (type: OpportunityListType) =>
  createSelector(selectOpportunityListFeature, opportunity => opportunity[type])

export const selectOpportunityListCurrentPage = (type: OpportunityListType) =>
  createSelector(selectOpportunityList(type), list => list.currentPage)
export const selectOpportunityListExpandedState = (type: OpportunityListType) =>
  createSelector(selectOpportunityList(type), list => list.expanded)
export const selectOpportunityListTimeFrom = (type: OpportunityListType) => createSelector(selectOpportunityList(type), list => list.from)
export const selectOpportunityListTimeTo = (type: OpportunityListType) => createSelector(selectOpportunityList(type), list => list.to)
export const selectOpportunityListData = (type: OpportunityListType) =>
  createSelector(selectOpportunityList(type), list => list.list.data)
export const selectOpportunityListLoading = (type: OpportunityListType) =>
  createSelector(selectOpportunityList(type), list => list.list.loading)

export const selectCurrentOpportunity = createSelector(selectOpportunityFeature, opportunity => opportunity.currentOpportunity)

export const selectCurrentOpportunityErrors = createSelector(selectOpportunityFeature, opportunity => opportunity.currentOpportunityErrors)

export const selectCurrentOpportunityViewer = createSelector(
  selectOpportunityFeature,
  opportunity => opportunity.currentOpportunityViewers && opportunity.currentOpportunityViewers[0],
)

export const selectOpportunitySearchPhrase = createSelector(selectOpportunityListFeature, list => list.searchPhrase)

export const selectOpportunityListsFilters = createSelector(selectOpportunityListFeature, list => list.filters)

export const selectOpportunityListsFiltersCount = createSelector(selectOpportunityListFeature, list =>
  Object.entries(list.filters).reduce((prev, [, value]) => {
  const count = value instanceof Array
    ? value.length
    : (value ? 1 : 0)

  return prev + count
  }, 0))

export const selectOpportunityListAssignmentFilter = createSelector(selectOpportunityListFeature, list => list.assignmentFilter)

export const selectBankAccountNumbersForInsurer = createSelector(selectOpportunityInsurer, insurer => insurer.bankAccountsForInsurer)

export const selectSelectedAsProcessedUuid = createSelector(selectOpportunityListFeature, list => list.selectedAsProcessedUuid)

export const selectReport = createSelector(selectOpportunityReport, report => report.report)

export const selectOpportunityListDealerLocationsFilters =
  createSelector(selectOpportunityListFeature, list => list.dealerLocationFilters)

export const selectOpportunityListBrandFilters =
  createSelector(selectOpportunityListFeature, list => list.dealerBrandFilters)
