import { CarGuarantee, CarService } from 'actff-bo-lib/car'

export const carGuaranteeInitialValues: CarGuarantee = {
  basicGuaranteeExpirationDate: null,
  dealerGuaranteeExpirationDate: null,
  dealerGuaranteeVisible: false,
  extendedGuaranteeExpirationDate: null,
}

export const carServiceDetailsInitialValues: CarService = {
  guarantee: {
    basicGuaranteeExpirationDate: null,
    dealerGuaranteeExpirationDate: null,
    dealerGuaranteeVisible: false,
    extendedGuaranteeExpirationDate: null,
  },
  lastServiceDate: null,
  liquids: {
    brakeLiquid: '',
    engineOil: '',
  },
  mot: null,
}
