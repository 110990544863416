import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectCarFeature = (state: State) => state.car.car

const selectCarList = createSelector(selectCarFeature, car => car.list)

export const selectCurrentPage =
  createSelector(selectCarFeature, car => car.currentPage)

export const selectCarsNoOfPages =
  createSelector(selectCarList, list => list.data?.noOfPages)

export const selectCurrentCar =
  createSelector(selectCarFeature, car => car.currentCar)

export const selectCurrentCarData =
  createSelector(selectCarFeature, car => car.currentCar.data)

export const selectCurrentCarVinExists =
  createSelector(selectCarFeature, car => car.currentCarVinExists)

export const selectCurrentCarRegistrationNumberExists =
  createSelector(selectCarFeature, car => car.currentCarRegistrationNumberExists)

export const selectCurrentCarEngineCode =
  createSelector(selectCarFeature, car => car.currentCar.data && car.currentCar.data.engineCode)

export const selectCurrentCarAttachmentList =
  createSelector(selectCarFeature, car => car.currentCar.data && car.currentCar.data.attachmentList)

export const selectIsLoadingCar =
  createSelector(selectCarFeature, car => car.currentCar.loading)

export const selectCurrentCarAttachments =
  createSelector(selectCarFeature, car => car.currentCarAttachments.data)

export const selectIsLoadingCarAttachments =
  createSelector(selectCarFeature, car => car.currentCarAttachments.loading)

export const selectIsNdcCar =
  createSelector(selectCarFeature, car => car.isNdcCar)

export const selectCars =
  createSelector(selectCarList, list => list.data)

export const selectIsLoadingCars =
  createSelector(selectCarList, list => list.loading)

export const selectNewCarsCount =
  createSelector(selectCarFeature, car => car.newCarsCount)

export const selectCarListFilters =
  createSelector(selectCarList, list => list.filters)

export const selectCarListSearchPhrase =
  createSelector(selectCarList, list => list.searchPhrase)

export const selectCarListDealerLocationFilters =
  createSelector(selectCarList, list => list.locationFilters)

export const selectCarListDealerBrandFilters =
  createSelector(selectCarList, list => list.brandFilters)
