import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { Table as TableStyled, theme } from 'styles'

const { table } = theme

export const Form = styled.form`
  margin-bottom: 30px;
  margin-top: 20px;
`

export const Header = styled(FormCell)`
  grid-template-columns: 1fr 200px;
  height: 63px;
`

export const CarData = styled.div`
  display: grid;
  grid-column-gap: 150px;
  grid-template-columns: 1fr 1fr 1fr;
  padding-right: 50px;
`

export const NoData = styled.span`
  margin: 24px;
`

export const Table = styled(TableStyled)`
  border: none;
`

export const Tbody = styled.tbody`
  &:last-child {
    border-bottom: none;
  }
`

export const Th = styled.th`
  padding: 0;
`

export const Td = styled.td`
  border-bottom: 1px solid ${table.item.border};
  border-right: 1px solid ${table.item.border};
  margin: 0;
  padding: 0;
`
