import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { colors } from 'styles'
import { OpportunityStatusContainer as OpportunityStatusContainerStyled } from '../Styled'

type ExpandableProps = {
  readonly expanded: boolean,
  readonly expandable?: boolean,
}

type QuickNotificationsButtonProps = {
  readonly disabled: boolean,
}

export const OpportunityContent = styled.div`
  align-content: start;
  display: grid;
  grid-template-areas:
    'status-action-data'
    'additional-information'
    'owner'
    'preferences'
    'car-header'
    'car-data'
    'car-value'
    'rest-owner-cars'
    'matched-opportunities'
    'events-history'
    'internal-comments'
    'add-internal-comment'
  ;
`

export const OpportunityStatusContainer = styled(OpportunityStatusContainerStyled)`
  padding: 18px 0 0;
`

export const ButtonWithIconStyled = styled(ButtonWithIcon)`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  margin-right: 20px;
  padding: 5px 20px;
  width: 190px;
`

export const QuickNotificationsButton = styled(ButtonWithIconStyled)<QuickNotificationsButtonProps>`
  border-color: ${colors.dustyGray};
  border-right: 1px solid;
  margin-right: 0;
  padding: 5px 37px;

  span {
    ${({ disabled }: QuickNotificationsButtonProps) => disabled && `color: ${colors.dustyGray}`}
  }
`

export const StatusWithActionData = styled(FormCell)`
  display: grid;
  grid-area: status-action-data;
  grid-template-columns: 350px 1fr 100px;
`

export const ActionData = styled(FormCell)`
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr;
  padding-left: 0;
`

export const OpportunityDataFlag = styled.div`
  bottom: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  justify-content: left;
  position: relative;
`

export const HeaderContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  justify-content: left;
`

export const Expandable = styled.div`
  background: ${colors.mysticGray};
  cursor: ${({ expandable }: ExpandableProps) => expandable ? 'pointer' : 'default'};
  display: grid;
  font-weight: 600;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;;
  margin: 32px 32px 0;
  padding: 16px 24px;
`

export const ExpandableContent = styled.div`
  display: ${({ expanded }: ExpandableProps) => expanded ? 'block' : 'none'};
  margin: 0 32px;
`

export const OpportunityHeaderValue = styled.label`
  color: ${colors.black};
  font-weight: 600;
  text-transform: none;
`
