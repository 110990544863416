import { CarEquipmentAction, CarEquipmentActionType } from 'actff-bo-lib/car/actions'
import {
  getCarEquipmentData,
  getCarEquipmentItems,
  getCarEquipmentItemsEdit,
  updateCarEquipmentData,
  updateCarEquipmentItems,
} from 'actff-bo-lib/car/dao'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'

const getCarEquipmentDataEpic: Epic<CarEquipmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarEquipmentAction.getCarEquipmentData>>(CarEquipmentActionType.GetCarEquipmentData),
  switchMap(({ payload }) => getCarEquipmentData(payload).pipe(
    map(CarEquipmentAction.getCarEquipmentDataSuccess),
    catchError((error: AjaxError) => of(CarEquipmentAction.getCarEquipmentDataFailure(error))),
  )),
)

const getCarEquipmentItemsEpic: Epic<CarEquipmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarEquipmentAction.getCarEquipmentItems>>(CarEquipmentActionType.GetCarEquipmentItems),
  switchMap(({ payload }) => getCarEquipmentItems(payload).pipe(
    map(CarEquipmentAction.getCarEquipmentItemsSuccess),
    catchError((error: AjaxError) => of(CarEquipmentAction.getCarEquipmentItemsFailure(error))),
  )),
)

const getCarEquipmentItemsEditEpic: Epic<CarEquipmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarEquipmentAction.getCarEquipmentItemsEdit>>(CarEquipmentActionType.GetCarEquipmentItemsEdit),
  switchMap(({ payload }) => getCarEquipmentItemsEdit(payload).pipe(
    map(CarEquipmentAction.getCarEquipmentItemsEditSuccess),
    catchError((error: AjaxError) => of(CarEquipmentAction.getCarEquipmentItemsEditFailure(error))),
  )),
)

const updateCarEquipmentDataEpic: Epic<CarEquipmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarEquipmentAction.updateCarEquipmentData>>(CarEquipmentActionType.UpdateCarEquipmentData),
  switchMap(({ payload }) => updateCarEquipmentData(payload.data, payload.vin).pipe(
    map(CarEquipmentAction.updateCarEquipmentDataSuccess),
    catchError((error: AjaxError) => of(CarEquipmentAction.updateCarEquipmentDataFailure(error)),
  )),
))

const updateCarEquipmentItemsEpic: Epic<CarEquipmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarEquipmentAction.updateCarEquipmentItems>>(CarEquipmentActionType.UpdateCarEquipmentItems),
  switchMap(({ payload }) => updateCarEquipmentItems(payload.items, payload.vin).pipe(
    map(CarEquipmentAction.updateCarEquipmentItemsSuccess),
    catchError((error: AjaxError) => of(CarEquipmentAction.updateCarEquipmentItemsFailure(error)),
  )),
))

const notifyOnUpdateCarEquipmentSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<
    typeof CarEquipmentAction.updateCarEquipmentDataSuccess
    | typeof CarEquipmentAction.updateCarEquipmentItemsSuccess
    >>(
    CarEquipmentActionType.UpdateCarEquipmentDataSuccess,
    CarEquipmentActionType.UpdateCarEquipmentItemsSuccess,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: 'toast.changesSaved',
      id: 'toast.success',
      title: 'toast.success',
      type: ToastType.Success,
    }),
  ]),
)

const notifyOnUpdateCarEquipmentFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<
    typeof CarEquipmentAction.updateCarEquipmentDataFailure
    | typeof CarEquipmentAction.updateCarEquipmentItemsFailure
    >>(
    CarEquipmentActionType.UpdateCarEquipmentDataFailure,
    CarEquipmentActionType.UpdateCarEquipmentItemsFailure,
  ),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'carView.form.equipment.update.failure',
      title: 'carView.form.equipment.update.failure',
      type: ToastType.Error,
  })],
))

export const carEquipmentEpic = combineEpics(
  getCarEquipmentDataEpic,
  getCarEquipmentItemsEpic,
  getCarEquipmentItemsEditEpic,
  notifyOnUpdateCarEquipmentFailureEpic,
  notifyOnUpdateCarEquipmentSuccessEpic,
  updateCarEquipmentDataEpic,
  updateCarEquipmentItemsEpic,
)
