import { BtnType } from 'actff-bo-app/components/Button'
import {
  DatePickerInputField,
  Field,
  FormFooter,
  FormFooterLeft,
  FormFooterRight,
  FormHeader,
  SelectField,
} from 'actff-bo-app/components/Form'
import { Loader } from 'actff-bo-app/components/Loader'
import { ValidationError } from 'actff-bo-app/components/ValidationError'
import { OpportunityViewCar } from 'actff-bo-app/Crm/Service/OpportunityCar'
import { OpportunityViewClient } from 'actff-bo-app/Crm/Service/OpportunityClient'
import { OpportunityViewService } from 'actff-bo-app/Crm/Service/OpportunityView/OpportunityService'

import { Content, OpportunityHistory, TextareaStyled } from 'actff-bo-app/Crm/Styled'
import { Lead, OpportunityManualDtoForm, OpportunityManualPayload } from 'actff-bo-lib/crm/service'
import { dashDateFormat, formatDate } from 'actff-bo-lib/date'
import { Paths } from 'actff-bo-lib/menu/initial-menu'
import { history } from 'actff-bo-lib/router'
import { getServicesSelectElements, Service } from 'actff-bo-lib/service'
import { User } from 'actff-bo-lib/user/dto'
import { Form, FormikProps, withFormik } from 'formik'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { LinkButton, PrimaryButton } from 'styles'
import { date, object, string } from 'yup'
import {
  DateFieldContainer,
  DateFieldsContainer,
  Description,
  FormCellStyled,
  ManualOpportunityContent,
  OpportunityForm,
  ServiceStyled,
} from './Styled'

type OwnProps = {
  readonly availableServices: ReadonlyArray<Service>,
  readonly currentLead: Lead | null,
  readonly createOpportunity: (payload: OpportunityManualPayload) => void,
  readonly me: User,
  readonly testId: string,
}

type Props = OwnProps & FormikProps<OpportunityManualDtoForm> & WithTranslation

class OpportunityManualFormComponent extends React.Component<Props> {

  public render(): React.ReactElement {
    const {
      errors,
      availableServices,
      currentLead,
      handleSubmit,
      values,
      touched,
      testId,
      t,
    } = this.props

    if (!currentLead) {
      return <Loader/>
    }

    return (
      <Form>
        <Content>
          <ManualOpportunityContent>
            <OpportunityForm>
              <DateFieldsContainer>
                <FormHeader>
                  {t('opportunity.manual.header')}
                </FormHeader>
                <DateFieldContainer>
                  <label>{t('opportunity.manual.addDate')}:</label>
                  <Field
                    component={DatePickerInputField}
                    dateTime={values.startDate}
                    error={errors.startDate}
                    id='startDate'
                    name='startDate'
                    testId={`${testId}start-date`}
                    touched={touched.startDate}
                  />
                  <ValidationError name='startDate' testId={`${testId}start-date--error`}/>
                </DateFieldContainer>
              </DateFieldsContainer>

              <DateFieldsContainer>
                <FormHeader>
                  {t('opportunity.manual.opportunityData')}
                </FormHeader>
                <ServiceStyled>
                  <FormCellStyled>
                    <Field
                      component={SelectField}
                      error={errors.serviceKey}
                      id='serviceKey'
                      label={`${t('caption.service')}:`}
                      name='serviceKey'
                      options={getServicesSelectElements(availableServices, t, true)}
                      testId={`${testId}service-key`}
                      touched={touched.serviceKey}
                    />
                    <ValidationError name='serviceKey' testId={`${testId}service-key--error`}/>
                  </FormCellStyled>
                </ServiceStyled>
                <Description>
                  <label>{t('opportunity.manual.description')}:</label>
                  <Field
                    component={TextareaStyled}
                    data-testid={`${testId}description`}
                    touched={touched.description}
                    error={errors.description}
                    name='description'
                    value={values.description || ''}
                  />
                </Description>
              </DateFieldsContainer>
            </OpportunityForm>

            <OpportunityViewClient client={currentLead.client}/>
            <OpportunityViewCar car={currentLead.car}/>
            <OpportunityViewService
              lastServiceDate={currentLead.lastServiceDate}
              nextServiceDate={currentLead.nextServiceDate}
              i18Key='manual'
            />
          </ManualOpportunityContent>

          <OpportunityHistory>
            <FormHeader>
              {t('opportunity.incomingOpportunities')}
            </FormHeader>
            Wkrótce…
            <FormHeader>
              {t('opportunity.serviceHistory')}
            </FormHeader>
            Wkrótce…
          </OpportunityHistory>
        </Content>

        <FormFooter>
          <FormFooterLeft>
            <LinkButton
              onClick={this.handleGoBackClick}
              type={BtnType.Button}
              noPadding={true}
            >
              {t('caption.back')}
            </LinkButton>
          </FormFooterLeft>
          <FormFooterRight>
            <LinkButton
              type={BtnType.Button}
              onClick={this.handleGoBackClick}
              data-testid={`${testId}cancel`}
            >
              {t('caption.cancel')}
            </LinkButton>
            <PrimaryButton
              disabled={this.isSubmitButtonDisabled()}
              onClick={handleSubmit}
              type={BtnType.Submit}
            >
              {t('opportunity.create')}
            </PrimaryButton>
          </FormFooterRight>
        </FormFooter>
      </Form>
    )
  }

  private readonly isSubmitButtonDisabled = (): boolean => {
    const { isValid, dirty, values } = this.props

    return !isValid || !dirty || !values.startDate
  }

  private readonly handleGoBackClick = () => {
    history.push(Paths.CarList)
  }
}

const validationSchema = () => object<OpportunityManualDtoForm>().shape({
  dateTime: date(),
  description: string(),
  serviceKey: string().required(),
})

const formik = withFormik<OwnProps, OpportunityManualDtoForm>({
  handleSubmit: ({ description, startDate, serviceKey }, { props }) => {
    props.createOpportunity({
      opportunity: {
        author: props.me,
        description,
        serviceKey,
        startDate: formatDate(dashDateFormat)(startDate),
      },
      vin: props.currentLead?.car.vin as string,
    })
  },
  validateOnMount: true,
  validationSchema,
})

export const OpportunityManualForm = compose(
  formik,
  withTranslation(),
)(OpportunityManualFormComponent)
