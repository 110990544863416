import { AppConfig, getConfig } from 'actff-bo-lib/api'
import { UserId } from 'actff-bo-lib/user'
import * as LogRocket from 'logrocket'
import * as setupLogrocketReact from 'logrocket-react'
import { compose } from 'redux'
import { filterBodyOnRequestUrlThatContainString, filterRequestAuthHeaders, filterResponseToken } from './logrocket'

export const getLogrocketId = () => getConfig(AppConfig.LOGROCKET_ID)

export const initLogrocket = (logrocketId: string) => {
  // @ts-ignore
  if (LogRocket._isInitialized) {
    return
  }

  LogRocket.init(
    logrocketId,
    {
      // tslint:disable no-object-mutation no-string-literal no-let
      network: {
        requestSanitizer: compose(filterRequestAuthHeaders, filterBodyOnRequestUrlThatContainString('auth')),
        responseSanitizer: compose(filterResponseToken),
      },
    },
  )
  try {
    setupLogrocketReact(LogRocket)
  } catch (e) {
    return
  }
}
export const identifyLogrocket = (userUuid: UserId) => LogRocket.identify(userUuid)
