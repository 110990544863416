import { NominalString } from 'actff-bo-lib/global'
import { UserPermissions } from 'actff-bo-lib/user'

import { ComponentType } from './component-type'
import { IconType } from './icon-type'
import { WrapperProps, WrapperType } from './wrapper'

export type Path = NominalString<MenuItem>

export enum MenuItemParamName {
  DealerLocationKey = 'dealerLocationKey',
}

export type MenuItem = {
  readonly access: ReadonlyArray<UserPermissions>,
  readonly children: ReadonlyArray<MenuItem>,
  readonly component: ComponentType,
  readonly expanded: boolean,
  readonly path: Path,
  readonly childrenHasMultipleTabs?: boolean,
  readonly wrappers: ReadonlyArray<WrapperType>,
  readonly paramName?: MenuItemParamName,
  readonly paramValue?: string,
  readonly hiddenInMenu?: boolean,
  readonly icon?: IconType,
  readonly name?: string,
  readonly omitTranslate?: boolean,
  readonly wrappersProps?: WrapperProps,
}
