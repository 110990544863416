import { Filter, Filter as FilterType, Filters as FiltersType, isString } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { hasPermission, selectUserPermissions } from 'actff-bo-lib/user'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { colors } from 'styles'

// tslint:disable no-any
type Props<T> = Testable & {
  readonly filters: FiltersType<T>,
  readonly onFilterChange: (param: string, value: T | ReadonlyArray<T>) => void,
}

const FiltersContainer = styled.div`
  display: inline-block;
`

const StyledFilter = styled.button`
  background: ${({ selected }: FilterType<any>) => selected
    ? `${colors.straw}`
    : `${colors.white}`
  };
  border: ${({ selected }: FilterType<any>) => selected
    ? '1.5px solid transparent'
    : `1.5px solid ${colors.swirlGold}`
  };
  border-radius: 3px;
  font-size: 12px;
  margin: 6px 12px 6px 0;
  min-height: 38px;
  padding: 0 12px;
`

const FilterIndicator = styled.span`
  background: ${({ selected }: FilterType<any>) => selected
    ? `${colors.white}`
    : `${colors.athsSpecial}`
  };
  border-radius: 1px;
  display: inline-block;
  margin: 5px;
  padding: 6px;
`

export function Filters<T>({ filters, onFilterChange, testId }: Props<T>): React.ReactElement {
  const handleOnFilterChange = (param: string, value: T | ReadonlyArray<T>) => () => onFilterChange(param, value)
  const { t } = useTranslation()
  const userPermissions = useSelector(selectUserPermissions)
  const getDataTestId = (filter: Filter<T>) => isString(filter.value && filter.value).toString().toLowerCase()

  return (
    <FiltersContainer data-testid={`${testId}filter-container`}>
      {Object.entries(filters).map(([key, value]) => value.map(filter =>
        hasPermission(filter.requiredPermissions || [])(userPermissions) && (
          <StyledFilter
            data-testid={`${testId}filter-${key}--${getDataTestId(filter)}`}
            key={`${key}, ${filter.value}`}
            onClick={handleOnFilterChange(key, filter.value)}
            selected={filter.selected}
          >
            {t(filter.caption)}
            {filter.objectsCount && <FilterIndicator selected={filter.selected}>{filter.objectsCount}</FilterIndicator>}
          </StyledFilter>
        ),
      ))}
    </FiltersContainer>
  )
}
