import { EditableList } from 'actff-bo-app/components/EditableList/EditableList'
import { DictionaryType } from 'actff-bo-lib/dictionary'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { H2 } from 'styles'

import { Container, Header } from '../../Styled'
import { withAdminViewHeader } from '../../withAdminViewHeader'
import { ViewTabs } from '../ViewTabs'

const testId = 'admin-view-tester-vida__'

// TODO: replace with values fecthed from BE
const mockTesterVIDAQuestions: ReadonlyArray<DictionaryType<string>> = [
  {
    label: 'Pobrać oprogramowanie Volvo SELEKT',
    value: 'Download',
  },
  {
    label: 'Należy podłączyć do VIDA i wprowadzić kody objawów zidentyfikowanych w czasie testu drogowego',
    value: 'First step',
  },
  {
    label: 'Odczytać kody błędów i usunąć przyczynę zgodnie z instrukcją',
    value: 'Last step',
  },
]

const AdminTesterVIDAComponent: FC = () => {
  const { t } = useTranslation()

  const handleAdd = () => {
    // TODO: handle add new item
  }

  const handleDelete = () => {
    // TODO: handle delete new item
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.technicalCondition.vida.header')}</H2></Header>
        <EditableList
          tableHeaderCaptionTKey={'table.columns.content'}
          addButtonCaptionTKey={'admin.technicalCondition.vida.addVidaQuestion'}
          items={mockTesterVIDAQuestions}
          onAdd={handleAdd}
          onDelete={handleDelete}
        />
      </Container>
    </>
  )
}

export const AdminTesterVIDA = withAdminViewHeader(AdminTesterVIDAComponent)
