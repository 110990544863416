import { Filters } from 'actff-bo-app/components/Filters'
import { HeaderLeft } from 'actff-bo-app/components/Grid'
import { ListBrandFiltersForm } from 'actff-bo-app/components/ListFilters/ListBrandFilters'
import { ListFilters } from 'actff-bo-app/components/ListFilters/ListFilters'
import { ListLocationsFiltersForm } from 'actff-bo-app/components/ListFilters/ListLocationsFilters'
import { Loader } from 'actff-bo-app/components/Loader'
import { Search } from 'actff-bo-app/components/Search'
import { NoItems } from 'actff-bo-app/components/Table/NoItems'
import { WithPagination } from 'actff-bo-app/components/Table/Pagination'
import { PagedResult } from 'actff-bo-lib/api'
import {
  CarAction,
  CarFilterValues,
  CarInfoWithClient,
  selectCarListDealerBrandFilters,
  selectCarListDealerLocationFilters,
  selectCarListFilters,
  selectCarListSearchPhrase,
  selectCars,
  selectCurrentPage,
  selectIsLoadingCars,
  selectNewCarsCount,
} from 'actff-bo-lib/car'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { Filters as FiltersType } from 'actff-bo-lib/global'
import { Path, Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State as GlobalState } from 'actff-bo-lib/store'
import { selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import styled from 'styled-components'
import { H2, HeaderList, Small } from 'styles'

import { CarTable } from './CarTable'

type State = {
  readonly filtersExpanded: boolean,
}

type StateToProps = {
  readonly brandFilters: ReadonlyArray<string>,
  readonly carListFilters: FiltersType<CarFilterValues>,
  readonly cars: PagedResult<CarInfoWithClient> | null,
  readonly currentPage: number,
  readonly dealerLocationFilters: ReadonlyArray<DealerLocationKey>,
  readonly loading: boolean,
  readonly newCarsCount: number,
  readonly permissions: ReadonlyArray<UserPermissions>,
  readonly searchPhrase?: string,
}

type DispatchToProps = {
  readonly getCars: () => void,
  readonly getNewCarsCount: () => void,
  readonly onPageChange: (nextPage: number) => void,
  readonly onListFilterChange: (param: string, value: CarFilterValues) => void,
  readonly onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => void,
  readonly onBrandChange: (brands: ReadonlyArray<string>) => void,
  readonly onSearchPhraseChange: (searchPhrase?: string) => void,
}

const HeaderContent = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
  padding: 12px 0;
`

type CarListComponentProps = StateToProps & DispatchToProps & WithTranslation

const testId = 'car-list__'

export class CarListComponent extends React.Component<CarListComponentProps, State> {
  public readonly state: State = {
    filtersExpanded: false,
  }

  public componentDidMount(): void {
    this.props.getCars()
    this.props.getNewCarsCount()
  }

  public render(): React.ReactNode {
    const {
      brandFilters,
      carListFilters,
      cars,
      currentPage,
      dealerLocationFilters,
      loading,
      onListFilterChange,
      onBrandChange,
      onDealerLocationChange,
      onPageChange,
      onSearchPhraseChange,
      newCarsCount,
      t,
      permissions,
      searchPhrase,
    } = this.props

    if (loading) {
      return <Loader />
    }

    return cars && (
      <>
        <HeaderList>
          <H2 data-testid={`${testId}header`}>{t('carList.tableHeader')}</H2>
          <Small>{t('carList.newCars', { count: newCarsCount })}</Small>
          <HeaderContent>
            <HeaderLeft>
              <Filters filters={carListFilters} onFilterChange={onListFilterChange} testId={testId} />
              <ListFilters
                filtersCaption={t('caption.location')}
                filtersComponent={filterRendererProps => <ListLocationsFiltersForm {...filterRendererProps} />}
                filterValues={dealerLocationFilters}
                onSubmit={onDealerLocationChange}
              />
              <ListFilters
                filtersCaption={t('caption.brand')}
                filtersComponent={filterRendererProps => <ListBrandFiltersForm {...filterRendererProps} />}
                filterValues={brandFilters}
                onSubmit={onBrandChange}
              />
            </HeaderLeft>
            <Search onSearch={onSearchPhraseChange} initialValues={{ searchPhrase }} testId={testId} />
          </HeaderContent>
        </HeaderList>
        {
          cars.result.length ? (
            <WithPagination
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageCount={cars.noOfPages}
              path={Paths.CarList as Path}
              testId={testId}
              rowsInCurrentPage={cars.result.length}
            >
              <CarTable cars={cars} permissions={permissions} testId={testId} />
            </WithPagination>
          ) : <NoItems icon={IconType.NoCars} text={t('carList.noCars')} />
        }
      </>
    )
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  brandFilters: selectCarListDealerBrandFilters(state),
  carListFilters: selectCarListFilters(state),
  cars: selectCars(state),
  currentPage: selectCurrentPage(state),
  dealerLocationFilters: selectCarListDealerLocationFilters(state),
  loading: selectIsLoadingCars(state),
  newCarsCount: selectNewCarsCount(state),
  permissions: selectUserPermissions(state),
  searchPhrase: selectCarListSearchPhrase(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getCars: () => { dispatch(CarAction.getCars()) },
  getNewCarsCount: () => { dispatch(CarAction.getNewCarsCount()) },
  onBrandChange: (brands: ReadonlyArray<string>) => { dispatch(CarAction.changeCarBrandFilters(brands)) },
  onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => { dispatch(CarAction.changeDealerLocationFilters(locations)) },
  onListFilterChange: (param: string, value: CarFilterValues) => { dispatch(CarAction.changeListFilter(param, value)) },
  onPageChange: (nextPage: number) => { dispatch(CarAction.changePage(nextPage)) },
  onSearchPhraseChange: (searchPhrase: string) => { dispatch(CarAction.changeSearchPhrase(searchPhrase)) },
})

export const CarList = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CarListComponent),
)
