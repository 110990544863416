import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { colors } from 'styles'

export const EventsContainer = styled(FormCell)`
  border-top: 1px solid ${colors.athensGray};
  grid-area: events-history;
  overflow-y: auto;
  padding-bottom: 70px;
  padding-right: 32px;
`

export const LackOfEventsInfo = styled.p`
  font-weight: normal;
`

export const EventHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 10px 0;
`
