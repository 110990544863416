import { Container, Header } from 'actff-bo-app/Admin/Styled'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  ApplicationLinks,
  getLocationApplicationLinksAction,
  selectCurrentLocationApplicationLinks,
  updateLocationApplicationLinksAction,
} from 'actff-bo-lib/admin/dealer-locations'
import { selectCurrentLocation } from 'actff-bo-lib/dealership'
import { hasErrors } from 'actff-bo-lib/global'
import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { H2 } from 'styles'

import { ViewTabs } from '../ViewTabs'
import { ApplicationLink } from './ApplicationLink'

const testId = 'admin-view-dealer-locations--application-links__'

const Content = styled.div`
  display: grid;
  padding: 24px 24px 120px 0;
  grid-row-gap: 40px;
`

const setDefaultValues = (links: ApplicationLinks | null) => links || { }

const ApplicationLinksComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentLocation = useSelector(selectCurrentLocation)
  const { loading, data: links } = useSelector(selectCurrentLocationApplicationLinks)

  const {
    control,
    errors,
    formState,
    register,
    reset,
    handleSubmit,
  } = useForm<ApplicationLinks>({
    defaultValues: setDefaultValues(links),
  })

  const resetForm = () => { reset({ ...links }) }

  useEffect(() => {
    if (currentLocation) {
      dispatch(getLocationApplicationLinksAction(currentLocation.key))
    }
  }, [currentLocation])

  useEffect(() => {
    links && resetForm()
  }, [links])

  if (!links || !currentLocation) {
    return (
      <>
        <ViewTabs testId={testId} />
        <NoData>{t('caption.noData')}</NoData>
      </>
    )
  }

  if (loading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <LocalLoader />
      </>
    )
  }

  const { isDirty, isSubmitting, touched } = formState
  const isSaveDisabled = () => hasErrors(errors) || !isDirty || isSubmitting || !touched

  const onSubmit = handleSubmit<{}>((formValues: ApplicationLinks) => {
    dispatch(updateLocationApplicationLinksAction({ locationKey: currentLocation.key, links: formValues }))
  })

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('admin.dealerLocations.applicationLinks')}</H2></Header>
        <span>{t('admin.dealerLocations.applicationLinks.info')}</span>
        <Content>
          <ApplicationLink control={control} fieldName='accessories' register={register} link={links.accessories} />
          <ApplicationLink control={control} fieldName='newCars' register={register} link={links.newCars} />
          <ApplicationLink control={control} fieldName='usedCars' register={register} link={links.usedCars} />
        </Content>
      </Container>
      <FixedFooter isSaveDisabled={isSaveDisabled()} onCancel={resetForm} onSave={onSubmit} />
    </>
  )
}

export const DealerApplicationLinks = withAdminViewHeader(ApplicationLinksComponent)
