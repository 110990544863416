import { Loadable } from 'actff-bo-lib/global'
import { combineReducers } from 'redux'

import { Lead } from '../dto'
import { crmServiceListFiltersReducer, CrmServiceListFiltersState } from './filters'
import { crmServiceLeadReducer } from './lead'
import { crmServiceListReducer, CrmServiceListState } from './list'
import { crmServiceOpportunityReducer, CrmServiceOpportunityState } from './opportunity'

export type CrmServiceState = {
  readonly filters: CrmServiceListFiltersState,
  readonly lead: Loadable<Lead>,
  readonly list: CrmServiceListState,
  readonly opportunity: CrmServiceOpportunityState,
}

export const crmServiceReducer = combineReducers({
  filters: crmServiceListFiltersReducer,
  lead: crmServiceLeadReducer,
  list: crmServiceListReducer,
  opportunity: crmServiceOpportunityReducer,
})
