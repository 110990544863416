import { ClientPersonalDataVersion } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { PersonalDataType } from './PersonalDataGroup'

type PersonalDataCurrentStateProps = {
  readonly type: PersonalDataType,
  readonly personalData: ClientPersonalDataVersion,
}

export const PersonalDataCurrentState: React.FC<PersonalDataCurrentStateProps> = ({ type, personalData }) => {
  const { t } = useTranslation()

  return (
    <>
      {type === 'email'
        ? `${personalData.email} `
        : `${personalData.firstName} ${personalData.lastName} `
      }
      ({t('clientView.personalData.currentState')})
    </>
  )
}
