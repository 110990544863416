import {
  InputDateFilter,
  InputMileageFilter,
  InputPriceRangeFilter,
  InputProductionYearRangeFilter,
} from 'actff-bo-app/components/Filters'
import { FormHeader } from 'actff-bo-app/components/Form'
import { SelectableItem } from 'actff-bo-app/components/SelectableItem'
import { getBrandFuelTypes } from 'actff-bo-lib/admin/brands/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { CarFuelShortList, CarTransmissionShortListType } from 'actff-bo-lib/car'
import { ClientPreferences } from 'actff-bo-lib/client'
import { clearCarModels, clearCarVersions, getCarModelsAction, getCarVersionsAction } from 'actff-bo-lib/dealership'
import { DictionaryType, isNotNull } from 'actff-bo-lib/dictionary'
import { FormValues } from 'actff-bo-lib/form'
import { hasSelectedFormValues } from 'actff-bo-lib/form/rhf-helpers'
import { FormRange, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { getTranslation } from 'actff-bo-lib/i18n'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import isEmpty from 'lodash/isEmpty'
import React, { FC, useEffect, useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { Ref } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { CarData, CarDataHeaders, CarDataSegment, Container, Segment, SelectBrandInfo, TwoRowsSegment } from './Styled'

export type ClientPreferencesForm = {
  readonly brands: FormValues,
  readonly fuelTypes: FormValues,
  readonly equipment: ReadonlyArray<string>,
  readonly mileage: FormRange<SelectOption<string | null>>,
  readonly models: FormValues,
  readonly modelVersions: FormValues,
  readonly price: FormRange<SelectOption<string | null>>,
  readonly productionYear: FormRange<SelectOption<string | null>>,
  readonly purchaseDate: FormRange<Date>,
  readonly transmissions: FormValues,
  readonly version: string,
}

type Props = Testable & {
  readonly brands: ReadonlyArray<SelectOption<string>>,
  readonly fuelTypes: ReadonlyArray<string> | undefined,
  readonly models: ReadonlyArray<DictionaryType<string>>,
  readonly control: Control,
  readonly register: (ref: Ref | null) => void,
  readonly setValue: (key: string, value: boolean) => void,
  readonly brandsFormValue: FormValues,
  readonly versions: ReadonlyArray<DictionaryType<string>> | null,
}

function prepareSelectRangeFormValue<T>(formValue: FormRange<SelectOption<T>>): FormRange<T> {
  return {
    from: isNotNull(formValue.from) ? (formValue.from as SelectOption<T>).value : null,
    to: isNotNull(formValue.to) ? (formValue.to as SelectOption<T>).value : null,
  }
}

export const preparePreferences = (formValues: ClientPreferencesForm): ClientPreferences => {
  const {
    brands,
    fuelTypes,
    mileage,
    models,
    modelVersions,
    price,
    productionYear,
    purchaseDate,
    transmissions,
  } = formValues

  return {
    ...formValues,
    brands: Object.keys(brands).filter(key => brands[key]),
    fuelTypes: Object.keys(fuelTypes).filter(key => fuelTypes[key]),
    mileage: prepareSelectRangeFormValue<string | null>(mileage),
    modelVersions: Object.keys(modelVersions).filter(key => modelVersions[key]),
    models: Object.keys(models).filter(key => models[key]),
    price: prepareSelectRangeFormValue<string | null>(price),
    productionYear: prepareSelectRangeFormValue<string | null>(productionYear),
    purchaseDate: {
      from: purchaseDate.from || null,
      to: purchaseDate.to || null,
    },
    transmissions: Object.keys(transmissions).filter(key => transmissions[key]),
  }
}

function getOppositeKey<T>(key: string, pairedKeysList: T): string {
  return pairedKeysList[0] === key ? pairedKeysList[1] : pairedKeysList[0]
}

export const setNestedFormValues = (
  fieldName: string,
  fields: ReadonlyArray<DictionaryType<string>> | null,
  setValue: (name: string, value: boolean) => void,
  value = false,
) => {
  fields?.forEach(field => setValue(`${fieldName}.${field.value}`, value))
}

export const PreferencesForm: FC<Props> = ({ brands, brandsFormValue, models, control, register, setValue, versions }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [brandsValue, setBrandsValue] = useState(brandsFormValue || {})

  const { data: fuelTypes } = useQuery([QueryKeys.GET_BRAND_FUEL_TYPES, brandsValue], async () =>
    await getBrandFuelTypes(Object.entries(brandsValue).filter(([, value]) => !!value).map(([key]) => key)), {
    onError: async () => {
      dispatch(displayFailureToast(
        'admin.brands.getFuelTypes',
        ToastActionType.GET,
        'admin.brands.fuelTypes.getFailureAlert.body',
      ))
    },
    refetchOnWindowFocus: false,
    retry: false,
  })

  const productionYearFrom = useWatch<SelectOption<string>>({ control, name: 'productionYear.from' })
  const knownMileageFrom = useWatch<SelectOption<string>>({ control, name: 'knownMileage.from' })
  const priceFrom = useWatch<SelectOption<string>>({ control, name: 'price.from' })

  const shouldShowNoVersionsForBrand = () => hasSelectedFormValues(brandsValue) && versions?.length === 0
  const shouldShowNoModelsForBrand = () => hasSelectedFormValues(brandsValue) && models?.length === 0
  const shouldShowNoFuelTypesForBrand = () => hasSelectedFormValues(brandsValue) && fuelTypes?.length === 0

  useEffect(() => {
    if (isEmpty(brandsValue)) {
      setBrandsValue(brandsFormValue)
    }
  }, [brandsFormValue])

  useEffect(() => {
    const selectedBrands = Object.keys(brandsValue).filter(key => brandsValue[key])

    if (selectedBrands.length > 0) {
      dispatch(getCarVersionsAction(selectedBrands))
      dispatch(getCarModelsAction(selectedBrands))
    } else {
      dispatch(clearCarVersions())
      dispatch(clearCarModels())
    }
  }, [brandsValue])

  const handleSetBinaryValue = (fieldName: string, key: string, keyList: ReadonlyArray<string>) => ( value: boolean) => {
    if (value) {
      setValue(`${fieldName}.${getOppositeKey(key, keyList)}`, !value)
    }
  }

  const ModelFilterInfo = () => (
    <>
      {!hasSelectedFormValues(brandsValue) && (
        <SelectBrandInfo>{t('crmTrade.sale.preferences.model.selectBrandToShowList')}</SelectBrandInfo>
      )}
      {shouldShowNoModelsForBrand() && (
        <SelectBrandInfo>{t('crmTrade.sale.preferences.model.noVersions')}</SelectBrandInfo>
      )}
    </>
  )

  const VersionFilterInfo = () => (
    <>
      {!hasSelectedFormValues(brandsValue) && (
        <SelectBrandInfo>{t('crmTrade.sale.preferences.version.selectBrandToShowList')}</SelectBrandInfo>
      )}
      {shouldShowNoVersionsForBrand() && (
        <SelectBrandInfo>{t('crmTrade.sale.preferences.version.noVersions')}</SelectBrandInfo>
      )}
    </>
  )

  const FuelTypesFilterInfo = () => (
    <>
      {!hasSelectedFormValues(brandsValue) && (
        <SelectBrandInfo>{t('crmTrade.sale.preferences.fuelTypes.selectBrandToShowList')}</SelectBrandInfo>
      )}
      {shouldShowNoFuelTypesForBrand() && (
        <SelectBrandInfo>{t('crmTrade.sale.preferences.fuelTypes.noFuelTypes')}</SelectBrandInfo>
      )}
    </>
  )

  const onBrandsValueChange = (name: string, value: boolean) => {
    setBrandsValue({
      ...brandsValue,
      [name.split('.')[1]]: value,
    })

    setNestedFormValues('modelVersions', versions, setValue)
    setNestedFormValues('models', models, setValue)

    fuelTypes?.forEach(fuelType => {
      setValue(`fuelTypes.${fuelType}`, false)
    })
  }

  return (
    <Container>
      <FormHeader>{t('preferences.form.header')}</FormHeader>
      <label>{t('car.brand')}:</label>
      <Segment>
        {brands.map(brand => (
          <SelectableItem
            control={control}
            register={register}
            label={brand.label}
            name={`brands.${brand.value}`}
            key={brand.value}
            onValueChange={onBrandsValueChange}
          />
        ))}
      </Segment>
      <label>{t('car.model')}:</label>
      <TwoRowsSegment>
        {models.map(model => (
          <SelectableItem control={control} register={register} label={model.label} name={`models.${model.value}`} key={model.value} />
        ))}
        <ModelFilterInfo />
      </TwoRowsSegment>
      <label>{t('car.version')}:</label>
      <Segment>
        <VersionFilterInfo />
        {versions?.map(version => (
          <SelectableItem
            control={control}
            register={register}
            label={version.label}
            name={`modelVersions.${version.value}`}
            key={version.value}
          />
        ))}
      </Segment>
      <label>{t('car.fuelType')}:</label>
      <Segment>
        <FuelTypesFilterInfo />
        {fuelTypes?.map(fuelType => (
          <SelectableItem
            control={control}
            register={register}
            label={fuelType}
            name={`fuelTypes.${fuelType}`}
            key={fuelType}
            setValue={handleSetBinaryValue('fuelTypes', fuelType, Object.values(CarFuelShortList))}
          />
        ))}
      </Segment>
      <label>{t('car.transmission')}:</label>
      <Segment>
        {Object.values(CarTransmissionShortListType).map(transmission => (
          <SelectableItem
            control={control}
            register={register}
            label={getTranslation(t, 'car.transmission.', transmission)}
            name={`transmissions.${transmission}`}
            key={transmission}
            setValue={handleSetBinaryValue('transmissions', transmission, Object.values(CarTransmissionShortListType))}
          />
        ))}
      </Segment>

      <CarDataSegment>
        <CarDataHeaders><label>{t('car.knownMileage')}:</label><label>{t('car.productionYear')}:</label></CarDataHeaders>
        <CarData>
          <InputMileageFilter caption='caption.from' control={control} name='mileage.from' />
          <InputMileageFilter caption='caption.to' control={control} name='mileage.to' startFrom={knownMileageFrom} />
          <InputProductionYearRangeFilter caption='caption.from' control={control} name='productionYear.from' />
          <InputProductionYearRangeFilter
            caption='caption.to'
            control={control}
            startFrom={productionYearFrom}
            name='productionYear.to'
          />
        </CarData>
        <CarDataHeaders><label>{t('caption.price2')}:</label><label>{t('crmTrade.potentialBuyDate')}:</label></CarDataHeaders>
        <CarData>
          <InputPriceRangeFilter caption='caption.from' control={control} name='price.from' />
          <InputPriceRangeFilter caption='caption.to' control={control} name='price.to' startFrom={priceFrom} />
          <InputDateFilter control={control} name='purchaseDate.from' caption='caption.from' />
          <InputDateFilter control={control} name='purchaseDate.to' caption='caption.to' />
        </CarData>
      </CarDataSegment>
    </Container>
  )
}
