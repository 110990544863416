import { ErrorMessage } from '@hookform/error-message'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { DatePickerInputPosition } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormContent } from '../ResourceModal/Styled'
import { RepeatEveryContainer } from './Styled'

type Props = Testable & Pick<UseFormMethods, 'control' | 'errors'> & {
  readonly availableEndDate: Date,
}

export const RepeatCycle: FC<Props> = ({ control, errors, availableEndDate }) => {
  const { t } = useTranslation()

  return (
    <FormContent>
      <FormCell noPaddingLeft={true}>
        <label>{t('admin.employeeSchedule.form.cycle.repeat')}</label>
        <RepeatEveryContainer>
          <span>{t('admin.employeeSchedule.form.cycle.every')}</span>
            <Controller
              render={({onChange, value}) => (
                <input
                  onChange={onChange}
                  type='number'
                  step='1'
                  min='1'
                  value={value}
                />
              )}
              control={control}
              name='every'
            />
          <span>{t('caption.week').toLowerCase()}</span>
        </RepeatEveryContainer>
      </FormCell>

      <FormCell noPaddingLeft={true}>
        <label>{t('admin.employeeSchedule.form.cycle.end')}</label>
        <Controller
          render={({ onChange, value }) => (
            <DatePickerInput
              date={value}
              onChange={onChange}
              timeDisabled={true}
              disabledDays={{ before: availableEndDate }}
              position={DatePickerInputPosition.RIGHT}
            />
          )}
          rules={{ required: 'caption.error.required' }}
          control={control}
          name='end'
        />
        <ErrorMessage errors={errors} name='end' as={TranslatedErrorMessage} />
      </FormCell>
    </FormContent>
  )
}
