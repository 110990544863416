import { Client } from 'actff-bo-lib/client'
import React, { FC } from 'react'

import { TypeIcons } from './TypeIcons'

type Props = {
  readonly person: Client,
  readonly className?: string,
}

export const PersonType: FC<Props> = ({ person, className }) =>
  person.type?.length > 0
    ? (
      <TypeIcons className={className} person={person} />
    )
    : null
