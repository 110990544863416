import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { InspectionPaintCoatElementType } from 'actff-bo-lib/car/dto'
import { AjaxError } from 'rxjs/ajax'

import { Attachment, AttachmentUploadType, BlobUrl, IdentifiedUrl, Url } from './dto'

export enum AttachmentActionType {
  GetAttachmentThumbnail = '[ATTACHMENT] GET ATTACHMENT THUMBNAIL',
  GetAttachmentThumbnailFailure = '[ATTACHMENT] GET ATTACHMENT THUMBNAIL FAILURE',
  GetAttachmentThumbnailSuccess = '[ATTACHMENT] GET ATTACHMENT THUMBNAIL SUCCESS',

  GetAttachment = '[ATTACHMENT] GET ATTACHMENT',
  GetAttachmentFailure = '[ATTACHMENT] GET ATTACHMENT FAILURE',
  GetAttachmentSuccess = '[ATTACHMENT] GET ATTACHMENT SUCCESS',

  ClearAttachmentInStore = '[ATTACHMENT] CLEAR ATTACHMENT IN STORE',

  SendAttachments = '[ATTACHMENT] SEND ATTACHMENTS',
  SendAttachmentsFailure = '[ATTACHMENT] SEND ATTACHMENTS FAILURE',
  SendAttachmentsComplete = '[ATTACHMENT] SEND ATTACHMENTS COMPLETE',

  SendSingleAttachment = '[ATTACHMENT] SEND SINGLE ATTACHMENT',
  SendSingleAttachmentFailure = '[ATTACHMENT] SEND SINGLE ATTACHMENT FAILURE',
  SendSingleAttachmentSuccess = '[ATTACHMENT] SEND SINGLE ATTACHMENT SUCCESS',
}

export const AttachmentAction = {
  getAttachmentThumbnail: (url: Url) => createAction(AttachmentActionType.GetAttachmentThumbnail, url),
  getAttachmentThumbnailFailure: (url: Url, error: AjaxError) =>
    createAction(AttachmentActionType.GetAttachmentThumbnailFailure, { url, error }),
  getAttachmentThumbnailSuccess: (url: Url, blob: BlobUrl) =>
    createAction(AttachmentActionType.GetAttachmentThumbnailSuccess, { url, blob }),

  getAttachment: (url: Url) => createAction(AttachmentActionType.GetAttachment, url),
  getAttachmentFailure: (error: AjaxError) => createAction(AttachmentActionType.GetAttachmentFailure, error),
  getAttachmentSuccess: (url: Url, blob: BlobUrl) =>
    createAction(AttachmentActionType.GetAttachmentSuccess, { url, blob }),

  clearAttachmentInStore: (url: Url) => createAction(AttachmentActionType.ClearAttachmentInStore, url),

  sendSingleAttachment: (attachment: Attachment) => createAction(AttachmentActionType.SendSingleAttachment, attachment),
  sendSingleAttachmentFailure: (error: AjaxError) => createAction(AttachmentActionType.SendSingleAttachmentFailure, error),
  sendSingleAttachmentSuccess: (url: Url, key: InspectionPaintCoatElementType) =>
    createAction(AttachmentActionType.SendSingleAttachmentSuccess, key ? { key, url } : url),

  sendAttachments: <T>(attachments: ReadonlyArray<Attachment>, type: AttachmentUploadType, props: { readonly [key: string]: T }) =>
    createAction(AttachmentActionType.SendAttachments, { attachments, type, props }),
  sendAttachmentsComplete: (
    urls: ReadonlyArray<Url | IdentifiedUrl>,
    type: AttachmentUploadType,
    props: any, // tslint:disable-line no-any
  ) =>
    createAction(AttachmentActionType.SendAttachmentsComplete, { urls, type, props }),
  sendAttachmentsFailure: () => createAction(AttachmentActionType.SendAttachmentsFailure),
}

export type AttachmentAction = ActionsUnion<typeof AttachmentAction>
