import { Action } from '@reduxjs/toolkit'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import {
  AdminLocationSettingsAction,
  AdminLocationSettingsActionType,
  AdminOpeningHoursAction,
  AdminOpeningHoursActionType,
  getApplicationLinksActionSuccess,
  getLocationApplicationLinksAction,
  getLocationApplicationLinksActionFailure,
  updateApplicationLinksActionSuccess,
  updateLocationApplicationLinksAction,
  updateLocationApplicationLinksActionFailure,
} from '../actions'
import {
  getDealerLocationApplicationLinks,
  getDealerSettings,
  getOpeningHours,
  updateDealerLocationApplicationLinks,
  updateDealerSettings,
  updateOpeningHours,
} from '../dao'

const getOpeningHoursEpic: Epic<AdminOpeningHoursAction, AdminOpeningHoursAction, State> = action$ => action$.pipe(
  ofType<ReturnType<typeof AdminOpeningHoursAction.getOpeningHours>>(AdminOpeningHoursActionType.GetOpeningHours),
  switchMap(({ payload }) => getOpeningHours(payload).pipe(
    map(AdminOpeningHoursAction.getOpeningHoursSuccess),
    catchError((error: AjaxError) => of(AdminOpeningHoursAction.getOpeningHoursFailure(error)),
  )),
))

const updateOpeningHoursEpic: Epic<AdminOpeningHoursAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AdminOpeningHoursAction.updateOpeningHours>>(AdminOpeningHoursActionType.UpdateOpeningHours),
  switchMap(({ payload: { payload, locationKey }}) => updateOpeningHours(payload, locationKey).pipe(
    map(AdminOpeningHoursAction.updateOpeningHoursSuccess),
    catchError((error: AjaxError) => of(AdminOpeningHoursAction.updateOpeningHoursFailure(error))),
  )),
)

const getDealerSettingsEpic: Epic<AdminLocationSettingsAction, AdminLocationSettingsAction, State> = action$ => action$.pipe(
  ofType<ReturnType<typeof AdminLocationSettingsAction.getDealerSettings>>(AdminLocationSettingsActionType.GetDealerSettings),
  switchMap(({ payload }) => getDealerSettings(payload).pipe(
    map(AdminLocationSettingsAction.getDealerSettingsSuccess),
    catchError((error: AjaxError) => of(AdminLocationSettingsAction.getDealerSettingsFailure(error)),
  )),
))

const updateDealerSettingsEpic: Epic<AdminLocationSettingsAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AdminLocationSettingsAction.updateDealerSettings>>(AdminLocationSettingsActionType.UpdateDealerSettings),
  switchMap(({ payload: { payload, locationKey }}) => updateDealerSettings(payload, locationKey).pipe(
    map(() => AdminLocationSettingsAction.updateDealerSettingsSuccess(payload)),
    catchError((error: AjaxError) => of(AdminLocationSettingsAction.updateDealerSettingsFailure(error))),
  )),
)

const notifyOnUpdateSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<
    typeof AdminLocationSettingsAction.updateDealerSettingsSuccess
    | typeof AdminOpeningHoursAction.updateOpeningHoursSuccess
    >>(
    AdminLocationSettingsActionType.UpdateDealerSettingsSuccess,
    AdminOpeningHoursActionType.UpdateOpeningHoursSuccess,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: 'toast.updateActionSuccess',
      id: 'admin.dealerLocations.form.update.success',
      title: 'toast.success',
      type: ToastType.Success,
    })],
  ))

const notifyOnUpdateFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<
    typeof AdminLocationSettingsAction.updateDealerSettingsFailure
    | typeof AdminOpeningHoursAction.updateOpeningHoursFailure
    >>(
    AdminLocationSettingsActionType.UpdateDealerSettingsFailure,
    AdminOpeningHoursActionType.UpdateOpeningHoursFailure,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      body: 'toast.updateActionFailure',
      id: 'admin.dealerLocations.form.update.failure',
      title: 'toast.failure',
      type: ToastType.Error,
    })],
  ))

export const getApplicationLinksEpic: Epic<Action> = action$ => action$.pipe(
  filter(getLocationApplicationLinksAction.match),
  switchMap(({ payload }) => getDealerLocationApplicationLinks(payload).pipe(
    map(getApplicationLinksActionSuccess),
    catchError((error: AjaxError) => of(getLocationApplicationLinksActionFailure(error))),
  )),
)

export const updateApplicationLinksEpic: Epic<Action> = action$ => action$.pipe(
  filter(updateLocationApplicationLinksAction.match),
  switchMap((({ payload }) => updateDealerLocationApplicationLinks(payload.locationKey, payload.links).pipe(
    map(updateApplicationLinksActionSuccess),
    catchError((error: AjaxError) => of(updateLocationApplicationLinksActionFailure(error))),
    )),
  ),
)
export const adminLocationSettingsEpic = combineEpics(
  getApplicationLinksEpic,
  getDealerSettingsEpic,
  getOpeningHoursEpic,
  notifyOnUpdateFailureEpic,
  notifyOnUpdateSuccessEpic,
  updateApplicationLinksEpic,
  updateDealerSettingsEpic,
  updateOpeningHoursEpic,
)
