import { NotificationAction, NotificationActionType } from './actions'
import { NotificationToken } from './dto'

export type NotificationState = {
  readonly areNotificationsInitialized: boolean,
  readonly notificationToken: NotificationToken | null,
}

const notificationInitialState: NotificationState = {
  areNotificationsInitialized: false,
  notificationToken: null,
}

export const notificationReducer = (state: NotificationState = notificationInitialState, action: NotificationAction): NotificationState => {
  switch (action.type) {
    case NotificationActionType.Init:
      return {
        ...state,
        areNotificationsInitialized: true,
      }
    case NotificationActionType.RegisterToken:
      return {
        ...state,
        notificationToken: action.payload,
      }
    case NotificationActionType.UnregisterTokenSuccess:
      return {
        ...state,
        notificationToken: null,
      }

    default:
      return state
  }
}
