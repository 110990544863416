import { TradeOpportunityType } from 'actff-bo-lib/crm/trade'
import { isNotEmptyString } from 'actff-bo-lib/dictionary/helpers'
import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { maxPage, redirectToList } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { LOCATION_CHANGE } from 'connected-react-router'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import {
  ClientAction,
  ClientActionType,
  ContactApprovalsAction,
  ContactApprovalsActionType,
  selectClientListSearchPhrase,
  selectClientsNoOfPages,
  selectCurrentFilters,
  selectCurrentPage,
} from '.'
import {
  addClient,
  archiveClient,
  getClient,
  getClientCars,
  getClientCount,
  getClientMatchedOpportunities,
  getClientMatchedSaleOpportunity,
  getClientPersonalDataHistory,
  getClientPreferences,
  getClients,
  getContactApprovals,
  restoreClient,
  saveClientPreferences,
  updateClient,
  updateContactApprovals,
} from './dao'
import { selectCurrentClientCarsPage } from './selectors'

const archiveClientEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.archiveClient>>(ClientActionType.ArchiveClient),
  switchMap(({ payload }) => archiveClient(payload).pipe(
    map(ClientAction.archiveClientSuccess),
    catchError((error: AjaxError) => of(ClientAction.archiveClientFailure(error))),
  )),
)

const changeListParamsEpic: Epic<ClientAction, ClientAction, State> = (action$, store$) => action$.pipe(
  ofType<
    ReturnType<typeof ClientAction.changePage>
    | ReturnType<typeof ClientAction.changeFilters>
    >
  (
    ClientActionType.ChangePage,
    ClientActionType.ChangeFilters,
  ),
  withLatestFrom(store$),
  tap(([, store]) => {
    redirectToList(Paths.ClientList, selectCurrentPage(store))
  }),
  map(ClientAction.getClients),
)

const getPersonalDataHistoryEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClientPersonalDataHistory>>(ClientActionType.GetClientPersonalDataHistory),
  switchMap(({ payload }) => getClientPersonalDataHistory(payload).pipe(
    map(ClientAction.getClientPersonalDataHistorySuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientPersonalDataHistoryFailure(error))),
  )),
)

const getClientPreferencesEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClientPreferences>>(ClientActionType.GetClientPreferences),
  switchMap(({ payload }) => getClientPreferences(payload).pipe(
    map(ClientAction.getClientPreferencesSuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientPreferencesFailure(error))),
  )),
)

const getClientMatchedPurchaseOpportunitiesEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClientMatchedPurchaseOpportunities>>(ClientActionType.GetClientMatchedPurchaseOpportunities),
  switchMap(({ payload }) => getClientMatchedOpportunities(payload, TradeOpportunityType.Purchase).pipe(
    map(ClientAction.getClientMatchedPurchaseOpportunitiesSuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientMatchedPurchaseOpportunitiesFailure(error))),
  )),
)

const getClientMatchedSaleOpportunitiesEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClientMatchedSaleOpportunity>>(ClientActionType.GetClientMatchedSaleOpportunity),
  switchMap(({ payload }) => getClientMatchedSaleOpportunity(payload).pipe(
    map(ClientAction.getClientMatchedSaleOpportunitySuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientMatchedSaleOpportunityFailure(error))),
  )),
)

const getContactApprovalsEpic: Epic<ContactApprovalsAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ContactApprovalsAction.getContactApprovals>>(ContactApprovalsActionType.GetContactApprovals),
  switchMap(({ payload }) => getContactApprovals(payload).pipe(
    map(ContactApprovalsAction.getContactApprovalsSuccess),
    catchError((error: AjaxError) => of(ContactApprovalsAction.getContactApprovalsFailure(error)),
  )),
))

const updateContactApprovalsEpic: Epic<ContactApprovalsAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ContactApprovalsAction.updateContactApprovals>>(ContactApprovalsActionType.UpdateContactApprovals),
  switchMap(({ payload }) => updateContactApprovals(payload).pipe(
    map(ContactApprovalsAction.updateContactApprovalsSuccess),
    catchError((error: AjaxError) => of(ContactApprovalsAction.updateContactApprovalsFailure(error)),
  )),
))

const addClientEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.addClient>>(ClientActionType.AddClient),
  switchMap(({ payload }) => addClient(payload).pipe(
    map(ClientAction.addClientSuccess),
    catchError((error: AjaxError) => of(ClientAction.addClientFailure(error)),
  )),
))

const redirectToViewWhenAddClientSuccessAndNotifyUserEpic: Epic<ClientAction, AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.addClientSuccess>>(ClientActionType.AddClientSuccess),
  tap(({ payload }) => {
    history.replace(Paths.ClientViewBasicData, { clientId: payload.uuid })
  }),
  switchMap(() => [
    ToastAction.displayToast({
      body: 'client.addNew.success',
      id: 'client.addNew.success',
      title: 'toast.success',
      type: ToastType.Success,
    }),
  ]),
)

const getClientEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClient>>(ClientActionType.GetClient),
  switchMap(({ payload }) => getClient(payload).pipe(
    map(ClientAction.getClientSuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientFailure(error)),
  )),
))

const getClientCarsEpic: Epic<ClientAction, ClientAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClientCars>>(ClientActionType.GetClientCars),
  withLatestFrom(store$),
  switchMap(([{ payload }, state]) => getClientCars(payload, selectCurrentClientCarsPage(state) - 1).pipe(
    map(ClientAction.getClientCarsSuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientCarsFailure(error))),
  )),
)

const getClientCountEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType(ClientActionType.GetClientCount),
  switchMap(() => getClientCount().pipe(
    map(ClientAction.getClientCountSuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientCountFailure(error))),
  )),
)

const getClientsEpic: Epic<ClientAction, ClientAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClients>>(ClientActionType.GetClients),
  withLatestFrom(store$),
  switchMap(([, state]) => getClients(
    selectCurrentPage(state) - 1,
    undefined,
    selectCurrentFilters(state),
    encodeStringIfExists(selectClientListSearchPhrase(state)),
  ).pipe(
    map(ClientAction.getClientsSuccess),
    catchError((error: AjaxError) => of(ClientAction.getClientsFailure(error)),
  )),
))

const changeClientCarListPageEpic: Epic<ClientAction, ClientAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof ClientAction.changeClientCarsPage>>(ClientActionType.ChangeClientCarsPage),
  withLatestFrom(store$),
  tap(([, store]) => {
    redirectToList(window.location.pathname, selectCurrentClientCarsPage(store), selectCurrentFilters(store))
  }),
  ignoreElements(),
)

const changePageWhenOutOfPagesRangeEpic: Epic<ClientAction, ClientAction> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof ClientAction.getClientsSuccess>>(ClientActionType.GetClientsSuccess),
  withLatestFrom(state$),
  filter(([, store]) => selectCurrentPage(store) > (selectClientsNoOfPages(store) || maxPage)),
  map(([, store]) => ClientAction.changePage(selectClientsNoOfPages(store) || 1)),
)

const changeToFirstPageWhenRouteOutsideListEpic: Epic<AnyAction, ClientAction> = (action$, state$) => action$.pipe(
  ofType(LOCATION_CHANGE),
  withLatestFrom(state$),
  filter(([action]) => action.payload.location.pathname === Paths.ClientList),
  filter(([action]) => !isNotEmptyString(action.payload.location.search)),
  map(() => ClientAction.changePage( 1)),
)

const changePageOnChangeSearchPhraseEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType(ClientActionType.ChangeSearchPhrase),
  map(() => ClientAction.changePage(1)),
)

const restoreClientEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.restoreClient>>(ClientActionType.RestoreClient),
  switchMap(({ payload }) => restoreClient(payload).pipe(
    map(ClientAction.restoreClientSuccess),
    catchError((error: AjaxError) => of(ClientAction.restoreClientFailure(error))),
  )),
)

const updateClientEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.updateClient>>(ClientActionType.UpdateClient),
  switchMap(({ payload }) => updateClient(payload).pipe(
    map(ClientAction.updateClientSuccess),
    catchError((error: AjaxError) => of(ClientAction.updateClientFailure(error)),
  )),
))

const saveClientPreferencesEpic: Epic<ClientAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.saveClientPreferences>>(ClientActionType.SaveClientPreferences),
  switchMap(({ payload }) => saveClientPreferences(payload).pipe(
    map(ClientAction.saveClientPreferencesSuccess),
    catchError((error: AjaxError) => of(ClientAction.saveClientPreferencesFailure(error)),
  )),
))

const alertOnFailureActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.saveClientPreferencesFailure>>(ClientActionType.SaveClientPreferencesFailure),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'toast.form.formSave.failure',
      title: 'toast.form.formSave.failure',
      type: ToastType.Error,
    }),
  ]),
)

const informOnSuccessActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ClientAction.saveClientPreferencesSuccess>>(ClientActionType.SaveClientPreferencesSuccess),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      id: 'toast.form.formSave.success',
      title: 'toast.form.formSave.success',
      type: ToastType.Success,
    }),
  ]),
)

export const clientEpic = combineEpics(
  addClientEpic,
  alertOnFailureActionEpic,
  archiveClientEpic,
  changeClientCarListPageEpic,
  changeListParamsEpic,
  changePageOnChangeSearchPhraseEpic,
  changePageWhenOutOfPagesRangeEpic,
  changeToFirstPageWhenRouteOutsideListEpic,
  getClientCarsEpic,
  getClientCountEpic,
  getClientEpic,
  getClientMatchedPurchaseOpportunitiesEpic,
  getClientMatchedSaleOpportunitiesEpic,
  getClientPreferencesEpic,
  getClientsEpic,
  getContactApprovalsEpic,
  getPersonalDataHistoryEpic,
  informOnSuccessActionEpic,
  redirectToViewWhenAddClientSuccessAndNotifyUserEpic,
  restoreClientEpic,
  saveClientPreferencesEpic,
  updateClientEpic,
  updateContactApprovalsEpic,
)
