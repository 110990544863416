import { decodeJwtPayload } from 'actff-bo-lib/auth/decode-jwt-payload'
import { TokenPayload } from 'actff-bo-lib/auth/dto'
import { selectToken } from 'actff-bo-lib/auth/selectors'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { UserAction, UserActionType } from './actions'
import { getUser, getUsers } from './dao'

const getMeEpic: Epic<UserAction, UserAction, State> = (action$, state$) => action$.pipe(
  ofType(UserActionType.GetMe),
  withLatestFrom(state$),
  map(([, state]): TokenPayload | null => decodeJwtPayload<TokenPayload>(selectToken(state))),
  filter<TokenPayload>(Boolean),
  switchMap(tokenPayload => getUser(tokenPayload.sub).pipe(
    map(UserAction.getMeSuccess),
    catchError((error: AjaxError) => of(UserAction.getMeFailure(error))),
  )),
)

const getMeSuccessEpic: Epic<UserAction> = action$ => action$.pipe(
  ofType(UserActionType.GetMeSuccess),
  tap(() => {
    if (history.location.pathname === Paths.Login) {
      history.replace(Paths.Main)
    }
  }),
  map(UserAction.setMeInitialized),
)

const getUserListEpic: Epic<UserAction> = action$ => action$.pipe(
  ofType(UserActionType.GetUserList),
  switchMap(() => getUsers().pipe(
    map(UserAction.getUserListSuccess),
    catchError((error: AjaxError) => of(UserAction.getUserListFailure(error))),
  )),
)

export const userEpic = combineEpics(
  getMeEpic,
  getMeSuccessEpic,
  getUserListEpic,
)
