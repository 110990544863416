import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Client, ContactApprovals, selectCurrentClient, selectCurrentContactApprovals } from 'actff-bo-lib/client'
import { defaultShortDateFormat } from 'actff-bo-lib/date/format'
import { connector } from 'actff-bo-lib/global'
import { State } from 'actff-bo-lib/store'
import LogoImage from 'assets/logo.svg'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { MapStateToProps } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { compose } from 'redux'
import styled from 'styled-components'

import { PersonalDataHistory } from '../../BasicData'
import { ApprovalClient } from './ApprovalClient'
import { ApprovalGroup, ApprovalType } from './ApprovalGroup'
import { ApprovalGroupType } from './ApprovalHeaderWithCurrentState'

type StateToProps = {
  readonly approvals: ContactApprovals | null,
  readonly client: Client | null,
}

type Props = {
  // TODO: Find a type or fix broken library types
  // tslint:disable-next-line no-any
  readonly historyDownloadTrigger: () => any,
}

type ApprovalsHistoryProps = StateToProps & Props & WithTranslation

type GridAreaProps = {
  readonly gridArea: string,
}

const Container = styled.div`
  @media print {
    display: grid;
    margin: 10mm 0;
  }

  display: none;
  grid-auto-flow: row;
  grid-template-areas:
    'logo date'
    'client-data client-data'
    'marketing-trade-approvals-header marketing-trade-approvals-header'
    'marketing-trade-approvals marketing-trade-approvals'
    'registration-approvals-header registration-approvals-header'
    'registration-approvals registration-approvals'
  ;
  margin: 40px 0;
  font-family: 'Poppins', sans-serif;
  padding: 0 40px;
  font-size: 10px;
`

const Logo = styled.img`
  grid-area: logo;
  margin: 0 0 48px;
`

const PdfGenerationDate = styled.span`
  grid-area: date;
  justify-self: end;
`

const Approvals = styled.div`
  display: grid;
  grid-area: ${({ gridArea }: GridAreaProps) => gridArea};
  grid-auto-flow: row;
`

const ApprovalsHeader = styled.span`
  font-weight: 600;
  grid-area: ${({ gridArea }: GridAreaProps) => gridArea};
  padding: 32px 0 0;
  text-transform: uppercase;
`

class ApprovalsHistoryComponent extends React.Component<ApprovalsHistoryProps> {
  private readonly pdfRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

  public render(): React.ReactNode {
    const { approvals, client, historyDownloadTrigger, t } = this.props
    const emptyDiv = document.createElement('div')

    // TODO: Find a type or fix broken library types
    // tslint:disable jsx-no-lambda
    return (
      approvals && client
      ? (
        <>
          <ReactToPrint trigger={historyDownloadTrigger} content={() => this.pdfRef.current || emptyDiv} />
          <div ref={this.pdfRef}>
            <Container>
              <Logo src={LogoImage} />
              <PdfGenerationDate><FormattedDate date={new Date()} format={defaultShortDateFormat} /></PdfGenerationDate>
              <ApprovalClient client={client} />
              <ApprovalsHeader gridArea='marketing-trade-approvals-header'>{t('clientView.approvals.formsOfContact')}</ApprovalsHeader>
              <Approvals gridArea='marketing-trade-approvals'>
                <ApprovalGroup
                  approvals={approvals.marketingOffers.pushNotifications}
                  approvalType={ApprovalType.Marketing}
                  groupType={ApprovalGroupType.Push}
                />
                <ApprovalGroup
                  approvals={approvals.tradeOffers.pushNotifications}
                  approvalType={ApprovalType.Trade}
                  groupType={ApprovalGroupType.Push}
                />
                <ApprovalGroup
                  approvals={approvals.marketingOffers.sms}
                  approvalType={ApprovalType.Marketing}
                  groupType={ApprovalGroupType.SMS}
                />
                <ApprovalGroup
                  approvals={approvals.tradeOffers.sms}
                  approvalType={ApprovalType.Trade}
                  groupType={ApprovalGroupType.SMS}
                />
                <ApprovalGroup
                  approvals={approvals.marketingOffers.email}
                  approvalType={ApprovalType.Marketing}
                  groupType={ApprovalGroupType.Email}
                />
                <ApprovalGroup
                  approvals={approvals.tradeOffers.email}
                  approvalType={ApprovalType.Trade}
                  groupType={ApprovalGroupType.Email}
                />
              </Approvals>
              <ApprovalsHeader gridArea='registration-approvals-header'>{t('clientView.approvals.registrationApproval')}</ApprovalsHeader>
              <Approvals gridArea='registration-approvals'>
                <ApprovalGroup approvals={approvals.personalData.general} groupType={ApprovalGroupType.PersonalData} />
                <ApprovalGroup approvals={approvals.termsAndConditions.general} groupType={ApprovalGroupType.TermsAndConditions} />
                <ApprovalGroup approvals={approvals.profile.general} groupType={ApprovalGroupType.Profiling} />
              </Approvals>
              <PersonalDataHistory />
            </Container>
          </div>
        </>
      ) : null
    )
  }

}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  approvals: selectCurrentContactApprovals(state),
  client: selectCurrentClient(state),
})

export const ApprovalsHistory = compose(
  connector(mapStateToProps, null),
  withTranslation(),
)(ApprovalsHistoryComponent)
