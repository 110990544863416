import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { redirectToList } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { TelephoneRequestAction, TelephoneRequestActionType } from './actions'
import {
  getNewTelephoneRequestCount,
  getTelephoneRequest,
  getTelephoneRequestCars,
  getTelephoneRequests,
  updateTelephoneRequest,
} from './dao'
import {
  selectTelephoneRequestBrandFilters,
  selectTelephoneRequestCurrentPage,
  selectTelephoneRequestDealerLocationFilters,
  selectTelephoneRequestListFilters,
  selectTelephoneRequestSearchPhrase,
} from './selectors'

const changeListParamsEpic: Epic<TelephoneRequestAction, TelephoneRequestAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof TelephoneRequestAction.changePage>>(TelephoneRequestActionType.ChangePage),
  withLatestFrom(state$),
  tap(([, store]) => {
    redirectToList(Paths.TelephoneRequests, selectTelephoneRequestCurrentPage(store))
  }),
  map(TelephoneRequestAction.getTelephoneRequests),
)

const changeFilterOrSearchPhraseEpic: Epic<TelephoneRequestAction> = action$ => action$.pipe(
  ofType(
    TelephoneRequestActionType.ChangeListFilter,
    TelephoneRequestActionType.ChangeSearchPhrase,
    TelephoneRequestActionType.ChangeDealerLocations,
    TelephoneRequestActionType.ChangeBrands,
  ),
  map(() => TelephoneRequestAction.changePage(1)),
)

const getTelephoneRequestsEpic: Epic<TelephoneRequestAction, TelephoneRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(TelephoneRequestActionType.GetTelephoneRequests),
  withLatestFrom(state$),
  switchMap(([, state]) =>
    getTelephoneRequests(
      selectTelephoneRequestListFilters(state),
      selectTelephoneRequestCurrentPage(state) - 1,
      encodeStringIfExists(selectTelephoneRequestSearchPhrase(state)),
      selectTelephoneRequestDealerLocationFilters(state),
      selectTelephoneRequestBrandFilters(state),
    ).pipe(
      map(TelephoneRequestAction.getTelephoneRequestsSuccess),
      catchError((error: AjaxError) => of(TelephoneRequestAction.getTelephoneRequestsFailure(error))),
    ),
))

const getTelephoneRequestEpic: Epic<TelephoneRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof TelephoneRequestAction.getTelephoneRequest>>(TelephoneRequestActionType.GetTelephoneRequest),
  switchMap(({ payload }) => getTelephoneRequest(payload).pipe(
    map(TelephoneRequestAction.getTelephoneRequestSuccess),
    catchError((error: AjaxError) => of(TelephoneRequestAction.getTelephoneRequestFailure(error)),
  )),
))

const getNewTelephoneRequestCountEpic: Epic<TelephoneRequestAction> = action$ => action$.pipe(
  ofType(TelephoneRequestActionType.GetNewTelephoneRequestCount),
  switchMap(() => getNewTelephoneRequestCount().pipe(
    map(TelephoneRequestAction.getNewTelephoneRequestCountSuccess),
    catchError((error: AjaxError) => of(TelephoneRequestAction.getNewTelephoneRequestCountFailure(error))),
  )),
)

const getTelephoneRequestCarsEpic: Epic<TelephoneRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof TelephoneRequestAction.getTelephoneRequestSuccess>>(TelephoneRequestActionType.GetTelephoneRequestSuccess),
  switchMap(({ payload }) => getTelephoneRequestCars(payload.client.uuid).pipe(
    map(TelephoneRequestAction.getTelephoneRequestCarsSuccess),
    catchError((error: AjaxError) => of(TelephoneRequestAction.getTelephoneRequestCarsFailure(error))),
  )),
)

const updateTelephoneRequestEpic: Epic<TelephoneRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof TelephoneRequestAction.updateTelephoneRequest>>(TelephoneRequestActionType.UpdateTelephoneRequest),
  switchMap(({ payload }) => updateTelephoneRequest(payload).pipe(
    map(TelephoneRequestAction.updateTelephoneRequestSuccess),
    catchError((error: AjaxError) => of(TelephoneRequestAction.updateTelephoneRequestFailure(error))),
  )),
)

const updateTelephoneRequestSuccessEpic: Epic<TelephoneRequestAction> = action$ => action$.pipe(
  ofType(TelephoneRequestActionType.UpdateTelephoneRequestSuccess),
  tap(() => {
    history.push(Paths.TelephoneRequests)
  }),
  ignoreElements(),
)

export const telephoneRequestEpic = combineEpics(
  changeListParamsEpic,
  changeFilterOrSearchPhraseEpic,
  getTelephoneRequestsEpic,
  getTelephoneRequestEpic,
  getNewTelephoneRequestCountEpic,
  getTelephoneRequestCarsEpic,
  updateTelephoneRequestEpic,
  updateTelephoneRequestSuccessEpic,
)
