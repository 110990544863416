import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { ApprovalsContent } from './dto'

export enum ApprovalsContentActionType {
  GetApprovalsContent = '[APPROVALS CONTENT] GET APPROVALS CONTENT',
  GetApprovalsContentFailure = '[APPROVALS CONTENT] GET APPROVALS CONTENT FAILURE',
  GetApprovalsContentSuccess = '[APPROVALS CONTENT] GET APPROVALS CONTENT SUCCESS',
}

export const ApprovalsContentAction = {
  getApprovalsContent: () => createAction(ApprovalsContentActionType.GetApprovalsContent),
  getApprovalsContentFailure: (error: AjaxError) => createAction(ApprovalsContentActionType.GetApprovalsContentFailure, error),
  getApprovalsContentSuccess: (approvalsContent: ApprovalsContent) =>
    createAction(ApprovalsContentActionType.GetApprovalsContentSuccess, approvalsContent),
}

export type ApprovalsContentAction = ActionsUnion<typeof ApprovalsContentAction>
