import { Client } from 'actff-bo-lib/client'
import { dayLocalYearFormat, formatDate, parseToDateOrNull } from 'actff-bo-lib/date'
import { Attachment, NominalString, Url } from 'actff-bo-lib/global'
import { User } from 'actff-bo-lib/user'
import { List } from 'immutable'

export type MessageId = NominalString<Message>

export enum MessageStatus {
  CLOSED = 'CLOSED',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
}

export enum MessagePayloadType {
  CLOSED = 'CLOSED',
  ECHO = 'ECHO',
  MESSAGE = 'MESSAGE',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
}

export type MessagePayload = {
  readonly type: MessagePayloadType,
  readonly message?: string,
  readonly attachments?: ReadonlyArray<Attachment>,
}

type MessageProps = {
  readonly attachments: ReadonlyArray<Url>,
  readonly type: MessagePayloadType,
  readonly msgUuid: MessageId,
  readonly message: string,
  readonly messageTime: Date,
  readonly statusTime: Date,
  readonly status: MessageStatus,
}

type ClientMessage = MessageProps & {
  readonly client: Client,
  readonly user: null,
}

type UserMessage = MessageProps & {
  readonly client: null,
  readonly user: User,
}

export type Message = ClientMessage | UserMessage

export const createMessage = (data: any): Message => ({ // tslint:disable-line no-any
  ...data,
  messageTime: parseToDateOrNull(data.messageTime),
  statusTime: parseToDateOrNull(data.statusTime),
})

const dateFormatter = formatDate(dayLocalYearFormat)

/*
* Groups a given collection of Chat Messages by days for Chat display by days:
* [Message, Message] -> {
*   '2022 January 04': [Message, Message],
*   '2022 February 14': [Message],
* }
* */
export const groupMessagesByDay = (messages: ReadonlyArray<Message> | undefined) =>
  messages
    ? List(messages)
      .groupBy(message => dateFormatter(message.messageTime))
      .toJSON()
    : {}
