import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { Client, ClientId } from 'actff-bo-lib/client'
import { AjaxError } from 'rxjs/ajax'

import { CarInfoDto, CarInfoWithClient } from '../dto'

export enum NewCarActionType {
  CreateCar = '[CAR] CREATE CAR',
  CreateCarFailure = '[CAR] CREATE CAR FAILURE',
  CreateCarSuccess = '[CAR] CREATE CAR SUCCESS',

  GetSelectedClient = '[CAR] GET SELECTED CLIENT',
  GetSelectedClientFailure = '[CAR] GET SELECTED CLIENT FAILURE',
  GetSelectedClientSuccess = '[CAR] GET SELECTED CLIENT SUCCESS',
}

export const NewCarAction = {
  createCar: (carDto: CarInfoDto, clientUuid: ClientId) => createAction(NewCarActionType.CreateCar, { carDto, clientUuid }),
  createCarFailure: (error: AjaxError) => createAction(NewCarActionType.CreateCarFailure, error),
  createCarSuccess: (car: CarInfoWithClient) => createAction(NewCarActionType.CreateCarSuccess, car),

  getSelectedClient: (uuid: ClientId) => createAction(NewCarActionType.GetSelectedClient, uuid),
  getSelectedClientFailure: (error: AjaxError) => createAction(NewCarActionType.GetSelectedClientFailure, error),
  getSelectedClientSuccess: (client: Client) => createAction(NewCarActionType.GetSelectedClientSuccess, client),

}

export type NewCarAction = ActionsUnion<typeof NewCarAction>
