import { ErrorMessage } from '@hookform/error-message'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell, FormCellWide, getDateValue, MarketValue, renderSelectController } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import {
  OpportunityOfferDecision,
  OpportunityOfferDeliveryType,
  OpportunityOfferPickUpType,
} from 'actff-bo-lib/crm/insurance'
import { SelectOption } from 'actff-bo-lib/global'
import { mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import { isAfter, isToday } from 'date-fns'
import * as React from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { PolicyReceivedCell, SectionForm, TextareaStyled } from '../Styled'

type Props = Testable
  & SectionFormType
  & {
    readonly isContactSucceed: boolean,
    readonly readOnly?: boolean,
  }

export const SectionOffer: React.FC<Props> = ({
  control,
  isContactSucceed,
  errors,
  expanded,
  register,
  setValue,
  testId,
  watch,
  readOnly,
}) => {
  const { t } = useTranslation()

  const offerDecisions = mapToOptions(Object.keys(OpportunityOfferDecision), 'crmInsurance.clientsDecision', t)
  const deliveryTypes = mapToOptions(Object.keys(OpportunityOfferDeliveryType), 'crmInsurance.deliveryType', t)
  const pickupTypes = mapToOptions(Object.keys(OpportunityOfferPickUpType), 'crmInsurance.pickupType', t)

  const clientsDecision = useWatch<OpportunityOfferDecision>({ control, name: 'offer.clientsDecision' })
  const pickupType = useWatch<OpportunityOfferPickUpType>({ control, name: 'offer.pickupType' })
  const newPolicyNumber = useWatch<number>({ control, name: 'newPolicy.number' })
  const marketValue = useWatch<number>({ control, name: 'offer.marketValue' })

  const isPickupDateRequired = () => clientsDecision === OpportunityOfferDecision.ISSUE_POLICY && 'caption.error.required'
  const isDueDateRequired = () => isContactSucceed && (clientsDecision !== OpportunityOfferDecision.REJECTED) && 'caption.error.required'
  const isOfferDecisionRequired = () => !!(pickupType || newPolicyNumber) && 'caption.error.required'
  const isDecisionDueDateRequired = () => !!marketValue && 'caption.error.required'
  const contactDateValidation = (date: Date) => date && isAfter(date, new Date()) && !isToday(date)
      ? 'caption.error.isAfterToday'
      : undefined

  React.useEffect(() => {
    clientsDecision === OpportunityOfferDecision.REJECTED && setValue('offer.pickupType', null)
  }, [clientsDecision, setValue])

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.offer'), !!errors.offer)}>
      <SectionForm>
        <FormCell className='datepicker-fullwidth' id='offer-dueDate'>
          <label>{t('crmInsurance.offer.dueDate')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                timeDisabled={true}
                date={getDateValue(value)}
                onChange={onChange}
                testId={`${testId}offer-dueDate`}
                disabled={readOnly}
              />
            )}
            rules={{ required: isDueDateRequired() }}
            control={control}
            name='offer.dueDate'
          />
          <ErrorMessage errors={errors} name={'offer.dueDate'} as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.offer.deliveryType')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...deliveryTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-offer_deliveryType`,
              transKey: 'crmInsurance.deliveryType',
            })}
            control={control}
            name='offer.deliveryType'
          />
          <ErrorMessage errors={errors} name='offer.deliveryType' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell/>
        <FormCell/>

        {/* marketValue */}
        <MarketValue
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          testId={testId}
          name='offer'
          readOnly={readOnly}
        />
        <FormCell />

        <FormCellWide>
          <label>{t('crmInsurance.offer.description')}:</label>
          <TextareaStyled name='offer.description' ref={register} disabled={readOnly} />
          <ErrorMessage errors={errors} name='offer.description' as={TranslatedErrorMessage} />
        </FormCellWide>
        <FormCellWide>
          <label>{t('crmInsurance.offer.importantInformation')}:</label>
          <TextareaStyled name='offer.importantInformation' ref={register} disabled={readOnly} />
          <ErrorMessage errors={errors} name='offer.importantInformation' as={TranslatedErrorMessage} />
        </FormCellWide>

        <FormCell className='datepicker-fullwidth' id='offer-decisionDueDate'>
          <label>{t('crmInsurance.offer.decisionDueDate')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                timeDisabled={true}
                date={getDateValue(value)}
                onChange={onChange}
                testId={`${testId}offer-decisionDueDate`}
                disabled={readOnly}
              />
            )}
            rules={{ required: isDecisionDueDateRequired() }}
            control={control}
            name='offer.decisionDueDate'
          />
          <ErrorMessage errors={errors} name='offer.decisionDueDate' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell className='datepicker-fullwidth'>
          <label>{t('crmInsurance.offer.contactDate')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                date={getDateValue(value)}
                onChange={onChange}
                testId={`${testId}offer-contactDate`}
                timeDisabled={true}
                disabled={readOnly}
              />
            )}
            rules={{ validate: contactDateValidation }}
            control={control}
            name='offer.contactDate'
          />
          <ErrorMessage errors={errors} name='offer.contactDate' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell id='offer-clientsDecision'>
          <label>{t('crmInsurance.offer.clientsDecision')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...offerDecisions] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-offer_decision`,
              transKey: 'crmInsurance.clientsDecision',
            })}
            control={control}
            rules={{ required: isOfferDecisionRequired() }}
            name='offer.clientsDecision'
          />
          <ErrorMessage errors={errors} name='offer.clientsDecision' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell />

        <FormCell className='datepicker-fullwidth' id='offer-pickupDate'>
          <label>{t('crmInsurance.offer.pickupDate')}:</label>
          <Controller
            render={({ onChange, value }) => (
              <DatePickerInput
                timeDisabled={true}
                date={getDateValue(value)}
                onChange={onChange}
                testId={`${testId}offer-pickupDate`}
                disabled={readOnly}
              />
            )}
            rules={{ required: isPickupDateRequired() }}
            control={control}
            name='offer.pickupDate'
          />
          <ErrorMessage errors={errors} name='offer.pickupDate' as={TranslatedErrorMessage} />
        </FormCell>
        <FormCell id='offer-pickupType'>
          <label>{t('crmInsurance.offer.pickupType')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly || clientsDecision === OpportunityOfferDecision.REJECTED,
              options: [...pickupTypes] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-pickupType`,
              transKey: 'crmInsurance.pickupType',
            })}
            rules={{ required: isPickupDateRequired() }}
            control={control}
            name='offer.pickupType'
          />
          <ErrorMessage errors={errors} name='offer.pickupType' as={TranslatedErrorMessage} />
        </FormCell>
        <PolicyReceivedCell>
          <label data-testid={`${testId}picked-up--label`}>{t('crmInsurance.offer.pickedUp')}:</label>
          <input
            data-testid={`${testId}picked-up`}
            id='picked-up'
            name='offer.pickedUp'
            ref={register}
            type='checkbox'
            disabled={readOnly}
          />
          <label
            data-testid={`${testId}picked-up--label-hidden`}
            htmlFor='picked-up'
          />
        </PolicyReceivedCell>
      </SectionForm>
    </CollapsableSection>
  )
}
