import styled from 'styled-components'
import { colors } from 'styles'

type ContainerProps = {
  readonly expanded: boolean,
}

export const Container = styled.div<ContainerProps>`
  display: ${({ expanded }: ContainerProps) => expanded ? 'block' : 'none'};
`

export const OpportunityListHeaderContainer = styled.div`
  background: ${colors.athensGray};
  border: 1px solid ${colors.mystic};
  display: grid;
  grid-auto-flow: column;
  justify-items: stretch;
  padding: 12px 32px;
`

export const OpportunityListHeader = styled.span`
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
`

export const OpportunityListExpandCollapseButton = styled.span`
  justify-self: right;
`
