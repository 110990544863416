import { ErrorMessage } from '@hookform/error-message'
import { BtnType } from 'actff-bo-app/components/Button'
import { FormCell, FormFooterRight } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Label } from 'actff-bo-app/components/Label'
import { CalendarResource, CalendarResourceType } from 'actff-bo-lib/admin'
import { createHumanResource, updateHumanResource } from 'actff-bo-lib/admin/employee-scheduler/dao'
import { Testable } from 'actff-bo-lib/global/testable'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QueryObserverResult } from 'react-query'
import { useDispatch } from 'react-redux'
import { LinkButton, PrimaryButton } from 'styles'
import { AddResourceModalFooter, FormContent } from './Styled'

type Props = Testable & {
  readonly resourceType: CalendarResourceType,
  readonly onClose: () => void,
  readonly onRefetch: () => Promise<QueryObserverResult>,
  readonly editMode: boolean,
  readonly resource?: CalendarResource,
}

export const EmployeeForm: FC<Props> = ({ resourceType, onClose, testId, onRefetch, resource, editMode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { handleSubmit, register, errors } = useForm<CalendarResource>({
    defaultValues: resource,
    mode: 'onChange',
  })

  const onSubmit = async (form: CalendarResource) => {
    try {
      if (editMode) {
        await updateHumanResource(form, resourceType, resource?.uuid)
      } else {
        await createHumanResource(form, resourceType)
      }
      onClose()
      dispatch(displaySuccessToast('admin.employeeSchedule.saveResource.success', ToastActionType.CREATE_OR_UPDATE))
      await onRefetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.employeeSchedule.saveResource.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>{t('admin.employeeSchedule.resourceModal.enterEmployeeData')}</p>
      <FormContent>

        <FormCell noPaddingLeft={true}>
          <Label>{t('firstName')}:</Label>
          <input
            type='text'
            name='firstName'
            ref={register({required: { value: true, message: 'form.validation.required' }})}
            data-testid={`${testId}firstName`}
            required={true}
          />
          <ErrorMessage errors={errors} name='firstName' as={TranslatedErrorMessage} />
        </FormCell>

        <FormCell noPaddingLeft={true}>
          <Label>{t('lastName')}:</Label>
          <input
            type='text'
            name='lastName'
            ref={register({required: { value: true, message: 'form.validation.required' }})}
            data-testid={`${testId}lastName`}
            required={true}
          />
          <ErrorMessage errors={errors} name='lastName' as={TranslatedErrorMessage} />
        </FormCell>

      </FormContent>

      <AddResourceModalFooter>
        <FormFooterRight>
          <LinkButton data-testid={`${testId}-button--cancel`} type={BtnType.Button} onClick={onClose}>
            {t('caption.cancel')}
          </LinkButton>
          <PrimaryButton data-testid={`${testId}-button--confirm`} type={BtnType.Submit} onClick={handleSubmit}>
            {t('caption.save')}
          </PrimaryButton>
        </FormFooterRight>
      </AddResourceModalFooter>
    </form>
  )
}
