import { FormFooter } from 'actff-bo-app/components/Form'
import styled from 'styled-components'

export const FormContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  padding-bottom: 16px;
`

export const AddResourceModalFooter = styled(FormFooter)`
  padding: 16px 0;
`
