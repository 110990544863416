import { ErrorMessage } from '@hookform/error-message'
import { BtnTheme } from 'actff-bo-app/components/Button'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { Item } from 'actff-bo-app/components/EditableList/Item'
import {
  AddingDialogContent,
  ButtonWithIcon,
  Container,
  Label,
  Table,
  Th,
} from 'actff-bo-app/components/EditableList/Styled'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { selectDealerBrands } from 'actff-bo-lib/dealership'
import { mapToOptions, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu'
import differenceBy from 'lodash/differenceBy'
import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Creatable from 'react-select/creatable'
import { Tr } from 'styles'

type Props = Testable & {
  readonly onAdd: (name: string) => void,
  readonly onDelete: (itemValue: string) => void,
  readonly brands: ReadonlyArray<string>,
}

type FormValues = {
  readonly name: string,
}

export const BrandsTable: FC<Props> = ({ onDelete, onAdd, brands, testId }) => {
  const { t } = useTranslation()

  const { data: adminBrands } = useSelector(selectDealerBrands)
  const availableBrands = differenceBy(adminBrands, brands)

  const [addNewItemOpen, setAddNewItemOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<string | null>(null)

  const {
    errors,
    handleSubmit,
    control,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
    mode: 'onSubmit',
  })

  const toggleAddDialogOpen = () => { setAddNewItemOpen(!addNewItemOpen) }

  const onDialogClose = () => {
    toggleAddDialogOpen()
  }

  const onDialogConfirm = (formValues: FormValues) => {
    onAdd(formValues.name)
    toggleAddDialogOpen()
  }

  const handleDelete = (item: string) => () => {
    setItemToDelete(item)
  }

  const onDeleteDialogConfirm = () => {
    if (!itemToDelete) { return }

    onDelete(itemToDelete)
    setItemToDelete(null)
  }

  const onDeleteDialogClose = () => {
    setItemToDelete(null)
  }

  const handleInputChange = (onChange: (value: string | null) => void) => (option: SelectOption<string>) => {
    option ? onChange(option.value) : onChange(null)
  }

  return (
    <form>
      <Container>
        <Table>
          <thead>
          <Tr>
            <Th>{t('caption.listNumber')}</Th>
            <Th>{t('admin.dealerLocations.brands')}</Th>
          </Tr>
          </thead>
          <tbody>
          {brands?.map((item, index) => (
            <Item
              key={item}
              item={item}
              index={index}
              onDelete={handleDelete(item)}
            />
          ))}
          </tbody>
        </Table>
        <ButtonWithIcon
          caption={t('admin.dealerLocations.brands.add')}
          onClick={toggleAddDialogOpen}
          iconType={IconType.Plus}
          theme={BtnTheme.Link}
        />
      </Container>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.add.content'
        open={addNewItemOpen}
        onConfirm={handleSubmit(onDialogConfirm)}
        onClose={onDialogClose}
        title='contentManagement.dialog.add.title'
      >
        <AddingDialogContent>
          <Label>{t('caption.name')}:</Label>
          <Controller
            render={({ onChange }) => (
              <Creatable
                allowCreateWhileLoading={true}
                formatCreateLabel={text => `${t('caption.use')} "${text}"?`}
                isClearable={true}
                isMulti={false}
                loadingMessage={() => `${t('caption.loading')}`}
                noOptionsMessage={() => `${t('caption.noResults')}`}
                options={mapToOptions(availableBrands) || []}
                onChange={handleInputChange(onChange)}
                placeholder={t('placeholder.choose')}
                testId={`${testId}--new-brand-name`}
              />
            )}
            rules={{ required: 'caption.error.required' }}
            control={control}
            name='name'
          />
          <ErrorMessage errors={errors} name='name' as={TranslatedErrorMessage} />
        </AddingDialogContent>
      </Dialog>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.delete.content'
        open={!!itemToDelete}
        onConfirm={onDeleteDialogConfirm}
        onClose={onDeleteDialogClose}
        title='contentManagement.dialog.delete.title'
      />
    </form>
  )
}
