import { Map } from 'immutable'

export enum OpportunityStatus {
  UNKNOWN = 'UNKNOWN',
  BEFORE_NOTIFICATION = 'BEFORE_NOTIFICATION',
  BEFORE_CONTACT = 'BEFORE_CONTACT',
  RETRY_CONTACT = 'RETRY_CONTACT',
  BEFORE_OFFER = 'BEFORE_OFFER',
  BEFORE_DECISION = 'BEFORE_DECISION',
  BEFORE_PICKUP = 'BEFORE_PICKUP',
  BEFORE_SIGNATURE = 'BEFORE_SIGNATURE',
  BEFORE_VERIFICATION = 'BEFORE_VERIFICATION',
  BEFORE_PAYMENT = 'BEFORE_PAYMENT',
  CLOSED = 'CLOSED',
}

export enum OpportunityResult {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  DEFINITIVE_FAILURE = 'DEFINITIVE_FAILURE',
}

export const OpportunityFailureResult: ReadonlyArray<OpportunityResult> = [
  OpportunityResult.FAILURE,
  OpportunityResult.DEFINITIVE_FAILURE,
]

export enum OpportunitySourceName {
  MANUAL = 'MANUAL',
  MOBILE = 'MOBILE',
  DMS = 'DMS',
  RENEWAL = 'RENEWAL',
}

export const OpportunitySourceAbbreviationsMap: Map<OpportunitySourceName, string> = Map([
  [OpportunitySourceName.MANUAL, 'M'],
  [OpportunitySourceName.MOBILE, 'A'],
  [OpportunitySourceName.DMS, 'D'],
  [OpportunitySourceName.RENEWAL, 'W'],
]) as Map<OpportunitySourceName, string>

export enum OpportunityFailureReason {
  NO_DATA = 'NO_DATA',
  REJECT_OFFER = 'REJECT_OFFER',
  NO_CONTACT = 'NO_CONTACT',
  POLICY_RESIGN = 'POLICY_RESIGN',
  BETTER_OFFER = 'BETTER_OFFER',
  LONGTERM_LEASE = 'LONGTERM_LEASE',
  OTHER_AGENT = 'OTHER_AGENT',
  TOO_LATE = 'TOO_LATE',
  LEASING_INSURANCE = 'LEASING_INSURANCE',
  OTHER = 'OTHER',
}

export enum OpportunityDefinitiveFailureReason {
  LACK_OF_APPROVALS = 'LACK_OF_APPROVALS',
  OWNER_CHANGE = 'OWNER_CHANGE',
  LARGE_FLEET = 'LARGE_FLEET',
  CFM_LONGTERM = 'CFM_LONGTERM',
}
