import { createGlobalStyle } from 'styled-components'
import { colors } from 'styles'

import { theme } from './theme'

export const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    margin: 0;
    padding: 0;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    line-height: normal;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 0;
    width: auto;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    color: currentColor;
    text-decoration: none;

    &:visited {
      color: currentColor;
    }
  }

  h1 {
    font-size: 36px;
    font-weight: normal;
  }

  h2 {
    font-size: 24px;
    font-weight: normal;
  }

  label {
    color: ${theme.form.label};
    font-weight: 600;
    text-transform: uppercase;
  }

  input {
    border: 1.5px solid ${theme.form.input.border};
    border-radius: 3px;
    color: ${theme.form.input.text};
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    height: 32px;
    padding: 7px 16px;
    outline: none;

    &.error {
      border: 1.5px solid ${colors.flamingoRed};
    }

    &:disabled {
      color: ${colors.dustyGray};
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    visibility: hidden;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    color: ${theme.form.radio.label};
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    padding-left: 28px;
    position: relative;
    text-transform: initial;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    background: #fff;
    border: 1px solid ${theme.form.radio.border};
    border-radius: 100%;
    content: '';
    height: 14px;
    left: 0;
    position: absolute;
    top: 0;
    width: 14px;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    border-radius: 100%;
    border: 4px solid ${theme.form.radio.checked};
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease;
    width: 8px;
  }

  [type="radio"]:disabled + label {
    color: ${colors.nepalBlue};
  }

  [type="radio"]:disabled + label:after {
    border: 4px solid ${colors.nepalBlue};
  }

  [type="radio"]:checked + .error:after {
    border: 4px solid ${colors.flamingoRed};
  }


  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    visibility: hidden;
  }

  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label {
    color: ${theme.form.checkbox.label};
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    padding-left: 22px;
    position: relative;
    text-transform: initial;
  }

  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    background: #fff;
    border: 1px solid ${theme.form.checkbox.border};
    border-radius: 4px;
    content: '';
    height: 14px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 14px;
  }

  [type="checkbox"]:checked + label:before {
    background: ${theme.form.checkbox.checkedBackground};
    border: 1px solid ${theme.form.checkbox.checkedBackground};
  }

  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 4px;
    position: absolute;
    top: 5px;
    left: 4px;
    border: 1px solid ${theme.form.checkbox.checked};
    border-top: none;
    border-right: none;
    background: ${theme.form.checkbox.checkedBackground};
    opacity: 0;

  }

  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  [type="checkbox"]:disabled + label {
    color: ${colors.dustyGray};
  }

  [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: rotate(-45deg) scale(1);
  }


  .ReactModalPortal {
    .ReactModal__Overlay {
      z-index: 1301 !important;
    }
  }

  .datepicker-fullwidth {
    .DayPickerInput {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
`
