import { ErrorMessage } from '@hookform/error-message'
import { FormCell, renderSelectController } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import {
  OpportunityDefinitiveFailureReason,
  OpportunityFailureReason,
  OpportunityFailureResult,
  OpportunityResult,
} from 'actff-bo-lib/crm/insurance'
import { mapToOptions, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = Pick<UseFormMethods, 'register' | 'control' | 'errors' | 'setValue' | 'watch'>
  & Testable
  & {
    readonly readOnly?: boolean,
  }

export const SectionProcessFailureReasons: React.FC<Props> = ({
  control,
  errors,
  watch,
  register,
  testId,
  readOnly,
}) => {
  const { t } = useTranslation()

  const opportunityResult = watch('process.result')

  const failureReasons = mapToOptions(Object.keys(OpportunityFailureReason), 'crmInsurance.failureReason', t)
  const definitiveFailureReasons = mapToOptions(Object.keys(OpportunityDefinitiveFailureReason), 'crmInsurance.failureReason', t)

  const getFailureReasons = () => opportunityResult === OpportunityResult.FAILURE ? failureReasons : definitiveFailureReasons

  const showOtherFailureReason = watch('process.failureReason') === OpportunityFailureReason.OTHER
    && watch('process.result') === OpportunityResult.FAILURE
  const showFailureReasons = OpportunityFailureResult.includes(opportunityResult)

  return (
    <>
      {showFailureReasons &&
        <FormCell id='process-failureReason'>
          <label>{t('crmInsurance.process.failureReason')}:</label>
          <Controller
            render={renderSelectController({
              disabled: readOnly,
              options: [...getFailureReasons()] as ReadonlyArray<SelectOption<string>>,
              t,
              testId: `${testId}-failureReason`,
              transKey: 'crmInsurance.failureReason',
            })}
            rules={{ required: 'caption.error.required' }}
            control={control}
            name='process.failureReason'
            disabled={readOnly}
          />
          <ErrorMessage errors={errors} name='process.failureReason' as={TranslatedErrorMessage} />
        </FormCell>
      }
      {showOtherFailureReason &&
        <FormCell>
          <label>{t('crmInsurance.process.failureOtherReason')}:</label>
          <input
            type='text'
            required={showOtherFailureReason}
            name='process.otherFailureReasonComment'
            ref={register}
            disabled={readOnly}
          />
        </FormCell>
      }
    </>
  )
}
