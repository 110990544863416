import { CalendarResourceType } from 'actff-bo-lib/admin'
import { axiosApi } from 'actff-bo-lib/api/axios-requests'
import { buildUrl } from 'actff-bo-lib/global'

import { CalendarResourceData, Holiday } from './dto'

export const getCalendar = (day: Date, type: CalendarResourceType) => {
  const date = day.toISOString()

  return axiosApi.get<ReadonlyArray<CalendarResourceData>>(
    buildUrl('/new-calendar/day-calendar/day', { date, type }),
  )
}

export const getHolidays = (year: number) => axiosApi.get<ReadonlyArray<Holiday>>(`/calendar/holidays/${year}`)

export const addHoliday = (holiday: Holiday) => axiosApi.post<ReadonlyArray<Holiday>, Holiday>('/calendar/holidays', holiday)

export const deleteHoliday = (holidayId?: number) => axiosApi.delete(`/calendar/holidays/${holidayId}`)
