import { Attachment } from 'actff-bo-app/components/Attachment'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Message, MessagePayloadType, MessageStatus } from 'actff-bo-lib/chat'
import { getFullName } from 'actff-bo-lib/client'
import { defaultDateTimeFormat, defaultTimeFormat, getLocale } from 'actff-bo-lib/date'
import { Url } from 'actff-bo-lib/global/attachment'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { format } from 'date-fns'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  ChatAttachmentsContainer,
  ChatAvatar,
  ChatConversationCloseMessageContainer,
  ChatConversationItem,
  ChatMessage,
  ChatMessageStatus,
  MessageTime,
} from './ChatConversationStyled'

type Props = {
  readonly isReceptionMessage: boolean,
  readonly message: Message,
}

type CurrentLanguage = {
  readonly currentLanguage: Language,
}

type ConversationAttachmentsProps = {
  readonly attachmentUrls: ReadonlyArray<Url>,
}

const ChatConversationAttachments: FC<ConversationAttachmentsProps> = ({ attachmentUrls }) => (
  <ChatAttachmentsContainer>
    {attachmentUrls.length > 0 && attachmentUrls.map(attachment => <Attachment key={attachment} url={attachment} />)}
  </ChatAttachmentsContainer>
)

const ChatConversationMessage: FC<Props> = ({ isReceptionMessage, message }) => {
  const { t } = useTranslation()
  const messageSender = message.client || message.user

  return (
    <ChatConversationItem receptionMessage={isReceptionMessage}>
      {messageSender &&
        <ChatAvatar
          firstName={messageSender.firstName}
          lastName={messageSender.lastName}
        />
      }
      <MessageTime><FormattedDate date={message.messageTime} format={defaultTimeFormat} /></MessageTime>
      <ChatMessage receptionMessage={isReceptionMessage}>
        {message.message}
        <ChatConversationAttachments attachmentUrls={message.attachments} />
      </ChatMessage>
      {message.status === MessageStatus.RECEIVED &&
        <ChatMessageStatus receptionMessage={isReceptionMessage}>
          {t('chat.message.status.received')}{format(message.statusTime, defaultDateTimeFormat)}
        </ChatMessageStatus>
      }
    </ChatConversationItem>
  )
}

const ChatConversationSystemMessage: FC<Props & CurrentLanguage> = ({ currentLanguage, message }) => {
  const locale = getLocale(currentLanguage)
  const { t } = useTranslation()

  return (
    <ChatConversationCloseMessageContainer>
      {format(message.messageTime, defaultDateTimeFormat, { locale })}
      &nbsp;-&nbsp;
      <strong>{t(message.message)}</strong>
    </ChatConversationCloseMessageContainer>
  )
}

const ChatConversationCloseMessage: FC<Props & CurrentLanguage> = ({ currentLanguage, message }) => {
  const locale = getLocale(currentLanguage)
  const user = message.client ? message.client : message.user && message.user
  const { t } = useTranslation()

  return (
    <ChatConversationCloseMessageContainer>
      {format(message.messageTime, defaultDateTimeFormat, { locale })}
      &nbsp;-&nbsp;
      <strong>{t('chat.closed')}</strong>&nbsp;{t('caption.by')}&nbsp;<strong>{getFullName(user)}</strong>
    </ChatConversationCloseMessageContainer>
  )
}

export const ChatConversationElement: FC<Props> = props => {
  const currentLanguage = useSelector(selectCurrentLanguage)

  switch (props.message.type) {
    case MessagePayloadType.SYSTEM_MESSAGE:
      return <ChatConversationSystemMessage {...props} currentLanguage={currentLanguage} />
    case MessagePayloadType.CLOSED:
      return <ChatConversationCloseMessage {...props} currentLanguage={currentLanguage} />
    default:
      return <ChatConversationMessage {...props} />
  }
}
