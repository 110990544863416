import { Popover } from '@material-ui/core'
import { Avatar, AvatarStyle } from 'actff-bo-app/components/Client'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { crmTradeActionsMap, OpportunityTradeId, TradeOpportunityType } from 'actff-bo-lib/crm/trade'
import { User, UserWithoutPermissions } from 'actff-bo-lib/user'
import { Map } from 'immutable'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { OpportunityAssignedPopoverContent } from './Assigned'
import { OpportunityAssignedToCurrentUserPopoverContent } from './AssignedToCurrentUser'
import { Divider, Link, PopoverContentContainer, Strong } from './Styled'
import { OpportunityUnassignedPopoverContent } from './Unassigned'

enum AssigneeStatus {
  Unassigned,
  Assigned,
  AssignedToCurrentUser,
}

type Props = {
  readonly assignee: UserWithoutPermissions | null,
  readonly currentUser: User | null,
  readonly opportunityId: OpportunityTradeId | null,
  readonly opportunityListType: OpportunityListType,
  readonly opportunityType: TradeOpportunityType,
  readonly userList: ReadonlyArray<UserWithoutPermissions> | null,
}

type PopoverContentProps = {
  readonly assignee?: UserWithoutPermissions | null,
  readonly handleClick?: () => void,
}

const getAssigneeStatus = ({ assignee, currentUser }: Pick<Props, 'assignee' | 'currentUser'>): AssigneeStatus =>
  assignee?.uuid === currentUser?.uuid
    ? AssigneeStatus.AssignedToCurrentUser
    : assignee
      ? AssigneeStatus.Assigned
      : AssigneeStatus.Unassigned

const assigneeStatusAvatarStyleMap = Map([
  [AssigneeStatus.Unassigned, AvatarStyle.Dashed],
  [AssigneeStatus.Assigned, AvatarStyle.Default],
  [AssigneeStatus.AssignedToCurrentUser, AvatarStyle.Filled],
]) as Map<AssigneeStatus, AvatarStyle>

const assigneeStatusPopoverContentMap = Map([
  [AssigneeStatus.Unassigned, OpportunityUnassignedPopoverContent],
  [AssigneeStatus.Assigned, OpportunityAssignedPopoverContent],
  [AssigneeStatus.AssignedToCurrentUser, OpportunityAssignedToCurrentUserPopoverContent],
]) as Map<AssigneeStatus, FC<PopoverContentProps>>

export const OpportunityAssign: FC<Props> = ({ assignee, currentUser, opportunityId, opportunityType, opportunityListType, userList }) => {
  const { t } = useTranslation()
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLDivElement | null>(null)
  const CrmTradeActions = crmTradeActionsMap.get(opportunityType)
  const dispatch = useDispatch()

  const assignToCurrentUser = () => {
    if (!currentUser) {
      return
    }

    const { disabled, permissions, ...userWithoutPermissions } = currentUser

    opportunityId
      && dispatch(CrmTradeActions.assignOpportunity(opportunityId, userWithoutPermissions, opportunityListType))
      && setPopoverAnchor(null)
  }

  const assign = (user: UserWithoutPermissions) => () => {
    opportunityId
      && dispatch(CrmTradeActions.assignOpportunity(opportunityId, user, opportunityListType))
      && setPopoverAnchor(null)
  }

  const unassign = () => {
    opportunityId
      && dispatch(CrmTradeActions.unassignOpportunity(opportunityId, opportunityListType))
      && setPopoverAnchor(null)
  }

  const assigneeStatusPopoverHandleClickFunctionMap = Map([
    [AssigneeStatus.Unassigned, { handleClick: assignToCurrentUser }],
    [AssigneeStatus.Assigned, { assignee, handleClick: assignToCurrentUser }],
    [AssigneeStatus.AssignedToCurrentUser, { handleClick: unassign }],
  ]) as Map<AssigneeStatus, PopoverContentProps>

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
    event.preventDefault()
  }

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(null)
    event.stopPropagation()
  }

  const handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }
  const assigneeStatus = getAssigneeStatus({ assignee, currentUser })
  const avatarStyle = assigneeStatusAvatarStyleMap.get(assigneeStatus)
  const open = Boolean(popoverAnchor)
  const PopoverContent = assigneeStatusPopoverContentMap.get(assigneeStatus)
  const popoverProps = assigneeStatusPopoverHandleClickFunctionMap.get(assigneeStatus)

  return (
    <>
      <div onClick={handleClick}>
        <Avatar
          style={avatarStyle}
          firstName={assignee?.firstName}
          lastName={assignee?.lastName}
        />
      </div>
      <Popover
        anchorEl={popoverAnchor}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
      >
        <PopoverContentContainer onClick={handlePopoverClick}>
          {PopoverContent && <PopoverContent {...popoverProps} />}
          <Divider />
          <Strong>{t('opportunity.assignTo')}</Strong>
          {userList?.map(user => (
            <Link
              onClick={assign(user)}
              noPadding={true}
              noHeight={true}
              textAlign='left'
              key={user.uuid}
            >
              {user.firstName} {user.lastName}
            </Link>
          ))}
        </PopoverContentContainer>
      </Popover>
    </>
  )
}
