import { InspectionPaintCoatFormElement } from 'actff-bo-lib/car/helpers'
import {
  Attachment,
  AttachmentAction,
  AttachmentActionType,
  AttachmentUploadType,
  haveAttachmentsToUpload,
  isIdentifiedUrl,
  Url,
  UrlIdentificationKeyType,
} from 'actff-bo-lib/global'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { redirectToList } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { iif, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

import { CarInspectionAction, CarInspectionActionType } from '../actions'
import {
  createInspection,
  getCarInspections,
  getExistingCarInspection,
  getNewCarInspection,
  updateInspection,
} from '../dao'
import {
  CarId,
  Inspection,
  InspectionCommentDto,
  InspectionCommentType,
  InspectionDto,
  InspectionId,
  InspectionPaintCoatElementType,
} from '../dto'
import { selectCurrentCarData, selectCurrentInspectionsPage, selectIsNdcCar } from '../selectors'

type InspectionOrAttachmentAction = CarInspectionAction | AttachmentAction

// TODO: Make proper change list for NDC cars (with VIN instead of carId)
const changeListParamsEpic: Epic<CarInspectionAction, CarInspectionAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.changePage>>(CarInspectionActionType.ChangePage),
  withLatestFrom(state$),
  map(([, state]) => [selectCurrentCarData(state)?.uuid, state]),
  filter<[CarId, State]>((carId, state) => Boolean(carId) && !![Boolean(carId), state]),
  tap(([carId, state]) => {
    redirectToList(createRoute(Paths.CarViewInspections, { carId }), selectCurrentInspectionsPage(state))
  }),
  map(CarInspectionAction.getInspections),
)

const getExistingInspectionEpic: Epic<CarInspectionAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.getInspection>>(CarInspectionActionType.GetInspection),
  filter(({ payload }) => !!payload.inspectionId),
  switchMap(({ payload }) => getExistingCarInspection(payload.vin, payload.inspectionId as InspectionId)
    .pipe(
      map(CarInspectionAction.getInspectionSuccess),
      catchError((error: AjaxError) => of(CarInspectionAction.getInspectionFailure(error)),
    )),
))

const getNewInspectionEpic: Epic<CarInspectionAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.getInspection>>(CarInspectionActionType.GetInspection),
  filter(({ payload }) => !payload.inspectionId),
  switchMap(() => getNewCarInspection()
    .pipe(
      map(CarInspectionAction.getInspectionSuccess),
      catchError((error: AjaxError) => of(CarInspectionAction.getInspectionFailure(error)),
    )),
))

const getInspectionsEpic: Epic<CarInspectionAction, CarInspectionAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.getInspections>>(CarInspectionActionType.GetInspections),
  withLatestFrom(state$),
  map(([, state]) => [selectCurrentCarData(state)?.vin, state]),
  filter<[CarId, State]>((vin, state) => Boolean(vin) && !![Boolean(vin), state]),
  switchMap(([vin, state]) => getCarInspections(
      vin,
      selectCurrentInspectionsPage(state) - 1,
    )
    .pipe(
      map(CarInspectionAction.getInspectionsSuccess),
      catchError((error: AjaxError) => of(CarInspectionAction.getInspectionsFailure(error)),
    )),
))

const redirectOnInspectionCreateOrUpdateEpic: Epic<CarInspectionAction> = (action$, state$) => action$.pipe(
  ofType(CarInspectionActionType.CreateInspectionSuccess, CarInspectionActionType.UpdateInspectionSuccess),
  withLatestFrom(state$),
  tap(([, store]) => {
    if (selectIsNdcCar(store)) {
      history.push(createRoute(Paths.NdcCarViewInspections, { vin: selectCurrentCarData(store)?.vin }))

      return
    }

    history.push(createRoute(Paths.CarViewInspections, { carId: selectCurrentCarData(store)?.uuid }))
  }),
  ignoreElements(),
)

const createInspectionAndSendAttachmentsEpic: Epic<InspectionOrAttachmentAction, InspectionOrAttachmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.createInspectionAndSendAttachments>>(
    CarInspectionActionType.CreateInspectionAndSendAttachments,
  ),
  mergeMap(({ payload: { attachments, inspection, vin } }) =>
    iif(
      () => haveAttachmentsToUpload(attachments),
      of(
        AttachmentAction.sendAttachments<InspectionDto | string>(
          attachments as ReadonlyArray<Attachment>,
          AttachmentUploadType.Inspection,
          { inspection, vin }),
      ),
      of(CarInspectionAction.createInspection(vin, inspection)),
    ),
))

const createInspectionEpic: Epic<CarInspectionAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.createInspection>>(CarInspectionActionType.CreateInspection),
  switchMap(({ payload: { vin, inspection } }) => createInspection(vin, inspection).pipe(
    map(CarInspectionAction.createInspectionSuccess),
    catchError((error: AjaxError) => of(CarInspectionAction.createInspectionFailure(error))),
  )),
)

const updateInspectionAndSendAttachmentsEpic: Epic<InspectionOrAttachmentAction, InspectionOrAttachmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.updateInspectionAndSendAttachments>>(
    CarInspectionActionType.UpdateInspectionAndSendAttachments,
  ),
  mergeMap(({ payload: { attachments, inspection, inspectionId, vin } }) => iif(
    () => haveAttachmentsToUpload(attachments),
    of(AttachmentAction.sendAttachments<InspectionDto | InspectionId | string>(
      attachments as ReadonlyArray<Attachment>,
      AttachmentUploadType.Inspection,
      { inspection, inspectionId, vin },
    )),
    of(CarInspectionAction.updateInspection(vin, inspectionId, inspection)),
  )),
)

const updateInspectionEpic: Epic<CarInspectionAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CarInspectionAction.updateInspection>>(CarInspectionActionType.UpdateInspection),
  switchMap(({ payload: { inspection, inspectionId, vin } }) => updateInspection(vin, inspectionId, inspection).pipe(
    map(CarInspectionAction.updateInspectionSuccess),
    catchError((error: AjaxError) => of(CarInspectionAction.updateInspectionFailure(error))),
  )),
)

const uploadAttachmentsSuccessEpic: Epic<InspectionOrAttachmentAction, CarInspectionAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AttachmentAction.sendAttachmentsComplete>>(AttachmentActionType.SendAttachmentsComplete),
  pluck('payload'),
  filter(({ type }) => type === AttachmentUploadType.Inspection),
  map(({ urls, props: { inspection, inspectionId, vin } }) => {
    const identifiedInspection: Inspection = inspection as Inspection
    const paintCoatUrls = urls
      .filter(isIdentifiedUrl)
      .filter(iUrl =>
        Object.values<UrlIdentificationKeyType>(InspectionPaintCoatElementType).includes(iUrl.key),
      )

    const commentUrls = urls
      .filter(isIdentifiedUrl)
      .filter(iUrl => Object.values<UrlIdentificationKeyType>(InspectionCommentType).includes(iUrl.key))

    const inspectionUrls = urls.filter(url => !isIdentifiedUrl(url))

    return {
      inspection: {
        ...identifiedInspection,
        ...(identifiedInspection.comments && { comments: identifiedInspection.comments && identifiedInspection.comments
            .map(({ attachments, ...rest }: InspectionCommentDto<Url>) => ({
              attachments: commentUrls.filter(url => url.key === rest.section).map(url => url.url),
              ...rest,
            }))}),
        paintCoat: identifiedInspection.paintCoat
          .map((paintCoat: InspectionPaintCoatFormElement) => {
            const { attachments, evaluation, ...restPaintCoat } = paintCoat

            return {
              ...restPaintCoat,
              photos: [
                ...(restPaintCoat.photos || []),
                ...paintCoatUrls.filter(url => url.key === paintCoat.key).map(url => url.url),
              ],
            }
          }),
        photos: inspectionUrls,
      },
      inspectionId,
      vin,
    }
  }),
  mergeMap(({ inspection, inspectionId, vin }) => iif(
    () => !!inspectionId,
    of(CarInspectionAction.updateInspection(vin as string, inspectionId as InspectionId, inspection as InspectionDto)),
    of(CarInspectionAction.createInspection(vin as string, inspection as InspectionDto)),
  )),
)

export const carInspectionEpic = combineEpics(
  changeListParamsEpic,
  createInspectionEpic,
  createInspectionAndSendAttachmentsEpic,
  getExistingInspectionEpic,
  getNewInspectionEpic,
  getInspectionsEpic,
  redirectOnInspectionCreateOrUpdateEpic,
  updateInspectionEpic,
  updateInspectionAndSendAttachmentsEpic,
  uploadAttachmentsSuccessEpic,
)
