import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectClientFeature = (state: State) => state.client

export const selectClientListSearchPhrase =
  createSelector(selectClientFeature, client => client.list.searchPhrase)

export const selectCurrentFilters =
  createSelector(selectClientFeature, client => client.filters)

export const selectCurrentPage =
  createSelector(selectClientFeature, client => client.currentPage)

export const selectClientsNoOfPages =
  createSelector(selectClientFeature, client => client.list.data?.noOfPages)

export const selectCurrentContactApprovals =
  createSelector(selectClientFeature, client => client.currentContactApprovals.data)

export const selectIsLoadingContactApprovals =
  createSelector(selectClientFeature, client => client.currentContactApprovals.loading)

export const selectCurrentClient =
  createSelector(selectClientFeature, client => client.currentClient.data)

export const selectCurrentClientMatchedSaleOpportunity =
  createSelector(selectClientFeature, client => client.currentClientMatchedSaleOpportunity.data)

export const selectCurrentClientMatchedPurchaseOpportunities =
  createSelector(selectClientFeature, client => client.currentClientMatchedPurchaseOpportunities.data)

export const selectCurrentClientCars = createSelector(selectClientFeature, client => client.currentClientCars)

export const selectCurrentClientCarsPage = createSelector(selectClientFeature, client => client.currentClientCarsPage)

export const selectClients =
  createSelector(selectClientFeature, client => client.list)

export const selectClientsData =
  createSelector(selectClientFeature, client => client.list.data)

export const selectPersonalDataHistory =
  createSelector(selectClientFeature, client => client.currentClientPersonalDataHistory.data)

export const selectClientPreferences =
  createSelector(selectClientFeature, client => client.currentClientPreferences)

export const selectClientCount = createSelector(selectClientFeature, client => client.clientCount)

export const selectIsLoadingClient =
  createSelector(selectClientFeature, client => client.currentClient.loading)

export const selectIsLoadingClients =
  createSelector(selectClientFeature, client => client.list.loading)
