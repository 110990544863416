import { SpanStyled } from 'actff-bo-app/Cars/CarView/Styled'
import { Observer } from 'actff-bo-app/components/Observer'
import { getCarLiquids } from 'actff-bo-lib/admin/brands/dao'
import { CarLiquids } from 'actff-bo-lib/admin/brands/dto'
import { CarEngineCode, CarEngineFuelType, CarId, CarInfoWithClient } from 'actff-bo-lib/car'
import { CarModel } from 'actff-bo-lib/dealership'
import { mapValuesToSelectOptions } from 'actff-bo-lib/global'
import { Language } from 'actff-bo-lib/i18n'
import { UserPermissions } from 'actff-bo-lib/user'
import { hasPermission } from 'actff-bo-lib/user/has-permission'
import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { withCarViewHeader } from '../withCarViewHeader'
import { CarViewServiceForm } from './CarViewServiceForm'
import { CarViewServiceReadonly } from './CarViewServiceReadonly'
import { DispatchToProps, mapDispatchToProps, mapStateToProps, StateToProps } from './Redux'

type InternalState = {
  readonly currentBrand: string | null,
  readonly currentEngineCode: string | null,
  readonly currentBrakeLiquid: string | null,
  readonly currentFuelType: CarEngineFuelType,
  readonly currentEngineOil: string,
  readonly currentCarModel: CarModel | null,
  readonly currentCarVersion: string | null,
  readonly carLiquids: ReadonlyArray<CarLiquids> | null,
}

type CarViewServiceComponentProps = {
  readonly car: CarInfoWithClient,
  readonly currentLanguage: Language,
  readonly rejectCar: (carId: CarId) => void,
  readonly deleteCar: (carId: CarId) => void,
  readonly revertCar: (carId: CarId) => void,
}

export type CarViewServiceProps = InternalState & StateToProps & DispatchToProps & WithTranslation & CarViewServiceComponentProps

class CarViewServiceComponent extends React.Component<CarViewServiceProps> {
  public static getDerivedStateFromProps(nextProps: CarViewServiceProps, prevState: InternalState): InternalState {
    if (!nextProps.oilTypes) { return prevState }

    const nextOilType = nextProps.oilTypes.length > 0 ? nextProps.oilTypes[0] : ''
    if (nextOilType !== prevState.currentEngineOil) {
      return {
        ...prevState,
        currentEngineOil: nextOilType,
      }
    }

    return prevState
  }

  public readonly state: InternalState = {
    carLiquids: null,
    currentBrakeLiquid: null,
    currentBrand: null,
    currentCarModel: null,
    currentCarVersion: null,
    currentEngineCode: null,
    currentEngineOil: '',
    currentFuelType: CarEngineFuelType.NA,
  }

  public componentDidMount(): void {
    const { car } = this.props
    const { brand, engineCode } = car

    if (brand) {
      this.props.getCarModels(brand)
      this.getCarEngineCodes(brand)
      this.props.getBrakeLiquids(brand)
      this.getOilTypes(brand, engineCode)
      this.props.getCarVersions(brand)
    }

    this.props.getFuelTypes()
    this.props.getTireTypes()

    this.setState({
      currentBrakeLiquid: car.serviceDetails?.liquids.brakeLiquid,
      currentBrand: car.brand,
      currentCarModel: car.model,
      currentCarVersion: car.version,
      currentEngineCode: car.engineCode,
      currentEngineOil: car.serviceDetails?.liquids.engineOil,
      currentFuelType: car.fuel || CarEngineFuelType.NA,
    })
  }

  public componentWillUnmount(): void {
    this.props.clearOilTypes()
    this.props.clearBrakeLiquids()
    this.props.clearCarVersions()
  }

  public render(): React.ReactNode {
    const {
      brakeLiquids,
      car,
      carAttachments,
      carModels,
      carVersions,
      dealerBrands,
      checkRegistrationNumberExistence,
      checkVinExistence,
      deleteCar,
      oilTypes,
      currentCarRegistrationNumberExists,
      currentCarVinExists,
      rejectCar,
      revertCar,
      updateCar,
      userPermissions,
      t,
    } = this.props

    const { data: brakeLiquidsList } = brakeLiquids
    const {
      currentBrand,
      currentCarModel,
      currentCarVersion,
      currentEngineCode,
      currentFuelType,
      currentBrakeLiquid,
      currentEngineOil,
      carLiquids,
    } = this.state

    if (hasPermission([
      UserPermissions.AdminAllService,
      UserPermissions.ServiceEditService,
      UserPermissions.InsuranceEditService,
      UserPermissions.FinanceEditService,
    ])(userPermissions)) {
      return (
        <>
          <CarViewServiceForm
            brakeLiquid={currentBrakeLiquid}
            brakeLiquids={brakeLiquidsList}
            dealerBrands={dealerBrands}
            car={car}
            carAttachments={carAttachments}
            carModels={mapValuesToSelectOptions(carModels)}
            carVersions={mapValuesToSelectOptions(carVersions.data)}
            brand={currentBrand}
            checkRegistrationNumberExistence={checkRegistrationNumberExistence}
            checkVinExistence={checkVinExistence}
            currentCarRegistrationNumberExists={currentCarRegistrationNumberExists}
            currentCarVinExists={currentCarVinExists}
            carModel={currentCarModel}
            carVersion={currentCarVersion}
            deleteCar={deleteCar}
            engineCode={currentEngineCode}
            engineCodes={mapValuesToSelectOptions(carLiquids?.map(carLiquid => carLiquid.engineCode))}
            fuelType={currentFuelType}
            onCarModelChange={this.handleCarModelChange}
            onBrandChange={this.handleBrandChange}
            onBrakeLiquidChange={this.handleBrakeLiquidChange}
            onCarVersionChange={this.handleCarVersionChange}
            onEngineCodeChange={this.handleEngineCodeChange}
            engineOil={currentEngineOil}
            oilTypes={mapValuesToSelectOptions(oilTypes)}
            onFuelTypeChange={this.handleFuelTypeChange}
            onOilTypeChange={this.handleOilTypeChange}
            rejectCar={rejectCar}
            revertCar={revertCar}
            updateCar={updateCar}
          />
          <Observer value={currentEngineCode} didUpdate={this.handleEngineCodeUpdate} />
          <Observer value={brakeLiquids.data} didUpdate={this.handleBrakeLiquidsUpdate} />
          <Observer value={oilTypes} didUpdate={this.handleOilTypesUpdate} />
          <Observer value={carLiquids} didUpdate={this.handleEngineCodesUpdate} />
        </>
      )
    }

    if (!this.hasCarServiceDetails()) {
      return <SpanStyled>{t('carView.form.service.noData')}</SpanStyled>
    }

    return (
      <CarViewServiceReadonly
        car={car}
        carAttachments={carAttachments}
        carModels={mapValuesToSelectOptions(carModels)}
        fuelTypes={[]}
        oilTypes={mapValuesToSelectOptions(oilTypes)}
      />
    )
  }

  private readonly hasCarServiceDetails = () => this.props.car && this.props.car.serviceDetails

  private readonly getOilTypes = (brand: string | null, engineCode: CarEngineCode) => {
    this.props.getOilTypes(brand, engineCode)
  }

  private readonly getAndSaveCarLiquids = (brand: string) => {
    getCarLiquids([brand])
      .then(carLiquids => {
        this.setState({
          carLiquids,
        })
      }).catch(() => { this.props.showFailureToast() })
  }

  private readonly getCarEngineCodes = (brand: string) => {
    this.getAndSaveCarLiquids(brand)
  }

  private readonly handleCarModelChange = (carModel: CarModel) => {
    this.setState({ currentCarModel: carModel })
  }

  private readonly handleBrandChange = (brand: string) => {
    this.setState({
      carLiquids: null,
      currentBrakeLiquid: '',
      currentBrand: brand,
      currentCarModel: null,
      currentCarVersion: null,
      currentEngineCode: null,
      currentEngineOil: '',
      currentFuelTypes: [],
    })
    this.getAndSaveCarLiquids(brand)
    this.props.getBrakeLiquids(brand)
    this.getCarEngineCodes(brand)
    this.props.getOilTypes(brand, this.state.currentEngineCode)
    this.props.getCarModels(brand)
    this.props.getCarVersions(brand)
  }

  private readonly handleMapEngineCodeToFuelType = (engineCode: CarEngineCode) => {
    this.setState({
      currentFuelType: this.state.carLiquids?.find(liquid => liquid.engineCode === engineCode)?.fuelType || null,
    })
  }

  private readonly handleBrakeLiquidChange = (brakeLiquid: string) => {
    this.setState({ currentBrakeLiquid: brakeLiquid })
  }

  private readonly handleCarVersionChange = (version: string) => {
    this.setState({ currentCarVersion: version })
  }

  private readonly handleEngineCodeChange = (engineCode: string) => {
    this.setState({
      currentEngineCode: engineCode,
      currentEngineOil: null,
      currentFuelType: null,
    })
    this.handleMapEngineCodeToFuelType(engineCode)
    this.getOilTypes(this.state.currentBrand, engineCode)
  }

  private readonly handleBrakeLiquidsUpdate = () => {
    if (this.props.brakeLiquids.data?.length === 1) {
      this.setState({
        currentBrakeLiquid: this.props.brakeLiquids.data[0],
      })
    }
  }

  private readonly handleOilTypesUpdate = () => {
    if (this.props.oilTypes?.length === 1) {
      this.setState({ currentEngineOil: this.props.oilTypes[0] })
    }
  }

  private readonly handleEngineCodeUpdate = (newEngineCode: string) => {
    if (newEngineCode !== null && newEngineCode !== this.props.car.engineCode) {
      this.setState({ currentEngineOil: null })
    }
  }

  private readonly handleEngineCodesUpdate = () => {
    this.getOilTypes(this.state.currentBrand, this.state.currentEngineCode ?? '')
  }

  private readonly handleFuelTypeChange = (fuelType: CarEngineFuelType) => {
    this.setState({ currentFuelType: fuelType })
  }

  private readonly handleOilTypeChange = (oilType: string) => {
    this.setState({ currentEngineOil: oilType })
  }
}

export const CarViewService = compose(
  withCarViewHeader,
  connect(mapStateToProps, mapDispatchToProps),
)(CarViewServiceComponent)
