import { combineReducers } from 'redux'
import { opportunityPurchaseReducer, OpportunityPurchaseState } from './purchase'
import { opportunitySaleReducer, OpportunitySaleState } from './sale'

export type CrmTradeState = {
  readonly purchase: OpportunityPurchaseState,
  readonly sale: OpportunitySaleState,
}

export const crmTradeReducer = combineReducers<CrmTradeState>({
  purchase: opportunityPurchaseReducer,
  sale: opportunitySaleReducer,
})
