import { Label } from 'actff-bo-app/components/Label'
import { Counterparty, PersonAddress } from 'actff-bo-lib/car/dto'
import React, { FC } from 'react'
import { LabelStyled, Td, Tr } from '../../Styled'

type Props = {
  readonly onClick: (person: Counterparty) => void,
  readonly person: Counterparty,
  readonly selected: boolean,
  readonly testId: string,
}

const getPersonAddress = (address: PersonAddress) =>
  address && <>{address.streetPrefix} {address.street}, {address.postCode} {address.city}</>

export const CounterpartyRow: FC<Props> = ({ onClick, selected, person, testId }) => (
  <Tr onClick={onClick} selected={selected}>
    <Td dataTestId={`${testId}-selected`}><Label><input type='radio' readOnly={true} checked={selected} /><LabelStyled /></Label> </Td>
    <Td dataTestId={`${testId}-full-name`}>{person.name}</Td>
    <Td dataTestId={`${testId}-phone`}>{person.phone}</Td>
    <Td dataTestId={`${testId}-email`}>{person.email}</Td>
    <Td dataTestId={`${testId}-id`}>{person.id}</Td>
    <Td dataTestId={`${testId}-nip`}>{person.nip}</Td>
    <Td dataTestId={`${testId}-address`}>{getPersonAddress(person.address)}</Td>
  </Tr>
)
