import { api } from 'actff-bo-lib/api'
import { Observable } from 'rxjs'

import { Attachment, AttachmentUploadType, BlobUrl, Url } from './dto'

export const getAttachment = (url: Url) => api.getObjectUrl(url) as Observable<BlobUrl>

// tslint:disable-next-line:cyclomatic-complexity
export const sendAttachment = (attachment: Attachment, type: AttachmentUploadType, props: any) => { // tslint:disable-line no-any
  const body = new FormData()
  body.append('file', attachment.file)

  switch (type) {
    case AttachmentUploadType.OpportunityPurchaseEvent:
      return api.post<Url, FormData>(`/cdn/purchase_opportunity/${props.opportunityId}/attachment`, body, undefined, true)
    case AttachmentUploadType.OpportunitySaleEvent:
      return api.post<Url, FormData>(`/cdn/sale_opportunity/${props.opportunityId}/attachment`, body, undefined, true)
    case AttachmentUploadType.OpportunityComment:
      return api.post<Url, FormData>(`/cdn/opportunity/${props.uuid}/attachment`, body, undefined, true)
    default:
    case AttachmentUploadType.PaintCoat:
    case AttachmentUploadType.Inspection:
      return api.post<Url, FormData>(`/cdn/inspection/${props.vin}/attachment`, body, undefined, true)
  }
}
