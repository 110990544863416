import { CarViewFinanceFormValues } from 'actff-bo-app/Cars/CarView/CarViewFinance/CarViewFinanceForm'
import { CarViewInsuranceFormValues } from 'actff-bo-app/Cars/CarView/CarViewInsurance/withFormik'
import { CarViewServiceFormValues } from 'actff-bo-app/Cars/CarView/CarViewService/withFormik'
import { toPriceString } from 'actff-bo-lib/currency'
import { getOrElse } from 'actff-bo-lib/global/data'

import {
  CarEngineFuelType,
  CarFinance,
  CarFinanceWithCarUuid,
  CarId,
  CarInfoDto,
  CarInfoWithClient,
  CarInsurance,
  CarInsuranceWithCarUuid,
  CarStatus,
} from '../dto'

// Car
export const carFormDataToCarInfoDto = (
  car: CarInfoWithClient,
  fuel: CarEngineFuelType,
  status: CarStatus,
  formValues: CarViewServiceFormValues,
): CarInfoDto => ({
  brand: formValues.brand,
  carReleaseDate: formValues.carReleaseDate,
  dms: formValues.dms,
  engineCode: formValues.engineCode,
  firstRegistrationDate: formValues.firstRegistrationDate,
  fuel,
  model: getOrElse(formValues.carModel, '') as string,
  productionYear: car.productionYear,
  registrationNumber: formValues.registration.toUpperCase(),
  serviceDetails: {
    guarantee: {
      basicGuaranteeExpirationDate: formValues.basicGuaranteeExpirationDate,
      dealerGuaranteeExpirationDate: formValues.dealerGuaranteeExpirationDate,
      dealerGuaranteeVisible: formValues.dealerGuaranteeVisible,
      extendedGuaranteeExpirationDate: formValues.extendedGuaranteeExpirationDate,
    },
    lastServiceDate: formValues.lastServiceDate,
    liquids: {
      brakeLiquid: getOrElse(formValues.brakeLiquid, '') as string,
      engineOil: getOrElse(formValues.engineOil, '') as string,
    },
    mot: formValues.mot,
  },
  status,
  uuid: car.uuid,
  version: formValues.carVersion && formValues.carVersion.toUpperCase(),
  vin: formValues.vin.toUpperCase(),
})

// CarFinance
export const carFinanceFormDataToCarFinanceDto = (
  carUuid: CarId,
  carFinance: CarFinance,
  formValues: CarViewFinanceFormValues): CarFinanceWithCarUuid => ({
  ...carFinance,
  amount: formValues.amount,
  amountType: formValues.amountType,
  carUuid,
  currency: formValues.currency,
  financeEndDate: formValues.financeEndDate,
  financeType: formValues.financeType,
  inHouse: formValues.inHouse,
  lessorName: formValues.lessorName.toUpperCase(),
})

// CarInsurance
export const carInsuranceFormDataToDto = (
  carUuid: CarId,
  carInsurance: Omit<CarInsurance, 'insuranceValue' | 'uuid'>,
  formValues: CarViewInsuranceFormValues): CarInsuranceWithCarUuid => ({
  ...carInsurance,
  ac: formValues.ac,
  assistance: formValues.assistance,
  assistanceInHouse: {
    ...carInsurance.assistanceInHouse,
    expirationDate: formValues?.inHouseExpirationDate,
    insurer: formValues?.inHouseInsurer?.toUpperCase(),
    policyNumber: formValues?.inHousePolicyNumber?.toUpperCase(),
  },
  carUuid,
  expirationDate: formValues.manufacturerExpirationDate,
  inHouse: formValues.inHouse,
  insurer: formValues.manufacturerInsurer,
  manufacturerAssistance: formValues.manufacturerAssistance,
  marketValue: +toPriceString(formValues.marketValue?.toString() || '0'),
  nnw: formValues.nnw,
  oc: formValues.oc,
  valueForInsuranceCalculation: formValues.valueForInsuranceCalculation,
})
