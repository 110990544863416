import { TextareaAutosize } from '@material-ui/core'
import { BtnType } from 'actff-bo-app/components/Button'
import { getFullName } from 'actff-bo-lib/client/helpers'
import { OpportunityInternalComment } from 'actff-bo-lib/crm'
import { defaultDateTimeFormat, defaultLocale } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { format } from 'date-fns'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, PrimaryButton } from 'styles'

type Props = Testable & {
  readonly comments: ReadonlyArray<OpportunityInternalComment>,
  readonly onSend: (newComment: string) => void,
}

export const Container = styled.div`
  display: grid;
  grid-template-rows: 4fr auto;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 65px;
`

export const CommentHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 10px 0;
`

const CommentContent = styled.div`
  font-style: italic;
  font-weight: 300;
  line-height: 23px;
`

const CommentsFooter = styled.div`
  align-items: self-start;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 10px;
  grid-template-rows: auto 60px;
`

const TextareaAutosizeStyled = styled(TextareaAutosize)`
  border-radius: 5px;
  border: 1.5px solid ${colors.whisperBlue};
  box-sizing: border-box;
  max-height: 300px;
  padding: 10px;
  resize: none;
`

const SendCommentButton = styled(PrimaryButton)`
  height: 35px;
`

const TextAreaCommentRowsMin = 2
const TextAreaCommentRowsMax = 6

export const Comments: FC<Props> = ({ comments, onSend, testId }) => {
  const { t } = useTranslation()
  const [newComment, setNewComment] = useState('')
  const isValid = newComment !== ''

  const handleTextareaChange = (e: React.FormEvent<HTMLInputElement>) => {
    setNewComment(e.currentTarget.value)
  }

  const handleOnSendClick = () => {
    if (isValid) {
      onSend(newComment)
      setNewComment('')
    }
  }

  return (
    <Container>
      <div>
        {
          comments.slice().reverse().map((comment, index) => (
            <div data-testid={`${testId}-comment-${index}`} key={index}>
              <CommentHeader>
                <span>{getFullName(comment.author)}</span>
                <span>{format(comment.created, defaultDateTimeFormat, { locale: defaultLocale })}</span>
              </CommentHeader>
              <CommentContent>{comment.comment}</CommentContent>
            </div>
          ))
        }
      </div>
      <CommentsFooter>
        <TextareaAutosizeStyled
          onChange={handleTextareaChange}
          rowsMin={TextAreaCommentRowsMin}
          rowsMax={TextAreaCommentRowsMax}
          placeholder=''
          value={newComment}
        />
        <SendCommentButton disabled={!isValid} onClick={handleOnSendClick} type={BtnType.Button}>{t('caption.send')}</SendCommentButton>
      </CommentsFooter>
    </Container>
  )
}
