import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectCarFeature = (state: State) => state.car

export const selectCurrentCarTires =
  createSelector(selectCarFeature, car => car.currentCarTires.data)

export const selectIsLoadingCarTires =
  createSelector(selectCarFeature, car => car.currentCarTires.loading)
