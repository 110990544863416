import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { twoSeconds } from 'actff-bo-lib/date'
import {
  Attachment,
  AttachmentAction,
  AttachmentActionType,
  AttachmentUploadType,
  createInternalComment,
  haveAttachmentsToUpload,
} from 'actff-bo-lib/global'
import { retryWithDelay } from 'actff-bo-lib/global/operators'
import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { hasPermission, selectMe, selectUserPermissions, User, UserPermissions } from 'actff-bo-lib/user'
import * as statusCodes from 'http-status-codes'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { iif, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

import { CrmServiceAction, CrmServiceActionType } from './actions'
import {
  assignOpportunity,
  createOpportunityManual,
  getLead,
  getOpportunities,
  getOpportunity,
  sendOpportunityInternalComment,
  setOpportunityStatusAccomplished,
  setOpportunityStatusArranged,
  setOpportunityStatusFailed,
  setOpportunityStatusMissed,
  setOpportunityStatusRetry,
  unassignOpportunity,
} from './dao'
import { OpportunityId, OpportunityInternalCommentDto, OpportunityService, UpdateOpportunityFromDraftDto } from './dto'
import { isOriginalOpportunityUpdated, mapOpportunityStatusToAction } from './helpers'
import {
  selectCurrentOpportunity,
  selectOpportunityListAssignmentFilter,
  selectOpportunityListBrandFilters,
  selectOpportunityListCurrentPage,
  selectOpportunityListDealerLocationsFilters,
  selectOpportunityListsFilters,
  selectOpportunityListTimeFrom,
  selectOpportunityListTimeTo,
  selectOpportunitySearchPhrase,
} from './selectors'

type OpportunityOrAttachmentAction = CrmServiceAction | AttachmentAction

const getLeadRetryTimes = 3

const assignOpportunityEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.assignOpportunity>>(CrmServiceActionType.AssignOpportunity),
  switchMap(({ payload }) => assignOpportunity(payload.uuid, payload.user).pipe(
    map(opportunity => CrmServiceAction.assignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmServiceAction.assignOpportunityFailure(error))),
  ),
))

const changeFilterOrSearchPhraseEpic: Epic<CrmServiceAction, CrmServiceAction, State> = (action$, state$) => action$.pipe(
  ofType(
    CrmServiceActionType.ChangeListsFilter,
    CrmServiceActionType.ChangeSearchPhrase,
    CrmServiceActionType.ChangeAssignmentFilter,
    CrmServiceActionType.ChangeListsDealerLocationFilters,
    CrmServiceActionType.ChangeListsBrandFilters,
  ),
  withLatestFrom(state$),
  switchMap(([, store]) => {
    const userPermissions = selectUserPermissions(store)
    const defaultActionsToReturn: ReadonlyArray<CrmServiceAction> = [
      CrmServiceAction.resetPaginationOnLists(),
      CrmServiceAction.getOpportunities(OpportunityListType.future),
      CrmServiceAction.getOpportunities(OpportunityListType.overdue),
      CrmServiceAction.getOpportunities(OpportunityListType.new),
    ]

    return hasPermission([UserPermissions.AdminAllService])(userPermissions)
      ? ([
        ...defaultActionsToReturn,
        CrmServiceAction.getOpportunities(OpportunityListType.all),
      ])
      : defaultActionsToReturn
  }),
)

const getOpportunitiesEpic: Epic<CrmServiceAction, CrmServiceAction, State> = (action$, state$) => action$.pipe(
  ofType<
    ReturnType<typeof CrmServiceAction.getOpportunities>
      | ReturnType<typeof CrmServiceAction.changeOpportunityListCurrentPage>
      | ReturnType<typeof CrmServiceAction.changeOpportunityListTimeFrom>
      | ReturnType<typeof CrmServiceAction.changeOpportunityListTimeTo>
  >(
    CrmServiceActionType.GetOpportunities,
    CrmServiceActionType.ChangeOpportunityListCurrentPage,
    CrmServiceActionType.ChangeOpportunityListTimeFrom,
    CrmServiceActionType.ChangeOpportunityListTimeTo,
  ),
  withLatestFrom(state$),
  mergeMap(([{ payload: { type } }, store]) => {
    const page = selectOpportunityListCurrentPage(type)(store) - 1
    const assignmentFilter = selectOpportunityListAssignmentFilter(store)
    const filters = selectOpportunityListsFilters(store)
    const from = selectOpportunityListTimeFrom(type)(store)
    const to = selectOpportunityListTimeTo(type)(store)
    const searchPhrase = encodeStringIfExists(selectOpportunitySearchPhrase(store))
    const location = selectOpportunityListDealerLocationsFilters(store)
    const brand = selectOpportunityListBrandFilters(store)

    return getOpportunities(type, page, filters, assignmentFilter, location, brand, from, to, searchPhrase).pipe(
      map(list => CrmServiceAction.getOpportunitiesSuccess(type, list)),
      catchError((error: AjaxError) => of(CrmServiceAction.getOpportunitiesFailure(type, error))),
    )
  }),
)

const createOpportunityManualEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.createOpportunityManual>>(CrmServiceActionType.CreateOpportunityManual),
  switchMap(({ payload }) => createOpportunityManual(payload).pipe(
    map(CrmServiceAction.createOpportunityManualSuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.createOpportunityManualFailure(error))),
  )),
)

const getOpportunityEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.getOpportunity>>(CrmServiceActionType.GetOpportunity),
  switchMap(({ payload }) => getOpportunity(payload).pipe(
    map(CrmServiceAction.getOpportunitySuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.getOpportunityFailure(error))),
  )),
)

const getLeadEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.getLead>>(CrmServiceActionType.GetLead),
  switchMap(({ payload }) => getLead(payload).pipe(
    retryWithDelay(twoSeconds, getLeadRetryTimes),
    map(CrmServiceAction.getLeadSuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.getLeadFailure(error))),
  )),
)

const notifyUserWhenGetLeadFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.getLeadFailure>>(CrmServiceActionType.GetLeadFailure),
  filter(({ payload }) => payload.status === statusCodes.NOT_FOUND),
  tap(() => {
    history.replace(Paths.CarList)
  }),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      body: payload.message,
      id: 'api.error.404.getLeadFailure.title',
      title: 'api.error.404.getLeadFailure.title',
      type: ToastType.Error,
    }),
  ]),
)

const sendOpportunityInternalCommentEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.sendInternalComment>>(CrmServiceActionType.SendInternalComment),
  switchMap(({ payload }) => sendOpportunityInternalComment(payload.opportunityId, payload.internalComment).pipe(
    map(comment => CrmServiceAction.sendInternalCommentSuccess(createInternalComment(comment))),
    catchError((error: AjaxError) => of(CrmServiceAction.sendInternalCommentFailure(error))),
  )),
)

const setOpportunityStatusAccomplishedEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.setOpportunityStatusAccomplished>>(CrmServiceActionType.SetOpportunityStatusAccomplished),
  switchMap(({ payload }) => setOpportunityStatusAccomplished(payload).pipe(
    map(CrmServiceAction.setOpportunityStatusAccomplishedSuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.setOpportunityStatusAccomplishedFailure(error))),
  )),
)

const setOpportunityStatusArrangedEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.setOpportunityStatusArranged>>(CrmServiceActionType.SetOpportunityStatusArranged),
  switchMap(({ payload }) => setOpportunityStatusArranged(payload).pipe(
    map(CrmServiceAction.setOpportunityStatusArrangedSuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.setOpportunityStatusArrangedFailure(error))),
  )),
)

const setOpportunityStatusFailedEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.setOpportunityStatusFailed>>(CrmServiceActionType.SetOpportunityStatusFailed),
  switchMap(({ payload }) => setOpportunityStatusFailed(payload).pipe(
    map(CrmServiceAction.setOpportunityStatusFailedSuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.setOpportunityStatusFailedFailure(error))),
  )),
)

const setOpportunityStatusRetryEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.setOpportunityStatusRetry>>(CrmServiceActionType.SetOpportunityStatusRetry),
  switchMap(({ payload }) => setOpportunityStatusRetry(payload).pipe(
    map(CrmServiceAction.setOpportunityStatusRetrySuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.setOpportunityStatusRetryFailure(error))),
  )),
)

const setOpportunityStatusMissedEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.setOpportunityStatusMissed>>(CrmServiceActionType.SetOpportunityStatusMissed),
  switchMap(({ payload }) => setOpportunityStatusMissed(payload).pipe(
    map(CrmServiceAction.setOpportunityStatusMissedSuccess),
    catchError((error: AjaxError) => of(CrmServiceAction.setOpportunityStatusMissedFailure(error))),
  )),
)

const unassignOpportunityEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.unassignOpportunity>>(CrmServiceActionType.UnassignOpportunity),
  switchMap(({ payload }) => unassignOpportunity(payload.uuid).pipe(
    map(opportunity => CrmServiceAction.unassignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmServiceAction.unassignOpportunityFailure(error))),
  ),
))

const updateOpportunityEpic: Epic<OpportunityOrAttachmentAction, OpportunityOrAttachmentAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof CrmServiceAction.updateOpportunityFromDraft>>(CrmServiceActionType.UpdateOpportunityFromDraft),
  withLatestFrom(store$),
  map(([{ payload }, store]) => [payload, selectCurrentOpportunity(store), selectMe(store)]),
  filter<[UpdateOpportunityFromDraftDto, OpportunityService, User]>(([, , author]) => Boolean(author)),
  map(([
    { attachments, comment, opportunity: changedOpp },
    originalOpp,
    { permissions, disabled, ...author },
  ]) => [changedOpp, originalOpp, { author, comment }, attachments]),
  mergeMap((
    [changedOpportunity, originalOpportunity, comment, attachments]:
    [OpportunityService, OpportunityService, OpportunityInternalCommentDto, ReadonlyArray<Attachment>],
  ) => iif(
    () => haveAttachmentsToUpload(attachments),
    of(
      AttachmentAction.sendAttachments<OpportunityId | OpportunityService | OpportunityInternalCommentDto>(
        attachments as ReadonlyArray<Attachment>,
        AttachmentUploadType.OpportunityComment,
        { uuid: originalOpportunity.uuid, originalOpportunity, changedOpportunity, comment },
      ),
    ),
    of(isOriginalOpportunityUpdated(originalOpportunity, changedOpportunity)
      ? mapOpportunityStatusToAction(changedOpportunity, comment)
      : CrmServiceAction.sendInternalComment(originalOpportunity.uuid, comment),
    ),
  )),
)

const uploadAttachmentsSuccessEpic: Epic<OpportunityOrAttachmentAction, CrmServiceAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AttachmentAction.sendAttachmentsComplete>>(AttachmentActionType.SendAttachmentsComplete),
  pluck('payload'),
  filter(({ type }) => type === AttachmentUploadType.OpportunityComment),
  map(({ urls, props: { originalOpportunity, changedOpportunity, comment } }) => {
    const commentWithAttachments = {
      ...comment,
      attachments: urls,
    }

    return isOriginalOpportunityUpdated(originalOpportunity, changedOpportunity)
      ? mapOpportunityStatusToAction(changedOpportunity, commentWithAttachments)
      : CrmServiceAction.sendInternalComment(originalOpportunity.uuid, commentWithAttachments)
  }),
)

const redirectToOpportunityOnCreateSuccessEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType(
    CrmServiceActionType.CreateOpportunityManualSuccess,
  ),
  tap(({ payload }) => [
    history.push(createRoute(Paths.CrmServiceOpportunity, { opportunityId: payload.uuid })),
  ]),
  ignoreElements(),
)

const redirectToListOnOpportunityUpdateSuccessEpic: Epic<CrmServiceAction> = action$ => action$.pipe(
  ofType(
    CrmServiceActionType.SetOpportunityStatusAccomplishedSuccess,
    CrmServiceActionType.SetOpportunityStatusArrangedSuccess,
    CrmServiceActionType.SetOpportunityStatusFailedSuccess,
    CrmServiceActionType.SetOpportunityStatusMissedSuccess,
    CrmServiceActionType.SetOpportunityStatusRetrySuccess,
  ),
  tap(() => [
    history.push(Paths.CrmServiceOpportunityList),
  ]),
  ignoreElements(),
)

export const crmServiceEpic = combineEpics(
  assignOpportunityEpic,
  changeFilterOrSearchPhraseEpic,
  createOpportunityManualEpic,
  getOpportunitiesEpic,
  getLeadEpic,
  getOpportunityEpic,
  notifyUserWhenGetLeadFailureEpic,
  sendOpportunityInternalCommentEpic,
  setOpportunityStatusAccomplishedEpic,
  setOpportunityStatusArrangedEpic,
  setOpportunityStatusFailedEpic,
  setOpportunityStatusRetryEpic,
  setOpportunityStatusMissedEpic,
  updateOpportunityEpic,
  redirectToListOnOpportunityUpdateSuccessEpic,
  redirectToOpportunityOnCreateSuccessEpic,
  unassignOpportunityEpic,
  uploadAttachmentsSuccessEpic,
// tslint:disable-next-line:max-file-line-count
)
