import { PagedResult } from 'actff-bo-lib/api'
import { Filters } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { parseUrl } from 'actff-bo-lib/pagination'

import { TestDriveRequestAction, TestDriveRequestActionType } from './actions'
import { TestDriveRequest, TestDriveRequestFilterValues, TestDriveRequestStatus } from './dto'

export type TestDriveRequestState = {
  readonly currentPage: number,
  readonly currentTestDriveRequest: TestDriveRequest | null,
  readonly list: PagedResult<TestDriveRequest> | null,
  readonly listFilters: Filters<TestDriveRequestFilterValues>,
  readonly newTestDriveRequestCount: number,
}

const parsedUrl = parseUrl(Paths.TestDriveRequests)
const initialState: TestDriveRequestState = {
  currentPage: parsedUrl.page ? +parsedUrl.page : 1,
  currentTestDriveRequest: null,
  list: null,
  listFilters: {
    status: [
      {
        caption: 'filters.new',
        selected: false,
        value: TestDriveRequestStatus.NEW,
      },
      {
        caption: 'filters.cancelled',
        selected: false,
        value: TestDriveRequestStatus.CANCELED,
      },
      {
        caption: 'filters.completed',
        selected: false,
        value: TestDriveRequestStatus.COMPLETED,
      },
    ],
  },
  newTestDriveRequestCount: 0,
}

// tslint:disable cyclomatic-complexity
export const testDriveRequestReducer = (
  state: TestDriveRequestState = initialState,
  action: TestDriveRequestAction,
): TestDriveRequestState => {
  switch (action.type) {
    case TestDriveRequestActionType.ClearTestDriveRequest:
      return {
        ...state,
        currentTestDriveRequest: null,
      }

    case TestDriveRequestActionType.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case TestDriveRequestActionType.ChangeListFilter:
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          [action.payload.param]: [
            ...state.listFilters[action.payload.param]
              .map(filter => filter.value === action.payload.value
                ? ({
                  ...filter,
                  selected: !filter.selected,
                })
                : ({ ...filter }),
              ),
          ],
        },
      }

    case TestDriveRequestActionType.GetTestDriveRequestsSuccess:
      return {
        ...state,
        list: action.payload,
      }

    case TestDriveRequestActionType.GetTestDriveRequestSuccess:
      return {
        ...state,
        currentTestDriveRequest: action.payload,
      }

    case TestDriveRequestActionType.GetNewTestDriveRequestCountSuccess:
      return {
        ...state,
        newTestDriveRequestCount: action.payload.requestCount,
      }

    default:
      return state
  }
}
