import { CheckboxContainer } from 'actff-bo-app/Clients/Styled'
import { TypeIconMap } from 'actff-bo-app/components/Client/TypeIcons'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { CheckboxInputField, EditSaveActionButton, FieldValue, FormCell } from 'actff-bo-app/components/Form'
import { Icon } from 'actff-bo-app/components/Icon'
import { ValidationError } from 'actff-bo-app/components/ValidationError'
import { Client, ClientDto, clientFormToClientDto, ClientType, formatPhoneNumber } from 'actff-bo-lib/client'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { IconType } from 'actff-bo-lib/menu'
import { User } from 'actff-bo-lib/user'
import { Field, Form, FormikProps, withFormik } from 'formik'
import i18next from 'i18next'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { FormBody, FormHeaderStyled, IconContainer } from './Styled'

export type BasicDataFormValues = {
  readonly firstName: string,
  readonly lastName: string,
  readonly email: string,
  readonly phone: string,
  readonly type: ReadonlyArray<ClientType>,
}

export type BasicDataFormProps = {
  readonly client: Client,
  readonly handleToggleEditable: () => void,
  readonly handleToggleResetForm: () => void,
  readonly isInEditMode: boolean,
  readonly shouldResetForm: boolean,
  readonly updateClient: (client: ClientDto) => void,
  readonly user: User,
}

type RegisteredFromProps = {
  readonly client: Client,
  readonly t: i18next.TFunction,
}

type BasicDataFormComponentProps = BasicDataFormProps & FormikProps<BasicDataFormValues> & WithTranslation

const RegisteredFrom: React.FC<RegisteredFromProps> = ({ client, t }) => (
  <FormCell>
    <label htmlFor='registrationDate'>{t('clientView.basicData.registeredFrom')}:</label>
    <FieldValue id='registrationDate' data-testid={'client_view_form__registered-form--registration-date'} >
      {client.registrationDate && <FormattedDate date={client.registrationDate} format={defaultDateFormat} />}
    </FieldValue>
  </FormCell>
)
const testId = 'client-view-basic-data-form__'

export class BasicDataFormComponent extends React.Component<BasicDataFormComponentProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: BasicDataFormComponentProps): void {
    if (nextProps.shouldResetForm) {
      this.props.resetForm()
      nextProps.handleToggleResetForm()
    }
  }

  public render(): React.ReactNode {
    const { client, isInEditMode, t, handleToggleEditable } = this.props

    return (
      <>
        <FormHeaderStyled>
          {t('clientView.basicData.header')}
          <EditSaveActionButton
            onEdit={handleToggleEditable}
            onSave={this.saveAndToggleEditable}
            isInEditMode={isInEditMode}
            testId={`${testId}edit-save`}
          />
        </FormHeaderStyled>
        {isInEditMode
          ? (
            <Form>
              <FormBody>
                <FormCell>
                  <label htmlFor='firstName'>{t('firstName')}:</label>
                  <Field id='firstName' name='firstName' type='text' data-testid={`${testId}form-first-name`} />
                  <ValidationError name='firstName' testId={`${testId}form-first-name--error`} />
                </FormCell>
                <FormCell>
                  <label htmlFor='lastName'>{t('lastName')}:</label>
                  <Field id='lastName' name='lastName' type='text' data-testid={`${testId}last-name`} />
                  <ValidationError name='lastName' testId={`${testId}form-last-name--error`} />
                </FormCell>
                <FormCell>
                  <label htmlFor='clientId'>{t('clientId')}:</label>
                  <FieldValue id='clientId' data-testid={`${testId}client-id`} >{client.id}</FieldValue>
                </FormCell>
                <FormCell>
                  <label htmlFor='phone'>{t('phone')}:</label>
                  <FieldValue id='phone' data-testid={`${testId}phone`} >{formatPhoneNumber(client.phone)}</FieldValue>
                </FormCell>
                <FormCell>
                  <label htmlFor='email'>{t('email')}:</label>
                  <Field id='email' name='email' type='text' data-testid={`${testId}form-email`} />
                  <ValidationError name='email' testId={`${testId}form-email--error`} />
                </FormCell>
                <RegisteredFrom client={client} t={t} />
                <FormCell>
                  <label>{t('clientView.basicData.type')}:</label>
                  <CheckboxContainer>
                    <Field
                      id='type[0]'
                      name='type[0]'
                      component={CheckboxInputField}
                      testId={`${testId}form-client-type--0`}
                    />
                    <Icon type={IconType.Flag} />
                    <Field
                      id='type[1]'
                      name='type[1]'
                      component={CheckboxInputField}
                      testId={`${testId}form-client-type--1`}
                    />
                    <Icon type={IconType.Crown} />
                  </CheckboxContainer>
                  <ValidationError name='type' testId={`${testId}form-client-type--error`} />
                </FormCell>
              </FormBody>
            </Form>
          )
          : (
            <FormBody>
              <FormCell>
                <label htmlFor='firstName'>{t('firstName')}:</label>
                <FieldValue id='firstName' data-testid={`${testId}first-name`}>{client.firstName}</FieldValue>
              </FormCell>
              <FormCell>
                <label htmlFor='lastName'>{t('lastName')}:</label>
                <FieldValue id='lastName' data-testid={`${testId}last-name`}>{client.lastName}</FieldValue>
              </FormCell>
              <FormCell>
                <label htmlFor='clientId'>{t('clientId')}:</label>
                <FieldValue id='clientId' data-testid={`${testId}client-id`}>{client.id}</FieldValue>
              </FormCell>
              <FormCell>
                <label htmlFor='phone'>{t('phone')}:</label>
                <FieldValue id='phone' data-testid={`${testId}phone`}>{formatPhoneNumber(client.phone)}</FieldValue>
              </FormCell>
              <FormCell>
                <label htmlFor='email'>{t('email')}:</label>
                <FieldValue id='email' data-testid={`${testId}email`}>{client.email}</FieldValue>
              </FormCell>
              <RegisteredFrom client={client} t={t} />
              <FormCell>
                <label htmlFor='type'>{t('clientView.basicData.type')}:</label>
                <FieldValue id='type' data-testid={`${testId}type`}>
                  <IconContainer>
                    {client.type.map((type => <Icon key={type} type={TypeIconMap.get(type, IconType.Flag)} />))}
                  </IconContainer>
                </FieldValue>
              </FormCell>
            </FormBody>
          )
        }
      </>
    )
  }

  private readonly saveAndToggleEditable = () => {
    const { handleToggleEditable, submitForm } = this.props

    handleToggleEditable()
    submitForm()
  }
}

const formik = withFormik<BasicDataFormProps, BasicDataFormValues>({
  handleSubmit: (formValues: BasicDataFormValues, { props }) => {
    const client = props.client

    props.updateClient(
      clientFormToClientDto(client, formValues),
    )
  },
  mapPropsToValues: ({ client: clientFormProps }) => {
    const { email, firstName, lastName, phone, type } = clientFormProps

    return {
      email,
      firstName,
      lastName,
      phone,
      type,
    }
  },
})

export const BasicDataForm = compose(
  formik,
  withTranslation(),
)(BasicDataFormComponent)
