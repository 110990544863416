import { Loader } from 'actff-bo-app/components/Loader'
import {
  CarAction,
  CarEquipmentAction,
  CarEquipmentData,
  CarEquipmentItems,
  CarInfo,
  CarInfoDto,
  selectCurrentCar,
  selectCurrentCarEquipmentData,
  selectCurrentCarEquipmentItems,
  selectCurrentCarEquipmentItemsEdit,
} from 'actff-bo-lib/car'
import { Loadable } from 'actff-bo-lib/global/data-types'
import { State } from 'actff-bo-lib/store'
import React, { Component, ReactNode } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'

import { withCarViewHeader } from '../withCarViewHeader'
import { CarViewEquipmentDataForm, CarViewEquipmentItemsForm } from './CarViewEquipmentForm'
import { NoData } from './Styled'

type StateToProps = {
  readonly car: Loadable<CarInfo>,
  readonly carEquipmentData: Loadable<CarEquipmentData>,
  readonly carEquipmentItems: Loadable<CarEquipmentItems>,
  readonly itemsEdit: CarEquipmentItems | null,
}

type DispatchToProps = {
  readonly getCarEquipmentData: (vin: string) => void,
  readonly getCarEquipmentItems: (vin: string) => void,
  readonly getCarEquipmentItemsEdit: (vin: string) => void,
  readonly updateCar: (car: CarInfoDto) => void,
  readonly updateCarEquipmentData: (carEquipmentData: CarEquipmentData, vin: string) => void,
  readonly updateCarEquipmentItems: (carEquipmentItems: CarEquipmentItems, vin: string) => void,
}

type OwnProps = {
  readonly isNdcCar: boolean,
}

type Props = OwnProps & StateToProps & DispatchToProps & WithTranslation

const testId = 'car-view-equipment__'

class CarViewEquipmentComponent extends Component<Props> {

  public componentDidMount(): void {
    const { car: { data } } = this.props

    if (data?.vin) {
      this.props.getCarEquipmentData(data.vin)
      this.props.getCarEquipmentItems(data.vin)
    }
  }

  public render(): ReactNode {
    const { car, carEquipmentData, carEquipmentItems, isNdcCar, itemsEdit, t } = this.props
    const { data } = carEquipmentData
    const { data: items } = carEquipmentItems

    if (this.isLoading()) {
      return <Loader />
    }

    if (this.hasNoData()) {
      return <NoData>{t('noData')}</NoData>
    }

    return (
      <>
        <CarViewEquipmentDataForm
          car={car.data as CarInfo}
          isNdcCar={isNdcCar}
          data={data as CarEquipmentData}
          testId={testId}
          onSubmitForm={this.handleSubmitEquipmentData}
        />
        <CarViewEquipmentItemsForm
          onEdit={this.handleEdit}
          items={items as CarEquipmentItems}
          itemsEdit={itemsEdit}
          testId={testId}
          onSubmitForm={this.handleSubmitEquipmentItems}
        />
      </>
    )
  }

  private readonly handleEdit = () => { this.props.getCarEquipmentItemsEdit(this.props.car.data?.vin as string) }
  private readonly isLoading = () => this.props.carEquipmentData.loading || this.props.carEquipmentItems.loading
  private readonly hasNoData = () => !this.props.car.data || !this.props.carEquipmentData.data || !this.props.carEquipmentItems.data

  private readonly handleSubmitEquipmentData = (data: CarEquipmentData, car: CarInfoDto) => {
    this.props.updateCarEquipmentData(data, this.props.car.data?.vin as string)

    if (car.uuid) {
      this.props.updateCar({ productionYear: car.productionYear, uuid: car.uuid })
    }
  }

  private readonly handleSubmitEquipmentItems = (items: CarEquipmentItems) => {
    this.props.updateCarEquipmentItems(items, this.props.car.data?.vin as string)
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  car: selectCurrentCar(state),
  carEquipmentData: selectCurrentCarEquipmentData(state),
  carEquipmentItems: selectCurrentCarEquipmentItems(state),
  itemsEdit: selectCurrentCarEquipmentItemsEdit(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getCarEquipmentData: (vin: string) => { dispatch(CarEquipmentAction.getCarEquipmentData(vin)) },
  getCarEquipmentItems: (vin: string) => { dispatch(CarEquipmentAction.getCarEquipmentItems(vin)) },
  getCarEquipmentItemsEdit: (vin: string) => { dispatch(CarEquipmentAction.getCarEquipmentItemsEdit(vin)) },
  updateCar: (car: CarInfoDto) => { dispatch(CarAction.updateCar(car)) },
  updateCarEquipmentData: (carEquipmentData: CarEquipmentData, vin: string) => {
    dispatch(CarEquipmentAction.updateCarEquipmentData(carEquipmentData, vin))
  },
  updateCarEquipmentItems: (carEquipmentItems: CarEquipmentItems, vin: string) => {
    dispatch(CarEquipmentAction.updateCarEquipmentItems(carEquipmentItems, vin))
  },
})

export const CarViewEquipment = compose(
  withCarViewHeader,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(CarViewEquipmentComponent)
