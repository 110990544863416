import { api } from 'actff-bo-lib/api'
import { ClientId } from 'actff-bo-lib/client'

import { OpportunityPurchase, OpportunityPurchaseId, OpportunitySaleId, TradeOpportunityDto } from 'actff-bo-lib/crm/trade/dto'

export const getOpportunitySaleByClient = (clientId: ClientId) => api.get(`/trade/sale/client/${clientId}`)

export const matchPurchaseOpportunities = (saleId: OpportunitySaleId | undefined, opportunities: ReadonlyArray<OpportunityPurchaseId>) =>
  api.put<ReadonlyArray<OpportunityPurchase>, ReadonlyArray<OpportunityPurchaseId>>(`/trade/matched/sale/${saleId}`, opportunities)

export const updateOpportunitySale = (opportunity: TradeOpportunityDto) => {
  const { uuid, ...dataToPatch } = opportunity

  return api.patch(`/trade/sale/${uuid}`, dataToPatch)
}
