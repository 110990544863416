import { FormCell, Textarea as TextareaForm } from 'actff-bo-app/components/Form'
import { InternalComments } from 'actff-bo-app/components/InternalComments'
import styled from 'styled-components'
import { colors } from 'styles'

export const OpportunityLeadSourceName = styled.span`
  font-weight: 600;
`

export const OpportunityStatusContainer = styled(FormCell)`
  align-items: start;
  display: grid;
  grid-template-columns: 64px 1fr;
  padding: 24px 0 0 32px;
  position: relative;

  & ul {
    cursor: pointer;
  }

  label {
    height: 20px;
  }
`

export const Li = styled.li`
  &:hover {
    color: ${colors.darkerYellowOrange};
    cursor: pointer;
  }
`

export const OpportunityStatusButton = styled.button`
  color: ${colors.kumera};
  height: 20px;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
`

export const OpportunityMainData = styled(FormCell)`
  display: grid;
  grid-area: chance-type;
  padding: 24px 0 24px 32px;
  grid-template-columns: 42px 1fr;
  grid-gap: 10px;
`

export const OpportunityPushContainer = styled(FormCell)`
  border-bottom: 1px solid ${colors.mysticGray};
  display: grid;
  grid-area: push;
  padding: 24px 32px;
`

export const OpportunityAdditionalInformationContainer = styled.div`
  border-top: 1px solid ${colors.mystic};
  display: grid;
  grid-area: additional-information;
`

export const OpportunityHistory = styled.div`
  border-left: 1px solid ${colors.mystic};
  height: 100%;
  padding: 24px 20px;
`

export const Textarea = styled.textarea`
  border-radius: 3px;
  border: 1.5px solid ${colors.mysticGray};
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
  margin-top: 30px;
  min-height: 100px;
  outline: none;
  resize: vertical;
  width: 100%;
`

export const TextareaStyled = styled(TextareaForm)`
  ${Textarea};
  min-height: 100px !important;
`

export const OpportunityContent = styled.div`
  display: grid;
  grid-template-areas:
    'status chance-type push'
    'additional-information additional-information .'
    'description description description'
    'client client client'
    'car-header car-header car-header'
    'car-data-first car-data-second .'
    'service-first service-second .'
    'events-history events-history events-history'
    'internal-comments internal-comments internal-comments'
    'add-internal-comment add-internal-comment add-internal-comment'
  ;
`

export const OpportunityInternalComments = styled(InternalComments)`
  grid-area: internal-comments;
`

export const Content = styled.div`
  border-bottom: 1px solid ${colors.mysticGray};
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 1fr;
`
