import { DictionaryAction, DictionaryActionType } from './actions'
import { DictionaryType } from './dto'

export type DictionaryState = {
  readonly fuelTypes: ReadonlyArray<DictionaryType<string>>,
  readonly tireTypes: ReadonlyArray<DictionaryType<string>>,
}

const initialState: DictionaryState = {
  fuelTypes: [],
  tireTypes: [],
}

export const dictionaryReducer =
// tslint:disable-next-line cyclomatic-complexity
  (state: DictionaryState = initialState, action: DictionaryAction): DictionaryState => {
    switch (action.type) {

      case DictionaryActionType.GetTireTypesSuccess:
        return {
          ...state,
          tireTypes: action.payload,
        }

      case DictionaryActionType.GetFuelTypesSuccess:
        return {
          ...state,
          fuelTypes: action.payload,
        }

      default:
        return state
    }
  }
