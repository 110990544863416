import { FormikState, FormikValues } from 'formik'
import * as React from 'react'

type Props = {
  readonly onChange: (props: FormikState<FormikValues>, nextProps: FormikState<FormikValues>) => void,
  readonly formik: FormikState<FormikValues>,
}

export class FormikEffect extends React.Component<Props> {
  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const {
      errors,
      isSubmitting,
      isValidating,
      submitCount,
      touched,
      values,
    } = this.props.formik

    const {
      errors: nextErrors,
      isSubmitting: nextIsSubmitting,
      isValidating: nextIsValidating,
      submitCount: nextSubmitCount,
      touched: nextTouched,
      values: nextValues,
    } = nextProps.formik

    if (nextProps.formik !== this.props.formik) {
      this.props.onChange(
        {
          errors,
          isSubmitting,
          isValidating,
          submitCount,
          touched,
          values,
        },
        {
          errors: nextErrors,
          isSubmitting: nextIsSubmitting,
          isValidating: nextIsValidating,
          submitCount: nextSubmitCount,
          touched: nextTouched,
          values: nextValues,
        },
      )
    }
  }

  public render(): React.ReactNode {
    return this.props.children
  }
}
