import { filterActionsWithCredentials, filterTokenState } from 'actff-bo-lib/debug-tools'
import { filterActionsWithTokenPayload } from 'actff-bo-lib/debug-tools/'
import { history } from 'actff-bo-lib/router'
import { routerMiddleware } from 'connected-react-router'
import * as Logrocket from 'logrocket'
import { applyMiddleware, compose, createStore, Middleware, Store } from 'redux'
import { createEpicMiddleware } from 'redux-observable'

import { rootEpic } from './root-epic'
import { rootReducer } from './root-reducer'
import { State } from './state'

const epicMiddleware = createEpicMiddleware()

const middlewares: ReadonlyArray<Middleware> = [
  routerMiddleware(history),
  Logrocket.reduxMiddleware({
    actionSanitizer: compose(filterActionsWithTokenPayload, filterActionsWithCredentials),
    stateSanitizer: filterTokenState,
  }),
  epicMiddleware,
]

// tslint:disable-next-line:no-any
const composeEnhancers = process.env.NODE_ENV !== 'production' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

const enhacer = composeEnhancers(applyMiddleware(...middlewares))

export const store = (): Store<State> => {
  const storeTemp = createStore(
    rootReducer(history),
    enhacer,
  )
  epicMiddleware.run(rootEpic)

  return storeTemp
}
