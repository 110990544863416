import { Slider } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { colors, tabletBoundary } from 'styles'

type ContainerProps = {
  readonly filled: boolean,
}

type ClickableAreaProps = {
  readonly bottom?: number,
  readonly left?: number,
}

type MaxGoodMarkerProps = {
  readonly offset: number,
}

type TextFieldStyledProps = {
  readonly error: boolean,
}

type SliderStyledProps = {
  readonly disabled: boolean,
}

export const ClickableArea = styled.div<ClickableAreaProps>`
  bottom: ${({ bottom }: ClickableAreaProps) => `${bottom}`};
  cursor: pointer;
  display: grid;
  height: 75px;
  left: ${({ left }: ClickableAreaProps) => `${left}`};
  place-content: center;
  position: absolute;
  width: 75px;
  @media screen and (max-width: ${tabletBoundary}) {
    height: 50px;
    width: 50px;
  }
`

export const EvaluationContent = styled.div<ContainerProps>`
  background-color: ${({ filled }: ContainerProps) => filled ? colors.white : colors.dark};
  border-radius: 3px;
  border: 1px solid ${colors.dark};
  color: ${({ filled }: ContainerProps) => filled ? colors.dark : colors.white};
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 3px;
  height: 25px;
  min-width: 28px;
  padding: 1px 6px;
  place-items: center;
`

export const Content = styled.div`
  display: grid;
`

export const CoatThicknessContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 30px 1fr 65px;
  place-items: center;
  position: relative;

  .MuiSlider-root {
    z-index: 2;
  }

  .MuiSlider-marked {
    margin-bottom: 0;
  }
`

export const DamageTypeContainer = styled.div`
  border-top: 1px solid ${colors.mysticGray};
  display: grid;
  padding: 24px 20% 0 0;
`

export const CheckboxContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 30px 1fr;
  margin: 15px 0 22px 0;
`

export const Footer = styled.div`
  align-items: center;
  border-top: 1px solid ${colors.mysticGray};
  display: grid;
  height: 72px;
  justify-content: end;
`

export const Value = styled.div`
  font-size: 14px;
  line-height: 21px;
`

export const ThicknessMin = styled.p`
  display: flex;
  font-size: 13px;
  letter-spacing: 0.05em;
  line-height: 23px;
`

export const ThicknessLabel = styled.div`
  margin: 10px 0;
`

export const MaxGoodMarker = styled.div<MaxGoodMarkerProps>`
  display: grid;
  grid-row-gap: 10px;
  grid-template-rows: 20px 2fr 2fr;
  height: 60px;
  justify-items: center;
  position: absolute;
  right: ${({ offset }: MaxGoodMarkerProps) => `${offset}px`};
  top: -5px;
`

export const SliderStyled = styled(Slider)<SliderStyledProps>`
  .MuiSlider-rail {
    height: 4px;
  }

  .MuiSlider-track {
    height: 4px;
  }

  .MuiSlider-mark, .MuiSlider-markActive {
    background-color: ${({ disabled }: SliderStyledProps) => disabled ? `${colors.mystic}` : `${colors.blueViolet}`};
    bottom: -6px;
    color: ${colors.midnightBlue};
    height: 40px;
  }

  .MuiSlider-markLabel {
    top: 36px;
  }

  .MuiSlider-thumb {
    background: #FFFFFF;
    border: 1px solid rgba(2, 0, 0, 0.1);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.03);
    height: 20px;
    margin-left: -9px;
    margin-top: -8px;
    width: 20px;
  }
`

export const TextFieldStyled = styled(TextField)<TextFieldStyledProps>`
  .MuiOutlinedInput-input {
    ${({ error }: TextFieldStyledProps) => error && 'border: 2px solid red'};
    min-width: 55px;
    padding: 10px 4px 10px 7.5px;
  }
`

export const CheckboxStyled = styled(Checkbox)`
  .PrivateSwitchBase-root-10 {
    padding: 9px 0;
  }
`

export const ThicknessError = styled.p`
  color: ${colors.flamingoRed};
  left: 375px;
  position: absolute;
  top: 45px;
  width: 180px;
`

export const WithArrowContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  place-items: center;
`
