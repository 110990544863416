import { Column } from 'react-data-grid'

export enum ReportType {
  Payments = 'PAYMENTS',
  Sales = 'SALES',
}

export type ReportRaw = string
export type Report = ReadonlyArray<ReadonlyArray<string>>

export type SalesReport = {
  readonly assigneeFullName: string,
  readonly brand: string,
  readonly commissionsValue: string,
  readonly driverFullName: string,
  readonly grossPremium: string,
  readonly insuranceValue: string,
  readonly insurer: string,
  readonly issued: string,
  readonly model: string,
  readonly number: string,
  readonly opportunityClosed: string,
  readonly opportunityRenewed: string,
  readonly ownerFullName: string,
  readonly policyConfirmed: string,
  readonly sellType: string,
  readonly type: string,
  readonly vin: string,
  readonly sourceName: string,
}

export type SalesReportSummary = {
  readonly id: string,
  readonly totalNumber: number,
  readonly totalGrossPremium: number,
  readonly totalInsuranceValue: number,
  readonly totalCommissionsValue: number,
}

export type PaymentsReport = {
  readonly amount: number,
  readonly bankAccount: string,
  readonly date: Date,
  readonly driverFullName: string,
  readonly insurer: string,
  readonly issued: string,
  readonly number: string,
  readonly receiver: string,
  readonly registrationNumber: string,
  readonly sellType: string,
}

export type PaymentsReportSummary = {
  readonly id: string,
  readonly totalAmount: number,
  readonly totalNumber: number,
}

export enum FilterType {
  Equal = 'equal',
  Like = 'like',
  Multi = 'multi',
}

export type ReportColumns<T, S> = ReadonlyArray<
  Column<T, S>
  & {
    readonly filterType?: FilterType,
    readonly hideOnPrint?: boolean,
  }
>

export type ColConfig = {
  readonly width: number,
}

export type ReportConfig = {
  readonly [key: string]: ColConfig,
}
// tslint:disable:object-literal-sort-keys
export const reportPrintColsConfig: ReportConfig = {
  insurer: {
    width: 0,
  },
  sellType: {
    width: 100,
  },
  number: {
    width: 0,
  },
  issued: {
    width: 0,
  },
  driverFullName: {
    width: 350,
  },
  registrationNumber: {
    width: 0,
  },
  date: {
    width: 0,
  },
  'money-value': {
    width: 0,
  },
  receiver: {
    width: 0,
  },
  bankAccount: {
    width: 200,
  },
  sourceName: {
    width: 50,
  },
}

export const reportColsConfig: ReportConfig = {
  insurer: {
    width: 200,
  },
  sellType: {
    width: 166,
  },
  number: {
    width: 100,
  },
  issued: {
    width: 145,
  },
  driverFullName: {
    width: 300,
  },
  registrationNumber: {
    width: 80,
  },
  date: {
    width: 95,
  },
  'money-value': {
    width: 111,
  },
  receiver: {
    width: 105,
  },
  bankAccount: {
    width: 255,
  },
  sourceName: {
    width: 120,
  },
}
