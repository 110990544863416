import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { AjaxError } from 'rxjs/ajax'

import {
  TestDriveRequest,
  TestDriveRequestCount,
  TestDriveRequestFilterValues,
  TestDriveRequestId,
  UpdateTestDriveRequestDto,
} from './dto'

export enum TestDriveRequestActionType {
  ChangeListFilter = '[TEST DRIVE REQUEST] CHANGE LIST FILTER',
  ChangePage = '[TEST DRIVE REQUEST] CHANGE PAGE',

  ClearTestDriveRequest = '[TEST DRIVE REQUEST] CLEAR TEST DRIVE REQUEST',

  GetTestDriveRequest = '[TEST DRIVE REQUEST] GET TEST DRIVE REQUEST',
  GetTestDriveRequestFailure = '[TEST DRIVE REQUEST] GET TEST DRIVE REQUEST FAILURE',
  GetTestDriveRequestSuccess = '[TEST DRIVE REQUEST] GET TEST DRIVE REQUEST SUCCESS',

  GetNewTestDriveRequestCount = '[TEST DRIVE REQUEST] GET NEW TEST DRIVE REQUEST COUNT',
  GetNewTestDriveRequestCountFailure = '[TEST DRIVE REQUEST] GET NEW TEST DRIVE REQUEST COUNT FAILURE',
  GetNewTestDriveRequestCountSuccess = '[TEST DRIVE REQUEST] GET NEW TEST DRIVE REQUEST COUNT SUCCESS',

  GetTestDriveRequests = '[TEST DRIVE REQUEST] GET TEST DRIVE REQUESTS',
  GetTestDriveRequestsFailure = '[TEST DRIVE REQUEST] GET TEST DRIVE REQUESTS FAILURE',
  GetTestDriveRequestsSuccess = '[TEST DRIVE REQUEST] GET TEST DRIVE REQUESTS SUCCESS',

  UpdateTestDriveRequest = '[TEST DRIVE REQUEST] UPDATE TEST DRIVE REQUEST',
  UpdateTestDriveRequestFailure = '[TEST DRIVE REQUEST] UPDATE TEST DRIVE REQUEST FAILURE',
  UpdateTestDriveRequestSuccess = '[TEST DRIVE REQUEST] UPDATE TEST DRIVE REQUEST SUCCESS',
}

export const TestDriveRequestAction = {
  changeListFilter: (param: string, value: TestDriveRequestFilterValues) =>
    createAction(TestDriveRequestActionType.ChangeListFilter, { param, value }),
  changePage: (page: number) => createAction(TestDriveRequestActionType.ChangePage, page),

  clearTestDriveRequest: () => createAction(TestDriveRequestActionType.ClearTestDriveRequest),

  getTestDriveRequest: (uuid: TestDriveRequestId) => createAction(TestDriveRequestActionType.GetTestDriveRequest, uuid),
  getTestDriveRequestFailure: (error: AjaxError) => createAction(TestDriveRequestActionType.GetTestDriveRequestFailure, error),
  getTestDriveRequestSuccess: (testDriveRequest: TestDriveRequest) =>
    createAction(TestDriveRequestActionType.GetTestDriveRequestSuccess, testDriveRequest),

  getNewTestDriveRequestCount: () => createAction(TestDriveRequestActionType.GetNewTestDriveRequestCount),
  getNewTestDriveRequestCountFailure: (error: AjaxError) =>
    createAction(TestDriveRequestActionType.GetNewTestDriveRequestCountFailure, error),
  getNewTestDriveRequestCountSuccess: (count: TestDriveRequestCount) =>
    createAction(TestDriveRequestActionType.GetNewTestDriveRequestCountSuccess, count),

  getTestDriveRequests: () => createAction(TestDriveRequestActionType.GetTestDriveRequests),
  getTestDriveRequestsFailure: (error: AjaxError) => createAction(TestDriveRequestActionType.GetTestDriveRequestsFailure, error),
  getTestDriveRequestsSuccess: (testDriveRequests: PagedResult<TestDriveRequest>) =>
    createAction(TestDriveRequestActionType.GetTestDriveRequestsSuccess, testDriveRequests),

  updateTestDriveRequest: (dto: UpdateTestDriveRequestDto) =>
    createAction(TestDriveRequestActionType.UpdateTestDriveRequest, dto),
  updateTestDriveRequestFailure: (error: AjaxError) =>
    createAction(TestDriveRequestActionType.UpdateTestDriveRequestFailure, error),
  updateTestDriveRequestSuccess: (testDriveRequest: TestDriveRequest) =>
    createAction(TestDriveRequestActionType.UpdateTestDriveRequestSuccess, testDriveRequest),
}

export type TestDriveRequestAction = ActionsUnion<typeof TestDriveRequestAction>
