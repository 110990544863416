import LoginVolvoImage from 'assets/login_volvo.jpg'
import { WrapperType } from '../dto/wrapper'

export const LoggedInWrappers = {
  wrappers: [WrapperType.WithTopBar, WrapperType.WithNavigation],
}

export const AuthWrappers = {
  wrappers: [WrapperType.WithImageColumn],
  wrappersProps: new Map([[WrapperType.WithImageColumn, { image: LoginVolvoImage }]]),
}
