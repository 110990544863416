import { ErrorMessage } from '@hookform/error-message'
import { CarLiquidsRow } from 'actff-bo-app/Admin/Brands/CarLiquids/CarLiquidsRow'
import { FormSegment, Input } from 'actff-bo-app/Admin/Dealership/Users/Styled'
import { BtnTheme } from 'actff-bo-app/components/Button'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { AddingDialogContent, ButtonWithIcon, Label, Th } from 'actff-bo-app/components/EditableList/Styled'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { getReactSelectStyles } from 'actff-bo-app/components/Select/Styles'
import { getBrandFuelTypes } from 'actff-bo-lib/admin/brands/dao'
import { CarLiquids } from 'actff-bo-lib/admin/brands/dto'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { getOilCodesWithAxios, selectCurrentBrand } from 'actff-bo-lib/dealership'
import { nonEmptyCharNoSpecial } from 'actff-bo-lib/form/dto'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import { mapToOptions, SelectOption } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import Creatable from 'react-select/creatable'
import styled from 'styled-components'
import { colors, LightTable, Tr } from 'styles'

import { Container } from '../../Styled'

type Props = {
  readonly currentBrand: string | null,
  readonly onAdd: (formValues: CarLiquids) => void,
  readonly onDelete: (itemValue: CarLiquids | null) => void,
  readonly carLiquids?: ReadonlyArray<CarLiquids>,
}

type Form = {
  readonly engineCode: string,
  readonly fuelType: SelectOption<string>,
  readonly oilCode: SelectOption<string>,
}

const Table = styled(LightTable)`
  width: 70%;

  tbody {
    tr {
      &:hover {
        box-shadow: 0 0 4px ${colors.dustyGray};
      }
    }
    td:last-child {
      min-width: 350px;
    }
  }
`

export const CarLiquidsTable: FC<Props> = ({ carLiquids, onAdd, onDelete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentBrand = useSelector(selectCurrentBrand)

  const [addNewItemDialogOpen, setAddNewItemDialogOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<CarLiquids | null>(null)

  const {
    control,
    errors,
    register,
    handleSubmit,
  } = useForm<Form>()

  const { data: fuelTypes } = useQuery([QueryKeys.GET_BRAND_FUEL_TYPES, addNewItemDialogOpen], async () =>
    await getBrandFuelTypes([currentBrand]), {
      onError: async () => {
        dispatch(displayFailureToast(
          'admin.brands.getFuelTypes',
          ToastActionType.GET,
          'admin.brands.fuelTypes.getFailureAlert.body',
        ))
      },
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  const { data: oilCodes } = useQuery([QueryKeys.GET_OIL_CODES], async () =>
    await getOilCodesWithAxios(), { retry: false, refetchOnWindowFocus: false },
  )
  const toggleAddDialogOpen = () => { setAddNewItemDialogOpen(!addNewItemDialogOpen) }

  const onDialogClose = () => {
    toggleAddDialogOpen()
  }

  const onDialogConfirm = handleSubmit(formValues => {
    const { oilCode, engineCode, fuelType } = formValues
    onAdd({ engineCode, fuelType: fuelType.value, oilCode: oilCode.value })
    toggleAddDialogOpen()
  })

  const handleDelete = (item: CarLiquids) => () => {
    setItemToDelete(item)
  }

  const onDeleteDialogConfirm = async () => {
    onDelete(itemToDelete)
    setItemToDelete(null)
  }

  const onDeleteDialogClose = () => {
    setItemToDelete(null)
  }

  return (
    <form>
      <Container noPadding={true}>
        <Table>
          <thead>
          <Tr>
            <Th>{t('caption.listNumber')}</Th>
            <Th>{t('admin.brands.carLiquids.engineCode')}</Th>
            <Th>{t('admin.brands.carLiquids.oilCode')}</Th>
            <Th>{t('admin.brands.carLiquids.fuelType')}</Th>
          </Tr>
          </thead>
          <tbody>
          {carLiquids?.map((liquids, index) => (
            <CarLiquidsRow
              key={JSON.stringify(liquids)}
              index={index}
              carLiquids={liquids}
              onDelete={handleDelete(liquids)}
            />
          ))}
          </tbody>
        </Table>
        <ButtonWithIcon
          caption={t('admin.brands.carLiquids.add')}
          onClick={toggleAddDialogOpen}
          iconType={IconType.Plus}
          theme={BtnTheme.Link}
        />
      </Container>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.add.content'
        open={addNewItemDialogOpen}
        onConfirm={onDialogConfirm}
        onClose={onDialogClose}
        title='admin.brands.carLiquids.dialog.title'
      >
        <AddingDialogContent>
          <FormSegment>
            <Label>{t('admin.brands.carLiquids.engineCode')}:</Label>
            <Input
              type='text'
              name='engineCode'
              ref={register({
                ...getValidator({ type: ValidationType.Required }),
                ...getValidator({
                  msgTKey: 'admin.brands.brakeLiquids.addBrakeLiquid.engineCode.validationMsg',
                  regExp: nonEmptyCharNoSpecial,
                  type: ValidationType.Pattern,
                }),
              })}
            />
            <ErrorMessage errors={errors} name='engineCode' as={TranslatedErrorMessage} />
          </FormSegment>
          <FormSegment>
            <Label>{t('admin.brands.carLiquids.oilCode')}:</Label>
            <Controller
              render={({ onChange }) => (
                <Creatable
                  allowCreateWhileLoading={true}
                  formatCreateLabel={text => `${t('caption.use')} "${text}"?`}
                  isClearable={true}
                  isMulti={false}
                  loadingMessage={() => `${t('caption.loading')}`}
                  noOptionsMessage={() => `${t('caption.noResults')}`}
                  options={mapToOptions(oilCodes || [])}
                  onChange={onChange}
                  placeholder={t('placeholder.choose')}
                  styles={getReactSelectStyles()}
                />
              )}
              rules={{ ...getValidator({ type: ValidationType.Required }) }}
              control={control}
              name='oilCode'
            />
            <ErrorMessage errors={errors} name='oilCode' as={TranslatedErrorMessage} />
          </FormSegment>
          <FormSegment>
            <Label>{t('admin.brands.carLiquids.fuelType')}:</Label>
            <Controller
              render={({ onChange }) => (
                <Creatable
                  allowCreateWhileLoading={true}
                  formatCreateLabel={text => `${t('caption.use')} "${text}"?`}
                  isClearable={true}
                  isMulti={false}
                  loadingMessage={() => `${t('caption.loading')}`}
                  noOptionsMessage={() => `${t('caption.noResults')}`}
                  options={mapToOptions(fuelTypes || [])}
                  onChange={onChange}
                  placeholder={t('placeholder.choose')}
                  styles={getReactSelectStyles()}
                />
              )}
              rules={{ ...getValidator({ type: ValidationType.Required }) }}
              control={control}
              name='fuelType'
            />
            <ErrorMessage errors={errors} name='fuelType' as={TranslatedErrorMessage} />
          </FormSegment>
        </AddingDialogContent>
      </Dialog>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.delete.content'
        contentProps={{ item: itemToDelete?.engineCode || '' }}
        open={!!itemToDelete}
        onConfirm={onDeleteDialogConfirm}
        onClose={onDeleteDialogClose}
        title='contentManagement.dialog.delete.title'
      />
    </form>
  )
}
