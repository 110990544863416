export enum ComponentType {
  AdminBrands = 'adminBrands',
  AdminBrakeLiquids = 'adminBrakeLiquids',
  AdminOilsAndEngines = 'adminOilsAndEngines',
  AdminAssistance = 'adminAssistance',
  AdminCarModels = 'adminCarModels',
  AdminCarVersions = 'adminCarVersions',
  AdminInsurers = 'adminInsurers',
  AdminInsurersQuestions = 'adminInsurersQuestions',
  AdminRegulationsAndApprovals = 'adminRegulationsAndApprovals',
  AdminHolidays = 'adminHolidays',
  AdminUsers = 'adminUsers',
  AdminOpeningHours = 'adminOpeningHours',
  AdminVisitAvailability = 'adminVisitAvailability',
  AdminContactDetails = 'adminContactDetails',
  AdminD2DCities = 'adminD2DCities',
  AdminApplicationLinks = 'adminApplicationLinks',
  AdminLocationsBrands = 'adminLocationsBrands',
  AdminEmployeeSchedule = 'adminEmployeeSchedule',
  AdminEquipment = 'adminEquipment',
  AdminTesterVIDA = 'adminTesterVIDA',
  CarList = 'carList',
  CarNew = 'carNew',
  CarViewBasicData = 'carViewBasicData',
  CarViewEquipment = 'carViewEquipment',
  CarViewFinance = 'carViewFinance',
  CarViewInspection = 'carViewInspection',
  CarViewInspections = 'carViewInspections',
  CarViewInsurance = 'carViewInsurance',
  CarViewService = 'carViewService',
  CarViewTires = 'carViewTires',
  ChatView = 'chatView',
  ClientList = 'clientList',
  ClientViewApprovals = 'clientViewApprovals',
  ClientViewBasicData = 'clientViewBasicData',
  ClientNew = 'clientNew',
  ClientViewCars = 'clientViewCars',
  ClientViewHistory = 'clientViewHistory',
  ClientViewInvoices = 'clientViewInvoices',
  ClientViewOpportunities = 'clientViewOpportunities',
  ClientViewPreferences = 'clientViewPreferences',
  CrmTradePurchaseOpportunity = 'crmTradePurchaseOpportunity',
  CrmTradeOpportunityLists = 'crmTradeOpportunityLists',
  CrmTradeSaleOpportunity = 'crmTradeSaleOpportunity',
  CrmTradeSaleOpportunityLists = 'crmTradeSaleOpportunityLists',
  CrmInsuranceOpportunity = 'crmInsuranceOpportunity',
  CrmInsuranceOpportunityLists = 'crmInsuranceOpportunityLists',
  CrmInsuranceReport = 'crmInsuranceReport',
  CrmServiceOpportunityLists = 'crmServiceOpportunityLists',
  CrmServiceOpportunityManual = 'crmServiceOpportunityManual',
  CrmServiceOpportunityView = 'crmServiceOpportunityView',
  CalendarServiceAdvisors = 'calendarServiceAdvisors',
  CalendarMechanics = 'calendarMechanics',
  CalendarElectronicsEngineers = 'calendarElectronicEngineers',
  CalendarReplacementCars = 'calendarReplacementCars',
  Dashboard = 'dashboard',
  Login = 'login',
  Main = 'main',
  NdcCars = 'ndcCars',
  OfferRequestList = 'offerRequestList',
  OfferRequestView = 'offerRequestView',
  QuickCampaign = 'quickCampaign',
  ServiceRequestList = 'serviceRequestList',
  ServiceRequestView = 'serviceRequestView',
  TelephoneRequestList = 'telephoneRequestList',
  TelephoneRequestView = 'telephoneRequestView',
  TestDriveRequestList = 'testDriveRequestList',
  TestDriveRequestView = 'testDriveRequestView',
}
