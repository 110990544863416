import { ValueForInsuranceCalculation } from 'actff-bo-lib/car'

export enum OpportunityOfferStatus {
  NOT_PREPARED = 'NOT_PREPARED',
  OFFER_READY = 'OFFER_READY',
  PREPARE_AGAIN = 'PREPARE_AGAIN',
  POLICY_READY = 'POLICY_READY',
}

export enum OpportunityOfferDecision {
  ISSUE_POLICY = 'ISSUE_POLICY',
  REJECTED = 'REJECTED',
}

export enum OpportunityOfferPickUpType {
  PERSONAL = 'PERSONAL',
  COURIER = 'COURIER',
  REMOTE = 'REMOTE',
}

export enum OpportunityOfferDeliveryType {
  APPLICATION = 'APPLICATION',
  PERSONAL = 'PERSONAL',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export type OpportunityOffer = {
  readonly dueDate: Date,
  readonly deliveryType: OpportunityOfferDeliveryType,
  readonly marketValue: string,
  readonly calculationBase: ValueForInsuranceCalculation,
  readonly insuranceValue: string,
  readonly importantInformation: string,
  readonly description: string,
  readonly decisionDueDate: Date,
  readonly contactDate: Date,
  readonly clientsDecision: OpportunityOfferDecision,
  readonly pickupDate: Date,
  readonly pickupType: OpportunityOfferPickUpType,
  readonly pickedUp?: boolean,
}
