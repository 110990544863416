import { ClientFullName, ClientType } from 'actff-bo-app/components/Client'
import { Car } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import * as React from 'react'
import styled from 'styled-components'
import { OrangeLink, theme } from 'styles'

type Props = {
  readonly client: Client,
  readonly car: Partial<Car>,
}

const ClientBar = styled.div`
  align-items: center;
  display: grid;
  grid-area: client;
  grid-auto-flow: column;
  justify-content: left;
`

const ClientBarItem = styled.span`
  justify-content: left;
  padding: 0 12px;

  :first-child {
    padding-left: 0;
  }

  :last-child {
    padding-right: 0;
  }

  :not(:first-child):not(:last-child) {
    border-left: 1px solid ${theme.chat.conversation.border};
    border-right: 1px solid ${theme.chat.conversation.border};
  }
`

const ClientTypeStyled = styled(ClientType)`
  display: inline;
`

export const ChatClientBar: React.FC<Props> = ({ car, client }) => (
  <ClientBar>
    <ClientBarItem>
      <ClientTypeStyled client={client} />
      <OrangeLink noPadding={true} to={createRoute(Paths.ClientViewBasicData, { clientId: client.uuid })}>
        <ClientFullName client={client} />
      </OrangeLink>
    </ClientBarItem>
    {car && (
      <>
        <ClientBarItem>{car.registrationNumber}</ClientBarItem>
        <ClientBarItem>{car.brand} {car.model}</ClientBarItem>
        <ClientBarItem>{car.dealerLocation?.name}</ClientBarItem>
      </>
    )}
  </ClientBar>
)
