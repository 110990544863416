import { ResourceLabelContentArg } from '@fullcalendar/resource-common'
import { Modal } from 'actff-bo-app/components/Modal'
import { CalendarResource, CalendarResourceType } from 'actff-bo-lib/admin/employee-scheduler/dto'
import { Testable } from 'actff-bo-lib/global/testable'
import isEmpty from 'lodash/isEmpty'
import React, { FC } from 'react'
import { QueryObserverResult } from 'react-query'

import { EmployeeForm } from './EmployeeForm'
import { ReplacementCarForm } from './ReplacementCarForm'

type Props = Testable & {
  readonly resourceContent: ResourceLabelContentArg,
  readonly resourceModalOpen: boolean,
  readonly onClose: () => void,
  readonly onRefetch: () => Promise<QueryObserverResult>,
}

export const ResourceModal: FC<Props> = ({ resourceContent, resourceModalOpen, onClose, onRefetch, testId }) => {
  const resourceData = resourceContent.resource.extendedProps as CalendarResource
  const editMode = !isEmpty(resourceData)
  const resourceType = editMode ? resourceData.type : CalendarResourceType[resourceContent.resource.id]

  const getModalTitle = () => {
    if (resourceType === CalendarResourceType.REPLACEMENT_CAR) {
      if (editMode) {
        return 'admin.employeeSchedule.resourceModal.editReplacementCar'
      } else {
        return 'admin.employeeSchedule.resourceModal.addReplacementCar'
      }
    }

    if (editMode) {
      return 'admin.employeeSchedule.resourceModal.editEmployee'
    } else {
      return 'admin.employeeSchedule.resourceModal.addEmployee'
    }
  }

  return (
    <Modal
      open={resourceModalOpen}
      onClose={onClose}
      title={getModalTitle()}
    >
      {resourceType === CalendarResourceType.REPLACEMENT_CAR ?
        (
          <ReplacementCarForm
            resource={resourceData}
            resourceType={resourceType}
            onClose={onClose}
            testId={testId}
            onRefetch={onRefetch}
            editMode={editMode}
          />
        ) :
        (
          <EmployeeForm
            resource={resourceData}
            resourceType={resourceType}
            onClose={onClose}
            testId={testId}
            onRefetch={onRefetch}
            editMode={editMode}
          />
        )}
    </Modal>
  )
}
