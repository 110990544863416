import { websocketPrefixedUrlWithAuthToken } from 'actff-bo-lib/api/request-utils'
import { getCar } from 'actff-bo-lib/car'
import { Message, MessagePayload, selectChatDealershipFilters } from 'actff-bo-lib/chat'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { interval, merge, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, flatMap, ignoreElements, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { webSocket } from 'rxjs/webSocket'

import { ChatAction, ChatActionType } from './actions'
import {
  createNewThread,
  getMessages,
  getThreadList,
  searchThreads,
  sendChatConversationMessageWithAttachments,
  updateChatThread,
} from './dao'
import { createMessage, createThread, MessagePayloadType, Thread, ThreadPayload, ThreadType } from './dto'
import { selectCurrentConversationThread, selectThreadListCurrentPage } from './selectors'

const echoMessageTimeInterval = 10000

const connectChatConversationSocketEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.connectChatConversationSocket>>(ChatActionType.ConnectChatConversationSocket),
  flatMap(({ payload: connectUrl }) => {
    const socket$ = webSocket<Message | MessagePayload>({
      deserializer: ({ data }) => createMessage(JSON.parse(data)),
      url: websocketPrefixedUrlWithAuthToken(`/chat/web/window/${connectUrl}`),
    })

    return merge(
      socket$.pipe(
        filter(message => message.type !== MessagePayloadType.ECHO),
        map(ChatAction.receivedChatConversationMessage),
      ),
      action$.pipe(
        ofType<ReturnType<typeof ChatAction.sendChatConversationMessage>>(ChatActionType.SendChatConversationMessage),
        filter(({ payload }) =>
          !payload.messagePayload.attachments || (payload.messagePayload.attachments && payload.messagePayload.attachments.length === 0),
        ),
        tap<ReturnType<typeof ChatAction.sendChatConversationMessage>>(({ payload }) => {
          const { messagePayload } = payload
          const { attachments, ...message } = messagePayload

          socket$.next(message)
        }),
        ignoreElements(),
      ),
      interval(echoMessageTimeInterval).pipe(
        tap(() => socket$.next({ type: MessagePayloadType.ECHO })),
        ignoreElements(),
      ),
    )
    .pipe(
      takeUntil(action$.pipe(
        ofType<ReturnType<typeof ChatAction.disconnectChatConversationSocket>>(ChatActionType.DisconnectChatConversationSocket),
        filter(({ payload: disconnectUrl }) => disconnectUrl === connectUrl),
      )),
    )
  }),
)

const connectChatThreadListSocketEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.connectChatThreadsSocket>>(ChatActionType.ConnectChatThreadListSocket),
  flatMap(() => {
    const socket$ = webSocket<Thread | ThreadPayload>({
      deserializer: ({ data }) => createThread(JSON.parse(data)),
      url: websocketPrefixedUrlWithAuthToken('/chat/web/list'),
    })

    return merge(
      socket$.pipe(
        filter(thread => thread.type === ThreadType.THREAD),
        map(ChatAction.receivedChatThreadUpdate),
        takeUntil(action$.pipe(
          ofType<ReturnType<typeof ChatAction.disconnectChatThreadsSocket>>(ChatActionType.DisconnectChatThreadListSocket),
        )),
      ),
      interval(echoMessageTimeInterval).pipe(
        tap(() => socket$.next({ type: ThreadType.ECHO })),
        ignoreElements(),
      ),
    )
  }),
)

const getThreadListEpic: Epic<ChatAction, ChatAction, State> = (action$, state$) => action$.pipe(
  ofType(
    ChatActionType.GetThreads,
    ChatActionType.ChangeDealershipFilters,
  ),
  withLatestFrom(state$),
  switchMap(([, state]) => getThreadList(selectThreadListCurrentPage(state) - 1, selectChatDealershipFilters(state)).pipe(
    map(ChatAction.getThreadsSuccess),
    catchError((error: AjaxError) => of(ChatAction.getThreadsFailure(error))),
  )),
)

const getConversationDetailsEpic: Epic<ChatAction, ChatAction, State> = (action$, state$) => action$.pipe(
  ofType(ChatActionType.SelectActiveThread),
  withLatestFrom(state$),
  map(([, state]) => selectCurrentConversationThread(state)),
  filter<Thread>(Boolean),
  switchMap(thread => [
    ChatAction.getChatConversationMessages(thread.threadUuid),
    ChatAction.getChatConversationCar(thread.carUuid),
  ]),
)

const getChatConversationCarEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.getChatConversationCar>>(ChatActionType.GetChatConversationCar),
  switchMap(({ payload }) => getCar(payload).pipe(
    map(ChatAction.getChatConversationCarSuccess),
    catchError((error: AjaxError) => of(ChatAction.getChatConversationCarFailure(error))),
  )),
)

const getChatConversationMessagesEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.getChatConversationMessages>>(ChatActionType.GetChatConversationMessages),
  switchMap(({ payload }) => getMessages(payload).pipe(
    map(ChatAction.getChatConversationMessagesSuccess),
    catchError((error: AjaxError) => of(ChatAction.getChatConversationMessagesFailure(error))),
  )),
)

const loadMoreThreads: Epic<ChatAction> = action$ => action$.pipe(
  ofType(ChatActionType.LoadMoreThreads),
  map(ChatAction.getThreads),
)

const sendChatConversationMessageWithAttachmentsEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.sendChatConversationMessage>>(ChatActionType.SendChatConversationMessage),
  filter(({ payload }) => !!payload.messagePayload.attachments && payload.messagePayload.attachments.length > 0),
  switchMap(({ payload }) => sendChatConversationMessageWithAttachments(payload.threadId, payload.messagePayload).pipe(
    map(ChatAction.sendChatConversationMessageSuccess),
    catchError((error: AjaxError) => of(ChatAction.sendChatConversationMessageFailure(error))),
  )),
)

const updateChatThreadEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.updateChatThread>>(ChatActionType.UpdateChatThread),
  switchMap(({ payload }) => updateChatThread(payload.threadId, payload.body).pipe(
    map(ChatAction.updateChatThreadSuccess),
    catchError((error: AjaxError) => of(ChatAction.updateChatThreadFailure(error))),
  )),
)

const searchThreadsEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.searchThreads>>(ChatActionType.SearchThreads),
  switchMap(({ payload }) => searchThreads(payload).pipe(
    map(ChatAction.searchThreadsSuccess),
    catchError((error: AjaxError) => of(ChatAction.searchThreadsFailure(error))),
  )),
)

const createNewThreadEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.createNewThread>>(ChatActionType.CreateNewThread),
  switchMap(({ payload }) => createNewThread(payload.carId, payload.messagePayload).pipe(
    map(ChatAction.createNewThreadSuccess),
    catchError((error: AjaxError) => of(ChatAction.createNewThreadFailure(error))),
  )),
)

const createNewThreadSuccessEpic: Epic<ChatAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ChatAction.createNewThreadSuccess>>(ChatActionType.CreateNewThreadSuccess),
  tap(({ payload }) => {
    history.push(createRoute(Paths.ChatView, { threadId: payload.threadUuid }))
  }),
  ignoreElements(),
)

export const chatEpic = combineEpics(
  connectChatConversationSocketEpic,
  connectChatThreadListSocketEpic,
  createNewThreadEpic,
  createNewThreadSuccessEpic,
  getChatConversationCarEpic,
  getChatConversationMessagesEpic,
  getConversationDetailsEpic,
  getThreadListEpic,
  loadMoreThreads,
  searchThreadsEpic,
  sendChatConversationMessageWithAttachmentsEpic,
  updateChatThreadEpic,
)
