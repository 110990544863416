import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

import { CollapsableSectionCheckboxLabel } from './Styled'

type Props = Testable & {
  readonly title: string,
  readonly expanded?: boolean,
  readonly expandable?: boolean,
}

type ContentProps = {
  readonly expanded: boolean,
  readonly expandable: boolean,
}

const Container = styled.div`
  margin-top: 10px;
`

const Section = styled.div`
  background: ${colors.mysticGray};
  cursor: ${({ expandable }: ContentProps) => expandable ? 'pointer' : 'default'};
  display: grid;
  font-weight: 600;
  grid-auto-flow: column;
  grid-template-columns: 50px auto 100px;
  margin: 0 32px;
  padding: 16px 24px;
`

const Content = styled.div`
  display: ${({ expanded }: ContentProps) => expanded ? 'block' : 'none'};
  margin: 24px 32px;
  visibility: ${({ expandable }: ContentProps) => expandable ? 'visible' : 'hidden'};
`

// tslint:disable-next-line cyclomatic-complexity
export const CollapsableSectionWithCheckbox: FC<Props> = ({
  children,
  expanded: expandedDefault,
  expandable: expandableDefault,
  testId,
  title,
}) => {
  const [expanded, setExpanded] = useState(expandedDefault || false)
  const [expandable, setExpandable] = useState(expandableDefault || false)
  const handleSetExpandable = () => {
    expandable && setExpanded(false)
    setExpandable(!expandable)
  }

  const handleToggleExpanded = () => expandable && setExpanded(!expanded)

  return (
    <Container>
      <Section data-testid={`${testId}${title}-section`} expandable={expandable} onClick={handleToggleExpanded} >
        <span>
          <input
            data-testid={`${testId}`}
            checked={expandable}
            id={title}
            onChange={handleSetExpandable}
            type='checkbox'
          />
          <CollapsableSectionCheckboxLabel
            data-testid={`${testId}--label`}
            htmlFor={title}
          />
        </span>
        {title}
        {expandable && <ExpandCollapseButton expanded={expanded} onClick={handleToggleExpanded} />}
      </Section>
      {expandable && <Content expanded={expanded} expandable={expandable}>{children}</Content>}
    </Container>
  )
}
