import { Chart } from 'actff-bo-app/components/Chart'
import { DashboardSummaryData } from 'actff-bo-lib/dashboard'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { colors, Opacity, withOpacity } from 'styles'

import { Summary } from './Styled'

type Props = {
  readonly summaryData: DashboardSummaryData,
}

export const DashboardSummary: React.FC<Props> = ({ summaryData }) => {
  const { t } = useTranslation()

  // tslint:disable-next-line: readonly-array
  const dataColors = [withOpacity(colors.midnightBlue, Opacity['70%']), withOpacity(colors.flamingoRed, Opacity['70%'])]

  return (
    <Summary>
      <Chart
        data={[
          { label: t('dashboard.label.clients.all'), values: [summaryData.clients.all] },
          { label: t('dashboard.label.clients.unapproved').split(' '), values: [summaryData.clients.unapprovedCars] },
        ]}
        dataColors={dataColors}
        title={t('dashboard.title.clients')}
        type='bar'
      />
      <Chart
        data={[
          { label: t('dashboard.label.cars.all'), values: [summaryData.cars.all] },
          { label: t('dashboard.label.cars.unapproved'), values: [summaryData.cars.unapproved] },
        ]}
        dataColors={dataColors}
        title={t('dashboard.title.cars')}
        type='bar'
      />
      <Chart
        data={[
          { label: t('dashboard.label.serviceRequests.all'), values: [summaryData.serviceRequests.all] },
          { label: t('dashboard.label.serviceRequests.statusNew'), values: [summaryData.serviceRequests.statusNew] },
          {
            label: [t('dashboard.label.serviceRequests.createdBy'), t('dashboard.label.serviceRequests.createdBy.client')],
            values: [summaryData.serviceRequests.createdByClients],
          },
          {
            label: [t('dashboard.label.serviceRequests.createdBy'), t('dashboard.label.serviceRequests.createdBy.backoffice')],
            values: [summaryData.serviceRequests.createdByBackoffice],
          },
        ]}
        dataColors={[...dataColors, ...dataColors]}
        title={t('dashboard.title.serviceRequests')}
        type='bar'
      />
    </Summary>
  )
}
