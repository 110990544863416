import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import i18next from 'i18next'
import * as React from 'react'

import { BtnTheme } from './Button'
import { ButtonWithIcon } from './ButtonWithIcon'

type SaveEditButtonProps = Testable & {
  readonly className: string,
  readonly editMode: boolean,
  readonly hidden?: boolean,
  readonly onClick: () => void,
  readonly t: i18next.TFunction,
}

export const SaveEditButton: React.FC<SaveEditButtonProps> = ({ className, editMode, hidden, onClick, t, testId }) => {
  if (hidden) {
    return null
  }

  return (
    editMode
      ? (
        <ButtonWithIcon
          caption={t('caption.save')}
          className={className}
          iconType={IconType.Accept}
          onClick={onClick}
          testId={`${testId}--save`}
          theme={BtnTheme.Link}
        />
      ) : (
        <ButtonWithIcon
          caption={t('caption.edit')}
          className={className}
          iconType={IconType.Edit}
          onClick={onClick}
          testId={`${testId}--edit`}
          theme={BtnTheme.Link}
        />
      )
  )
}
