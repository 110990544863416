import { ClientFullName, ClientPhone, ClientType } from 'actff-bo-app/components/Client'
import { TimeAgo } from 'actff-bo-app/components/DateTime'
import { Icon } from 'actff-bo-app/components/Icon'
import { WithStyledRow } from 'actff-bo-app/components/Table'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { defaultTimeFormat } from 'actff-bo-lib/date'
import { selectDealerLocationByKey } from 'actff-bo-lib/dealership'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, IconType, Paths } from 'actff-bo-lib/menu'
import { parseRequestNumber, ServiceRequest, ServiceRequestStatus } from 'actff-bo-lib/service-request'
import { format } from 'date-fns'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { TableInfo } from 'styles'

import { ServiceRequestServices } from './ServiceRequestServices'
import { StatusInfo } from './StatusInfo'

type Props = Testable & {
  readonly serviceRequest: ServiceRequest,
}

export const ServiceRequestRow: React.FC<Props> = ({ serviceRequest, testId }) => {
  const { client } = serviceRequest
  const route = createRoute(Paths.ServiceRequestsView, { serviceRequestId: serviceRequest.uuid })
  const Td = TdDecorator(route)

  const dealerLocation = useSelector(selectDealerLocationByKey(serviceRequest.locationKey))

  return (
    <WithStyledRow
      isStyled={serviceRequest.status === ServiceRequestStatus.NEW}
      time={serviceRequest.createdTime}
    >
      <Td testId={`${testId}-created-time`}>
        {format(serviceRequest.createdTime, defaultTimeFormat)}
        <TableInfo><TimeAgo date={serviceRequest.createdTime} /></TableInfo>
      </Td>
      <Td testId={`${testId}-request-number`}>{parseRequestNumber(serviceRequest.requestNumber)}</Td>
      <Td testId={`${testId}-status`}><StatusInfo status={serviceRequest.status} updatedTime={serviceRequest.updatedTime} /></Td>
      <Td testId={`${testId}-full-name`}><ClientFullName client={client}/></Td>
      <Td testId={`${testId}-type`}><ClientType client={client} /></Td>
      <Td testId={`${testId}-registration-number`}>{serviceRequest.car.registrationNumber}</Td>
      <Td testId={`${testId}-phone`} styles={{ minWidth: '160px' }}><ClientPhone client={client} /></Td>
      <Td testId={`${testId}-services`}><ServiceRequestServices services={serviceRequest.services} /></Td>
      <Td testId={`${testId}-additional-remarks`}>
        {(serviceRequest.additionalRemarks || serviceRequest.attachmentList.length > 0) && <Icon type={IconType.Comment} />}
        {serviceRequest.author && <Icon type={IconType.NewServiceRequest} />}
      </Td>
      <Td testId={`${testId}-dealer-location`}>{dealerLocation?.name}</Td>
    </WithStyledRow>
  )
}
