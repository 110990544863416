import { Tabs } from 'actff-bo-app/Navigation'
import { Client } from 'actff-bo-lib/client'
import { Path, Paths, TabMenuItem } from 'actff-bo-lib/menu'
import i18next from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly client?: Client,
}

const getTabItems = (t: i18next.TFunction, client?: Client): ReadonlyArray<TabMenuItem> => {
  if (!client) {
    return [
      {
        name: t('clientView.basicData'),
        parameters: { clientId: null },
        path: Paths.ClientNew as Path,
      },
    ]
  }

  return [
    {
      name: t('clientView.basicData'),
      parameters: { clientId: client.uuid },
      path: Paths.ClientViewBasicData as Path,
    },
    {
      name: t('clientView.cars'),
      parameters: { clientId: client.uuid },
      path: Paths.ClientViewCars as Path,
    },
    {
      name: t('clientView.approvals'),
      parameters: { clientId: client.uuid },
      path: Paths.ClientViewApprovals as Path,
    },
    {
      name: t('clientView.preferences'),
      parameters: { clientId: client.uuid },
      path: Paths.ClientViewPreferences as Path,
    },
    {
      name: t('clientView.opportunities'),
      parameters: { clientId: client.uuid },
      path: Paths.ClientViewOpportunities as Path,
    },
  ]
}

export const ClientViewTabs: React.FC<Props> = ({ client }) => {
  const { t } = useTranslation()

  return <Tabs tabItems={getTabItems(t, client)} />
}
