import 'react-toastify/dist/ReactToastify.css' // tslint:disable-line: no-import-side-effect

import { Icon } from 'actff-bo-app/components/Icon'
import { connector } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu/dto'
import { State } from 'actff-bo-lib/store'
import {
  selectActiveToasts,
  Toast as ToastT,
  ToastAction,
  ToastBodyComponentType,
  ToastBodyProps,
  ToastType,
} from 'actff-bo-lib/toast'
import { Map } from 'immutable'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ToastStyles } from 'styles/Toast'

import { ToastBodyComponentMap } from './ToastBodyComponentType'

type Props = {
  readonly dismissId: string,
  readonly onCloseClick: (id: string) => void,
  readonly title: string,
  readonly body: ToastBodyComponentType | string | undefined,
  readonly bodyProps?: ToastBodyProps,
  readonly type?: ToastType,
}

type StateToProps = {
  readonly activeToasts: ReadonlyArray<ToastT>,
}

type DispatchToProps = {
  readonly hideToast: (id: string) => void,
}

const ToastContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-template-areas:
    'icon title close'
    'body body body';
  grid-template-columns: 1fr 9fr 1fr;
  justify-content: start;
  width: auto;
`
const ToastIcon = styled(Icon)`
  grid-area: icon;
`

const Title = styled.h2`
  font-size: 18px;
  grid-area: title;
`

const BodyContainer = styled.span`
  font-size: 11px;
  grid-area: body;
`

const CloseButton = styled.button`
  cursor: pointer;
  grid-area: close;
  justify-self: end;
`

const ToastTypeIconMap = Map([
  [ToastType.Success, IconType.CheckMarkWhite],
  [ToastType.Error, IconType.DangerTriangle],
]) as Map<ToastType, IconType>

const ToastContent: React.FC<Props> = ({
  body,
  bodyProps,
  dismissId,
  onCloseClick,
  title,
  type = ToastType.Default,
}) => {
  const { t } = useTranslation()
  const ToastIconType = ToastTypeIconMap.get(type, IconType.CheckMarkWhite)

  const handleCloseClick = () => {
    toast.dismiss(dismissId)
    onCloseClick(dismissId)
  }

  const Body =
    (body && Object.values(ToastBodyComponentType).indexOf(body as ToastBodyComponentType) !== -1)
      && ToastBodyComponentMap.get(body as ToastBodyComponentType)

  const BodyContent = Body instanceof Function
    ? <Body {...bodyProps} />
    : `${t(body as string)}`

  return (
    <>
      <ToastStyles />
      <ToastContainer>
        <ToastIcon type={ToastIconType} />
        <Title>{t(title)}</Title>
        <CloseButton onClick={handleCloseClick}>
          <Icon type={IconType.CloseWhite} />
        </CloseButton>
        <BodyContainer>
          {BodyContent}
        </BodyContainer>
      </ToastContainer>
    </>
  )
}

class ToastComponent extends React.Component<StateToProps & DispatchToProps> {
  public componentDidUpdate(): void {
    const { activeToasts, hideToast } = this.props
    const defaultToastConfig = {
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
    }

    activeToasts.map(({ autoClose = false, body, bodyProps, id, title, type }) => {
      const content = <ToastContent onCloseClick={hideToast} title={title} body={body} bodyProps={bodyProps} dismissId={id} type={type} />

      toast.configure({
        ...defaultToastConfig,
        autoClose,
      })

      // tslint:disable-next-line cyclomatic-complexity
      switch (type) {
        case ToastType.Success:
          return toast.success(content, { toastId: id, className: 'toast toast--success' })

        case ToastType.Warning:
          return toast.warn(content, { toastId: id, className: 'toast toast--warn' })

        case ToastType.Info:
          return toast.info(content, { toastId: id, className: 'toast toast--info' })

        case ToastType.Error:
          return toast.error(content, { toastId: id, className: 'toast toast--error' })

        case ToastType.Default:
        default:
          return toast(content, { toastId: id })
      }
    })
  }

  public render(): React.ReactNode {
    return null
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  activeToasts: selectActiveToasts(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  hideToast: (id: string) => { dispatch(ToastAction.hideToast(id)) },
})

export const Toast = connector(mapStateToProps, mapDispatchToProps)(ToastComponent)
