import { CalendarResourceType } from 'actff-bo-lib/admin'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { H2, Header } from 'styles'

import { CalendarView } from './CalendarView'

export const CalendarReplacementCars = () => {
  const { t } = useTranslation()

  return (
    <>
      <Header>
        <H2>{t('calendar.replacementCars.header')}</H2>
      </Header>
      <CalendarView resourceType={CalendarResourceType.REPLACEMENT_CAR} queryKey={QueryKeys.GET_REPLACEMENT_CARS} />
    </>
  )
}
