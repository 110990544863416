import { Filters } from 'actff-bo-app/components/Filters'
import { HeaderContainer, HeaderLeft } from 'actff-bo-app/components/Grid'
import { ListBrandFiltersForm } from 'actff-bo-app/components/ListFilters/ListBrandFilters'
import { ListFilters } from 'actff-bo-app/components/ListFilters/ListFilters'
import { ListLocationsFiltersForm } from 'actff-bo-app/components/ListFilters/ListLocationsFilters'
import { Search } from 'actff-bo-app/components/Search'
import {
  OpportunityListType,
  selectOpportunityListBrandFilters,
  selectOpportunityListDealerLocationsFilters,
} from 'actff-bo-lib/crm'
import {
  CrmServiceAction,
  OpportunityFilterValues,
  selectOpportunityListsFilters,
  selectOpportunitySearchPhrase,
} from 'actff-bo-lib/crm/service'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { ServiceAction } from 'actff-bo-lib/service'
import { selectUserPermissions, selectUsersWithoutMe, UserAction, UserPermissions } from 'actff-bo-lib/user'
import { hasPermission } from 'actff-bo-lib/user/has-permission'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { H2, Header } from 'styles'

import { OpportunityAssignFilters } from './OpportunityAssign'
import { OpportunityList } from './OpportunityList'

const testId = 'opportunity-list__'

const HeaderFiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 12px 0 0;
`

export const OpportunityLists: React.FC = () => {
  const dispatch = useDispatch()
  const getUserList = useMemo(() => () => { dispatch(UserAction.getUserList()) }, [dispatch])
  const getServices = useMemo(() => () => { dispatch(ServiceAction.getServices()) }, [dispatch])
  const { data: userList } = useSelector(selectUsersWithoutMe)

  useEffect(() => {
    getUserList()
    getServices()
  }, [getServices, getUserList])

  const { t } = useTranslation()
  const userPermissions = useSelector(selectUserPermissions)
  const existingSearchPhrase = useSelector(selectOpportunitySearchPhrase)
  const opportunityListsFilters = useSelector(selectOpportunityListsFilters)
  const dealerLocationFilters = useSelector(selectOpportunityListDealerLocationsFilters)
  const brandFilters = useSelector(selectOpportunityListBrandFilters)

  const onSearch = (searchPhrase: string) => dispatch(CrmServiceAction.changeSearchPhrase(searchPhrase))
  const onListsFilterChange = (param: string, value: OpportunityFilterValues) => dispatch(CrmServiceAction.changeListFilters(param, value))

  const onDealerLocationChange = (values: ReadonlyArray<DealerLocationKey>) => {
    dispatch(CrmServiceAction.changeListsDealerLocationFilters(values))
  }

  const onBrandChange = (values: ReadonlyArray<string>) => {
    dispatch(CrmServiceAction.changeListsBrandFilters(values))
  }

  return (
    <>
      <Header>
        <HeaderContainer>
          <div><H2 data-testid={`${testId}header`}>{t('opportunity.list.header')}</H2></div>
          <Search
            testId={testId}
            onSearch={onSearch}
            initialValues={{ searchPhrase: existingSearchPhrase }}
          />
        </HeaderContainer>
        <HeaderFiltersContainer>
          <HeaderLeft>
            <Filters filters={opportunityListsFilters} onFilterChange={onListsFilterChange} testId={testId} />
            <ListFilters
              leftDirection={true}
              filtersCaption={t('caption.location')}
              filtersComponent={filterRendererProps => <ListLocationsFiltersForm {...filterRendererProps} />}
              filterValues={dealerLocationFilters}
              onSubmit={onDealerLocationChange}
            />
            <ListFilters
              defaultFieldValue={false}
              leftDirection={true}
              filtersCaption={t('caption.brand')}
              filtersComponent={filterRendererProps => <ListBrandFiltersForm {...filterRendererProps} />}
              filterValues={brandFilters}
              onSubmit={onBrandChange}
            />
          </HeaderLeft>
          <OpportunityAssignFilters userList={userList} />
        </HeaderFiltersContainer>
      </Header>
      <OpportunityList
        title='opportunity.list.past'
        type={OpportunityListType.overdue}
      />
      <OpportunityList
        title='opportunity.list.current'
        type={OpportunityListType.new}
      />
      <OpportunityList
        changeTimeTo={true}
        title='opportunity.list.future'
        type={OpportunityListType.future}
      />
      {hasPermission([UserPermissions.AdminAllService])(userPermissions) && (
        <OpportunityList
          title='opportunity.list.all'
          type={OpportunityListType.all}
        />
      )}
    </>
  )
}
