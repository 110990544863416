import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { AppPerson, Counterparty, OwnedCar, OwnerCarId } from 'actff-bo-lib/car/dto'
import { Client } from 'actff-bo-lib/client'
import { AjaxError } from 'rxjs/ajax'

export enum OwnershipActionType {
  ClearCurrentCarOwnership = '[OWNERSHIP] CLEAR CURRENT CAR OWNERSHIP',
  ClearCounterpartyList = '[OWNERSHIP] CLEAR COUNTERPARTY LIST',

  GetAppPersonList = '[OWNERSHIP] GET APP PERSON LIST',
  GetAppPersonListFailure = '[OWNERSHIP] GET APP PERSON LIST FAILURE',
  GetAppPersonListSuccess = '[OWNERSHIP] GET APP PERSON LIST SUCCESS',

  GetCounterpartyList = '[OWNERSHIP] GET COUNTERPARTY LIST',
  GetCounterpartyListFailure = '[OWNERSHIP] GET COUNTERPARTY LIST FAILURE',
  GetCounterpartyListSuccess = '[OWNERSHIP] GET COUNTERPARTY LIST SUCCESS',

  GetOwnedCarById = '[OWNERSHIP] GET OWNED CAR BY ID',
  GetOwnedCarByIdFailure = '[OWNERSHIP] GET OWNED CAR BY ID FAILURE',
  GetOwnedCarByIdSuccess = '[OWNERSHIP] GET OWNED CAR BY ID SUCCESS',

  ChangeAppPersonListPage = '[OWNERSHIP] CHANGE APP PERSON LIST PAGE',
  ChangeCounterpartyListPage = '[OWNERSHIP] CHANGE COUNTERPARTY LIST PAGE',
  ChangeAppPersonListSearchPhrase = '[OWNERSHIP] CHANGE APP PERSON LIST SEARCH PHRASE',
  ChangeCounterpartyListSearchPhrase = '[OWNERSHIP] CHANGE COUNTERPARTY LIST SEARCH PHRASE',

  GetOwnedCarByVin = '[OWNERSHIP] GET OWNED CAR BY VIN',
  GetOwnedCarByVinFailure = '[OWNERSHIP] GET OWNED CAR BY VIN FAILURE',
  GetOwnedCarByVinSuccess = '[OWNERSHIP] GET OWNED CAR BY VIN SUCCESS',

  DeleteAppDriver = '[OWNERSHIP] DELETE APP DRIVER',
  DeleteAppDriverFailure = '[OWNERSHIP] DELETE APP DRIVER FAILURE',
  DeleteAppDriverSuccess = '[OWNERSHIP] DELETE APP DRIVER SUCCESS',

  DeleteNdcDriver = '[OWNERSHIP] DELETE NDC DRIVER',
  DeleteNdcDriverFailure = '[OWNERSHIP] DELETE NDC DRIVER FAILURE',
  DeleteNdcDriverSuccess = '[OWNERSHIP] DELETE NDC DRIVER SUCCESS',

  DeleteNdcOwner = '[OWNERSHIP] DELETE NDC OWNER',
  DeleteNdcOwnerFailure = '[OWNERSHIP] DELETE NDC OWNER FAILURE',
  DeleteNdcOwnerSuccess = '[OWNERSHIP] DELETE NDC OWNER SUCCESS',

  ResetGetOwnedCarFetchFailed = '[OWNERSHIP] RESET GET OWNED CAR FETCH FAILED',

  UpdateAppDriver = '[OWNERSHIP] UPDATE APP DRIVER',
  UpdateAppDriverFailure = '[OWNERSHIP] UPDATE APP DRIVER FAILURE',
  UpdateAppDriverSuccess = '[OWNERSHIP] UPDATE APP DRIVER SUCCESS',

  UpdateNdcDriver = '[OWNERSHIP] UPDATE NDC DRIVER',
  UpdateNdcDriverFailure = '[OWNERSHIP] UPDATE NDC DRIVER FAILURE',
  UpdateNdcDriverSuccess = '[OWNERSHIP] UPDATE NDC DRIVER SUCCESS',

  UpdateNdcOwner = '[OWNERSHIP] UPDATE NDC OWNER',
  UpdateNdcOwnerFailure = '[OWNERSHIP] UPDATE NDC OWNER FAILURE',
  UpdateNdcOwnerSuccess = '[OWNERSHIP] UPDATE NDC OWNER SUCCESS',
}

export const OwnershipAction = {
  clearCurrentCarOwnership: () => createAction(OwnershipActionType.ClearCurrentCarOwnership),
  clearCurrentCounterpartyList: () => createAction(OwnershipActionType.ClearCounterpartyList),

  changeAppPersonListPage: (page: number) => createAction(OwnershipActionType.ChangeAppPersonListPage, page),
  changeAppPersonSearchPhrase: (searchPhrase: string) => createAction(OwnershipActionType.ChangeAppPersonListSearchPhrase, searchPhrase),
  changeCounterpartyListPage: (page: number) => createAction(OwnershipActionType.ChangeCounterpartyListPage, page),
  changeCounterpartyListSearchPhrase: (searchPhrase: string) =>
    createAction(OwnershipActionType.ChangeCounterpartyListSearchPhrase, searchPhrase),

  getAppPersonList: () => createAction(OwnershipActionType.GetAppPersonList),
  getAppPersonListFailure: (error: AjaxError) => createAction(OwnershipActionType.GetAppPersonListFailure, error),
  getAppPersonListSuccess: (appPersonList: PagedResult<AppPerson>) =>
    createAction(OwnershipActionType.GetAppPersonListSuccess, appPersonList),

  getCounterpartyList: () => createAction(OwnershipActionType.GetCounterpartyList),
  getCounterpartyListFailure: (error: AjaxError) => createAction(OwnershipActionType.GetCounterpartyListFailure, error),
  getCounterpartyListSuccess: (ndcPersonList: PagedResult<Counterparty>) =>
    createAction(OwnershipActionType.GetCounterpartyListSuccess, ndcPersonList),

  getOwnedCarById: (uuid: OwnerCarId) => createAction(OwnershipActionType.GetOwnedCarById, uuid),
  getOwnedCarByIdFailure: (error: AjaxError) => createAction(OwnershipActionType.GetOwnedCarByIdFailure, error),
  getOwnedCarByIdSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.GetOwnedCarByIdSuccess, ownedCar),

  getOwnedCarByVin: (vin: string) => createAction(OwnershipActionType.GetOwnedCarByVin, vin),
  getOwnedCarByVinFailure: (error: AjaxError) => createAction(OwnershipActionType.GetOwnedCarByVinFailure, error),
  getOwnedCarByVinSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.GetOwnedCarByVinSuccess, ownedCar),

  deleteAppDriver: (vin: string) => createAction(OwnershipActionType.DeleteAppDriver, vin),
  deleteAppDriverFailure: (error: AjaxError) => createAction(OwnershipActionType.DeleteAppDriverFailure, error),
  deleteAppDriverSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.DeleteAppDriverSuccess, ownedCar),

  deleteNdcDriver: (vin: string) => createAction(OwnershipActionType.DeleteNdcDriver, vin),
  deleteNdcDriverFailure: (error: AjaxError) => createAction(OwnershipActionType.DeleteNdcDriverFailure, error),
  deleteNdcDriverSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.DeleteNdcDriverSuccess, ownedCar),

  deleteNdcOwner: (vin: string) => createAction(OwnershipActionType.DeleteNdcOwner, vin),
  deleteNdcOwnerFailure: (error: AjaxError) => createAction(OwnershipActionType.DeleteNdcOwnerFailure, error),
  deleteNdcOwnerSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.DeleteNdcOwnerSuccess, ownedCar),

  resetGetOwnedCarFetchFailed: () => createAction(OwnershipActionType.ResetGetOwnedCarFetchFailed),

  updateAppDriver: (vin: string, appPerson: Client) =>
    createAction(OwnershipActionType.UpdateAppDriver, { vin, appPerson }),
  updateAppDriverFailure: (error: AjaxError) => createAction(OwnershipActionType.UpdateAppDriverFailure, error),
  updateAppDriverSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.UpdateAppDriverSuccess, ownedCar),

  updateNdcDriver: (vin: string, ndcPerson: Counterparty) =>
    createAction(OwnershipActionType.UpdateNdcDriver, { vin, ndcPerson }),
  updateNdcDriverFailure: (error: AjaxError) => createAction(OwnershipActionType.UpdateNdcDriverFailure, error),
  updateNdcDriverSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.UpdateNdcDriverSuccess, ownedCar),

  updateNdcOwner: (vin: string, ndcPerson: Counterparty) =>
    createAction(OwnershipActionType.UpdateNdcOwner, { vin, ndcPerson }),
  updateNdcOwnerFailure: (error: AjaxError) => createAction(OwnershipActionType.UpdateNdcOwnerFailure, error),
  updateNdcOwnerSuccess: (ownedCar: OwnedCar) => createAction(OwnershipActionType.UpdateNdcOwnerSuccess, ownedCar),
}

export type OwnershipAction = ActionsUnion<typeof OwnershipAction>
