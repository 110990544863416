import { isNotEmptyString } from 'actff-bo-lib/dictionary/helpers'
import { Paths } from 'actff-bo-lib/menu'
import { maxPage, redirectToList } from 'actff-bo-lib/pagination'
import { State } from 'actff-bo-lib/store'
import { LOCATION_CHANGE } from 'connected-react-router'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { NdcCarAction, NdcCarActionType } from './actions'
import { getNdcCars } from './dao'
import {
  selectNdcCarsCurrentPage,
  selectNdcCarsDealerLocationFilters,
  selectNdcCarsNoOfPages,
  selectNdcCarsSearchPhrase,
} from './selectors'

const changeListParamsEpic: Epic<NdcCarAction, NdcCarAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof NdcCarAction.changePage>>(NdcCarActionType.ChangePage),
  withLatestFrom(state$),
  tap(([, store]) => {
    redirectToList(Paths.NdcCars, selectNdcCarsCurrentPage(store))
  }),
  map(NdcCarAction.getNdcCars),
)

const changePageWhenOutOfPagesRangeEpic: Epic<AnyAction, AnyAction> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof NdcCarAction.getNdcCarsSuccess>>(NdcCarActionType.GetNdcCarsSuccess),
  withLatestFrom(state$),
  filter(([, store]) => selectNdcCarsCurrentPage(store) > Math.min(selectNdcCarsNoOfPages(store) || maxPage, maxPage)),
  map(([, store]) => NdcCarAction.changePage(selectNdcCarsNoOfPages(store) || 1)),
)

const changeToFirstPageWhenRouteOutsideListEpic: Epic<AnyAction, NdcCarAction> = (action$, state$) => action$.pipe(
  ofType(LOCATION_CHANGE),
  withLatestFrom(state$),
  filter(([action]) => action.payload.location.pathname === Paths.NdcCars),
  filter(([action]) => !isNotEmptyString(action.payload.location.search)),
  map(() => NdcCarAction.changePage( 1)),
)

const changeSearchPhraseOrDealerLocationEpic: Epic<NdcCarAction> = action$ => action$.pipe(
  ofType(
    NdcCarActionType.ChangeSearchPhrase,
    NdcCarActionType.ChangeDealerLocationFilters,
  ),
  map(() => NdcCarAction.changePage(1)),
)

const getNdcCarsEpic: Epic<NdcCarAction, NdcCarAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof NdcCarAction.getNdcCars>>(NdcCarActionType.GetNdcCars),
  withLatestFrom(state$),
  switchMap(([, store]) => getNdcCars(
    selectNdcCarsCurrentPage(store) - 1,
    selectNdcCarsSearchPhrase(store),
    selectNdcCarsDealerLocationFilters(store),
  ).pipe(
    map(NdcCarAction.getNdcCarsSuccess),
    catchError((error: AjaxError) => of(NdcCarAction.getNdcCarsFailure(error)),
  )),
))

export const ndcCarEpic = combineEpics(
  changeListParamsEpic,
  changeToFirstPageWhenRouteOutsideListEpic,
  changePageWhenOutOfPagesRangeEpic,
  changeSearchPhraseOrDealerLocationEpic,
  getNdcCarsEpic,
)
