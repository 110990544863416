import styled from 'styled-components'
import { colors, PrimaryButton } from '../../styles'
import { Field } from '../components/Form'

export const TextareaStyled = styled(Field)`
  max-width: 600px;
  text-transform: none;
`

export const CostButton = styled(PrimaryButton)`
  width: 108px;
`

export const DealershipFiltersContainer = styled.div`
  display: grid;
`

export const DealershipFiltersHeader = styled.div`
  display: grid;
  align-items: center;
  background-color: ${colors.whisperBlue};
  grid-template-columns: 300px repeat(auto-fit, 130px);
  padding: 10px;
`

export const CostInfo = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  justify-content: start;
`
