import { ErrorMessage } from '@hookform/error-message'
import { FormCell, renderSelectController } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { OpportunityInsurancePaymentMethods, OpportunityInsurancePaymentTypes } from 'actff-bo-lib/crm/insurance'
import { mapToOptions, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = Pick<UseFormMethods, 'register' | 'control' | 'errors' | 'setValue' | 'watch'>
  & Testable
  & {
    readonly paymentType: OpportunityInsurancePaymentTypes,
    readonly isIssuePolicySelected: boolean,
    readonly readOnly?: boolean,
  }

const installmentMaxCount = 4

export const NewPolicyPayment: React.FC<Props> = ({ testId, control, errors, isIssuePolicySelected, paymentType, readOnly }) => {
  const { t } = useTranslation()

  const insurancePaymentMethods = mapToOptions(Object.keys(OpportunityInsurancePaymentMethods), 'crmInsurance.insurancePaymentMethods', t)
  const insurancePaymentTypes = mapToOptions(Object.keys(OpportunityInsurancePaymentTypes), 'crmInsurance.insurancePaymentType', t)

  const installmentOptions = Array(installmentMaxCount)
    .fill(null)
    .map((_, index) => index + 1)
    .map(value => ({ label: value.toString(), value }))
    .slice(1)

  const isFieldRequired = () => isIssuePolicySelected && 'caption.error.required'

  return (
    <>
      <FormCell id='newPolicy-paymentType'>
        <label>{t('crmInsurance.newPolicy.paymentType')}:</label>
        <Controller
          render={renderSelectController({
            disabled: readOnly,
            options: [...insurancePaymentTypes] as ReadonlyArray<SelectOption<string>>,
            t,
            testId: `${testId}-sellType`,
            transKey: 'crmInsurance.insurancePaymentType',
          })}
          control={control}
          rules={{ required: isFieldRequired() }}
          name='newPolicy.paymentType'
        />
        <ErrorMessage errors={errors} name='newPolicy.paymentType' as={TranslatedErrorMessage} />
      </FormCell>

      <FormCell id='newPolicy-paymentMethod'>
        <label>{t('crmInsurance.newPolicy.paymentMethod')}:</label>
        <Controller
          render={renderSelectController({
            disabled: readOnly,
            options: [...insurancePaymentMethods] as ReadonlyArray<SelectOption<string>>,
            t,
            testId: `${testId}-insurancePaymentMethod`,
            transKey: 'crmInsurance.insurancePaymentMethods',
          })}
          control={control}
          rules={{ required: isFieldRequired() }}
          name='newPolicy.paymentMethod'
        />
        <ErrorMessage errors={errors} name='newPolicy.paymentMethod' as={TranslatedErrorMessage} />
      </FormCell>

      <FormCell id='newPolicy.installmentCount'>
        <label>{t('crmInsurance.newPolicy.installmentCount')}:</label>
        <Controller
          render={renderSelectController<number>({
            disabled: readOnly || paymentType === OpportunityInsurancePaymentTypes.ONE_TIME,
            options: installmentOptions,
            t,
            testId: `${testId}-installmentCount`,
          })}
          control={control}
          rules={{ required: isFieldRequired() }}
          name='newPolicy.installmentCount'
        />
        <ErrorMessage errors={errors} name='newPolicy.installmentCount' as={TranslatedErrorMessage} />
      </FormCell>
    </>
  )
}
