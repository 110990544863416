import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'
import { DealerLocationKey } from './dto'

export const selectDealerBrands = (state: State) => state.dealership.brands

export const selectDealerLocations = (state: State) => state.dealership.locations

export const selectDealerLocationsData = (state: State) => state.dealership.locations.data

export const dealerBrandsExist = createSelector(selectDealerBrands, brands => !!(brands.data?.length))

export const dealerLocationsExist = createSelector(selectDealerLocations, locations => !!(locations.data?.length))

export const selectDealerLocationByKey = (key: DealerLocationKey) => createSelector(selectDealerLocations, locations =>
  locations.data?.find(location => location.key === key))

export const selectDealerLocationByNdcName = (ndcName: string) => createSelector(selectDealerLocations, locations =>
  locations.data?.find(location => location.ndcName === ndcName))

export const selectCurrentBrand = (state: State) => state.dealership.currentBrand

export const selectCurrentLocation = (state: State) => state.dealership.currentLocation

export const selectDealerBrandsData = (state: State) => state.dealership.brands.data

export const selectBrakeLiquids = (state: State) => state.dealership.brakeLiquids

export const selectCarModels = (state: State) => state.dealership.models

export const selectCarModelsData = (state: State) => state.dealership.models.data

export const selectCarVersions = (state: State) => state.dealership.versions

export const selectCarEngineCodes = (state: State) => state.dealership.engineCodes

export const selectOilTypes = (state: State) => state.dealership.oilTypes
