import { Attachment } from 'actff-bo-app/components/Attachment'
import { EventHeader } from 'actff-bo-app/components/EventsHistory/Styled'
import { EventHistory } from 'actff-bo-lib/crm'
import { defaultDateTimeFormat, defaultLocale } from 'actff-bo-lib/date'
import { Url } from 'actff-bo-lib/global/attachment'
import { Testable } from 'actff-bo-lib/global/testable'
import { getTranslation } from 'actff-bo-lib/i18n'
import { format } from 'date-fns'
import i18next from 'i18next'
import React, { FC } from 'react'
import styled from 'styled-components'

type EventHistoryProps = Testable & {
  readonly event: EventHistory,
  readonly locale: object,
  readonly index: number,
  readonly t: i18next.TFunction,
}

const EventContent = styled.div`
  font-weight: 300;
  font-style: italic;
  line-height: 23px;
`

const HeaderLeft = styled.div`
  display: grid;
  justify-content: space-between;
`

const Author = styled.span`
  font-weight: normal;
`

const PhotoContainer = styled.div`
  display: inline-block;
  margin-top: 24px;

  > * {
    margin-right: 10px;
  }
`

const thumbnailSize = 64

export const BusinessEventHistory: FC<EventHistoryProps> = ({ event, locale = {}, index, testId, t }) => (
  <div data-testid={`${testId}internal-event-${index}`} key={index}>
    <EventHeader>
      <HeaderLeft>
        <span>{getTranslation(t, 'crmTrade.eventType.', event.type)}</span>
        <Author>{event.author.firstName} {event.author.lastName}</Author>
      </HeaderLeft>
      <span>{format(event.created, defaultDateTimeFormat, { locale: locale || defaultLocale })}</span>
    </EventHeader>
    <EventContent>{event.comment}</EventContent>
    <PhotoContainer>
      {event.attachments && event.attachments.length > 0 && (
        event.attachments.map((photoUrl: Url) => <Attachment key={photoUrl} thumbnailSize={thumbnailSize} url={photoUrl} />)
      )}
    </PhotoContainer>
  </div>
)
