import { Overlay } from 'actff-bo-app/components/Overlay'
import { SelectOption } from 'actff-bo-lib/global'
import React, { FC } from 'react'
import SelectComponent, { components, OptionTypeBase, Props as ReactSelectProps } from 'react-select'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { StylesConfig } from 'react-select/src/styles'
import { ActionMeta } from 'react-select/src/types'
import styled from 'styled-components/macro'
import { getReactSelectStyles, IsMulti } from './Styles'

type Props = ReactSelectProps & {
  readonly hideDropdownIndicator?: boolean,
  readonly minWidth?: number,
  readonly maxWidth?: number,
  readonly onValueChange?: (v: SelectOption<string | null>) => void,
  readonly trimLength?: number,
  readonly customStyles?: () => StylesConfig<OptionTypeBase, IsMulti>,
  readonly isMulti?: boolean,
  readonly hasSelectedOptionBackground?: boolean,
}

const TrimmedValuesContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  line-height: 18px;
`

const HiddenChildren = styled.div`
  display: inline-flex;
  align-items: center;
`

const baseTrimLength = 3

const ValueContainer = (trimLength?: number) => ({ children, ...rest }: ValueContainerProps<OptionTypeBase, IsMulti>) => {
  const length = children ? children[0]?.length : 0
  const getHiddenChildrenLength = () => length - (trimLength || baseTrimLength)

  return (
    <components.ValueContainer {...rest}>
      {length > (trimLength ?? baseTrimLength) ? (
        <TrimmedValuesContainer>
          {children && children[0].slice(0, trimLength ?? baseTrimLength)}
          <HiddenChildren>{`+${getHiddenChildrenLength()}`}</HiddenChildren>
        </TrimmedValuesContainer>
      ) : (
        <>{children}</>
      )}
    </components.ValueContainer>
  )
}

export const ReactSelect: FC<Props> = ({
  components: modifiedComponents,
  minWidth = 0,
  maxWidth,
  hideDropdownIndicator = false,
  onValueChange,
  onChange,
  trimLength,
  customStyles,
  hasSelectedOptionBackground,
  ...props}) => {

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const styles = getReactSelectStyles(customStyles, minWidth, maxWidth, hasSelectedOptionBackground)

  const handleChange = (value: SelectOption<string>, action: ActionMeta<SelectOption<string>>) => {
    onChange && onChange(value, action)
    onValueChange && onValueChange(value)
  }

  const handleOutsideClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <Overlay onClick={handleOutsideClick} shouldRender={isMenuOpen} />
      <SelectComponent
        {...props}
        cropWithEllipsis={true}
        menuIsOpen={isMenuOpen}
        styles={styles}
        onMenuOpen={handleOutsideClick}
        onChange={handleChange}
        components={{
          ...modifiedComponents,
          ...(hideDropdownIndicator && { DropdownIndicator: () => null }),
          ValueContainer: ValueContainer(trimLength),
        }}
      />
    </>
  )
}
