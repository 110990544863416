import { ErrorIcon, ValidationError } from 'actff-bo-app/components/Form'
import { AuthAction, Credentials, selectLoginFailure } from 'actff-bo-lib/auth'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { LoginForm } from './LoginForm'

const H1 = styled.h1`
  margin-bottom: 61px;
`

const ValidationErrorStyled = styled(ValidationError)`
  margin-top: -40px;
  position: absolute;
`

export const Login: React.FC = () => {
  const dispatch = useDispatch()
  const login = (credentials: Credentials) => dispatch(AuthAction.login(credentials))
  const requestError = useSelector(selectLoginFailure)
  const { t } = useTranslation()

  const validationError = requestError
    && (<ValidationErrorStyled><ErrorIcon type={IconType.ErrorTriangle} />{t('login.requestError')}</ValidationErrorStyled>)

  return (
    <>
      <H1>{t('login.title')}</H1>
      {validationError}
      <LoginForm login={login} />
    </>
  )
}
