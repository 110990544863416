import { InspectionPaintCoatElementType } from 'actff-bo-lib/car/dto'
import { fiveSeconds as delayTimeToMakeLastElementUploadedVisible } from 'actff-bo-lib/date'
import { AttachmentUploadResultType, Url } from 'actff-bo-lib/global/attachment/dto'
import { retryWithDelay } from 'actff-bo-lib/global/operators'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, delay, filter, map, mergeMap, retry, switchMap, timeout, withLatestFrom } from 'rxjs/operators'

import { AttachmentAction, AttachmentActionType } from './actions'
import { getAttachment, sendAttachment } from './dao'
import { selectAttachmentsUpload } from './selectors'

const sendAttachmentDelay = 1000
const sendAttachmentRetryTimes = 5
const sendAttachmentTimeout = 60000

const getAttachmentThumbnailEpic: Epic<AttachmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AttachmentAction.getAttachmentThumbnail>>(AttachmentActionType.GetAttachmentThumbnail),
  mergeMap(({ payload }) => getAttachment(payload).pipe(
    retry(sendAttachmentRetryTimes),
    map(blob => AttachmentAction.getAttachmentThumbnailSuccess(payload, blob)),
    catchError((error: AjaxError) => of(AttachmentAction.getAttachmentThumbnailFailure(payload, error))),
  )),
)

const getAttachmentEpic: Epic<AttachmentAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof AttachmentAction.getAttachment>>(AttachmentActionType.GetAttachment),
  mergeMap(({ payload }) => getAttachment(payload).pipe(
    map(blob => AttachmentAction.getAttachmentSuccess(payload, blob)),
    catchError((error: AjaxError) => of(AttachmentAction.getAttachmentFailure(error))),
  )),
)

const sendAttachmentsEpic: Epic<AttachmentAction, AttachmentAction, State> = (action$, state$) => action$.pipe(
  ofType(AttachmentActionType.SendAttachments),
  withLatestFrom(state$),
  switchMap(([, state]) => {
    const upload = selectAttachmentsUpload(state)

    return Array.from(upload.toUpload).map(pending => AttachmentAction.sendSingleAttachment(pending))
  }),
)

const sendSingleAttachmentEpic: Epic<AttachmentAction, AttachmentAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof AttachmentAction.sendSingleAttachment>>(AttachmentActionType.SendSingleAttachment),
  withLatestFrom(state$),
  mergeMap(([{ payload }, state]) => {
    const upload = selectAttachmentsUpload(state)

    return sendAttachment(payload, upload.type, upload.props).pipe(
      retryWithDelay(sendAttachmentDelay, sendAttachmentRetryTimes),
      timeout(sendAttachmentTimeout),
      map(url => AttachmentAction.sendSingleAttachmentSuccess(url, payload.key as InspectionPaintCoatElementType)),
      catchError((error: AjaxError) => of(AttachmentAction.sendSingleAttachmentFailure(error))),
    )
  }),
)

const sendSingleAttachmentSuccessEpic: Epic<AttachmentAction, AttachmentAction, State> = (action$, state$) => action$.pipe(
  ofType(AttachmentActionType.SendSingleAttachmentSuccess, AttachmentActionType.SendSingleAttachmentFailure),
  withLatestFrom(state$),
  filter(([, state]) => {
    const { toUpload, uploaded } = selectAttachmentsUpload(state)

    return toUpload.length === uploaded.length
  }),
  delay(delayTimeToMakeLastElementUploadedVisible),
  map(([, state]) => {
    const { props, type, uploaded } = selectAttachmentsUpload(state)

    return AttachmentAction.sendAttachmentsComplete(
      Array.from(uploaded)
        .filter(a => a !== AttachmentUploadResultType.Failure) as ReadonlyArray<Url>, type, props,
    )
  }),
)

const sendAttachmentsFailureEpic: Epic<AttachmentAction | ToastAction, ToastAction> = action$ => action$.pipe(
  ofType(AttachmentActionType.SendAttachmentsFailure),
  switchMap(() => of(ToastAction.displayToast({
    id: 'attachment.uploadFailure',
    title: 'attachments.uploadFailure.title',
    type: ToastType.Error,
  }))),
)

export const attachmentEpic = combineEpics(
  getAttachmentThumbnailEpic,
  getAttachmentEpic,
  sendAttachmentsEpic,
  sendSingleAttachmentEpic,
  sendSingleAttachmentSuccessEpic,
  sendAttachmentsFailureEpic,
)
