import { ErrorMessage } from '@hookform/error-message'
import { DatePickerInput } from 'actff-bo-app/components/DateTime'
import { FormCell, getDateValue, MoneyInput } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = Pick<UseFormMethods, 'register' | 'control' | 'errors' | 'setValue' | 'watch'>
  & Testable
  & {
    readonly index: number,
    readonly installmentCount: number,
    readonly readOnly?: boolean,
  }

export const NewPolicyInstallment: React.FC<Props> = ({ control, errors, testId, index, installmentCount, readOnly }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormCell id={`newPolicy-installments[${index}].amount`}>
        <label>{t('crmInsurance.newPolicy.installmentValue', { number: index + 1 })}:</label>
        <MoneyInput
          control={control}
          data-testid={`${testId}newPolicy-installmentValue${index}`}
          name={`newPolicy.installments[${index}].amount`}
          rules={{ required: { value: !!installmentCount, message: 'caption.error.required' } }}
          disabled={readOnly}
        />
        <ErrorMessage errors={errors} name={`newPolicy.installments[${index}].amount`} as={TranslatedErrorMessage} />
      </FormCell>
      <FormCell className='datepicker-fullwidth' id={`newPolicy-installments[${index}].dueDate`}>
        <label>{t('crmInsurance.newPolicy.installmentPaymentDate', { number: index + 1 })}:</label>
        <Controller
          render={({ onChange, value }) => (
            <DatePickerInput
              date={getDateValue(value)}
              onChange={onChange}
              testId={`${testId}payments-installmentDate${index}`}
              timeDisabled={true}
              disabled={readOnly}
            />
          )}
          control={control}
          name={`newPolicy.installments[${index}].dueDate`}
          rules={{ required: { value: !!installmentCount, message: 'caption.error.required' } }}
        />
        <ErrorMessage errors={errors} name={`newPolicy.installments[${index}].dueDate`} as={TranslatedErrorMessage} />
      </FormCell>
      <FormCell className='datepicker-fullwidth'>
        <label>{t('crmInsurance.newPolicy.installmentContactDate', { number: index + 1 })}:</label>
        <Controller
          render={({ onChange, value }) => (
            <DatePickerInput
              date={getDateValue(value)}
              onChange={onChange}
              testId={`${testId}payments-installmentDate${index}`}
              timeDisabled={true}
              disabled={readOnly}
            />
          )}
          control={control}
          name={`newPolicy.installments[${index}].contactDate`}
        />
        <ErrorMessage errors={errors} name={`newPolicy.installments[${index}].contactDate`} as={TranslatedErrorMessage} />
      </FormCell>
      <FormCell />
    </>
  )
}
