import { CarTiresAction, CarTiresActionType } from '../actions/tires'
import { CarTiresState } from '../state'

const carTiresInitialState: CarTiresState = {
  data: null,
  loading: false,
}

export const carTiresReducer = (state: CarTiresState = carTiresInitialState, action: CarTiresAction): CarTiresState => {
  switch (action.type) {
    case CarTiresActionType.GetCarTires:
      return ({
        data: null,
        loading: true,
      })

    case CarTiresActionType.GetCarTiresSuccess:
      return ({
        data: action.payload,
        loading: false,
      })

    case CarTiresActionType.GetCarTiresFailure:
      return ({
        data: null,
        loading: false,
      })
    default:
      return state
  }
}
