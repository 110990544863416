import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const InvalidSectionTitle = styled.span`
  color: ${colors.flamingoRed};
`

export const getSectionTitle = (title: string, hasErrors: boolean) => hasErrors
  ? <InvalidSectionTitle>{title}</InvalidSectionTitle>
  : title
