import { Tabs } from 'actff-bo-app/Navigation'
import { Testable } from 'actff-bo-lib/global/testable'
import { Path, Paths, TabMenuItem } from 'actff-bo-lib/menu'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { H2 } from 'styles'

import { MainHeader } from '../Styled'

const ViewTabsHeader = styled(MainHeader)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr;
  grid-gap: 24px;
  padding-bottom: 110px;
`

const getTabItems = (testId: string, t: i18next.TFunction): ReadonlyArray<TabMenuItem> => ([
  {
    name: t('admin.technicalCondition.equipment'),
    path: Paths.AdminTechnicalConditionEquipment as Path,
    testId: `${testId}tabs--equipment`,
  },
  {
    name: t('admin.technicalCondition.vida'),
    path: Paths.AdminTechnicalConditionVida as Path,
    testId: `${testId}tabs--vida`,
  },
])

export const ViewTabs: React.FC<Testable> = ({ testId }) => {
  const { t } = useTranslation()

  return (
    <>
      <ViewTabsHeader>
        <div><H2>{t('admin.technicalCondition.header')}</H2></div>
      </ViewTabsHeader>
      <Tabs tabItems={getTabItems(testId as string, t)} />
    </>
  )
}
