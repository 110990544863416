import { ErrorMessage } from '@hookform/error-message'
import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { FormCell } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { FormFormattedPhoneInput } from 'actff-bo-app/components/FormFormattedPhoneInput'
import { OpportunityContactPerson } from 'actff-bo-lib/crm/insurance'
import { emailRegExp } from 'actff-bo-lib/form'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { getSectionTitle } from '../section-title'
import { SectionFormType } from '../SectionFormType'
import { SectionForm } from '../Styled'

type OwnProps = {
  readonly contactPerson?: OpportunityContactPerson,
}

type Props = Testable
  & SectionFormType
  & OwnProps

export const SectionContactPerson: React.FC<Props & OwnProps> = ({
  contactPerson,
  control,
  errors,
  expanded,
  register,
  testId,
  readOnly,
}) => {
  const { t } = useTranslation()

  return (
    <CollapsableSection expanded={expanded} title={getSectionTitle(t('crmInsurance.section.contactPerson'), !!errors.contactPerson)}>
      <SectionForm>
        <FormCell>
          <label>{t('crmInsurance.contactPerson.firstName')}:</label>
          <input
            type='text'
            name='contactPerson.firstName'
            ref={register}
            data-testid={`${testId}contactPerson-firstName`}
            disabled={readOnly}
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.contactPerson.lastName')}:</label>
          <input
            type='text'
            name='contactPerson.lastName'
            ref={register}
            data-testid={`${testId}contactPerson-lastName`}
            disabled={readOnly}
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.contactPerson.cellPhone')}:</label>
          <FormFormattedPhoneInput
            control={control}
            defaultValue={contactPerson?.mobile}
            name='contactPerson.mobile'
            data-testid={`${testId}contactPerson-cellPhone`}
            disabled={readOnly}
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.contactPerson.phone')}:</label>
          <FormFormattedPhoneInput
            control={control}
            defaultValue={contactPerson?.phone}
            name='contactPerson.phone'
            data-testid={`${testId}contactPerson-phone`}
            disabled={readOnly}
          />
        </FormCell>
        <FormCell>
          <label>{t('crmInsurance.contactPerson.email')}:</label>
          <input
            type='text'
            name='contactPerson.email'
            formNoValidate={true}
            ref={register({ pattern: { value: emailRegExp, message: 'caption.error.email' } })}
            data-testid={`${testId}contactPerson-email`}
            required={false}
            disabled={readOnly}
          />
          <ErrorMessage errors={errors} name='contactPerson.email' as={TranslatedErrorMessage} />
        </FormCell>
      </SectionForm>
    </CollapsableSection>
  )
}
