import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { CarEquipmentData, CarEquipmentItem, CarEquipmentItems } from '../dto'

export enum CarEquipmentActionType {
  AddEquipmentItem = '[CAR] ADD EQUIPMENT ITEM',
  AddEquipmentItemFailure = '[CAR] ADD EQUIPMENT ITEM FAILURE',
  AddEquipmentItemSuccess = '[CAR] ADD EQUIPMENT ITEM SUCCESS',

  DeleteEquipmentItem = '[CAR] DELETE EQUIPMENT ITEM',
  DeleteEquipmentItemFailure = '[CAR] DELETE EQUIPMENT ITEM FAILURE',
  DeleteEquipmentItemSuccess = '[CAR] DELETE EQUIPMENT ITEM SUCCESS',

  GetCarEquipmentData = '[CAR] GET EQUIPMENT DATA',
  GetCarEquipmentDataFailure = '[CAR] GET EQUIPMENT DATA FAILURE',
  GetCarEquipmentDataSuccess = '[CAR] GET EQUIPMENT DATA SUCCESS',

  GetCarEquipmentItems = '[CAR] GET EQUIPMENT ITEMS',
  GetCarEquipmentItemsFailure = '[CAR] GET EQUIPMENT ITEMS FAILURE',
  GetCarEquipmentItemsSuccess = '[CAR] GET EQUIPMENT ITEMS SUCCESS',

  GetCarEquipmentItemsEdit = '[CAR] GET EQUIPMENT ITEMS EDIT',
  GetCarEquipmentItemsEditFailure = '[CAR] GET EQUIPMENT ITEMS EDIT FAILURE',
  GetCarEquipmentItemsEditSuccess = '[CAR] GET EQUIPMENT ITEMS EDIT SUCCESS',

  UpdateCarEquipmentData = '[CAR] UPDATE EQUIPMENT DATA',
  UpdateCarEquipmentDataFailure = '[CAR] UPDATE EQUIPMENT DATA FAILURE',
  UpdateCarEquipmentDataSuccess = '[CAR] UPDATE EQUIPMENT DATA SUCCESS',

  UpdateCarEquipmentItems = '[CAR] UPDATE EQUIPMENT ITEMS',
  UpdateCarEquipmentItemsFailure = '[CAR] UPDATE EQUIPMENT ITEMS FAILURE',
  UpdateCarEquipmentItemsSuccess = '[CAR] UPDATE EQUIPMENT ITEMS SUCCESS',
}

export const CarEquipmentAction = {
  addEquipmentItem: (equipmentItem: Partial<CarEquipmentItem>) => createAction(CarEquipmentActionType.AddEquipmentItem, equipmentItem),
  addEquipmentItemFailure: (error: AjaxError) => createAction(CarEquipmentActionType.AddEquipmentItemFailure, error),
  addEquipmentItemSuccess: (equipmentItem: CarEquipmentItem) =>
    createAction(CarEquipmentActionType.AddEquipmentItemSuccess, equipmentItem),

  deleteEquipmentItem: (uuid: string) => createAction(CarEquipmentActionType.DeleteEquipmentItem, uuid),
  deleteEquipmentItemFailure: (error: AjaxError) => createAction(CarEquipmentActionType.DeleteEquipmentItemFailure, error),
  deleteEquipmentItemSuccess: (equipmentItem: CarEquipmentItem) =>
    createAction(CarEquipmentActionType.DeleteEquipmentItemSuccess, equipmentItem),

  getCarEquipmentData: (vin: string) => createAction(CarEquipmentActionType.GetCarEquipmentData, vin),
  getCarEquipmentDataFailure: (error: AjaxError) => createAction(CarEquipmentActionType.GetCarEquipmentDataFailure, error),
  getCarEquipmentDataSuccess: (carEquipment: CarEquipmentData) =>
    createAction(CarEquipmentActionType.GetCarEquipmentDataSuccess, carEquipment),

  getCarEquipmentItems: (vin?: string) => createAction(CarEquipmentActionType.GetCarEquipmentItems, vin),
  getCarEquipmentItemsFailure: (error: AjaxError) => createAction(CarEquipmentActionType.GetCarEquipmentItemsFailure, error),
  getCarEquipmentItemsSuccess: (items: CarEquipmentItems) => createAction(CarEquipmentActionType.GetCarEquipmentItemsSuccess, items),

  getCarEquipmentItemsEdit: (vin: string) => createAction(CarEquipmentActionType.GetCarEquipmentItemsEdit, vin),
  getCarEquipmentItemsEditFailure: (error: AjaxError) => createAction(CarEquipmentActionType.GetCarEquipmentItemsEditFailure, error),
  getCarEquipmentItemsEditSuccess: (items: CarEquipmentItems) =>
    createAction(CarEquipmentActionType.GetCarEquipmentItemsEditSuccess, items),

  updateCarEquipmentData: (data: CarEquipmentData, vin: string) =>
    createAction(CarEquipmentActionType.UpdateCarEquipmentData, { data, vin }),
  updateCarEquipmentDataFailure: (error: AjaxError) => createAction(CarEquipmentActionType.UpdateCarEquipmentDataFailure, error),
  updateCarEquipmentDataSuccess: (data: CarEquipmentData) => createAction(CarEquipmentActionType.UpdateCarEquipmentDataSuccess, data),

  updateCarEquipmentItems: (items: CarEquipmentItems, vin: string) =>
    createAction(CarEquipmentActionType.UpdateCarEquipmentItems, { items, vin }),
  updateCarEquipmentItemsFailure: (error: AjaxError) => createAction(CarEquipmentActionType.UpdateCarEquipmentItemsFailure, error),
  updateCarEquipmentItemsSuccess: (items: CarEquipmentItems) =>
    createAction(CarEquipmentActionType.UpdateCarEquipmentItemsSuccess, items),
}

export type CarEquipmentAction = ActionsUnion<typeof CarEquipmentAction>
