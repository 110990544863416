import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectCarFeature = (state: State) => state.car

export const selectCurrentCarFinance =
  createSelector(selectCarFeature, car => car.currentCarFinance.data)

export const selectIsLoadingCarFinance =
  createSelector(selectCarFeature, car => car.currentCarFinance.loading)
