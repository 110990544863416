import { Rating as RatingComponent } from 'actff-bo-app/components/Rating'
import { ratingFactor } from 'actff-bo-lib/car'
import { OpportunityPurchaseInspection } from 'actff-bo-lib/crm/trade'
import { decimalNumberFixing } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = Testable & {
  readonly onClick: () => void,
  readonly inspection: OpportunityPurchaseInspection,
}

export const RatingStyled = styled(RatingComponent)`
  grid-column-gap: 0;
`

export const Container = styled.div`
  cursor: pointer;
  display: grid;
  grid-auto-flow: row;
  padding-right: 24px;
  place-items: center;

  > div {
    grid-column-gap: 0;
  }
`

export const RatingContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 140px 1fr;
`

export const RatingLabel = styled.label`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const Rating: FC<Props> = ({ onClick, inspection }) => {
  const { t } = useTranslation()
  const { rating } = inspection

  return (
    <Container onClick={onClick}>
      <RatingLabel>{t('inspectionReview')}:</RatingLabel>
      <RatingContainer>
        <RatingStyled defaultValue={rating / ratingFactor} readonly={true}/>
        {rating === 0 ? rating : (rating / ratingFactor).toFixed(decimalNumberFixing)}
      </RatingContainer>
    </Container>
  )
}
