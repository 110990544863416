import { parseToDateOrNull } from 'actff-bo-lib/date'
import { Attachment, SelectOption, Url, UrlIdentificationKeyType } from 'actff-bo-lib/global'
import { UserWithoutPermissions } from 'actff-bo-lib/user'
import i18next from 'i18next'

import {
  CarInfoWithClient,
  DamageType,
  Inspection,
  InspectionBasicData,
  InspectionCheckboxElementDto,
  InspectionChoiceElement,
  InspectionCommentContent,
  InspectionCommentDto,
  InspectionCommentType,
  InspectionDto,
  InspectionPaintCoatElement,
  InspectionPaintCoatElementDto,
  InspectionPaintCoatElementType,
  PaintCoatElementEvaluation,
} from '../dto'

export type InspectionPaintCoatFormElement = Omit<InspectionPaintCoatElement, 'damageType'> & {
  readonly attachments: ReadonlyArray<Attachment>,
  readonly damageTypes: ReadonlyArray<SelectOption<DamageType>>,
}

export type FormValueT<T = string> = { readonly [key: string]: T }

export type FormValuesT = {
  readonly attachments?: ReadonlyArray<Attachment>,
  readonly basicData: InspectionBasicData,
  readonly rating: number,
  readonly roadTest: FormValueT,
  readonly visualQualityControl: FormValueT,
  readonly workshopControl: {
    readonly computerDiagnostic: FormValueT<boolean>,
    readonly functionalCheck: FormValueT,
    readonly motCheck: FormValueT,
  },
  readonly claims?: number,
  readonly expenses?: number,
  readonly evaluations?: number,
  readonly comments?: FormValueT<InspectionCommentContent>,
  readonly photos?: ReadonlyArray<string>,
}

// tslint:disable-next-line:cyclomatic-complexity
const getPaintCoatElementEvaluation = (element: InspectionPaintCoatElementDto): PaintCoatElementEvaluation => {
  if (!element) {
    return PaintCoatElementEvaluation.NotEvaluated
  }
  if (!element.thickness && element.damageTypes.length < 1) {
    return PaintCoatElementEvaluation.Good
  }

  if (element.thickness && element.thickness < element.maxCorrectThickness && element.damageTypes.length < 1) {
    return PaintCoatElementEvaluation.Good
  }

  if (element.thickness && element.thickness < element.maxCorrectThickness && element.damageTypes.length > 0) {
    return PaintCoatElementEvaluation.Medium
  }

  return PaintCoatElementEvaluation.Bad
}

export type PaintCoatAttachments = {
  readonly attachments: ReadonlyArray<Attachment>,
  readonly key: InspectionPaintCoatElementType,
}

export const getPaintCoatAttachments = (paintCoat: FormValueT<InspectionPaintCoatFormElement>): ReadonlyArray<Attachment> =>
  Object.entries(paintCoat)
    .filter(([, val]) => val.attachments?.length > 0)
    .map(([key, val]) => ({
      attachments: val.attachments,
      key: key as InspectionPaintCoatElementType,
    }))
    .reduce((prev, curr) =>
      ([...prev, ...curr.attachments.map(attachment => ({
        ...attachment,
        key: curr.key,
    }))]), [])

export const getCommentAttachments = (comments?: FormValueT<InspectionCommentContent>): ReadonlyArray<Attachment> => {
  if (!comments) {
    return []
  }

  const commentSections = Object.entries(comments)
  const anyCommentSectionHasAttachment = commentSections.some(([, commentContent]) => commentContent.attachments?.length > 0)

  return anyCommentSectionHasAttachment
    ? commentSections
      .map(([key, val]: [UrlIdentificationKeyType, InspectionCommentContent]) => ({
        attachments: val.attachments || [],
        key: key as InspectionCommentType,
      }))
      .reduce((prev, curr) =>
        ([...prev, ...curr.attachments?.map(attachment => ({
          ...attachment,
          key: curr.key,
        }))]), [])
    : []
}

export const mapPaintCoatToDefaultValues = (
  t: i18next.TFunction,
  elements: ReadonlyArray<InspectionPaintCoatElement>,
) =>
  elements.reduce((prev, curr) => ({
    ...prev,
    [curr.key]: {
      ...curr,
      damageTypes: curr.damageTypes ? curr.damageTypes.map(damageType => ({
        label: t(`carView.inspection.form.paintCoat.damageType.${damageType}`),
        value: damageType || '',
      })) : [],
      evaluation: getPaintCoatElementEvaluation({
        ...curr,
        damageTypes: curr.damageTypes || [],
      }),
      requiresMeasurement: curr.requiresMeasurement !== false, // tslint:disable-line
    },
  }), {})

export const mapPaintCoatFormElementsToDto = (
  elements: FormValueT<InspectionPaintCoatFormElement>,
): ReadonlyArray<InspectionPaintCoatElementDto> =>
  Object.entries(elements).map(([, val]) =>
    ({
      ...val,
      damageTypes: val.damageTypes ? val.damageTypes.map((damageType: SelectOption<DamageType>) => damageType.value) : [],
    }))

export const mapElementToMapUuidStatus = (
  providedElements: ReadonlyArray<InspectionChoiceElement>,
) => providedElements && providedElements.reduce((prev, curr) => ({
    ...prev,
    [curr.uuid]: curr.status,
  }), {})

export const mapElementToMapUuidValue = (
    providedElements: ReadonlyArray<InspectionCheckboxElementDto>,
  ) => providedElements.reduce((prev, curr) => ({
      ...prev,
      [curr.uuid]: curr.value,
    }), {})

export const mapMapUuidStatusToChoiceElementDto = (mapUuidStatus: FormValueT): ReadonlyArray<any> => // tslint:disable-line
  mapUuidStatus && Object.entries(mapUuidStatus).map(([key, val]) =>
    ({
      status: val || null,
      uuid: key,
    }))

const mapMapUuidValueToCheckboxElementDto = (mapUuidValue: FormValueT<boolean>): ReadonlyArray<InspectionCheckboxElementDto> =>
  Object.entries(mapUuidValue).map(([key, val]) =>
    ({
      uuid: key,
      value: val,
    }))

const mapCommentsToCommentDto = (
  user: UserWithoutPermissions | null,
  comments?: FormValueT<InspectionCommentContent>,
): ReadonlyArray<InspectionCommentDto<Attachment>> =>
    comments && user
      ? Object.entries(comments)
          .filter(([key, val]) => Boolean(key) && Boolean(val))
          .map(([key, val]: [InspectionCommentType, InspectionCommentContent]) =>
            ({
              attachments: val.attachments,
              author: user,
              section: key,
              text: val.text,
            }))
      : []

const ratingFactor = 2

export const getInspectionDto = (
  formValues: FormValuesT,
  paintCoat: FormValueT<InspectionPaintCoatFormElement>,
  user: UserWithoutPermissions | null): InspectionDto => {
  const {
    basicData,
    claims = 0,
    comments,
    evaluations = 0,
    expenses = 0,
    photos = [],
    rating,
    roadTest,
    visualQualityControl,
    workshopControl,
  } = formValues

  return ({
    ...formValues,
    basicData: {
      ...basicData,
      ...user && { author: user.email },
      inspectionDate: basicData.inspectionDate || new Date(),
      origin: basicData.origin ? basicData.origin : null,
    },
    claims,
    comments: mapCommentsToCommentDto(user, comments),
    evaluations,
    expenses,
    paintCoat: mapPaintCoatFormElementsToDto(paintCoat),
    photos: photos as ReadonlyArray<Url>,
    rating: rating * ratingFactor,
    roadTest: mapMapUuidStatusToChoiceElementDto(roadTest),
    visualQualityControl: mapMapUuidStatusToChoiceElementDto(visualQualityControl),
    workshopControl: {
      computerDiagnostic: mapMapUuidValueToCheckboxElementDto(workshopControl.computerDiagnostic),
      functionalCheck: mapMapUuidStatusToChoiceElementDto(workshopControl.functionalCheck),
      motCheck: mapMapUuidStatusToChoiceElementDto(workshopControl.motCheck),
    },
  })
}

export const getInspectionDefaultValues = (
  car: CarInfoWithClient,
  user: UserWithoutPermissions | null,
  formValues: Inspection,
): FormValuesT => {
  const lastServiceDate = formValues.basicData ? formValues.basicData.lastServiceDate : car.lastServiceDate
  const motDate = formValues.basicData ? formValues.basicData.motDate : car.mot

  return ({
    ...formValues,
    attachments: [],
    basicData: {
      ...formValues.basicData,
      author: formValues.basicData?.author || user?.email,
      lastServiceDate: parseToDateOrNull(lastServiceDate),
      motDate: parseToDateOrNull(motDate),
    },
    rating: (formValues.rating || 0) / ratingFactor,
    roadTest: mapElementToMapUuidStatus(formValues.roadTest),
    visualQualityControl: mapElementToMapUuidStatus(formValues.visualQualityControl),
    workshopControl: {
      computerDiagnostic: mapElementToMapUuidValue(formValues.workshopControl.computerDiagnostic),
      functionalCheck: mapElementToMapUuidStatus(formValues.workshopControl.functionalCheck),
      motCheck: mapElementToMapUuidStatus(formValues.workshopControl.motCheck),
    },
  }) as FormValuesT // tslint:disable-line
}
