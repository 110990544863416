import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

export const selectChatFeature = (state: State) => state.chat

export const selectCurrentConversation = createSelector(selectChatFeature, chat => chat.currentConversation)

export const selectCurrentConversationThread = createSelector(selectCurrentConversation, conversation => conversation.thread
  ? conversation.thread
  : null,
)
export const selectIsNewCurrentConversation = createSelector(selectCurrentConversation, conversation => conversation.isNew || false)

export const selectThreadList = createSelector(selectChatFeature, chat => chat.threadList.data?.result || [])

export const selectSearchThreadResults = createSelector(selectChatFeature, chat => chat.searchThreadsResults)

export const selectThreadListCurrentPage = createSelector(selectChatFeature, chat => chat.currentPage)

export const selectThreadListNumberOfPages = createSelector(selectChatFeature, chat => chat.threadList.data?.noOfPages)

export const selectChatDealershipFilters = createSelector(selectChatFeature, chat => chat.dealershipFilters)
