import { parseToDateOrNull } from 'actff-bo-lib/date'
import { NominalString, Url } from 'actff-bo-lib/global'
import { Attachment, AttachmentAcceptType } from 'actff-bo-lib/global/attachment'
import { UserWithoutPermissions } from 'actff-bo-lib/user'

export const ratingFactor = 2

export enum InspectionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum InspectionCommentType {
  PAINT_COAT = 'PAINT_COAT',
  VISUAL_QUALITY_CONTROL = 'VISUAL_QUALITY_CONTROL',
  ROAD_TEST = 'ROAD_TEST',
  COMPUTER_DIAGNOSTIC = 'COMPUTER_DIAGNOSTIC',
  FUNCTIONAL_CHECK = 'FUNCTIONAL_CHECK',
  MOT_CHECK = 'MOT_CHECK',
  ADDITIONAL_REMARKS = 'ADDITIONAL_REMARKS',
  CLAIMS = 'CLAIMS',
  EXPENSES = 'EXPENSES',
  EVALUATIONS = 'EVALUATIONS',
}

export enum DamageType {
  RUST = 'RUST',
  SCRATCH = 'SCRATCH',
  DENT = 'DENT',
  DIRT = 'DIRT',
  CRACK = 'CRACK',
  CHIP = 'CHIP',
}

export enum InspectionPaintCoatElementType {
  frontBumper = 'FRONT_BUMPER',
  engineCover = 'ENGINE_COVER',
  rearBumper = 'REAR_BUMPER',
  rearLeftDoor = 'REAR_LEFT_DOOR',
  rearRightDoor = 'REAR_RIGHT_DOOR',
  frontLeftDoor = 'FRONT_LEFT_DOOR',
  frontRightDoor = 'FRONT_RIGHT_DOOR',
  roof= 'ROOF',
  leftSill = 'LEFT_SILL',
  rightSill = 'RIGHT_SILL',
  frontRightFender = 'FRONT_RIGHT_FENDER',
  frontLeftFender = 'FRONT_LEFT_FENDER',
  rearLeftFender = 'REAR_LEFT_FENDER',
  rearRightFender = 'REAR_RIGHT_FENDER',
  trunkLid = 'TRUNK_LID',
}

export enum PaintCoatElementEvaluation {
  Good,
  Medium,
  Bad,
  NotEvaluated,
}

export enum ChoiceElementStatusValues {
  OK = 'OK',
  NOT_OK = 'NOT_OK',
  FIXED = 'FIXED',
  NA = 'NA',
}

export const paintCoatElementMaxThickness = 999
export const paintCoatElementMaxRangeThickness = 400

export const InspectionPhotoAcceptTypes = [AttachmentAcceptType.PNG, AttachmentAcceptType.JPG] as ReadonlyArray<AttachmentAcceptType>
export const InspectionAttachmentAcceptTypes = [
  AttachmentAcceptType.PNG,
  AttachmentAcceptType.JPG,
  AttachmentAcceptType.PDF,
] as ReadonlyArray<AttachmentAcceptType>

export const ChoiceElementStatusNames: ReadonlyArray<string> = [
  'carView.form.choiceElementStatus.ok',
  'carView.form.choiceElementStatus.notOk',
  'carView.form.choiceElementStatus.fixed',
  'carView.form.choiceElementStatus.na',
]

export const ChoiceElementStatus = Object.values(ChoiceElementStatusValues).reduce((prev, curr, index) => ({
  ...prev,
  [ChoiceElementStatusNames[index]]: curr,
}), {})

export type InspectionPaintCoatElement = {
  readonly photos: ReadonlyArray<Url>,
  readonly evaluation?: PaintCoatElementEvaluation,
  readonly name: string,
  readonly key: InspectionPaintCoatElementType,
  readonly thickness: number | null,
  readonly maxCorrectThickness: number,
  readonly damageTypes: ReadonlyArray<DamageType> | null,
  readonly requiresMeasurement: boolean,
}

export type InspectionPaintCoatElementDto = Omit<InspectionPaintCoatElement, 'name' | 'damageTypes'> & {
  readonly damageTypes: ReadonlyArray<DamageType>,
}

export type InspectionComment = {
  readonly uuid: string,
  readonly created: Date,
  readonly text: string,
  readonly attachments: ReadonlyArray<Url>,
  readonly section: InspectionCommentType,
  readonly author: UserWithoutPermissions,
}

export type InspectionCommentContent<T = Attachment> = Pick<InspectionComment, 'text'> & {
  readonly attachments: ReadonlyArray<T>,
}

export type InspectionCommentDto<T> = Pick<InspectionComment, 'author' | 'section'> & InspectionCommentContent<T>

export enum InspectionBasicDataOrigin {
  OWN = 'OWN',
  COUNTRY = 'COUNTRY',
  IMPORT = 'IMPORT',
}

export type InspectionBasicData = {
  readonly lastServiceDate: Date,
  readonly motDate: Date,
  readonly inspectionDate: Date,
  readonly author: string,
  readonly knownMileage: number,
  readonly knownMileageFake: boolean,
  readonly productionYear: number,
  readonly version: string,
  readonly origin: InspectionBasicDataOrigin | null,
}

export type InspectionChoiceElement = {
  readonly uuid: string,
  readonly status: ChoiceElementStatusValues | null,
  readonly name: string,
}

export type InspectionCheckboxElement = {
  readonly uuid: string,
  readonly value: boolean,
  readonly name: string,
}

export type InspectionChoiceElementDto = Omit<InspectionChoiceElement, 'name'>

export type InspectionCheckboxElementDto = Omit<InspectionCheckboxElement, 'name'>

export type InspectionId = NominalString<Inspection>

export type Inspection = {
  readonly basicData?: InspectionBasicData,
  readonly paintCoat: ReadonlyArray<InspectionPaintCoatElement>,
  readonly roadTest: ReadonlyArray<InspectionChoiceElement>,
  readonly visualQualityControl: ReadonlyArray<InspectionChoiceElement>,
  readonly workshopControl: {
    readonly computerDiagnostic: ReadonlyArray<InspectionCheckboxElement>,
    readonly functionalCheck: ReadonlyArray<InspectionChoiceElement>,
    readonly motCheck: ReadonlyArray<InspectionChoiceElement>,
  },
  readonly claims: number,
  readonly expenses: number,
  readonly evaluations: number,
  readonly comments?: ReadonlyArray<InspectionComment>,
  readonly rating?: number,
  readonly status?: InspectionStatus,
  readonly photos: ReadonlyArray<Url>,
}

export type InspectionDto<T = Attachment> = {
  readonly basicData?: InspectionBasicData,
  readonly paintCoat?: ReadonlyArray<InspectionPaintCoatElementDto>,
  readonly roadTest?: ReadonlyArray<InspectionChoiceElementDto>,
  readonly visualQualityControl?: ReadonlyArray<InspectionChoiceElementDto>,
  readonly workshopControl?: {
    readonly computerDiagnostic: ReadonlyArray<InspectionCheckboxElementDto>,
    readonly functionalCheck: ReadonlyArray<InspectionChoiceElementDto>,
    readonly motCheck: ReadonlyArray<InspectionChoiceElementDto>,
  }
  readonly claims?: number,
  readonly expenses?: number,
  readonly evaluations?: number
  readonly comments?: ReadonlyArray<InspectionCommentDto<T>>,
  readonly rating?: number,
  readonly status?: InspectionStatus,
  readonly photos?: ReadonlyArray<Url>,
}

export const mapToInspectionDto = (data: any): Inspection => ({ // tslint:disable-line no-any
  ...data,
  basicData: {
    ...data.basicData,
    creationDate: parseToDateOrNull(data.basicData.creationDate),
    inspectionDate: parseToDateOrNull(data.basicData.inspectionDate),
    lastServiceDate: parseToDateOrNull(data.basicData.lastServiceDate),
    motDate: parseToDateOrNull(data.basicData.motDate),
  },
})

export type InspectionListElement = {
  readonly uuid: string,
  readonly inspectionDate: Date,
  readonly lastServiceDate: Date,
  readonly motDate: Date,
  readonly status: InspectionStatus,
  readonly rating: number,
}

export const mapToInspectionListElement = (data: any): InspectionListElement => ({ // tslint:disable-line no-any
  ...data,
  inspectionDate: parseToDateOrNull(data.inspectionDate),
  lastServiceDate: parseToDateOrNull(data.lastServiceDate),
  motDate: parseToDateOrNull(data.motDate),
  rating: data.rating / ratingFactor,
})
