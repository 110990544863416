import {
  Approvals,
  CarInDms,
  CarsCountRange,
  ClientType,
  RegistrationDate,
  Status,
} from 'actff-bo-app/Clients/ClientList/ClientListFilters/Filters'
import { Footer } from 'actff-bo-app/components/TabFilters/Footer'
import { ClientFilters } from 'actff-bo-lib/client/dto'
import { IconType } from 'actff-bo-lib/menu'
import { FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { ArrowIcon, TabListStyled, TabsLabelStyled, TabsStyled, TabStyled } from 'styles'

import { FiltersContainer, TabPanelStyled } from './Styled'

type ClientListFiltersProps = {
  readonly changeFilters: (filters: ClientFilters) => void,
  readonly currentFilters: ClientFilters,
  readonly clearFilters: () => void,
  readonly getClients: () => void,
}

type OwnState = {
  readonly filtersExpanded: boolean,
}
type Props = ClientListFiltersProps & FormikProps<ClientFilters> & WithTranslation

const formik = withFormik<ClientListFiltersProps, ClientFilters>({
  enableReinitialize: true,
  handleSubmit: (formValues: ClientFilters, { props }) => {
    // TODO temporary solution due to AUT-1328
    const values: ClientFilters | null = {
      ...formValues,
      clientStatuses: {
        ...formValues.clientStatuses,
        Deleted: formValues.clientStatuses.Deleted || formValues.clientStatuses.Locked,
        Locked: false,
      },
    }

    props.changeFilters(values as ClientFilters)
  },
  mapPropsToValues: ({ currentFilters }) => currentFilters,
})

const testId = 'client-list-filters__'

class ClientListFiltersComponent extends React.Component<Props> {
  public readonly state: OwnState = { filtersExpanded: false }

  public render(): React.ReactNode {
    const { handleSubmit, t } = this.props
    const { filtersExpanded } = this.state

    return (
      <TabsStyled data-testid={`${testId}filters`}>
        <TabListStyled>
          <TabStyled expanded={filtersExpanded ? 1 : 0} onClick={this.toggleFilterTab}>
            <TabsLabelStyled dark={true}>{t('clientList.filters.defaultFilters.tabLabel')}</TabsLabelStyled>
            {this.renderFilterTabIcon()}
          </TabStyled>
        </TabListStyled>
        <TabPanelStyled expanded={filtersExpanded ? 1 : 0}>
          <FiltersContainer>
            <Status testId={testId} t={t} />
            <CarsCountRange testId={testId} t={t} />
            <RegistrationDate testId={testId} t={t} />
            <ClientType testId={testId} t={t} />
            <CarInDms testId={testId} t={t} />
            <Approvals testId={testId} t={t} />
            <Footer handleClearClick={this.handleClearClick} onSubmit={handleSubmit} onClose={this.toggleFilterTab} testId={testId} t={t} />
          </FiltersContainer>
        </TabPanelStyled>
      </TabsStyled>
    )
  }

  private readonly toggleFilterTab = () => {
    this.setState({
      filtersExpanded: !this.state.filtersExpanded,
    })
  }

  private readonly handleClearClick = () => {
    this.props.clearFilters()
    this.props.resetForm()
  }

  private readonly renderFilterTabIcon = () => this.state.filtersExpanded
    ? (
      <ArrowIcon type={IconType.ArrowTop} />
    )
    : (
      <ArrowIcon type={IconType.ArrowBottom} />
    )
}

export const ClientListFilters = compose(
  formik,
  withTranslation(),
)(ClientListFiltersComponent)
