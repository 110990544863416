import { PagedResult } from 'actff-bo-lib/api'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { DetailedFiltersFormValues, initialDetailedFiltersFormValues } from 'actff-bo-lib/crm/trade/purchase'

import { Filters, initialListPage, Loadable } from 'actff-bo-lib/global'
import { UserId } from 'actff-bo-lib/user'
import { addDays, endOfToday } from 'date-fns'
import { OpportunityPurchase, OpportunitySale, OpportunitySaleFilterValues } from '../dto'
import { CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleActionType } from './actions'
import { initialStatusFilters } from './initial-status-filters'

export type OpportunitySaleList = {
  readonly [key in keyof typeof OpportunityListType]: {
    readonly from?: Date,
    readonly to?: Date,
    readonly currentPage: number,
    readonly list: Loadable<PagedResult<OpportunitySale>>,
  }
}

export type OpportunitySaleState = OpportunitySaleList & {
  readonly currentOpportunity: OpportunitySale | null,
  readonly assignmentFilter: UserId | null,
  readonly currentMatchedPurchaseOpportunities: ReadonlyArray<OpportunityPurchase>,
  readonly filters?: Filters<OpportunitySaleFilterValues>,
  readonly detailedFilters: DetailedFiltersFormValues | null,
  readonly stringifiedDetailedFilters: string | null,
  readonly statusFilters: Filters<OpportunitySaleFilterValues>,
  readonly searchPhrase?: string,
}

const defaultFutureListOffset = 4

// tslint:disable object-literal-sort-keys no-magic-numberst
const initialState: OpportunitySaleState = {
  currentMatchedPurchaseOpportunities: [],
  all: {
    currentPage: initialListPage,
    list: {
      data: null,
      loading: false,
    },
  },
  assignmentFilter: null,
  currentOpportunity: null,
  detailedFilters: initialDetailedFiltersFormValues,
  future: {
    currentPage: initialListPage,
    list: {
      data: null,
      loading: false,
    },
    to: addDays(endOfToday(), defaultFutureListOffset),
  },
  new: {
    currentPage: initialListPage,
    list: {
      data: null,
      loading: false,
    },
  },
  overdue: {
    currentPage: initialListPage,
    list: {
      data: null,
      loading: false,
    },
  },
  statusFilters: initialStatusFilters,
  stringifiedDetailedFilters: null,
}

export const opportunitySaleReducer =
// tslint:enable
// tslint:disable-next-line:cyclomatic-complexity
  (state: OpportunitySaleState = initialState, action: CrmTradeOpportunitySaleAction): OpportunitySaleState => {
    switch (action.type) {
      case CrmTradeOpportunitySaleActionType.ChangeOpportunityListCurrentPage:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            currentPage: action.payload.page,
            list: {
              loading: true,
            },
          },
        })

      case CrmTradeOpportunitySaleActionType.ChangeOpportunityListTimeFrom:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            from: action.payload.date,
            list: {
              loading: true,
            },
          },
        })

      case CrmTradeOpportunitySaleActionType.ChangeOpportunityListTimeTo:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              loading: true,
            },
            to: action.payload.date,
          },
        })

      case CrmTradeOpportunitySaleActionType.GetOpportunityByClientSuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunitySaleActionType.ChangeAssignmentFilter:
        return {
          ...state,
          assignmentFilter: action.payload,
        }

      case CrmTradeOpportunitySaleActionType.ChangeListsStatusFilters:
        return {
          ...state,
          statusFilters: {
            ...state.statusFilters,
            [action.payload.param]: [
              ...state.statusFilters.statuses
                .map(filter => filter.value === action.payload.value
                  ? ({
                    ...filter,
                    selected: !filter.selected,
                  })
                  : ({ ...filter }),
                ),
            ],
          },
        }

      case CrmTradeOpportunitySaleActionType.ChangeListsDetailedFilters:
        return {
          ...state,
          detailedFilters: action.payload,
        }

      case CrmTradeOpportunitySaleActionType.ClearCurrentOpportunity:
        return {
          ...state,
          currentOpportunity: null,
        }

      case CrmTradeOpportunitySaleActionType.ClearSaleState:
        return {
          ...state,
          currentOpportunity: null,
        }

      case CrmTradeOpportunitySaleActionType.ChangeSearchPhrase:
        const { to, ...currentFutureListState } = state.future

        return action.payload
          ? ({
            ...state,
            future: {
              ...currentFutureListState,
            },
            searchPhrase: action.payload,
          })
          : ({
            ...initialState,
            assignmentFilter: state.assignmentFilter,
            detailedFilters: state.detailedFilters,
            statusFilters: state.statusFilters,
          })

      case CrmTradeOpportunitySaleActionType.GetOpportunities:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              loading: true,
            },
          },
        })

      case CrmTradeOpportunitySaleActionType.GetOpportunitiesFailure:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              loading: false,
            },
          },
        })

      case CrmTradeOpportunitySaleActionType.GetOpportunitiesSuccess:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              data: action.payload.opportunityList,
              loading: false,
            },
          },
        })

      case CrmTradeOpportunitySaleActionType.GetOpportunitySuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunitySaleActionType.GetMatchedPurchaseOpportunitiesSuccess:
      case CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesSuccess:
      case CrmTradeOpportunitySaleActionType.UnmatchPurchaseOpportunitySuccess:
        return ({
          ...state,
          currentMatchedPurchaseOpportunities: action.payload,
        })

      case CrmTradeOpportunitySaleActionType.ResetPaginationOnLists:
        return ({
          ...state,
          all: {
            ...state.all,
            currentPage: initialState.all.currentPage,
          },
          future: {
            ...state.future,
            currentPage: initialState.future.currentPage,
          },
          new: {
            ...state.new,
            currentPage: initialState.new.currentPage,
          },
          overdue: {
            ...state.overdue,
            currentPage: initialState.overdue.currentPage,
          },
        })

      case CrmTradeOpportunitySaleActionType.SendInternalEventSuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunitySaleActionType.SendInternalCommentSuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunitySaleActionType.UpdateOpportunitySuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunitySaleActionType.AssignOpportunitySuccess:
      case CrmTradeOpportunitySaleActionType.UnassignOpportunitySuccess:
        const opportunityListToUpdate = state[action.payload.opportunityListType]
        const opportunityResultsToUpdate = opportunityListToUpdate.list.data?.result || []
        const indexOfUpdatedOpportunitySale =
          opportunityResultsToUpdate?.findIndex(element => element.uuid === action.payload.opportunity.uuid)

        const updatedResult =
          // tslint:disable-next-line: prefer-object-spread
          Object.assign([...opportunityResultsToUpdate], { [indexOfUpdatedOpportunitySale]: action.payload.opportunity })

        return {
          ...state,
          [action.payload.opportunityListType]: {
            ...opportunityListToUpdate,
            list: {
              data: {
                ...opportunityListToUpdate.list.data,
                result: updatedResult,
              },
              loading: false,
            },
          },
        }

      default:
        return state
    }
    // tslint:disable-next-line:max-file-line-count
  }
