import { ClientFullName, ClientType as ClientTypeComponent } from 'actff-bo-app/components/Client'
import { Icon } from 'actff-bo-app/components/Icon'
import { ClientBasicData } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu'
import * as React from 'react'
import styled from 'styled-components'
import { colors, H2 } from 'styles'

type Props = {
  readonly client: ClientBasicData,
}

const ClientContainer = styled.div`
  background: ${colors.athensGray};
  grid-area: client;
  padding: 32px 30px;
  `

const ClientNameContainer = styled.div`
  grid-area: type-name;
`

const ClientName = styled(H2)`
  margin-right: 10px;
`

const ClientType = styled(ClientTypeComponent)`
  display: inline;
`

const ClientItem = styled.span`
  align-items: center;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  margin-right: 24px;
`

export const OpportunityViewClient: React.FC<Props> = ({ client }) => {
  const { email, id, phone } = client

  return (
    <ClientContainer>
      <ClientNameContainer>
        <ClientName>
          <ClientFullName client={client} />
        </ClientName>
        <ClientType className='client-type' client={client} />
      </ClientNameContainer>
      {id && <ClientItem><Icon type={IconType.IdCard} />{id}</ClientItem>}
      {phone && <ClientItem><Icon type={IconType.Phone} />{phone}</ClientItem>}
      {email && <ClientItem><Icon type={IconType.Email} />{email}</ClientItem>}
    </ClientContainer>
  )
}
