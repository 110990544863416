import {
  CarAction,
  CarEngineCode,
  CarId,
  CarInfoDto,
  CarInfoWithClient,
  selectCurrentCarAttachments,
  selectCurrentCarRegistrationNumberExists,
  selectCurrentCarVinExists,
} from 'actff-bo-lib/car'
import {
  CarModel,
  CarVersion,
  clearBrakeLiquids,
  clearCarVersions,
  clearOilTypes,
  getBrakeLiquidsAction,
  getCarModelsAction,
  getCarVersionsAction,
  getOilTypesAction,
  GetOilTypesFn,
  selectBrakeLiquids,
  selectCarModelsData,
  selectCarVersions,
  selectDealerBrands,
  selectOilTypes,
} from 'actff-bo-lib/dealership'
import { DictionaryAction, DictionaryType, selectFuelTypes } from 'actff-bo-lib/dictionary'
import { Loadable } from 'actff-bo-lib/global'
import { State } from 'actff-bo-lib/store'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'

export type DispatchToProps = {
  readonly checkRegistrationNumberExistence: (carId: CarId, registrationNumber: string) => void,
  readonly checkVinExistence: (carId: CarId, vin: string) => void,
  readonly clearBrakeLiquids: () => void,
  readonly clearCarVersions: () => void,
  readonly clearOilTypes: () => void,
  readonly getCarModels: (brand: string) => void,
  readonly getBrakeLiquids: (brand: string) => void,
  readonly getCarVersions: (brand: string) => void,
  readonly getFuelTypes: () => void,
  readonly getOilTypes: GetOilTypesFn,
  readonly getTireTypes: () => void,
  readonly showFailureToast: () => void,
  readonly updateCar: (car: CarInfoWithClient) => void,
}

export type StateToProps = {
  readonly brakeLiquids: Loadable<ReadonlyArray<string> | null>,
  readonly carAttachments: ReadonlyArray<string> | null,
  readonly carModels: ReadonlyArray<CarModel> | null,
  readonly carVersions: Loadable<ReadonlyArray<CarVersion>>,
  readonly dealerBrands: Loadable<ReadonlyArray<string> | null>,
  readonly fuelTypes: ReadonlyArray<DictionaryType<string>>,
  readonly currentCarVinExists: boolean,
  readonly currentCarRegistrationNumberExists: boolean,
  readonly oilTypes: ReadonlyArray<string> | null,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

export const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  checkRegistrationNumberExistence: (carId: CarId, registrationNumber: string) => {
    dispatch(CarAction.checkRegistrationNumberExistence(carId, registrationNumber))
  },
  checkVinExistence: (carId: CarId, vin: string) => { dispatch(CarAction.checkVinExistence(carId, vin)) },
  clearBrakeLiquids: () => { dispatch(clearBrakeLiquids()) },
  clearCarVersions: () => { dispatch(clearCarVersions()) },
  clearOilTypes: () => { dispatch(clearOilTypes()) },
  deleteCar: (carId: CarId) => { dispatch(CarAction.deleteCar(carId)) },
  getBrakeLiquids: (brand: string) => { dispatch(getBrakeLiquidsAction(brand)) },
  getCarModels: (brand: string) => { dispatch(getCarModelsAction([brand])) },
  getCarVersions: (brand: string) => { dispatch(getCarVersionsAction([brand])) },
  getFuelTypes: () => { dispatch(DictionaryAction.getFuelTypes()) },
  getOilTypes: (brand: string | null, engineCode: CarEngineCode) => {
    dispatch(getOilTypesAction({ brand, engineCode }))
  },
  getTireTypes: () => { dispatch(DictionaryAction.getTireTypes()) },
  showFailureToast: () => {
    dispatch(displayFailureToast('admin.car.service.getCarCodeEnginesFailure', ToastActionType.GET))
  },
  updateCar: (car: CarInfoDto) => { dispatch(CarAction.updateCar(car)) },
})

export const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  brakeLiquids: selectBrakeLiquids(state),
  carAttachments: selectCurrentCarAttachments(state),
  carModels: selectCarModelsData(state),
  carVersions: selectCarVersions(state),
  currentCarRegistrationNumberExists: selectCurrentCarRegistrationNumberExists(state),
  currentCarVinExists: selectCurrentCarVinExists(state),
  dealerBrands: selectDealerBrands(state),
  fuelTypes: selectFuelTypes(state),
  oilTypes: selectOilTypes(state),
  userPermissions: selectUserPermissions(state),
})
