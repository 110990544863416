import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { switchMap } from 'rxjs/operators'
import {
  AdminLocationSettingsAction,
  AdminLocationSettingsActionType,
  AdminOpeningHoursAction,
  AdminOpeningHoursActionType,
} from '../actions'

const notifyOnUpdateSuccessEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<
    typeof AdminLocationSettingsAction.updateDealerSettingsSuccess
    | typeof AdminOpeningHoursAction.updateOpeningHoursSuccess
    | typeof AdminOpeningHoursAction.updateOpeningHoursSuccess
    >>(
    AdminLocationSettingsActionType.UpdateDealerSettingsSuccess,
    AdminOpeningHoursActionType.UpdateOpeningHoursSuccess,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: 'toast.updateActionSuccess',
      id: 'admin.dealerLocations.form.update.success',
      title: 'toast.success',
      type: ToastType.Success,
    })],
  ))

const notifyOnUpdateFailureEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<ReturnType<
    typeof AdminLocationSettingsAction.updateDealerSettingsFailure
    | typeof AdminOpeningHoursAction.updateOpeningHoursFailure
    >>(
    AdminLocationSettingsActionType.UpdateDealerSettingsFailure,
    AdminOpeningHoursActionType.UpdateOpeningHoursFailure,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      body: 'toast.updateActionFailure',
      id: 'admin.dealerLocations.form.update.failure',
      title: 'toast.failure',
      type: ToastType.Error,
    })],
  ))

export const notifyEpic = combineEpics(
  notifyOnUpdateSuccessEpic,
  notifyOnUpdateFailureEpic,
)
