import { Testable } from 'actff-bo-lib/global/testable'
import { Field } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

import { Label } from '../Label'
import { CheckboxInputField } from './CheckboxInputField'

type YesNoCheckboxProps = Testable & {
  readonly darkColor?: boolean,
  readonly darkLabel?: boolean,
  readonly disabled?: boolean,
  readonly error?: string,
  readonly inline?: boolean,
  readonly label?: string,
  readonly name: string,
  readonly onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  readonly slim?: boolean,
  readonly uppercaseLabel?: boolean,
}

const Container = styled.div<YesNoCheckboxProps>`
  display: ${({ inline }: YesNoCheckboxProps) => inline ? 'inline-grid' : 'grid'};
  grid-template-columns: ${({ inline }: YesNoCheckboxProps) => inline ? '1fr 1fr' : ''};
`

export const YesNoCheckbox: React.FC<YesNoCheckboxProps> = ({
  darkLabel= false,
  label,
  name,
  testId,
  uppercaseLabel = true,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <Label color={!darkLabel ? colors.dustyGray : colors.dark} uppercase={uppercaseLabel} htmlFor={name}>{label}</Label>
      <Container>
        <Field
          component={CheckboxInputField}
          htmlFor={`${name}Yes`}
          id={`${name}Yes`}
          label={t('caption.yes')}
          name={name}
          slim={true}
          testId={testId}
          value={true}
        />
        <Field
          component={CheckboxInputField}
          htmlFor={`${name}No`}
          id={`${name}No`}
          label={t('caption.no')}
          name={name}
          slim={true}
          testId={testId}
          value={false}
        />
      </Container>
    </div>
  )
}
