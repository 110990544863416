import { api } from 'actff-bo-lib/api'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { ApprovalsContent } from './dto'

// TODO: Remove contactApprovals field when it will be consistent with contracts on BE side
export const getPrivacyPolicy = (): Observable<ApprovalsContent> => api
  .get<{ readonly contactApprovals: ApprovalsContent }>('/contact-approvals').pipe(
    map(improperModelFromAPI => improperModelFromAPI.contactApprovals),
  )
