import { SelectOption } from 'actff-bo-lib/global'
import i18next from 'i18next'
import { Filters } from 'react-data-grid'
import { readString } from 'react-papaparse'

import {
  FilterType,
  OpportunityInsuranceSellTypes,
  OpportunityInsuranceType,
  OpportunitySourceName,
  Report,
  ReportColumns,
} from '../dto'

const translatableKeys = {
  confirm: ['true', 'false'],
  insuranceSellType: Object.keys(OpportunityInsuranceSellTypes),
  insuranceSourceName: Object.keys(OpportunitySourceName),
  insuranceType: Object.keys(OpportunityInsuranceType),
}

const translateValue = (value: string) => {
  const [toTranslate] = Object.keys(translatableKeys)
    .filter(key => translatableKeys[key].indexOf(value) > -1)
    .map(key => `crmInsurance.${key}.${value.toLowerCase()}`)

  return toTranslate || value
}

export const parseReport = (raw: string): Report => (readString(raw).data as Report)
  .map((row, index) => row
    .map(value =>
      index === 0
        ? `crmInsurance.reportHeader.${value}`
        : translateValue(value),
      ))

export const translateReport = <T>(data: Report, cols: ReadonlyArray<string>, translationBaseKey: string, t: i18next.TFunction) => data
  .map<T>(row =>
    row.reduce((sum, col, index) => ({
      ...sum,
      [cols[index]]: col.includes(translationBaseKey)
        ? t(col)
        : col,
    }),
    {} as T, // tslint:disable-line no-object-literal-type-assertion
  ),
)

export const filterData = <R, S>(data: Report | null, columns: ReportColumns<R, S>, filters?: Filters) => {
  if (!data) {
    return []
  }

  if (!filters) {
    return data
  }

  const colKeys = columns
    .map(({ key }, index) => [key, index])
    .reduce((keys, [ key, index ]) => ({ ...keys, [key]: index }), {})

  const filterKeys = Object.keys(filters)
  const appliedFiltersLength = filterKeys.filter(Boolean).length

  const getMultiFiltersResults = (filtersValue: Filters, row: string) => {
    const multiFilters = filtersValue ? filtersValue.map((filter: SelectOption<string>) => filter.value) : []

    if (multiFilters.length === 0) {
      return true
    }

    return multiFilters.includes(row)
  }

  // tslint:disable:cyclomatic-complexity
  return data.filter(
      row => filterKeys
        .filter(colName => colKeys[colName] !== undefined)
        .filter(colName => {
          const type = columns[colKeys[colName]].filterType || FilterType.Like

          if (filters[colName] === '') {
            return true
          }

          switch (type) {
            case FilterType.Multi:
              return getMultiFiltersResults(filters[colName], row[colKeys[colName]])
            case FilterType.Equal:
              return row[colKeys[colName]] === filters[colName]
            case FilterType.Like:
            default:
              return row[colKeys[colName]].toLowerCase().includes(filters[colName].toLowerCase())
          }
        })
        .length === appliedFiltersLength,
      )

}
