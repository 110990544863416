import { ValidationError } from 'actff-bo-app/components/Form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type ValidationErrorProps = {
  readonly children: string,
}

export const ValidationErrorMessage: React.FC<ValidationErrorProps> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <ValidationError>{t(children)}</ValidationError>
  )
}
