import axios from 'axios'

import {
  Headers,
  mergeToDefaultHeaders,
  mergeToHeadersWithPayload,
  prefixedUrl,
} from './request-utils'

const get = async <T>(url: string, headers?: Headers): Promise<T> => {
  const { data } = await axios.get(prefixedUrl(url), { headers: mergeToDefaultHeaders(headers) })

  return data
}

const post = async <T, U>(url: string, requestData: U, headers?: Headers, autoFillContentType?: boolean): Promise<T> => {
  const { data } = await axios.post(
    prefixedUrl(url),
    requestData,
    {
      headers: autoFillContentType
        ? mergeToDefaultHeaders(headers)
        : mergeToHeadersWithPayload(headers),
    },
  )

  return data
}

const put = async <T, U>(url: string, requestData: U, headers?: Headers): Promise<T> => {
  const { data } = await axios.put(
    prefixedUrl(url),
    requestData,
    {headers: mergeToHeadersWithPayload(headers)},
  )

  return data
}

const patch = async <T, U>(url: string, requestData: U, headers?: Headers, autoFillContentType?: boolean): Promise<T> => {
  const { data } = await axios.patch(
    prefixedUrl(url),
    requestData,
    {
      headers: autoFillContentType
        ? mergeToDefaultHeaders(headers)
        : mergeToHeadersWithPayload(headers),
    },
  )

  return data
}

const _delete = async <T, U>(url: string, requestData?: U, headers?: Headers): Promise<T> => {
  const { data } = await axios.delete(
    prefixedUrl(url),
    {
      headers: mergeToDefaultHeaders(headers),
      // tslint:disable-next-line:object-literal-sort-keys data key should go after headers
      data: requestData,
    },
  )

  return data
}

export const axiosApi = {
  delete: _delete,
  get,
  patch,
  post,
  put,
}
