import { State } from 'actff-bo-lib/store'
import { selectUserPermissions } from 'actff-bo-lib/user/selectors'
import { createSelector } from 'reselect'

import { Path } from './dto'
import { filterAvailableMenuItems } from './helpers/filter-available-menu-items'
import { filterHiddenMenuItems } from './helpers/filter-hidden-menu-items'
import { flatNestedMenuItems } from './helpers/flat-nested-menu-items'
import { getMenuItemWithSpecifiedPath } from './helpers/get-menu-item-with-specified-path'

const selectMenuFeature = (state: State) => state.menu

export const selectMenuExpanded = createSelector(selectMenuFeature, menu => menu.expanded)

export const selectMenuDisabled = createSelector(selectMenuFeature, menu => menu.disabled)

export const selectMenuItems = createSelector(selectMenuFeature, menu => menu.items)

export const selectMenuAsFlatStructure = createSelector(selectMenuItems, flatNestedMenuItems)

export const selectAvailableMenuItems =
  createSelector(selectMenuItems, selectUserPermissions, filterAvailableMenuItems)

export const selectVisibleMenuItems = createSelector(selectAvailableMenuItems, filterHiddenMenuItems)

export const selectMenuForCurrentUser =
  createSelector(selectMenuItems, selectUserPermissions, (menu, permissions) =>
    filterHiddenMenuItems(filterAvailableMenuItems(menu, permissions)))

export const selectMenuItemWithSpecifiedPath = (path: Path) =>
  createSelector(selectMenuItems, selectUserPermissions, (menu, permissions) =>
    getMenuItemWithSpecifiedPath(filterAvailableMenuItems(menu, permissions), path))
