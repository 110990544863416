import { DateSelectArg, EventInput } from '@fullcalendar/core'
import { SchedulerViewType } from 'actff-bo-app/Admin/EmployeeSchedule/config'
import {
  CalendarResourceAvailability,
  CalendarResourceStatus,
  CalendarResourceType,
  Shift,
} from 'actff-bo-lib/admin/employee-scheduler/dto'
import { dashDateFormat, defaultTimeFormat, localTimeFormat } from 'actff-bo-lib/date'
import { endOfDay, format, parse, startOfDay, subDays } from 'date-fns'
import i18next from 'i18next'

import { CalendarResourceId, emptyShift, ScheduleRuleForm } from './dto'

const getResourceTitle = (availability: CalendarResourceAvailability) =>
  availability.resource.type === CalendarResourceType.REPLACEMENT_CAR
    ? `${availability.resource.registrationNumber}`
    : `${availability.resource.firstName} ${availability.resource.lastName}`

const mapResource = (mappedResource: CalendarResourceAvailability) =>
  ({
    extendedProps: mappedResource.resource,
    id: mappedResource.resource.uuid,
    title: getResourceTitle(mappedResource),
  })

export const getShiftTitle = (from: string, to: string) =>
  `${format(parse(from), defaultTimeFormat)} - ${format(parse(to), defaultTimeFormat)}`

export const mapScheduleToResources = (
  schedule?: ReadonlyArray<CalendarResourceAvailability>,
  t?: i18next.TFunction,
) => {
  const resourceTypeTitle = (type: string) =>
    (t && t(`admin.employeeSchedule.resourceType.${type}`)) ?? type

  const resources = Object.keys(CalendarResourceType).map(resourceType => ({
    id: resourceType, title: resourceTypeTitle(resourceType),
  }))

  return resources.map(resource => {
    const mappedResources =
      schedule?.filter(item => item.resource.status !== CalendarResourceStatus.ARCHIVED)
        .filter(item => item.resource.type === resource.id)
        .map(mapResource)

    return {
      ...resource,
      children: mappedResources,
    }
  })

}

const mapHoursToColor = (timeFrom: string, timeTo: string, shifts?: ReadonlyArray<Shift>): string =>
  shifts
    ? shifts.filter(shift => shift.from === timeFrom && shift.to === timeTo)[0]?.colour
    : emptyShift.colour

export const mapScheduleToEvents = (shifts?: ReadonlyArray<Shift>, schedule?: ReadonlyArray<CalendarResourceAvailability>) =>
  schedule?.map(item => {
    const mappedHours = item.workingHours.map(workingHours => {
      const timeFrom = format(parse(workingHours.from), localTimeFormat)
      const timeTo = format(parse(workingHours.to), localTimeFormat)
      const color = mapHoursToColor(timeFrom, timeTo, shifts)
      const title = `${format(parse(workingHours.from), defaultTimeFormat)} - ${format(parse(workingHours.to), defaultTimeFormat)}`

      return {
        backgroundColor: color,
        borderColor: color,
        end: workingHours.to,
        extendedProps: {
          eventId: `${item.resource.uuid}-${workingHours.from}-${workingHours.to}`,
          resourceLabel: getResourceTitle(item),
          selected: false,
        },
        resourceId: item.resource.uuid,
        start: workingHours.from,
        title,
      }
    })

    return mappedHours
  }).reduce((acc, val) => acc.concat(val))

export const mapSelectionToDefaultValues = (click: DateSelectArg): ScheduleRuleForm =>
  ({
    elements: [{
      from: click.start,
      hoursFrom: click.start,
      hoursTo: endOfDay(subDays(click.end, 1)),
      to: click.view.type === SchedulerViewType.DAY ? click.end : endOfDay(subDays(click.end, 1)),
    }],
    isRecurrent: false,
    resources: [
      {
        label: click.resource?._resource.title || emptyShift.name,
        value: (click.resource?._resource.id || emptyShift.name) as CalendarResourceId,
      },
    ],
  })

const getElements = (form?: ScheduleRuleForm) =>
  form?.elements.map(element => ({
    from: format(element.from, dashDateFormat),
    hoursFrom: format(element.hoursFrom, localTimeFormat),
    hoursTo: format(element.hoursTo, localTimeFormat),
    to: format(element.to, dashDateFormat),
  }))

export const mapScheduleFormToRules = (form: ScheduleRuleForm) =>
  ({
    elements: getElements(form),
    end: form.end,
    every: form.every,
    isRecurrent: form.isRecurrent,
    resourceUuids: form.resources.map(resource => resource.value),
  })

export const mapScheduleFormToDeleteRules = (form?: ScheduleRuleForm) =>
  ({
    elements: getElements(form),
    resourceUuid: form?.resources[0].value,
  })

const mapHoursToShift = (timeFrom: string, timeTo: string, shifts?: ReadonlyArray<Shift>) => {
  const shift = shifts?.filter(item =>
    item.from === format(timeFrom, localTimeFormat) &&
    item.to === format(timeTo, localTimeFormat),
  )[0]

  return {
    label: shift?.name,
    value: shift,
  }
}

const groupEvents = (selectedEvents?: ReadonlyArray<EventInput>) =>
  selectedEvents?.reduce((reducedEvents: ReadonlyArray<EventInput>, selectedEvent: EventInput) => {
    const shouldAddToLastGroup = reducedEvents.length &&
      selectedEvent.backgroundColor === reducedEvents[reducedEvents.length - 1][0].backgroundColor

    if (shouldAddToLastGroup) {
      return reducedEvents.map((eventArray: ReadonlyArray<EventInput>, index) => {
        const isLastItem = index === (reducedEvents.length - 1)

        return isLastItem ? [...eventArray, selectedEvent] : eventArray
      })
    } else {
      return [...reducedEvents, [selectedEvent]]
    }
  }, [])

export const mapSelectionToFormValues =
  (shifts?: ReadonlyArray<Shift>, selectedEvents?: ReadonlyArray<EventInput>) => {

  const elements = groupEvents(selectedEvents)?.map((event: EventInput) => {
      const eventStart = event[0].start
      const eventEnd = event[0].end

      if (event.length === 1) {
        return {
          from: startOfDay(eventStart),
          hoursFrom: eventStart,
          hoursTo: eventEnd,
          shift: mapHoursToShift(eventStart, eventEnd, shifts),
          to: startOfDay(eventEnd),
        }
      }

      return {
        from: startOfDay(eventStart),
        hoursFrom: eventStart,
        hoursTo: eventEnd,
        shift: mapHoursToShift(eventStart, eventEnd, shifts),
        to: startOfDay(event[event.length - 1].end),
      }
    })

  const selectedEvent = selectedEvents && selectedEvents[0]
  const resourceLabel = selectedEvent?.extendedProps?.resourceLabel || ''
  const resourceValue = selectedEvent?.resourceId || ''

  return {
    elements,
    isRecurrent: false,
    resources: [{
      label: resourceLabel,
      value: resourceValue as CalendarResourceId,
    }],
  }
}
