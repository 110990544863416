import { ServiceOpportunityInternalComment } from 'actff-bo-lib/crm/service'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { ServiceRequestInternalComment } from 'actff-bo-lib/service-request'

export type InternalComment = ServiceRequestInternalComment | ServiceOpportunityInternalComment

export const createInternalComment = (data: any): InternalComment => ({ // tslint:disable-line no-any
  ...data,
  created: parseToDateOrNull(data.created),
  updated: parseToDateOrNull(data.updatedTime),
})
