import { DayOfWeek } from '../../date'
import { DealerLocationKey } from '../../dealership'

export type AdminDealerSettings = {
  readonly workdaysToAvailableVisit: number,
  readonly contact: {
    readonly email: string,
    readonly receptionPhone: string,
    readonly officePhone: string,
  },
  readonly address: {
    readonly city: string,
    readonly street: string,
    readonly houseNumber: string,
    readonly postcode: string,
  },
}

export type Time = ReadonlyArray<number>

export type OpeningHours = {
  readonly isWorkingDay: boolean,
  readonly dayOfWeek: number,
  readonly timeFrom: Time | null,
  readonly timeTo: Time | null,
}

export const defaultWorkdaysFormValues: ReadonlyArray<OpeningHours> = Array.from(DayOfWeek).map(dayOfWeek => ({
  dayOfWeek,
  isWorkingDay: false,
  timeFrom: null,
  timeTo: null,
}))

export enum ApplicationLinkType {
  ACCESSORIES = 'ACCESSORIES',
  NEW_CARS = 'NEW_CARS',
  USED_CARS = 'USED_CARS',
}

export type ApplicationLink = {
  readonly type: ApplicationLinkType,
  readonly enabled: boolean,
  readonly url: string,
}

export type ApplicationLinks = {
  readonly accessories: ApplicationLink,
  readonly usedCars: ApplicationLink,
  readonly newCars: ApplicationLink,
}

export type UpdateDealerLocationBrandsPayload = {
  readonly name: string,
  readonly key: DealerLocationKey,
}

export type UpdateDealerLocationD2DCitiesPayload = {
  readonly name: string,
  readonly key: DealerLocationKey,
}

export type UpdateDealerLocationApplicationLinksPayload = {
  readonly links: ApplicationLinks,
  readonly locationKey: DealerLocationKey,
}
