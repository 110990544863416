import { ClientAction, ClientActionType, isClientPreferences, selectClientPreferences } from 'actff-bo-lib/client'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import {
  assignTradeOpportunity,
  getMatchedTradeOpportunities,
  getTradeOpportunities,
  getTradeOpportunity,
  sendTradeOpportunityInternalComment,
  sendTradeOpportunityInternalEvent,
  unassignTradeOpportunity,
} from 'actff-bo-lib/crm/trade/dao'
import {
  OpportunityPurchase,
  OpportunityPurchaseId,
  OpportunitySale,
  OpportunityTradeInternalEventDto,
  TradeOpportunityType,
} from 'actff-bo-lib/crm/trade/dto'
import {
  CrmTradeOpportunityPurchaseAction,
  CrmTradeOpportunityPurchaseActionType,
} from 'actff-bo-lib/crm/trade/purchase/actions'
import {
  getMatchingPurchaseOpportunities,
  matchSaleOpportunities,
  updateOpportunityPurchase,
} from 'actff-bo-lib/crm/trade/purchase/dao'
import { stringifyDetailedFilters } from 'actff-bo-lib/crm/trade/purchase/filters'
import {
  selectOpportunityPurchaseListAssignmentFilter,
  selectOpportunityPurchaseListCurrentPage,
  selectOpportunityPurchaseListsDetailedFilters,
  selectOpportunityPurchaseListsStatusFilters,
  selectOpportunityPurchaseListsStringifiedDetailedFilters,
  selectOpportunityPurchaseListTimeFrom,
  selectOpportunityPurchaseListTimeTo,
  selectOpportunityPurchaseSearchPhrase,
} from 'actff-bo-lib/crm/trade/purchase/selectors'
import { isNotNull } from 'actff-bo-lib/dictionary'
import {
  Attachment,
  AttachmentAction,
  AttachmentActionType,
  AttachmentUploadType,
  haveAttachmentsToUpload,
} from 'actff-bo-lib/global'
import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { Paths } from 'actff-bo-lib/menu'
import { redirectToList } from 'actff-bo-lib/pagination'
import { State } from 'actff-bo-lib/store'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import { hasPermission, selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { iif, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { CrmTradeOpportunitySaleAction, CrmTradeOpportunitySaleActionType } from '../sale'

type OpportunityOrAttachmentAction = CrmTradeOpportunityPurchaseAction | AttachmentAction

const assignOpportunityEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.assignOpportunity>>(CrmTradeOpportunityPurchaseActionType.AssignOpportunity),
  switchMap(({ payload }) => assignTradeOpportunity<OpportunityPurchase>(payload.uuid, payload.user, TradeOpportunityType.Purchase).pipe(
    map(opportunity => CrmTradeOpportunityPurchaseAction.assignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.assignOpportunityFailure(error))),
  ),
))

const prepareDetailedFiltersEpic: Epic<CrmTradeOpportunityPurchaseAction, CrmTradeOpportunityPurchaseAction, State> =
  (action$, state$) => action$.pipe(
    ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.changeListsDetailedFilters>>(
      CrmTradeOpportunityPurchaseActionType.ChangeListsDetailedFilters,
    ),
    withLatestFrom(state$),
    map(([, store]) => CrmTradeOpportunityPurchaseAction.saveStringifiedDetailedFilters(stringifyDetailedFilters(
      selectOpportunityPurchaseListsDetailedFilters(store)),
    )),
)

const changeFilterOrSearchPhraseEpic: Epic<CrmTradeOpportunityPurchaseAction, CrmTradeOpportunityPurchaseAction, State> =
  (action$, state$) =>
    action$.pipe(
      ofType(
        CrmTradeOpportunityPurchaseActionType.ChangeListsStatusFilters,
        CrmTradeOpportunityPurchaseActionType.SaveStringifiedDetailedFilters,
        CrmTradeOpportunityPurchaseActionType.ClearListsDetailedFilters,
        CrmTradeOpportunityPurchaseActionType.ChangeSearchPhrase,
        CrmTradeOpportunityPurchaseActionType.ChangeAssignmentFilter),
      withLatestFrom(state$),
      switchMap(([, store]) => {
        const userPermissions = selectUserPermissions(store)
        const defaultActionsToReturn: ReadonlyArray<CrmTradeOpportunityPurchaseAction> = [
          CrmTradeOpportunityPurchaseAction.resetPaginationOnLists(),
          CrmTradeOpportunityPurchaseAction.getOpportunities(OpportunityListType.future),
          CrmTradeOpportunityPurchaseAction.getOpportunities(OpportunityListType.overdue),
          CrmTradeOpportunityPurchaseAction.getOpportunities(OpportunityListType.new),
        ]

        return hasPermission([UserPermissions.AdminAllService])(userPermissions)
          ? ([
            ...defaultActionsToReturn,
            CrmTradeOpportunityPurchaseAction.getOpportunities(OpportunityListType.all),
          ])
          : defaultActionsToReturn
      }),
)

const getOpportunitiesEpic: Epic<CrmTradeOpportunityPurchaseAction, CrmTradeOpportunityPurchaseAction, State> =
  (action$, state$) => action$.pipe(
    ofType<
      ReturnType<typeof CrmTradeOpportunityPurchaseAction.getOpportunities>
      | ReturnType<typeof CrmTradeOpportunityPurchaseAction.changeOpportunityListCurrentPage>
      | ReturnType<typeof CrmTradeOpportunityPurchaseAction.changeOpportunityListTimeFrom>
      | ReturnType<typeof CrmTradeOpportunityPurchaseAction.changeOpportunityListTimeTo>
    >(
      CrmTradeOpportunityPurchaseActionType.GetOpportunities,
      CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListCurrentPage,
      CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListTimeFrom,
      CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListTimeTo,
    ),
    withLatestFrom(state$),
    mergeMap(([{ payload: { type } }, store]) => {
      const page = selectOpportunityPurchaseListCurrentPage(type)(store) - 1
      const detailedFilters = selectOpportunityPurchaseListsDetailedFilters(store)
      const stringifiedDetailedFilters = selectOpportunityPurchaseListsStringifiedDetailedFilters(store)
      const preparedStringifiedDetailedFilters =
        isNotNull(stringifiedDetailedFilters) ? (stringifiedDetailedFilters as string) : stringifyDetailedFilters(detailedFilters)
      const assignmentFilter = selectOpportunityPurchaseListAssignmentFilter(store)
      const statusFilters = selectOpportunityPurchaseListsStatusFilters(store)
      const from = selectOpportunityPurchaseListTimeFrom(type)(store)
      const to = selectOpportunityPurchaseListTimeTo(type)(store)
      const search = encodeStringIfExists(selectOpportunityPurchaseSearchPhrase(store))

      return getTradeOpportunities<OpportunityPurchase>({
        assignmentFilter,
        detailedFilters: preparedStringifiedDetailedFilters,
        fromDate: detailedFilters?.from || from,
        opportunityType: TradeOpportunityType.Purchase,
        page,
        search,
        statusFilters,
        toDate: detailedFilters?.to || to,
        type,
      }).pipe(
        map(list => CrmTradeOpportunityPurchaseAction.getOpportunitiesSuccess(type, list)),
        catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.getOpportunitiesFailure(type, error))),
      )
    }),
)

const getOpportunityEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.getOpportunity>>(CrmTradeOpportunityPurchaseActionType.GetOpportunity),
  switchMap(({ payload }) => getTradeOpportunity<OpportunityPurchase>(payload, TradeOpportunityType.Purchase).pipe(
    map(CrmTradeOpportunityPurchaseAction.getOpportunitySuccess),
    catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.getOpportunityFailure(error))),
  )),
)

const getMatchingPurchaseOpportunitiesEpic: Epic<
  CrmTradeOpportunityPurchaseAction | ClientAction | CrmTradeOpportunitySaleAction,
  CrmTradeOpportunityPurchaseAction,
  State> = (action$, state$) =>
  action$.pipe(
    ofType<
      ReturnType<typeof ClientAction.saveClientPreferencesSuccess>
      | ReturnType<typeof ClientAction.getClientPreferencesSuccess>
      | ReturnType<typeof CrmTradeOpportunitySaleAction.matchPurchaseOpportunitiesSuccess>
      | ReturnType<typeof CrmTradeOpportunityPurchaseAction.changeSearchMatchingOpportunitiesCurrentPage>
      | ReturnType<typeof CrmTradeOpportunityPurchaseAction.changeSearchMatchingOpportunitiesSearchPhrase>
    >(
      ClientActionType.SaveClientPreferencesSuccess,
      ClientActionType.GetClientPreferencesSuccess,
      CrmTradeOpportunitySaleActionType.MatchPurchaseOpportunitiesSuccess,
      CrmTradeOpportunityPurchaseActionType.ChangeSearchMatchingOpportunitiesCurrentPage,
      CrmTradeOpportunityPurchaseActionType.ChangeSearchMatchingOpportunitiesSearchPhrase,
    ),
    withLatestFrom(state$),
    switchMap(([{ payload }, store]) => {
      const page = selectOpportunityPurchaseListCurrentPage(OpportunityListType.all)(store) - 1
      const preferences = isClientPreferences(payload) ? payload : selectClientPreferences(store).data
      const searchPhrase = encodeStringIfExists(selectOpportunityPurchaseSearchPhrase(store))

      return getMatchingPurchaseOpportunities(
        page,
        preferences,
        searchPhrase,
      ).pipe(
        map(list => CrmTradeOpportunityPurchaseAction.getMatchingPurchaseOpportunitiesSuccess(list)),
        catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.getMatchingPurchaseOpportunitiesFailure(error))),
      )
    }),
  )

const getMatchedSaleOpportunitiesEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.getMatchedSaleOpportunities>>(
    CrmTradeOpportunityPurchaseActionType.GetMatchedSaleOpportunities,
  ),
  switchMap(({ payload }) =>
    getMatchedTradeOpportunities<OpportunityPurchaseId, OpportunitySale>(payload.opportunityId, TradeOpportunityType.Purchase).pipe(
      map(CrmTradeOpportunityPurchaseAction.getMatchedSaleOpportunitiesSuccess),
      catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.getMatchedSaleOpportunitiesFailure(error))),
    ),
  ))

const matchSaleOpportunitiesEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunityPurchaseAction.matchSaleOpportunities>
    >(CrmTradeOpportunityPurchaseActionType.MatchSaleOpportunities),
  switchMap(({ payload }) => matchSaleOpportunities(payload.purchaseId, payload.opportunities).pipe(
    map(CrmTradeOpportunityPurchaseAction.matchSaleOpportunitiesSuccess),
    catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.matchSaleOpportunitiesFailure(error))),
  ),
))

const sendOpportunityInternalEventWithAttachmentsEpic: Epic<OpportunityOrAttachmentAction, OpportunityOrAttachmentAction, State> =
  (action$, state$) =>
    action$.pipe(
      ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.sendInternalEventWithAttachments>>(
        CrmTradeOpportunityPurchaseActionType.SendInternalEventWithAttachments,
      ),
      withLatestFrom(state$),
      mergeMap(([{ payload: { opportunityId, internalEvent, attachments } }]) =>
        iif(
          () => haveAttachmentsToUpload(attachments),
          of(
            AttachmentAction.sendAttachments<OpportunityTradeInternalEventDto | OpportunityPurchaseId>(
              attachments as ReadonlyArray<Attachment>,
              AttachmentUploadType.OpportunityPurchaseEvent,
              { internalEvent, opportunityId }),
          ),
          of(CrmTradeOpportunityPurchaseAction.sendInternalEvent(
            opportunityId,
            internalEvent,
          )),
        ),
      ),
    )

const uploadAttachmentsSuccessEpic: Epic<OpportunityOrAttachmentAction, OpportunityOrAttachmentAction, State> = action$ => action$.pipe(
  ofType<ReturnType<typeof AttachmentAction.sendAttachmentsComplete>>(AttachmentActionType.SendAttachmentsComplete),
  pluck('payload'),
  filter(({ type }) => type === AttachmentUploadType.OpportunityPurchaseEvent),
  map(({ urls, props: { internalEvent, opportunityId } }) => {
    const eventWithAttachments = {
      ...(internalEvent as OpportunityTradeInternalEventDto),
      attachments: urls,
    }

    return CrmTradeOpportunityPurchaseAction.sendInternalEvent(
      opportunityId as OpportunityPurchaseId,
      eventWithAttachments as OpportunityTradeInternalEventDto,
    )
  }),
)

const sendOpportunityInternalEventEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.sendInternalEvent>>(CrmTradeOpportunityPurchaseActionType.SendInternalEvent),
  switchMap(({ payload }) =>
    sendTradeOpportunityInternalEvent<OpportunityPurchase>(payload.opportunityId, payload.internalEvent, TradeOpportunityType.Purchase)
      .pipe(
        map(CrmTradeOpportunityPurchaseAction.sendInternalEventSuccess),
        catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.sendInternalEventFailure(error))),
      )),
)

const sendOpportunityInternalCommentEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunityPurchaseAction.sendInternalComment>
  >(CrmTradeOpportunityPurchaseActionType.SendInternalComment),
  switchMap(({ payload }) =>
    sendTradeOpportunityInternalComment(payload.opportunityId, payload.internalCommentDto, TradeOpportunityType.Purchase).pipe(
    map(CrmTradeOpportunityPurchaseAction.sendInternalCommentSuccess),
    catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.sendInternalCommentFailure(error))),
  )),
)

const unassignOpportunityEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.unassignOpportunity>>
    (CrmTradeOpportunityPurchaseActionType.UnassignOpportunity),
  switchMap(({ payload }) => unassignTradeOpportunity<OpportunityPurchase>(payload.uuid, TradeOpportunityType.Purchase).pipe(
    map(opportunity => CrmTradeOpportunityPurchaseAction.unassignOpportunitySuccess(opportunity, payload.opportunityListType)),
    catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.unassignOpportunityFailure(error))),
  ),
))

const updateOpportunityEpic: Epic<CrmTradeOpportunityPurchaseAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.updateOpportunity>>
    (CrmTradeOpportunityPurchaseActionType.UpdateOpportunity),
  switchMap(({ payload }) => updateOpportunityPurchase(payload).pipe(
    map((opportunity: OpportunityPurchase) => CrmTradeOpportunityPurchaseAction.updateOpportunitySuccess(opportunity)),
    catchError((error: AjaxError) => of(CrmTradeOpportunityPurchaseAction.updateOpportunityFailure(error))),
  ),
))
const redirectToListOnUpdateSuccessEpic: Epic<CrmTradeOpportunityPurchaseAction, CrmTradeOpportunityPurchaseAction, State> =
  (action$, store$) => action$.pipe(
    ofType<ReturnType<typeof CrmTradeOpportunityPurchaseAction.updateOpportunitySuccess>>
      (CrmTradeOpportunityPurchaseActionType.UpdateOpportunitySuccess),
    withLatestFrom(store$),
    tap(() => {
      redirectToList(Paths.CrmTradePurchaseOpportunityList, 1)
    }),
    ignoreElements(),
)

const alertOnFailureActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunityPurchaseAction.sendInternalCommentFailure>
    | ReturnType<typeof CrmTradeOpportunityPurchaseAction.sendInternalEventFailure>
    | ReturnType<typeof CrmTradeOpportunityPurchaseAction.updateOpportunityFailure>
    >(
    CrmTradeOpportunityPurchaseActionType.SendInternalCommentFailure,
    CrmTradeOpportunityPurchaseActionType.SendInternalEventFailure,
    CrmTradeOpportunityPurchaseActionType.UpdateOpportunityFailure,
  ),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'api.actionFailure',
      title: 'api.actionFailure',
      type: ToastType.Error,
    }),
  ]),
)

const informOnSuccessActionEpic: Epic<AnyAction> = action$ => action$.pipe(
  ofType<
    ReturnType<typeof CrmTradeOpportunityPurchaseAction.sendInternalCommentSuccess>
    | ReturnType<typeof CrmTradeOpportunityPurchaseAction.sendInternalEventSuccess>
    | ReturnType<typeof CrmTradeOpportunityPurchaseAction.updateOpportunitySuccess>
    >(
    CrmTradeOpportunityPurchaseActionType.SendInternalCommentSuccess,
    CrmTradeOpportunityPurchaseActionType.SendInternalEventSuccess,
    CrmTradeOpportunityPurchaseActionType.UpdateOpportunitySuccess,
  ),
  switchMap(() => [
    ToastAction.displayToast({
      autoClose: 3000,
      id: 'toast.actionSuccess',
      title: 'toast.actionSuccess',
      type: ToastType.Success,
    }),
  ]),
)

export const opportunityPurchaseEpic = combineEpics(
  alertOnFailureActionEpic,
  assignOpportunityEpic,
  changeFilterOrSearchPhraseEpic,
  getMatchingPurchaseOpportunitiesEpic,
  getMatchedSaleOpportunitiesEpic,
  getOpportunitiesEpic,
  getOpportunityEpic,
  matchSaleOpportunitiesEpic,
  informOnSuccessActionEpic,
  prepareDetailedFiltersEpic,
  redirectToListOnUpdateSuccessEpic,
  sendOpportunityInternalCommentEpic,
  sendOpportunityInternalEventEpic,
  sendOpportunityInternalEventWithAttachmentsEpic,
  unassignOpportunityEpic,
  updateOpportunityEpic,
  uploadAttachmentsSuccessEpic,
// TODO refactor to smaller if needed
// tslint:disable-next-line:max-file-line-count
)
