import { OpportunityAccomplishmentDetails as OpportunityAccomplishmentDetailsType } from 'actff-bo-lib/crm'
import { OpportunityService, OpportunityStatus } from 'actff-bo-lib/crm/service'
import { getLocale } from 'actff-bo-lib/date'
import { Language } from 'actff-bo-lib/i18n'
import * as React from 'react'

import { OpportunityAccomplishmentDetails } from './OpportunityAccomplishmentDetails'
import { OpportunityFailureReason } from './OpportunityFailureReason'
import { OpportunityRetry } from './OpportunityRetry'
import { OpportunityVisitTime } from './OpportunityVisitTime'

type Props = {
  readonly currentLanguage: Language,
  readonly handleAccomplishmentDetailsChange: (values: OpportunityAccomplishmentDetailsType) => void,
  readonly handleDateChange: (dateField: keyof Pick<OpportunityService, 'retryDate' | 'appointmentDate'>) => (date: Date | null) => void,
  readonly handleValidateChange: (hasErrors: boolean) => void,
  readonly opportunity: OpportunityService,
  readonly updateOpportunity: (opportunity: Partial<OpportunityService>) => void,
}

export const OpportunityAdditionalInformation: React.FC<Props> = ({
  currentLanguage,
  handleAccomplishmentDetailsChange,
  handleDateChange,
  handleValidateChange,
  opportunity,
  updateOpportunity,
}) => {
  // tslint:disable-next-line:cyclomatic-complexity
  const locale = getLocale(currentLanguage)

  switch (opportunity.status) {
    case OpportunityStatus.ACCOMPLISHED:
      const { invoiceNumber, orderNumber, partsProfit, labourProfit } = opportunity
      const accomplishmentDetails = { invoiceNumber, orderNumber, partsProfit, labourProfit }

      return (
        <>
          <OpportunityVisitTime
            editable={true}
            locale={locale}
            onChange={handleDateChange('appointmentDate')}
            onValidateChange={handleValidateChange}
            visitTime={opportunity.appointmentDate}
          />
          <OpportunityAccomplishmentDetails
            accomplishmentDetails={accomplishmentDetails}
            onChange={handleAccomplishmentDetailsChange}
            onValidateChange={handleValidateChange}
          />
        </>
      )

    case OpportunityStatus.RETRY:
    case OpportunityStatus.RETRY_LIST:
      return (
        <OpportunityRetry
          locale={locale}
          onChange={handleDateChange('retryDate')}
          onValidateChange={handleValidateChange}
          retryTime={opportunity.retryDate}
        />
      )

    case OpportunityStatus.FAILED:
      return (
        <>
          <OpportunityVisitTime
            editable={false}
            locale={locale}
            onChange={handleDateChange('appointmentDate')}
            visitTime={opportunity.appointmentDate}
          />
          <OpportunityFailureReason
            failureReason={opportunity.failureReason}
            onChange={updateOpportunity}
          />
        </>
      )

    case OpportunityStatus.EXPIRED:
    case OpportunityStatus.ARRANGED:
      return (
        <OpportunityVisitTime
          editable={true}
          locale={locale}
          onValidateChange={handleValidateChange}
          onChange={handleDateChange('appointmentDate')}
          visitTime={opportunity.appointmentDate}
        />
      )

    case OpportunityStatus.AFTER_APPOINTMENT_LIST:
    case OpportunityStatus.MISSED:
      return (
        <OpportunityVisitTime
          editable={false}
          locale={locale}
          onChange={handleDateChange('appointmentDate')}
          visitTime={opportunity.appointmentDate}
        />
      )
    default:
      return null
  }
}
