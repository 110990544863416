import { ImageCarousel } from 'actff-bo-app/components/ImageCarousel'
import * as React from 'react'

import { ImageViewer } from '../Styled'

type CarDocumentsViewer = {
  readonly carAttachments: ReadonlyArray<string> | null,
}

export const CarDocumentsViewer: React.FC<CarDocumentsViewer> = ({ carAttachments }) => (
  <ImageViewer>
    <ImageCarousel images={carAttachments || []}/>
  </ImageViewer>
)
