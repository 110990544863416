import { PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { Loadable } from 'actff-bo-lib/global'
import { UserAction, UserActionType, UserId } from 'actff-bo-lib/user'
import { addDays, endOfToday } from 'date-fns'

import { CrmInsuranceListAction, CrmInsuranceListActionType } from '../actions/list'
import { OpportunityInsuranceId, OpportunityListItem, OpportunityListType } from '../dto'
import { InsuranceFilters } from '../filters'
import { defaultInsuranceListFilters } from './list-filters'

export type CrmInsuranceListState = {
  readonly assignmentFilter: UserId | null,
  readonly filters: InsuranceFilters,
  readonly searchPhrase: string,
  readonly dealerLocationFilters: ReadonlyArray<DealerLocationKey>,
  readonly dealerBrandFilters: ReadonlyArray<string>,
  readonly selectedAsProcessedUuid: OpportunityInsuranceId | null,
} & {
  readonly [key in keyof typeof OpportunityListType]: {
    readonly from?: Date,
    readonly to?: Date,
    readonly currentPage: number,
    readonly expanded: boolean,
    readonly list: Loadable<PagedResult<OpportunityListItem>>,
  }
}

// tslint:disable object-literal-sort-keys no-magic-numbers
export const initialListState = {
  assignmentFilter: null,
  dealerBrandFilters: [],
  filters: defaultInsuranceListFilters,
  dealerLocationFilters: [],
  overdue: {
    currentPage: 1,
    expanded: false,
    list: {
      loading: false,
      data: null,
    },
  },
  new: {
    currentPage: 1,
    expanded: true,
    list: {
      loading: false,
      data: null,
    },
  },
  future: {
    currentPage: 1,
    expanded: false,
    to: addDays(endOfToday(), 4),
    list: {
      loading: false,
      data: null,
    },
  },
  all: {
    currentPage: 1,
    expanded: false,
    list: {
      loading: false,
      data: null,
    },
  },
  selectedAsProcessedUuid: null,
  searchPhrase: '',
}

// tslint:disable-next-line
export const crmInsuranceListReducer = (
  state: CrmInsuranceListState = initialListState,
  action: CrmInsuranceListAction | UserAction,
): CrmInsuranceListState => {
  switch (action.type) {
    case CrmInsuranceListActionType.ChangeAssignmentFilter:
      return {
        ...state,
        assignmentFilter: action.payload,
      }

    case CrmInsuranceListActionType.ChangeOpportunityListCurrentPage:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          currentPage: action.payload.page,
          list: {
            loading: true,
          },
        },
      })

    case CrmInsuranceListActionType.ChangeOpportunityListExpandedState:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          expanded: action.payload.expanded,
        },
      })

    case CrmInsuranceListActionType.ChangeOpportunityListTimeFrom:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          from: action.payload.date,
          list: {
            loading: true,
          },
        },
      })

    case CrmInsuranceListActionType.ChangeOpportunityListTimeTo:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            loading: true,
          },
          to: action.payload.date,
        },
      })

    case CrmInsuranceListActionType.ChangeListsFilters:
      return {
        ...state,
        filters: action.payload,
      }

    case CrmInsuranceListActionType.ClearSelectedAsProcessedUuid:
      return {
        ...state,
        selectedAsProcessedUuid: null,
      }

    case CrmInsuranceListActionType.SetSelectedAsProcessedUuid:
      return {
        ...state,
        selectedAsProcessedUuid: action.payload,
      }

    case CrmInsuranceListActionType.ClearListsFilters:
      return {
        ...state,
        filters: {
          ...initialListState.filters,
        },
      }

    case CrmInsuranceListActionType.ChangeListsDealerLocationFilters:
      return {
        ...state,
        dealerLocationFilters: action.payload,
      }

    case CrmInsuranceListActionType.ChangeListsBrandFilters:
      return {
        ...state,
        dealerBrandFilters: action.payload,
      }

    case CrmInsuranceListActionType.ChangeSearchPhrase:
      return action.payload
        ? ({
          ...state,
          searchPhrase: action.payload,
        })
        : ({
          ...initialListState,
          new: state.new,
          future: state.future,
          overdue: state.overdue,
          all: state.all,
          filters: state.filters,
        })

    case CrmInsuranceListActionType.GetOpportunities:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            loading: true,
          },
        },
      })

    case CrmInsuranceListActionType.GetOpportunitiesFailure:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            loading: false,
          },
        },
      })

    case CrmInsuranceListActionType.GetOpportunitiesSuccess:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            data: action.payload.opportunityList,
            loading: false,
          },
        },
      })

    case CrmInsuranceListActionType.ResetPaginationOnLists:
      return ({
        ...state,
        all: {
          ...state.all,
          currentPage: initialListState.all.currentPage,
        },
        future: {
          ...state.future,
          currentPage: initialListState.future.currentPage,
        },
        new: {
          ...state.new,
          currentPage: initialListState.new.currentPage,
        },
        overdue: {
          ...state.overdue,
          currentPage: initialListState.overdue.currentPage,
        },
      })

    case CrmInsuranceListActionType.AssignOpportunitySuccess:
    case CrmInsuranceListActionType.UnassignOpportunitySuccess:
        const opportunityListToUpdate = state[action.payload.opportunityListType]
        const opportunityResultsToUpdate = opportunityListToUpdate.list.data?.result || []
        const indexOfUpdatedOpportunity =
          opportunityResultsToUpdate?.findIndex(element => element.uuid === action.payload.opportunity.uuid)

        // tslint:disable-next-line: prefer-object-spread
        const updatedResult = Object.assign(
          [...opportunityResultsToUpdate],
          {
            [indexOfUpdatedOpportunity]: {
              ...opportunityResultsToUpdate[indexOfUpdatedOpportunity],
              assignee: action.payload.opportunity.assignee,
            },
          },
        )

        return {
          ...state,
          [action.payload.opportunityListType]: {
            ...opportunityListToUpdate,
            list: {
              data: {
                ...opportunityListToUpdate.list.data,
                result: updatedResult,
              },
              loading: false,
            },
          },
        }

    case UserActionType.GetMeSuccess:
      return {
        ...state,
        dealerLocationFilters: action.payload.locations.map(location => location.key),
      }

    default:
      return state
  }
}
