import { DealershipInsurer } from 'actff-bo-lib/admin/dealership'
import { OpportunityInsurerOther } from 'actff-bo-lib/crm/insurance'
import i18next from 'i18next'

export const insurerOtherOrValue = (t: i18next.TFunction, value: string) =>
  value === OpportunityInsurerOther ? t('caption.other') : value

export const insurerOptionValueMapper = (t: i18next.TFunction) => (value: string) =>
  ({ value, label: insurerOtherOrValue(t, value) })

export const insurersToSelectOptions = (insurers: ReadonlyArray<DealershipInsurer> | undefined) =>
  insurers?.map(insurer => ({ label: insurer.insurerName, value: insurer.insurerName })) ?? []
