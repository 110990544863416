import { Loader } from 'actff-bo-app/components/Loader'
import { selectToken, Token } from 'actff-bo-lib/auth'
import { Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { connect, MapStateToProps } from 'react-redux'

type StateToProps = {
  readonly token: Token | null,
}

class MainComponent extends React.Component<StateToProps> {
  public componentDidMount(): void {
    return this.props.token
      ? history.push(Paths.Dashboard)
      : history.push(Paths.Login)
  }

  public render(): React.ReactNode {
    return <Loader />
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  token: selectToken(state),
})

export const Main = connect(mapStateToProps)(MainComponent)
