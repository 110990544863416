import { BtnType } from 'actff-bo-app/components/Button'
import { FormCell, FormFooterRight, renderSelectController } from 'actff-bo-app/components/Form'
import { SearchCarField } from 'actff-bo-app/components/SearchCarField/SearchCarField'
import { CalendarResource, CalendarResourceType } from 'actff-bo-lib/admin'
import { createReplacementCar, updateReplacementCar } from 'actff-bo-lib/admin/employee-scheduler/dao'
import { SearchCarResult } from 'actff-bo-lib/car'
import { getCarModelsAction, selectCarModels } from 'actff-bo-lib/dealership'
import { mapValuesToSelectOptions, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React, { FC, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QueryObserverResult } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { LinkButton, PrimaryButton } from 'styles'
import { AddResourceModalFooter, FormContent } from './Styled'

type Props = Testable & {
  readonly resourceType: CalendarResourceType,
  readonly onClose: () => void,
  readonly onRefetch: () => Promise<QueryObserverResult>,
  readonly editMode: boolean,
  readonly resource?: CalendarResource,
}

export const ReplacementCarForm: FC<Props> = ({ resourceType, onClose, testId, onRefetch, resource, editMode }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getCarModels = useMemo(() => () => { dispatch(getCarModelsAction()) }, [dispatch])

  useEffect(() => {
    getCarModels()
  }, [getCarModels])

  const { data: carModels } = useSelector(selectCarModels)

  const { handleSubmit, register, control, reset, setValue } = useForm<CalendarResource>({
    defaultValues: resource || {},
    mode: 'onChange',
  })

  const onSubmit = async (form: CalendarResource) => {
    try {
      if (editMode) {
        await updateReplacementCar(form, resourceType, resource?.uuid)
      } else {
        await createReplacementCar(form, resourceType)
      }
      onClose()
      dispatch(displaySuccessToast('admin.employeeSchedule.saveResource.success', ToastActionType.CREATE_OR_UPDATE))
      await onRefetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.employeeSchedule.saveResource.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  const setFormValues = (car: SearchCarResult) => {
    setValue('car', car)
    setValue('registrationNumber', car.registrationNumber)
    setValue('vin', car.vin)
    setValue('model', car.model)
  }

  const handleValueChange =
    (field: string, value: SelectOption<string>, action: string, carSearchResults?: ReadonlyArray<SearchCarResult>) => {

    if (value) {
      const selectedCar = carSearchResults?.find(car => car[field] === value.value)

      if (action === 'select-option' && selectedCar) {
        setFormValues(selectedCar)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>{t('admin.employeeSchedule.resourceModal.enterReplacementCarData')}</p>
      <FormContent>

        <FormCell noPaddingLeft={true}>
          <label>{t('crmInsurance.car.vin')}:</label>
          <SearchCarField
            control={control}
            name='vin'
            register={register}
            reset={reset}
            setValue={setValue}
            onValueChange={handleValueChange}
          />
        </FormCell>

        <FormCell noPaddingLeft={true}>
          <label>{t('crmInsurance.car.registrationNumber')}:</label>
          <SearchCarField
            control={control}
            name='registrationNumber'
            register={register}
            reset={reset}
            setValue={setValue}
            onValueChange={handleValueChange}
          />
        </FormCell>

        <FormCell noPaddingLeft={true}>
          <label>{t('crmInsurance.car.model')}:</label>
          <Controller
            render={renderSelectController({
              creatable: true,
              options: [...mapValuesToSelectOptions(carModels)],
              t,
              testId: `${testId}-carModel`,
            })}
            control={control}
            name='model'
          />
        </FormCell>

      </FormContent>

      <AddResourceModalFooter>
        <FormFooterRight>
          <LinkButton data-testid={`${testId}-button--cancel`} type={BtnType.Button} onClick={onClose}>
            {t('caption.cancel')}
          </LinkButton>
          <PrimaryButton data-testid={`${testId}-button--confirm`} type={BtnType.Submit} onClick={handleSubmit}>
            {t('caption.save')}
          </PrimaryButton>
        </FormFooterRight>
      </AddResourceModalFooter>
    </form>
  )
}
