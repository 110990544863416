import { DealerLocation, DealerLocationKey } from 'actff-bo-lib/dealership'
import { NominalString } from 'actff-bo-lib/global'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'

import { OpportunityCar } from './car'
import { ContactHistory } from './contactHistory'
import { InsurancePolicy, NewPolicy, OpportunityInsuranceSellTypes, OpportunityInsuranceSource } from './insurance'
import { OpportunityOffer, OpportunityOfferStatus } from './offer'
import { OpportunityCarDriver, OpportunityCarOwner, OpportunityContactPerson } from './owners'
import { OpportunityPayments, OpportunityPaymentsConfirmation } from './payments'
import { OpportunityFailureReason, OpportunityResult, OpportunitySourceName, OpportunityStatus } from './process'

export type OpportunityInsuranceId = NominalString<OpportunityInsurance>

export type OpportunityListItem = {
  readonly assignee: UserWithoutPermissions | null,
  readonly actionDate: Date,
  readonly start: Date,
  readonly driverName: string,
  readonly driverPhone?: string,
  readonly status: OpportunityStatus,
  readonly boughtAt: OpportunityInsuranceSource,
  readonly inApp: boolean,
  readonly dealerLocation: DealerLocation | null,
  readonly decisionDue: Date,
  readonly expires: Date,
  readonly insurer: string,
  readonly offerDue: Date,
  readonly offerRequestFromApp: boolean,
  readonly productionYear: string,
  readonly registrationNumber: string,
  readonly model: string,
  readonly contactPhone?: string,
  readonly phone?: string,
  readonly mobile: string,
  readonly result: OpportunityResult,
  readonly failureReason: OpportunityFailureReason,
  readonly retry: Date,
  readonly sellType: OpportunityInsuranceSellTypes,
  readonly sourceName: OpportunitySourceName,
  readonly uuid: OpportunityInsuranceId,
  readonly locationKey: DealerLocationKey,
}

export type OpportunityUpdatePayload = {
  readonly assignee: UserWithoutPermissions | null,
  readonly created: Date,
  readonly driverName: string,
  readonly expires: Date,
  readonly inApp: boolean,
  readonly notificationExpires: Date,
  readonly registrationNumber: string,
  readonly retry: Date,
  readonly sourceName: OpportunitySourceName,
  readonly start: Date,
  readonly status: OpportunityStatus,
  readonly uuid: OpportunityInsuranceId,
  readonly vin: string,
}

export type OpportunityInsurance = {
  readonly car: OpportunityCar,
  readonly contactHistory: ContactHistory,
  readonly contactPerson: OpportunityContactPerson,
  readonly driver: OpportunityCarDriver,
  readonly offer: OpportunityOffer,
  readonly oldPolicy: InsurancePolicy,
  readonly newPolicy: NewPolicy,
  readonly owner: OpportunityCarOwner,
  readonly process: OpportunityProcess,
  readonly uuid: OpportunityInsuranceId,
  readonly payments: OpportunityPayments,
  readonly confirmation: OpportunityPaymentsConfirmation,
}

export type OpportunityProcess = {
  readonly uuid: OpportunityInsuranceId,
  readonly author: UserWithoutPermissions | null,
  readonly assignee: UserWithoutPermissions | null,
  readonly actionDate: Date,
  readonly start: Date,
  readonly retry: Date,
  readonly pushSent: Date,
  readonly inApp: boolean,
  readonly expires: Date,
  readonly status: OpportunityStatus,
  readonly sourceName: OpportunitySourceName,
  readonly offerStatus: OpportunityOfferStatus,
  readonly locationKey: string,
}

export type OpportunityProcessCreationData = {
  readonly author: UserWithoutPermissions | null,
  readonly assignee: UserWithoutPermissions | null,
  readonly locationKey: string,
}

export enum OpportunityListType {
  future = 'future',
  new = 'new',
  overdue = 'overdue',
  all = 'all',
}

export type FormSection = keyof OpportunityInsurance

export enum OpportunityViewerPayloadType {
  ECHO = 'ECHO',
  VIEWERS = 'VIEWERS',
}

export type OpportunityViewerMessage = {
  readonly type: OpportunityViewerPayloadType,
  readonly viewers?: ReadonlyArray<OpportunityViewer>,
}

export type OpportunityViewer = {
  readonly userUuid: UserId,
  readonly start: string,
}

export const opportunityUnassigned: UserId = 'UNASSIGNED' as UserId
