import { FormCell as FormCellStyled } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { Header as HeaderStyled } from 'styles'
import { ButtonWithIcon as ButtonWithIconComponent } from '../components/Button'

type ContainerProps = {
  readonly noPadding?: boolean,
}

export const MainHeader = styled(HeaderStyled)`
  height: 120px;
`

export const ButtonWithIcon = styled(ButtonWithIconComponent)`
  padding: 24px;
`

export const AdminContainer = styled.div`
  position: relative;
`

export const Form = styled.form`
  padding: 24px;
`

export const FormCell = styled(FormCellStyled)`
  padding: 12.5px 0;
`

export const AddingDialogContent = styled.div`
  display: grid;
  padding: 24px 0;
`

export const Container = styled.div`
  ${({ noPadding }: ContainerProps) => !noPadding && 'padding: 24px 24px 80px 24px;'}
`

export const Header = styled.div`
  line-height: 27px;
  padding: 0 0 24px;
  text-transform: uppercase;

  h2 {
    font-size: 18px;
  }
`

export const Labels = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 150px) 1fr;
  padding-bottom: 10px;
`

export const Label = styled.label`
  padding: 10px 0;
`

export const Th = styled.th`
  font-weight: bold;

  &:first-child {
    width: 50px;
  }
`

export const Td = styled.td`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  height: 49px;
`

export const TdWithDelete = styled(Td)`
  justify-content: space-between;
`

export const Tr = styled.tr`
  cursor: pointer;
`

export const ViewTabsHeader = styled(MainHeader)`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr;
  grid-gap: 12px;
  padding-bottom: 110px;
`
