import { Avatar } from 'actff-bo-app/components/Client'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Icon } from 'actff-bo-app/components/Icon'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { assigneeStatusAvatarStyleMap, getAssigneeStatus } from 'actff-bo-lib/client/dto'
import {
  getMatchedTradeOpportunitiesWithAxios,
  OpportunityPurchase,
  OpportunityPurchaseId,
  TradeOpportunityType,
} from 'actff-bo-lib/crm/trade'
import { noCentsDivider } from 'actff-bo-lib/currency'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { getTranslation } from 'actff-bo-lib/i18n'
import { IconType } from 'actff-bo-lib/menu'
import { createRoute } from 'actff-bo-lib/menu/helpers'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { UserWithoutPermissions } from 'actff-bo-lib/user'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Td, Tr } from 'styles'

type Props = {
  readonly onRemoveClick: (opportunityId: OpportunityPurchaseId) => void,
  readonly opportunity: OpportunityPurchase,
  readonly withStatusIn?: boolean,
  readonly currentUser?: UserWithoutPermissions,
  readonly unmatchable: boolean,
}

export const OpportunityRow: FC<Props> = ({ onRemoveClick, opportunity, withStatusIn, currentUser, unmatchable }) => {
  const { t } = useTranslation()
  const { assignee, car, insurance, status, actionDate, uuid } = opportunity
  const href = createRoute(Paths.CrmTradePurchaseOpportunity, { opportunityId: uuid })
  const ClickableTd = TdDecorator(href)

  const { data: matchedSaleOpportunities } = useQuery(QueryKeys.MATCHED_SALE_OPPORTUNITIES, async () =>
      await getMatchedTradeOpportunitiesWithAxios(uuid, TradeOpportunityType.Purchase),
    { retry: false },
  )

  const getMarketValue = () => insurance?.marketValue
    ? `${insurance?.marketValue / noCentsDivider} ${t('currency.pln')}` : `- ${t('currency.pln')}`

  const assigneeStatus = getAssigneeStatus({ assignee, currentUser })
  const avatarStyle = assigneeStatusAvatarStyleMap.get(assigneeStatus)

  return (
    <>
      <Tr clickable={true} isStyled={true}>
        <ClickableTd>{getTranslation(t, 'crmTrade.status.', status)}</ClickableTd>
        {withStatusIn && <ClickableTd>status in opportunity</ClickableTd>}
        <ClickableTd><FormattedDate date={actionDate} format={defaultDateFormat} /></ClickableTd>
        <ClickableTd>{car?.vin}</ClickableTd>
        <ClickableTd>{car?.model}</ClickableTd>
        <ClickableTd>{car?.productionYear}</ClickableTd>
        <ClickableTd>{getMarketValue()}</ClickableTd>
        <ClickableTd>{matchedSaleOpportunities?.length}</ClickableTd>
        {currentUser && (
          <Td>
            <Avatar
              style={avatarStyle}
              firstName={assignee?.firstName}
              lastName={assignee?.lastName}
            />
          </Td>
        )}
        {unmatchable && <Td><Icon type={IconType.Trash} onClick={() => onRemoveClick(opportunity.uuid)}/></Td>}
      </Tr>
    </>
  )
}
