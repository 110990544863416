import { OpportunitySaleFilterValues, OpportunitySaleStatusType } from 'actff-bo-lib/crm/trade/dto'
import { Filters } from 'actff-bo-lib/global'
import { UserPermissions } from 'actff-bo-lib/user'

export const initialStatusFilters: Filters<OpportunitySaleFilterValues> = {
  statuses: [
    {
      caption: 'filters.opportunity.waitForFirstContact',
      selected: false,
      value: OpportunitySaleStatusType.WAIT_FOR_FIRST_CONTACT,
    },
    {
      caption: 'filters.opportunity.firstContact',
      selected: false,
      value: OpportunitySaleStatusType.FIRST_CONTACT,
    },
    {
      caption: 'filters.opportunity.retry',
      selected: false,
      value: OpportunitySaleStatusType.RETRY_CONTACT,
    },
    {
      caption: 'filters.opportunity.postponedContact',
      selected: false,
      value: OpportunitySaleStatusType.POSTPONED_CONTACT,
    },
    {
      caption: 'filters.opportunity.success',
      requiredPermissions: [UserPermissions.AdminAllService],
      selected: false,
      value: OpportunitySaleStatusType.SUCCESS,
    },
    {
      caption: 'filters.opportunity.failure',
      requiredPermissions: [UserPermissions.AdminAllService],
      selected: false,
      value: OpportunitySaleStatusType.FAILURE,
    },
  ],
}
