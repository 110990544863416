import { FormCell } from 'actff-bo-app/components/Form'
import { connector } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { placeholderService, selectSelectableServices, Service, ServiceAction, ServiceID } from 'actff-bo-lib/service'
import { State as GlobalState } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import { RowSaveEditButton } from '../ServiceRequestViewStyled'
import { AddMoreButton } from './AddMoreButton'
import { ServiceRequestService } from './ServiceRequestService'

type Props = {
  readonly services: ReadonlyArray<Service>,
  readonly onServicesChange: (services: ReadonlyArray<Service>) => void,
}

type State = {
  readonly editMode: boolean,
}

type StateToProps = {
  readonly availableServices: ReadonlyArray<Service>,
}

type DispatchToProps = {
  readonly getServices: () => void,
}

type ServiceRequestServicesComponentProps = Testable & Props & StateToProps & DispatchToProps & WithTranslation

const ServiceListContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
`

class ServiceRequestServiceComponent extends React.Component<ServiceRequestServicesComponentProps, State> {
  public readonly state: State = {
    editMode: false,
  }

  public componentDidMount(): void {
    this.props.getServices()
  }

  public render(): React.ReactNode {
    const { availableServices, services, t, testId } = this.props
    const { editMode } = this.state

    return (
      <FormCell>
        <label>{t('serviceRequest.services.label')}:</label>
        <ServiceListContainer>
          {services.map((service, index) =>
            <ServiceRequestService
              availableServices={availableServices}
              editMode={editMode}
              key={service.uuid}
              onServiceChange={this.handleServiceChange}
              service={service}
              testId={`${testId}service-${index}`}
            />,
          )}
        </ServiceListContainer>
        {editMode && <AddMoreButton onClick={this.handleAddService} testId={`${testId}service-button--add`} />}
        <RowSaveEditButton
          className='car-leave-save-edit'
          editMode={editMode}
          onClick={this.handleSaveEditButtonClick}
          t={t}
          testId={`${testId}service-button`}
        />
      </FormCell>
    )
  }

  private readonly handleAddService = () => {
    const { onServicesChange, services } = this.props

    if (services.find(service => service.key === placeholderService.key)) {
      return
    }

    onServicesChange([
      ...services,
      placeholderService,
    ])
  }

  private readonly handleServiceChange = (oldUuid: ServiceID, uuid: ServiceID) => {
    const { availableServices, onServicesChange, services } = this.props
    const serviceToChange = availableServices.find(service => service.uuid === uuid) || availableServices[0]

    onServicesChange([
      ...services.filter(service => service.uuid !== oldUuid && service.uuid !== uuid),
      serviceToChange,
    ])
  }

  private readonly filterPlaceholderServices = () => {
    const { onServicesChange, services } = this.props

    onServicesChange([
      ...services.filter(service => service.uuid !== placeholderService.uuid),
    ])
  }

  private readonly toggleEditMode = () => { this.setState({ editMode: !this.state.editMode })}

  private readonly handleSaveEditButtonClick = () => {
    const { editMode } = this.state

    if (editMode) {
      this.filterPlaceholderServices()
    }

    this.toggleEditMode()
  }

}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  availableServices: selectSelectableServices(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getServices: () => { dispatch(ServiceAction.getServices()) },
})

export const ServiceRequestServices = compose(
  connector(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(ServiceRequestServiceComponent)
