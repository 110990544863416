import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { redirectToList } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { TestDriveRequestAction, TestDriveRequestActionType } from './actions'
import {
  getNewTestDriveRequestCount,
  getTestDriveRequest,
  getTestDriveRequests,
  updateTestDriveRequest,
} from './dao'
import { selectTestDriveRequestCurrentPage, selectTestDriveRequestListFilters } from './selectors'

const changeListParamsEpic: Epic<TestDriveRequestAction, TestDriveRequestAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof TestDriveRequestAction.changePage>>(TestDriveRequestActionType.ChangePage),
  withLatestFrom(state$),
  tap(([, store]) => {
    redirectToList(Paths.TestDriveRequests, selectTestDriveRequestCurrentPage(store))
  }),
  map(TestDriveRequestAction.getTestDriveRequests),
)

const changeFilterEpic: Epic<TestDriveRequestAction> = action$ => action$.pipe(
  ofType(TestDriveRequestActionType.ChangeListFilter),
  map(() => TestDriveRequestAction.changePage(1)),
)

const getTestDriveRequestsEpic: Epic<TestDriveRequestAction, TestDriveRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(TestDriveRequestActionType.GetTestDriveRequests),
  withLatestFrom(state$),
  switchMap(([, state]) =>
    getTestDriveRequests(selectTestDriveRequestListFilters(state), selectTestDriveRequestCurrentPage(state) - 1).pipe(
      map(TestDriveRequestAction.getTestDriveRequestsSuccess),
      catchError((error: AjaxError) => of(TestDriveRequestAction.getTestDriveRequestsFailure(error))),
    ),
))

const getTestDriveRequestEpic: Epic<TestDriveRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof TestDriveRequestAction.getTestDriveRequest>>(TestDriveRequestActionType.GetTestDriveRequest),
  switchMap(({ payload }) => getTestDriveRequest(payload).pipe(
    map(TestDriveRequestAction.getTestDriveRequestSuccess),
    catchError((error: AjaxError) => of(TestDriveRequestAction.getTestDriveRequestFailure(error)),
  )),
))

const getNewTestDriveRequestCountEpic: Epic<TestDriveRequestAction> = action$ => action$.pipe(
  ofType(TestDriveRequestActionType.GetNewTestDriveRequestCount),
  switchMap(() => getNewTestDriveRequestCount().pipe(
    map(TestDriveRequestAction.getNewTestDriveRequestCountSuccess),
    catchError((error: AjaxError) => of(TestDriveRequestAction.getNewTestDriveRequestCountFailure(error))),
  )),
)

const updateTestDriveRequestEpic: Epic<TestDriveRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof TestDriveRequestAction.updateTestDriveRequest>>(TestDriveRequestActionType.UpdateTestDriveRequest),
  switchMap(({ payload }) => updateTestDriveRequest(payload).pipe(
    map(TestDriveRequestAction.updateTestDriveRequestSuccess),
    catchError((error: AjaxError) => of(TestDriveRequestAction.updateTestDriveRequestFailure(error))),
  )),
)

const updateTestDriveRequestSuccessEpic: Epic<TestDriveRequestAction> = action$ => action$.pipe(
  ofType(TestDriveRequestActionType.UpdateTestDriveRequestSuccess),
  tap(() => {
    history.push(Paths.TestDriveRequests)
  }),
  ignoreElements(),
)

export const testDriveRequestEpic = combineEpics(
  changeListParamsEpic,
  changeFilterEpic,
  getTestDriveRequestsEpic,
  getTestDriveRequestEpic,
  getNewTestDriveRequestCountEpic,
  updateTestDriveRequestEpic,
  updateTestDriveRequestSuccessEpic,
)
