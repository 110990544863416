import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { DateRange } from 'actff-bo-lib/date'
import { AjaxError } from 'rxjs/ajax'

import { DashboardData, DashboardDataGroupByType, DashboardDataUrlType, DashboardSummaryData } from './dto'

export enum DashboardActionType {
  ChangeDashboardDetailedDataDateRange = '[DASHBOARD] CHANGE DASHBOARD DETAILED DATA DATE RANGE',
  ChangeDashboardDetailedDataGroupBy = '[DASHBOARD] CHANGE DASHBOARD DETAILED DATA GROUP BY',
  ChangeDashboardDetailedDataType = '[DASHBOARD] CHANGE DASHBOARD DETAILED DATA TYPE',

  GetDashboardSummaryData = '[DASHBOARD] GET DASHBOARD SUMMARY DATA',
  GetDashboardSummaryDataFailure = '[DASHBOARD] GET DASHBOARD SUMMARY DATA FAILURE',
  GetDashboardSummaryDataSuccess = '[DASHBOARD] GET DASHBOARD SUMMARY DATA SUCCESS',

  GetDashboardDetailedData = '[DASHBOARD] GET DASHBOARD DETAILED DATA',
  GetDashboardDetailedDataFailure = '[DASHBOARD] GET DASHBOARD DETAILED DATA FAILURE',
  GetDashboardDetailedDataSuccess = '[DASHBOARD] GET DASHBOARD DETAILED DATA SUCCESS',
}

export const DashboardAction = {
  changeDashboardDetailedDataDateRange: (range: DateRange | null) =>
    createAction(DashboardActionType.ChangeDashboardDetailedDataDateRange, range),
  changeDashboardDetailedDataGroupBy: (type: DashboardDataGroupByType) =>
      createAction(DashboardActionType.ChangeDashboardDetailedDataGroupBy, type),
  changeDashboardDetailedDataType: (type: DashboardDataUrlType) => createAction(DashboardActionType.ChangeDashboardDetailedDataType, type),

  getDashboardSummaryData: () => createAction(DashboardActionType.GetDashboardSummaryData),
  getDashboardSummaryDataFailure: (error: AjaxError) => createAction(DashboardActionType.GetDashboardSummaryDataFailure, error),
  getDashboardSummaryDataSuccess: (summary: DashboardSummaryData) =>
    createAction(DashboardActionType.GetDashboardSummaryDataSuccess, summary),

  getDashboardDetailedData: () => createAction(DashboardActionType.GetDashboardDetailedData),
  getDashboardDetailedDataFailure: (error: AjaxError) => createAction(DashboardActionType.GetDashboardDetailedDataFailure, error),
  getDashboardDetailedDataSuccess: (data: DashboardData) => createAction(DashboardActionType.GetDashboardDetailedDataSuccess, data),
}

export type DashboardAction = ActionsUnion<typeof DashboardAction>
