import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { AppPersonId, CarInfo } from 'actff-bo-lib/car'
import { OpportunityPurchase, OpportunitySale } from 'actff-bo-lib/crm/trade'
import { AjaxError } from 'rxjs/ajax'

import { Client, ClientDto, ClientFilters, ClientId, ClientPreferencesDto, NewClientDto } from '../dto'
import { ClientCount, ClientPersonalData } from '../dto/client'

export enum ClientActionType {
  AddClient = '[CLIENT] ADD CLIENT',
  AddClientFailure = '[CLIENT] ADD CLIENT FAILURE',
  AddClientSuccess = '[CLIENT] ADD CLIENT SUCCESS',

  ArchiveClient = '[CLIENT] ARCHIVE CLIENT',
  ArchiveClientFailure = '[CLIENT] ARCHIVE CLIENT FAILURE',
  ArchiveClientSuccess = '[CLIENT] ARCHIVE CLIENT SUCCESS',

  ChangePage = '[CLIENT] CHANGE PAGE',
  ChangeSearchPhrase = '[CLIENT] CHANGE SEARCH PHRASE',
  ChangePurchaseOpportunitiesSearchSearchPhrase = '[CLIENT] CHANGE PURCHASE OPPORTUNITIES SEARCH SEARCH PHRASE',

  ChangeSearchDialogPage = '[CLIENT] CHANGE SEARCH DIALOG PAGE',
  ChangeSearchDialogSearchPhrase = '[CLIENT] CHANGE SEARCH DIALOG SEARCH PHRASE',

  ChangeFilters = '[CLIENT] CHANGE FILTERS',
  ClearClientCars = '[CLIENT] CLEAR CLIENT CARS',
  ClearClientTradeData = '[CLIENT] CLEAR CLIENT TRADE DATA',
  ClearFilters = '[CLIENT] CLEAR FILTERS',
  ClearList = '[CLIENT] CLEAR LIST',

  CreateClient = '[CLIENT] CREATE CLIENT',
  CreateClientFailure = '[CLIENT] CREATE CLIENT FAILURE',
  CreateClientSuccess = '[CLIENT] CREATE CLIENT SUCCESS',

  GetClient = '[CLIENT] GET CLIENT',
  GetClientFailure = '[CLIENT] GET CLIENT FAILURE',
  GetClientSuccess = '[CLIENT] GET CLIENT SUCCESS',

  GetClientCars = '[CLIENT] GET CLIENT CARS',
  GetClientCarsFailure = '[CLIENT] GET CLIENT CARS FAILURE',
  GetClientCarsSuccess = '[CLIENT] GET CLIENT CARS SUCCESS',

  GetClientMatchedPurchaseOpportunities = '[CLIENT] GET CLIENT MATCHED PURCHASE OPPORTUNITIES',
  GetClientMatchedPurchaseOpportunitiesFailure = '[CLIENT] GET CLIENT MATCHED PURCHASE OPPORTUNITIES FAILURE',
  GetClientMatchedPurchaseOpportunitiesSuccess = '[CLIENT] GET CLIENT MATCHED PURCHASE OPPORTUNITIES SUCCESS',

  GetClientMatchedSaleOpportunity = '[CLIENT] GET CLIENT MATCHED SALE OPPORTUNITY',
  GetClientMatchedSaleOpportunityFailure = '[CLIENT] GET CLIENT MATCHED SALE OPPORTUNITY FAILURE',
  GetClientMatchedSaleOpportunitySuccess = '[CLIENT] GET CLIENT MATCHED SALE OPPORTUNITY SUCCESS',

  GetClientPreferences = '[CLIENT] GET CLIENT PREFERENCES',
  GetClientPreferencesFailure = '[CLIENT] GET CLIENT PREFERENCES FAILURE',
  GetClientPreferencesSuccess = '[CLIENT] GET CLIENT PREFERENCES SUCCESS',

  ChangeClientCarsPage = '[CLIENT] CHANGE CLIENT CARS PAGE',

  GetClientPersonalDataHistory = '[CLIENT] GET CLIENT PERSONAL DATA HISTORY',
  GetClientPersonalDataHistoryFailure = '[CLIENT] GET CLIENT PERSONAL DATA HISTORY FAILURE',
  GetClientPersonalDataHistorySuccess = '[CLIENT] GET CLIENT PERSONAL DATA HISTORY SUCCESS',

  GetClientCount = '[CLIENT] GET CLIENT COUNT',
  GetClientCountFailure = '[CLIENT] GET CLIENT COUNT FAILURE',
  GetClientCountSuccess = '[CLIENT] GET CLIENT COUNT SUCCESS',

  GetClients = '[CLIENT] GET CLIENTS',
  GetClientsFailure = '[CLIENT] GET CLIENTS FAILURE',
  GetClientsSuccess = '[CLIENT] GET CLIENTS SUCCESS',

  RestoreClient = '[CLIENT] RESTORE CLIENT',
  RestoreClientFailure = '[CLIENT] RESTORE CLIENT FAILURE',
  RestoreClientSuccess = '[CLIENT] RESTORE CLIENT SUCCESS',

  SaveClientPreferences = '[CLIENT] SAVE CLIENT PREFERENCES',
  SaveClientPreferencesFailure = '[CLIENT] SAVE CLIENT PREFERENCES FAILURE',
  SaveClientPreferencesSuccess = '[CLIENT] SAVE CLIENT PREFERENCES SUCCESS',

  UpdateClient = '[CLIENT] UPDATE CLIENT',
  UpdateClientFailure = '[CLIENT] UPDATE CLIENT FAILURE',
  UpdateClientSuccess = '[CLIENT] UPDATE CLIENT SUCCESS',
}

export const ClientAction = {
  addClient: (client: NewClientDto) => createAction(ClientActionType.AddClient, client),
  addClientFailure: (error: AjaxError) => createAction(ClientActionType.AddClientFailure, error),
  addClientSuccess: (client: Client) => createAction(ClientActionType.AddClientSuccess, client),

  archiveClient: (id: ClientId) => createAction(ClientActionType.ArchiveClient, id),
  archiveClientFailure: (error: AjaxError) => createAction(ClientActionType.ArchiveClientFailure, error),
  archiveClientSuccess: () => createAction(ClientActionType.ArchiveClientSuccess),

  changeFilters: (filters: ClientFilters) => createAction(ClientActionType.ChangeFilters, filters),
  changePage: (page: number) => createAction(ClientActionType.ChangePage, page),
  changePurchaseOpportunitiesSearchSearchPhrase: (searchPhrase: string) =>
    createAction(ClientActionType.ChangePurchaseOpportunitiesSearchSearchPhrase, searchPhrase),
  changeSearchDialogPage: (page: number) => createAction(ClientActionType.ChangeSearchDialogPage, page),
  changeSearchDialogSearchPhrase: (searchPhrase: string) => createAction(ClientActionType.ChangeSearchDialogSearchPhrase, searchPhrase),
  changeSearchPhrase: (searchPhrase: string) => createAction(ClientActionType.ChangeSearchPhrase, searchPhrase),
  clearClientCars: () => createAction(ClientActionType.ClearClientCars),
  clearClientTradeData: () => createAction(ClientActionType.ClearClientTradeData),
  clearFilters: () => createAction(ClientActionType.ClearFilters),
  clearList: () => createAction(ClientActionType.ClearList),

  createClient: (client: Client) => createAction(ClientActionType.CreateClient, client),
  createClientFailure: (error: AjaxError) => createAction(ClientActionType.CreateClientFailure, error),
  createClientSuccess: (client: Client) => createAction(ClientActionType.CreateClientSuccess, client),

  getClient: (clientUuid: ClientId) => createAction(ClientActionType.GetClient, clientUuid),
  getClientFailure: (error: AjaxError) => createAction(ClientActionType.GetClientFailure, error),
  getClientSuccess: (data: Client) => createAction(ClientActionType.GetClientSuccess, data),

  getClientCars: (id: ClientId | AppPersonId) => createAction(ClientActionType.GetClientCars, id),
  getClientCarsFailure: (error: AjaxError) => createAction(ClientActionType.GetClientCarsFailure, error),
  getClientCarsSuccess: (cars: PagedResult<CarInfo>) => createAction(ClientActionType.GetClientCarsSuccess, cars),

  getClientMatchedPurchaseOpportunities: (clientUuid: ClientId) =>
    createAction(ClientActionType.GetClientMatchedPurchaseOpportunities, clientUuid),
  getClientMatchedPurchaseOpportunitiesFailure: (error: AjaxError) =>
    createAction(ClientActionType.GetClientMatchedPurchaseOpportunitiesFailure, error),
  getClientMatchedPurchaseOpportunitiesSuccess: (opportunities: ReadonlyArray<OpportunityPurchase>) =>
    createAction(ClientActionType.GetClientMatchedPurchaseOpportunitiesSuccess, opportunities),

  getClientMatchedSaleOpportunity: (clientUuid: ClientId) =>
    createAction(ClientActionType.GetClientMatchedSaleOpportunity, clientUuid),
  getClientMatchedSaleOpportunityFailure: (error: AjaxError) =>
    createAction(ClientActionType.GetClientMatchedSaleOpportunityFailure, error),
  getClientMatchedSaleOpportunitySuccess: (opportunity: OpportunitySale) =>
    createAction(ClientActionType.GetClientMatchedSaleOpportunitySuccess, opportunity),

  changeClientCarsPage: (page: number) => createAction(ClientActionType.ChangeClientCarsPage, page),

  getClientPersonalDataHistory: (clientUuid: ClientId) => createAction(ClientActionType.GetClientPersonalDataHistory, clientUuid),
  getClientPersonalDataHistoryFailure: (error: AjaxError) => createAction(ClientActionType.GetClientPersonalDataHistoryFailure, error),
  getClientPersonalDataHistorySuccess: (data: ClientPersonalData) =>
    createAction(ClientActionType.GetClientPersonalDataHistorySuccess, data),

  getClientPreferences: (clientUuid: ClientId) => createAction(ClientActionType.GetClientPreferences, clientUuid),
  getClientPreferencesFailure: (error: AjaxError) => createAction(ClientActionType.GetClientPreferencesFailure, error),
  getClientPreferencesSuccess: (preferences: ClientPreferencesDto) =>
    createAction(ClientActionType.GetClientPreferencesSuccess, preferences),

  getClientCount: () => createAction(ClientActionType.GetClientCount),
  getClientCountFailure: (error: AjaxError) => createAction(ClientActionType.GetClientCountFailure, error),
  getClientCountSuccess: (count: ClientCount) => createAction(ClientActionType.GetClientCountSuccess, count),

  getClients: () => createAction(ClientActionType.GetClients),
  getClientsFailure: (error: AjaxError) => createAction(ClientActionType.GetClientsFailure, error),
  getClientsSuccess: (data: PagedResult<Client>) => createAction(ClientActionType.GetClientsSuccess, data),

  restoreClient: (clientUuid: ClientId) => createAction(ClientActionType.RestoreClient, clientUuid),
  restoreClientFailure: (error: AjaxError) => createAction(ClientActionType.RestoreClientFailure, error),
  restoreClientSuccess: () => createAction(ClientActionType.RestoreClientSuccess),

  saveClientPreferences: (preferences: ClientPreferencesDto) => createAction(ClientActionType.SaveClientPreferences, preferences),
  saveClientPreferencesFailure: (error: AjaxError) => createAction(ClientActionType.SaveClientPreferencesFailure, error),
  saveClientPreferencesSuccess: (preferences: ClientPreferencesDto) =>
    createAction(ClientActionType.SaveClientPreferencesSuccess, preferences),

  updateClient: (data: ClientDto) => createAction(ClientActionType.UpdateClient, data),
  updateClientFailure: (error: AjaxError) => createAction(ClientActionType.UpdateClientFailure, error),
  updateClientSuccess: (data: Client) => createAction(ClientActionType.UpdateClientSuccess, data),
}

export type ClientAction = ActionsUnion<typeof ClientAction>
