import * as React from 'react'
import { useTranslation } from 'react-i18next'

type PersonalDataChangeStateProps = {
  readonly first: boolean,
}

export const PersonalDataChangeState: React.FC<PersonalDataChangeStateProps> = ({ first }) => {
  const { t } = useTranslation()

  return <>{first ? t('clientView.personalData.entered') : t('clientView.personalData.changedTo')}:</>
}
