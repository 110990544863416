import { ClientFullName, ClientType } from 'actff-bo-app/components/Client'
import { TimeAgo } from 'actff-bo-app/components/DateTime'
import { Message as MessageType, ThreadStatus } from 'actff-bo-lib/chat'
import * as React from 'react'

import { ChatThreadListItemStatus } from './ChatThreadListItemStatus'
import { ChatThreadListItemDate, ChatThreadListItemName, ChatThreadListItemStyled, Props } from './ChatThreadListStyled'

const maxMessageLength = 50

const Message: React.FC<Pick<MessageType, 'message'>> = ({ message }) => (
  <span>
    {message && message.length > maxMessageLength
      ? `${message.substr(0, maxMessageLength)}...`
      : message
    }
  </span>
)

const isThreadStatusSent = (status: ThreadStatus) => status === ThreadStatus.SENT

export const ChatThreadListItem: React.FC<Props> = ({ active, thread, thread: { status } }) => (
  <ChatThreadListItemStyled active={active} unread={isThreadStatusSent(status)}>
    <ChatThreadListItemName unread={isThreadStatusSent(status)}>
      <ClientType client={thread.client} />
      <ClientFullName client={thread.client} />
    </ChatThreadListItemName>
    <ChatThreadListItemDate>
      <TimeAgo date={thread.lastMessageTime} />
    </ChatThreadListItemDate>
    <Message message={thread.lastMessage} />
    {isThreadStatusSent(status) && <ChatThreadListItemStatus date={thread.lastMessageTime} />}
  </ChatThreadListItemStyled>
)
