import { InspectionCheckboxElement } from 'actff-bo-lib/car'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, Opacity, withOpacity } from 'styles'

type Props = {
  readonly elements: ReadonlyArray<InspectionCheckboxElement>,
  readonly register: () => void,
}

const Container = styled.table`
  border-spacing: 0;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid ${colors.solitude};
    :last-child {
      border-bottom: none;
    }
  }

  td {
    border-right: 1px solid ${colors.solitude};
    padding: 24px 16px;
    :first-child {
      background: ${withOpacity(colors.solitude, Opacity['15%'])};
    }
    :last-child {
      border-right: none;
    }
  }
`

const testId = 'car-view-inspection-form__computer-diagnostic-'

export const ComputerDiagnostic: FC<Props> = ({ elements, register }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <tbody>
        {elements.map((element, index) => (
          <tr key={element.uuid}>
            <td>
              <input
                data-testid={`${testId}${index}`}
                id={element.uuid}
                name={`workshopControl.computerDiagnostic[${element.uuid}]`}
                ref={register}
                type='checkbox'
              />
              <label
                data-testid={`${testId}${index}--label`}
                htmlFor={element.uuid}
              >
                {t('carView.inspection.form.workshopControl.done')}
              </label>
            </td>
            <td data-testid={`${testId}${index}--name`}>
              {element.name}
            </td>
          </tr>
        ))}
      </tbody>
    </Container>
  )
}
