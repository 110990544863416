import { NotFound } from 'actff-bo-app/NotFound'
import { hasMenuAccess, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { selectMe, selectUserPermissions, User, UserPermissions } from 'actff-bo-lib/user'
import * as React from 'react'
import { connect, MapStateToProps } from 'react-redux'

type StateToProps = {
  readonly currentUser: User | null,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

export const withAuthorization = (Component: React.ComponentType, permissionsToAccess: ReadonlyArray<UserPermissions>) => {
  class AuthorizedComponent extends React.Component<StateToProps> {
    public componentDidMount(): void {
      if (!this.props.currentUser) {
        history.push(Paths.Login)
      }
    }

    public render(): React.ReactNode {
      return (
        hasMenuAccess(permissionsToAccess, this.props.userPermissions)
          ? <Component />
          : <NotFound />
      )
    }
  }

  const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
    currentUser: selectMe(state),
    userPermissions: selectUserPermissions(state),
  })

  return connect(mapStateToProps, null)(AuthorizedComponent)
}
