import { IconType, Path } from 'actff-bo-lib/menu/dto'
import { LoggedInPermissions } from 'actff-bo-lib/user'

import { ComponentType } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

// tslint:disable object-literal-sort-keys
export const crmTradeMenu = {
  access: LoggedInPermissions,
  component: ComponentType.CrmTradeOpportunityLists,
  expanded: false,
  icon: IconType.CrmTrade,
  name: 'crmTrade',
  path: Paths.CrmTrade as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmTradeOpportunityLists,
      expanded: false,
      name: 'menu.crmTrade.opportunityPurchaseList',
      path: Paths.CrmTradePurchaseOpportunityList as Path,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmTradePurchaseOpportunity,
      expanded: false,
      hiddenInMenu: true,
      name: 'opportunityPurchase',
      path: Paths.CrmTradePurchaseOpportunity as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.CrmTradePurchaseOpportunity,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.CrmTradePurchaseOpportunity as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmTradeOpportunityLists,
      expanded: false,
      name: 'menu.crmTrade.opportunitySaleList',
      path: Paths.CrmTradeSaleOpportunityList as Path,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CrmTradeSaleOpportunity,
      expanded: false,
      hiddenInMenu: true,
      name: 'crm.opportunitySale',
      path: Paths.CrmTradeSaleOpportunity as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.CrmTradeSaleOpportunity,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.CrmTradeSaleOpportunity as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
  ],
}
