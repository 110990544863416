import { reportColsConfig, ReportColumns, reportPrintColsConfig, ReportType } from 'actff-bo-lib/crm/insurance'
import i18next from 'i18next'
import { Filters } from 'react-data-grid'

import { PaymentsReportColumns, PaymentsReportFilters, PaymentsReportSummaryRowsFn } from './Payments'
import { SalesReportColumns, SalesReportFilters, SalesReportSummaryRowsFn } from './Sales'

type SummaryFn = <R, S>(rows: ReadonlyArray<R>) => ReadonlyArray<S>
const ReportSummaryMap = {
  [ReportType.Sales]: SalesReportSummaryRowsFn,
  [ReportType.Payments]: PaymentsReportSummaryRowsFn,
}

export function getReportSummary(type: ReportType): SummaryFn {
  return (ReportSummaryMap[type] || (() => [])) as SummaryFn
}

const ReportColumnsMap = {
  [ReportType.Sales]: SalesReportColumns,
  [ReportType.Payments]: PaymentsReportColumns,
}

export function getReportColumns<R, S>(type: ReportType): ReportColumns<R, S> {
  return (ReportColumnsMap[type] || []) as ReportColumns<R, S>
}

const ReportFiltersMap = {
  [ReportType.Sales]: SalesReportFilters,
  [ReportType.Payments]: PaymentsReportFilters,
}

export function getReportFilters(type: ReportType): Filters | undefined {
  return ReportFiltersMap[type]
}

export function getTranslatedReportColumns<R, S>(type: ReportType, t: i18next.TFunction): ReportColumns<R, S> {
  return getReportColumns<R, S>(type)
    .map(col => ({ ...col, name: t(`crmInsurance.reportHeader.${col.name}`) }))
}

export function prepareColumns<R, S>(cols: ReportColumns<R, S>, printMode: boolean): ReportColumns<R, S> {
  return cols.map(col => {
    if (printMode) {
      return ({
        ...col,
        ...(reportPrintColsConfig[col.key] && { width: reportPrintColsConfig[col.key].width }),
      })
    }

    return ({
      ...col,
      ...(reportColsConfig[col.key] && { width: reportColsConfig[col.key].width }),
    })
  })
}
