import { ClientEmail, ClientFullName, ClientPhone } from 'actff-bo-app/components/Client'
import { ContactInfoStyled, EmailIcon } from 'actff-bo-app/components/Client/Styled'
import { Icon } from 'actff-bo-app/components/Icon'
import { Client } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu'
import * as React from 'react'
import { H2 } from 'styles'

import { CarViewHeaderStyled } from '../CarView/Styled'

type Props = {
  readonly client: Client | null,
}

export const CarNewHeader: React.FC<Props> = ({ client }) => (
  client
    ? (
      <CarViewHeaderStyled>
        <H2>
          <ClientFullName client={client} />
        </H2>
        <ContactInfoStyled>
          <Icon type={IconType.Phone} />
          <ClientPhone client={client} />
          <EmailIcon type={IconType.Email} />
          <ClientEmail client={client} />
        </ContactInfoStyled>
      </CarViewHeaderStyled>
    )
    : null
)
