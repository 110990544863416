import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = {
  readonly year: number,
  readonly onYearChange: (year: number) => void,
}

const YearPickerStyled = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const YearPickerNav = styled.div`
  border: 1px solid ${colors.mysticGray};
  border-radius: 5px;
  display: inline-flex;
  margin-left: 15px;
`

const YearLabel = styled.label`
  padding: 10px;
  color: ${colors.black};
`

const IconArrow = styled(Icon)`
  padding: 0 12px;
`

const IconRightArrow = styled(IconArrow)`
  transform: rotate(180deg);
`

export const YearPicker: FC<Props> = ({ year, onYearChange }) => {
  const { t } = useTranslation()

  const prevYear = () => {
    onYearChange(year - 1)
  }

  const nextYear = () => {
    onYearChange(year + 1)
  }

  return (
    <YearPickerStyled>
      {t('admin.dealership.holidays.changeYear')}:
      <YearPickerNav>
        <button onClick={prevYear}>
          <IconArrow type={IconType.Arrow} />
        </button>
        <YearLabel>{year}</YearLabel>
        <button onClick={nextYear}>
          <IconRightArrow type={IconType.Arrow} />
        </button>
      </YearPickerNav>
    </YearPickerStyled>
  )
}
