import styled from 'styled-components'
import { FormCell } from '../Form'

export const Container = styled.div`
  padding-bottom: 64px;
`

export const HeaderContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 12px 0 0;
`

export const Cell = styled(FormCell)`
  padding: 12.5px 0;
`

export const HeaderLeft = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: left;
`
