import { Map } from 'immutable'

export enum ServiceRequestStatus {
  NEW = 'NEW',
  INPROGRESS = 'INPROGRESS',
  CANCELLED = 'CANCELLED',
  MODIFIED = 'MODIFIED',
  ACCEPTED = 'ACCEPTED',
  POSTPONED = 'POSTPONED',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
}

export const ServiceRequestStatusMap: Map<ServiceRequestStatus, string> = Map([
  [ServiceRequestStatus.ACCEPTED, 'serviceRequest.status.accepted'],
  [ServiceRequestStatus.NEW, 'serviceRequest.status.new'],
  [ServiceRequestStatus.INPROGRESS, 'serviceRequest.status.inprogress'],
  [ServiceRequestStatus.CANCELLED, 'serviceRequest.status.cancelled'],
  [ServiceRequestStatus.MODIFIED, 'serviceRequest.status.modified'],
  [ServiceRequestStatus.POSTPONED, 'serviceRequest.status.postponed'],
  [ServiceRequestStatus.FAILED, 'serviceRequest.status.failed'],
  [ServiceRequestStatus.CLOSED, 'serviceRequest.status.closed'],
]) as Map<ServiceRequestStatus, string>
