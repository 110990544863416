import { AuthActionType } from 'actff-bo-lib/auth'
import { State } from 'actff-bo-lib/store'
import { AnyAction } from 'redux'

// tslint:disable no-object-mutation no-string-literal
export const filterActionsWithTokenPayload = (action: AnyAction) =>
  (action.type === AuthActionType.SetToken || action.type === AuthActionType.LoginSuccess) ? {type: null } : action

export const filterActionsWithCredentials = (action: AnyAction) =>
  action.type === AuthActionType.Login ? { type: null } : action

export const filterTokenState = (state: State) => ({
  ...state,
  auth: {
    token: null,
  },
})

export const filterRequestAuthHeaders = (request: object) => {
  request['headers']['Authorization'] = null

  return request
}

export const filterBodyOnRequestUrlThatContainString = (filterString: string) => (request: object) => {
  if (request['url'].toLowerCase().indexOf(filterString) !== -1) {
    request['body'] = null
  }

  return request
}

export const filterResponseToken = (response: object) => {
  if (response['body'] && response['body']['token']) {
    const mutatedResponse = JSON.parse(JSON.stringify(response))
    mutatedResponse.body.token = null

    return mutatedResponse
  }

  return response
}
