import { ApprovalsContentAction, ApprovalsContentActionType } from 'actff-bo-lib/approvals-content/actions'
import { getPrivacyPolicy } from 'actff-bo-lib/approvals-content/dao'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'

const getApprovalsContentEpic: Epic<ApprovalsContentAction> = action$ => action$.pipe(
  ofType(ApprovalsContentActionType.GetApprovalsContent),
  switchMap(() => getPrivacyPolicy().pipe(
    map(ApprovalsContentAction.getApprovalsContentSuccess),
    catchError((error: AjaxError) => of(ApprovalsContentAction.getApprovalsContentFailure(error))),
  )),
)

export const approvalsContentEpic = combineEpics(
  getApprovalsContentEpic,
)
