import { reactSelectStyles, SelectStyled, selectTheme } from 'actff-bo-app/components/Form'
import { Label } from 'actff-bo-app/components/Label'
import { isSelectOption, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { FieldProps } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionTypeBase, SelectComponentsConfig } from 'react-select'
import { SelectComponentsProps } from 'react-select/src/Select'
import { colors, layers } from 'styles'

type ReactSelectProps<T> = Testable & SelectComponentsProps & {
  readonly disabled?: boolean,
  readonly error?: string,
  readonly label?: string,
  readonly withLabel?: boolean,
  readonly options?: ReadonlyArray<SelectOption<T | string | number>>,
  readonly touched?: string,
  readonly value?: string,
  readonly onChange?: (optionValue: SelectOption<T>) => void,
  readonly defaultValue?: SelectOption<T>,
  readonly formatOptionLabel?: (selectOption: SelectOption<T>) => JSX.Element,
  readonly textTransformUppercase?: boolean,
}

function getSelectOptionValue<T extends string>(
  options?: ReadonlyArray<SelectOption<T>>,
  value?: T,
  fieldValue?: T,
  ): SelectOption<T> | undefined {
  const getValueToSet = () => value || fieldValue || ''

  return { label: getValueToSet() || '', value: getValueToSet() as T }
}

// tslint:disable: trailing-comma
export function Select<T>({
  testId,
  ...props
}: ReactSelectProps<T> & SelectComponentsConfig<SelectOption<string | number>, false>): React.ReactElement {
  const { t } = useTranslation()

  return (
    <div data-testid={`${testId}`}>
      <SelectStyled
        placeholder={t('placeholder.choose')}
        styles={{
          ...reactSelectStyles,
        }}
        theme={selectTheme}
        {...props}
      />
    </div>
  )
}

// Wrapper for Formik Field
export function SelectField<T>({
  disabled = false,
  error,
  onChange,
  options,
  menuPlacement,
  label,
  field: { name, value: fieldValue, onBlur },
  withLabel = true,
  form: { setFieldValue },
  id,
  testId,
  touched,
  value,
}: HTMLElement & ReactSelectProps<T> & FieldProps): React.ReactElement {
  const { t } = useTranslation()

  useEffect(() => {
    if (value !== undefined) {
      setFieldValue(name, value)
    }
  }, [value])

  const hasError = error && touched
  const validatedOptions = options?.filter(isSelectOption) || []

  const handleOnChange = (option: OptionTypeBase) => {
    setFieldValue(name, option.value)
    if (onChange) {
      onChange(option.value)
    }
  }

  // tslint:disable-next-line cyclomatic-complexity
  return (
    <>
      {withLabel && (
        <Label
          {...(hasError && { className: 'error' })}
          disabled={disabled}
          error={hasError}
          htmlFor={id}
          {...(testId && { 'data-testid': `${testId}--label` })}
        >
          {label}
        </Label>
      )}
      <div data-testid={`${testId}`}>
        <SelectStyled
          isDisabled={disabled}
          options={validatedOptions || value || fieldValue}
          placeholder={t('placeholder.choose')}
          styles={{
            ...reactSelectStyles,
            control: (provided: object) => ({
              ...provided,
              border: hasError ? `2px solid ${colors.flamingoRed}` : `2px solid ${colors.mysticGray}`,
            }),
            menu: (base: object) => ({
              ...base,
              zIndex: layers.selectMenu,
            }),
          }}
          menuPlacement={menuPlacement}
          name={name}
          value={getSelectOptionValue(validatedOptions, value, fieldValue) || null}
          onChange={handleOnChange}
          onBlur={onBlur}
          theme={selectTheme}
          {...(testId && { 'data-testid': `${testId}--select` })}
        />
      </div>
    </>
  )
}
