import { Avatar } from 'actff-bo-app/components/Client'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { WithStyledRow } from 'actff-bo-app/components/Table'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { assigneeStatusAvatarStyleMap, getAssigneeStatus, getFullName } from 'actff-bo-lib/client'
import {
  getMatchedTradeOpportunitiesWithAxios,
  OpportunitySale,
  OpportunitySaleStatusType,
  TradeOpportunityType,
} from 'actff-bo-lib/crm/trade'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { getTranslation } from 'actff-bo-lib/i18n'
import { createRoute } from 'actff-bo-lib/menu/helpers'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { UserWithoutPermissions } from 'actff-bo-lib/user'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { Td, Tr } from 'styles'

type Props = {
  readonly opportunity: OpportunitySale,
  readonly currentUser?: UserWithoutPermissions,
}

const TdMatches = styled(Td)`
  display: flex;
  justify-content: center;
`

const isOpportunityInRetryStatus = (opportunity: OpportunitySale) =>
  opportunity.status === OpportunitySaleStatusType.RETRY_CONTACT

export const MatchedOpportunityRow: FC<Props> = ({ currentUser, opportunity }) => {
  const { assignee, client, uuid, preferences } = opportunity
  const { t } = useTranslation()
  const href = createRoute(Paths.CrmTradeSaleOpportunity, { opportunityId: uuid })
  const ClickableTd = TdDecorator(href)

  const { data: matchedPurchaseOpportunities } = useQuery(QueryKeys.MATCHED_PURCHASE_OPPORTUNITIES, async () =>
      await getMatchedTradeOpportunitiesWithAxios(uuid, TradeOpportunityType.Sale),
    { retry: false },
  )

  const Row = isOpportunityInRetryStatus(opportunity) ? WithStyledRow : Tr
  const assigneeStatus = getAssigneeStatus({ assignee, currentUser })
  const avatarStyle = assigneeStatusAvatarStyleMap.get(assigneeStatus)

  const renderCurrentUser = () => currentUser && (
    <Td>
      <Avatar
        style={avatarStyle}
        firstName={assignee?.firstName}
        lastName={assignee?.lastName}
      />
    </Td>
  )

  return (
    <>
      <Row clickable={true} dangerStyleLimit={0} isStyled={true} time={opportunity.actionDate}>
        <ClickableTd>{getTranslation(t, 'crmTrade.status.', opportunity.status)}</ClickableTd>
        <ClickableTd>{client && getFullName(client)}</ClickableTd>
        <ClickableTd>{client?.phone}</ClickableTd>
        <ClickableTd>{client?.email}</ClickableTd>
        {preferences && <ClickableTd><FormattedDate date={preferences.purchaseDate?.from} format={defaultDateFormat} /></ClickableTd>}
        <TdMatches>{matchedPurchaseOpportunities?.length}</TdMatches>
        {renderCurrentUser()}
      </Row>
    </>
  )
}
