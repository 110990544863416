export const convertToUTC = (dateTimeValue: string | Date ) => {
  const dateTime = new Date(dateTimeValue)
  const date = Date.UTC(
    dateTime.getFullYear(),
    dateTime.getMonth(),
    dateTime.getDate(),
  )

  return new Date(date)
}
