import { Filters } from 'actff-bo-app/components/Filters'
import { HeaderLeft } from 'actff-bo-app/components/Grid'
import { ListBrandFiltersForm } from 'actff-bo-app/components/ListFilters/ListBrandFilters'
import { ListFilters } from 'actff-bo-app/components/ListFilters/ListFilters'
import { ListLocationsFiltersForm } from 'actff-bo-app/components/ListFilters/ListLocationsFilters'
import { Loader } from 'actff-bo-app/components/Loader'
import { Search } from 'actff-bo-app/components/Search'
import { NoItems, WithExpandableHeader, WithPagination } from 'actff-bo-app/components/Table'
import { PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { Filters as FiltersType } from 'actff-bo-lib/global'
import { Path, Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import {
  selectCurrentPage,
  selectIsLoadingServiceRequestList,
  selectNewServiceRequestsCount,
  selectOverdueListCurrentPage,
  selectOverdueServiceRequestsList,
  selectServiceRequestBrandFilters,
  selectServiceRequestDealerLocationFilters,
  selectServiceRequestListFilters,
  selectServiceRequestSearchPhrase,
  selectServiceRequestsList,
  ServiceRequest,
  ServiceRequestAction,
  ServiceRequestFilterValues,
} from 'actff-bo-lib/service-request'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import { H2, HeaderFiltersContainer, HeaderList, Small } from 'styles'

import { ServiceRequestTable } from './ServiceRequestTable'

type StateToProps = {
  readonly brandFilters: ReadonlyArray<string>,
  readonly currentPage: number,
  readonly dealerLocationFilters: ReadonlyArray<DealerLocationKey>,
  readonly loading: boolean,
  readonly newServiceRequestsCount: number,
  readonly overdueCurrentPage: number,
  readonly overdueServiceRequests: PagedResult<ServiceRequest> | null,
  readonly serviceRequests: PagedResult<ServiceRequest> | null,
  readonly serviceRequestListFilters: FiltersType<ServiceRequestFilterValues>,
  readonly serviceRequestSearchPhrase?: string,
}

type DispatchToProps = {
  readonly getNewServiceRequestCount: () => void,
  readonly onListFilterChange: (param: string, value: ServiceRequestFilterValues) => void,
  readonly getOverdueServiceRequests: () => void,
  readonly getServiceRequests: () => void,
  readonly onBrandChange: (brands: ReadonlyArray<string>) => void,
  readonly onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => void,
  readonly onOverduePageChange: (nextPage: number) => void,
  readonly onPageChange: (nextPage: number) => void,
  readonly onSearchPhraseChange: (searchPhrase?: string) => void,
}

type ServiceRequestListComponentProps = StateToProps & DispatchToProps & WithTranslation

const testId = 'service-request-list__'

const HeaderSearch = styled.div`
  display: grid;
  align-items: center;
`

export class ServiceRequestListComponent extends React.Component<ServiceRequestListComponentProps> {

  public componentDidMount(): void {
    this.props.getServiceRequests()
    this.props.getOverdueServiceRequests()
    this.props.getNewServiceRequestCount()
  }

  public render(): React.ReactNode {
    const {
      currentPage,
      brandFilters,
      dealerLocationFilters,
      loading,
      onBrandChange,
      onListFilterChange,
      onPageChange,
      onOverduePageChange,
      newServiceRequestsCount,
      onDealerLocationChange,
      onSearchPhraseChange,
      overdueCurrentPage,
      overdueServiceRequests,
      serviceRequestSearchPhrase,
      serviceRequests,
      serviceRequestListFilters,
      t,
    } = this.props

    if (loading) {
      return <Loader />
    }

    const renderOverdueServiceRequests = () => !!overdueServiceRequests?.result.length && (
      <WithExpandableHeader header='serviceRequest.status.accepted' testId={`${testId}overdue-`}>
        <WithPagination
          currentPage={overdueCurrentPage}
          onPageChange={onOverduePageChange}
          pageCount={overdueServiceRequests.noOfPages}
          path={Paths.ServiceRequests as Path}
          rowsInCurrentPage={overdueServiceRequests.result.length}
          testId={testId}
        >
          <ServiceRequestTable serviceRequests={overdueServiceRequests} testId={`${testId}overdue-`} />
        </WithPagination>
      </WithExpandableHeader>
    )

    return serviceRequests
      ? (
        <>
          <HeaderList>
            <H2 data-testid={`${testId}header`}>{t('serviceRequestList.tableHeader')}</H2>
            <Small data-testid={`${testId}new-requests-count`}>
              {t('serviceRequestList.newRequests', { count: newServiceRequestsCount })}
            </Small>
            <HeaderFiltersContainer>
              <HeaderLeft>
                <Filters filters={serviceRequestListFilters} onFilterChange={onListFilterChange} testId={testId} />
                <ListFilters
                  filtersCaption={t('caption.location')}
                  filtersComponent={filterRendererProps => <ListLocationsFiltersForm {...filterRendererProps} />}
                  filterValues={dealerLocationFilters}
                  onSubmit={onDealerLocationChange}
                />
                <ListFilters
                  filtersCaption={t('caption.brand')}
                  filtersComponent={filterRendererProps => <ListBrandFiltersForm {...filterRendererProps} />}
                  filterValues={brandFilters}
                  onSubmit={onBrandChange}
                />
              </HeaderLeft>
              <HeaderSearch>
                <Search
                  initialValues={{ searchPhrase: serviceRequestSearchPhrase }}
                  onSearch={onSearchPhraseChange}
                  testId={testId}
                />
              </HeaderSearch>
            </HeaderFiltersContainer>
          </HeaderList>
          {
            serviceRequests.result.length ? (
              <WithPagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageCount={serviceRequests.noOfPages}
                path={Paths.ServiceRequests as Path}
                rowsInCurrentPage={serviceRequests.result.length}
                testId={testId}
              >
                <ServiceRequestTable serviceRequests={serviceRequests} testId={testId} />
              </WithPagination>
            ) : <NoItems icon={IconType.NoCars} text={t('serviceRequestList.noRequests')} />
          }
          {renderOverdueServiceRequests()}
        </>
      )
      : <Loader />
  }
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = (state: State) => ({
  brandFilters: selectServiceRequestBrandFilters(state),
  currentPage: selectCurrentPage(state),
  dealerLocationFilters: selectServiceRequestDealerLocationFilters(state),
  loading: selectIsLoadingServiceRequestList(state),
  newServiceRequestsCount: selectNewServiceRequestsCount(state),
  overdueCurrentPage: selectOverdueListCurrentPage(state),
  overdueServiceRequests: selectOverdueServiceRequestsList(state),
  serviceRequestListFilters: selectServiceRequestListFilters(state),
  serviceRequestSearchPhrase: selectServiceRequestSearchPhrase(state),
  serviceRequests: selectServiceRequestsList(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = (dispatch: Dispatch) => ({
  getNewServiceRequestCount: () => { dispatch(ServiceRequestAction.getNewServiceRequestCount()) },
  getOverdueServiceRequests: () => { dispatch(ServiceRequestAction.getOverdueServiceRequests()) },
  getServiceRequests: () => { dispatch(ServiceRequestAction.getServiceRequests()) },
  onBrandChange: (brands: ReadonlyArray<string>) => { dispatch(ServiceRequestAction.changeCarBrands(brands)) },
  onDealerLocationChange: (locations: ReadonlyArray<DealerLocationKey>) => {
    dispatch(ServiceRequestAction.changeDealerLocations(locations))
  },
  onListFilterChange: (param: string, value: ServiceRequestFilterValues) => {
    dispatch(ServiceRequestAction.changeListFilter(param, value))
  },
  onOverduePageChange: (nextPage: number) => { dispatch(ServiceRequestAction.changeOverdueListPage(nextPage)) },
  onPageChange: (nextPage: number) => { dispatch(ServiceRequestAction.changePage(nextPage)) },
  onSearchPhraseChange: (searchPhrase: string) => { dispatch(ServiceRequestAction.changeSearchPhrase(searchPhrase)) },
})

export const ServiceRequestList = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ServiceRequestListComponent),
)
