import { CollapsableSection } from 'actff-bo-app/components/CollapsableSection'
import { InspectionPaintCoatElementType, PaintCoatElementEvaluation } from 'actff-bo-lib/car/dto'
import { FormValueT, InspectionPaintCoatFormElement } from 'actff-bo-lib/car/helpers'
import { Map } from 'immutable'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Color, colors, CssOffsetCoordinates } from 'styles'

import { Evaluation } from './Evaluation'
import { PaintCoatCarSvg } from './PaintCoatCarSvg'

type Props = {
  readonly elements: FormValueT<InspectionPaintCoatFormElement>,
  readonly setPaintCoatValue: (updatedPaintCoat: FormValueT<InspectionPaintCoatFormElement>) => void,
}

const Container = styled.div`
  position: relative;
`

const Bolder = styled.span`
  font-weight: 600;
`

const Info = styled.div`
  padding: 10px 24px;
  margin-bottom: 45px;
`

export const EvaluationElementsPositionCoordinatesMap: Map<InspectionPaintCoatElementType, CssOffsetCoordinates> = Map([
  [InspectionPaintCoatElementType.frontBumper, { bottom: '45%', left: '5%' }],
  [InspectionPaintCoatElementType.engineCover, { bottom: '45%', left: '25%' }],
  [InspectionPaintCoatElementType.rearBumper, { bottom: '45%', left: '90%' }],
  [InspectionPaintCoatElementType.rearLeftDoor, { bottom: '10%', left: '55%' }],
  [InspectionPaintCoatElementType.rearRightDoor, { bottom: '80%', left: '55%'}],
  [InspectionPaintCoatElementType.frontLeftDoor, { bottom: '10%', left: '43%' }],
  [InspectionPaintCoatElementType.frontRightDoor, { bottom: '80%', left: '42%' }],
  [InspectionPaintCoatElementType.roof, { bottom: '45%', left: '50%' }],
  [InspectionPaintCoatElementType.leftSill, { bottom: '-2%', left: '50%' }],
  [InspectionPaintCoatElementType.rightSill, { bottom: '93%', left: '50%' }],
  [InspectionPaintCoatElementType.frontRightFender, { bottom: '79%', left: '32%' }],
  [InspectionPaintCoatElementType.frontLeftFender, { bottom: '12%', left: '32%' }],
  [InspectionPaintCoatElementType.rearLeftFender, { bottom: '13%', left: '65%' }],
  [InspectionPaintCoatElementType.rearRightFender, { bottom: '79%', left: '65%' }],
  [InspectionPaintCoatElementType.trunkLid, { bottom: '45%', left: '84%' }],

]) as Map<InspectionPaintCoatElementType, CssOffsetCoordinates>

// tslint:disable-next-line:cyclomatic-complexity
const evaluateElementColor = (element: InspectionPaintCoatFormElement): Color => {
  const { thickness, damageTypes, maxCorrectThickness, requiresMeasurement } = element
  const hasDamages = damageTypes?.length > 0

  if (!thickness && hasDamages) {
    return colors.sunglow
  }

  if (!requiresMeasurement && !hasDamages) {
    return colors.mintJelly
  }

  if (!thickness) {
    return colors.iceCastle
  }

  if (thickness <= maxCorrectThickness && hasDamages) {
    return colors.sunglow
  }

  if (thickness <= maxCorrectThickness) {
    return colors.mintJelly
  }

  return colors.khmerCurry
}

const getInspectionPaintCoatElementTypeKey = (keyValue: string) => Object.keys(InspectionPaintCoatElementType)
    .find(key => InspectionPaintCoatElementType[key] === keyValue) as string

const generatePropsForSvgCar = (elements: FormValueT<InspectionPaintCoatFormElement>) =>
  Object.entries(elements).reduce((prev, [key, val]) => ({
    ...prev,
    [getInspectionPaintCoatElementTypeKey(key)]: evaluateElementColor(val),
  }), {})

export const EvaluationElementsBgMap: Map<PaintCoatElementEvaluation, Color> = Map([
  [PaintCoatElementEvaluation.Good, colors.mintJelly],
  [PaintCoatElementEvaluation.Medium, colors.sunglow],
  [PaintCoatElementEvaluation.Bad, colors.khmerCurry],
  [PaintCoatElementEvaluation.NotEvaluated, colors.iceCastle],
]) as Map<PaintCoatElementEvaluation, Color>

export const PaintCoat: FC<Props> = ({ elements, setPaintCoatValue }) => {
  const { t } = useTranslation()
  const getElementOffsetCoordinates = (element: InspectionPaintCoatFormElement) => EvaluationElementsPositionCoordinatesMap.get(element.key)

  return (
    <CollapsableSection title={t('carView.inspection.form.paintCoat.header')}>
      <Info>
        {t('carView.inspection.form.paintCoat.infoBeginning')}
        <Bolder>{t('carView.inspection.form.paintCoat.infoBolder')}</Bolder>
        {t('carView.inspection.form.paintCoat.infoEnd')}
      </Info>
      <Container>
        <PaintCoatCarSvg {...generatePropsForSvgCar(elements)} />
        {Object.entries<InspectionPaintCoatFormElement>(elements).map(([key, value]) => (
          <Evaluation
            bottom={getElementOffsetCoordinates(value)?.bottom}
            element={value}
            key={key}
            left={getElementOffsetCoordinates(value)?.left}
            setPaintCoatValue={setPaintCoatValue}
          />
        ))}
      </Container>
    </CollapsableSection>
  )
}
