import { Icon } from 'actff-bo-app/components/Icon'
import styled from 'styled-components'

export const ContactInfoStyled = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, fit-content(100%));
`

export const EmailIcon = styled(Icon)`
  margin-left: 24px;
`
