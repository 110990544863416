import { Loader } from 'actff-bo-app/components/Loader'
import {
  Client,
  ClientId,
  ContactApprovals,
  ContactApprovalsAction,
  ContactApprovalsWithClientIdToAPI,
  selectCurrentContactApprovals,
  selectIsLoadingContactApprovals,
} from 'actff-bo-lib/client'
import { Language } from 'actff-bo-lib/i18n'
import { State } from 'actff-bo-lib/store'
import { User } from 'actff-bo-lib/user'
import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'

import { withClientViewHeader } from '../withClientViewHeader'
import { ApprovalsForm } from './ApprovalsForm'
import { NoData } from './styled'

type DispatchToProps = {
  readonly getContactApprovals: (clientId: ClientId) => void,
  readonly updateContactApprovals: (contactApprovals: ContactApprovalsWithClientIdToAPI) => void,
}

type StateToProps = {
  readonly approvals: ContactApprovals | null,
  readonly isLoading: boolean,
}

type ClientViewApprovalsComponentProps = {
  readonly client: Client,
  readonly currentLanguage: Language,
  readonly currentUser: User | null,
}

export type ClientViewApprovalsProps = StateToProps & DispatchToProps & WithTranslation & ClientViewApprovalsComponentProps

class ApprovalsComponent extends React.Component<ClientViewApprovalsProps> {
  public componentDidMount(): void {
    this.getContactApprovals()
  }

  public render(): React.ReactNode {
    const { approvals, client, currentUser, isLoading, updateContactApprovals, t } = this.props

    if (isLoading) {
      return <Loader />
    }

    if (!approvals) {
      return (
        <NoData>{t('caption.noData')}</NoData>
      )
    }

    return (
      <ApprovalsForm
        approvals={approvals}
        client={client}
        user={currentUser}
        updateContactApprovals={updateContactApprovals}
      />
    )
  }

  private readonly getContactApprovals = () => { this.props.getContactApprovals(this.props.client.uuid) }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getContactApprovals: (clientUuid: ClientId) => { dispatch(ContactApprovalsAction.getContactApprovals(clientUuid))},
  updateContactApprovals: (payload: ContactApprovalsWithClientIdToAPI) => {
    dispatch(ContactApprovalsAction.updateContactApprovals(payload))
  },
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  approvals: selectCurrentContactApprovals(state),
  isLoading: selectIsLoadingContactApprovals(state),
})

export const Approvals = compose(
  withClientViewHeader,
  connect(mapStateToProps, mapDispatchToProps),
)(ApprovalsComponent)
