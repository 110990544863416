import { PagedResult } from 'actff-bo-lib/api'
import { WithDealershipFilters } from 'actff-bo-lib/dealership'
import { Filterable, InternalComment, Loadable, Searchable } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { parseUrl } from 'actff-bo-lib/pagination'
import { updateStateFieldIfExists } from 'actff-bo-lib/store/helpers'
import { UserAction, UserActionType } from 'actff-bo-lib/user'

import { ServiceRequestAction, ServiceRequestActionType } from './actions'
import { CarLeaveOption, ServiceRequest, ServiceRequestFilterValues, ServiceRequestStatus } from './dto'

export type ServiceRequestState = {
  readonly availableCarLeaveOptions: ReadonlyArray<CarLeaveOption>
  readonly createServiceRequestPending: boolean,
  readonly overdueCurrentPage: number,
  readonly currentPage: number,
  readonly currentServiceRequest: {
    readonly attachments: ReadonlyArray<string> | null,
    readonly comments: ReadonlyArray<InternalComment> | null,
    readonly data: ServiceRequest | null,
  },
  readonly draftServiceRequest: ServiceRequest | null,
  readonly isUpdatingServiceRequest: boolean,
  readonly list: Searchable<WithDealershipFilters<Filterable<Loadable<PagedResult<ServiceRequest>>, ServiceRequestFilterValues>>>,
  readonly newServiceRequestsCount: number,
  readonly overdueList: Loadable<PagedResult<ServiceRequest>>,
}

const parsedUrl = parseUrl(Paths.ServiceRequests)
const initialState: ServiceRequestState = {
  availableCarLeaveOptions: [],
  createServiceRequestPending: false,
  currentPage: parsedUrl.page ? +parsedUrl.page : 1,
  currentServiceRequest: {
    attachments: null,
    comments: null,
    data: null,
  },
  draftServiceRequest: null,
  isUpdatingServiceRequest: false,
  list: {
    brandFilters: [],
    data: null,
    filters: {
      status: [
        {
          caption: 'filters.new',
          selected: true,
          value: ServiceRequestStatus.NEW,
        },
        {
          caption: 'filters.serviceRequest.accepted',
          selected: false,
          value: ServiceRequestStatus.ACCEPTED,
        },
        {
          caption: 'filters.inprogress',
          selected: false,
          value: ServiceRequestStatus.INPROGRESS,
        },
        {
          caption: 'filters.cancelled',
          selected: false,
          value: ServiceRequestStatus.CANCELLED,
        },
        {
          caption: 'filters.serviceRequest.modified',
          selected: false,
          value: ServiceRequestStatus.MODIFIED,
        },
        {
          caption: 'filters.serviceRequest.postponed',
          selected: false,
          value: ServiceRequestStatus.POSTPONED,
        },
        {
          caption: 'filters.serviceRequest.failed',
          selected: false,
          value: ServiceRequestStatus.FAILED,
        },
        {
          caption: 'filters.closed',
          selected: false,
          value: ServiceRequestStatus.CLOSED,
        },
      ],
    },
    loading: false,
    locationFilters: [],
  },
  newServiceRequestsCount: 0,
  overdueCurrentPage: 1,
  overdueList: {
    data: null,
    loading: false,
  },
}

// tslint:disable cyclomatic-complexity
export const serviceRequestReducer = (
  state: ServiceRequestState = initialState,
  action: ServiceRequestAction | UserAction,
): ServiceRequestState => {
  switch (action.type) {
    case ServiceRequestActionType.CreateServiceRequest:
      return {
        ...state,
        createServiceRequestPending: true,
      }

    case ServiceRequestActionType.CreateServiceRequestFailure:
    case ServiceRequestActionType.CreateServiceRequestSuccess:
        return {
          ...state,
          createServiceRequestPending: false,
        }

    case ServiceRequestActionType.ChangeOverdueListPage:
        return {
          ...state,
          overdueCurrentPage: action.payload,
        }

    case ServiceRequestActionType.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case ServiceRequestActionType.ChangeListFilter:
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            [action.payload.param]: [
              ...state.list.filters[action.payload.param]
                .map(filter => filter.value === action.payload.value
                  ? ({
                    ...filter,
                    selected: !filter.selected,
                  })
                  : ({ ...filter }),
                ),
            ],
          },
        },
      }

    case ServiceRequestActionType.ChangeSearchPhrase:
      return {
        ...state,
        list: {
          ...state.list,
          searchPhrase: action.payload,
        },
      }

    case ServiceRequestActionType.ChangeDealerLocations:
      return {
        ...state,
        list: {
          ...state.list,
          locationFilters: action.payload,
        },
      }

    case ServiceRequestActionType.ChangeBrands:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload,
        },
      }

    case ServiceRequestActionType.ClearDraft:
      return {
        ...state,
        draftServiceRequest: null,
      }

    case ServiceRequestActionType.ClearServiceRequest:
      return {
        ...state,
        currentServiceRequest: {
          attachments: null,
          comments: null,
          data: null,
        },
      }

    case ServiceRequestActionType.GetAvailableCarLeaveOptionsSuccess:
      return {
        ...state,
        availableCarLeaveOptions: action.payload,
      }

    case ServiceRequestActionType.GetServiceRequestSuccess:
      return {
        ...state,
        currentServiceRequest: {
          ...state.currentServiceRequest,
          data: action.payload,
        },
        draftServiceRequest: action.payload,
      }

    case ServiceRequestActionType.GetNewServiceRequestCountSuccess:
      return {
        ...state,
        newServiceRequestsCount: action.payload.requestCount,
      }

    case ServiceRequestActionType.GetServiceRequestCommentsSuccess:
      return {
        ...state,
        currentServiceRequest: {
          ...state.currentServiceRequest,
          comments: action.payload,
        },
      }

    case ServiceRequestActionType.GetServiceRequests:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }

    case ServiceRequestActionType.GetServiceRequestsSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload,
          loading: false,
        },
      }

    case ServiceRequestActionType.GetServiceRequestsFailure:
      return {
        ...state,
        list: {
          ...state.list,
          data: null,
          loading: false,
        },
      }

    case ServiceRequestActionType.GetOverdueServiceRequestsSuccess:
      return {
        ...state,
        overdueList: {
          ...state.overdueList,
          data: action.payload,
        },
      }

    case ServiceRequestActionType.GetServiceRequestAttachmentsSuccess:
      return {
        ...state,
        currentServiceRequest: {
          ...state.currentServiceRequest,
          attachments: action.payload,
        },
      }

    case ServiceRequestActionType.SetDraftCarLeaveOption:
      return updateStateFieldIfExists(state, 'draftServiceRequest', { carLeaveOption: action.payload })

    case ServiceRequestActionType.SetDraftInterestedInEarlierDate:
      return updateStateFieldIfExists(state, 'draftServiceRequest', { interestedInEarlierDate: action.payload })

    case ServiceRequestActionType.SetDraftServices:
      return updateStateFieldIfExists(state, 'draftServiceRequest', { services: action.payload })

    case ServiceRequestActionType.SetDraftStatus:
      return updateStateFieldIfExists(state, 'draftServiceRequest', { status: action.payload })

    case ServiceRequestActionType.SendInternalCommentSuccess:
      return {
        ...state,
        currentServiceRequest: {
          ...state.currentServiceRequest,
          comments: [
            ...state.currentServiceRequest.comments || [],
            action.payload,
          ],
        },
      }

    case ServiceRequestActionType.UpdateServiceRequestFromDraft:
      return {
        ...state,
        isUpdatingServiceRequest: true,
      }

    case ServiceRequestActionType.UpdateServiceRequestFromDraftSuccess:
    case ServiceRequestActionType.UpdateServiceRequestFromDraftFailure:
      return {
        ...state,
        isUpdatingServiceRequest: false,
      }

    case ServiceRequestActionType.UpdateServiceRequestAsInProgress:
      return updateStateFieldIfExists(state, 'draftServiceRequest', { status: ServiceRequestStatus.INPROGRESS })

    case ServiceRequestActionType.UndoChanges:
      return {
        ...state,
        draftServiceRequest: state.currentServiceRequest.data,
      }

    case UserActionType.GetMeSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload.brands || [],
          locationFilters: action.payload.locations.map(location => location.key),
        },
      }

    default:
      return state
  }
// tslint:disable-next-line:max-file-line-count
}
