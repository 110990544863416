import { api, PagedResult } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { buildUrl, getSimpleFiltersAsObject } from 'actff-bo-lib/global'
import { UserId, UserWithoutPermissions } from 'actff-bo-lib/user'
import { Observable } from 'rxjs'

import { OpportunityInsuranceId, OpportunityListItem, OpportunityListType, OpportunityUpdatePayload } from '../dto'
import { InsuranceFilters } from '../filters'

export const assignOpportunity = (uuid: OpportunityInsuranceId, assignee: UserWithoutPermissions | null) =>
  api.patch<OpportunityUpdatePayload, UserWithoutPermissions | {}>(
    `/insurance/opportunity/${uuid}/assign`,
    assignee || {},
  )

export const unassignOpportunity = (uuid: OpportunityInsuranceId) =>
  api.delete<OpportunityUpdatePayload>(
    `/insurance/opportunity/${uuid}/assign`,
  )

export const getOpportunities = (
  type: OpportunityListType,
  page: number,
  filters: InsuranceFilters,
  assignmentFilter: UserId | null,
  location?: ReadonlyArray<DealerLocationKey>,
  brand?: ReadonlyArray<string>,
  fromDate?: Date,
  toDate?: Date,
  phrase?: string,
  size = 10,
): Observable<PagedResult<OpportunityListItem>> => {
  const from = fromDate && fromDate.toISOString()
  const to = toDate && toDate.toISOString()

  if (type === OpportunityListType.all) {
    return api.get(
      buildUrl('/insurance/opportunity/all',
      {
        ...getSimpleFiltersAsObject(filters),
        assignee: assignmentFilter,
        page,
        // tslint:disable-next-line:object-literal-sort-keys
        from,
        to,
        search: phrase,
        size,
        ...getSimpleFiltersAsObject({ location, brand }),
      },
    ))
  }

  return api.get(buildUrl(
    `/insurance/opportunity/${type}`,
    {
      ...getSimpleFiltersAsObject(filters),
      assignee: assignmentFilter,
      page,
      // tslint:disable-next-line:object-literal-sort-keys
      from,
      to,
      search:
      phrase,
      size,
      ...getSimpleFiltersAsObject({ location, brand }),
    },
  ))
}
