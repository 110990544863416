import { CarColor } from 'actff-bo-lib/car'
import styled from 'styled-components'
import { colors } from 'styles'

type ColorSquareProps = {
  readonly color: CarColor,
  readonly selected: boolean,
}

export const Container = styled.div`
  display: grid;
  grid-row-gap: 10px;
  padding: 24px;
`

export const Segment = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
`

export const TwoRowsSegment = styled(Segment)`
  grid-template-rows: repeat(2, 1fr);
  justify-items: center;
`

export const AdditionalEquipment = styled.div`
  width: 25%;
`

export const CarDataSegment = styled.div`
  width: 80%;
`

export const CarDataHeaders = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 20px;
`

export const CarData = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  justify-items: left;
`

export const SelectableItemContent = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  place-items: center;
`

export const ColorSquare = styled.div<ColorSquareProps>`
  ${({ color }: ColorSquareProps) => color === 'white' as CarColor && 'border: 1px solid black'};
  background-color: ${({ color }: ColorSquareProps) => color || colors.white};
  border-radius: 2px;
  color: ${({ selected }: ColorSquareProps) => selected ? colors.white : 'default'};
  cursor: pointer;
  height: 16px;
  width: 16px;

  label: {
    cursor: pointer;
  }
`

export const SelectBrandInfo = styled.span`
  font-style: italic;
`
