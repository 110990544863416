import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import styled from 'styled-components'

import { Icon } from './Icon/Icon'

type Props = {
  readonly expanded: boolean,
}

const DropdownIcon = styled(Icon)`
  margin-left: 6px;
`

const RotatedDropdownIcon = styled(DropdownIcon)`
  transform: rotate(180deg);
`

export const ExpandCollapse: React.FC<Props> = ({ expanded }) => (
  expanded ? <RotatedDropdownIcon className='dropdown' type={IconType.DropdownGold} /> : <DropdownIcon type={IconType.DropdownGold} />
)
