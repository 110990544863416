import { FormCell, reactSelectStyles, selectTheme } from 'actff-bo-app/components/Form'
import { getLocale } from 'actff-bo-lib/date'
import { connector } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import {
  CarLeaveOption,
  CarLeaveOptionName,
  CarLeaveOptionNameMap,
  selectAvailableCarLeaveOptions,
} from 'actff-bo-lib/service-request'
import { State as ReduxState } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { MapStateToProps } from 'react-redux'
import Select from 'react-select'
import { compose } from 'redux'
import styled from 'styled-components'

import { RowSaveEditButton } from '../ServiceRequestViewStyled'
import { Contact, D2D, LeaveCar, WaitInPlace } from './CarLeaveOption'

type Props = {
  readonly carLeaveOption: CarLeaveOption,
  readonly interestedInEarlierDate: boolean,
  readonly onCarLeaveOptionChange: (option: CarLeaveOption) => void,
  readonly onInterestedInEarlierDateChange: (value: boolean) => void,
}

type StateToProps = {
  readonly availableCarLeaveOptions: ReadonlyArray<CarLeaveOption>,
  readonly currentLanguage: Language,
}

type State = {
  readonly carLeaveOption: CarLeaveOption,
  readonly editMode: boolean,
}

type ServiceRequestCarLeaveProps = Testable & Props & WithTranslation & StateToProps

type CarLeaveOptionSelect = {
  readonly label: CarLeaveOptionName,
  readonly value: CarLeaveOption,
}

const Container = styled.div`
  display: grid;
`

const CarLeaveOptionContainer = styled(FormCell)`
  max-width: 50%;
`

class ServiceRequestCarLeaveComponent extends React.Component<ServiceRequestCarLeaveProps, State> {
  public readonly state: State = {
    carLeaveOption: this.props.carLeaveOption,
    editMode: false,
  }

  public render(): React.ReactNode {
    const { carLeaveOption, interestedInEarlierDate, t, testId } = this.props
    const { editMode } = this.state

    return (
      <Container>
        <CarLeaveOptionContainer data-testid={`${testId}car-leave-option`}>
          <label>{t('serviceRequest.carLeaveOption.label')}</label>
          {this.renderCarLeaveOption()}
        </CarLeaveOptionContainer>
        <FormCell>
          <input
            id='interested-in-earlier-date'
            disabled={!editMode}
            checked={!!interestedInEarlierDate}
            onChange={this.handleInterestedInEarlierDateChange}
            type='checkbox'
          />
          <label
            data-testid={`${testId}interested-in-earlier-date`}
            htmlFor='interested-in-earlier-date'
          >
            {t('serviceRequest.clientInterestedInEarlierDate')}
          </label>
        </FormCell>
        {this.getCarLeaveComponent(carLeaveOption)}
        <RowSaveEditButton
          className='car-leave-save-edit'
          editMode={editMode}
          onClick={this.toggleEditMode}
          t={t}
          testId={`${testId}car-leave-button`}
        />
      </Container>
    )
  }

  private readonly handleSelectOptionChange = (value: CarLeaveOptionSelect) => {
    this.props.onCarLeaveOptionChange(value.value)
  }

  private readonly handleInterestedInEarlierDateChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onInterestedInEarlierDateChange(event.target.checked)

  private readonly getCurrentValue = (): CarLeaveOptionSelect => ({
    label: this.props.t(CarLeaveOptionNameMap.get(this.props.carLeaveOption.carLeaveOptionName, '')),
    value: this.props.carLeaveOption,
  })

  private readonly getSelectElements = (): ReadonlyArray<CarLeaveOptionSelect> =>
    this.props.availableCarLeaveOptions
      .map(carLeaveOption => ({
        label: this.props.t(CarLeaveOptionNameMap.get(carLeaveOption.carLeaveOptionName, '')),
        value: carLeaveOption,
      }))

  private readonly renderCarLeaveOption = () => this.state.editMode
    ? (
      <Select
        defaultValue={this.getCurrentValue()}
        styles={reactSelectStyles}
        theme={selectTheme}
        options={this.getSelectElements()}
        onChange={this.handleSelectOptionChange}
        value={this.getCurrentValue()}
      />
    )
    : this.props.t(CarLeaveOptionNameMap.get(this.props.carLeaveOption.carLeaveOptionName, ''))

  private readonly getCarLeaveComponent = (carLeaveOption: CarLeaveOption) => {
    const { currentLanguage, onCarLeaveOptionChange, testId } = this.props
    const { editMode } = this.state
    const locale = getLocale(currentLanguage)
    const parameters = { editMode, locale, onCarLeaveOptionChange, testId }

    // tslint:disable-next-line cyclomatic-complexity
    switch (carLeaveOption.carLeaveOptionName) {
      default:
      case CarLeaveOptionName.Contact:
        return <Contact testId={testId} />

      case CarLeaveOptionName.WaitInPlace:
        return <WaitInPlace carLeaveOption={carLeaveOption} {...parameters} />

      case CarLeaveOptionName.LeaveCar:
        return <LeaveCar carLeaveOption={carLeaveOption} {...parameters} />

      case CarLeaveOptionName.D2D:
        return <D2D carLeaveOption={carLeaveOption} {...parameters} />
    }
  }

  private readonly toggleEditMode = () => { this.setState({ editMode: !this.state.editMode }) }
}

const mapStateToProps: MapStateToProps<StateToProps, null, ReduxState> = state => ({
  availableCarLeaveOptions: selectAvailableCarLeaveOptions(state),
  currentLanguage: selectCurrentLanguage(state),
})

export const ServiceRequestCarLeave = compose(
  connector(mapStateToProps),
  withTranslation(),
)(ServiceRequestCarLeaveComponent)
