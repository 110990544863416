import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { UploadComponentProps } from './UploadFile'

const PhotosUploadContainer = styled.div`
  height: 40px;
  position: relative;
`

const PhotosUpload = styled.div`
  cursor: pointer;
  pointer-events: none;
  position: absolute;
`

const FileUploadNativeButton = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
`

export const UploadButton: FC<UploadComponentProps> = ({ acceptTypes, onChange, multiple }) => {
  const { t } = useTranslation()

  return (
    <PhotosUploadContainer>
      <PhotosUpload>
        <ButtonWithIcon caption={t('caption.addAttachments')} iconType={IconType.Attachment} noPadding={true} theme={BtnTheme.Link}/>
      </PhotosUpload>
      <FileUploadNativeButton
        accept={acceptTypes}
        onChange={onChange}
        multiple={multiple}
        type='file'
      />
    </PhotosUploadContainer>
  )
}
