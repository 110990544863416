import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormHeader } from './Form'

type Props = {
  readonly text?: string,
}

const NoDataContainer = styled(FormHeader)`
  padding: 24px 0;
`

export const NoData: FC<Props> = ({ text }) => {
  const { t } = useTranslation()

  return text ? <NoDataContainer>{t(text)}</NoDataContainer> : <NoDataContainer>{t('caption.noData')}</NoDataContainer>
}
