import { ClientFilters } from 'actff-bo-lib/client/dto'
import { DetailedFilters } from 'actff-bo-lib/crm/trade/purchase/filters/filters'

type Filters = ClientFilters
type FilterValue = Date | number | object | string | null

const getDivider = (idx: number, acc: string): string => idx === 0 || acc === '&' ? '' : '&'

// Check if any current filter object value has some not falsy value so that it should be used in URL string
const hasValues = (object: keyof ClientFilters) => Object.values(object).some(value => value)

const isFilterNotNullObject = (filter: FilterValue): boolean => typeof filter === 'object' && filter !== null

// If current filter is Date type, convert it to ISOString and return as key-date value
const getDateFilterAsUrlString = (actualValue: string, index: number, filters: Filters | DetailedFilters, filterKey: string): string =>
  `${actualValue}${getDivider(index, actualValue)}${filterKey}=${filters[filterKey].toISOString()}`

// If current filter is an object with nested filter values, split it by keys and return as string with filters
// as object name key- filter keys value, eg: CarStatuses=ALL_CARS_APPROVED
// Make it in UPPERCASE URL string format instead of camelCase object keys format
const getNestedFiltersAsUrlString = (actualValue: string, index: number, filters: Filters | DetailedFilters, filterKey: string): string =>
  hasValues(filters[filterKey]) ? `${actualValue}${getDivider(index, actualValue)}${filterKey}=${
    Object.keys(filters[filterKey])
      .filter(key => filters[filterKey][key])
      .map(innerKey => filters[filterKey][innerKey] ? innerKey.split(/(?=[A-Z])/).join('_').toUpperCase() : '')
}` : actualValue

// If current filter is not a Date or an object nor is not null, return it as a URL string with key-value
// eg `filterKey=filterValue`
const getFilterAsUrlString = (actualValue: string, index: number, filters: Filters | DetailedFilters, filterKey: string): string =>
  `${actualValue}${getDivider(index, actualValue)}${filterKey}=${filters[filterKey]}`

export const buildSpecificFiltersUrl = (filters: Filters | DetailedFilters | null): string | null =>
  filters && Object.keys(filters).reduce((acc, filterKey, idx) => {
    if (filters[filterKey] instanceof Date) {
      return getDateFilterAsUrlString(acc, idx, filters, filterKey)
    }

    if (isFilterNotNullObject(filters[filterKey])) {
      return getNestedFiltersAsUrlString(acc, idx, filters, filterKey)
    }

    // If current filter is null, skip this filter and don't create any URL string with filter key-value
    if (filters[filterKey] === null) {
      return acc
    }

    return getFilterAsUrlString(acc, idx, filters, filterKey)
  }, '&')
