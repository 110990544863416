import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectInspectionFeature = (state: State) => state.car.currentCarInspection

export const selectCurrentInspection =
  createSelector(selectInspectionFeature, Inspection => Inspection.currentInspection)

export const selectCurrentInspections =
  createSelector(selectInspectionFeature, Inspection => Inspection.currentInspections)

export const selectCurrentInspectionsPage =
  createSelector(selectInspectionFeature, Inspection => Inspection.currentPage)
