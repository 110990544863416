import { buildUrl } from 'actff-bo-lib/global'
import { NotificationType, NotificationTypeComponentMap } from 'actff-bo-lib/notification'
import { history } from 'actff-bo-lib/router'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'

type NotificationParams = {
  readonly type: NotificationType,
  readonly uuid?: string,
}

type Props = RouteComponentProps<NotificationParams>

class NotificationHandlerComponent extends React.Component<Props> {
  public componentDidMount(): void {
    const { type, uuid } = this.props.match.params
    const path = NotificationTypeComponentMap.get(type)

    if (path) {
      const pathWithUuid = uuid ? path.replace(/(\/:\w+\/?)/, `/${uuid}`) : path

      history.replace(buildUrl(pathWithUuid))
    }
  }

  public render(): React.ReactNode {
    return null
  }
}

export const NotificationHandler = compose(withRouter)(NotificationHandlerComponent)
