import { Client, ClientBasicData, ClientStatus, formatPhoneNumber } from 'actff-bo-lib/client'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly client: Client | ClientBasicData,
}

export const ClientData: FC<Props> = ({ client }) => {
  const { t } = useTranslation()

  return client?.status !== ClientStatus.Deleted
    ? <>{client?.firstName} {client?.lastName} <br /> {formatPhoneNumber(client?.phone)}</>
    : <>{t('caption.noData')}</>
}
