import { WithStyledRow } from 'actff-bo-app/components/Table'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { selectDealerLocationByNdcName } from 'actff-bo-lib/dealership'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { NdcCar } from 'actff-bo-lib/ndc-car'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { NdcCarRowMenu } from './NdcCarRowMenu'

type Props = Testable & {
  readonly ndcCar: NdcCar,
}

export const NdcCarRow: FC<Props> = ({ ndcCar }) => {
  const route = createRoute(Paths.NdcCarViewBasicData, { vin: ndcCar.vin })
  const Td = TdDecorator(route)

  const dealerLocation = useSelector(selectDealerLocationByNdcName(ndcCar.ndcLocation))

  return (
    <WithStyledRow menu={<NdcCarRowMenu ndcCar={ndcCar} />} isStyled={false}>
      <Td>{ndcCar.ndcCarId}</Td>
      <Td>{ndcCar.vin}</Td>
      <Td>{ndcCar.registrationNumber}</Td>
      <Td>{ndcCar.ndcOwner?.name}</Td>
      <Td>{ndcCar.ndcOwner?.phone}</Td>
      <Td>{dealerLocation?.name}</Td>
    </WithStyledRow>
  )
}
