import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { Loader } from 'actff-bo-app/components/Loader'
import { WithPagination } from 'actff-bo-app/components/Table/Pagination'
import { PagedResult } from 'actff-bo-lib/api'
import {
  CrmInsuranceAction,
  OpportunityInsuranceId,
  OpportunityListItem,
  OpportunityListType,
  selectOpportunityListCurrentPage,
  selectOpportunityListData,
  selectOpportunityListExpandedState,
  selectOpportunityListLoading,
  selectOpportunityListTimeTo,
  selectOpportunitySearchPhrase,
} from 'actff-bo-lib/crm/insurance'
import { Path } from 'actff-bo-lib/menu'
import { Paths } from 'actff-bo-lib/menu/initial-menu'
import { State as GlobalState } from 'actff-bo-lib/store'
import { addDays, endOfToday, subDays } from 'date-fns'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'

import { OpportunityListDayOffset } from './OpportunityListDayOffset'
import { OpportunityTable } from './OpportunityTable'
import {
  OpportunityListExpandCollapseButton,
  OpportunityListHeader,
  OpportunityListHeaderContainer,
} from './OpportunityTableStyled'

type Props = {
  readonly title: string,
  readonly type: OpportunityListType,
  readonly changeTimeFrom?: boolean,
  readonly changeTimeTo?: boolean,
  readonly expandedOnLoad?: boolean,
  readonly searchPhrase?: string,
  readonly setSelectedAsProcessedUuid: (uuid: OpportunityInsuranceId) => void,
}

type StateToProps = {
  readonly currentPage: number,
  readonly expanded: boolean,
  readonly loading: boolean,
  readonly opportunities: PagedResult<OpportunityListItem> | null,
  readonly timeFrom?: Date,
  readonly timeTo?: Date,
}

type DispatchToProps = {
  readonly getOpportunities: () => void,
  readonly onPageChange: (page: number) => void,
  readonly onTimeFromChange: (date: Date) => void,
  readonly onTimeToChange: (date: Date) => void,
  readonly setListExpandedState: (expanded: boolean) => void,
}

type OpportunityListProps = Props & StateToProps & DispatchToProps & WithTranslation

class OpportunityListComponent extends React.Component<OpportunityListProps> {

  public componentDidMount(): void {
    this.props.getOpportunities()
  }

  // tslint:disable-next-line cyclomatic-complexity
  public render(): React.ReactNode {
    const {
      changeTimeFrom,
      changeTimeTo,
      currentPage,
      expanded,
      loading,
      onPageChange,
      opportunities,
      timeFrom,
      timeTo,
      title,
      type,
      t,
      searchPhrase,
      setSelectedAsProcessedUuid,
    } = this.props

    const shouldExpandList = searchPhrase !== '' ? true : expanded

    return (
      <>
        <OpportunityListHeaderContainer>
          <OpportunityListHeader>
            {changeTimeFrom && <OpportunityListDayOffset currentDate={timeFrom} onSubmit={this.handleTimeFromChange} />}
            {t(title)}
            {changeTimeTo && (
              <>
                <OpportunityListDayOffset currentDate={timeTo} onSubmit={this.handleTimeToChange} />
                {t('caption.days')}
              </>
            )}
          </OpportunityListHeader>
          <OpportunityListExpandCollapseButton>
            <ExpandCollapseButton expanded={shouldExpandList} onClick={this.toggleExpanded} />
          </OpportunityListExpandCollapseButton>
        </OpportunityListHeaderContainer>
        {shouldExpandList && (
          loading
            ? <Loader />
            : opportunities && opportunities.result.length > 0 && (
              <WithPagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageCount={opportunities.noOfPages}
                path={Paths.CrmInsuranceOpportunityList as Path}
                rowsInCurrentPage={10} // tslint:disable-line no-magic-numbers
              >
                <OpportunityTable opportunities={opportunities} type={type} setSelectedAsProcessedUuid={setSelectedAsProcessedUuid} />
              </WithPagination>
            )
        )}
      </>
    )
  }

  private readonly toggleExpanded = () => {
    const { setListExpandedState, expanded } = this.props

    setListExpandedState(!expanded)
  }
  private readonly handleTimeFromChange = (dateOffset: number) => { this.props.onTimeFromChange(subDays(endOfToday(), dateOffset)) }
  private readonly handleTimeToChange = (dateOffset: number) => { this.props.onTimeToChange(addDays(endOfToday(), dateOffset)) }
}

const mapStateToProps: MapStateToProps<StateToProps, Props, GlobalState> = (state, { type }) => ({
  currentPage: selectOpportunityListCurrentPage(type)(state),
  expanded: selectOpportunityListExpandedState(type)(state),
  loading: selectOpportunityListLoading(type)(state),
  opportunities: selectOpportunityListData(type)(state),
  searchPhrase: selectOpportunitySearchPhrase(state),
  timeFrom: selectOpportunityListTimeTo(type)(state),
  timeTo: selectOpportunityListTimeTo(type)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, Props> = (dispatch, { type }) => ({
  getOpportunities: () => { dispatch(CrmInsuranceAction.getOpportunities(type))},
  onPageChange: (nextPage: number) => { dispatch(CrmInsuranceAction.changeOpportunityListCurrentPage(nextPage, type))},
  onTimeFromChange: (date: Date) => { dispatch(CrmInsuranceAction.changeOpportunityListTimeFrom(date, type))},
  onTimeToChange: (date: Date) => { dispatch(CrmInsuranceAction.changeOpportunityListTimeTo(date, type))},
  setListExpandedState: (expanded: boolean) => {
    dispatch(CrmInsuranceAction.changeOpportunityListExpandedState(expanded, type))
  },
})

export const OpportunityList = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(OpportunityListComponent)
