import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu'
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  readonly expanded: boolean,
  readonly onClick: () => void,
}

const NewThreadIcon = styled(Icon)`
  cursor: pointer;
`

export const ChatNewThreadButton: React.FC<Props> = ({ expanded, onClick }) => (
  <NewThreadIcon onClick={onClick} type={expanded ? IconType.ChatOpenExpanded : IconType.ChatOpen} />
)
