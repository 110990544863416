import { CarInfo } from 'actff-bo-lib/car'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly active: boolean,
  readonly car: CarInfo,
  readonly fullHeight?: boolean,
  readonly numeral?: number,
}

type ContainerProps = {
  readonly fullHeight?: boolean,
}

type RowProps = {
  readonly active: boolean,
  readonly highlighted: boolean,
}

const Container = styled.div<ContainerProps>`
  border-radius: 4px;
  border: ${({ active }: Props) => active ? `2px solid ${colors.straw}` : `1px solid ${colors.mystic}`};
  cursor: pointer;
  display: grid;
  font-weight: 600;
  grid-auto-flow: row;
  height: ${({ fullHeight = false }: ContainerProps) => fullHeight ? '100%' : 'initial'};
  transition: background-color .3s ease;
`

const Header = styled.span`
  color: ${colors.kumera};
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  transition: color, text-decoration .3s ease;

  &:hover {
    color: ${colors.darkerYellowOrange};
    text-decoration: underline;
  }
`

const Row = styled.div`
  background: ${({ active, highlighted }: RowProps) =>
    highlighted
      ? active
        ? colors.sprintWoodGold
        : colors.athensGray
      : 'default'
  }
  cursor: pointer;
  display: grid;
  grid-auto-flow: row;
  padding: 12px 24px;
`

export const CarCard: React.FC<Props> = ({ active, car, fullHeight = false, numeral, testId }) => {
  const { t } = useTranslation()
  const { uuid, wilsonCarUuid, vin } = car

  const handleCarClick = () => {
    if (car.uuid || car.wilsonCarUuid) {
      history.push(createRoute(Paths.CarViewService, { carId: uuid || wilsonCarUuid }))

      return
    }
    history.push(createRoute(Paths.NdcCarViewBasicData, { vin }))
  }

  return (
    <Container active={active} fullHeight={fullHeight} onClick={handleCarClick}>
      <Row><Header data-testid={`${testId}car-header`}>{t('carCard.header')} {numeral}</Header></Row>
      <Row active={active} highlighted={true} data-testid={`${testId}registration-number`}>
        <label>{t('carCard.registrationNumber')}:</label>
        {car.registrationNumber}
      </Row>
      <Row data-testid={`${testId}mileage`}>
        <label>{t('carCard.mileage')}:</label>
        {car.knownMileage}
      </Row>
      <Row data-testid={`${testId}vin`}>
        <label>{t('carCard.vin')}:</label>
        {car.vin}
      </Row>
      <Row data-testid={`${testId}model`}>
        <label>{t('carCard.model')}:</label>
        {car.model}
      </Row>
      {car.alias && (
        <Row data-testid={`${testId}alias`}>
          <label>{t('carCard.alias')}:</label>
          {car.alias}
        </Row>
      )}
      <Row data-testid={`${testId}production-year`}>
        <label>{t('carCard.productionYear')}:</label>
        {car.productionYear}
      </Row>
    </Container>
  )
}
