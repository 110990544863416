/* tslint:disable: no-object-mutation disable for redux-toolkit */
import { createSlice } from '@reduxjs/toolkit'

export type UIState = {
  readonly activePopupElementKey: string | null,
}

const initialState: UIState = {
  activePopupElementKey: null,
}

export const uiSlice = createSlice({
  initialState,
  name: 'ui',
  reducers: {
    setActivePopupElementKey: (state, action) => {
      state.activePopupElementKey = action.payload
    },
  },
})

export const {
  setActivePopupElementKey,
} = uiSlice.actions

export default uiSlice.reducer
