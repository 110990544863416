import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'
import { UserId, UserPermissions, UserWithoutPermissions } from './dto'

export const selectUserFeature = (state: State) => state.user

export const selectUserPermissions = createSelector(
  selectUserFeature,
  user => user.me ? user.me.permissions : [UserPermissions.Unauthorized],
)

export const selectMe = createSelector(selectUserFeature, user => user.me)

export const selectMeWithoutPermissions = createSelector(selectUserFeature, user => {
  if (user.me) {
    const { disabled, permissions, ...rest } = user.me

    return rest as UserWithoutPermissions
  }

  return null
})

export const selectMeInitialized = createSelector(selectUserFeature, user => user.meInitialized)

export const selectUsersWithoutMe = createSelector(selectUserFeature, user =>
  user.userList.data ? ({
    ...user.userList,
    data: user.userList.data.filter(u => u.uuid !== user.me?.uuid),
  }) : user.userList,
)

export const selectUsers = createSelector(selectUserFeature, user => user.userList)

export const selectUser = (userId: UserId) =>
  createSelector(selectUserFeature, user => user.userList.data?.find(u => u.uuid === userId))
