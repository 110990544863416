import { Loadable } from 'actff-bo-lib/global'

import { CrmInsuranceInsurerAction, CrmInsuranceInsurerActionType } from '../actions/insurer'
import { BankAccount, Insurer } from '../dto/payments'

export type CrmInsuranceInsurerState = {
  readonly bankAccountsForInsurer: Loadable<ReadonlyArray<BankAccount>>,
  readonly insurers: Loadable<ReadonlyArray<Insurer>>,
}

export const initialInsurerState: CrmInsuranceInsurerState = {
  bankAccountsForInsurer: {
    data: null,
    loading: false,
  },
  insurers: {
    data: [],
    loading: false,
  },
}

// tslint:disable-next-line cyclomatic-complexity
export const crmInsuranceInsurerReducer = (
  state: CrmInsuranceInsurerState = initialInsurerState,
  action: CrmInsuranceInsurerAction,
): CrmInsuranceInsurerState => {
  switch (action.type) {
    case CrmInsuranceInsurerActionType.GetBankAccountNumbersForInsurer:
      return ({
        ...state,
        bankAccountsForInsurer: {
          data: null,
          loading: true,
        },
      })

    case CrmInsuranceInsurerActionType.GetBankAccountNumbersForInsurerSuccess:
      return ({
        ...state,
        bankAccountsForInsurer: {
          data: action.payload,
          loading: false,
        },
      })

    case CrmInsuranceInsurerActionType.GetBankAccountNumbersForInsurerFailure:
      return ({
        ...state,
        bankAccountsForInsurer: {
          data: null,
          loading: false,
        },
      })

    case CrmInsuranceInsurerActionType.GetInsurers:
      return ({
        ...state,
        insurers: {
          data: null,
          loading: true,
        },
      })

    case CrmInsuranceInsurerActionType.GetInsurersSuccess:
      return ({
        ...state,
        insurers: {
          data: action.payload,
          loading: false,
        },
      })

    case CrmInsuranceInsurerActionType.GetInsurersFailure:
      return ({
        ...state,
        insurers: {
          data: null,
          loading: false,
        },
      })

    default:
      return state
  }
}
