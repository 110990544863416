import { api, PagedResult } from 'actff-bo-lib/api'
import { CarId } from 'actff-bo-lib/car'
import { Attachment, buildUrl, getSimpleFiltersAsObject } from 'actff-bo-lib/global'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { WithDealershipFilters } from '../dealership'

import {
  createMessage,
  createThread,
  Message,
  MessagePayload,
  Thread,
  ThreadBasicData,
  ThreadId,
  ThreadStatus,
} from './dto'

const createFormData = (payload: any, attachments?: ReadonlyArray<Attachment>): FormData => { // tslint:disable-line no-any
  const body = new FormData()

  if (payload.message || payload.status === ThreadStatus.CLOSED) {
    body.append('body', JSON.stringify(payload))
  }

  if (attachments) {
    attachments.forEach(({ file }) => {
      body.append('file', file)
    })
  }

  return body
}

export const getThreadList = (page: number, filters?: WithDealershipFilters): Observable<PagedResult<Thread>> =>
  api.get<PagedResult<Thread>>(
    buildUrl('/chat/list', {
      page,
      size: 25,
      ...getSimpleFiltersAsObject({ locationKey: filters?.locationFilters, brand: filters?.brandFilters }),
    }))
    .pipe(
      map(response => ({
        ...response,
        result: response.result.map(createThread),
      })),
    )

export const getMessages = (threadId: ThreadId) => api.get<ReadonlyArray<Message>>(`/chat/window/${threadId}`)
  .pipe(
    map(response => response.map(createMessage)),
  )

export const updateChatThread = (threadId: ThreadId, body: Partial<Thread>) =>
  api.patch(`/chat/window/${threadId}`, createFormData(body), undefined, true)

export const searchThreads = (phrase: string) => api.get<ReadonlyArray<ThreadBasicData>>(`/chat/list/all?searchText=${phrase}`)

export const createNewThread = (carId: CarId, messagePayload: MessagePayload) => {
  const { attachments, ...payload} = messagePayload

  return api.post(`/chat/window/${carId}`, createFormData(payload, attachments), undefined, true)
}

export const sendChatConversationMessageWithAttachments = (threadId: ThreadId, messagePayload: MessagePayload) => {
  const { attachments, ...payload} = messagePayload

  return api.patch(`/chat/window/${threadId}`, createFormData(payload, attachments), undefined, true)
}
