import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { UserId } from 'actff-bo-lib/user'

export enum DebugToolsActionType {
  IdentifyLogrocket = '[DEBUG] IDENTIFY LOGROCKET',

  InitLogrocket = '[DEBUG] INIT LOGROCKET',
  InitLogrocketSuccess = '[DEBUG] INIT LOGROCKET SUCCESS',
}

export const DebugToolsAction = {
  identifyLogrocket: (userUuid: UserId) => createAction(DebugToolsActionType.IdentifyLogrocket, userUuid),
  initLogrocket: () => createAction(DebugToolsActionType.InitLogrocket),
  initLogrocketSuccess: () => createAction(DebugToolsActionType.InitLogrocketSuccess),
}

export type DebugToolsAction = ActionsUnion<typeof DebugToolsAction>
