import { CheckboxContainer } from 'actff-bo-app/components/Checkbox'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import {
  OpportunityInsuranceId,
  OpportunityListItem,
  OpportunityListType,
  OpportunitySourceAbbreviationsMap,
} from 'actff-bo-lib/crm/insurance'
import { defaultShortDateFormat } from 'actff-bo-lib/date'
import { selectDealerLocationByKey } from 'actff-bo-lib/dealership'
import { getTranslation } from 'actff-bo-lib/i18n'
import { createRoute } from 'actff-bo-lib/menu/helpers'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { selectMe, UserWithoutPermissions } from 'actff-bo-lib/user'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { colors, Tr as TrStyled, TrDanger, TrWarning } from 'styles'

import { insurerOtherOrValue } from '../../OpportunityView/insurer-option-mapper'
import { OpportunityAssign } from '../OpportunityAssign'
import { OpportunityRowPhone } from './OpportunityRowPhone'
import { OpportunityRowStatus } from './OpportunityRowStatus'

type Props = {
  readonly opportunity: OpportunityListItem,
  readonly rowComponent: typeof TrStyled | typeof TrDanger | typeof TrWarning,
  readonly opportunityListType: OpportunityListType,
  readonly userList: ReadonlyArray<UserWithoutPermissions> | null,
  readonly onSelectInsurance: (uuid: OpportunityInsuranceId) => void,
  readonly selectedInsuranceUuid: OpportunityInsuranceId | null,
}

const CenteredCheckbox = styled(CheckboxContainer)`
  margin-left: 6px;
  position: relative;
  top: 7px;
`

export const OpportunityRow: React.FC<Props> = ({
  opportunity,
  rowComponent: Tr,
  opportunityListType,
  onSelectInsurance,
  selectedInsuranceUuid,
  userList,
}) => {
  const { t } = useTranslation()
  const { assignee, actionDate, sourceName, status, failureReason, result, uuid } = opportunity

  const isSelectedAsProcessed = selectedInsuranceUuid === uuid

  const handleClick = () => {
    onSelectInsurance(uuid)
  }

  const bgColor = isSelectedAsProcessed ? colors.ulvaLactucaGreen : undefined

  const href = createRoute(Paths.CrmInsuranceOpportunity, { opportunityId: uuid })
  const Td = TdDecorator(href, true, handleClick, isSelectedAsProcessed, true)

  const getPhone = () => {
    const { phone, contactPhone, driverPhone } = opportunity

    return contactPhone || phone || driverPhone
  }

  const currentUser = useSelector(selectMe)
  const dealerLocation = useSelector(selectDealerLocationByKey(opportunity.locationKey))

  return (
    <>
      <Tr clickable={true} dangerStyleLimit={0} isStyled={true} bgColor={bgColor}>
        <Td>
          <OpportunityAssign
            assignee={assignee}
            currentUser={currentUser}
            opportunityId={uuid}
            opportunityListType={opportunityListType}
            userList={userList}
          />
        </Td>
        <Td>
          <OpportunityRowStatus failureReason={failureReason} result={result} status={status} />
        </Td>
        <Td><FormattedDate date={actionDate} format={defaultShortDateFormat} /></Td>
        <Td>{OpportunitySourceAbbreviationsMap.get(sourceName)}</Td>
        <Td>{opportunity.driverName} <OpportunityRowPhone phone={getPhone()} /></Td>
        <Td>
          <CenteredCheckbox>
            <input type='checkbox' defaultChecked={opportunity.inApp} readOnly={true} /><label>&nbsp;</label>
          </CenteredCheckbox>
        </Td>
        <Td>{opportunity.registrationNumber}</Td>
        <Td>{opportunity.productionYear}</Td>
        <Td>{opportunity.model}</Td>
        <Td>{dealerLocation?.name}</Td>
        <Td>{insurerOtherOrValue(t, opportunity.insurer)}</Td>
        <Td><FormattedDate date={opportunity.expires} format={defaultShortDateFormat} /></Td>
        <Td>{getTranslation(t, 'crmInsurance.insuranceSellType.', opportunity.sellType)}</Td>
        <Td>{getTranslation(t, 'crmInsurance.boughtAtShort.', opportunity.boughtAt)}</Td>
      </Tr>
    </>
  )
}
