import { YesNoCheckbox } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'

type Props = Testable & {
  readonly t: i18next.TFunction,
}

const Container = styled.div`
  display: grid;
  grid-area: car-in-dms;
`

export const CarInDms: React.FC<Props> = ({ testId, t }) => (
  <Container>
    <YesNoCheckbox
      darkLabel={false}
      inline={false}
      label={t('car.carInDms')}
      name='dms'
      testId={`${testId}car-in-dms`}
      uppercaseLabel={false}
    />
  </Container>
)
