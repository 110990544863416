import { FormValues } from '../form'

export const QuickCampaignPhoneNumbersRegexp = /\+(?:[/]?[0-9]){9,12}$/

export enum QuickCampaignChannel {
  INTERNAL = 'INTERNAL',
  PUSH = 'PUSH',
  SMS = 'SMS',
}

export type QuickCampaignSuccessParams = {
  readonly content: string,
  readonly count: number,
}

export type QuickCampaignDto = {
  readonly locationKeyBrands: FormValues,
  readonly channel: QuickCampaignChannel,
  readonly message: string,
  readonly phones: ReadonlyArray<string>,
}

export type CreateQuickCampaignResponseDto = {
  readonly clientsNotified: number,
}
