import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import {
  DatePickerInputField,
  Field,
  FormCell,
  FormCellFullWidth,
  FormCellWide,
  FormHeader,
  FormWrapper,
  InputField,
  PrimaryCell,
  SelectField,
  YesNoInput,
} from 'actff-bo-app/components/Form'
import { ValidationError } from 'actff-bo-app/components/ValidationError'
import { getBrandFuelTypes } from 'actff-bo-lib/admin/brands/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { checkIsCarRejected } from 'actff-bo-lib/car'
import { DatePickerInputPosition } from 'actff-bo-lib/date'
import { mapToOptions } from 'actff-bo-lib/global'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { Form, FormikProps } from 'formik'
import React, { FC } from 'react'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { compose } from 'redux'
import { HtmlElementEvent } from 'typings/html-element-event'

import { CarActionButtons } from '../CarActionButtons'
import { ImageViewer } from '../Styled'
import { CarDocumentsViewer } from './CarDocumentsViewer'
import { CarViewServiceFormProps, CarViewServiceFormValues, formik, vinRegexp } from './withFormik'

const testId = 'car-view-service-form__'

const CarViewServiceFormComponent: FC<CarViewServiceFormProps & FormikProps<CarViewServiceFormValues> & WithTranslation> = ({
  brakeLiquid,
  brakeLiquids,
  brand,
  car,
  checkVinExistence,
  checkRegistrationNumberExistence,
  setFieldValue,
  carAttachments,
  carModels,
  carVersions,
  currentCarVinExists,
  currentCarRegistrationNumberExists,
  dealerBrands,
  deleteCar,
  engineCode,
  engineCodes,
  errors,
  fuelType,
  handleSubmit,
  i18n,
  isNewCar,
  carModel,
  carVersion,
  engineOil,
  oilTypes,
  onCarModelChange,
  onCarVersionChange,
  onBrandChange,
  onBrakeLiquidChange,
  onEngineCodeChange,
  onFuelTypeChange,
  onOilTypeChange,
  rejectCar,
  revertCar,
  touched,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: fuelTypes } = useQuery([QueryKeys.GET_BRAND_FUEL_TYPES, brand], async () =>
    await getBrandFuelTypes([brand]), {
    onError: async () => {
      dispatch(displayFailureToast(
        'admin.brands.getFuelTypes',
        ToastActionType.GET,
        'admin.brands.fuelTypes.getFailureAlert.body',
      ))
    },
    refetchOnWindowFocus: false,
    retry: false,
  })

  const handleVinChange = (event: HtmlElementEvent<string>) => {
    const { value } = event.target
    setFieldValue('vin', value)
    if (new RegExp(vinRegexp).test(value)) {
      checkVinExistence(car.uuid, value)
    }
  }

  const handleBrandChange = (_brand: string) => {
    onBrandChange(_brand)
    setFieldValue('fuel', null)
  }

  const handleRegistrationNumberChange = (event: HtmlElementEvent<string>) => {
    const { value } = event.target
    setFieldValue('registration', value)
    checkRegistrationNumberExistence(car.uuid, value)
  }

  const getVinValidation = () => currentCarVinExists && t('carView.form.update.failure.vinExists')

  const getRegistrationNumberValidation = () => currentCarRegistrationNumberExists &&
    t('carView.form.update.failure.registrationNumberExists')

  const getFuelFormFieldValue = () => {
    if (!fuelType) {
      return t('car.fuelType.na')
    }

    if (!i18n.exists(`car.fuelType.${fuelType.toLowerCase()}`)) {
      return fuelType
    }

    return t(`car.fuelType.${fuelType.toLowerCase()}`)
  }

  return (
    <Form>
      <FormWrapper>
        <FormCellWide>
          <YesNoInput label={t('carView.form.DMS')} name='dms' testId={`${testId}dms`} />
          <ValidationError name='dms' testId={`${testId}dms--error`} />
        </FormCellWide>
        {isNewCar
          ? <ImageViewer />
          : <CarDocumentsViewer carAttachments={carAttachments}/>
        }
        <FormCell slim={true}>
          <FormHeader>{t('carView.form.carDetails')}</FormHeader>
        </FormCell>
        <FormCell>
          <label htmlFor='brand'>{t('carView.form.brand')}</label>
          <Field
            component={SelectField}
            error={errors.brand}
            id='brand'
            name='brand'
            onChange={handleBrandChange}
            options={mapToOptions(dealerBrands.data)}
            testId={`${testId}brand`}
            touched={touched.brand}
            type='text'
            value={brand}
          />
          <ValidationError name='brand' testId={`${testId}brand--error`} />
        </FormCell>
        <FormCell>
          <label htmlFor='registration'>{t('carView.form.registration')}</label>
          <Field
            component={InputField}
            error={errors.registration}
            id='registration'
            name='registration'
            onBlur={handleRegistrationNumberChange}
            onChange={handleRegistrationNumberChange}
            testId={`${testId}registration`}
            touched={touched.registration}
            type='text'
            validate={getRegistrationNumberValidation}
          />
          <ValidationError name='registration' testId={`${testId}registration--error`} />
        </FormCell>
        <FormCell>
          <label htmlFor='vin'>{t('carView.form.vin')}</label>
          <Field
            component={InputField}
            error={errors.vin}
            id='vin'
            name='vin'
            onBlur={handleVinChange}
            onChange={handleVinChange}
            testId={`${testId}vin`}
            touched={touched.vin}
            type='text'
            validate={getVinValidation}
          />
          <ValidationError name='vin' testId={`${testId}vin-error`} />
        </FormCell>
        <FormCell>
          <Field
            component={SelectField}
            error={errors.carModel}
            id='carModel'
            label={t('carView.form.carModel')}
            name='carModel'
            onChange={onCarModelChange}
            options={carModels}
            testId={`${testId}car-model`}
            touched={touched.carModel}
            value={carModel}
          />
          <ValidationError name='carModel' testId={`${testId}car-model--error`} />
        </FormCell>
        <FormCell>
          <Field
            component={SelectField}
            error={errors.carVersion}
            id='carVersion'
            label={t('carView.form.carVersion')}
            name='carVersion'
            options={carVersions}
            onChange={onCarVersionChange}
            testId={`${testId}car-version`}
            touched={touched.carVersion}
            value={carVersion}
          />
        </FormCell>
        <FormCell>
          <label htmlFor='firstRegistrationDate'>{t('carView.form.firstRegistrationDate')}</label>
          <Field
            component={DatePickerInputField}
            error={errors.firstRegistrationDate}
            id='firstRegistrationDate'
            name='firstRegistrationDate'
            testId={`${testId}first-registration-date`}
            touched={touched.firstRegistrationDate}
          />
          <ValidationError name='firstRegistrationDate' testId={`${testId}first-registration-date--error`} />
        </FormCell>
        <FormCell>
          <label htmlFor='engineCode'>{t('carView.form.engineCode')}</label>
          <Field
            component={SelectField}
            error={errors.engineCode}
            id='engineCode'
            name='engineCode'
            onChange={onEngineCodeChange}
            options={engineCodes}
            data-testid={`${testId}engine-code`}
            touched={touched.engineCode}
            value={engineCode}
          />
          <ValidationError name='engineCode' testId={`${testId}engine-code--error`} />
        </FormCell>
        <FormCell>
          <FormHeader>{t('carView.form.service.technicalExamination')}</FormHeader>
        </FormCell>
        <FormCell>
          <FormHeader>{t('carView.form.service.carService')}</FormHeader>
        </FormCell>
        <FormCell>
          <label htmlFor='mot'>{t('carView.form.service.mot')}</label>
          <Field touched={touched.mot} error={errors.mot} id='mot' name='mot' component={DatePickerInputField} testId={`${testId}mot`} />
        </FormCell>
        <FormCell>
          <label htmlFor='lastServiceDate'>{t('carView.form.service.lastServiceDate')}</label>
          <Field
            component={DatePickerInputField}
            error={errors.lastServiceDate}
            id='lastServiceDate'
            name='lastServiceDate'
            testId={`${testId}last-service-date`}
            touched={touched.lastServiceDate}
          />
        </FormCell>
        <FormCellFullWidth>
          <FormHeader>{t('carView.form.service.liquids')}</FormHeader>
        </FormCellFullWidth>
        <FormCell>
          <label>{t('carView.form.service.brakeLiquid')}</label>
          <Field
            component={SelectField}
            id='brakeLiquid'
            name='brakeLiquid'
            onChange={onBrakeLiquidChange}
            options={mapToOptions(brakeLiquids)}
            testId={`${testId}brake-liquid`}
            value={brakeLiquid}
          />
        </FormCell>
        <FormCell>
          <label htmlFor='engineOil'>{t('carView.form.service.engineOil')}</label>
          <Field
            component={SelectField}
            id='engineOil'
            name='engineOil'
            onChange={onOilTypeChange}
            options={oilTypes}
            testId={`${testId}engine-oil`}
            value={engineOil}
          />
        </FormCell>
        <FormCell>
          <label htmlFor='fuel'>{t('carView.form.service.fuel')}</label>
          <Field
            component={SelectField}
            id='fuel'
            name='fuel'
            onChange={onFuelTypeChange}
            options={mapToOptions(fuelTypes || [])}
            testId={`${testId}fuel`}
            value={getFuelFormFieldValue()}
          />
          <ValidationError name='fuel' testId={`${testId}fuel--error`} />
        </FormCell>
        <FormCell/>
        <FormCellFullWidth>
          <FormHeader>{t('carView.form.service.guarantee')}</FormHeader>
        </FormCellFullWidth>
        <FormCell>
          <label htmlFor='carReleaseDate'>{t('carView.form.service.carReleaseDate')}</label>
          <Field
            id='carReleaseDate'
            name='carReleaseDate'
            touched={touched.carReleaseDate}
            error={errors.carReleaseDate}
            component={DatePickerInputField}
            position={DatePickerInputPosition.TOP}
            testId={`${testId}car-release-date`}
          />
          <ValidationError name='carReleaseDate' testId={`${testId}car-release-date--error`} />
        </FormCell>
        <FormCell>
          <label htmlFor='basicGuaranteeExpirationDate'>{t('carView.form.service.basicGuaranteeExpirationDate')}</label>
          <Field
            component={DatePickerInputField}
            error={errors.basicGuaranteeExpirationDate}
            id='basicGuaranteeExpirationDate'
            name='basicGuaranteeExpirationDate'
            position={DatePickerInputPosition.TOP}
            testId={`${testId}basic-guarantee-expiration-date`}
            touched={touched.basicGuaranteeExpirationDate}
          />
          <ValidationError name='basicGuaranteeExpirationDate' testId={`${testId}basic-guarantee-expiration-date--error`} />
        </FormCell>
        <FormCell>
          <label htmlFor='extendedGuaranteeExpirationDate'>{t('carView.form.service.extendedGuaranteeExpirationDate')}</label>
          <Field
            component={DatePickerInputField}
            error={errors.extendedGuaranteeExpirationDate}
            id='extendedGuaranteeExpirationDate'
            name='extendedGuaranteeExpirationDate'
            position={DatePickerInputPosition.TOP}
            testId={`${testId}extended-guarantee-expiration-date`}
            touched={touched.extendedGuaranteeExpirationDate}
          />
        </FormCell>
        <PrimaryCell>
          <FormCell>
            <label htmlFor='dealerGuaranteeExpirationDate'>{t('carView.form.service.dealerGuaranteeExpirationDate')}</label>
            <Field
              component={DatePickerInputField}
              error={errors.dealerGuaranteeExpirationDate}
              id='dealerGuaranteeExpirationDate'
              name='dealerGuaranteeExpirationDate'
              position={DatePickerInputPosition.TOP}
              testId={`${testId}dealer-guarantee-expiration-date`}
              touched={touched.dealerGuaranteeExpirationDate}
            />
          </FormCell>
          <FormCell>
            <YesNoInput
              label={t('carView.form.service.dealerGuaranteeVisible')}
              name='dealerGuaranteeVisible'
              testId={`${testId}dealer-guarantee-visible`}
            />
          </FormCell>
        </PrimaryCell>
      </FormWrapper>
      <FixedFooter>
        <CarActionButtons
          carId={car.uuid}
          inRejectedMode={checkIsCarRejected(car)}
          onAccept={handleSubmit}
          onDelete={deleteCar}
          onReject={rejectCar}
          onRevert={revertCar}
          testId={testId}
        />
      </FixedFooter>
    </Form>
  )
}

// tslint:disable-next-line:max-file-line-count TODO refactor the file by splitting to smaller pieces
export const CarViewServiceForm = compose(formik, withTranslation())(CarViewServiceFormComponent)
