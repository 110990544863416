import * as t from 'io-ts'

export enum CommunicationsMethod {
  PUSH_NOTIFICATIONS = 'pushNotifications',
  SMS = 'sms',
  EMAIL = 'email',
  GENERAL = 'general',
}

export enum CommunicationsTopic {
  MARKETING = 'marketingOffers',
  TRADE = 'tradeOffers',
  PERSONAL = 'personalData',
  PROFILE = 'profile',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
}

export type AgreementUpdate = {
  readonly document?: string,
  readonly content?: string,
  readonly method: CommunicationsMethod,
  readonly topic: CommunicationsTopic,
}

export type RegulationsAndApprovalsForm = {
  readonly termsAndConditions: string,
  readonly personalData: string,
}

export const DealerInsurerOutput = t.type({
  insurerName: t.string,
  main: t.boolean,
})

export const DealerInsurersOutput = t.array(DealerInsurerOutput)

export type DealershipInsurer = t.TypeOf<typeof DealerInsurerOutput>

export type InsurerQuestion = {
  readonly uuid: string | null,
  readonly question: string;
}

export type InsurerQuestions = {
  readonly questions: ReadonlyArray<InsurerQuestion>,
}
