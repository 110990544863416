import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { Car, CarId } from 'actff-bo-lib/car'
import { AjaxError } from 'rxjs/ajax'
import { WithDealershipFilters } from '../dealership'

import { Message, MessagePayload, Thread, ThreadBasicData, ThreadId } from './dto'

export enum ChatActionType {
  GetChatConversationCar = '[CHAT] GET CONVERSATION CAR',
  GetChatConversationCarFailure = '[CHAT] GET CONVERSATION CAR FAILURE',
  GetChatConversationCarSuccess = '[CHAT] GET CONVERSATION CAR SUCCESS',

  ChangeDealershipFilters = '[CHAT] CHANGE DEALERSHIP FILTERS',

  GetChatConversationMessages = '[CHAT] GET CONVERSATION MESSAGES',
  GetChatConversationMessagesFailure = '[CHAT] GET CONVERSATION MESSAGES FAILURE',
  GetChatConversationMessagesSuccess = '[CHAT] GET CONVERSATION MESSAGES SUCCESS',

  ConnectChatConversationSocket = '[CHAT] CONNECT CHAT CONVERSATION SOCKET',
  DisconnectChatConversationSocket = '[CHAT] DISCONNECT CHAT CONVERSATION SOCKET',
  ReceivedChatConversationMessage = '[CHAT] RECEIVED CONVERSATION MESSAGE',

  SendChatConversationMessage = '[CHAT] SEND CHAT CONVERSATION MESSAGE',
  SendChatConversationMessageFailure = '[CHAT] SEND CHAT CONVERSATION MESSAGE FAILURE',
  SendChatConversationMessageSuccess = '[CHAT] SEND CHAT CONVERSATION MESSAGE SUCCESS',

  ConnectChatThreadListSocket = '[CHAT] CONNECT CHAT THREADS SOCKET',
  DisconnectChatThreadListSocket = '[CHAT] DISCONNECT CHAT THREADS SOCKET',
  ReceivedChatThreadUpdate = '[CHAT] RECEIVED CHAT THREAD UPDATE',

  GetThreads = '[CHAT] GET THREADS',
  GetThreadsFailure = '[CHAT] GET THREADS FAILURE',
  GetThreadsSuccess = '[CHAT] GET THREADS SUCCESS',

  LoadMoreMessages = '[CHAT] LOAD MORE MESSAGES',
  LoadMoreMessagesFailure = '[CHAT] LOAD MORE MESSAGES FAILURE',
  LoadMoreMessagesSuccess = '[CHAT] LOAD MORE MESSAGES SUCCESS',

  LoadMoreThreads = '[CHAT] LOAD MORE THREADS',
  LoadMoreThreadsFailure = '[CHAT] LOAD MORE THREADS FAILURE',
  LoadMoreThreadsSuccess = '[CHAT] LOAD MORE THREADS SUCCESS',

  ClearActiveThread = '[CHAT] CLEAR ACTIVE THREAD',
  SelectActiveThread = '[CHAT] SELECT ACTIVE THREAD',

  UpdateChatThread = '[CHAT] UPDATE CHAT THREAD',
  UpdateChatThreadFailure = '[CHAT] UPDATE CHAT THREAD FAILURE',
  UpdateChatThreadSuccess = '[CHAT] UPDATE CHAT THREAD SUCCESS',

  ResetSearchThreadResults = '[CHAT] RESET SEARCH THREAD RESULTS',
  SearchThreads = '[CHAT] SEARCH THREADS',
  SearchThreadsFailure = '[CHAT] SEARCH THREADS FAILURE',
  SearchThreadsSuccess = '[CHAT] SEARCH THREADS SUCCESS',

  CreateNewThread = '[CHAT] CREATE NEW THREAD',
  CreateNewThreadFailure = '[CHAT] CREATE NEW THREAD FAILURE',
  CreateNewThreadSuccess = '[CHAT] CREATE NEW THREAD SUCCESS',
  StartNewThread = '[CHAT] START NEW THREAD',
}

export const ChatAction = {
  getChatConversationCar: (carId: CarId) => createAction(ChatActionType.GetChatConversationCar, carId),
  getChatConversationCarFailure: (error: AjaxError) => createAction(ChatActionType.GetChatConversationCarFailure, error),
  getChatConversationCarSuccess: (car: Car) => createAction(ChatActionType.GetChatConversationCarSuccess, car),

  getChatConversationMessages: (threadId: ThreadId) => createAction(ChatActionType.GetChatConversationMessages, threadId),
  getChatConversationMessagesFailure: (error: AjaxError) => createAction(ChatActionType.GetChatConversationMessagesFailure, error),
  getChatConversationMessagesSuccess: (messages: ReadonlyArray<Message>) =>
    createAction(ChatActionType.GetChatConversationMessagesSuccess, messages),

  changeDealershipFilters: (dealershipFilters: WithDealershipFilters) =>
    createAction(ChatActionType.ChangeDealershipFilters, dealershipFilters),

  connectChatConversationSocket: (threadId: ThreadId) => createAction(ChatActionType.ConnectChatConversationSocket, threadId),
  disconnectChatConversationSocket: (threadId: ThreadId) => createAction(ChatActionType.DisconnectChatConversationSocket, threadId),
  receivedChatConversationMessage: (message: Message) => createAction(ChatActionType.ReceivedChatConversationMessage, message),

  sendChatConversationMessage: (messagePayload: MessagePayload, threadId: ThreadId) =>
    createAction(ChatActionType.SendChatConversationMessage, { messagePayload, threadId }),
  sendChatConversationMessageFailure: (error: AjaxError) => createAction(ChatActionType.SendChatConversationMessageFailure, error),
  sendChatConversationMessageSuccess: () => createAction(ChatActionType.SendChatConversationMessageSuccess),

  connectChatThreadsSocket: () => createAction(ChatActionType.ConnectChatThreadListSocket),
  disconnectChatThreadsSocket: () => createAction(ChatActionType.DisconnectChatThreadListSocket),
  receivedChatThreadUpdate: (thread: Thread) => createAction(ChatActionType.ReceivedChatThreadUpdate, thread),

  getThreads: () => createAction(ChatActionType.GetThreads),
  getThreadsFailure: (error: AjaxError) => createAction(ChatActionType.GetThreadsFailure, error),
  getThreadsSuccess: (threads: PagedResult<Thread>) => createAction(ChatActionType.GetThreadsSuccess, threads),

  loadMoreMessages: () => createAction(ChatActionType.LoadMoreMessages),
  loadMoreMessagesFailure: (error: AjaxError) => createAction(ChatActionType.LoadMoreMessagesFailure, error),
  loadMoreMessagesSuccess: (messages: ReadonlyArray<Message>) => createAction(ChatActionType.LoadMoreMessagesSuccess, messages),

  loadMoreThreads: () => createAction(ChatActionType.LoadMoreThreads),
  loadMoreThreadsFailure: (error: AjaxError) => createAction(ChatActionType.LoadMoreThreadsFailure, error),
  loadMoreThreadsSuccess: (threads: PagedResult<Thread>) => createAction(ChatActionType.LoadMoreThreadsSuccess, threads),

  clearActiveThread: () => createAction(ChatActionType.ClearActiveThread),
  selectActiveThread: (threadId: ThreadId) => createAction(ChatActionType.SelectActiveThread, threadId),

  updateChatThread: (threadId: ThreadId, body: Partial<Thread>) => createAction(ChatActionType.UpdateChatThread, { threadId, body }),
  updateChatThreadFailure: (error: AjaxError) => createAction(ChatActionType.UpdateChatThreadFailure, error),
  updateChatThreadSuccess: (thread: Thread) => createAction(ChatActionType.UpdateChatThreadSuccess, thread),

  resetSearchThreadResults: () => createAction(ChatActionType.ResetSearchThreadResults),
  searchThreads: (phrase: string) => createAction(ChatActionType.SearchThreads, phrase),
  searchThreadsFailure: (error: AjaxError) => createAction(ChatActionType.SearchThreadsFailure, error),
  searchThreadsSuccess: (results: ReadonlyArray<ThreadBasicData>) => createAction(ChatActionType.SearchThreadsSuccess, results),

  createNewThread: (carId: CarId, messagePayload: MessagePayload) =>
    createAction(ChatActionType.CreateNewThread, { carId, messagePayload }),
  createNewThreadFailure: (error: AjaxError) => createAction(ChatActionType.CreateNewThreadFailure, error),
  createNewThreadSuccess: (thread: Thread) => createAction(ChatActionType.CreateNewThreadSuccess, thread),
  startNewThread: (thread: ThreadBasicData) => createAction(ChatActionType.StartNewThread, thread),
}

export type ChatAction = ActionsUnion<typeof ChatAction>
