import { PagedResult } from 'actff-bo-lib/api'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { OpportunityPurchase, OpportunityPurchaseFilterValues, OpportunitySale } from 'actff-bo-lib/crm/trade/dto'
import { Filters, Loadable } from 'actff-bo-lib/global'
import { UserId } from 'actff-bo-lib/user'
import { addDays, endOfToday } from 'date-fns'

import { DetailedFiltersFormValues, initialDetailedFiltersFormValues } from './filters'
import { initialStatusFilters } from './initial-status-filters'

export type OpportunityPurchaseList = {
  readonly [key in keyof typeof OpportunityListType]: {
    readonly from?: Date,
    readonly to?: Date,
    readonly currentPage: number,
    readonly list: Loadable<PagedResult<OpportunityPurchase>>,
  }
}

export type OpportunityPurchaseState = OpportunityPurchaseList & {
  readonly currentOpportunity: OpportunityPurchase | null,
  readonly currentMatchedSaleOpportunities: ReadonlyArray<OpportunitySale>,
  readonly assignmentFilter: UserId | null,
  readonly detailedFiltersFormValues: DetailedFiltersFormValues | null,
  readonly stringifiedDetailedFilters: string | null,
  readonly statusFilters: Filters<OpportunityPurchaseFilterValues>,
  readonly searchPhrase?: string,
}

// tslint:disable object-literal-sort-keys no-magic-numbers
export const initialState: OpportunityPurchaseState = {
  currentOpportunity: null,
  currentMatchedSaleOpportunities: [],
  assignmentFilter: null,
  detailedFiltersFormValues: initialDetailedFiltersFormValues,
  stringifiedDetailedFilters: null,
  statusFilters: initialStatusFilters,
  overdue: {
    currentPage: 1,
    list: {
      loading: false,
      data: null,
    },
  },
  new: {
    currentPage: 1,
    list: {
      loading: false,
      data: null,
    },
  },
  future: {
    currentPage: 1,
    to: addDays(endOfToday(), 4),
    list: {
      loading: false,
      data: null,
    },
  },
  all: {
    currentPage: 1,
    list: {
      loading: false,
      data: null,
    },
  },
}
