import * as t from 'io-ts'

export type CarLiquids = {
  readonly oilCode: string,
  readonly engineCode: string,
  readonly fuelType: string,
}

export type CarLiquidsDto = CarLiquids & {
  readonly brand: string,
}

export type CarAssistance = {
  readonly assistanceName: string,
  readonly assistancePhoneNumber: string,
}

export type CarVersionMutationParams = {
  readonly brand: string | null,
  readonly versionName: string,
}

export const CarVersionsOutput = t.array(t.string)
