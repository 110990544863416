import { ErrorMessage } from '@hookform/error-message'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { AddingDialogContent, Table, Th } from 'actff-bo-app/components/EditableList/Styled'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Label } from 'actff-bo-app/components/Label'
import { InsurerQuestion } from 'actff-bo-lib/admin/dealership/dto'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, Tr } from 'styles'

import { Footer } from '../../Footer'
import { Container } from '../../Styled'
import { InsurersQuestionsRow } from './InsurersQuestionsRow'

type Props = {
  readonly onUpdate: (list?: ReadonlyArray<InsurerQuestion>) => void,
  readonly items?: ReadonlyArray<InsurerQuestion>,
}

type Form = {
  readonly questionContent: string,
}

export const Textarea = styled.textarea`
  border-radius: 3px;
  border: 1.5px solid ${colors.mysticGray};
  min-height: 100px;
  outline: none;
  resize: vertical;
  width: 100%;
`

export const StyledTable = styled(Table)`
  width: 75%;
`

const maxQuestionLength = 2000

export const InsurerQuestionsTable: FC<Props> = ({ onUpdate, items }) => {
  const { t } = useTranslation()

  const [formDirty, setFormDirty] = useState(false)

  const [questions, setQuestions] = useState<ReadonlyArray<InsurerQuestion>>(items || [])

  const [editNewItemOpen, setEditNewItemOpen] = useState(false)
  const [itemToEdit, setItemToEdit] = useState<InsurerQuestion | null>(null)

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<InsurerQuestion | null>(null)

  const { handleSubmit, errors, register, setValue, reset } = useForm<Form>({
    defaultValues: { questionContent: '' },
    mode: 'onBlur',
  })

  const toggleEditDialogOpen = () => { setEditNewItemOpen(!editNewItemOpen) }
  const toggleDeleteDialogOpen = () => { setDeleteDialogOpen(!deleteDialogOpen) }

  const handleDelete = (item: InsurerQuestion) => () => {
    setDeleteDialogOpen(true)
    setItemToDelete(item)
  }

  const onDeleteDialogConfirm = () => {
    const updatedList = questions?.filter(item => item.question !== itemToDelete?.question)
    setQuestions(updatedList)
    setItemToDelete(null)
    toggleDeleteDialogOpen()
    setFormDirty(true)
  }

  const onDeleteDialogClose = () => {
    setItemToDelete(null)
    toggleDeleteDialogOpen()
  }

  const handleEdit = (question: InsurerQuestion) => {
    toggleEditDialogOpen()
    setValue('questionContent', question?.question)
    setItemToEdit(question)
  }

  const resetForm = () => {
    reset()
    setItemToEdit(null)
    toggleEditDialogOpen()
  }

  const addNewQuestion = (question: string) => [ ...questions, { uuid: null, question }]

  const updateQuestion = (question: string) => {
    const editedQuestionIndex = questions?.findIndex(item => item.uuid === itemToEdit?.uuid)

    return questions?.map((item, index) => {
      if (index === editedQuestionIndex) {
        return { ...item, question }
      }

      return item
    })
  }

  const onEditDialogConfirm = (formValues: Form) => {
    if (!itemToEdit) {
      const updatedList = addNewQuestion(formValues.questionContent)
      setQuestions(updatedList)
    } else {
      const updatedList = updateQuestion(formValues.questionContent)
      setQuestions(updatedList)
    }
    setFormDirty(true)
    resetForm()
  }

  const handleAdd = () => {
    toggleEditDialogOpen()
  }

  const handleSave = () => {
    onUpdate(questions)
    setFormDirty(false)
  }

  return (
    <form>
      <Container>
        <StyledTable>
          <thead>
          <Tr>
            <Th>{t('caption.listNumber')}</Th>
            <Th>{t('table.columns.insurerQuestion')}</Th>
          </Tr>
          </thead>
          <tbody>
          {questions?.map((item, index) => (
            <InsurersQuestionsRow
              key={item.uuid}
              index={index}
              item={item}
              onDelete={handleDelete(item)}
              onEdit={handleEdit}
            />
          ))}
          </tbody>
        </StyledTable>
        <Footer
          onAddClick={handleAdd}
          onSave={handleSave}
          onAddTLabel='admin.dealership.insurersQuestions.add'
          isSaveDisabled={!formDirty}
        />
      </Container>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.add.content'
        confirmDisabled={false}
        open={editNewItemOpen}
        onConfirm={handleSubmit(onEditDialogConfirm)}
        onClose={resetForm}
        title='contentManagement.dialog.add.title'
      >
        <AddingDialogContent>
          <Label>{t('caption.name')}:</Label>
          <Textarea
            name='questionContent'
            ref={register({
              ...getValidator({ type: ValidationType.Required }),
              ...getValidator({
                msgTKey: 'form.validation.insurerQuestion.tooLong',
                type: ValidationType.MaxLength,
                value: maxQuestionLength,
              }),
            })}
          />
          <ErrorMessage errors={errors} name='questionContent' as={TranslatedErrorMessage} />
        </AddingDialogContent>
      </Dialog>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.delete.content'
        open={deleteDialogOpen}
        onConfirm={onDeleteDialogConfirm}
        onClose={onDeleteDialogClose}
        title='contentManagement.dialog.delete.title'
      />
    </form>
  )
}
