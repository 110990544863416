import { BtnType } from 'actff-bo-app/components/Button'
import { ClientFullName } from 'actff-bo-app/components/Client'
import { Popup } from 'actff-bo-app/components/Popup'
import { Client, ClientId } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { MinorButton, PrimaryButton } from 'styles/Button'

type Props = {
  readonly client: Client,
  readonly isOpened: boolean,
  readonly onCancel: () => void,
  readonly onConfirm: (uuid: ClientId) => void,
}

const PopupFooter = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 130px);
  justify-content: end;
  margin-top: 20px;
`

export const ArchiveClientPopup: React.FC<Props> = ({ client, isOpened, onCancel, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Popup
      header={t('clientView.archivePopup.header')}
      open={isOpened}
      onClose={onCancel}
    >
      <>
        <strong>{t('clientView.archivePopup.subtitle')} <ClientFullName client={client} />?</strong><br />
        {t('clientView.archivePopup.content')}
        <PopupFooter>
          <MinorButton type={BtnType.Button} onClick={onCancel}>{t('caption.cancel')}</MinorButton>
          <PrimaryButton type={BtnType.Button} onClick={onConfirm(client.uuid)}>{t('caption.confirm')}</PrimaryButton>
        </PopupFooter>
      </>
    </Popup>
  )
}
