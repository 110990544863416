import { PagedResult } from 'actff-bo-lib/api'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { Loadable } from 'actff-bo-lib/global'
import { addDays, endOfToday } from 'date-fns'

import { CrmServiceAction, CrmServiceActionType } from '../actions'
import { OpportunityService } from '../dto'

export type CrmServiceListState = {} & {
  readonly [key in keyof typeof OpportunityListType]: {
    readonly from?: Date,
    readonly to?: Date,
    readonly currentPage: number,
    readonly list: Loadable<PagedResult<OpportunityService>>,
  }
}

// tslint:disable object-literal-sort-keys no-magic-numbers
export const initialListState = {
  overdue: {
    currentPage: 1,
    list: {
      loading: false,
      data: null,
    },
  },
  new: {
    currentPage: 1,
    list: {
      loading: false,
      data: null,
    },
  },
  future: {
    currentPage: 1,
    to: addDays(endOfToday(), 4),
    list: {
      loading: false,
      data: null,
    },
  },
  all: {
    currentPage: 1,
    list: {
      loading: false,
      data: null,
    },
  },
}

// tslint:disable-next-line:cyclomatic-complexity
export const crmServiceListReducer = (
  state: CrmServiceListState = initialListState,
  action: CrmServiceAction,
) => {
  switch (action.type) {
    case CrmServiceActionType.ChangeOpportunityListCurrentPage:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          currentPage: action.payload.page,
          list: {
            loading: true,
          },
        },
      })

    case CrmServiceActionType.ChangeOpportunityListTimeFrom:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          from: action.payload.date,
          list: {
            loading: true,
          },
        },
      })

    case CrmServiceActionType.ChangeOpportunityListTimeTo:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            loading: true,
          },
          to: action.payload.date,
        },
      })

    case CrmServiceActionType.ChangeSearchPhrase:
      const { to, ...currentFutureListState } = state.future

      return action.payload
        ? ({
          ...state,
          future: {
            ...currentFutureListState,
          },
        })
        : ({
          ...initialListState,
        })

    case CrmServiceActionType.GetOpportunities:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            loading: true,
          },
        },
      })

    case CrmServiceActionType.GetOpportunitiesFailure:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            loading: false,
          },
        },
      })

    case CrmServiceActionType.GetOpportunitiesSuccess:
      return ({
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          list: {
            data: action.payload.opportunityList,
            loading: false,
          },
        },
      })

    case CrmServiceActionType.ResetPaginationOnLists:
      return ({
        ...state,
        all: {
          ...state.all,
          currentPage: initialListState.all.currentPage,
        },
        future: {
          ...state.future,
          currentPage: initialListState.future.currentPage,
        },
        new: {
          ...state.new,
          currentPage: initialListState.new.currentPage,
        },
        overdue: {
          ...state.overdue,
          currentPage: initialListState.overdue.currentPage,
        },
      })

    case CrmServiceActionType.AssignOpportunitySuccess:
    case CrmServiceActionType.UnassignOpportunitySuccess:
      const opportunityListToUpdate = state[action.payload.opportunityListType]
      const opportunityResultsToUpdate = opportunityListToUpdate.list.data?.result || []
      const indexOfUpdatedOpportunity =
        opportunityResultsToUpdate?.findIndex(element => element.uuid === action.payload.opportunity.uuid)

      // tslint:disable-next-line: prefer-object-spread
      const updatedResult = Object.assign([...opportunityResultsToUpdate], { [indexOfUpdatedOpportunity]: action.payload.opportunity })

      return {
        ...state,
        [action.payload.opportunityListType]: {
          ...opportunityListToUpdate,
          list: {
            data: {
              ...opportunityListToUpdate.list.data,
              result: updatedResult,
            },
            loading: false,
          },
        },
      }

    default:
      return state
  }
}
