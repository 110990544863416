import { objectDiff } from 'actff-bo-lib/global'

import { OpportunityService } from '../dto'

const filterComments = (opportunity: OpportunityService) => {
  const { comments, ...rest } = opportunity

  return rest
}

export const isOriginalOpportunityUpdated = (oldOpportunity: OpportunityService, newOpportunity: OpportunityService) =>
  Object.keys(objectDiff(filterComments(oldOpportunity), filterComments(newOpportunity))).length > 0
