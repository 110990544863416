import { api, axiosApi } from 'actff-bo-lib/api'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import {
  AdminDealerSettings,
  ApplicationLinks,
  OpeningHours,
  UpdateDealerLocationBrandsPayload,
  UpdateDealerLocationD2DCitiesPayload,
} from './dto'

export const getOpeningHours = (locationKey?: DealerLocationKey) =>
  api.get<ReadonlyArray<OpeningHours>>(`/calendar/opening-hours/${locationKey}`)

export const updateOpeningHours = (openingHours: ReadonlyArray<OpeningHours>, locationKey?: DealerLocationKey) =>
  api.patch<ReadonlyArray<OpeningHours>, ReadonlyArray<OpeningHours>>(`/calendar/opening-hours/${locationKey}`, openingHours)

export const getDealerSettings = (locationKey?: DealerLocationKey) => api.get<AdminDealerSettings>(`/dealer-settings/${locationKey}`)

export const updateDealerSettings = (dealerSettings: AdminDealerSettings, locationKey?: DealerLocationKey) =>
  api.patch<AdminDealerSettings, AdminDealerSettings>(`/dealer-settings/${locationKey}`, dealerSettings)

export const getDealerLocationApplicationLinks = (locationKey: DealerLocationKey) =>
  api.get<ApplicationLinks>(`/dealer-settings/${locationKey}/application-links`)

export const updateDealerLocationApplicationLinks = (locationKey: DealerLocationKey, links: ApplicationLinks) =>
  api.post<ApplicationLinks, ApplicationLinks>(`/dealer-settings/${locationKey}/application-links`, links)

export const getDealerLocationD2DCities = (key?: DealerLocationKey): Promise<ReadonlyArray<string>> =>
  axiosApi.get(`/dealer-settings/${key}/d2dCities`)

export const deleteDealerLocationD2DCity = ({ name, key }: UpdateDealerLocationD2DCitiesPayload) =>
  axiosApi.delete(`/dealer-settings/${key}/d2dCities/${name}`)

export const addDealerLocationD2DCity = ({ name, key }: UpdateDealerLocationBrandsPayload) =>
  axiosApi.post(`/dealer-settings/${key}/d2dCities`, { city: name })

export const getDealerLocationBrands = (key?: DealerLocationKey): Promise<ReadonlyArray<string>> =>
  axiosApi.get(`/dealer-settings/${key}/brands`)

export const deleteDealerBrandLocation = ({ name, key }: UpdateDealerLocationBrandsPayload) =>
  axiosApi.delete(`/dealer-settings/${key}/brands/${name}`)

export const addDealerBrandToLocation = ({ name, key }: UpdateDealerLocationBrandsPayload) =>
  axiosApi.post(`/dealer-settings/${key}/brands`, { name })
