import { ContactApproval } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type ApprovalStateProps = {
  readonly approval: ContactApproval,
}

export const ApprovalState: React.FC<ApprovalStateProps> = ({ approval }) => {
  const { t } = useTranslation()

  return (
    <>
      {approval.approved ? t('clientView.approvals.accept') : t('clientView.approvals.reject')}
    </>
  )
}
