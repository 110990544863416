import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { Loader } from 'actff-bo-app/components/Loader'
import { WithPagination } from 'actff-bo-app/components/Table'
import { Expandable, ExpandableContent } from 'actff-bo-app/Crm/Trade/Styled'
import { AppPersonId, isAppPerson } from 'actff-bo-lib/car'
import { ClientAction, selectCurrentClientCars, selectCurrentClientCarsPage } from 'actff-bo-lib/client'
import { CrmTradeOwner } from 'actff-bo-lib/crm/trade'
import { Path, Paths } from 'actff-bo-lib/menu'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { colors, Table, Th, Tr } from 'styles'
import { CarRow } from './CarRow'

const Container = styled.div`
  grid-area: rest-owner-cars;
  margin-bottom: 24px;
`

const TableStyled = styled(Table)`
  border-top: 1px solid ${colors.mysticGray};
`

type Props = {
  readonly owner?: CrmTradeOwner,
}

export const RestOwnerCars: FC<Props> = ({ owner }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getAppPersonCars = useMemo(() => (uuid: AppPersonId) => {
    dispatch(ClientAction.getClientCars(uuid))
  }, [dispatch])

  const clearClientCars = useMemo(() => () => { dispatch(ClientAction.clearClientCars()) }, [dispatch])

  useEffect(() => {
    if (owner && isAppPerson(owner)) {
      getAppPersonCars(owner.uuid)

      return
    }
    clearClientCars()
  }, [getAppPersonCars, clearClientCars, owner])

  const handlePageChange = (page: number) => dispatch(ClientAction.changeClientCarsPage(page))

  const [expanded, setExpanded] = useState(false)
  const handleToggleExpanded = () => { setExpanded(!expanded) }
  const { data: clientCars, loading } = useSelector(selectCurrentClientCars)
  const currentPage = useSelector(selectCurrentClientCarsPage)
  const getCarsCount = () => clientCars?.result.length || 0
  const getCarsCountLabel = () => getCarsCount() > 0 ? `(${getCarsCount()})` : t('caption.absence')

  return (
    <Container>
      <Expandable onClick={handleToggleExpanded}>
        <span>{t('crmTrade.restClientCars')}: {getCarsCountLabel()}</span>
        {clientCars && <ExpandCollapseButton expanded={expanded} onClick={handleToggleExpanded}/>}
      </Expandable>
      <ExpandableContent expanded={expanded}>
        {!loading ? clientCars ? (
          <WithPagination
            currentPage={currentPage}
            onPageChange={handlePageChange}
            pageCount={clientCars.noOfPages}
            path={Paths.CrmServiceOpportunityList as Path}
            rowsInCurrentPage={10} // tslint:disable-line no-magic-numbers
          >
            <TableStyled>
              <thead>
              <Tr>
                <Th hasPadding={false}>{t('car.model')}:</Th>
                <Th hasPadding={false}>{t('car.alias')}:</Th>
                <Th hasPadding={false}>{t('car.modelYear')}:</Th>
                <Th hasPadding={false}>{t('car.firstRegistrationDate')}:</Th>
                <Th hasPadding={false}>{t('car.version')}:</Th>
                <Th hasPadding={false}>{t('car.vinNumber')}:</Th>
              </Tr>
              </thead>
              <tbody>
              {clientCars.result.map(car =>
                <CarRow key={car.uuid} car={car} />,
              )}
              </tbody>
            </TableStyled>
          </WithPagination>
        ) : null : <Loader />}
      </ExpandableContent>
    </Container>
  )
}
