import { FormCell } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const Contact: React.FC<Testable> = ({ testId }) => {
  const { t } = useTranslation()

  return (<FormCell data-testid={`${testId}contact`}>{t('serviceRequest.carLeaveOption.contactDescription')}</FormCell>)
}
