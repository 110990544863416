import { Loadable } from 'actff-bo-lib/global'

import { CrmInsuranceReportAction, CrmInsuranceReportActionType } from '../actions/report'
import { Report } from '../dto'

export type CrmInsuranceReportState = {
  readonly report: Loadable<Report>,
}

const initialReportState: CrmInsuranceReportState = {
  report: {
    data: null,
    loading: false,
  },
}

// tslint:disable-next-line:cyclomatic-complexity
export const crmInsuranceReportReducer = (
  state: CrmInsuranceReportState = initialReportState,
  action: CrmInsuranceReportAction,
): CrmInsuranceReportState => {
  switch (action.type) {
    case CrmInsuranceReportActionType.GetReport:
      return ({
        ...state,
        report: {
          data: null,
          loading: true,
        },
      })

    case CrmInsuranceReportActionType.GetReportSuccess:
      return ({
        ...state,
        report: {
          data: action.payload,
          loading: false,
        },
      })

    case CrmInsuranceReportActionType.GetReportFailure:
      return ({
        ...state,
        report: {
          ...initialReportState.report,
        },
      })

    default:
      return state
  }
}
