import { FormHeader } from 'actff-bo-app/components/Form'
import styled from 'styled-components'

export const FormHeaderStyled = styled(FormHeader)`
  align-items: center;
  display: inline-grid;
  grid-auto-flow: unset;
  grid-template-columns: 1fr auto;
  padding: 28px 32px;
  width: 100%;
`

export const IconContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 20px 20px;
`

export const FormBody = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 40px;
  grid-template-columns: repeat(4, auto);
  padding-right: 32px;
  padding-bottom: 100px;
  width: 100%;
`
