import { connector } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { MenuItem, TabMenuItem } from 'actff-bo-lib/menu/dto'
import { createRoute, isMenuPathActive } from 'actff-bo-lib/menu/helpers'
import { selectMenuItemWithSpecifiedPath } from 'actff-bo-lib/menu/selectors'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { MapStateToProps } from 'react-redux'

import { Li, LiActive, LinkStyled } from './TabItemStyled'

type TabItemContainerProps = Testable & {
  readonly active: boolean,
  readonly autoWidth: boolean,
}

type StateToProps = {
  readonly menuItem: MenuItem | undefined,
}

type TabItemComponentProps = TabMenuItem & StateToProps & Testable

const TabItemContainer: React.FC<TabItemContainerProps> = ({ active, autoWidth, children }) => {
  const ItemContainer = active ? LiActive : Li

  return <ItemContainer autoWidth={autoWidth}>{children}</ItemContainer>
}

class TabItemComponent extends React.Component<TabItemComponentProps> {
  public render(): React.ReactNode {
    const { autoWidth = false, hidden = false, menuItem, name, path, parameters, testId } = this.props

    return (
      menuItem && !hidden
        ? (
          <TabItemContainer
            active={isMenuPathActive(window.location.pathname, path)}
            autoWidth={autoWidth}
            testId={testId}
          >
            <LinkStyled data-testid={testId} to={createRoute(path, parameters)}>{name}</LinkStyled>
          </TabItemContainer>
        )
        : null
    )
  }
}

const mapStateToProps: MapStateToProps<StateToProps, TabMenuItem, State> = (state, props) => ({
  menuItem: selectMenuItemWithSpecifiedPath(props.path)(state),
})

export const TabItem = connector(mapStateToProps, null)(TabItemComponent)
