import { ReactSelect } from 'actff-bo-app/components/Select'
import { selectCurrentLocation, selectDealerLocations, setCurrentLocationAction } from 'actff-bo-lib/dealership'
import { Option } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getContainerMaxWidth } from './getContainerMaxWidth'
import { Container, customStyles } from './Styled'

export const LocationsDropdown: React.FC<Testable> = () => {
  const dispatch = useDispatch()

  const { data: dealerLocations } = useSelector(selectDealerLocations)
  const currentLocation = useSelector(selectCurrentLocation)
  const currentValue = currentLocation ? { label: currentLocation.name, value: currentLocation.key } : null

  const handleLocationChange = (selectOption: Option) => {
    dispatch(setCurrentLocationAction(dealerLocations?.find(location => location.key === selectOption.value)))
  }

  const locationOptions = dealerLocations?.map(location => ({
    label: location.name,
    value: location.key,
  }))

  return (
    <Container maxWidth={getContainerMaxWidth(currentLocation?.name)}>
      <ReactSelect
        customStyles={customStyles}
        isDisabled={dealerLocations?.length === 1}
        isClearable={false}
        hideSelectedOptions={true}
        options={locationOptions as ReadonlyArray<Option>}
        onChange={handleLocationChange}
        value={currentValue}
      />
    </Container>
  )
}
