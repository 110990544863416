import { BtnType } from 'actff-bo-app/components/Button'
import { CarCard } from 'actff-bo-app/components/CarCard'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { FormFooterLeft, FormFooterRight } from 'actff-bo-app/components/Form'
import { Loader } from 'actff-bo-app/components/Loader'
import { NoItems, WithPagination } from 'actff-bo-app/components/Table'
import { PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { Client, ClientAction, ClientId, ClientStatus, selectCurrentClientCars, selectCurrentClientCarsPage } from 'actff-bo-lib/client'
import { Loadable } from 'actff-bo-lib/global'
import { Language } from 'actff-bo-lib/i18n'
import { createRoute, IconType, Path, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { State } from 'actff-bo-lib/store'
import { selectUserPermissions, User, UserPermissions } from 'actff-bo-lib/user'
import { hasPermission } from 'actff-bo-lib/user/has-permission'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { H2, MinorButton, PrimaryButton, tabletBoundary } from 'styles'

import { withClientViewHeader } from '../withClientViewHeader'

type StateToProps = {
  readonly cars: Loadable<PagedResult<CarInfo>>,
  readonly currentPage: number,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

type DispatchToProps = {
  readonly getCars: (clientUuid: ClientId) => void,
  readonly onPageChange: (nextPage: number) => void,
}

type ClientViewCarsComponentProps = {
  readonly client: Client,
  readonly currentLanguage: Language,
  readonly currentUser: User | null,
}

type Props = DispatchToProps & StateToProps & ClientViewCarsComponentProps & WithTranslation

const Container = styled.div`
  padding: 32px 34px 100px;
`

const CarContainer = styled.div`
  display: grid;
  grid-area: content;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 25px;
  padding-top: 20px;

  @media screen and (max-width: ${tabletBoundary}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
const testId = 'client-view-cars__'

class ClientViewCarsComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.getCars(this.props.client.uuid)
  }

  public componentWillUnmount(): void {
    this.resetCurrentPage()
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.currentPage !== prevProps.currentPage) {
      this.props.getCars(this.props.client.uuid)
    }
  }

  // TODO: Refactor this component to enable linter
  // tslint:disable-next-line cyclomatic-complexity
  public render(): React.ReactNode {
    const { cars: { data, loading }, currentPage, onPageChange, t } = this.props
    const perPage = 10

    return (
      <Container>
        {loading
          ? <Loader />
          : data && data.result.length > 0
            ? (
              <>
                <H2 bold={true} testId={`${testId}header`}>{t('clientView.addedCars')}</H2>
                <WithPagination
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  pageCount={data.noOfPages}
                  path={Paths.ClientViewCars as Path}
                  rowsInCurrentPage={perPage}
                >
                  <CarContainer>
                    {data.result.map((car, index) =>
                      <CarCard
                        active={true}
                        key={car.uuid}
                        car={car}
                        numeral={this.getNumeralCarNumber(currentPage, index, perPage)}
                        testId={`${testId}car--${this.getNumeralCarNumber(currentPage, index, perPage)}`}
                      />,
                    )}
                  </CarContainer>
                </WithPagination>
              </>
            )
            : <NoItems text={t('clientView.noCars')} icon={IconType.NoCars} />
        }
        <FixedFooter>
          <>
            <FormFooterLeft />
            <FormFooterRight>
              {this.userCanCreateCar()
              && this.canCreateCarForClient()
              && (
                <MinorButton type={BtnType.Button} onClick={this.handleNewCarClick}>{t('clientView.newCar')}</MinorButton>
              )
              }
              <PrimaryButton type={BtnType.Button} onClick={this.handleGoBackClick}>{t('caption.backToList')}</PrimaryButton>
            </FormFooterRight>
          </>
        </FixedFooter>
      </Container>
    )
  }

  public readonly getNumeralCarNumber = (currentPage: number, index: number, perPage: number) => (currentPage - 1) * perPage + index + 1

  public readonly handleNewCarClick = () => {
    history.push(createRoute(Paths.CarNew, { clientId: this.props.client.uuid }))
  }

  private readonly handleGoBackClick = () => {
    history.push(Paths.ClientList)
  }

  private readonly resetCurrentPage = () => {
    if (this.props.currentPage !== 1) {
      this.props.onPageChange(1)
    }
  }

  private readonly canCreateCarForClient = () =>
    this.props.client.status === ClientStatus.Verified

  private readonly userCanCreateCar = () => hasPermission([
    UserPermissions.AdminAllService,
    UserPermissions.ServiceEditService,
    UserPermissions.InsuranceEditService,
    UserPermissions.FinanceEditService,
  ])(this.props.userPermissions)
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  cars: selectCurrentClientCars(state),
  currentPage: selectCurrentClientCarsPage(state),
  userPermissions: selectUserPermissions(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getCars: clientUuid => { dispatch(ClientAction.getClientCars(clientUuid)) },
  onPageChange: (nextPage: number) => { dispatch(ClientAction.changeClientCarsPage(nextPage)) },
})

export const ClientViewCars = compose(
  withClientViewHeader,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ClientViewCarsComponent)
