import { Rating as MaterialRating } from '@material-ui/lab'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import styled from 'styled-components'

type Props = {
  readonly defaultValue?: number,
  readonly name?: string,
  readonly control?: Control,
  readonly precision?: number | undefined,
  readonly readonly?: boolean,
}

type RatingComponentProps = Props & {
  readonly onChange?: () => void,
  readonly value?: number,
}

const Container = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-column-gap: 20px;

  label {
    color: inherit;
  }

  [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
    color: inherit;
    cursor: pointer;
    display: initial;
    font-weight: normal;
    padding-left: auto;
    position: relative;
    padding: 0;
    text-transform: initial;
  }
  [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
`

const defaultPrecision = 0.5

export const RatingComponent: FC<RatingComponentProps> = ({ defaultValue, onChange, precision = defaultPrecision, readonly, value }) => (
  <>
    <MaterialRating
      defaultValue={defaultValue}
      name='rating'
      onChange={onChange}
      precision={precision}
      readOnly={readonly}
      size='large'
      value={value}
    />
    <span>{value}</span>
  </>
)

export const Rating: FC<Props> = props => {
  const { control, readonly, name = '', precision = defaultPrecision } = props

  return (
    <Container>
      {readonly
        ? <RatingComponent precision={precision} {...props} />
        : (
          <Controller
            control={control}
            name={name}
            render={({ onChange, value }) => (
              <RatingComponent
                onChange={onChange}
                precision={precision}
                readonly={readonly}
                value={value}
              />
            )}
          />
        )
      }
    </Container>
  )
}
