import { Container, Header } from 'actff-bo-app/Admin/Styled'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  AdminOpeningHoursAction,
  OpeningHours as OpeningHoursDto,
  selectOpeningHours,
} from 'actff-bo-lib/admin/dealer-locations'
import { selectCurrentLocation } from 'actff-bo-lib/dealership'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { H2 } from 'styles/Content'

import { ViewTabs } from '../ViewTabs'
import { OpeningHoursForm } from './OpeningHoursForm'

const testId = 'admin-view-opening-hours__'

const OpeningHoursComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentLocation = useSelector(selectCurrentLocation)
  const { loading, data: openingHours } = useSelector(selectOpeningHours)

  const updateOpeningHours = (openingHoursDto: ReadonlyArray<OpeningHoursDto>) =>
    dispatch(AdminOpeningHoursAction.updateOpeningHours(openingHoursDto, currentLocation?.key))

  const getOpeningHours = () => {
    dispatch(AdminOpeningHoursAction.getOpeningHours(currentLocation?.key))
  }

  useEffect(() => {
    getOpeningHours()
  }, [currentLocation])

  if (loading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <Container>
          <Header><H2>{t('openingHours')}</H2></Header>
          <LocalLoader />
        </Container>
      </>
    )
  }
  if (!openingHours) {
    return (
      <>
        <ViewTabs testId={testId} />
        <NoData>{t('caption.noData')}</NoData>
      </>
    )
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <Header><H2>{t('openingHours')}</H2></Header>
        <OpeningHoursForm openingHours={openingHours} updateOpeningHours={updateOpeningHours} testId={testId} />
      </Container>
    </>
  )
}

export const OpeningHours = withAdminViewHeader(OpeningHoursComponent)
