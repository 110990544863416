import { BlobUrl, selectAttachmentThumbnailForUrl, thumbnailPostfix, Url } from 'actff-bo-lib/global/attachment'
import { AttachmentAction } from 'actff-bo-lib/global/attachment/actions'
import { State as GlobalState } from 'actff-bo-lib/store'
import React from 'react'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import styled from 'styled-components'

type Props = {
  readonly url: Url,
  readonly thumbnailSize?: number,
  readonly onLightboxOpen: (url: Url) => void,
}

type StateToProps = {
  readonly thumbnail?: BlobUrl,
}

type DispatchToProps = {
  readonly getAttachmentThumbnail: (url: Url) => void,
}

const defaultThumbnailSize = 72

const Placeholder = styled.div`
  display: inline-block;
  height: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
  width: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
`

export const ThumbnailFileStyled = styled.img`
  border-radius: 5px;
  cursor: pointer;
  height: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
  object-fit: cover;
  width: ${({ thumbnailSize }: Props) => `${thumbnailSize || defaultThumbnailSize}px`};
`

const getAttachmentThumbnailUrl = (url: Url) => {
  const [urlBeforeParams, ...params] = url.split('?')

  return urlBeforeParams + thumbnailPostfix + (params.length > 0 ? `?${params.toString()}` : '') as Url
}

type ThumbnailProps = Props & StateToProps & DispatchToProps

class ThumbnailComponent extends React.Component<ThumbnailProps> {

  public componentDidMount(): void {
    const { getAttachmentThumbnail, thumbnail, url } = this.props

    if (!thumbnail) {
      getAttachmentThumbnail(getAttachmentThumbnailUrl(url))
    }
  }

  public render(): React.ReactNode {
    const { thumbnail, thumbnailSize } = this.props

    return (
      <>
        {thumbnail
          ? (<ThumbnailFileStyled onClick={this.openLightbox} src={thumbnail} thumbnailSize={thumbnailSize} />)
          : <Placeholder thumbnailSize={thumbnailSize}>Loading</Placeholder> // TODO: Add loader
        }
      </>
    )
  }

  private readonly openLightbox = () => {
    const { onLightboxOpen, url } = this.props

    onLightboxOpen(url)
  }
}

const mapStateToProps: MapStateToProps<StateToProps, Props, GlobalState> = (state, props) => ({
  thumbnail: selectAttachmentThumbnailForUrl(getAttachmentThumbnailUrl(props.url))(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getAttachmentThumbnail: (url: Url) => { dispatch(AttachmentAction.getAttachmentThumbnail(url)) },
})

export const Thumbnail = connect(mapStateToProps, mapDispatchToProps)(ThumbnailComponent)
