import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import {
  CarId,
  CarInsurance,
  CarInsuranceWithCarUuid,
} from '../dto'

export enum CarInsuranceActionType {
  GetCarInsurance = '[CAR] GET CAR INSURANCE',
  GetCarInsuranceFailure = '[CAR] GET CAR INSURANCE FAILURE',
  GetCarInsuranceSuccess = '[CAR] GET CAR INSURANCE SUCCESS',

  UpdateCarInsurance = '[CAR] UPDATE CAR INSURANCE',
  UpdateCarInsuranceFailure = '[CAR] UPDATE CAR INSURANCE FAILURE',
  UpdateCarInsuranceSuccess = '[CAR] UPDATE CAR INSURANCE SUCCESS',

}

export const CarInsuranceAction = {
  getCarInsurance: (carId: CarId) => createAction(CarInsuranceActionType.GetCarInsurance, carId),
  getCarInsuranceFailure: (error: AjaxError) => createAction(CarInsuranceActionType.GetCarInsuranceFailure, error),
  getCarInsuranceSuccess: (carInsurance: CarInsurance) =>
    createAction(CarInsuranceActionType.GetCarInsuranceSuccess, carInsurance),
  updateCarInsurance: (payload: CarInsuranceWithCarUuid) => createAction(CarInsuranceActionType.UpdateCarInsurance, payload),
  updateCarInsuranceFailure: (error: AjaxError) => createAction(CarInsuranceActionType.UpdateCarInsuranceFailure, error),
  updateCarInsuranceSuccess: (payload: CarInsurance) => createAction(CarInsuranceActionType.UpdateCarInsuranceSuccess, payload),
}

export type CarInsuranceAction = ActionsUnion<typeof CarInsuranceAction>
