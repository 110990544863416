import { Loadable } from 'actff-bo-lib/global'

import { ApprovalsContentAction, ApprovalsContentActionType } from './actions'
import { ApprovalsContent } from './dto'

export type ApprovalsContentState = Loadable<ApprovalsContent>

const defaultApprovalsContentState: ApprovalsContentState = {
  data: null,
  loading: false,
}

export const approvalsContentReducer =
  (state: ApprovalsContentState = defaultApprovalsContentState, action: ApprovalsContentAction): ApprovalsContentState => {
  switch (action.type) {
    case ApprovalsContentActionType.GetApprovalsContent:
      return {
        data: null,
        loading: true,
      }

    case ApprovalsContentActionType.GetApprovalsContentFailure:
      return {
        data: null,
        loading: false,
      }

    case ApprovalsContentActionType.GetApprovalsContentSuccess:
      return {
        data: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
