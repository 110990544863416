import { OpportunityInsurance } from 'actff-bo-lib/crm/insurance/dto'

const isEmptyValue = (value?: string | null) => !value || value === ''
const reduceObjectToBoolean = <T>(obj: T) => Object.keys(obj)
  .reduce((previous, current) => Boolean(previous || !isEmptyValue(obj[current])), false)

const removeUnfilledRows = <T>(arrayToFilter: ReadonlyArray<T> = []) => arrayToFilter.filter(reduceObjectToBoolean)

export const cleanOpportunityValues = (opportunity: OpportunityInsurance): OpportunityInsurance => ({
  ...opportunity,
  confirmation: {
    ...opportunity.confirmation,
    commissions: removeUnfilledRows(opportunity.confirmation.commissions),
  },
  contactHistory: {
    ...opportunity.contactHistory,
    attempts: removeUnfilledRows(opportunity.contactHistory.attempts),
  },
  newPolicy: {
    ...opportunity.newPolicy,
    installments: removeUnfilledRows(opportunity.newPolicy.installments),
  },
  payments: {
    ...opportunity.payments,
    installments: removeUnfilledRows(opportunity.payments.installments),
  },
})
