import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'
import { Credentials, Token } from './dto'

export enum AuthActionType {
  LoadToken = '[AUTH] LOAD TOKEN',
  Login = '[AUTH] LOGIN',
  LoginFailure = '[AUTH] LOGIN FAILURE',
  LoginSuccess = '[AUTH] LOGIN SUCCESS',
  Logout = '[AUTH] LOGOUT',
  SetToken = '[AUTH] SET TOKEN',
}

export const AuthAction = {
  loadToken: () => createAction(AuthActionType.LoadToken),
  login: (payload: Credentials) => createAction(AuthActionType.Login, payload),
  loginFailure: (error: AjaxError) => createAction(AuthActionType.LoginFailure, error),
  loginSuccess: (token: Token) => createAction(AuthActionType.LoginSuccess, token),
  logout: () => createAction(AuthActionType.Logout),
  setToken: (token: Token) => createAction(AuthActionType.SetToken, token),
}

export type AuthAction = ActionsUnion<typeof AuthAction>
