import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { Loader } from 'actff-bo-app/components/Loader'
import { WithPagination } from 'actff-bo-app/components/Table/Pagination'
import { PagedResult } from 'actff-bo-lib/api'
import { OpportunityListType } from 'actff-bo-lib/crm'
import {
  CrmServiceAction,
  OpportunityService,
  selectOpportunityListCurrentPage,
  selectOpportunityListData,
  selectOpportunityListLoading,
  selectOpportunityListTimeTo,
} from 'actff-bo-lib/crm/service'
import { Path } from 'actff-bo-lib/menu'
import { Paths } from 'actff-bo-lib/menu/initial-menu'
import { State as GlobalState } from 'actff-bo-lib/store'
import { addDays, endOfToday, subDays } from 'date-fns'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'

import { OpportunityListDayOffset } from './OpportunityListDayOffset'
import { OpportunityTable } from './OpportunityTable'
import { OpportunityListExpandCollapseButton, OpportunityListHeader, OpportunityListHeaderContainer } from './OpportunityTableStyled'

type Props = {
  readonly title: string,
  readonly type: OpportunityListType,
  readonly changeTimeFrom?: boolean,
  readonly changeTimeTo?: boolean,
}

type State = {
  readonly expanded: boolean,
}

type StateToProps = {
  readonly currentPage: number,
  readonly loading: boolean,
  readonly opportunities: PagedResult<OpportunityService> | null,
  readonly timeFrom?: Date,
  readonly timeTo?: Date,
}

type DispatchToProps = {
  readonly getOpportunities: () => void,
  readonly onPageChange: (page: number) => void,
  readonly onTimeFromChange: (date: Date) => void,
  readonly onTimeToChange: (date: Date) => void,
}

type OpportunityListProps = Props & StateToProps & DispatchToProps & WithTranslation

class OpportunityListComponent extends React.Component<OpportunityListProps, State> {
  public readonly state: State = { expanded: true }

  public componentDidMount(): void {
    this.props.getOpportunities()
  }

  // tslint:disable-next-line cyclomatic-complexity
  public render(): React.ReactNode {
    const { changeTimeFrom, changeTimeTo, currentPage, loading, onPageChange, opportunities, timeFrom, timeTo, title, type, t } = this.props
    const { expanded } = this.state

    return (
      <>
        <OpportunityListHeaderContainer>
          <OpportunityListHeader>
            {changeTimeFrom && <OpportunityListDayOffset currentDate={timeFrom} onSubmit={this.handleTimeFromChange} />}
            {t(title)}
            {changeTimeTo && (
              <>
                <OpportunityListDayOffset currentDate={timeTo} onSubmit={this.handleTimeToChange} />
                {t('caption.days')}
              </>
            )}
          </OpportunityListHeader>
          <OpportunityListExpandCollapseButton>
            <ExpandCollapseButton expanded={expanded} onClick={this.toggleExpanded} />
          </OpportunityListExpandCollapseButton>
        </OpportunityListHeaderContainer>
        {expanded && (
          loading
            ? <Loader />
            : opportunities && opportunities.result.length > 0 && (
              <WithPagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                pageCount={opportunities.noOfPages}
                path={Paths.CrmServiceOpportunityList as Path}
                rowsInCurrentPage={10} // tslint:disable-line no-magic-numbers
              >
                <OpportunityTable opportunities={opportunities} type={type} />
              </WithPagination>
            )
        )}
      </>
    )
  }

  private readonly toggleExpanded = () => { this.setState({ expanded: !this.state.expanded }) }
  private readonly handleTimeFromChange = (dateOffset: number) => { this.props.onTimeFromChange(subDays(endOfToday(), dateOffset)) }
  private readonly handleTimeToChange = (dateOffset: number) => { this.props.onTimeToChange(addDays(endOfToday(), dateOffset)) }
}

const mapStateToProps: MapStateToProps<StateToProps, Props, GlobalState> = (state, { type }) => ({
  currentPage: selectOpportunityListCurrentPage(type)(state),
  loading: selectOpportunityListLoading(type)(state),
  opportunities: selectOpportunityListData(type)(state),
  timeFrom: selectOpportunityListTimeTo(type)(state),
  timeTo: selectOpportunityListTimeTo(type)(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, Props> = (dispatch, { type }) => ({
  getOpportunities: () => { dispatch(CrmServiceAction.getOpportunities(type))},
  onPageChange: (nextPage: number) => { dispatch(CrmServiceAction.changeOpportunityListCurrentPage(nextPage, type))},
  onTimeFromChange: (date: Date) => { dispatch(CrmServiceAction.changeOpportunityListTimeFrom(date, type))},
  onTimeToChange: (date: Date) => { dispatch(CrmServiceAction.changeOpportunityListTimeTo(date, type))},
})

export const OpportunityList = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(OpportunityListComponent)
