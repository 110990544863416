import { createRoute, Paths } from 'actff-bo-lib/menu'
import { ServiceRequestExistsParams } from 'actff-bo-lib/service-request'
import { ToastBodyProps } from 'actff-bo-lib/toast'
import React, { FC } from 'react'
import { Trans } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

export const ServiceRequestWithServiceExists: FC<ToastBodyProps<ServiceRequestExistsParams>> = ({ params }) => (
  <BrowserRouter>
    {params?.map(({ serviceKey, serviceRequestUuid}) => (
        <React.Fragment key={`${serviceKey}${serviceRequestUuid}`}>
          <Trans
            key={serviceKey}
            i18nKey='serviceRequest.errors.exists.body'
            values={{ serviceKey }}
            components={[
              <a
                href={`${window.location.origin}${createRoute(Paths.ServiceRequestsView, { serviceRequestId: serviceRequestUuid })}`}
                key={`${serviceKey}-${serviceRequestUuid}`}
                rel='noreferrer noopener'
                target='_blank'
              >
                Service request
              </a>,

            ]}
          />
          <br />
        </React.Fragment>
      ),
    )}
    </BrowserRouter>
)
