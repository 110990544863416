import { ResourceLabelContentArg } from '@fullcalendar/resource-common'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { CalendarResourceType } from 'actff-bo-lib/admin'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryObserverResult } from 'react-query'
import { useHover } from 'react-use'
import styled from 'styled-components'
import { SecondaryButton } from 'styles'
import { ResourceModal } from '../ResourceModal/ResourceModal'

import { ResourceContentMenu } from './ResourceContentMenu'

const AddResourceButton = styled(SecondaryButton)`
  margin-top: 5px;
`

const ResourceTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
`

const ResourceContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }
`

type Props = Testable & {
  readonly resourceData: ResourceLabelContentArg,
  readonly onRemoveSchedule: (resourceId: string) => void,
  readonly onRefetch: () => Promise<QueryObserverResult>,
}

export const ResourceContent: FC<Props> = ({ resourceData, onRefetch, onRemoveSchedule, testId }) => {
  const { t } = useTranslation()

  const { id: resourceId, title: resourceTitle } = resourceData.resource
  const hasResourceHeader = Object.keys(CalendarResourceType).includes(resourceId)

  const resourceLabel = resourceId === CalendarResourceType.REPLACEMENT_CAR ?
    t('admin.employeeSchedule.addReplacementCar') :
    t('admin.employeeSchedule.addEmployee')

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [resourceModalOpen, setResourceModalOpen] = useState(false)

  const toggleOpenConfirmDialog = () => {
    setConfirmDialogOpen(!confirmDialogOpen)
  }

  const handleRemoveSchedule = () => {
    onRemoveSchedule(resourceId)
    setConfirmDialogOpen(false)
  }

  const renderResourceModal = () => resourceModalOpen && (
    <ResourceModal
      resourceContent={resourceData}
      resourceModalOpen={resourceModalOpen}
      onClose={() => setResourceModalOpen(false)}
      testId={testId}
      onRefetch={onRefetch}
    />
  )

  const hoverableElement = (isHovered: boolean) => (
    <ResourceContentWrapper>
      <span>{resourceTitle}</span>
      {isHovered && (
        <ResourceContentMenu
          onEdit={() => setResourceModalOpen(true)}
          onToggleConfirmDialog={toggleOpenConfirmDialog}
          viewType={resourceData.view.type}
        />
      )}
      <Dialog
        content='confirmDialog.employeeScheduler.delete.content'
        open={confirmDialogOpen}
        onConfirm={handleRemoveSchedule}
        onClose={toggleOpenConfirmDialog}
        title='confirmDialog.employeeScheduler.delete.title'
      />
      {renderResourceModal()}
    </ResourceContentWrapper>
  )

  const [hoverable] = useHover(hoverableElement)

  if (hasResourceHeader) {
    return (
      <>
        <ResourceTitle>{resourceTitle}</ResourceTitle><br />
        <AddResourceButton onClick={() => setResourceModalOpen(true)}>
          + {resourceLabel}
        </AddResourceButton>
        {renderResourceModal()}
      </>
    )
  }

  return hoverable
}
