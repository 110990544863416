import { Icon } from 'actff-bo-app/components/Icon'
import { InspectionPaintCoatElementType } from 'actff-bo-lib/car/dto'
import { FormValueT, InspectionPaintCoatFormElement } from 'actff-bo-lib/car/helpers'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import React, { FC, useState } from 'react'
import { EvaluationModal } from './EvaluationModal'
import { ClickableArea, EvaluationContent, Value, WithArrowContainer } from './Styled'

type Props = Testable & {
  readonly element: InspectionPaintCoatFormElement,
  readonly setPaintCoatValue: (updatedPaintCoat: FormValueT<InspectionPaintCoatFormElement>) => void,
  readonly bottom?: string,
  readonly left?: string,
}

export const Evaluation: FC<Props> = ({ left, bottom, element, setPaintCoatValue }) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const withPhotos = () => element.photos?.length > 0 || element.attachments?.length > 0
  const getElementShouldBeEvaluated = () => element.thickness || element.damageTypes?.length > 0
  const getIconType = () => getElementShouldBeEvaluated() ? IconType.ImageBlack : IconType.ImageWhite

  const getArrowIcon = () => {
    switch (element.key) {
      case InspectionPaintCoatElementType.leftSill:
        return <Icon type={IconType.ArrowTop2} />

      case InspectionPaintCoatElementType.rightSill:
        return <Icon type={IconType.ArrowBottom2} />

      default:
        return null
    }
  }

  const toggleDialogVisibility = () => setIsDialogVisible(!isDialogVisible)

  return (
    <>
      <ClickableArea left={left} bottom={bottom} onClick={toggleDialogVisibility}>
        <WithArrowContainer>
          <EvaluationContent filled={getElementShouldBeEvaluated()}>
            {withPhotos() && <Icon type={getIconType()} />}
            <Value>{element.thickness || 0}</Value>
          </EvaluationContent>
          {getArrowIcon()}
        </WithArrowContainer>

      </ClickableArea>

      {isDialogVisible && (
        <EvaluationModal
          element={element}
          setIsDialogVisible={setIsDialogVisible}
          toggleDialogVisibility={toggleDialogVisibility}
          setPaintCoatValue={setPaintCoatValue}
        />
      )}
    </>
  )
}
