import { SaveEditButton } from 'actff-bo-app/components/Button'
import { FormHeader, RadioValue } from 'actff-bo-app/components/Form'
import { CarEquipmentItem, CarEquipmentItems } from 'actff-bo-lib/car/dto'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Tr } from 'styles'

import { Form, Header, Table, Tbody, Td, Th } from '../Styled'
import { EquipmentItem } from './EquipmentItem'

type Props = Testable & {
  readonly onEdit: () => void,
  readonly items: CarEquipmentItems,
  readonly itemsEdit: CarEquipmentItems | null,
  readonly onSubmitForm: (carEquipment: CarEquipmentItems) => void,
}

type CarEquipmentItemsForm = {
  readonly [key: string]: RadioValue | null,
  readonly headerLeft: RadioValue | null,
  readonly headerRight: RadioValue | null,
}

const halfDivider = 2

export const getInitialValues = (carEquipment: CarEquipmentItems): CarEquipmentItemsForm => ({
  ...carEquipment.reduce((acc, item) => ({
    ...acc, [item.uuid]: item.value === null ? null : item.value ? RadioValue.Yes : RadioValue.No,
  }), {}),
  headerLeft: null,
  headerRight: null,
})

export const getPreparedValues = (items: CarEquipmentItems, formValues: CarEquipmentItemsForm): CarEquipmentItems => {
  const { headerLeft, headerRight, ...restEquipmentFormValues } = formValues

  return Object.entries(restEquipmentFormValues).map(([uuid, value]) => {
    const element = items.find(item => item.uuid === uuid) as CarEquipmentItem

    return {
      ...element,
      value: value === null ? null : value === RadioValue.Yes,
    }
  })
}

export const CarViewEquipmentItemsForm: FC<Props> = ({ itemsEdit, onEdit, items, onSubmitForm, testId }) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)

  const { control, getValues, handleSubmit, reset, setValue } =
    useForm<CarEquipmentItemsForm>({ defaultValues: getInitialValues(itemsEdit || items)})

  const onSubmit = handleSubmit(formValues => {
    onSubmitForm(getPreparedValues(itemsEdit || items, formValues))
    setEditMode(false)
  })

  const handleEditClick = () => {
    if (editMode) {
      onSubmit()
    }
    reset()
    onEdit()
    setEditMode(!editMode)
  }

  const columns: { readonly leftColumn: ReadonlyArray<CarEquipmentItem>, readonly rightColumn: ReadonlyArray<CarEquipmentItem> } =
    (itemsEdit || items).reduce(
    (acc, item, idx) => idx % halfDivider === 0
      ? { leftColumn: [...acc.leftColumn, item], rightColumn: acc.rightColumn }
      : { leftColumn: acc.leftColumn, rightColumn: [...acc.rightColumn, item] },
    { leftColumn: [], rightColumn: []},
  )

  const leftColumnItems = useWatch<ReadonlyArray<string>>({ control, name: columns.leftColumn.map(item => item.uuid) })
  const rightColumnItems = useWatch<ReadonlyArray<string>>({ control, name: columns.rightColumn.map(item => item.uuid) })

  const handleHeaderChange = (headerName: string, column: ReadonlyArray<CarEquipmentItem>, value: RadioValue) => {
    const headerValue = getValues(headerName)
    if (headerValue === value) {
      column.forEach(equipment => {
        setValue(`${equipment.uuid}`, null)
      })
    }

    column.forEach(equipment => {
      setValue(`${equipment.uuid}`, value)
    })
  }

  return (
    <Form>
      <Header>
        <FormHeader data-testid={`${testId}header-equipment`}>{t('carView.form.equipment.equipmentHeader')}</FormHeader>
        <SaveEditButton className='edit-time' editMode={editMode} onClick={handleEditClick} t={t} />
      </Header>

      <Table>
        <thead>
          <Tr>
            <Th>
              <Controller
                as={
                  <EquipmentItem
                    disabled={!editMode}
                    fieldName='headerLeft'
                    headerChange={(value: RadioValue) => handleHeaderChange('headerLeft', columns.leftColumn, value)}
                    isHeader={true}
                    items={leftColumnItems}
                  />
                }
                control={control}
                name='headerLeft'

              />
            </Th>
            <Th>
              <Controller
                as={
                  <EquipmentItem
                    disabled={!editMode}
                    fieldName='headerRight'
                    headerChange={(value: RadioValue) => handleHeaderChange('headerRight', columns.rightColumn, value)}
                    isHeader={true}
                    items={rightColumnItems}
                  />
                }
                name='headerRight'
                control={control}
              />
            </Th>
          </Tr>
        </thead>
        <Tbody>
          {columns.leftColumn.map((equipment, index) => (
            <Tr key={equipment.uuid}>
              <Td>
                <Controller
                  as={<EquipmentItem label={equipment.name} disabled={!editMode} />}
                  control={control}
                  name={`${equipment.uuid}`}
                  fieldName={`${equipment.uuid}`}
                />
              </Td>
              <Td>
                {columns.rightColumn[index] && (
                  <Controller
                    as={<EquipmentItem label={columns.rightColumn[index].name} disabled={!editMode} />}
                    fieldName={`${columns.rightColumn[index].uuid}`}
                    name={`${columns.rightColumn[index].uuid}`}
                    control={control}
                  />
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Form>
  )
}
