import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormFooterRight } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import { MenuAction, Paths, selectMenuDisabled } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { history } from 'actff-bo-lib/router'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { colors, LinkButton, PrimaryButton, Switch } from 'styles'

export const DisableMenuToggle = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 10px;
  align-items: center;
  border-left: 1px solid ${colors.mysticGray};
  padding-left: 40px;
`

export const OpportunityListLink = styled(ButtonWithIcon)`
  border-left: 1px solid ${colors.mysticGray};
  padding-left: 40px;
`

export const OpportunityFormFooterLeft = styled.div`
  display: grid;
  justify-items: start;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: 120px 225px 300px;
`

type Props = Testable & {
  readonly isSaveDisabled: boolean,
  readonly isEditMode: boolean,
  readonly onCancel: () => void,
  readonly onSave: () => void,
  readonly onEdit: () => void,
  readonly saveButtonType: BtnType,
  readonly isEditedByOtherUser: boolean,
}

export const OpportunityFooter: FC<Props> = ({
  isSaveDisabled,
  isEditMode,
  onCancel,
  onSave,
  onEdit,
  saveButtonType,
  testId,
  isEditedByOtherUser,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const menuDisabled = useSelector(selectMenuDisabled)

  const handleGoBackClick = () => { history.goBack() }
  const handleSaveClick = () => { onSave && onSave() }
  const handleEditClick = () => { onEdit && onEdit() }
  const handleDisableMenu = () => dispatch(MenuAction.toggleDisableMenu())
  const handleOpportunityListRedirect = () => window.open(Paths.CrmInsuranceOpportunityList, '_blank')

  const renderDisableMenuToggle = () => (
    <DisableMenuToggle>
      <Switch type='checkbox' onChange={handleDisableMenu} checked={menuDisabled} />
      <label>{menuDisabled ? t('crmInsurance.footer.unblockCard') : t('crmInsurance.footer.blockCard')}</label>
    </DisableMenuToggle>
  )

  const renderOpportunityListLink = () => (
    <OpportunityListLink
      caption={t('crmInsurance.footer.opportunitiesList')}
      iconType={IconType.NewPage}
      onClick={handleOpportunityListRedirect}
      testId={`${testId}opportunitiesList`}
      theme={BtnTheme.Link}
    />
  )

  const renderCancel = () => (
    <LinkButton disabled={menuDisabled} onClick={onCancel || handleGoBackClick} testId={`${testId}cancel`} type={BtnType.Button}>
      {t('caption.cancel')}
    </LinkButton>
  )

  const renderSave = () => !isEditedByOtherUser && (
    <PrimaryButton
      data-testid={`${testId}submit`}
      disabled={isSaveDisabled || menuDisabled}
      type={saveButtonType || BtnType.Button}
      onClick={handleSaveClick}
    >
      {t('caption.save')}
    </PrimaryButton>
  )

  const renderEdit = () => (
    <ButtonWithIcon
      caption={t('caption.edit')}
      disabled={menuDisabled}
      iconType={IconType.Edit}
      onClick={handleEditClick}
      testId={`${testId}edit`}
    />
  )

  return (
    <>
      <OpportunityFormFooterLeft>
        <ButtonWithIcon
          caption={t('caption.back')}
          iconType={IconType.Back}
          onClick={handleGoBackClick}
          testId={`${testId}go-back`}
          theme={BtnTheme.Link}
          disabled={menuDisabled}
        />
        {renderDisableMenuToggle()}
        {menuDisabled && renderOpportunityListLink()}
      </OpportunityFormFooterLeft>
      <FormFooterRight>
        {isEditMode && (
          <>
            {renderCancel()}
            {renderSave()}
          </>
        )}
        {!isEditMode && renderEdit()}
      </FormFooterRight>
    </>
  )
}
