import {
  CarAction,
  CarEngineCode,
  CarId,
  CarInfoDto,
  NewCarAction,
  selectCurrentCarRegistrationNumberExists,
  selectCurrentCarVinExists,
  selectIsLoadingSelectedClient,
  selectSelectedClient,
} from 'actff-bo-lib/car'
import { Client, ClientId } from 'actff-bo-lib/client'
import {
  CarModel,
  CarVersion,
  clearBrakeLiquids,
  clearCarVersions,
  clearOilTypes,
  getBrakeLiquidsAction,
  getCarModelsAction,
  getCarVersionsAction,
  getOilTypesAction,
  GetOilTypesFn,
  selectBrakeLiquids,
  selectCarModelsData,
  selectCarVersions,
  selectDealerBrands,
  selectOilTypes,
} from 'actff-bo-lib/dealership'
import { DictionaryAction, DictionaryType, selectFuelTypes } from 'actff-bo-lib/dictionary'
import { Loadable } from 'actff-bo-lib/global'
import { State as GlobalState } from 'actff-bo-lib/store'
import { displayFailureToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps } from 'react-router'

export type DispatchToProps = {
  readonly checkRegistrationNumberExistence: (carId: CarId, registrationNumber: string) => void,
  readonly checkVinExistence: (carId: CarId, vin: string) => void,
  readonly clearBrakeLiquids: () => void,
  readonly clearCarVersions: () => void,
  readonly clearOilTypes: () => void,
  readonly createCar: (carDto: CarInfoDto) => void,
  readonly getBrakeLiquids: (brand: string) => void,
  readonly getCarModels: (brand: string) => void,
  readonly getCarVersions: (brand: string) => void,
  readonly getFuelTypes: () => void,
  readonly getOilTypes: GetOilTypesFn,
  readonly getSelectedClient: (uuid: ClientId) => void,
  readonly getTireTypes: () => void,
  readonly showFailureToast: () => void,
}

export type StateToProps = {
  readonly brakeLiquids: Loadable<ReadonlyArray<string> | null>,
  readonly carModels: ReadonlyArray<CarModel> | null,
  readonly carVersions: Loadable<ReadonlyArray<CarVersion>>,
  readonly currentCarVinExists: boolean,
  readonly currentCarRegistrationNumberExists: boolean,
  readonly dealerBrands: Loadable<ReadonlyArray<string> | null>,
  readonly fuelTypes: ReadonlyArray<DictionaryType<string>>,
  readonly isLoadingSelectedClient: boolean,
  readonly oilTypes: ReadonlyArray<string> | null,
  readonly selectedClient: Client | null,
}

type CarNewParams = {
  readonly clientId: string,
}

export type Props = StateToProps & DispatchToProps & RouteComponentProps<CarNewParams>

export const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  brakeLiquids: selectBrakeLiquids(state),
  carModels: selectCarModelsData(state),
  carVersions: selectCarVersions(state),
  currentCarRegistrationNumberExists: selectCurrentCarRegistrationNumberExists(state),
  currentCarVinExists: selectCurrentCarVinExists(state),
  dealerBrands: selectDealerBrands(state),
  fuelTypes: selectFuelTypes(state),
  isLoadingSelectedClient: selectIsLoadingSelectedClient(state),
  oilTypes: selectOilTypes(state),
  selectedClient: selectSelectedClient(state),
})

export const mapDispatchToProps: MapDispatchToProps<DispatchToProps, Props> = (dispatch, props) => ({
  checkRegistrationNumberExistence: (carId: CarId, registrationNumber: string) => {
    dispatch(CarAction.checkRegistrationNumberExistence(null, registrationNumber))
  },
  checkVinExistence: (carId: CarId, vin: string) => {
    dispatch(CarAction.checkVinExistence(null, vin))
  },
  clearBrakeLiquids: () => { dispatch(clearBrakeLiquids()) },
  clearCarVersions: () => { dispatch(clearCarVersions()) },
  clearOilTypes: () => { dispatch(clearOilTypes()) },
  createCar: (carDto: CarInfoDto) => dispatch(NewCarAction.createCar(carDto, props.match.params.clientId as ClientId)),
  getBrakeLiquids: (brand: string) => { dispatch(getBrakeLiquidsAction(brand)) },
  getCarModels: (brand: string) => { dispatch(getCarModelsAction([brand])) },
  getCarVersions: (brand: string) => { dispatch(getCarVersionsAction([brand])) },
  getFuelTypes: () => { dispatch(DictionaryAction.getFuelTypes()) },
  getOilTypes: (brand: string | null, engineCode: CarEngineCode) => { dispatch(getOilTypesAction({ brand, engineCode })) },
  getSelectedClient: (uuid: ClientId) => { dispatch(NewCarAction.getSelectedClient(uuid)) },
  getTireTypes: () => { dispatch(DictionaryAction.getTireTypes()) },
  showFailureToast: () => {
    dispatch(displayFailureToast('admin.car.service.getCarCodeEnginesFailure', ToastActionType.GET))
  },
})
