/*
* [key1, key2, key3] -> { key1: true | false, key2: true | false, key3: true | false }
* or if values to Form has nested structure:
* {
*   nested1: [key1, key2, key3],
*   nested1: [key1, key2, key3],
* } -> {
*  nested: { key1: true | false, key2: true | false }
*  nested2: { key1: true | false, key2: true | false }
* }
* */
export const mapKeysToFormValues = <T>(keys: ReadonlyArray<T> | T, defaultValue: T | boolean = true): object =>
  keys && Array.isArray(keys)
    ? Object.fromEntries(keys.map(key => [key, defaultValue]))
    : Object.keys(keys).reduce((prev, curr) => ({
      ...prev,
      [curr]: mapKeysToFormValues(keys[curr], defaultValue),
    }), {})

/*
* { key1: true | false, key2: true | false, key3: true | false } -> [key1, key2, key3]
* or if Form object has nested structure:
* {
*  nested: { key1: true | false, key2: true | false }
*  nested2: { key1: true | false, key2: true | false }
* } -> {
*   nested: [key1, key2]
*  nested2: [key1, key2]
* }
* */
export const mapFormValuesToKeys = (values: object | null): object => {
  if (!values) {
    return []
  }

  const entries = Object.entries(values)

  return entries && typeof entries[0][1] === 'object'
    ? entries.reduce((prev, [key, value]) => ({
      ...prev,
      [key]: mapFormValuesToKeys(value),
    }), {})
    : entries.filter(([, value]) => value).map(([key]) => key)
}
