export enum ContactHistoryOutcome {
  SUCCESS = 'SUCCESS',
  REFUSAL = 'REFUSAL',
  NOT_NOW = 'NOT_NOW',
  WRONG_NUMBER = 'WRONG_NUMBER',
  NO_ANSWER = 'NO_ANSWER',
  VOICEMAIL = 'VOICEMAIL',
}

export type ContactHistoryAttempt = {
  readonly date: Date,
  readonly outcome: ContactHistoryOutcome,
  readonly retry: Date,
}

export type ContactHistory = {
  readonly attempts: ReadonlyArray<ContactHistoryAttempt>,
}
