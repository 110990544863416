import { OpportunityStatus, OpportunityStatusNameMap } from '../dto'

const nonChangeableStatuses: ReadonlyArray<OpportunityStatus> = [
  OpportunityStatus.AFTER_APPOINTMENT_LIST,
  OpportunityStatus.CANCELLED,
  OpportunityStatus.CANCELLED_LIST,
  OpportunityStatus.EXPIRED,
  OpportunityStatus.NEW_LIST,
  OpportunityStatus.NEW,
  OpportunityStatus.POSTPONED,
  OpportunityStatus.PUSH_SENT,
  OpportunityStatus.RETRY_LIST,
]

export const changeableStatuses = OpportunityStatusNameMap
  .entrySeq()
  .filter(([statusType]) => nonChangeableStatuses.findIndex(status => status === statusType) === -1)
