import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Label } from '../Label'
import { FiltersRendererProps } from '../TabFilters'
import { ListBrandFiltersForm } from './ListBrandFilters'
import { ListLocationsFiltersForm } from './ListLocationsFilters'

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
`

const Segment = styled.div`
  align-items: start;
  display: grid;
  grid-template-rows: 30px;
`

export const CombinedDealershipFilters: FC<FiltersRendererProps> = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Segment>
        <Label>{t('caption.location')}</Label>
        <ListLocationsFiltersForm {...props} filterFormNamePrefix='locationFilters' />
      </Segment>
      <Segment>
        <Label>{t('caption.brand')}</Label>
        <ListBrandFiltersForm {...props} filterFormNamePrefix='brandFilters' />
      </Segment>
    </Container>
  )
}
