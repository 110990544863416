import { DropdownContent } from 'actff-bo-app/components/DropdownContent'
import { ExpandCollapse } from 'actff-bo-app/components/ExpandCollapse'
import { Overlay } from 'actff-bo-app/components/Overlay'
import { Testable } from 'actff-bo-lib/global/testable'
import { ServiceRequestStatus as ServiceRequestStatusType, ServiceRequestStatusMap } from 'actff-bo-lib/service-request'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'

import { ServiceRequestStatusItem } from './ServiceRequestStatusItem'

type Props = Testable & {
  readonly onStatusChange: (status: ServiceRequestStatusType) => void,
  readonly status: ServiceRequestStatusType,
}

type State = {
  readonly expanded: boolean,
}

const StatusContainer = styled.div`
  display: inline-block;
  padding: 12.5px 0 12.5px 32px;
  position: relative;
`

const StatusLabel = styled.span`
  display: inline;
  margin-right: 4px;
`

const StatusButton = styled.button`
  color: ${colors.kumera};
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
`

class ServiceRequestStatusComponent extends React.Component<Props & WithTranslation, State> {
  public readonly state: State = {
    expanded: false,
  }

  public render(): React.ReactNode {
    const { expanded } = this.state
    const { t, testId, status } = this.props

    return (
      <>
        {expanded && <Overlay onClick={this.toggleExpanded} />}
        <StatusContainer>
          <StatusLabel>Status:</StatusLabel>
          <StatusButton data-testid={`${testId}current-status`} onClick={this.toggleExpanded}>
            {t(ServiceRequestStatusMap.get(status, ''))}
            <ExpandCollapse expanded={expanded} />
          </StatusButton>
          {expanded &&
            <DropdownContent>
              <ul>
                {ServiceRequestStatusMap.entrySeq().map(([statusType, statusName]) =>
                  (<ServiceRequestStatusItem
                    active={this.isItemActive(statusType)}
                    key={statusType}
                    onClick={this.handleStatusItemClick(statusType)}
                    type={statusType}
                    name={statusName}
                    testId={testId}
                  />),
                )}
              </ul>
            </DropdownContent>
          }
        </StatusContainer>
      </>
    )
  }

  private readonly isItemActive = (itemStatusType: ServiceRequestStatusType) => itemStatusType === this.props.status

  private readonly handleStatusItemClick = (type: ServiceRequestStatusType) => () => {
    this.toggleExpanded()
    this.props.onStatusChange(type)
  }

  private readonly toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }
}

export const ServiceRequestStatus = withTranslation()(ServiceRequestStatusComponent)
