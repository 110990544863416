import 'lightbox-react/style.css' // tslint:disable-line
import styled from 'styled-components'
import { colors } from 'styles'

export const SettingButton = styled.button`
  background: ${colors.white};
  border: 1px solid ${colors.mysticGray};
  border-radius: 4px;
  color: ${colors.dark};
  height: 24px;
  font-size: 15px;
  font-weight: bold;
  width: 24px;
  touch-action: manipulation;

  &:disabled {
    color: ${colors.swirlGold};
  }
`

export const SettingsButtons = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  touch-action: manipulation;
`
export const ImageWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 47px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  touch-action: manipulation;
`

export const ImagesWrapper = styled.div`
  height: 100%;
  padding: 24px;
  position: relative;
  overflow: hidden;
  touch-action: manipulation;
`

export const PreviewImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  touch-action: manipulation;
`

export const CropperWrapper = styled.div`
  align-items: center;
  background: ${colors.mysticGray}
  border-radius: 4px;
  grid-row: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  touch-action: manipulation;
`

export const ButtonsPanel = styled.div`
  padding-top: 10px;
`

export const PaginationWrapper = styled.div`
  bottom: 20px;
  position: absolute;
  right: 24px;
  touch-action: manipulation;
`
