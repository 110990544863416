import { BtnTheme, BtnType } from 'actff-bo-app/components/Button'
import { Field } from 'actff-bo-app/components/Form'
import { Icon } from 'actff-bo-app/components/Icon'
import { PreviewFiles, UploadButton, UploadFile } from 'actff-bo-app/components/UploadFile'

import { Textarea } from 'actff-bo-app/Crm/Styled'
import { InspectionAttachmentAcceptTypes } from 'actff-bo-lib/car'
import { Attachment } from 'actff-bo-lib/global'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OpportunityInternalCommentValues } from '../OpportunityView'
import { AddButton, CommentRequiredIndicator, OpportunityAddInternalCommentContainer } from './Styled'

type Props = {
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  readonly onAttachmentChange: (attachments: ReadonlyArray<Attachment>) => void
  readonly onAttachmentDelete: (attachment: Attachment) => () => void,
  readonly values: OpportunityInternalCommentValues,
  readonly addFormVisible?: boolean,
  readonly required?: boolean,
}

export const OpportunityAddInternalComment: FC<Props> = ({
  addFormVisible,
  onAttachmentChange,
  onAttachmentDelete,
  onChange,
  required,
  values,
}) => {
  const [ isAddFormVisible, setAddFormVisible ] = useState(false)
  const toggleIsAddFormVisible = () => setAddFormVisible(!isAddFormVisible)
  const { t } = useTranslation()

  useEffect(() => {
    setAddFormVisible(addFormVisible || false)
  }, [addFormVisible])

  const renderAddCommentField = () => (
    <>
      <Textarea id='comment' name='comment' onChange={onChange} />
      {required && (
        <CommentRequiredIndicator>
          <Icon type={IconType.Info} />
          {t('internalComments.required')}
        </CommentRequiredIndicator>
      )}
      <PreviewFiles files={values.attachments} onDeleteFile={onAttachmentDelete} />
      <Field>
        {() => (
          <UploadFile
            acceptTypes={InspectionAttachmentAcceptTypes.join(', ')}
            component={UploadButton}
            onChange={onAttachmentChange}
            multiple={true}
          />
        )}
      </Field>
    </>
  )

  const renderFormOpenButton = () => (
    <AddButton
      caption={t('internalComments.add')}
      iconType={IconType.Plus}
      noPadding={true}
      onClick={toggleIsAddFormVisible}
      theme={BtnTheme.Link}
      type={BtnType.Button}
    />
  )

  return (
    <OpportunityAddInternalCommentContainer>
      {isAddFormVisible ? renderAddCommentField() : renderFormOpenButton()}
    </OpportunityAddInternalCommentContainer>
  )
}
