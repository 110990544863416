import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import styled from 'styled-components'

export const AddNewClientButton = styled(ButtonWithIcon)`
  bottom: 0;
  // TODO hidden until API ready
  display: none;
  max-width: 180px;
  padding: 7px;
  position: absolute;
  right: 15px;
`

export const Container = styled.div`
  position: relative;
`
