import { Loader } from 'actff-bo-app/components/Loader'
import { WithPagination } from 'actff-bo-app/components/Table/Pagination'
import { OwnershipAction } from 'actff-bo-lib/car/actions'
import { Counterparty } from 'actff-bo-lib/car/dto'
import {
  selectCounterpartyList,
  selectCounterpartyListCurrentPage,
  selectCounterpartyListSearchPhrase,
} from 'actff-bo-lib/car/selectors'
import { Path } from 'actff-bo-lib/menu/dto'
import { Paths } from 'actff-bo-lib/menu/initial-menu'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CounterpartyList } from './PersonList'
import { SearchPersonDialog } from './SearchPersonDialog'

type Props = {
  readonly header: string,
  readonly open: boolean,
  readonly onClose: () => void,
  readonly onConfirm: (person: Counterparty) => void,
}

const testId = 'search-dms-person__'

export const SearchCounterpartyPerson: FC<Props> = ({ header, onClose, onConfirm, open }) => {
  const dispatch = useDispatch()
  const [selectedNdcPerson, setSelectedNdcPerson] = useState<Counterparty | null>(null)
  const searchPhrase = useSelector(selectCounterpartyListSearchPhrase)

  const getCounterpartyList = () => { dispatch(OwnershipAction.getCounterpartyList()) }

  const changeSearchPhrase = (newSearchPhrase: string) => { dispatch(OwnershipAction.changeCounterpartyListSearchPhrase(newSearchPhrase)) }

  const handleChangePage = (nextPage: number) => {
    dispatch(OwnershipAction.changeCounterpartyListPage(nextPage))
  }

  const handleConfirm = () => {
    if (selectedNdcPerson) {
      onConfirm(selectedNdcPerson)
      setSelectedNdcPerson(null)
    }
  }

  const handleSearch = (newSearchPhrase: string) => {
    changeSearchPhrase(newSearchPhrase)
    getCounterpartyList()
  }

  const handleSelectPerson = (person: Counterparty) => {
    setSelectedNdcPerson(person)
  }

  const handleClose = () => {
    changeSearchPhrase('')
    setSelectedNdcPerson(null)
    onClose()
  }

  const { loading, data } = useSelector(selectCounterpartyList)
  const currentPage = useSelector(selectCounterpartyListCurrentPage)
  const list = () => data?.result || []

  return (
    <SearchPersonDialog
      confirmDisabled={!selectedNdcPerson}
      header={header}
      onClose={handleClose}
      onConfirm={handleConfirm}
      onSearch={handleSearch}
      open={open}
      searchPhrase={searchPhrase}
      testId={`${testId}dialog`}
    >
      {
        loading ? <Loader /> : (
          <WithPagination
            currentPage={currentPage}
            onPageChange={handleChangePage}
            pageCount={data?.noOfPages || 0}
            path={Paths.CarViewBasicData as Path}
            rowsInCurrentPage={data?.result.length || 0}
            testId={testId}
          >
            <CounterpartyList list={list()} onPersonClick={handleSelectPerson} selectedPerson={selectedNdcPerson} testId={testId} />
          </WithPagination>
        )
      }
    </SearchPersonDialog>
  )
}
