import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { ServiceRequestStatus, ServiceRequestStatusMap } from 'actff-bo-lib/service-request'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly status: ServiceRequestStatus,
  readonly updatedTime: Date,
}

export const StatusInfo: React.FC<Props> = ({ status, updatedTime }) => {
  const { t } = useTranslation()

  return (
    <>
      <div>{t(ServiceRequestStatusMap.get(status, ''))}</div>
      {status === ServiceRequestStatus.CANCELLED && <FormattedDate date={updatedTime} />}
    </>
  )
}
