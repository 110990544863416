import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { InsurerQuestion } from 'actff-bo-lib/admin/dealership/dto'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TdWithDelete } from '../../Styled'

type Props = {
  readonly index: number,
  readonly item: InsurerQuestion,
  readonly onDelete: (uuid: string | null) => void,
  readonly onEdit: (question: InsurerQuestion) => void,
}

const Menu = styled.span`
  float: right;
`

const TdWithDeleteQuestion = styled(TdWithDelete)`
  display: grid;
  grid-template-columns: 1fr 200px;
`

const QuestionContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const InsurersQuestionsRow: FC<Props> = ({ index, onDelete, onEdit, item }) => {
  const { t } = useTranslation()
  const [menuVisible, setMenuVisible] = useState(false)
  const showMenu = () => {
    setMenuVisible(true)
  }
  const hideMenu = () => {
    setMenuVisible(false)
  }

  const handleStopEvents = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDelete = (uuid: string | null) => () => { onDelete(uuid) }

  const handleEdit = (question: InsurerQuestion) => () => { onEdit(question) }

  return (
    <tr key={`${item.uuid} ${index}`}>
      <td onMouseOver={showMenu} onMouseLeave={hideMenu}>{index + 1}</td>
      <TdWithDeleteQuestion onMouseOver={showMenu} onMouseLeave={hideMenu}>
        <QuestionContent>{item.question}</QuestionContent>
        {menuVisible && (
          <Menu
            onMouseOver={handleStopEvents}
            onMouseLeave={hideMenu}
          >
            <ButtonWithIcon
              caption={t('caption.edit')}
              onClick={handleEdit(item)}
              iconType={IconType.Edit}
              noPadding={false}
              theme={BtnTheme.Link}
            />
            <ButtonWithIcon
              caption={t('caption.delete')}
              onClick={handleDelete(item.uuid)}
              iconType={IconType.Trash}
              noPadding={true}
              theme={BtnTheme.Link}
            />
          </Menu>
        )}
      </TdWithDeleteQuestion>
    </tr>
  )
}
