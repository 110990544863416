import { Loadable } from 'actff-bo-lib/global/data-types'
import {
  AdminLocationSettingsAction,
  AdminLocationSettingsActionType,
  AdminOpeningHoursAction,
  AdminOpeningHoursActionType,
} from './actions'
import { AdminDealerSettings, OpeningHours } from './dto'

export type LocationSettingsState = {
  readonly dealerSettings: Loadable<AdminDealerSettings>,
  readonly openingHours: Loadable<ReadonlyArray<OpeningHours>>,
}

const initialState: LocationSettingsState = {
  dealerSettings: {
    data: null,
    loading: true,
  },
  openingHours: {
    data: null,
    loading: true,
  },
}

export const locationSettingsReducer =
  // tslint:disable-next-line:cyclomatic-complexity
  (state: LocationSettingsState = initialState, action: AdminLocationSettingsAction | AdminOpeningHoursAction): LocationSettingsState => {
    switch (action.type) {
      case AdminOpeningHoursActionType.GetOpeningHours:
        return {
          ...state,
          openingHours: {
            ...state.openingHours,
            loading: true,
          },
        }

      case AdminOpeningHoursActionType.GetOpeningHoursFailure:
        return {
          ...state,
          openingHours: {
            data: null,
            loading: false,
          },
        }

      case AdminOpeningHoursActionType.GetOpeningHoursSuccess:
        return {
          ...state,
          openingHours: {
            data: action.payload,
            loading: false,
          },
        }

      case AdminOpeningHoursActionType.UpdateOpeningHoursSuccess:
        return {
          ...state,
          openingHours: {
            data: action.payload,
            loading: false,
          },
        }

      case AdminLocationSettingsActionType.GetDealerSettings:
        return {
          ...state,
          dealerSettings: {
            data: null,
            loading: true,
          },
        }

      case AdminLocationSettingsActionType.GetDealerSettingsFailure:
        return {
          ...state,
          dealerSettings: {
            data: null,
            loading: false,
          },
        }

      case AdminLocationSettingsActionType.GetDealerSettingsSuccess:
        return {
          ...state,
          dealerSettings: {
            data: action.payload,
            loading: false,
          },
        }

      case AdminLocationSettingsActionType.UpdateDealerSettingsSuccess:
        return {
          ...state,
          dealerSettings: {
            data: action.payload,
            loading: false,
          },
        }

      default:
        return state
    }
}
