import { defaultTimeFormat } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css' // tslint:disable-line no-import-side-effect
import styled from 'styled-components'
import { colors, theme } from 'styles'

type Props = Testable & {
  readonly date: Date | null,
  readonly disabled?: boolean,
  readonly error?: boolean,
  readonly format?: string,
  readonly interval?: number,
  readonly maxTime?: Date,
  readonly minTime?: Date,
  readonly onChange: (time: Date | null) => void,
}

type TimePickerProps = Testable & Props

const TimePickerContainer = styled.div`
  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100% !important;
  }
`

const TimePickerComponentStyled = styled(DatePicker)`
  border-radius: 3px;
  border: 1.5px solid;
  border-color: ${({ error }: Props) => error ? colors.flamingoRed : colors.mysticGray} !important;
  color: ${colors.dark};
  text-align: left;
`

const TimeContainerStyled = styled.div`
  font-weight: 300;

  .react-datepicker__time-container {
    background: white;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    min-width: 100px;
  }

  .react-datepicker__time-list-item:hover {
    background-color: ${theme.form.select.hover} !important;
    color: ${theme.form.select.hoverText} !important;
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${theme.form.select.selected} !important;
  }

  .react-datepicker__header {
    display: none;
  }

  div {
    width: 100% !important;
  }
`

const TimeContainer: React.FC = ({ children }) => (<TimeContainerStyled>{children}</TimeContainerStyled>)

export const TimePicker: React.FC<TimePickerProps> =
  ({
     date,
     disabled,
     error,
     format = defaultTimeFormat,
     interval,
     maxTime,
     minTime,
     onChange,
     testId,
   }) => (
  <TimePickerContainer {...(testId && { 'data-testid': `${testId}` })}>
    <TimePickerComponentStyled
      calendarContainer={TimeContainer}
      data-testid={testId}
      dateFormat={format}
      disabled={disabled}
      error={error}
      maxTime={maxTime}
      minTime={minTime}
      onChange={onChange}
      selected={date}
      showTimeSelect={true}
      showTimeSelectOnly={true}
      timeFormat={format}
      timeIntervals={interval}
    />
  </TimePickerContainer>
)
