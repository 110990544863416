import { Paper, Popper } from '@material-ui/core'
import { formatPhoneNumber } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconWrapper, PopperContent, StatusIcon } from './Styled'

type Props = {
  readonly phone?: string,
}

export const OpportunityRowPhone: React.FC<Props> = ({ phone }) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLSpanElement | null>(null)
  const open = Boolean(popoverAnchor)
  const { t } = useTranslation()

  if (!phone) {
    return null
  }

  const handleMouseEnter = (event: React.MouseEvent<HTMLSpanElement>) => {
    setPopoverAnchor(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setPopoverAnchor(null)
  }

  return (
    <>
      <IconWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
        <StatusIcon type={IconType.Call} />
      </IconWrapper>

      <Popper
        anchorEl={popoverAnchor}
        open={open}
        placement='top'
      >
        <Paper>
          <PopperContent>
            <b>{t('crmInsurance.row.contactNumber')}: </b><br />
            {formatPhoneNumber(phone)}
          </PopperContent>
        </Paper>
      </Popper>
    </>
  )
}
