import { Popover } from '@material-ui/core'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { Icon } from 'actff-bo-app/components/Icon'
import { Link } from 'actff-bo-app/components/Popover'
import { createRoute, IconType, Paths } from 'actff-bo-lib/menu'
import { NdcCar } from 'actff-bo-lib/ndc-car'
import { history } from 'actff-bo-lib/router'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly ndcCar: NdcCar,
}

const Container = styled.div`
  align-content: center;
  display: grid;
  height: 100%;
  padding: 10px;
`

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
`

export const NdcCarRowMenu: FC<Props> = ({ ndcCar }) => {
  const { t } = useTranslation()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)
  const [confirmOpportunityManualOpen, setConfirmOpportunityManualOpen] = useState(false)

  const handleMenuIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
    setPopoverOpen(true)
  }

  const closePopover = () => {
    setPopoverAnchor(null)
    setPopoverOpen(false)
  }

  const handleConfirmCreateOpportunity = () => {
    setConfirmOpportunityManualOpen(false)

    history.push(createRoute(Paths.CrmServiceOpportunityManual, { vin: ndcCar.vin }))
  }

  const toggleOpenConfirmDialog = () => {
    setConfirmOpportunityManualOpen(!confirmOpportunityManualOpen)
  }

  return (
    <>
      <Container>
        <IconContainer onClick={handleMenuIconClick}>
          <Icon type={IconType.More} />
        </IconContainer>
        <Popover
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          onClose={closePopover}
          open={popoverOpen}
          anchorEl={popoverAnchor}
        >
          <Link onClick={toggleOpenConfirmDialog}>
            <Icon type={IconType.Cog} />
            <span>{t('carList.columns.actions.createOpportunity')}</span>
          </Link>
        </Popover>
      </Container>
      <Dialog
        content='confirmDialog.opportunity.manual.content'
        open={confirmOpportunityManualOpen}
        onConfirm={handleConfirmCreateOpportunity}
        onClose={toggleOpenConfirmDialog}
        title='confirmDialog.opportunity.manual.title'
      />
    </>
  )
}
