import { Thread } from 'actff-bo-lib/chat'
import styled from 'styled-components'
import { colors, theme } from 'styles'

export type Props = {
  readonly thread: Thread,
  readonly active?: boolean,
  readonly unread?: boolean,
}

export const ChatThreadListContainer = styled.div`
  border-top: 1px solid ${colors.mysticGray};
  border-right: 1px solid ${colors.mysticGray};
`

export const ChatThreadListStyled = styled.ul`
  display: grid;
  grid-auto-flow: row;
  margin: 0;
  max-height: calc(100vh - 142px);
  overflow: auto;
`

export const ChatThreadHeader = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 5fr 2fr;
  align-items: center;
  border-bottom: 1px solid ${colors.mysticGray};
`

export const ChatThreadListItemStyled = styled.li`
  background: ${({ active }: Props) => active
    ? theme.chat.list.background.active
    : 'default'
  };
  border-top: ${({ active }: Props) => active
    ? `1px solid ${theme.chat.list.border}`
    : '1px solid transparent'};
  border-bottom: ${({ active }: Props) => active
    ? `1px solid ${theme.chat.list.border}`
    : '1px solid transparent'};
  display: grid;
  font-weight: ${({ unread }: Props) => unread
    ? '600'
    : '300'
  };
  grid-column-gap: 5px;
  grid-template:
    'name hour'
    'message icon';
  grid-template-columns: auto 80px;
  grid-row-gap: 5px;
  letter-spacing: 0.05em;
  padding: 7px 24px;
`

export const ChatThreadListItemName = styled.span`
  align-items: center;
  cursor: pointer;
  display: grid;
  font-weight: ${({ unread }: Props) => unread ? '700' : '600'};
  grid-auto-flow: column;
  grid-column-gap: 10px;
  justify-self: start;
`

export const ChatThreadListItemDate = styled.span`
  font-size: 10px;
  justify-self: right;
  text-align: right;
  justify-self: right;
`
