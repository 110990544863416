import { Tabs } from 'actff-bo-app/Navigation'
import { Testable } from 'actff-bo-lib/global/testable'
import { Path, Paths, TabMenuItem } from 'actff-bo-lib/menu'
import i18next from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { H2 } from 'styles'

import { LocationsDropdown } from '../DealershipDropdown'
import { ViewTabsHeader } from '../Styled'

const getTabItems = (testId: string, t: i18next.TFunction): ReadonlyArray<TabMenuItem> => ([
  {
    name: t('admin.dealerLocations.openingHours'),
    path: Paths.AdminLocationsOpeningHours as Path,
    testId: `${testId}tabs--opening-hours`,
  },
  {
    name: t('admin.dealerLocations.holidays'),
    path: Paths.AdminLocationsHolidays as Path,
    testId: `${testId}tabs--holidays`,
  },
  {
    name: t('admin.dealerLocations.visitsAvailability'),
    path: Paths.AdminLocationsVisitAvailability as Path,
    testId: `${testId}tabs--visits-availability`,
  },
  {
    name: t('admin.dealerLocations.contactDetails'),
    path: Paths.AdminLocationsContactDetails as Path,
    testId: `${testId}tabs--contact-details`,
  },
  {
    name: t('admin.dealerLocations.d2dCities'),
    path: Paths.AdminLocationsD2DCities as Path,
    testId: `${testId}tabs--d2d-cities`,
  },
  {
    name: t('admin.dealerLocations.brands'),
    path: Paths.AdminLocationsBrands as Path,
    testId: `${testId}tabs--brands`,
  },
  {
    name: t('admin.dealerLocations.applicationLinks'),
    path: Paths.AdminLocationsApplicationLinks as Path,
    testId: `${testId}tabs--application-links`,
  },
])

export const ViewTabs: React.FC<Testable> = ({ testId }) => {
  const { t } = useTranslation()

  return (
    <>
      <ViewTabsHeader>
        <div><H2>{t('admin.dealerLocations')}</H2></div>
        <LocationsDropdown testId={testId} />
      </ViewTabsHeader>
      <Tabs tabItems={getTabItems(testId as string, t)} />
    </>
  )
}
