export type Translations = {
  readonly [key: string]: string,
}

export type Resource = {
  readonly [key: string]: {
    readonly translation: Translations,
  },
}

export enum Language {
  pl = 'pl',
  en = 'en',
}

export const translationsAndLanguageToResource = (language: Language, translations: Translations): Resource => ({
  [language]: {
    translation: translations,
  },
})
