import styled from 'styled-components'
import { Header, Small, Span, theme } from 'styles'

export const CarViewHeaderStyled = styled(Header)`
  grid-auto-flow: unset;
  grid-template-columns: 1fr auto;
`

export const Form = styled.div`
  display: grid;
  grid-auto-flow: row;
`

export const CarData = styled.div`
  display: grid;
  grid-auto-flow: column;
`

export const NdcCarViewHeaderStyled = styled(Header)`
  height: 100px;
`

export const CarViewCreatedDateStyled = styled(Small)`
  justify-self: end;
`

export const ImageViewer = styled.div`
  background: ${theme.imageViewer.background};
  border-radius: 4px;
  grid-column: span 2;
  grid-row: span 9;
  margin: 0 32px;
  max-height: 90%;
  touch-action: none;
`

export const SpanStyled = styled(Span)`
  padding: 30px;
`

export const CarViewContainer = styled.div`
  position: relative;
`
