import { ActionsUnion } from '@evojam/redux-utils'

import { CrmInsuranceInsurerAction, CrmInsuranceInsurerActionType } from './insurer'
import { CrmInsuranceListAction, CrmInsuranceListActionType } from './list'
import { CrmInsuranceOpportunityAction, CrmInsuranceOpportunityActionType } from './opportunity'
import { CrmInsuranceReportAction, CrmInsuranceReportActionType } from './report'

export const CrmInsuranceActionType = {
  ...CrmInsuranceOpportunityActionType,
  ...CrmInsuranceListActionType,
  ...CrmInsuranceInsurerActionType,
  ...CrmInsuranceReportActionType,
}
export type CrmInsuranceActionType = CrmInsuranceOpportunityActionType
  | CrmInsuranceListActionType
  | CrmInsuranceInsurerActionType
  | CrmInsuranceReportActionType

export const CrmInsuranceAction = {
  ...CrmInsuranceListAction,
  ...CrmInsuranceInsurerAction,
  ...CrmInsuranceOpportunityAction,
  ...CrmInsuranceReportAction,
}

export type CrmInsuranceAction = ActionsUnion<
  typeof CrmInsuranceOpportunityAction
  & typeof CrmInsuranceListAction
  & typeof CrmInsuranceInsurerAction
  & typeof CrmInsuranceReportAction
>
