import { Client, ClientBasicData, ClientStatus } from 'actff-bo-lib/client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  readonly client: Client | ClientBasicData,
}

export const ClientFullName: React.FC<Props> = ({ client }) => {
  const { t } = useTranslation()

  return client?.status !== ClientStatus.Deleted
    ? <>{client?.firstName} {client?.lastName}</>
    : <>{t('caption.noData')}</>
}
