import { FiltersRendererProps } from 'actff-bo-app/components/TabFilters'
import { selectDealerLocations } from 'actff-bo-lib/dealership'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

type ListLocationsFiltersFormProps = FiltersRendererProps & {
  readonly control: Control,
  readonly filterFormNamePrefix?: string,
  readonly isNdc?: boolean,
}

const DealerLocationContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
`

export const ListLocationsFiltersForm: FC<ListLocationsFiltersFormProps> = ({ control, filterFormNamePrefix, isNdc = false }) => {
  const { data } = useSelector(selectDealerLocations)

  return (
    data && (
      <DealerLocationContainer>
        {data.map(location => {
          const { available, key, name, ndcName } = location
          const locationKey = isNdc ? ndcName : key

          return (
            <div key={locationKey}>
              <Controller
                render={({onChange, value}) => (
                  <input
                    checked={value}
                    disabled={!available}
                    id={`filter-locations-${locationKey}`}
                    onChange={() => onChange(!value)}
                    type='checkbox'
                    value={value}
                  />
                )}
                control={control}
                name={`${filterFormNamePrefix ? `${filterFormNamePrefix}.${locationKey}` : locationKey}`}
              />
              <label id={`filter-locations-${locationKey}`} htmlFor={`filter-locations-${locationKey}`}>{name}</label>
            </div>
          )
        })}
      </DealerLocationContainer>
    )
  )
}
