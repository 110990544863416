import { isNotNull } from 'actff-bo-lib/dictionary'
import { isString, SelectOption } from 'actff-bo-lib/global/data-types'
import i18next from 'i18next'

type MapToOptionProps = {
  readonly defaultValue?: string,
  readonly value: string | null,
  readonly tKey?: string,
  readonly t?: i18next.TFunction,
}

const getEmptyStringIfNull = (value: string | null | undefined) => value || ''

export function mapToOption({ value, tKey, t, defaultValue = '' }: MapToOptionProps): SelectOption<string> {
  if (!isNotNull(value)) {
    return ({ value: defaultValue, label: defaultValue as string })
  }

  const getTranslationIfString = () =>
    isString(value) ? t && t(`${tKey}.${value.toLowerCase()}`) : value

  if (tKey && t) {
    return ({ value: getEmptyStringIfNull(value), label: getEmptyStringIfNull(getTranslationIfString()) })
  }

  return ({ value: getEmptyStringIfNull(value), label: value as string })
}

export const mapToOptions = (keys: ReadonlyArray<string> | null, tKey?: string, t?: i18next.TFunction) =>
  keys?.map(value => mapToOption({ value, tKey, t })) || []

export function mapOptionsToArray<T>(
  options?: ReadonlyArray<SelectOption<T>> | null,
  defaultValue?: ReadonlyArray<T>,
): ReadonlyArray<T> | null {
  return options ? options.map(option => option.value) : defaultValue || null
}
