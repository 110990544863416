import { reactSelectStyles } from 'actff-bo-app/components/Form'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { searchCar, SearchCarResult } from 'actff-bo-lib/car'
import { SelectOption } from 'actff-bo-lib/global'
import { mapToOption, mapToOptions } from 'actff-bo-lib/global/form-mappers'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Controller, UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { ActionMeta, components, InputActionMeta, OptionTypeBase, SingleValueProps } from 'react-select'
import Creatable from 'react-select/creatable'
import { Subject } from 'rxjs'
import { debounceTime as debounce, distinctUntilChanged } from 'rxjs/operators'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable
  & Pick<UseFormMethods, 'control' | 'register' | 'reset' | 'setValue'>
  & {
    readonly name: keyof Pick<SearchCarResult, 'vin' | 'registrationNumber'>,
    readonly onValueChange:
      (field: string, value: SelectOption<string>, action: string, carSearchResults?: ReadonlyArray<SearchCarResult>) => void,
    readonly disabled?: boolean,
  }

const debounceTime = 800

const SingleValueInput = styled.input`
  width: 100%;
  border: 0;
  padding-left: 0;
  position: relative;
  top: -3px;
  ${({ disabled }: Props) =>
    disabled && `
      background-color: ${colors.athensGray};
      color: ${colors.dustyGray}
    `
  };
`

export const SearchCarField: FC<Props> = ({ control, name, setValue, testId, disabled, onValueChange }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [vinOrRegistrationNumber, setVinOrRegistrationNumber] = useState('')

  const { data: carSearchResults, isLoading: loadingCarSearchResults } = useQuery(
    [QueryKeys.SEARCH_CARS, { status: 'active', vinOrRegistrationNumber }],
    async () =>
      await searchCar(vinOrRegistrationNumber),
    { retry: false },
  )

  const inputRef = useRef<HTMLInputElement>(null)

  const setCarOptions = (results: ReadonlyArray<SearchCarResult>) => {
    const inputVal = inputRef.current?.value
    const options = mapToOptions(results.map(car => car[name]))
    const notExistingOption = { value: inputVal, label: `${t('caption.use')} "${inputVal}"?` }

    return [...options, notExistingOption]
  }

  const carOptions = carSearchResults && setCarOptions(carSearchResults)

  const inputValue$ = new Subject<string>()

  useEffect(() => {
    const inputValueSubscription = inputValue$.pipe(
      debounce(debounceTime),
      distinctUntilChanged(),
    ).subscribe(setVinOrRegistrationNumber)

    return () => inputValueSubscription.unsubscribe()
  }, [dispatch, inputValue$])

  useEffect(() => {
    const inputValueMenuSubscription = inputValue$.pipe(
      debounce(debounceTime),
      distinctUntilChanged(),
    ).subscribe(() => {
      setMenuIsOpen(true)
    })

    return () => inputValueMenuSubscription.unsubscribe()
  }, [setMenuIsOpen, inputValue$])

  const handleValueChange = (field: 'vin' | 'registrationNumber') =>
    (value: SelectOption<string>, { action }: ActionMeta<OptionTypeBase>) => {
    setMenuIsOpen(false)
    onValueChange(field, value, action, carSearchResults)
  }

  const handleInputChange = (phrase: string, event: InputActionMeta) => {
    if (event.action === 'input-change') {
      setMenuIsOpen(true)
      inputValue$.next(phrase)
    }
  }

  const handleSingleValueChange = (field: 'vin' | 'registrationNumber') => (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement

    inputValue$.next(target?.value)
    setValue(`car.${field}`, target.value, { shouldDirty: true })
  }

  const handleSingleValueKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement

    if (target.value.length > 0) {
      e.stopPropagation()
    }
  }

  const handleBlur = () => {
    setMenuIsOpen(false)
  }

  // Adds workaround for disappearing text selection on Windows
  const handleContextMenuOpen = () => {
    inputRef.current?.select()
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedText = e.clipboardData.getData('text')
    pastedText && setVinOrRegistrationNumber(pastedText)
  }

  const SingleValue = ({ children, ...props }: SingleValueProps<SelectOption<string>>) =>
    (
      <components.SingleValue {...props}>
        <SingleValueInput
          type='text'
          ref={inputRef}
          value={props.data.value}
          onChange={handleSingleValueChange(name)}
          onKeyDown={handleSingleValueKeyDown}
          onContextMenu={handleContextMenuOpen}
          onBlur={handleBlur}
          disabled={props.isDisabled}
        />
        {children}
      </components.SingleValue>
    )

  return (
    <Controller
      render={({ value }) =>
        <div onPaste={handlePaste}>
          <Creatable
            isDisabled={disabled}
            allowCreateWhileLoading={true}
            formatCreateLabel={text => `${t('caption.use')} "${text}"?`}
            isClearable={true}
            isLoading={loadingCarSearchResults}
            isMulti={false}
            menuIsOpen={menuIsOpen}
            loadingMessage={() => `${t('caption.loading')}`}
            noOptionsMessage={() => `${t('caption.noResults')}`}
            onChange={handleValueChange(name)}
            onInputChange={handleInputChange}
            onBlur={handleBlur}
            options={carOptions || []}
            placeholder={t('placeholder.choose')}
            styles={{...reactSelectStyles}}
            testId={`${testId}-carVin`}
            value={value && mapToOption({ value })}
            components={{ SingleValue }}
          />
        </div>}
      control={control}
      name={`car.${name}`}
    />
  )
}
