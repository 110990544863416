import { FormHeader } from 'actff-bo-app/components/Form'
import { ClientAction, ClientId, selectCurrentClientMatchedSaleOpportunity } from 'actff-bo-lib/client'
import { OpportunityPurchaseId } from 'actff-bo-lib/crm/trade'
import { CrmTradeOpportunityPurchaseAction, selectMatchedSaleOpportunities } from 'actff-bo-lib/crm/trade/purchase'
import { UserWithoutPermissions } from 'actff-bo-lib/user'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Table, ThLighterBold, Tr } from 'styles'

import { NoMatchedOpportunities } from '../Styled'
import { MatchedOpportunityRow } from './OpporunityRow'

const Container = styled.div`
  grid-area: matched-opportunities;
`

const FormHeaderStyled = styled(FormHeader)`
  padding: 0 0 16px 30px;
`
type Props = {
  readonly onRemoveClick?: (opportunityId: OpportunityPurchaseId) => void,
  readonly clientId?: ClientId,
  readonly currentUser?: UserWithoutPermissions,
  readonly purchaseId?: OpportunityPurchaseId,
}

export const MatchedSaleOpportunities: FC<Props> = ({ clientId, currentUser, purchaseId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const opportunity = useSelector(selectCurrentClientMatchedSaleOpportunity)
  const opportunities = useSelector(selectMatchedSaleOpportunities)
  const getIsNoDataSale = () => !opportunity && !opportunities

  const getClientSaleOpportunity = useMemo(() => (_clientId: ClientId) => {
    dispatch(ClientAction.getClientMatchedSaleOpportunity(_clientId))
  }, [dispatch])

  const getMatchedSaleOpportunities = useMemo(() => (id: OpportunityPurchaseId) => {
    dispatch(CrmTradeOpportunityPurchaseAction.getMatchedSaleOpportunities(id))
  }, [dispatch])

  useEffect(() => {
    if (clientId) {
      getClientSaleOpportunity(clientId)

      return
    }

    if (purchaseId) {
      getMatchedSaleOpportunities(purchaseId)
    }
  }, [clientId, getMatchedSaleOpportunities, purchaseId, getClientSaleOpportunity])

  const getHeader = () => t(
    clientId ? 'clientView.opportunities.matchedSaleOpportunity' : 'crmTrade.opportunityPurchase.matchedSaleOpportunities',
  )

  if (getIsNoDataSale()) {
    return (
      <Container>
        <FormHeaderStyled>
          {getHeader()}:
        </FormHeaderStyled>
        <NoMatchedOpportunities>
          {t(opportunities
            ? 'crmTrade.opportunityPurchase.noMatchedSaleOpportunities'
            : 'clientView.opportunities.matchedSaleOpportunity.noMatchedOpportunity')}:
        </NoMatchedOpportunities>
      </Container>
    )
  }

  return (
    <Container>
      <FormHeaderStyled>
        {getHeader()}:
      </FormHeaderStyled>
      <Table>
        <thead>
        <Tr>
          <ThLighterBold>{t('table.columns.status')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.fullName')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.phone')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.email')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.potentialBuyDateFrom')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.purchaseOpportunitiesCount')}:</ThLighterBold>
          <ThLighterBold />
        </Tr>
        </thead>
        <tbody>
        {opportunity ?
          <MatchedOpportunityRow currentUser={currentUser} key={opportunity.uuid} opportunity={opportunity}/>
          : opportunities.map(opportunitySale => (
            <MatchedOpportunityRow currentUser={currentUser} key={opportunitySale.uuid} opportunity={opportunitySale}/>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
