import { Tabs } from 'actff-bo-app/Navigation'
import { CarInfoWithClient, CarStatus } from 'actff-bo-lib/car'
import { Testable } from 'actff-bo-lib/global/testable'
import { Path, Paths, TabMenuItem } from 'actff-bo-lib/menu'
import i18next from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type Props = Testable & {
  readonly car: CarInfoWithClient,
  readonly isNdcCar: boolean,
}

const getTabItems = (
  car: CarInfoWithClient,
  isNdcCar: boolean,
  t: i18next.TFunction,
  testId?: string,
): ReadonlyArray<TabMenuItem> => {
  const isAppCarOrStatusNew = () => !isNdcCar || car.status === CarStatus.NEW
  const isNdcCarOrStatusNew = () => isNdcCar || car.status === CarStatus.NEW

  return ([
    {
      hidden: !isNdcCar,
      name: t('carView.tab.basicData'),
      parameters: { vin: car.vin },
      path: Paths.NdcCarViewBasicData as Path,
      testId: `${testId}-basic-data`,
    },
    {
      hidden: isNdcCarOrStatusNew(),
      name: t('carView.tab.basicData'),
      parameters: { carId: car.uuid },
      path: Paths.CarViewBasicData as Path,
      testId: `${testId}-basic-data`,
    },
    {
      hidden: isNdcCar,
      name: t('carView.tab.service'),
      parameters: { carId: car.uuid },
      path: Paths.CarViewService as Path,
      testId: `${testId}-service`,
    },
    {
      hidden: isNdcCar,
      name: t('carView.tab.insurance'),
      parameters: { carId: car.uuid },
      path: Paths.CarViewInsurance as Path,
      testId: `${testId}-insurance`,
    },
    {
      hidden: isNdcCar,
      name: t('carView.tab.finance'),
      parameters: { carId: car.uuid },
      path: Paths.CarViewFinances as Path,
      testId: `${testId}-finance`,
    },
    {
      hidden: isNdcCar,
      name: t('carView.tab.tires'),
      parameters: { carId: car.uuid },
      path: Paths.CarViewTires as Path,
      testId: `${testId}-tires`,
    },
    {
      hidden: !isNdcCar,
      name: t('carView.tab.tires'),
      parameters: { vin: car.vin },
      path: Paths.NdcCarViewTires as Path,
      testId: `${testId}-ndc-tires`,
    },
    {
      hidden: isAppCarOrStatusNew(),
      name: t('carView.tab.equipment'),
      parameters: { vin: car.vin },
      path: Paths.NdcCarViewEquipment as Path,
      testId: `${testId}-ndc-equipment`,
    },
    {
      hidden: isAppCarOrStatusNew(),
      name: t('carView.tab.Inspections'),
      parameters: { vin: car.vin },
      path: Paths.NdcCarViewInspections as Path,
      testId: `${testId}-inspection`,
    },
    {
      hidden: isNdcCarOrStatusNew(),
      name: t('carView.tab.equipment'),
      parameters: { carId: car.uuid },
      path: Paths.CarViewEquipment as Path,
      testId: `${testId}-tires`,
    },
    {
      hidden: isNdcCarOrStatusNew(),
      name: t('carView.tab.Inspections'),
      parameters: { carId: car.uuid },
      path: Paths.CarViewInspections as Path,
      testId: `${testId}-inspection`,
    },
  ])
}

export const CarViewTabs: React.FC<Props> = ({ car, isNdcCar, testId }) => {
  const { t } = useTranslation()

  return <Tabs testId={testId} tabItems={getTabItems(car, isNdcCar, t, testId)} />
}
