import { NotificationAction } from 'actff-bo-lib/notification'
import * as React from 'react'
import { connect, MapDispatchToProps } from 'react-redux'

type DispatchToProps = {
  readonly logout: () => void,
}

class LogoutComponent extends React.Component<DispatchToProps> {
  public componentDidMount(): void {
    this.props.logout()
  }

  public readonly render = (): React.ReactNode => null
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  logout: () => { dispatch(NotificationAction.unregisterToken()) },
})

export const Logout = connect(null, mapDispatchToProps)(LogoutComponent)
