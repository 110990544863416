import { api } from 'actff-bo-lib/api'
import { DashboardData } from 'actff-bo-lib/dashboard/dto'
import { DateRange } from 'actff-bo-lib/date'
import { buildUrl } from 'actff-bo-lib/global'
import { format } from 'date-fns'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { DashboardDataFromApi, DashboardDataGroupByType, DashboardDataUrlType, toDashboardData } from './dto'

export const getDashboardSummaryData = () => api.get('/dashboard/summary')

export const getDashboardDetailedData = (
  groupBy: DashboardDataGroupByType,
  { from: fromDate, to: toDate }: DateRange,
  type: DashboardDataUrlType,
): Observable<DashboardData> => {
  const from = fromDate ? format(fromDate, 'YYYY-MM-DD') : null
  const to = toDate ? format(toDate, 'YYYY-MM-DD') : null

  return api.get<DashboardDataFromApi>(buildUrl(`${type}`, { from, groupBy, to }))
    .pipe(
      map(toDashboardData),
    )
}
