import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Label } from 'actff-bo-app/components/Label'
import { isCounterparty } from 'actff-bo-lib/car'
import { getFullName } from 'actff-bo-lib/client'
import { maxMatchedOpportunityNumber, OpportunityPurchase, OpportunitySaleId } from 'actff-bo-lib/crm/trade'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { getTranslation } from 'actff-bo-lib/i18n'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles'

import { LabelStyled, Td, Tr } from './Styled'

type Props = {
  readonly onClick: (opportunity: OpportunityPurchase) => void,
  readonly opportunity: OpportunityPurchase,
  readonly saleUuid?: OpportunitySaleId,
  readonly selected: boolean,
  readonly testId: string,
}

type ClickableTdProps = {
  readonly dataTestId: string,
  readonly children: ReactElement,
  readonly color?: string,
}

const GetClickableTd = (href: string): FC<ClickableTdProps> => ({ color, children, dataTestId }) => {
  const handleClick = () => { window.open(href, '_blank') }

  return (
    <Td onClick={handleClick} color={color} dataTestId={dataTestId}>
      {children}
    </Td>
  )
}

export const OpportunityRow: FC<Props> = ({ onClick, selected, opportunity, saleUuid, testId }) => {
  const { t } = useTranslation()
  const { car, client, matches, owner, uuid } = opportunity
  const ClickableTd = GetClickableTd(createRoute(Paths.CrmTradePurchaseOpportunity, { opportunityId: uuid }))
  const getName = () => isCounterparty(owner) ? owner.name : getFullName(client)

  const getMatchesLength = () => matches ?
    (matches.some(match => match.saleUuid === saleUuid) && !selected)
      ? matches.length - 1
      : matches.length
    : 0

  const handleClick = () => {
    if (selected) {
      onClick(opportunity)

      return
    }

    if (!hasMaxMatches) {
      onClick(opportunity)
    }
  }

  const hasMaxMatches = getMatchesLength() >= maxMatchedOpportunityNumber
  const getMatchCountColor = () => hasMaxMatches ? colors.red : colors.green

  return (
    <Tr selected={selected} disabled={hasMaxMatches}>
      <Td dataTestId={`${testId}selected`} onClick={handleClick}>
        <Label><input type='checkbox' readOnly={true} checked={selected}/><LabelStyled/></Label>
      </Td>
      <ClickableTd dataTestId={`${testId}status`}><>{getTranslation(t, 'crmTrade.status.', opportunity.status)}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}action-date`}>
        <FormattedDate date={opportunity.actionDate} format={defaultDateFormat} />
      </ClickableTd>
      <ClickableTd dataTestId={`${testId}name`}><>{getName()}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}vin-number`}><>{car.vin}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}car-model`}><>{car.model}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}production-year`}><>{car.productionYear}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}known-mileage`}><>{car.knownMileage}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}fuel`}><>{car.fuel}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}market-value`}><>{car.marketValue} {t('currency.pln')}</></ClickableTd>
      <ClickableTd dataTestId={`${testId}match-count`} color={getMatchCountColor()}><>{getMatchesLength()}</></ClickableTd>
    </Tr>
  )
}
