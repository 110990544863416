import { Icon } from 'actff-bo-app/components/Icon'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import styled from 'styled-components'

import { BtnTheme, BtnType, ButtonComponent } from './Button'

type Props = Testable & {
  readonly caption: string,
  readonly iconType: IconType,
  readonly className?: string,
  readonly disabled?: boolean,
  readonly onClick?: () => void,
  readonly noPadding?: boolean,
  readonly type?: BtnType,
  readonly theme?: BtnTheme,
}

const ButtonIcon = styled(Icon)`
  margin-right: 5px;
`

const ButtonCaption = styled.span`
  position: relative;
  top: -1px;
`

export const ButtonWithIcon: React.FC<Props> = ({
  caption,
  className,
  disabled,
  iconType,
  noPadding,
  onClick,
  testId,
  theme = BtnTheme.Primary,
  type = BtnType.Button,
}) => {
  const Button = ButtonComponent(theme)

  return (
    <Button
      {...(testId && { 'data-testid': `${testId}` })}
      className={className}
      disabled={disabled}
      onClick={onClick}
      noPadding={noPadding}
      type={type}
    >
      {!disabled && <ButtonIcon type={iconType} />}
      <ButtonCaption>{caption}</ButtonCaption>
    </Button>
  )
}
