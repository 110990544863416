import { DateRange } from 'actff-bo-lib/date'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'

import { DashboardAction, DashboardActionType } from './actions'
import { getDashboardDetailedData, getDashboardSummaryData } from './dao'
import { DashboardDataGroupByType, DashboardDataUrlType } from './dto'
import {
  selectDashboardDetailedDataDateRange,
  selectDashboardDetailedDataGroupBy,
  selectDashbordDetailedDataType,
} from './selectors'

const getDashboardSummaryDataEpic: Epic<DashboardAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof DashboardAction.getDashboardSummaryData>>(DashboardActionType.GetDashboardSummaryData),
  switchMap(() => getDashboardSummaryData().pipe(
    map(DashboardAction.getDashboardSummaryDataSuccess),
    catchError((error: AjaxError) => of(DashboardAction.getDashboardSummaryDataFailure(error))),
  )),
)

const changeDashboardDetailedDataType: Epic<DashboardAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof DashboardAction.changeDashboardDetailedDataType>>(DashboardActionType.ChangeDashboardDetailedDataType),
  switchMap(() => [
    DashboardAction.changeDashboardDetailedDataDateRange(null),
  ]),
)

const changeDashboardDetailedDataDateRangeEpic: Epic<DashboardAction> = action$ => action$.pipe(
  ofType(
    DashboardActionType.ChangeDashboardDetailedDataDateRange,
    DashboardActionType.ChangeDashboardDetailedDataGroupBy,
  ),
  switchMap(() => [
    DashboardAction.getDashboardDetailedData(),
  ]),
)

const getDashboardDetailedDataEpic: Epic<DashboardAction, DashboardAction, State> = (action$, store$) => action$.pipe(
  ofType<ReturnType<typeof DashboardAction.getDashboardDetailedData>>(DashboardActionType.GetDashboardDetailedData),
  withLatestFrom(store$),
  map(([, state]) =>
    [selectDashbordDetailedDataType(state), selectDashboardDetailedDataDateRange(state), selectDashboardDetailedDataGroupBy(state)],
  ),
  switchMap(([type, range, groupBy]: [DashboardDataUrlType, DateRange, DashboardDataGroupByType]) =>
    getDashboardDetailedData(groupBy, range, type).pipe(
      map(DashboardAction.getDashboardDetailedDataSuccess),
      catchError((error: AjaxError) => of(DashboardAction.getDashboardDetailedDataFailure(error))),
    ),
  ),
)

export const dashboardEpic = combineEpics(
  changeDashboardDetailedDataType,
  changeDashboardDetailedDataDateRangeEpic,
  getDashboardSummaryDataEpic,
  getDashboardDetailedDataEpic,
)
