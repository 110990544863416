import { DashboardAction, DashboardActionType } from './actions'
import { DashboardState, initialState } from './state'
// tslint:disable-next-line cyclomatic-complexity
export const dashboardReducer = (state: DashboardState = initialState, action: DashboardAction): DashboardState => {
  switch (action.type) {
    case DashboardActionType.ChangeDashboardDetailedDataType:
      return {
        ...state,
        detailedData: {
          ...initialState.detailedData,
          type: action.payload,
        },
      }

    case DashboardActionType.ChangeDashboardDetailedDataGroupBy:
      return {
        ...state,
        detailedData: {
          ...initialState.detailedData,
          ...state.detailedData,
          groupBy: action.payload,
        },
      }

    case DashboardActionType.ChangeDashboardDetailedDataDateRange:
      return {
        ...state,
        detailedData: {
          ...initialState.detailedData,
          ...state.detailedData,
          ...action.payload,
        },
      }

    case DashboardActionType.GetDashboardSummaryDataSuccess:
      return {
        ...state,
        summaryData: action.payload,
      }

    case DashboardActionType.GetDashboardDetailedDataSuccess:
      return {
        ...state,
        detailedData: {
          ...state.detailedData,
          data: action.payload,
        },
      }

    default:
      return state
  }
}
