import { CalendarResourceType } from 'actff-bo-lib/admin'

import { CalendarResourceData } from './dto'

export const mapCalendarDataToResources =
  (data?: ReadonlyArray<CalendarResourceData>) =>
    data?.map(resourceData => {
      const { uuid, firstName, lastName, registrationNumber, type } = resourceData.resource
      const title = type === CalendarResourceType.REPLACEMENT_CAR ? `${registrationNumber}` : `${firstName} ${lastName}`

      return { id: uuid, title }
    })

export const mapCalendarDataToEvents = (data?: ReadonlyArray<CalendarResourceData>) =>
  data?.map(resourceData => {
    const resourceId = resourceData.resource.uuid

    const appointments = resourceData.appointments.map(appointment => {
      const { from, to } = appointment

      return {
        end: to,
        resourceId,
        start: from,
        title: `Appointment ${from} - ${to}`,
      }
    })

    const absences = resourceData.absences.map(absence =>
      ({
        end: absence.to,
        resourceId,
        start: absence.from,
        title: `${absence.type} ${absence.from} - ${absence.to}`,
      }))

    return appointments.concat(absences)
  }).reduce((acc, val) => acc.concat(val))
