import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, map, switchMap } from 'rxjs/operators'

import { DictionaryAction, DictionaryActionType } from './actions'
import { getFuelTypes, getTireTypes } from './dao'

export const getFuelTypeEpic: Epic<DictionaryAction> = action$ => action$.pipe(
  ofType(DictionaryActionType.GetFuelTypes),
  switchMap(() => getFuelTypes().pipe(
    map(DictionaryAction.getFuelTypesSuccess),
    catchError((error: AjaxError) => of(DictionaryAction.getFuelTypesFailure(error)),
  )),
))

export const getTireTypeEpic: Epic<DictionaryAction> = action$ => action$.pipe(
  ofType(DictionaryActionType.GetTireTypes),
  switchMap(() => getTireTypes().pipe(
    map(DictionaryAction.getTireTypesSuccess),
    catchError((error: AjaxError) => of(DictionaryAction.getTireTypesFailure(error)),
  )),
))

export const dictionaryEpic = combineEpics(
  getFuelTypeEpic,
  getTireTypeEpic,
)
