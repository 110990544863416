import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormFooter, FormFooterLeft, FormFooterRight } from 'actff-bo-app/components/Form'
import { InternalComments } from 'actff-bo-app/components/InternalComments'
import { Loader } from 'actff-bo-app/components/Loader'
import { defaultDateTimeFormat } from 'actff-bo-lib/date'
import { getLocale } from 'actff-bo-lib/date/locales'
import { InternalComment } from 'actff-bo-lib/global'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { IconType } from 'actff-bo-lib/menu/dto'
import { Service } from 'actff-bo-lib/service'
import {
  CarLeaveOption,
  parseRequestNumber,
  selectDraftServiceRequest,
  ServiceRequest,
  ServiceRequestAction,
  ServiceRequestId,
  ServiceRequestStatus,
} from 'actff-bo-lib/service-request'
import {
  selectCurrentServiceRequestAttachments,
  selectCurrentServiceRequestInternalComments,
  selectIsUpdatingServiceRequest,
} from 'actff-bo-lib/service-request/selectors'
import { State } from 'actff-bo-lib/store'
import { selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import { format } from 'date-fns'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import { colors, H2, Header, LinkButton, PrimaryButton, Small } from 'styles'

import { ServiceRequestAddInternalComment } from './ServiceRequestAddInternalComment'
import { ServiceRequestCarLeave } from './ServiceRequestCarLeave'
import { ServiceRequestClient } from './ServiceRequestClient'
import { ServiceRequestClientComments } from './ServiceRequestClientComments'
import { ServiceRequestServices } from './ServiceRequestServices'
import { ServiceRequestStatus as ServiceRequestStatusComponent } from './ServiceRequestStatus'
import { ClientContent, Content, Row, ServiceRequestContent } from './ServiceRequestViewStyled'
import { ServiceRequestViewTabs } from './ServiceRequestViewTabs'

type DispatchToProps = {
  readonly clearDraft: () => void,
  readonly clearServiceRequest: () => void,
  readonly getAvailableCarLeaveOptions: () => void,
  readonly getServiceRequest: (serviceRequestId: ServiceRequestId) => void,
  readonly sendInternalComment: (serviceRequest: ServiceRequest) => (text: string) => void,
  readonly setDraftCarLeaveOption: (option: CarLeaveOption) => void,
  readonly setDraftInterestedInEarlierDate: (value: boolean) => void,
  readonly setDraftServices: (services: ReadonlyArray<Service>) => void,
  readonly setDraftStatus: (status: ServiceRequestStatus) => void,
  readonly updateServiceRequestAndSetDraftAsInProgress: () => void,
  readonly undoChanges: () => void,
  readonly updateServiceRequestFromDraft: () => void,
}

type StateToProps = {
  readonly currentLanguage: Language,
  readonly isUpdatingServiceRequest: boolean,
  readonly serviceRequest: ServiceRequest | null,
  readonly serviceRequestAttachments: ReadonlyArray<string> | null,
  readonly serviceRequestInternalComments: ReadonlyArray<InternalComment> | null,
  readonly userPermissions: ReadonlyArray<UserPermissions>,
}

type ServiceRequestViewParams = {
  readonly serviceRequestId: string,
}

type ServiceRequestViewComponentState = {
  readonly isUpdating: boolean,
}

type ServiceRequestViewComponentProps = StateToProps
  & DispatchToProps
  & RouteComponentProps<ServiceRequestViewParams>
  & WithTranslation

const testId = 'service-request-view__'

class ServiceRequestViewComponent extends React.Component<ServiceRequestViewComponentProps, ServiceRequestViewComponentState> {
  public readonly state: ServiceRequestViewComponentState = {
    isUpdating: false,
  }

  public componentDidMount(): void {
    this.getServiceRequest()
    this.props.getAvailableCarLeaveOptions()
  }

  public UNSAFE_componentWillReceiveProps(newProps: ServiceRequestViewComponentProps): void {
    this.assignAndSetServiceRequestAsInProgress(newProps)

    if (this.getServiceRequestId(this.props) !== this.getServiceRequestId(newProps)) {
      this.getServiceRequest()
    }
  }

  public componentWillUnmount(): void {
    this.props.clearDraft()
    this.props.clearServiceRequest()
  }

  public render(): React.ReactNode {
    const {
      currentLanguage,
      isUpdatingServiceRequest,
      sendInternalComment,
      serviceRequest,
      serviceRequestAttachments,
      serviceRequestInternalComments,
      setDraftCarLeaveOption,
      setDraftInterestedInEarlierDate,
      setDraftServices,
      setDraftStatus,
      t,
      undoChanges,
      userPermissions,
    } = this.props

    const locale = getLocale(currentLanguage)

    return serviceRequest
      ? (
        <>
          <Header>
            <H2 data-testid={`${testId}header`}>
              {t('serviceRequestView.header', { number: parseRequestNumber(serviceRequest.requestNumber) })}
            </H2>
            <Small data-testid={`${testId}created-time`}>
              {format(serviceRequest.createdTime, defaultDateTimeFormat, { locale })}
            </Small>
          </Header>
          <ServiceRequestViewTabs serviceRequest={serviceRequest} testId={testId} userPermissions={userPermissions} />
          <Content>
            <ServiceRequestContent>
              <Row>
                <ServiceRequestStatusComponent onStatusChange={setDraftStatus} status={serviceRequest.status} testId={testId} />
              </Row>
              <Row>
                <ServiceRequestServices onServicesChange={setDraftServices} services={serviceRequest.services} testId={testId} />
              </Row>
              <Row>
                <ServiceRequestCarLeave
                  carLeaveOption={serviceRequest.carLeaveOption}
                  interestedInEarlierDate={serviceRequest.interestedInEarlierDate}
                  onCarLeaveOptionChange={setDraftCarLeaveOption}
                  onInterestedInEarlierDateChange={setDraftInterestedInEarlierDate}
                  testId={testId}
                />
              </Row>
              {(serviceRequest.additionalRemarks || serviceRequestAttachments) &&
                <Row color={colors.athensGray}>
                  <ServiceRequestClientComments
                    clientAttachments={serviceRequestAttachments}
                    clientComment={serviceRequest.additionalRemarks}
                    testId={testId}
                  />
                </Row>
              }
              <InternalComments
                comments={serviceRequestInternalComments}
                currentLanguage={currentLanguage}
                testId={testId}
              />
              <ServiceRequestAddInternalComment onSend={sendInternalComment(serviceRequest)} testId={testId} />
            </ServiceRequestContent>
            <ClientContent>
              <ServiceRequestClient car={serviceRequest.car} client={serviceRequest.client} testId={testId} />
            </ClientContent>
          </Content>
          <FormFooter>
            <FormFooterLeft>
              <ButtonWithIcon
                caption={t('caption.undoChanges')}
                iconType={IconType.Undo}
                onClick={undoChanges}
                testId={`${testId}button--undo`}
                theme={BtnTheme.Link}
              />
              <ButtonWithIcon
                caption={t('caption.postpone')}
                iconType={IconType.Clock}
                testId={`${testId}button--postpone`}
                theme={BtnTheme.Link}
              />
            </FormFooterLeft>
            <FormFooterRight>
              <LinkButton
                data-testid={`${testId}button--cancel`}
                disabled={isUpdatingServiceRequest}
                onClick={this.setDraftStatusAsCancelledAndUpdate}
              >
                {t('serviceRequest.cancel')}
              </LinkButton>
              <PrimaryButton
                data-testid={`${testId}button--accept`}
                disabled={isUpdatingServiceRequest}
                onClick={this.update}
              >
                {t('caption.accept')}
              </PrimaryButton>
            </FormFooterRight>
          </FormFooter>
        </>
      )
      : <Loader />
  }

  private readonly getServiceRequestId = (props: ServiceRequestViewComponentProps) =>
    props.match.params.serviceRequestId as ServiceRequestId

  private readonly getServiceRequest = () => { this.props.getServiceRequest(this.getServiceRequestId(this.props)) }

  private readonly update = () => {
    this.props.updateServiceRequestFromDraft()
  }

  private readonly setDraftStatusAsCancelledAndUpdate = () => {
    this.props.setDraftStatus(ServiceRequestStatus.CANCELLED)
    this.update()
  }

  private readonly assignAndSetServiceRequestAsInProgress = (
    newProps: ServiceRequestViewComponentProps,
  ) => {
    if (
      !this.props.serviceRequest
      && newProps.serviceRequest
      && newProps.serviceRequest.status === ServiceRequestStatus.NEW
    ) {
      newProps.updateServiceRequestAndSetDraftAsInProgress()
    }
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  clearDraft: () => { dispatch(ServiceRequestAction.clearDraft()) },
  clearServiceRequest: () => { dispatch(ServiceRequestAction.clearServiceRequest()) },
  getAvailableCarLeaveOptions: () => { dispatch(ServiceRequestAction.getAvailableCarLeaveOptions()) },
  getServiceRequest: (serviceRequestId: ServiceRequestId) => { dispatch(ServiceRequestAction.getServiceRequest(serviceRequestId)) },
  sendInternalComment: (serviceRequest: ServiceRequest) => (text: string) => {
    dispatch(ServiceRequestAction.sendInternalComment(serviceRequest.uuid, text))
  },
  setDraftCarLeaveOption: (option: CarLeaveOption) => { dispatch(ServiceRequestAction.setDraftCarLeaveOption(option)) },
  setDraftInterestedInEarlierDate: (value: boolean) => { dispatch(ServiceRequestAction.setDraftInterestedInEarlierDate(value)) },
  setDraftServices: (services: ReadonlyArray<Service>) => { dispatch(ServiceRequestAction.setDraftServices(services)) },
  setDraftStatus: (status: ServiceRequestStatus) => { dispatch(ServiceRequestAction.setDraftStatus(status)) },
  undoChanges: () => { dispatch(ServiceRequestAction.undoChanges()) },
  updateServiceRequestAndSetDraftAsInProgress: () => { dispatch(ServiceRequestAction.updateServiceRequestAsInProgress()) },
  updateServiceRequestFromDraft: () => { dispatch(ServiceRequestAction.updateServiceRequestFromDraft())},
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  currentLanguage: selectCurrentLanguage(state),
  isUpdatingServiceRequest: selectIsUpdatingServiceRequest(state),
  serviceRequest: selectDraftServiceRequest(state),
  serviceRequestAttachments: selectCurrentServiceRequestAttachments(state),
  serviceRequestInternalComments: selectCurrentServiceRequestInternalComments(state),
  userPermissions: selectUserPermissions(state),
})

export const ServiceRequestView = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ServiceRequestViewComponent)
