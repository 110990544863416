import { CrmTradeOpportunityPurchaseAction, CrmTradeOpportunityPurchaseActionType } from './actions'
import { initialDetailedFiltersFormValues } from './filters'
import { initialState, OpportunityPurchaseState } from './state'

export const opportunityPurchaseReducer =
// tslint:disable-next-line:cyclomatic-complexity
  (state: OpportunityPurchaseState = initialState, action: CrmTradeOpportunityPurchaseAction): OpportunityPurchaseState => {
    switch (action.type) {
      case CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListCurrentPage:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            currentPage: action.payload.page,
            list: {
              loading: true,
            },
          },
        })

      case CrmTradeOpportunityPurchaseActionType.ChangeSearchMatchingOpportunitiesCurrentPage:
        return ({
          ...state,
          all: {
            ...state.all,
            currentPage: action.payload.page,
            list: {
              data: null,
              loading: true,
            },
          },
        })

      case CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListTimeFrom:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            from: action.payload.date,
            list: {
              loading: true,
            },
          },
        })

      case CrmTradeOpportunityPurchaseActionType.ChangeOpportunityListTimeTo:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              loading: true,
            },
            to: action.payload.date,
          },
        })

      case CrmTradeOpportunityPurchaseActionType.ChangeAssignmentFilter:
        return {
          ...state,
          assignmentFilter: action.payload,
        }

      case CrmTradeOpportunityPurchaseActionType.ChangeListsStatusFilters:
        return {
          ...state,
          statusFilters: {
            ...state.statusFilters,
            [action.payload.param]: [
              ...state.statusFilters.statuses
                .map(filter => filter.value === action.payload.value
                  ? ({
                    ...filter,
                    selected: !filter.selected,
                  })
                  : ({ ...filter }),
                ),
            ],
          },
        }

      case CrmTradeOpportunityPurchaseActionType.ChangeListsDetailedFilters:
        return {
          ...state,
          detailedFiltersFormValues: action.payload,
        }

      case CrmTradeOpportunityPurchaseActionType.ClearCurrentOpportunity:
        return {
          ...state,
          currentOpportunity: null,
        }

      case CrmTradeOpportunityPurchaseActionType.ClearPurchaseState:
        return {
          ...state,
          all: {
            ...state.all,
            currentPage: 1,
            list: {
              data: null,
              loading: false,
            },
          },
          currentMatchedSaleOpportunities: [],
          searchPhrase: '',
        }

      case CrmTradeOpportunityPurchaseActionType.ClearListsDetailedFilters:
        return {
          ...state,
          detailedFiltersFormValues: initialDetailedFiltersFormValues,
          stringifiedDetailedFilters: '',
        }

      case CrmTradeOpportunityPurchaseActionType.SaveStringifiedDetailedFilters:
        return {
          ...state,
          stringifiedDetailedFilters: action.payload,
        }

      case CrmTradeOpportunityPurchaseActionType.ChangeSearchMatchingOpportunitiesSearchPhrase:
      case CrmTradeOpportunityPurchaseActionType.ChangeSearchPhrase:

        const { to, ...currentFutureListState } = state.future

        return action.payload
          ? ({
            ...state,
            future: {
              ...currentFutureListState,
            },
            searchPhrase: action.payload,
          })
          : ({
            ...initialState,
            assignmentFilter: state.assignmentFilter,
            detailedFiltersFormValues: state.detailedFiltersFormValues,
            statusFilters: state.statusFilters,
          })

      case CrmTradeOpportunityPurchaseActionType.GetOpportunities:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              loading: true,
            },
          },
        })

      case CrmTradeOpportunityPurchaseActionType.GetMatchingPurchaseOpportunities:
        return ({
          ...state,
          all: {
            ...state.all,
            currentPage: 1,
          },
        })

      case CrmTradeOpportunityPurchaseActionType.GetMatchedSaleOpportunitiesSuccess:
        return ({
          ...state,
          currentMatchedSaleOpportunities: action.payload,
        })

      case CrmTradeOpportunityPurchaseActionType.GetMatchingPurchaseOpportunitiesSuccess:
        return ({
          ...state,
          all: {
            ...state.all,
            list: {
              data: action.payload,
              loading: false,
            },
          },
        })

      case CrmTradeOpportunityPurchaseActionType.GetOpportunitiesFailure:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              loading: false,
            },
          },
        })

      case CrmTradeOpportunityPurchaseActionType.GetOpportunitiesSuccess:
        return ({
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            list: {
              data: action.payload.opportunityList,
              loading: false,
            },
          },
        })

      case CrmTradeOpportunityPurchaseActionType.GetOpportunitySuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunityPurchaseActionType.ResetPaginationOnLists:
        return ({
          ...state,
          all: {
            ...state.all,
            currentPage: initialState.all.currentPage,
          },
          future: {
            ...state.future,
            currentPage: initialState.future.currentPage,
          },
          new: {
            ...state.new,
            currentPage: initialState.new.currentPage,
          },
          overdue: {
            ...state.overdue,
            currentPage: initialState.overdue.currentPage,
          },
        })

      case CrmTradeOpportunityPurchaseActionType.SendInternalEventSuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunityPurchaseActionType.SendInternalCommentSuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunityPurchaseActionType.UpdateOpportunitySuccess:
        return ({
          ...state,
          currentOpportunity: action.payload,
        })

      case CrmTradeOpportunityPurchaseActionType.AssignOpportunitySuccess:
      case CrmTradeOpportunityPurchaseActionType.UnassignOpportunitySuccess:
        const opportunityListToUpdate = state[action.payload.opportunityListType]
        const opportunityResultsToUpdate = opportunityListToUpdate.list.data?.result || []
        const indexOfUpdatedOpportunityPurchase =
          opportunityResultsToUpdate?.findIndex(element => element.uuid === action.payload.opportunity.uuid)

        const updatedResult =
          // tslint:disable-next-line: prefer-object-spread
          Object.assign([...opportunityResultsToUpdate], { [indexOfUpdatedOpportunityPurchase]: action.payload.opportunity })

        return {
          ...state,
          [action.payload.opportunityListType]: {
            ...opportunityListToUpdate,
            list: {
              data: {
                ...opportunityListToUpdate.list.data,
                result: updatedResult,
              },
              loading: false,
            },
          },
        }

      default:
        return state
    }
}
