import { updateStateFieldIfExists } from 'actff-bo-lib/store/helpers'

import { CrmServiceAction, CrmServiceActionType } from '../actions'
import { OpportunityService } from '../dto'

export type CrmServiceOpportunityState = {
  readonly currentOpportunity: OpportunityService | null,
  readonly draftOpportunity: OpportunityService | null,
}

const initialState = {
  currentOpportunity: null,
  draftOpportunity: null,
}

// tslint:disable-next-line:cyclomatic-complexity
export const crmServiceOpportunityReducer = (
  state: CrmServiceOpportunityState = initialState,
  action: CrmServiceAction,
) => {
  switch (action.type) {
    case CrmServiceActionType.GetOpportunitySuccess:
      return ({
        ...state,
        currentOpportunity: action.payload,
        draftOpportunity: action.payload,
      })

    case CrmServiceActionType.ClearOpportunity:
      return ({
        ...state,
        currentOpportunity: null,
        draftOpportunity: null,
      })

    case CrmServiceActionType.SendInternalCommentSuccess:
      const existingComments = (state.draftOpportunity && state.draftOpportunity.comments) || []

      return updateStateFieldIfExists(state, 'draftOpportunity', {
        comments: [
          ...existingComments,
          action.payload,
        ],
      })

    case CrmServiceActionType.UpdateDraftOpportunity:
      return updateStateFieldIfExists(state, 'draftOpportunity', { ...action.payload })

    case CrmServiceActionType.UpdateStatusAndResetDraftOpportunity:
      return updateStateFieldIfExists(state, 'draftOpportunity', { ...state.currentOpportunity, status: action.payload })

    default:
      return state
  }
}
