import { Loader } from 'actff-bo-app/components/Loader'
import { SanitizeHtml } from 'actff-bo-app/components/SanitizeHtml'
import { ApprovalsContentAction, selectPersonalDataApprovalsContent } from 'actff-bo-lib/approvals-content'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { compose } from 'redux'

type DispatchToProps = {
  readonly getApprovalsContent: () => void,
}

type StateToProps = {
  readonly approvalsContent: string | null,
}

type Props = StateToProps & DispatchToProps & WithTranslation

export class PrivacyPolicyComponent extends React.Component<Props> {
  public componentDidMount(): void {
    this.props.getApprovalsContent()
  }

  public render(): React.ReactNode {
    return this.props.approvalsContent
      ? <SanitizeHtml dirtyText={this.props.approvalsContent} />
      : <Loader />
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getApprovalsContent: () => { dispatch(ApprovalsContentAction.getApprovalsContent()) },

})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  approvalsContent: selectPersonalDataApprovalsContent(state),
})

export const PrivacyPolicy = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(PrivacyPolicyComponent)
