import { getLocale, oneSecond } from 'actff-bo-lib/date'
import { selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { differenceInDays, distanceInWordsToNow } from 'date-fns'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

type Props = {
  readonly date: Date,
}

// tslint:disable no-magic-numbers
const timeToRefresh = oneSecond * 15
const differenceInDaysToRecalculateDate = 1
// tslint:enable

export const TimeAgo: FC<Props> = ({ date }) => {
  const [, updateState] = useState<null>()
  const currentLanguage = useSelector(selectCurrentLanguage)
  const [difference, setDifference] = useState(differenceInDays(new Date(), date))
  const locale = getLocale(currentLanguage)
  const { t } = useTranslation()

  useEffect(() => {
    if (difference <= differenceInDaysToRecalculateDate) {
      const interval = setInterval(() => {
        updateState(null)
        setDifference(differenceInDays(new Date(), date))
      }, timeToRefresh)

      return () => clearInterval(interval)
    }

    return
  })

  return <>{distanceInWordsToNow(date, { locale })} {t('ago')}</>
}
