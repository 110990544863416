import * as React from 'react'
import styled from 'styled-components'
import { colors, layers } from 'styles'

export enum DropdownPosition {
  Left,
  Right,
}

type DropdownProps = {
  readonly className?: string,
  readonly position?: DropdownPosition,
  readonly topOffset?: number,
}

const DropdownContentStyled = styled.div`
  background: ${colors.white};
  border-radius: 3px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-row-gap: 5px;
  ${({ position }: DropdownProps) =>
    position === DropdownPosition.Right ? 'right: 0' : 'left: 0'
  };
  margin: 0;
  padding: 14px 18px;
  position: absolute;
  white-space: nowrap;
  ${({ topOffset }: DropdownProps) =>
    topOffset ? `top: ${topOffset}px` : 'top: auto'
  };
  z-index: ${layers.dropdownContent};
`

export const DropdownContent: React.FC<DropdownProps> = ({ children, className, position, topOffset }) => (
  <DropdownContentStyled className={className} position={position} topOffset={topOffset}>
    {children}
  </DropdownContentStyled>
)
