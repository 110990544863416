import { AuthAction } from 'actff-bo-lib/auth/actions'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { ToastAction, ToastType } from 'actff-bo-lib/toast'
import * as statusCodes from 'http-status-codes'
import { AnyAction } from 'redux'
import { combineEpics, Epic } from 'redux-observable'
import { AjaxError } from 'rxjs/ajax'
import { filter, map, switchMap } from 'rxjs/operators'

import { ApiResponseError, Errors } from './errors'

const handleErrorEpic: Epic<AnyAction> = action$ => action$.pipe(
  filter(action => action.payload instanceof AjaxError),
  filter(({ payload }) => payload.status === statusCodes.BAD_REQUEST),
  map(({ payload }) => [Errors.filter(error => error.key === payload.response?.name).first(), payload.response?.params ]),
  filter(([error]) => Boolean(error)),
  switchMap(([{ body, title }, params]: [ApiResponseError, any]) => [ // tslint:disable-line no-any
    ToastAction.displayToast({
      body,
      bodyProps: { params },
      id: `${title}${JSON.stringify(params)}`,
      title,
      type: ToastType.Error,
    }),
  ]),
)

const handleAPIErrorEpic: Epic<AnyAction> = action$ => action$.pipe(
  filter(action => action.payload instanceof AjaxError),
  filter(({ payload }) => payload.status === statusCodes.INTERNAL_SERVER_ERROR),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      body: payload.message,
      id: 'api.internalServerError',
      title: 'api.internalServerError.title',
      type: ToastType.Error,
    }),
  ]),
)

const redirectOnUserNotFoundEpic: Epic<AnyAction> = action$ => action$.pipe(
  filter(action => action.payload instanceof AjaxError),
  filter(({ payload }) => payload.status === statusCodes.NOT_FOUND && payload.response?.message === 'user.notfound'),
  switchMap(() => [
    ToastAction.displayToast({
      body: '',
      id: 'user.unauthorized',
      title: 'user.unauthorized',
      type: ToastType.Error }),
    AuthAction.logout(),
  ]),
)

const redirectOnUnauthorizedEpic: Epic<AnyAction> = action$ => action$.pipe(
  filter(action => action.payload instanceof AjaxError),
  filter(({ payload }) => payload.status === statusCodes.UNAUTHORIZED),
  filter(() => !window.location.href.includes(Paths.Login)),
  switchMap(() => [
    ToastAction.displayToast({
      body: '',
      id: 'user.unauthorized',
      title: 'user.unauthorized',
      type: ToastType.Error,
    }),
    AuthAction.logout(),
  ]),
)

export const apiEpics = combineEpics(
  handleAPIErrorEpic,
  handleErrorEpic,
  redirectOnUserNotFoundEpic,
  redirectOnUnauthorizedEpic,
)
