import { BtnTheme, BtnType, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormFooter, FormFooterLeft, FormFooterRight } from 'actff-bo-app/components/Form'
import { CarId } from 'actff-bo-lib/car/dto'
import { Testable } from 'actff-bo-lib/global/testable'
import { Paths } from 'actff-bo-lib/menu'
import { IconType } from 'actff-bo-lib/menu/dto'
import { history } from 'actff-bo-lib/router'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { LinkButton, PrimaryButton } from 'styles'

type Props = Testable & {
  readonly acceptDisabled?: boolean,
  readonly carId: CarId,
  readonly onAccept: () => void,
  readonly onReject?: (carId: CarId) => void,
  readonly onDelete?: (carId: CarId) => void,
  readonly onRevert?: (carId: CarId) => void,
  readonly inRejectedMode: boolean,
}

const handleOnGoBackClick = () => {
  history.push(Paths.CarList)
}

export const CarActionButtons: React.FC<Props> = ({
  acceptDisabled,
  carId,
  onAccept,
  onReject,
  onDelete,
  onRevert,
  inRejectedMode,
  testId,
}) => {
  const { t } = useTranslation()
  const handleOnDelete = () => onDelete && onDelete(carId)
  const handleOnRevert = () => onRevert && onRevert(carId)
  const handleOnReject = () => onReject && onReject(carId)

  // tslint:disable-next-line
  return (
    <FormFooter>
      <FormFooterLeft>
        <ButtonWithIcon
          caption={t('caption.back')}
          onClick={handleOnGoBackClick}
          iconType={IconType.Back}
          theme={BtnTheme.Link}
          testId={`${testId}go-back`}
        />
      </FormFooterLeft>
      {
        inRejectedMode
          ? (
            <FormFooterRight>
              {onDelete && <LinkButton type={BtnType.Button} onClick={handleOnDelete}>{t('caption.delete')}</LinkButton>}
              {onRevert && <PrimaryButton type={BtnType.Button} onClick={handleOnRevert}>{t('caption.revert')}</PrimaryButton>}
            </FormFooterRight>
          ) : (
            <FormFooterRight>
              {onReject &&
                <LinkButton data-testid={`${testId}button-reject`} type={BtnType.Button} onClick={handleOnReject}>
                  {t('caption.reject')}
                </LinkButton>
              }
              <PrimaryButton data-testid={`${testId}button-submit`} disabled={acceptDisabled} type={BtnType.Button} onClick={onAccept}>
                {t('caption.accept')}
              </PrimaryButton>
            </FormFooterRight>
          )
      }
    </FormFooter>
  )
}
