import { Popover } from '@material-ui/core'
import { Icon } from 'actff-bo-app/components/Icon'
import { Link } from 'actff-bo-app/components/Popover'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SchedulerViewType } from '../config'

type Props = {
  readonly onEdit: () => void,
  readonly onToggleConfirmDialog: () => void,
  readonly viewType: string,
}

const Menu = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-left: auto;
`

const MenuIconContainer = styled.div`
  height: 20px;
  width: 20px;
`

export const ResourceContentMenu: FC<Props> = ({ onEdit, onToggleConfirmDialog, viewType }) => {
  const { t } = useTranslation()

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)

  const renderRemoveLabel = () => {
    switch (viewType) {
      case SchedulerViewType.DAY:
        return t('caption.day').toLowerCase()
      case SchedulerViewType.WEEK:
        return t('caption.week').toLowerCase()
      case SchedulerViewType.MONTH:
        return t('caption.month').toLowerCase()
      default:
        return t('caption.month').toLowerCase()
    }
  }

  const handleMenuIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
    setPopoverOpen(true)
  }

  const closePopover = () => {
    setPopoverAnchor(null)
    setPopoverOpen(false)
  }

  const handleEdit = () => {
    onEdit()
    closePopover()
  }

  const handleDelete = () => {
    onToggleConfirmDialog()
    closePopover()
  }

  return (
    <Menu>
      <MenuIconContainer onClick={handleMenuIconClick}>
        <Icon type={IconType.More} />
      </MenuIconContainer>
      <Popover
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={closePopover}
        open={popoverOpen}
        anchorEl={popoverAnchor}
      >
        <Link onClick={handleEdit}>
          <Icon type={IconType.Edit} />
          <span>{t('admin.employeeSchedule.editResource')}</span>
        </Link>
        <Link onClick={handleDelete}>
          <Icon type={IconType.Trash} />
          <span>{t('caption.delete')} {renderRemoveLabel()}</span>
        </Link>
      </Popover>
    </Menu>
  )
}
