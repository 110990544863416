import styled from 'styled-components'
import { colors } from 'styles'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 12px;
`

export const SpacedFilterContainer = styled.div`
  align-items: start;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 10px;
  max-height: 230px;
`

export const FilterContainer = styled(SpacedFilterContainer)`
  grid-row-gap: 0;
  margin-top: 0;
`

export const FilterContent = styled.div`
  align-content: start;
  display: grid;
  grid-auto-flow: row;
`

export const SpacedFilterContent = styled(FilterContent)`
  align-content: start;
  grid-row-gap: 15px;
`

export const SaleOpportunitiesCountFilterContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 60px;
  justify-content: left;
`

export const Label = styled.label`
  color: ${colors.black};
  text-transform: none;
`

export const Version = styled.div`
  align-content: start;
  display: grid;
  grid-auto-flow: row;

  label {
    text-transform: none;
  }

  input {
    border-color: ${colors.lustrianUndergrowth};
  }
`

export const VersionInput = styled.input`
  height: 38px;
  margin-top: 10px;
`
