import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { TdDelete } from 'actff-bo-app/components/EditableList/Styled'
import { Holiday } from 'actff-bo-lib/calendar/dto'
import { defaultShortDateFormat } from 'actff-bo-lib/date'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly holiday: Holiday,
  readonly onDelete: () => void,
}

const Menu = styled.span`
  float: right;
`

const ButtonWithIconStyled = styled(ButtonWithIcon)`
  padding: 0 24px;
`

export const HolidayRow: FC<Props> = ({ onDelete, holiday }) => {
  const { t } = useTranslation()
  const [menuVisible, setMenuVisible] = useState(false)

  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }

  const handleStopEvents = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <tr>
      <td onMouseOver={toggleMenu} onMouseLeave={toggleMenu}>
        <FormattedDate date={holiday.date} format={defaultShortDateFormat} />
      </td>
      <TdDelete onMouseOver={toggleMenu} onMouseLeave={toggleMenu}>
        {holiday.description}
        {menuVisible && (
          <Menu
            onMouseOver={handleStopEvents}
            onMouseLeave={toggleMenu}
          >
            <ButtonWithIconStyled
              caption={t('caption.delete')}
              onClick={onDelete}
              iconType={IconType.Trash}
              noPadding={true}
              theme={BtnTheme.Link}
            />
          </Menu>
        )}
      </TdDelete>
    </tr>
  )
}
