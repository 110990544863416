import { ErrorMessage } from '@hookform/error-message'
import { Ref } from '@hookform/error-message/dist/types'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Label } from 'actff-bo-app/components/Label'
import { OpportunityTradeFailureReasonType } from 'actff-bo-lib/crm/trade'
import React, { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { FieldErrors, ValidationRules } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly control: Control,
  readonly editMode?: boolean,
  readonly errors: FieldErrors,
  readonly failureExplanation: string,
  readonly failureReason: OpportunityTradeFailureReasonType | null,
  readonly register: (rules?: ValidationRules) => (ref: Ref | null) => void,
}

type OpportunityFailureReasonProps = Props

const FailureReasonContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 24px 32px;
`

const LabelStyled = styled(Label)`
  bottom: 8px;
  left: 10px;

  label:after  {
    top: 1px;
  }
`

const OtherReason = styled.div`
  display: grid;
`

const SpanStyled = styled.span`
  font-weight: bolder;
  text-transform: none;
`

const RadioButtons = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 35px);
  margin-top: 24px;
  min-width: 450px;
  width: 50%;
`

const RadioBox = styled.div`
  display: grid;
`

const RadioBoxWithOther = styled(RadioBox)`
  align-items: baseline;
  display: grid;
  grid-template-columns: 80px 300px;
`

const OtherReasonInput = styled.input`
  bottom: 12px;
  position: relative;
`

export const OpportunityFailureReason: FC<OpportunityFailureReasonProps> = ({
  control,
  errors,
  register,
  editMode = true,
  failureReason,
}) => {
  const { t } = useTranslation()

  const watchedFailureReason = useWatch<OpportunityTradeFailureReasonType | null>({
    control,
    defaultValue: failureReason,
    name: 'failureReason',
  })

  const isChecked = (radioValue: OpportunityTradeFailureReasonType) => watchedFailureReason === radioValue

  return (
    <FailureReasonContainer>
      <label htmlFor='failureReason'>
        {t('opportunity.failureReason.caption')}
      </label>
      <RadioButtons>
        <Controller
          render={({ onChange }) => (
            <RadioBox>
              <input
                disabled={!editMode}
                checked={isChecked(OpportunityTradeFailureReasonType.NOT_INTERESTED)}
                onChange={onChange}
                type='radio'
                value={OpportunityTradeFailureReasonType.NOT_INTERESTED}
              />
              <LabelStyled htmlFor='failureReason' onClick={() => onChange(OpportunityTradeFailureReasonType.NOT_INTERESTED)}>
                <SpanStyled>{t('crmTrade.failureReason.not_interested')}</SpanStyled>
              </LabelStyled>
            </RadioBox>
          )}
          defaultValue={failureReason}
          control={control}
          name='failureReason'
          rules={{ required: true }}
        />
        <Controller
          render={({ onChange }) => (
            <RadioBoxWithOther>
              <input
                disabled={!editMode}
                checked={isChecked(OpportunityTradeFailureReasonType.OTHER)}
                onChange={onChange}
                type='radio'
                value={OpportunityTradeFailureReasonType.OTHER}
              />
              <LabelStyled htmlFor='failureReason' onClick={() => onChange(OpportunityTradeFailureReasonType.OTHER)}>
                <SpanStyled>{t('crmTrade.failureReason.other')}</SpanStyled>
              </LabelStyled>
              {watchedFailureReason === OpportunityTradeFailureReasonType.OTHER && (
                (
                  <OtherReason>
                    <OtherReasonInput
                      placeholder={t('crmTrade.failureExplanation.placeholder')}
                      type='text'
                      name='failureExplanation'
                      ref={register({ required: 'caption.error.required' })}
                    />
                    <ErrorMessage errors={errors} name='failureExplanation' as={TranslatedErrorMessage} />
                  </OtherReason>
                ))}
            </RadioBoxWithOther>
          )}
          defaultValue={failureReason}
          control={control}
          name='failureReason'
          rules={{ required: 'caption.error.required' }}
        />
        <ErrorMessage errors={errors} name='failureReason' as={TranslatedErrorMessage} />
      </RadioButtons>
    </FailureReasonContainer>
  )
}
