import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'

import { BankAccount, Insurer, InsurerName } from '../dto/payments'

export enum CrmInsuranceInsurerActionType {
  GetInsurers = '[CRM INSURANCE] GET INSURERS',
  GetInsurersFailure = '[CRM INSURANCE] GET INSURERS FAILURE',
  GetInsurersSuccess = '[CRM INSURANCE] GET INSURERS SUCCESS',

  GetBankAccountNumbersForInsurer = '[CRM INSURANCE] GET BANK ACCOUNT NUMBERS FOR INSURER',
  GetBankAccountNumbersForInsurerFailure = '[CRM INSURANCE] GET BANK ACCOUNT NUMBERS FOR INSURER FAILURE',
  GetBankAccountNumbersForInsurerSuccess = '[CRM INSURANCE] GET BANK ACCOUNT NUMBERS FOR INSURER SUCCESS',
}

export const CrmInsuranceInsurerAction = {
  getInsurers: () =>
    createAction(CrmInsuranceInsurerActionType.GetInsurers),
  getInsurersFailure: (error: AjaxError) =>
    createAction(CrmInsuranceInsurerActionType.GetInsurersFailure, error),
  getInsurersSuccess: (insurers: ReadonlyArray<Insurer>) =>
    createAction(CrmInsuranceInsurerActionType.GetInsurersSuccess, insurers),

  getBankAccountNumbersForInsurer: (insurer: InsurerName) =>
    createAction(CrmInsuranceInsurerActionType.GetBankAccountNumbersForInsurer, insurer),
  getBankAccountNumbersForInsurerFailure: (error: AjaxError) =>
    createAction(CrmInsuranceInsurerActionType.GetBankAccountNumbersForInsurerFailure, error),
  getBankAccountNumbersForInsurerSuccess: (accountNumbers: ReadonlyArray<BankAccount>) =>
    createAction(CrmInsuranceInsurerActionType.GetBankAccountNumbersForInsurerSuccess, accountNumbers),
}

export type CrmInsuranceInsurerAction = ActionsUnion<typeof CrmInsuranceInsurerAction>
