import { Counterparty } from 'actff-bo-lib/car/dto'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { Placeholder } from '../../Styled'
import { CounterpartyRow } from './CounterpartyRow'

type Props = {
  readonly list: ReadonlyArray<Counterparty>,
  readonly onPersonClick: (person: Counterparty) => void,
  readonly selectedPerson: Counterparty | null,
  readonly testId: string,
}

export const CounterpartyList: FC<Props> = ({ onPersonClick, list, selectedPerson, testId }) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
        <Tr>
          <Th />
          <Th>{t('table.columns.fullName')}</Th>
          <Th>{t('table.columns.phone')}</Th>
          <Th>{t('table.columns.email')}</Th>
          <Th>{t('table.columns.id')}</Th>
          <Th>{t('table.columns.companyTaxNumber')}</Th>
          <Th>{t('table.columns.address')}</Th>
        </Tr>
        </thead>
          <tbody>
            {list.map((person: Counterparty) => (
              <CounterpartyRow
                key={person.id}
                person={person}
                onClick={() => onPersonClick(person)}
                selected={person.id === selectedPerson?.id}
                testId={`${testId}person-row`}
              />
            ))}
          </tbody>
      </Table>
      {list.length < 1 && (
        <Placeholder>{t('ownership.searchPerson.placeholder')}</Placeholder>
      )}
    </HorizontallyScrolledContainer>
  )
}
