import { FormCell } from 'actff-bo-app/components/Form'
import { Textarea } from 'actff-bo-app/Crm/Styled'
import styled from 'styled-components'

export const FormContent = styled.div`
  padding-bottom: 80px;
`

export const PolicyReceivedCell = styled(FormCell)`
  align-items: center;
  display: grid;
  grid-template-rows: 20px 20px;
`

export const SectionForm = styled.div`
  display: grid;
  grid-column-gap: 28px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-right: 50px;
`

export const TextareaStyled = styled(Textarea)`
  margin-top: 0;
`
