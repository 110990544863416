import { BtnTheme } from 'actff-bo-app/components/Button'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import { CarEquipmentAction, CarEquipmentItem, selectCurrentCarEquipmentItems } from 'actff-bo-lib/car'
import { IconType } from 'actff-bo-lib/menu/dto'
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Tr } from 'styles'
import { Item } from './Item'

import { AddingDialogContent, ButtonWithIcon, Container, Label, Table, Th } from '../../Styled'

// TODO only for test purpose, remove after API ready
const mockEquipments: ReadonlyArray<CarEquipmentItem> = [
  {
    name: 'Wersja SELEKT',
    uuid: '26d19285-f77f-46e7-9d16-69a1ae48fd8a',
    value: null,
  },
  {
    name: 'Parkowanie przód',
    uuid: '957d15bb-05f5-4ee9-b97c-cc851d5efeee',
    value: null,
  },
  {
    name: 'Parkowanie tył',
    uuid: '2d812ecd-d538-424b-a231-aff8a7a1a6ff',
    value: null,
  },
  {
    name: 'Elektryczne fotele przód',
    uuid: '49fa179e-b194-4875-a075-9bc0563dd641',
    value: null,
  },
  {
    name: 'Klimatyzacja automatyczna',
    uuid: '2c4cfc6e-050f-49ce-971b-ba539e2c6a45',
    value: null,
  },
  {
    name: 'Podgrzewane fotele przód',
    uuid: '515b9738-27a4-4a04-88ca-c83049f3c5fd',
    value: null,
  },
  {
    name: 'Wentylowane fotele',
    uuid: 'd0ff4f2a-1712-49a0-9f7a-f450dc827700',
    value: null,
  },
  {
    name: 'Podgrzewana kierownica',
    uuid: 'a430e59a-d11b-4445-acd6-6df69d87946f',
    value: null,
  },
  {
    name: 'Podgrzewana przednia szyba',
    uuid: '91a62002-11c0-49da-9fa7-fe0b73162205',
    value: null,
  },
  {
    name: 'Pneumatyczne zawieszenie',
    uuid: '0f966296-f074-4502-b87e-9e87f914c585',
    value: null,
  },
  {
    name: 'Tapicerka skórzana',
    uuid: '1345ca26-cbc8-4ac1-acf3-a6bc4f699d8e',
    value: null,
  },
  {
    name: 'Tapicerka tekstylna',
    uuid: 'f7b11671-35b7-4628-98e7-f77323ea4586',
    value: null,
  },
  {
    name: 'Nawigacja GPS',
    uuid: '61e72786-ccc1-41f2-b44e-6a6123d6bff4',
    value: null,
  },
  {
    name: 'Dach panoramiczny',
    uuid: '8a07a260-d89d-4273-8e93-f2cadaf0905f',
    value: null,
  },
  {
    name: 'Szyberdach',
    uuid: '190bb901-5b75-44f2-b9ff-162e6f3307c8',
    value: null,
  },
  {
    name: 'HUD',
    uuid: '867005f0-cc9c-4d9b-adb5-b8f3f85169d7',
    value: null,
  },
  {
    name: 'Kamera cofania',
    uuid: '6c3fb563-5b17-42b0-b3ab-4a16a7551834',
    value: null,
  },
  {
    name: 'Kamery 360',
    uuid: 'cfd53af6-7aab-444f-a1b4-c25be9100d0e',
    value: null,
  },
  {
    name: 'Światła Xenon',
    uuid: '3c8e0fe9-c5f8-41a4-b8fa-7b76baefed6d',
    value: null,
  },
  {
    name: 'Światła LED',
    uuid: '3d1ca156-76b9-423f-9746-10e8f386e5e7',
    value: null,
  },
  {
    name: 'Tempomat',
    uuid: '2449448c-8a5e-4624-9ccb-6c9e62b7cafa',
    value: null,
  },
  {
    name: 'Tempomat aktywny',
    uuid: '0bf27f87-1a15-45d4-aafa-21697184718d',
    value: null,
  },
  {
    name: 'Asystent pasa ruchu',
    uuid: 'b0ba58f5-ae42-44a0-bf75-840fb7cc55f5',
    value: null,
  },
  {
    name: 'BLIS',
    uuid: 'e14022c5-aa2c-4dac-92e6-08e8c77d096e',
    value: null,
  },
  {
    name: 'Keyless',
    uuid: 'f6720ff3-87af-40b0-a902-c1594f1e68e9',
    value: null,
  },
  {
    name: 'Hak',
    uuid: 'ce450a0c-4d6b-4b64-b9a5-ee1323973e46',
    value: null,
  },
]
export const EquipmentList: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { loading, data: equipment } = useSelector(selectCurrentCarEquipmentItems)

  const [newItemName, setNewItemName] = useState('')
  const [addNewItemOpen, setAddNewItemOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<CarEquipmentItem | null>(null)

  const getEquipment = useMemo(() => () => {
    // TODO remove uuid and set proper action, only for test purpose
    dispatch(CarEquipmentAction.getCarEquipmentItems('c47d1672-a00c-4447-bb4b-2a0ae02dce1b'))
  }, [dispatch])

  useEffect(() => {
    if (!equipment) {
      getEquipment()
    }
  }, [equipment, getEquipment])

  const toggleAddDialogOpen = () => { setAddNewItemOpen(!addNewItemOpen) }

  const onDialogClose = () => {
    toggleAddDialogOpen()
    setNewItemName('')
  }

  const onAddDialogConfirm = () => {
    dispatch(CarEquipmentAction.addEquipmentItem({ name: newItemName }))
    setNewItemName('')
    toggleAddDialogOpen()
  }

  const handleDelete = (item: CarEquipmentItem) => () => {
    setItemToDelete(item)
  }

  const onDeleteDialogConfirm = () => {
    itemToDelete?.uuid && dispatch(CarEquipmentAction.deleteEquipmentItem(itemToDelete.uuid))
    setItemToDelete(null)
  }

  const onDeleteDialogClose = () => {
    setItemToDelete(null)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => { setNewItemName(event.target.value) }

  if (loading) { return <LocalLoader /> }

  if (!equipment) { return <NoData>{t('caption.noData')}</NoData> }

  return (
    <>
      <form>
        <Container>
          <Table>
            <thead>
            <Tr>
              <Th>{t('caption.listNumber')}</Th>
              <Th>{t('table.columns.equipment')}</Th>
            </Tr>
            </thead>
            <tbody>
              {mockEquipments.map((item, index) => (
                <Item
                  key={item.uuid}
                  item={item}
                  index={index}
                  onDelete={handleDelete(item)}
                />
              ))}
            </tbody>
          </Table>
          <ButtonWithIcon
            caption={t('admin.technicalCondition.equipment.addEquipmentItem')}
            onClick={toggleAddDialogOpen}
            iconType={IconType.Plus}
            theme={BtnTheme.Link}
          />
        </Container>

        <Dialog
          autoWidth={true}
          content='admin.technicalCondition.equipment.dialog.add.content'
          confirmDisabled={newItemName === ''}
          open={addNewItemOpen}
          onConfirm={onAddDialogConfirm}
          onClose={onDialogClose}
          title='admin.technicalCondition.equipment.dialog.add.title'
        >
          <AddingDialogContent>
            <Label>{t('caption.name')}:</Label>
            <input onChange={handleInputChange} type='text' />
          </AddingDialogContent>
        </Dialog>

        <Dialog
          autoWidth={true}
          content='contentManagement.dialog.delete.content'
          contentProps={{ item: itemToDelete?.name || '' }}
          open={!!itemToDelete}
          onConfirm={onDeleteDialogConfirm}
          onClose={onDeleteDialogClose}
          title='contentManagement.dialog.delete.title'
        />
      </form>
    </>
  )
}
