import { ClientAction, ClientId, ClientPersonalData, selectPersonalDataHistory } from 'actff-bo-lib/client'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'redux'
import styled from 'styled-components'
import { colors } from 'styles'

import { PersonalDataCurrentState } from './PersonalDataCurrentState'
import { PersonalDataGroup } from './PersonalDataGroup'

type StateToProps = {
  readonly personalDataHistory: ClientPersonalData | null,
}

type PersonalDataHistoryParams = {
  readonly clientId: ClientId,
}

type DispatchToProps = {
  readonly getPersonalDataHistory: (clientId: ClientId) => void,
}

type PersonalDataHistoryComponentProps = StateToProps & DispatchToProps & WithTranslation & RouteComponentProps<PersonalDataHistoryParams>

type GridAreaProps = {
  readonly gridArea: string,
}

const Container = styled.div`
  border-top: 3px solid ${colors.athsSpecial};
  display: grid;
  grid-auto-flow: row;
  grid-template-areas:
    'header'
    'personal-header'
    'personal'
    'contact-header'
    'contact'
  ;
`

const PersonalDataHistoryHeader = styled.div`
  font-size: 13px;
  font-weight: 700;
  margin-top: 30px;
  text-transform: uppercase;
`

const PersonalDataGroups = styled.div`
  border-bottom: 3px solid ${colors.solitude};
  display: grid;
  grid-area: ${({ gridArea }: GridAreaProps) => gridArea};
  grid-auto-flow: row;

  &:last-child {
    border-bottom: none;
  }
`

const PersonalDataGroupsHeader = styled.span`
  font-weight: 600;
  grid-area: ${({ gridArea }: GridAreaProps) => gridArea};
  padding: 32px 0 0;
`

class PersonalDataHistoryComponent extends React.Component<PersonalDataHistoryComponentProps> {
  public componentDidMount(): void {
    this.props.getPersonalDataHistory(this.getClientId())
  }

  public render(): React.ReactNode {
    const { personalDataHistory, t } = this.props

    return (
      personalDataHistory
        ? (
          <Container>
            <PersonalDataHistoryHeader>{t('clientView.personalData.header')}</PersonalDataHistoryHeader>
            <PersonalDataGroupsHeader gridArea='personal-header'>
              {t('clientView.personalData.personalData')}&nbsp;-&nbsp;
              <PersonalDataCurrentState type='firstName' personalData={personalDataHistory[0]} />
            </PersonalDataGroupsHeader>
            <PersonalDataGroups gridArea='personal'>
              <PersonalDataGroup personalDataHistory={personalDataHistory} personalDataType='firstName' />
              <PersonalDataGroup personalDataHistory={personalDataHistory} personalDataType='lastName' />
            </PersonalDataGroups>
            <PersonalDataGroupsHeader gridArea='contact-header'>
              {t('clientView.personalData.contactData')} - <PersonalDataCurrentState type='email' personalData={personalDataHistory[0]} />
            </PersonalDataGroupsHeader>
            <PersonalDataGroups gridArea='contact'>
              <PersonalDataGroup personalDataHistory={personalDataHistory} personalDataType='email' />
            </PersonalDataGroups>
          </Container>
        )
        : null
    )
  }

  private readonly getClientId = () => this.props.match.params.clientId as ClientId
}

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  personalDataHistory: selectPersonalDataHistory(state),
})

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  getPersonalDataHistory: (clientId: ClientId) => { dispatch(ClientAction.getClientPersonalDataHistory(clientId)) },
})

export const PersonalDataHistory = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PersonalDataHistoryComponent)
