import { Dialog } from 'actff-bo-app/components/Dialog'
import { FormHeader } from 'actff-bo-app/components/Form'
import { ClientAction, ClientId, selectCurrentClientMatchedPurchaseOpportunities } from 'actff-bo-lib/client'
import { OpportunityPurchaseId } from 'actff-bo-lib/crm/trade'
import { CrmTradeOpportunitySaleAction, selectMatchedPurchaseOpportunities } from 'actff-bo-lib/crm/trade/sale'
import { UserWithoutPermissions } from 'actff-bo-lib/user'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { colors, Table, ThLighterBold, Tr } from 'styles'

import { NoMatchedOpportunities } from '../Styled'
import { OpportunityRow } from './OpporunityRow'

const Container = styled.div`
  border-top: 1px solid ${colors.athensGray};
  grid-area: matched-opportunities;
`

const FormHeaderStyled = styled(FormHeader)`
  padding: 12px 32px;
`

const TableStyled = styled(Table)`
  border-top: 1px solid ${colors.mysticGray};
  grid-area: matched-opportunities;
`

type Props = {
  readonly withStatusIn?: boolean,
  readonly clientId?: ClientId,
  readonly currentUser?: UserWithoutPermissions,
}

export const MatchedPurchaseOpportunities: FC<Props> = ({ clientId, withStatusIn, currentUser }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [confirmUnmatchOpen, setConfirmUnmatchOpen] = useState(false)
  const [purchaseOpportunityIdToUnmatch, setPurchaseOpportunityIdToUnmatch] = useState<OpportunityPurchaseId | null>()
  const toggleConfirmUnmatchDialog = () => { setConfirmUnmatchOpen(!confirmUnmatchOpen) }

  const matchedPurchaseOpportunities = useSelector(
    clientId ? selectCurrentClientMatchedPurchaseOpportunities : selectMatchedPurchaseOpportunities,
  )

  const getMatchedPurchaseOpportunitiesByClient = useMemo(() => (_clientId: ClientId) => {
    dispatch(ClientAction.getClientMatchedPurchaseOpportunities(_clientId))
  }, [dispatch])

  const unmatchPurchaseOpportunity = () => {
    if (purchaseOpportunityIdToUnmatch) {
      dispatch(CrmTradeOpportunitySaleAction.unmatchPurchaseOpportunity(purchaseOpportunityIdToUnmatch))
    }
    toggleConfirmUnmatchDialog()
  }

  const handleClickOpportunityPurchaseToUnmatch = (opportunityId: OpportunityPurchaseId) => {
    setPurchaseOpportunityIdToUnmatch(opportunityId)
    setConfirmUnmatchOpen(true)
  }

  useEffect(() => {
    if (!matchedPurchaseOpportunities && clientId) {
      getMatchedPurchaseOpportunitiesByClient(clientId)
    }
  }, [getMatchedPurchaseOpportunitiesByClient, matchedPurchaseOpportunities, clientId])

  const hasOpportunities = () => matchedPurchaseOpportunities && matchedPurchaseOpportunities.length > 0

  if (!hasOpportunities()) {
    return (
      <Container>
        <FormHeaderStyled>
          {t('clientView.opportunities.matchedPurchaseOpportunities')}:
        </FormHeaderStyled>
        <NoMatchedOpportunities>
          {t('clientView.opportunities.matchedPurchaseOpportunities.noMatchedOpportunities')}
        </NoMatchedOpportunities>
      </Container>
    )
  }

  return (
    <Container>
      <FormHeaderStyled>
        {t('clientView.opportunities.matchedPurchaseOpportunities')}:
      </FormHeaderStyled>
      <TableStyled>
        <thead>
        <Tr>
          <ThLighterBold>{t('table.columns.status')}:</ThLighterBold>
          {withStatusIn && <ThLighterBold>{t('table.columns.statusInOpportunity')}:</ThLighterBold>}
          <ThLighterBold>{t('table.columns.opportunityDate')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.vin')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.model')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.productionYear')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.marketValue')}:</ThLighterBold>
          <ThLighterBold>{t('table.columns.potentialBuyers')}:</ThLighterBold>
          <ThLighterBold />
        </Tr>
        </thead>
        <tbody>
        {matchedPurchaseOpportunities?.map(opportunity => (
          <OpportunityRow
            key={opportunity.uuid}
            onRemoveClick={handleClickOpportunityPurchaseToUnmatch}
            opportunity={opportunity}
            currentUser={currentUser}
            unmatchable={!clientId}
          />
        ))}
        </tbody>
      </TableStyled>
      <Dialog
        content='preferences.unmatchPurchaseOpportunity.confirmDialog.content'
        open={confirmUnmatchOpen}
        onConfirm={unmatchPurchaseOpportunity}
        onClose={toggleConfirmUnmatchDialog}
        title='preferences.unmatchPurchaseOpportunity.confirmDialog.title'
      />
    </Container>
  )
}
