import { ListTypeTrStyleMap } from 'actff-bo-app/components/Table'
import { PagedResult } from 'actff-bo-lib/api'
import { OpportunityListType } from 'actff-bo-lib/crm'
import { OpportunityService } from 'actff-bo-lib/crm/service'
import { selectUsersWithoutMe } from 'actff-bo-lib/user'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Table, Th, Tr } from 'styles'

import { OpportunityRow } from './OpporunityRow'

type Props = {
  readonly opportunities: PagedResult<OpportunityService>,
  readonly type: OpportunityListType,
}

export const OpportunityTable: React.FC<Props> = ({ opportunities, type }) => {
  const { t } = useTranslation()
  const { data: userList } = useSelector(selectUsersWithoutMe)

  return (
    <Table>
      <thead>
        <Tr>
          <Th />
          <Th>{t('table.columns.status')}</Th>
          <Th>{t('table.columns.registrationNumber')}</Th>
          <Th>{t('table.columns.fullName')}</Th>
          <Th>{t('table.columns.phone')}</Th>
          <Th>{t('table.columns.service')}</Th>
          <Th>{t('table.columns.lastService')}</Th>
          <Th>{t('table.columns.opportunityDate')}</Th>
          <Th>{t('table.columns.location')}</Th>
        </Tr>
      </thead>
      <tbody>
        {opportunities.result.map(opportunity =>
          <OpportunityRow
            key={opportunity.uuid}
            opportunity={opportunity}
            rowComponent={ListTypeTrStyleMap.get(type)}
            opportunityListType={type}
            userList={userList}
          />,
        )}
      </tbody>
    </Table>
  )
}
