import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { DateRange } from 'actff-bo-lib/date'
import { AjaxError } from 'rxjs/ajax'

import { Report, ReportType } from '../dto'

export enum CrmInsuranceReportActionType {
  GetReport = '[CRM INSURANCE] GET REPORT',
  GetReportFailure = '[CRM INSURANCE] GET REPORT FAILURE',
  GetReportSuccess = '[CRM INSURANCE] GET REPORT SUCCESS',
}

export const CrmInsuranceReportAction = {
  getReport: (type: ReportType, dateRange: DateRange) =>
    createAction(CrmInsuranceReportActionType.GetReport, { type, dateRange }),
  getReportFailure: (error: AjaxError) =>
    createAction(CrmInsuranceReportActionType.GetReportFailure, error),
  getReportSuccess: (report: Report) =>
    createAction(CrmInsuranceReportActionType.GetReportSuccess, report),
}

export type CrmInsuranceReportAction = ActionsUnion<typeof CrmInsuranceReportAction>
