import { CheckboxInputField, Field } from 'actff-bo-app/components/Form'
import { Icon } from 'actff-bo-app/components/Icon'
import { Label } from 'actff-bo-app/components/Label'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import i18next from 'i18next'
import * as React from 'react'
import styled from 'styled-components'

type Props = Testable & {
  readonly t: i18next.TFunction,
}

const Container = styled.div`
  display: grid;
  grid-area: client-type;
`
const CheckboxContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 20px 20px;
  place-items: center;
`

const IconStyled = styled(Icon)`
  position: relative;
  top: 10px;
`

export const ClientType: React.FC<Props> = ({ testId, t }) => (
  <Container>
    <Label uppercase={false}>{t('client.type')}:</Label>
    <CheckboxContainer>
      <Field
        component={CheckboxInputField}
        id={'types.Vip'}
        name={'types.Vip'}
        slim={true}
        testId={`${testId}type--vip`}
      />
      <IconStyled type={IconType.Crown} />
      <Field
        component={CheckboxInputField}
        id={'types.Attention'}
        name={'types.Attention'}
        slim={true}
        testId={`${testId}type--vip`}
      />
      <IconStyled type={IconType.Flag} />
    </CheckboxContainer>
  </Container>
)
