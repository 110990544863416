import { EventContentArg } from '@fullcalendar/core'
import React, { FC } from 'react'
import styled from 'styled-components'
import { colors, Opacity, withOpacity } from 'styles'

import { SchedulerViewType } from './config'

type Props = {
  readonly eventData: EventContentArg,
}

type EventContentWrapperProps = {
  readonly isSelected: boolean,
  readonly viewType: SchedulerViewType,
}

const getSelectionPosition = (isViewTypeMonth: boolean) =>
  isViewTypeMonth ?
    `width: calc(100% + 16px);
     height: calc(100% + 80px);
     top: -40px;
     left: -8px;` :
    `width: calc(100% + 10px);
     height: calc(100% + 10px);
     top: -15px;
     left: -15px;`

const EventContentWrapper = styled.div<EventContentWrapperProps>`
  color: ${colors.black};
  font-size: 13px;

  ${({ isSelected, viewType }: EventContentWrapperProps) => (
    isSelected &&
      `&::before {
        position: absolute;
        ${getSelectionPosition(viewType === SchedulerViewType.MONTH)}
        content: '';
        background: ${withOpacity(colors.kumera, Opacity['25%'])};
        border: 1px solid ${colors.kumera};
    }`
  )}
`

export const EventContent: FC<Props> = ({ eventData }) => {
  const { view, event } = eventData

  return (
    <EventContentWrapper isSelected={event.extendedProps.selected} viewType={view.type}>
      {view.type !== SchedulerViewType.MONTH && (<b>{eventData.event.title}</b>)}
    </EventContentWrapper>
  )
}
