import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'

export const OpportunityAddInternalCommentContainer = styled(FormCell)`
  grid-area: add-internal-comment;
  padding-right: 32px;
`

export const AddButton = styled(ButtonWithIcon)`
  justify-self: start;
`

export const CommentRequiredIndicator = styled.span`
  align-items: center;
  display: grid;
  font-weight: 400;
  grid-auto-flow: column;
  grid-column-gap: 6px;
  justify-content: start;
`
