import { Backdrop, Fade, Modal as ModalComponent } from '@material-ui/core'
import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu/dto'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, H2 } from 'styles'

type Props = ModalContentProps & {
  readonly autoWidth?: boolean,
  readonly children: React.ReactNode,
  readonly onClose: (event: React.MouseEvent) => void,
  readonly open: boolean,
  readonly title: string,
  readonly titleWithoutTranslation?: boolean,
}

type ModalContentProps = {
  readonly autoWidth?: boolean,
  readonly fullScreen?: boolean,
  readonly width?: number,
}

const ModalStyled = styled(ModalComponent)`
  border-radius: 3px;
  display: grid;
  place-items: center;
  z-index: 50;

  &:focus {
    outline: none;
  }
`

const ModalContent = styled.div<ModalContentProps>`
  background-color: ${colors.white};
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  height: auto;
  position: relative;
  width: ${({ autoWidth, width }: ModalContentProps) => width ? `${width}px` : autoWidth ? 'auto' : '562px'};

  &:focus {
    outline: none;
  }
`

const HeaderStyled = styled.header`
  align-items: center;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.athensGray};
  border-radius: 3px;
  display: grid;
  font-weight: bold;
  grid-template-areas: 'title . close';
  grid-template-columns: 1fr 100px;
  height: 70px;
  padding: 12px 32px;
  text-transform: uppercase;
`

const H2Styled = styled(H2)`
  font-size: 18px;
  grid-area: title;
`

const BodyContainer = styled.div`
  display: grid;
  padding: 12px 32px;
`

const IconStyled = styled(Icon)`
  cursor: pointer;
  grid-area: close;
`

const defaultFadeTimeout = 500

export const Modal: React.FC<Props> = ({ autoWidth= false, children, title, open, onClose, titleWithoutTranslation = false, width }) => {
  const { t } = useTranslation()

  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <ModalStyled
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      onClick={handleOnClick}
      onClose={onClose}
      open={open}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: defaultFadeTimeout,
      }}
      width={width}
    >
      <Fade in={open}>
        <ModalContent autoWidth={autoWidth} width={width}>
          <HeaderStyled>
            <H2Styled>{titleWithoutTranslation ? title : t(title)}</H2Styled>
            <IconStyled type={IconType.Close} onClick={onClose} />
          </HeaderStyled>
          <BodyContainer>
            {children}
          </BodyContainer>
        </ModalContent>
      </Fade>
    </ModalStyled>
  )
}
