import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import styled from 'styled-components'

const NoItemsWrapperStyled = styled.div`
  display: grid;
  grid-template-areas:
    'icon'
    'info'
  ;
  grid-row-gap: 18px;
  padding-top: 100px;
`

const NoItemsIconStyled = styled(Icon)`
  align-self: end;
  grid-area: icon;
  justify-self: center;
  width: 80px;
`

const NoItemsInfoStyled = styled.div`
  font-size: 18px;
  font-weight: 300;
  grid-area: info;
  justify-self: center;
  text-align: center;
  width: 250px;
`

type Props = {
  readonly icon: IconType,
  readonly text: string,
}

export const NoItems: React.FC<Props> = ({ icon, text }) => (
  <NoItemsWrapperStyled>
    <NoItemsIconStyled type={icon} />
    <NoItemsInfoStyled>{text}</NoItemsInfoStyled>
  </NoItemsWrapperStyled>
)
