import { LoggedInPermissions } from 'actff-bo-lib/user'

import { ComponentType, IconType, Path } from '../dto'
import { Paths } from './paths'
import { LoggedInWrappers } from './wrappers'

// tslint:disable object-literal-sort-keys
export const ndcCarMenu = {
  access: LoggedInPermissions,
  component: ComponentType.NdcCars,
  expanded: false,
  icon: IconType.CarWhite,
  name: 'menu.ndcCars',
  path: Paths.NdcCars as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      component: ComponentType.CarViewBasicData,
      expanded: false,
      path: Paths.NdcCarViewBasicData as Path,
      hiddenInMenu: true,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CarViewEquipment,
      expanded: false,
      path: Paths.NdcCarViewEquipment as Path,
      hiddenInMenu: true,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CarViewInspections,
      expanded: false,
      path: Paths.NdcCarViewInspections as Path,
      hiddenInMenu: true,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CarViewInspection,
      expanded: false,
      path: Paths.NdcCarViewNewInspection as Path,
      hiddenInMenu: true,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.CarViewInspection,
      expanded: false,
      path: Paths.NdcCarViewInspection as Path,
      hiddenInMenu: true,
      ...LoggedInWrappers,
      children: [],
    },
  ],
}
