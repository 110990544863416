import { FiltersRenderer, TabFilters } from 'actff-bo-app/components/TabFilters'
import { initialDealershipFilters } from 'actff-bo-lib/chat'
import { DealerLocationKey, WithDealershipFilters } from 'actff-bo-lib/dealership'
import { FormValues } from 'actff-bo-lib/form'
import { mapFormValuesToKeys, mapKeysToFormValues } from 'actff-bo-lib/form/map-values-to-keys'
import React from 'react'

type FiltersType = DealerLocationKey | string

type ListFiltersProps<T> = {
  readonly defaultFieldValue?: boolean,
  readonly filtersComponent: FiltersRenderer,
  readonly filterValues?: ReadonlyArray<T>,
  readonly combinedFilterValues?: WithDealershipFilters,
  readonly filtersCaption: string,
  readonly leftDirection?: boolean,
  readonly onSubmit: (values: ReadonlyArray<string> | object) => void,
  readonly styles?: object,
  readonly transparent?: boolean,
  readonly tabWidth?: string,
}

export const ListFilters = ({
  defaultFieldValue = true,
  filtersComponent,
  filterValues,
  combinedFilterValues,
  filtersCaption,
  onSubmit,
  leftDirection,
  transparent,
  tabWidth,
}: ListFiltersProps<FiltersType>) => {
  if (!filterValues && !combinedFilterValues) { return null }

  const getFilterValues = () => filterValues || combinedFilterValues || []

  const getFiltersLabel = () => {
    const filtersLength = combinedFilterValues
      ? combinedFilterValues.locationFilters?.length + combinedFilterValues.brandFilters?.length
      : filterValues?.length

    return filtersLength && filtersLength > 0 ? `${filtersCaption} (${filtersLength})` : filtersCaption
  }

  const handleSubmit = (values: FormValues) => {
    const mappedFormValuesToKeys = mapFormValuesToKeys(values)
    onSubmit(mappedFormValuesToKeys)
  }

  const handleClear = () => {
    const clearValue = combinedFilterValues ? initialDealershipFilters : []
    onSubmit(clearValue)
  }

  return (
    <TabFilters
      buttonLabel={getFiltersLabel()}
      defaultFormValues={
        mapKeysToFormValues<ReadonlyArray<FiltersType> | WithDealershipFilters>(getFilterValues(), defaultFieldValue)
      }
      leftDirection={leftDirection}
      filters={filtersComponent}
      onClear={handleClear}
      onSubmit={handleSubmit}
      tabWidth={tabWidth}
      transparent={transparent}
    />
  )
}
