import { ErrorMessage } from '@hookform/error-message'
import { ClientViewTabs } from 'actff-bo-app/Clients/ClientView/ClientViewTabs'
import { CheckboxContainer, ClientContainer, H2Styled } from 'actff-bo-app/Clients/Styled'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { FormCell } from 'actff-bo-app/components/Form'
import { TranslatedErrorMessage } from 'actff-bo-app/components/Form/TranslatedErrorMessage'
import { Icon } from 'actff-bo-app/components/Icon'
import { Padder } from 'actff-bo-app/components/Padder'
import { Client, ClientAction, ClientType, NewClientDto } from 'actff-bo-lib/client'
import { IconType } from 'actff-bo-lib/menu'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Header } from 'styles'

const Form = styled.form`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
`

const testId = 'client-new-basic-data-form__'

type ClientNewForm = Omit<Client, 'type'> & {
  readonly type: {
    readonly attention: boolean,
    readonly vip: boolean,
  },
}

export const ClientNew: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false)

  const { register, errors, formState, handleSubmit, reset } = useForm<ClientNewForm>({ defaultValues: {
    type: {
      attention: false,
      vip: false,
    },
  },
    mode: 'onBlur',
  })
  const isSaveDisabled = !formState.isValid
  const toggleOpenConfirmDialog = () => { setConfirmDialogOpen(!confirmDialogOpen) }
  const addClient = (client: NewClientDto) => { dispatch(ClientAction.addClient(client)) }
  const handleSave = () => { toggleOpenConfirmDialog() }

  const handleConfirmAddClient = handleSubmit(formValues => {
    addClient({
      ...formValues,
      type: Object.keys(ClientType).filter(key => formValues.type[key.toLowerCase()]) as ReadonlyArray<ClientType>,
    })
    toggleOpenConfirmDialog()
  })

  const handleReset = () => {
    if (formState.isDirty) {
      reset()
    }
  }

  return (
    <ClientContainer>
      <Header>
        <H2Styled>{t('client.addNew.header')}</H2Styled>
      </Header>
      <ClientViewTabs />
      <Padder padding='20px 20px 100px'>
        <Form>
          <FormCell>
          <label htmlFor='firstName'>{t('firstName')}:</label>
          <input
            name='firstName'
            type='text'
            data-testid={`${testId}form-first-name`}
            ref={register({required: { value: true, message: 'form.validation.required' }})}
          />
            <ErrorMessage errors={errors} name='firstName' as={TranslatedErrorMessage} />
          </FormCell>
          <FormCell>
            <label htmlFor='lastName'>{t('lastName')}:</label>
            <input
              name='lastName'
              type='text'
              data-testid={`${testId}last-name`}
              ref={register({required: { value: true, message: 'form.validation.required' }})}
            />
            <ErrorMessage errors={errors} name='lastName' as={TranslatedErrorMessage} />
          </FormCell>
          <FormCell>
            <label htmlFor='clientId'>{t('clientId')}:</label>
            <input
              name='clientId'
              data-testid={`${testId}client-id`}
              ref={register({required: { value: true, message: 'form.validation.required' }})}
            />
            <ErrorMessage errors={errors} name='clientId' as={TranslatedErrorMessage} />
          </FormCell>
          <FormCell>
            <label htmlFor='phone'>{t('phone')}:</label>
            <input
              name='phone'
              data-testid={`${testId}phone`}
              ref={register({required: { value: true, message: 'form.validation.required' }})}
            />
            <ErrorMessage errors={errors} name='phone' as={TranslatedErrorMessage} />
          </FormCell>
          <FormCell>
            <label htmlFor='email'>{t('email')}:</label>
            <input
              name='email'
              type='email'
              data-testid={`${testId}form-email`}
              ref={register({required: { value: true, message: 'form.validation.required' }})}
            />
            <ErrorMessage errors={errors} name='email' as={TranslatedErrorMessage} />
          </FormCell>
          <FormCell>
          <label>{t('clientView.basicData.type')}:</label>
          <CheckboxContainer>
            <input ref={register} type='checkbox' id='type.vip' name='type.vip' />
            <label htmlFor='type.vip' {...(testId && { 'data-testid': `${testId}-label` })} />
            <Icon type={IconType.Flag} />
            <input ref={register} type='checkbox' id='type.attention' name='type.attention' />
            <label htmlFor='type.attention' {...(testId && { 'data-testid': `${testId}-label` })} />
            <Icon type={IconType.Crown} />
          </CheckboxContainer>
        </FormCell>
        </Form>
      </Padder>

      <Dialog
        content='client.addNew.confirmDialog.content'
        open={confirmDialogOpen}
        onConfirm={handleConfirmAddClient}
        onClose={toggleOpenConfirmDialog}
        title='client.addNew.confirmDialog.title'
      />
      <FixedFooter onSave={handleSave} onCancel={handleReset} isSaveDisabled={isSaveDisabled} />
    </ClientContainer>
  )
}
