import { buildSpecificFiltersUrl } from 'actff-bo-lib/client/build-specific-filters-url'
import { ClientFilters } from 'actff-bo-lib/client/dto'
import { history } from 'actff-bo-lib/router'

export const maxPage = 999

export const redirectToList = (pathname: string, currentPage: number, filters?: ClientFilters | null) =>
  history.push({
    pathname,
    search: `page=${currentPage}${filters ? buildSpecificFiltersUrl(filters) : ''}`,
  })
