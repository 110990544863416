/* tslint:disable: no-object-mutation disable for redux-toolkit */
import { createSlice } from '@reduxjs/toolkit'
import { Loadable } from '../global'
import { DealerLocation } from './dto'

export type DealershipState = {
  readonly currentLocation: DealerLocation | null,
  readonly locations: Loadable<ReadonlyArray<DealerLocation> | null>,
  readonly brands: Loadable<ReadonlyArray<string> | null>,
  readonly currentBrand: string | null,
  readonly brakeLiquids: Loadable<ReadonlyArray<string> | null>,
  readonly engineCodes: Loadable<ReadonlyArray<string>>,
  readonly oilTypes: ReadonlyArray<string> | null,
  readonly models: Loadable<ReadonlyArray<string> | null>,
  readonly versions: Loadable<ReadonlyArray<string>>,
}

const initialState: DealershipState = {
  brakeLiquids: {
    data: null,
    loading: false,
  },
  brands: {
    data: null,
    loading: false,
  },
  currentBrand: null,
  currentLocation: null,
  engineCodes: {
    data: null,
    loading: false,
  },
  locations: {
    data: null,
    loading: false,
  },
  models: {
    data: null,
    loading: false,
  },
  oilTypes: null,
  versions: {
    data: null,
    loading: false,
  },
}

export const dealershipSlice = createSlice({
  initialState,
  name: 'dealership',
  reducers: {
    clearBrakeLiquids: state => {
      state.brakeLiquids.data = null
    },
    clearCarModels: state => {
      state.models.data = null
    },
    clearCarVersions: state => {
      state.versions.data = null
    },
    clearOilTypes: state => {
      state.oilTypes = null
    },
    getBrakeLiquidsSuccess: (state, action) => {
      state.brakeLiquids.data = action.payload
    },
    getCarModelsSuccess: (state, action) => {
      state.models.data = action.payload
    },
    getCarVersionsSuccess: (state, action) => {
      state.versions.data = action.payload
    },
    getDealerBrandsSuccess: (state, action) => {
      state.brands.data = action.payload
      state.currentBrand = action.payload[0]
    },
    getDealerLocationsSuccess: (state, action) => {
      state.locations.data = action.payload
      state.currentLocation = action.payload[0]
    },
    getEngineCodesSuccess: (state, action) => {
      state.engineCodes.data = action.payload
    },
    getOilTypesSuccess: (state, action) => {
      state.oilTypes = action.payload
    },
    setCurrentBrandAction: (state, action) => {
      state.currentBrand = action.payload
    },
    setCurrentLocationAction: (state, action) => {
      state.currentLocation = action.payload
    },
  },
})

export default dealershipSlice.reducer
