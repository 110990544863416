import { Filter } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { parseUrl } from 'actff-bo-lib/pagination'
import { UserAction, UserActionType } from 'actff-bo-lib/user'

import { CarAction, CarActionType } from '../actions'
import { CarFilterValues, CarStatus } from '../dto'
import { CarState } from '../state'

const parsedUrl = parseUrl(Paths.CarList)

const carInitialState: CarState = {
  currentCar: {
    data: null,
    loading: false,
  },
  currentCarAttachments: {
    data: null,
    loading: false,
  },
  currentCarRegistrationNumberExists: false,
  currentCarVinExists: false,
  currentPage: parsedUrl.page ? +parsedUrl.page :  1,
  isNdcCar: false,
  list: {
    brandFilters: [],
    data: null,
    filters: {
      status: [
        {
          caption: 'filters.new',
          selected: false,
          value: CarStatus.NEW,
        },
        {
          caption: 'filters.accepted',
          selected: false,
          value: CarStatus.ACCEPTED,
        },
        {
          caption: 'filters.rejected',
          selected: false,
          value: CarStatus.REJECTED,
        },
      ],
    },
    loading: false,
    locationFilters: [],
  },
  newCarsCount: 0,
}

// tslint:disable-next-line cyclomatic-complexity
export const carReducer = (state: CarState = carInitialState, action: CarAction | UserAction): CarState => {
  switch (action.type) {
    case CarActionType.ClearCurrentCar:
      const { currentCar, currentCarAttachments, currentCarRegistrationNumberExists, currentCarVinExists, isNdcCar } = carInitialState

      return {
        ...state,
        currentCar,
        currentCarAttachments,
        currentCarRegistrationNumberExists,
        currentCarVinExists,
        isNdcCar,
      }

    case CarActionType.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case CarActionType.ChangeDealerLocationFilters:
      return {
        ...state,
        list: {
          ...state.list,
          locationFilters: action.payload,
        },
      }

    case CarActionType.ChangeBrandFilters:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload,
        },
      }

    case CarActionType.ChangeListFilter:
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            [action.payload.param]: [
              ...state.list.filters[action.payload.param]
                .map((filter: Filter<CarFilterValues>) => filter.value === action.payload.value
                  ? ({
                    ...filter,
                    selected: !filter.selected,
                  })
                  : ({ ...filter }),
                ),
            ],
          },
          loading: true,
        },
      }

    case CarActionType.ChangeSearchPhrase:
      return {
        ...state,
        list: {
          ...state.list,
          ...(action.payload !== '' && {
            brandFilters: [],
            dealerLocationFilters: [],
          }),
          searchPhrase: action.payload,
        },
      }

    case CarActionType.DeleteCar:
    case CarActionType.RevertCar:
    case CarActionType.RejectCar:
    case CarActionType.GetCar:
      return {
        ...state,
        currentCar: {
          ...state.currentCar,
          loading: true,
        },
      }

    case CarActionType.DeleteCarFailure:
    case CarActionType.RevertCarFailure:
    case CarActionType.RejectCarFailure:
    case CarActionType.GetCarFailure:
      return {
        ...state,
        currentCar: {
          ...state.currentCar,
          loading: false,
        },
      }

    case CarActionType.DeleteCarSuccess:
      return {
        ...state,
        currentCar: {
          data: null,
          loading: false,
        },
      }

    case CarActionType.GetCarSuccess:
      return {
        ...state,
        currentCar: {
          data: action.payload,
          loading: false,
        },
        currentCarAttachments: {
          ...state.currentCarAttachments,
          data: null,
        },
        currentCarRegistrationNumberExists: false,
        currentCarVinExists: false,
      }

    case CarActionType.GetNdcCarSuccess:
      return {
        ...state,
        currentCar: {
          data: action.payload,
          loading: false,
        },
        currentCarAttachments: {
          ...state.currentCarAttachments,
          data: null,
        },
        currentCarRegistrationNumberExists: false,
        currentCarVinExists: true,
      }

    case CarActionType.GetCarAttachmentsSuccess:
      return {
        ...state,
        currentCarAttachments: {
          data: action.payload,
          loading: false,
        },
      }

    case CarActionType.GetCars:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }

    case CarActionType.GetCarsFailure:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
      }

    case CarActionType.GetCarsSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload,
          loading: false,
        },
      }

    case CarActionType.GetNewCarsCountSuccess:
      return {
        ...state,
        newCarsCount: action.payload.carsCount,
      }

    case CarActionType.UpdateCar:
      return {
        ...state,
        currentCar: {
          ...state.currentCar,
          loading: true,
        },
      }

    case CarActionType.UpdateCarFailure:
      return {
        ...state,
        currentCar: {
          ...state.currentCar,
          loading: false,
        },
      }

    case CarActionType.RejectCarSuccess:
    case CarActionType.RevertCarSuccess:
    case CarActionType.UpdateCarSuccess:
      return {
        ...state,
        currentCar: {
          data: action.payload,
          loading: false,
        },
      }

    case CarActionType.SetIsNdcCar:
      return {
        ...state,
        isNdcCar: action.payload,
      }

    case CarActionType.CheckRegistrationNumberExistenceSuccess:
      return {
        ...state,
        currentCarRegistrationNumberExists: action.payload.exists,
      }

    case CarActionType.CheckVinExistenceSuccess:
      return {
        ...state,
        currentCarVinExists: action.payload.exists,
      }

    case UserActionType.GetMeSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload.brands,
          locationFilters: action.payload.locations.map(location => location.key),
        },
      }

    default:
      return state
  }
}
