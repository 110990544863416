import { Loadable } from 'actff-bo-lib/global'

import { CrmServiceAction, CrmServiceActionType } from '../actions'
import { Lead } from '../dto'

const initialState = {
  data: null,
  loading: false,
}

export const crmServiceLeadReducer = (
  state: Loadable<Lead> = initialState,
  action: CrmServiceAction,
) => {
  switch (action.type) {
    case CrmServiceActionType.GetLead:
      return ({
        ...state,
        data: null,
        loading: true,
      })

    case CrmServiceActionType.GetLeadSuccess:
      return ({
        ...state,
        data: action.payload,
        loading: false,
      })

    case CrmServiceActionType.GetLeadFailure:
      return ({
        ...state,
        data: null,
        loading: false,
      })

    default:
      return state
  }
}
