import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { ValidationError } from './FormStyled'

export const TranslatedErrorMessage: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const message = children?.toString()

  return message ? <ValidationError>{t(message)}</ValidationError> : null
}
