import { ClientFullName, ClientPhone, ClientType } from 'actff-bo-app/components/Client'
import { TimeAgo } from 'actff-bo-app/components/DateTime'
import { WithStyledRow } from 'actff-bo-app/components/Table'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { defaultTimeFormat } from 'actff-bo-lib/date'
import { selectDealerLocationByKey } from 'actff-bo-lib/dealership'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { OfferRequest, OfferRequestStatus, OfferRequestTypeMap } from 'actff-bo-lib/offer-request'
import { format } from 'date-fns'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TableInfo } from 'styles'

type Props = Testable & {
  readonly offerRequest: OfferRequest,
}

export const OfferRequestRow: React.FC<Props> = ({ offerRequest, testId }) => {
  const { t } = useTranslation()
  const { car, client, requestTime, status, type } = offerRequest
  const route = createRoute(Paths.OfferRequestsView, { offerId: offerRequest.uuid })
  const Td = TdDecorator(route)

  const dealerLocation = useSelector(selectDealerLocationByKey(offerRequest.locationKey))

  return (
    <WithStyledRow
      isStyled={status === OfferRequestStatus.NEW}
      time={requestTime}
    >
      <Td testId={`${testId}request-time`}>
        {format(requestTime, defaultTimeFormat)}
        <TableInfo><TimeAgo date={requestTime} /></TableInfo>
      </Td>
      <Td testId={`${testId}full-name`}><ClientFullName client={client} /></Td>
      <Td testId={`${testId}type`}><ClientType client={client} /></Td>
      <Td testId={`${testId}phone`}><ClientPhone client={client} /></Td>
      <Td testId={`${testId}alias`}>{car.alias}</Td>
      <Td testId={`${testId}status`}>{t(OfferRequestTypeMap.get(type, ''))}</Td>
      <Td testId={`${testId}dealer-location`}>{dealerLocation?.name}</Td>
    </WithStyledRow>
  )
}
