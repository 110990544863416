import { Loader } from 'actff-bo-app/components/Loader'
import { UploadFileProgress } from 'actff-bo-app/components/UploadFile'
import { CarId, CarInspectionAction, InspectionDto, InspectionId, selectCurrentInspection } from 'actff-bo-lib/car'
import { Attachment } from 'actff-bo-lib/global'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { withCarViewHeader, WithCarViewHeaderProps } from '../withCarViewHeader'
import { InspectionForm } from './InspectionForm'

type ExistingInspectionParams = {
  readonly inspectionId: InspectionId,
  readonly carId: CarId,
}

type Props = WithCarViewHeaderProps

const InspectionComponent: FC<Props> = ({ car }) => {
  const { inspectionId } = useParams<ExistingInspectionParams>()

  const dispatch = useDispatch()

  const saveInspection = (inspection: InspectionDto, attachments?: ReadonlyArray<Attachment>) =>
    inspectionId
      ? dispatch(CarInspectionAction.updateInspectionAndSendAttachments(
        car.vin,
        inspectionId,
        inspection,
        attachments,
      ))
      : dispatch(CarInspectionAction.createInspectionAndSendAttachments(car.vin, inspection, attachments))

  const getInspection = useMemo(() => () => {
    inspectionId
      ? dispatch(CarInspectionAction.getInspection(car.vin, inspectionId))
      : dispatch(CarInspectionAction.getInspection(car.vin))
  }, [dispatch, inspectionId, car.vin])

  const { loading, data } = useSelector(selectCurrentInspection)

  useEffect(() => {
    getInspection()
  }, [getInspection])

  if (loading) {
    return <Loader />
  }

  return (
    data && (
      <>
        <InspectionForm
          car={car}
          defaultValues={data}
          handleSubmit={saveInspection}
        />
        <UploadFileProgress />
      </>
    )
  )
}

export const Inspection = withCarViewHeader(InspectionComponent)
