import { ReactSelect } from 'actff-bo-app/components/Select'
import { SelectOption } from 'actff-bo-lib/global'
import i18next from 'i18next'
import React, { FC } from 'react'
import { components } from 'react-select'
import { OptionProps } from 'react-select/src/components/Option'
import { Props as ReactSelectProps } from 'react-select/src/Select'
import styled from 'styled-components'
import { FlattenInterpolation } from 'styled-components/macro'

type Props = ReactSelectProps & {
  readonly shouldTranslate: boolean,
  readonly t?: i18next.TFunction,
  readonly tOptionKey?: string,
  readonly singleOptionStyles?: FlattenInterpolation<string>,
}

type OptionContainerProps = {
  readonly styles: string,
}

const OptionContainer = styled.div<OptionContainerProps>`
  cursor: pointer;
  ${({ styles }: OptionContainerProps) => styles};
`

const Option = (shouldTranslate: boolean, t?: i18next.TFunction, tKey?: string, singleOptionStyles?: FlattenInterpolation<string>) => (
  optionProps: OptionProps<SelectOption<string>, true>) => {
  const { children, isSelected, label } = optionProps

  const getLabel = () => {
    if (t && tKey) {
      return t(`${tKey}.children`)
    }

    return children
  }

  return (
    <components.Option {...optionProps}>
      <OptionContainer styles={singleOptionStyles}>
        <input
          id={`filter--${children}`}
          checked={isSelected}
          readOnly={true}
          type='checkbox'
          value=''
        />
        <label htmlFor={`filter--${label}`}>{getLabel()}</label>
      </OptionContainer>
    </components.Option>
  )
}

export const MultipleSelect: FC<Props> = ({ shouldTranslate, t, tOptionKey, singleOptionStyles, ...props }) =>
  (
    <ReactSelect
      {...props}
      hasSelectedOptionBackground={false}
      // @ts-ignore mismatch with react-select typings
      components={{ Option: Option(shouldTranslate, t, tOptionKey, singleOptionStyles) }}
    />
  )
