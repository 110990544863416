import { DebugToolsAction, DebugToolsActionType } from './actions'

export type DebugToolsState = {
  readonly logrocket: {
    readonly initialized: boolean,
  },
}

const debugToolsInitialState: DebugToolsState = {
  logrocket: {
    initialized: false,
  },
}

export const debugToolsReducer = (state: DebugToolsState = debugToolsInitialState, action: DebugToolsAction): DebugToolsState => {
  switch (action.type) {
    case DebugToolsActionType.InitLogrocketSuccess:
      return {
        ...state,
        logrocket: {
          initialized: true,
        },
      }
    default:
      return state
  }
}
