import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'redux'
import { formik } from './Form'
import { mapDispatchToProps, mapStateToProps, QuickCampaignComponent } from './QuickCampaignComponent'

export const QuickCampaign = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  formik,
)(QuickCampaignComponent)
