import { endOfToday, startOfToday, subMonths } from 'date-fns'

import { DashboardData, DashboardDataGroupByType, DashboardDataUrlType, DashboardSummaryData } from './dto'

export type DashboardState = {
  readonly detailedData: {
    readonly data: DashboardData | null,
    readonly from: Date | null,
    readonly groupBy: DashboardDataGroupByType,
    readonly to: Date | null,
    readonly type: DashboardDataUrlType,
  },
  readonly summaryData: DashboardSummaryData | null,
}

const fromMonthDifference = 5

export const initialState: DashboardState = {
  detailedData: {
    data: null,
    from: subMonths(startOfToday(), fromMonthDifference),
    groupBy: DashboardDataGroupByType.Day,
    to: endOfToday(),
    type: DashboardDataUrlType.Clients,
  },
  summaryData: null,
}
