import { ValueForInsuranceCalculation } from 'actff-bo-lib/car'
import { toPriceString } from 'actff-bo-lib/currency'

export const insuranceValueCalculator = (marketValue: number, valueForCalculation: ValueForInsuranceCalculation): number => {
  const parsedGrossValue: number = Math.round(+(toPriceString(marketValue?.toString() || '0')))
  const value = isNaN(parsedGrossValue) ? 0 : parsedGrossValue

  const netFactor = 1.23
  const customFactor = 1.115
  const netValue = Math.round(value / netFactor)
  const customValue = Math.round((value / netFactor) * customFactor)

  // tslint:disable-next-line cyclomatic-complexity
  const insuranceValue = () => {
    switch (valueForCalculation) {
      case ValueForInsuranceCalculation.CUSTOM:
        return customValue
      case ValueForInsuranceCalculation.GROSS:
        return value
      case ValueForInsuranceCalculation.NET:
        return netValue
      default:
        return 0
    }
  }

  return insuranceValue()
}
