import { I18nAction, I18nActionType } from './actions'
import { Language } from './dto'

export type I18nState = {
  readonly initialized: boolean,
  readonly language: Language,
}

const i18nInitialState: I18nState = {
  initialized: false,
  language: Language.pl,
}

export const i18nReducer = (state: I18nState = i18nInitialState, action: I18nAction): I18nState => {
  switch (action.type) {
    case I18nActionType.InitSuccess:
      return {
        ...state,
        initialized: true,
      }
    default:
      return state
  }
}
