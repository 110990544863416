import { CombinedDealershipFilters, ListFilters } from 'actff-bo-app/components/ListFilters'
import { FiltersRendererProps } from 'actff-bo-app/components/TabFilters'
import { ChatAction, selectThreadListNumberOfPages, Thread, ThreadId } from 'actff-bo-lib/chat'

import { selectChatDealershipFilters, selectThreadListCurrentPage } from 'actff-bo-lib/chat/selectors'
import { WithDealershipFilters } from 'actff-bo-lib/dealership'
import { connector } from 'actff-bo-lib/global'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { State } from 'actff-bo-lib/store'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { MapDispatchToProps, MapStateToProps } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import { LinkButton } from 'styles'
import { ChatNewThread } from '../ChatNewThread'
import { ChatThreadListItem } from './ChatThreadListItem'
import { ChatThreadHeader, ChatThreadListContainer, ChatThreadListStyled } from './ChatThreadListStyled'

type Props = {
  readonly currentThreadId: ThreadId,
  readonly threadList: ReadonlyArray<Thread>,
}

type DispatchToProps = {
  readonly changeDealershipFilters: (filters: WithDealershipFilters) => void,
  readonly connectChatThreadListSocket: () => void,
  readonly disconnectChatThreadListSocket: () => void,
  readonly loadMoreThreads: () => void,
}

type StateToProps = {
  readonly dealershipFilters: WithDealershipFilters,
  readonly currentPage: number,
  readonly numberOfPages?: number,
}

type ChatThreadListProps = Props & DispatchToProps & StateToProps & WithTranslation

class ChatThreadListComponent extends React.Component<ChatThreadListProps> {
  public componentDidMount(): void {
    this.props.connectChatThreadListSocket()
  }

  public componentWillUnmount(): void {
    this.props.disconnectChatThreadListSocket()
  }

  public render(): React.ReactNode {
    const {
      changeDealershipFilters,
      dealershipFilters,
      currentThreadId,
      currentPage,
      loadMoreThreads,
      numberOfPages = 0,
      threadList,
      t,
    } = this.props

    return threadList
      ? (
        <ChatThreadListContainer>
          <ChatThreadHeader>
            <ChatNewThread />
            <ListFilters
              filtersCaption={t('caption.filter')}
              filtersComponent={(filterRendererProps: FiltersRendererProps) => <CombinedDealershipFilters {...filterRendererProps} />}
              combinedFilterValues={dealershipFilters}
              onSubmit={changeDealershipFilters}
              styles={{ backgroundColor: 'white' }}
              tabWidth='120'
              transparent={true}
            />
          </ChatThreadHeader>
          <ChatThreadListStyled>
            {threadList.map(thread => (
              <NavLink key={thread.threadUuid} to={createRoute(Paths.ChatView, { threadId: thread.threadUuid })}>
                <ChatThreadListItem active={currentThreadId === thread.threadUuid} thread={thread} />
              </NavLink>
            ))}
            {currentPage < numberOfPages && <LinkButton onClick={loadMoreThreads}>{t('chat.threadList.loadMore')}</LinkButton>}
          </ChatThreadListStyled>
        </ChatThreadListContainer>
      ) : null
  }
}

const mapDispatchToProps: MapDispatchToProps<DispatchToProps, null> = dispatch => ({
  changeDealershipFilters: filters => { dispatch(ChatAction.changeDealershipFilters(filters)) },
  connectChatThreadListSocket: () => { dispatch(ChatAction.connectChatThreadsSocket()) },
  disconnectChatThreadListSocket: () => { dispatch(ChatAction.disconnectChatThreadsSocket()) },
  loadMoreThreads: () => { dispatch(ChatAction.loadMoreThreads()) },
})

const mapStateToProps: MapStateToProps<StateToProps, null, State> = state => ({
  currentPage: selectThreadListCurrentPage(state),
  dealershipFilters: selectChatDealershipFilters(state),
  numberOfPages: selectThreadListNumberOfPages(state),
})

export const ChatThreadList = compose(
  connector(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(ChatThreadListComponent)
