import { SaveEditButton } from 'actff-bo-app/components/Button'
import { Icon } from 'actff-bo-app/components/Icon'
import styled from 'styled-components'
import { colors } from 'styles'

type RowProps = {
  readonly color: string,
}

export const StyledIcon = styled(Icon)`
  margin-left: 14px;
`
export const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px solid ${colors.mysticGray};
`

export const ServiceRequestContent = styled.div``

export const ClientContent = styled.div`
  border-left: 1px solid ${colors.mysticGray};
  padding: 24px 32px;
`

export const Row = styled.div`
  background: ${({ color }: RowProps) => color};
  border-bottom: 1px solid ${colors.mysticGray};
  padding-right: 32px;
  position: relative;
`

export const RowSaveEditButton = styled(SaveEditButton)`
  color: ${colors.kumera};
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  right: 32px;
  top: 3px;
  display: inline;
`

export const CommentContent = styled.div`
  font-weight: 300;
  font-style: italic;
  line-height: 23px;
`
