import {
  OpportunityPurchaseId,
  OpportunityPurchaseStatusType,
  OpportunitySaleId,
  OpportunitySaleStatusType,
  OpportunityTradeInternalEvent,
} from 'actff-bo-lib/crm/trade'
import { UserWithoutPermissions } from 'actff-bo-lib/user/dto'

export type OpportunityInternalComment = {
  readonly created: Date,
  readonly comment: string,
  readonly author: UserWithoutPermissions,
}

export type CrmInternalCommentDto = {
  readonly comment: string,
}

export type EventHistory = OpportunityTradeInternalEvent

export enum OpportunityListType {
  future = 'future',
  new = 'new',
  overdue = 'overdue',
  all = 'all',
}

export type OpportunityAccomplishmentDetails = {
  readonly invoiceNumber: string,
  readonly orderNumber: string,
  readonly partsProfit: string,
  readonly labourProfit: string,
}

const TradeStatuses = { ...OpportunitySaleStatusType, ...OpportunityPurchaseStatusType }
type TradeOpportunityStatusType = typeof TradeStatuses

export type TradeMatch = {
  readonly statusInOpportunity: OpportunityPurchaseStatusType,
  readonly status: TradeOpportunityStatusType,
  readonly created: Date,
  readonly purchaseUuid: OpportunityPurchaseId,
  readonly saleUuid: OpportunitySaleId,
  readonly uuid: string,
  readonly updated: Date,
}
