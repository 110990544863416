import { Client } from 'actff-bo-lib/client'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontallyScrolledContainer, Table, Th, Tr } from 'styles'

import { Placeholder } from '../../Styled'
import { AppPersonRow } from './AppPersonRow'

type Props = {
  readonly list: ReadonlyArray<Client>,
  readonly onPersonClick: (person: Client) => void,
  readonly selectedPerson: Client | null,
}

const testId = 'app-person-list__person-'

export const AppPersonList: FC<Props> = ({ onPersonClick, list, selectedPerson }) => {
  const { t } = useTranslation()

  return (
    <HorizontallyScrolledContainer>
      <Table>
        <thead>
        <Tr>
          <Th />
          <Th>{t('table.columns.fullName')}</Th>
          <Th>{t('table.columns.phone')}</Th>
          <Th>{t('table.columns.email')}</Th>
          <Th>{t('table.columns.id')}</Th>
          <Th>{t('table.columns.type')}</Th>
        </Tr>
        </thead>
        <tbody>
        {list.length < 1 ? (
        <Placeholder>{t('ownership.searchPerson.placeholder')}</Placeholder>
      ) : (
        list.map((person: Client, index: number) => (
          <AppPersonRow
            key={person.uuid}
            person={person}
            onClick={() => onPersonClick(person)}
            selected={person.uuid === selectedPerson?.uuid}
            testId={`${testId}row-${index}`}
          />
          ))
        )}
        </tbody>
      </Table>
    </HorizontallyScrolledContainer>
  )
}
