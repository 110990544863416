import { Icon } from 'actff-bo-app/components/Icon'
import { IconType, MenuItem } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import styled from 'styled-components'

type ExpandCollapseProps = {
  readonly menuItem: MenuItem,
  readonly onClick?: () => void,
}

const DropdownButton = styled.button`
  align-self: center;
  cursor: pointer;
  display: grid;
  justify-self: end;
  padding: 10px;
`

const RotatedImg = styled(Icon)`
  transform: rotate(180deg);
`

export const ExpandCollapse: React.FC<ExpandCollapseProps> = ({ menuItem, onClick }) => (
  menuItem.children.length > 0
    ? (
      <DropdownButton onClick={onClick && onClick}>
        {menuItem.expanded ? <RotatedImg className='dropdown' type={IconType.Dropdown} /> : <Icon type={IconType.Dropdown} />}
      </DropdownButton>
    )
    : null
)
