import { BasicDataFormValues } from 'actff-bo-app/Clients/ClientView/BasicData/BasicDataForm'
import { Client, ClientDto, ClientType } from 'actff-bo-lib/client'

export const clientHasType = (client: Client, type: ClientType) =>
  client.type.some(clientType => clientType === type)

export const clientFormToClientDto = (client: Client, formValues: BasicDataFormValues): ClientDto => ({
  email: formValues.email,
  firstName: formValues.firstName,
  lastName: formValues.lastName,
  type: Object.values(ClientType).filter((_, index) => formValues.type[index]),
  uuid: client.uuid,
})
