export enum InsuranceCarKind {
  PASSENGER = 'Osobowy',
  TRUCK = 'Ciężarowy',
  MOTOR_VAN = 'Furgon',
  MOTORBICYCLE = 'Motorower',
  MOTORCYCLE = 'Motocykl',
  TRAILER = 'Przyczepa',
  SCOOTER = 'Skuter',
}

export enum InternalCarType {
  DEMO = 'DEMO',
  TINSMITH_CAR = 'TINSMITH_CAR',
  COMPANY_CAR = 'COMPANY_CAR',
  LOANER = 'LOANER',
}

export type OpportunityCar = {
  readonly vin: string,
  readonly registrationNumber: string,
  readonly carKind: string,
  readonly brand: string,
  readonly model: string,
  readonly modelVersion: string,
  readonly productionYear: number,
  readonly firstRegistrationDate: Date,
  readonly inHouse: boolean,
  readonly inHouseType: string,
  readonly imported: boolean,
}
