import { Testable } from 'actff-bo-lib/global/testable'
import { OfferRequestAnswer as OfferRequestAnswerType } from 'actff-bo-lib/offer-request'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, H2 } from 'styles'

type Props = Testable & {
  readonly answers: ReadonlyArray<OfferRequestAnswerType>,
}

const Container = styled.div`
  grid-area: questions;
  padding-left: 32px;
`

const Question = styled.div`
  margin-bottom: 10px;
`

const AgreementYes = styled.span`
  color: ${colors.shamrock};
  font-weight: 600;
`

const AgreementNo = styled.span`
  color: ${colors.flamingoRed};
  font-weight: 600;
`

const Header = styled(H2)`
  display: block;
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const OfferRequestAnswers: FC<Props> = ({ answers, testId }) => {
  const { t } = useTranslation()

  return (
    <Container>
      {answers.length > 0
        ? (
          <>
          <Header data-testid={`${testId}header`}>{t('offerRequest.renew.header')}</Header>
            {answers.map((question, index) => (
              <Question data-testid={`${testId}${index}`} key={`${question} + ${index}`}>
                {index + 1}.&nbsp;
                <span data-testid={`${testId}${index}-question`}>{question.question}&nbsp;</span>
                {question.agreed
                  ? <AgreementYes data-testid={`${testId}${index}-answer--yes`}>{t('caption.yes')}</AgreementYes>
                  : <AgreementNo data-testid={`${testId}${index}-answer--no`}>{t('caption.no')}</AgreementNo>}
                <br />
              </Question>
            ))}
          </>
        )
        : null
      }
    </Container>
  )
}
