import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { PagedResult } from 'actff-bo-lib/api'
import { CarId } from 'actff-bo-lib/car'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { InternalComment } from 'actff-bo-lib/global'
import { Service } from 'actff-bo-lib/service'
import { ServiceRequestStatus } from 'actff-bo-lib/service-request'
import { AjaxError } from 'rxjs/ajax'

import { CarLeaveOption, ServiceRequest, ServiceRequestCount, ServiceRequestFilterValues, ServiceRequestId } from './dto'

export enum ServiceRequestActionType {
  ChangeDealerLocations = '[SERVICE REQUEST] CHANGE DEALER LOCATIONS',
  ChangeBrands = '[SERVICE REQUEST] CHANGE CAR BRANDS',
  ChangeListFilter = '[SERVICE REQUEST] CHANGE LIST FILTER',
  ChangePage = '[SERVICE REQUEST] CHANGE PAGE',
  ChangeOverdueListPage = '[SERVICE REQUEST] CHANGE OVERDUE LIST PAGE',
  ChangeSearchPhrase = '[SERVICE REQUEST] CHANGE SEARCH PHRASE',

  ClearServiceRequest = '[SERVICE REQUEST] CLEAR SERVICE REQUEST',

  CreateServiceRequest = '[SERVICE REQUEST] CREATE SERVICE REQUEST',
  CreateServiceRequestFailure = '[SERVICE REQUEST] CREATE SERVICE REQUEST FAILURE',
  CreateServiceRequestSuccess = '[SERVICE REQUEST] CREATE SERVICE REQUEST SUCCESS',

  GetServiceRequest = '[SERVICE REQUEST] GET SERVICE REQUEST',
  GetServiceRequestFailure = '[SERVICE REQUEST] GET SERVICE REQUEST FAILURE',
  GetServiceRequestSuccess = '[SERVICE REQUEST] GET SERVICE REQUEST SUCCESS',

  GetServiceRequestAttachments = '[SERVICE REQUEST] GET SERVICE REQUEST ATTACHMENTS',
  GetServiceRequestAttachmentsFailure = '[SERVICE REQUEST] GET SERVICE REQUEST ATTACHMENTS FAILURE',
  GetServiceRequestAttachmentsSuccess = '[SERVICE REQUEST] GET SERVICE REQUEST ATTACHMENTS SUCCESS',

  GetServiceRequestComments = '[SERVICE REQUEST] GET SERVICE REQUEST COMMENTS',
  GetServiceRequestCommentsFailure = '[SERVICE REQUEST] GET SERVICE REQUEST COMMENTS FAILURE',
  GetServiceRequestCommentsSuccess = '[SERVICE REQUEST] GET SERVICE REQUEST COMMENTS SUCCESS',

  GetAvailableCarLeaveOptions = '[SERVICE REQUEST] GET AVAILABLE CAR LEAVE OPTIONS',
  GetAvailableCarLeaveOptionsFailure = '[SERVICE REQUEST] GET AVAILABLE CAR LEAVE OPTIONS FAILURE',
  GetAvailableCarLeaveOptionsSuccess = '[SERVICE REQUEST] GET AVAILABLE CAR LEAVE OPTIONS SUCCESS',

  GetServiceRequests = '[SERVICE REQUEST] GET SERVICE REQUESTS',
  GetServiceRequestsFailure = '[SERVICE REQUEST] GET SERVICE REQUESTS FAILURE',
  GetServiceRequestsSuccess = '[SERVICE REQUEST] GET SERVICE REQUESTS SUCCESS',

  GetOverdueServiceRequests = '[SERVICE REQUEST] GET OVERDUE SERVICE REQUESTS',
  GetOverdueServiceRequestsFailure = '[SERVICE REQUEST] GET OVERDUE SERVICE REQUESTS FAILURE',
  GetOverdueServiceRequestsSuccess = '[SERVICE REQUEST] GET OVERDUE SERVICE REQUESTS SUCCESS',

  GetNewServiceRequestCount = '[SERVICE REQUEST] GET NEW SERVICE REQUEST COUNT',
  GetNewServiceRequestCountFailure = '[SERVICE REQUEST] GET NEW SERVICE REQUEST COUNT FAILURE',
  GetNewServiceRequestCountSuccess = '[SERVICE REQUEST] GET NEW SERVICE REQUEST COUNT SUCCESS',

  ClearDraft = '[SERVICE REQUEST] CLEAR DRAFT',
  SetDraftCarLeaveOption = '[SERVICE REQUEST] SET DRAFT CAR LEAVE OPTION',
  SetDraftInterestedInEarlierDate = '[SERVICE REQUEST] SET DRAFT INTERESTED IN EARLIER DATE',
  SetDraftServices = '[SERVICE REQUEST] SET DRAFT SERVICES',
  SetDraftStatus = '[SERVICE REQUEST] SET DRAFT STATUS',

  SendInternalComment = '[SERVICE REQUEST] SEND INTERNAL COMMENT',
  SendInternalCommentFailure = '[SERVICE REQUEST] SEND INTERNAL COMMENT FAILURE',
  SendInternalCommentSuccess = '[SERVICE REQUEST] SEND INTERNAL COMMENT SUCCESS',

  UndoChanges = '[SERVICE REQUEST] UNDO CHANGES',

  UpdateServiceRequestAsInProgress = '[SERVICE REQUEST] UPDATE SERVICE REQUEST AS IN PROGRESS',
  UpdateServiceRequestAsInProgressFailure = '[SERVICE REQUEST] UPDATE SERVICE REQUEST AS IN PROGRESS FAILURE',
  UpdateServiceRequestAsInProgressSuccess = '[SERVICE REQUEST] UPDATE SERVICE REQUEST AS IN PROGRESS SUCCESS',
  UpdateServiceRequestFromDraft = '[SERVICE REQUEST] UPDATE SERVICE REQUEST FROM DRAFT',
  UpdateServiceRequestFromDraftFailure = '[SERVICE REQUEST] UPDATE SERVICE REQUEST FROM DRAFT FAILURE',
  UpdateServiceRequestFromDraftSuccess = '[SERVICE REQUEST] UPDATE SERVICE REQUEST FROM DRAFT SUCCESS',
}

export const ServiceRequestAction = {
  changeCarBrands: (brands: ReadonlyArray<string>) =>
    createAction(ServiceRequestActionType.ChangeBrands, brands),
  changeDealerLocations: (dealerLocations: ReadonlyArray<DealerLocationKey>) =>
    createAction(ServiceRequestActionType.ChangeDealerLocations, dealerLocations),
  changeListFilter: (param: string, value: ServiceRequestFilterValues) =>
    createAction(ServiceRequestActionType.ChangeListFilter, { param, value }),
  changeOverdueListPage: (page: number) => createAction(ServiceRequestActionType.ChangeOverdueListPage, page),
  changePage: (page: number) => createAction(ServiceRequestActionType.ChangePage, page),
  changeSearchPhrase: (searchPhrase: string) => createAction(ServiceRequestActionType.ChangeSearchPhrase, searchPhrase),

  getAvailableCarLeaveOptions: () => createAction(ServiceRequestActionType.GetAvailableCarLeaveOptions),
  getAvailableCarLeaveOptionsFailure: (error: AjaxError) =>
    createAction(ServiceRequestActionType.GetAvailableCarLeaveOptionsFailure, error),
  getAvailableCarLeaveOptionsSuccess: (carLeaveOptions: ReadonlyArray<CarLeaveOption>) =>
    createAction(ServiceRequestActionType.GetAvailableCarLeaveOptionsSuccess, carLeaveOptions),

  clearServiceRequest: () => createAction(ServiceRequestActionType.ClearServiceRequest),

  createServiceRequest: (carUuid: CarId) => createAction(ServiceRequestActionType.CreateServiceRequest, carUuid),
  createServiceRequestFailure: (error: AjaxError) => createAction(ServiceRequestActionType.CreateServiceRequestFailure, error),
  createServiceRequestSuccess: (serviceRequest: ServiceRequest) =>
    createAction(ServiceRequestActionType.CreateServiceRequestSuccess, serviceRequest),

  getServiceRequest: (serviceRequestId: ServiceRequestId) => createAction(ServiceRequestActionType.GetServiceRequest, serviceRequestId),
  getServiceRequestFailure: (error: AjaxError) => createAction(ServiceRequestActionType.GetServiceRequestFailure, error),
  getServiceRequestSuccess: (serviceRequest: ServiceRequest) =>
    createAction(ServiceRequestActionType.GetServiceRequestSuccess, serviceRequest),

  getServiceRequestAttachments: () => createAction(ServiceRequestActionType.GetServiceRequestAttachments),
  getServiceRequestAttachmentsFailure: (error: AjaxError) =>
    createAction(ServiceRequestActionType.GetServiceRequestAttachmentsFailure, error),
  getServiceRequestAttachmentsSuccess: (comments: ReadonlyArray<string>) =>
    createAction(ServiceRequestActionType.GetServiceRequestAttachmentsSuccess, comments),

  getServiceRequestComments: (serviceRequestId: ServiceRequestId) =>
    createAction(ServiceRequestActionType.GetServiceRequestComments, serviceRequestId),
  getServiceRequestCommentsFailure: (error: AjaxError) =>
    createAction(ServiceRequestActionType.GetServiceRequestCommentsFailure, error),
  getServiceRequestCommentsSuccess: (comments: ReadonlyArray<InternalComment>) =>
    createAction(ServiceRequestActionType.GetServiceRequestCommentsSuccess, comments),

  getServiceRequests: () => createAction(ServiceRequestActionType.GetServiceRequests),
  getServiceRequestsFailure: (error: AjaxError) => createAction(ServiceRequestActionType.GetServiceRequestsFailure, error),
  getServiceRequestsSuccess: (payload: PagedResult<ServiceRequest>) =>
    createAction(ServiceRequestActionType.GetServiceRequestsSuccess, payload),

  getOverdueServiceRequests: () => createAction(ServiceRequestActionType.GetOverdueServiceRequests),
  getOverdueServiceRequestsFailure: (error: AjaxError) => createAction(ServiceRequestActionType.GetOverdueServiceRequestsFailure, error),
  getOverdueServiceRequestsSuccess: (payload: PagedResult<ServiceRequest>) =>
    createAction(ServiceRequestActionType.GetOverdueServiceRequestsSuccess, payload),

  getNewServiceRequestCount: () => createAction(ServiceRequestActionType.GetNewServiceRequestCount),
  getNewServiceRequestCountFailure: (error: AjaxError) => createAction(ServiceRequestActionType.GetNewServiceRequestCountFailure, error),
  getNewServiceRequestCountSuccess: (count: ServiceRequestCount) =>
    createAction(ServiceRequestActionType.GetNewServiceRequestCountSuccess, count),

  clearDraft: () => createAction(ServiceRequestActionType.ClearDraft),
  setDraftCarLeaveOption: (option: CarLeaveOption) => createAction(ServiceRequestActionType.SetDraftCarLeaveOption, option),
  setDraftInterestedInEarlierDate: (value: boolean) => createAction(ServiceRequestActionType.SetDraftInterestedInEarlierDate, value),
  setDraftServices: (services: ReadonlyArray<Service>) => createAction(ServiceRequestActionType.SetDraftServices, services),
  setDraftStatus: (status: ServiceRequestStatus) => createAction(ServiceRequestActionType.SetDraftStatus, status),

  sendInternalComment: (serviceRequestUuid: ServiceRequestId, comment: string) =>
    createAction(ServiceRequestActionType.SendInternalComment, { serviceRequestUuid, comment }),
  sendInternalCommentFailure: (error: AjaxError) => createAction(ServiceRequestActionType.SendInternalCommentFailure, error),
  sendInternalCommentSuccess: (comment: InternalComment) =>
    createAction(ServiceRequestActionType.SendInternalCommentSuccess, comment),

  undoChanges: () => createAction(ServiceRequestActionType.UndoChanges),

  updateServiceRequestAsInProgress: () => createAction(ServiceRequestActionType.UpdateServiceRequestAsInProgress),
  updateServiceRequestAsInProgressFailure: (error: AjaxError) =>
    createAction(ServiceRequestActionType.UpdateServiceRequestAsInProgressFailure, error),
  updateServiceRequestAsInProgressSuccess: () => createAction(ServiceRequestActionType.UpdateServiceRequestAsInProgressSuccess),
  updateServiceRequestFromDraft: () => createAction(ServiceRequestActionType.UpdateServiceRequestFromDraft),
  updateServiceRequestFromDraftFailure: (error: AjaxError) =>
  createAction(ServiceRequestActionType.UpdateServiceRequestFromDraftFailure, error),
  updateServiceRequestFromDraftSuccess: () => createAction(ServiceRequestActionType.UpdateServiceRequestFromDraftSuccess),
}

export type ServiceRequestAction = ActionsUnion<typeof ServiceRequestAction>
