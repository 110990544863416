import { ErrorMessage } from '@hookform/error-message'
import { BtnTheme } from 'actff-bo-app/components/Button'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { DictionaryType } from 'actff-bo-lib/dictionary/dto'
import { getValidator, ValidationType } from 'actff-bo-lib/form/rhf-validators'
import { hasErrors, isString } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Tr } from 'styles'
import { TranslatedErrorMessage } from '../Form/TranslatedErrorMessage'

import { Item } from './Item'

import { AddingDialogContent, ButtonWithIcon, Container, Label, Table, Th } from './Styled'

type Props = Testable & {
  readonly items?: ReadonlyArray<DictionaryType<string> | string>,
  readonly onAdd: (name: string) => void,
  readonly onDelete: (itemValue: string | DictionaryType<string>) => void,
  readonly addButtonCaptionTKey: string,
  readonly noPadding?: boolean,
  readonly tableHeaderCaptionTKey: string,
}

type Form = {
  readonly name: string,
}

export const EditableList: FC<Props> = ({ addButtonCaptionTKey, items, noPadding, onDelete, onAdd, tableHeaderCaptionTKey }) => {
  const { t } = useTranslation()

  const [addNewItemOpen, setAddNewItemOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<DictionaryType<string> | string | null>(null)

  const { handleSubmit, formState, errors, register } = useForm<Form>({
    defaultValues: { name: '' },
    mode: 'onBlur',
  })

  const toggleAddDialogOpen = () => { setAddNewItemOpen(!addNewItemOpen) }

  const onDialogClose = () => {
    toggleAddDialogOpen()
  }

  const onDialogConfirm = (formValues: Form) => {
    onAdd(formValues.name)
    toggleAddDialogOpen()
  }

  const handleDelete = (item: DictionaryType<string> | string) => () => {
    setItemToDelete(item)
  }

  const onDeleteDialogConfirm = () => {
    if (!itemToDelete) { return }

    isString(itemToDelete) ? onDelete(itemToDelete) : onDelete(itemToDelete.value)
    setItemToDelete(null)
  }

  const onDeleteDialogClose = () => {
    setItemToDelete(null)
  }

  const getItemKey = (item: DictionaryType<string> | string) => isString(item) ? item : item.value
  const getItemLabel = (_item: DictionaryType<string> | string | null) => _item ? isString(_item) ? _item : _item.label : ''
  const getAddItemButtonTKey = () => addButtonCaptionTKey ?? 'caption.addElement'

  const isSubmitFormDisabled = !formState.isDirty || hasErrors(errors)

  return (
    <form>
      <Container noPadding={noPadding}>
        <Table>
          <thead>
          <Tr>
            <Th>{t('caption.listNumber')}</Th>
            <Th>{t(tableHeaderCaptionTKey)}</Th>
          </Tr>
          </thead>
          <tbody>
            {items?.map((item, index) => (
              <Item
                key={getItemKey(item)}
                item={item}
                index={index}
                onDelete={handleDelete(item)}
              />
            ))}
          </tbody>
        </Table>
        <ButtonWithIcon
          caption={t(getAddItemButtonTKey())}
          onClick={toggleAddDialogOpen}
          iconType={IconType.Plus}
          theme={BtnTheme.Link}
        />
      </Container>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.add.content'
        confirmDisabled={isSubmitFormDisabled}
        open={addNewItemOpen}
        onConfirm={handleSubmit(onDialogConfirm)}
        onClose={onDialogClose}
        title='contentManagement.dialog.add.title'
      >
        <AddingDialogContent>
          <Label>{t('caption.name')}:</Label>
          <input
            type='text'
            name='name'
            ref={register({
              ...getValidator({ type: ValidationType.Required }),
            })}
          />
          <ErrorMessage errors={errors} name='name' as={TranslatedErrorMessage} />
        </AddingDialogContent>
      </Dialog>

      <Dialog
        autoWidth={true}
        content='contentManagement.dialog.delete.content'
        contentProps={{ item: getItemLabel(itemToDelete) || '' }}
        open={!!itemToDelete}
        onConfirm={onDeleteDialogConfirm}
        onClose={onDeleteDialogClose}
        title='contentManagement.dialog.delete.title'
      />
    </form>
  )
}
