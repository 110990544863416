import { VisitAvailabilityForm } from 'actff-bo-app/Admin/DealerLocations/VisitAvailability/VisitAvailabilityForm'
import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { NoData } from 'actff-bo-app/components/NoData'
import {
  AdminDealerSettings,
  AdminLocationSettingsAction,
  selectAdminDealerSettings,
} from 'actff-bo-lib/admin/dealer-locations'
import { selectCurrentLocation } from 'actff-bo-lib/dealership'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMount, usePrevious } from 'react-use'

import { ViewTabs } from '../ViewTabs'

const testId = 'admin-view-visit-availability__'

const VisitAvailabilityComponent: FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentLocation = useSelector(selectCurrentLocation)
  const dealerLocationKey = currentLocation?.key
  const previousDealerLocationKey = usePrevious(dealerLocationKey)

  const updateDealerSettings = (settings: AdminDealerSettings) =>
    dispatch(AdminLocationSettingsAction.updateDealerSettings(settings, dealerLocationKey))

  const handleUpdateDealerSettings = (settings: AdminDealerSettings) => (workdaysToAvailableVisit: number) => {
    updateDealerSettings({
      ...settings,
      workdaysToAvailableVisit,
    })
  }

  const hasDealerLocationChanged = () =>
    previousDealerLocationKey && previousDealerLocationKey !== dealerLocationKey

  const { loading, data: dealerSettings } = useSelector(selectAdminDealerSettings)
  const getDealerSettings = () =>
    dispatch(AdminLocationSettingsAction.getDealerSettings(dealerLocationKey))

  useMount(() => {
    getDealerSettings()
  })

  useEffect(() => {
    if (hasDealerLocationChanged()) {
      getDealerSettings()
    }
  }, [dealerLocationKey, getDealerSettings])

  const renderContent = () => dealerSettings
    ? (
      <VisitAvailabilityForm
        workdaysToAvailableVisit={dealerSettings.workdaysToAvailableVisit}
        updateVisitAvailability={handleUpdateDealerSettings(dealerSettings)}
      />
    )
    : <NoData>{t('caption.noData')}</NoData>

  return (
    <>
      <ViewTabs testId={testId} />
      {loading ? <LocalLoader /> : renderContent()}
    </>
  )
}

export const VisitAvailability = withAdminViewHeader(VisitAvailabilityComponent)
