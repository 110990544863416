import { ApprovalCounts } from 'actff-bo-lib/client'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { FormRange, NominalString } from 'actff-bo-lib/global'
import { User } from 'actff-bo-lib/user'
import { Map } from 'immutable'

export type ClientId = NominalString<Client>

export enum CommunicationsMethod {
  PushNotifications = 'PUSH_NOTIFICATIONS',
  Sms = 'SMS',
  Email = 'EMAIL',
}

type ClientTypeFilter = {
  readonly Attention: boolean,
  readonly Vip: boolean,
}

export type ClientPreferences = {
  readonly brands: ReadonlyArray<string>,
  readonly fuelTypes: ReadonlyArray<string>,
  readonly equipment: ReadonlyArray<string>,
  readonly mileage: FormRange<string | null>,
  readonly models: ReadonlyArray<string>,
  readonly modelVersions: ReadonlyArray<string>,
  readonly price: FormRange<string | null>,
  readonly productionYear: FormRange<string | null>,
  readonly purchaseDate: FormRange<Date>,
  readonly version: string,
  readonly transmissions: ReadonlyArray<string>,
}

// tslint:disable-next-line:no-any
export const isClientPreferences = (value: ClientPreferences | any): value is ClientPreferences =>
  value && value.hasOwnProperty('fuelTypes')

export type ClientPreferencesDto = ClientPreferences & {
  readonly clientUuid: ClientId,
}

export type CommunicationsMethodFilter = {
  readonly Email: boolean,
  readonly PushNotifications: boolean,
  readonly Sms: boolean,
}

export enum ClientType {
  Attention = 'ATTENTION',
  VIP = 'VIP',
}

export enum ClientStatus {
  NoCars = 'NO_CARS',
  UnapprovedCars = 'UNAPPROVED_CARS',
  AllCarsApproved = 'ALL_CARS_APPROVED',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
  Locked = 'LOCKED',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
}

export enum AssigneeStatus {
  Unassigned = 'UNASSIGNED',
  Assigned = 'ASSIGNED',
  AssignedToCurrentUser = 'ASSIGNED_TO_CURRENT_USER',
}

export enum AvatarStyle {
  Default,
  Filled,
  Dashed,
}

export const assigneeStatusAvatarStyleMap = Map([
  [AssigneeStatus.Unassigned, AvatarStyle.Dashed],
  [AssigneeStatus.Assigned, AvatarStyle.Default],
  [AssigneeStatus.AssignedToCurrentUser, AvatarStyle.Filled],
]) as Map<AssigneeStatus, AvatarStyle>

// TODO: Reanalyze Client model and find a way to refactor it
export type Client = {
  readonly approvalCounts: ApprovalCounts,
  readonly carsCount: number,
  readonly email: string,
  readonly firstName: string,
  readonly id: string,
  readonly lastName: string,
  readonly location?: DealerLocationKey,
  readonly phone: string,
  readonly registrationDate: Date,
  readonly deletionDate: Date,
  readonly preferences?: ClientPreferences,
  readonly status: ClientStatus,
  readonly type: ReadonlyArray<ClientType>,
  readonly uuid: ClientId,
  readonly appClientUuid?: ClientId,
}

export type ClientDto = Pick<Client, 'firstName' | 'lastName' | 'email' | 'type' | 'uuid'>
export type NewClientDto = Omit<ClientDto, 'uuid'>

export type ClientBasicData = ClientDto & Pick<Client, 'phone' | 'id' | 'type' | 'status' | 'preferences'>

export type ClientCount = {
  readonly clientCount: number,
}

export type ClientPersonalData = ReadonlyArray<ClientPersonalDataVersion>

export type ClientPersonalDataVersion = Pick<Client, 'firstName' | 'lastName' | 'email'> & {
  readonly client: Client | null,
  readonly from: Date,
  readonly user: User | null,
}

type ClientListClientCarStatusFilter = {
  readonly NoCars: boolean,
  readonly UnapprovedCars: boolean,
  readonly AllCarsApproved: boolean,
}

type ClientListClientAccountStatusFilter = {
  readonly Created: boolean,
  readonly Deleted: boolean,
  readonly Locked: boolean,
  readonly Verified: boolean,
}

export type ClientSearchParams = {
  readonly page?: number,
  readonly size?: number,
  readonly searchText?: string,
}

export type ClientFilters = {
  readonly carStatuses: ClientListClientCarStatusFilter,
  readonly clientStatuses: ClientListClientAccountStatusFilter,
  readonly carsCountFrom: number | null,
  readonly carsCountTo: number | null,
  readonly types: ClientTypeFilter,
  readonly dms: boolean | null,
  readonly registrationDateFrom: Date | null,
  readonly registrationDateTo: Date | null,
  readonly tradeApprovals: CommunicationsMethodFilter,
  readonly marketingApprovals: CommunicationsMethodFilter,
}
