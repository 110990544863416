import { Icon } from 'actff-bo-app/components/Icon'
import styled from 'styled-components'

export const StatusIcon = styled(Icon)`
  padding-left: 6px;
  position: relative;
  top: 6px;
`

export const PopperContent = styled.div`
  max-width: 440px;
  padding: 12px 14px;
  place-content: center;
  position: relative;
`
export const IconWrapper = styled.span`
  position: relative;
`
