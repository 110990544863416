import { NominalString, SelectOption } from 'actff-bo-lib/global'

import { colors } from 'styles'

export enum CalendarResourceType {
  MECHANIC = 'MECHANIC',
  ADVISOR = 'ADVISOR',
  ELECTRONIC_ENGINEER = 'ELECTRONIC_ENGINEER',
  REPLACEMENT_CAR = 'REPLACEMENT_CAR',
}

export enum CalendarResourceStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type CalendarResourceId = NominalString<CalendarResource>

export type CalendarResource = {
  readonly uuid?: CalendarResourceId,
  readonly status: CalendarResourceStatus,
  readonly type: CalendarResourceType,
  readonly firstName?: string,
  readonly lastName?: string,
  readonly vin?: string,
  readonly registrationNumber?: string,
  readonly model?: string,
}

export type CalendarResourceWorkingHours = {
  readonly from: Date,
  readonly to: Date,
}

export type CalendarResourceAvailabilityId = NominalString<CalendarResourceAvailability>

export type CalendarResourceAvailability = {
  readonly created: Date,
  readonly resource: CalendarResource,
  readonly uuid: CalendarResourceAvailabilityId,
  readonly workingHours: ReadonlyArray<CalendarResourceWorkingHours>,
}

export type Shift = {
  readonly colour: string,
  readonly name: string,
  readonly from?: string,
  readonly to?: string,
}

export type ScheduleElementForm = {
  readonly from: Date,
  readonly to: Date,
  readonly hoursFrom: Date,
  readonly hoursTo: Date,
  readonly shift?: SelectOption<string>,
}

export type ScheduleRuleForm = {
  readonly resources: ReadonlyArray<SelectOption<CalendarResourceId>>,
  readonly elements: ReadonlyArray<ScheduleElementForm>,
  readonly isRecurrent: boolean,
  readonly every?: number,
  readonly end?: Date,
}

export type ScheduleElement = {
  readonly from: string,
  readonly to: string,
  readonly hoursFrom: string,
  readonly hoursTo: string,
}

export type ScheduleRule = {
  readonly elements?: ReadonlyArray<ScheduleElement>,
  readonly resourceUuids?: ReadonlyArray<CalendarResourceId>,
  readonly resourceUuid?: CalendarResourceId,
}

export const emptyShift: Shift = {
  colour: colors.dustyGray,
  name: 'caption.other.female',
}
