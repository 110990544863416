import { Action } from '@reduxjs/toolkit'
import { AuthAction, AuthActionType } from 'actff-bo-lib/auth'
import { GetOilTypesPayload } from 'actff-bo-lib/dealership/dto'
import { AnyAction } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { ToastAction, ToastType } from '../toast'

import {
  addDealerBrandAction,
  getBrakeLiquidsAction,
  getBrakeLiquidsFailure,
  getBrakeLiquidsSuccess,
  getCarModelsAction,
  getCarModelsFailure,
  getCarModelsSuccess,
  getCarVersionsAction,
  getCarVersionsFailure,
  getCarVersionsSuccess,
  getDealerBrandsAction,
  getDealerBrandsFailure,
  getDealerBrandsSuccess,
  getDealerLocationsAction,
  getDealerLocationsFailure,
  getDealerLocationsSuccess,
  getEngineCodesAction,
  getEngineCodesFailure,
  getEngineCodesSuccess,
  getOilTypesAction,
  getOilTypesFailure,
  getOilTypesSuccess,
  setCurrentBrandAction,
  setCurrentLocationAction,
} from './actions'
import {
  addDealerCarBrand,
  getBrakeLiquids,
  getCarModels,
  getCarVersions,
  getDealerBrands,
  getDealerLocations,
  getEngineCodes,
  getOilTypes,
} from './dao'

const getDealerLocationsEpic: Epic<Action> = action$ => action$.pipe(
  ofType<Action | ReturnType<typeof AuthAction.loginSuccess>>(getDealerLocationsAction, AuthActionType.LoginSuccess),
  switchMap(() => getDealerLocations().pipe(
    map(getDealerLocationsSuccess),
    catchError((error: AjaxError) => of(getDealerLocationsFailure(error))),
  )),
)

const getDealerLocationsSuccessEpic: Epic<Action> = action$ => action$.pipe(
  filter(getDealerLocationsSuccess.match),
  map(({ payload }) => setCurrentLocationAction(payload[0])),
)

const alertOnFailureActionEpic: Epic<Action> = action$ => action$.pipe(
  filter(getDealerLocationsFailure.match),
  switchMap(({ payload }) => [
    ToastAction.displayToast({
      autoClose: 3000,
      body: payload.message,
      id: 'api.actionFailure',
      title: 'api.actionFailure',
      type: ToastType.Error,
    }),
  ]),
)

const getDealerBrandsEpic: Epic<Action | AnyAction> = action$ => action$.pipe(
  ofType<Action | ReturnType<typeof AuthAction.loginSuccess>>(getDealerBrandsAction, AuthActionType.LoginSuccess),
  switchMap(() => getDealerBrands().pipe(
    map(getDealerBrandsSuccess),
    catchError((error: AjaxError) => of(getDealerBrandsFailure(error))),
  )),
)

const getDealerBrandsSuccessEpic: Epic<Action> = action$ => action$.pipe(
  filter(getDealerBrandsSuccess.match),
  map(({ payload }) => setCurrentBrandAction(payload[0])),
)

const addDealerBrandEpic: Epic<Action> = action$ => action$.pipe(
  filter(addDealerBrandAction.match),
  switchMap((({ payload }) => addDealerCarBrand(payload).pipe(
    map(getDealerBrandsSuccess),
    catchError((error: AjaxError) => of(getDealerBrandsFailure(error))),
  )),
))

export const getCarModelEpic: Epic<Action> = action$ => action$.pipe(
  filter(getCarModelsAction.match),
  switchMap(({ payload }) => getCarModels(payload).pipe(
    map(getCarModelsSuccess),
    catchError((error: AjaxError) => of(getCarModelsFailure(error)),
    )),
  ))

export const getEngineCodesEpic: Epic<Action> = action$ => action$.pipe(
  ofType(getEngineCodesAction),
  switchMap(() => getEngineCodes().pipe(
    map(getEngineCodesSuccess),
    catchError((error: AjaxError) => of(getEngineCodesFailure(error)),
    )),
  ))

export const getBrakeLiquidsEpic: Epic<Action> = action$ => action$.pipe(
  filter(getBrakeLiquidsAction.match),
  switchMap(({ payload }) => getBrakeLiquids(payload).pipe(
    map(getBrakeLiquidsSuccess),
    catchError((error: AjaxError) => of(getBrakeLiquidsFailure(error)),
    )),
  ))

export const getCarVersionsEpic: Epic<Action> = action$ => action$.pipe(
  filter(getCarVersionsAction.match),
  switchMap(({ payload }) => getCarVersions(payload).pipe(
    map(getCarVersionsSuccess),
    catchError((error: AjaxError) => of(getCarVersionsFailure(error)),
    )),
  ))

export const getOilTypeEpic: Epic<Action> = action$ => action$.pipe(
  filter(getOilTypesAction.match),
  map(({ payload }) => payload),
  filter<GetOilTypesPayload>(payload => !!payload.engineCode && !!payload.brand),
  switchMap(payload => getOilTypes(payload).pipe(
    map(getOilTypesSuccess),
    catchError((error: AjaxError) => of(getOilTypesFailure(error)),
    )),
  ))

export const dealershipEpic = combineEpics(
  addDealerBrandEpic,
  getDealerBrandsEpic,
  getDealerLocationsEpic,
  getDealerLocationsSuccessEpic,
  getDealerBrandsSuccessEpic,
  alertOnFailureActionEpic,
  getCarModelEpic,
  getEngineCodesEpic,
  getBrakeLiquidsEpic,
  getCarVersionsEpic,
  getOilTypeEpic,
)
