import { Attachment } from 'actff-bo-app/components/Attachment'
import { FormHeader } from 'actff-bo-app/components/Form'
import { defaultDateTimeFormat, getLocale } from 'actff-bo-lib/date'
import { InternalComment } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { Language } from 'actff-bo-lib/i18n'
import { format } from 'date-fns'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CommentHeader, CommentsContainer } from './Styled'

type Props = {
  readonly comments: ReadonlyArray<InternalComment> | null,
  readonly currentLanguage: Language,
  readonly className?: string,
}

type InternalCommentsComponentProps = Testable & Props & WithTranslation

const CommentContent = styled.div`
  font-weight: 300;
  font-style: italic;
  line-height: 23px;
`

const PhotoContainer = styled.div`
  display: inline-block;
  margin-top: 24px;

  > * {
    margin-right: 10px;
  }
`

const thumbnailSize = 64

class InternalCommentsComponent extends React.Component<InternalCommentsComponentProps> {

  public render(): React.ReactNode {
    const { className, comments, t, testId } = this.props
    const locale = getLocale(this.props.currentLanguage)

    return (
      <CommentsContainer className={className} data-testid={`${testId}internal-comments`}>
        <FormHeader>
          {t('internalComments.header')}
        </FormHeader>
        {comments && comments.map(this.renderComment(locale, testId))}
      </CommentsContainer>
    )
  }

  public readonly renderComment = (locale: {}, testId?: string) => (comment: InternalComment, index: number) => {
    const commentContent = 'text' in comment
      ? comment.text
      : 'comment' in comment ? comment.comment : ''

    return (
      <div data-testid={`${testId}internal-comment-${index}`} key={index}>
        <CommentHeader>
          <span>{comment.author?.email}</span>
          <span>{format(comment.created, defaultDateTimeFormat, { locale })}</span>
        </CommentHeader>
        <CommentContent>{commentContent}</CommentContent>
        <PhotoContainer>
          {comment.attachments?.length > 0 && (
            comment.attachments.map(photoUrl => <Attachment key={photoUrl} thumbnailSize={thumbnailSize} url={photoUrl} />)
          )}
        </PhotoContainer>
      </div>
    )
  }
}

export const InternalComments = withTranslation()(InternalCommentsComponent)
