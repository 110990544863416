import { FormHeader } from 'actff-bo-app/components/Form'
import { EventHistory } from 'actff-bo-lib/crm'
import { getLocale } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { Language } from 'actff-bo-lib/i18n'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BusinessEventHistory } from './BusinessEventHistory'

import { EventsContainer, LackOfEventsInfo } from './Styled'

type Props = Testable & {
  readonly businessEvents: ReadonlyArray<EventHistory>,
  readonly currentLanguage: Language,
  readonly className?: string,
}

export const BusinessEventsHistory: FC<Props> = ({ className, businessEvents, currentLanguage, testId }) => {
  const { t } = useTranslation()
  const locale = getLocale(currentLanguage)

  return (
    <EventsContainer className={className}>
      <FormHeader>{t('internalEvents.header')}</FormHeader>
      {businessEvents?.length > 0 ? businessEvents.map((event: EventHistory, index: number) => (
        <BusinessEventHistory locale={locale} event={event} index={index} testId={testId} t={t} key={index} />
      )) : <LackOfEventsInfo>{t('crmTrade.opportunity.lackOfBusinessEvents')}</LackOfEventsInfo>}
    </EventsContainer>
  )
}
