import { ClientData, ClientEmail } from 'actff-bo-app/components/Client'
import { TimeAgo } from 'actff-bo-app/components/DateTime'
import { WithStyledRow } from 'actff-bo-app/components/Table/Row'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { CarInfoWithClient, CarStatus } from 'actff-bo-lib/car'
import { defaultTimeFormat } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import { createRoute, Paths } from 'actff-bo-lib/menu'
import { format } from 'date-fns'
import * as React from 'react'
import { TableInfo } from 'styles'

import { CarRowMenu } from './CarRowMenu'

export type CarRowHighlightParams = {
  readonly status: CarStatus,
  readonly time: keyof Pick<CarInfoWithClient, 'created' | 'serviceAcceptanceDate'>,
}

type Props = Testable & {
  readonly car: CarInfoWithClient,
  readonly highlightParams?: CarRowHighlightParams,
}

export const CarRow: React.FC<Props> = ({ car, highlightParams, testId }) => {
  const route = createRoute(Paths.CarViewService, { carId: car.uuid })
  const { alias, client, created, status } = car
  const Td = TdDecorator(route)

  return highlightParams
    ? (
      <WithStyledRow
        menu={<CarRowMenu car={car} client={client} />}
        isStyled={status === highlightParams.status}
        time={car[highlightParams.time]}
      >
        <Td testId={`${testId}--data`}>
          {format(created, defaultTimeFormat)}
          <TableInfo><TimeAgo date={car.created} /></TableInfo>
        </Td>
        <Td testId={`${testId}--clientData`}><ClientData client={client}/></Td>
        <Td testId={`${testId}--email`}><ClientEmail client={client}/></Td>
        <Td testId={`${testId}--alias`}>{alias}</Td>
        <Td>{car.dealerLocation?.name}</Td>
        <Td>{car.brand || ''}</Td>
      </WithStyledRow>
    )
    : null
}
