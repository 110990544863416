import {
  CarId,
  CarInfoWithClient,
  CarInsurance,
  carInsuranceFormDataToDto,
  CarInsuranceWithCarUuid,
  ValueForInsuranceCalculation,
} from 'actff-bo-lib/car'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { withFormik } from 'formik'
import { array, boolean, mixed, number, object, string } from 'yup'

import { carInsuranceInitialValues } from './initialValues'

export type CarViewInsuranceFormValues = {
  readonly ac: boolean,
  readonly oc: boolean,
  readonly nnw: boolean,
  readonly assistance: boolean,
  readonly inHouseExpirationDate: Date | null,
  readonly inHouseInsurer: string,
  readonly inHouse: boolean,
  readonly manufacturerExpirationDate: Date | null,
  readonly manufacturerInsurer: string,
  readonly manufacturerAssistance: boolean,
  readonly marketValue: number | null,
  readonly valueForInsuranceCalculation: ValueForInsuranceCalculation | null,
  readonly inHousePolicyNumber: string,
}

export type CarViewInsuranceFormProps = {
  readonly car: CarInfoWithClient,
  readonly carInsurance: CarInsurance | null,
  readonly updateCarInsurance: (carInsurance: CarInsuranceWithCarUuid) => void,
  readonly rejectCar: (carId: CarId) => void,
  readonly deleteCar: (carId: CarId) => void,
  readonly revertCar: (carId: CarId) => void,
}

// tslint:disable:object-literal-sort-keys
const validationSchema = () => object<CarInsurance>().shape({
  inHouse: boolean().required(),
  inHouseExpirationDate: mixed()
    .when('inHouse', {
      is: true,
      then: mixed().required('carView.form.insurance.inHouseExpirationDate.isRequired'),
      otherwise: mixed().nullable(true),
    }),
  insuranceRange: array().of(string()),
  inHouseInsurer: string()
    .when('inHouse', {
      is: true,
      then: string().required('carView.form.insurance.inHouseInsurer.isRequired').nullable(true),
      otherwise: string().nullable(true),
    }),
  inHousePolicyNumber: string()
    .when('inHouse', {
      is: true,
      then: string().required('carView.form.insurance.inHousePolicyNumber.isRequired'),
      otherwise: string(),
    }),
  marketValue: mixed()
    .when('inHouse', {
      is: true,
      then: number().positive().integer(),
      otherwhise: string().nullable(true),
  }),
  manufacturerInsurer: mixed()
    .when('manufacturerAssistance', {
      is: true,
      then: string().required(),
      otherwhise: string().nullable(true),
    }),
  valueForInsuranceCalculation: mixed()
    .when('marketValue', (insuranceValue: string) => insuranceValue ? mixed().required() : mixed()),
})
// tslint:enable

export const formik = withFormik<CarViewInsuranceFormProps, CarViewInsuranceFormValues>({
  enableReinitialize: true,
  handleSubmit: (values: CarViewInsuranceFormValues, { props }) => {
    const car = props.car
    const carInsurance = props.carInsurance || carInsuranceInitialValues
    const { insuranceValue, uuid, ...restCarInsurance } = carInsurance

    props.updateCarInsurance(
      carInsuranceFormDataToDto(car.uuid, restCarInsurance, values),
    )
  },
  mapPropsToValues: ({ carInsurance: carInsuranceFromProps }) => {
    const carInsurance = carInsuranceFromProps || carInsuranceInitialValues
    const {
      ac,
      assistance,
      insuranceValue: customValue,
      expirationDate,
      inHouse,
      insurer: manufacturerInsurer,
      manufacturerAssistance,
      marketValue,
      nnw,
      oc,
      valueForInsuranceCalculation,
    } = carInsurance
    const {
      expirationDate: inHouseExpirationDate,
      insurer: inHouseInsurer,
      policyNumber: inHousePolicyNumber,
    } = carInsurance.assistanceInHouse || {}

    return {
      ac,
      assistance,
      customValue,
      inHouse,
      inHouseExpirationDate: parseToDateOrNull(inHouseExpirationDate),
      inHouseInsurer,
      inHousePolicyNumber,
      manufacturerAssistance,
      manufacturerExpirationDate: parseToDateOrNull(expirationDate),
      manufacturerInsurer,
      marketValue,
      nnw,
      oc,
      valueForInsuranceCalculation,
    }
  },
  validationSchema,
})
