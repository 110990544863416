import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import { defaultWorkdaysFormValues, OpeningHours, Time } from 'actff-bo-lib/admin/dealer-locations'
import { FormOpeningHours } from 'actff-bo-lib/calendar/dto'
import { DayOfWeek } from 'actff-bo-lib/date'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Labels } from '../../Styled'
import { Weekday } from './Weekday'

type AdminOpeningHoursForm = {
  readonly [x: number]: FormOpeningHours,
}

type Props = Testable & {
  readonly openingHours: ReadonlyArray<OpeningHours>,
  readonly updateOpeningHours: (openingHours: ReadonlyArray<OpeningHours>) => void,
}

const setDate = (time: Time | null): Date | null => {
  if (!time) { return null }

  const date = new Date()
  date.setHours(time[0])
  date.setHours(time[0])
  date.setMinutes(time[1])

  return date
}

const getInitialValues = (workdays: ReadonlyArray<OpeningHours>): AdminOpeningHoursForm =>
  workdays.reduce((prev, curr) => ({ ...prev, [curr.dayOfWeek]: {
      ...curr,
      dayOfWeek: curr.dayOfWeek,
      timeFrom: setDate(curr.timeFrom),
      timeTo: setDate(curr.timeTo),
    } }), {})

const mapToDto = (formValues: AdminOpeningHoursForm): ReadonlyArray<OpeningHours> => Object.values(formValues).map(value => ({
  ...value,
  timeFrom: value.timeFrom ? [value.timeFrom?.getHours(), value.timeFrom?.getMinutes()] : null,
  timeTo: value.timeTo ? [value.timeTo?.getHours(), value.timeTo?.getMinutes()] : null,
}))

export const OpeningHoursForm: FC<Props> = ({ openingHours, testId, updateOpeningHours }) => {
  const { t } = useTranslation()
  const defaultValues = openingHours.length === 0 ? getInitialValues(defaultWorkdaysFormValues) : getInitialValues(openingHours)
  const { control, formState, register, handleSubmit, reset, setValue } = useForm<AdminOpeningHoursForm>({ defaultValues })
  const { isDirty, isSubmitting } = formState

  const resetForm = () => {
    isDirty && reset(defaultValues)
    Object.values(defaultValues).forEach(value => {
      setValue(value.dayOfWeek.toString(), value)
    })

  }

  const onSubmit = handleSubmit<AdminOpeningHoursForm>((formValues: AdminOpeningHoursForm) => {
    reset(formValues)
    Object.values(formValues).forEach(value => {
      setValue(value.dayOfWeek.toString(), value)
    })
    updateOpeningHours(mapToDto(formValues))
  })

  return (
    <>
      <form>
        <Labels>
          <label>{t('openingHours.workingDays')}:</label>
          <label>{t('openingHours.open')}:</label>
          <label>{t('openingHours.close')}:</label>
        </Labels>

        {
          Object.values(DayOfWeek).map((day: number) =>
            <Weekday control={control} testId={testId} key={day} name={day.toString()} register={register} setValue={setValue} />,
          )
        }

        <FixedFooter
          isSaveDisabled={!isDirty || isSubmitting}
          onCancel={resetForm}
          onSave={onSubmit}
          testId={testId}
        />
      </form>
    </>
  )
}
