import { CarCard } from 'actff-bo-app/components/CarCard'
import { ClientFullName, ClientPhone, ClientType as BasicClientType } from 'actff-bo-app/components/Client'
import { CarInfo } from 'actff-bo-lib/car'
import { Client } from 'actff-bo-lib/client'
import { Testable } from 'actff-bo-lib/global/testable'
import * as React from 'react'
import styled from 'styled-components'
import { colors, H2 } from 'styles'

type Props = Testable & {
  readonly car: CarInfo,
  readonly client: Client,
}

const ClientName = styled(H2)`
  color: ${colors.kumera};
`

const ClientImportantInfo = styled.span`
  color: ${colors.dark};
  display: block;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
`

const ClientType = styled(BasicClientType)`
  padding-bottom: 30px;
`

export const ServiceRequestClient: React.FC<Props> = ({ car, client, testId }) => (
  <>
    <ClientName data-testid={`${testId}client-full-name`}><ClientFullName client={client} /></ClientName>
    <ClientImportantInfo data-testid={`${testId}client-phone`}><ClientPhone client={client} /></ClientImportantInfo>
    <ClientType data-testid={`${testId}client-type`} client={client} />
    <CarCard active={false} car={car} testId={`${testId}client-car-`} />
  </>
)
