import { DealerLocation, selectDealerBrands } from 'actff-bo-lib/dealership'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { CheckboxInputField, Field } from '../components/Form'

type Props = Testable & {
  readonly location: DealerLocation,
  readonly onLocationsGroupSelectorChange: () => void,
}

const Container = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 300px repeat(auto-fit, 130px);
  padding-left: 10px;
`

const LocationGroupFilterHeader = styled.div`
  label {
    top: 17px;
  }
`

export const LocationFilterRow: FC<Props> = ({ location, onLocationsGroupSelectorChange, testId }) => {
  const { data: dealerBrands } = useSelector(selectDealerBrands)

  return (
    <Container>
      <LocationGroupFilterHeader>
        <Field
          component={CheckboxInputField}
          id={`locationsGroupSelector.${location.key}`}
          key={`locationsGroupSelector.${location.key}`}
          label={location.name}
          labelTruncated={true}
          labelWidth='285'
          name={`locationsGroupSelector.${location.key}`}
          onChange={onLocationsGroupSelectorChange}
          testId={`${testId}locations--${location.key}`}
        />
      </LocationGroupFilterHeader>
      {dealerBrands?.map(brand => (
        <Field
          component={CheckboxInputField}
          id={`${location.key}.${brand}`}
          key={brand}
          label=''
          name={`${location.key}.${brand}`}
          testId={`${testId}locations--${brand}`}
        />
      ))}
    </Container>
  )
}
