import {
  convertToUTC,
  DatePickerInputPosition,
  defaultDateFormat,
  getLocale,
  months,
  weekdaysLong,
  weekdaysShort,
} from 'actff-bo-lib/date'
import { connector } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import { Language, selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { State as GlobalState } from 'actff-bo-lib/store'
import { format as dateFnsFormat } from 'date-fns'
import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css' // tslint:disable-line: no-import-side-effect
import { Modifier } from 'react-day-picker/types/common'
import { DayPickerProps } from 'react-day-picker/types/props'
import { WithTranslation, withTranslation } from 'react-i18next'
import { MapStateToProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { colors, theme } from 'styles'

import { YearMonth } from './YearMonth'

type Props = Testable & {
  readonly date: Date | null,
  readonly onChange: (day: Date | null) => void,
  readonly component?: React.ComponentType,
  readonly className?: string,
  readonly clickUnselectsDay?: boolean,
  readonly disabledDays?: Modifier[],   // tslint:disable-line: readonly-array
  readonly disabled?: boolean,
  readonly error?: boolean,
  readonly format?: string,
  readonly futureYearsOffset?: number,
  readonly pastYearsOffset?: number,
  readonly placeholder?: string | Date | null,
  readonly timeDisabled?: boolean,
  readonly withPlaceholder?: boolean,
  readonly position?: DatePickerInputPosition,
}

type State = {
  readonly yearMonthSelectDate: Date,
}

type StateToProps = {
  readonly currentLanguage: Language,
}

type DatePickerInputProps = Props & StateToProps & WithTranslation

const weekdayLength = 3

const DayPickerInputWrapper = styled.div`
  cursor: pointer;
  width: 100%;

  & input {
    background-color: white;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;

    &:disabled {
      cursor: initial;
      color: black !important;
    }
  }

  .DayPickerInput-Overlay {
    ${({ position }: Props) => position === DatePickerInputPosition.RIGHT && 'left: auto; right: 0'};
    ${({ position }: Props) => position === DatePickerInputPosition.TOP && 'left: auto; bottom: 32px'};
  }
`

const DayPickerInputStyled = styled(DayPickerInput)`
  cursor: pointer;
  width: 100%;


  .DayPickerInput {
    input {
      background-color: white;
      cursor: pointer;
      text-transform: uppercase;
      width: 100%;
    }
  }
`

const dayPickerInputProps = (disabled?: boolean, error?: boolean) => ({
  disabled,
  readOnly: true,
  style: {
    backgroundColor: disabled && colors.athensGray,
    borderColor: error && colors.flamingoRed,
  },
})

class DatePickerInputComponent extends React.Component<DatePickerInputProps, State> {
  public readonly state: State = ({
    yearMonthSelectDate: this.props.date || new Date(),
  })

  private readonly locale: {} = getLocale(this.props.currentLanguage)

  public componentDidUpdate(prevProps: DatePickerInputProps): void {
    if (this.props.date && prevProps.date !== this.props.date) {
      this.changeYearMonthSelectDate(this.props.date)
    }
  }

  public render(): React.ReactNode {
    const {
      clickUnselectsDay = true,
      component,
      date,
      disabled = false,
      disabledDays,
      futureYearsOffset,
      error,
      format,
      pastYearsOffset,
      placeholder,
      testId,
      timeDisabled,
      t,
      withPlaceholder = true,
      position,
    } = this.props
    const { yearMonthSelectDate } = this.state
    // tslint:disable no-unnecessary-callback-wrapper
    const daypickerMonths = months.map(month => t(month))
    const daypickerWeekdaysLong = weekdaysLong.map(day => t(day))
    const daypickerWeekdaysShort = weekdaysShort.map(day => t(day))
    // tslint:enable
    const modifiersStyles = {
      selected: {
        backgroundColor: theme.form.select.selected,
      },
    }

    const onChange = (value: Date) => {
      if (!timeDisabled || !value) {
        this.props.onChange(value)

        return
      }

      this.props.onChange(convertToUTC(value))
    }

    const dayPickerProps: DayPickerProps = {
      captionElement: () => (
        <YearMonth
          date={yearMonthSelectDate}
          futureYearsOffset={futureYearsOffset}
          pastYearsOffset={pastYearsOffset}
          onChange={this.changeYearMonthSelectDate}
        />
      ),
      disabledDays,
      firstDayOfWeek: 1,
      modifiersStyles,
      month: yearMonthSelectDate,
      months: daypickerMonths,
      onMonthChange: this.changeYearMonthSelectDate,
      selectedDays: date,
      weekdaysLong: daypickerWeekdaysLong,
      weekdaysShort: daypickerWeekdaysShort,
    }

    return (
      <DayPickerInputWrapper {...(testId && { 'data-testid': `${testId}` })} position={position}>
        <DayPickerInputStyled
          className={this.props.className}
          clickUnselectsDay={clickUnselectsDay}
          component={component}
          dayPickerProps={dayPickerProps}
          hideAfterDayClick={false}
          format={format}
          formatWeekDay={(nameOfDay: string) => nameOfDay.substr(0, weekdayLength)}
          formatDate={this.formatDate(format)}
          inputProps={dayPickerInputProps(disabled, error)}
          onDayChange={onChange}
          placeholder={withPlaceholder ? placeholder : null}
          value={date || ''}
          data-testid={testId}
          useWeekdaysShort={true}
        />
      </DayPickerInputWrapper>
    )
  }

  private readonly changeYearMonthSelectDate = (date: Date) => {
    this.setState({ yearMonthSelectDate: date })
  }

  private readonly formatDate = (format: string = defaultDateFormat) => (date: Date) => dateFnsFormat(date, format, { locale: this.locale })
}

const mapStateToProps: MapStateToProps<StateToProps, null, GlobalState> = state => ({
  currentLanguage: selectCurrentLanguage(state),
})

export const DatePickerInput = compose(
  connector(mapStateToProps, null),
  withTranslation(),
)(DatePickerInputComponent)
