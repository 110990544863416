import { api, PagedResult } from 'actff-bo-lib/api'
import { AppPersonId, CarInfo } from 'actff-bo-lib/car'
import { OpportunitySale, TradeOpportunityType } from 'actff-bo-lib/crm/trade'
import { buildUrl } from 'actff-bo-lib/global'
import { axiosApi } from '../api/axios-requests'

import {
  Client,
  ClientCount,
  ClientDto,
  ClientFilters,
  ClientId,
  ClientPersonalData,
  ClientPreferences,
  ClientPreferencesDto,
  ClientSearchParams,
  ContactApprovalsFromAPI,
  ContactApprovalsToAPI,
  ContactApprovalsWithClientIdToAPI,
  NewClientDto,
} from './dto'

export const archiveClient = (clientUuid: ClientId) => api.delete(`/client/${clientUuid}`)

export const getContactApprovals = (clientUuid: ClientId) => api.get<ContactApprovalsFromAPI>(`/client/${clientUuid}/approvals`)

export const addClient = (client: NewClientDto) => api.post<Client, NewClientDto>('/client', client)

export const getClient = (clientUuid: ClientId) => api.get<Client>(`/client/${clientUuid}`)

export const getClientCars = (clientUuid: ClientId | AppPersonId, page?: number) =>
  api.get<PagedResult<CarInfo>>(buildUrl('/car', { clientUuid, page }))

export const getClientMatchedOpportunities = <T>(clientUuid: ClientId, opportunityType: TradeOpportunityType) =>
  api.get<ReadonlyArray<T>>(`/trade/${opportunityType}/client/${clientUuid}`)

export const getClientMatchedSaleOpportunity = (clientUuid: ClientId) => api.get<OpportunitySale>(`/trade/sale/client/${clientUuid}`)

export const getClientPersonalDataHistory = (clientUuid: ClientId) => api.get<ClientPersonalData>(`/client/${clientUuid}/personal-data`)

export const getClientPreferences = (clientUuid: ClientId) => api.get<ClientPreferences>(`/trade/preferences/${clientUuid}`)

export const getClients = (page?: number, size?: number, filters: ClientFilters | null = null, searchText?: string) =>
  api.get<PagedResult<Client>>(
    buildUrl('/client', { page, size, searchText }, filters),
)

export const getClientsWithAxios = (searchParams: ClientSearchParams, filters: ClientFilters | null = null) =>
  axiosApi.get<PagedResult<Client>>(
    buildUrl('/client', searchParams, filters),
  )

export const getClientCount = () => api.get<ClientCount>('/client/count')

export const restoreClient = (clientUuid: ClientId) => api.post(`/client/${clientUuid}/restore`, null)

export const updateContactApprovals = (payload: ContactApprovalsWithClientIdToAPI) =>
  api.patch<ContactApprovalsFromAPI, ContactApprovalsToAPI>(`/client/${payload.clientUuid}/approvals`, payload.contactApprovals)

export const createClient = (payload: ClientDto) => {
  const { uuid, ...payloadWithoutUuid } = payload

  return api.post<Client, Omit<ClientDto, 'uuid'>>('/client', payloadWithoutUuid)
}

export const updateClient = (payload: ClientDto) => {
  const { uuid, ...payloadWithoutUuid } = payload

  return api.patch<Client, Omit<ClientDto, 'uuid'>>(`/client/${uuid}`, payloadWithoutUuid)
}

export const saveClientPreferences = (payload: ClientPreferencesDto) =>
  api.put<ClientPreferencesDto, ClientPreferences>('/trade/preferences', payload)
