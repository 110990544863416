import { mapToOption, SelectOption } from 'actff-bo-lib/global'
import { CssElementsPlacement } from 'actff-bo-lib/style'
import i18next from 'i18next'
import * as React from 'react'
import Select from 'react-select'
import Creatable from 'react-select/creatable'

import { reactSelectStyles } from '../FormStyled'

type Props<T> = {
  readonly clearable?: boolean,
  readonly creatable?: boolean,
  readonly customValueMapper?: (value: T) => SelectOption<T>,
  readonly defaultValue?: SelectOption<T>,
  readonly disabled?: boolean,
  readonly menuPlacement?: CssElementsPlacement,
  readonly onChange?: (option: T) => void,
  readonly options: ReadonlyArray<SelectOption<T>> | null,
  readonly t: i18next.TFunction,
  readonly testId: string,
  readonly transKey?: string,
}

export const renderSelectController = <T extends unknown>({
  clearable,
  creatable,
  customValueMapper,
  disabled,
  onChange: propOnChange,
  menuPlacement,
  options,
  t,
  transKey,
  testId,
  defaultValue,
}: Props<T>) => ({ value, onChange }: any) => { // tslint:disable-line no-any
  const handleOnChange = (option: SelectOption<T>) => {
    const nextValue = option ? option.value : null

    propOnChange && propOnChange(nextValue as T)
    onChange(nextValue)
  }

  const Component = creatable ? Creatable as React.ElementType : Select

  const getValue = () => {
    if (!value) {
      if (defaultValue) {
        handleOnChange(defaultValue)
      }

      return null
    }

    return customValueMapper
      ? customValueMapper(value)
      : mapToOption({ value, tKey: transKey, t })
  }

  const formatCreateLabel = (text: string) => `${t('caption.use')} "${text}"?`

  return (
    <Component
      isMulti={false}
      formatCreateLabel={formatCreateLabel}
      placeholder={t && t('placeholder.choose')}
      menuPlacement={menuPlacement}
      testId={testId}
      options={options}
      isDisabled={disabled}
      styles={{...reactSelectStyles}}
      isClearable={clearable}
      onChange={handleOnChange}
      value={getValue()}
    />
  )
}
