import { NominalString } from 'actff-bo-lib/global'

export type CarFinanceId = NominalString<CarFinance>

export enum CurrencyType {
  Pln = 'pln',
  Eur = 'eur',
}

export enum AmountType {
  Net = 'net',
  Gross = 'Gross',
}

export type CarFinance = {
  readonly amount: number,
  readonly amountType: AmountType,
  readonly currency: CurrencyType,
  readonly financeEndDate: Date | null,
  readonly financeType: string,
  readonly inHouse: boolean,
  readonly lessorName: string,
  readonly uuid: CarFinanceId,
}

export type CarFinanceDto = Omit<CarFinance, 'uuid'>
