import { FieldProps } from 'formik'
import React, { FC } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'
import { theme } from 'styles'

const TextareaAutosizeStyled = styled(TextareaAutosize)`
  border-radius: 3px;
  border: 1.5px solid ${theme.form.input.border};
  font-size: 13px;
  padding: 8px 16px;
  resize: none;
  text-transform: none;

  &:focus {
    outline: none;
  }
`

const defaultMinRows = 3
const defaultMaxRows = 5

export const Textarea: FC<FieldProps> = ({ field, form, ...props }) => {
  const { name } = field

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    form.setFieldValue(name, event.target.value)
  }

  return (
    <TextareaAutosizeStyled
      onChange={handleOnChange}
      minRows={defaultMinRows}
      maxRows={defaultMaxRows}
      {...props}
    />
  )
}
