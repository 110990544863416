import { Filters } from './filter'

// tslint:disable-next-line no-any
export const getFiltersAsObject = (filters: Filters<any>): { readonly [x: string]: string } =>
  Object
    .entries(filters)
    .map(([param, value]) => {
      const values = value
      .filter(filter => filter.selected)
      .reduce(((prev, curr) => `${prev}${curr.value},`), '')
      .replace(/,([^,]*)$/, '$1')

      return values ? { [param]: values } : {}
    })
    .reduce(((prev, curr) => ({...prev, ...curr})), {})

// tslint:disable-next-line no-any
export const getSimpleFiltersAsObject = (filters: any) =>
  Object.entries(filters)
    .filter(([, value]) => (value instanceof Array && value.length > 0) || !!(!(value instanceof Array) && value))
    .reduce((acc, [param, value]: [string, unknown]) => ({
      ...acc,
      [param]: value instanceof Array
        ? value
          .reduce(((prev, curr) =>
            typeof curr === 'object' ? `${prev}${curr.value},` : `${prev}${curr},`)
            , '',
          )
          .replace(/,([^,]*)$/, '$1')
        : value instanceof Date ? value.toISOString() : value,
    }), {})

export function getStatusFiltersAsString<T>(filters: Filters<T>): { readonly [x: string]: {} } {
  return Object
    .entries(filters)
    .map(([param, value]) => ({
      [param]: value
        .filter(filter => filter.selected)
        .map(filter => filter.value),
    }))
    .reduce(((prev, curr) => ({ ...prev, ...curr })), {})
}

export function getObjectFiltersAsArrayString<T>(obj: T): ReadonlyArray<string> {
  return Object.entries(obj)
    .filter(entry => entry[1])
    .reduce((prev, [field]) => [...prev, field], [])
    .map(value => value.toUpperCase())
}
