import { BtnType } from 'actff-bo-app/components/Button'
import { CheckboxLabel } from 'actff-bo-app/components/Checkbox'
import { PhotosHeaderStyles } from 'actff-bo-app/components/Form/Photos'
import { Label } from 'actff-bo-app/components/Label'
import { Modal } from 'actff-bo-app/components/Modal'
import {
  DamageType,
  InspectionPaintCoatElement,
  paintCoatElementMaxRangeThickness,
  paintCoatElementMaxThickness,
} from 'actff-bo-lib/car/dto'
import { FormValueT, InspectionPaintCoatFormElement } from 'actff-bo-lib/car/helpers'
import { hasErrors } from 'actff-bo-lib/global/data-types'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import React, { FC } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { PrimaryButton } from 'styles'

import { Photos } from '..'
import {
  CheckboxContainer,
  CheckboxStyled,
  CoatThicknessContainer,
  Content,
  DamageTypeContainer,
  Footer,
  SliderStyled,
  TextFieldStyled,
  ThicknessError,
  ThicknessLabel,
  ThicknessMin,
} from './Styled'

type Props = Testable & {
  readonly element: InspectionPaintCoatFormElement,
  readonly setIsDialogVisible: (value: boolean) => void,
  readonly setPaintCoatValue: (updatedPaintCoat: FormValueT<InspectionPaintCoatFormElement>) => void,
  readonly toggleDialogVisibility: () => void,
}

const getSelectObject = (t: i18next.TFunction, type: DamageType | string) => ({
  label: t(`carView.inspection.form.paintCoat.damageType.${type}`),
  value: type,
})

const photosContainerMaxHeight = 360

export const EvaluationModal: FC<Props> = ({
  element,
  setIsDialogVisible,
  setPaintCoatValue,
  toggleDialogVisibility,
  testId,
}) => {
  const { t } = useTranslation()
  const options = Object.keys(DamageType).map(damage => getSelectObject(t, damage))
  const getDefaultThickness = () => element.thickness || 0

  const handleThicknessInputChange = (onChangeFn: (newValue: number) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = Number(event.target.value)
    if (numberValue <= paintCoatElementMaxThickness) {
      onChangeFn(numberValue)
    }
  }

  const {
    control,
    errors,
    handleSubmit,
    setValue,
  } = useForm<InspectionPaintCoatElement>({ defaultValues: { [element.key]: element } })

  const requiresMeasurement = useWatch({
    control,
    name: `${element.key}.requiresMeasurement`,
  })

  const thickness = useWatch({
    control,
    defaultValue: getDefaultThickness(),
    name: `${element.key}.thickness`,
  })

  if (!requiresMeasurement && thickness !== 0) {
    setValue(`${element.key}.thickness`, 0)
  }

  const handleConfirm = handleSubmit((formValues: InspectionPaintCoatFormElement) => {
    setPaintCoatValue({
      [element.key]: {
        ...element,
        ...formValues[element.key],
        thickness: Number(formValues[element.key].thickness),
      },
    })
    toggleDialogVisibility()
  })

  return (
    <Modal onClose={() => setIsDialogVisible(false)} open={true} title={element.name} titleWithoutTranslation={true}>
      <>
        <Content>
          <ThicknessLabel>
            <label>{t('carView.inspection.form.paintCoat.thicknessLabel')}</label><Label uppercase={false}>(&micro;m)</Label>
          </ThicknessLabel>
          <CoatThicknessContainer>
            <ThicknessMin>0</ThicknessMin>
            <Controller
              render={({ onChange }) => (
                <SliderStyled
                  disabled={!requiresMeasurement}
                  max={paintCoatElementMaxRangeThickness}
                  onChange={(event: React.ChangeEvent<{}>, newValue: number | ReadonlyArray<number>) => { onChange(newValue) }}
                  aria-labelledby='continuous-slider'
                  marks={[{
                    label: `${element.maxCorrectThickness} MAX`,
                    value: element.maxCorrectThickness,
                  }]}
                  value={thickness}
                  valueLabelDisplay='off'
                />
              )}
              control={control}
              name={`${element.key}.thickness`}
            />
            <div>
              <Controller
                render={({ onChange }) => (
                  <TextFieldStyled
                    error={errors[element.key]?.thickness}
                    disabled={!requiresMeasurement}
                    inputProps={{ min: 0, max: paintCoatElementMaxThickness, step: 1 }}
                    onChange={handleThicknessInputChange(onChange)}
                    type='number'
                    variant='outlined'
                    value={thickness}
                  />
                )}
                control={control}
                name={`${element.key}.thickness`}
                rules={{ min: 0, max: paintCoatElementMaxThickness }}
              />
              <ThicknessError>
                {errors[element.key]?.thickness && t(
                  'carView.inspection.form.paintCoat.errors.thicknessOutOfRange',
                  { maxThickness: paintCoatElementMaxThickness})
                }
              </ThicknessError>
            </div>
          </CoatThicknessContainer>
          <Controller
            render={({ onChange, value }) => (
              <CheckboxContainer>
                <CheckboxStyled
                  checked={!value}
                  color='primary'
                  onClick={() => onChange(!value)}
                  onChange={() => onChange(!value)}
                  id='requires-measurement'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <CheckboxLabel
                  data-testid={`${testId}requires-measurement`}
                  htmlFor='requires-measurement'
                >
                  {t('carView.inspection.form.paintCoat.dialog.measurementNotRequired')}
                </CheckboxLabel>
              </CheckboxContainer>
            )}
            control={control}
            name={`${element.key}.requiresMeasurement`}
          />
          <DamageTypeContainer>
            <label>{t('carView.inspection.form.paintCoat.damageType')}</label>
            <Controller
              as={<Select isMulti={true} placeholder={t('placeholder.choose')} testId={`${testId}-damageTypes`} options={options} />}
              control={control}
              name={`${element.key}.damageTypes`}
            />
          </DamageTypeContainer>

          <Photos
            control={control}
            photoUrls={element.photos}
            formFieldName={`${element.key}.attachments`}
            maxHeight={photosContainerMaxHeight}
            noPadding={true}
            setValue={setValue}
            headerStyles={PhotosHeaderStyles}
            withBackground={false}
          />

        </Content>
        <Footer>
          <PrimaryButton
            disabled={hasErrors(errors as FormValueT<InspectionPaintCoatElement>)}
            type={BtnType.Button}
            onClick={handleConfirm}
          >
            {t('caption.confirm')}
          </PrimaryButton>
        </Footer>
      </>
    </Modal>
  )
}
