import { ValidationError } from 'actff-bo-app/components/Form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type ValidationErrorProps = {
  readonly message?: string | null,
}

export const ErrorMessage: React.FC<ValidationErrorProps> = ({ message }) => {
  const { t } = useTranslation()

  return (
    <>
      {message && <ValidationError>{t(message)}</ValidationError>}
    </>
  )
}
