import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FormHeader as FormHeaderComponent } from 'actff-bo-app/components/Form'
import { ClientPreferences } from 'actff-bo-lib/client'
import { defaultDateFormat } from 'actff-bo-lib/date'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Value } from './Styled'
import { TrimPreferences } from './TrimPreferences'

type Props = {
  readonly preferences: ClientPreferences | undefined,
}

const Container = styled.div`
  grid-area: preferences;
  padding: 24px;
`

const Content = styled.div`
  display: grid;
  grid-column-gap: 65px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
`

const FormHeader = styled(FormHeaderComponent)`
  padding-bottom: 24px;
`

export const Preferences: FC<Props> = ({ preferences }) => {
  const { t } = useTranslation()

  if (!preferences) {
    return null
  }

  return (
    <Container>
      <FormHeader>{t('crmTrade.sale.preferences.header')}:</FormHeader>
      <Content>
        <TrimPreferences preferences={preferences.brands} tKey='brands' />
        <TrimPreferences preferences={preferences.models} tKey='models' />
        <TrimPreferences preferences={preferences.fuelTypes} shouldTranslate={true} tKey='fuelType' />
        <TrimPreferences preferences={preferences.transmissions} shouldTranslate={true} tKey='transmission' />

        <TrimPreferences preferences={preferences.modelVersions} tKey='version' />
        <div>
          <label>{t('crmTrade.sale.preferences.mileage')}:</label>
          <Value>{preferences.mileage.from} - {preferences.mileage.to} km</Value>
        </div>

        <div>
          <label>{t('crmTrade.sale.preferences.productionYear')}:</label>
          <Value>{preferences.productionYear.from} - {preferences.productionYear.to}</Value>
        </div>
        <div>
          <label>{t('crmTrade.sale.preferences.plannedPurchaseDate')}:</label>
          <Value>
            <FormattedDate date={preferences.purchaseDate.from} format={defaultDateFormat} /> -
            &nbsp;<FormattedDate date={preferences.purchaseDate.to} format={defaultDateFormat} />
          </Value>
        </div>
        <div>
          <label>{t('crmTrade.sale.preferences.price')}:</label>
          <Value>{preferences.price.from} - {preferences.price.to} PLN</Value>
        </div>
      </Content>
    </Container>
  )
}
