import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, flatMap, map, switchMap, withLatestFrom } from 'rxjs/operators'

import { I18nAction, I18nActionType } from './actions'
import { getTranslations, initI18n } from './dao'
import { getLanguageFromStorage } from './storage'

export const initI18nEpic: Epic<I18nAction, I18nAction, State> = (action$, state$) => action$.pipe(
  ofType(I18nActionType.Init),
  withLatestFrom(state$),
  switchMap(([, state]) => {
    const currentLanguage = getLanguageFromStorage() || state.i18n.language

    return getTranslations(currentLanguage).pipe(
      flatMap(translations => initI18n(currentLanguage, translations).pipe(
        map(I18nAction.initSuccess),
        catchError((error: AjaxError) => of(I18nAction.initFailure(error))),
      )),
    )
  }),
)

export const i18nEpic = combineEpics(initI18nEpic)
