// tslint:disable: ordered-imports Fullcalendar library must be imported before plugins
import FullCalendar from '@fullcalendar/react'
import { DatesSetArg } from '@fullcalendar/common'
import plLocale from '@fullcalendar/core/locales/pl'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { CalendarResourceType } from 'actff-bo-lib/admin'
import { getCalendar } from 'actff-bo-lib/calendar/dao'
import { mapCalendarDataToEvents, mapCalendarDataToResources } from 'actff-bo-lib/calendar/mappers'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { CalendarSidebar } from './CalendarSidebar'
import { calendarHeaderToolbarConfig, calendarInitialView } from './config'
import { Calendar } from './Styled'

type Props = {
  readonly resourceType: CalendarResourceType,
  readonly queryKey: QueryKeys,
}

export const CalendarViewContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`

export const CalendarView: FC<Props> = ({ resourceType, queryKey }) => {
  const { t } = useTranslation()

  const today = new Date()
  const [date, setDate] = useState(today)

  const { data, refetch } = useQuery(queryKey, async () =>
      await getCalendar(date, resourceType),
    { retry: false },
  )

  useEffect(() => { refetch() }, [date])

  const resources = mapCalendarDataToResources(data)
  const noResources = resources?.length === 0
  const events = !noResources ? mapCalendarDataToEvents(data) : []

  const handleDateSet = (arg: DatesSetArg) => {
    setDate(arg.end)
  }

  return (
    <CalendarViewContainer>
      <Calendar>
        {!noResources ?
          <FullCalendar
            locale={plLocale}
            plugins={[ resourceTimeGridPlugin, interactionPlugin ]}
            headerToolbar={calendarHeaderToolbarConfig}
            initialView={calendarInitialView}
            allDaySlot={false}
            resources={resources}
            events={events}
            datesSet={handleDateSet}
          /> :
          t('calendar.noResources')
        }
      </Calendar>
      <CalendarSidebar />
    </CalendarViewContainer>
  )
}
