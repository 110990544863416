import { SaveEditButton } from 'actff-bo-app/components/Button'
import { DateTime } from 'actff-bo-app/components/DateTime'
import { Field } from 'actff-bo-app/components/Form'
import { FormikEffect } from 'actff-bo-app/components/Form/FormikEffect'
import { hasErrors } from 'actff-bo-lib/global'
import { startOfToday } from 'date-fns'
import { FormikProps, FormikState, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'
import { date, object } from 'yup'

type Props = {
  readonly locale: {},
  readonly onChange: (date: Date | null) => void,
  readonly onValidateChange: (hasErrors: boolean) => void,
  readonly retryTime: Date | null,
}

type FormProps = {
  readonly retryTime: Date | null,
}

type State = {
  readonly editMode: boolean,
}

type OpportunityRetryProps = FormikProps<FormProps> & Props & WithTranslation

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
`

class OpportunityRetryComponent extends React.Component<OpportunityRetryProps> {
  public readonly state: State = {
    editMode: false,
  }

  public render(): React.ReactNode {
    const { editMode } = this.state
    const { errors, locale, retryTime, t } = this.props

    return (
      <FormikEffect formik={this.props} onChange={this.handleValidateChange}>
        <Container>
          <Field
            component={DateTime}
            dateTime={retryTime}
            editMode={editMode}
            error={errors.retryTime}
            disabledDays={{ before: startOfToday() }}
            futureDate={true}
            id='retryTime'
            locale={locale}
            name='retryTime'
            onDateTimeChange={this.handleDateTimeChange}
          />
          <SaveEditButton className='retry-time' editMode={editMode} onClick={this.toggleEditMode} t={t} />
        </Container>
      </FormikEffect>
    )
  }

  private readonly toggleEditMode = () => this.setState({ editMode: !this.state.editMode })

  private readonly handleDateTimeChange = (changedDate: Date | null) => {
    this.props.onChange(changedDate)
  }

  private readonly handleValidateChange = (props: FormikState<FormProps>, newProps: FormikState<FormProps>) => {
    if (hasErrors(props.errors) !== hasErrors(newProps.errors)) {
      this.props.onValidateChange(hasErrors(newProps.errors))
    }
  }
}

const validationSchema = () => object<FormProps>().shape({
  retryTime: date().min(new Date()),
})

const formik = withFormik<Props, FormProps>({
  handleSubmit: () => null,
  mapPropsToValues: ({ retryTime }) => ({
    retryTime,
  }),
  validateOnMount: true,
  validationSchema,
})

export const OpportunityRetry = compose(
  formik,
  withTranslation(),
)(OpportunityRetryComponent)
