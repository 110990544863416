import { reactSelectStyles, selectTheme } from 'actff-bo-app/components/Form'
import { Testable } from 'actff-bo-lib/global/testable'
import { getServiceName, getServicesSelectElements, Service, ServiceID } from 'actff-bo-lib/service'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import Select from 'react-select'
import styled from 'styled-components'

type Props = {
  readonly availableServices: ReadonlyArray<Service>
  readonly editMode: boolean,
  readonly onServiceChange: (oldUuid: ServiceID, uuid: ServiceID) => void,
  readonly service: Service,
}

type SelectOptionType = {
  readonly label: string,
  readonly value: ServiceID,
}

type ServiceRequestServiceProps = Props & Testable & WithTranslation

const Container = styled.div`
  margin: 10px 0;
  max-width: 50%;
`

class ServiceRequestServiceComponent extends React.Component<ServiceRequestServiceProps> {
  public render(): React.ReactNode {
    const { availableServices, editMode, service, t, testId } = this.props

    return editMode
      ? (
        <Container data-testid={testId}>
          <Select
            onChange={this.handleServiceChange}
            options={getServicesSelectElements(availableServices, t)}
            styles={reactSelectStyles}
            theme={selectTheme}
            value={this.getCurrentValue()}
          />
        </Container>
      )
      : <Container data-testid={testId}>{getServiceName(service, t)}</Container>
  }

  private readonly handleServiceChange = (option: SelectOptionType) => {
    const { service, onServiceChange } = this.props

    onServiceChange(service.uuid, option.value)
  }

  private readonly getCurrentValue = (): SelectOptionType => {
    const { service, t } = this.props

    return ({
      label: getServiceName(service, t),
      value: service.uuid,
    })
  }
}

export const ServiceRequestService = withTranslation()(ServiceRequestServiceComponent)
