import { BtnType } from 'actff-bo-app/components/Button'
import { Icon } from 'actff-bo-app/components/Icon'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { H3, Span } from 'styles'

import { ApprovalTypeStyled, ReadApprovalButton } from './styled'

type ApprovalTypeProps = Testable & {
  readonly header: string,
  readonly onReadClick: () => void,
  readonly info?: string,
  readonly icon?: IconType,
}

const H3WithLabel = styled(H3)`
  align-items: center;
  display: inline-grid;
  grid-template-columns: 1fr 8fr;
`

export const ApprovalType: React.FC<ApprovalTypeProps> = ({
  header,
  onReadClick,
  icon,
  info,
  testId,
}) => {
  const { t } = useTranslation()

  return (
    <ApprovalTypeStyled>
      {icon ? <H3WithLabel><Icon type={icon} /> {header}</H3WithLabel> : <H3 data-testid={`${testId}--header`}>{header}</H3>}
      {info && <Span data-testid={`${testId}--info`}>{info}</Span>}
      <ReadApprovalButton
        onClick={onReadClick}
        noPadding={true}
        type={BtnType.Button}
        data-testid={`${testId}--read-approval`}
      >
          <span>{t('clientView.approvals.readActualAgreement')}</span>
      </ReadApprovalButton>
    </ApprovalTypeStyled>
  )
}
