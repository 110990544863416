export enum QueryKeys {
  ADD_NEW_USER = 'addNewUser',
  CHANGE_USER_PASSWORD = 'changeUserPassword',
  DELETE_USER = 'deleteUser',
  ADD_CAR_VERSION = 'addCarVersion',
  DELETE_CAR_VERSION = 'deleteCarVersion',
  GET_CAR_MODELS = 'getCarModels',
  GET_CAR_VERSIONS = 'getCarVersions',
  GET_CLIENTS = 'getClients',
  GET_ELECTRONICS_ENGINEERS = 'getElectronicsEngineers',
  GET_BRAND_FUEL_TYPES = 'getBrandFuelTypes',
  GET_FUEL_TYPES = 'getFuelTypes',
  GET_OIL_CODES = 'getOilCodes',
  GET_HOLIDAYS = 'getHolidays',
  ADD_HOLIDAY = 'addHoliday',
  DELETE_HOLIDAY = 'deleteHoliday',
  GET_INSURERS = 'getInsurers',
  ADD_NEW_INSURER = 'addInsurer',
  DELETE_INSURER = 'deleteInsurer',
  GET_INSURERS_QUESTIONS = 'getInsurersQuestions',
  UPDATE_INSURERS_QUESTIONS = 'updateInsurersQuestions',
  GET_MECHANICS = 'getMechanics',
  GET_REPLACEMENT_CARS = 'getReplacementCars',
  GET_SERVICE_ADVISORS = 'getServiceAdvisors',
  GET_SHIFTS = 'getShifts',
  MATCHED_PURCHASE_OPPORTUNITIES = 'matchedPurchaseOpportunities',
  MATCHED_SALE_OPPORTUNITIES = 'matchedSaleOpportunities',
  SCHEDULE = 'schedule',
  SEARCH_CARS = 'searchCars',
  UPDATE_USERS = 'updateUsers',
  GET_BRAKE_LIQUIDS = 'getBrakeLiquids',
  GET_OILS_AND_ENGINES = 'getOilsAndEngines',
  GET_ASSISTANCE = 'getAssistance',
  GET_REGULATIONS_AND_APPROVALS = 'getRegulationsAndApprovals',
  GET_USERS = 'getUsers',
  GET_LOCATION_BRANDS = 'getLocationBrands',
  ADD_LOCATION_D2D_CITY = 'addLocationD2DCity',
  DELETE_LOCATION_D2D_CITY = 'deleteLocationD2DCity',
  ADD_LOCATION_BRAND = 'addLocationBrand',
  DELETE_LOCATION_BRAND = 'deleteLocationBrand',
  UPDATE_REGULATIONS_AND_APPROVALS = 'updateRegulationsAndApprovals',
  UPDATE_INSURERS = 'updateInsurers',
}
