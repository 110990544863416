import { State } from 'actff-bo-lib/store'
import { createSelector } from 'reselect'

const selectOwnershipFeature = (state: State) => state.car.ownership

export const selectCounterpartyListCurrentPage = createSelector(selectOwnershipFeature, ownership => ownership.counterpartyList.currentPage)

export const selectCounterpartyListSearchPhrase =
  createSelector(selectOwnershipFeature, ownership => ownership.counterpartyList.searchPhrase)

export const selectCurrentOwnedCar = createSelector(selectOwnershipFeature, ownership => ownership.currentOwnedCar)

export const selectIsLoadingOwnedCar =
  createSelector(selectOwnershipFeature, ownership => ownership.currentOwnedCar.loading)

export const selectIsFetchOwnedCarFailed =
  createSelector(selectOwnershipFeature, ownership => ownership.currentOwnedCar.fetchFailed)

export const selectCounterpartyList =
  createSelector(selectOwnershipFeature, ownership => ownership.counterpartyList)
