import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { FieldValue } from 'actff-bo-app/components/Form'
import { Cell } from 'actff-bo-app/components/Grid'
import { CarInfo } from 'actff-bo-lib/car/dto'
import { defaultDateFormat } from 'actff-bo-lib/date'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CarContainer, Content, SectionHeader } from './Styled'

type Props = {
  readonly car: CarInfo,
}

const testId = 'car-data__'

export const CarData: FC<Props> = ({ car }) => {
  const { t } = useTranslation()

  return (
    <CarContainer>
      <SectionHeader>{t('carView.basicData.carData.header')}</SectionHeader>

      <Content>
        <Cell>
          <label>{t('carCard.registrationNumber')}:</label>
          <FieldValue data-testid={`${testId}registration-number`}>{car.registrationNumber}</FieldValue>
        </Cell>
        <Cell>
          <label>{t('car.vinNumber')}:</label>
          <FieldValue data-testid={`${testId}vin`}>{car.vin}</FieldValue>
        </Cell>
        <Cell>
          <label>{t('car.firstRegistrationDate')}:</label>
          <FieldValue data-testid={`${testId}first-registration-date`}>
            <FormattedDate date={car.firstRegistrationDate} format={defaultDateFormat} />
          </FieldValue>
        </Cell>
        <Cell>
          <label>{t('car.modelYear')}:</label>
          <FieldValue data-testid={`${testId}model-year`}>{car.modelYear}</FieldValue>
        </Cell>

        <Cell>
          <label>{t('car.model')}:</label>
          <FieldValue data-testid={`${testId}model`}>{car.model}</FieldValue>
        </Cell>
        <Cell>
          <label>{t('car.version')}:</label>
          <FieldValue data-testid={`${testId}version`}>{car.version}</FieldValue>
        </Cell>
        <Cell>
          <label>{t('car.engineCode')}:</label>
          <FieldValue data-testid={`${testId}engine-code`}>{car.engineCode}</FieldValue>
        </Cell>
      </Content>

    </CarContainer>
  )
}
