import { withAdminViewHeader } from 'actff-bo-app/Admin/withAdminViewHeader'
import { EditableList } from 'actff-bo-app/components/EditableList/EditableList'
import { FormHeader } from 'actff-bo-app/components/Form'
import { LocalLoader } from 'actff-bo-app/components/Loader'
import { addCarModel, deleteCarModel, getCarModels } from 'actff-bo-lib/admin/brands/dao'
import { QueryKeys } from 'actff-bo-lib/api/query-keys'
import { selectCurrentBrand } from 'actff-bo-lib/dealership'
import { displayFailureToast, displaySuccessToast, ToastActionType } from 'actff-bo-lib/toast/display-toats'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { Container } from '../../Styled'
import { ViewTabs } from '../ViewTabs'

const testId = 'admin-view-content-management__'

const CarModelsComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentBrand = useSelector(selectCurrentBrand)
  const { data: models, isLoading, isError, refetch } = useQuery([QueryKeys.GET_CAR_MODELS, currentBrand], async () =>
    await getCarModels([currentBrand]), { retry: false, refetchOnWindowFocus: false },
  )

  const handleAdd = async (newModel: string) => {
    try {
      await addCarModel(currentBrand, newModel)
      dispatch(displaySuccessToast('admin.brands.carModels.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      await refetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.brands.carModels.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  const handleDelete = async (itemValue: string) => {
    try {
      await deleteCarModel(currentBrand, itemValue)
      dispatch(displaySuccessToast('admin.brands.carModels.form.save.success', ToastActionType.CREATE_OR_UPDATE))
      await refetch()
    } catch (error) {
      dispatch(displayFailureToast('admin.brands.carModels.form.save.failure', ToastActionType.CREATE_OR_UPDATE))
    }
  }

  if (isLoading) {
    return (
      <>
        <ViewTabs testId={testId} />
        <Container><LocalLoader /></Container>
      </>
    )
  }

  if (isError) {
    dispatch(displayFailureToast('admin.brands.carModels.get.failure', ToastActionType.GET))
  }

  return (
    <>
      <ViewTabs testId={testId} />
      <Container>
        <FormHeader>{t('admin.brands.carModels.subheader')}</FormHeader>
        <EditableList
          tableHeaderCaptionTKey={'admin.brands.carModels.model'}
          addButtonCaptionTKey={'admin.brands.carModels.addNewModel'}
          items={models}
          onAdd={handleAdd}
          onDelete={handleDelete}
        />
      </Container>
    </>
  )
}

export const CarModels = withAdminViewHeader(CarModelsComponent)
