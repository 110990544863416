import { getFullName } from 'actff-bo-lib/client'
import { CrmInsuranceAction, OpportunityInsuranceId, selectCurrentOpportunityViewer } from 'actff-bo-lib/crm/insurance'
import { selectMe, selectUser } from 'actff-bo-lib/user'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useOpportunityViewerStatus = (opportunityId: OpportunityInsuranceId | undefined) => {
  const dispatch = useDispatch()

  const currentUser = useSelector(selectMe)
  const currentOpportunityViewer = useSelector(selectCurrentOpportunityViewer)
  const isEditedByOtherUser = currentOpportunityViewer && (currentUser?.uuid !== currentOpportunityViewer?.userUuid)

  const user = useSelector(selectUser(currentOpportunityViewer?.userUuid))
  const userName = user ? getFullName(user) : currentOpportunityViewer?.userUuid

  React.useEffect(() => {
    opportunityId && dispatch(CrmInsuranceAction.connectOpportunityViewersSocket(opportunityId))

    return () => {
      opportunityId && dispatch(CrmInsuranceAction.disconnectOpportunityViewersSocket(opportunityId))
    }
  }, [dispatch, opportunityId])

  return { isEditedByOtherUser, userName }
}
