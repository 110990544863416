import { BtnTheme, BtnType } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu'
import { useFormik } from 'formik'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkButton, PrimaryButton } from 'styles'
import { object, string } from 'yup'

import { AddButton, ButtonsContainer, CommentsContainer, FooterContainer, Textarea } from './Styled'

type Props = Testable & {
  readonly onSend: (comment: string) => void,
}

type InternalCommentValues = {
  readonly text: string,
}

const maxCommentLength = 500

const validationSchema = () => object<InternalCommentValues>().shape({
  text: string().required().min(1).max(maxCommentLength),
})

export const ServiceRequestAddInternalComment: FC<Props> = ({ onSend, testId }) => {
  const [ isAddFormVisible, setAddFormVisible ] = useState(false)
  const { t } = useTranslation()
  const { handleSubmit, isValid, handleChange, values: formikValues } = useFormik({
    initialValues: useMemo(() => ({
      text: '',
    }), []),
    onSubmit: (values, { resetForm }) => {
      onSend(values.text)
      resetForm()
    },
    validateOnMount: true,
    validationSchema,
  })

  const toggleIsAddFormVisible = () => setAddFormVisible(!isAddFormVisible)

  const renderAddCommentForm = () => (
    <form onSubmit={handleSubmit}>
      <Textarea name='text' onChange={handleChange} data-testid={`${testId}add-internal-comment-input`} value={formikValues.text} />
      <FooterContainer>
        <ButtonsContainer>
          <LinkButton
            onClick={toggleIsAddFormVisible}
            data-testid={`${testId}add-internal-comment-button--cancel`}
            type={BtnType.Reset}
          >
            {t('caption.cancel')}
          </LinkButton>
          <PrimaryButton
            disabled={!isValid}
            data-testid={`${testId}add-internal-comment-button--submit`}
            type={BtnType.Submit}
          >
            {t('caption.send')}
          </PrimaryButton>
        </ButtonsContainer>
      </FooterContainer>
    </form>
  )

  const renderFormOpenButton = () => (
    <AddButton
      caption={t('internalComments.add')}
      iconType={IconType.Plus}
      noPadding={true}
      onClick={toggleIsAddFormVisible}
      theme={BtnTheme.Link}
    />
  )

  return (
    <CommentsContainer>
      {isAddFormVisible ? renderAddCommentForm() : renderFormOpenButton()}
    </CommentsContainer>
  )
}
