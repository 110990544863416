import Tab from '@material-ui/core/Tab'
import TabsComponent from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

export type TabItem = {
  readonly label: string,
  readonly children: React.ReactNode,
}

type Props = {
  readonly tabs: ReadonlyArray<TabItem>,
}

const Container = styled.div`
  height: 100%;
`

const TabsStyled = styled(TabsComponent)`
  .MuiTabs-flexContainer {
    background-color: ${colors.athensGray};
    border-top: 1px solid ${colors.mystic};
  }

  .PrivateTabIndicator-colorSecondary-7 {
    background-color: ${colors.blueViolet};
  }

  root {
    border-bottom: 1px solid #e8e8e8;
    width: 100px;
  }

  .PrivateTabIndicator-colorSecondary-3 {
    background-color: ${colors.midnightBlue};
  }
`

const TabStyled = styled(Tab)`
  &.MuiButtonBase-root {
    max-width: 160px;
    padding: 10px 25px;
  }

  .MuiTab-wrapper {
    font-size: 11px;
    text-transform: none;
    font-weight: bolder;
  }
`

const TabsHeader = styled.div`
  border-bottom: 1px solid #e8e8e8;
`

const Content = styled.div`
  height: 100%;
`

export const Tabs: FC<Props> = ({ tabs }) => {
  const [selectedTabValue, setSelectedTabValue] = React.useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabValue(newValue)
  }

  return (
    <Container>
      <TabsHeader>
        <TabsStyled value={selectedTabValue} onChange={handleChange}>
          {tabs.map(tab => <TabStyled key={tab.label} disableRipple={true} label={tab.label} />)}
        </TabsStyled>
        <Typography />
      </TabsHeader>
      <Content>
        {tabs[selectedTabValue].children}
      </Content>
    </Container>
  )
}
