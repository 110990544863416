import { Field } from 'actff-bo-app/components/Form'
import { differenceInDays, startOfToday } from 'date-fns'
import { Form, FormikProps, withFormik } from 'formik'
import React, { FC } from 'react'
import { compose } from 'redux'
import styled from 'styled-components'
import { number, object } from 'yup'

type Props = {
  readonly onSubmit: (values?: number) => void,
  readonly currentDate?: Date,
}

type Values = {
  readonly date?: number,
}

type OpportunityListDayOffsetProps = Props & FormikProps<Values>

const DayOffsetForm = styled(Form)`
  display: inline-block;
  margin: 0 10px;
`

const DayOffsetField = styled(Field)`
  max-width: 70px;
`

const validationSchema = () => object<Values>().shape({
  date: number().positive(),
})

const OpportunityListDayOffsetComponent: FC<OpportunityListDayOffsetProps> = ({ errors, touched, values }) => (
  <DayOffsetForm>
    <DayOffsetField error={errors.date} name='date' touched={touched.date} type='number' value={values.date} />
  </DayOffsetForm>
)

const formik = withFormik<Props, Values>({
  enableReinitialize: true,
  handleSubmit: (values: Values, { props }) => {
    props.onSubmit(values.date)
  },
  mapPropsToValues: ({ currentDate }) => ({
    date: currentDate ? differenceInDays(currentDate, startOfToday()) : 0,
  }),
  validationSchema,
})

export const OpportunityListDayOffset = compose(formik)(OpportunityListDayOffsetComponent)
