import { userPasswordRegExp } from 'actff-bo-lib/user'
import { phoneRegExp } from './dto'

export enum ValidationType {
  MinLength = 'MinLength',
  MaxLength = 'MaxLength',
  Pattern = 'Pattern',
  PhoneNumber = 'PhoneNumber',
  Required = 'Required',
  Validate = 'Validate',
}

type BaseValidation = {
  readonly msgTKey?: string,
  readonly value?: number,
}

type MaxLengthValidation = BaseValidation & {
  readonly type: ValidationType.MaxLength,
}

type MinLengthValidation = BaseValidation & {
  readonly type: ValidationType.MinLength,
}

type PatternValidation = Omit<BaseValidation, 'value'> & {
  readonly type: ValidationType.Pattern,
  readonly regExp?: RegExp,
}

type RequiredValidation = BaseValidation & {
  readonly type: ValidationType.Required,
  readonly value?: boolean,
}

type PhoneNumber = {
  readonly type: ValidationType.PhoneNumber,
}

const minDefaultLength = 3
const maxDefaultLength = 15

type GetValidatorProps = MinLengthValidation | MaxLengthValidation | PatternValidation | RequiredValidation | PhoneNumber

// tslint:disable-next-line:cyclomatic-complexity
export const getValidator = (props: GetValidatorProps) => {
  switch (props.type) {
    case ValidationType.MaxLength:
      return {
        maxLength: {
          message: props.msgTKey || 'form.validation.password.tooLong',
          value: props.value || maxDefaultLength,
        }}
    case ValidationType.MinLength:
      return {
        minLength: {
          message: props.msgTKey || 'form.validation.password.tooShort',
          value: props.value || minDefaultLength,
        }}
    case ValidationType.Pattern:
      return {
        pattern: {
          message: props.msgTKey || 'form.validation.password.wrong',
          value: props.regExp || userPasswordRegExp,
        },
      }
    case ValidationType.Required:
      return {
        pattern: {
          message: 'form.validation.string.noEmpty',
          value: /^(?!\s*$).+/,
        },
        required: {
          message: props.msgTKey || 'form.validation.required',
          value: props.value || true,
        },
      }
    case ValidationType.PhoneNumber:
      return {
        pattern: {
          message: 'form.validation.phoneNumber.wrong',
          value: phoneRegExp,
        },
      }

    default:
      return {}
  }
}
