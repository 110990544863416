import { ThreadBasicData, ThreadStatusNameMap } from 'actff-bo-lib/chat'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly thread?: ThreadBasicData,
}

const ChatThreadStatusContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: left;
`

export const ChatThreadStatus: React.FC<Props> = ({ thread }) => {
  const { t } = useTranslation()

  return (
    thread
      ? (
        <ChatThreadStatusContainer>
          {t('chat.thread.status')}:&nbsp;
          <strong>
            {t(thread.status ? ThreadStatusNameMap.get(thread.status, 'chat.thread.status.new') : 'chat.thread.status.new')}
          </strong>
        </ChatThreadStatusContainer>
      )
      : null
  )
}
