import { BtnType } from 'actff-bo-app/components/Button'
import { Icon } from 'actff-bo-app/components/Icon'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactJsPopup from 'reactjs-popup'
import styled from 'styled-components'
import { colors, H3 } from 'styles'

const defaultPadding = 32

export enum PopupPosition {
  TopLeft = 'top left',
  TopRight = 'top right',
  BottomRight = 'bottom right',
  BottomLeft = 'bottom left',
  RightCenter = 'right center',
  LeftCenter = 'left center',
  TopCenter = 'top center',
  BottomCenter = 'bottom center',
}

const PopupStyled = styled(ReactJsPopup)`
  max-width: 602px;
`

const HeaderContent = styled(H3)`
  color: ${colors.dark};
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
`

const PopupHeader = styled.div`
  border-bottom: 1px solid ${colors.athensGray};
  display: inline-grid;
  grid-template-columns: 11fr 1fr;
  justify-content: space-between;
  padding-left: ${defaultPadding}px;
  width: 100%;
`

const PopupContent = styled.div`
`

const IconContainer = styled.div`
  align-items: center;
  display: grid;
  justify-content: center;

  img {
    cursor: pointer;
  }
`

const PopupBody = styled.div`
  padding: ${defaultPadding}px 30px;
`

type PopupProps = {
  readonly hasCloseButton?: boolean;
  readonly trigger?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  // TODO remove '?'
  readonly onClose?: () => void;
  readonly open: boolean;
  readonly header?: string;
  readonly children: JSX.Element | ((close: () => void, isOpen: boolean) => JSX.Element);
  readonly position?: PopupPosition;
}

const PopupComponent: React.FC<PopupProps & WithTranslation> = ({
  children,
  hasCloseButton = true,
  header,
  onClose,
  open = false,
  position = PopupPosition.BottomCenter,
}) => (
    <PopupStyled open={open} onClose={onClose} closeOnDocumentClick={true} position={position}>
      <PopupContent>
        <PopupHeader>
          {header && <HeaderContent>{header}</HeaderContent>}
          {hasCloseButton
            && <IconContainer>
              <button type={BtnType.Button} onClick={onClose}><Icon type={IconType.Close} /></button>
            </IconContainer>
          }
        </PopupHeader>
        <PopupBody>
          {children}
        </PopupBody>
      </PopupContent>
    </PopupStyled>
  )

export const Popup = withTranslation()(PopupComponent)
