import { Map } from 'immutable'

export enum ServiceType {
  ServiceAfterYear = 'AFTER YEAR',
  ServiceAfter15000 = 'AFTER 15000',
  TyreChange = 'TYRE CHANGE',
}

export const ServiceTypeMap: Map<ServiceType, string> = Map([
  [ServiceType.ServiceAfterYear, 'serviceRequest.type.serviceAfterYear'],
  [ServiceType.ServiceAfter15000, 'serviceRequest.type.serviceAfter15000'],
  [ServiceType.TyreChange, 'serviceRequest.type.tyreChange'],
]) as Map<ServiceType, string>
