import { createInternalComment } from 'actff-bo-lib/global'
import { encodeStringIfExists } from 'actff-bo-lib/global/string'
import { createRoute } from 'actff-bo-lib/menu'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { redirectToList } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import {
  selectServiceRequestBrandFilters,
  selectServiceRequestDealerLocationFilters,
  ServiceRequest,
  ServiceRequestAction,
  ServiceRequestActionType,
} from 'actff-bo-lib/service-request'
import { State } from 'actff-bo-lib/store'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { forkJoin, of } from 'rxjs'
import { AjaxError } from 'rxjs/ajax'
import { catchError, filter, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import {
  createServiceRequest,
  getAvailableCarLeaveOptions,
  getNewServiceRequestCount,
  getOverdueServiceRequests,
  getServiceRequest,
  getServiceRequestAttachment,
  getServiceRequestComments,
  getServiceRequests,
  sendServiceRequestInternalComment,
  updateServiceRequest,
} from './dao'
import {
  selectCurrentPage,
  selectCurrentServiceRequest,
  selectDraftServiceRequestDiffAndId,
  selectOverdueListCurrentPage,
  selectServiceRequestListFilters,
  selectServiceRequestSearchPhrase,
} from './selectors'

const changeListParamsEpic: Epic<ServiceRequestAction, ServiceRequestAction, State> = (action$, state$) => action$.pipe(
  ofType<ReturnType<typeof ServiceRequestAction.changePage>>(ServiceRequestActionType.ChangePage),
  withLatestFrom(state$),
  tap(([, store]) => {
    redirectToList(Paths.ServiceRequests, selectCurrentPage(store))
  }),
  map(ServiceRequestAction.getServiceRequests),
)

const changeOverduePageEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType(ServiceRequestActionType.ChangeOverdueListPage),
  map(ServiceRequestAction.getOverdueServiceRequests),
)

const changeFilterOrSearchPhraseEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType(
    ServiceRequestActionType.ChangeListFilter,
    ServiceRequestActionType.ChangeSearchPhrase,
    ServiceRequestActionType.ChangeDealerLocations,
    ServiceRequestActionType.ChangeBrands,
  ),
  map(() => ServiceRequestAction.changePage(1)),
)

const createServiceRequestEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ServiceRequestAction.createServiceRequest>>(ServiceRequestActionType.CreateServiceRequest),
  switchMap(({ payload }) => createServiceRequest(payload).pipe(
    map(ServiceRequestAction.createServiceRequestSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.createServiceRequestFailure(error))),
  )),
)

const createServiceRequestSuccessEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ServiceRequestAction.createServiceRequestSuccess>>(ServiceRequestActionType.CreateServiceRequestSuccess),
  tap(({ payload }) => {
    history.replace(createRoute(Paths.ServiceRequestsView, { serviceRequestId: payload.uuid }))
  }),
  ignoreElements(),
)

const getAvailableCarLeaveOptionsEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType(ServiceRequestActionType.GetAvailableCarLeaveOptions),
  switchMap(() => getAvailableCarLeaveOptions().pipe(
    map(ServiceRequestAction.getAvailableCarLeaveOptionsSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.getAvailableCarLeaveOptionsFailure(error)),
  )),
))

const getServiceRequestEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ServiceRequestAction.getServiceRequest>>(ServiceRequestActionType.GetServiceRequest),
  switchMap(({ payload }) => getServiceRequest(payload).pipe(
    map(ServiceRequestAction.getServiceRequestSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.getServiceRequestFailure(error)),
  )),
))

const getNewServiceRequestCountEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType(ServiceRequestActionType.GetNewServiceRequestCount),
  switchMap(() => getNewServiceRequestCount().pipe(
    map(ServiceRequestAction.getNewServiceRequestCountSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.getNewServiceRequestCountFailure(error))),
  )),
)

const getServiceRequestSuccessEpic: Epic<ServiceRequestAction, ServiceRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(ServiceRequestActionType.GetServiceRequestSuccess),
  withLatestFrom(state$),
  map(([, state]) => selectCurrentServiceRequest(state)),
  filter<ServiceRequest>(Boolean),
  switchMap(({ uuid }) => [
    ServiceRequestAction.getServiceRequestAttachments(),
    ServiceRequestAction.getServiceRequestComments(uuid),
  ]),
)

const getServiceRequestCommentsEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ServiceRequestAction.getServiceRequestComments>>(ServiceRequestActionType.GetServiceRequestComments),
  switchMap(({ payload }) => getServiceRequestComments(payload).pipe(
    map(ServiceRequestAction.getServiceRequestCommentsSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.getServiceRequestCommentsFailure(error))),
  )),
)

const getServiceRequestAttachmentsEpic: Epic<ServiceRequestAction, ServiceRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(ServiceRequestActionType.GetServiceRequestAttachments),
  withLatestFrom(state$),
  map(([, state]) => selectCurrentServiceRequest(state)),
  filter<ServiceRequest>(Boolean),
  map(serviceRequest => serviceRequest.attachmentList.map(getServiceRequestAttachment)),
  switchMap(attachmentList => forkJoin(attachmentList).pipe(
    map(ServiceRequestAction.getServiceRequestAttachmentsSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.getServiceRequestAttachmentsFailure(error))),
  )),
)

const getServiceRequestsEpic: Epic<ServiceRequestAction, ServiceRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(ServiceRequestActionType.GetServiceRequests),
  withLatestFrom(state$),
  switchMap(([, state]) => getServiceRequests(
    selectServiceRequestListFilters(state),
    selectCurrentPage(state) - 1,
    encodeStringIfExists(selectServiceRequestSearchPhrase(state)),
    selectServiceRequestDealerLocationFilters(state),
    selectServiceRequestBrandFilters(state),
  ).pipe(
    map(ServiceRequestAction.getServiceRequestsSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.getServiceRequestsFailure(error)),
  )),
))

const getOverdueServiceRequestsEpic: Epic<ServiceRequestAction, ServiceRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(ServiceRequestActionType.GetOverdueServiceRequests),
  withLatestFrom(state$),
  switchMap(([, state]) => getOverdueServiceRequests(
    selectOverdueListCurrentPage(state) - 1,
  ).pipe(
    map(ServiceRequestAction.getOverdueServiceRequestsSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.getOverdueServiceRequestsFailure(error)),
  )),
))

const updateServiceRequestAsInProgress: Epic<ServiceRequestAction, ServiceRequestAction, State> = (action$, state$) =>
  action$.pipe(
    ofType(ServiceRequestActionType.UpdateServiceRequestAsInProgress),
    withLatestFrom(state$),
    map(([, state]) => selectDraftServiceRequestDiffAndId(state)),
    filter<ServiceRequest>(Boolean),
    switchMap(serviceRequest => updateServiceRequest(serviceRequest).pipe(
      map(ServiceRequestAction.updateServiceRequestAsInProgressSuccess),
      catchError((error: AjaxError) => of(ServiceRequestAction.updateServiceRequestAsInProgressFailure(error))),
    )),
)

const updateServiceRequestFromDraftEpic: Epic<ServiceRequestAction, ServiceRequestAction, State> = (action$, state$) => action$.pipe(
  ofType(ServiceRequestActionType.UpdateServiceRequestFromDraft),
  withLatestFrom(state$),
  map(([, state]) => selectDraftServiceRequestDiffAndId(state)),
  filter<ServiceRequest>(Boolean),
  switchMap(serviceRequest => updateServiceRequest(serviceRequest).pipe(
    map(ServiceRequestAction.updateServiceRequestFromDraftSuccess),
    catchError((error: AjaxError) => of(ServiceRequestAction.updateServiceRequestFromDraftFailure(error))),
  )),
)

const updateServiceRequestFromDraftFinishedEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType(ServiceRequestActionType.UpdateServiceRequestFromDraftSuccess),
  tap(() => [
    history.push(Paths.ServiceRequests),
  ]),
  ignoreElements(),
)

const sendServiceRequestInternalCommentEpic: Epic<ServiceRequestAction> = action$ => action$.pipe(
  ofType<ReturnType<typeof ServiceRequestAction.sendInternalComment>>(ServiceRequestActionType.SendInternalComment),
  switchMap(({ payload }) => sendServiceRequestInternalComment(payload.serviceRequestUuid, payload.comment).pipe(
    map(comment => ServiceRequestAction.sendInternalCommentSuccess(createInternalComment(comment))),
    catchError((error: AjaxError) => of(ServiceRequestAction.sendInternalCommentFailure(error))),
  )),
)

export const serviceRequestEpic = combineEpics(
  changeListParamsEpic,
  changeOverduePageEpic,
  changeFilterOrSearchPhraseEpic,
  createServiceRequestEpic,
  createServiceRequestSuccessEpic,
  getAvailableCarLeaveOptionsEpic,
  getServiceRequestEpic,
  getNewServiceRequestCountEpic,
  getOverdueServiceRequestsEpic,
  getServiceRequestsEpic,
  getServiceRequestSuccessEpic,
  getServiceRequestCommentsEpic,
  getServiceRequestAttachmentsEpic,
  updateServiceRequestAsInProgress,
  updateServiceRequestFromDraftEpic,
  updateServiceRequestFromDraftFinishedEpic,
  sendServiceRequestInternalCommentEpic,
)
