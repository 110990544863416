import { PagedResult } from 'actff-bo-lib/api'
import { WithDealershipFilters } from 'actff-bo-lib/dealership'
import { Loadable, Searchable } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu'
import { parseUrl } from 'actff-bo-lib/pagination'
import { AnyAction } from 'redux'

import { NdcCarActionType } from './actions'
import { NdcCar } from './dto'

export type NdcCarState = {
  readonly currentPage: number,
  readonly list: Searchable<WithDealershipFilters<Loadable<PagedResult<NdcCar>>>>,
}

const parsedUrl = parseUrl(Paths.NdcCars)
const initialState: NdcCarState = {
  currentPage: parsedUrl.page ? +parsedUrl.page : 1,
  list: {
    brandFilters: [],
    data: null,
    loading: false,
    locationFilters: [],
  },
}

// tslint:disable-next-line:cyclomatic-complexity
export const ndcCarReducer = (state: NdcCarState = initialState, action: AnyAction): NdcCarState => {
  switch (action.type) {
    case NdcCarActionType.ChangeDealerLocationFilters:
      return ({
        ...state,
        list: {
          ...state.list,
          locationFilters: action.payload,
        },
      })

    case NdcCarActionType.ChangeSearchPhrase:
      return ({
        ...state,
        list: {
          ...state.list,
          searchPhrase: action.payload,
        },
      })

    case NdcCarActionType.ChangePage:
      return ({
        ...state,
        currentPage: action.payload,
      })

    case NdcCarActionType.GetNdcCars:
      return ({
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      })

    case NdcCarActionType.GetNdcCarsSuccess:
      return ({
        ...state,
        list: {
          ...state.list,
          data: action.payload,
          loading: false,
        },
      })

    default:
      return state
  }
}
