import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { OwnershipType } from 'actff-bo-lib/car/dto'
import { IconType } from 'actff-bo-lib/menu/dto'
import { Map } from 'immutable'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AddUserContainer } from './Styled'

type Props = {
  readonly onClick: () => void,
  readonly ownershipType: OwnershipType,
  readonly show: boolean,
}

const OwnershipTypeAddUserMap: Map<OwnershipType, string> = Map([
  [OwnershipType.DmsOwner, 'ownership.add.owner'],
  [OwnershipType.AppDriver, 'ownership.add.user'],
  [OwnershipType.DmsDriver, 'ownership.add.user'],
]) as Map<OwnershipType, string>

export const AddUserLinkButton: FC<Props> = ({ onClick, ownershipType, show }) => {
  const { t } = useTranslation()

  return show ? (
    <AddUserContainer>
      <ButtonWithIcon
        onClick={onClick}
        theme={BtnTheme.Link}
        iconType={IconType.Plus}
        caption={t(OwnershipTypeAddUserMap.get(ownershipType, 'ownership.add.user'))}
      />
    </AddUserContainer>
  ) : null
}
