import { QuickCampaignToastSuccess } from 'actff-bo-app/Campaign'
import { ServiceRequestWithServiceExists } from 'actff-bo-app/ServiceRequest'
import { ToastBodyComponentType } from 'actff-bo-lib/toast'
import { ToastBodyProps } from 'actff-bo-lib/toast/dto'
import { ComponentType } from 'react'

// TODO: Refactor toast body components to combine component with props and have proper typing without TS errors
// @ts-ignore
export const ToastBodyComponentMap: Map<ToastBodyComponentType, ComponentType<ToastBodyProps>> = new Map([
  [ToastBodyComponentType.ServiceRequestWithServiceExists, ServiceRequestWithServiceExists],
  [ToastBodyComponentType.QuickCampaignSuccess, QuickCampaignToastSuccess],
])
