import { HeaderContainer } from 'actff-bo-app/components/Grid'
import { ListBrandFiltersForm } from 'actff-bo-app/components/ListFilters/ListBrandFilters'
import { ListFilters } from 'actff-bo-app/components/ListFilters/ListFilters'
import { ListLocationsFiltersForm } from 'actff-bo-app/components/ListFilters/ListLocationsFilters'
import { Search } from 'actff-bo-app/components/Search'
import { TabFilters } from 'actff-bo-app/components/TabFilters'
import {
  CrmInsuranceAction,
  OpportunityInsuranceId,
  OpportunityListType,
  selectOpportunityListBrandFilters,
  selectOpportunityListDealerLocationsFilters,
  selectOpportunityListsFilters,
  selectOpportunityListsFiltersCount,
  selectOpportunitySearchPhrase,
} from 'actff-bo-lib/crm/insurance'
import { CrmInsuranceListAction } from 'actff-bo-lib/crm/insurance/actions/list'
import { InsuranceFilters } from 'actff-bo-lib/crm/insurance/filters'
import { defaultInsuranceListFilters } from 'actff-bo-lib/crm/insurance/reducer/list-filters'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { IconType } from 'actff-bo-lib/menu'
import { selectUsersWithoutMe, UserAction } from 'actff-bo-lib/user'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSessionStorage } from 'react-use'
import styled from 'styled-components'
import { H2, HeaderList, TabsLabelStyled, TabStyled } from 'styles'

import { OpportunityAssignFilters } from './OpportunityAssign'
import { OpportunityList } from './OpportunityList'
import { OpportunityListFilters } from './OpportunityListFilters/OpportunityListFilters'
import { ListButtons, RefreshButtonIcon } from './Styled'

const testId = 'opportunity-list__'

const HeaderFiltersContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 12px 0;
`

export const OpportunityLists: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const getUserList = useMemo(() => () => { dispatch(UserAction.getUserList()) }, [dispatch])
  const { data: userList } = useSelector(selectUsersWithoutMe)

  const [selectedInsuranceUuid, setSelectedInsuranceUuid] =
    useSessionStorage<OpportunityInsuranceId | null>('opportunity-insurance-selected-as-processed', null)

  useEffect(() => {
    if (selectedInsuranceUuid) {
      dispatch(CrmInsuranceListAction.setSelectedAsProcessedUuid(selectedInsuranceUuid))
    }
  }, [])

  const handleSelectInsuranceAsProcessed = (uuid: OpportunityInsuranceId) => {
    dispatch(CrmInsuranceListAction.setSelectedAsProcessedUuid(uuid))
    setSelectedInsuranceUuid(uuid)
  }

  const filters = useSelector(selectOpportunityListsFilters)
  const dealerLocationFilters = useSelector(selectOpportunityListDealerLocationsFilters)
  const brandFilters = useSelector(selectOpportunityListBrandFilters)

  const existingSearchPhrase = useSelector(selectOpportunitySearchPhrase)
  const onSearch = (searchPhrase: string) => dispatch(CrmInsuranceAction.changeSearchPhrase(searchPhrase))
  const onFiltersSubmit = (values: InsuranceFilters) => {
    dispatch(CrmInsuranceAction.changeListFilters(values))
  }
  const filtersCount = useSelector(selectOpportunityListsFiltersCount)

  const onFiltersClear = () => {
    dispatch(CrmInsuranceAction.clearListFilters())
  }

  const handleRefresh = () => {
    Object.keys(OpportunityListType).map(key => dispatch(CrmInsuranceAction.getOpportunities(OpportunityListType[key])))
  }

  const onDealerLocationChange = (values: ReadonlyArray<DealerLocationKey>) => {
    dispatch(CrmInsuranceListAction.changeListsDealerLocationFilters(values))
  }

  const onBrandChange = (values: ReadonlyArray<string>) => {
    dispatch(CrmInsuranceListAction.changeListsBrandFilters(values))
  }

  const getFiltersLabel = () => filtersCount
    ? `${t('clientList.filters.defaultFilters.tabLabel')} (${filtersCount})`
    : t('clientList.filters.defaultFilters.tabLabel')

  useEffect(() => {
    getUserList()
  }, [getUserList])

  return (
    <>
      <HeaderList>
        <HeaderContainer>
          <div><H2 data-testid={`${testId}header`}>{t('crmInsurance.list.header')}</H2></div>
          <Search
            testId={testId}
            onSearch={onSearch}
            placeholder={t('crmInsurance.search.placeholder')}
            initialValues={{ searchPhrase: existingSearchPhrase }}
          />
        </HeaderContainer>
        <HeaderFiltersContainer>
          <ListButtons>
            <TabStyled onClick={handleRefresh}>
              <TabsLabelStyled dark={true}>{t('caption.refresh')}</TabsLabelStyled>
              <RefreshButtonIcon type={IconType.Refresh} />
            </TabStyled>
            <TabFilters
              buttonLabel={getFiltersLabel()}
              defaultFormValues={filters}
              filters={filterRendererProps => <OpportunityListFilters filters={filters} {...filterRendererProps} />}
              resetValues={defaultInsuranceListFilters}
              onClear={onFiltersClear}
              onSubmit={onFiltersSubmit}
            />
            <ListFilters
              filtersCaption={t('caption.location')}
              filtersComponent={filterRendererProps => <ListLocationsFiltersForm {...filterRendererProps} />}
              filterValues={dealerLocationFilters}
              onSubmit={onDealerLocationChange}
            />
            <ListFilters
              defaultFieldValue={false}
              filtersCaption={t('caption.brand')}
              filtersComponent={filterRendererProps => <ListBrandFiltersForm {...filterRendererProps} />}
              filterValues={brandFilters}
              onSubmit={onBrandChange}
            />
          </ListButtons>
          <OpportunityAssignFilters userList={userList} />
        </HeaderFiltersContainer>
      </HeaderList>
      <OpportunityList
        title='opportunity.list.past'
        type={OpportunityListType.overdue}
        setSelectedAsProcessedUuid={handleSelectInsuranceAsProcessed}
      />
      <OpportunityList
        title='opportunity.list.current'
        type={OpportunityListType.new}
        setSelectedAsProcessedUuid={handleSelectInsuranceAsProcessed}
      />
      <OpportunityList
        changeTimeTo={true}
        title='opportunity.list.future'
        type={OpportunityListType.future}
        setSelectedAsProcessedUuid={handleSelectInsuranceAsProcessed}
      />
      <OpportunityList
        title='opportunity.list.all'
        type={OpportunityListType.all}
        setSelectedAsProcessedUuid={handleSelectInsuranceAsProcessed}
      />
    </>
  )
}
