import { envSelector } from 'actff-bo-lib/api'
import { IconType, Path } from 'actff-bo-lib/menu/dto'
import { UserPermissions } from 'actff-bo-lib/user'

import { ComponentType } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

// tslint:disable object-literal-sort-keys
export const adminMenu = ({
  access: [UserPermissions.AdminAllService],
  component: ComponentType.AdminCarModels,
  expanded: false,
  icon: IconType.Crm,
  name: 'admin',
  path: Paths.Admin as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: [UserPermissions.AdminAllService],
      component: ComponentType.AdminEmployeeSchedule,
      expanded: true,
      hiddenInMenu: envSelector({
        default: false,
        dev: false,
        prod: true,
        stg: true,
        test: false,
      }),
      name: 'admin.employeeSchedule',
      path: Paths.AdminEmployeeSchedule as Path,
      ...LoggedInWrappers,
      children: [],
    },
    {
      access: [UserPermissions.AdminAllService],
      component: ComponentType.AdminOpeningHours,
      expanded: true,
      name: 'admin.dealerLocations',
      path: Paths.AdminLocationsOpeningHours as Path,
      childrenHasMultipleTabs: true,
      ...LoggedInWrappers,
      children: [
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminOpeningHours,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminLocationsOpeningHours as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminVisitAvailability,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminLocationsVisitAvailability as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminContactDetails,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminLocationsContactDetails as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminD2DCities,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminLocationsD2DCities as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminLocationsBrands,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminLocationsBrands as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminApplicationLinks,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminLocationsApplicationLinks as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: [UserPermissions.AdminAllService],
      component: ComponentType.AdminCarModels,
      expanded: true,
      name: 'admin.brands',
      path: Paths.AdminCarModels as Path,
      childrenHasMultipleTabs: true,
      ...LoggedInWrappers,
      children: [
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminCarModels,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminCarModels as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminCarVersions,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminCarVersions as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminBrakeLiquids,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminBrakeLiquids as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminOilsAndEngines,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminEngines as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminAssistance,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminAssistance as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: [UserPermissions.AdminAllService],
      component: ComponentType.AdminInsurers,
      expanded: true,
      name: 'admin.dealership',
      path: Paths.AdminDealershipInsurers as Path,
      childrenHasMultipleTabs: true,
      ...LoggedInWrappers,
      children: [
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminInsurers,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminDealershipInsurers as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminInsurersQuestions,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminDealershipInsurersQuestions as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminRegulationsAndApprovals,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminDealershipRegulationsAndApprovals as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminHolidays,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminDealershipHolidays as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminUsers,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminDealershipUsers as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: [UserPermissions.AdminAllService],
      component: ComponentType.AdminEquipment,
      expanded: true,
      hiddenInMenu: true,
      name: 'admin.technicalCondition',
      path: Paths.AdminTechnicalConditionEquipment as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminEquipment,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminTechnicalConditionEquipment as Path,
          ...LoggedInWrappers,
          children: [],
        },
        {
          access: [UserPermissions.AdminAllService],
          component: ComponentType.AdminTesterVIDA,
          expanded: true,
          hiddenInMenu: true,
          path: Paths.AdminTechnicalConditionVida as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
  ],
})
