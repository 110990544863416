import { ActionsUnion } from '@evojam/redux-utils'
import { Map } from 'immutable'
import { AnyAction } from 'redux'

import { TradeOpportunityType } from './dto'
import { CrmTradeOpportunityPurchaseAction } from './purchase'
import { CrmTradeOpportunitySaleAction } from './sale'

export const crmTradeActionsMap = Map([
  [TradeOpportunityType.Purchase, CrmTradeOpportunityPurchaseAction],
  [TradeOpportunityType.Sale, CrmTradeOpportunitySaleAction],
]) as Map<TradeOpportunityType, ActionsUnion<AnyAction>>
