// tslint:disable max-file-line-count
import { BtnTheme, BtnType } from 'actff-bo-app/components/Button'
import { DatePickerInput, FormattedDate } from 'actff-bo-app/components/DateTime'
import { BusinessEventsHistory } from 'actff-bo-app/components/EventsHistory'
import { FormFooter, FormFooterLeft, FormFooterRight, getDateValue, RadioValue } from 'actff-bo-app/components/Form'
import { UploadFileProgress } from 'actff-bo-app/components/UploadFile'
import { OpportunityCommentsAndServiceHistory } from 'actff-bo-app/Crm/OpportunityCommentsAndServiceHistory'
import { OpportunityOwner } from 'actff-bo-app/Crm/OpportunityOwner'
import { Content } from 'actff-bo-app/Crm/Styled'
import { AddOpportunityEvent } from 'actff-bo-app/Crm/Trade/AddOpportunityEvent'
import { MatchedSaleOpportunities } from 'actff-bo-app/Crm/Trade/MatchedOpportunities/MatchedSaleOpportunities'
import { OpportunityStatus as OpportunityStatusComponent } from 'actff-bo-app/Crm/Trade/OpportunityStatus'
import { RestOwnerCars } from 'actff-bo-app/Crm/Trade/RestOwnerCars'
import {
  OpportunityPurchase,
  OpportunityPurchaseId,
  OpportunityPurchaseStatusType,
  OpportunityPurchaseViewForm,
  OpportunityTradeEventType,
  OpportunityTradeInternalEventFormWithAttachments,
} from 'actff-bo-lib/crm/trade'
import { CrmTradeOpportunityPurchaseAction, selectCurrentOpportunityPurchase } from 'actff-bo-lib/crm/trade/purchase'
import { noCentsDivider } from 'actff-bo-lib/currency'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { buildUrl, hasErrors, mapToOptions, NullableString, SelectOption } from 'actff-bo-lib/global'
import { selectCurrentLanguage } from 'actff-bo-lib/i18n'
import { IconType, Paths } from 'actff-bo-lib/menu'
import { history } from 'actff-bo-lib/router'
import { hasPermission, selectMeWithoutPermissions, selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useParams } from 'react-router-dom'
import { Header, LinkButton, PrimaryButton } from 'styles'

import { CarData, CarValue } from '../../Car'
import {
  ActionData,
  ButtonWithIconStyled,
  HeaderContent,
  OpportunityContent,
  OpportunityDataFlag,
  OpportunityHeaderValue,
  QuickNotificationsButton,
  StatusWithActionData,
} from '../../Styled'
import { OpportunityAdditionalInformation } from './OpportunityAdditionalInformation'

type OpportunityViewParams = {
  readonly opportunityId: OpportunityPurchaseId,
}

const testId = 'Opportunity-Purchase-View__'

type OpportunityViewComponentProps = RouteComponentProps<OpportunityViewParams>

export const OpportunityPurchaseView: FC<OpportunityViewComponentProps> = () => {
  const dispatch = useDispatch()
  const { opportunityId } = useParams<OpportunityViewParams>()
  const [eventDialogVisible, setEventDialogVisible] = useState(false)

  const { control, errors, formState: { isValid, isDirty }, handleSubmit, register, reset } =
    useForm<OpportunityPurchaseViewForm>({ mode: 'onChange'})

  const clearCurrentOpportunity = useMemo(() => () => {
    dispatch(CrmTradeOpportunityPurchaseAction.clearCurrentOpportunity())
  }, [dispatch])

  const getCurrentOpportunity = useMemo(() => (uuid: OpportunityPurchaseId) => {
    dispatch(CrmTradeOpportunityPurchaseAction.getOpportunity(uuid))
  }, [dispatch])

  const updateOpportunity = useMemo(() => (opportunity: Partial<OpportunityPurchase>) => {
    dispatch(CrmTradeOpportunityPurchaseAction.updateOpportunity(opportunity))
  }, [dispatch])

  const userPermissions = useSelector(selectUserPermissions)
  const isAcceptButtonDisabled = !isValid || !isDirty || hasErrors(errors)
  const hasAdminAllServicePermissions = hasPermission([UserPermissions.AdminAllService])(userPermissions)

  const sendNewComment = (opportunityPurchase: OpportunityPurchase, comment: string) =>
    dispatch(CrmTradeOpportunityPurchaseAction.sendInternalComment(opportunityPurchase.uuid, { comment }))

  useEffect(() => {
    getCurrentOpportunity(opportunityId)

    return () => { clearCurrentOpportunity() }
  }, [getCurrentOpportunity, opportunityId])

  const { t } = useTranslation()
  const currentOpportunity = useSelector(selectCurrentOpportunityPurchase)
  const getNewCarOfferFlagValue = (value: boolean | undefined) => value ? RadioValue.Yes : RadioValue.No

  useEffect(() => {
    if (currentOpportunity) {
      const { insurance, innerValuation, isNewCar, ...rest } = currentOpportunity
      reset({
        ...rest,
        innerValuation: NullableString(innerValuation),
        insuranceValue: NullableString(insurance?.insuranceValue ? insurance?.insuranceValue / noCentsDivider : ''),
        isNewCar: getNewCarOfferFlagValue(isNewCar),
        newCarOffer: getNewCarOfferFlagValue(isNewCar),
        startDate: insurance?.startDate || null,
      })
    }
  }, [currentOpportunity, reset])

  const me = useSelector(selectMeWithoutPermissions)
  const currentLanguage = useSelector(selectCurrentLanguage)
  const shouldDisableQuickNotificationsButton = () => !(currentOpportunity?.client || currentOpportunity?.owner)
  const eventTypes = mapToOptions(Object.keys(OpportunityTradeEventType), 'crmTrade.eventType', t)

  const watchedStatus = useWatch({ control, name: 'status', defaultValue: currentOpportunity?.status })
  const toggleDialogVisible = () => { setEventDialogVisible(!eventDialogVisible) }
  const handleGoBack = () => { history.goBack() }
  const handleCancel = () => { reset() }
  const getOwner = () => currentOpportunity?.client || currentOpportunity?.owner
  const getStatusOrDefault = (status: OpportunityPurchaseStatusType | null) => status || currentOpportunity?.status
  const getNewCarOfferValue = (fieldValue: RadioValue) => fieldValue === RadioValue.Yes || false

  const handleQuickNotificationsClick = (opportunityPurchase: OpportunityPurchase) =>
    () => opportunityPurchase.client?.phone ? history.push(buildUrl(
      Paths.CrmServiceQuickCampaign,
      { phone: opportunityPurchase.client.phone }),
    ) : history.push(Paths.CrmServiceQuickCampaign)

  const submitForm = (opportunityPurchase: OpportunityPurchase) => handleSubmit(formValues => {
    const {
      actionDate,
      innerValuation,
      failureExplanation,
      failureReason,
      invoiceNumber,
      plannedSaleFrom,
      plannedSaleTo,
      status } = formValues

    updateOpportunity({
      actionDate,
      innerValuation,
      isNewCar: getNewCarOfferValue(formValues.isNewCar),
      newCarOffer: getNewCarOfferValue(formValues.isNewCar),
      plannedSaleFrom,
      plannedSaleTo,
      ...(status === OpportunityPurchaseStatusType.FAILURE && { failureReason, failureExplanation }),
      ...(status === OpportunityPurchaseStatusType.SUCCESS && { invoiceNumber }),
      status: getStatusOrDefault(status),
      uuid: opportunityPurchase.uuid,
    })
  })

  const handleNewCommentSend = (comment: string) => {
    if (me && currentOpportunity) {
      sendNewComment(currentOpportunity, comment)
    }
  }

  const sendEvent = (id: OpportunityPurchaseId) => (event: OpportunityTradeInternalEventFormWithAttachments) => {
    toggleDialogVisible()
    const { attachments, ...restEvent } = event
    dispatch(CrmTradeOpportunityPurchaseAction.sendInternalEventWithAttachments(id, {
      ...restEvent,
    }, attachments))
  }

  if (!currentOpportunity) { return null }

  const getOpportunityCreationDate = () => (
    <OpportunityDataFlag>
      <label data-testid={`${testId}creation-date-label`}>{t('caption.creationDate')}:</label>
      <OpportunityHeaderValue data-testid={`${testId}creation-date`}>
        <FormattedDate date={currentOpportunity.created} format={defaultDateFormat} />
      </OpportunityHeaderValue>
    </OpportunityDataFlag>
  )

  // TODO remove after API (flag isFromNdc or similar name) ready
  // tslint:disable-next-line: strict-type-predicates
  const getOpportunitySource = () => typeof currentOpportunity.isFromNdc === 'boolean' && (
    <OpportunityDataFlag>
      <label data-testid={`${testId}isFromFinance-label`}>{t('caption.source')}:</label>
      <OpportunityHeaderValue data-testid={`${testId}isFromFinance-value`}>
        {currentOpportunity.isFromNdc ? t('crmTrade.source.ndc') : t('crmTrade.source.application')}
      </OpportunityHeaderValue>
    </OpportunityDataFlag>
  )

  const getOpportunityKind = () => (
    <OpportunityDataFlag>
      <label data-testid={`${testId}kind-label`}>
        {t('crmTrade.opportunityPurchase.header.opportunityType')}:
      </label>
      <OpportunityHeaderValue data-testid={`${testId}kind-value`}>
        {currentOpportunity.isFromFinance ? t('crmTrade.fromFinance') : t('crmTrade.tradePotential')}
      </OpportunityHeaderValue>
    </OpportunityDataFlag>
  )

  const getOpportunityFinancingEndDate = () => currentOpportunity.financeEndDate && (
    <OpportunityDataFlag>
      <label data-testid={`${testId}financeEndDate-label`}>
        {t('crmTrade.opportunityPurchase.header.financeEndDate')}:
      </label>
      <OpportunityHeaderValue data-testid={`${testId}financeEndDate-value`}>
        <FormattedDate date={currentOpportunity.financeEndDate} format={defaultDateFormat} />
      </OpportunityHeaderValue>
    </OpportunityDataFlag>
  )

  const getQuickNotificationsButton = () => hasAdminAllServicePermissions && (
    <QuickNotificationsButton
      disabled={shouldDisableQuickNotificationsButton()}
      theme={BtnTheme.Link}
      iconType={IconType.Speed}
      caption={t('quickNotifications')}
      noPadding={false}
      onClick={handleQuickNotificationsClick(currentOpportunity)}
    />
  )

  return (
    <>
      <div>
        <Header>
          <h2>{t('crm.opportunityPurchase')}</h2>
          <HeaderContent>
            {getOpportunityCreationDate()}
            {getOpportunitySource()}
            {getOpportunityKind()}
            {getOpportunityFinancingEndDate()}
          </HeaderContent>
        </Header>
        <Content>
          <OpportunityContent>
            <StatusWithActionData>
              <Controller
                render={({ onChange, value }) => (
                  <OpportunityStatusComponent
                    currentStatus={value}
                    onChange={onChange}
                    statuses={Object.values(OpportunityPurchaseStatusType)}
                  />
                )}
                defaultValue={currentOpportunity.status}
                control={control}
                name='status'
              />
              <ActionData>
                <label>{t('crmTrade.opportunityPurchase.opportunityDate')}:</label>
                <Controller
                  render={({ onChange, value }) => (
                    <DatePickerInput timeDisabled={true} date={getDateValue(value)} onChange={onChange} />
                  )}
                  defaultValue={currentOpportunity.actionDate}
                  control={control}
                  name='actionDate'
                />
              </ActionData>
            </StatusWithActionData>
            <OpportunityAdditionalInformation
              control={control}
              currentLanguage={currentLanguage}
              currentStatus={watchedStatus as OpportunityPurchaseStatusType}
              errors={errors}
              opportunity={currentOpportunity}
              register={register}
              testId={testId}
            />
            <OpportunityOwner owner={getOwner()} header='crmTrade.seller' />
            <CarData
              car={currentOpportunity.car}
              equipment={currentOpportunity.equipment}
              control={control}
              inspection={currentOpportunity.inspection}
              insurance={currentOpportunity.insurance}
              innerValuation={currentOpportunity.innerValuation}
              register={register}
              testId={testId}
            />
            <CarValue
              control={control}
              insurance={currentOpportunity.insurance}
              innerValuation={currentOpportunity.innerValuation}
              register={register}
              testId={testId}
            />
            <RestOwnerCars owner={currentOpportunity.owner} />
            <MatchedSaleOpportunities purchaseId={currentOpportunity.uuid} />
            <BusinessEventsHistory
              className='internal-comments'
              businessEvents={currentOpportunity.businessEvents}
              currentLanguage={currentLanguage}
            />

          </OpportunityContent>
          <OpportunityCommentsAndServiceHistory comments={currentOpportunity.comments} onSend={handleNewCommentSend} />
        </Content>
        <FormFooter>
          <FormFooterLeft>
            <LinkButton
              onClick={handleGoBack}
              type={BtnType.Button}
              noPadding={true}
            >
              {t('caption.back')}
            </LinkButton>
          </FormFooterLeft>
          <FormFooterRight>
            {getQuickNotificationsButton()}
            <LinkButton onClick={handleCancel} type={BtnType.Button}>{t('caption.cancel')}</LinkButton>
            <ButtonWithIconStyled
              theme={BtnTheme.Minor}
              iconType={IconType.Event}
              caption={t('registerEvent')}
              onClick={() => setEventDialogVisible(true)}
              noPadding={false}
            />
            <PrimaryButton
              disabled={isAcceptButtonDisabled}
              onClick={submitForm(currentOpportunity)}
              type={BtnType.Button}
            >
              {t('caption.accept')}
            </PrimaryButton>
          </FormFooterRight>
        </FormFooter>
      </div>
      <UploadFileProgress />
      <AddOpportunityEvent
        currentUser={me}
        onClose={toggleDialogVisible}
        open={eventDialogVisible}
        options={eventTypes as ReadonlyArray<SelectOption<OpportunityTradeEventType>>}
        sendEvent={sendEvent(currentOpportunity.uuid)}
      />
      <UploadFileProgress />
    </>
  )
}
