import { Attachment } from 'actff-bo-app/components/Attachment'
import { FormattedDate } from 'actff-bo-app/components/DateTime'
import { Rating } from 'actff-bo-app/components/Rating'
import { TdDecorator } from 'actff-bo-app/components/Table/Td'
import { OpportunityAssign } from 'actff-bo-app/Crm/Trade/OpportunityAssign'
import { Counterparty } from 'actff-bo-lib/car'
import { getFullName } from 'actff-bo-lib/client'
import { OpportunityListType } from 'actff-bo-lib/crm/dto'
import { OpportunityPurchase, TradeOpportunityType } from 'actff-bo-lib/crm/trade'
import { defaultDateFormat } from 'actff-bo-lib/date'
import { createRoute } from 'actff-bo-lib/menu/helpers'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { selectMe, UserWithoutPermissions } from 'actff-bo-lib/user'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Td, Tr as TrStyled, TrDanger, TrWarning } from 'styles'

import { OpportunityRowStatus } from './OpportunityRowStatus'

type Props = {
  readonly opportunity: OpportunityPurchase,
  readonly opportunityListType: OpportunityListType,
  readonly rowComponent: typeof TrStyled | typeof TrDanger | typeof TrWarning,
  readonly userList: ReadonlyArray<UserWithoutPermissions> | null,
}

export const TdAttachment = styled(Td)`
  padding: 20px 24px;
`

export const OpportunityRow: React.FC<Props> = ({ opportunity, rowComponent: Tr, opportunityListType, userList }) => {
  const { assignee, car, client, owner, inspection, actionDate, matches, uuid } = opportunity
  const href = createRoute(Paths.CrmTradePurchaseOpportunity, { opportunityId: uuid })
  const LinkedTd = TdDecorator(href)
  const getPerson = () => client || owner

  const currentUser = useSelector(selectMe)
  const attachmentThumbnailSize = 60
  const ratingDivider = 2
  const getFirstAttachmentIfExists = () => inspection && inspection.attachments.length > 0 &&
    <Attachment url={inspection.attachments[0]} thumbnailSize={attachmentThumbnailSize} />

  const getRatingIfExists = () => inspection &&
    <Rating defaultValue={inspection.rating / ratingDivider} name='rating' readonly={true} />

  const getPersonName = () => client ? getFullName(client) : owner && (owner as Counterparty).name

  return (
    <>
      <Tr clickable={true} dangerStyleLimit={0} isStyled={true} time={opportunity.actionDate}>
        <LinkedTd>
          <OpportunityAssign
            assignee={assignee}
            currentUser={currentUser}
            opportunityId={uuid}
            opportunityListType={opportunityListType}
            opportunityType={TradeOpportunityType.Purchase}
            userList={userList}
          />
        </LinkedTd>
        <LinkedTd>
          <OpportunityRowStatus {...opportunity} />
        </LinkedTd>
        <LinkedTd><FormattedDate date={actionDate} format={defaultDateFormat} /></LinkedTd>
        <LinkedTd>{getPersonName()}</LinkedTd>
        <LinkedTd>{getPerson()?.phone}</LinkedTd>
        <LinkedTd>{getPerson()?.email}</LinkedTd>
        <LinkedTd>{car.model}</LinkedTd>
        <LinkedTd>{car.productionYear}</LinkedTd>
        <Td>{getRatingIfExists()}</Td>
        <TdAttachment>{getFirstAttachmentIfExists()}</TdAttachment>
        <LinkedTd>{matches?.length}</LinkedTd>
      </Tr>
    </>
  )
}
