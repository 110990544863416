import { CarInsurance, CarInsuranceId, ValueForInsuranceCalculation } from 'actff-bo-lib/car/dto'

export const carInsuranceInitialValues: CarInsurance = {
  ac: false,
  assistance: false,
  assistanceInHouse: {
    expirationDate: null,
    insurer: '',
    policyNumber: '',
  },
  expirationDate: null,
  gap: {
    insurer: '',
  },
  inHouse: false,
  insuranceValue: null,
  insurer: '',
  manufacturerAssistance: false,
  marketValue: null,
  nnw: false,
  oc: false,
  policyNumber: '',
  uuid: '' as CarInsuranceId,
  valueForInsuranceCalculation: ValueForInsuranceCalculation.GROSS,
}
