import { oneSecond } from 'actff-bo-lib/date'
import { differenceInMinutes } from 'date-fns'
import * as React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = {
  readonly date: Date,
}

type State = {
  readonly interval: NodeJS.Timer | null,
  readonly minutesAgo: number,
}

type StatusIconProps = {
  readonly color: string,
}

// tslint:disable-next-line
const timeToUpdateStatus = 30 * oneSecond
const minutesAgoLimit = 30

const StatusIcon = styled.span`
  min-height: 10px;
  position: relative;

  :after {
    background: ${({ color }: StatusIconProps) => color};
    border-radius: 50%;
    content: ' ';
    height: 8px;
    position: absolute;
    right: 0;
    width: 8px;
  }
`

export class ChatThreadListItemStatus extends React.Component<Props, State> {
  public readonly state: State = {
    interval: null,
    minutesAgo: 0,
  }

  public componentDidUpdate(prevProps: Props): void {
    if (prevProps.date !== this.props.date) {
      this.setInterval()
    }
  }

  public componentDidMount(): void {
    this.setInterval()
  }

  public componentWillUnmount(): void {
    this.clearInterval()
  }

  public render(): React.ReactNode {
    const { minutesAgo } = this.state

    return <StatusIcon color={minutesAgo < minutesAgoLimit ? colors.kumera : colors.flamingoRed} />
  }

  private readonly minutesAgo = (): number => differenceInMinutes(new Date(), this.props.date)

  private readonly updateMinutes = (): void => {
    this.setState({ minutesAgo: this.minutesAgo() })
    if (this.minutesAgo() >= minutesAgoLimit) {
      this.clearInterval()
    }
  }

  private readonly clearInterval = (): void => {
    if (this.state.interval) {
      clearInterval(this.state.interval)
      this.setState({ interval: null })
    }
  }

  private readonly setInterval = (): void => {
    this.setState({
      minutesAgo: this.minutesAgo(),
    })

    this.setState({
      interval: this.minutesAgo() < minutesAgoLimit ? setInterval(this.updateMinutes, timeToUpdateStatus) : null,
    })
  }
}
