import { ExpandCollapseButton } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

type Props = Testable & {
  readonly title: string | React.ReactNode,
  readonly subtitle?: string | React.ReactNode,
  readonly hasErrors?: boolean,
  readonly expanded?: boolean,
}

type SectionProps = {
  readonly hasErrors: boolean,
}

type ContentProps = {
  readonly expanded: boolean,
}

const Container = styled.div`
  margin-top: 10px;
`

const Section = styled.div`
  background: ${colors.mysticGray};
  border: ${({ hasErrors }: SectionProps) => hasErrors ? '2px solid red' : 'none'};
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  margin: 0 32px;
  padding: 16px 24px;
`

const Title = styled.span`
  font-weight: 600;
  margin-right: 30px;
`

const Content = styled.div`
  display: ${({ expanded }: ContentProps) => expanded ? 'block' : 'none'};
  margin: 24px 32px;
`

export const CollapsableSection: FC<Props> = ({ children, hasErrors, expanded: expandedDefault, testId, title, subtitle }) => {
  const [expanded, setExpanded] = useState(expandedDefault || false)
  const handleToggleExpanded = () => setExpanded(!expanded)

  useEffect(() => setExpanded(expandedDefault || false), [expandedDefault])

  return (
    <Container>
      <Section data-testid={`${testId}-section`} hasErrors={hasErrors} onClick={handleToggleExpanded} >
        <div>
          <Title>{title}</Title>
          <span>{subtitle}</span>
        </div>
        <ExpandCollapseButton expanded={expanded} onClick={handleToggleExpanded} />
      </Section>
      <Content expanded={expanded}>{children}</Content>
    </Container>
  )
}
