import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import { FormCell } from 'actff-bo-app/components/Form'
import styled from 'styled-components'
import { colors, tabletBoundary } from 'styles'

export const Form = styled.form`
  input {
    width: 290px;
    @media screen and (max-width: ${tabletBoundary}) {
      width: 205px;
    }
  }
`
export const Separator = styled.hr`
  background-color: ${colors.mysticGray};
  border: none;
  clear: both;
  height: 1px;
  width: 100%;
`
export const BasicData = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: ${tabletBoundary}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const RemarkSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`

export const AdditionalDescriptionContainer = styled.div`
  align-items: center;
  background: ${colors.athensGray};
  display: grid;
  grid-template-columns: 1fr 5fr;
  margin: 16px 17px;
  padding: 16px 32px;
`
export const CommentsContainer = styled.div`
  margin: 15px 32px 15px 0;
`

export const CommentHeader = styled.div`
  display: grid;
  font-weight: 600;
  grid-auto-flow: column;
  justify-content: space-between;
  margin: 10px 0;
`
export const CommentContent = styled.div`
  font-weight: 300;
  font-style: italic;
  line-height: 23px;
`

export const Textarea = styled.textarea`
  border-radius: 3px;
  border: 1.5px solid ${colors.mysticGray};
  font-size: 13px;
  font-weight: 300;
  line-height: 23px;
  margin-top: 15px;
  min-height: 100px;
  outline: none;
  resize: vertical;
  width: 100%;
`

export const OpportunityAddInternalCommentContainer = styled(FormCell)`
  grid-area: add-internal-comment;
  padding-right: 32px;
`

export const AddButton = styled(ButtonWithIcon)`
  text-align: left;
  width: 100%;
`

export const Subheader = styled.div`
  color: ${colors.black};
  font-weight: 600;
  margin: 16px 0;
  text-transform: uppercase;
`

export const KnownMileageContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 205px);

  [type="number"] {
    width: 140px;
  }
`

export const KnownMileageFakeContainer = styled(FormCell)`
  align-self: center;
  padding: 0;
  margin-top: 18px;
`

export const OriginValuesContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  margin-top: 5px;
`

export const BasicDataSecondContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const SectionErrorContainer = styled.span`
  margin-left: 12px;
`
