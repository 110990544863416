import styled from 'styled-components'

export const Values = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: auto 30px;
`

export const Value = styled.div`
  margin: 0;
`
