import { FormRow } from 'actff-bo-app/components/Form'
import { FieldWithError } from 'actff-bo-app/components/Form/FieldWithError'
import { Credentials } from 'actff-bo-lib/auth'
import { Form, FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'
import { PrimaryButton } from 'styles'

import { validationSchema } from './validation'

const FormStyled = styled(Form)`
  width: 100%;
`

const Label = styled.label`
  margin-bottom: 16px;
`

const Input = styled(FieldWithError)`
  height: 40px;
  margin-bottom: 32px;
  padding: 12px 18px;
  width: 100%;
`

const Button = styled(PrimaryButton)`
  margin-bottom: 16px;
  height: 42px;
  width: 100%;
`

type Props = {
  readonly login: (credentials: Credentials) => void,
}

type LoginFormProps = FormikProps<Credentials> & WithTranslation

const testId = 'login__'

const LoginFormComponent: React.FC<LoginFormProps> = ({ errors, isValid, t, touched }) => (
  <FormStyled>
    <FormRow>
      <Label htmlFor='login'>{t('login.form.login')}</Label>
      <Input
        data-testid={`${testId}input-login`}
        error={touched.login && errors.login}
        id='login'
        name='login'
        type='text'
      />
    </FormRow>
    <FormRow>
      <Label htmlFor='password'>{t('login.form.password')}</Label>
      <Input
        data-testid={`${testId}input-password`}
        error={touched.password && errors.password}
        id='password'
        name='password'
        type='password'
      />
    </FormRow>
    <FormRow>
      <Button
        data-testid={`${testId}button-submit`}
        disabled={!isValid}
        id='submit'
        type='submit'
      >
        {t('login.form.submit')}
      </Button>
    </FormRow>
  </FormStyled>
)

const formik = withFormik<Props, Credentials>({
  handleSubmit: (values: Credentials, { props }) => {
    props.login(values)
  },
  mapPropsToValues: () => ({
    login: '',
    password: '',
  }),
  validationSchema,
})

export const LoginForm = compose(
  formik,
  withTranslation(),
)(LoginFormComponent)
