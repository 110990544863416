import { InsuranceFilters } from '../filters'

// tslint:disable object-literal-sort-keys
export const defaultInsuranceListFilters: InsuranceFilters = {
  sourceName: [],
  status: [],
  expiresMin: null,
  expiresMax: null,
  createdMin: null,
  createdMax: null,
  oldPolicySellType: [],
  newPolicySellType: [],
  result: [],
  failureReason: [],
}
