import { LoggedInPermissions } from 'actff-bo-lib/user'

import { ComponentType, IconType, Path } from '../../dto'
import { Paths } from '../paths'
import { LoggedInWrappers } from '../wrappers'

// tslint:disable object-literal-sort-keys
export const taskList = {
  access: LoggedInPermissions,
  component: ComponentType.ServiceRequestList,
  expanded: false,
  icon: IconType.TaskList,
  isLink: false,
  name: 'menu.taskList',
  path: Paths.TaskList as Path,
  ...LoggedInWrappers,
  children: [
    {
      access: LoggedInPermissions,
      component: ComponentType.ServiceRequestList,
      expanded: false,
      name: 'menu.serviceRequests',
      path: Paths.ServiceRequests as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.ServiceRequestView,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.ServiceRequestsView as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.TelephoneRequestList,
      expanded: false,
      name: 'menu.telephoneRequests',
      path: Paths.TelephoneRequests as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.TelephoneRequestView,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.TelephoneRequestsView as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.OfferRequestList,
      expanded: false,
      name: 'menu.offers',
      path: Paths.OfferRequests as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.OfferRequestView,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.OfferRequestsView as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
    {
      access: LoggedInPermissions,
      component: ComponentType.TestDriveRequestList,
      expanded: false,
      name: 'menu.testDriveRequests',
      path: Paths.TestDriveRequests as Path,
      ...LoggedInWrappers,
      children: [
        {
          access: LoggedInPermissions,
          component: ComponentType.TestDriveRequestView,
          expanded: false,
          hiddenInMenu: true,
          path: Paths.TestDriveRequestsView as Path,
          ...LoggedInWrappers,
          children: [],
        },
      ],
    },
  ],
}
