import { ReactSelect } from 'actff-bo-app/components/Select'
import { carKnownMileageInterval, carMaxKnownMileage } from 'actff-bo-lib/car'
import { range, SelectOption } from 'actff-bo-lib/global'
import { Testable } from 'actff-bo-lib/global/testable'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Ref } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'

import { FilterContent } from './Styled'

type InputMileageRangeFilter = Testable & {
  readonly control: Control,
  readonly name: string,
  readonly caption?: string,
  readonly register?: (ref: Ref | null) => void,
  readonly startFrom?: SelectOption<string>,
}

export const InputMileageFilter: FC<InputMileageRangeFilter> = ({ name, control, caption, startFrom, testId }): React.ReactElement => {
  const { t } = useTranslation()
  const options =
    range(startFrom ? Number(startFrom.value) : 0, carMaxKnownMileage, carKnownMileageInterval).map(value => ({ label: value, value }))

  return (
    <FilterContent>
      {caption && <label>{t(caption)}:</label>}
      <Controller
        as={
          <ReactSelect
            isClearable={true}
            hideSelectedOptions={false}
            placeholder={t('placeholder.choose')}
            testId={`${testId}--${name}`}
            options={options}
            trimFilter={true}
          />
        }
        control={control}
        name={name}
      />
    </FilterContent>
  )
}
