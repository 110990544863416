import { Ref } from '@hookform/error-message/dist/types'
import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import styled from 'styled-components'
import { colors } from 'styles'

type BaseProps = {
  readonly onValueChange?: (name: string, value: boolean) => void,
  readonly control: Control,
  readonly name: string,
  readonly register: (ref: Ref | null) => void,
  readonly setValue?: (value: boolean) => void,
}

type LabeledProps = BaseProps & {
  readonly label: string,
}

type ChildfullProps = BaseProps & {
  readonly children: JSX.Element,
}

type Props = LabeledProps | ChildfullProps

type ItemProps = {
  readonly selected: boolean,
}

const Item = styled.button<ItemProps>`
  background-color: ${({ selected }: ItemProps) => selected ? colors.nepalBlue : colors.white};
  border-radius: 3px;
  border: 1.5px solid ${colors.mysticGray};
  box-sizing: border-box;
  cursor: pointer;
  font-family: Poppins,serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  height: 34px;
  line-height: 180%;
  padding: 6px;
  transition: background-color .3s ease;
  width: fit-content;;
`

export const SelectableItem: FC<Props> = ({ control, name, onValueChange, setValue, ...rest }) => (
  <Controller
    render={({ value, onChange }) => {
      const handleChange = () => {
        onChange(!value)
        onValueChange && onValueChange(name, !value)
        setValue && setValue(!value)
      }

      return (
        <Item
          onClick={handleChange}
          selected={value}
        >
          {rest.children ?? (rest as LabeledProps).label}
        </Item>
      )
    }}
    control={control}
    name={name}
  />
)
