import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styles'

type LiProps = {
  readonly autoWidth?: boolean,
}

export const Li = styled.li<LiProps>`
  background: ${theme.tabs.item.background.primary};
  border-radius: 4px 4px 0 0;
  bottom: 1px;
  display: grid;
  justify-items: center;
  position: relative;
  width: ${({ autoWidth = false }: LiProps) => autoWidth ? 'auto' : '180'};
`

export const LiActive = styled(Li)`
  background: ${theme.tabs.item.background.active};
  border: 1px solid ${theme.tabs.item.border.active};
  border-bottom: none;
  font-weight: bold;
  width: ${({ autoWidth = false }: LiProps) => autoWidth ? 'auto' : '180'};
`

export const LinkStyled = styled(NavLink)`
  cursor: pointer;
  height: 40px;
  padding: 10px 27px;
`
