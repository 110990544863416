import { AttachmentsViewer } from 'actff-bo-app/components/AttachmentsViewer'
import { OpportunityPurchaseInspection } from 'actff-bo-lib/crm/trade'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = {
  readonly inspection: OpportunityPurchaseInspection | null,
}

const Container = styled.div`
  align-items: start;
  display: grid;
  grid-row-gap: 10px;
  justify-content: center;
  padding-left: 34px;
`
const AttachmentsContainer = styled.div`
  padding: 0 24px 0 0;
`

export const CarGallery: FC<Props> = ({ inspection }) => {
  const { t } = useTranslation()

  if (!inspection || (inspection && inspection.attachments.length < 1)) {
    return <Container><label>{t('crmTrade.opportunityPurchase.noInspectionAttachments')}</label></Container>
  }

  return (
    <Container>
      <AttachmentsContainer>
        <AttachmentsViewer attachments={inspection?.attachments} />
      </AttachmentsContainer>
    </Container>
  )
}
