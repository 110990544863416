import { createAction } from '@reduxjs/toolkit'
import { DealerLocationKey } from 'actff-bo-lib/dealership'
import { AjaxError } from 'rxjs/ajax'
import { UpdateDealerLocationApplicationLinksPayload } from '../dto'
import { dealerLocationsSlice } from '../slice'

export const getLocationBrandsAction = createAction('[DEALERSHIP] GET LOCATION BRANDS')
export const getLocationBrandsActionFailure = createAction<AjaxError>('[DEALERSHIP] GET LOCATION BRANDS FAILURE')

export const getLocationApplicationLinksAction = createAction<DealerLocationKey>('[DEALERSHIP] GET APPLICATION LINKS')
export const getLocationApplicationLinksActionFailure = createAction<AjaxError>('[DEALERSHIP] GET APPLICATION LINKS FAILURE')

export const updateLocationApplicationLinksAction =
  createAction<UpdateDealerLocationApplicationLinksPayload>('[DEALERSHIP] UPDATE APPLICATION LINKS')
export const updateLocationApplicationLinksActionFailure = createAction<AjaxError>('[DEALERSHIP] UPDATE APPLICATION LINKS FAILURE')

export const {
  getLocationBrandsActionSuccess,
  getApplicationLinksActionSuccess,
  updateApplicationLinksActionSuccess,
} = dealerLocationsSlice.actions
