import { FixedFooter } from 'actff-bo-app/components/FixedFooter'
import {
  DatePickerInputField,
  Field,
  FormCell,
  FormCellFullWidth,
  FormCellWide,
  FormHeader,
  FormWrapper,
  SelectField,
} from 'actff-bo-app/components/Form'
import { YesNoInput } from 'actff-bo-app/components/Form/YesNoInput'
import { ValidationError } from 'actff-bo-app/components/ValidationError'
import {
  AmountType,
  CarFinance,
  carFinanceFormDataToCarFinanceDto,
  CarFinanceWithCarUuid,
  CarId,
  CarInfoWithClient,
  checkIsCarRejected,
  CurrencyType,
} from 'actff-bo-lib/car'
import { parseToDateOrNull } from 'actff-bo-lib/date'
import { FormikProps, withFormik } from 'formik'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'
import { boolean, mixed, object } from 'yup'

import { CarActionButtons } from '../CarActionButtons'
import { carFinanceInitialValues } from './initialValues'

const InstalmentContainer = styled.div`
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 120px 120px 120px;
`

const FinanceContainer = styled(FormCellFullWidth)`
  align-items: center;
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  width: 100%;
`

const AmountFieldContainer = styled.div`
  margin-top: 13px;

  input {
    max-width: 120px;
  }
`

export type CarViewFinanceFormValues = {
  readonly amount: number,
  readonly amountType: AmountType,
  readonly currency: CurrencyType,
  readonly financeEndDate: Date | null,
  readonly financeType: string,
  readonly inHouse: boolean,
  readonly lessorName: string,
}

export type CarViewFinanceFormProps = {
  readonly car: CarInfoWithClient,
  readonly carFinance: CarFinance | null,
  readonly deleteCar: (carId: CarId) => void,
  readonly rejectCar: (carId: CarId) => void,
  readonly revertCar: (carId: CarId) => void,
  readonly updateCarFinance: (carFinance: CarFinanceWithCarUuid) => void,
}

const testId = 'car-view-finance-form__'

export const CarViewFinanceFormComponent: React.FC<
    FormikProps<CarViewFinanceFormValues> & WithTranslation & CarViewFinanceFormProps
  > = ({ errors, touched, car, rejectCar, revertCar, deleteCar, submitForm, t, values }) => (

  <FormWrapper>
  {/* FINANCE IN HOUSE */}
    <FormCellWide>
      <YesNoInput label={t('carView.form.finance.inHouse')} name='inHouse' testId={`${testId}net-value`} />
    </FormCellWide>

    {/* FINANCE DATA*/}

    <FormCellFullWidth slim={true}>
      <FormHeader>{t('carView.form.finance.financeInfo')}</FormHeader>
    </FormCellFullWidth>

    <FinanceContainer>
      <FormCell>
        <label htmlFor='lessorName'>{t('carView.form.finance.lessorName')}</label>
        <Field
          id='lessorName'
          name='lessorName'
          disabled={!values.inHouse}
          component={SelectField}
          error={errors.lessorName}
          touched={touched.lessorName}
          // TODO dictionary data type
          options={[
            { label: 'GetinFeet', value: 'GETINFEET' },
            { label: 'mLeasing', value: 'MLEASING' },
          ]}
          testId={`${testId}lessor-name`}
        />
        <ValidationError testId={`${testId}lessor-name--error`} name='lessorName' />
      </FormCell>

      <FormCell>
        <label htmlFor='financeEndDate'>{t('carView.form.finance.financeEndDate')}</label>
        <Field
          id='financeEndDate'
          name='financeEndDate'
          disabled={!values.inHouse}
          error={errors.financeEndDate}
          touched={touched.financeEndDate}
          component={DatePickerInputField}
          testId={`${testId}finance--end-date`}
        />
        <ValidationError name='financeEndDate' testId={`${testId}finance-end-date--error`} />
      </FormCell>

      <FormCell>
        <label htmlFor='financeType'>{t('carView.form.finance.type')}</label>
        <Field
          id='financeType'
          name='financeType'
          disabled={!values.inHouse}
          component={SelectField}
          // TODO dictionary data type
          options={[
            { label: 'leasing', value: 'leasing' },
            { label: 'kredyt', value: 'kredyt' },
            { label: 'wynajem', value: 'wynajem' },
            { label: 'Care by Volvo', value: 'Care by Volvo' },
            { label: 'Volvo Car Easy Lease', value: 'Volvo Car Easy Lease' }]}
          testId={`${testId}finance--type`}
        />
      </FormCell>
    </FinanceContainer>

    <FormCell>
      <label>{t('carView.form.finance.instalment')}</label>
      <InstalmentContainer>
        <AmountFieldContainer>
          <Field data-testid={`${testId}amount`} disabled={!values.inHouse} id='amount' name='amount' type='text'/>
        </AmountFieldContainer>
        <FormCell>
          <Field
            id='currency'
            name='currency'
            disabled={!values.inHouse}
            withLabel={false}
            component={SelectField}
            options={[{ label: 'PLN', value: 'PLN' } , { label: 'USD', value: 'USD' }]}
            testId={`${testId}currency`}
          />
        </FormCell>
        <FormCell>
          <Field
            id='amountType'
            name='amountType'
            disabled={!values.inHouse}
            withLabel={false}
            component={SelectField}
            options={[{ label: t('caption.net'), value: 'net' } , { label: t('caption.gross'), value: 'gross' }]}
            testId={`${testId}amount-type`}
          />
        </FormCell>
      </InstalmentContainer>
    </FormCell>

    <FixedFooter>
      <CarActionButtons
        carId={car.uuid}
        inRejectedMode={checkIsCarRejected(car)}
        onAccept={submitForm}
        onDelete={deleteCar}
        onReject={rejectCar}
        onRevert={revertCar}
        testId={testId}
      />
    </FixedFooter>
  </FormWrapper>
)

// tslint:disable:object-literal-sort-keys
const validationSchema = () => object<CarViewFinanceFormValues>().shape({
  inHouse: boolean().required(),
  financeEndDate: mixed()
    .when('inHouse', {
      is: true,
      then: mixed().required('carView.form.finance.financeEndDate.isRequired'),
      otherwise: mixed().nullable(true),
    }),
  lessorName: mixed()
    .when('inHouse', {
      is: true,
      then: mixed().required('carView.form.finance.lessorName.isRequired'),
      otherwise: mixed().nullable(true),
    }),
})

const formik = withFormik<CarViewFinanceFormProps, CarViewFinanceFormValues>({
  handleSubmit: (values: CarViewFinanceFormValues, { props }) => {
    const car = props.car
    const carFinance = props.carFinance || carFinanceInitialValues

    props.updateCarFinance(
      carFinanceFormDataToCarFinanceDto(car.uuid, carFinance, values),
    )
  },
  mapPropsToValues: ({ carFinance: carFinanceFromProps }) => {
    const carFinance = carFinanceFromProps || carFinanceInitialValues
    const { amount, amountType, currency, financeType, inHouse, lessorName } = carFinance

    return {
      amount,
      amountType,
      currency,
      financeEndDate: parseToDateOrNull(carFinance.financeEndDate),
      financeType,
      inHouse,
      lessorName,
    }
  },
  validationSchema,
})

export const CarViewFinanceForm = compose(
  formik,
  withTranslation(),
)(CarViewFinanceFormComponent)
