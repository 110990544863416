import { Popover } from '@material-ui/core'
import { Dialog } from 'actff-bo-app/components/Dialog'
import { Icon } from 'actff-bo-app/components/Icon'
import { Link } from 'actff-bo-app/components/Popover'
import { OpportunityInsuranceId } from 'actff-bo-lib/crm/insurance'
import { CrmInsuranceOpportunityAction } from 'actff-bo-lib/crm/insurance/actions/opportunity'
import { IconType } from 'actff-bo-lib/menu'
import { hasPermission, selectUserPermissions, UserPermissions } from 'actff-bo-lib/user'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { H1, Header } from 'styles'

type Props = {
  readonly isEditedByOtherUser: boolean,
  readonly editingUserName: string,
  readonly opportunityId?: OpportunityInsuranceId,
}

const OpportunityHeaderWrapper = styled(Header)`
  display: grid;
`

const OpportunityH1 = styled(H1)`
  grid-column: 1;
  grid-row: 1
`

const Viewer = styled.div`
  align-items: center;
  align-self: center;
  display: grid;
  grid-auto-flow: column;
  grid-column: 2;
  grid-gap: 10px;
  justify-self: right;
`

const EditIcon = styled(Icon)`
  height: 20px;
  width: 20px;
`

const EditIconWrapper = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: grid;
  height: 40px;
  justify-content: center;
  width: 40px;
`

const Menu = styled.div`
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
`

const MenuIconContainer = styled.div`
  height: 20px;
  width: 20px;
`

export const OpportunityHeader: FC<Props> = ({ isEditedByOtherUser, editingUserName, opportunityId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userPermissions = useSelector(selectUserPermissions)
  const hasAdminAllInsurancePermissions = hasPermission([UserPermissions.AdminAllInsurance])(userPermissions)

  const shouldRenderDeleteOpportunity = opportunityId && hasAdminAllInsurancePermissions && !isEditedByOtherUser

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const handleMenuIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setPopoverAnchor(event.currentTarget)
    setPopoverOpen(true)
  }

  const closePopover = () => {
    setPopoverAnchor(null)
    setPopoverOpen(false)
  }

  const toggleOpenConfirmDialog = () => {
    setConfirmDialogOpen(!confirmDialogOpen)
  }

  const handleConfirmDeleteOpportunity = () => {
    opportunityId && dispatch(CrmInsuranceOpportunityAction.deleteOpportunity(opportunityId))
  }

  const renderMenu = () => shouldRenderDeleteOpportunity && (
    <Menu>
      <MenuIconContainer onClick={handleMenuIconClick}>
        <Icon type={IconType.More} />
      </MenuIconContainer>
      <Popover
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={closePopover}
        open={popoverOpen}
        anchorEl={popoverAnchor}
      >
        <Link onClick={toggleOpenConfirmDialog}>
          <Icon type={IconType.Trash} />
          <span>{t('crmInsurance.view.deleteOpportunity')}</span>
        </Link>
      </Popover>
    </Menu>
  )

  const renderConfirmDialog = () => shouldRenderDeleteOpportunity && (
    <Dialog
      content='confirmDialog.opportunity.delete.content'
      open={confirmDialogOpen}
      onConfirm={handleConfirmDeleteOpportunity}
      onClose={toggleOpenConfirmDialog}
      title='confirmDialog.opportunity.delete.title'
    />
  )

  const renderCurrentViewer = () =>
    isEditedByOtherUser && (
      <Viewer>
        <EditIconWrapper><EditIcon type={IconType.EditBlack} /></EditIconWrapper>
        <p>{t('crmInsurance.view.editedBy')}: <b>{editingUserName}</b></p>
      </Viewer>
    )

  return (
    <OpportunityHeaderWrapper centered={true} withAlert={isEditedByOtherUser}>
      <OpportunityH1>
        {t('crmInsurance.view.header')}
        {renderMenu()}
        {renderConfirmDialog()}
      </OpportunityH1>
      {renderCurrentViewer()}
    </OpportunityHeaderWrapper>
  )
}
