import { PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car/dto'
import { Client, ClientFilters, ClientPersonalData, ClientPreferences, ContactApprovals } from 'actff-bo-lib/client/dto'
import { filtersInitialState } from 'actff-bo-lib/client/filters-initial-state'
import { OpportunityPurchase, OpportunitySale } from 'actff-bo-lib/crm/trade'
import { Loadable, Searchable } from 'actff-bo-lib/global/data-types'
import { Paths } from 'actff-bo-lib/menu/initial-menu'
import { parseUrl } from 'actff-bo-lib/pagination'
import { history } from 'actff-bo-lib/router'
import { parse } from 'query-string'

export type ClientState = {
  readonly clientCount: number,
  readonly currentClient: Loadable<Client>,
  readonly currentClientMatchedPurchaseOpportunities: Loadable<ReadonlyArray<OpportunityPurchase>>,
  readonly currentClientMatchedSaleOpportunity: Loadable<OpportunitySale>,
  readonly currentClientPreferences: Loadable<ClientPreferences>,
  readonly currentClientCars: Loadable<PagedResult<CarInfo>>,
  readonly currentClientCarsPage: number,
  readonly currentClientPersonalDataHistory: Loadable<ClientPersonalData>,
  readonly currentContactApprovals: Loadable<ContactApprovals>,
  readonly currentPurchaseOpportunitiesSearch: Searchable<Loadable<PagedResult<ContactApprovals>>>,
  readonly currentPage: number,
  readonly filters: ClientFilters,
  readonly list: Searchable<Loadable<PagedResult<Client>>>,
}

const parsedClientUrl = parseUrl(Paths.ClientList)
const parsedCurrentClientCarsUrl = parse(history.location.search)

export const initialState: ClientState = {
  clientCount: 0,
  currentClient: {
    data: null,
    loading: false,
  },
  currentClientCars: {
    data: null,
    loading: false,
  },
  currentClientCarsPage: parsedCurrentClientCarsUrl.page ? +parsedCurrentClientCarsUrl.page : 1,
  currentClientMatchedPurchaseOpportunities: {
    data: null,
    loading: false,
  },
  currentClientMatchedSaleOpportunity: {
    data: null,
    loading: false,
  },
  currentClientPersonalDataHistory: {
    data: null,
    loading: false,
  },
  currentClientPreferences: {
    data: null,
    loading: false,
  },
  currentContactApprovals: {
    data: null,
    loading: false,
  },
  currentPage: parsedClientUrl.page ? +parsedClientUrl.page : 1,
  currentPurchaseOpportunitiesSearch: {
    data: {
      noOfPages: 0,
      pageNo: 0,
      result: [],
    },
    loading: false,
    searchPhrase: '',
  },
  filters: filtersInitialState,
  list: {
    data: null,
    loading: false,
    searchPhrase: '',
  },
}
