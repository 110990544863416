export const layers = {
  dropdownContent: 2,
  fixedFooter: 2,
  menuActiveIndicator: 1,
  navbar: 3,
  overlay: 1,
  selectMenu: 3,
  tabs: 2,
  tooltip: 2,
}
