import { Padder } from 'actff-bo-app/components/Padder'
import { TabItem, Tabs } from 'actff-bo-app/components/Tabs/Tabs'
import { OpportunityInternalComment } from 'actff-bo-lib/crm'
import { Testable } from 'actff-bo-lib/global/testable'
import i18next from 'i18next'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles'
import { Comments } from './Comments'

type Props = Testable & {
  readonly comments: ReadonlyArray<OpportunityInternalComment>,
  readonly onSend: (newComment: string) => void,
}

const Container = styled.div`
  border-left: 1px solid ${colors.mystic};
  height: 100%;
`

const TabsStyled = styled(Tabs)`
  height: 100%;
  padding-bottom: 70px;
`

const getTabItems = (
  comments: ReadonlyArray<OpportunityInternalComment>,
  onSend: (newComment: string) => void, t: i18next.TFunction,
  testId?: string,
): ReadonlyArray<TabItem> => ([
  {
    children: <Comments comments={comments} onSend={onSend} testId={testId} />,
    label: t('internalComments.header'),
  },
  {
    children: <Padder>{t('soon')}</Padder>,
    label: t('opportunity.serviceHistory'),
  },
])

export const OpportunityCommentsAndServiceHistory: FC<Props> = ({ comments, onSend, testId }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <TabsStyled tabs={getTabItems(comments, onSend, t, testId)} />
    </Container>
  )
}
