import { PagedResult } from 'actff-bo-lib/api'
import { CarInfo } from 'actff-bo-lib/car'
import { WithDealershipFilters } from 'actff-bo-lib/dealership'
import { Filters, Loadable, Searchable } from 'actff-bo-lib/global'
import { Paths } from 'actff-bo-lib/menu/initial-menu/paths'
import { parseUrl } from 'actff-bo-lib/pagination'
import { UserAction, UserActionType } from 'actff-bo-lib/user'

import { TelephoneRequestAction, TelephoneRequestActionType } from './actions'
import { TelephoneRequest, TelephoneRequestFilterValues, TelephoneRequestStatus } from './dto'

export type TelephoneRequestState = {
  readonly currentPage: number,
  readonly currentTelephoneRequest: TelephoneRequest | null,
  readonly currentTelephoneRequestCars: PagedResult<CarInfo> | null,
  readonly list: Searchable<WithDealershipFilters<Loadable<PagedResult<TelephoneRequest>>>>,
  readonly listFilters: Filters<TelephoneRequestFilterValues>,
  readonly newTelephoneRequestCount: number,
}

const parsedUrl = parseUrl(Paths.TelephoneRequests)
const initialState: TelephoneRequestState = {
  currentPage: parsedUrl.page ? +parsedUrl.page : 1,
  currentTelephoneRequest: null,
  currentTelephoneRequestCars: null,
  list: {
    brandFilters: [],
    data: null,
    loading: false,
    locationFilters: [],
  },
  listFilters: {
    status: [
      {
        caption: 'filters.new',
        selected: false,
        value: TelephoneRequestStatus.NEW,
      },
      {
        caption: 'filters.cancelled',
        selected: false,
        value: TelephoneRequestStatus.CANCELED,
      },
      {
        caption: 'filters.completed',
        selected: false,
        value: TelephoneRequestStatus.COMPLETED,
      },
    ],
  },
  newTelephoneRequestCount: 0,
}

// tslint:disable cyclomatic-complexity
export const telephoneRequestReducer = (
  state: TelephoneRequestState = initialState,
  action: TelephoneRequestAction | UserAction,
): TelephoneRequestState => {
  switch (action.type) {
    case TelephoneRequestActionType.ChangePage:
      return {
        ...state,
        currentPage: action.payload,
      }

    case TelephoneRequestActionType.ChangeListFilter:
      return {
        ...state,
        listFilters: {
          ...state.listFilters,
          [action.payload.param]: [
            ...state.listFilters[action.payload.param]
              .map(filter => filter.value === action.payload.value
                ? ({
                  ...filter,
                  selected: !filter.selected,
                })
                : ({ ...filter }),
              ),
          ],
        },
      }

    case TelephoneRequestActionType.ChangeSearchPhrase:
      return {
        ...state,
        list: {
          ...state.list,
          searchPhrase: action.payload,
        },
      }

    case TelephoneRequestActionType.ChangeDealerLocations:
      return {
        ...state,
        list: {
          ...state.list,
          locationFilters: action.payload,
        },
      }

    case TelephoneRequestActionType.ChangeBrands:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload,
        },
      }

    case TelephoneRequestActionType.GetTelephoneRequests:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }

    case TelephoneRequestActionType.GetTelephoneRequestsSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload,
          loading: false,
        },
      }

    case TelephoneRequestActionType.GetTelephoneRequestsFailure:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
      }

    case TelephoneRequestActionType.GetTelephoneRequestSuccess:
      return {
        ...state,
        currentTelephoneRequest: action.payload,
      }

    case TelephoneRequestActionType.GetNewTelephoneRequestCountSuccess:
      return {
        ...state,
        newTelephoneRequestCount: action.payload.requestCount,
      }

    case TelephoneRequestActionType.GetTelephoneRequestCarsSuccess:
      return {
        ...state,
        currentTelephoneRequestCars: action.payload,
      }

    case TelephoneRequestActionType.ClearTelephoneRequest:
      return {
        ...state,
        currentTelephoneRequest: null,
        currentTelephoneRequestCars: null,
      }

    case UserActionType.GetMeSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          brandFilters: action.payload.brands,
          locationFilters: action.payload.locations.map(location => location.key),
        },
      }

    default:
      return state
  }
}
