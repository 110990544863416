import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { ClientId, ContactApprovalsFromAPI, ContactApprovalsWithClientIdToAPI } from 'actff-bo-lib/client'

import { AjaxError } from 'rxjs/ajax'

export enum ContactApprovalsActionType {
  GetContactApprovals = '[CLIENT] GET CONTACT APPROVALS',
  GetContactApprovalsSuccess = '[CLIENT] GET CONTACT APPROVALS SUCCESS',
  GetContactApprovalsFailure = '[CLIENT] GET CONTACT APPROVALS FAILURE',

  UpdateContactApprovals = '[CLIENT] UPDATE CONTACT APPROVALS',
  UpdateContactApprovalsSuccess = '[CLIENT] UPDATE CONTACT APPROVALS SUCCESS',
  UpdateContactApprovalsFailure = '[CLIENT] UPDATE CONTACT APPROVALS FAILURE',
}

export const ContactApprovalsAction = {
  getContactApprovals: (clientUuid: ClientId) => createAction(ContactApprovalsActionType.GetContactApprovals, clientUuid),
  getContactApprovalsFailure: (error: AjaxError) => createAction(ContactApprovalsActionType.GetContactApprovalsFailure, error),
  getContactApprovalsSuccess: (data: ContactApprovalsFromAPI) =>
    createAction(ContactApprovalsActionType.GetContactApprovalsSuccess, data),
  updateContactApprovals: (data: ContactApprovalsWithClientIdToAPI) =>
    createAction(ContactApprovalsActionType.UpdateContactApprovals, data),
  updateContactApprovalsFailure: (error: AjaxError) => createAction(ContactApprovalsActionType.UpdateContactApprovalsFailure, error),
  updateContactApprovalsSuccess: (data: ContactApprovalsFromAPI) =>
    createAction(ContactApprovalsActionType.UpdateContactApprovalsSuccess, data),
}

export type ContactApprovalsAction = ActionsUnion<typeof ContactApprovalsAction>
