import { Overlay } from 'actff-bo-app/components/Overlay'
import {
  AttachmentUploadResultType,
  selectAttachmentsToUploadCount,
  selectAttachmentsUpload,
  selectAttachmentsUploadedCount,
} from 'actff-bo-lib/global'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { colors, H3 } from 'styles'

const Failed = styled(H3)`
  color: ${colors.burgundy};
`

const Container = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
`

export const UploadFileProgress: FC = () => {
  const uploadedCount = useSelector(selectAttachmentsUploadedCount)
  const { uploaded } = useSelector(selectAttachmentsUpload)

  const failedIndexes = Array.from(uploaded)
    .map((upload, index) => ({ upload, index }))
    .filter(u => u.upload === AttachmentUploadResultType.Failure)
    .map(failedUpload => failedUpload.index + 1)

  const toUpload = useSelector(selectAttachmentsToUploadCount)
  const { t } = useTranslation()

  return (
    toUpload
      ? (
        <Overlay opacity='0.85' color={colors.white}>
          <Container>
            <H3>{t('attachments.uploading', { uploadedCount, toUpload })}</H3>
            {failedIndexes.map(failedIndex => (
              <Failed key={failedIndex}>{t('attachments.uploading.failed')} {failedIndex}</Failed>
            ))}
          </Container>
        </Overlay>
      )
      : null
  )
}
