import { ButtonWithIcon } from 'actff-bo-app/components/Button'
import styled from 'styled-components'
import { theme } from 'styles'

type PaginationWrapperStyledProps = {
  readonly centered: boolean,
}

export const PaginationWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  ${({ centered }: PaginationWrapperStyledProps) => centered && `
    text-align: center;
  `}
`

export const PaginationNavigationStyled = styled.div`
  margin: 20px auto;

  li {
    cursor: pointer;
    list-style: none;
    display: inline-block;
  }

  a {
    display: inline-block;
    outline: none;
    padding: 3px 8px;
  }

  .active a {
    background-color: ${theme.pagination.item.background.active};
    border-radius: 5px;
    color: ${theme.pagination.item.text.primary};
  }

  .next img {
    transform: rotate(180deg);
  }
`

export const PaginationSummaryStyled = styled.div`
  position: absolute;
  left: 32px;
  top: 5px;
`

export const AddNewClientButton = styled(ButtonWithIcon)`
  bottom: 0;
  max-width: 180px;
  padding: 7px;
  position: absolute;
  right: 15px;
`
