import { ActionsUnion, createAction } from '@evojam/redux-utils'
import { AjaxError } from 'rxjs/ajax'
import { Service } from './dto'

export enum ServiceActionType {
  GetServices = '[SERVICE] GET SERVICES',
  GetServicesFailure = '[SERVICE] GET SERVICES FAILURE',
  GetServicesSuccess = '[SERVICE] GET SERVICES SUCCESS',
}

export const ServiceAction = {
  getServices: () => createAction(ServiceActionType.GetServices),
  getServicesFailure: (error: AjaxError) => createAction(ServiceActionType.GetServicesFailure, error),
  getServicesSuccess: (services: ReadonlyArray<Service>) => createAction(ServiceActionType.GetServicesSuccess, services),
}

export type ServiceAction = ActionsUnion<typeof ServiceAction>
