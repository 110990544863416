import { Icon } from 'actff-bo-app/components/Icon'
import styled from 'styled-components'
import { theme } from 'styles'

export const LogoContainer = styled.div`
  align-content: center;
  display: grid;
  height: ${theme.logoContainer.height};
`
export const LogoutContainer = styled.div`
  align-content: center;
  display: inline-grid;
  grid-template-columns: auto 50px;
  height: ${theme.logoContainer.height};
  padding-right: 17px;
`
export const LogoStyled = styled.img`
  margin-left: 32px;
`

export const TopBarContainer = styled.nav`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
`

export const LogoutIcon = styled(Icon)`
  cursor: pointer;
  height: 20px;
`
