export const objectDiff = <T extends object>(old: T, updated: T): Partial<T> =>
  Object.keys(updated).reduce((currentDiff, currentKey) =>
    old[currentKey] !== updated[currentKey]
      ? ({
        ...currentDiff,
        [currentKey]: updated[currentKey],
      })
      : ({
          ...currentDiff,
        })
  , {})
