import { combineReducers } from 'redux'

import { carReducer } from './car'
import { carEquipmentReducer } from './equipment'
import { carFinanceReducer } from './finance'
import { carInspectionReducer } from './inspection'
import { carInsuranceReducer } from './insurance'
import { newCarReducer } from './new-car'

import { ownershipReducer } from './ownership'
import { carTiresReducer } from './tires'

export const carRootReducer = combineReducers({
  car: carReducer,
  currentCarEquipment: carEquipmentReducer,
  currentCarFinance: carFinanceReducer,
  currentCarInspection: carInspectionReducer,
  currentCarInsurance: carInsuranceReducer,
  currentCarTires: carTiresReducer,
  newCar: newCarReducer,
  ownership: ownershipReducer,
})
