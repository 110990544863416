import { BtnTheme, ButtonWithIcon } from 'actff-bo-app/components/Button'
import { Testable } from 'actff-bo-lib/global/testable'
import { IconType } from 'actff-bo-lib/menu/dto'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type Props = Testable & {
  readonly onClick: () => void,
}

const AddMoreButtonStyled = styled(ButtonWithIcon)`
  justify-self: left;
`

export const AddMoreButton: React.FC<Props> = ({ onClick, testId }) => {
  const { t } = useTranslation()

  return (
    <AddMoreButtonStyled
      caption={t('serviceRequest.services.addNew')}
      iconType={IconType.Plus}
      onClick={onClick}
      testId={testId}
      theme={BtnTheme.Link}
    />
  )
}
